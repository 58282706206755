import React, { useMemo } from 'react'
import EventStart from './pressure_events_start'
import EventEnd from './pressure_events_end'
import { line } from 'd3-shape'

import styles from './styles.css'

const PressureEventsMarker = ({
  children,
  clipPath,
  index,
  graphConfig,
  pressureData,
  color,
  isActive = false,
  onClick,
}) => {
  if (pressureData.length === 0) {
    return null
  }

  const startMarkerCoordinates = useMemo(() => {
    const { xScale, yScale } = graphConfig
    if (!pressureData[0]) {
      return {
        startCoordinates: {
          x: 0,
          y: 0,
        },
        endCoordinates: {
          x: 0,
          y: 0,
        },
      }
    }
    return {
      startCoordinates: {
        x: xScale(pressureData[0].time),
        y: yScale(pressureData[0].mean),
      },
      endCoordinates: {
        x: xScale(pressureData[pressureData.length - 1].time),
        y: yScale(pressureData[pressureData.length - 1].mean),
      },
    }
  }, [pressureData, graphConfig])

  const dashedLinePath = useMemo(() => {
    return line()
      .x(d => graphConfig.xScale(d.time))
      .y(d => graphConfig.yScale(d.mean))(pressureData)
  }, [pressureData, graphConfig])

  return (
    <g key={index}>

      <HighlightedLinePlot
        clipPath={clipPath}
        stroke={color}
        color={color}
        d={dashedLinePath} />

      <EventStart
        isActive={isActive}
        {...startMarkerCoordinates.startCoordinates}
        color={color}
        onClick={onClick}>
        {children}
      </EventStart>

      <EventEnd
        isActive={isActive}
        {...startMarkerCoordinates.endCoordinates}
        color={color}
        onClick={onClick} />
    </g>
  )
}

function HighlightedLinePlot(props) {
  return <path
    strokeDasharray="0 4 8"
    fill="none"
    strokeWidth="2px"
    className={styles['highlight-line']}
    {...props} />
}

export default PressureEventsMarker
