import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { GraphConfig, withGraphConfig }
from '@@src/components/graphs/graph_context'

import styles from './area_overlay.css'

class AreaOverlay extends React.PureComponent {
  static propTypes = {
    xRange: PropTypes.arrayOf(PropTypes.number),
    yRange: PropTypes.arrayOf(PropTypes.number),
    className: PropTypes.string,
    graphConfig: PropTypes.instanceOf(GraphConfig).isRequired,
  }

  render() {
    const { xRange, yRange, className, graphConfig } = this.props

    const xOffset = graphConfig.leftPadding
    const yOffset = graphConfig.topPadding

    return (
      <g name="graph-area-layer">
        <rect
          className={classnames(
            styles.base,
            className || styles.default
          )}
          x={xRange ? xRange[0] : xOffset}
          y={yRange ? yRange[0] : yOffset}
          width={xRange ? xRange[1] - xRange[0] : graphConfig.plotAreaWidth}
          height={yRange ? yRange[1] - yRange[0] : graphConfig.plotAreaHeight}
        />
      </g>
    )
  }
}

export default withGraphConfig()(AreaOverlay)
