import React from 'react'
import { isMobile } from '../../../utils'
import { Col, Row } from 'reactstrap'
import { Link } from 'react-router-dom'

import styles from './summary_info.css'
const SummaryInfoTexts = ({
  name,
  value,
  color,
  text,
  link,
  isMobileDevice = false,
}) => {
  return (
    <Col className={`col-sm-auto ${styles.container}`}>
      <Row className="d-flex align-items-center">
        <div className={`${styles.circle} ${styles[color]} mr-1`} />

        {link ? (
          <Link
            name={name}
            className={
              `d-inline-block text-dark my-0 ${styles['hover-underline']}` +
              (isMobileDevice ? ' label' : ' mx-1 h5')
            }
            to={link}
          >
            {value}
          </Link>
        ) : (
          <span
            name={name}
            className={
              'd-inline-block text-dark my-0' +
              (isMobileDevice ? ' label' : ' mx-1 h5')
            }
          >
            {value}
          </span>
        )}
      </Row>
      <Row>
        <p className={`${styles['bottom-text']} mt-1`}>{text}</p>
      </Row>
    </Col>
  )
}

export const SummaryInfo = ({ summaryInfos }) => {
  const isMobileDevice = isMobile()

  return (
    <div
      className={`d-flex flex-column ${
        isMobileDevice ? 'pl-3 ml-1' : 'pl-5 pb-4'
      }`}
    >
      {summaryInfos.map((info) => (
        <SummaryInfoTexts key={info.text} {...info} />
      ))}
    </div>
  )
}
