import React, { memo } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { Button } from 'reactstrap'

function MoveUpHierarchyButton(props) {
  const { className, buttonClassName, handleClick } = props

  return (
    <div className={classnames('d-flex flex-column', className)}>
      <Button
        name="move-up-one-level-button"
        onClick={handleClick}
        color="link"
        type="button"
        className={classnames(
          'w-100 text-left pl-0 px-3 rounded-0 py-1',
          buttonClassName,
        )}>
        <span className="far fa-arrow-left mr-3 text-secondary" />
        <span className="text-dark">...</span>
      </Button>
    </div>
  )
}

MoveUpHierarchyButton.propTypes = {
  handleClick: PropTypes.func,
  className: PropTypes.string,
  buttonClassName: PropTypes.string,
}

export default memo(MoveUpHierarchyButton)
