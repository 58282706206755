import React, { useCallback, useState } from 'react'
import LoadingIcon from '../../../components/loading_icon'
import { useFeatureFlagsForTenant } from './hooks/get_feataure_flags_for_tenant'

export const FeatureFlags = {
  anomalyDetection: 'anomaliesFeatureEnabled',
  devicesListV2: 'devicesManagementListViewV2Enabled',
  networkAssetsListV2: 'networkAssetsManagementListViewV2Enabled',
}

export const TenantsFeatureFlagsContext = React.createContext({
  overrideFeatureFlag: () => null,
  isFeatureEnabled: () => false,
})

export const withTenantFeatureFlags = (Component) => (props) => (
  <TenantsFeatureFlagsContext.Consumer>
    {(contextValues) => (<Component {...props} {...contextValues} />)}
  </TenantsFeatureFlagsContext.Consumer>
)

export const isFeatureFlagEnabled = (flag, Component, FeaturedComponent) => props => (
  <TenantsFeatureFlagsContext.Consumer>
    {
      ({ isFeatureEnabled }) => isFeatureEnabled(flag)
        ? <FeaturedComponent {...props} />
        : <Component {...props} />
    }
  </TenantsFeatureFlagsContext.Consumer>
)

const storeFeatureFlagsOverrides = (overrides) => {
  if (window.localStorage) {
    window.localStorage.setItem('feature_flags', JSON.stringify(overrides))
  }
}

const getStoredFeatureFlagsOverrides = () => {
  try {
    if (window.localStorage) {
      return JSON.parse(window.localStorage.getItem('feature_flags'))
    }
    return {}
  } catch (e) {
    return {}
  }
}

const TenantsFeatureFlagsProvider = ({ children }) => {
  const { featureFlags, isLoading } = useFeatureFlagsForTenant()
  const [featureFlagsOverrides, setFeatureFlagsOverrides] = useState(getStoredFeatureFlagsOverrides())

  const overrideFeatureFlag = useCallback((featureFlag, newValue) => {
    const newOverrides = { ...featureFlagsOverrides, [featureFlag]: newValue || false }

    storeFeatureFlagsOverrides(newOverrides)
    setFeatureFlagsOverrides(newOverrides)
  }, [featureFlags, featureFlagsOverrides])

  const isFeatureEnabled = useCallback((featureFlag, options = {}) => {
    const { omitOverridden } = Object.assign({ omitOverridden: false }, options)
    const isEnabled = !!featureFlags && !!featureFlags.isEnabled(featureFlag)

    if (!omitOverridden && featureFlagsOverrides && featureFlagsOverrides.hasOwnProperty(featureFlag)) {
      return isEnabled && (featureFlagsOverrides[featureFlag] || false)
    }

    return isEnabled
  }, [featureFlags, featureFlagsOverrides])

  return (
    <TenantsFeatureFlagsContext.Provider value={{ isFeatureEnabled, overrideFeatureFlag }}>
      {isLoading ? <LoadingIcon /> : children}
    </TenantsFeatureFlagsContext.Provider>
  )
}

export default TenantsFeatureFlagsProvider
