import gql from 'graphql-tag'
import React from 'react'
import { compose } from 'redux'
import { graphql } from '@apollo/client/react/hoc'

import transformProps from '@@src/components/transform_props'
import AsyncResultSwitch from '@@src/components/async_result_switch'
import { parseGraphQLResult } from '@@src/api/presenters'
import { createSelectGraphQLResult } from '@@src/utils'
import {
  renderAbsolutePositionedLoading,
} from '@@src/components/app_suspense_container'

export const TenantConfigContext = React.createContext()

const noop = () => null

class TenantConfigProvider extends React.PureComponent {
  render() {
    return (
      <AsyncResultSwitch
        result={this.props.result}
        renderPendingResult={
          this.props.hideLoading ? noop : renderAbsolutePositionedLoading
        }
        renderSuccessResult={this.renderSuccessResult}>
      </AsyncResultSwitch>
    )
  }

  renderSuccessResult = ({ data: config }) => {
    return (
      <TenantConfigContext.Provider value={config}>
        {this.props.children}
      </TenantConfigContext.Provider>
    )
  }

  static TENANT_CONFIG_QUERY = gql`
    query TenantConfig {
      tenantConfig {
        sessionTimeout
        deviceConfig {
          dateFrom
          secondsInterval
          timeZone
        }
      }
    }
  `
}

export default compose(
  transformProps(() => () => {
    return {
      selectTenantConfigResult: createSelectGraphQLResult('tenantConfig', {
        mapResult: parseGraphQLResult,
        nullObject: null,
      }),
    }
  }),
  graphql(TenantConfigProvider.TENANT_CONFIG_QUERY, {
    options: () => ({
      fetchPolicy: 'cache-and-network',
    }),
    props: ({ data, ownProps: { selectTenantConfigResult } }) => {
      return {
        result: selectTenantConfigResult(data),
      }
    },
  })
)(TenantConfigProvider)
