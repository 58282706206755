import React from 'react'
import PlotClusterAnnotationIcon from '../../icon-components/plot_cluster_annotation_icon'
import EventStart from './pressure_events_start'

const PressureEventCluster = ({ id, children, color, x, y, count, onClick }) => {
  return (
    <EventStart
      id={id}
      isActive={false}
      color={color}
      x={x}
      y={y}
      name={'cluster'}
      onClick={onClick}
    >
      <PlotClusterAnnotationIcon x={16} y={16} count={count} />

      {children}
    </EventStart>
  )
}

export default PressureEventCluster
