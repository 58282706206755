import React, { useMemo } from 'react'
import { LegendItemColorContext } from
'@@src/components/colors/legend_item_color_provider'
import GraphContext from '@@src/components/graphs/graph_context'
import { FeatureFlags, withTenantFeatureFlags } from '../../_v2/contexts/feature_flags/tenants_feature_flags.context'
import PressureEventsLayer from './pressure_subset_graph/pressure_events_layer/pressure_events_layer'
import AnomalyLayer from './pressure_subset_graph/anomaly_layer/anomaly_layer'
import { map } from 'lodash/fp/collection'
import { withTranslation } from 'react-i18next'
import { flow } from 'lodash/fp/util'
import { toPairs } from 'lodash/fp/object'
import { compose } from 'redux'

export const CLUSTER_SIZE = 21

const PressureEventsAnomaliesContainer = (props) => {
  const {
    graphItems,
    pressureUnits,
    pressureEventsWithData,
    anomalyData,
    getLegendItemColor,
    graphConfig,
    displayAnomalies,
    displayEvents,
    activeAnomalyId,
    isActiveAnomalyExpanded,
    setActiveExpandedAnomalyId,
    activeEventId,
    eventIndexInSet,
    setActiveEventForSlice,
    isActiveEventExpanded,
    setActiveExpandedEventId,
    toggleShowBoundsForActiveAnomaly,
    boundsShown,
    isFeatureEnabled,
  } = props

  const pressureEventsWithDataAndColor = useMemo(() => {
    return pressureEventsWithData.data.map((node) => {
      const { legendItem, ...rest } = node
      return {
        ...rest,
        color: legendItem ? getLegendItemColor(legendItem) : 'black',
      }
    })
  }, [pressureEventsWithData, getLegendItemColor])

  const anomalyMarkersAndClasters = useMemo(() => {
    const { xScale } = graphConfig

    const anomalyItemsWithAddedColor = anomalyData.anomaliesWithSourcesAndPressure.map(anomalyItem => {
      return {
        ...anomalyItem,
        color: anomalyItem.legendItem ? getLegendItemColor(anomalyItem.legendItem) : 'black',
      }
    })

    const threshold = 3 * Math.abs(xScale.invert(0) - xScale.invert(CLUSTER_SIZE))
    const isTooClose = (startTime, endTime) => {
      if (!startTime || !endTime) {
        return false
      }
      return Math.abs(startTime.getTime() - endTime.getTime()) <= threshold
    }
    const isPartOfCluster = (cluster, anomaly) => {
      return !cluster.some(clustersAnomaly => {
        return !isTooClose(clustersAnomaly.anomaly.startTime, anomaly.startTime) ||
          !isTooClose(clustersAnomaly.anomaly.endTime, anomaly.startTime) ||
          !isTooClose(clustersAnomaly.anomaly.startTime, anomaly.endTime) ||
          !isTooClose(clustersAnomaly.anomaly.endTime, anomaly.endTime)
      })
    }

    const anomalyItemsBySource = anomalyItemsWithAddedColor.reduce((acc, anomalyItem) => {
      acc[anomalyItem.anomaly.networkAssetId] ?
        acc[anomalyItem.anomaly.networkAssetId].push(anomalyItem)
        :
        acc[anomalyItem.anomaly.networkAssetId] = [anomalyItem]
      return acc
    }, {})

    return flow(
      toPairs,
      map(([, value]) => {
        const sortedAnomalies = value.sort((anomalyItemA, anomalyItemB) => {
          return anomalyItemA.anomaly.startTime.getTime() - anomalyItemB.anomaly.startTime.getTime()
        })

        const markersAndClusters = sortedAnomalies.reduce(({ clusters, markers }, anomalyItem) => {
          const rangeTooSmall = isTooClose(anomalyItem.anomaly.startTime, anomalyItem.anomaly.endTime)

          if (!rangeTooSmall) {
            return {
              clusters,
              markers: [...markers, anomalyItem],
            }
          }

          if (!clusters.length) {
            return {
              clusters: [[anomalyItem]],
              markers,
            }
          }
          const clusterIndex = clusters.findIndex((cluster) => {
            return isPartOfCluster(cluster, anomalyItem.anomaly)
          })

          if (clusterIndex === -1) {
            return {
              clusters: [...clusters, [anomalyItem]],
              markers,
            }
          }
          clusters[clusterIndex] = [...clusters[clusterIndex], { ...anomalyItem }]
          return {
            clusters: [...clusters],
            markers,
          }
        }, { clusters: [], markers: [] })

        return markersAndClusters
      }),
    )(anomalyItemsBySource)
  }, [anomalyData.anomaliesWithSourcesAndPressure, graphConfig, getLegendItemColor])

  return (
    <>
      {
        displayEvents ?
          <PressureEventsLayer
            graphItems={graphItems}
            graphConfig={graphConfig}
            pressureUnits={pressureUnits}
            eventIndexInSet={eventIndexInSet}
            setActiveEventForSlice={setActiveEventForSlice}
            pressureEventsWithDataAndColor={pressureEventsWithDataAndColor}
            activeEventId={activeEventId}
            isActiveEventExpanded={isActiveEventExpanded}
            setActiveExpandedEventId={setActiveExpandedEventId} />
          : null
      }
      {
        isFeatureEnabled(FeatureFlags.anomalyDetection) &&
        <AnomalyLayer
          graphConfig={graphConfig}
          activeAnomalyId={activeAnomalyId}
          isActiveAnomalyExpanded={isActiveAnomalyExpanded}
          anomalyMarkersAndClusters={anomalyMarkersAndClasters}
          setActiveExpandedAnomalyId={setActiveExpandedAnomalyId}
          toggleShowBoundsForActiveAnomaly={toggleShowBoundsForActiveAnomaly}
          displayAnomalies={displayAnomalies}
          boundsShown={boundsShown}
        />
      }
    </>
  )
}

function PressureEventsAnomalies(props) {
  return (
    <LegendItemColorContext.Consumer>
      {({ getLegendItemColor }) => (
        <GraphContext.Consumer>
          {config => (
            <PressureEventsAnomaliesContainer
              getLegendItemColor={getLegendItemColor}
              graphConfig={config}
              {...props} />
          )}
        </GraphContext.Consumer>
      )}
    </LegendItemColorContext.Consumer>
  )
}

export default compose(
  withTranslation([
    'src/analysis_path/pressure_analysis_path/legend_list_item',
    'common/text',
  ]),
  withTenantFeatureFlags,
)(PressureEventsAnomalies)
