import gql from 'graphql-tag'
import React from 'react'
import fileDownload from 'js-file-download'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { graphql } from '@apollo/client/react/hoc'
import { DropdownItem } from 'reactstrap'
import { withTranslation } from 'react-i18next'

import config from '@@src/config'
import LoadingIcon from '@@src/components/loading_icon'
import AsyncResultSwitch from '@@src/components/async_result_switch'
import ReportDownloadIcon from '@@src/components/icons/report_download_icon'
import { AsyncResult, createSelectGraphQLResult } from '@@src/utils'
import { getReportFilename } from '@@src/utils/report_downloads'
import { withAuthorization } from '../../../_v2/contexts/authorization/authorization.context'
import styles from './csv_downloads_dropdown_item_list.css'

const NO_RESULT = AsyncResult.notFound()

class CsvDownloadsDropdownItemList extends React.PureComponent {
  render() {
    const { downloadsResult } = this.props

    return (
      <AsyncResultSwitch
        result={downloadsResult || NO_RESULT}
        renderPendingResult={this.renderPending}
        renderSuccessResult={this.renderSuccess}
      />
    )
  }

  renderPending = () => {
    return (
      <div className={styles.loading}>
        <LoadingIcon />
      </div>
    )
  }

  /* Renders a placeholder when there are no files to download */
  renderEmpty = () => {
    const { t } = this.props
    return (
      <DropdownItem disabled>{t('text.no_downloads_available')}</DropdownItem>
    )
  }

  renderSuccess = ({ data: dataReports }) => {
    const { t } = this.props

    if (dataReports.length < 1) {
      return this.renderEmpty()
    }

    return (
      <React.Fragment>
        {dataReports.map((report) => {
          const canBeDownloaded = report.reportStatus === 'complete'

          return (
            <DropdownItem
              key={report.jobId}
              className={
                styles[
                  canBeDownloaded ? 'download-item-success' : 'download-item'
                ]
              }
              onClick={
                canBeDownloaded
                  ? this.createOnClickToDownload(report)
                  : undefined
              }
            >
              <ReportDownloadIcon
                report={report}
                className={styles['download-item-icon']}
              />

              <div className={styles['download-item-description']}>
                <span className={styles['download-item-report-name']}>
                  {getReportFilename({
                    t,
                    jobCreated: report.jobCreated,
                    dataType: report.dataType,
                    reportType: report.reportType,
                  })}
                </span>

                <span className={styles['download-item-report-status']}>
                  {this.getReportStatus(report.reportStatus)}
                </span>
              </div>
            </DropdownItem>
          )
        })}
      </React.Fragment>
    )
  }

  getReportStatus(reportStatus) {
    const { t } = this.props

    switch (reportStatus) {
      case 'new':
      case 'inProgress':
        return t('text.report_status_in_progress')

      case 'complete':
        return t('text.report_status_complete')

      case 'noData':
        return t('text.report_status_no_data')

      default:
        return t('text.report_status_error')
    }
  }

  createOnClickToDownload = (report) => async () => {
    const { t, authorization } = this.props

    const fileListStatus = report.fileListStatus[0]
    const downloadLink = getDownloadLink(fileListStatus.uuid)
    const filename = getReportFilename({
      t,
      jobCreated: report.jobCreated,
      dataType: report.dataType,
      reportType: report.reportType,
    })

    const token = await authorization.getCurrentUserToken()
    const signedUrlResponse = await fetch(downloadLink, {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${token}`,
      }),
    })

    const signedUrlResponseBody = await signedUrlResponse.json()
    const signedUrl = signedUrlResponseBody.signedUrl

    const downloadResponse = await fetch(signedUrl, { method: 'GET' })
    const downloadText = await downloadResponse.text()

    fileDownload(downloadText, filename)
  }

  static DOWNLOAD_REPORTS_QUERY = gql`
    query DownloadReportsQuery {
      dataReports {
        ... on DataReportListItem {
          jobId
          dataType
          start
          end
          jobCreated
          reportStatus
          reportType
          logicalChannel
          aggregateType
          aggregateName
          estimatedCompletion
          fileListStatus {
            reportStatus
            uuid
          }
        }

        ... on AlertReportListItem {
          jobId
          dataType
          jobCreated
          reportType
          reportStatus
          fileListStatus {
            reportStatus
            uuid
          }
        }

        ... on DevicesDataReportItem {
          jobId
          dataType
          jobCreated
          reportType
          reportStatus
          fileListStatus {
            reportStatus
            uuid
          }
        }

        ... on NetworkAssetsDataReportItem {
          jobId
          dataType
          jobCreated
          reportType
          reportStatus
          fileListStatus {
            reportStatus
            uuid
          }
        }

        ... on EventsDataReportItem {
          jobId
          dataType
          jobCreated
          reportType
          reportStatus
          fileListStatus {
            reportStatus
            uuid
          }
        }

        ... on DevicesHealthDataReportItem {
          jobId
          dataType
          reportType
          jobCreated
          reportStatus
          fileListStatus {
            reportStatus
            uuid
          }
        }

        ... on AnomalyReportItem {
          jobId
          jobCreated
          reportStatus
          dataType
          reportType
          start
          end
          fileListStatus {
            reportStatus
            uuid
          }
        }
      }
    }
  `
}

function getDownloadLink(uuid) {
  return `${config.apiUrl}/v1/rest/reports/${config.tenant}/${uuid}`
}

function createMapStateToProps() {
  const selectDownloadsResult = createSelectGraphQLResult('dataReports', {
    mapResult: (r) => r.slice(0, 4),
    nullObject: [],
  })

  return function mapStateToProps() {
    return {
      selectDownloadsResult,
    }
  }
}

export default compose(
  withTranslation(
    'src/components/app_layout/csv_downloads_dropdown/csv_downloads_dropdown_item_list'
  ),
  withAuthorization,
  connect(createMapStateToProps),
  graphql(CsvDownloadsDropdownItemList.DOWNLOAD_REPORTS_QUERY, {
    skip: ({ isOpen }) => !isOpen,
    options: () => {
      return {
        fetchPolicy: 'network-only',
        pollInterval: 10000,
      }
    },
    props: ({ data, ownProps: { selectDownloadsResult } }) => {
      return {
        downloadsResult: selectDownloadsResult(data),
      }
    },
  })
)(CsvDownloadsDropdownItemList)
