import React from 'react'
import { Controller } from 'react-hook-form'
import { Button, FormGroup } from 'reactstrap'
import NetworkAssetListNewNetworkAssetModalGroupSelect from
'./network_assets_list_new_network_asset_modal_group_select.component'

const NetworkAssetGroupFormFields = ({ control }) => {
  return(
    <FormGroup tag="fieldset" className="m-0 p-0">
      <Controller
        name={'group'}
        control={control}
        render={(fields) => {
          const { field: { onChange, value } } = fields
          return (
            !value ? <NetworkAssetListNewNetworkAssetModalGroupSelect onChangeGroup={onChange} />
            :
            <div className="d-flex w-100 justify-content-center align-items-center">
              {value && value.groupName}
              <Button
                size="sm"
                outline={true}
                name="clear-selected-source-filter-button"
                className="ml-auto px-3"
                onClick={() => onChange(null)}>
                <span className="far fa-times" />
              </Button>
            </div>
          )
        }}
      />
    </FormGroup>
  )
}

export default NetworkAssetGroupFormFields
