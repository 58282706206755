import { LatLng } from 'leaflet'

export default class GeoCoordinate {
  constructor(lat, lng) {
    // if (!this._isValidLatitude(lat)) {
    //   throw new Error('Invalid latitude value. Latitude must be between -90 and 90 degrees.')
    // } else if (!this._isValidLongitude(lng)) {
    //   throw new Error('Invalid longitude value. Longitude must be between -180 and 180 degrees.')
    // }

    this.lat = lat
    this.lng = lng

    Object.freeze(this)
  }

  static fromLatLng(latLng) {
    return new GeoCoordinate(latLng.lat, latLng.lng)
  }

  static fromValue(lat, lng) {
    return new GeoCoordinate(lat, lng)
  }

  toLatLng() {
    return new LatLng(this.lat, this.lng)
  }

  toArray() {
    return [this.lat, this.lng]
  }

  toString() {
    return [this.lat.toFixed(6), this.lng.toFixed(6)].join(',')
  }

  equals() {
    if (arguments.length === 1) {
      const coordObject = arguments[0]

      if (coordObject instanceof GeoCoordinate) {
        return this.lat === coordObject.lat && this.lng === coordObject.lng
      }
      throw Error('Wrong type provided to GeoCoordinate.equals function')
    }
    if (arguments.length === 2) {
      return arguments[0] === this.lat && arguments[1] === this.lng
    }
    throw Error('Wrong number of arguments provided to GeoCoordinate.equals function')
  }

  _isValidLatitude(lat) {
    return typeof lat === 'number' && lat >= -90 && lat <= 90
  }

  _isValidLongitude(lng) {
    return typeof lng === 'number' && lng >= -180 && lng <= 180
  }
}
