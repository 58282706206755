import React from 'react'
import chroma from 'chroma-js'
import { PopupAnchor } from '@@src/components/popup'
import {
  IMAGE_SIZE,
} from '../../../analysis_path/pressure_analysis_path/pressure_subset_graph/anomaly_layer/anomaly_marker'
import EventsStartIcon from '@@src/components/icon-components/event_start_icon'

import styles from './styles.css'

const EventStart = ({ children, color: sourceColor, x, y, isActive, onClick, name = 'marker' }) => {
  const color = isActive ? chroma(sourceColor).darken() : sourceColor

  return (
    <PopupAnchor
      x={x - IMAGE_SIZE / 2}
      y={y - IMAGE_SIZE / 2}
      is-active={isActive.toString()}
      name={name}
      width={IMAGE_SIZE + 10}
      height={IMAGE_SIZE + 10}
      className={styles['event-boundary-marker']}
      onClick={onClick}
      elementType="svg" >
      <EventsStartIcon color={color} />

      {children}
    </PopupAnchor>
  )
}

export default EventStart
