import React from 'react'
import AppLayout from '@@src/components/app_layout'
import { Row, Col, Form, Alert, Button } from 'reactstrap'
import gql from 'graphql-tag'
import { compose } from 'redux'
import { graphql } from '@apollo/client/react/hoc'
import { createSelectGraphQLResult, FormFields, AsyncResult } from '@@src/utils'

import { parseGraphQLResult } from '@@src/api/presenters'
import { withTranslation } from 'react-i18next'
import ErrorInfo from '@@src/components/error_info'
import transformProps from '@@src/components/transform_props'
import requiresLogin from '@@src/components/security/requires_login'
import userPermissions from '@@src/components/permissions/user_permissions'
import AsyncResultSwitch from '@@src/components/async_result_switch'
import SubmitButton from '@@src/components/buttons/submit_button'
import AppFormGroup from '@@src/components/forms/app_form_group'
import UnitSettings from '@@src/components/forms/unit_settings'
import * as analytics from '@@src/analytics'
import { UserSettingsContext } from '@@src/components/user_settings_provider'

import styles from './index_page.css'

class UserSettingsPage extends React.PureComponent {
  render() {
    const { userSettings, t } = this.props
    const { result } = this.state

    return (
      <AppLayout title={t('headings.page_title')}>
        <div className="container">
          <Row>
            <Col sm="5">
              <h1 className={styles['settings-title']}>
                {t('headings.page_title')}
              </h1>

              {result.wasSuccessful() ? (
                <Alert color="success">{t('text.update_successful')}</Alert>
              ) : null}
              {result.wasFailure() ? <ErrorInfo error={result.error} /> : null}

              <AsyncResultSwitch
                result={userSettings}
                renderSuccessResult={this.renderSettingsSelectors}
              />
            </Col>
          </Row>
        </div>
      </AppLayout>
    )
  }

  constructor(props) {
    super(props)

    const requiredValidator = (v) => (v ? '' : 'errors.required')

    this.fields = new FormFields(this, 'settingsFields', {
      language: requiredValidator,
      pressureUnit: requiredValidator,
      distanceUnit: requiredValidator,
      temperatureUnit: requiredValidator,
    })

    this.state = {
      result: AsyncResult.notFound(),
      settingsFields: this.fields.initialState(),
    }
  }

  componentDidUpdate = (prevProps) => {
    const { userSettings } = this.props

    if (
      prevProps.userSettings !== userSettings &&
      userSettings.wasSuccessful()
    ) {
      this.setState({
        settingsFields: this.settingsFieldsInitialState(),
      })
    }
  }

  selectFieldValue = (fieldName) => {
    const fieldValue = this.fields.selectValue(this.state, fieldName)
    return fieldValue ? fieldValue : undefined
  }

  selectFieldErrorText = (fieldName) => {
    const error = this.fields.selectError(this.state, fieldName)
    return error ? this.props.t(error) : error
  }

  onSubmitForm = async (event) => {
    const { refetchUserSettings } = this.props

    event.preventDefault()
    this.setState({ result: AsyncResult.pending() })
    const formLanguage = this.selectFieldValue('language')
    const formTemperatureUnit = this.selectFieldValue('temperatureUnit')
    const formDistanceUnit = this.selectFieldValue('distanceUnit')
    const formPressureUnit = this.selectFieldValue('pressureUnit')

    try {
      await this.props.editUserSettings({
        variables: {
          language: formLanguage,
          units: {
            temperature: formTemperatureUnit,
            distance: formDistanceUnit,
            pressure: formPressureUnit,
          },
        },
      })
      await this.setState({ result: AsyncResult.success() })
      await this.setState({
        settingsFields: this.settingsFieldsInitialState({
          language: formLanguage,
          temperatureUnit: formTemperatureUnit,
          distanceUnit: formDistanceUnit,
          pressureUnit: formPressureUnit,
        }),
      })
      // If we change language with the form, refetch user settings so that they are applied immediately
      // Changing units is not necessary as they aren't applied on this page

      await refetchUserSettings()
    } catch (e) {
      analytics.logError(e)
      this.setState({ result: AsyncResult.fail(e) })
    }
  }

  settingsFieldsInitialState = (overrides = {}, props = this.props) => {
    const {
      userSettings: {
        data: {
          language,
          units: { temperature, distance, pressure },
        },
      },
    } = props

    return this.fields.initialState({
      language,
      temperatureUnit: temperature,
      distanceUnit: distance,
      pressureUnit: pressure,
      ...overrides,
    })
  }

  onResetForm = async (event) => {
    event.preventDefault()

    await this.setState({
      settingsFields: this.settingsFieldsInitialState(),
    })
  }

  getChangeHandler = (field) => {
    return this.fields.onChangeHandlerFor(field)
  }

  renderSettingsSelectors = (data) => {
    const {
      data: {
        languagesAvailable,
        units: {
          temperatureUnitsAvailable,
          distanceUnitsAvailable,
          pressureUnitsAvailable,
        },
      },
    } = data
    const { t } = this.props
    const { result } = this.state

    return (
      <React.Fragment>
        <Form name="user-settings-form" className="pb-3">
          <AppFormGroup
            name="language"
            type="select"
            className={styles['form-language']}
            label={t('common/forms:labels.language')}
            value={this.selectFieldValue('language')}
            onChange={this.getChangeHandler('language')}
            errorText={this.selectFieldErrorText('language')}
          >
            {languagesAvailable.map((localecode) => (
              <option key={localecode} value={localecode}>
                {t(`common/forms:language.${localecode}`)}
              </option>
            ))}
          </AppFormGroup>

          <UnitSettings
            pressureUnit={this.selectFieldValue('pressureUnit')}
            distanceUnit={this.selectFieldValue('distanceUnit')}
            temperatureUnit={this.selectFieldValue('temperatureUnit')}
            availablePressureUnits={pressureUnitsAvailable}
            availableDistanceUnits={distanceUnitsAvailable}
            availableTemperatureUnits={temperatureUnitsAvailable}
            className="mt-5"
            getChangeHandler={this.getChangeHandler}
            selectFieldErrorText={this.selectFieldErrorText}
          />

          <SubmitButton
            name="edit-user-settings-submit-button"
            color="primary"
            submitText={t('button.update')}
            result={result}
            onSubmitForm={this.onSubmitForm}
          />

          <Button
            name="edit-user-settings-reset-button"
            color="secondary"
            className="ml-2"
            disabled={this.fields.isPristine()}
            onClick={this.onResetForm}
          >
            {t('button.reset')}
          </Button>
        </Form>
      </React.Fragment>
    )
  }

  static GET_USER_SETTINGS_QUERY = gql`
    query userSettings {
      userSettings {
        language
        languagesAvailable
        units {
          temperature
          temperatureUnitsAvailable
          distance
          distanceUnitsAvailable
          pressure
          pressureUnitsAvailable
        }
      }
    }
  `

  static EDIT_USER_SETTINGS_MUTATION = gql`
    mutation EditUserSettings($language: String!, $units: UnitSettingsInput!) {
      editUserSettings(language: $language, units: $units) {
        language
      }
    }
  `
}

function UserSettingsPageWithContext(props) {
  return (
    <UserSettingsContext.Consumer>
      {({ refetchUserSettings }) => (
        <UserSettingsPage refetchUserSettings={refetchUserSettings} {...props}>
          {props.children}
        </UserSettingsPage>
      )}
    </UserSettingsContext.Consumer>
  )
}

export default compose(
  requiresLogin,
  userPermissions,
  withTranslation(['src/settings_path/index_page', 'common/forms']),
  transformProps(() => () => ({
    selectUserSettingsResult: createSelectGraphQLResult('userSettings', {
      mapResult: parseGraphQLResult,
    }),
  })),
  graphql(UserSettingsPage.GET_USER_SETTINGS_QUERY, {
    options: () => {
      return {
        fetchPolicy: 'network-only',
      }
    },
    props: ({ data, ownProps }) => {
      const { selectUserSettingsResult } = ownProps
      const { refetch } = data
      return {
        refetchData: refetch,
        userSettings: selectUserSettingsResult(data),
      }
    },
  }),
  graphql(UserSettingsPage.EDIT_USER_SETTINGS_MUTATION, {
    name: 'editUserSettings',
  })
)(UserSettingsPageWithContext)
