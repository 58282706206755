import React from 'react'
import PropTypes from 'prop-types'
import { line } from 'd3-shape'
import { get } from 'lodash/fp/object'
import { createSelector } from 'reselect'

import { GraphConfig } from '@@src/components/graphs/graph_context'
import HorizontalBand from '@@src/components/graphs/horizontal_band'
import { LinePlot } from
'@@src/management_path/devices_path/history_modal/history_plot_layer'
import styles from './signal_history_plot_layer.css'

// Anything below -105 is considered a 'Poor' signal
// Anything above -69 is considered 'Strong'
export const AVERAGE_SIGNAL_THRESHOLD = -98
export const WEAK_SIGNAL_THRESHOLD = -105

const signalBands = [
  {
    className: styles['poor-history-band'],
    yStart: Number.NEGATIVE_INFINITY,
    yEnd: WEAK_SIGNAL_THRESHOLD,
  },
  {
    className: styles['weak-history-band'],
    yStart: WEAK_SIGNAL_THRESHOLD,
    yEnd: AVERAGE_SIGNAL_THRESHOLD,
  },
]

export default class SignalHistoryPlotLayer extends React.PureComponent {
  static propTypes = {
    telemetrySegments: PropTypes.array.isRequired,
    graphConfig: PropTypes.instanceOf(GraphConfig).isRequired,
    clipPathId: PropTypes.string.isRequired,
  }

  render() {
    const { telemetrySegments, graphConfig, clipPathId } = this.props

    return (
      <>
        <g name="signal-history-bands">
          {signalBands.map((band, i) => (
            <HorizontalBand
              graphConfig={graphConfig}
              key={`signal-history-band-${i}`}
              band={band}/>
          ))}
        </g>
        {telemetrySegments.map(({ data, index }) => (
          this.renderSegment(index, data, clipPathId))
        )}
      </>
    )
  }

  renderSegment = (index, data, clipPathId) => {
    const signalHistoryLineSelector =
      this.selectSignalHistoryLineGenerator(this.props)

    return (
      <g key={index}>
        <LinePlot
          d={signalHistoryLineSelector(data)}
          name="signal-history-plot"
          clipPath={`url(#${clipPathId})`}
          className={styles['signal-history-line']}/>
      </g>
    )
  }

  selectSignalHistoryLineGenerator = createSelector(
    [get('graphConfig')],
    graphConfig => {
      return line()
        .x(d => graphConfig.xScale(d.time))
        .y(d => graphConfig.yScale(d.value))
    }
  )
}
