import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { Row } from 'reactstrap'
import { isMobile } from '../../../utils'
export const SummaryInfoPlaceholder = ({ count = 1 }) => {
  const isMobileDevice = isMobile()
  const numberOfSummaryInfos = 3

  return (
    <Row>
      <div className="d-flex row-sm col-lg-3 justify-content-center mb-4">
        <div className="d-flex flex-column align-items-center justify-content-center">
          <Skeleton height={35} width={70} />
          <Skeleton height={20} width={100} className="mt-2" />
        </div>
      </div>

      <div
        className={`d-flex col-lg row ${
          isMobileDevice && 'justify-content-center'
        }`}
      >
        <div className="d-flex col-lg row justify-content-center align-items-center">
          {Array.from({ length: count }).map((c) => (
            <div
              key={c}
              className={`d-flex flex-row justify-content-center align-items-center ${
                !isMobileDevice && 'ml-5'
              }`}
            >
              <Skeleton
                circle
                height={isMobileDevice ? 140 : 200}
                width={isMobileDevice ? 140 : 200}
              />
              <div className="d-flex flex-column justify-content-between pl-5">
                {Array.from({ length: numberOfSummaryInfos }).map((n) => (
                  <div key={n}>
                    <Skeleton height={20} width={30} />
                    <Skeleton height={20} width={100} className="mb-3" />
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </Row>
  )
}
