import React, { PureComponent, createRef } from 'react'
import PropTypes from 'prop-types'
import { noop } from 'lodash/fp/util'

import GraphItem from '@@src/components/graphs/graph_item'
import RequestRawDataModal from
'@@src/components/graphs/modals/request_raw_data_modal'
import AsyncResult from '@@src/utils/async_result'

import styles from './select_dropdown.css'

export default class SelectDropdown extends PureComponent {
  static propTypes = {
    refetchRawDataRequests: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    resetSelection: PropTypes.func.isRequired,
    timeFrom: PropTypes.instanceOf(Date).isRequired,
    timeTo: PropTypes.instanceOf(Date).isRequired,
    networkAssetGraphItems:
      PropTypes.arrayOf(PropTypes.instanceOf(GraphItem)).isRequired,
    listRawDataRequestsResult: PropTypes.instanceOf(AsyncResult).isRequired,
  }

  static defaultProps = {
    refetchRawDataRequests: noop,
    listRawDataRequestsResult: AsyncResult.success({
      rawDataRequests: [],
      networkAssetsMinutesLeft: [],
    }),
  }

  state = {
    rawDataModalIsOpen: false,
  }

  dropdownRef = createRef()

  render() {
    const {
      t, timeFrom, timeTo, networkAssetGraphItems, refetchRawDataRequests,
      listRawDataRequestsResult,
    } = this.props
    const { rawDataModalIsOpen } = this.state

    return (
      <div ref={this.dropdownRef}>
        <RequestRawDataModal
          listRawDataRequestsResult={listRawDataRequestsResult}
          onRequestSuccess={refetchRawDataRequests}
          isOpen={rawDataModalIsOpen}
          handleToggle={this.handleRawDataModalClick}
          timeFrom={timeFrom}
          timeTo={timeTo}
          networkAssetGraphItems={networkAssetGraphItems}
          t={t}/>
        <div className={styles['dropdown-title']}>
          {t('header.selection')}
        </div>
        <ul className="m-0 list-unstyled">
          <li className={styles['option-item']}>
            <button
              name="request-raw-data-btn"
              className={styles['dropdown-option']}
              type="button"
              onClick={this.handleRawDataModalClick}>
              <i className={styles['dropdown-option-icon']} />
              &nbsp;
              {t('option.request_raw_data')}
            </button>
          </li>
        </ul>
      </div>
    )
  }

  componentDidMount() {
    document.getElementById('container')
      .addEventListener('click', this.handleDropdownClickClose)
  }

  componentWillUnmount() {
    this.removeHandleDropdownClickClose()
  }

  handleRawDataModalClick = (ev) => {
    ev.stopPropagation()

    this.setState({
      rawDataModalIsOpen: !this.state.rawDataModalIsOpen,
    })
  }

  handleDropdownClickClose = ({ target }) => {
    const { resetSelection } = this.props

    if (this.dropdownRef.current &&
      (!this.dropdownRef.current.isEqualNode(target) &&
        !this.dropdownRef.current.contains(target))) {
      resetSelection()

      this.removeHandleDropdownClickClose()
    }
  }

  removeHandleDropdownClickClose() {
    document.removeEventListener('click', this.handleDropdownClickClose)
  }
}
