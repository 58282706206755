import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem }
from 'reactstrap'
import { withTranslation } from 'react-i18next'
import classnames from 'classnames'

import DisplayDropdownItem from
'@@src/analysis_path/pressure_analysis_path/display_dropdown_item'
import TenantLicenceFeatureGate, { TENANT_LICENCE_FEATURES } from
'@@src/components/tenant_licence/tenant_licence_feature_gate'

import styles from './display_dropdown.css'

class DisplayDropdown extends PureComponent {
  static propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      licenceFeature: PropTypes.oneOf(TENANT_LICENCE_FEATURES),
    })).isRequired,
    isOpen: PropTypes.bool.isRequired,
    onChangeValue: PropTypes.func.isRequired,
    onToggle: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    value: PropTypes.string,
  }

  static defaultProps = {
    isOpen: false,
  }

  renderDropdownItems = () => {
    const {
      options, value: selectedValue = options[0].value, onChangeValue,
    } = this.props

    return (
      <DropdownMenu className={styles['dropdown-menu']}>
        {options.map(({ value, text, description, image, licenceFeature }) => {
          const dropdownItem = (
            <DropdownItem
              key={value}
              className={styles['dropdown-item']}
              value={value}
              onClick={onChangeValue}>
              <DisplayDropdownItem
                isSelected={selectedValue === value}
                text={text}
                description={description}
                image={image}
              />
            </DropdownItem>
          )

          if (TENANT_LICENCE_FEATURES.includes(licenceFeature)) {
            return (
              <TenantLicenceFeatureGate
                key={value}
                requiredTenantLicenceFeature={licenceFeature}>
                {dropdownItem}
              </TenantLicenceFeatureGate>
            )
          } else {
            return dropdownItem
          }
        })}
      </DropdownMenu>
    )
  }

  render() {
    const { t, isOpen, onToggle, value, options } = this.props
    const toggleClassnames = classnames(
      styles['dropdown-toggle'], 'border-0', 'text-left', {
        [styles['dropdown-toggle-active']]: isOpen,
      }
    )
    const titleClassnames = classnames(
      styles['dropdown-title'], 'text-uppercase', 'font-weight-normal'
    )
    let selectedValue = options[0]

    if (typeof value !== 'undefined') {
      selectedValue = options.find(e => e.value === value)
    }

    return(
      <ButtonDropdown isOpen={isOpen} toggle={onToggle}>
        <DropdownToggle color="link" caret
          className={toggleClassnames}>
          <img className={styles['dropdown-img']} src={selectedValue.image}
            alt="" />
          <div>
            <div className={titleClassnames}>
              {t('text.title')}
            </div>
            <div className={styles['dropdown-selected']}>
              {selectedValue.text}
            </div>
          </div>
        </DropdownToggle>
        {isOpen ? this.renderDropdownItems() : null}
      </ButtonDropdown>
    )
  }
}

export default withTranslation(
  'src/analysis_path/pressure_analysis_path/display_dropdown'
)(DisplayDropdown)
