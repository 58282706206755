// todo: change user to authorized user from user context
export function setupFreshServiceScript(user) {
  const freshServiceScript = document.createElement('script')

  freshServiceScript.onload = () => {
    window.FreshWidget.init('', {
      'queryString': !user
        ? '&widgetType=popup&formTitle=Contact+us&attachFile=no'
        : `&helpdesk_ticket[requester]=${user.email}&widgetType=popup&formTitle=Contact+us&attachFile=no`,
      'utf8': '✓',
      'widgetType': 'popup',
      'buttonType': 'text',
      'buttonText': 'Need Help?',
      'buttonColor': 'white',
      'buttonBg': '#46baba',
      'alignment': '3',
      'offset': '88%',
      'formHeight': '500px',
      'url': 'https://inflowmatix.freshservice.com',
    })
  }
  freshServiceScript.src = 'https://assets.freshservice.com/widget/freshwidget.js'

  document.head.appendChild(freshServiceScript)
}
