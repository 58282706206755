import DeviceConfig from './device_config'

export default class TenantConfig {
  constructor(data) {
    Object.assign(this, data)

    this.deviceConfig = data.deviceConfig ?
      new DeviceConfig(data.deviceConfig) : null
  }
}
