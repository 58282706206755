import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap'
import { compose } from 'redux'
import { withRouter } from 'react-router'

import ToggleDragModeButton from
'@@src/components/graphs/toggle_drag_mode_button'
import TimeRangePicker from '@@src/components/dropdowns/time_range_picker'

import styles from './simple_view_modal.css'

class SimpleViewModal extends React.PureComponent {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func,
    t: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
    dispatchUpdateQueryParams: PropTypes.func.isRequired,
    dispatchClearQueryParams: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    startTime: PropTypes.instanceOf(Date),
    endTime: PropTypes.instanceOf(Date),
    timezone: PropTypes.string,
  }

  render() {
    const {
      t,
      isOpen,
      toggle,
      children,
      title,
      endTime,
      startTime,
      timezone,
    } = this.props

    return (
      <Modal
        isOpen={isOpen}
        toggle={this.handleToggle}
        className={styles.container}>
        <div className="modal-header">
          <div
            className="d-flex justify-content-between w-100 align-items-center">
            <h5 className="modal-title">
              {title}
            </h5>
            <TimeRangePicker
              endTime={endTime}
              onChange={this.handleTimeRangeChange}
              timezone={timezone}
              startTime={startTime}
              displayQuickTimeRanges={false}
              source="history-modal" />
          </div>
        </div>

        <ModalBody className="p-0 position-relative">
          <ToggleDragModeButton
            showSelectOption={false}
            className={styles['toggle-button']} />
          {children}
        </ModalBody>

        <ModalFooter>
          <Button
            name="cancel-button"
            type="button"
            color="secondary"
            onClick={toggle}>
            {t('buttons.back')}
          </Button>
        </ModalFooter>
      </Modal>
    )
  }

  handleToggle = () => {
    const { dispatchClearQueryParams, toggle } = this.props

    dispatchClearQueryParams(this.props)
    toggle()
  }

  handleTimeRangeChange = ({ startTime, endTime, timezone }) => {
    this.props.dispatchUpdateQueryParams(this.props, {
      startTime,
      endTime,
      timezone,
    })
  }
}

export default compose(
  withTranslation([
    'src/management_path/devices_path/history_modal/index_page',
  ]),
  withRouter,
)(SimpleViewModal)
