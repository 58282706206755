import React from 'react'
import { Alert } from 'reactstrap'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

export const WARNING_HOUR_SECONDS_INTERVAL_THRESHOLD = 60 * 60

function FrequentDeviceSessionConfigAlert(props) {
  const { t, secondsInterval, className } = props

  if (secondsInterval > WARNING_HOUR_SECONDS_INTERVAL_THRESHOLD) {
    return null
  }

  return (
    <Alert className={className} color="warning">
      {t('text.battery_and_comms_warning')}
    </Alert>
  )
}

FrequentDeviceSessionConfigAlert.propTypes = {
  secondsInterval: PropTypes.number.isRequired,
  className: PropTypes.string,
}

export default withTranslation([
  'src/components/alerts/frequent_device_session_config_alert',
])(FrequentDeviceSessionConfigAlert)
