import React, { useState } from 'react'

import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap'
import classnames from 'classnames'
import PropTypes from 'prop-types'

const ListSort = ({
  t,
  onSortChange = () => null,
  orderBy,
  orderDirection,
  sortOptions,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const toggle = () => setDropdownOpen((prevState) => !prevState)

  return (
    <div className="d-flex flex-row align-self-center align-content-end align-items-center">
      <div className="mr-1">{t('v2/common/filters:sort_by')}</div>
      <Dropdown toggle={toggle} isOpen={dropdownOpen}>
        <DropdownToggle
          caret
          color="transparent"
          className="p-0 rounded-0 text-lowercase shadow-none"
        >
          {t(`sort_options.${orderBy}`)} (
          {t(`v2/common/filters:sort_direction.${orderDirection}`)})
        </DropdownToggle>
        <DropdownMenu container="body" end>
          {sortOptions.map((orderByOption) => (
            <DropdownItem
              key={`order.item.${orderByOption}`}
              onClick={() =>
                onSortChange({
                  orderBy: orderByOption,
                  orderDirection:
                    orderByOption === orderBy && orderDirection === 'asc'
                      ? 'desc'
                      : 'asc',
                })
              }
            >
              <span className="d-flex flex-row justify-content-between align-items-center">
                <span
                  className={`mr-3 ${
                    orderByOption === orderBy ? 'font-weight-bold' : ''
                  }`}
                >
                  {t(`sort_options.${orderByOption}`)}
                </span>
                <span
                  className={classnames('fa', {
                    'fa-arrow-up': orderDirection === 'asc',
                    'fa-arrow-down': orderDirection === 'desc',
                    visible: orderByOption === orderBy,
                    invisible: orderByOption !== orderBy,
                  })}
                />
              </span>
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </div>
  )
}

ListSort.propTypes = {
  t: PropTypes.func.isRequired,
  onSortChange: PropTypes.func,
  orderBy: PropTypes.string,
  orderDirection: PropTypes.oneOf(['asc', 'desc']),
  sortOptions: PropTypes.arrayOf(PropTypes.string),
}

export default ListSort
