import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { Row, Col, Badge } from 'reactstrap'
import moment from 'moment'

import {
  EXPORT_DATA_TYPE,
  EXPORT_REPORT_TYPE,
} from '@@src/components/dropdowns/export_dropdown_with_modal/export_dropdown_with_modal'

import {
  getReportFilename,
  getReportFileType,
  getReportDataType,
} from '@@src/utils/report_downloads'

import * as analytics from '@@src/analytics'
import config from '@@src/config'
import SubmitButton from '@@src/components/buttons/submit_button'
import ReportDownloadIcon from '@@src/components/icons/report_download_icon'
import { AppError, AsyncResult } from '@@src/utils'

import styles from './data_download_item.css'

const DISPLAY_DATE_FORMAT = 'MMM D, YYYY H:mm A'
const PARSE_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSSZ'

export function getDownloadLink(uuid) {
  return `${config.apiUrl}/v1/rest/${config.tenant}/reports/${uuid}`
}

class DownloadItem extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    reportType: PropTypes.oneOf(EXPORT_REPORT_TYPE).isRequired,
    dataType: PropTypes.oneOf(EXPORT_DATA_TYPE).isRequired,
    reportStatus: PropTypes.string.isRequired,
    uuid: PropTypes.string,
    fileIndex: PropTypes.number,
    jobCreated: PropTypes.string.isRequired,
    onDownloadClick: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      downloadResult: AsyncResult.notFound(),
    }
  }

  getReportStatus = () => {
    const { reportStatus, t } = this.props

    switch (reportStatus) {
      case 'new':
      case 'inProgress':
        return t('text.in_progress')

      case 'noData':
        return t('text.no_data')

      default:
        return t('text.failed')
    }
  }

  onClick = async () => {
    const {
      dataType,
      jobCreated,
      onDownloadClick,
      reportType,
      t,
      uuid,
    } = this.props

    const downloadLink = getDownloadLink(uuid)
    const filename = getReportFilename({ t, jobCreated, dataType, reportType })

    this.setState({ downloadResult: AsyncResult.pending() })

    try {
      await onDownloadClick(downloadLink, filename)
      this.setState({ downloadResult: AsyncResult.success() })
    } catch (err) {
      analytics.logError(err)
      this.setState({ downloadResult: AsyncResult.fail(AppError.from(err)) })
    }
  }

  render() {
    const { downloadResult } = this.state
    const { reportStatus, jobCreated, t, reportType, dataType } = this.props

    return (
      <li className={styles['download-item']}>
        <Row>
          <Col className="align-items-center d-flex">
            <span className={styles['download-item-icon']}>
              <ReportDownloadIcon report={{ reportType, reportStatus }}/>
            </span>
            <div>
              <div>
                <span className={styles['download-item-name']}>
                  {getReportFilename({ t, jobCreated, dataType, reportType })}
                </span>
                <Badge className={styles['download-item-badge']} color="light">
                  {getReportFileType(reportType)}
                </Badge>
                <Badge className={styles['download-item-badge']} color="light">
                  {getReportDataType({ t, dataType })}
                </Badge>
              </div>
              <time
                className={styles['download-item-time']}
                dateTime={jobCreated}>
                {
                  moment(jobCreated, PARSE_DATE_FORMAT)
                    .format(DISPLAY_DATE_FORMAT)
                }
              </time>
            </div>
          </Col>
          <Col sm="auto" className="align-items-center d-flex">
            {
              reportStatus !== 'complete' ?
                <span className={styles['download-item-status']}>
                  {this.getReportStatus()}
                </span>
                : null
            }
            {
              reportStatus === 'complete' ?
                <SubmitButton
                  color="primary"
                  submitText={t('button.download')}
                  onSubmitForm={this.onClick}
                  result={downloadResult}>
                </SubmitButton>
                : null
            }
          </Col>
        </Row>
      </li>
    )
  }
}

export default withTranslation([
  'src/downloads_path/data_download_item',
  'common/text',
])(DownloadItem)
