class User {

  static ROLE_VIEWER = 'viewer'
  static ROLE_EDITOR = 'editor'
  static ROLE_SUPERUSER = 'superuser'
  static ROLE_ADMINISTRATOR = 'administrator'

  constructor(rawData) {
    Object.assign(this, rawData)

    Object.freeze(this)
  }

  static from(...args) {
    return new User(...args)
  }
}

module.exports = {
  User,
}
