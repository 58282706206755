import gql from 'graphql-tag'
import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { graphql } from '@apollo/client/react/hoc'
import { withTranslation } from 'react-i18next'

import * as analytics from '@@src/analytics'
import ErrorInfo from '@@src/components/error_info'
import SubmitButton from '@@src/components/buttons/submit_button'
import { AsyncResult } from '@@src/utils'
import {
  Row, Col, Button, Modal, ModalBody, ModalHeader, ModalFooter, Form,
} from 'reactstrap'

class VoidGroupModal extends React.PureComponent {
  static defaultProps = {
    onSuccess: () => { },
  }

  static propTypes = {
    onSuccess: PropTypes.func.isRequired,
  }

  render() {
    const { t, isOpen, group } = this.props
    const { result } = this.state
    const warningText = this.getWarningText(t, group.name, group.members.count)

    return (
      <Form>
        <Modal
          isOpen={isOpen}
          toggle={this.onToggle}>

          <ModalHeader toggle={this.toggle}>
            {t('headings.title')}
          </ModalHeader>

          <ModalBody>
            {result.wasFailure() ? <ErrorInfo error={result.error}/> : null}

            <Row>
              <Col sm="12">
                {warningText}
              </Col>
            </Row>
          </ModalBody>

          <ModalFooter>
            <Button
              name="cancel-button"
              type="button"
              color="secondary"
              onClick={this.onCancel}>
              {t('buttons.cancel')}
            </Button>

            <SubmitButton
              name="delete-button"
              color="danger"
              result={result}
              buttonStatus=""
              onSubmitForm={this.onSubmitForm}
              submitText={t('buttons.delete')}>
            </SubmitButton>
          </ModalFooter>
        </Modal>
      </Form>
    )
  }

  constructor(props) {
    super(props)

    this.state = {
      result: AsyncResult.notFound(),
    }
  }

  onCancel = () => {
    this.onToggle()
  }

  onToggle = (...args) => {
    const { toggle } = this.props
    this.resetState()

    if (toggle) {
      toggle(...args)
    }
  }

  resetState = () => {
    this.setState({
      result: AsyncResult.notFound(),
    })
  }

  onSubmitForm = async event => {
    event.preventDefault()

    this.setState({ result: AsyncResult.pending() })

    const { group } = this.props

    try {
      await this.props.voidGroup({
        variables: { groupId: group.id },
      })

      this.setState({
        result: AsyncResult.success(),
      })

      this.onToggle()
      this.props.onSuccess()
    } catch (e) {
      analytics.logError(e)
      this.setState({ result: AsyncResult.fail(e) })
    }
  }

  getWarningText = (t, groupName, memberCount) => {
    if(memberCount > 0) {
      return t('text.warning_with_aggregate', {
        groupName,
        count: memberCount,
      })
    } else {
      return t('text.warning_no_aggregate', {
        groupName,
      })
    }
  }

  static VOID_GROUP_MUTATION = gql`
    mutation VoidGroup($groupId: Int!) {
      voidGroup (groupId: $groupId)
  }`
}

export default compose(
  graphql(VoidGroupModal.VOID_GROUP_MUTATION, {
    name: 'voidGroup',
  }),
  withTranslation([
    'src/management_path/groups_path/void_group_modal',
    'common/text',
  ])
)(VoidGroupModal)
