import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { get } from 'lodash/fp/object'
import { noop } from 'lodash/fp/util'
import { compose } from 'redux'
import { Table, Alert, Card } from 'reactstrap'
import { createSelector } from 'reselect'
import { withTranslation } from 'react-i18next'

import AsyncResult from '@@src/utils/async_result'
import EventListItem from './event_list_item'
import PaginationRow from '@@src/components/pagination_row'
import { DEFAULT_TIMEZONE } from '@@src/utils'
import { ORDER_ASC, ORDER_DESC } from
'@@src/analysis_path/pressure_analysis_path/with_orderby_and_direction'
import AppSettingsConsumer from '@@src/components/app_settings_consumer'
import {
  AVAILABLE_PRESSURE_UNITS,
} from '@@src/utils/unit_constants'

import styles from './event_list_table_content.css'

const PressureUnit = ({ unit, t }) => {

  return (
    <div className="font-weight-normal small">
      ({t(`common/text:units.${unit}`)})
    </div>
  )
}

export const START_TIME_KEY = 'startTime'
export const END_TIME_KEY = 'endTime'
export const MIN_PRESSURE_KEY = 'minPressure'
export const MAX_PRESSURE_KEY = 'maxPressure'
export const MEAN_PRESSURE_KEY = 'meanPressure'
export const CPIS_KEY = 'cpis'
export const SEVERITY_KEY = 'severity'

class EventListTableContent extends React.PureComponent {
  static defaultProps = {
    timezone: DEFAULT_TIMEZONE,
    orderBy: START_TIME_KEY,
    createPageLink: noop,
    page: 1,
    totalPages: 1,
    onMouseEnterEventRow: noop,
    onMouseLeaveEventRow: noop,
  }

  static propTypes = {
    page: PropTypes.number.isRequired,
    perPage: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired,
    timezone: PropTypes.string.isRequired,
    pagedPressureEventsResult: PropTypes.instanceOf(AsyncResult).isRequired,
    legendItemsResult: PropTypes.instanceOf(AsyncResult).isRequired,
    onClickViewEvents: PropTypes.func.isRequired,
    setOrderByAndDirection: PropTypes.func.isRequired,
    orderBy: PropTypes.oneOf([
      START_TIME_KEY,
      END_TIME_KEY,
      MIN_PRESSURE_KEY,
      MAX_PRESSURE_KEY,
      MEAN_PRESSURE_KEY,
      CPIS_KEY,
      SEVERITY_KEY,
    ]).isRequired,
    orderDirection: PropTypes.oneOf([ORDER_ASC, ORDER_DESC]).isRequired,
    className: PropTypes.string,
    onMouseEnterEventRow: PropTypes.func,
    onMouseLeaveEventRow: PropTypes.func,
    onChangePerPage: PropTypes.func,
    children: PropTypes.node,
    pressureUnits: PropTypes.oneOf(AVAILABLE_PRESSURE_UNITS).isRequired,
  }

  constructor(props) {
    super(props)
    this.handleStartClick = this.handleSortClick.bind(this, START_TIME_KEY)
    this.handleEndClick = this.handleSortClick.bind(this, END_TIME_KEY)
    this.handleMinPressureClick = this.handleSortClick.bind(
      this,
      MIN_PRESSURE_KEY
    )
    this.handleMaxPressureClick = this.handleSortClick.bind(
      this,
      MAX_PRESSURE_KEY
    )
    this.handleMeanPressureClick = this.handleSortClick.bind(
      this,
      MEAN_PRESSURE_KEY
    )
    this.handleCPISClick = this.handleSortClick.bind(this, CPIS_KEY)
    this.handleSeverityClick = this.handleSortClick.bind(this, SEVERITY_KEY)
  }

  render() {
    const {
      t, className, page, perPage, createPageLink, onChangePerPage, timezone,
      onClickViewEvents, totalPages, children, pressureUnits,
    } = this.props

    const pressureEventsWithLegendItem =
      this.selectPressureEventsWithLegendItemResult(this.props)
    const sortableItemClassnames = classnames(
      styles['event-header-item'],
      styles['event-header-item-sortable']
    )

    return (
      <div className={classnames(className, 'd-flex flex-column pb-2')}>
        {children}

        {
          pressureEventsWithLegendItem.data.length === 0 &&
            pressureEventsWithLegendItem.wasSuccessful() ? (
              <Alert color="warning">{t('text.no_events_found')}</Alert>
            ) : (
              <React.Fragment>
                <Card>
                  <Table className="mb-0">
                    <thead className={styles['event-head']}>
                      <tr>
                        <th className={styles['event-header-item']} />
                        <th className={styles['event-header-item']}>
                          {t('headings.asset')}
                        </th>
                        <th className={styles['event-header-item']}>
                          {t('headings.channel')}
                        </th>
                        <th
                          name="sortable-event-header"
                          onClick={this.handleStartClick}
                          className={sortableItemClassnames}>
                          {t('headings.start')}
                          &nbsp;
                          {this.renderSortCaret(START_TIME_KEY)}
                        </th>
                        <th
                          name="sortable-event-header"
                          onClick={this.handleEndClick}
                          className={sortableItemClassnames}>
                          {t('headings.end')}
                          &nbsp;
                          {this.renderSortCaret(END_TIME_KEY)}
                        </th>
                        <th className={styles['event-header-item']}>
                          {t('headings.duration')}
                        </th>
                        <th
                          name="sortable-event-header"
                          onClick={this.handleCPISClick}
                          className={sortableItemClassnames}>
                          {t('headings.cpis_score')}
                          &nbsp;
                          {this.renderSortCaret(CPIS_KEY)}
                        </th>
                        <th
                          name="sortable-event-header"
                          onClick={this.handleSeverityClick}
                          className={sortableItemClassnames}>
                          {t('headings.severity')}
                          &nbsp;
                          {this.renderSortCaret(SEVERITY_KEY)}
                          <PressureUnit
                            unit={pressureUnits}
                            t={t}
                          />
                        </th>
                        <th
                          name="sortable-event-header"
                          onClick={this.handleMinPressureClick}
                          className={sortableItemClassnames}>
                          {t('headings.min')}
                          &nbsp;
                          {this.renderSortCaret(MIN_PRESSURE_KEY)}
                          <PressureUnit
                            unit={pressureUnits}
                            t={t}
                          />
                        </th>
                        <th
                          name="sortable-event-header"
                          onClick={this.handleMeanPressureClick}
                          className={sortableItemClassnames}>
                          {t('headings.mean')}
                          &nbsp;
                          {this.renderSortCaret(MEAN_PRESSURE_KEY)}
                          <PressureUnit
                            unit={pressureUnits}
                            t={t}
                          />
                        </th>
                        <th
                          name="sortable-event-header"
                          onClick={this.handleMaxPressureClick}
                          className={sortableItemClassnames}>
                          {t('headings.max')}
                          &nbsp;
                          {this.renderSortCaret(MAX_PRESSURE_KEY)}
                          <PressureUnit
                            unit={pressureUnits}
                            t={t}
                          />
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {pressureEventsWithLegendItem.data.map(
                        eventWithLegend => (
                          <EventListItem
                            onMouseEnterRow={
                              this.getOnMouseEnterEventRowHandler(
                                eventWithLegend.pressureEvent
                              )
                            }
                            pressureUnits={pressureUnits}
                            onMouseLeaveRow={
                              this.getOnMouseLeaveEventRowHandler(
                                eventWithLegend.pressureEvent
                              )
                            }
                            key={eventWithLegend.pressureEvent.id}
                            event={eventWithLegend.pressureEvent}
                            timezone={timezone}
                            legendItem={eventWithLegend.legendItem}
                            onClickViewEvents={onClickViewEvents}/>
                        )
                      )}
                    </tbody>
                  </Table>
                </Card>

                <PaginationRow
                  page={page}
                  perPage={perPage}
                  className="flex-grow-0 flex-shrink-0 mt-2"
                  totalPages={totalPages}
                  createPageLink={createPageLink}
                  onChangePerPage={onChangePerPage}/>
              </React.Fragment>
            )
        }

      </div>
    )
  }

  getOnMouseEnterEventRowHandler = pressureEvent => {
    const { onMouseEnterEventRow } = this.props

    return () => onMouseEnterEventRow(pressureEvent)
  }

  getOnMouseLeaveEventRowHandler = pressureEvent => {
    const { onMouseLeaveEventRow } = this.props

    return () => onMouseLeaveEventRow(pressureEvent)
  }

  renderSortCaret = orderKey => {
    const { orderBy, orderDirection } = this.props
    const caretClassnames = classnames('fas', {
      'fa-caret-up': orderKey === orderBy && orderDirection === ORDER_ASC,
      'fa-caret-down': orderKey === orderBy && orderDirection === ORDER_DESC,
      'fa-minus': orderKey !== orderBy,
    })

    return (
      <i name="sort-direction-indicator" className={caretClassnames} />
    )
  }

  handleSortClick(newOrderBy) {
    const { setOrderByAndDirection, orderBy, orderDirection } = this.props
    let newOrderDirection = ORDER_DESC

    if (orderBy === newOrderBy && orderDirection === ORDER_DESC) {
      newOrderDirection = ORDER_ASC
    }

    setOrderByAndDirection(newOrderBy, newOrderDirection)
  }

  selectPressureEventsWithLegendItemResult = createSelector(
    [get('pagedPressureEventsResult'), get('legendItemsResult')],
    (pagedPressureEventsResult, legendItemsResult) => {
      return AsyncResult.fromArray([
        pagedPressureEventsResult,
        legendItemsResult,
      ]).map(([pressureEventsData, legendItems]) => {
        return pressureEventsData.pressureEvents.map(event => {
          const legendItem = legendItems.find(item => {
            return String(event.assetId) === String(item.sourceId) &&
              event.logicalChannel === item.sourceChannel
          })

          return {
            legendItem,
            pressureEvent: event,
          }
        })
      })
    }
  )

  getAssetId = id => {
    const { t, dataSourcesAndGroupsResult = [] } = this.props

    const dataSource = dataSourcesAndGroupsResult
      .find(data => String(data.id) === String(id))

    return dataSource ? dataSource.assetId : t('errors.unknown')
  }
}

function EventListTableContentContainer(props) {
  return (
    <AppSettingsConsumer>
      {
        (units) => (
          <EventListTableContent
            pressureUnits={units.pressure}
            {...props}
          />
        )
      }
    </AppSettingsConsumer>
  )
}

export default compose(
  withTranslation([
    'src/analysis_path/pressure_analysis_path/event_list_table/event_list_table_content', // eslint-disable-line max-len
    'common/text',
  ]),
)(EventListTableContentContainer)
