import gql from 'graphql-tag'
import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { graphql } from '@apollo/client/react/hoc'
import { withTranslation } from 'react-i18next'

import NetworkAssetFormModal from './network_asset_form_modal'

class CreateNetworkAssetModal extends React.PureComponent {
  static defaultProps = {
    onSuccess: () => { },
  }

  static propTypes = {
    onSuccess: PropTypes.func.isRequired,
  }

  render() {
    const { t, isOpen, toggle, className } = this.props

    return (
      <NetworkAssetFormModal
        title={t('headings.title')}
        isOpen={isOpen}
        toggle={toggle}
        onSubmit={this.onSubmitNetworkAsset}
        className={className}/>
    )
  }

  onSubmitNetworkAsset = async networkAssetFields => {
    await this.props.mutate({ variables: networkAssetFields })

    this.props.onSuccess()
  }

  static CREATE_NETWORK_ASSET_MUTATION = gql`
  mutation CreateNetworkAsset(
    $groupIds: [Int],
    $assetId: String!,
    $comment: String,
    $location: AssetLocationInput!,
    $assetType: InflowNetAssetType!,
    $assetName: String,
    $customLabels: String,
    $locationSource: LocationSourceType,
  ) {
    createNetworkAsset(
      groupIds: $groupIds,
      comment: $comment,
      assetId: $assetId,
      location: $location,
      assetName: $assetName,
      assetType: $assetType,
      customLabels: $customLabels,
      locationSource: $locationSource,
    ) {
      id
    }
  }
  `
}

export default compose(
  graphql(CreateNetworkAssetModal.CREATE_NETWORK_ASSET_MUTATION),
  withTranslation([
    'src/management_path/network_assets_path/create_network_asset_modal',
  ])
)(CreateNetworkAssetModal)
