import React from 'react'
import { connect } from 'react-redux'
import { find } from 'lodash/fp/collection'
import { v4 as uuidv4 } from 'uuid'

import Trigger from './trigger'
import Contents from './contents'
import TooltipStore from './store'

class Wrapper extends React.PureComponent {
  render() {
    const { trigger } = this.props

    return React.cloneElement(trigger, {
      triggerRef: this.handleTriggerRef,
      onMouseEnter: this.onMouseEnter,
      onMouseLeave: this.onMouseLeave,
    })
  }

  constructor(props) {
    super(props)

    this.tooltipId = uuidv4()
    this.handleTriggerRef = this.handleTriggerRef.bind(this)
    this.onMouseEnter = this.onMouseEnter.bind(this)
    this.onMouseLeave = this.onMouseLeave.bind(this)
  }

  handleTriggerRef(r) {
    this.triggerRef = r
  }

  onMouseEnter(...args) {
    const { trigger, content, renderTooltip } = this.props

    renderTooltip(this.tooltipId, this.triggerRef, content)

    if (trigger.props.onMouseEnter) {
      trigger.props.onMouseEnter(...args)
    }
  }

  onMouseLeave(...args) {
    const { trigger, removeTooltip } = this.props

    removeTooltip(this.tooltipId)

    if (trigger.props.onMouseLeave) {
      trigger.props.onMouseLeave(...args)
    }
  }

  componentWillUnmount() {
    this.props.removeTooltip(this.tooltipId)
  }
}

function mapStateToProps(_state, props) {
  const childrenArray = React.Children.toArray(props.children)

  return {
    content: find(({ type }) => type === Contents)(childrenArray),
    trigger: find(({ type }) => type === Trigger)(childrenArray),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    renderTooltip(tooltipId, ref, content) {
      dispatch(TooltipStore.create(tooltipId, ref, content))
    },
    removeTooltip(tooltipId) {
      dispatch(TooltipStore.remove(tooltipId))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Wrapper)
