import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash/fp/object'
import { createSelector } from 'reselect'
import { compose } from 'redux'
import gql from 'graphql-tag'
import { graphql } from '@apollo/client/react/hoc'
import { connect } from 'react-redux'

import EventSourceLocalisationStore from '@@src/store/event_source_localisation'
import { createSelectGraphQLResult } from '@@src/utils'
import { parseGraphQLResult } from '@@src/api/presenters'
import { ORDER_ASC, ORDER_DESC } from
'@@src/analysis_path/pressure_analysis_path/with_orderby_and_direction'
import {
  START_TIME_KEY,
  END_TIME_KEY,
  MIN_PRESSURE_KEY,
  MAX_PRESSURE_KEY,
  MEAN_PRESSURE_KEY,
  CPIS_KEY,
  SEVERITY_KEY,
} from '@@src/analysis_path/pressure_analysis_path/event_list_table/event_list_table'
import EventSourceLocalisationMap from
'@@src/analysis_path/pressure_analysis_path/event_source_localisation/event_source_localisation_map' // eslint-disable-line max-len
import AsyncResult from '@@src/utils/async_result'
import LegendItem from '@@src/components/graphs/legend_item'
import { DEFAULT_TIMEZONE } from '@@src/utils'

class EventSourceLocalisationMapWithEvents extends PureComponent {
  static propTypes = {
    eventsOrderBy: PropTypes.oneOf([
      START_TIME_KEY,
      END_TIME_KEY,
      MIN_PRESSURE_KEY,
      MAX_PRESSURE_KEY,
      MEAN_PRESSURE_KEY,
      CPIS_KEY,
      SEVERITY_KEY,
    ]).isRequired,
    eventsOrderDirection: PropTypes.oneOf([ORDER_ASC, ORDER_DESC]).isRequired,
    eventSourceLocalisationsResult:
      PropTypes.instanceOf(AsyncResult).isRequired,
    viewOnlyUnreadEventSourceLocalisations: PropTypes.bool.isRequired,
    selectedGroup: PropTypes.instanceOf(LegendItem),
  }

  static defaultProps = {
    eventsOrderBy: START_TIME_KEY,
    eventsOrderDirection: ORDER_DESC,
    viewOnlyUnreadEventSourceLocalisations: false,
    eventSourceLocalisationsResult: AsyncResult.pending([]),
    timezone: DEFAULT_TIMEZONE,
  }

  state = {
    heatmapIndex: 0,
  }

  render() {
    const { heatmapIndex } = this.state
    const { eventsOrderBy, eventsOrderDirection, ...rest } = this.props

    return (
      <EventSourceLocalisationMap
        eventsOrderBy={eventsOrderBy}
        eventsOrderDirection={eventsOrderDirection}
        heatmapIndex={heatmapIndex}
        setHeatmapIndex={this.setHeatmapIndex}
        {...rest} />
    )
  }

  setHeatmapIndex = index => {
    this.setState({ heatmapIndex: index - 1 })
  }

  static EVENT_SOURCE_LOCALISATIONS_QUERY = gql`
    query EventSourceLocalisations(
      $groupId: Int!,
      $start: String!,
      $end: String!,
      $groupAlertStatus: [EventSourceLocalisationStatus]!
    ) {
      eventSourceLocalisations(
        groupId: $groupId,
        start: $start,
        end: $end,
        groupAlertStatus: $groupAlertStatus,
      ) {
        groupId
        signedUrl
        timeRefPoint
        heatmapId
        status
        eventIds
      }
    }
  `
}

function createMapStateToProps() {
  const selectValidGroupSource = createSelector(
    [get('groupDataSourcesLegendItems')],
    groupDataSourcesLegendItems => {
      const groups = groupDataSourcesLegendItems.data.filter(item => {
        return item.children.filter(c => c.isFromNetworkAsset()).length > 2
      })

      if (groups.length === 1) {
        return groups[0]
      } else {
        return null
      }
    }
  )

  const selectEventSourceLocalisationsResult = createSelectGraphQLResult(
    'eventSourceLocalisations',
    {
      mapResult: parseGraphQLResult,
      nullObject: [],
    }
  )

  return function mapStateToProps(state, ownProps) {
    return {
      selectedGroup: selectValidGroupSource(ownProps),
      selectEventSourceLocalisationsResult,
      viewOnlyUnreadEventSourceLocalisations:
        state.eventSourceLocalisation.viewOnlyUnreadEventSourceLocalisations,
    }
  }
}

export default compose(
  connect(createMapStateToProps),
  graphql(
    EventSourceLocalisationMapWithEvents.EVENT_SOURCE_LOCALISATIONS_QUERY,
    {
      skip: ({ selectedGroup }) => !selectedGroup,
      options: ({
        fatchingVariables,
        selectedGroup,
        viewOnlyUnreadEventSourceLocalisations,
      }) => {
        return {
          variables: {
            start: new Date(fatchingVariables.start).toISOString(),
            end: new Date(fatchingVariables.end).toISOString(),
            groupId: selectedGroup.source.id,
            groupAlertStatus: viewOnlyUnreadEventSourceLocalisations ?
              [EventSourceLocalisationStore.ALERT_STATUS_UNREAD] :
              EventSourceLocalisationStore.ALERT_STATUSES,
          },
        }
      },
      props: ({ data, ownProps }) => {
        const { selectEventSourceLocalisationsResult } = ownProps

        return {
          refetchEventSourceLocalisations: data.refetch,
          eventSourceLocalisationsResult:
            selectEventSourceLocalisationsResult(data),
        }
      },
    }
  ),
)(EventSourceLocalisationMapWithEvents)
