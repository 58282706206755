import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { withRouter } from 'react-router'
import moment from 'moment'

import SimpleViewModal from
'@@src/management_path/devices_path/history_modal/simple_view_modal'
import defineQueryParams from '@@src/components/define_query_params'
import { DEFAULT_TIMEZONE } from '@@src/utils'
import { DEFAULT_PERIOD_MILLISECONDS } from
'@@src/management_path/devices_path/history_modal/history_graph_container'

export default function withHistoryModal(Component) {
  function HistoryModal(props) {
    const {
      title,
      deviceId,
      startTime,
      endTime,
      timezone,
      dispatchUpdateQueryParams,
      dispatchClearQueryParams,
      children,
      channels,
      ...rest
    } = props

    return (
      <SimpleViewModal
        dispatchUpdateQueryParams={dispatchUpdateQueryParams}
        dispatchClearQueryParams={dispatchClearQueryParams}
        startTime={startTime}
        endTime={endTime}
        timezone={timezone}
        title={title}
        {...rest}>
        <Component
          dispatchUpdateQueryParams={dispatchUpdateQueryParams}
          dispatchClearQueryParams={dispatchClearQueryParams}
          startTime={startTime}
          endTime={endTime}
          timezone={timezone}
          deviceId={deviceId}
          channels={channels}>
          {children}
        </Component>
      </SimpleViewModal>
    )
  }

  HistoryModal.propTypes = {
    title: PropTypes.string.isRequired,
    deviceId: PropTypes.number.isRequired,
    dispatchUpdateQueryParams: PropTypes.func.isRequired,
    dispatchClearQueryParams: PropTypes.func.isRequired,
    startTime: PropTypes.instanceOf(Date),
    endTime: PropTypes.instanceOf(Date),
    timezone: PropTypes.string,
  }

  return compose(
    withRouter,
    defineQueryParams(() => {
      const now = new Date()
      const yesterday = moment(now)
        .subtract(DEFAULT_PERIOD_MILLISECONDS, 'milliseconds')
        .toDate()

      return {
        startTime: {
          alias: 'from',
          default: yesterday,
          parseFunc: defineQueryParams.parseDate,
        },
        endTime: {
          alias: 'to',
          default: now,
          parseFunc: defineQueryParams.parseDate,
        },
        timezone: {
          alias: 'tz',
          default: DEFAULT_TIMEZONE,
        },
      }
    }),
  )(React.memo(HistoryModal))
}
