import React, { useState } from 'react'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import { AsyncResult } from '../../../utils'
import { ANOMALY_REPORT_CSV } from './consts'
import { withTranslation } from 'react-i18next'
import { Button, ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'
import styles from '@@src/components/dropdowns/export_dropdown_with_modal/export_dropdown_with_modal.css'

AnomalyListExportDropdown.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onSelectReportType: PropTypes.func.isRequired,
  requestReportResult: PropTypes.instanceOf(AsyncResult).isRequired,
}

function AnomalyListExportDropdown({ requestReportResult, onSelectReportType, disabled, t }) {
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => setIsOpen(!isOpen)

  return (<>
    {disabled &&
      <Button color="info" disabled={true}>
        <i className="fa fa-file-download" />
        <span className={styles['dropdown-toggle']}>{t('text.label')}</span>
      </Button>}
    {!disabled && requestReportResult.isPending() &&
      <Button color="info" disabled={true}>
        <i className="fa fa-spinner fa-spin" />
      </Button>}
    {!disabled && !requestReportResult.isPending() &&
      <ButtonDropdown isOpen={isOpen} toggle={toggle}>
        <DropdownToggle color="info" caret>
          <i className="fa fa-file-download" />
          <span className={styles['dropdown-toggle']}>{t('text.label')}</span>
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem
            className={styles['dropdown-item']}
            onClick={() => onSelectReportType(ANOMALY_REPORT_CSV)}
          >
            CSV Data
          </DropdownItem>
        </DropdownMenu>
      </ButtonDropdown>
    }
  </>)
}

export default compose(
  withTranslation(['src/analysis_path/pressure_analysis_path/export_dropdown'])
)(AnomalyListExportDropdown)
