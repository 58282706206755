import { map } from 'lodash/fp/collection'
import { flow } from 'lodash/fp/util'
import { toPairs } from 'lodash/fp/object'
import { fromPairs } from 'lodash/fp/array'
import { mapValues } from 'lodash'

import * as analytics from '@@src/analytics'
import { GroupDetailsType, MixedGroupDetailsType, GetGroupsResult } from './group'
import Device from './device'
import PagedData from './paged_data'
import Installation from './installation'
import NetworkAsset from './network_asset'
import PressureEvent from './pressure_event'
import DeviceCommission from './device_commission'
import DevicesStatisticsModel from './dashboard_statistics_model'
import NetworkAssetsStatistics from './network_assets_statistics'
import AnomalyBoundsModel from './anomaly_bounds_model'
import AnomalyModel from './anomaly_model.js'
import AnomaliesPlotResultModel from './anomalies_plot_result_model'
import AnomalyBoundsExceededModel from './anomaly_bounds_exceeded_model'
import Pagination from './pagination'
import TimeSeriesModel from './time_series_model'
import TenantSettingsModel from './tenant_settings_model'
import PagedPressureSubsetData from './paged_pressure_subset_data'
import TimeSeriesRawDataListItems from './time_series_raw_data_list_items'
import EventSourceLocalisation from './event_source_localisation'
import DeviceIssue from './device_issue'
import DeviceConfig from './device_config'
import TenantConfig from './tenant_config'
import RawDataRequestDataSlice from './raw_data_request_data_slice'
import {
  default as Alert,
  StandardAlertDetails,
  DeviceNoCommsAlertDetails,
  EventSourceLocalisationAlertDetails,
  EventAlertDetails,
} from './alert'
import Comment from './comment'
import EventPlotCluster from './event_plot_cluster'
import DetailsOfEventInSingleEventsPlotCluster from './details_of_even_in_single_events_plot_cluster'
import TenantFeatureFlags from './tenant_feature_flags'
import NetworkAssetListItem from './network_asset_list_item'
import InstalledDeviceOnAssetListModel from './installed_device_on_asset_list_model'

export function parseGraphQLResult(data) {
  return data instanceof Array
    ? parseRawCollectionData(data)
    : data && hasOwnProperty(data, '__typename')
      ? parseRawObjectData(data)
      : data
}

function hasOwnProperty(obj, propertyName) {
  return Object.prototype.hasOwnProperty.call(obj, propertyName)
}

function parseRawObjectData(inputData) {
  const data = flow(
    toPairs,
    map(([key, value]) => [key, parseGraphQLResult(value)]),
    fromPairs
  )(inputData)

  switch (data.__typename) {
    case 'Device':
      return new Device(data)
    case 'PagedDevices':
      return new PagedData({
        data: data.devices.map(Device.from),
        pagination: new Pagination(data.pagination),
      })

    case 'PagedDataSourceListItemsTypesTypes':
      return new PagedData({
        data: data.allDataSources,
        pagination: new Pagination(data.pagination),
      })

    case 'Commission':
    case 'CurrentCommission':
      return new DeviceCommission(data)

    case 'Installation':
      return new Installation(data)

    case 'NetworkAsset':
      return new NetworkAsset(data)
    case 'PagedNetworkAssets':
      return new PagedData({
        data: data.networkAssets.map(NetworkAsset.from),
        pagination: new Pagination(data.pagination),
      })

    case 'GroupDetailsType':
      return new GroupDetailsType(data)
    case 'MixedGroupDetailsType':
      return new MixedGroupDetailsType(data)

    case 'GroupMembersType':
      return new GroupDetailsType.GroupMembersType(data)
    case 'MixedGroupMembersType':
      return new MixedGroupDetailsType.MixedGroupMembersType(data)

    case 'GroupListItemModel':
      return new GetGroupsResult.GroupListItemModel(data)

    case 'PagedGroups':
      return new PagedData({
        data: data.groups.map(MixedGroupDetailsType.from),
        pagination: new Pagination(data.pagination),
      })

    case 'GetGroupsResult':
      return new PagedData({
        data: data.groups.map(GetGroupsResult.from),
        pagination: new Pagination(data.pagination),
      })

    case 'PagedUsers':
      return new PagedData({
        data: data.users,
        pagination: new Pagination(data.pagination),
      })

    case 'Event':
    case 'EventsBySeverity':
      return new PressureEvent(data)

    case 'Pagination':
    case 'PartialPagination':
      return new Pagination(data)

    case 'RawDataRequestDataSlice':
      return new RawDataRequestDataSlice(data)

    case 'Alert':
      return new Alert(data)
    case 'StandardAlertDetails':
      return new StandardAlertDetails(data)
    case 'DeviceNoCommsAlertDetails':
      return new DeviceNoCommsAlertDetails(data)
    case 'EventSourceLocalisationAlertDetails':
      return new EventSourceLocalisationAlertDetails(data)
    case 'EventAlertDetails':
      return new EventAlertDetails(data)
    case 'PagedAlerts': {
      const { alerts, pagination, ...rest } = data
      return new PagedData({
        ...rest,
        data: alerts.map(Alert.from),
        pagination: new Pagination(pagination),
      })
    }

    case 'Comment':
      return new Comment(data)

    case 'PagedComments':
      return new PagedData({
        data: data.comments.map(Comment.from),
        pagination: new Pagination(data.pagination),
      })

    case 'DevicesStatisticsModel':
      return new DevicesStatisticsModel(data)

    case 'NetworkAssetsStatistics':
      return new NetworkAssetsStatistics(data)

    case 'AnomalyBoundsModel':
      return new AnomalyBoundsModel(data)

    case 'AnomalyModel':
      return new AnomalyModel(data)

    case 'AnomaliesPlotResultModel':
      return new AnomaliesPlotResultModel(data)

    case 'AnomalyBoundsExceededModel':
      return new AnomalyBoundsExceededModel(data)

    case 'EventsPlotCluster':
      return new EventPlotCluster(data)

    case 'DetailsOfEventInSingleEventsPlotCluster':
      return new DetailsOfEventInSingleEventsPlotCluster(data)

    case 'Mapping':
    case 'Location':
    case 'DatePeriod':
    case 'EventClass':
    case 'PagedRawData':
    case 'RawDataPoint':
    case 'ChannelMapping':
    case 'CpisSubsetPoint':
    case 'AlertThresholds':
    case 'PaginatedEvents':
    case 'TelemetryDataModel':
    case 'EventCharacteristic':
    case 'TimeSeriesDataListItem':
    case 'TimeSeriesTelemetryDataModel':
    case 'NetworkAssetCpisSubsetDataItem':
    case 'CpisTimeSeriesChunkedDataListItems':
    case 'GroupAlertSummary':
    case 'PermissionsModel':
    case 'RoleModel':
    case 'UserModel':
    case 'RawDataRequestsWithMinutesLeft':
    case 'RawDataNetworkAssetMinutesLeft':
    case 'RawDataChannelMinutesLeft':
    case 'RawDataMinutesLeft':
    case 'RawDataRequestItem':
    case 'NetworkAssetChannelOutput':
    case 'CommissionPressureSubsetDataChunk':
    case 'InstallationPressureSubsetDataChunk':
    case 'MostDistantDeviceOrAssetGroupType':
    case 'MostDistantGroupListItem':
    case 'AssetAncestryGroup':
    case 'AncestorType':
    case 'ReportRequestResponse':
    case 'CommentAuthor':
    case 'ReleaseNote':
    case 'UnitSettings':
    case 'AnomaliesResultModel':
    case 'CongruityInfoMap':
    case 'DeviceHasData':
    case 'GroupCongruityType':
    case 'GroupCongruityZoneType':
    case 'GetGroupsGroupMember':
    case 'NetworkAssetChannel':
    case 'GetEventsPlotClustersTimeRangeInput':
    case 'DetailsOfEventInSingleEventsPlotCluster':
      return data

    case 'InstalledDeviceOnAssetListModel':
      return new InstalledDeviceOnAssetListModel(data)

    case 'NetworkAssetListItem':
      return new NetworkAssetListItem(data)

    case 'TenantFeatureFlags':
      return new TenantFeatureFlags(data)

    case 'TenantConfig':
      return new TenantConfig(data)

    case 'DeviceConfig':
      return new DeviceConfig(data)

    case 'PagedPressureSubsetData':
      return new PagedPressureSubsetData(data)

    case 'DeviceIssue':
      return new DeviceIssue(data)

    case 'EventSourceLocalisation':
      return new EventSourceLocalisation(data)

    case 'TimeSeriesModel':
      return new TimeSeriesModel(data)
    case 'TimeSeriesRawDataListItems':
      return new TimeSeriesRawDataListItems(data)

    case 'UserSettingsModel':
      return data

    case 'TenantSettingsModel':
      return new TenantSettingsModel(data)

    case 'AlertThresholds':
      const processedValues = mapValues(data, (v) =>
        typeof v === 'boolean' ? v.toString() : v
      )
      return processedValues

    default:
      analytics.logError('Unrecognised type', data.__typename)
      throw new Error(`Unrecognized type "${data.__typename}"`)
  }
}

function parseRawCollectionData(rawGraphQLCollectionData) {
  return rawGraphQLCollectionData.map(parseGraphQLResult)
}
