import { createElement, PureComponent } from 'react'
import PropTypes from 'prop-types'

import styles from './contents.css'

const TOP = 'tooltip.top'
const BOTTOM = 'tooltip.bottom'

const defaultProps = {
  location: TOP,
}

const propTypes = {
  location: PropTypes.oneOf([TOP, BOTTOM]).isRequired,
  triggerBoundingRect: PropTypes.any,
}

class Contents extends PureComponent {
  render() {
    const { children } = this.props

    return createElement('div', this.tooltipProps(), children)
  }

  tooltipProps() {
    const { location, triggerBoundingRect } = this.props

    switch (location) {
      case TOP:
        return {
          className: styles['tooltip-top'],
          style: !triggerBoundingRect ? undefined : {
            top: triggerBoundingRect.top,
            left: triggerBoundingRect.left + triggerBoundingRect.width / 2,
          },
        }

      case BOTTOM:
        return {
          className: styles['tooltip-bottom'],
          style: !triggerBoundingRect ? undefined : {
            top: triggerBoundingRect.top,
            left: triggerBoundingRect.left + triggerBoundingRect.width / 2,
          },
        }

      default:
        return null
    }
  }
}

Contents.propTypes = propTypes
Contents.defaultProps = defaultProps

Contents.TOP = TOP
Contents.BOTTOM = BOTTOM

export default Contents
