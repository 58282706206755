import React from 'react'
import gql from 'graphql-tag'
import { graphql } from '@apollo/client/react/hoc'
import { compose } from 'redux'
import PropTypes from 'prop-types'

import CommentForm from '@@src/alerts_path/comment_form'
import AsyncResult from '@@src/utils/async_result'
import * as analytics from '@@src/analytics'
import Alert from '@@src/api/presenters/alert'
import Comment from '@@src/api/presenters/comment'

class CreateCommentForm extends React.PureComponent {
  static propTypes = {
    createComment: PropTypes.func.isRequired,
    alert: PropTypes.instanceOf(Alert).isRequired,
    parentComment: PropTypes.instanceOf(Comment),
    comment: PropTypes.instanceOf(Comment),
  }

  render() {
    const { createCommentResult } = this.state

    return (
      <CommentForm
        {...this.props}
        commentResult={createCommentResult}
        handleSubmit={this.createComment}
        handleSubmitError={this.setCreateCommentError} />
    )
  }

  state = {
    createCommentResult: AsyncResult.notFound(),
  }

  createComment = async newComment => {
    const {
      createComment,
      parentComment,
      alert,
    } = this.props

    try {
      this.setState({ createCommentResult: AsyncResult.pending() })

      const variables = {
        alertId: alert.alertId,
        comment: newComment,
      }

      if (parentComment) {
        variables.parentId = parentComment.commentId
      }

      await createComment({ variables })

      this.setState({ createCommentResult: AsyncResult.success() })
    } catch (err) {
      analytics.logError(err)
      this.setState({ createCommentResult: AsyncResult.fail(err) })
    }
  }

  setCreateCommentError = err => {
    this.setState({
      createCommentResult: AsyncResult.fail(err),
    })
  }

  static CREATE_NEW_COMMENT_MUTATION = gql`
    mutation CreateComment (
      $alertId: Int!,
      $comment: String!,
      $parentId: Int
    ) {
      createComment (
        alertId: $alertId,
        comment: $comment,
        parentId: $parentId
      )
    }
  `
}

export default compose(
  graphql(CreateCommentForm.CREATE_NEW_COMMENT_MUTATION, {
    name: 'createComment',
  }),
)(CreateCommentForm)
