import React from 'react'
import PropTypes from 'prop-types'
import { createSelector } from 'reselect'
import * as analytics from '@@src/analytics'

import {
  SETTING_GRAPH_OPTIONS,
  SETTING_COLLECTION_OBJECT,
  SETTING_DISPLAY_ALERTS,
  SETTING_DISPLAY_EVENTS,
  SETTING_DISPLAY_ANOMALIES,
  SETTING_DISPLAY_RAW_DATA,
} from '../constants'

export default function withPlotVisibilityOptions(Component) {
  return class PlotVisibilityOptionsContainer extends React.PureComponent {
    static propTypes = {
      selectedGraphSetting: PropTypes.array.isRequired,
    }

    constructor(props) {
      super(props)
      const { displayRawData, displayAnomalies, displayAlerts, displayEvents } = this.props
      const visibilitySettings = { ...SETTING_COLLECTION_OBJECT }
      if(displayAlerts !== undefined) {
        visibilitySettings[SETTING_DISPLAY_ALERTS] = displayAlerts
      }
      if(displayAnomalies !== undefined) {
        visibilitySettings[SETTING_DISPLAY_ANOMALIES] = displayAnomalies
      }
      if(displayRawData !== undefined) {
        visibilitySettings[SETTING_DISPLAY_RAW_DATA] = displayRawData
      }
      if(displayEvents !== undefined) {
        visibilitySettings[SETTING_DISPLAY_EVENTS] = displayEvents
      }
      this.state = {
        visibilitySettings,
      }
    }

    render() {
      const visibilitySettings = this.selectVisibilitySettings(this.props, this.state)

      return (
        <Component
          visibilitySettings={visibilitySettings}
          onChangeVisibilityOptions={this.onChangeVisibilityOptions}
          displayEvents={visibilitySettings[SETTING_DISPLAY_EVENTS]}
          displayAnomalies={visibilitySettings[SETTING_DISPLAY_ANOMALIES]}
          displayAlerts={visibilitySettings[SETTING_DISPLAY_ALERTS]}
          displayRawData={visibilitySettings[SETTING_DISPLAY_RAW_DATA]}
          {...this.props} />
      )
    }

    selectVisibilitySettings = createSelector(
      [
        (props, _state) => props.selectedGraphSetting,
        (_props, state) => state.visibilitySettings,
      ],
      (selectedGraphSetting, visibilitySettings) => {
        const settings = { ...visibilitySettings }
        SETTING_GRAPH_OPTIONS.forEach(setting => {
          settings[setting] = selectedGraphSetting.includes(setting)
        })
        return settings
      }
    )

    onChangeVisibilityOptions = (value) => {
      const { visibilitySettings } = this.state

      this.setState({
        visibilitySettings: { ...visibilitySettings, [value]: !visibilitySettings[value] },
      })

      if (!visibilitySettings.hasOwnProperty(value)) {
        analytics.logError('Option was not found')
        return
      }
    }
  }
}
