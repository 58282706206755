import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'reactstrap'
import { compose } from 'redux'
import gql from 'graphql-tag'
import { graphql } from '@apollo/client/react/hoc'
import { noop } from 'lodash/fp/util'
import { withTranslation } from 'react-i18next'

import MessageBox from '@@src/components/modals/message_box'
import AsyncResult from '@@src/utils/async_result'
import * as analytics from '@@src/analytics'
import Comment from '@@src/api/presenters/comment'
import { sleep } from '@@src/utils'

const INFLOWNET_WAIT = process.env.NODE_ENV === 'test' ? 0 : 1000

class DeleteCommentButton extends React.PureComponent {
  static propTypes = {
    deleteComment: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    comment: PropTypes.instanceOf(Comment).isRequired,
    onDeleteSuccess: PropTypes.func.isRequired,
    className: PropTypes.string,
  }

  state = {
    confirmationModalIsOpen: false,
    deleteResult: AsyncResult.notFound([]),
    onDeleteSuccess: noop,
  }

  render() {
    const { t, className } = this.props
    const { confirmationModalIsOpen, deleteResult } = this.state

    return (
      <React.Fragment>
        <MessageBox
          results={deleteResult}
          headerText={t('heading.delete_comment')}
          acceptText={t('button.delete')}
          cancelText={t('button.cancel')}
          toggle={this.handleToggleOpenConfirmationModal}
          onAccept={this.handleClickDelete}
          isOpen={confirmationModalIsOpen}>
          {t('text.confirm_delete')}
        </MessageBox>
        <Button
          aria-label={t('heading.delete_comment')}
          type="button"
          className={className}
          color="secondary"
          onClick={this.handleToggleOpenConfirmationModal}>
          <span className="far fa-trash-alt" />
        </Button>
      </React.Fragment>
    )
  }

  handleClickDelete = async () => {
    try {
      const { comment, deleteComment, onDeleteSuccess } = this.props

      this.setState({ deleteResult: AsyncResult.pending() })

      await deleteComment({
        variables: {
          commentId: comment.commentId,
        },
      })

      await sleep(INFLOWNET_WAIT)
      await onDeleteSuccess()

      this.setState({ deleteResult: AsyncResult.success() })

      this.handleToggleOpenConfirmationModal()
    } catch (err) {
      analytics.logError(err)
      this.setState({ deleteResult: AsyncResult.fail(err) })
    }
  }

  handleToggleOpenConfirmationModal = () => {
    this.setState({
      confirmationModalIsOpen: !this.state.confirmationModalIsOpen,
    })
  }

  static DELETE_COMMENT_MUTATION = gql`
    mutation DeleteComment($commentId: Int!) {
      deleteComment(commentId: $commentId)
    }
  `
}


export default compose(
  withTranslation([
    'src/alerts_path/delete_comment_button',
  ]),
  graphql(DeleteCommentButton.DELETE_COMMENT_MUTATION, {
    name: 'deleteComment',
  }),
)(DeleteCommentButton)
