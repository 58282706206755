import PropTypes from 'prop-types'
import React from 'react'
import classnames from 'classnames'
import { withTranslation } from 'react-i18next'
import { Badge } from 'reactstrap'

import './issues_summary_badge.component.scss'

const IssuesSummaryBadgeComponent = ({ t, issue, errors, warnings, information, className }) => {
  const hasSeparator = !!issue && !!(errors || warnings || information)
  const issueColor = classnames({
    'danger': issue && issue.severity === 'ERROR',
    'warning': issue && issue.severity === 'WARNING',
    'info': issue && issue.severity === 'INFORMATION',
  })
  return (
    <div className={['issue-badge-wrapper d-flex flex-nowrap', className].join(' ')}>
      {!!issue && (<Badge color={issueColor} className={'issue-badge'}>
        {t(`options.${issue.type}`)}
      </Badge>)}
      {hasSeparator && (<Badge color={'transparent'} className={'issue-badge-separator'}> + </Badge>)}
      {!!errors && (<Badge color={'danger'} className={'issue-badge'}>{errors}</Badge>)}
      {!!warnings && (<Badge color={'warning'} className={'issue-badge'}>{warnings}</Badge>)}
      {!!information && (<Badge color={'info'} className={'issue-badge'}>{information}</Badge>)}
    </div>
  )
}

IssuesSummaryBadgeComponent.propTypes = {
  issue: PropTypes.shape({
    type: PropTypes.string.isRequired,
    severity: PropTypes.string.isRequired,
  }),
  errors: PropTypes.number,
  warnings: PropTypes.number,
  information: PropTypes.number,
  className: PropTypes.string,
}

export default withTranslation([
  'v2/network_assets/filters',
])(IssuesSummaryBadgeComponent)
