import { MapLayer, withLeaflet } from 'react-leaflet'
import L from 'leaflet'
import { abbreviateNumber, renderClusterIcon } from '../../../../../components/map_marker/utils'

import 'leaflet.markercluster'

L.MarkerClusterGroup.include({
  _flushLayerBuffer() {
    this.addLayers(this._layerBuffer)
    this._layerBuffer = []
  },

  addLayer(layer) {
    if (this._layerBuffer.length === 0) {
      setTimeout(this._flushLayerBuffer.bind(this), 50)
    }
    this._layerBuffer.push(layer)
  },
})

L.MarkerClusterGroup.addInitHook(function initHook() {
  this._layerBuffer = []
})

const getStatsOfClusterChildren = (cluster) => {
  let errors = 0
  let warnings = 0
  let information = 0

  cluster
    .getAllChildMarkers()
    .forEach(children => {
      if (
        children.options &&
        children.options.children &&
        children.options.children.props &&
        children.options.children.props.networkAsset
      ) {
        const {
          activeErrorCount,
          activeWarningCount,
          activeInformationCount,
        } = children.options.children.props.networkAsset || {}
        errors += +activeErrorCount || 0
        warnings += +activeWarningCount || 0
        information += +activeInformationCount || 0
      }
    })

  return { errors, warnings, information }
}

class NetworkAssetMarkerClusterComponent extends MapLayer {
  createLeafletElement({ leaflet: { map }, ...clusterProps }) {
    const props = {}
    const events = {}

    Object
      .entries(clusterProps)
      .forEach(([name, value]) => {
        name.startsWith('on')
          ? events[name] = value
          : props[name] = value
      })

    const layerContainer = new L.markerClusterGroup({
      ...props,
      iconCreateFunction: (cluster) => {
        const countText = abbreviateNumber(cluster.getChildCount())
        const { errors, warnings, information } = getStatsOfClusterChildren(cluster)

        return renderClusterIcon({ errors, warnings, information, countText })
      },
    })

    this.contextValue = { layerContainer, map }

    Object
      .entries(events)
      .forEach(([eventAsProp, callback]) => {
        layerContainer.on(`cluster${eventAsProp.substring(2).toLowerCase()}`, callback)
      })

    return layerContainer
  }
}

export default withLeaflet(NetworkAssetMarkerClusterComponent)
