import React, { useState, useCallback } from 'react'
import gql from 'graphql-tag'
import { graphql } from '@apollo/client/react/hoc'
import { useMutation } from '@apollo/client'
import {
  UncontrolledButtonDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from 'reactstrap'
import { NavLink } from 'react-router-dom'
import MuteAlertsModal from '@@src/components/modals/mute_alerts_modal/mute_alerts_modal'
import GroupsAlertsDropdown from '../../dropdowns/management_groups_alerts_dropdown'

import * as analytics from '@@src/analytics'
import routes from '@@src/routes'
import classnames from 'classnames'

const MuteAlertsForGroupModal = graphql(
  gql`
    mutation MuteAlertsForGroup(
      $groupId: Int!
      $startTime: DateString!
      $endTime: DateString!
    ) {
      muteAlertsForGroup(
        groupId: $groupId
        startTime: $startTime
        endTime: $endTime
      )
    }
  `,
  { name: 'muteAlerts' }
)(MuteAlertsModal)

const UNMUTE_ALERTS = gql`
  mutation UnmuteAlertsForGroup($groupId: Int!) {
    unmuteAlertsForGroup(groupId: $groupId)
  }
`

function EditGroupButton({
  t,
  group,
  canEdit,
  dataTip,
  onMove,
  onDelete,
  refetchGroups,
}) {
  const [isMuteAlertsModalOpen, setIsMuteAlertsModalOpen] = useState(false)

  const [unMuteAlerts] = useMutation(UNMUTE_ALERTS)

  const createSelectionItems = () => {
    return [
      {
        name: 'move-group',
        icon: 'far fa-arrow-right',
        title: t('buttons.move'),
        onClick: onMove,
      },
      {
        name: 'delete-group',
        icon: 'far fa-trash-alt',
        title: t('buttons.delete'),
        onClick: onDelete,
      },
    ]
  }

  const handleSubmitMuteAlertsModal = useCallback(async () => {
    await refetchGroups()
  }, [refetchGroups])

  const handleToggleMuteAlertsModal = useCallback(
    async (event) => {
      event.persist()

      setIsMuteAlertsModalOpen(!isMuteAlertsModalOpen)
    },
    [setIsMuteAlertsModalOpen, isMuteAlertsModalOpen]
  )

  const handleToggleUnmuteAlertsModal = useCallback(
    async (event) => {
      event.preventDefault()

      try {
        await unMuteAlerts({
          variables: {
            groupId: group.id,
          },
        })

        await refetchGroups()
      } catch (e) {
        analytics.logError(e)
      }
    },
    [unMuteAlerts, refetchGroups, group]
  )

  return (
    <span data-tip={dataTip}>
      <MuteAlertsForGroupModal
        isOpen={isMuteAlertsModalOpen}
        handleSubmit={handleSubmitMuteAlertsModal}
        handleToggle={handleToggleMuteAlertsModal}
        muteAlertsVariables={{ groupId: group.id }}
      />

      <UncontrolledButtonDropdown
        name="edit-group-dropdown"
        disabled={!canEdit}
      >
        <NavLink
          to={routes.editGroupPath(group.id)}
          className={classnames(
            'btn btn-outline-secondary',
            canEdit ? '' : 'disabled'
          )}
        >
          {t('buttons.edit')}
        </NavLink>
        <DropdownToggle caret={true} outline={true} disabled={!canEdit} />
        <DropdownMenu end>
          <GroupsAlertsDropdown
            t={t}
            group={group}
            handleToggleMuteAlertsModal={handleToggleMuteAlertsModal}
            handleToggleUnmuteAlertsModal={handleToggleUnmuteAlertsModal}
          />

          {createSelectionItems().map((selection, i) => (
            <DropdownItem
              name={selection.name}
              key={selection.title}
              onClick={selection.onClick}
            >
              <i className={classnames(selection.icon, 'mr-2')} />
              {selection.title}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    </span>
  )
}

export default EditGroupButton
