import React from 'react'
import {
  Button,
} from 'reactstrap'
import PropTypes from 'prop-types'

export default class SubmitButton extends React.Component {
  static defaultProps = {
    size: '',
    className: '',
    latchDisable: false,
    disabled: false,
  }

  static propTypes = {
    disabled: PropTypes.bool.isRequired,
    latchDisable: PropTypes.bool.isRequired,
    className: PropTypes.string,
    size: PropTypes.string,
    submitText: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  }

  constructor(props) {
    super(props)
    this.state = {
      disabled: false,
      buttonStatus: '',
    }
  }

  render() {
    const renderButtonContent = () => {
      switch(this.state.buttonStatus) {
        case 'is-error':
          return(
            <i className="far fa-exclamation-triangle cursor-pointer"></i>
          )
        case 'is-success':
          return(
            <i className="far fa-check cursor-pointer"></i>
          )
        case 'is-loading':
          return(
            <i className="far fa-spinner-third fa-spin cursor-pointer"></i>
          )
        default:
          return this.props.submitText
      }
    }
    return (
      <Button
        name={this.props.name}
        color={this.props.color + ' ' + this.state.buttonStatus}
        className={this.props.className}
        size={this.props.size}
        type="submit"
        disabled={this.state.disabled || this.props.disabled}
        onClick={this.onClick}
      >
        <span className="btn-content cursor-pointer">
          {renderButtonContent()}
        </span>
      </Button>
    )
  }

  saveButtonUpdate = () => {
    if (this.props.result.isPending()) {
      this.setIsLoading()
    } else if (this.props.result.wasFailure() ||
               this.props.result.wasNotFound()) {
      this.setIsError()
    } else if (this.props.result.wasSuccessful()) {
      this.setIsSuccess()
    } else {this.setIsDefault()}
  }

  onClick = async (event) => {
    this.setIsLoading()
    await this.props.onSubmitForm(event)
    this.saveButtonUpdate()
  }

  setIsError = () => {
    this.setState({ buttonStatus: 'is-error',
      disabled: this.props.latchDisable && this.state.disabled })
  }

  setIsSuccess = () => {
    this.setState({ buttonStatus: 'is-success',
      disabled: this.props.latchDisable && this.state.disabled })
  }

  setIsLoading = () => {
    this.setState({ buttonStatus: 'is-loading',
      disabled: true })
  }

  setIsDefault = () => {
    this.setState({ buttonStatus: '',
      disabled: false })
  }

}
