import moment from 'moment'

export default class DetailsOfEventInSingleEventsPlotCluster {
  constructor(data) {
    Object.assign(this, data)
  }

  withTimezone(timezone) {
    return new DetailsOfEventInSingleEventsPlotCluster({
      ...this,
      startLocalized: moment.utc(this.utcStart).tz(timezone).format(),
      endLocalized: moment.utc(this.utcEnd).tz(timezone).format(),
    })
  }
}
