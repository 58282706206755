import gql from 'graphql-tag'
import React from 'react'
import { compose } from 'redux'
import { graphql } from '@apollo/client/react/hoc'
import PropTypes from 'prop-types'
import { get } from 'lodash/object'
import transformProps from '@@src/components/transform_props'
import AsyncResultSwitch from '@@src/components/async_result_switch'
import { parseGraphQLResult } from '@@src/api/presenters'
import { AsyncResult, createSelectGraphQLResult } from '@@src/utils'
import {
  renderAbsolutePositionedLoading,
} from '@@src/components/app_suspense_container'
import { createSelector } from 'reselect'
import { CAN_VIEW_ALERTS } from '../_v2/contexts/user/consts/permissions'
import { withUser } from '../_v2/contexts/user/user.context'

const noop = () => null

export const TenantSettingsContext = React.createContext()

export function withTenantSettings({
  tenantSettingsResultAlias = 'tenantSettingsResult',
} = {}) {
  return Component => {
    return function WithTenantSettings(props) {
      return (
        <TenantSettingsContext.Consumer>
          {value => <Component {...{
            ...props,
            refetchTenantSettings: props.refetchTenantSettings,
            [tenantSettingsResultAlias]: value.settings,
          }} />}
        </TenantSettingsContext.Consumer>
      )
    }
  }
}

class TenantSettingsProvider extends React.PureComponent {
  static propTypes = {
    refetchTenantSettings: PropTypes.func.isRequired,
    tenantSettingsResult: PropTypes.instanceOf(AsyncResult).isRequired,
  }

  render() {
    return (
      <AsyncResultSwitch
        result={this.props.tenantSettingsResult}
        renderPendingResult={
          this.props.hideLoading ? noop : renderAbsolutePositionedLoading
        }
        renderSuccessResult={this.renderSuccessResult}>
      </AsyncResultSwitch>
    )
  }

  renderSuccessResult = result => {
    return (
      <TenantSettingsContext.Provider value={
        this.selectContextValue(this.props, result)
      }>
        {this.props.children}
      </TenantSettingsContext.Provider>
    )
  }

  selectContextValue = createSelector(
    [get('refetchTenantSettings'), (_, { data }) => data],
    (refetchTenantSettings, settings) => {
      return {
        refetchTenantSettings,
        settings,
      }
    },
  )

  static TENANT_SETTINGS_QUERY = gql`
    query TenantSettings($skipAlerts: Boolean!) {
      tenantSettings {
        language
        languagesAvailable
        alertThresholds @skip(if: $skipAlerts) {
          dailyCpisHigh
          dailyCpisHighEnabled
          dailyCpisLow
          dailyCpisLowEnabled
          deviceNoComms
          deviceNoCommsEnabled
          eventCpis
          eventCpisEnabled
          eventSeverity
          eventSeverityEnabled
          eventSourceLocalisationEnabled
          pressureHigh
          pressureHighEnabled
          pressureLow
          pressureLowEnabled
          batteryDepletingEnabled
        }
        units {
          pressure
          temperature
          distance
          pressureUnitsAvailable
          temperatureUnitsAvailable
          distanceUnitsAvailable
        }
      }
    }
  `
}

export default compose(
  transformProps(() => () => {
    return {
      selectTenantSettingsResult: createSelectGraphQLResult('tenantSettings', {
        mapResult: parseGraphQLResult,
        nullObject: null,
      }),
    }
  }),
  withUser,
  graphql(TenantSettingsProvider.TENANT_SETTINGS_QUERY, {
    options: ({ authorizedUser }) => ({
      fetchPolicy: 'network-only',
      variables: {
        skipAlerts: !authorizedUser.can(CAN_VIEW_ALERTS),
      },
    }),
    props: ({ data, ownProps: { selectTenantSettingsResult } }) => {
      return {
        refetchTenantSettings: data.refetch,
        tenantSettingsResult: selectTenantSettingsResult(data),
      }
    },
  }),
)(TenantSettingsProvider)
