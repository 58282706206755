import React from 'react'
import { debounce } from 'lodash/function'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import {
  Label, FormGroup, Input, Dropdown, DropdownMenu, DropdownItem, DropdownToggle,
  Button,
} from 'reactstrap'
import { compose } from 'redux'
import { connect } from 'react-redux'
import gql from 'graphql-tag'
import { graphql } from '@apollo/client/react/hoc'
import classnames from 'classnames'

import { createSelectGraphQLResult } from '@@src/utils'
import { parseGraphQLResult } from '@@src/api/presenters'
import AsyncResult from '@@src/utils/async_result'
import LoadingIcon from '@@src/components/loading_icon'
import NetworkAsset from '@@src/api/presenters/network_asset'
import withSourceFilterProvider from
'@@src/components/forms/source_filter_provider'

export const SOURCE_FILTER_PARAM = 'source'
const SEARCH_DEBOUNCE = process.env.NODE_ENV === 'test' ? 0 : 500

import styles from './source_filter.css'

function SourceFilterDropdownOption(props) {
  const { handleClick, children } = props

  return (
    <DropdownItem
      name="source-filter-option"
      onClick={handleClick}
      className={styles['text-wrap']}>
      {children}
    </DropdownItem>
  )
}

SourceFilterDropdownOption.propTypes = {
  children: PropTypes.node.isRequired,
  handleClick: PropTypes.func.isRequired,
}

const V_DEVICE_TYPE = 'VDevice'
export const V_NETWORK_ASSET_TYPE = 'VNetworkAsset'
const V_GROUP_TYPE = 'VGroup'
const AGGREGATE_TYPES = [
  V_DEVICE_TYPE,
  V_NETWORK_ASSET_TYPE,
  V_GROUP_TYPE,
]

class SourceFilter extends React.PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    pagedSourcesResult: PropTypes.instanceOf(AsyncResult).isRequired,
    networkAssetSourceFilterResult:
      PropTypes.instanceOf(AsyncResult).isRequired,
    groupSourceFilterResult: PropTypes.instanceOf(AsyncResult).isRequired,
    dropdownDirection:
      PropTypes.oneOf(['up', 'down', 'left', 'right']).isRequired,
    location: PropTypes.object.isRequired,
    displayLabel: PropTypes.bool.isRequired,
    displayAssets: PropTypes.bool.isRequired,
    displayGroups: PropTypes.bool.isRequired,
    groupTypes: PropTypes.arrayOf(PropTypes.oneOf(AGGREGATE_TYPES)).isRequired,
    handleClickSelectSource: PropTypes.func,
    sourceFilter: PropTypes.string,
    refetchPagedSources: PropTypes.func,
  }

  static defaultProps = {
    pagedSourcesResult: AsyncResult.notFound([]),
    dropdownDirection: 'left',
    displayLabel: true,
    displayAssets: true,
    displayGroups: true,
    groupTypes: AGGREGATE_TYPES,
  }

  dropdownMenuRef = React.createRef()

  constructor(props) {
    super(props)

    this.state = {
      searchValue: props.sourceFilter,
      searchDropdownIsOpen: false,
      isSearching: false,
    }
  }

  render() {
    const { t, sourceFilter, displayLabel } = this.props

    return (
      <FormGroup>
        <Label htmlFor="source-filter-input" hidden={!displayLabel}>
          {t('label.source')}
        </Label>
        {
          sourceFilter ?
            this.renderSelectedSource() :
            this.renderSearchInput()
        }
      </FormGroup>
    )
  }

  renderSearchInput = () => {
    const { t, pagedSourcesResult, dropdownDirection } = this.props
    const { searchValue, searchDropdownIsOpen, isSearching } = this.state

    return (
      <Dropdown
        direction={dropdownDirection}
        isOpen={searchDropdownIsOpen && !!searchValue}
        toggle={this.handleSearchDropdownToggle}>
        <DropdownToggle tag="div" className={classnames('sr-only', {
          'h-100': dropdownDirection === 'up' || dropdownDirection === 'down',
          'w-100': dropdownDirection === 'right',
        })} />
        <Input
          onBlur={this.handleSearchBlur}
          onFocus={this.handleSearchDropdownToggle}
          placeholder={t('input.search')}
          value={searchValue || ''}
          onChange={this.handleSearchChange}
          id="source-filter-search"
          name="sourceFilterSearch"
          type="input" />
        <DropdownMenu className="w-100">
          <div ref={this.dropdownMenuRef}>
            {
              pagedSourcesResult.isPending() || isSearching ?
                <LoadingIcon className="fa-2x text-center" /> :
                this.renderPagedSources()
            }
          </div>
        </DropdownMenu>
      </Dropdown>
    )
  }

  renderSelectedSource = () => {
    const {
      sourceFilter, networkAssetSourceFilterResult, groupSourceFilterResult, t,
    } = this.props

    if (networkAssetSourceFilterResult.isPending() ||
      groupSourceFilterResult.isPending()) {
      return (
        <LoadingIcon className="fa-2x text-center" />
      )
    } else {
      return (
        <div
          name="selected-source-filter"
          className="d-flex justify-content-between align-items-center">
          {
            NetworkAsset.isNetworkAssetDataSourceId(sourceFilter) ?
              <div className="d-flex flex-column">
                {networkAssetSourceFilterResult.data.assetId}
                {
                  networkAssetSourceFilterResult.data.assetName ?
                    <small className="text-muted">
                      {networkAssetSourceFilterResult.data.assetName}
                    </small>
                    : null
                }
              </div> :
              groupSourceFilterResult.data.name
          }
          <Button
            size="sm"
            outline={true}
            aria-label={t('button.clear')}
            name="clear-selected-source-filter-button"
            className="ml-4 px-3"
            onClick={this.getHandleClickSelectSource('')}>
            <span className="far fa-times" />
          </Button>
        </div>
      )
    }
  }

  componentDidUpdate(prevProps) {
    const { sourceFilter: prevSourceFilter } = prevProps
    const { sourceFilter } = this.props

    if (prevSourceFilter !== sourceFilter) {
      this.setState({ searchValue: sourceFilter })
    }
  }

  renderPagedSources = () => {
    const { t, displayGroups, displayAssets, pagedSourcesResult } = this.props
    const [networkAssetsData, groupsData] = pagedSourcesResult.data

    return (
      <React.Fragment>
        <DropdownItem
          className={classnames(
            'font-weight-normal font-italic',
            styles['text-wrap'])
          }
          header={true}>
          {
            displayAssets && displayGroups ?
              t('text.source_search_results_limit') :
              displayAssets ?
                t('text.assets_search_results_limit') :
                t('text.groups_search_results_limit')
          }
        </DropdownItem>
        { displayAssets ? this.renderAssets(networkAssetsData) : null }
        {
          displayAssets && displayGroups ?
            <DropdownItem divider={true} /> : null
        }
        { displayGroups ? this.renderGroups(groupsData) : null }
      </React.Fragment>
    )
  }

  renderAssets = networkAssetsData => {
    const { t } = this.props

    return (
      <React.Fragment>
        <DropdownItem header>
          {
            Array.isArray(networkAssetsData.data) &&
              networkAssetsData.data.length > 0 ?
              t('text.network_asset_results', {
                results: Math.min(
                  networkAssetsData.data.length,
                  networkAssetsData.pagination.totalResults
                ),
                totalResults: networkAssetsData.pagination.totalResults,
              })
              :
              t('text.network_assets')
          }
        </DropdownItem>
        {
          Array.isArray(networkAssetsData.data) &&
            networkAssetsData.data.length > 0 ?
            networkAssetsData.data.map(pagedAsset => (
              <SourceFilterDropdownOption
                key={pagedAsset.dataSourceId}
                handleClick={
                  this.getHandleClickSelectSource(pagedAsset.dataSourceId)
                }>
                {pagedAsset.assetId}
                {
                  pagedAsset.assetName ?
                    <span className="font-italic ml-2">
                      {pagedAsset.assetName}
                    </span> :
                    null
                }
              </SourceFilterDropdownOption>
            ))
            :
            <DropdownItem className="font-italic">
              {t('text.no_network_asset_results')}
            </DropdownItem>

        }
      </React.Fragment>
    )
  }

  renderGroups = groupsData => {
    const { t } = this.props

    return (
      <React.Fragment>
        <DropdownItem header>
          {
            Array.isArray(groupsData.data) && groupsData.data.length > 0 ?
              t('text.group_results', {
                results: Math.min(
                  groupsData.data.length,
                  groupsData.pagination.totalResults
                ),
                totalResults: groupsData.pagination.totalResults,
              })
              :
              t('text.groups')
          }
        </DropdownItem>
        {
          Array.isArray(groupsData.data) && groupsData.data.length > 0 ?
            groupsData.data.map(pagedGroup => (
              <SourceFilterDropdownOption
                key={pagedGroup.dataSourceId}
                handleClick={
                  this.getHandleClickSelectSource(pagedGroup.dataSourceId)
                }>
                {pagedGroup.name}
              </SourceFilterDropdownOption>
            ))
            :
            <DropdownItem className="font-italic">
              {t('text.no_group_results')}
            </DropdownItem>
        }
      </React.Fragment>
    )
  }

  handleSearchBlur = ev => {
    if (!ev.target || !this.dropdownMenuRef.current ||
      (!this.dropdownMenuRef.current.contains(ev.target) &&
        this.dropdownMenuRef.current.isEqualNode(ev.target))) {
      this.setState({ searchDropdownIsOpen: false })
    }
  }

  handleSearchDropdownToggle = () => {
    this.setState({ searchDropdownIsOpen: !this.state.searchDropdownIsOpen })
  }

  handleSearchChange = ev => {
    const searchValue = ev.target.value.substring(0, 200)
    const { searchDropdownIsOpen } = this.state

    this.setState({
      searchValue,
      isSearching: true,
    })
    this.dispatchSearchChange(searchValue)

    if (!searchDropdownIsOpen) {
      this.setState({ searchDropdownIsOpen: true })
    }
  }

  getHandleClickSelectSource = source => {
    const { handleClickSelectSource } = this.props

    return () => {
      this.setState({ searchValue: '' })
      handleClickSelectSource(source)
    }
  }

  dispatchSearchChange = debounce(searchValue => {
    const {
      refetchPagedSources,
      displayGroups,
      displayAssets,
      groupTypes,
    } = this.props

    if (typeof refetchPagedSources === 'function') {
      refetchPagedSources({
        searchQuery: searchValue,
        skipGroups: !displayGroups,
        skipAssets: !displayAssets,
        groupTypesFilter: groupTypes,
      })
    }

    this.setState({ isSearching: false })
  }, SEARCH_DEBOUNCE, { leading: false, trailing: true })

  static PAGED_ASSETS_AND_GROUPS_QUERY = gql`
    query PagedAssetsAndGroups(
      $searchQuery: String,
      $groupTypesFilter: [AggregateType],
      $skipAssets: Boolean!,
      $skipGroups: Boolean!,
    ) {
      pagedNetworkAssets(
        searchQuery: $searchQuery,
        pageNumber: 1,
        resultsPerPage: 5,
      ) @skip(if: $skipAssets) {
        networkAssets {
          id
          assetId
          assetName
        }
        pagination {
          totalResults
        }
      }

      pagedGroups(
        searchQuery: $searchQuery,
        groupTypesFilter: $groupTypesFilter,
        pageNumber: 1,
        resultsPerPage: 5,
      ) @skip(if: $skipGroups) {
        groups {
          id
          name
          members {
            type
            count
          }
          mostDistantDeviceOrAssetGroups {
            id
            name
            type
          }
        }
        pagination {
          totalResults
        }
      }
    }
  `
}

function createMapStateToProps() {
  const selectPagedGroupsResult = createSelectGraphQLResult('pagedGroups', {
    mapResult: parseGraphQLResult,
    nullObject: [],
  })

  const selectPagedNetworkAssetsResult =
    createSelectGraphQLResult('pagedNetworkAssets', {
      mapResult: parseGraphQLResult,
      nullObject: [],
    })

  return function mapStateToProps() {
    return {
      selectPagedNetworkAssetsResult,
      selectPagedGroupsResult,
    }
  }
}

export default compose(
  connect(createMapStateToProps),
  withTranslation([
    'src/components/forms/source_filter',
  ]),
  graphql(SourceFilter.PAGED_ASSETS_AND_GROUPS_QUERY, {
    options: () => {
      return {
        variables: {
          skipAssets: true,
          skipGroups: true,
        },
      }
    },
    props: ({ data, ownProps }) => {
      const { refetch } = data
      const {
        selectPagedGroupsResult, selectPagedNetworkAssetsResult,
      } = ownProps

      return {
        refetchPagedSources: refetch,
        pagedSourcesResult: AsyncResult.fromArray([
          selectPagedNetworkAssetsResult(data),
          selectPagedGroupsResult(data),
        ]),
      }
    },
  }),
  withSourceFilterProvider,
)(SourceFilter)
