import React, { Component, useState, createContext, useEffect, useContext } from 'react'
import { renderAbsolutePositionedLoading } from '../../../components/app_suspense_container'
import { AppConfigContext } from '../app_config/app-config.context'
import { AuthorizationService } from './services/authorization-service'

const InitialContextData = Object.freeze({ authorization: AuthorizationService.initializeNull() })
export const AuthorizationContext = createContext(InitialContextData)

export const AuthorizationProvider = ({ children }) => {
  const [authorization, setAuthorization] = useState(null)
  const { appConfig } = useContext(AppConfigContext)

  useEffect(() => {
    AuthorizationService
      .initializeForAppConfigConfig(appConfig)
      .then(authorization => setAuthorization(authorization))
  }, [setAuthorization])


  if (!authorization) {
    return renderAbsolutePositionedLoading()
  }

  return (
    <AuthorizationContext.Provider value={{ authorization }}>
      {children}
    </AuthorizationContext.Provider>
  )
}

export const withAuthorization = (Component) => (props) => (
  <AuthorizationContext.Consumer>
    {(contextValues) => (<Component {...props} {...contextValues} />)}
  </AuthorizationContext.Consumer>
)
