import { LatLng, LatLngBounds } from 'leaflet'
import GeoCoordinate from './geo_coordinate'

export default class GeoBounds {
  constructor(minLat, minLng, maxLat, maxLng) {
    this.southwest = new GeoCoordinate(minLat, minLng)
    this.northeast = new GeoCoordinate(maxLat, maxLng)

    Object.freeze(this)
  }

  static fromCoordinates(lat1, lng1, lat2, lng2) {
    const minLat = Math.min(parseFloat(lat1), parseFloat(lat2))
    const minLng = Math.min(parseFloat(lng1), parseFloat(lng2))
    const maxLat = Math.max(parseFloat(lat1), parseFloat(lat2))
    const maxLng = Math.max(parseFloat(lng1), parseFloat(lng2))

    return new GeoBounds(minLat, minLng, maxLat, maxLng)
  }

  // we should be able to pass array of GeoCoordinates and get bounds that contains all coordinates
  static fromGeoCoordinates(geoCoordinatesArray) {
    if (Array.isArray(geoCoordinatesArray) && geoCoordinatesArray.length >= 2) {
      const { minLat, minLng, maxLat, maxLng } = geoCoordinatesArray.reduce((acc, coordinate) => {
        return {
          minLat: Math.min(acc.minLat, coordinate.lat),
          minLng: Math.min(acc.minLng, coordinate.lng),
          maxLat: Math.max(acc.maxLat, coordinate.lat),
          maxLng: Math.max(acc.minLng, coordinate.lng),
        }
      }, {
        minLat: 0,
        minLng: 0,
        maxLat: 0,
        maxLng: 0,
      })

      return new GeoBounds(minLat, minLng, maxLat, maxLng)
    }

    throw Error('Wrong arguments provided to GeoBounds.fromGeoCoordinates')
  }

  static fromJSON(json) {
    const southwest = (json || {}).southwest || {}
    const northeast = (json || {}).northeast || {}

    return new GeoBounds(southwest.lat, southwest.lng, northeast.lat, northeast.lng)
  }

  static fromLatLngBounds(latLngBounds) {
    const southWest = latLngBounds.getSouthWest()
    const northEast = latLngBounds.getNorthEast()

    return new GeoBounds(
      southWest.lat < -85 ? -90 : southWest.lat > 85 ? 90 : southWest.lat,
      southWest.lng,
      northEast.lat < -85 ? -90 : northEast.lat > 85 ? 90 : northEast.lat,
      northEast.lng,
    )
  }

  toLatLngBounds() {
    return new LatLngBounds(
      new LatLng(this.southwest.lat, this.southwest.lng),
      new LatLng(this.northeast.lat, this.northeast.lng),
    )
  }

  toSuperclusterBounds() {
    return [
      this.southwest.lng,
      this.southwest.lat,
      this.northeast.lng,
      this.northeast.lat,
    ]
  }

  toObject() {
    return {
      southwest: {
        lat: this.southwest.lat,
        lng: this.southwest.lng,
      },
      northeast: {
        lat: this.northeast.lat,
        lng: this.northeast.lng,
      },
    }
  }

  equals(otherBounds) {
    if (otherBounds === undefined) {
      return false
    }

    if (otherBounds instanceof GeoBounds) {
      return otherBounds.southwest.lat === this.southwest.lat
        && otherBounds.southwest.lng === this.southwest.lng
        && otherBounds.northeast.lng === this.northeast.lng
        && otherBounds.northeast.lng === this.northeast.lng
    }

    throw Error('GeoBounds.equals() function expects to receive type GeoBounds')
  }

  getCenter() {
    const centerLat = (this.southwest.lat + this.northeast.lat) / 2
    const centerLng = (this.southwest.lng + this.northeast.lng) / 2
    return new GeoCoordinate(centerLat, centerLng)
  }

  contains(otherBounds) {
    if (otherBounds instanceof GeoBounds) {
      return (this.southwest.lat < otherBounds.southwest.lat) &&
        (this.southwest.lng < otherBounds.southwest.lng) &&
        (this.northeast.lat > otherBounds.northeast.lat) &&
        (this.northeast.lng > otherBounds.northeast.lng)
    }
    throw Error('GeoBounds.contains() function expects to receive type GeoBounds')
  }
}
