import React, { useContext } from 'react'
import { Button, ButtonGroup, UncontrolledTooltip } from 'reactstrap'
import {
  FeatureFlags,
  TenantsFeatureFlagsContext,
} from '../../../_v2/contexts/feature_flags/tenants_feature_flags.context'
import {
  SETTING_DISPLAY_EVENTS,
  SETTING_DISPLAY_RAW_DATA,
  SETTING_DISPLAY_ALERTS,
  SETTING_DISPLAY_ANOMALIES,
} from '../../../analysis_path/pressure_analysis_path/constants'

import EventsOn from '../../../static_assets/svgs/plot_feature_events_on.svg'
import EventsOff from '../../../static_assets/svgs/plot_feature_events_off.svg'
import AlertsOn from '../../../static_assets/svgs/plot_feature_alerts_on.svg'
import AlertsOff from '../../../static_assets/svgs/plot_feature_alerts_off.svg'
import AnomaliesOn from '../../../static_assets/svgs/plot_feature_anomaly_on.svg'
import AnomaliesOff from '../../../static_assets/svgs/plot_feature_anomaly_off.svg'
import RawDataOn from '../../../static_assets/svgs/plot_feature_rawdata_on.svg'
import RawDataOff from '../../../static_assets/svgs/plot_feature_rawdata_off.svg'

import styles from './visibility_button_group.css'

export default function VisibilityButtonGroupContainer(props) {
  const {
    t,
    displayAnomalies,
    displayRawData,
    displayEvents,
    displayAlerts,
    onChangeOptions,
  } = props

  const { isFeatureEnabled } = useContext(TenantsFeatureFlagsContext)

  return (
    <>
      <ButtonGroup id="visibility-button-group">
        {
          isFeatureEnabled(FeatureFlags.anomalyDetection) &&
          <Button
            id="show-anomalies-button"
            size="sm"
            color="light"
            className={styles['non-focusable']}
            active={displayAnomalies}
            disabled={!(typeof displayAnomalies === 'boolean')}
            onClick={() => onChangeOptions(SETTING_DISPLAY_ANOMALIES)}
            aria-label={t('buttons.show_anomalies')}>
            <img
              src={displayAnomalies ? AnomaliesOn : AnomaliesOff}
              className={styles['visibility-icon']}
              alt="anomaly-on-off" />

            <UncontrolledTooltip placement="top" target="show-anomalies-button">
              {t('buttons.show_anomalies')}
            </UncontrolledTooltip>
          </Button>
        }

        <Button
          id="show-events-button"
          size="sm"
          color="light"
          className={styles['non-focusable']}
          active={displayEvents}
          disabled={!(typeof displayEvents === 'boolean')}
          onClick={() => onChangeOptions(SETTING_DISPLAY_EVENTS)}
          aria-label={t('buttons.show_events')}>
          <img
            src={displayEvents ? EventsOn : EventsOff}
            className={styles['visibility-icon']}
            alt="events-on-off"
          />

          <UncontrolledTooltip placement="top" target="show-events-button">
            {t('buttons.show_events')}
          </UncontrolledTooltip>
        </Button>

        <Button
          id="show-alerts-button"
          size="sm"
          color="light"
          className={styles['non-focusable']}
          active={displayAlerts}
          disabled={!(typeof displayAlerts === 'boolean')}
          onClick={() => onChangeOptions(SETTING_DISPLAY_ALERTS)}
          aria-label={t('buttons.show_alerts')}>
          <img
            src={displayAlerts ? AlertsOn : AlertsOff}
            className={styles['visibility-icon']}
            alt="alerts-on-off" />

          <UncontrolledTooltip placement="top" target="show-alerts-button">
            {t('buttons.show_alerts')}
          </UncontrolledTooltip>
        </Button>

        <Button
          id="show-raw-data-button"
          size="sm"
          color="light"
          className={styles['non-focusable']}
          active={displayRawData}
          disabled={!(typeof displayRawData === 'boolean')}
          onClick={() => onChangeOptions(SETTING_DISPLAY_RAW_DATA)}
          aria-label={t('buttons.show_raw_data')}>
          <img
            src={displayRawData ? RawDataOn : RawDataOff}
            className={styles['visibility-icon']}
            alt="raw-data-on-off" />

          <UncontrolledTooltip placement="top" target="show-raw-data-button">
            {t('buttons.show_raw_data')}
          </UncontrolledTooltip>
        </Button>
      </ButtonGroup>
    </>
  )
}
