import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { get } from 'lodash/fp/object'
import { fromPairs } from 'lodash/fp/array'
import { noop } from 'lodash/fp/util'
import { createSelector } from 'reselect'
import classnames from 'classnames'
import { withTranslation } from 'react-i18next'

import GraphItem from '@@src/components/graphs/graph_item'
import GraphContext, { GraphConfig }
from '@@src/components/graphs/graph_context'
import { Popup, PopupAnchor, RIGHT } from '@@src/components/popup'
import { MouseEventContext } from '@@src/components/graphs/mouse_event_layer'
import TimeSeriesRawDataListItems from
'@@src/api/presenters/time_series_raw_data_list_items'
import { DEFAULT_TIMEZONE, AVAILABLE_TIME_ZONES } from '@@src/utils'
import { globalSequence } from '@@src/utils'
import { closestPointWithX, closestGraphItemWithY } from '@@src/utils/graphs'
import { LegendItemColorContext } from
'@@src/components/colors/legend_item_color_provider'

import styles from './graph_plot_measurement_ruler.css'

const TIME_FORMAT = 'MMM DD YYYY, HH:mm:ss.SSS'
const defaultSelectFormatX = createSelector(
  [timezone => timezone],
  timezone => time => moment(time).tz(timezone).format(TIME_FORMAT)
)

class GraphPlotMeasurementRuler extends React.PureComponent {
  static defaultProps = {
    timezone: DEFAULT_TIMEZONE,
    selectFormatX: defaultSelectFormatX,
    rawDataColumns: [],
    setFocusedLegendItem: noop,
  }

  static propTypes = {
    getX: PropTypes.func.isRequired,
    getYComparable: PropTypes.func.isRequired,
    timezone: PropTypes.oneOf(AVAILABLE_TIME_ZONES).isRequired,
    dataColumns: PropTypes.any.isRequired,
    selectFormatX: PropTypes.func.isRequired,
    rawDataColumns: PropTypes.any.isRequired,
    dataUnits: PropTypes.any.isRequired,
    graphItems: PropTypes.arrayOf(PropTypes.instanceOf(GraphItem)).isRequired,
    graphConfig: PropTypes.instanceOf(GraphConfig).isRequired,
    t: PropTypes.func.isRequired,
    getLegendItemColor: PropTypes.func.isRequired,
    setFocusedLegendItem: PropTypes.func.isRequired,
  }

  state = {
    rulerState: null,
    display: true,
  }

  id = globalSequence.next()

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown)
    document.addEventListener('keyup', this.handleKeyUp)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown)
    document.removeEventListener('keyup', this.handleKeyUp)
  }

  handleKeyDown = ev => {
    if (ev.shiftKey) {
      this.setState({ display: false })
    }
  }

  handleKeyUp = ev => {
    if (!ev.shiftKey) {
      this.setState({ display: true })
    }
  }

  render() {
    const { MouseEventChannel } = this.props
    const { rulerState } = this.state

    return (
      <MouseEventChannel
        onMouseMove={this.onMouseMove}
        onMouseLeave={this.onMouseLeave}>
        {
          rulerState ?
            this.renderRulerAndPopup(rulerState) : null
        }
      </MouseEventChannel>
    )
  }

  renderRulerAndPopup({
    mouseX,
    mouseY,
    mouseValueX,
    popupData,
    focusedLegendItemId,
  }) {
    const {
      graphConfig,
      dataUnits,
      getX,
      selectFormatX,
      timezone,
      t,
      getLegendItemColor,
    } = this.props
    const { display } = this.state
    const { xScale, yScale } = graphConfig
    const topOffset = graphConfig.topPadding
    const leftOffset = graphConfig.leftPadding
    const [minY, maxY] = yScale.range()
    const columnHeadings = this.selectColumnHeadings(this.props, popupData)
    const formatX = selectFormatX(timezone)
    const clipPathId = `graph-plot-measurement-ruler-${this.id}`

    return (
      <React.Fragment>
        <line
          x1={mouseX}
          y1={minY}
          x2={mouseX}
          y2={maxY}
          className={styles.ruler}/>

        <g>
          <defs>
            <clipPath id={clipPathId}>
              <rect
                x={leftOffset}
                y={topOffset}
                width={graphConfig.plotAreaWidth}
                height={graphConfig.plotAreaHeight}/>
            </clipPath>
          </defs>
          {popupData.map(({ graphItem, point }) => (
            <g clipPath={`url(#${clipPathId})`} key={graphItem.id}>
              {
                point ? columnHeadings.map(heading => {
                  const yValue = yScale(this.pointValueFor(heading, point))

                  return Number.isFinite(yValue) ? (
                    <circle
                      r="3"
                      cx={xScale(getX(point))}
                      cy={yValue}
                      key={heading}
                      stroke={getLegendItemColor(graphItem.legendItem)}
                      className={styles['ruler-point-marker']}
                    />
                  ) : null
                }) : null
              }
            </g>
          ))}
        </g>

        <PopupAnchor elementType="circle" r="0" cx={mouseX} cy={mouseY}>
          <Popup
            isOpen={display}
            location={RIGHT}
            className={styles['ruler-popup']}
            hideArrow={true}>
            <table className={styles['ruler-data-table']}>
              <thead>
                <tr>
                  <th
                    colSpan="2"
                    className={styles['ruler-date-measurement']}>
                    {formatX(mouseValueX)}
                  </th>

                  <th
                    colSpan={columnHeadings.length}
                    className={styles['ruler-data-units']}>
                    {dataUnits}
                  </th>
                </tr>

                <tr className={styles['ruler-data-column-headings-row']}>
                  <th colSpan="2"></th>
                  {
                    columnHeadings.map(heading => (
                      <th key={heading}>{heading}</th>
                    ))
                  }
                </tr>
              </thead>

              <tbody>
                {popupData.map(({ graphItem, point }) => (
                  <tr
                    className={classnames({
                      'font-weight-bold':
                        graphItem.legendItem.id === focusedLegendItemId,
                    })}
                    key={graphItem.id}>
                    <td>
                      <div
                        style={{
                          backgroundColor:
                            getLegendItemColor(graphItem.legendItem),
                        }}
                        className={styles['ruler-data-table-line-preview']}
                      />
                    </td>
                    <td>
                      {graphItem.legendItem.legendItemNameAndChannelText(t)}
                    </td>
                    {columnHeadings.map(heading => {
                      const value = point ?
                        this.pointValueFor(heading, point) : 'N/A'

                      return (
                        <td key={heading}>
                          {
                            Number.isFinite(value) ?
                              value.toFixed(2) : value
                          }
                        </td>
                      )
                    })}
                  </tr>
                ))}
              </tbody>

            </table>
            <p className={styles['ruler-hotkey-display-instruction']}>
              {t('text.display_instruction')}
            </p>
          </Popup>
        </PopupAnchor>
      </React.Fragment>
    )
  }

  componentDidUpdate(prevProps) {
    if (prevProps.graphItems !== this.props.graphItems) {
      this.setState({ rulerState: null })
    }
  }

  selectDataColumnHeadings = createSelector(
    [get('dataColumns')],
    columns => columns.map(column => column[0])
  )

  selectRawDataColumnHeadings = createSelector(
    [get('rawDataColumns')],
    columns => columns.map(column => column[0])
  )

  selectColumnHeadings = createSelector(
    [
      (_, popupData) => popupData,
      this.selectDataColumnHeadings,
      this.selectRawDataColumnHeadings,
    ],
    (popupData, dataColumnHeadings, rawDataColumnHeadings) => {
      const columnHeadings = []

      if (!popupData.every(({ point }) =>
        point instanceof TimeSeriesRawDataListItems)) {
        columnHeadings.push(...dataColumnHeadings)
      }

      if (popupData.some(({ point }) =>
        point instanceof TimeSeriesRawDataListItems)) {
        columnHeadings.push(...rawDataColumnHeadings)
      }

      return columnHeadings
    }
  )

  selectColumnGetterMap = createSelector(
    [get('dataColumns'), get('rawDataColumns')],
    (dataColumns, rawDataColumns) => {
      return fromPairs(dataColumns.concat(rawDataColumns))
    }
  )

  pointValueFor = (heading, point) => {
    const dataColumnHeadings = this.selectDataColumnHeadings(
      this.props
    )
    const rawDataColumnHeadings = this.selectRawDataColumnHeadings(this.props)
    const columnGetterMap = this.selectColumnGetterMap(this.props)

    if ((point instanceof TimeSeriesRawDataListItems &&
      rawDataColumnHeadings.includes(heading)) ||
        !(point instanceof TimeSeriesRawDataListItems) &&
          dataColumnHeadings.includes(heading)) {
      return columnGetterMap[heading](point)
    } else {
      return 'N/A'
    }
  }

  onMouseMove = (event, { mouseX, mouseY }) => {
    const {
      graphConfig, graphItems, getX, getYComparable, setFocusedLegendItem,
    } = this.props

    const mouseValueX = Number(graphConfig.xScale.invert(mouseX))
    const mouseValueY = Number(graphConfig.yScale.invert(mouseY))

    const popupData = graphItems.map(graphItem => ({
      graphItem,
      point: closestPointWithX(graphItem, getX, mouseValueX),
    }))

    const closestGraphItem =
      closestGraphItemWithY(popupData, getYComparable, mouseValueY)

    this.setState({
      rulerState: {
        mouseX,
        mouseY,
        popupData,
        mouseValueX,
        focusedLegendItemId: closestGraphItem ?
          closestGraphItem.legendItem.id : '',
      },
    })

    setFocusedLegendItem(closestGraphItem ? closestGraphItem.legendItem : null)
  }

  onMouseLeave = () => {
    this.setState({ rulerState: null })
    this.props.setFocusedLegendItem(null)
  }
}

export default function GraphPlotMeasurementRulerContainer(props) {
  const TranslatedGraphPlotMeasurementRuler = withTranslation([
    // eslint-disable-next-line max-len
    'src/analysis_path/pressure_analysis_path/graph_plot_measurement_ruler',
    'common/text',
  ])(GraphPlotMeasurementRuler)

  return (
    <GraphContext.Consumer>
      {config => (
        <MouseEventContext.Consumer>
          {({ Channel }) => (
            <LegendItemColorContext.Consumer>
              {({ getLegendItemColor }) => (
                <TranslatedGraphPlotMeasurementRuler
                  getLegendItemColor={getLegendItemColor}
                  graphConfig={config}
                  MouseEventChannel={Channel}
                  {...props}/>
              )}
            </LegendItemColorContext.Consumer>
          )}
        </MouseEventContext.Consumer>
      )}
    </GraphContext.Consumer>
  )
}
