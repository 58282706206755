import { flow } from 'lodash/fp/util'
import { filter, flatMap, map, some } from 'lodash/fp/collection'

import AppError from '@@src/utils/app_error'

const FAIL = 'async.fail'
const SUCCESS = 'async.success'
const PENDING = 'async.pending'
const NOT_FOUND = 'async.not_found'
const ANY_FAILURES = 'async.any_failures'
const ALL_SUCCESSFUL = 'async.all_successful'
const ANY_PENDING = 'async.any_pending'

export default class AsyncResult {
  constructor({ status, data, error, meta }) {
    Object.assign(this, { status, data, meta: meta || {}, error })
  }

  isPending() {
    return this.status === PENDING
  }

  wasNotFound() {
    return this.status === NOT_FOUND
  }

  wasSuccessful() {
    return this.status === SUCCESS
  }

  wasFailure() {
    return this.status === FAIL
  }

  mergeMeta(meta) {
    return new AsyncResult(Object.assign({}, this, {
      meta: Object.assign({}, this.meta, meta),
    }))
  }

  withMeta(meta) {
    return new AsyncResult(Object.assign({}, this, { meta }))
  }

  map(mapper) {
    return this.withData(mapper(this.data))
  }

  flatMap(mapper) {
    if (this.wasSuccessful()) {
      return AsyncResult.fromArray(mapper(this.data), this.meta)
    } else {
      return this
    }
  }

  withData(data) {
    return new AsyncResult({
      ...this,
      data,
    })
  }

  and(otherResult) {
    const statuses = [this.status, otherResult.status]

    return new AsyncResult({
      meta: { ...this.meta, ...otherResult.meta },
      data: [this.data, otherResult.data],
      error: this.error || otherResult.error,
      status:
        statuses.includes(FAIL) ? FAIL :
        statuses.includes(NOT_FOUND) ? NOT_FOUND :
        statuses.includes(PENDING) ? PENDING :
        SUCCESS,
    })
  }

  originalErrorMessages() {
    if (this.wasFailure() && this.error instanceof AppError) {
      if (this.error.isAggregate()) {
        return this.error.original.map(e => e.originalMessageError())
      }

      return [this.error.original.message]
    }

    return []
  }
}

AsyncResult.FAIL = FAIL
AsyncResult.PENDING = PENDING
AsyncResult.SUCCESS = SUCCESS
AsyncResult.NOT_FOUND = NOT_FOUND
AsyncResult.ANY_FAILURES = ANY_FAILURES
AsyncResult.ALL_SUCCESSFUL = ALL_SUCCESSFUL
AsyncResult.ANY_PENDING = ANY_PENDING

AsyncResult.success = function success(data, meta) {
  return new AsyncResult({
    data,
    meta,
    error: null,
    status: SUCCESS,
  })
}

AsyncResult.notFound = function notFound(nullObject, meta) {
  return new AsyncResult({
    data: nullObject,
    meta,
    error: null,
    status: NOT_FOUND,
  })
}

AsyncResult.pending = function pending(nullObject, meta) {
  return new AsyncResult({
    data: nullObject,
    meta,
    error: null,
    status: PENDING,
  })
}

AsyncResult.fail = function fail(error, nullObject, meta) {
  return new AsyncResult({
    data: nullObject,
    meta,
    error: AppError.from(error || 'Something bad happened'),
    status: FAIL,
  })
}

AsyncResult.combineStatus = function combineStatus(asyncResults) {
  if (some((result) => result.wasFailure())(asyncResults)) {
    return ANY_FAILURES
  }

  if (some((result) => result.wasNotFound())(asyncResults)) {
    return NOT_FOUND
  }

  if (some((result) => result.isPending())(asyncResults)) {
    return ANY_PENDING
  }

  return ALL_SUCCESSFUL
}

AsyncResult.fromArray = function fromArray(array) {
  const asyncResults =
    filter((item) => item instanceof AsyncResult)(array)

  const combinedStatus = AsyncResult.combineStatus(asyncResults)
  const newCollection = map((item) => {
    return (item instanceof AsyncResult) ? item.data : item
  })(array)

  switch (combinedStatus) {
    case AsyncResult.ALL_SUCCESSFUL: {
      return AsyncResult.success(newCollection)
    }

    case AsyncResult.ANY_FAILURES: {
      const errors = flow(
        filter(result => result.wasFailure()),
        flatMap(result => {
          return result.error.isAggregate() ? result.error.original :
            result.error
        })
      )(asyncResults)

      return AsyncResult.fail(AppError.from(errors), newCollection)
    }

    case AsyncResult.NOT_FOUND: {
      return AsyncResult.notFound(newCollection)
    }

    default:
      return AsyncResult.pending(newCollection)
  }
}
