import gql from 'graphql-tag'

export const AnomaliesTableQuery = gql`
  query GetAnomaliesTable(
    $start: String,
    $end: String, 
    $sources: [AnomalySource], 
    $pressureUnit: PressureUnit,
    $orderBy: AnomaliesSortOptions,
    $orderDirection: AnomaliesSortDirection,
    $page: Int!,
    $perPage: Int,
  ) {
    getAnomalies(
      startTime: $start
      endTime: $end
      sources: $sources
      pressureUnit: $pressureUnit
      orderBy: $orderBy
      orderDirection: $orderDirection
      pageNumber: $page
      resultsPerPage: $perPage
    ) {
      anomalies {
        startTime
        endTime
        type
        deviceId
        networkAssetId
        networkAssetReadableId
        networkAssetChannel
        duration
        avg
        min
        max
        diff
      }
      pagination {
        perPage
        pageIndex
        pageNumber
        totalPages
        totalResults
      }     
    }
  }
`
