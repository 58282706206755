import proj4 from 'proj4'

export const COORDS_FORMAT_WGS84 = 'WGS84'
export const COORDS_FORMAT_OSGB36 = 'OSGB36'
export const COORDS_FORMAT_DEFAULT = COORDS_FORMAT_WGS84

export const SUPPORTED_COORDS_FORMAT = [
  COORDS_FORMAT_WGS84,
  COORDS_FORMAT_OSGB36,
]

// Taken from https://epsg.io/
proj4.defs([
  [
    'OSGB36', // EPSG:27700
    '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.9996012717 +x_0=400000 +y_0=-100000 +ellps=airy +towgs84=446.448,-125.157,542.060,0.1502,0.2470,0.8421,-20.4894 +units=m +no_defs', // eslint-disable-line max-len
  ],
])

export function convertToWGS84(format, coords) {
  return format === COORDS_FORMAT_WGS84 ?
    coords :
    proj4(format, COORDS_FORMAT_WGS84, coords)
      .reverse()
      .map(n => Number.isFinite(n) ? n : 0)
}
