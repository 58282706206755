import React from 'react'
import { useTranslation } from 'react-i18next'
import { Input, FormGroup } from 'reactstrap'

const NetworkAssetDescriptionFormFields = ({ register }) => {
  const { t } = useTranslation('v2/network_assets/forms')
  return(
    <FormGroup tag="fieldset">
      <label className="h6">{t('text.custom_labels')}</label>
      <Input
        placeholder={t('text.seperate_commas')}
        className="mb-3"
        {...register('customLabels')} />
      <label className="h6">{t('text.comments')}</label>
      <Input
        className="mb-3"
        type="textarea"
        {...register('comments')} />
    </FormGroup>
  )
}

export default NetworkAssetDescriptionFormFields
