import moment from 'moment'
import { DAYS } from '@@src/utils/constants'
import routes from '@@src/routes'

export const linkToAnalysisEventsPage = () => {
  return routes.analysisPressurePath({
    dv: 'events',
  })
}

export const getAnalysisPathRoute = (params) => {
  const { start, end, assetId } = params

  return routes.analysisPressurePath({
    from: moment(start).toISOString(),
    to: moment(end).toISOString(),
    d: `a.${assetId}`,
  })
}

const getEventDetailRoute = (params) => {
  const { start, end, assetIds, eventIds } = params
  const assetIdsFormatted = assetIds.map((assetId) => {
    return `a.${assetId}`
  })
  return routes.analysisPressureEventsPath({
    from: moment(start).toISOString(),
    to: moment(end).toISOString(),
    d: assetIdsFormatted,
    ev: eventIds.join('+'),
    ob: 'severity',
    od: 'desc',
  })
}

export const showAllLink = (eventsResult) => {
  if (!eventsResult || !eventsResult.data) {
    return linkToAnalysisEventsPage()
  }

  const { data } = eventsResult
  if (data.length < 1) {
    return linkToAnalysisEventsPage()
  }

  const start = new Date(new Date().getTime() - DAYS * 7).toISOString()
  const end = new Date().toISOString()

  const assetIds = [...new Set(data.map((event) => event.assetId))]
  const eventIds = data.map((event) => event.eventId)

  return getEventDetailRoute({
    start,
    end,
    assetIds,
    eventIds,
  })
}

export const getAlertTimestamp = (event) => {
  const startMoment = moment(event.start)
  const timestamp = startMoment.format('YYYY-MM-DD H:mm:ss')
  return timestamp
}
