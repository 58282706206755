import React, { useState, useEffect } from 'react'

import { Link } from 'react-router-dom'
import { Col, Row } from 'reactstrap'
import classnames from 'classnames'
import routes from '@@src/routes'
import { compose } from 'redux'

import GroupTypeIcon from '@@src/components/icons/group_type_icon'
import DeviceIssueSeverityBadge from '@@src/components/badges/device_issue_severity_badge'
import EventSourceLocalisationLink from '@@src/management_path/groups_path/event_source_localisation_link'

import { useGetMostDistantGroups } from '../../../../hooks/management/groups/useGetMostDistantGroups'
import { useGetGroupMembers } from '../../../../hooks/management/groups/useGetGroupMembers'
import { withTranslation } from 'react-i18next'
import { withWindowSizes } from '../../../../components/window_size_provider'

import styles from '../group_list.css'

function GroupListItem({
  group,
  t,
  isMobile,
  isEventSourceLocalisationLicensed,
}) {
  // eslint-disable-next-line no-warning-comments
  // TODO: need to add this type and then add to scema and then make type, and make dummy data for this
  const { mostDistantGroups } = useGetMostDistantGroups(
    group,
    isEventSourceLocalisationLicensed
  )
  const { groupMembers } = useGetGroupMembers(group)

  const [currentConfig, setCurrentConfig] = useState({
    rowClassnames: '',
    groupLinkIconName: '',
    groupMembersType: '',
    mostSevereDeviceIssue: '',
    dataButtonEnabled: null,
    groupIdWithMembersIds: [],
  })

  useEffect(() => {
    const rowClassnames = classnames('d-flex flex-row', styles['group-item'])
    const groupLinkIconName = group.isGroupOfGroups()
      ? 'arrow-icon-link'
      : 'info-icon-link'

    let groupMembersType = ''
    switch (true) {
      case group.isDeviceGroup(): {
        groupMembersType = t(
          'src/management_path/groups_path/group_list:types.device',
          {
            count: group.countOfMembers,
          }
        )
        break
      }
      case group.isNetworkAssetGroup(): {
        groupMembersType = t(
          'src/management_path/groups_path/group_list:types.asset',
          {
            count: group.countOfMembers,
          }
        )
        break
      }
      case group.isGroupOfGroups(): {
        groupMembersType = t(
          'src/management_path/groups_path/group_list:types.group',
          {
            count: group.countOfMembers,
          }
        )
        break
      }
      default:
        groupMembersType = t('types.unknown')
    }

    const arrayOfIds = [group.uuid]
    groupMembers.forEach((member) => {
      arrayOfIds.push(member.uuid)
    })

    mostDistantGroups.forEach((mostDistantGroup) => {
      arrayOfIds.push(mostDistantGroup.uuid)
      if (mostDistantGroup.membersType === 'VNetworkAsset') {
        mostDistantGroup.membersIds.forEach((id) => arrayOfIds.push(`a.${id}`))
        return
      }
      if (mostDistantGroup.membersType === 'VDevice') {
        mostDistantGroup.membersIds.forEach((id) => arrayOfIds.push(`d.${id}`))
      }
    })

    const uniqueIds = new Set(arrayOfIds)
    const viewPlotDataEnabled = [...uniqueIds].some((uniqueId) => {
      return uniqueId.includes('a') || uniqueId.includes('d')
    })

    setCurrentConfig({
      rowClassnames,
      groupLinkIconName,
      mostSevereDeviceIssue: group.mostSevereDeviceIssue,
      groupMembersType,
      dataButtonEnabled: !viewPlotDataEnabled ? 'disabled' : null,
      groupIdWithMembersIds: [...uniqueIds],
    })
  }, [group, mostDistantGroups, groupMembers])

  return (
    <Row
      name="group-list-group-item"
      key={group.groupId}
      className={currentConfig.rowClassnames}
      noGutters={true}
    >
      <div
        name="group-type-icon-container"
        className="d-flex flex-row justify-content-start align-items-center
        pr-3"
      >
        <GroupTypeIcon className="fa-2x" group={group} />
      </div>

      <Col className="d-flex flex-row justify-content-start align-items-center">
        <div>
          <span className="text-muted">
            {group.constructGroupAncestryPathWithLinks()}
          </span>

          <span className="d-block">
            <Link
              to={routes.managementGroupsDetailPath(group.groupId)}
              className={classnames('mr-2', 'text-dark')}
            >
              <strong>{group.groupName}</strong>
            </Link>
            {currentConfig.mostSevereDeviceIssue ? (
              <DeviceIssueSeverityBadge
                issue={currentConfig.mostSevereDeviceIssue}
              />
            ) : null}
          </span>

          <span className="text-muted">
            {t('src/management_path/groups_path/group_list:text.group_count', {
              count: group.countOfMembers,
              type: currentConfig.groupMembersType,
            })}
          </span>
        </div>
      </Col>

      <div className="d-flex flex-row justify-content-end align-items-center">
        {!isMobile ? (
          <EventSourceLocalisationLink
            group={group}
            mostDistantAssetGroups={mostDistantGroups}
            groupIdWithMembersIds={currentConfig.groupIdWithMembersIds}
          />
        ) : null}
        {!isMobile ? (
          <div
            className="position-relative d-inline-flex align-middle"
            data-tip={t(
              'src/analysis_path/pressure_analysis_path:text.no_data_sources_found'
            )}
          >
            <Link
              name="analysis-view-link"
              to={routes.analysisPathForGroupList(
                currentConfig.groupIdWithMembersIds
              )}
              className={classnames(
                styles['action-button'],
                currentConfig.dataButtonEnabled,
                'mr-3 btn-light btn'
              )}
            >
              <i className="fa fa-chart-area" />
            </Link>
          </div>
        ) : null}
        <Link
          name={currentConfig.groupLinkIconName}
          to={routes.managementGroupsDetailPath(group.groupId)}
          className={classnames('btn btn-light', styles['action-button'])}
        >
          {group.isGroupOfGroups() ? (
            <i className="fa fa-arrow-right" />
          ) : (
            <i className="fa fa-info" />
          )}
        </Link>
      </div>
    </Row>
  )
}

export default compose(
  withTranslation([
    'src/management_path/groups_path/event_source_localisation_link',
    'src/management_path/groups_path/group_list',
    'src/analysis_path/index_page',
    'common/text',
  ]),
  withWindowSizes
)(GroupListItem)
