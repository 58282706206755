import React from 'react'
import PropTypes from 'prop-types'
import { difference } from 'lodash/fp/array'
import { withTranslation } from 'react-i18next'
import { FormGroup, Label, Input } from 'reactstrap'
import { noop } from 'lodash/fp/util'

import { validGroupCategories } from '@@src/utils'

class CategoriesFilters extends React.PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    selection: PropTypes.array.isRequired,
    onChangeSelection: PropTypes.func,
  }

  static defaultProps = {
    selection: [],
    onChangeSelection: noop,
  }

  render() {
    const { t, selection } = this.props

    return (
      <FormGroup tag="fieldset">
        <legend className="h6">{t('headings.categories')}</legend>

        {
          validGroupCategories().map(category => (
            <FormGroup check key={category}>
              <Label check>
                <Input
                  checked={selection.includes(category)}
                  onChange={this.onChangeItem}
                  type="checkbox"
                  name={category} />
                &nbsp;
                {t(`common/text:text.category_${category}`)}
              </Label>
            </FormGroup>
          ))
        }
      </FormGroup>
    )
  }

  onChangeItem = event => {
    const { selection, onChangeSelection } = this.props
    const value = event.target.name

    const newSelection = selection.includes(value) ?
      difference(selection, [value]) : selection.concat(value)

    onChangeSelection(newSelection, value)
  }
}

export default withTranslation([
  'src/management_path/groups_path/index_page/categories_filters',
  'common/text',
])(CategoriesFilters)
