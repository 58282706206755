import { graphql } from '@apollo/client/react/hoc'
import classnames from 'classnames'
import gql from 'graphql-tag'
import { get } from 'lodash/fp/object'
import PropTypes from 'prop-types'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { Button, Col, InputGroup, InputGroupText, Row } from 'reactstrap'
import { compose } from 'redux'
import { createSelector } from 'reselect'

import { parseGraphQLResult } from '@@src/api/presenters'
import DeviceIssue from '@@src/api/presenters/device_issue'
import { MixedGroupDetailsType } from '@@src/api/presenters/group'
import ActiveFiltersAlert from '@@src/components/alerts/active_filters_alert'
import defineQueryParams from '@@src/components/define_query_params'
import AppFilterDropdown from '@@src/components/dropdowns/app_filter_dropdown'
import DropdownGroup from '@@src/components/dropdowns/dropdown_group'
import DebouncedInput from '@@src/components/forms/debounced_input'
import AsyncList from '@@src/components/lists/async_list'
import {
  ADVANCED_ERROR_FILTERS,
  ADVANCED_INFORMATION_FILTERS,
  ADVANCED_WARNING_FILTERS,
} from '@@src/components/modals/device_issue_filters'
import MoveMembersModal from '@@src/components/modals/move_groups_modal/move_members_modal'
import getWithPagination from '@@src/components/pagination/pagination_container'
import PaginationToolbar from '@@src/components/pagination/pagination_toolbar'
import getSortDropdown from '@@src/components/sort_component/sort_component'
import { EVENT_LOCALISATION_FEATURE } from '@@src/components/tenant_licence/tenant_licence_feature_gate'
import transformProps from '@@src/components/transform_props'
import { createSelectGraphQLResult, getResultString } from '@@src/utils'
import AsyncResult from '@@src/utils/async_result'
import DebouncedInputController from '@@src/utils/debounced_input_controller'
import { CAN_VIEW_GROUPS } from '../../../_v2/contexts/user/consts/permissions'
import { withUser } from '../../../_v2/contexts/user/user.context'
import { AuthorizedUser } from '../../../_v2/models/authorized-user'
import AdvancedFilterOptionsModal from '../index_page/advanced_filter_options_modal'
import GroupListItem from './group_list_item/group_list_item'

import styles from './group_list.css'

export const VISIBLE_GROUPS_PAGES = 3
export const SEED_GROUPS_PAGE_NUMBER = 1
export const SEED_GROUPS_RESULTS_PER_PAGE = 10

export const ORDER_BY_ACTIVE_ISSUE_TIME = 'recent_issue_time'
export const ORDER_BY_ISSUE_TYPE_SEVERITY = 'severity'
export const ORDER_BY_GROUP_ID = 'id'
export const ORDER_BY_GROUP_NAME = 'name'

const SortDropdown = getSortDropdown()

class GroupList extends React.PureComponent {
  static propTypes = {
    authorizedUser: PropTypes.instanceOf(AuthorizedUser).isRequired,
    location: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    setPageNumber: PropTypes.func.isRequired,
    setResultsPerPage: PropTypes.func.isRequired,
    dispatchUpdateQueryParams: PropTypes.func.isRequired,
    displayDeviceIssueSortAndFilter: PropTypes.bool.isRequired,
    refetchGroupsData: PropTypes.func.isRequired,
    legacyGroupsFilter: PropTypes.string,
    ancestor: PropTypes.instanceOf(MixedGroupDetailsType),
    newlyCreatedGroupId: PropTypes.number,
  }

  static defaultProps = {
    pagedGroupsResult: AsyncResult.pending([]),
    groupsOrder: ORDER_BY_GROUP_NAME,
    groupsOrderDirection: 'asc',
    displayDeviceIssueSortAndFilter: true,
  }

  constructor(props) {
    super(props)

    this.state = {
      isCreateGroupModalOpen: false,
      searchInputController: new DebouncedInputController(),
      isAdvancedFilterModalOpen: false,
      isMoveGroupsModalOpen: false,
    }
  }

  componentDidMount() {
    if (this.props.legacyGroupsFilter) {
      const filters = this.props.legacyGroupsFilter.slice(0)
      const groupTypesFilter = []
      const groupTypes = ['VNetworkAsset', 'VDevice', 'VGroup']

      groupTypes.forEach((item) => {
        if (filters.includes(item)) {
          filters.splice(filters.indexOf(item), 1)
          groupTypesFilter.push(item)
        }
      })

      this.props.dispatchUpdateQueryParams(this.props, {
        groupTypesFilter,
        deviceIssuesFilter: filters,
        legacyGroupsFilter: undefined,
      })
    }
  }

  componentDidUpdate = (prevProps) => {
    const { newlyCreatedGroupId, refetchGroupsData } = this.props
    if (prevProps.newlyCreatedGroupId !== newlyCreatedGroupId) {
      refetchGroupsData()
    }
  }

  render() {
    const {
      t,
      pagedGroupsResult,
      refetchGroupsData,
      groupsOrder,
      groupsQuery,
      groupsOrderDirection,
      groupTypesFilter,
      resultsPerPage,
      setPageNumber,
      categoriesFilter,
      setResultsPerPage,
      deviceIssuesFilter,
      authorizedUser,
      displayDeviceIssueSortAndFilter,
      ancestor,
      isMobileDevice,
      isEventSourceLocalisationLicensed,
    } = this.props

    // Does the user have permissions for this page?
    if (!authorizedUser.can(CAN_VIEW_GROUPS)) {
      return <Redirect to="/page-not-found" />
    }

    const {
      searchInputController,
      isAdvancedFilterModalOpen,
      isMoveGroupsModalOpen,
    } = this.state
    const sortList = this.selectSortList(this.props)

    const orderBy = this.getOrderNameForOrderType(sortList, groupsOrder)
    const orderDir =
      groupsOrderDirection === 'desc' ? t('text.desc') : t('text.asc')

    const resultsString = getResultString(t, pagedGroupsResult, resultsPerPage)

    const orderingString = `${t('text.order_by', {
      orderDir,
      orderBy,
    })}`

    return (
      <React.Fragment>
        <AdvancedFilterOptionsModal
          displayDeviceIssuesFilters={displayDeviceIssueSortAndFilter}
          isOpen={isAdvancedFilterModalOpen}
          toggle={this.handleAdvancedFilterToggle}
          onAcceptChanges={this.onAcceptAdvancedFiltersChanges}
          groupTypesFilter={groupTypesFilter}
          categoriesFilter={categoriesFilter}
          deviceIssuesFilter={deviceIssuesFilter}
        />

        <Row
          className="d-flex mb-3 justify-content-between align-items-center"
          noGutters={true}
        >
          <Col sm="auto">
            <div className={styles['search-results-text']}>
              {pagedGroupsResult.isPending()
                ? t('text.results_pending')
                : resultsString}
            </div>

            <div className={styles['ordering-text']}>
              {pagedGroupsResult.isPending() ? '' : orderingString}
            </div>
          </Col>

          <div className="d-flex align-items-start justify-content-end">
            <Button
              className="mr-2"
              name="clear-filters-button"
              color="secondary"
              outline
              onClick={this.onClearFilters}
            >
              {t('buttons.clear_filters')}
            </Button>

            <InputGroup className={styles['search-input']}>
              <DebouncedInput
                type="search"
                name="groups-search-input"
                debounce={250}
                onChange={this.onChangeGroupsQuery}
                controller={searchInputController}
                initialValue={groupsQuery}
                placeholder={t('text.search_placeholder')}
              />

              <InputGroupText>
                <i className="fa fa-search" />
              </InputGroupText>
            </InputGroup>

            <AppFilterDropdown
              dropdownToggleClassname="mr-2"
              clearFiltersButton
              name="filter-input"
              selection={groupTypesFilter}
              advancedOption={{
                partial: this.hasAnyAdvancedFilterOptions(),
                onClick: this.handleAdvancedFilterToggle,
              }}
              onClearFilters={this.onClearDropdownFilters}
              onChangeSelection={this.onChangeFilterSelection}
            >
              <AppFilterDropdown.Item header>
                {t('text.group_content_filters_heading')}
              </AppFilterDropdown.Item>
              <AppFilterDropdown.Item value="VDevice">
                {t('text.device')}
              </AppFilterDropdown.Item>
              <AppFilterDropdown.Item value="VNetworkAsset">
                {t('text.asset')}
              </AppFilterDropdown.Item>
              <AppFilterDropdown.Item value="VGroup">
                {t('text.group')}
              </AppFilterDropdown.Item>

              {displayDeviceIssueSortAndFilter ? (
                <DropdownGroup
                  selection={deviceIssuesFilter}
                  onChangeSelection={this.onChangeIssuesFilterSelection}
                >
                  <AppFilterDropdown.Item header>
                    {t('text.group_issues_filters_heading')}
                  </AppFilterDropdown.Item>

                  <DropdownGroup.Item
                    value={DeviceIssue.ERROR_SEVERITY_FILTER}
                    partial={this.hasAnyAdvancedErrorFilterOption()}
                  >
                    {t('text.group_filter_errors')}
                  </DropdownGroup.Item>

                  <DropdownGroup.Item
                    value={DeviceIssue.WARNING_SEVERITY_FILTER}
                    partial={this.hasAnyAdvancedWarningFilterOption()}
                  >
                    {t('text.group_filter_warnings')}
                  </DropdownGroup.Item>

                  <DropdownGroup.Item
                    value={DeviceIssue.INFORMATION_SEVERITY_FILTER}
                    partial={this.hasAnyAdvancedInformationFilterOption()}
                  >
                    {t('text.group_filter_info')}
                  </DropdownGroup.Item>
                </DropdownGroup>
              ) : null}
            </AppFilterDropdown>

            <SortDropdown
              name="sort-input"
              options={sortList}
              onChange={this.onChangeGroupsSort}
              isDescending={groupsOrderDirection === 'desc'}
              value={groupsOrder}
            />

            {!isMobileDevice ? (
              <React.Fragment>
                <MoveMembersModal
                  onMoveGroups={refetchGroupsData}
                  group={ancestor}
                  handleToggle={this.handleToggleMoveGroupsModal}
                  isOpen={isMoveGroupsModalOpen}
                />
                <Button
                  name="move-members-toggle-button"
                  className="ml-2"
                  onClick={this.handleToggleMoveGroupsModal}
                >
                  <span className="far fa-arrow-right" />
                  <span
                    className={classnames(
                      'ml-2',
                      styles['movegroups-button-text'],
                    )}
                  >
                    {t('buttons.move_groups')}
                  </span>
                </Button>
              </React.Fragment>
            ) : null}
          </div>
        </Row>

        <ActiveFiltersAlert
          activeFilters={groupTypesFilter.concat(
            categoriesFilter,
            deviceIssuesFilter,
          )}
        />

        <AsyncList
          result={pagedGroupsResult}
          name="group-list"
          className={styles['groups-list']}
          renderItem={(group) => (
            <GroupListItem
              name="group-list-item"
              group={group}
              key={group.groupId}
              isEventSourceLocalisationLicensed={
                isEventSourceLocalisationLicensed
              }
            />
          )}
          noResultText={t('text.no_groups_found')}
          onRequestRetry={this.handleRequestRetry}
        />
        {pagedGroupsResult.wasSuccessful() ? (
          <PaginationToolbar
            visiblePageOptionCount={VISIBLE_GROUPS_PAGES}
            pageNumber={pagedGroupsResult.data.pageNumber}
            totalPages={pagedGroupsResult.data.totalPages}
            totalResults={pagedGroupsResult.data.totalResults}
            resultsPerPage={resultsPerPage}
            setPageNumber={setPageNumber}
            setResultsPerPage={setResultsPerPage}
          />
        ) : null}
      </React.Fragment>
    )
  }

  getOrderNameForOrderType = (sortList, sortValue) => {
    const sortItem = sortList.filter((item) => {
      return item.value === sortValue
    })
    return sortItem.length < 1 ? '' : sortItem[0].text.toLowerCase()
  }

  handleAdvancedFilterToggle = () => {
    this.setState({
      isAdvancedFilterModalOpen: !this.state.isAdvancedFilterModalOpen,
    })
  }

  onAcceptAdvancedFiltersChanges = (filters) => {
    const { deviceIssuesFilter, groupTypesFilter, categoriesFilter } = filters

    this.props.dispatchUpdateQueryParams(this.props, {
      categoriesFilter,
      groupTypesFilter,
      deviceIssuesFilter,
    })
  }

  onClearFilters = (event) => {
    event.preventDefault()
    const { searchInputController } = this.state

    this.props.dispatchClearQueryParams(this.props)
    searchInputController.dispatchClearValueEvent()
  }

  onChangeGroupsQuery = (event) => {
    this.props.setPageNumber(1)
    this.props.dispatchUpdateQueryParams(this.props, {
      groupsQuery: event.target.value,
    })
  }

  hasAnyAdvancedFilterOptions() {
    const { categoriesFilter } = this.props

    return (
      this.hasAnyAdvancedErrorFilterOption() ||
      this.hasAnyAdvancedWarningFilterOption() ||
      this.hasAnyAdvancedInformationFilterOption() ||
      categoriesFilter.length > 0
    )
  }

  onClearDropdownFilters = () => {
    this.props.dispatchUpdateQueryParams(this.props, {
      categoriesFilter: [],
      groupTypesFilter: [],
      deviceIssuesFilter: [],
    })
  }

  onChangeFilterSelection = (newSelection) => {
    this.props.setPageNumber(1)
    this.props.dispatchUpdateQueryParams(this.props, {
      groupTypesFilter: newSelection,
    })
  }

  onChangeIssuesFilterSelection = (newIssuesFilter) => {
    this.props.setPageNumber(1)

    const { deviceIssuesFilter } = this.props

    let issuesFilter = [...newIssuesFilter]
    if (issuesFilter.includes(DeviceIssue.ERROR_SEVERITY_FILTER)) {
      issuesFilter = issuesFilter.filter(
        (x) => !DeviceIssue.ERROR_SEVERITY_FILTER_COLLECTION.includes(x),
      )

      if (!deviceIssuesFilter.includes(DeviceIssue.ERROR_SEVERITY_FILTER)) {
        issuesFilter = issuesFilter.filter(
          (x) =>
            x !== DeviceIssue.WARNING_SEVERITY_FILTER &&
            x !== DeviceIssue.INFORMATION_SEVERITY_FILTER,
        )
      }
    }
    if (issuesFilter.includes(DeviceIssue.WARNING_SEVERITY_FILTER)) {
      issuesFilter = issuesFilter.filter(
        (x) => !DeviceIssue.WARNING_SEVERITY_FILTER_COLLECTION.includes(x),
      )

      if (!deviceIssuesFilter.includes(DeviceIssue.WARNING_SEVERITY_FILTER)) {
        issuesFilter = issuesFilter.filter(
          (x) =>
            x !== DeviceIssue.ERROR_SEVERITY_FILTER &&
            x !== DeviceIssue.INFORMATION_SEVERITY_FILTER,
        )
      }
    }
    if (issuesFilter.includes(DeviceIssue.INFORMATION_SEVERITY_FILTER)) {
      issuesFilter = issuesFilter.filter(
        (x) => !DeviceIssue.INFORMATION_SEVERITY_FILTER_COLLECTION.includes(x),
      )

      if (
        !deviceIssuesFilter.includes(DeviceIssue.INFORMATION_SEVERITY_FILTER)
      ) {
        issuesFilter = issuesFilter.filter(
          (x) =>
            x !== DeviceIssue.ERROR_SEVERITY_FILTER &&
            x !== DeviceIssue.WARNING_SEVERITY_FILTER,
        )
      }
    }

    this.props.dispatchUpdateQueryParams(this.props, {
      deviceIssuesFilter: issuesFilter,
    })
  }

  hasAnyAdvancedErrorFilterOption() {
    const { deviceIssuesFilter } = this.props

    return ADVANCED_ERROR_FILTERS.some((i) => deviceIssuesFilter.includes(i))
  }

  hasAnyAdvancedWarningFilterOption() {
    const { deviceIssuesFilter } = this.props

    return ADVANCED_WARNING_FILTERS.some((i) => deviceIssuesFilter.includes(i))
  }

  hasAnyAdvancedInformationFilterOption() {
    const { deviceIssuesFilter } = this.props

    return ADVANCED_INFORMATION_FILTERS.some((i) =>
      deviceIssuesFilter.includes(i),
    )
  }

  onChangeGroupsSort = (orderBy) => {
    const { groupsOrder, groupsOrderDirection } = this.props

    let newOrderDirection = groupsOrderDirection
    if (orderBy === groupsOrder) {
      newOrderDirection = newOrderDirection === 'desc' ? 'asc' : 'desc'
    } else {
      newOrderDirection = 'asc' // default to ascending on change of orderBy
    }

    this.props.dispatchUpdateQueryParams(this.props, {
      groupsOrder: orderBy,
      groupsOrderDirection: newOrderDirection,
    })
  }

  handleToggleMoveGroupsModal = () => {
    this.setState({ isMoveGroupsModalOpen: !this.state.isMoveGroupsModalOpen })
  }

  handleRequestRetry = () => {
    this.props.refetchGroupsData()
  }

  selectSortList = createSelector(
    [get('t'), get('displayDeviceIssueSortAndFilter')],
    (t, displayDeviceIssueSortAndFilter) => {
      const sortList = [
        { value: ORDER_BY_GROUP_ID, text: t('options.created') },
        { value: ORDER_BY_GROUP_NAME, text: t('options.name') },
      ]

      if (displayDeviceIssueSortAndFilter) {
        sortList.push(
          {
            value: ORDER_BY_ACTIVE_ISSUE_TIME,
            text: t('options.latest_issues'),
          },
          {
            value: ORDER_BY_ISSUE_TYPE_SEVERITY,
            text: t('common/text:options.issue_type_severity'),
          },
        )
      }

      return sortList
    },
  )

  static GROUPS_QUERY = gql`
    query GetGroups(
      $issuesTypes: [String]
      $membersTypes: [String]
      $categories: [String]
      $search: String
      $pageNumber: Int!
      $resultsPerPage: Int!
      $parentGroupId: Int
      $orderBy: String
      $orderDirection: String
      $withErrors: Boolean!
      $withInformative: Boolean!
      $withWarnings: Boolean!
      $isEventSourceLocalisationLicensed: Boolean!
    ) {
      getGroups(
        issuesTypes: $issuesTypes
        search: $search
        pageNumber: $pageNumber
        categories: $categories
        membersTypes: $membersTypes
        resultsPerPage: $resultsPerPage
        parentGroupId: $parentGroupId
        orderBy: $orderBy
        orderDirection: $orderDirection
        withErrors: $withErrors
        withInformative: $withInformative
        withWarnings: $withWarnings
      ) {
        pagination {
          perPage
          pageNumber
          pageIndex
          totalPages
          totalResults
        }
        groups {
          groupId
          groupName
          hasErrors
          hasWarnings
          hasInformative
          membersType
          membersCount
          unreadEventSourceLocalisationsCount
          @include(if: $isEventSourceLocalisationLicensed)
          lastUnreadEventSourceLocalisationDate
          @include(if: $isEventSourceLocalisationLicensed)
          firstUnreadEventSourceLocalisationDate
          @include(if: $isEventSourceLocalisationLicensed)
        }
      }
    }
  `
}

function createMapStateToProps() {
  const selectPagedGroupsResult = createSelectGraphQLResult('getGroups', {
    mapResult: parseGraphQLResult,
    nullObject: [],
  })

  const selectGroupsQueryVariables = createSelector(
    [
      get('groupsQuery'),
      get('deviceIssuesFilter'),
      get('pageNumber'),
      get('resultsPerPage'),
      get('groupsOrder'),
      get('categoriesFilter'),
      get('groupTypesFilter'),
      get('groupsOrderDirection'),
      get('ancestor'),
    ],
    (
      groupsQuery,
      deviceIssuesFilter,
      pageNumber,
      resultsPerPage,
      groupsOrder,
      categoriesFilter,
      groupTypesFilter,
      groupsOrderDirection,
      ancestor,
    ) => {
      const ancestorId = ancestor ? ancestor.id : undefined
      const showOnlyGroupsWithNoParent =
        deviceIssuesFilter.length === 0 &&
        categoriesFilter.length === 0 &&
        groupTypesFilter.length === 0 &&
        groupsQuery === undefined &&
        ancestorId === undefined

      const issuesTypes = deviceIssuesFilter.filter(
        (filter) =>
          filter !== DeviceIssue.ERROR_SEVERITY_FILTER &&
          filter !== DeviceIssue.WARNING_SEVERITY_FILTER &&
          filter !== DeviceIssue.INFORMATION_SEVERITY_FILTER,
      )
      const withErrors = deviceIssuesFilter.includes(
        DeviceIssue.ERROR_SEVERITY_FILTER,
      )
      const withWarnings = deviceIssuesFilter.includes(
        DeviceIssue.WARNING_SEVERITY_FILTER,
      )
      const withInformative = deviceIssuesFilter.includes(
        DeviceIssue.INFORMATION_SEVERITY_FILTER,
      )
      const parentGroupId = showOnlyGroupsWithNoParent ? null : ancestorId

      return {
        issuesTypes,
        categories: categoriesFilter,
        membersTypes: groupTypesFilter,
        search: groupsQuery,
        orderBy: groupsOrder,
        orderDirection: groupsOrderDirection,
        pageNumber,
        resultsPerPage,
        withErrors,
        withWarnings,
        withInformative,
        parentGroupId,
      }
    },
  )

  return function mapStateToProps(state) {
    // todo: tenantLicenceFeatures
    const tenantLicenceFeatures = state?.data?.tenantLicenceFeatures || []
    const isEventSourceLocalisationLicensed = tenantLicenceFeatures.includes(EVENT_LOCALISATION_FEATURE)

    return {
      isEventSourceLocalisationLicensed,
      selectGroupsQueryVariables,
      selectPagedGroupsResult,
    }
  }
}

export default compose(
  withUser,
  connect(createMapStateToProps),
  withTranslation([
    'src/management_path/groups_path/group_list',
    'common/text',
  ]),
  getWithPagination({
    pageNumber: SEED_GROUPS_PAGE_NUMBER,
    resultsPerPage: SEED_GROUPS_RESULTS_PER_PAGE,
  }),
  defineQueryParams({
    groupsOrder: { alias: 'ob' },
    groupsQuery: { alias: 'q' },
    categoriesFilter: { alias: 'gc', parseFunc: defineQueryParams.parseArray },
    groupTypesFilter: { alias: 'gt', parseFunc: defineQueryParams.parseArray },
    deviceIssuesFilter: {
      alias: 'gi',
      parseFunc: defineQueryParams.parseArray,
    },
    legacyGroupsFilter: {
      alias: 't',
      parseFunc: (s) => (s ? String(s).split('+') : ''),
    },
    groupsOrderDirection: { alias: 'od' },
  }),
  transformProps(
    () =>
      ({
        displayDeviceIssueSortAndFilter,
        deviceIssuesFilter,
        groupsOrder,
        ...rest
      }) => {
        if (displayDeviceIssueSortAndFilter === false) {
          return {
            ...rest,
            deviceIssuesFilter: [],
            groupsOrder:
              groupsOrder !== ORDER_BY_ISSUE_TYPE_SEVERITY &&
              groupsOrder !== ORDER_BY_ACTIVE_ISSUE_TIME
                ? groupsOrder
                : ORDER_BY_GROUP_NAME,
          }
        } else {
          return {
            deviceIssuesFilter,
            groupsOrder,
            ...rest,
          }
        }
      },
  ),
  graphql(GroupList.GROUPS_QUERY, {
    options: ({
      selectGroupsQueryVariables,
      isEventSourceLocalisationLicensed,
      ...rest
    }) => {
      const variables = selectGroupsQueryVariables(rest)
      return {
        fetchPolicy: 'network-only',
        variables: {
          ...variables,
          isEventSourceLocalisationLicensed,
        },
      }
    },
    props: ({ data, ownProps: { selectPagedGroupsResult } }) => {
      return {
        refetchGroupsData: (...args) => data.refetch(...args),
        pagedGroupsResult: selectPagedGroupsResult(data),
      }
    },
  }),
)(GroupList)
