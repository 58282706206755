import {
  COORDS_FORMAT_OSGB36,
  COORDS_FORMAT_WGS84,
  convertToWGS84,
} from '../../../../../utils/conversions/coordinate_conversions'
import GeoCoordinate from '../../../../../utils/graphs/geo_coordinate'

export const adjustRegister = (register) => (name = '', options = {}) => {
  const { ref, ...rest } = register(name, options)
  return { innerRef: ref, ...rest }
}

export const validateCoordinates = (coordinatesType, latitude, longitude) => {
  switch(coordinatesType) {
    case COORDS_FORMAT_WGS84: {
      if (Math.abs(latitude) <= 90 && Math.abs(longitude) <= 180) {
        const normalizedCoords = convertToWGS84(COORDS_FORMAT_WGS84, [latitude, longitude])
        return new GeoCoordinate(normalizedCoords[0], normalizedCoords[1])
      }
      return null
    }
    case COORDS_FORMAT_OSGB36: {
      if (
        latitude < -90619.29 ||
        latitude > 612435.55 ||
        longitude < 10097.13 ||
        longitude > 1234954.16
      ) {
        const normalizedCoords = convertToWGS84(COORDS_FORMAT_OSGB36, [latitude, longitude])
        return new GeoCoordinate(normalizedCoords[0], normalizedCoords[1])
      }
      return null
    }
    default: {
      return null
    }
  }
}
