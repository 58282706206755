import React, { createRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import GeoBounds from '../../../../utils/graphs/geo_bounds'
import GeoCoordinate from '../../../../utils/graphs/geo_coordinate'

const MAP_STABILIZATION_TIME = 1000

const BaseMapControllerComponent = ({ children, onPositionChange, mapRef }) => {
  const [mapIsReadyFlag, setMapIsReadyFlag] = useState(false)
  const containerRef = createRef()

  useEffect(() => {
    if(mapRef.current) {
      let timeout = null
      mapRef.current.leafletElement.invalidateSize()
      mapRef.current.leafletElement.whenReady(() => {
        timeout = setTimeout(() => {
          setMapIsReadyFlag(true)
          timeout = null
        }, MAP_STABILIZATION_TIME)
      })
      return () => timeout && clearTimeout(timeout)
    }
    return () => setMapIsReadyFlag(false)
  }, [mapRef.current])

  useEffect(() => {
    if(mapIsReadyFlag && mapRef.current) {
      const updatePosition = (mapEvent) => onPositionChange({
        zoom: mapEvent.target.getZoom(),
        center: GeoCoordinate.fromLatLng(mapEvent.target.getCenter()),
        bounds: GeoBounds.fromLatLngBounds(mapEvent.target.getBounds()),
      })

      mapRef.current.leafletElement.on('moveend', updatePosition)
      mapRef.current.leafletElement.invalidateSize()
      return () => {
        if (onPositionChange) {
          mapRef.current && mapRef.current.leafletElement.off('moveend', updatePosition)
        }
      }
    }
    return () => null
  }, [onPositionChange, mapRef.current, mapIsReadyFlag])

  return (
    <div className="position-absolute w-100 h-100" ref={containerRef}>
      {children || null}
    </div>
  )
}

BaseMapControllerComponent.propTypes = {
  mapRef: PropTypes.shape({ current: PropTypes.object }),
  children: PropTypes.node,
  onPositionChange: PropTypes.func,
}

export default BaseMapControllerComponent
