import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { Modal, ModalBody, ModalFooter, Button, ModalHeader } from 'reactstrap'
import { noop } from 'lodash/fp/util'

import ErrorInfo from '@@src/components/error_info'
import SubmitButton from '@@src/components/buttons/submit_button'
import { AsyncResult } from '@@src/utils'

class ConfirmationModal extends React.PureComponent {
  static propTypes = {
    renderConfirmationContent: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    t: PropTypes.func.isRequired,
    handleToggle: PropTypes.func.isRequired,
    handleConfirm: PropTypes.func.isRequired,
    result: PropTypes.instanceOf(AsyncResult).isRequired,
    isDisabled: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    submitText: PropTypes.string,
    children: PropTypes.node,
  }

  state = {
    isConfirming: false,
    isOpen: false,
    isDisabled: false,
    handleToggle: noop,
    handleConfirm: noop,
  }

  render() {
    const {
      isOpen, t, children, result, isDisabled, title, renderConfirmationContent,
      submitText,
    } = this.props
    const { isConfirming } = this.state

    return (
      <Modal
        isOpen={isOpen}
        toggle={this.handleToggle}>
        <ModalHeader toggle={this.handleToggle}>
          {title}
        </ModalHeader>
        <ModalBody>
          { result.wasFailure() ? <ErrorInfo error={result.error}/> : null }
          {
            isConfirming ?
              renderConfirmationContent() :
              children
          }
        </ModalBody>
        <ModalFooter>
          {
            isConfirming ?
              <React.Fragment>
                <Button
                  name="cancel-confirm-button"
                  className="mr-2"
                  onClick={this.handleCancelConfirmClick}>
                  {t('button.cancel')}
                </Button>
                <SubmitButton
                  name="confirm-button"
                  result={result}
                  color="danger"
                  disabled={isDisabled}
                  submitText={t('button.confirm')}
                  onSubmitForm={this.handleConfirm} />
              </React.Fragment>
              :
              <React.Fragment>
                <Button
                  name="cancel-button"
                  className="mr-2"
                  onClick={this.handleToggle}>
                  {t('button.cancel')}
                </Button>
                <Button
                  disabled={isDisabled}
                  name="submit-button"
                  className="mr-2"
                  color="primary"
                  onClick={this.handleSubmitClick}>
                  {submitText ? submitText : t('button.submit')}
                </Button>
              </React.Fragment>
          }
        </ModalFooter>
      </Modal>
    )
  }

  handleToggle = () => {
    this.props.handleToggle()

    this.setState({ isConfirming: false })
  }

  handleSubmitClick = () => {
    this.setState({ isConfirming: true })
  }

  handleConfirm = async (...args) => {
    await this.props.handleConfirm(...args)

    this.setState({ isConfirming: false })
  }

  handleCancelConfirmClick = () => {
    this.setState({ isConfirming: false })
  }
}

export default withTranslation([
  'src/components/modals/confirmation_modal',
])(ConfirmationModal)
