import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { select } from 'd3-selection'
import { axisLeft } from 'd3-axis'

import GraphContext, { GraphConfig }
from '@@src/components/graphs/graph_context'

import styles from './left_axis.css'

export default function LeftAxisContainer(props) {
  return (
    <GraphContext.Consumer>
      {config => <LeftAxis graphConfig={config} {...props}/> }
    </GraphContext.Consumer>
  )
}

class LeftAxis extends React.PureComponent {
  static defaultProps = {
    withGuides: false,
    tickPadding: 10,
  }

  static propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    withGuides: PropTypes.bool,
    tickValues: PropTypes.array,
    graphConfig: PropTypes.instanceOf(GraphConfig).isRequired,
    tickPadding: PropTypes.number.isRequired,
    tickFormatter: PropTypes.func,
  }

  axisRef = React.createRef()
  guidesRef = React.createRef()

  render() {
    const { name, graphConfig, withGuides } = this.props

    const leftOffset = graphConfig.leftPadding

    return (
      <g
        name={name || 'left-axis-layer'}
        transform={`translate(${leftOffset}, 0)`}>
        {
          withGuides ? (
            <g
              ref={this.guidesRef}
              name="left-axis-guides-layer"
              className={styles['guides-layer']}/>
          ) : null
        }

        <g
          ref={this.axisRef}
          name="left-axis-ticks-layer"
          className={styles['ticks-layer']}/>

        {this.renderLabel()}
      </g>
    )
  }

  renderLabel() {
    const { label, graphConfig } = this.props

    const labelOffset = graphConfig.topPadding - 20

    if (typeof label === 'string') {
      return (
        <text
          className={styles.label}
          transform={`translate(0, ${labelOffset})`}>
          {label}
        </text>
      )
    } else if (label) {
      return React.cloneElement(label, {
        className: classnames(styles.label, label.props.className),
        transform: `translate(0, ${labelOffset})`,
      })
    } else {
      return null
    }
  }

  componentDidUpdate() {
    this.renderAxis()
  }

  componentDidMount() {
    this.renderAxis()
  }

  renderAxis() {
    const {
      tickPadding, tickValues, tickFormatter, graphConfig, withGuides,
    } = this.props

    let axisGenerator = axisLeft(graphConfig.yScale)
      .tickPadding(tickPadding)

    if (tickFormatter) {
      axisGenerator = axisGenerator.tickFormat(tickFormatter)
    }

    if (tickValues) {
      axisGenerator = axisGenerator.tickValues(tickValues)
    }

    select(this.axisRef.current).call(axisGenerator)

    if (withGuides) {
      select(this.guidesRef.current).call(
        axisGenerator.tickFormat('').tickSize(-graphConfig.plotAreaWidth)
      )
    }
  }
}
