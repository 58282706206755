import React, { useCallback, useEffect, useState } from 'react'
import { Button, Col, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import DevicesAdvancedFilterModalSubgroup from './devices_advanced_filter_modal_subgroup.component'
import { useTranslation } from 'react-i18next'

import { toggleArrayElement } from '../../../../../utils'
import {
  ADVANCED_ERROR_FILTERS,
  ADVANCED_INFORMATION_FILTERS,
  ADVANCED_WARNING_FILTERS,
} from '../../../../../../components/modals/device_issue_filters'
import { NETWORK_ASSET_FILTERS } from '../../../../../../components/modals/network_asset_filters'
import { DEVICE_STATE_FILTERS } from '../../../../../../components/modals/device_state_filters'

import PropTypes from 'prop-types'

const DevicesListAdvancedFilterModal = ({
  isOpen,
  toggle,
  activeFilterList = [],
  handleAccept = () => null,
  clearFilters = () => null,
}) => {
  const [activeFiltersCopy, setActiveFiltersCopy] = useState([...activeFilterList])

  useEffect(() => {
    setActiveFiltersCopy(activeFilterList)
  }, [activeFilterList])

  const { t } = useTranslation([
    'src/components/modals/device_issue_filters',
    'v2/common/buttons',
  ])

  const handleOnChangeAdvancedFilter = (filter) => {
    setActiveFiltersCopy(oldValues => {
      if(NETWORK_ASSET_FILTERS.includes(filter)) {
        if(activeFiltersCopy.includes(filter)) {
          return toggleArrayElement(activeFiltersCopy, filter)
        }
        const clearedInstallationType = NETWORK_ASSET_FILTERS.reduce((acc, type) => {
          return activeFiltersCopy.includes(type) ? toggleArrayElement(acc, type) : acc
        }, activeFiltersCopy)
        return toggleArrayElement(clearedInstallationType, filter)
      }
      if(DEVICE_STATE_FILTERS.includes(filter)) {
        if(activeFiltersCopy.includes(filter)) {
          return toggleArrayElement(activeFiltersCopy, filter)
        }
        const clearedStateType = DEVICE_STATE_FILTERS.reduce((acc, type) => {
          return activeFiltersCopy.includes(type) ? toggleArrayElement(acc, type) : acc
        }, activeFiltersCopy)
        return toggleArrayElement(clearedStateType, filter)
      }
      return toggleArrayElement(oldValues, filter)
    })
  }

  const handleOnAccept = useCallback(() => {
    handleAccept(activeFiltersCopy)
    toggle()
  }, [activeFiltersCopy, handleAccept])

  const handleCloseModal = () => {
    setActiveFiltersCopy([...activeFilterList])
    toggle()
  }

  const handleClearFilters = () => {
    setActiveFiltersCopy([])
    clearFilters()
  }

  return (
    <Modal size={'md'} isOpen={isOpen} toggle={handleCloseModal}>
      <ModalHeader toggle={handleCloseModal}>
        {t('v2/common/filters:headers.advanced_filters')}
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col sm="5">
            <FormGroup tag="fieldset">
              <legend className="h6">{t('v2/devices/filters:advanced_headings.network_assets')}</legend>
              <div className="d-flex flex-column">
                <DevicesAdvancedFilterModalSubgroup
                  filtersInSubrgroup={DEVICE_STATE_FILTERS}
                  selectedFilters={activeFiltersCopy}
                  onChange={handleOnChangeAdvancedFilter}
                />
                <legend className="h6">{t('v2/devices/filters:advanced_headings.state')}</legend>
                <DevicesAdvancedFilterModalSubgroup
                  filtersInSubrgroup={NETWORK_ASSET_FILTERS}
                  selectedFilters={activeFiltersCopy}
                  onChange={handleOnChangeAdvancedFilter}
                />
              </div>
            </FormGroup>
          </Col>
          <Col sm="7">
            <FormGroup tag="fieldset">
              <legend className="h6">{t('v2/devices/filters:advanced_headings.health')}</legend>
              <DevicesAdvancedFilterModalSubgroup
                className={'pl-3'}
                subgroupHeading={t('v2/devices/filters:advanced_headings.errors')}
                filtersInSubrgroup={ADVANCED_ERROR_FILTERS}
                selectedFilters={activeFiltersCopy}
                onChange={handleOnChangeAdvancedFilter}
              />
              <DevicesAdvancedFilterModalSubgroup
                className={'pl-3'}
                subgroupHeading={t('v2/devices/filters:advanced_headings.warnings')}
                filtersInSubrgroup={ADVANCED_WARNING_FILTERS}
                selectedFilters={activeFiltersCopy}
                onChange={handleOnChangeAdvancedFilter}
              />
              <DevicesAdvancedFilterModalSubgroup
                className={'pl-3'}
                subgroupHeading={t('v2/devices/filters:advanced_headings.information')}
                filtersInSubrgroup={ADVANCED_INFORMATION_FILTERS}
                selectedFilters={activeFiltersCopy}
                onChange={handleOnChangeAdvancedFilter}
              />
            </FormGroup>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <div className="mr-auto">
          <Button
            name="clear-filters-button"
            type="button"
            color="light"
            onClick={handleClearFilters}>
            {t('v2/common/buttons:clear_filters')}
          </Button>
        </div>
        <Button
          name="cancel-button"
          color="secondary"
          onClick={handleCloseModal}>
          {t('v2/common/buttons:cancel')}
        </Button>
        <Button
          name="accept-button"
          color="secondary"
          onClick={handleOnAccept}>
          {t('v2/common/buttons:save')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

DevicesListAdvancedFilterModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  activeFilterList: PropTypes.arrayOf(PropTypes.string),
  handleAccept: PropTypes.func,
  clearFilters: PropTypes.func,
}

export default DevicesListAdvancedFilterModal
