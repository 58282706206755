import React from 'react'

export default function PlotClusterAnnotationIcon({ x, y, count }) {
  if(count && count < 1) {
    return null
  }
  return(
    <svg x={x} y={y} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.25 1.5H3.75A2.25 2.25 0 0 0 1.5 3.75v16.5a2.25 2.25 0 0 0 2.25 2.25h16.5a2.25 2.25 0
         0 0 2.25-2.25V3.75a2.25 2.25 0 0 0-2.25-2.25z"
        transform="scale(0.5)"
        fill="#000"
        fillRule="evenodd"/>
      <text x={count > 9 ? '2' : '4'} y={9} fill={'white'} fontSize="8px">
        {count > 9 ? '9+' : count}
      </text>
    </svg>
  )
}
