import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { compose } from 'redux'
import { NavLink } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { Card, Badge, Collapse } from 'reactstrap'

import ErrorInfo from '@@src/components/error_info'
import AsyncResult from '@@src/utils/async_result'
import { CAN_VIEW_ALERTS } from '../../_v2/contexts/user/consts/permissions'
import AlertListItem from './alert_list_item'
import AsyncResultSwitch from '@@src/components/async_result_switch'
import AppSettingsConsumer from '@@src/components/app_settings_consumer'
import requiresPermissions from '@@src/components/security/requires_permissions'
import { DEFAULT_TIMEZONE } from '@@src/utils'
import { AVAILABLE_PRESSURE_UNITS } from '@@src/utils/unit_constants'

import styles from './collapsible_alerts_section.css'

class CollapsibleAlertsSection extends React.PureComponent {
  static propTypes = {
    mutedUntil: PropTypes.instanceOf(Date),
    pressureUnits: PropTypes.oneOf(AVAILABLE_PRESSURE_UNITS).isRequired,
    pagedAlertsResult: PropTypes.instanceOf(AsyncResult).isRequired,
    onChangeAlertStatus: PropTypes.func.isRequired,
    showAllOpenAlertsHref: PropTypes.string,
    currentSetting: PropTypes.shape({
      editHref: PropTypes.string.isRequired,
      inheritedFrom: PropTypes.oneOf(['group', 'tenant']),
    }),
  }

  state = {
    error: null,
    isCollapsed: true,
  }

  render() {
    const { t, className, pagedAlertsResult, mutedUntil } = this.props
    const { isCollapsed } = this.state

    return (
      <Card className={classnames(className, styles.container, {
        [styles['no-alerts']]: pagedAlertsResult.wasSuccessful() &&
          !pagedAlertsResult.data.length,
      })}>
        <div className={styles.summary}>
          <h5 className={styles.heading}>
            {t('headings.alerts')}
          </h5>

          <div className={styles['badge-container']}>
            <AsyncResultSwitch
              result={pagedAlertsResult}
              renderSuccessResult={this.renderOpenAlertsBadge}/>
          </div>

          <span className={styles.chevron} onClick={this.toggleCollapse}>
            <i className={classnames('far', {
              'fa-chevron-down': isCollapsed,
              'fa-chevron-right': !isCollapsed,
            })}></i>
          </span>
        </div>

        {
          mutedUntil ? (
            <span className={styles['mute-info']}>
              {t('text.muted_until', {
                date: moment(mutedUntil)
                  .tz(DEFAULT_TIMEZONE)
                  .format('YYYY.MM.DD h:mm a'),
              })}
            </span>
          ) : null
        }

        <Collapse isOpen={!isCollapsed}>
          <AsyncResultSwitch
            result={pagedAlertsResult}
            renderSuccessResult={this.renderPagedAlerts}/>
        </Collapse>
      </Card>
    )
  }

  renderOpenAlertsBadge = ({ data: { openAlertsCount } }) => {
    const { t } = this.props

    return openAlertsCount > 0 ? (
      <Badge color="danger" className={styles.badge}>
        {t('text.open_alerts_text', { count: openAlertsCount })}
      </Badge>
    ) : null
  }

  renderPagedAlerts = ({ data: { data: alerts, pagination } }) => {
    const {
      t, showAllOpenAlertsHref, onChangeAlertStatus, currentSetting,
      pressureUnits,
    } = this.props
    const { error } = this.state
    const numberOfNotDisplayed = pagination.totalResults - alerts.length

    return (
      <div>
        {error ? <ErrorInfo error={error}/> : null}

        <ul className={styles['alerts-list']}>
          {alerts.map(alert => (
            <AlertListItem
              pressureUnits={pressureUnits}
              key={alert.alertId}
              alert={alert}
              onError={this.onChangeAlertStatusError}
              onChangeAlertStatus={onChangeAlertStatus}
              onClickChangeAlertStatus={this.onClickChangeAlertStatus}/>
          ))}
        </ul>

        {
          pagination.totalResults === 0 ? (
            <span>{t('text.no_open_alerts')}</span>
          ) : null
        }

        {
          numberOfNotDisplayed > 0 ? (
            <span>
              {t('text.number_of_alerts_not_displayed', {
                count: numberOfNotDisplayed,
              })}
            </span>
          ) : null
        }

        <div className={styles['links-below-list']}>
          {
            showAllOpenAlertsHref && pagination.totalResults > 0 ? (
              <NavLink to={showAllOpenAlertsHref}>
                {t('links.show_all_alerts')}
              </NavLink>
            ) : null
          }

          {
            currentSetting ? (
              <span className={styles['inherited-from-text']}>
                {this.formatInheritedFromConfig(currentSetting.inheritedFrom)}

                (
                <NavLink
                  to={currentSetting.editHref}
                  className={styles['link-to-edit-alerts-config']}>
                  {t('links.edit_alerts_configuration')}
                </NavLink>
                )
              </span>
            ) : null
          }
        </div>
      </div>
    )
  }

  onChangeAlertStatusError = error => {
    this.setState({ error })
  }

  onClickChangeAlertStatus = () => {
    this.setState({ error: null })
  }

  toggleCollapse = event => {
    event.preventDefault()

    this.setState({ isCollapsed: !this.state.isCollapsed })
  }

  formatInheritedFromConfig = inheritedFrom => {
    const { t } = this.props

    switch (inheritedFrom) {
      case 'group':
        return t('text.inherited_configuration_from_group')

      case 'tenant':
        return t('text.inherited_configuration_from_tenant')

      default:
        return t('text.inherited_configuration_from_none')
    }
  }
}

function CollapsibleAlertsSectionContainer(props) {
  return (
    <AppSettingsConsumer>
      {
        (units) => (
          <CollapsibleAlertsSection
            pressureUnits={units.pressure}
            {...props}
          />
        )
      }
    </AppSettingsConsumer>
  )
}

export default compose(
  requiresPermissions([CAN_VIEW_ALERTS]),
  withTranslation(['src/components/alerts/collapsible_alerts_section']),
)(CollapsibleAlertsSectionContainer)
