import Store from './store'
import Trigger from './trigger'
import Wrapper from './wrapper'
import Contents from './contents'
import Instance from './instance'

export default {
  Store,
  Contents,
  Trigger,
  Wrapper,
  Instance,
}
