import React, { PureComponent } from 'react'
import gql from 'graphql-tag'
import { graphql } from '@apollo/client/react/hoc'
import PropTypes from 'prop-types'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Alert,
  Button } from 'reactstrap'
import moment from 'moment'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import { noop } from 'lodash/fp/util'
import RawDataNetworkAssetItem from
'@@src/components/graphs/modals/raw_data_network_asset_item'
import GraphItem from '@@src/components/graphs/graph_item'
import AsyncResult from '@@src/utils/async_result'
import {
  DEFAULT_TIMEZONE,
  AVAILABLE_TIME_ZONES,
} from '@@src/utils'
import selectNetworkAssetMinutesLeft from
'@@src/utils/graphs/asset_minutes_left'
import mapErrorToText from '@@src/utils/graphs/modal_error'
import styles from './transient_localisation_modal.css'

// Import constant from controller
import { LOCALISATION_STARTED } from
'@@src/components/graphs/modals/transient_localisation_modal_controller'

class TransientLocalisationModal extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    handleToggle: PropTypes.func.isRequired,
    timeFrom: PropTypes.instanceOf(Date).isRequired,
    timeTo: PropTypes.instanceOf(Date).isRequired,
    listRawDataRequestsResult: PropTypes.instanceOf(AsyncResult).isRequired,
    networkAssetGraphItems:
    PropTypes.arrayOf(PropTypes.instanceOf(GraphItem)).isRequired,
    timezone: PropTypes.oneOf(AVAILABLE_TIME_ZONES).isRequired,
    // setModalType will be called on success to show a success modal
    setModalType: PropTypes.func.isRequired,
    onRequestSuccess: PropTypes.func,
  }

  static defaultProps = {
    isOpen: false,
    networkAssetGraphItems: [],
    onRequestSuccess: noop,
    timezone: DEFAULT_TIMEZONE,
  }

  state = {
    errors: [],
    success: false,
  }

  render() {
    const {
      isOpen,
      handleToggle,
      timeFrom,
      timeTo,
      networkAssetGraphItems,
      t,
    } = this.props

    // SCOPED VARIABLES (for render)
    const networkAssetsMinutesLeft = selectNetworkAssetMinutesLeft(this.props)
    const { errors, success } = this.state
    const disableRequestButton = this.disableRequestButton()

    return (
      <Modal
        centered={true}
        isOpen={isOpen}
        toggle={handleToggle}>
        <ModalHeader>
          <span className={styles['modal-title']}>
            {t('headings.title')}
          </span>
        </ModalHeader>
        <ModalBody>
          {/* DATETIME RANGE */}
          <div className={styles['time-range-title']}>
            {t('text.time_range')}
          </div>
          <div className="font-weight-bold mb-2 mt-2">
            <time dateTime={timeFrom.toISOString()}>
              {moment(timeFrom).format('ddd D MMM, YYYY h:mm a')}
            </time>
            &nbsp;→ &nbsp;
            <time dateTime={timeTo.toISOString()}>
              {moment(timeTo).format('ddd D MMM, YYYY h:mm a')}
            </time>
          </div>
          <div className={styles['time-request-limit']}>
            {t('text.request_limit')}
          </div>
          {
            // SUCCESS MESSAGE
            success && !errors.length ?
              <Alert color="success">
                {t('alert.success')}
              </Alert>
              :
              null
          }
          {
            // ERROR MESSAGES
            errors.length ?
              errors.map((error, i) => (
                <Alert name="api-errors" key={i} color="danger">
                  {t(error)}
                </Alert>
              ))
              :
              null
          }
          {
            // ALL ASSSETS SHOULD HAVE CONGRUITY ENABLED
            !this.congruityEnabledForAllItems() ?
              <Alert color="danger" name="event-localisation-support-alert">
                {t('alert.not_all_support_event_localisation')}
              </Alert>
              :
              null
          }
          {
          /* CONDITION MESSAGE (hide if successfully completed request) */
            !this.state.success ?
              <Alert className={styles['time-range-alert']} color="warning">
                {t('alert.condition')}
              </Alert>
              : null}
          {/* NETWORK ASSETS */}
          <h2 className={styles['assets-title']}>
            {t('headings.network_assets')}
          </h2>
          {
            <ul className="list-unstyled">
              {networkAssetGraphItems.map(asset => {
                const networkAssetMinutesLeft = networkAssetsMinutesLeft.find(
                  ({ networkAssetId, channel }) =>
                    String(networkAssetId) === String(asset.sourceId) &&
                      channel === asset.sourceChannel
                )

                return (
                  <RawDataNetworkAssetItem
                    minutesLeft={
                      networkAssetMinutesLeft ?
                        networkAssetMinutesLeft.minutesLeft : undefined
                    }
                    color={asset.color}
                    networkAssetLegendItem={asset.legendItem}
                    showCongruityIsDisabled={
                      asset.source.congruityInfo === null
                    }
                    key={asset.id} />
                )
              })}
            </ul>
          }
        </ModalBody>
        <ModalFooter>
          {/* BUTTONS */}
          <Button color="secondary" onClick={handleToggle}>
            {t('buttons.cancel')}
          </Button>
          <Button
            name="submit-transient-btn"
            color="primary"
            disabled={disableRequestButton}
            aria-disabled={disableRequestButton.toString()}
            onClick={this.handleRequestClick}>
            {t('buttons.request')}
          </Button>
        </ModalFooter>
      </Modal>
    )
  }

  handleRequestClick = async () => {
    const {
      timeFrom,
      timeTo,
      requestTransientLocalisation,
      networkAssetGraphItems } = this.props

    try {

      /* requestTransientLocalisation is passed in as a prop to our component
          via the graphql function. This is our GraphQL mutation which automatically
          returns the response once variables have been provided

         The data object returned to us contains the server response
         (the server will throw an error if anything is wrong)
      */
      const { data } = await requestTransientLocalisation({
        variables: {
          start: timeFrom.toISOString(),
          end: timeTo.toISOString(),
          networkAssets: networkAssetGraphItems.map(asset => {
            return {
              networkAssetId: asset.source.id,
              channel: asset.sourceChannel,
            }
          }),
        },
      })

      // If the server responded with a code starting with 2
      // (i.e. 200, 201, etc) then we have been successful
      const serverResponse = data.requestTransientLocalisation.toString()
      if(serverResponse[0] === '2') {
        this.setState({ success: true, errors: [] })
        // Change the type of modal on success
        this.props.setModalType(LOCALISATION_STARTED)
        return
      }

      /* A different code was returned by the server (did not start with 2)
        (it shouldn't ever hit this point but for belt and braces
        the app can still handle a different server response code
        in case the api logic changes)
      */
      throw new Error('api responded with code ' + serverResponse)

    } catch (err) {

      // If we don't know this error, default to standard server error message
      // (-4 is our custom error code for unknown error)
      let errorMessages = mapErrorToText(-4)

      // Standard 'GraphQL error: N' error message
      if (err && (/^GraphQL error/).test(err.message)) {

        // if there is a number in the error message (e.g. 'GraphQL error: 2')
        // then lookup the appropriate translated template text to use.
        // (Error code number is in the second array index: gqlError[1]).
        const gqlError = err.message.match(/(-?\d+)$/)
        if (gqlError) {
          errorMessages = [mapErrorToText(gqlError[1])]

        // if it's not a standard 'GraphQL error: {number}' message try and return
        // appropriate translated template text...
        } else if (err.message.includes('Exceeds raw data request quota')) {
          errorMessages = [mapErrorToText(4)]

        } else if (err.message.includes('Rate exceeded')) {
          errorMessages = [mapErrorToText(7)]
        }

      // Network Error
      } else if (
        err &&
        err.networkError &&
        err.networkError.result &&
        err.networkError.result.errors) {
        errorMessages = err.networkError.result.errors
          .map(networkError => mapErrorToText(networkError.message))
      }

      this.setState({ success: false, errors: Array.isArray(errorMessages) ? errorMessages : [errorMessages] })
    }
  }

  // We should disable the request button if:
  // 1. errors
  // 2. already successfully requested
  // 3. congruity is not enabled for all devices
  disableRequestButton = () => {
    return !!(this.state.errors.length ||
      this.state.success ||
      !this.congruityEnabledForAllItems())
  }

  congruityEnabledForAllItems = () => {
    return this.props.networkAssetGraphItems.every(
      item => {return item.source.congruityInfo !== null})
  }

  // This is our GraphQL mutation that is passed in as a prop
  // as the component is composed (graphql function does this)
  // It runs when the request button is clicked
  static TRANSIENT_LOCALISATION_MUTATION = gql`
    mutation RequestTransientLocalization(
      $start: String!, 
      $end: String!, 
      $networkAssets: [NetworkAssetChannel]!) {
      requestTransientLocalisation(
        start: $start
        end: $end
        networkAssetChannels: $networkAssets
      )
    }
    `

}

export default compose(
  withTranslation(
  ['src/components/graphs/modals/transient_localisation_modals/transient_localisation_modal'] // eslint-disable-line max-len
  ),
  graphql(TransientLocalisationModal.TRANSIENT_LOCALISATION_MUTATION, {
    name: 'requestTransientLocalisation',
  }),

)(TransientLocalisationModal)
