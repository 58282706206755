import React from 'react'
import PropTypes from 'prop-types'
import { noop } from 'lodash/fp/util'
import { scaleLinear } from 'd3-scale'
import { format } from 'd3-format'
import gql from 'graphql-tag'

import GraphContext from '@@src/components/graphs/graph_context'
import LeftAxis from '@@src/components/graphs/left_axis'
import BottomTimeAxis from '@@src/components/graphs/bottom_time_axis'
import DragEventLayer, { ONLY_X }
from '@@src/components/graphs/drag_layer/drag_event_layer'
import HistoryGraph from
'@@src/management_path/devices_path/history_modal/history_graph'
import DragControlsLayer from '@@src/components/graphs/drag_controls_layer'
import BatteryHistoryPlotLayer from
'@@src/management_path/devices_path/battery_history_modal/battery_history_plot_layer' // eslint-disable-line max-len
import TelemetryGraphStateOverlay from
'@@src/management_path/devices_path/history_modal/telemetry_graph_state_overlay' // eslint-disable-line max-len
import AsyncResult from '@@src/utils/async_result'
import DragAreaOverlay
from '@@src/components/graphs/drag_layer/drag_area_overlay'

import styles from './battery_history_graph.css'

export const BATTERY_VOLTAGE_SCALE = [0, 8]
export const HLC_VOLTAGE_SCALE = [0, 4]

const formatTicks = format(',.1f')

export default class BatteryHistoryGraph extends React.PureComponent {
  static defaultProps = {
    onAxesChange: noop,
    onResetZoom: noop,
    handleGraphPan: noop,
  }

  static propTypes = {
    className: PropTypes.string,
    start: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    end: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    panStart: PropTypes.instanceOf(Date),
    panEnd: PropTypes.instanceOf(Date),
    onAxesChange: PropTypes.func.isRequired,
    onResetZoom: PropTypes.func.isRequired,
    handleGraphPan: PropTypes.func.isRequired,
    selectXScale: PropTypes.func,
    refetchData: PropTypes.func,
    deviceWithTelemetryResult: PropTypes.instanceOf(AsyncResult),
  }

  static deviceTelemetryQuery = gql`
  query DeviceTelemetryQuery(
    $id: Int!,
    $end: String!,
    $start: String!,
    $channels: [TelemetryChannelsTypes!]!
  ) {
    device(id: $id) {
      id
      serialNumber
      telemetry(start: $start, end: $end, channels: $channels) {
        time
        value
        channel
      }
    }
  }
`

  render() {
    const {
      className,
      onAxesChange,
      onResetZoom,
      deviceWithTelemetryResult,
      selectXScale,
      start,
      end,
      panStart,
      panEnd,
      refetchData,
      deviceId,
      handleGraphPan,
      timezone,
    } = this.props
    const telemetrySegments =
      deviceWithTelemetryResult.data.telemetrySegments || []

    return (
      <TelemetryGraphStateOverlay
        usesTooltips={false}
        dataResult={deviceWithTelemetryResult}>
        <HistoryGraph
          deviceId={deviceId}
          className={className}
          refetchData={refetchData}
          panStart={panStart}
          panEnd={panEnd}
          start={start}
          end={end}
          xScale={selectXScale(this.props)}
          yScale={this.selectYScale(this.props)}
          yScale2={this.selectYScale2(this.props)}>
          <LeftAxis withGuides label={
            <g>
              <g transform="translate(0, -10)">
                <circle
                  r="3"
                  cx="-33"
                  cy="-4"
                  className={styles['battery-legend']}/>
                <text transform="translate(25, 0)">Battery V</text>
              </g>
              <g transform="translate(0, 5)">
                <circle
                  r="3"
                  cx="-33"
                  cy="-4"
                  className={styles['hlc-legend']}/>
                <text transform="translate(7, 0)">HLC V</text>
              </g>
            </g>
          } tickFormatter={formatTicks}/>
          <BottomTimeAxis timezone={timezone}/>

          <GraphContext.Consumer>
            {config => (
              <BatteryHistoryPlotLayer
                graphConfig={config}
                telemetrySegments={telemetrySegments}/>
            )}
          </GraphContext.Consumer>

          <DragEventLayer
            dragBehaviour={ONLY_X}
            onDoubleClick={onResetZoom}>
            <DragControlsLayer
              onPan={handleGraphPan}
              onAxesChange={onAxesChange}
              dragBehaviour={ONLY_X}/>

            <DragAreaOverlay />
          </DragEventLayer>
        </HistoryGraph>
      </TelemetryGraphStateOverlay>
    )
  }

  selectYScale = () => {
    return scaleLinear().domain(BATTERY_VOLTAGE_SCALE)
  }

  selectYScale2 = () => {
    return scaleLinear().domain(HLC_VOLTAGE_SCALE)
  }
}
