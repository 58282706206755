import React from 'react'
import { withTranslation } from 'react-i18next'
import { Redirect } from 'react-router-dom'
import { Row } from 'reactstrap'
import { compose } from 'redux'

import AppLayout from '@@src/components/app_layout'
import requiresLogin from '@@src/components/security/requires_login'
import DevicesSummary from '@@src/components/summary/devices_summaries/devices_summary'
import HighSeverityPressureEventsSummary
  from '@@src/components/summary/high_severity_events_summaries/high_severity_events_summary'
import { CAN_MANAGE_USERS, CAN_VIEW_ANALYSIS, CAN_VIEW_DEVICES } from '../_v2/contexts/user/consts/permissions'
import { withUser } from '../_v2/contexts/user/user.context'
import DevicesInstalledHealthySummary
  from '../components/summary/devices_installed_healthy_summaries/devices_installed_healthy_summary'
import NetworkAssetSummary from '../components/summary/network_asset_summaries/network_asset_summary'
import UnhealthyAssetsSummary from '../components/summary/unhealthy_assets_summaries/unhealthy_assets_summary'
import routes from '../routes'

import styles from './index_page.css'

function HomePage({ t, authorizedUser }) {
  if (authorizedUser.can(CAN_MANAGE_USERS) && !authorizedUser.can(CAN_VIEW_DEVICES)) {
    return (<Redirect to={{ pathname: routes.adminUsersPath() }} />)
  } else if (!authorizedUser.can(CAN_VIEW_DEVICES)) {
    return <Redirect to="/page-not-found" />
  }

  return (
    <AppLayout title={t('headings.page_title')} showFooter={true}>
      <div className="container">
        <p className={styles['welcome-text']}>
          {t('text.welcome')}
          <span className={styles['welcome-text-username']}>
            {authorizedUser.displayName}
          </span>
        </p>
        <div className={styles['welcome-line']} />

        <Row>
          <DevicesInstalledHealthySummary />
          <DevicesSummary />
          <NetworkAssetSummary />

          <UnhealthyAssetsSummary />
          {authorizedUser.can(CAN_VIEW_ANALYSIS) && (<HighSeverityPressureEventsSummary />)}
        </Row>
      </div>
    </AppLayout>
  )
}

export default compose(
  requiresLogin,
  withUser,
  withTranslation(['src/home_path/index_page']),
)(HomePage)
