import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'reactstrap'

import styles from './summary_panel_list_item.css'

function SummaryPanelListItem({ icon, children, itemActions }) {
  return (
    <li name="summary-list-item" className={styles['summary-list-item']}>
      <Row className={styles['summary-list-item-row']}>
        <Col sm="6" className="d-flex align-items-top">
          <span className="pr-3 d-flex">
            {icon}
          </span>
          <div className="d-flex flex-column">
            {children}
          </div>
        </Col>
        <Col sm="6" className="d-flex align-items-top justify-content-end">
          {itemActions}
        </Col>
      </Row>
    </li>
  )
}

SummaryPanelListItem.propTypes = {
  icon: PropTypes.node,
  children: PropTypes.node,
  itemActions: PropTypes.node,
}

export default memo(SummaryPanelListItem)
