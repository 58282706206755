import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import styles from './display_dropdown_item.css'

const DisplayDropdownItem = props => {
  const { image, text, description, isSelected } = props
  const checkClassnames = classnames(
    styles['dropdown-icon'], 'fa fa-check', 'font-weight-normal'
  )
  const textClassnames = classnames(styles['dropdown-text'], {
    [styles['dropdown-text-active']]: isSelected,
  })

  return (
    <>
      <img className={styles['dropdown-img']} src={image} alt="" />
      <div>
        <div className={textClassnames}>
          {text}
        </div>
        <div className={styles['dropdown-description']}>
          {description}
        </div>
      </div>
      {isSelected ?
        <i name="check-icon"
          className={checkClassnames} />
        : null
      }
    </>
  )
}

DisplayDropdownItem.propTypes = {
  text: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
}

DisplayDropdownItem.defaultProps = {
  isSelected: false,
}

export default DisplayDropdownItem
