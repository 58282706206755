import { useEffect, useRef, useState } from 'react'
import { getDevicesForListCall } from '../../../../api/devices.service'
import { mapFlattenedFiltersToQueryObject } from '../utils/filters'
import { normalizeGeoBoundsToQuery } from '../../../../components/base_map/helpers/map-positions'
import GeoBounds from '../../../../../utils/graphs/geo_bounds'
import { useApolloClient } from '@apollo/client'

const useGetListDevices = (filters) => {
  const client = useApolloClient()
  const abortController = useRef(null)
  const [results, setResults] = useState({ error: null, devices: [], pagination: null, isLoading: true })

  const handleFetchDevices = () => {
    if (abortController.current) {
      abortController.current.abort()
    }

    if (!results.isLoading) {
      setResults({ ...results, isLoading: true })
    }

    abortController.current = new AbortController()

    getDevicesForListCall({
      client, abortController: abortController.current,
      pagination: {
        pageNumber: filters.pageNumber,
        resultsPerPage: filters.resultsPerPage,
      },
      order: {
        by: filters.orderBy,
        direction: filters.orderDirection,
      },
      filters: {
        search: filters.search,
        ...mapFlattenedFiltersToQueryObject(filters.filters),
        inBounds: filters && filters.mapPosition && filters.mapPosition.bounds instanceof GeoBounds
          ? normalizeGeoBoundsToQuery(filters.mapPosition.bounds).toObject()
          : null,
      },
    })
      .then(result => {
        setResults({
          devices: result.devices,
          pagination: result.pagination,
          error: null,
          isLoading: false,
        })
      })
      .catch(error => {
        setResults({ error, devices: [], pagination: null, isLoading: false })
      })
      .finally(() => {
        abortController.current = null
      })
  }

  useEffect(() => {
    const timeoutID = setTimeout(() => {
      handleFetchDevices()
    }, 400)

    return () => clearTimeout(timeoutID)
  }, [filters])

  return { ...results, refetch: handleFetchDevices }
}

export default useGetListDevices
