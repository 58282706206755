import React, { PureComponent } from 'react'
import gql from 'graphql-tag'
import PropTypes from 'prop-types'
import { Modal, ModalHeader, ModalBody, ModalFooter, Alert,
  Button } from 'reactstrap'
import moment from 'moment'
import { compose } from 'redux'
import { graphql } from '@apollo/client/react/hoc'
import { noop } from 'lodash/fp/util'
import { connect } from 'react-redux'

import RawDataNetworkAssetItem from
'@@src/components/graphs/modals/raw_data_network_asset_item'
import GraphItem from '@@src/components/graphs/graph_item'
import AsyncResult from '@@src/utils/async_result'
import {
  DEFAULT_TIMEZONE, createCreateSelectParsedSearchParam, AVAILABLE_TIME_ZONES,
} from '@@src/utils'
import selectNetworkAssetMinutesLeft from
'@@src/utils/graphs/asset_minutes_left'
import mapErrorToText from '@@src/utils/graphs/modal_error'

import styles from './request_raw_data_modal.css'

const componentI18NameSpace =
  ['src/components/graphs/modals/request_raw_data_modal']

class RequestRawDataModal extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    handleToggle: PropTypes.func.isRequired,
    timeFrom: PropTypes.instanceOf(Date).isRequired,
    timeTo: PropTypes.instanceOf(Date).isRequired,
    listRawDataRequestsResult: PropTypes.instanceOf(AsyncResult).isRequired,
    networkAssetGraphItems:
      PropTypes.arrayOf(PropTypes.instanceOf(GraphItem)).isRequired,
    timezone: PropTypes.oneOf(AVAILABLE_TIME_ZONES).isRequired,
    onRequestSuccess: PropTypes.func,
  }

  static defaultProps = {
    isOpen: false,
    networkAssetGraphItems: [],
    onRequestSuccess: noop,
    timezone: DEFAULT_TIMEZONE,
  }

  state = {
    errors: [],
    success: false,
  }

  render() {
    const {
      isOpen, handleToggle, timeFrom, timeTo, networkAssetGraphItems, t,
    } = this.props
    const { errors = [], success } = this.state
    const timeDiff = moment.duration(moment(timeTo).diff(moment(timeFrom)))
    const secondsTimeDiff = timeDiff.asSeconds()
    const networkAssetsMinutesLeft = selectNetworkAssetMinutesLeft(this.props)

    return (
      <Modal
        centered={true}
        isOpen={isOpen}
        toggle={handleToggle}>
        <ModalHeader toggle={handleToggle}>
          <span className={styles['modal-title']}>
            {t('headings.title', {
              ns: componentI18NameSpace,
            })}
          </span>
        </ModalHeader>
        <ModalBody>
          <div className={styles['time-range-title']}>
            {t('text.time_range', {
              ns: componentI18NameSpace,
            })}
          </div>
          <div className="font-weight-bold mb-2 mt-2">
            <time dateTime={timeFrom.toISOString()}>
              {moment(timeFrom).format('ddd D MMM, YYYY h:mm a')}
            </time>
            &nbsp;→&nbsp;
            <time dateTime={timeTo.toISOString()}>
              {moment(timeTo).format('ddd D MMM, YYYY h:mm a')}
            </time>
          </div>
          <div className={styles['time-request-limit']}>
            {t('text.request_limit', {
              ns: componentI18NameSpace,
            })}
          </div>
          {
            success && !errors.length ?
              <Alert color="success">
                {t('alert.success', {
                  ns: componentI18NameSpace,
                })}
              </Alert>
              :
              null
          }
          {
            errors.length ?
              errors.map((error, i) => (
                <Alert name="api-errors" key={i} color="danger">
                  {t(error, {
                    ns: componentI18NameSpace,
                  })}
                </Alert>
              ))
              :
              null
          }
          {
            secondsTimeDiff > 600 ?
              <Alert color="danger">
                {t('alert.over_allowed_time', {
                  ns: componentI18NameSpace,
                })}
              </Alert>
              :
              null
          }
          <Alert className={styles['time-range-alert']} color="warning">
            {t('alert.condition', {
              ns: componentI18NameSpace,
            })}
          </Alert>
          <h2 className={styles['assets-title']}>
            {t('headings.network_assets', {
              ns: componentI18NameSpace,
            })}
          </h2>
          {
            networkAssetGraphItems.length ?
              <ul className="list-unstyled">
                {networkAssetGraphItems.map(asset => {
                  const networkAssetMinutesLeft = networkAssetsMinutesLeft.find(
                    ({ networkAssetId, channel }) =>
                      String(networkAssetId) === String(asset.sourceId) &&
                        channel === asset.sourceChannel
                  )

                  return (
                    <RawDataNetworkAssetItem
                      minutesLeft={
                        networkAssetMinutesLeft ?
                          networkAssetMinutesLeft.minutesLeft : undefined
                      }
                      color={asset.color}
                      networkAssetLegendItem={asset.legendItem}
                      key={asset.id} />
                  )
                })}
              </ul>
              :
              <Alert color="warning">
                {t('alert.no_assets', {
                  ns: componentI18NameSpace,
                })}
              </Alert>
          }
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={handleToggle}>
            {t('buttons.cancel', {
              ns: componentI18NameSpace,
            })}
          </Button>
          <Button
            name="submit-request-btn"
            disabled={!networkAssetGraphItems.length ||
                      errors.length ||
                      secondsTimeDiff > 600
            }
            color="primary"
            onClick={this.handleRequestClick}>
            {t('buttons.request', {
              ns: componentI18NameSpace,
            })}
          </Button>
        </ModalFooter>
      </Modal>
    )
  }

  handleRequestClick = async () => {
    const { requestRawData, timeFrom, timeTo, networkAssetGraphItems,
      onRequestSuccess } = this.props

    this.setState({
      error: '',
      success: false,
    })

    try {
      await requestRawData({
        variables: {
          start: timeFrom.toISOString(),
          end: timeTo.toISOString(),
          networkAssets: networkAssetGraphItems.map(asset => {
            return {
              networkAssetId: asset.source.id,
              channel: asset.sourceChannel,
            }
          }),
        },
      })

      this.setState({
        success: true,
      })

      onRequestSuccess()

    } catch (err) {

      // If we don't know this error, default to standard server error message
      // (-4 is our custom error code for unknown error)
      let errorMessages = mapErrorToText(-4)

      // Standard 'GraphQL error: N' error message
      if (err && (/^GraphQL error/).test(err.message)) {

        // if there is a number in the error message (e.g. 'GraphQL error: 2')
        // then lookup the appropriate translated template text to use.
        // (Error code number is in the second array index: gqlError[1]).
        const gqlError = err.message.match(/(-?\d+)$/)
        if (gqlError) {
          errorMessages = [mapErrorToText(gqlError[1])]

        // if it's not a standard 'GraphQL error: {number}' message try and return
        // appropriate translated template text...
        } else if (err.message.includes('Exceeds raw data request quota')) {
          errorMessages = [mapErrorToText(4)]

        } else if (err.message.includes('Rate exceeded')) {
          errorMessages = [mapErrorToText(7)]
        }

      // Network Error
      } else if (
        err &&
        err.networkError &&
        err.networkError.result &&
        err.networkError.result.errors) {
        errorMessages = err.networkError.result.errors
          .map(networkError => mapErrorToText(networkError.message))
      }

      this.setState({ errors: Array.isArray(errorMessages) ? errorMessages : [errorMessages] })
    }
  }

  static RAW_DATA_MUTATION = gql`
    mutation CreateRawDataRequest(
      $start: String!,
      $end: String!,
      $networkAssets: [NetworkAssetChannel]!
    ) {
    createRawDataRequest(
      start: $start,
      end: $end,
      networkAssetChannels: $networkAssets
    )
  }
  `
}

function createMapStateToProps() {
  const createSelectParsedSearchParam =
    createCreateSelectParsedSearchParam(
      router => router ? router.location.search : ''
    )
  const selectTimezone = createSelectParsedSearchParam('tz', DEFAULT_TIMEZONE)

  return function mapStateToProps(state) {
    return {
      timezone: selectTimezone(state.router),
    }
  }
}

export default compose(
  connect(createMapStateToProps),
  graphql(RequestRawDataModal.RAW_DATA_MUTATION, {
    name: 'requestRawData',
  }),
)(RequestRawDataModal)
