import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash/fp/object'
import { createSelector } from 'reselect'
import { withTranslation } from 'react-i18next'

import AsyncResult from '@@src/utils/async_result'
import AnalysisGraphProgressBar
from '../analysis_graph_progress_bar'
import { DAYS, HOURS, MINUTES, SECONDS } from '@@src/utils'
import { withPressureSubsetDataContainer } from '../../../hooks/plot/PlotDataContext'
import { compose } from 'redux'

const PROGRESS_COMPLETE = { pageNumber: 1, totalPages: 1 }
const PROGRESS_JUST_STARTED = { pageNumber: 0, totalPages: 1 }

const DUMMY_START_PROGRESS = 15

class PressureSubsetGraphProgressBar extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    resolution: PropTypes.number.isRequired,
    alertsResult: PropTypes.instanceOf(AsyncResult).isRequired,
    isOnlyRawData: PropTypes.bool.isRequired,
    isRawDataAvailable: PropTypes.bool.isRequired,
    rawDataRequestsResult: PropTypes.instanceOf(AsyncResult).isRequired,
    pressureSubsetDataResult: PropTypes.instanceOf(AsyncResult).isRequired,
    pressureEventsWithData: PropTypes.instanceOf(AsyncResult).isRequired,
    anomalyResult: PropTypes.instanceOf(AsyncResult).isRequired,
  }

  render() {
    const { className } = this.props

    return (
      <AnalysisGraphProgressBar
        className={className}
        {...this.selectProgressInfo(this.props)} />
    )
  }

  selectProgressInfo = createSelector(
    [
      get('t'),
      get('pressureSubsetDataResult'),
      get('pressureEventsWithData'),
      get('anomalyResult'),
      get('resolution'),
      get('isOnlyRawData'),
      get('isRawDataAvailable'),
      get('alertsResult'),
    ],
    (t, pressureSubsetDataResult, pressureEventsWithData, anomalyResult, inputResolution, isOnlyRawData, isRawDataAvailable, alertsResult) => { // eslint-disable-line max-len
      const resolution = inputResolution * SECONDS

      const resolutionText = computeResolutionText(
        t, resolution, isOnlyRawData, isRawDataAvailable
      )

      const { loadingText, progress } = computeCurrentProgress(t, {
        alertsResult,
        resolutionText,
        pressureSubsetDataResult,
        pressureEventsWithData,
        anomalyResult,
      })
      const errorDetail = computeErrors(t, [
        alertsResult,
        pressureSubsetDataResult,
        pressureEventsWithData,
        anomalyResult,
      ])

      return {
        text:
          progress < 100 ? loadingText : errorDetail ? t('text.loading_error', { resolutionText }) : resolutionText,
        color: errorDetail ? 'danger' : (progress < 100 ? 'info' : 'success'),
        progress,
        errorDetail,
      }
    }
  )
}

function computeErrors(t, results) {
  return results.filter(result => result.wasFailure())
    .map(result => result.error.translateWith(t))
    .join('. ')
}

function computeCurrentProgress(t, {
  alertsResult,
  resolutionText,
  pressureSubsetDataResult,
  pressureEventsWithData,
  anomalyResult,
}) {
  switch (true) {
    // if alerts is skipped, pagination will be empty
    case (alertsResult.isPending() && !!alertsResult.data.pagination): {
      const pgn = alertsResult.data.pagination

      return {
        progress: computeProgress(pgn.pageNumber, pgn.totalPages),
        loadingText: t('text.loading_alerts'),
      }
    }

    case (pressureSubsetDataResult.isPending()): {
      const pgn = pressureSubsetDataResult.data.timeSlicePagination ||
        (pressureSubsetDataResult.wasSuccessful() ?
          PROGRESS_COMPLETE : PROGRESS_JUST_STARTED)

      return {
        progress: computeProgress(
          pgn.totalPages - pgn.pageNumber,
          pgn.totalPages,
        ),
        loadingText: t('text.loading_subset_data', { resolutionText }),
      }
    }

    case (anomalyResult.isPending()): {
      const { activeParts, totalParts } = anomalyResult.data
      const alreadyFetched = totalParts - activeParts
      return {
        progress: computeProgress(alreadyFetched, totalParts),
        loadingText: t('text.loading_anomalies_data', { alreadyFetched, totalFetch: totalParts }),
      }
    }

    case (pressureEventsWithData.isPending()): {
      return {
        progress: computeProgress(9, 10),
        loadingText: t('text.loading_events_data'),
      }
    }

    default:
      return { progress: 100 }
  }
}

function computeProgress(page, total) {
  return DUMMY_START_PROGRESS + Math.floor(
    (100 - DUMMY_START_PROGRESS) * Math.max(0, page) / total
  )
}

function computeResolutionText(t, resolution, isOnlyRawData, hasRawData) {
  return isOnlyRawData ? t('text.only_raw_data_available') :
    hasRawData ? t('text.raw_data_available', {
      resolutionText: formatResolution(t, resolution),
    }) : formatResolution(t, resolution)
}

function formatResolution(t, resolution) {
  switch (true) {
    case (resolution % DAYS === 0):
      return t('text.resolution_option_days', { count: resolution / DAYS })

    case (resolution % HOURS === 0):
      return t('text.resolution_option_hours', { count: resolution / HOURS })

    case (resolution % MINUTES === 0):
      return t('text.resolution_option_minutes', {
        count: resolution / MINUTES,
      })

    default:
      return t('text.resolution_option_seconds', {
        count: resolution / SECONDS,
      })
  }
}

export default compose(
  withTranslation([
    'src/analysis_path/pressure_analysis_path/pressure_subset_graph/pressure_subset_graph_progress_bar', // eslint-disable-line max-len
    'common/text',
  ]),
  withPressureSubsetDataContainer,
)(PressureSubsetGraphProgressBar)
