import React, { useMemo } from 'react'
import BorderlessButton from '@@src/components/buttons/borderless_button'
import { useTranslation } from 'react-i18next'
import { formatEventDuration } from '@@src/utils'
import { convertUnit } from '@@src/utils/app_unit_conversion'
import { Popup } from '@@src/components/popup'
import LoadingIcon from '@@src/components/loading_icon'
import { PRESSURE_EVENTS_QUERY } from '../../../analysis_path/pressure_analysis_path/pressure_subset_graph/graphql'
import { INTERNAL_PRESSURE_UNIT } from '@@src/utils/unit_constants'
import PressureEvent from '../../../api/presenters/pressure_event'
import { useQuery } from '@apollo/client'

import styles from './styles.css'

const PressureEventsPopup = ({
  currentlySelectedEvent,
  eventId = null,
  start,
  end,
  isActiveEventExpanded,
  isActive,
  networkAssetChannel,
  onRemoveExpandedEvent,
  pressureUnits,
}) => {
  const { t } = useTranslation('src/analysis_path/pressure_analysis_path/pressure_subset_graph/pressure_events_layer')
  const { data: popupEventData, loading } = useQuery(PRESSURE_EVENTS_QUERY, {
    variables: {
      start,
      end,
      eventIds: eventId ? [eventId] : [],
      pageNumber: currentlySelectedEvent + 1,
      resultsPerPage: 1,
      networkAssetChannels: [networkAssetChannel],
    },
  })

  const convertedEvent = useMemo(() => {
    if (!popupEventData) {
      return null
    }

    const eventResult =
      popupEventData.pagedPressureEvents.pressureEvents.length && popupEventData.pagedPressureEvents.pressureEvents[0]

    if (!eventResult) {
      return null
    }

    return new PressureEvent({
      ...eventResult,
      maxPressure: convertUnit(eventResult.maxPressure, INTERNAL_PRESSURE_UNIT, pressureUnits), // eslint-disable-line max-len
      minPressure: convertUnit(eventResult.minPressure, INTERNAL_PRESSURE_UNIT, pressureUnits), // eslint-disable-line max-len
      meanPressure: convertUnit(eventResult.meanPressure, INTERNAL_PRESSURE_UNIT, pressureUnits), // eslint-disable-line max-len
      eventCharacteristics: (eventResult.eventCharacteristics || []).map(ec => {
        if (ec.eventCharacteristic === 'severity') {
          return {
            ...ec,
            value: convertUnit(ec.value, INTERNAL_PRESSURE_UNIT, pressureUnits), // eslint-disable-line max-len
          }
        } else {
          return ec
        }
      }),
    })
  }, [popupEventData])

  return (
    <Popup
      key={currentlySelectedEvent}
      isOpen={
        isActive && isActiveEventExpanded
      }
      className={`${styles.popup} ${loading ? 'p-0 m-0' : ''}`}>
      {
        loading || !convertedEvent ?
          <div className="d-flex align-items-center justify-content-center">
            <LoadingIcon className={styles['loading-spinner']} />
          </div>
          :
          <>
            <div className={styles['tooltip-actions-row']}>
              <div className={styles['tooltip-actions-row-text']}>
                <span className={styles['duration-text']}>
                  {formatEventDuration(convertedEvent)}
                </span>
              </div>

              <div className={styles['tooltip-actions-row-actions']}>
                <BorderlessButton
                  color="secondary"
                  onClick={onRemoveExpandedEvent}
                  className={styles['close-button']}>
                  <i className="fal fa-times"></i>
                </BorderlessButton>
              </div>
            </div>

            <table className={styles.table}>
              <thead>
                <tr>
                  <th>{t('text.severity_label')}</th>
                  <th>{t('text.cpis_label')}</th>
                  <th>{t('text.min_label')}</th>
                  <th>{t('text.max_label')}</th>
                  <th>{t('text.mean_label')}</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>
                    {
                      convertedEvent.severity === null ? (
                        t('text.not_available')
                      ) : formatPressure(convertedEvent.severity)
                    }
                  </td>

                  <td>
                    {
                      convertedEvent.cpisScore === null ? (
                        t('text.not_available')
                      ) : formatPressure(convertedEvent.cpisScore)
                    }
                  </td>

                  <td>
                    {formatPressure(convertedEvent.minPressure)}
                  </td>
                  <td>
                    {formatPressure(convertedEvent.maxPressure)}
                  </td>
                  <td>
                    {formatPressure(convertedEvent.meanPressure)}
                  </td>
                </tr>
              </tbody>
            </table>
          </>
      }
    </Popup>
  )
}

function formatPressure(value) {
  return value.toFixed(1)
}

export default PressureEventsPopup
