import React from 'react'
import { FormGroup, Input, Label } from 'reactstrap'

import PropTypes from 'prop-types'

const AdvancedFilterModalSubgroup = ({
  t,
  className,
  subgroupHeading,
  filtersInSubrgroup = [],
  selectedFilters = [],
  onChange,
}) => {
  return (
    <FormGroup>
      {
        subgroupHeading &&
        <legend className="font-weight-normal h6">
          {subgroupHeading}
        </legend>
      }
      <div className={className}>
        {
          filtersInSubrgroup.map(filter => (
            <FormGroup key={filter} check={true}>
              <Label check={true}>
                <Input
                  checked={selectedFilters.includes(filter)}
                  onChange={() => onChange(filter)}
                  type="checkbox"
                  name={filter}/>
                  &nbsp;
                {t(filter)}
              </Label>
            </FormGroup>
          ))
        }
      </div>
    </FormGroup>
  )
}

AdvancedFilterModalSubgroup.propTypes = {
  t: PropTypes.func.isRequired,
  subgroupHeading: PropTypes.string,
  filtersInSubrgroup: PropTypes.arrayOf(PropTypes.string),
  selectedFilters: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
}

export default AdvancedFilterModalSubgroup
