import React from 'react'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'
import { noop } from 'lodash/fp/util'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import { Button } from 'reactstrap'
import classnames from 'classnames'

import { TenantConfigContext } from '@@src/components/tenant_config_provider'
import Device from '@@src/api/presenters/device'
import CreateGroupModal, {
  CreateGroupModalWithDeviceConfig,
} from './create_group_modal'
import userPermissions from '@@src/components/permissions/user_permissions'
import { MixedGroupDetailsType } from '@@src/api/presenters/group'

class CreateGroupButtonAndModal extends React.PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    permissions: PropTypes.array.isRequired,
    handleSuccessfullyCreatedNewGroup: PropTypes.func.isRequired,
    handleCreatedAndCreateAnother: PropTypes.func.isRequired,
    parentGroup: PropTypes.instanceOf(MixedGroupDetailsType),
    className: PropTypes.string,
  }

  static defaultProps = {
    permissions: [],
    handleSuccessfullyCreatedNewGroup: noop,
    handleCreatedAndCreateAnother: noop,
  }

  state = {
    isCreateGroupModalOpen: false,
  }

  render() {
    const { t, permissions, className } = this.props

    return (
      <div className={classnames('flex-grow-0 flex-shrink-0', className)}>
        {this.renderCreateGroupModal()}
        <span data-tip={t('common/text:permissions.disabled')}>
          <Button
            disabled={!permissions.includes('can_edit_groups')}
            name="create-group-button"
            color="primary"
            onClick={this.openCreateGroupModal}>
            <i className="far fa-plus" />
            &nbsp;
            {t('buttons.create_new_group')}
          </Button>
        </span>
        {
          permissions.includes('can_edit_groups') ?
            null :
            <ReactTooltip effect="solid" place="bottom"/>
        }
      </div>
    )
  }

  renderCreateGroupModal = () => {
    const { handleSuccessfullyCreatedNewGroup, handleCreatedAndCreateAnother,
      parentGroup } = this.props
    const { isCreateGroupModalOpen } = this.state

    return (
      <TenantConfigContext.Consumer>
        {(config = {}) => {
          const {
            dateFrom: tenantDateFrom,
            secondsInterval: tenantSecondsInterval,
            timeZone: tenantTimeZone,
          } = config.deviceConfig || {}
          const tenantConfiguration = {
            dateFrom: tenantDateFrom,
            secondsInterval: tenantSecondsInterval,
            timeZone: tenantTimeZone,
            sourceAggregate: Device.CONFIGURATION_SOURCE_TENANT_TYPE,
          }

          if (Number.isFinite(tenantSecondsInterval) && tenantDateFrom) {
            const groupConfiguration = {
              ...tenantConfiguration,
              sourceAggregate: Device.CONFIGURATION_SOURCE_GROUP_TYPE,
              displayLabel: 'custom',
            }

            return (
              <CreateGroupModalWithDeviceConfig
                configurations={[tenantConfiguration, groupConfiguration]}
                selectedConfiguration={tenantConfiguration}
                parentGroup={parentGroup}
                isOpen={isCreateGroupModalOpen}
                toggle={this.onToggleCreateGroupModal}
                onSuccess={handleSuccessfullyCreatedNewGroup}
                onCreateAndCreateAnother={handleCreatedAndCreateAnother}
              />
            )
          } else {
            return (
              <CreateGroupModal
                parentGroup={parentGroup}
                isOpen={isCreateGroupModalOpen}
                toggle={this.onToggleCreateGroupModal}
                onSuccess={handleSuccessfullyCreatedNewGroup}
                onCreateAndCreateAnother={handleCreatedAndCreateAnother}
              />
            )
          }
        }}
      </TenantConfigContext.Consumer>
    )
  }

  onToggleCreateGroupModal = () => {
    this.setState({ isCreateGroupModalOpen: false })
  }

  openCreateGroupModal = () => {
    this.setState({ isCreateGroupModalOpen: true })
  }
}

export default compose(
  userPermissions,
  withTranslation([
    'src/management_path/groups_path/create_group_button_and_modal',
    'common/text',
  ])
)(CreateGroupButtonAndModal)
