import { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { updateSearchParams } from '../../../utils'

const useUrlFilterControll = (queryFilters) => {
  const history = useHistory()
  const [listQueryFilters, setListQueryFilters] = useState(queryFilters)
  const [currentMapPosition, setCurrentMapPosition] = useState(
    queryFilters.mapPosition
  )
  const [listQueryPagination, setListQueryPagination] = useState({
    pageNumber: queryFilters.pageNumber,
    resultsPerPage: queryFilters.resultsPerPage,
  })

  const mergedFilters = useMemo(() => {
    return {
      ...listQueryFilters,
      mapPosition: currentMapPosition,
      ...listQueryPagination,
    }
  }, [listQueryFilters, currentMapPosition, listQueryPagination])

  useEffect(() => {
    history.replace({ search: updateSearchParams(mergedFilters) })
  }, [mergedFilters])

  const setQueryFilters = useCallback(
    (newFilters) => {
      setListQueryFilters(newFilters)
      setListQueryPagination((oldQueryFilters) => ({
        ...oldQueryFilters,
        pageNumber: 1,
      }))
    },
    [setListQueryFilters]
  )

  const onPositionUpdate = useCallback(
    (newMapPosition) => {
      if (!newMapPosition) {
        setCurrentMapPosition({
          bounds: undefined,
          center: undefined,
          zoom: undefined,
        })
        setListQueryPagination((oldQueryFilters) => ({
          ...oldQueryFilters,
          pageNumber: 1,
        }))
      } else {
        const prevMapPosition = mergedFilters.mapPosition || {}
        const boundsChanged =
          !prevMapPosition.bounds ||
          !newMapPosition.bounds.equals(prevMapPosition.bounds)
        const centerChanged =
          !prevMapPosition.center ||
          !newMapPosition.center.equals(prevMapPosition.center)
        const zoomChanged = newMapPosition.zoom !== prevMapPosition.zoom
        if (boundsChanged || centerChanged || zoomChanged) {
          setCurrentMapPosition(newMapPosition)
          setListQueryPagination((oldQueryFilters) => ({
            ...oldQueryFilters,
            pageNumber: 1,
          }))
        }
      }
    },
    [mergedFilters, setCurrentMapPosition, setQueryFilters]
  )

  return {
    listQueryFilters,
    currentMapPosition,
    mergedFilters,
    setQueryFilters,
    setListQueryPagination,
    onPositionUpdate,
  }
}

export default useUrlFilterControll
