import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

export const PM_PLUS_FEATURE = 'pm_plus'
export const EPANET_IMPORT_FEATURE = 'epanet_import'
export const FREE_CPIS_FEATURE = 'free_cpis'
export const FREE_EVENT_DETECTION_FEATURE = 'free_event_detection'
export const EVENT_LOCALISATION_FEATURE = 'event_localisation'
export const EXPORT_RAW_DATA_FEATURE = 'export_raw_data'

export const TENANT_LICENCE_FEATURES = [
  PM_PLUS_FEATURE,
  EPANET_IMPORT_FEATURE,
  FREE_CPIS_FEATURE,
  FREE_EVENT_DETECTION_FEATURE,
  EVENT_LOCALISATION_FEATURE,
  EXPORT_RAW_DATA_FEATURE,
]

class TenantLicenceFeatureGate extends PureComponent {
  static propTypes = {
    requiredTenantLicenceFeature:
      PropTypes.oneOf(TENANT_LICENCE_FEATURES).isRequired,
    userTenantLicenceFeatures:
      PropTypes.arrayOf(PropTypes.oneOf(TENANT_LICENCE_FEATURES)).isRequired,
    renderInactiveLicenceFeatureChild: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
  }

  static defaultProps = {
    userTenantLicenceFeatures: [],
    renderInactiveLicenceFeatureChild: () => null,
    children: null,
  }

  render() {
    const { children, renderInactiveLicenceFeatureChild } = this.props

    if (this.isLicenceFeatureActive()) {
      return children
    } else {
      return renderInactiveLicenceFeatureChild()
    }
  }

  isLicenceFeatureActive() {
    const {
      userTenantLicenceFeatures, requiredTenantLicenceFeature,
    } = this.props

    return userTenantLicenceFeatures.includes(requiredTenantLicenceFeature)
  }
}

function mapStateToProps() {
  return {
    // todo: tenantLicenceFeatures
    userTenantLicenceFeatures: [],
  }
}

export default connect(mapStateToProps)(TenantLicenceFeatureGate)
