import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Col, Row } from 'reactstrap'
import { withTranslation } from 'react-i18next'

import * as analytics from '@@src/analytics'

import styles from './quick_time_ranges_picker.css'

class QuickTimeRangesPicker extends React.PureComponent {
  static defaultProps = {
    source: 'unknown',
  }

  static propTypes = {
    onChangeStartTime: PropTypes.func.isRequired,
    onChangeEndTime: PropTypes.func.isRequired,
    applyTimeRange: PropTypes.func.isRequired,
    source: PropTypes.string.isRequired,
  }

  constructor(props) {
    super(props)
  }

  changeTimeRange = async (e) => {
    const { onChangeStartTime, applyTimeRange,
      onChangeEndTime, source } = this.props
    const { value } = e.target.attributes.value

    const now = moment()

    switch (value) {
      case 'today':
        await onChangeStartTime(now.clone().startOf('day'))
        await onChangeEndTime(now.clone())
        await applyTimeRange()
        analytics.trackDaterangePicked(source, 'today')
        break

      case 'yesterday':
        await onChangeStartTime(now.clone().subtract(1, 'day').startOf('day'))
        await onChangeEndTime(now.clone().subtract(1, 'day').endOf('day'))
        await applyTimeRange()
        analytics.trackDaterangePicked(source, 'yesterday')
        break

      case '2_days_ago':
        await onChangeStartTime(now.clone().subtract(2, 'day').startOf('day'))
        await onChangeEndTime(now.clone().subtract(2, 'day').endOf('day'))
        await applyTimeRange()
        analytics.trackDaterangePicked(source, '2_days_ago')
        break

      case 'this_week':
        await onChangeStartTime(now.clone().startOf('isoWeek'))
        await onChangeEndTime(now.clone())
        await applyTimeRange()
        analytics.trackDaterangePicked(source, 'this_week')
        break

      case 'this_month':
        await onChangeStartTime(now.clone().startOf('month'))
        await onChangeEndTime(now.clone())
        await applyTimeRange()
        analytics.trackDaterangePicked(source, 'this_month')
        break

      case 'this_day_last_week':
        await onChangeStartTime(now.clone().subtract(7, 'day').startOf('day'))
        await onChangeEndTime(now.clone().subtract(7, 'day').endOf('day'))
        await applyTimeRange()
        analytics.trackDaterangePicked(source, 'this_day_last_week')
        break

      case 'previous_month':
        await onChangeStartTime(
          now.clone().subtract(1, 'month').startOf('month')
        )
        await onChangeEndTime(now.clone().subtract(1, 'month').endOf('month'))
        await applyTimeRange()
        analytics.trackDaterangePicked(source, 'previous_month')
        break

      case 'previous_week':
        await onChangeStartTime(
          now.clone().subtract(1, 'week').startOf('isoWeek')
        )
        await onChangeEndTime(now.clone().subtract(1, 'week').endOf('isoWeek'))
        await applyTimeRange()
        analytics.trackDaterangePicked(source, 'previous_week')
        break

      case 'last_30_mins':
        await onChangeStartTime(now.clone().subtract(30, 'minute'))
        await onChangeEndTime(now.clone())
        await applyTimeRange()
        analytics.trackDaterangePicked(source, 'last_30_mins')
        break

      case 'last_1_hour':
        await onChangeStartTime(now.clone().subtract(1, 'hour'))
        await onChangeEndTime(now.clone())
        await applyTimeRange()
        analytics.trackDaterangePicked(source, 'last_1_hour')
        break

      case 'last_3_hours':
        await onChangeStartTime(now.clone().subtract(3, 'hour'))
        await onChangeEndTime(now.clone())
        await applyTimeRange()
        analytics.trackDaterangePicked(source, 'last_3_hours')
        break

      case 'last_6_hours':
        await onChangeStartTime(now.clone().subtract(6, 'hour'))
        await onChangeEndTime(now.clone())
        await applyTimeRange()
        analytics.trackDaterangePicked(source, 'last_6_hours')
        break

      case 'last_12_hours':
        await onChangeStartTime(now.clone().subtract(12, 'hour'))
        await onChangeEndTime(now.clone())
        await applyTimeRange()
        analytics.trackDaterangePicked(source, 'last_12_hours')
        break

      case 'last_24_hours':
        await onChangeStartTime(now.clone().subtract(24, 'hour'))
        await onChangeEndTime(now.clone())
        await applyTimeRange()
        analytics.trackDaterangePicked(source, 'last_24_hours')
        break

      case 'last_2_days':
        await onChangeStartTime(now.clone().subtract(2, 'day'))
        await onChangeEndTime(now.clone())
        await applyTimeRange()
        analytics.trackDaterangePicked(source, 'last_2_days')
        break

      case 'last_7_days':
        await onChangeStartTime(now.clone().subtract(7, 'day'))
        await onChangeEndTime(now.clone())
        await applyTimeRange()
        analytics.trackDaterangePicked(source, 'last_7_days')
        break

      case 'last_30_days':
        await onChangeStartTime(now.clone().subtract(30, 'day'))
        await onChangeEndTime(now.clone())
        await applyTimeRange()
        analytics.trackDaterangePicked(source, 'last_30_days')
        break

      case 'last_90_days':
        await onChangeStartTime(now.clone().subtract(90, 'day'))
        await onChangeEndTime(now.clone())
        await applyTimeRange()
        analytics.trackDaterangePicked(source, 'last_90_days')
        break

      case 'last_6_months':
        await onChangeStartTime(now.clone().subtract(6, 'month'))
        await onChangeEndTime(now.clone())
        await applyTimeRange()
        analytics.trackDaterangePicked(source, 'last_6_months')
        break

      default:
        analytics.logError('Invalid quick time range')
        break
    }
  }

  render() {
    const { t } = this.props

    return (
      <div className="w-100">
        <div className={styles.title}>
          {t('text.quick_ranges')}
        </div>

        <Row>
          <Col sm="6">
            <ul className={styles['ranges-list']}>
              <li value="today" onClick={this.changeTimeRange}>
                {t('buttons.today')}
              </li>
              <li value="this_week" onClick={this.changeTimeRange}>
                {t('buttons.this.week')}
              </li>
              <li value="this_month" onClick={this.changeTimeRange}>
                {t('buttons.this.month')}
              </li>
              <li value="previous_month" onClick={this.changeTimeRange}>
                {t('buttons.previous.month')}
              </li>
            </ul>
          </Col>
          <Col sm="6">
            <ul className={styles['ranges-list']}>
              <li value="yesterday" onClick={this.changeTimeRange}>
                {t('buttons.yesterday')}
              </li>
              <li value="2_days_ago" onClick={this.changeTimeRange}>
                {t('buttons.2_days_ago')}
              </li>
              <li value="this_day_last_week" onClick={this.changeTimeRange}>
                {t('buttons.this.day_last_week')}
              </li>
              <li value="previous_week" onClick={this.changeTimeRange}>
                {t('buttons.previous.week')}
              </li>
            </ul>
          </Col>
        </Row>

        <hr />

        <Row>
          <Col sm="6">
            <ul className={styles['ranges-list']}>
              <li value="last_30_mins" onClick={this.changeTimeRange}>
                {t('buttons.last.30_mins')}
              </li>
              <li value="last_1_hour" onClick={this.changeTimeRange}>
                {t('buttons.last.1_hour')}
              </li>
              <li value="last_3_hours" onClick={this.changeTimeRange}>
                {t('buttons.last.3_hours')}
              </li>
              <li value="last_6_hours" onClick={this.changeTimeRange}>
                {t('buttons.last.6_hours')}
              </li>
              <li value="last_12_hours" onClick={this.changeTimeRange}>
                {t('buttons.last.12_hours')}
              </li>
              <li value="last_24_hours" onClick={this.changeTimeRange}>
                {t('buttons.last.24_hours')}
              </li>
            </ul>
          </Col>
          <Col sm="6">
            <ul className={styles['ranges-list']}>
              <li value="last_2_days" onClick={this.changeTimeRange}>
                {t('buttons.last.2_days')}
              </li>
              <li value="last_7_days" onClick={this.changeTimeRange}>
                {t('buttons.last.7_days')}
              </li>
              <li value="last_30_days" onClick={this.changeTimeRange}>
                {t('buttons.last.30_days')}
              </li>
              <li value="last_90_days" onClick={this.changeTimeRange}>
                {t('buttons.last.90_days')}
              </li>
              <li value="last_6_months" onClick={this.changeTimeRange}>
                {t('buttons.last.6_months')}
              </li>
            </ul>
          </Col>
        </Row>

      </div>
    )
  }

}

export default withTranslation([
  'src/components/dropdowns/time_range_picker/quick_time_ranges_picker',
  'common/text',
])(QuickTimeRangesPicker)
