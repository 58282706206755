import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import {
  Container,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap'
import { graphql } from '@apollo/client/react/hoc'
import gql from 'graphql-tag'
import { Link, Redirect } from 'react-router-dom'
import moment from 'moment'
import classnames from 'classnames'

import requiresLogin from '@@src/components/security/requires_login'
import AppLayout from '@@src/components/app_layout'
import userPermissions from '@@src/components/permissions/user_permissions'
import routes from '@@src/routes'
import { parseGraphQLResult } from '@@src/api/presenters'
import { createSelectGraphQLResult } from '@@src/utils'
import AsyncResult from '@@src/utils/async_result'
import transformProps from '@@src/components/transform_props'
import AsyncResultSwitch from '@@src/components/async_result_switch'
import ChangeAlertStatusDropdown
from '@@src/alerts_path/change_alert_status_dropdown'
import ErrorInfo from '@@src/components/error_info'
import NetworkAssetIcon from '@@src/components/icons/network_asset_icon'
import GroupIcon from '@@src/components/icons/group_icon'
import AlertDialog from '@@src/alerts_path/alert_dialog'
import deviceIconError from '@@src/static_assets/svgs/device_icon_error.svg'
import AlertTypeLinkButton from '@@src/alerts_path/alert_type_link_button'
import AlertSourceMap from '@@src/alerts_path/alert_source_map'
import AppSettingsConsumer from '@@src/components/app_settings_consumer'
import { AVAILABLE_PRESSURE_UNITS } from '@@src/utils/unit_constants'
import {
  convertAlertThreshold,
  convertAlertValue,
  convertAlertUnitText,
} from '@@src/utils/app_unit_conversion'

import styles from './alert_details_page.css'

class AlertDetailsPage extends React.PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    permissions: PropTypes.array.isRequired,
    alertId: PropTypes.number.isRequired,
    alertResult: PropTypes.instanceOf(AsyncResult).isRequired,
    pressureUnits: PropTypes.oneOf(AVAILABLE_PRESSURE_UNITS).isRequired,
    refetchAlert: PropTypes.func,
  }

  state = {
    updateStatusResult: AsyncResult.notFound(),
  }

  render() {
    const { t, permissions, alertId, alertResult } = this.props

    if (!permissions.includes('can_view_alerts') || !Number.isFinite(alertId)) {
      return (
        <Redirect to="/page-not-found" />
      )
    }

    return (
      <AppLayout
        contentsStyle="fixed-at-full-height"
        title={t('headings.page_title')}>
        <div className="d-flex flex-row w-100 h-100 p-0 m-0">
          <Col sm="12" lg="6" className="px-4 pt-1 overflow-auto">
            <Container className={styles['alert-container']} fluid={true}>
              <Breadcrumb>
                <BreadcrumbItem>
                  <Link to={routes.alertsPath()}>
                    {t('buttons.alerts')}
                  </Link>
                </BreadcrumbItem>

                <BreadcrumbItem active>
                  {t('text.alert_breadcrumb', { id: alertId })}
                </BreadcrumbItem>
              </Breadcrumb>

              <AsyncResultSwitch
                result={alertResult}
                renderSuccessResult={this.renderAlertDetails}
              />
            </Container>
          </Col>
          <Col sm="0" lg="6" className="p-0 m-0">
            <AsyncResultSwitch
              result={alertResult}
              renderSuccessResult={this.renderAlertSourceMap}
            />
          </Col>
        </div>
      </AppLayout>
    )
  }

  renderAlertDetails = ({ data: { data: alerts } }) => {
    if (!Array.isArray(alerts) || alerts.length === 0) {
      return (
        <Redirect to="/page-not-found" />
      )
    }

    const { t, pressureUnits } = this.props
    const { updateStatusResult } = this.state
    const [alert] = alerts

    return (
      <React.Fragment>
        <div className="d-flex align-items-center justify-content-between">
          <h1 className="mr-4">
            {t(alert.descriptionKey, {
              threshold: convertAlertThreshold(alert, pressureUnits),
              unit: t(
                convertAlertUnitText(alert, pressureUnits), {
                  count: convertAlertThreshold(alert, pressureUnits),
                }
              ),
            })}
          </h1>
          {
            updateStatusResult.wasFailure() ?
              <ErrorInfo error={updateStatusResult.error}/> :
              null
          }
          <div className="d-flex">
            <ChangeAlertStatusDropdown
              updateStatusResult={updateStatusResult}
              onChangeAlertStatus={this.onChangeAlertStatus}
              className="mr-2"
              alert={alert} />
            <AlertTypeLinkButton alert={alert} />
          </div>
        </div>
        {this.renderInformation(alert)}
        {this.renderSource(alert)}
        <div className="my-5">
          <h2 className="h4 mb-4">
            {t('headings.comments')}
          </h2>
          <AlertDialog alert={alert} />
        </div>
      </React.Fragment>
    )
  }

  renderInformation = alert => {
    const { t, pressureUnits } = this.props

    return (
      <Row name="alert-data" className="mt-5">
        <Col sm="3">
          <div className="font-weight-bold">
            {t('text.time')}
          </div>
          {moment(alert.occurredAt).format('lll')}
        </Col>
        {
          Number.isFinite(alert.details.threshold) ?
            <Col sm="3">
              <div className="font-weight-bold">
                {t('text.threshold')}
              </div>
              {convertAlertThreshold(alert, pressureUnits)}
              {' '}
              {t(convertAlertUnitText(alert, pressureUnits), {
                count: convertAlertThreshold(alert, pressureUnits),
              })}
            </Col>
            : null
        }
        {
          alert.details.value ?
            <Col sm="3">
              <div className="font-weight-bold">
                {t('text.value')}
              </div>
              {convertAlertValue(alert, pressureUnits)}
              {' '}
              {t(convertAlertUnitText(alert, pressureUnits), {
                count: convertAlertValue(alert, pressureUnits),
              })}
            </Col>
            : null
        }
      </Row>
    )
  }

  renderSource = alert => {
    const { t } = this.props
    let source = null

    if (alert.isEventSourceLocalisationAlert()) {
      source = (
        <div className="d-flex align-items-center">
          <GroupIcon className={classnames(styles['group-icon'], 'mr-3')} />
          <Link to={routes.managementGroupsDetailPath(alert.details.groupId)}>
            {alert.details.groupName}
          </Link>
        </div>
      )
    } else if (alert.isDeviceNoCommsAlert() ||
      alert.isBatteryDepletingAlert()) {
      source = (
        <div className="d-flex align-items-center">
          <img
            name="alert-device-icon"
            src={deviceIconError}
            className={classnames('mr-3', styles['source-icon'])} />
          <Link to={routes.managementDevicesDetailPath(alert.details.deviceId)}>
            {alert.details.deviceSerialNumber}
          </Link>
        </div>
      )
    } else {
      source = (
        <div className="d-flex align-items-center">
          <NetworkAssetIcon
            className={classnames(styles['source-icon'], 'mr-3')}
            networkAsset={{ assetType: alert.details.networkAssetType }}/>
          <div>
            <div>
              <Link to={
                routes.networkAssetsDetailPath(alert.details.networkAssetId)
              }>
                {alert.details.networkAssetName}
              </Link>
              <span className="font-italic ml-2">
                {alert.details.networkAssetFriendlyName}
              </span>
            </div>
            <div>
              <small className="text-muted mr">
                {t(`common/text:text.${alert.details.networkAssetType}`)}
              </small>
              <small className="text-muted ml-1">
                {
                  alert.details.logicalChannel ?
                    <span>
                      {/* eslint-disable-next-line max-len */}
                      ({t(`common/text:text.asset_channel_${alert.details.logicalChannel}`)})
                    </span>
                    : null
                }
              </small>
            </div>
          </div>
        </div>
      )
    }

    return (
      <div name="alert-source" className="rounded bg-white mt-5 border p-3">
        <h2 className="h4 mb-3">
          {t('headings.source')}
        </h2>
        {source}
      </div>
    )
  }

  renderAlertSourceMap = ({ data: { data: alerts } }) => {
    if (!Array.isArray(alerts) || alerts.length === 0) {
      return null
    }

    return (
      <AlertSourceMap className="w-100 h-100" alert={alerts[0]} />
    )
  }

  onChangeAlertStatus = result => {
    const { refetchAlert } = this.props

    if (result.wasSuccessful() && typeof refetchAlert === 'function') {
      refetchAlert()
    }

    this.setState({ updateStatusResult: result })
  }

  static ALERT_QUERY = gql`
    query PagedAlerts(
      $alertIds: [Int],
    ) {
      pagedAlerts(
        pageNumber: 1,
        resultsPerPage: 1,
        alertIds: $alertIds,
      ) {
        alerts {
          alertId
          status
          type
          occurredAt
          commentCount
          details {
            ... on StandardAlertDetails {
              networkAssetId
              networkAssetName
              networkAssetFriendlyName
              networkAssetType
              deviceId
              deviceSerialNumber
              logicalChannel
              threshold
              value
              unit
            }
            ... on DeviceNoCommsAlertDetails {
              deviceId
              deviceSerialNumber
              threshold
              unit
            }
            ... on EventSourceLocalisationAlertDetails {
              groupId
              groupName
              localisationId
              eventIds
              eventsStartDate
              eventsEndDate
            }
            ... on EventAlertDetails {
              networkAssetId
              networkAssetName
              networkAssetFriendlyName
              networkAssetType
              deviceId
              logicalChannel
              eventCharacteristic
              eventId
              startTimestamp
              endTimestamp
              value
              threshold
              unit
            }
          }
        }
        pagination {
          pageNumber
        }
      }
    }
  `
}

function AlertDetailsPageContainer(props) {
  return (
    <AppSettingsConsumer>
      {
        (units) => (
          <AlertDetailsPage
            pressureUnits={units.pressure}
            {...props}
          />
        )
      }
    </AppSettingsConsumer>
  )
}

export default compose(
  requiresLogin,
  userPermissions,
  withTranslation(['src/alerts_path/alert_details_page']),
  transformProps(() => ({ match }) => ({
    selectAlertResult: createSelectGraphQLResult('pagedAlerts', {
      mapResult: parseGraphQLResult,
      nullObject: [],
    }),
    alertId: Number(decodeURIComponent(match.params.id)),
  })),
  graphql(AlertDetailsPage.ALERT_QUERY, {
    skip: (({ alertId }) => !Number.isFinite(alertId)),
    options: ({ alertId }) => {
      return {
        variables: { alertIds: [alertId] },
      }
    },
    props: ({ data: { refetch, ...rest }, ownProps }) => {
      const { selectAlertResult } = ownProps

      return {
        refetchAlert: refetch,
        alertResult: selectAlertResult(rest),
      }
    },
  }),
)(AlertDetailsPageContainer)
