import { globalSequence } from '@@src/utils'

export const CHANGE_NOTIFICATION = '@change/change-notification'

export const CSV_DOWNLOAD_CHANGES = '@change/csv-downloads-changes'

export function selectChangeInfo(state, key) {
  return state.changes[key]
}

export function notifyChange(key, payload) {
  return { type: CHANGE_NOTIFICATION, key, payload }
}

function createInitialState() {
  return {}
}

export function reducer(state = createInitialState(), action) {
  switch (action.type) {
    case CHANGE_NOTIFICATION:
      return {
        ...state,
        [action.key]: {
          id: globalSequence.next(),
          payload: action.payload,
        },
      }

    default:
      return state
  }
}
