import React from 'react'
import PropTypes from 'prop-types'
import { Popup } from 'react-leaflet'
import { Label, Input } from 'reactstrap'
import { isNil } from 'lodash/lang'
import { withTranslation } from 'react-i18next'

import DatetimePicker from '@@src/components/forms/datetime_picker'
import SubmitButton from '@@src/components/buttons/submit_button'
import StreetViewLink from '@@src/components/maps/markers/street_view_link'
import DeviceIssueSeverityBadge from
'@@src/components/badges/device_issue_severity_badge'

import styles from './network_asset_popup_with_assignment_options.css'

class NetworkAssetPopupWithAssignmentOptions extends React.PureComponent {
  static defaultProps = {
    onSelectAssetChannel: () => { },
  }

  static propTypes = {
    isValidDate: PropTypes.func,
    selectedChannel: PropTypes.string,
    onSelectAssetChannel: PropTypes.func.isRequired,
    selectedNetworkAssetId: PropTypes.number,
    googleMaps: PropTypes.object,
  }

  render() {
    const {
      t, networkAsset, onClickInstall, onChangeDatetime, googleMaps,
      datetime, datetimeError, selectedChannel, selectedNetworkAssetId, result,
      isValidDate,
    } = this.props
    const mostSevereDeviceIssue = networkAsset.mostSevereDeviceIssue

    return (
      <Popup>
        <div className="p-3">
          <div className="d-flex align-items-center flex-wrap">
            <h5 className={styles['network-asset-id']}>
              {networkAsset.assetId}
            </h5>
            {
              mostSevereDeviceIssue ?
                <DeviceIssueSeverityBadge
                  issue={mostSevereDeviceIssue}
                  className={styles['device-issue-badge']} />
                : null
            }
          </div>

          <div className={styles['network-asset-type']}>
            {t(`common/text:text.${networkAsset.assetType}`)}
          </div>

          {
            !isNil(googleMaps) && networkAsset.location ?
              <StreetViewLink
                t={t}
                googleMaps={googleMaps}
                latitude={networkAsset.location.latitude}
                longitude={networkAsset.location.longitude} /> :
              null
          }

          <ul className="mt-3 pl-3">
            {
              networkAsset.availableChannels.map(channel => (
                <li key={channel}>
                  <Label onClick={stopPropagation}>
                    <Input
                      type="radio"
                      name="network-asset-popup-channel-input"
                      value={channel}
                      checked={
                        networkAsset.id === selectedNetworkAssetId &&
                          channel === selectedChannel
                      }
                      onChange={
                        this.createOnChangeNetworkAssetChannel(
                          networkAsset,
                          channel
                        )
                      }/>
                    {t(`common/text:text.asset_channel_${channel}`)}
                  </Label>
                </li>
              ))
            }
          </ul>

          <div className="d-flex flex-row mt-3" onClick={stopPropagation}>

            <SubmitButton
              name="popup-submit-button"
              size="sm"
              buttonStatus=""
              color="primary"
              onSubmitForm={onClickInstall}
              result={result}
              submitText={t('buttons.create_installation')}>
            </SubmitButton>

            {' '}

            <DatetimePicker
              name="popup-installation-start-time"
              type="datetime"
              value={datetime}
              invalid={!!datetimeError}
              onChange={onChangeDatetime}
              className={styles['datetime-input']}
              isValidDate={isValidDate}/>
          </div>
        </div>
      </Popup>
    )
  }

  createOnChangeNetworkAssetChannel(networkAsset, channel) {
    return () => {
      this.props.onSelectAssetChannel(networkAsset, channel)
    }
  }
}

function stopPropagation(event) {
  event.stopPropagation()
}

export default withTranslation([
  'src/management_path/network_assets_path/assign_network_asset_to_device_modal/network_asset_popup_with_assignment_options', // eslint-disable-line max-len
  'src/components/maps/markers/street_view_link',
  'common/text',
])(NetworkAssetPopupWithAssignmentOptions)
