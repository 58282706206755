import React, { createRef } from 'react'
import { Input } from 'reactstrap'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { withTranslation } from 'react-i18next'
import { get } from 'lodash/fp/object'
import { map, flatMap } from 'lodash/fp/collection'
import { createSelector } from 'reselect'

import { LEGEND_ITEM_TYPE_CHANNEL } from '@@src/components/graphs/legend_item'
import LegendItem from '@@src/components/graphs/legend_item'
import AsyncResult from '@@src/utils/async_result'
import * as analytics from '@@src/analytics'
import { EXPORT_RAW_DATA_TYPE } from './export_dropdown_with_modal'

import styles from './export_legend_item.css'

class ExportLegendItem extends React.PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    legendItem: PropTypes.instanceOf(LegendItem).isRequired,
    getHandleSelectLegendItem: PropTypes.func.isRequired,
    dataResult: PropTypes.instanceOf(AsyncResult).isRequired,
    indeterminate: PropTypes.bool.isRequired,
    checked: PropTypes.bool.isRequired,
    dataType: PropTypes.string.isRequired,
    legendItemColor: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.node,
  }

  static defaultProps = {
    checked: false,
    indeterminate: false,
  }

  checkboxRef = createRef()

  render() {
    const {
      legendItem,
      legendItemColor,
      children,
      className,
      checked,
      t,
      dataType,
    } = this.props
    const itemHasData = this.selectLegendItemHasData(this.props)

    return (
      <li
        className={classnames(styles['export-legend-list-item'], className)}
        name="data-source-item"
        style={{ borderLeftColor: legendItemColor }}>
        <Input
          disabled={!itemHasData || dataType === EXPORT_RAW_DATA_TYPE}
          onChange={this.selectHandleSelectLegendItem(this.props)}
          checked={itemHasData ? checked : false}
          className="mr-2 position-relative m-0 ml-2"
          innerRef={this.checkboxRef}
          type="checkbox"
          name={`legend-item-${legendItem.id}-export`} />
        {children}
        <div className="pr-2 flex-grow-1">
          {this.formatLegendItemName(legendItem)}
        </div>
        {
          !itemHasData ?
            <small className="font-italic">
              {
                t('text.no_data_in_range')
              }
            </small>
            : null
        }
      </li>
    )
  }

  componentDidMount() {
    const { indeterminate } = this.props

    if (this.checkboxRef.current) {
      this.checkboxRef.current.indeterminate = indeterminate
      this.forceUpdate()
    }
  }

  componentDidUpdate() {
    const { indeterminate } = this.props

    if (this.checkboxRef.current &&
      this.checkboxRef.current.indeterminate !== indeterminate) {
      this.checkboxRef.current.indeterminate = indeterminate
      this.forceUpdate()
    }
  }

  formatLegendItemName(legendItem) {
    const { t } = this.props

    switch (legendItem.type) {
      case LEGEND_ITEM_TYPE_CHANNEL:
        return t(`common/text:text.asset_channel_${legendItem.sourceChannel}`)

      default:
        return legendItem.name
    }
  }

  deviceListItemHasData = (data, sourceId) => {
    return String(data.deviceId) === String(sourceId)
  }

  networkAssetListItemHasData = (data, sourceId) => {
    return String(data.networkAssetId) === String(sourceId)
  }

  networkAssetChanneListItemHasSubsetData = (data, sourceId, sourceChannel) => {
    return String(data.networkAssetId) === String(sourceId) &&
      data.channel === sourceChannel
  }

  networkAssetChanneListItemHasCpisData = (data, item) => {
    return String(data.networkAssetId) === String(item.sourceId) &&
      data.logicalChannel === item.sourceChannel
  }

  groupListItemHasData = (data, legendItem) => {
    if (legendItem.source.isDeviceGroup()) {
      return legendItem.children
        .some(child => this.deviceListItemHasData(data, child.sourceId))
    } else if (legendItem.source.isNetworkAssetGroup()) {
      return legendItem.children
        .some(child => {
          if (child.isFromNetworkAssetChannel()) {
            return this.networkAssetChanneListItemHasSubsetData(
              data,
              child.sourceId,
              child.sourceChannel
            )
          } else if (child.isFromNetworkAsset()) {
            return this.networkAssetListItemHasData(data, child.sourceId)
          }

          return false
        })
    }

    analytics.logError(
      'unknown export legend list item group type',
      legendItem.source.members.type
    )
    return false
  }

  legendItemHasPressureSubsetData = (data, item) => {
    if (data.data.length === 0) {
      return false
    }

    if (item.isFromDevice()) {
      return this.deviceListItemHasData(data, item.sourceId)
    } else if (item.isFromNetworkAssetChannel()) {
      return this.networkAssetChanneListItemHasSubsetData(
        data,
        item.sourceId,
        item.sourceChannel
      )
    } else if (item.isFromNetworkAsset()) {
      return this.networkAssetListItemHasData(data, item.sourceId)
    } else if (item.isFromGroup()) {
      return this.groupListItemHasData(data, item)
    }

    return false
  }

  legendItemHasCpisData = (data, item) => {
    if (data.chunkedData.length === 0) {
      return false
    }

    return this.networkAssetChanneListItemHasCpisData(data, item)
  }

  selectSelectableLegendItems = createSelector(
    [get('legendItem')],
    legendItem => {
      if (!legendItem.hasChildren()) {
        return [legendItem]
      } else {
        return flatMap(child => {
          return child.hasChildren() ? child.children : child
        })(legendItem.children)
      }
    }
  )

  selectSelectableLegendItemsWithData = createSelector(
    [this.selectSelectableLegendItems, get('dataResult')],
    (selectableLegendItems, dataResult) => {
      if (dataResult.wasSuccessful()) {
        return selectableLegendItems.filter(item => {
          return dataResult.data.some(data => {
            if (data.__typename === 'NetworkAssetCpisSubsetDataItem') {
              return this.legendItemHasCpisData(data, item)
            } else {
              return this.legendItemHasPressureSubsetData(data, item)
            }
          })
        })
      }

      return []
    }
  )

  selectLegendItemHasData = createSelector(
    [this.selectSelectableLegendItemsWithData, get('dataType')],
    (legendItemChildrenWithData, dataType) =>
      dataType === EXPORT_RAW_DATA_TYPE ||
        legendItemChildrenWithData.length !== 0
  )

  selectHandleSelectLegendItem = createSelector(
    [
      this.selectSelectableLegendItemsWithData,
      get('getHandleSelectLegendItem'),
    ], (selectableLegendItemsWithData, getHandleSelectLegendItem) => {
      return getHandleSelectLegendItem(map('id')(
        selectableLegendItemsWithData
      ))
    }
  )
}

export default withTranslation([
  'src/analysis_path/pressure_analysis_path/export_legend_item',
  'common/text',
])(ExportLegendItem)
