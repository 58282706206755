import React from 'react'
import {
  Input,
  FormGroup,
  UncontrolledPopover,
  PopoverBody,
} from 'reactstrap'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'

import InfoButton from '@@src/components/buttons/info_button'

import styles from './alert_setting.css'

/**
 * Create form field for tenant settings
 * @param {Boolean} fieldBool - Field 'enable' value
 * @param {Function} fieldBoolOnChange - Field 'enable' on change
 * @param {Number} fieldValue - Field value, nullable to disable field
 * @param {Function} fieldValueOnChange - Field value on change
 * @param {String} fieldLegend - Legend for fieldset
 * @param {String} fieldInfo - Info for fieldset
 * @param {String} boundString - String to translate upper/lower bound
 * @param {String} unit - measurement unit, not rendered if undefined
 * @param {Number} min - minimum field value
 * @param {Number} max - maximum field value
 * @param {String} name - field name
 * Note that as the select is a dropdown, the 'enable' field will be handled as a string.
*/

class AlertSetting extends React.PureComponent {

  minInputRestriction = (e) => {
    const { max, min, fieldValueOnChange } = this.props
    if (e.target.value > max) {
      e.target.value = max
    }
    if (e.target.value < min) {
      e.target.value = min
    }
    fieldValueOnChange(e)
  }

  renderValueFields = (isRendering) => {
    // If enable is false, do not render field
    if (isRendering === 'false' || !isRendering) { return null }

    const {
      disabled,
      fieldValue,
      fieldValueOnChange,
      boundString,
      unit,
      min,
      max,
      name,
    } = this.props

    // If there is no field value (only a boolean), do not render field
    if (fieldValue === null) { return null }

    return (
      <React.Fragment>
        <span className="mr-3">
          {boundString}
        </span>

        <FormGroup className="mr-2 mb-0">
          <Input
            className={styles['number-input']}
            type="number"
            value={fieldValue}
            onChange={fieldValueOnChange}
            min={min}
            max={max}
            step="1"
            name={name}
            onBlur={this.minInputRestriction}
            disabled={disabled}
          />
        </FormGroup>

        {
          unit ? (
            <span className="mr-3">
              {unit}
            </span>
          ) : null
        }
      </React.Fragment>
    )
  }

  render() {
    const {
      disabled,
      fieldLegend,
      fieldBool,
      fieldBoolOnChange,
      fieldInfo,
      name,
      t,
    } = this.props

    const targetId = `${name}-popover`

    return (
      <FormGroup tag="fieldset">
        <div className="d-inline-flex align-items-start">
          <legend className="h6 mr-2 mt-1">
            {fieldLegend}
          </legend>
          <InfoButton id={targetId} onClick={e => e.preventDefault()} />
          <UncontrolledPopover
            trigger="focus"
            placement="top-start"
            target={targetId}>
            <PopoverBody>
              {fieldInfo}
            </PopoverBody>
          </UncontrolledPopover>
        </div>

        <div className="d-flex align-items-center mb-2">
          <FormGroup className="mr-3 mb-0">
            <Input
              type="select"
              value={fieldBool}
              disabled={disabled}
              onChange={fieldBoolOnChange}
              name={`${name}-enable`}>
              <option value="true">
                {t('common/text:options.enable')}
              </option>
              <option value="false">
                {t('common/text:options.disable')}
              </option>
            </Input>
          </FormGroup>

          {this.renderValueFields(fieldBool)}
        </div>
      </FormGroup>
    )
  }
}

export default compose(
  withTranslation([
    'common/text',
  ]),
)(AlertSetting)
