import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import ReactTooltip from 'react-tooltip'
import { Progress } from 'reactstrap'

import styles from './analysis_graph_progress_bar.css'

export default class AnalysisGraphProgressBar extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    text: PropTypes.string,
    color: PropTypes.oneOf(['info', 'warning', 'danger', 'success']).isRequired,
    progress: PropTypes.number,
    errorDetail: PropTypes.string,
  }

  render() {
    const { className, color, progress, text, errorDetail } = this.props

    return (
      <div className={classnames(styles.container, className)}>
        <span className={styles.text}>{text}</span>

        &nbsp;

        <Progress
          value={progress}
          color={color}
          style={progress === 100 ? { width: '0px' } : undefined}
          striped={progress !== 100}
          animated={progress !== 100}
          className={classnames({
            [styles.progress]: progress !== 100,
            [styles['progress-done']]: progress === 100,
          })}/>

        <i
          data-tip={errorDetail}
          className={classnames(styles['progress-icon'], {
            'fal fa-clock': progress < 100,
            'fal fa-check': progress === 100,
            'fas fa-exclamation-circle': color === 'danger',
          })}>
        </i>

        {
          color === 'danger' ? (
            <ReactTooltip effect="solid" place="bottom" />
          ) : null
        }
      </div>
    )
  }
}
