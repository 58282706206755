import React from 'react'
import PropTypes from 'prop-types'
import {
  Modal, ModalBody, Button, Col,
  Row, FormGroup, ModalFooter, ModalHeader,
  Label, Input,
} from 'reactstrap'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'

import styles from './map_config_modal.css'
import { MAP_TILES, TILE_TYPES } from '../../utils/map_utils'

/**
 * Map Config Modal, button to open a modal for changing map settings
 * @param {Function} changeSettings - Function to change map settings. Currently a setState function passed from parent
 */

class MapConfigModal extends React.PureComponent {

  static propTypes = {
    changeSettings: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      isOpen: false,
      tileType: MAP_TILES,
    }
  }

  handleToggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }

  onSelectMapTile = tileType => {
    this.setState({
      tileType,
    })
  }

  submitMapChanges = async () => {
    const { changeSettings } = this.props
    const { tileType } = this.state

    await changeSettings({
      tileType,
    })

    this.setState({
      isOpen: false,
    })
  }

  render() {
    const { t } = this.props
    const { isOpen, tileType } = this.state

    return (
      <React.Fragment>
        <Modal isOpen={isOpen} toggle={this.handleToggle}>
          <ModalHeader toggle={this.handleToggle}>
            {t('heading.map_settings')}
          </ModalHeader>
          <ModalBody>
            <Col>
              <Row>
                <FormGroup
                  tag="fieldset"
                  className={styles['tile-radio-buttons']}>
                  <legend className={styles['tile-legend']}>
                    {t('field.map_tiles')}
                  </legend>
                  {TILE_TYPES.map(tileValue => (
                    <Label
                      key={tileValue}
                      className="ml-4 mb-0">
                      <Input
                        type="radio"
                        name="tile-type"
                        value={tileValue}
                        checked={tileType === tileValue}
                        name={`tile-select-${tileValue}`}
                        onChange={
                          () => this.onSelectMapTile(tileValue)
                        }/>
                      <span className={styles['tile-radio-button']}>
                        {t(`options.${tileValue}`)}
                      </span>
                    </Label>
                  ))}
                </FormGroup>
              </Row>
            </Col>
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={this.handleToggle}
              name="map-modal-close"
            >
              {t('buttons.cancel')}
            </Button>
            <Button
              color="primary"
              onClick={this.submitMapChanges}
              name="map-modal-save"
            >
              {t('buttons.save_changes')}
            </Button>
          </ModalFooter>
        </Modal>
        <Button
          color="light"
          size="lg"
          onClick={this.handleToggle}
          name="map-modal-open"
        >
          <i className="far fa-layer-group"></i>
        </Button>
      </React.Fragment>
    )
  }
}

export default compose(
  withTranslation([
    'src/components/maps/map_config_modal',
  ])
)(MapConfigModal)
