export class FeatureFlagsForTenant {
  constructor(props) {
    this.anomaliesFeatureEnabled = props.anomaliesFeatureEnabled || false
    this.devicesManagementListViewV2Enabled = props.devicesManagementListViewV2Enabled || false
    this.networkAssetsManagementListViewV2Enabled = props.networkAssetsManagementListViewV2Enabled || false
  }

  static ofEmpty() {
    return new FeatureFlagsForTenant({})
  }

  isEnabled(featureFlag) {
    return (this.hasOwnProperty(featureFlag) && typeof this[featureFlag] === 'boolean' && this[featureFlag]) || false
  }
}
