import 'leaflet/dist/leaflet.css'
import 'leaflet.markercluster/dist/MarkerCluster.css'
import 'leaflet.markercluster/dist/MarkerCluster.Default.css'
import moment from 'moment'
import 'moment-timezone'
import 'moment/locale/en-gb'
import 'moment/locale/es'
import 'moment/locale/fr'
import 'moment/locale/nl'
import 'moment/locale/it'
import '@rschedule/standard-date-adapter/setup'

import '@fortawesome/fontawesome-pro/css/all.css'
import 'inflowmatix-bootstrap-theme/scss/inflowmatix.scss'

moment.locale('en-gb')
