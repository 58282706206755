import React from 'react'
import { withTranslation } from 'react-i18next'
import { CardText } from 'reactstrap'

function SummaryPanelEmpty({ t }) {
  return (
    <CardText>
      {t('text.no_results')}
    </CardText>
  )
}

export default withTranslation([
  'src/components/summary/summary_panel_empty',
])(SummaryPanelEmpty)
