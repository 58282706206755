import React from 'react'
import { get } from 'lodash/fp/object'
import { push } from 'connected-react-router'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'

import Device from '@@src/api/presenters/device'
import NetworkAsset from '@@src/api/presenters/network_asset'
import { GroupDetailsType } from '@@src/api/presenters/group'
import transformProps from '@@src/components/transform_props'
import {
  createCreateSelectParsedSearchParam, mergeSearchParams,
} from '@@src/utils'
import routes from '../routes'

export const STORAGE_KEY = '@inflownet/last_data_sources'

export default function withDataSources(transformToProps = () => { }) {
  return function createWithDataSourcesContainerWithComponent(Component) {
    class ComponentWithDataSources extends React.PureComponent {
      render() {
        const { children } = this.props

        return (
          <Component
            {...this.props}
            updateDataSources={this.updateDataSources}>
            {children}
          </Component>
        )
      }

      updateDataSources = sources => {
        this.props.dispatchUpdateDataSources(this.props, sources)
      }
    }

    return compose(
      transformProps(() => ({ localStorage }) => ({
        localStorage: localStorage === undefined ?
          window.localStorage : localStorage,
      })),
      connect(
        createCreateMapStateToProps(transformToProps),
        mapDispatchToProps
      ),
    )(ComponentWithDataSources)
  }
}

function createCreateMapStateToProps(transformToProps) {
  return function createMapStateToProps() {
    const createSelectParsedSearchParam =
      createCreateSelectParsedSearchParam((_s, { location }) => location.search)

    const selectDataSourceIdsWithFilters = createSelector(
      [
        createSelectParsedSearchParam('d', []),
        (_, props) => props.localStorage,
      ],
      (rawResult, localStorage) => {
        const result = ([]).concat(rawResult)

        if (!result || result.length === 0) {
          const storedItem = localStorage.getItem(STORAGE_KEY)

          if (storedItem) {
            return JSON.parse(storedItem)
          } else {
            return []
          }
        } else {
          return ([]).concat(result)
        }
      }
    )

    const selectDataSourceIds = createSelector(
      [selectDataSourceIdsWithFilters],
      dataSourceIdsWithFilters => {
        return dataSourceIdsWithFilters.filter(id => {
          return !NetworkAsset.isNetworkAssetDataSourceChannelId(id)
        })
      }
    )

    // for legacy reasons, this is kept here despite the filters being
    // completely superseded by the visibility toggle, to avoid parsing issues
    // with old URLs
    const selectParsedDataSourceIdsWithFilters = createSelector(
      [selectDataSourceIdsWithFilters],
      dataSourceIdsWithFilters => {
        return dataSourceIdsWithFilters.reduce((acc, idsWithFilters) => {
          const match = idsWithFilters.match(/^([^\(]+)(\([\w,]*\))?$/)
          const dataSourceId = match[1]

          if (Device.isDeviceDataSourceId(dataSourceId)) {
            const deviceId = Device.parseIdFromDataSourceId(dataSourceId)

            return {
              ...acc,
              deviceIds: acc.deviceIds.concat(deviceId),
            }
          } else if (NetworkAsset.isNetworkAssetDataSourceId(dataSourceId)) {
            const networkAssetId =
              NetworkAsset.parseIdFromDataSourceId(dataSourceId)

            return {
              ...acc,
              networkAssetIds: acc.networkAssetIds.concat(networkAssetId),
            }
          } else if (
            NetworkAsset.isNetworkAssetDataSourceChannelId(dataSourceId)
          ) {
            const channelFilter = match[2]
              .replace(/^\(/, '').replace(/\)$/, '')
              .split(',').filter(a => a)
            const networkAssetId =
              NetworkAsset.parseIdFromDataSourceChannelId(dataSourceId)

            return {
              ...acc,
              channelFilters: {
                ...acc.channelFilters,
                [networkAssetId]: channelFilter,
              },
            }
          } else if (
            GroupDetailsType.isGroupDetailsDataSourceId(dataSourceId)
          ) {
            const groupId =
              GroupDetailsType.parseIdFromDataSourceId(dataSourceId)

            return {
              ...acc,
              groupIds: acc.groupIds.concat(groupId),
            }
          } else {
            throw new Error(`Unrecognised data source: "${dataSourceId}"`)
          }
        }, {
          networkAssetIds: [],
          deviceIds: [],
          channelFilters: [],
          groupIds: [],
        })
      }
    )

    const selectDeviceIds = createSelector(
      [selectParsedDataSourceIdsWithFilters],
      get('deviceIds')
    )

    const selectNetworkAssetIds = createSelector(
      [selectParsedDataSourceIdsWithFilters],
      get('networkAssetIds')
    )

    const selectGroupIds = createSelector(
      [selectParsedDataSourceIdsWithFilters],
      get('groupIds')
    )

    return function mapStateToProps(state, ownProps) {
      return transformToProps({
        groupIds: selectGroupIds(state, ownProps),
        deviceIds: selectDeviceIds(state, ownProps),
        dataSourceIds: selectDataSourceIds(state, ownProps),
        networkAssetIds: selectNetworkAssetIds(state, ownProps),
      })
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchUpdateDataSources(props, sources) {
      const { location, localStorage } = props
      const dataSourceIds = sources.map(source => source.dataSourceId)

      if(dataSourceIds.length === 0 && location.pathname !== routes.analysisListPath()) {
        localStorage.setItem(STORAGE_KEY, '')

        dispatch(push({
          search: mergeSearchParams(location.search, {
            d: [],
          }),
          pathname: routes.analysisListPath(),
        }))
      } else {
        localStorage.setItem(STORAGE_KEY, JSON.stringify(dataSourceIds))

        dispatch(push({
          search: mergeSearchParams(location.search, {
            d: dataSourceIds,
          }),
          pathname: location.pathname,
        }))
      }
    },
  }
}
