import { values } from 'lodash/fp/object'

import Device from '@@src/api/presenters/device'

import {
  mapAssetTypeToLogicalChannels,
  translateLastCommunication as translateLastCommunicationUtil,
} from '@@src/utils'

export default class NetworkAsset {
  constructor(data) {
    Object.assign(this, data)

    this.uuid = `a.${data.id}`
    this.metadata = data.metadata || []
    this.groups = data.groups ? data.groups : []

    this.lastCommunicationTime = data.lastCommunicationTime
      ? new Date(data.lastCommunicationTime)
      : null

    this.currentAlertsMuteEnd = data.currentAlertsMuteEnd
      ? new Date(data.currentAlertsMuteEnd)
      : null

    this.installations = (data.installations || []).map((i) =>
      i.withNetworkAsset(this)
    )

    Object.freeze(this)
  }

  static VOIDED_STATE = 'voided'

  static CONGRUITY_TYPE_PREFIX = 'congruity_'

  get dataSourceId() {
    return this.uuid
  }

  get elevation() {
    return String(this.location.altitude)
  }

  get coordinates() {
    return `${this.location.latitude}, ${this.location.longitude}`
  }

  get availableChannels() {
    return mapAssetTypeToLogicalChannels(this.assetType)
  }

  get currentInstallations() {
    return this.installations.filter((installation) => {
      return installation.end === null
    })
  }

  get assignableChannelCount() {
    return this.availableChannels.length - this.currentInstallations.length
  }

  get mostSevereDeviceIssue() {
    const mostSevereIssues = this.installations
      .map(
        (i) =>
          i.device &&
          !i.device.isDecommissioned &&
          i.end === null &&
          i.device.mostSevereIssue
      )
      .filter((a) => a)

    return Device.getMostSevereIssue(mostSevereIssues)
  }

  isCongruityAsset() {
    if (!this.assetType) {
      return false
    }

    return this.assetType.startsWith(NetworkAsset.CONGRUITY_TYPE_PREFIX)
  }

  translateAssetType(t) {
    return t(`common/text:text.${this.assetType}`)
  }

  dataSourceIdForChannel(channel) {
    const channels = this.availableChannels

    if (!channels.includes(channel) || channels.length === 1) {
      return this.dataSourceId
    } else {
      return `${this.dataSourceId}.${channel}`
    }
  }

  formattedCoordinates(format) {
    switch (format) {
      case 'latlng': {
        const lat = this.location.latitude
        const lng = this.location.longitude
        return `${lat.toFixed(5)}, ${lng.toFixed(5)}`
      }

      default:
        throw new Error(`Unsupported format: ${format}`)
    }
  }

  metadataFor(key) {
    const metaItem = this.metadata.find((d) => d.key === key)

    return metaItem ? metaItem.value : undefined
  }

  installationsAtTime(date) {
    return this.installations.filter((installation) => {
      return installation.wasActiveAtTime(date)
    })
  }

  installationForChannel(channel, date = new Date()) {
    return this.installationsAtTime(date).find((installation) => {
      return values(installation.channelMap).indexOf(channel) !== -1
    })
  }

  translateLastCommunication(t) {
    return translateLastCommunicationUtil(t, this.lastCommunicationTime)
  }

  static MAX_ASSET_ID_LENGTH = 32

  static from(rawData) {
    return new NetworkAsset(rawData)
  }

  static isNetworkAssetDataSourceId(dataSourceId = '') {
    return String(dataSourceId).match(/^a\.\d+$/)
  }

  static isNetworkAssetDataSourceChannelId(dataSourceId = '') {
    return String(dataSourceId).match(/^c\.a\.\d+(\([a-z_,]*\))?$/)
  }

  static parseIdFromDataSourceId(dataSourceId = '') {
    return parseInt(String(dataSourceId).replace(/^a\./, ''), 10)
  }

  static parseIdFromDataSourceChannelId(dataSourceId = '') {
    return parseInt(String(dataSourceId).replace(/^c\.a\./, ''), 10)
  }
}
