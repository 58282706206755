import React from 'react'
import TenantDeviceSessionConfigurationForm from '@@src/components/device_configuration/tenant_device_session_configuration_form'
import { TenantConfigContext } from '@@src/components/tenant_config_provider'
import Device from '@@src/api/presenters/device'

class DevicesSettings extends React.PureComponent {
  render() {
    const { t } = this.props

    return (
      <React.Fragment>
        <h2 className="h2 mb-0">{t('headings.devices')}</h2>
        <hr className="mb-5" />
        <section name="tenant-devices">
          <h3 className="h4 mb-4">{t('headings.default_configuration')}</h3>
          <TenantConfigContext.Consumer>
            {(config = {}) => {
              const { dateFrom, secondsInterval, timeZone } =
                config.deviceConfig || {}

              return (
                <TenantDeviceSessionConfigurationForm
                  selectedConfiguration={{
                    dateFrom,
                    secondsInterval,
                    timeZone,
                    sourceAggregate: Device.CONFIGURATION_SOURCE_TENANT_TYPE,
                  }}
                  dateFrom={dateFrom}
                  secondsInterval={secondsInterval}
                />
              )
            }}
          </TenantConfigContext.Consumer>
        </section>
      </React.Fragment>
    )
  }
}

export default DevicesSettings
