import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { get } from 'lodash/fp/object'
import { noop } from 'lodash/fp/util'
import { compose } from 'redux'
import { createSelector } from 'reselect'
import { scaleLinear, scaleTime } from 'd3-scale'
import { withTranslation } from 'react-i18next'
import { Button } from 'reactstrap'

import Graph from '@@src/components/graphs/graph'
import LeftAxis from '@@src/components/graphs/left_axis'
import GraphItem from '@@src/components/graphs/graph_item'
import AsyncResult from '@@src/utils/async_result'
import DragEventLayer, { ONLY_X, X_AND_Y, NONE }
from '@@src/components/graphs/drag_layer/drag_event_layer'
import GraphStateOverlay from '@@src/components/graphs/graph_state_overlay'
import PressureEventsAnomalies from '../pressure_events_anomalies_container'
import AnomalyBounds from './anomaly_layer/anomaly_bounds'
import AlertsDisplayLayer from './alerts_display_layer/alerts_display_layer'
import EventDetailCarousel from '@@src/components/graphs/carousels/event_detail_carousel/event_detail_carousel'
import AnomalyDetailCarousel from '../../../components/graphs/carousels/anomaly_detail_carousel/anomaly_detail_carousel'
import GraphPlotMeasurementRuler from '@@src/components/graphs/measurement_ruler/graph_plot_measurement_ruler'
import InteractionControllerLayer
from './interaction_controller_layer'
import PressureSubsetGraphToolbar
from './pressure_subset_graph_toolbar'
import HistogramLayer
from './histogram_layer/histogram_layer'
import RequestRawDataModal from
'@@src/components/graphs/modals/request_raw_data_modal'
import TransientLocalisationModalController from
'@@src/components/graphs/modals/transient_localisation_modal_controller' // eslint-disable-line max-len
import TimeSeriesRawDataListItems from
'@@src/api/presenters/time_series_raw_data_list_items'
import SubsetPlotsLayer from './subset_plots_layer'
import PressureSubsetGraphAlertGroup
from './pressure_subset_graph_alert_group'
import withNetworkAssetsAndGraphItems
from './with_network_assets_and_graph_items_container'
import withPlotVisibilityOptions from './with_plot_visibility_options'
import { DEFAULT_TIMEZONE } from '@@src/utils'
import { htmlLabelFor, svgLabelFor } from '@@src/components/graphs/labels'
import { HISTOGRAM_TYPES } from '../constants'
import BottomTimeAxis from '@@src/components/graphs/bottom_time_axis'
import RawDataRequestsLayer
from './raw_data_requests_layer/raw_data_requests_layer'
import withEventsAnomaliesData from '../with_events_anomalies_data'
import EventFilterAlert from '@@src/components/alerts/event_filter_alert'
import userPermissions from '@@src/components/permissions/user_permissions'
import withPlotDataWrapper from './data_containers/plot_data_wrapper'
import {
  VIEW_TYPE_EVENTS,
  VIEW_TYPE_ANOMALY_LIST,
  SETTING_DISPLAY_EVENTS,
  SETTING_DISPLAY_ALERTS,
  SETTING_DISPLAY_ANOMALIES,
  SETTING_MIN_MAX_LINE,
  SETTING_CURSOR_TOOL_DISPLAY,
  SETTING_AUTO_Y_AXIS_ZOOM,
  ZOOM_MODIFIER,
} from '../constants'

import styles from './pressure_subset_graph.css'

class PressureSubsetGraph extends React.PureComponent {
  static defaultProps = {
    timezone: DEFAULT_TIMEZONE,
    onAxesChange: noop,
    isActiveEventExpanded: false,
    isActiveAnomalyExpanded: false,
    onChangeViewType: noop,
    setActiveExpandedEventId: noop,
    setActiveExpandedAnomalyId: noop,
    toggleShowBoundsForActiveAnomaly: noop,
    selectedItemAnomaly: {},
    allAnomalies: [],
    anomalyResult: AsyncResult.success({ anomalies: [] }),
    anomalyBoundsResult: AsyncResult.success({ getAnomaliesBounds: [] }),
    setActiveGraphItemId: noop,
    setFocusedLegendItem: noop,
    onClearEvents: noop,
    onCopyBoundsIntoLink: noop,
    onDismissDragAreaSummary: noop,
    onChangeTimeRange: noop,
  }

  static propTypes = {
    timezone: PropTypes.string.isRequired,
    startTime: PropTypes.instanceOf(Date).isRequired,
    endTime: PropTypes.instanceOf(Date).isRequired,
    graphItems: PropTypes.arrayOf(PropTypes.instanceOf(GraphItem)).isRequired,
    anomalyBoundsResult: PropTypes.instanceOf(AsyncResult).isRequired,
    onAxesChange: PropTypes.func.isRequired,
    maxPressure: PropTypes.number,
    minPressure: PropTypes.number,
    activeEventId: PropTypes.number,
    activeAnomalyId: PropTypes.number,
    isActiveEventExpanded: PropTypes.bool.isRequired,
    isActiveAnomalyExpanded: PropTypes.bool.isRequired,
    onChangeViewType: PropTypes.func.isRequired,
    setActiveExpandedEventId: PropTypes.func.isRequired,
    setActiveExpandedAnomalyId: PropTypes.func.isRequired,
    selectedItemAnomaly: PropTypes.object,
    allAnomalies: PropTypes.array,
    toggleShowBoundsForActiveAnomaly: PropTypes.func.isRequired,
    listRawDataRequestsResult: PropTypes.instanceOf(AsyncResult).isRequired,
    refetchRawDataRequests: PropTypes.func.isRequired,
    setFocusedLegendItem: PropTypes.func.isRequired,
    onClearEvents: PropTypes.func.isRequired,
    eventIds: PropTypes.arrayOf(PropTypes.number),
    measureBounds: PropTypes.array,
    onCopyBoundsIntoLink: PropTypes.func.isRequired,
    onDismissDragAreaSummary: PropTypes.func.isRequired,
    onChangeTimeRange: PropTypes.func.isRequired,
  }
  constructor(props) {
    super(props)

    this.state = {
      xAxes: null,
      yAxes: null,
      selection: null,
      isRawDataModalOpen: false,
      isTransientLocalisationModalOpen: false,
      histogramOption: HISTOGRAM_TYPES[0],
    }
  }

  render() {
    const {
      anomalyData,
      className,
      t,
      timezone,
      alertsResult,
      legendItemsResult,
      networkAssetGraphItems,
      onChangeViewType,
      fatchingInfo,
      anomalyResult,
      graphItems,
      startTime,
      endTime,
      resolution,
      listRawDataRequestsResult,
      minPressure,
      maxPressure,
      pressureEventsWithData,
      isActiveEventExpanded,
      isActiveAnomalyExpanded,
      activeEventId,
      totalEventCount,
      eventIndexInSet,
      setActiveEventForSlice,
      activeAnomalyId,
      toggleShowBoundsForActiveAnomaly,
      boundsShown,
      allAnomalies,
      selectedItemAnomaly,
      selectedItemBounds,
      eventIds,
      onClearEvents,
      rawDataRequestsResult,
      cycleEventsDisplayMode,
      onChangeTimeRange,
      permissions,
      visibilitySettings,
    } = this.props

    const dragBehaviour =
      visibilitySettings[SETTING_AUTO_Y_AXIS_ZOOM] === true ? ONLY_X : X_AND_Y
    const isOnlyRawData = this.selectIsOnlyRawData(this.props)
    const isRawDataAvailable = this.selectIsRawDataAvailable(this.props)
    const pressureUnits = fatchingInfo.units.pressure
    const canViewAlerts = permissions.includes('can_view_alerts')
    const {
      isRawDataModalOpen,
      isTransientLocalisationModalOpen,
      selection,
      histogramOption,
    } = this.state
    return (
      <React.Fragment>
        {isRawDataModalOpen && selection ? this.renderRawDataModal() : null}
        {isTransientLocalisationModalOpen && selection
          ? this.renderTransientLocalisationModal()
          : null}

        <GraphStateOverlay
          className={classnames(styles['pressure-subset-graph'], className)}
          graphItems={graphItems}
          noDataText={t('text.no_pressure_data')}
          dataSourcesResult={legendItemsResult}
        >
          <PressureSubsetGraphToolbar
            className={styles.toolbar}
            resolution={resolution}
            alertsResult={alertsResult}
            isOnlyRawData={isOnlyRawData}
            displayRawData={this.props.displayRawData}
            displayEvents={this.props.displayEvents}
            displayAlerts={this.props.displayAlerts}
            displayAnomalies={this.props.displayAnomalies}
            histogramOption={histogramOption}
            isRawDataAvailable={isRawDataAvailable}
            rawDataRequestsResult={rawDataRequestsResult}
            cycleEventsDisplayMode={cycleEventsDisplayMode}
            pressureEventsWithData={pressureEventsWithData}
            anomalyResult={anomalyResult}
            onChangeOptions={this.props.onChangeVisibilityOptions}
            setHistogramOption={this.onHistogramOptionChange}
          />

          <PressureSubsetGraphAlertGroup
            className={styles['alert-group']}
            alertsResult={alertsResult}
            rawDataRequestsResult={rawDataRequestsResult}
            pressureEventsWithData={pressureEventsWithData}
          />

          <Graph
            xScale={this.selectXScale(this.props, this.state)}
            yScale={this.selectYScale(this.props, this.state)}
            className={styles.graph}
            topPadding={90}
            leftPadding={70}
            rightPadding={20}
            bottomPadding={50}
          >
            <HistogramLayer
              histogramOption={histogramOption}
              graphItems={graphItems}
              startTime={this.props.fatchingInfo.start}
              endTime={this.props.fatchingInfo.end}
              legendItemsResult={legendItemsResult}
              alertsResult={alertsResult}
              listRawDataRequestsResult={listRawDataRequestsResult}
              anomalyData={anomalyData}
              onChangeTimeRange={onChangeTimeRange}
              pressureEvents={this.props.pressureEvents}
            />
            <LeftAxis withGuides label={svgLabelFor(pressureUnits)} />
            <BottomTimeAxis timezone={timezone} />
            {visibilitySettings[SETTING_DISPLAY_ANOMALIES] ? (
              <AnomalyBounds
                boundsShown={boundsShown}
                selectedItemBounds={selectedItemBounds}
              />
            ) : null}
            <SubsetPlotsLayer
              graphItems={graphItems}
              displayMinMaxLine={visibilitySettings[SETTING_MIN_MAX_LINE]}
            />
            {isRawDataModalOpen || isTransientLocalisationModalOpen ? (
              <DragEventLayer dragBehaviour={NONE} onDoubleClick={noop}>
                {this.renderInteractionController(dragBehaviour)}
              </DragEventLayer>
            ) : (
              <DragEventLayer
                dragBehaviour={dragBehaviour}
                onDoubleClick={this.onResetZoom}
              >
                {this.renderInteractionController(dragBehaviour)}
              </DragEventLayer>
            )}
            {canViewAlerts && visibilitySettings[SETTING_DISPLAY_ALERTS] ? (
              <AlertsDisplayLayer
                disabled={false}
                graphItems={graphItems}
                alertsResult={alertsResult}
              />
            ) : null}
            <PressureEventsAnomalies
              graphItems={graphItems}
              anomalyData={anomalyData}
              displayAnomalies={visibilitySettings[SETTING_DISPLAY_ANOMALIES]}
              pressureEventsWithData={pressureEventsWithData}
              pressureUnits={pressureUnits}
              eventIndexInSet={eventIndexInSet}
              setActiveEventForSlice={setActiveEventForSlice}
              activeAnomalyId={activeAnomalyId}
              activeEventId={activeEventId}
              isActiveEventExpanded={isActiveEventExpanded}
              isActiveAnomalyExpanded={isActiveAnomalyExpanded}
              displayEvents={visibilitySettings[SETTING_DISPLAY_EVENTS]}
              toggleShowBoundsForActiveAnomaly={
                toggleShowBoundsForActiveAnomaly
              }
              boundsShown={boundsShown}
              setActiveExpandedAnomalyId={this.props.setActiveExpandedAnomalyId}
              setActiveExpandedEventId={this.props.setActiveExpandedEventId}
            />
            <RawDataRequestsLayer
              displayRawData={this.props.displayRawData}
              listRawDataRequestsResult={listRawDataRequestsResult}
              networkAssetGraphItems={networkAssetGraphItems}
              dataSourcesResult={legendItemsResult}
              timezone={timezone}
              endTime={endTime}
              startTime={startTime}
            />
          </Graph>
          {minPressure || maxPressure ? (
            <Button
              name="reset-axes-button"
              color="primary"
              className={styles['reset-axes-button']}
              onClick={this.handleResetPressureClick}
            >
              {t('button.reset_axes')}
            </Button>
          ) : null}
          {visibilitySettings[SETTING_DISPLAY_ANOMALIES] ? (
            <AnomalyDetailCarousel
              selectedItemAnomaly={selectedItemAnomaly}
              allAnomalies={allAnomalies}
              activeAnomalyId={activeAnomalyId}
              graphItems={graphItems}
              setActiveExpandedAnomalyId={this.props.setActiveExpandedAnomalyId}
              onNavigateToAnomalyList={() =>
                onChangeViewType(VIEW_TYPE_ANOMALY_LIST)
              }
            />
          ) : null}
          {visibilitySettings[SETTING_DISPLAY_EVENTS] ? (
            <React.Fragment>
              {Array.isArray(eventIds) ? (
                <EventFilterAlert handleClickShowAll={onClearEvents} />
              ) : null}
              <EventDetailCarousel
                onClickNextEvent={this.onClickNextEvent}
                onClickPrevEvent={this.onClickPrevEvent}
                activeEventId={activeEventId}
                totalEventCount={totalEventCount}
                onNavigateToEventsList={() =>
                  onChangeViewType(VIEW_TYPE_EVENTS)
                }
              ></EventDetailCarousel>
            </React.Fragment>
          ) : null}
        </GraphStateOverlay>
      </React.Fragment>
    )
  }

  renderInteractionController = (dragBehaviour) => {
    const {
      timezone,
      setFocusedLegendItem,
      graphItems,
      refetchRawDataRequests,
      listRawDataRequestsResult,
      measureBounds,
      onCopyBoundsIntoLink,
      onDismissDragAreaSummary,
      visibilitySettings,
    } = this.props

    return (
      <InteractionControllerLayer
        onPan={this.onGraphPan}
        timezone={timezone}
        dataUnits={this.renderDataUnits()}
        graphItems={graphItems}
        onAxesChange={this.onAxesChange}
        measureBounds={measureBounds}
        dragBehaviour={dragBehaviour}
        enableCursorTool={visibilitySettings[SETTING_CURSOR_TOOL_DISPLAY]}
        renderCursorTool={this.renderCursorTool}
        setFocusedLegendItem={setFocusedLegendItem}
        onCopyBoundsIntoLink={onCopyBoundsIntoLink}
        refetchRawDataRequests={refetchRawDataRequests}
        onDismissDragAreaSummary={onDismissDragAreaSummary}
        listRawDataRequestsResult={listRawDataRequestsResult}
        setSelection={this.setSelection}
        setRawDataModalOpen={this.setRawDataModalOpen}
        setTransientLocalisationModalOpen={
          this.setTransientLocalisationModalOpen
        }
      />
    )
  }

  renderCursorTool = () => {
    const { timezone, graphItems, setFocusedLegendItem } = this.props

    return (
      <GraphPlotMeasurementRuler
        setFocusedLegendItem={setFocusedLegendItem}
        getX={get('time')}
        getYComparable={get('mean')}
        timezone={timezone}
        dataColumns={this.selectDataMeasurementColumns(this.props)}
        rawDataColumns={this.selectRawDataMeasurementColumns(this.props)}
        dataUnits={this.renderDataUnits()}
        graphItems={graphItems}
      />
    )
  }

  renderDataUnits() {
    const { fatchingInfo } = this.props

    return htmlLabelFor(fatchingInfo.units.pressure)
  }

  setSelection = (selection) => {
    this.setState({ selection })
  }

  // Modal popup: Raw Data
  renderRawDataModal = () => {
    const { isRawDataModalOpen, selection } = this.state
    const { refetchRawDataRequests, listRawDataRequestsResult, t } = this.props
    return isRawDataModalOpen ? (
      <RequestRawDataModal
        listRawDataRequestsResult={listRawDataRequestsResult}
        onRequestSuccess={refetchRawDataRequests}
        isOpen={isRawDataModalOpen}
        handleToggle={this.handleRawDataModalToggle}
        timeFrom={selection.startDate}
        timeTo={selection.endDate}
        networkAssetGraphItems={selection.networkAssetGraphItems}
        t={t}
      />
    ) : null
  }

  setRawDataModalOpen = (state) => {
    this.setState({ isRawDataModalOpen: state })
  }

  handleRawDataModalToggle = () => {
    this.setRawDataModalOpen(false)
  }

  // Modal popup: Transient Localisation
  renderTransientLocalisationModal = () => {
    const { isTransientLocalisationModalOpen, selection } = this.state
    const { listRawDataRequestsResult, t } = this.props
    return isTransientLocalisationModalOpen ? (
      <TransientLocalisationModalController
        isOpen={isTransientLocalisationModalOpen}
        handleToggle={this.handleTransientLocalisationModalToggle}
        t={t}
        listRawDataRequestsResult={listRawDataRequestsResult}
        timeFrom={selection.startDate}
        timeTo={selection.endDate}
        networkAssetGraphItems={selection.networkAssetGraphItems}
      />
    ) : null
  }

  setTransientLocalisationModalOpen = (state) => {
    this.setState({ isTransientLocalisationModalOpen: state })
  }

  handleTransientLocalisationModalToggle = () => {
    this.setTransientLocalisationModalOpen(false)
  }

  handleResetPressureClick = () => {
    this.props.onAxesChange({
      xAxes: null,
      yAxes: {
        start: undefined,
        end: undefined,
      },
    })
  }

  selectPressureDataErrorText = ({ t, graphItemsResult }) => {
    return graphItemsResult.wasFailure()
      ? t('text.error_loading_pressure_data')
      : null
  }

  selectDataMeasurementColumns = createSelector(
    [get('t'), get('displayMinMaxLine')],
    (t, displayMinMaxLine) => {
      return [
        displayMinMaxLine ? [t('labels.min'), get('min')] : null,
        [t('labels.mean'), get('mean')],
        displayMinMaxLine ? [t('labels.max'), get('max')] : null,
      ].filter((a) => a)
    }
  )

  selectRawDataMeasurementColumns = createSelector([get('t')], (t) => [
    [t('labels.raw'), get('value')],
  ])

  selectXScale = createSelector(
    [get('startTime'), get('endTime'), (_props, { xAxes }) => xAxes],
    (startTime, endTime, xAxes) =>
      xAxes
        ? scaleTime().domain([xAxes.start, xAxes.end])
        : scaleTime().domain([startTime, endTime])
  )

  selectMinMaxPressure = createSelector(
    [get('graphItems'), get('maxMinPressureBounds')],
    (graphItems, maxMinPressureBounds) => {
      const computed = graphItems.reduce(
        (acc2, item) => {
          return item.dataSegments.reduce((acc1, dataSegment) => {
            return dataSegment.data.reduce((acc, point) => {
              return {
                minPressure: Math.min(acc.minPressure, point.min),
                maxPressure: Math.max(acc.maxPressure, point.max),
              }
            }, acc1)
          }, acc2)
        },
        { minPressure: 0, maxPressure: 0 }
      )

      let minPressure = computed.minPressure
      let maxPressure = computed.maxPressure

      minPressure = Math.min(minPressure, maxMinPressureBounds.min)
      maxPressure = Math.max(maxPressure, maxMinPressureBounds.max)

      const graphMaxPressure =
        maxPressure === 0
          ? 10
          : maxPressure + 0.05 * (maxPressure - minPressure)

      const graphMinPressure = minPressure - 0.05 * (maxPressure - minPressure)

      return { minPressure: graphMinPressure, maxPressure: graphMaxPressure }
    }
  )

  selectYScale = createSelector(
    [
      get('minPressure'),
      get('maxPressure'),
      this.selectMinMaxPressure,
      (_props, { yAxes }) => yAxes,
    ],
    (minPressureProp, maxPressureProp, { minPressure, maxPressure }, yAxes) => {
      if (yAxes) {
        return scaleLinear().domain([yAxes.start, yAxes.end])
      }

      if (
        Number.isFinite(minPressureProp) &&
        Number.isFinite(maxPressureProp)
      ) {
        return scaleLinear().domain([minPressureProp, maxPressureProp])
      }

      return scaleLinear().domain([
        Number.isFinite(minPressureProp) ? minPressureProp : minPressure,
        Number.isFinite(maxPressureProp) ? maxPressureProp : maxPressure,
      ])
    }
  )

  onGraphPan = ({ panEventX, panEventY }) => {
    if (panEventX) {
      this.setState({ xAxes: panEventX })
    }

    if (panEventY) {
      this.setState({ yAxes: panEventY })
    }
  }

  onAxesChange = ({ xAxes, yAxes }) => {
    if (xAxes || yAxes) {
      this.props.onAxesChange({ xAxes, yAxes })
    }

    this.setState({ xAxes: null, yAxes: null })
  }

  onHistogramOptionChange = (value) => {
    this.setState({ histogramOption: value })
  }

  selectHasAnyData = createSelector([get('graphItems')], (graphItems) =>
    graphItems.some(({ data: chunks }) =>
      chunks.some(({ data }) => data.length > 0)
    )
  )

  selectIsOnlyRawData = createSelector(
    [get('graphItems'), this.selectHasAnyData],
    (graphItems, hasAnyData) =>
      hasAnyData &&
      !graphItems.some(
        (
          { data: chunks } // eslint-disable-line max-len
        ) =>
          chunks.some(({ data }) =>
            data.some((point) => !(point instanceof TimeSeriesRawDataListItems))
          )
      )
  )

  selectIsRawDataAvailable = createSelector([get('graphItems')], (graphItems) =>
    graphItems.some(({ data: chunks }) =>
      chunks.some(({ data }) =>
        data.some((point) => point instanceof TimeSeriesRawDataListItems)
      )
    )
  )

  onResetZoom = () => {
    const { startTime, endTime, onChangeTimeRange } = this.props

    const currentRange = endTime.getTime() - startTime.getTime()
    const possibleNewEndTime =
      endTime.getTime() + 0.5 * ZOOM_MODIFIER * currentRange
    const nowTime = new Date().getTime()
    const newEndTime = new Date(
      nowTime > possibleNewEndTime ? possibleNewEndTime : nowTime
    )
    const newStartTime = new Date(
      newEndTime.getTime() - (1 + ZOOM_MODIFIER) * currentRange
    )

    onChangeTimeRange({ endTime: newEndTime, startTime: newStartTime })
  }

  onClickNextEvent = () => {
    const { activeEventId, setActiveExpandedEventId } = this.props
    setActiveExpandedEventId(activeEventId + 1, true)
  }

  onClickPrevEvent = () => {
    const { activeEventId, setActiveExpandedEventId } = this.props
    setActiveExpandedEventId(activeEventId - 1, true)
  }
}

export default compose(
  withTranslation([
    'src/analysis_path/pressure_analysis_path/pressure_subset_graph',
    'src/components/graphs/modals/request_raw_data_modal',
  ]),
  userPermissions,
  withPlotVisibilityOptions,
  withPlotDataWrapper,
  withNetworkAssetsAndGraphItems,
  withEventsAnomaliesData
)(PressureSubsetGraph)
