import { sleep } from '@@src/utils'
import { graphql } from '@apollo/client/react/hoc'
import gql from 'graphql-tag'
import { get } from 'lodash/fp/object'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { withTranslation } from 'react-i18next'
import MarkerClusterGroup from 'react-leaflet-markercluster'
import {
  Button,
  Col,
  Input,
  InputGroup,
  InputGroupText,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  UncontrolledTooltip,
} from 'reactstrap'
import { compose } from 'redux'
import { createSelector } from 'reselect'

import * as analytics from '@@src/analytics'
import { parseGraphQLResult } from '@@src/api/presenters'
import Device from '@@src/api/presenters/device'
import { MixedGroupDetailsType } from '@@src/api/presenters/group'
import AsyncResultSwitch from '@@src/components/async_result_switch'
import ErrorInfo from '@@src/components/error_info'
import DebouncedInput from '@@src/components/forms/debounced_input'
import DeviceIconWithStatus from '@@src/components/icons/device_icon_with_status'
import NetworkAssetIcon from '@@src/components/icons/network_asset_icon'
import withGoogleMapsProvider from '@@src/components/maps/google_maps_provider'
import DeviceCommissionMarker from '@@src/components/maps/markers/device_commission_marker'
import DeviceCommissionPopup from '@@src/components/maps/markers/device_commission_popup'
import NetworkAssetMarker from '@@src/components/maps/markers/network_asset_marker'
import NetworkAssetPopup from '@@src/components/maps/markers/network_asset_popup'
import StandardMap from '@@src/components/maps/standard_map'
import transformProps from '@@src/components/transform_props'
import {
  AsyncResult,
  createSelectGraphQLResult,
  mapAssetTypeToLogicalChannels,
  validAssetTypes,
} from '@@src/utils'
import FormFields from '@@src/utils/form_fields'

import styles from './add_aggregate_to_group_modal.css'

const NO_DEVICE = Device.from({})
const EMPTY_ARRAY = []
const API_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSS'
const INFLOWNET_WAIT = process.env.NODE_ENV === 'test' ? 0 : 1000

class AddAggregateToGroupModal extends React.PureComponent {
  static defaultProps = {
    onSuccess: () => {},
  }

  static propTypes = {
    onSuccess: PropTypes.func.isRequired,
    group: PropTypes.instanceOf(MixedGroupDetailsType),
    aggregateType: PropTypes.string,
  }

  render() {
    const {
      t,
      isOpen,
      devicesResult,
      networkAssetResult,
      group,
      googleMaps,
      aggregateType,
    } = this.props
    const { result, searchQuery, assetTypeFilter, selectedAggragate } =
      this.state
    const selectedAggregateId = selectedAggragate ? selectedAggragate.id : null

    return (
      <Modal
        isOpen={isOpen}
        toggle={this.onToggle}
        className={styles.container}
      >
        <ModalHeader>
          {t(`headings.title_${typeSuffixFor(group)}`, {
            groupName: group.name,
          })}
        </ModalHeader>

        <ModalBody>
          <div className={styles['modal-body-section']}>
            <Row className={styles['tab-contents-tab-row']}>
              <Col sm="6" className={styles['network-assets-list-section']}>
                {result.wasFailure() ? (
                  <ErrorInfo error={result.error} />
                ) : null}

                <Row className={styles['network-assets-list-controls']}>
                  <Col sm="5">
                    <p className={styles['network-assets-list-heading']}>
                      {t(`headings.available_${typeSuffixFor(group)}`)}
                    </p>
                  </Col>

                  <Col sm="4">
                    <InputGroup>
                      <DebouncedInput
                        type="search"
                        name="network-assets-search-input"
                        value={searchQuery}
                        debounce={200}
                        onChange={this.onChangeAggregatesQuery}
                        placeholder={t(
                          `text.search_placeholder_${typeSuffixFor(group)}`
                        )}
                      />

                      <InputGroupText>
                        <i className="fa fa-search"></i>
                      </InputGroupText>
                    </InputGroup>
                  </Col>

                  {aggregateType === 'VNetworkAsset' ? (
                    <Col sm="3">
                      <Input
                        type="select"
                        name="network-assets-filter-input"
                        value={assetTypeFilter}
                        onChange={this.onChangeAssetTypeFilter}
                      >
                        <option value="">{t('options.any')}</option>

                        {validAssetTypes().map((type) => (
                          <option key={type} value={type}>
                            {t(`common/text:text.${type}`)}
                          </option>
                        ))}
                      </Input>
                    </Col>
                  ) : null}
                </Row>

                {aggregateType === 'VNetworkAsset'
                  ? this.renderResults(
                      networkAssetResult,
                      this.renderNetworkAssetList
                  )
                  : this.renderResults(devicesResult, this.renderDevicesList)}
              </Col>

              {aggregateType === 'VNetworkAsset'
                ? this.renderNetworkAssetMap(
                    networkAssetResult,
                    selectedAggregateId,
                    googleMaps
                )
                : this.renderDevicesMap(
                    devicesResult,
                    selectedAggregateId,
                    googleMaps
                )}
            </Row>
          </div>
        </ModalBody>

        <ModalFooter className={styles['buttons-row']}>
          <Button
            name="cancel-button"
            type="button"
            color="secondary"
            onClick={this.onToggle}
            className="float-left"
          >
            {t('buttons.close_and_apply_changes')}
          </Button>
        </ModalFooter>
      </Modal>
    )
  }

  renderResults = (result, renderFunction) => {
    return (
      <AsyncResultSwitch result={result} renderSuccessResult={renderFunction} />
    )
  }

  renderNetworkAssetMap = (
    networkAssets,
    selectedNetworkAssetId,
    googleMaps
  ) => {
    return (
      <StandardMap
        zoom={this.selectMapZoom(this.props, this.state)}
        center={this.selectMapCenter(this.props, this.state)}
        className="col-sm-6 pr-0"
      >
        <MarkerClusterGroup
          showCoverageOnHover={false}
          removeOutsideVisibleBounds={true}
        >
          {networkAssets.data.map((networkAsset) => (
            <NetworkAssetMarker
              key={networkAsset.id}
              onClick={this.createOnClickNetworkAsset(networkAsset)}
              isPopupOpen={networkAsset.id === selectedNetworkAssetId}
              networkAsset={networkAsset}
            >
              <NetworkAssetPopup
                googleMaps={googleMaps}
                networkAsset={networkAsset}
              />
            </NetworkAssetMarker>
          ))}
        </MarkerClusterGroup>
      </StandardMap>
    )
  }

  renderDevicesMap = (devices, selectedAggregateId, googleMaps) => {
    return (
      <StandardMap
        zoom={this.selectMapZoom(this.props, this.state)}
        center={this.selectMapCenter(this.props, this.state)}
        className="col-sm-6 pr-0"
      >
        <MarkerClusterGroup
          showCoverageOnHover={false}
          removeOutsideVisibleBounds={true}
        >
          {devices.data
            .filter(
              (device) =>
                device.currentCommission !== null &&
                device.currentCommission !== undefined
            )
            .map((device) => (
              <DeviceCommissionMarker
                key={device.id}
                onClick={this.createOnClickMarker(device.currentCommission)}
                commission={device.currentCommission}
                isPopupOpen={device.id === selectedAggregateId}
              >
                <DeviceCommissionPopup
                  googleMaps={googleMaps}
                  commission={device.currentCommission}
                />
              </DeviceCommissionMarker>
            ))}
        </MarkerClusterGroup>
      </StandardMap>
    )
  }

  renderDevicesList = ({ data: devices }) => {
    const aggregateIdsInGroup = this.selectAggregateIdsInGroup(this.props)
    return (
      <ul className={styles['network-assets-list']}>
        {devices.map((device) => {
          const isMember = aggregateIdsInGroup.includes(device.id)
          const suffix = isMember ? '-is-member' : ''

          return (
            <li
              key={device.id}
              className={styles[`network-asset-list-item${suffix}`]}
            >
              <Row>
                <Col sm="8" className={styles['summary-column']}>
                  <div className={styles['icon-subcolumn']}>
                    <DeviceIconWithStatus
                      className={styles['device-icon']}
                      device={device}
                    />
                  </div>

                  <div className={styles['summary-subcolumn']}>
                    <span className={styles['network-asset-id']}>
                      {device.serialNumber}
                    </span>
                  </div>
                </Col>

                <Col sm="4" className={styles['actions-column']}>
                  <Button
                    name="toggle-network-asset-button"
                    type="button"
                    onClick={this.createOnToggleAggregate(device)}
                    className={styles['toggle-network-asset-button']}
                  >
                    {isMember ? <i className="far fa-check"></i> : ' '}
                  </Button>
                </Col>
              </Row>
            </li>
          )
        })}
      </ul>
    )
  }

  renderNetworkAssetList = ({ data: networkAssets }) => {
    const { t } = this.props
    const aggregateIdsInGroup = this.selectAggregateIdsInGroup(this.props)

    return (
      <ul className={styles['network-assets-list']}>
        {networkAssets.map((networkAsset) => {
          const isMember = aggregateIdsInGroup.includes(networkAsset.id)
          const suffix = isMember ? '-is-member' : ''

          return (
            <li
              key={networkAsset.id}
              name="network-asset-item"
              onClick={this.createOnClickNetworkAsset(networkAsset)}
              className={styles[`network-asset-list-item${suffix}`]}
            >
              <Row>
                <Col sm="8" className={styles['summary-column']}>
                  <div className={styles['icon-subcolumn']}>
                    <NetworkAssetIcon
                      networkAsset={networkAsset}
                      className={styles.icon}
                    />
                  </div>

                  <div className={styles['summary-subcolumn']}>
                    <span className={styles['network-asset-id']}>
                      {networkAsset.assetId}
                    </span>

                    {networkAsset.assetName ? (
                      <div>
                        <i>
                          <small>{networkAsset.assetName}</small>
                        </i>
                      </div>
                    ) : null}

                    <small className={styles['network-asset-type']}>
                      {networkAsset.translateAssetType(t)}
                    </small>
                  </div>
                </Col>

                <Col sm="4" className={styles['actions-column']}>
                  {mapAssetTypeToLogicalChannels(networkAsset.assetType).map(
                    (channel) => {
                      const installation =
                        networkAsset.installationForChannel(channel)
                      const device = installation
                        ? installation.device
                        : NO_DEVICE
                      const domId = `na-${networkAsset.id}-${channel}`

                      return (
                        <div key={channel}>
                          <DeviceIconWithStatus
                            id={domId}
                            device={device}
                            className={styles['device-icon']}
                          />

                          {installation ? (
                            <UncontrolledTooltip
                              target={domId}
                              // the following line is necessary to fix this issue:
                              // https://github.com/reactstrap/reactstrap/issues/1482#issuecomment-498747688
                              modifiers={{ flip: { enabled: false } }}
                              placement="top"
                              className={styles['device-serial-number-tooltip']}
                            >
                              {t(`common/text:text.asset_channel_${channel}`)}:{' '}
                              {device.serialNumber}
                            </UncontrolledTooltip>
                          ) : null}
                        </div>
                      )
                    }
                  )}

                  <Button
                    name="toggle-network-asset-button"
                    type="button"
                    onClick={this.createOnToggleAggregate(networkAsset)}
                    className={styles['toggle-network-asset-button']}
                  >
                    {isMember ? <i className="far fa-check"></i> : ' '}
                  </Button>
                </Col>
              </Row>
            </li>
          )
        })}
      </ul>
    )
  }

  constructor(props) {
    super(props)

    this.assignAssetFields = new FormFields(this, 'assignAssetFields', {
      startTime: (time) => {
        const { commission } = this.props

        if (!commission) {
          return ''
        }

        return moment(time).isAfter(moment(commission.start))
          ? ''
          : 'errors.invalid_installation_start_time'
      },
      channel: (v) => (v ? '' : 'errors.required'),
      aggregateId: (v) => (v ? '' : 'errors.required'),
    })

    this.state = this.initialState(props)
  }

  initialState(props) {
    const { commission } = props

    const startTime = commission
      ? moment(commission.start, API_DATE_FORMAT).endOf('minute').toDate()
      : moment().toDate()

    return {
      result: AsyncResult.notFound(),
      searchQuery: '',
      assetTypeFilter: '',
      assignAssetFields: this.assignAssetFields.initialState({ startTime }),
      selectedNetworkAsset: null,
    }
  }

  onToggle = (...args) => {
    const { toggle } = this.props

    this.setState(this.initialState(this.props))

    if (toggle) {
      toggle(...args)
    }
  }

  selectMapCenter = createSelector(
    [get('commission'), (props, state) => state.selectedNetworkAsset],
    (commission, selectedNetworkAsset) =>
      selectedNetworkAsset
        ? [
          selectedNetworkAsset.location.latitude,
          selectedNetworkAsset.location.longitude,
        ]
        : commission
          ? [commission.location.latitude, commission.location.longitude]
          : undefined
  )

  selectAggregateIdsInGroup = createSelector(
    [get('groupResult')],
    ({ data: group }) =>
      group && group.members && group.members.data
        ? group.members.data.map(({ id }) => id)
        : EMPTY_ARRAY
  )

  selectMapZoom = createSelector(
    [get('commission'), (props, state) => state.selectedNetworkAsset],
    (commission, selectedNetworkAsset) =>
      selectedNetworkAsset || commission ? 15 : undefined
  )

  onChangeAggregatesQuery = (event) => {
    this.setState({ searchQuery: event.target.value })
  }

  onChangeAssetTypeFilter = (event) => {
    this.setState({ assetTypeFilter: event.target.value })
  }

  componentWillReceiveProps(newProps) {
    if (newProps.commission !== this.props.commission) {
      this.setState(this.initialState(newProps))
    }
  }

  componentWillUpdate(newProps, newState) {
    if (
      this.state.searchQuery !== newState.searchQuery ||
      this.state.assetTypeFilter !== newState.assetTypeFilter
    ) {
      newProps.refetchNetworkAssets({
        searchQuery: newState.searchQuery,
        assetType: newState.assetTypeFilter || undefined,
      })
      newProps.refetchDevices({
        searchQuery: newState.searchQuery,
        assetType: undefined,
      })
    }
  }

  createOnClickNetworkAsset = (
    networkAsset,
    channel = networkAsset.availableChannels[0]
  ) => {
    return () => this.onChangeNetworkAsset(networkAsset, channel)
  }

  createOnClickMarker(commission) {
    return () => this.setState({ selectedAggregate: commission.device })
  }

  onChangeNetworkAsset = (networkAsset, channel) => {
    this.setState(
      {
        assignAssetFields: this.assignAssetFields.updateState(this.state, {
          channel,
          aggregateId: networkAsset.id,
        }),
        selectedNetworkAsset: networkAsset,
      },
      () => this.assignAssetFields.debounceValidations()
    )
  }

  createOnToggleAggregate = (aggregate) => {
    const { aggregateType } = this.props
    return async (event) => {
      event.stopPropagation()

      const { group } = this.props
      const aggregateIdsInGroup = this.selectAggregateIdsInGroup(this.props)

      this.setState({ result: AsyncResult.pending() })
      try {
        if (aggregateIdsInGroup.includes(aggregate.id)) {
          await this.props.removeAggregateFromGroup({
            variables: {
              groupId: group.id,
              aggregateId: aggregate.id,
              aggregateType: aggregateType,
            },
          })
        } else {
          await this.props.addAggregateToGroup({
            variables: {
              groupId: group.id,
              aggregateId: aggregate.id,
              aggregateType: aggregateType,
            },
          })
        }

        await sleep(INFLOWNET_WAIT)
        await this.props.onSuccess()
        await this.props.refetchGroup()

        this.setState({ result: AsyncResult.success() })
      } catch (e) {
        analytics.logError(e)

        this.setState({ result: AsyncResult.fail(e) })
      }
    }
  }

  static GROUP_QUERY = gql`
    query Group($id: Int!) {
      groupMix(id: $id) {
        id
        name
        category
        members {
          count
          type
          data {
            ... on NetworkAsset {
              __typename
              id
              assetId
              assetName
              assetType
            }
            ... on Device {
              __typename
              id
              serialNumber
              commissionStatus
              currentCommission {
                device {
                  id
                }
                location {
                  latitude
                  longitude
                }
                start
                end
              }
            }
          }
        }
      }
    }
  `

  static REMOVE_AGGREGATE_MUTATION = gql`
    mutation RemoveMemberFromGroup(
      $groupId: Int!
      $aggregateType: AggregateType!
      $aggregateId: Int!
    ) {
      removeMemberFromGroup(
        groupId: $groupId
        aggregateType: $aggregateType
        aggregateId: $aggregateId
      )
    }
  `

  static ADD_AGGREGATE_MUTATION = gql`
    mutation AddMemberToGroup(
      $groupId: Int!
      $aggregateType: AggregateType!
      $aggregateId: Int!
    ) {
      addMemberToGroup(
        groupId: $groupId
        aggregateType: $aggregateType
        aggregateId: $aggregateId
      )
    }
  `

  static QUERY_ALL_NETWORK_ASSETS = gql`
    query NetworkAssets($searchQuery: String, $assetType: AssetType) {
      networkAssets(searchQuery: $searchQuery, assetType: $assetType) {
        id
        assetId
        location {
          altitude
          latitude
          longitude
        }
        assetName
        assetType
        installations {
          start
          end
          device {
            id
            serialNumber
            currentCommission {
              end
              start
            }
            activeIssues {
              id
              type
              severity
              description
              deviceId
            }
          }
          channelMap {
            pressure_1
          }
        }
      }
    }
  `

  static QUERY_ALL_DEVICES = gql`
    query Devices($searchQuery: String) {
      devices(searchQuery: $searchQuery) {
        id
        serialNumber
        currentCommission {
          device {
            id
          }
          location {
            latitude
            longitude
          }
          start
          end
        }
        activeIssues {
          id
          type
          severity
          description
          deviceId
        }
      }
    }
  `
}

function typeSuffixFor(group) {
  switch (group.members.type) {
    case 'VDevice':
      return 'devices'

    case 'VNetworkAsset':
      return 'assets'

    default:
      return 'aggregates'
  }
}

export default compose(
  transformProps(() => () => {
    return {
      selectGroupResult: createSelectGraphQLResult('groupMix', {
        mapResult: parseGraphQLResult,
        nullObject: null,
      }),
      selectNetworkAssetsResult: createSelectGraphQLResult('networkAssets', {
        mapResult: parseGraphQLResult,
        nullObject: [],
      }),
      selectDevicesResult: createSelectGraphQLResult('devices', {
        mapResult: parseGraphQLResult,
        nullObject: [],
      }),
    }
  }),
  graphql(AddAggregateToGroupModal.GROUP_QUERY, {
    options: ({ group }) => {
      return {
        fetchPolicy: 'network-only',
        variables: { id: group.id },
      }
    },
    props: ({ data, ownProps: { selectGroupResult } }) => {
      return {
        groupResult: selectGroupResult(data),
        refetchGroup: data.refetch,
      }
    },
  }),
  graphql(AddAggregateToGroupModal.ADD_AGGREGATE_MUTATION, {
    name: 'addAggregateToGroup',
  }),
  graphql(AddAggregateToGroupModal.REMOVE_AGGREGATE_MUTATION, {
    name: 'removeAggregateFromGroup',
  }),
  graphql(AddAggregateToGroupModal.QUERY_ALL_DEVICES, {
    options: () => {
      return {
        variables: {},
        fetchPolicy: 'network-only',
      }
    },
    props: ({ data, ownProps: { selectDevicesResult } }) => {
      return {
        refetchDevices: data.refetch,
        devicesResult: selectDevicesResult(data),
      }
    },
  }),
  graphql(AddAggregateToGroupModal.QUERY_ALL_NETWORK_ASSETS, {
    options: () => {
      return {
        variables: {},
        fetchPolicy: 'network-only',
      }
    },
    props: ({ data, ownProps: { selectNetworkAssetsResult } }) => {
      return {
        refetchNetworkAssets: data.refetch,
        networkAssetResult: selectNetworkAssetsResult(data),
      }
    },
  }),
  withGoogleMapsProvider,
  withTranslation([
    'src/management_path/groups_path/add_aggregate_to_group_modal',
    'common/text',
  ])
)(AddAggregateToGroupModal)
