import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import SignalHistoryGraph from
'@@src/management_path/devices_path/signal_history_modal/signal_history_graph'
import getHistoryGraphContainer, { getHistoryGraphContainerChildWithQuery } from
'@@src/management_path/devices_path/history_modal/history_graph_container'
import withHistoryModal from '@@src/management_path/devices_path/history_modal'

import styles from '../history_modal/simple_view_modal.css'

const HistoryGraphContainerChildWithQuery =
  getHistoryGraphContainerChildWithQuery(
    SignalHistoryGraph.deviceTelemetryQuery
  )
const HistoryGraphContainer =
  getHistoryGraphContainer(HistoryGraphContainerChildWithQuery)
const HistoryModal = withHistoryModal(HistoryGraphContainer)

const channels = ['sgi_signal']

function SignalHistoryModal(props) {
  const {
    t,
    deviceId,
    displayTooltipMillisThreshold,
    ...rest
  } = props

  return (
    <HistoryModal
      deviceId={deviceId}
      channels={channels}
      title={t('headings.title')}
      {...rest}>
      <SignalHistoryGraph
        displayTooltipMillisThreshold={displayTooltipMillisThreshold}
        className={styles.graph}
        deviceId={deviceId}/>
    </HistoryModal>
  )
}

SignalHistoryModal.propTypes = {
  t: PropTypes.func.isRequired,
  deviceId: PropTypes.number.isRequired,
  displayTooltipMillisThreshold: PropTypes.number,
}

export default withTranslation([
  'src/management_path/devices_path/signal_history_modal/index_page',
])(React.memo(SignalHistoryModal))
