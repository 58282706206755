import React, { useRef } from 'react'
import MapMarkerComponent from '../../../../../components/map_marker/map_marker.component'
import DeviceMapMarkerPopup from './device_map_marker_popup.component'
import { DeviceForMapItem } from '../../../../../api/models/devices_for_map_item'

import PropTypes from 'prop-types'
const DeviceMapMarkerComponent = ({ device }) => {
  const markerRef = useRef()
  const coordinates = device.getCoordinates()

  if(coordinates.lat !== null && coordinates.lng !== null) {
    return(
      <MapMarkerComponent
        markerRef={markerRef}
        iconUrl={device.getTableIconURL()}
        errorCount={device.activeErrorsCount}
        warningCount={device.activeWarningsCount}
        position={coordinates} >
        <DeviceMapMarkerPopup deviceId={device.deviceId} markerRef={markerRef} />
      </MapMarkerComponent>
    )
  }
  return null
}

DeviceMapMarkerComponent.propTypes = {
  networkAsset: PropTypes.instanceOf(DeviceForMapItem),
}

export default React.memo(DeviceMapMarkerComponent)
