import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { Alert, Button } from 'reactstrap'

import routes from '@@src/routes'

export const INCORRECT_ID_ERROR = 'You have specified incorrect ids'

export function isIncorrectIdErrorResult(result) {
  return result.wasFailure() &&
    result.originalErrorMessages()
      .some((e) => e.includes(INCORRECT_ID_ERROR))
}

function InvalidSelectionAlert(props) {
  const { t, reloadPath } = props

  return (
    <Alert color="warning" className="d-flex flex-column">
      <p className="font-weight-bold">
        {t('text.invalid_selection')}
      </p>
      <p>
        {t('text.reselect_sources')}
      </p>
      <Button
        className="ml-auto"
        tag={Link}
        to={reloadPath}
        color="warning">
        {t('button.clear_and_reselect')}
      </Button>
    </Alert>
  )
}

InvalidSelectionAlert.propTypes = {
  t: PropTypes.func.isRequired,
  reloadPath: PropTypes.string.isRequired,
}

InvalidSelectionAlert.defaultProps = {
  reloadPath: routes.analysisListPath(),
}

export default withTranslation([
  'src/components/alerts/invalid_selection_alert',
])(InvalidSelectionAlert)
