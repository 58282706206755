import React from 'react'
import withGoogleMapsProvider from '@@src/components/maps/google_maps_provider'
import StandardMap from '@@src/components/maps/standard_map'
import MarkerClusterGroup from 'react-leaflet-markercluster'
import DeviceCommissionMarker
from '@@src/components/maps/markers/device_commission_marker'
import DeviceCommissionPopup
from '@@src/components/maps/markers/device_commission_popup'

function DevicesMap({
  googleMaps,
  devices,
  onClick,
  selectedAggregateId,
  mapCenter,
  mapZoom,
  mapBounds,
}) {

  return(
    <StandardMap
      zoom={mapZoom}
      bounds={mapBounds}
      center={mapCenter}
      className="h-100 p-0">
      {
        devices
          .filter(device => device.currentCommission !== null)
          .map(device => (
            <MarkerClusterGroup
              key={device.id}
              showCoverageOnHover={false}
              removeOutsideVisibleBounds={true}>
              <DeviceCommissionMarker
                key={device.id}
                onClick={() => onClick(device.currentCommission)}
                commission={device.currentCommission}
                isPopupOpen={device.id === selectedAggregateId}>
                <DeviceCommissionPopup
                  googleMaps={googleMaps}
                  commission={device.currentCommission} />
              </DeviceCommissionMarker>
            </MarkerClusterGroup>
          ))
      }
    </StandardMap>
  )
}

export default withGoogleMapsProvider(DevicesMap)
