import gql from 'graphql-tag'
import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { graphql } from '@apollo/client/react/hoc'
import { withTranslation } from 'react-i18next'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { omitBy } from 'lodash/fp/object'
import { isNil } from 'lodash/fp/lang'

import * as Changes from '@@src/store/changes'
import * as analytics from '@@src/analytics'
import AsyncResult from '@@src/utils/async_result'
import SubmitButton from '@@src/components/buttons/submit_button'
import { getMappedCongruityTypes } from '@@src/utils'

class ExportNetworkAssetsConfigModal extends React.PureComponent {
  static defaultProps = {
    onExportSuccess: () => { },
  }

  static propTypes = {
    isOpen: PropTypes.bool,
    toggle: PropTypes.func,
    onExportSuccess: PropTypes.func.isRequired,
    totalResultsCount: PropTypes.number.isRequired,
    exportQueryParams: PropTypes.shape({
      filterState: PropTypes.array,
      searchQuery: PropTypes.string,
      orderBy: PropTypes.string,
      orderDirection: PropTypes.string,
    }).isRequired,
  }

  state = {
    result: AsyncResult.notFound(),
  }

  render() {
    const { t, isOpen, toggle, totalResultsCount } = this.props
    const { result } = this.state

    return (
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader>
          {t('headings.modal_heading')}
        </ModalHeader>

        <ModalBody>
          {t('text.assets_count', { count: totalResultsCount })}
        </ModalBody>

        <ModalFooter>
          <Button name="cancel-button" color="secondary" onClick={toggle}>
            {t('buttons.cancel')}
          </Button>

          <SubmitButton
            disabled={totalResultsCount === 0}
            name="export-button"
            color="primary"
            result={result}
            submitText={t('buttons.export')}
            onSubmitForm={this.onExport}
          />
        </ModalFooter>
      </Modal>
    )
  }

  onExport = async event => {
    event.preventDefault()

    this.setState({ result: AsyncResult.pending() })

    try {
      const {
        exportQueryParams,
        requestNetworkAssetsReport } = this.props

      const variables = omitBy(param => {
        return param === '' || isNil(param)
      })(exportQueryParams)

      if (variables.filterState) {
        variables.filterState = getMappedCongruityTypes(variables.filterState)
      }

      await requestNetworkAssetsReport({
        variables,
      })

      this.setState({ result: AsyncResult.success() })

      this.props.dispatchNotifyDownloadsChanged()
      await this.props.onExportSuccess()
      await this.props.toggle()
    } catch (err) {
      analytics.logError(err)

      this.setState({ result: AsyncResult.fail(err) })
    }
  }

  static EXPORT_NETWORK_ASSETS_CSV_MUTATION = gql`
    mutation ExportNetworkAssetsToCsv(
      $filterState: [String],
      $searchQuery: String,
      $orderBy: String,
      $orderDirection: String,
    ) {
      requestNetworkAssetsReport(
        filterState: $filterState,
        searchQuery: $searchQuery,
        orderBy: $orderBy,
        orderDirection: $orderDirection,
      ) {
        jobId
      }
    }
  `
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchNotifyDownloadsChanged() {
      dispatch(Changes.notifyChange(Changes.CSV_DOWNLOAD_CHANGES))
    },
  }
}

export default compose(
  withTranslation([
    // eslint-disable-next-line max-len
    'src/management_path/network_assets_path/export_network_assets_config_modal',
    'common/text',
  ]),
  connect(null, mapDispatchToProps),
  graphql(ExportNetworkAssetsConfigModal.EXPORT_NETWORK_ASSETS_CSV_MUTATION, {
    name: 'requestNetworkAssetsReport',
  })
)(ExportNetworkAssetsConfigModal)
