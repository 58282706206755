import optimizeSubsetDataQuery from '../../analysis_path/pressure_analysis_path/constants/optimize_subset_data_query'
import { map, partition } from 'lodash/fp/collection'

export const MAX_SOURCES_PER_PAGE = 20
export const MAX_RESOLUTION_PARTS = 30
export const MAX_ALERTS_PER_PAGE = 250
export const TOTAL_EVENT_SLICES = 30

export const PLOT_QUERY_NAMES = [
  'GetAnomaliesPlot',
  'GetAnomaliesBounds',
  'RawDataQuery',
  'PagedPressureSubsetData',
  'GetAssetData',
  'PressureEventsData',
  'AlertsForPressureData',
  'ListRawDataRequests',
]

export function calculateFetchingVariables(
  startTime,
  endTime,
  legendItems,
  eventIds
) {
  const { start, end, resolution } = optimizeSubsetDataQuery({
    start: startTime,
    end: endTime,
  })

  // separate devices from assets in legend items result
  const [deviceSources, assetSources] = partition((item) =>
    item.isFromDevice()
  )(legendItems)

  // get device ids
  const deviceIds = deviceSources ? map('sourceId')(deviceSources) : []
  // get network asset channels
  const networkAssetChannels = assetSources.map(
    ({ sourceId, sourceChannel }) => {
      return { networkAssetId: sourceId, channel: sourceChannel }
    }
  )
  const parameters = {
    start,
    end,
    deviceIds,
    resolution,
    networkAssetChannels,
  }

  if (Array.isArray(eventIds)) {
    parameters.eventIds = eventIds
  }

  return parameters
}
