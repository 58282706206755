import PropTypes from 'prop-types'
import React from 'react'
import { Card } from 'reactstrap'
import ListSummary from '../../../../components/list_summary/list_summary.component'
import PaginationComponent from '../../../../components/pagination/pagination.component'
import PaginationLimitComponent from '../../../../components/pagination/pagination_limit_component'
import { ListPlaceholder } from '../../../../components/placeholders/list_placeholder'
import { useGetNetworkAssetsForList } from '../hooks/get_network_assets_for_list'
import NetworkAssetsListFiltersComponent from './header/network_assets_list_filters.component'
import NetworkAssetsListHeaderComponent from './header/network_assets_list_header.component'
import NetworkAssetsListSortComponent from './header/network_assets_list_sort.component'
import NetworkAssetsListItemComponent from './list-item/network_assets_list_item.component'
const NetworkAssetsListComponent = ({
  filters = {},
  setFilters = () => null,
  setPagination = () => null,
  onShowEverywhereClick = () => null,
}) => {
  const { isLoading, networkAssets, pagination } =
    useGetNetworkAssetsForList(filters)
  const onPaginate = (pageNumber) =>
    setPagination((oldPagination) => ({ ...oldPagination, pageNumber }))
  const onChangeLimit = (resultsPerPage) =>
    setPagination({ pageNumber: 1, resultsPerPage })

  return (
    <div className="h-100 pb-3 pt-5 pl-5 pr-5 d-flex flex-column">
      <NetworkAssetsListHeaderComponent
        filters={filters}
        networkAssetNumber={(pagination && pagination.totalResults) || 0}
        exportEnabled={!isLoading}
      />

      <div className="d-flex flex-row justify-content-between mt-4">
        <NetworkAssetsListFiltersComponent
          filters={filters}
          setFilters={setFilters}
        />
      </div>

      <div className="d-flex flex-row justify-content-between mt-3">
        <ListSummary
          isLoading={isLoading}
          totalResults={(pagination && pagination.totalResults) || 0}
          onShowEverywhereClick={onShowEverywhereClick}
        />
        <NetworkAssetsListSortComponent
          filters={filters}
          setFilters={setFilters}
        />
      </div>

      <Card className="w-100 h-100 mt-3 p-3 align-self-stretch overflow-auto border-0">
        {isLoading && <ListPlaceholder />}
        {!isLoading &&
          networkAssets.map((networkAsset, i) => (
            <NetworkAssetsListItemComponent
              key={`list.na.item.${networkAsset.assetId}`}
              isLast={
                i === pagination.perPage - 1 ||
                i === pagination.totalResults - 1
              }
              networkAsset={networkAsset}
            />
          ))}
      </Card>

      {pagination && (
        <div className="w-100 mt-3 d-flex flex-row justify-content-between">
          <PaginationComponent
            pagination={pagination}
            onPaginate={onPaginate}
          />
          <PaginationLimitComponent
            pagination={pagination}
            onChangeLimit={onChangeLimit}
          />
        </div>
      )}
    </div>
  )
}

NetworkAssetsListComponent.propTypes = {
  filters: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
  setPagination: PropTypes.func.isRequired,
  onShowEverywhereClick: PropTypes.func.isRequired,
}

export default NetworkAssetsListComponent
