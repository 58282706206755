import gql from 'graphql-tag'
import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { graphql } from '@apollo/client/react/hoc'
import { withTranslation } from 'react-i18next'

import transformProps from '@@src/components/transform_props'
import AsyncResultSwitch from '@@src/components/async_result_switch'
import AppSettingsConsumer from '@@src/components/app_settings_consumer'
import { parseGraphQLResult } from '@@src/api/presenters'
import { createSelectGraphQLResult } from '@@src/utils'
import ConvertedAlertThresholdSettingsFormContainer from
'@@src/components/alerts/converted_alert_threshold_settings_form_container'

import styles from './edit_alerts_configuration_section.css'

class EditAlertsConfigurationSection extends React.PureComponent {
  static propTypes = {
    groupId: PropTypes.number.isRequired,
  }

  render() {
    const { t, groupResult } = this.props

    return (
      <div>
        <h3>{t('headings.alerts')}</h3>
        <div className={styles.separator}/>

        <AsyncResultSwitch
          result={groupResult}
          renderSuccessResult={this.renderAlertsConfigForm}/>
      </div>
    )
  }

  renderAlertsConfigForm = ({ data: group }) => {
    const {
      alertThresholds: groupAlertThresholds,
      ancestry,
      descendants,
    } = group
    const {
      groupId,
      refetchData,
      editAlertsConfiguration,
    } = this.props

    return (
      <AppSettingsConsumer>
        {
          (units, tenantAlertThresholds) => (
            <ConvertedAlertThresholdSettingsFormContainer
              formatEditVariables={this.formatEditVariables}
              units={units}
              alertThresholds={groupAlertThresholds}
              tenantAlertThresholds={tenantAlertThresholds}
              editAlertsConfiguration={editAlertsConfiguration}
              ancestors={ancestry}
              descendants={descendants}
              refreshOnSubmit
              onReset={refetchData}
              onSubmitSuccess={refetchData}
              groupId={groupId}
            />
          )
        }
      </AppSettingsConsumer>
    )
  }

  formatEditVariables = variables => {
    const { groupId } = this.props
    const { inheritFrom, parentId } = variables

    return (inheritFrom ? {
      parentId,
      inheritFrom,
    } : {
      ...variables,
      groupId,
    })
  }

  static GROUP_ALERTS_CONFIGURATION_QUERY = gql`
    query GroupAlertsConfigurationSection($id: Int!) {
      groupMix(id: $id) {
        id
        descendants {
          ... on NetworkAsset {
            __typename
            id
            assetId
            assetType
            alertThresholds {
              inheritedFrom
              parentId
            }
          }
          ... on MixedGroupDetailsType {
            __typename
            id
            name
            category
            alertThresholds {
              inheritedFrom
              parentId
            }
          }
        }
        ancestry {
          id
          name
          index
          parentId
        }
        alertThresholds {
          inheritedFrom
          parentId

          dailyCpisHigh,
          dailyCpisHighEnabled,

          dailyCpisLow,
          dailyCpisLowEnabled,

          eventCpis,
          eventCpisEnabled,

          eventSeverity,
          eventSeverityEnabled,

          pressureHigh,
          pressureHighEnabled,

          pressureLow,
          pressureLowEnabled,

          deviceNoComms,
          deviceNoCommsEnabled,

          batteryDepletingEnabled,
          eventSourceLocalisationEnabled,
        }
      }
    }
  `

  static EDIT_ALERTS_CONFIGURATION_MUTATION = gql`
    mutation EditAlertsConfiguration(
      $groupId: Int!,

      $parentId: Int,
      $inheritFrom: AlertThresholdInheritFromTypes,

      $dailyCpisHigh: Float,
      $dailyCpisHighEnabled: Boolean,

      $dailyCpisLow: Float,
      $dailyCpisLowEnabled: Boolean,

      $deviceNoComms: Int,
      $deviceNoCommsEnabled: Boolean,

      $eventCpis: Float,
      $eventCpisEnabled: Boolean,

      $eventSeverity: Float,
      $eventSeverityEnabled: Boolean,

      $pressureHigh: Float,
      $pressureHighEnabled: Boolean,

      $pressureLow: Float,
      $pressureLowEnabled: Boolean,

      $batteryDepletingEnabled: Boolean,
      $eventSourceLocalisationEnabled: Boolean,
    ) {
      editGroup(
        groupId: $groupId,
        alertThresholds: {
          parentId: $parentId,
          inheritFrom: $inheritFrom,
          dailyCpisHigh: $dailyCpisHigh,
          dailyCpisHighEnabled: $dailyCpisHighEnabled,
          dailyCpisLow: $dailyCpisLow,
          dailyCpisLowEnabled: $dailyCpisLowEnabled,
          deviceNoComms: $deviceNoComms,
          deviceNoCommsEnabled: $deviceNoCommsEnabled,
          eventCpis: $eventCpis,
          eventCpisEnabled: $eventCpisEnabled,
          pressureLow: $pressureLow,
          pressureLowEnabled: $pressureLowEnabled,
          pressureHigh: $pressureHigh,
          pressureHighEnabled: $pressureHighEnabled,
          eventSeverity: $eventSeverity,
          eventSeverityEnabled: $eventSeverityEnabled,
          batteryDepletingEnabled: $batteryDepletingEnabled,
          eventSourceLocalisationEnabled: $eventSourceLocalisationEnabled,
        },
      ) {
        id
      }
    }
  `
}

export default compose(
  withTranslation([
    'src/admin_path/tenant_settings/alerts_path',
  ]),
  transformProps(() => () => {
    const selectGroupResult = createSelectGraphQLResult('groupMix', {
      mapResult: parseGraphQLResult,
      nullObject: null,
    })

    return {
      selectGroupResult,
    }
  }),
  graphql(EditAlertsConfigurationSection.GROUP_ALERTS_CONFIGURATION_QUERY, {
    options: ({ groupId }) => {
      return {
        fetchPolicy: 'network-only',
        variables: {
          id: groupId,
        },
      }
    },
    props: ({ data, ownProps }) => {
      const { selectGroupResult } = ownProps
      const { refetch } = data

      return {
        refetchData: () => refetch(data.variables),
        groupResult: selectGroupResult(data),
        alertThresholdsResult: selectGroupResult(data).map(group => {
          return group ? group.alertThresholds : {}
        }),
      }
    },
  }),
  graphql(EditAlertsConfigurationSection.EDIT_ALERTS_CONFIGURATION_MUTATION, {
    name: 'editAlertsConfiguration',
  }),
)(EditAlertsConfigurationSection)
