import React from 'react'
import classnames from 'classnames'
import styles from './device_icon_with_status.css'
import PropTypes from 'prop-types'

function DevicesTableItemIcon(props) {
  const { id, className, isCommissioned } = props
  const deviceStatus = props.deviceStatus.toLowerCase()
  const mostSevereIssueSeverity = props.mostSevereIssueSeverity && props.mostSevereIssueSeverity.toLowerCase() || null
  let iconClassName

  if (mostSevereIssueSeverity) {
    iconClassName = `icon-${mostSevereIssueSeverity}`
  } else if (deviceStatus === 'returned') {
    iconClassName = 'icon-returned'
  } else if (isCommissioned) {
    iconClassName = 'icon-commissioned'
  } else {
    iconClassName = 'icon-decommissioned'
  }

  return (
    <span id={id} className={classnames(className, styles[iconClassName])} />
  )
}

DevicesTableItemIcon.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  mostSevereIssueSeverity: PropTypes.string,
  deviceStatus: PropTypes.string.isRequired,
  isCommissioned: PropTypes.bool.isRequired,

}

export default DevicesTableItemIcon
