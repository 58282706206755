import { useCallback, useEffect, useRef, useState } from 'react'
import {
  canvas,
  circleMarker,
  polyline,
  polygon,
  latLng,
  latLngBounds,
} from 'leaflet'
import 'leaflet.markercluster'
import {
  getFeatureCoordinatesAndMinMaxLongLat,
  saveMinMaxValuesForLonLat,
  mapObjectEnteriesToParagraphs,
  createSimpleHeader,
  getMarkerClusterGroupLayer,
  HIGH_ZOOM_LEVEL,
  MEDIUM_ZOOM_LEVEL,
  LOW_ZOOM_LEVEL,
  POINT,
  MULTI_POINT,
  LINE_STRING,
  MULTI_LINE_STRING,
  POLYGON,
  MULTY_POLYGON,
} from './utils'
import { MAP_TILES } from '../utils/map_utils'

export function useMakeCanvasGEOJsonLayer(map, geoJsonData, tileType, zoomLevel, latestFileName) {
  const [currentStatus, setCurrentStatus] = useState('')
  const [canvasLayer, setCanvasLayer] = useState(undefined)
  const [canvasBounds, setCanvasBounds] = useState(undefined)
  const [markerClusterLayer, setMarkerClusterLayer] = useState(undefined)
  const [tooFarAwayMarker, setTooFarAwayMarker] = useState(undefined)
  const lineRefs = useRef([])
  const pointRefs = useRef([])

  useEffect(() => {
    if(lineRefs.current.length === 0 && pointRefs.current.length === 0) {
      return
    }
    setCurrentStatus('resizing lines')
    setTimeout(() => {
      switch (true) {
        case zoomLevel === HIGH_ZOOM_LEVEL: {
          markerClusterLayer.addTo(map)
          tooFarAwayMarker && map.removeLayer(tooFarAwayMarker)
          lineRefs.current.length > 0 &&
            lineRefs.current.forEach(line => {
              line.setStyle({ weight: 10 })
            })

          pointRefs.current.length > 0 &&
            pointRefs.current.forEach(point => {
              point.setStyle({ radius: 14 })
            })

          break
        }
        case zoomLevel === MEDIUM_ZOOM_LEVEL: {
          markerClusterLayer.addTo(map)
          tooFarAwayMarker && map.removeLayer(tooFarAwayMarker)
          lineRefs.current.length > 0 &&
            lineRefs.current.forEach(line => {
              line.setStyle({ weight: 5 })
            })

          pointRefs.current.length > 0 &&
            pointRefs.current.forEach(point => {
              point.setStyle({ radius: 7 })
            })
          break
        }
        case zoomLevel === LOW_ZOOM_LEVEL: {
          markerClusterLayer.addTo(map)
          tooFarAwayMarker && map.removeLayer(tooFarAwayMarker)
          lineRefs.current.length > 0 &&
            lineRefs.current.forEach(line => {
              line.setStyle({ weight: 1 })
            })

          pointRefs.current.length > 0 &&
            pointRefs.current.forEach(point => {
              point.setStyle({ radius: 2 })
            })
          break
        }
        default: {
          map.removeLayer(markerClusterLayer)
          tooFarAwayMarker.addTo(map)
        }
      }
      setCurrentStatus('')
    }, 100)
  }, [canvasLayer, zoomLevel])

  useEffect(() => {
    setCurrentStatus('remaking canvas')
    setTimeout(() => {
      setCanvasBounds(undefined)
      switch (true) {
        case !geoJsonData && !!canvasLayer: {
          map.removeLayer(canvasLayer)
          lineRefs.current = []
          pointRefs.current = []
          setCanvasLayer(undefined)
          markerClusterLayer && map.removeLayer(markerClusterLayer)
          setMarkerClusterLayer(undefined)
          break
        }
        case !!geoJsonData && !!canvasLayer: {
          map.removeLayer(canvasLayer)
          lineRefs.current = []
          pointRefs.current = []
          setCanvasLayer(canvas({ padding: 0.5 }))
          markerClusterLayer && map.removeLayer(markerClusterLayer)
          setMarkerClusterLayer(undefined)
          break
        }
        case !!geoJsonData && !canvasLayer: {
          lineRefs.current = []
          pointRefs.current = []
          setCanvasLayer(canvas({ padding: 0.5 }))
          markerClusterLayer && map.removeLayer(markerClusterLayer)
          setMarkerClusterLayer(undefined)
          break
        }
        default: {
          canvasLayer && map.removeLayer(canvasLayer)
          setCanvasLayer(undefined)
          markerClusterLayer && map.removeLayer(markerClusterLayer)
          setMarkerClusterLayer(undefined)
        }
      }
      setCurrentStatus('')
    }, 100)
  }, [geoJsonData])

  useEffect(() => {
    if (!canvasLayer) {
      return
    }
    setCurrentStatus('adding features')

    setTimeout(() => {
      const markers = getMarkerClusterGroupLayer()
      setMarkerClusterLayer(markers)
      const latitude = [100, -100]
      const longitude = [100, -100]

      geoJsonData.features.forEach((feature) => {
        const { coordinates, minMaxLatitude, minMaxLongitude } = getFeatureCoordinatesAndMinMaxLongLat(feature.geometry)

        saveMinMaxValuesForLonLat(latitude, longitude, [minMaxLatitude[0], minMaxLongitude[0]])
        saveMinMaxValuesForLonLat(latitude, longitude, [minMaxLatitude[1], minMaxLongitude[1]])

        switch (true) {
          case feature.geometry.type === POINT: {
            const circle = circleMarker(coordinates, {
              renderer: canvasLayer,
              stroke: true,
              weight: 0.5,
              color: 'black',
              fillColor: tileType === MAP_TILES ? 'red' : 'blue',
              fillOpacity: 0.9,
              radius: 5,
            }).addTo(markers).on(
            {
              mouseover: (event) => {
                event.target.setStyle({
                  fillColor: tileType === MAP_TILES ? 'orange' : 'purple',
                })
                event.target.bringToFront()
              },
              mouseout: (event) => {
                event.target.setStyle({
                  fillColor: tileType === MAP_TILES ? 'red' : 'blue',
                })
              },
            }).bindTooltip(mapObjectEnteriesToParagraphs(feature.properties), { direction: 'top' })
            pointRefs.current.push(circle)
            break
          }
          case feature.geometry.type === MULTI_POINT: {
            const multiPoints = coordinates.map(coordinate => {
              return circleMarker(coordinate, {
                renderer: canvasLayer,
                stroke: true,
                weight: 5,
                color: 'black',
                fillColor: tileType === MAP_TILES ? 'red' : 'blue',
                fillOpacity: 0.9,
                radius: 5,
              })
            })
            multiPoints.forEach(circle => {
              circle.on(
              {
                mouseover: (_event) => {
                  multiPoints.forEach(circleToChange => {
                    circleToChange.setStyle({
                      fillColor: tileType === MAP_TILES ? 'orange' : 'purple',
                    })
                    circleToChange.bringToFront()
                  })
                },
                mouseout: (_event) => {
                  multiPoints.forEach(circleToChange => {
                    circleToChange.setStyle({
                      fillColor: tileType === MAP_TILES ? 'green' : 'blue',
                    })
                  })
                },
              }).addTo(markers).bindTooltip(mapObjectEnteriesToParagraphs(feature.properties), { direction: 'top' })
              pointRefs.current.push(circle)
            })
            break
          }
          case feature.geometry.type === LINE_STRING || feature.geometry.type === MULTI_LINE_STRING: {
            const line = polyline(coordinates, {
              renderer: canvasLayer,
              stroke: true,
              weight: 2,
              color: 'black',
            })
              .on(
                {
                  mouseover: (event) => {
                    event.target.setStyle({
                      weight: event.target.options.weight + 3,
                      color: 'green',
                    })
                  },
                  mouseout: (event) => {
                    event.target.setStyle({
                      weight: event.target.options.weight - 3,
                      color: 'black',
                    })
                  },
                })
              .addTo(markers)
              .bindTooltip(mapObjectEnteriesToParagraphs(feature.properties), { direction: 'top' })
            lineRefs.current.push(line)
            break
          }
          case feature.geometry.type === POLYGON: {
            const simplifyCoords = [...coordinates]
            simplifyCoords[0].pop()
            polygon(simplifyCoords, {
              renderer: canvasLayer,
              stroke: true,
              weight: 0.5,
              color: 'black',
              fillColor: tileType === MAP_TILES ? 'red' : 'blue',
              fillOpacity: 0.4,
              radius: 2,
            }).addTo(markers)
            break
          }
          case feature.geometry.type === MULTY_POLYGON: {
            coordinates.forEach(coordinate => {
              const simplifyCoords = [...coordinate]
              simplifyCoords[0].pop()
              polygon(simplifyCoords, {
                renderer: canvasLayer,
                stroke: true,
                weight: 0.5,
                color: 'black',
                fillColor: tileType === MAP_TILES ? 'red' : 'blue',
                fillOpacity: 0.4,
                radius: 2,
              }).addTo(markers)
            })
            break
          }
        }
      })

      markers.addTo(map)

      const firstPoint = latLng(latitude[1], longitude[0])
      const secondPoint = latLng(latitude[0], longitude[1])
      const newBounds = latLngBounds(firstPoint, secondPoint)

      setCanvasBounds(newBounds)
      map.fitBounds(newBounds)

      const tooFarMarker = circleMarker(newBounds.getCenter(), {
        renderer: canvasLayer,
        stroke: true,
        weight: 0.5,
        color: 'black',
        fillColor: 'blue',
        fillOpacity: 0.4,
        radius: 20,
      }).on('click', (_event) => {
        map.fitBounds(newBounds)
      }).bindTooltip(createSimpleHeader(latestFileName), { direction: 'top' })

      setTooFarAwayMarker(tooFarMarker)

      setCurrentStatus('')
    }, 100)
  }, [canvasLayer])

  const clearGEOJsonData = useCallback(() => {
    if(canvasLayer) {
      map.removeLayer(canvasLayer)
      setCanvasLayer(undefined)
      setCanvasBounds(undefined)
      lineRefs.current = []
      pointRefs.current = []
    }
  }, [map, lineRefs, pointRefs, canvasLayer, setCanvasLayer])

  return {
    canvasLayer,
    canvasBounds,
    isLoading: currentStatus.length > 0,
    status: currentStatus,
    clearData: clearGEOJsonData,
  }
}
