import React from 'react'

export default function AnomalyStartIcon({ color, type }) {
  return(
    <>
      <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M22.682 9.713a3.242 3.242 0 0 1 0 4.584l-8.383 8.383a3.242 3.242 0 0
          1-4.585 0L1.33 14.297a3.242 3.242 0 0 1 0-4.584L9.714 1.33a3.242 3.242 0 0
          1 4.585 0l8.383 8.383z"
          fill="white"
          fillRule="evenodd"/>
      </svg>
      {
        type === 'under_pressure' ?
          <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path
              d="m14.299 22.68 8.383-8.383a3.242 3.242 0 0 0 0-4.584l-4.895-4.895-.034.035-1.415
          1.414a1.125 1.125 0 1 1-1.59-1.592l1.415-1.414.035-.031-1.9-1.9a3.242 3.242 0
          0 0-4.584 0L1.33 9.713a3.23 3.23 0 0 0-.751 1.172l8.426-.005.127.007c.252.029.488.142.669.323l3.08
          3.08v-1.28l.006-.123a1.125 1.125 0 0 1 2.243.123v4l-.006.123a1.125 1.125
          0 0 1-1.119 1.002h-4l-.122-.007A1.125 1.125 0 0 1 8.88 17.01l.007-.123a1.125
          1.125 0 0 1 1.118-1.002h1.288L8.54 13.13l-7.955.005c.157.424.406.822.747 1.162l8.383
          8.383a3.242 3.242 0 0 0 4.585 0zM11.21 9.8a1.125 1.125 0 0 1 .001-1.59l1.415-1.414a1.125
          1.125 0 0 1 1.59 1.591l-1.415 1.414c-.439.439-1.151.439-1.59 0z"
              fill={color} fillRule="evenodd"/>
          </svg>
          :
          <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path
              d="m14.299 1.33 8.383 8.383a3.242 3.242 0 0 1 0 4.584l-4.895 4.895a1.144
          1.144 0 0 0-.034-.035l-1.415-1.414a1.125 1.125 0 1 0-1.59 1.592l1.415
          1.414.035.031-1.9 1.9a3.242 3.242 0 0 1-4.584 0L1.33 14.297a3.23 3.23 0 0
          1-.751-1.172l8.426.005.127-.007c.252-.029.488-.142.669-.323l3.08-3.08V11l.006.123A1.125
          1.125 0 0 0 15.13 11V7l-.006-.123a1.125 1.125 0 0 0-1.119-1.002h-4l-.122.007A1.125 1.125
          0 0 0 8.88 7l.007.123a1.125 1.125 0 0 0 1.118 1.002h1.288L8.54 10.88l-7.955-.005a3.23 3.23
          0 0 1 .747-1.162L9.714 1.33a3.242 3.242 0 0 1 4.585 0zM11.21 14.21a1.125 1.125 0 0
          0 .001 1.59l1.415 1.414a1.125 1.125 0 0 0 1.59-1.591l-1.415-1.414a1.125 1.125 0 0 0-1.59 0z"
              fill={color} fillRule="evenodd"/>
          </svg>
      }
    </>
  )
}
