import { parseSearchParams } from '@@src/utils/index'
import {
  parseMapPositionFromLocation,
  parseMapPositionToLocationObject,
} from '@@src/_v2/components/base_map/helpers/map-positions'

/**
 * @param {string} locationSearch - search string from URL
 * @param {object} defaultValues - default values if nothing is collected from string
 * @param {{center: (GeoCoordinate|undefined), bounds: (GeoBounds|undefined), zoom: (number|undefined)}} defaultValues.mapPosition - default value for map position
 * @param {string} defaultValues.search - default value for search
 * @param {filters[]} defaultValues.filters - default value for filters
 * @param {string} defaultValues.orderBy - default value for sorting in the list
 * @param {string} defaultValues.orderDirection - default value for sorting direction in the list
 * @param {number} defaultValues.pageNumber - default value for page number in the list
 * @param {number} defaultValues.resultsPerPage - default value for results per page in the list
 * @returns {{
 * mapPosition: string,
 * search: string,
 * filters: string[],
 * orderBy: string,
 * orderDirection: string,
 * pageNumber: number,
 * resultsPerPage: number,
 * }} - returns all the components required to describe list and map state
 */
export const parseFiltersFromLocation = (
  locationSearch,
  defaultValues = {}
) => {
  const filtersFromLocation = parseSearchParams(locationSearch)
  const mapPositionFromLocation = parseMapPositionFromLocation(locationSearch)

  return {
    mapPosition: mapPositionFromLocation || defaultValues.mapPosition,
    search: (filtersFromLocation.search || defaultValues.search || '').trim(), // Previously: filtersFromLocation.s instead of filtersFromLocation.search - that created some confusion with the old approach and old views where s meant status, not search
    filters:
      typeof filtersFromLocation.f === 'string'
        ? filtersFromLocation.f.split(' ')
        : defaultValues.filters || [],
    orderBy: filtersFromLocation.ob || defaultValues.orderBy || 'name',
    orderDirection:
      filtersFromLocation.od || defaultValues.orderDirection || 'asc',
    pageNumber: +filtersFromLocation.p || defaultValues.pageNumber || 1,
    resultsPerPage:
      +filtersFromLocation.l || defaultValues.resultsPerPage || 10,
  }
}

export const updateSearchParams = (params) => {
  const position =
    params.mapPosition && parseMapPositionToLocationObject(params.mapPosition)

  const searchParams = Object.entries({
    ...position,
    search: (params.search || '').trim() || null,
    p: params.pageNumber || null,
    l: params.resultsPerPage || null,
    ob: params.orderBy || null,
    od: params.orderDirection || null,
    f: Array.isArray(params.filters) ? params.filters.join('+') || null : null,
  }).reduce(
    (acc, [key, value]) => ({
      ...acc,
      ...(typeof value !== 'undefined' && value !== null
        ? { [key]: value }
        : {}),
    }),
    {}
  )

  return decodeURIComponent(new URLSearchParams(searchParams).toString())
}

export const toggleArrayElement = (arr, val) =>
  arr.includes(val) ? arr.filter((el) => el !== val) : [...arr, val]
