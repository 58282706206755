import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { Redirect } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { Container, Row, Col } from 'reactstrap'

import requiresLogin from '@@src/components/security/requires_login'
import AppLayout from '@@src/components/app_layout'
import userPermissions from '@@src/components/permissions/user_permissions'
import AlertsList from '@@src/alerts_path/alerts_list'
import FiltersPanel from '@@src/alerts_path/filters_panel'
import ExportBar from '@@src/alerts_path/export_bar'
import PagedAlertsProvider from '@@src/alerts_path/paged_alerts_provider'

import styles from './index_page.css'

function AlertsPath(props) {
  const { t, permissions, location } = props

  if (!permissions.includes('can_view_alerts')) {
    return <Redirect to="/page-not-found" />
  }

  return (
    <AppLayout title={t('headings.page_title')}>
      <div className="d-flex w-100">
        <div className={styles['alerts-list-container']}>
          <PagedAlertsProvider>
            <ExportBar location={location} className="py-2 px-3" />
            <Container className="mt-5" fluid={true}>
              <Row>
                <Col sm="11">
                  <AlertsList location={location} />
                </Col>
              </Row>
            </Container>
          </PagedAlertsProvider>
        </div>
        <div className="bg-white p-3">
          <FiltersPanel />
        </div>
      </div>
    </AppLayout>
  )
}

AlertsPath.propTypes = {
  permissions: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
}

AlertsPath.defaultProps = {
  permissions: [],
}

export default compose(
  requiresLogin,
  userPermissions,
  withTranslation(['src/alerts_path/index_page'])
)(memo(AlertsPath))
