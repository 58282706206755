import React from 'react'
import { Popup } from '@@src/components/popup'
import { Link } from 'react-router-dom'
import BorderlessButton from '@@src/components/buttons/borderless_button'
import AnomalyTypeOver from '../../../../static_assets/svgs/anomaly-types-high.svg'
import AnomalyTypeUnder from '../../../../static_assets/svgs/anomaly-types-low.svg'
import {
  LEGEND_ITEM_TYPE_CHANNEL, LEGEND_ITEM_TYPE_MULTI_CHANNEL_ASSET,
  LEGEND_ITEM_TYPE_DEVICE, LEGEND_ITEM_TYPE_ASSET,
} from '@@src/components/graphs/legend_item'
import NetworkAssetIcon from '@@src/components/icons/network_asset_icon'
import DeviceIconWithStatus
from '@@src/components/icons/device_icon_with_status'
import routes from '@@src/routes'
import { convertMsToTime } from '../../../../utils'

import styles from './anomaly_layer.css'

export default function AnomalyPopup({
  t,
  anomalyItem,
  isActive,
  onRemoveExpandedAnomaly,
  isActiveAnomalyExpanded,
  onHideBounds,
  boundsShown,
}) {
  if(!anomalyItem) {
    return null
  }

  const formatDate = (date) => {
    const month = date.getMonth() + 1
    const day = date.getDate()
    const hours = date.getHours()
    const minutes = date.getMinutes()
    const seconds = date.getSeconds()
    // eslint-disable-next-line max-len
    return `${date.getFullYear()}.${month < 10 ? '0' + month : month}.${day < 10 ? '0' + day : day} ${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`
  }

  const getIconForLegendItemType = (legendItem) => {
    switch (legendItem.type) {
      case LEGEND_ITEM_TYPE_DEVICE: {
        const device = legendItem.source
        return (
          <DeviceIconWithStatus
            device={device}
            className={styles['legend-icon-device']} />
        )
      }

      case LEGEND_ITEM_TYPE_CHANNEL:
      case LEGEND_ITEM_TYPE_ASSET:
      case LEGEND_ITEM_TYPE_MULTI_CHANNEL_ASSET: {
        return (
          <NetworkAssetIcon
            networkAsset={legendItem.source}
            className={styles['legend-icon-network-asset']} />
        )
      }

      default:
        throw new Error(`Unexpected legend item type "${legendItem.type}"`)
    }
  }

  return(
    <Popup
      name={`anomaly-popup-${anomalyItem.id}`}
      key={anomalyItem.id}
      isOpen={isActive && isActiveAnomalyExpanded}
      className={styles.popup}
    >
      <div className={styles['tooltip-actions-row']}>
        <div className={styles['tooltip-actions-row-text']}>
          <span className={styles['duration-text']}>
            <img
              width="16px"
              height="16px"
              src={anomalyItem.anomaly.type === 'over_pressure' ? AnomalyTypeOver : AnomalyTypeUnder}
            >
            </img>
            &nbsp;
            &nbsp;
            {t(`text.${anomalyItem.anomaly.type}`)}
          </span>
        </div>

        <div className={styles['tooltip-actions-row-actions']}>
          <BorderlessButton
            color="secondary"
            onClick={onRemoveExpandedAnomaly}
            className={styles['close-button']}>
            <i className="fal fa-times"></i>
          </BorderlessButton>
        </div>
      </div>

      <div className={styles['tooltip-actions-row']}>
        <span className={styles['tooltip-actions-row-text']}>
          <span className={styles['tooltip-info-row']}>
            {
              formatDate(anomalyItem.anomaly.startTime)
            }
            &nbsp;
            {
              t('text.at')
            }
          </span>
          &nbsp;
          <Link to={routes.networkAssetsDetailPath(anomalyItem.legendItem.sourceId)}>
            {getIconForLegendItemType(anomalyItem.legendItem)}
          </Link>
          &nbsp;
          <Link to={routes.networkAssetsDetailPath(anomalyItem.legendItem.sourceId)}>
            {anomalyItem.legendItem.name}
          </Link>
        </span>
      </div>

      <div name="anomaly-popup-info-container" className={styles['tooltip-actions-row']}>
        <table name="anomaly-popup-info-table" className={styles.table}>
          <thead>
            <tr style={{ color: '#9b9b9b', fontSize: '7px', lineHeight: '0px' }}>
              <th>{t('text.average')}</th>
              <th>{t('text.min')}</th>
              <th>{t('text.max')}</th>
              <th>{t('text.diff')}</th>
              <th>{t('text.duration')}</th>
            </tr>
          </thead>

          <tbody>
            <tr className={styles['tooltip-actions-data']}>
              <td>
                {anomalyItem.anomaly.avg.toFixed(3)}
              </td>
              <td>
                {anomalyItem.anomaly.min.toFixed(3)}
              </td>
              <td>
                {anomalyItem.anomaly.max.toFixed(3)}
              </td>
              <td>
                {anomalyItem.anomaly.diff.toFixed(3)}
              </td>
              <td>
                {convertMsToTime(anomalyItem.anomaly.duration)}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className={styles['tooltip-actions-row-actions']}>
        <div className={styles['tooltip-actions-bounds']}>
          <BorderlessButton
            color="secondary"
            onClick={onHideBounds}
            className={styles['button-hide-show-bounds']}>
            <span>{boundsShown ? t('text.hide_bounds') : t('text.show_bounds')}</span>
          </BorderlessButton>
        </div>
      </div>
    </Popup>
  )
}
