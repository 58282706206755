import React from 'react'

export default function EventStartIcon({ color }) {
  return(
    <>
      <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12 1c6.075 0 11 4.925 11 11s-4.925 11-11 11S1 18.075 1 12 5.925 1 12 1z"
          fill="white"
          fillRule="evenodd"/>
      </svg>
      <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10.875 10.715V16l.006.118c.09.922 1.234 1.358 1.914.677l3.67-3.67h6.478C22.38 18.672 17.695
           23 12 23c-5.695 0-10.38-4.328-10.943-9.875H8l.127-.007c.252-.029.488-.142.668-.323l2.08-2.08zM12
           1c5.695 0 10.38 4.328 10.943 9.875H16l-.127.007a1.125 1.125 0 0 0-.668.323l-2.08
           2.078V8c0-1.002-1.212-1.504-1.92-.795l-3.672 3.67H1.057C1.62 5.33 6.304 1 12 1z"
          fill={color}
          fillRule="evenodd"/>
      </svg>
    </>
  )
}
