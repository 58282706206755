import React from 'react'
import { Alert } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import * as analytics from '@@src/analytics'
import DeviceIssue from '@@src/api/presenters/device_issue'
import { NETWORK_ASSET_FILTERS } from '@@src/components/modals/network_asset_filters'
import {
  INSTALLED,
  NOT_INSTALLED,
  DEVICE_STATE_FILTERS,
} from '@@src/components/modals/device_state_filters'
import { validGroupCategories, allValidDedupedAssetTypes } from '@@src/utils'
import {
  DEVICE,
  NETWORK_ASSET,
  GROUP,
  GROUP_TYPE_FILTERS,
} from '@@src/components/modals/group_type_filters'

const GROUP_CATEGORIES_FILTERS = validGroupCategories()
const ASSET_TYPE_FILTERS = allValidDedupedAssetTypes()

export const LITERAL_FILTERS = [
  DeviceIssue.HEALTHY_FILTER,
  INSTALLED,
  ...NETWORK_ASSET_FILTERS,
  ...DeviceIssue.VISIBLE_ISSUE_TYPES,
  ...GROUP_CATEGORIES_FILTERS,
  ...ASSET_TYPE_FILTERS,
]

const ActiveFiltersAlert = ({ activeFilters, className }) => {
  const { t } = useTranslation([
    'src/components/alerts/active_filters_alert',
    'src/components/modals/device_issue_filters',
    'src/components/summary/devices_summary',
    'common/text',
  ])

  const getFilterKey = (filter) => {
    if (LITERAL_FILTERS.includes(filter)) {
      return t(`filters.${filter}`)
    }

    switch (filter) {
      case NOT_INSTALLED:
        return t('filters.not_installed')
      case DeviceIssue.ERROR_SEVERITY_FILTER:
        return t('filters.with_errors')
      case DeviceIssue.WARNING_SEVERITY_FILTER:
        return t('filters.with_warnings')
      case DeviceIssue.INFORMATION_SEVERITY_FILTER:
        return t('filters.with_information')
      case NETWORK_ASSET:
        return t('filters.asset')
      case DEVICE:
        return t('filters.device')
      case GROUP:
        return t('filters.group')
      default:
        analytics.logError(`unknown active filter: ${filter}`)
        return ''
    }
  }

  const getFormattedActiveFilters = () => {
    if (!Array.isArray(activeFilters) || activeFilters.length === 0) {
      return ''
    } else {
      return activeFilters
        .map(getFilterKey)
        .map((f) => `"${f}"`)
        .filter((f) => f)
        .join(', ')
    }
  }

  return (
    <Alert
      className={className}
      isOpen={Array.isArray(activeFilters) && activeFilters.length > 0}
      color="info"
    >
      {t('text.showing_only')} <strong>{getFormattedActiveFilters()}</strong>
    </Alert>
  )
}

ActiveFiltersAlert.propTypes = {
  activeFilters: PropTypes.arrayOf(
    PropTypes.oneOf([
      DeviceIssue.HEALTHY_FILTER,
      ...DEVICE_STATE_FILTERS,
      ...NETWORK_ASSET_FILTERS,
      ...DeviceIssue.VISIBLE_ISSUE_TYPES,
      ...DeviceIssue.ISSUE_SEVERITY_FILTERS,
      ...GROUP_TYPE_FILTERS,
      ...GROUP_CATEGORIES_FILTERS,
      ...ASSET_TYPE_FILTERS,
    ])
  ).isRequired,
  className: PropTypes.string,
}

ActiveFiltersAlert.defaultProps = {
  activeFilters: [],
}

export default ActiveFiltersAlert
