import React from 'react'
import gql from 'graphql-tag'
import { graphql } from '@apollo/client/react/hoc'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { MixedGroupDetailsType } from '@@src/api/presenters/group'
import NetworkAsset from '@@src/api/presenters/network_asset'
import AsyncResult from '@@src/utils/async_result'
import { createSelectGraphQLResult } from '@@src/utils'
import { parseGraphQLResult } from '@@src/api/presenters'

export default function withSourceFilterProvider(Component) {
  class SourceFilterProvider extends React.PureComponent {
    static propTypes = {
      networkAssetSourceFilterResult:
        PropTypes.instanceOf(AsyncResult).isRequired,
      groupSourceFilterResult: PropTypes.instanceOf(AsyncResult).isRequired,
      sourceFilter: PropTypes.string,
    }

    static defaultProps = {
      networkAssetSourceFilterResult: AsyncResult.notFound([]),
      groupSourceFilterResult: AsyncResult.notFound([]),
    }

    render() {
      return (
        <Component {...this.props} />
      )
    }

    static NETWORK_ASSET_BY_ID_QUERY = gql`
      query NetworkAsset($id: Int!) {
        networkAsset(id: $id) {
          id
          assetId
          assetName
        }
      }
    `

    static GROUP_BY_ID_QUERY = gql`
      query GroupMix($id: Int!) {
        groupMix(id: $id) {
          id
          name
          category
          members {
            count
            type
          }
        }
      }
    `
  }

  function createMapStateToProps() {
    const selectNetworkAssetSourceFilterResult =
      createSelectGraphQLResult('networkAsset', {
        mapResult: parseGraphQLResult,
        nullObject: null,
      })

    const selectGroupSourceFilterResult = createSelectGraphQLResult(
      'groupMix', {
        mapResult: parseGraphQLResult,
        nullObject: null,
      })

    return function mapStateToProps() {
      return {
        selectNetworkAssetSourceFilterResult,
        selectGroupSourceFilterResult,
      }
    }
  }

  return compose(
    connect(createMapStateToProps),
    graphql(SourceFilterProvider.NETWORK_ASSET_BY_ID_QUERY, {
      skip({ sourceFilter }) {
        return !sourceFilter ||
          !NetworkAsset.isNetworkAssetDataSourceId(sourceFilter)
      },
      options: ({ sourceFilter }) => {
        return {
          variables: { id: NetworkAsset.parseIdFromDataSourceId(sourceFilter) },
        }
      },
      props: ({ data, ownProps: { selectNetworkAssetSourceFilterResult } }) => {
        return {
          networkAssetSourceFilterResult:
            selectNetworkAssetSourceFilterResult(data),
        }
      },
    }),
    graphql(SourceFilterProvider.GROUP_BY_ID_QUERY, {
      skip({ sourceFilter }) {
        return !sourceFilter ||
          !MixedGroupDetailsType.isGroupDetailsDataSourceId(sourceFilter)
      },
      options: ({ sourceFilter }) => {
        return {
          variables: {
            id: MixedGroupDetailsType.parseIdFromDataSourceId(sourceFilter),
          },
        }
      },
      props: ({ data, ownProps: { selectGroupSourceFilterResult } }) => {
        return {
          groupSourceFilterResult: selectGroupSourceFilterResult(data),
        }
      },
    }),
  )(SourceFilterProvider)
}
