/*
 * Modal for showing a successful localisation request
 *
 * Displays the user's email to notify them the address to which the localisation report will be sent to.
 * An estimated time is also displayed so user knows when to expect the report.
 * Note: all devices must have finished communicating before Arboricity team can generate metrics and send the report.
 */

import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import {
  Alert,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap'
import { createSelector } from 'reselect'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { get } from 'lodash/fp/object'
import { withTranslation } from 'react-i18next'
import { graphql } from '@apollo/client/react/hoc'
import gql from 'graphql-tag'
import { parseGraphQLResult } from '@@src/api/presenters'
import { createSelectGraphQLResult } from '@@src/utils'
import AsyncResult from '@@src/utils/async_result'
import GraphItem from '@@src/components/graphs/graph_item'
import moment from 'moment'
import AsyncResultSwitch from '@@src/components/async_result_switch'
import { withUser } from '../../../../_v2/contexts/user/user.context'

import styles from './transient_localisation_success_modal.css'

class TransientLocalisationSuccessModal extends PureComponent {

  static propTypes = {
    dataSourcesResult: PropTypes.instanceOf(AsyncResult).isRequired, // provided by compose
    handleToggle: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,

    networkAssetGraphItems:
    PropTypes.arrayOf(PropTypes.instanceOf(GraphItem)).isRequired,

    t: PropTypes.func.isRequired,
  }

  static defaultProps = {
    isOpen: false,
  }

  selectContactDetails = createSelector(
    [get('dataSourcesResult')],
    (dataSourcesResult) => {
      if (dataSourcesResult.wasSuccessful) {
        const { data: allDevices } = dataSourcesResult
        const timeNow = moment()

        // For every device obtain the next contact date (hours from now)
        const allNextContactAsHours = allDevices.map((device) => {

          let hours = null
          const nextContactDate = device.getNextContactDate()

          if (nextContactDate !== null) {
            hours = moment.duration(nextContactDate.diff(timeNow)).asHours()
          }

          return {
            id: device.id,
            serialNumber: device.serialNumber,
            hours,
          }
        })

        // We only need an array of hours (device id present for dev debugging purposes)
        const allHours = allNextContactAsHours
          .filter(d => d.hours !== null)
          .map(d => d.hours)

        const noNextContactDate = allNextContactAsHours
          .filter(d => d.hours === null)

        return {
          maxNextContactAsHours: Math.ceil(Math.max(...allHours)),
          noNextContactDate,
        }
      }

      // Still awaiting data to be returned (dataSourcesResult is pending)
      return {
        maxNextContactAsHours: null,
        noNextContactDate: [],
      }
    },
  )

  renderModalBody(noNextContactDate, maxNextContactAsHours, t) {
    const { authorizedUser } = this.props
    const email = authorizedUser.email

    return (
      <>
        {
          /* MISSING NEXT CONTACT MESSAGE
          (hide if we know what all device next contact times are) */
          noNextContactDate.length ?
            <Alert
              name="missing-device-next-contact-container"
              color="warning">
              <p>{t('body.missing_next_contact')}</p>
              <ul name="missing-device-next-contact-list">
                {noNextContactDate.map(
                  device => <li>{device.serialNumber}</li>)}
              </ul>
            </Alert>
            : null
        }
        <p name="model-body-paragraph-main">
          {t('body.once_finished', { email })}
        </p>
        <p name="model-body-paragraph-time">
          {maxNextContactAsHours < 0 ?
            t('body.no_estimated_time') :
            t('body.remaining_time', { count: maxNextContactAsHours })
          }
        </p>
      </>
    )
  }

  render() {

    const {
      handleToggle,
      isOpen,
      t,
    } = this.props

    /** Obtain our next device communication time (max time in hours for all devices).
     * Once all devices have communicated the localisation report can be generated by the
     * Arboricity team. This figure is an estimated time (hours) so the users know roughly
     * how long it will take before the report is sent to them via email later on.
     */

    /**
     * noNextContactDate:     an array of device info (objects) for devices
     *                        where we cannot obtain next contact dates (we shouldn't have any)
     *
     * maxNextContactAsHours: an integer for the max hours for next contact dates for all devices
     */
    const {
      noNextContactDate,
      maxNextContactAsHours,
    } = this.selectContactDetails(this.props)

    return (
      <Modal
        centered={true}
        isOpen={isOpen}
      >
        <ModalHeader>
          <span name="modal-title" className={styles['modal-title']}>
            {t('heading.title')}
          </span>
        </ModalHeader>
        <ModalBody>
          <AsyncResultSwitch
            result={this.props.dataSourcesResult}
            renderSuccessResult={() => this.renderModalBody(noNextContactDate, maxNextContactAsHours, t)}
          />
        </ModalBody>
        <ModalFooter>
          <Button name="button-cancel" color="secondary" onClick={handleToggle}>
            {t('buttons.close')}
          </Button>
        </ModalFooter>
      </Modal>
    )

  }
}

// Query returns device data for specified deviceIds
// (used to obtain device next communication times)
const DataSourcesQuery = gql`
  query DataSources(
    $dataSourceType: String,
    $deviceIds: [Int],
  ) {
    dataSources(
      dataSourceType: $dataSourceType,
      deviceIds: $deviceIds
    ) {
      ... on Device {
        id
        serialNumber
        protocolVersion
        currentCommission {
          start
          location {
            latitude
            longitude
          }
        }
        activeStandardConfiguration {
          dateFrom
          secondsInterval
          timeZone
        }
      }
    }
  }
`

function mapStateToProps() {
  // Add this function to props so it can be referred to by our graphql function (within compose)
  // (function obtains the device data from GraphQL query)
  const selectDataSourcesResult =
    createSelectGraphQLResult(
      'dataSources', {
        mapResult: parseGraphQLResult,
        nullObject: [],
      })

  return {
    selectDataSourcesResult,
  }
}

export default compose(
  withTranslation([
    'src/components/graphs/modals/transient_localisation_modals/transient_localisation_success_modal', // eslint-disable-line max-len
  ]),
  connect(mapStateToProps),
  withUser,
  graphql(DataSourcesQuery, {
    options: ({ networkAssetGraphItems }) => {

      // Get an array of device IDs as we want to know the next communication time
      const deviceIds = networkAssetGraphItems
        .map(graphItem => graphItem.subsetData.deviceId)

      return {
        fetchPolicy: 'network-only',
        variables: {
          dataSourceType: 'device',
          deviceIds: deviceIds,
        },
      }
    },
    props: ({ data, ownProps }) => {
      const { selectDataSourcesResult } = ownProps
      return {
        dataSourcesResult: selectDataSourcesResult(data),
      }
    },
  }),
)(TransientLocalisationSuccessModal)
