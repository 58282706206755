import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Marker, useLeaflet } from 'react-leaflet'
import GeoCoordinate from '../../../../../../utils/graphs/geo_coordinate'
import DeviceMapMarkerComponent from '../map_marker/device_map_marker.component'
import DeviceMapMarkerClusterComponent from './device_map_marker_cluster.component'
import { abbreviateNumber, renderClusterIcon } from '../../../../../components/map_marker/utils'

const getStatsOfClusterChildren = (supercluster, cluster) => {
  const children = supercluster.getChildren(cluster.id)
  const devices = []
  let [errors, warnings, information, shouldReturnDevices] = [0, 0, 0, true]

  children.forEach(kid => {
    const device = kid.properties.device
    const isCluster = kid.properties.cluster || false

    if (!isCluster) {
      devices.push(device)
      errors += +(device.activeErrorsCount || 0)
      warnings += +(device.activeWarningsCount || 0)
      information += +(device.activeInfoCount || 0)
    } else {
      const childrenCount = getStatsOfClusterChildren(supercluster, kid)

      shouldReturnDevices = false
      errors += +(childrenCount.errors || 0)
      warnings += +(childrenCount.warnings || 0)
      information += +(childrenCount.information || 0)
    }
  })

  return { errors, warnings, information, devices: shouldReturnDevices ? devices : null }
}

const DeviceMapSuperClusterComponent = ({ cluster, supercluster }) => {
  const [lng, lat] = cluster.geometry.coordinates
  const { map } = useLeaflet()
  const { errors, warnings, information, devices } = getStatsOfClusterChildren(supercluster, cluster)

  const countText = abbreviateNumber(cluster.properties.point_count)
  const icon = renderClusterIcon({ errors, warnings, information, countText })
  const onClusterClick = () => map.flyTo(
    GeoCoordinate.fromValue(lat, lng).toLatLng(),
    Math.min(supercluster.getClusterExpansionZoom(cluster.id), 20),
    { animate: true, duration: 0.2 },
  )

  const renderCorrectItem = useMemo(() => {
    if(devices) {
      return(<DeviceMapMarkerClusterComponent showCoverageOnHover={false} removeOutsideVisibleBounds={true}>
        {devices.map(device => (
          <DeviceMapMarkerComponent key={`d.marker.${device.deviceId}`} device={device} />
        ))}
      </DeviceMapMarkerClusterComponent>)
    }

    if(lat && lng) {
      return <Marker position={[lat, lng]} onClick={onClusterClick} icon={icon} />
    }

    return null
  }, [devices, icon, lat, lng])

  return renderCorrectItem
}

DeviceMapSuperClusterComponent.propTypes = {
  cluster: PropTypes.object.isRequired,
  supercluster: PropTypes.object.isRequired,
}

export default DeviceMapSuperClusterComponent
