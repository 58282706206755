import React, { useCallback, useState } from 'react'
import {
  DevicesListFilters,
  DevicesListActions,
  DevicesListBreadcrumbs,
} from './header'
import { Card } from 'reactstrap'
import { ListPlaceholder } from '../../../../components/placeholders/list_placeholder'
import useGetListDevices from '../hooks/use_get_list_devices'
import PaginationComponent from '../../../../components/pagination/pagination.component'
import PaginationLimitComponent from '../../../../components/pagination/pagination_limit_component'
import DevicesListItem from './list-item/devices_list_item.component'
import DevicesInstallModal from './devices_install_device_modal/devices_install_device_modal.component'
import useIntervalService from '../hooks/useIntervalService'
import PropTypes from 'prop-types'

const DevicesList = ({
  onShowEverywhereClick = () => null,
  setFilters = () => null,
  setPagination = () => null,
  currentSettings = {},
}) => {
  const { search, filters, orderBy, orderDirection } = currentSettings
  const {
    isLoading,
    devices,
    pagination,
    refetch: refetchDevicesList,
  } = useGetListDevices(currentSettings)

  const [installModalForDevice, setInstallModalForDevice] = useState(null)
  const toggleInstallModalOpen = () => setInstallModalForDevice(null)

  const onPaginate = (pageNumber) => setPagination({ pageNumber })
  const onChangeLimit = (resultsPerPage) =>
    setPagination({ pageNumber: 1, resultsPerPage })

  const handleOpenInstallModal = useCallback(
    (device) => {
      setInstallModalForDevice(device)
    },
    [setInstallModalForDevice]
  )

  const intervalService = useIntervalService(1000)

  // TODO: This is because we're getting an array with + as a separator from the URL, but we need to send an array with , as a separator in order to make the filters work.
  // Consider updating that split separator directly in the updateSearchParams function (and other places that affect this). This is a quick fix to avoid any other issues related to other components that might be using the same filters logic.
  const filtersConverted = filters.flatMap((item) => item.split('+'))

  return (
    <div className="h-100 pb-3 pt-5 pl-5 pr-5 d-flex flex-column">
      <DevicesInstallModal
        device={installModalForDevice}
        isOpen={installModalForDevice !== null}
        toggle={toggleInstallModalOpen}
        onInstallSuccess={refetchDevicesList}
      />
      <DevicesListBreadcrumbs />

      <DevicesListActions
        filters={currentSettings}
        enableExport={!isLoading}
        deviceNumber={(pagination && pagination.totalResults) || 0}
      />
      <DevicesListFilters
        loadingResults={isLoading}
        totalResults={(pagination && pagination.totalResults) || 0}
        setFilters={setFilters}
        search={search}
        filters={filtersConverted}
        orderBy={orderBy}
        orderDirection={orderDirection}
        onShowEverywhereClick={onShowEverywhereClick}
      />
      <Card className="w-100 h-100 mt-3 p-3 align-self-stretch overflow-auto border-0">
        {isLoading && <ListPlaceholder />}
        {!isLoading &&
          devices.map((device, i) => (
            <DevicesListItem
              key={`list.na.item.${device.deviceId}`}
              intervalService={intervalService}
              openInstallDeviceModal={handleOpenInstallModal}
              isLast={
                i === pagination.perPage - 1 ||
                i === pagination.totalResults - 1
              }
              device={device}
            />
          ))}
      </Card>
      {pagination && (
        <div className="w-100 mt-3 d-flex flex-row justify-content-between">
          <PaginationComponent
            pagination={pagination}
            onPaginate={onPaginate}
          />
          <PaginationLimitComponent
            pagination={pagination}
            onChangeLimit={onChangeLimit}
          />
        </div>
      )}
    </div>
  )
}

DevicesList.propTypes = {
  onShowEverywhereClick: PropTypes.func.isRequired,
  setFilters: PropTypes.func.isRequired,
  setPagination: PropTypes.func.isRequired,
  currentSettings: PropTypes.object.isRequired,
}

export default DevicesList
