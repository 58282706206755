import { flatMap } from 'lodash/fp/collection'

export const LEGEND_ITEM_TYPE_ASSET = 'legend-item-type/asset'
export const LEGEND_ITEM_TYPE_GROUP = 'legend-item-type/group'
export const LEGEND_ITEM_TYPE_DEVICE = 'legend-item-type/device'
export const LEGEND_ITEM_TYPE_CHANNEL = 'legend-item-type/channel'
export const LEGEND_ITEM_TYPE_MULTI_CHANNEL_ASSET =
  'legend-item-type/multi-channel-asset'

export default class LegendItem {
  constructor({
    id, name, visible = true, source, type, sourceChannel,
    children = null,
  }) {
    Object.assign(this, {
      id, type, name, source, sourceChannel, children, visible,
    })

    Object.freeze(this)
  }

  get sourceId() {
    return this.source.id
  }

  get dataSourceId() {
    return this.source.dataSourceId
  }

  get sourceType() {
    switch (this.type) {
      case LEGEND_ITEM_TYPE_DEVICE:
        return 'device'

      case LEGEND_ITEM_TYPE_ASSET:
      case LEGEND_ITEM_TYPE_CHANNEL:
      case LEGEND_ITEM_TYPE_MULTI_CHANNEL_ASSET:
        return this.source.assetType

      case LEGEND_ITEM_TYPE_GROUP:
        return 'group'

      default:
        throw new Error(`Unrecognised type for LegendItem: "${this.type}"`) // eslint-disable-line max-len
    }
  }

  isFromDevice() {
    return this.type === LEGEND_ITEM_TYPE_DEVICE
  }

  isFromNetworkAsset() {
    return this.type === LEGEND_ITEM_TYPE_ASSET ||
      this.type === LEGEND_ITEM_TYPE_MULTI_CHANNEL_ASSET ||
      this.type === LEGEND_ITEM_TYPE_CHANNEL
  }

  isFromNetworkAssetChannel() {
    return this.type === LEGEND_ITEM_TYPE_CHANNEL
  }

  isFromGroup() {
    return this.type === LEGEND_ITEM_TYPE_GROUP
  }

  hasChildren() {
    return this.children && this.children.length
  }

  allChildrenIds() {
    return flatMap(child => {
      return [child.id, ...child.allChildrenIds()]
    })(this.children || [])
  }

  isDataSource() {
    return this.type === LEGEND_ITEM_TYPE_DEVICE ||
      this.type === LEGEND_ITEM_TYPE_ASSET ||
      this.type === LEGEND_ITEM_TYPE_CHANNEL
  }

  mapWith(newOptions) {
    return new LegendItem({ ...this, ...newOptions })
  }

  legendItemNameAndChannelText = t => {
    if (this.type === LEGEND_ITEM_TYPE_CHANNEL) {
      const translatedChannel = t(`common/text:text.asset_channel_${this.name}`)

      return `${this.source.assetId} (${translatedChannel})`
    } else {
      return this.name
    }
  }

  static fromDataSource(dataSource, options = {}) {
    const {
      visible, ...extras
    } = options
    const params = {
      id: dataSource.dataSourceId,
      source: dataSource,
      visible,
    }

    switch (dataSource.__typename) {
      case 'Device':
        params.name = dataSource.serialNumber
        params.type = LEGEND_ITEM_TYPE_DEVICE
        break

      case 'NetworkAsset': {
        if (extras.sourceChannel) {
          params.id = dataSource.dataSourceIdForChannel(extras.sourceChannel)
          params.name = extras.sourceChannel
          params.type = LEGEND_ITEM_TYPE_CHANNEL
          params.sourceChannel = extras.sourceChannel

        } else if (dataSource.availableChannels.length > 1) {
          params.name = dataSource.assetName || dataSource.assetId
          params.type = LEGEND_ITEM_TYPE_MULTI_CHANNEL_ASSET
          params.children = dataSource.availableChannels.map(sourceChannel => {
            return LegendItem.fromDataSource(dataSource, {
              visible,
              sourceChannel,
            })
          })
        } else {
          params.name = dataSource.assetName || dataSource.assetId
          params.type = LEGEND_ITEM_TYPE_ASSET
          params.sourceChannel = dataSource.availableChannels[0]
        }
        break
      }

      case 'GroupDetailsType':
        params.name = dataSource.name
        params.type = LEGEND_ITEM_TYPE_GROUP
        params.children = dataSource.members.data.map(member => {
          return LegendItem.fromDataSource(member, {
            visible,
          })
        })
        break

      case 'MixedGroupDetailsType':
        params.name = dataSource.name
        params.type = LEGEND_ITEM_TYPE_GROUP
        params.children = dataSource.members.data.map(member => {
          return LegendItem.fromDataSource(member, {
            visible,
          })
        })
        break

      default:
        throw new Error(`Unsupported data source type for LegendItem: "${dataSource.__typename}"`) // eslint-disable-line max-len
    }

    return new LegendItem({
      ...params,
    })
  }
}
