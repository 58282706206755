import gql from 'graphql-tag'

export const GET_TENANT_FEATURE_FLAGS_GRAPHQL = gql`
  query GetFeatureFlagsForTenant {
    getTenantFeatureFlags {
      anomaliesFeatureEnabled
      devicesManagementListViewV2Enabled
      networkAssetsManagementListViewV2Enabled
    }
  }
`
