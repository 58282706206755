import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash/fp/object'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import { withTranslation } from 'react-i18next'

import GraphItem from '@@src/components/graphs/graph_item'
import AsyncResult from '@@src/utils/async_result'
import DragPanLayer from '@@src/components/graphs/drag_pan_layer'
import GraphContext from '@@src/components/graphs/graph_context'
import DragZoomLayer from '@@src/components/graphs/drag_zoom_layer'
import DragAreaOverlay
from '@@src/components/graphs/drag_layer/drag_area_overlay'
import DragAreaSummaryLayer
from '@@src/components/graphs/drag_area_summary_layer'
import { MouseEventContext } from '@@src/components/graphs/mouse_event_layer'
import { DragEventContext, ONLY_X, X_AND_Y, ONLY_Y, NONE }
from '@@src/components/graphs/drag_layer/drag_event_layer'
import {
  ZOOM_DRAG_MODE, SELECT_DRAG_MODE,
} from '@@src/components/singletons/drag_cover'

const noop = () => { }

class InteractionControllerLayer extends React.PureComponent {
  static propTypes = {
    onPan: PropTypes.func.isRequired,
    timezone: PropTypes.string,
    dataUnits: PropTypes.any.isRequired,
    graphItems: PropTypes.arrayOf(PropTypes.instanceOf(GraphItem)).isRequired,
    onAxesChange: PropTypes.func.isRequired,
    dragBehaviour: PropTypes.oneOf([NONE, ONLY_X, ONLY_Y, X_AND_Y]).isRequired,
    measureBounds: PropTypes.array,
    enableCursorTool: PropTypes.bool.isRequired,
    renderCursorTool: PropTypes.func.isRequired,
    setFocusedLegendItem: PropTypes.func.isRequired,
    onCopyBoundsIntoLink: PropTypes.func.isRequired,
    refetchRawDataRequests: PropTypes.func.isRequired,
    onDismissDragAreaSummary: PropTypes.func.isRequired,
    listRawDataRequestsResult: PropTypes.instanceOf(AsyncResult),
    setSelection: PropTypes.func.isRequired,
    setRawDataModalOpen: PropTypes.func.isRequired,
    setTransientLocalisationModalOpen: PropTypes.func.isRequired,
  }

  static defaultProps = {
    onPan: noop,
    dataUnits: '',
    graphItems: [],
    onAxesChange: noop,
    dragBehaviour: ONLY_X,
    enableCursorTool: false,
    renderCursorTool: noop,
    setFocusedLegendItem: noop,
    onCopyBoundsIntoLink: noop,
    refetchRawDataRequests: noop,
    onDismissDragAreaSummary: noop,
  }

  state = {
    hideCursor: false,
    showingAreaSummary: false,
  }

  render() {
    const {
      enableCursorTool, DragEventChannel, renderCursorTool, dragMode,
      measureBounds,
    } = this.props
    const { hideCursor, showingAreaSummary } = this.state

    return (
      <DragEventChannel onDrag={this.onDrag} onDragEnd={this.onDragEnd}>
        {
          enableCursorTool &&
          !hideCursor &&
          !measureBounds &&
          (!showingAreaSummary || dragMode !== SELECT_DRAG_MODE) ?
            renderCursorTool() : null
        }

        <DragAreaOverlay/>

        {this.renderDragModeComponent()}
      </DragEventChannel>
    )
  }

  renderDragModeComponent() {
    const {
      graphConfig, dragMode, graphItems, dataUnits, timezone, children,
      dragBehaviour, onPan, setFocusedLegendItem, measureBounds,
      onCopyBoundsIntoLink, setSelection, setRawDataModalOpen,
      setTransientLocalisationModalOpen,
    } = this.props

    const networkAssetGraphItems = this.selectNetworkAssetGraphItems(this.props)

    return (
      measureBounds || dragMode === SELECT_DRAG_MODE ? (
        <DragAreaSummaryLayer
          onAppear={this.onAppearDragAreaSummary}
          timezone={timezone}
          dataUnits={dataUnits}
          onDismiss={this.onDismissDragAreaSummary}
          graphItems={graphItems}
          measureBounds={measureBounds}
          onCopyBoundsIntoLink={onCopyBoundsIntoLink}
          setFocusedLegendItem={setFocusedLegendItem}
          networkAssetGraphItems={networkAssetGraphItems}
          setSelection={setSelection}
          setRawDataModalOpen={setRawDataModalOpen}
          setTransientLocalisationModalOpen=
            {setTransientLocalisationModalOpen}/>
      ) : dragMode === ZOOM_DRAG_MODE ? (
        <DragZoomLayer graphConfig={graphConfig} onZoom={this.onGraphZoom}>
          {children}
        </DragZoomLayer>
      ) : (
        <DragPanLayer
          graphConfig={graphConfig}
          dragBehaviour={dragBehaviour}
          onPan={onPan}
          onPanEnd={this.onGraphPanEnd}>
          {children}
        </DragPanLayer>
      )
    )
  }

  onAppearDragAreaSummary = () => {
    this.setState({ showingAreaSummary: true })
  }

  onDismissDragAreaSummary = () => {
    this.setState({ showingAreaSummary: false })

    this.props.onDismissDragAreaSummary()
  }

  onDrag = ({ x, y }) => {
    if (x || y) {
      this.setState({ hideCursor: true })
    } else {
      this.setState({ hideCursor: false })
    }
  }

  onDragEnd = () => {
    this.setState({ hideCursor: false })
  }

  onGraphZoom = ({ dragX, dragY }) => {
    if (dragX || dragY) {
      this.props.onAxesChange({ xAxes: dragX, yAxes: dragY })
    }
  }

  onZoomDrag = ({ dragX, dragY }) => {
    if (dragX || dragY) {
      this.props.onAxesChange({ xAxes: dragX, yAxes: dragY })
    }
  }

  onGraphPanEnd = ({ panEventX, panEventY }) => {
    if (panEventX || panEventY) {

      this.props.onAxesChange({ xAxes: panEventX, yAxes: panEventY })
    }
  }

  onGraphPan = ({ panEventX, panEventY }) => {
    if (panEventX || panEventY) {
      this.setState({ hideCursor: true })
    } else {
      this.setState({ hideCursor: false })
    }

    this.props.onPan({ panEventX, panEventY })
  }

  onAxesChange = (...args) => {
    this.props.onAxesChange(...args)
    this.setState({ hideCursor: false })
  }

  selectNetworkAssetGraphItems = createSelector(
    [get('graphItems')],
    (graphItems = []) => {
      return graphItems.filter(i => i.isFromNetworkAsset())
    }
  )
}

function InteractionControllerLayerContainer(props) {
  return (
    <GraphContext.Consumer>
      {config => (
        <MouseEventContext.Consumer>
          {({ Channel: MouseEventChannel }) => (
            <DragEventContext.Consumer>
              {({ Channel }) => (
                <InteractionControllerLayer
                  graphConfig={config}
                  DragEventChannel={Channel}
                  MouseEventChannel={MouseEventChannel}
                  {...props}/>
              )}
            </DragEventContext.Consumer>
          )}
        </MouseEventContext.Consumer>
      )}
    </GraphContext.Consumer>
  )
}

function mapStateToProps(state) {
  return {
    dragMode: state.dragCover.dragMode,
  }
}

export default compose(
  connect(mapStateToProps),
  withTranslation([
    'src/components/graphs/drag_area_summary_layer/index',
    'common/text'])
)(InteractionControllerLayerContainer)
