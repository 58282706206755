import gql from 'graphql-tag'

export const PRESSURE_SUBSET_PREVIEW_QUERY = gql`
    query PressureSubsetAndEventsData(
      $end: DateString!,
      $start: DateString!,
      $resolution: Int!,
      $networkAssetChannels: [NetworkAssetChannel],
    ) {
      pagedPressureSubsetData(
        end: $end,
        start: $start,
        deviceIds: [],
        pageNumber: 1,
        resolution: $resolution,
        networkAssetChannels: $networkAssetChannels,
      ) {
        data {
          ... on InstallationPressureSubsetDataChunk {
            end
            start
            channel
            deviceId
            networkAssetId
            data {
              time
              min
              max
              mean
            }
          }

          ... on CommissionPressureSubsetDataChunk {
            end
            start
            deviceId
            data {
              time
              min
              max
              mean
            }
          }
        }

        pagination {
          pageNumber
          totalPages
        }
      }
    }
  `
