import React from 'react'
import {
  Form,
  Alert,
  Button,
  Row,
  Col,
} from 'reactstrap'
import gql from 'graphql-tag'
import { compose } from 'redux'
import { graphql } from '@apollo/client/react/hoc'
import PropTypes from 'prop-types'

import { FormFields, AsyncResult } from '@@src/utils'
import { withTranslation } from 'react-i18next'
import ErrorInfo from '@@src/components/error_info'
import SubmitButton from '@@src/components/buttons/submit_button'
import AppFormGroup from '@@src/components/forms/app_form_group'
import * as analytics from '@@src/analytics'
import UnitSettings from '@@src/components/forms/unit_settings'
import { TenantSettingsContext } from
'@@src/components/tenant_settings_provider'

const EN_LANGUAGE = 'en'

class LanguageAndRegionSettings extends React.PureComponent {
  static propTypes = {
    refetchData: PropTypes.func.isRequired,
    tenantSettings: PropTypes.object,
  }

  render() {
    const { t } = this.props
    const { result } = this.state

    return (
      <React.Fragment>
        <h2 className="h2 mb-0">
          {t('headings.language_and_region')}
        </h2>
        <hr className="mb-5" />

        {
          result.wasSuccessful() ? (
            <Alert color="success">
              {t('text.update_successful')}
            </Alert>
          ) : null
        }
        {result.wasFailure() ? <ErrorInfo error={result.error}/> : null}

        {this.renderSettingsSelectors()}
      </React.Fragment>
    )
  }

  constructor(props) {
    super(props)

    const requiredValidator = v => v ? '' : 'errors.required'

    this.fields = new FormFields(this, 'settingsFields', {
      language: requiredValidator,
      pressureUnit: requiredValidator,
      distanceUnit: requiredValidator,
      temperatureUnit: requiredValidator,
    })

    this.state = {
      result: AsyncResult.notFound(),
      settingsFields: this.fields.initialState({
        language: props.tenantSettings.language,
        temperatureUnit: props.tenantSettings.units.temperature,
        distanceUnit: props.tenantSettings.units.distance,
        pressureUnit: props.tenantSettings.units.pressure,
      }),
    }
  }

  componentDidUpdate(prevProps) {
    const { tenantSettings } = this.props

    if (prevProps.tenantSettings !== tenantSettings) {
      this.setFieldValues(tenantSettings)
    }
  }

  selectFieldValue = (fieldName) => {
    const fieldValue = this.fields.selectValue(this.state, fieldName)
    return fieldValue ? fieldValue : undefined
  }

  selectFieldErrorText = (fieldName) => {
    const error = this.fields.selectError(this.state, fieldName)
    return error ? this.props.t(error) : error
  }

  onSubmitForm = async event => {
    event.preventDefault()

    const { refetchData } = this.props
    const formLanguage = this.selectFieldValue('language') || EN_LANGUAGE
    const formTemperatureUnit = this.selectFieldValue('temperatureUnit')
    const formDistanceUnit = this.selectFieldValue('distanceUnit')
    const formPressureUnit = this.selectFieldValue('pressureUnit')

    this.setState({ result: AsyncResult.pending() })

    try {
      await this.props.editTenantSettings({
        variables: {
          language: formLanguage,
          units: {
            temperature: formTemperatureUnit,
            distance: formDistanceUnit,
            pressure: formPressureUnit,
          },
        },
      })
      this.setState({ result: AsyncResult.success() })
      await refetchData()
    } catch (e) {
      analytics.logError(e)
      this.setState({ result: AsyncResult.fail(e) })
    }
  }

  onResetForm = async event => {
    const { tenantSettings } = this.props

    event.preventDefault()

    this.setFieldValues(tenantSettings)
    this.fields.resetState()
  }

  setFieldValues = async settings => {
    const {
      language,
      units: {
        temperature: temperatureUnit,
        distance: distanceUnit,
        pressure: pressureUnit,
      },
    } = settings

    await this.fields.batchUpdateFieldValues({
      language,
      temperatureUnit,
      distanceUnit,
      pressureUnit,
    })
  }

  renderSettingsSelectors = () => {
    const {
      tenantSettings: {
        languagesAvailable,
        units: {
          temperatureUnitsAvailable,
          distanceUnitsAvailable,
          pressureUnitsAvailable,
        },
      },
    } = this.props
    const { t } = this.props
    const { result } = this.state

    return (
      <Form name="tenant-language-and-region" className="pb-3">
        <Row>
          <Col sm="7">
            <AppFormGroup
              name="language"
              type="select"
              label={t('common/forms:labels.default_language')}
              value={this.selectFieldValue('language') || EN_LANGUAGE}
              onChange={this.fields.onChangeHandlerFor('language')}
              errorText={this.selectFieldErrorText('language')}
            >
              {
                languagesAvailable.map((localecode) => (
                  <option
                    key={localecode}
                    value={localecode}
                  >
                    {t(`common/forms:language.${localecode}`)}
                  </option>
                ))
              }
            </AppFormGroup>

            <UnitSettings
              heading={t('headings.default_unit_presets')}
              pressureUnit={this.selectFieldValue('pressureUnit')}
              distanceUnit={this.selectFieldValue('distanceUnit')}
              temperatureUnit={this.selectFieldValue('temperatureUnit')}
              availablePressureUnits={pressureUnitsAvailable}
              availableDistanceUnits={distanceUnitsAvailable}
              availableTemperatureUnits={temperatureUnitsAvailable}
              className="mt-5"
              getChangeHandler={this.getChangeHandler}
              selectFieldErrorText={this.selectFieldErrorText} />

            <p className="text-muted">
              {t('text.unit_settings_may_be_overridden')}
            </p>

            <div className="mt-5">
              <SubmitButton
                name="language-and-region-settings-submit"
                color="primary"
                submitText={t('buttons.save')}
                result={result}
                onSubmitForm={this.onSubmitForm}
              />

              <Button
                name="language-and-region-settings-reset"
                color="secondary"
                className="ml-2"
                onClick={this.onResetForm}
              >
                { t('buttons.discard_changes') }
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    )
  }

  getChangeHandler = field => {
    return this.fields.onChangeHandlerFor(field)
  }

  static EDIT_TENANT_SETTINGS_MUTATION = gql`
    mutation editTenantSettings (
      $language: Language!
      $units: UnitSettingsInput!
    ) {
      editTenantSettings (
        language: $language
        units: $units
      ) {
        language
      }
    }
  `
}

const LanguageAndRegionSettingsContainer = props => (
  <TenantSettingsContext.Consumer>
    {
      ({
        settings: tenantSettings,
        refetchTenantSettings: refetchData,
      } = {}) => (
        <LanguageAndRegionSettings
          tenantSettings={tenantSettings}
          refetchData={refetchData}
          {...props} />
      )
    }
  </TenantSettingsContext.Consumer>
)

export default compose(
  withTranslation([
    'src/admin_path/tenant_settings/language_and_region_path',
  ]),
  graphql(LanguageAndRegionSettings.EDIT_TENANT_SETTINGS_MUTATION, {
    name: 'editTenantSettings',
  })
)(LanguageAndRegionSettingsContainer)
