import gql from 'graphql-tag'

export const GET_NETWORK_ASSETS_FOR_MAP_QUERY = gql`
  query GetNetworkAssetsListForMap (
    $filters: GetNetworkAssetsListFilters,
  ) {
    getNetworkAssetsList(
      filters: $filters
    ) {
      meta {
        bounds {
          southwest {
            lat
            lng
          }
          northeast {
            lat
            lng
          }
        }
      }
      networkAssets {
        assetId
        type
        activeErrorCount
        activeWarningCount
        activeInformationCount
        lat
        lng
        alt
      }
    }
  }
`
