import classnames from 'classnames'
import PropTypes from 'prop-types'
import React, { memo } from 'react'
import { withTranslation } from 'react-i18next'
import { FormGroup, InputGroup, InputGroupText } from 'reactstrap'

import DebouncedInput from '@@src/components/forms/debounced_input'

const INFLOWNET_WAIT = process.env.NODE_ENV === 'test' ? 0 : 200

function GroupSearch(props) {
  const { className, t, handleChange, controller, value, name } = props

  return (
    <FormGroup className={classnames('mb-0', className)}>
      <InputGroup>
        <DebouncedInput
          type="search"
          name={name}
          debounce={INFLOWNET_WAIT}
          onChange={handleChange}
          controller={controller}
          initialValue={value}
          placeholder={t('input.search_placeholder')}
        />

        <InputGroupText>
          <i className="fa fa-search" />
        </InputGroupText>
      </InputGroup>
    </FormGroup>
  )
}

GroupSearch.propTypes = {
  controller: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  value: PropTypes.string,
  name: PropTypes.string,
}

export default withTranslation([
  'src/components/modals/move_groups_modal/group_search',
])(memo(GroupSearch))
