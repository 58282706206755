export default class TimeSeriesModel {
  constructor({ time, ...rest }) {
    Object.assign(this, rest, {
      time: time instanceof Date ? time : new Date(Number(time)),
    })
    Object.freeze(this)
  }

  static from(...data) {
    return new TimeSeriesModel(...data)
  }
}
