import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useAddNewNetworkAsset } from '../../hooks/get_add_new_network_asset'

import {
  Button,
  Form,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap'

import { validAssetTypes } from '@@src/utils'
import { adjustRegister, validateCoordinates } from '../../utils/form'
import { convertToWGS84 } from '../../../../../../utils/conversions/coordinate_conversions'
import { SUPPORTED_COORDS_FORMAT } from '@@src/utils/conversions/coordinate_conversions'
import { LOCATION_SOURCE_SURVEY } from '../../../../../../components/forms/inputs/location_information_input_group'
import { mapTypeAndColourToMarkerIcon } from '../../../../../../utils/network_asset'
import { COORDINATE_REGEX } from '../../../../../../management_path/network_assets_path/network_asset_fields'
import NetworkAssetLocationFormFields from './modal_fields/network_asset_location_form_fields.component'
import LoadingIcon from '../../../../../../components/loading_icon'
import NetworkAssetGroupFormFields from './modal_fields/network_asset_group_form_fields.component'
import PreviewSinglePinMap from '../../../../../components/preview_single_pin_map/PreviewSinglePinMap'
import NetworkAssetBasicFormFields from './modal_fields/network_asset_basic_form_fields.component'
import NetworkAssetDescriptionFormFields from './modal_fields/network_asset_description_form_fields.component'

import PropTypes from 'prop-types'

import './network_assets_list_new_network_asset_modal.component.scss'

const assetTypes = validAssetTypes()

const NetworkAssetListNewNetworkAssetModal = ({
  isOpen = false,
  toggle = () => null,
}) => {
  const { t } = useTranslation([
    'v2/network_assets/forms',
    'src/components/forms/source_filter',
    // eslint-disable-next-line max-len
    'src/management_path/devices_path/assign_network_asset_to_device_modal/create_and_assign_network_asset_interface/index',
  ])
  const { addNewNetworkAsset, loading } = useAddNewNetworkAsset()

  const {
    register,
    reset,
    watch,
    control,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      assetType: assetTypes[0],
      assetId: '',
      friendlyName: '',
      source: LOCATION_SOURCE_SURVEY,
      elevation: '',
      coordinatesType: SUPPORTED_COORDS_FORMAT[0],
      coordinates: '',
      group: null,
    },
  })
  const adjustedRegister = adjustRegister(register)

  const [mapPinPlacement, setMapPinPlacement] = useState(null)
  const [mapPinType, setMapPinType] = useState(assetTypes[0])

  useEffect(() => {
    const subscription = watch(
      (value, { name: fieldName, type: fieldType }) => {
        if (fieldType === 'change') {
          switch (fieldName) {
            case 'coordinates':
            case 'coordinatesType': {
              const latLng = value.coordinates.match(COORDINATE_REGEX)

              if (!latLng) {
                setMapPinPlacement(null)
                break
              }

              const latitude = parseFloat(latLng[1])
              const longitude = parseFloat(latLng[2])

              setMapPinPlacement(
                validateCoordinates(value.coordinatesType, latitude, longitude)
              )
              break
            }
            case 'assetType': {
              setMapPinType(value[fieldName])
              break
            }
            default:
          }
        }
      }
    )

    return () => subscription.unsubscribe()
  }, [watch])

  const handleCancel = () => {
    reset()
    toggle()
  }

  const handleAdvanced = () => {
    // to be made...
  }

  const handleSaveChanges = ({ coordinates, coordinatesType, ...rest }) => {
    const [latitudeRaw, longitudeRaw] = coordinates.split(',').map((a) => +a)
    const [latitude, longitude] = convertToWGS84(coordinatesType, [
      latitudeRaw,
      longitudeRaw,
    ])
    addNewNetworkAsset({ latitude, longitude, ...rest }, (status) => {
      if (status === 'success') {
        reset()
        toggle()
      } else {
        // eslint-disable-next-line no-console
        console.warn('Error adding network asset:', status)
      }
    })
  }

  return (
    <Modal size={'lg'} isOpen={isOpen} toggle={handleCancel}>
      <Form onSubmit={handleSubmit(handleSaveChanges)} noValidate>
        <ModalHeader toggle={handleCancel}>
          {t('text.create_network_asset')}
        </ModalHeader>
        <ModalBody>
          <NetworkAssetBasicFormFields
            register={adjustedRegister}
            errors={errors}
          />

          <div className="form-seperator" />

          <div className="form-group-container">
            <Col sm={4}>
              <h5>{t('text.location')}</h5>
            </Col>
            <Col sm={8} className="p-0 m-0">
              <NetworkAssetLocationFormFields
                control={control}
                register={adjustedRegister}
                errors={errors}
                getValues={getValues}
              />
              <Col sm={12} className="small-map p-0">
                <PreviewSinglePinMap
                  mapPinPlacement={mapPinPlacement}
                  mapPinIcon={mapTypeAndColourToMarkerIcon(
                    'primary',
                    mapPinType
                  )}
                />
              </Col>
            </Col>
          </div>

          <div className="form-seperator" />

          <div className="form-group-container">
            <Col sm={4}>
              <h5>{t('text.group')}</h5>
            </Col>
            <Col sm={8} className="p-0 m-0">
              <NetworkAssetGroupFormFields control={control} />
            </Col>
          </div>

          <div className="form-seperator" />

          <div className="form-group-container">
            <Col sm={4}>
              <h5>{t('text.description')}</h5>
            </Col>
            <Col sm={8} className={'p-0'}>
              <NetworkAssetDescriptionFormFields register={adjustedRegister} />
            </Col>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="mr-auto">
            <Button
              name="clear-filters-button"
              type="button"
              color="light"
              onClick={handleCancel}
            >
              {t('v2/common/buttons:cancel')}
            </Button>
          </div>
          <Button
            disabled
            name="cancel-button"
            color="secondary"
            onClick={handleAdvanced}
          >
            {t('v2/common/buttons:advanced')}
          </Button>
          <Button
            disabled={loading}
            type="submit"
            name="accept-button"
            color="primary"
          >
            {loading ? <LoadingIcon /> : t('v2/common/buttons:create_asset')}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  )
}

NetworkAssetListNewNetworkAssetModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  activeFilterList: PropTypes.arrayOf(PropTypes.string),
  handleAccept: PropTypes.func,
  clearFilters: PropTypes.func,
}

export default NetworkAssetListNewNetworkAssetModal
