import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'reactstrap'
import { withTranslation } from 'react-i18next'

import ErrorInfo from '@@src/components/error_info'
import AsyncResultSwitch from '@@src/components/async_result_switch'
import { AsyncResult } from '@@src/utils'

import styles from './async_list.css'
import { ListPlaceholder } from '../../_v2/components/placeholders/list_placeholder'

class AsyncList extends React.Component {
  static propTypes = {
    result: PropTypes.instanceOf(AsyncResult).isRequired,
    className: PropTypes.string,
    renderItem: PropTypes.func.isRequired,
    renderNotFoundResult: PropTypes.func,
    renderFailResult: PropTypes.func,
    noResultText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    onRequestRetry: PropTypes.func,
  }

  render() {
    const { className, name, result } = this.props

    return (
      <ul name={name} className={className}>
        <AsyncResultSwitch
          result={result}
          renderFailResult={this.renderFailResult}
          renderPendingResult={this.renderPendingResult}
          renderSuccessResult={this.renderSuccessResult}
          renderNotFoundResult={this.renderNotFoundResult}
        />
      </ul>
    )
  }

  renderSuccessResult = ({ data: items }) => {
    return items.length === 0
      ? this.renderNotFoundResult()
      : items.map(this.props.renderItem)
  }

  renderNotFoundResult = () => {
    const { renderNotFoundResult } = this.props

    if (typeof renderNotFoundResult === 'function') {
      return renderNotFoundResult()
    }

    return (
      <li name="no-results-text" className={styles['no-results-text']}>
        {this.props.noResultText || this.props.t('text.no_results')}
      </li>
    )
  }

  renderPendingResult = () => {
    return (
      <div className="px-2 py-4">
        <ListPlaceholder />
      </div>
    )
  }

  renderFailResult = ({ error }) => {
    const { t, onRequestRetry, renderFailResult } = this.props

    if (typeof renderFailResult === 'function') {
      return renderFailResult()
    }

    return (
      <React.Fragment>
        <ErrorInfo error={error} />

        <div className={styles['no-results-container']}>
          <div className={styles['no-results-text']}>
            <i className="fas fa-exclamation-triangle"></i>
            &nbsp;
            <span>{t('text.unable_to_load_results')}</span>
          </div>

          {onRequestRetry ? (
            <Button
              name="retry-button"
              color="light"
              onClick={onRequestRetry}
              className={styles['retry-button']}
            >
              <i className="far fa-sync"></i>
              &nbsp;
              {t('buttons.retry')}
            </Button>
          ) : null}
        </div>
      </React.Fragment>
    )
  }
}

export default withTranslation(['src/components/lists/async_list'])(AsyncList)
