import React from 'react'
import { get } from 'lodash/fp/object'
import { Translation, I18nextProvider } from 'react-i18next'

import routes from '@@src/routes'
import { Button, Navbar, NavbarBrand } from 'reactstrap'

import styles from './failed_to_start_app_error_page.css'
import englishTranslations
from '../../locales/en/src/error_path/failed_to_start_app_error_page.json'

class TranslateIfAvailable extends React.PureComponent {
  render() {
    const { i18n, children } = this.props

    return (
      i18n ? (
        <I18nextProvider i18n={i18n}>
          <Translation ns={[
            'src/error_path/failed_to_start_app_error_page',
            'common/errors',
          ]}>
            {t => children(t)}
          </Translation>
        </I18nextProvider>
      ) : (
        children(defaultTranslate)
      )
    )
  }
}

export default class FailedToStartAppPage extends React.PureComponent {

  pageTitle(t, error) {
    const defaultReturn = (
      <h2 className={styles['error-title']}>{t('headings.generic')}</h2>
    )

    // If error message is malformed
    if(!error) {
      return defaultReturn
    }

    const { i18nKey } = error

    switch(i18nKey) {
      case 'tenant_not_found':
        return (
          <h2 className={styles['error-title']}>{t(`headings.${i18nKey}`)}</h2>
        )
      default:
        return defaultReturn
    }
  }

  pageBody(t, error) {
    const defaultReturn = (
      <React.Fragment>
        <div className={styles['error-message']}>
          {t('text.generic')}
        </div>

        <div className={styles['error-retry-buttons']}>
          <Button
            name="refresh-button"
            onClick={this.refreshApp}
            type="submit"
            color="primary"
            className={styles['refresh-button']}>
            {t('buttons.refresh')}
          </Button>
        </div>
      </React.Fragment>
    )

    // If error message is malformed
    if(!error) {
      return defaultReturn
    }

    const { i18nKey } = error

    switch(i18nKey) {
      case 'tenant_not_found':
        return (
          <>
            <div className={styles['error-message']}>
              <p>
                {t(`text.${i18nKey}`)}
              </p>
              <div name="env-url" className="form-control">
                {getEnvironmentURL()}
              </div>
            </div>
          </>
        )
      default:
        return defaultReturn
    }

  }

  render() {
    const { i18n, error } = this.props

    return (
      <div className={styles.container}>
        <Navbar color="dark" dark expand="md" className={styles.navbar}>
          <NavbarBrand
            href={routes.homePath()}
            className={styles['brand-image']}/>
        </Navbar>

        <div className={styles.contents}>
          <TranslateIfAvailable i18n={i18n}>
            {t => (
              <div className={styles['message-card']}>
                {this.pageTitle(t, error)}
                {this.pageBody(t, error)}
              </div>
            )}
          </TranslateIfAvailable>
        </div>
      </div>
    )
  }
  refreshApp() {
    window.location.reload()
  }

}

function defaultTranslate(key) {
  return get(key)(englishTranslations)
}

// Function gets the appropriate environment domain by ignoring the tenant prefix in url.
// For example - https://unknown.inflowsysweb.com will return https://tenantname.inflowsysweb.com
//               https://unknown.dev.inflownet-webapp.com will return https://tenantname.dev.inflownet-webapp.com
//               http://unknown.localhost:8080 will return http://localhost:8080
export function getEnvironmentURL() {
  const wl = window.location
  // If we are on localhost then we should be using http://localhost:port
  if(wl.hostname.substr(wl.hostname.length - 9).toLowerCase() === 'localhost') {
    return `http://localhost${wl.port ? `:${wl.port}` : ''}`
  }

  // Return everything after the first period character
  return <>https://<b>tenantname</b>{wl.host.substring(wl.host.indexOf('.'))}</>
}
