import React from 'react'
import PropTypes from 'prop-types'
import { toPairs } from 'lodash/fp/object'
import { Container, Row, Col } from 'reactstrap'

import CategoriesFilters from './categories_filters'
import AdvancedFilterModal from '@@src/components/modals/advanced_filter_modal'
import GroupTypeFilters, { defaultGroupTypeFilters }
from '@@src/components/modals/group_type_filters'
import DeviceIssueFilters from '@@src/components/modals/device_issue_filters'

const noop = () => {}

export default class AdvancedFilterOptionsModal extends React.PureComponent {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    onAcceptChanges: PropTypes.func.isRequired,
    categoriesFilter: PropTypes.array.isRequired,
    groupTypesFilter: PropTypes.array.isRequired,
    deviceIssuesFilter: PropTypes.array.isRequired,
    displayDeviceIssuesFilters: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    toggle: noop,
    onAcceptChanges: noop,
    displayDeviceIssuesFilters: true,
  }

  state = {
    categoriesFilter: [],
    deviceIssuesFilter: [],
    groupTypesFilterMap: {},
  }

  render() {
    const { isOpen, toggle, displayDeviceIssuesFilters } = this.props
    const {
      categoriesFilter, groupTypesFilterMap, deviceIssuesFilter,
    } = this.state
    const groupAndCategoriesColWidth = displayDeviceIssuesFilters ? 12 : 6

    return (
      <AdvancedFilterModal
        isOpen={isOpen}
        handleAccept={this.handleChangeGroupAdvancedFilter}
        handleToggle={toggle}
        handleClearFilters={this.handleClearAdvancedFilters}>
        <Container fluid={true}>
          <Row>
            <Col>
              <Row>
                <Col sm={groupAndCategoriesColWidth}>
                  <GroupTypeFilters
                    handleChange={this.handleGroupTypeFiltersChange}
                    filters={groupTypesFilterMap} />
                </Col>
                <Col sm={groupAndCategoriesColWidth}>
                  <CategoriesFilters
                    selection={categoriesFilter}
                    onChangeSelection={this.handleCategoriesFiltersChange} />
                </Col>
              </Row>
            </Col>
            {
              displayDeviceIssuesFilters ?
                <Col sm="7">
                  <DeviceIssueFilters
                    onChangeSelection={this.handleDeviceIssuesFilterChange}
                    deviceIssuesFilter={deviceIssuesFilter}/>

                </Col>
                : null
            }
          </Row>
        </Container>
      </AdvancedFilterModal>
    )
  }

  componentDidUpdate(prevProps) {
    if (this.props.isOpen !== prevProps.isOpen && this.props.isOpen) {
      this.setState(this.initialState(this.props))
    }
  }

  initialState({ deviceIssuesFilter, groupTypesFilter, categoriesFilter }) {
    return {
      categoriesFilter,
      deviceIssuesFilter,
      groupTypesFilterMap: defaultGroupTypeFilters(groupTypesFilter),
    }
  }

  handleClearAdvancedFilters = () => {
    this.setState({
      categoriesFilter: [],
      deviceIssuesFilter: [],
      groupTypesFilterMap: {},
    })
  }

  handleCategoriesFiltersChange = newCategoriesFilter => {
    this.setState({ categoriesFilter: newCategoriesFilter })
  }

  handleGroupTypeFiltersChange = ev => {
    this.setState({
      groupTypesFilterMap: Object.assign({}, this.state.groupTypesFilterMap, {
        [ev.target.name]: ev.target.checked,
      }),
    })
  }

  handleDeviceIssuesFilterChange = newDeviceIssuesFilter => {
    this.setState({ deviceIssuesFilter: newDeviceIssuesFilter })
  }

  handleChangeGroupAdvancedFilter = () => {
    const {
      groupTypesFilterMap, categoriesFilter, deviceIssuesFilter,
    } = this.state

    const groupTypesFilter =
      toPairs(groupTypesFilterMap).filter(a => a[1]).map(a => a[0])

    this.props.onAcceptChanges({
      categoriesFilter,
      groupTypesFilter,
      deviceIssuesFilter,
    })
    this.props.toggle()
  }
}
