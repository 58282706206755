import React from 'react'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'

import PropTypes from 'prop-types'
import AdvancedFilterModalSubgroup from
'../../../../../components/modals/advanced_filter_subgroup/advanced_filter_subgroup.component'

const NetworkAssetListAdvancedFilterModalSubgroup = ({
  t,
  subgroupHeading,
  filtersInSubrgroup = [],
  selectedFilters = [],
  onChange,
}) => {
  return (
    <AdvancedFilterModalSubgroup
      t={(filter) => t(`options.${filter}`)}
      subgroupHeading={subgroupHeading}
      filtersInSubrgroup={filtersInSubrgroup}
      selectedFilters={selectedFilters}
      onChange={onChange}
    />
  )
}

NetworkAssetListAdvancedFilterModalSubgroup.propTypes = {
  t: PropTypes.func.isRequired,
  subgroupHeading: PropTypes.string,
  filtersInSubrgroup: PropTypes.arrayOf(PropTypes.string),
  selectedFilters: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
}

export default compose(
  withTranslation([
    'v2/network_assets/filters',
  ]),
)(NetworkAssetListAdvancedFilterModalSubgroup)
