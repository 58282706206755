import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { createSelector } from 'reselect'
import { withTranslation } from 'react-i18next'
import {
  Col, Row, Button, Modal, ModalHeader, ModalBody, ModalFooter,
} from 'reactstrap'
import MarkerClusterGroup from 'react-leaflet-markercluster'

import * as analytics from '@@src/analytics'
import ErrorInfo from '@@src/components/error_info'
import StandardMap from '@@src/components/maps/standard_map'
import NetworkAsset from '@@src/api/presenters/network_asset'
import AppFormGroup from '@@src/components/forms/app_form_group'
import NetworkAssetMarker
from '@@src/components/maps/markers/network_asset_marker'
import SubmitButton from '@@src/components/buttons/submit_button'
import NetworkAssetFields, { COORDINATE_REGEX } from './network_asset_fields'
import AppSettingsConsumer from '@@src/components/app_settings_consumer'
import LocationInformationInputGroup
from '@@src/components/forms/inputs/location_information_input_group'
import { AppError, AsyncResult, validAssetTypes } from '@@src/utils'
import SourceFilter, {
  V_NETWORK_ASSET_TYPE,
} from '@@src/components/forms/source_filter'
import { MixedGroupDetailsType } from '@@src/api/presenters/group'
import {
  convertToWGS84, COORDS_FORMAT_DEFAULT,
} from '@@src/utils/conversions/coordinate_conversions'
import {
  convertUnitToDB,
  DISTANCE_TYPE,
} from '@@src/utils/app_unit_conversion'

import styles from './network_asset_form_modal.css'

class NetworkAssetFormModal extends React.PureComponent {
  static defaultProps = {
    onSubmit: () => { },
  }

  static propTypes = {
    title: PropTypes.string.isRequired,
    distanceUnits: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
    networkAsset: PropTypes.instanceOf(NetworkAsset),
    saveButtonText: PropTypes.string,
    submissionError: PropTypes.instanceOf(AppError),
  }

  render() {
    const {
      t, isOpen, title, saveButtonText, networkAsset, distanceUnits,
    } = this.props
    const { result, sourceFilter } = this.state
    const validNetworkAsset =
      this.selectNetworkAssetIfValid(this.props, this.state)
    const hasInstallations = networkAsset &&
      Array.isArray(networkAsset.installations) &&
      networkAsset.installations.length > 0

    return (
      <Modal
        isOpen={isOpen}
        toggle={this.onToggle}
        className={styles.container}>
        <ModalHeader>{title}</ModalHeader>

        <ModalBody>
          {result.wasFailure() ? <ErrorInfo error={result.error}/> : null}

          <Row>
            <Col sm="4">

              <AppFormGroup
                disabled={hasInstallations}
                hint={
                  hasInstallations ?
                    t('text.asset_type_has_installations') : undefined
                }
                id="network-asset-type"
                name="network-asset-type"
                type="select"
                label={t('labels.asset_type_label')}
                value={this.selectFieldValue('assetType')}
                onChange={this.fieldOnChange('assetType')}
                required={true}
                errorText={this.selectFieldErrorText('assetType')}>
                <option>
                  {t('text.asset_type_option_blank')}
                </option>

                {
                  validAssetTypes().map(assetType => (
                    <option key={assetType} value={assetType}>
                      {t(`common/text:text.${assetType}`)}
                    </option>
                  ))
                }
              </AppFormGroup>
            </Col>

            <Col sm="8">
              <AppFormGroup
                maxLength={NetworkAsset.MAX_ASSET_ID_LENGTH}
                id="network-asset-id"
                name="network-asset-id"
                hint={t('text.asset_id_hint')}
                type="text"
                label={t('labels.asset_id_label')}
                value={this.selectFieldValue('assetId')}
                onChange={this.fieldOnChange('assetId')}
                required={true}
                errorText={this.selectFieldErrorText('assetId')}/>
            </Col>
          </Row>

          <Row>
            <Col sm={{ size: 8, offset: 4 }}>
              <AppFormGroup
                id="network-asset-name"
                name="network-asset-name"
                type="text"
                hint={t('text.asset_name_hint')}
                label={t('labels.asset_name')}
                value={this.selectFieldValue('assetName') || ''}
                onChange={this.fieldOnChange('assetName')}
                errorText={this.selectFieldErrorText('assetName')}/>
            </Col>
          </Row>

          <div className={styles.separator}></div>

          <Row>
            <Col sm="4">
              <h5>
                {t('headings.location')}
              </h5>
            </Col>

            <Col sm="8">
              <LocationInformationInputGroup
                locationSourceValue={this.selectFieldValue('locationSource')}
                locationSourceRequired={true}
                locationSourceErrorText={
                  this.selectFieldErrorText('locationSource')
                }
                onChangeLocationSource={this.fieldOnChange('locationSource')}

                elevationValue={this.selectFieldValue('elevation')}
                onChangeElevation={this.fieldOnChange('elevation')}
                elevationErrorText={this.selectFieldErrorText('elevation')}
                elevationPlaceholder={t(
                  `common/text:units.${distanceUnits}`,
                  // Plural for placeholder text
                  { count: 2 }
                )}

                coordinatesValue={this.selectFieldValue('coordinates')}
                onChangeCoordinates={this.fieldOnChange('coordinates')}
                coordinatesErrorText={this.selectFieldErrorText('coordinates')}
                coordinatesFormatValue={
                  this.selectFieldValue('coordinatesFormat')
                }
                onChangeCoordinatesFormat={this.onChangeCoordinatesFormat}/>

              <StandardMap
                center={this.selectMapCenter(this.props, this.state)}
                className={styles['location-preview-map']}>
                <MarkerClusterGroup
                  showCoverageOnHover={false}
                  removeOutsideVisibleBounds={true}>
                  {
                    validNetworkAsset ? (
                      <NetworkAssetMarker networkAsset={validNetworkAsset}/>
                    ) : null
                  }
                </MarkerClusterGroup>
              </StandardMap>
            </Col>
          </Row>

          <div className={styles.separator}></div>

          <Row>
            <Col sm="4">
              <h5>
                {t('headings.group')}
              </h5>
            </Col>

            <Col sm="8">

              <SourceFilter
                displayLabel={false}
                displayAssets={false}
                sourceFilter={sourceFilter}
                handleClickSelectSource={this.handleClickSelectGroup}
                location={location}
                dropdownDirection={'down'}
                groupTypes={[V_NETWORK_ASSET_TYPE]} />

            </Col>
          </Row>

          <div className={styles.separator}></div>

          <Row>
            <Col sm="4">
              <h5>
                {t('headings.description')}
              </h5>
            </Col>

            <Col sm="8">
              <AppFormGroup
                id="network-asset-custom-labels"
                name="network-asset-custom-labels"
                type="text"
                label={t('labels.custom_labels')}
                value={this.selectFieldValue('customLabels')}
                onChange={this.fieldOnChange('customLabels')}
                errorText={this.selectFieldErrorText('customLabels')}
                placeholder={t('text.custom_labels_placeholder')}/>

              <AppFormGroup
                id="network-asset-comments"
                name="network-asset-comments"
                rows={4}
                type="textarea"
                label={t('labels.comment')}
                value={this.selectFieldValue('comment')}
                onChange={this.fieldOnChange('comment')}
                errorText={this.selectFieldErrorText('comment')}/>
            </Col>
          </Row>
        </ModalBody>

        <ModalFooter>
          <Button
            name="cancel-button"
            type="button"
            color="secondary"
            onClick={this.onToggle}>
            {t('buttons.cancel')}
          </Button>

          {' '}

          <SubmitButton
            name="submit-button"
            buttonStatus=""
            color="primary"
            onSubmitForm={this.onSubmitForm}
            result={this.state.result}
            disabled={this.networkAssetFields.hasAnyValidationErrors()}
            submitText={saveButtonText || t('buttons.save_changes')}>
          </SubmitButton>
        </ModalFooter>
      </Modal>
    )
  }

  onChangeCoordinatesFormat = async format => {
    await this.networkAssetFields.updateFieldValue('coordinatesFormat', format)
  }

  constructor(props) {
    super(props)

    this.networkAssetFields = new NetworkAssetFields(this, 'networkAssetFields')

    this.state = {
      result: AsyncResult.notFound(),
      networkAssetFields: this.networkAssetFields.initialState(),
      sourceFilter: '',
    }
  }

  componentDidMount() {
    this.resetFieldValues()
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.isOpen !== prevProps.isOpen ||
      this.props.networkAsset !== prevProps.networkAsset
    ) {
      this.resetFieldValues()
    }
  }

  resetFieldValues() {
    const networkAsset = this.props.networkAsset || {}

    this.setState({
      networkAssetFields: this.networkAssetFields.initialState({
        assetId: networkAsset.assetId,
        comment: networkAsset.comment || '',
        assetName: networkAsset.assetName,
        assetType: networkAsset.assetType,
        elevation: networkAsset.elevation,
        coordinates: networkAsset.coordinates,
        customLabels: networkAsset.customLabels || '',
        locationSource: networkAsset.locationSource || 'survey',
        coordinatesFormat: COORDS_FORMAT_DEFAULT,
      }),
    })
  }

  onToggle = (...args) => {
    const { toggle } = this.props

    this.resetFieldValues()

    if (toggle) {
      toggle(...args)
    }
  }

  onSubmitForm = async event => {
    const { distanceUnits } = this.props
    event.preventDefault()

    this.setState({ result: AsyncResult.pending() })

    const { sourceFilter } = this.state
    const res =
      await this.networkAssetFields.performPreSubmitChecks().catch(() => {
        return false
      })

    if (!res) {
      this.setState({ result: AsyncResult.notFound() })
    } else {
      try {
        const coordinates = this.selectFieldValue('coordinates')
        const coordinatesFormat = this.selectFieldValue('coordinatesFormat')
        const [latitude, longitude] = convertToWGS84(
          coordinatesFormat, coordinates.split(',').map(parseFloat)
        )
        const altitude = convertUnitToDB(
          parseFloat(this.selectFieldValue('elevation') || '0'),
          distanceUnits,
          DISTANCE_TYPE
        )
        const groupIds = sourceFilter ? [
          MixedGroupDetailsType.parseIdFromDataSourceId(sourceFilter),
        ] : []

        await this.props.onSubmit({
          groupIds,
          assetId: this.selectFieldValue('assetId') || null,
          comment: this.selectFieldValue('comment') || null,
          location: { altitude, latitude, longitude },
          assetName: this.selectFieldValue('assetName') || null,
          assetType: this.selectFieldValue('assetType') || null,
          customLabels: this.selectFieldValue('customLabels') || null,
          locationSource: this.selectFieldValue('locationSource') || null,
        })

        this.setState({ result: AsyncResult.success() })
        this.onToggle()
      } catch (e) {
        analytics.logError(e)

        this.setState({ result: AsyncResult.fail(AppError.from(e)) })
      }
    }
  }

  selectFieldValue = fieldName => {
    return this.networkAssetFields.selectValue(this.state, fieldName)
  }

  selectFieldErrorText = fieldName => {
    const error = this.networkAssetFields.selectError(this.state, fieldName)
    return error ? this.props.t(error) : error
  }

  fieldOnChange = fieldName => {
    return this.networkAssetFields.onChangeHandlerFor(fieldName)
  }

  createSelectFieldValueFor = fieldName => {
    return (props, state) => {
      return this.networkAssetFields.selectValue(state, fieldName)
    }
  }

  handleClickSelectGroup = source => {
    this.setState({ sourceFilter: source })
  }

  selectNetworkAssetIfValid = createSelector(
    [
      this.createSelectFieldValueFor('assetType'),
      this.createSelectFieldValueFor('assetId'),
      this.createSelectFieldValueFor('coordinates'),
      this.createSelectFieldValueFor('coordinatesFormat'),
    ],
    (assetType, assetId, coordinates, coordinatesFormat) => {
      const match = coordinates.match(COORDINATE_REGEX)
      const coords = match ?
        convertToWGS84(coordinatesFormat, [
          parseFloat(match[1]),
          parseFloat(match[2]),
        ]) : null

      return coords ? NetworkAsset.from({
        assetId,
        location: {
          latitude: coords[0],
          longitude: coords[1],
        },
        assetType,
      }) : null
    }
  )

  selectMapCenter = createSelector(
    [this.selectNetworkAssetIfValid],
    networkAsset => networkAsset ? [
      networkAsset.location.latitude,
      networkAsset.location.longitude,
    ] : undefined
  )
}

function NetworkAssetFormModalContainer(props) {
  return (
    <AppSettingsConsumer>
      {
        (units) => (
          <NetworkAssetFormModal
            distanceUnits={units.distance}
            {...props}
          />
        )
      }
    </AppSettingsConsumer>
  )
}

export default compose(
  withTranslation([
    'src/management_path/network_assets_path/network_asset_form_modal',
    'common/text',
  ])
)(NetworkAssetFormModalContainer)
