import { useMemo } from 'react'
import gql from 'graphql-tag'
import Device from '../../../api/presenters/device'
import NetworkAsset from '../../../api/presenters/network_asset'
import { useQuery } from '@apollo/client'

export function useGetGroupMembers(group) {
  const GROUP_MEMBERS = gql`
  query GetGroupMember (	$parentGroupId: Int ) {
    getGroupMembers( parentGroupId: $parentGroupId ) {
     	id
			type
    }
  }
  `

  const groupMembers = useQuery(GROUP_MEMBERS, {
    variables: {
      parentGroupId: group.groupId,
    },
    skip: group.membersType === 'VGroup',
    fetchPolicy: 'network-only',
  })

  const selectGroupMembers = useMemo(() => {
    if(groupMembers.loading || !groupMembers.data) {
      return []
    }
    return groupMembers.data.getGroupMembers.map(member => {
      if(member.type === 'VDevice') {
        return new Device(member)
      }
      return new NetworkAsset(member)
    })
  }, [groupMembers])

  return {
    groupMembers: selectGroupMembers,
  }
}
