import React, { Suspense } from 'react'
import PropTypes from 'prop-types'

import LoadingIcon from '@@src/components/loading_icon'

import styles from './app_suspense_container.css'

export default class AppSuspenseContainer extends React.PureComponent {
  static defaultProps = {
    elemType: 'div',
  }

  static propTypes = {
    elemType: PropTypes.elementType,
    className: PropTypes.string,
    renderSuspenseFallback: PropTypes.func,
  }

  render() {
    const { children, elemType: Elem } = this.props

    if (Elem) {
      const { className } = this.props

      return (
        <Elem className={className}>
          <Suspense fallback={this.renderLoadingComponent()}>
            {children}
          </Suspense>
        </Elem>
      )
    } else {
      return (
        <Suspense fallback={this.renderLoadingComponent()}>
          {children}
        </Suspense>
      )
    }
  }

  renderLoadingComponent() {
    if (this.props.renderSuspenseFallback) {
      return this.props.renderSuspenseFallback()
    }

    return (
      <div className={styles['loading-container']}>
        <LoadingIcon className={styles.loading}/>
      </div>
    )
  }
}

export function renderAbsolutePositionedLoading() {
  return (
    <div className={styles['loading-container-absolute']}>
      <LoadingIcon className={styles.loading}/>
    </div>
  )
}
