import gql from 'graphql-tag'

export const EXPORT_NETWORK_ASSETS_MUTATION = gql`
mutation OrderNetworkAssetsListReport(
  $filters: GetNetworkAssetsListFilters, 
  $orderBy: GetNetworkAssetsListOrderBy, 
  $orderDirection: OrderDirection,
  ) {
  requestNetworkAssetsListReport(
    filters: $filters
    orderBy: $orderBy
    orderDirection: $orderDirection
  ) {
		jobId
  }
}
`
