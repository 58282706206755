import React from 'react'
import gql from 'graphql-tag'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { graphql } from '@apollo/client/react/hoc'
import { get } from 'lodash/fp/object'
import { createSelector } from 'reselect'

import transformProps from '@@src/components/transform_props'
import AsyncList from '@@src/components/lists/async_list'
import AnalysisListSourcesItem from '@@src/components/lists/analysis_list_sources_item/analysis_list_sources_item'
import AsyncResult from '@@src/utils/async_result'

import { createSelectGraphQLResult } from '@@src/utils'
import { SOURCE_LIMIT } from './pressure_analysis_path/constants'
import { parseGraphQLResult } from '@@src/api/presenters'
import InvalidSelectionAlert, { isIncorrectIdErrorResult } from '@@src/components/alerts/invalid_selection_alert'

import styles from './data_source_sidebar.css'
import { MixedGroupDetailsType } from '../api/presenters/group'

const formatGroupSources = (dataSourcesResult, groupSources) => {
  return groupSources.data.data.reduce((acc, group) => {
    if (group.members.type === 'VGroup') {
      const newData = groupSources.data.data.filter(group2 => {
        return group.mostDistantDeviceOrAssetGroups.find((mddoag) => mddoag.id === group2.id)
      }).map(newMember => {
        const validNewMembers =
          newMember.members.data.filter(member =>
            dataSourcesResult.data.some(source => member.uuid === source.uuid)
          )
        return MixedGroupDetailsType.from({
          ...newMember,
          members: {
            ...newMember.members,
            data: [...validNewMembers],
          },
        })
      })

      const newGroup = MixedGroupDetailsType.from({
        ...group,
        members:
          {
            ...group.members,
            data: [...newData],
          },
      })
      acc.groupGroups.push(newGroup)
      const newNonGroupGroups = acc.nonGroupGroups.filter(nonGroupGroup => {
        return !newGroup.members.data.some(member => member.id === nonGroupGroup.id)
      })
      acc.nonGroupGroups = [...newNonGroupGroups]
      return acc
    }
    const shouldAddNonGroupGroup = !acc.groupGroups.some(groupGroup => {
      return groupGroup.members.data.some(member => {
        return member.id === group.id
      })
    })
    if (shouldAddNonGroupGroup) {
      acc.nonGroupGroups.push(group)
    }
    return acc
  }, { nonGroupGroups: [], groupGroups: [] })
}

export const mergeSourceData = (dataSourcesResult, groupSourcesResult) => {
  const groupOfGroupsWithMembers = formatGroupSources(dataSourcesResult, groupSourcesResult)

  // filter assets and dont show those that are already shown in groups
  const uniqueAssetAndDataResults = dataSourcesResult.data.filter(source => {
    const existsInGroupOfGroups = !groupOfGroupsWithMembers.groupGroups.some((group) => {
      return group.members.data.some(memberSource => {
        if((memberSource.isNetworkAssetGroup() && source.__typename === 'NetworkAsset') ||
          (memberSource.isDeviceGroup() && source.__typename === 'Device')) {
          return memberSource.members.data.some(membersMemberSource => membersMemberSource.id === source.id)
        }
        return false
      })
    })
    const existsInGroups = !groupOfGroupsWithMembers.nonGroupGroups.some((group) => {
      if((group.isNetworkAssetGroup() && source.__typename === 'NetworkAsset') ||
          (group.isDeviceGroup() && source.__typename === 'Device')) {
        return group.members.data.some(membersMemberSource => membersMemberSource.id === source.id)
      }
      return false
    })

    return existsInGroupOfGroups && existsInGroups
  })

  return AsyncResult.success(
    uniqueAssetAndDataResults
      .concat([...groupOfGroupsWithMembers.nonGroupGroups, ...groupOfGroupsWithMembers.groupGroups])
  )
}

class AnalysisSidebar extends React.PureComponent {

  static propTypes = {
    dataSourcesResult: PropTypes.instanceOf(AsyncResult).isRequired,
    groupSourcesResult: PropTypes.instanceOf(AsyncResult).isRequired,
    deviceIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    groupIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    networkAssetIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    noResultText: PropTypes.string.isRequired,
    refetchData: PropTypes.func,
    removeFromSelected: PropTypes.func.isRequired,
    selectGroupsResult: PropTypes.func.isRequired,
  }

  static defaultProps = {
    dataSourcesResult: AsyncResult.success([]),
    groupSourcesResult: AsyncResult.success({ data: [] }),
  }

  selectAllSourcesData = createSelector(
    [get('dataSourcesResult'), get('groupSourcesResult')],
    (dataSourcesResult, groupSourcesResult) => {
      if (dataSourcesResult.wasSuccessful()
        && groupSourcesResult.wasSuccessful()) {
        return mergeSourceData(dataSourcesResult, groupSourcesResult)
      }

      return dataSourcesResult.and(groupSourcesResult)
    }
  )

  renderSelectedItem = selectedItem => {
    const allSourceData = this.selectAllSourcesData(this.props)
    const isLimitReached = allSourceData.data.length >= SOURCE_LIMIT
    const source = allSourceData.data.find(dataSource => {
      return dataSource.id === selectedItem.id
        && dataSource.__typename === selectedItem.__typename
    })

    if (source) {
      return (
        <AnalysisListSourcesItem
          isLimitReached={isLimitReached}
          allSourceData={allSourceData}
          key={source.uuid}
          dataSource={source}
          selected={true}
          sidebar
          removeFromSelected={this.props.removeFromSelected}
          showAncestors={true} />
      )
    } else {
      return null
    }
  }

  render() {
    const selectedDataSourcesResult = this.selectAllSourcesData(this.props)

    return (
      <AsyncList
        renderFailResult={
          selectedDataSourcesResult.wasFailure() &&
            isIncorrectIdErrorResult(selectedDataSourcesResult) ?
            this.handleFailResult : undefined
        }
        name="selected-data-sources-list"
        result={selectedDataSourcesResult}
        className={styles['selected-data-sources-list']}
        renderItem={this.renderSelectedItem}
        noResultText={(
          <span>
            {this.props.noResultText}
          </span>
        )}
      />
    )
  }

  handleFailResult = () => {
    return (
      <li className="mx-3">
        <InvalidSelectionAlert />
      </li>
    )
  }

  static SidebarItemsQuery = gql`
    query DataSources(
      $deviceIds: [Int],
      $networkAssetIds: [Int],
      $groupIds: [Int]
    ) {
      dataSources(deviceIds: $deviceIds, networkAssetIds: $networkAssetIds) {
        ... on Device {
          id
          serialNumber
          commissionStatus
          __typename
        }
        ... on NetworkAsset {
          id
          assetId
          assetName
          assetType
          __typename
        }
        __typename
      }
      pagedGroups(ids: $groupIds, pageNumber: 1, resultsPerPage: 10000) {
        groups {
          id
          name
          category
          ancestry {
            id
            name
          }
          mostDistantDeviceOrAssetGroups {
            id
            name
            type
          }
          members {
            type
            count
            data {
              ... on NetworkAsset {
                id
                assetId
                assetName
                assetType
                location {
                  latitude
                  longitude
                }
              }
              ... on Device {
                id
                serialNumber
                currentCommission {
                  location {
                    latitude
                    longitude
                  }
                }
              }
            }
          }
        }
        pagination {
          pageNumber
          totalPages
          totalResults
        }
      }
    }
  `
}

export default compose(
  transformProps(() => () => {
    return {
      selectDataSourcesResult: createSelectGraphQLResult('dataSources', {
        mapResult: parseGraphQLResult,
        nullObject: null,
      }),
      selectGroupsResult: createSelectGraphQLResult('pagedGroups', {
        mapResult: parseGraphQLResult,
        nullObject: null,
      }),
    }
  }),
  graphql(AnalysisSidebar.SidebarItemsQuery, {
    options: ({
      networkAssetIds,
      deviceIds,
      groupIds,
    }) => {
      return {
        variables: {
          networkAssetIds,
          deviceIds,
          groupIds,
        },
      }
    },
    props: ({
      data,
      ownProps: {
        selectDataSourcesResult,
        selectGroupsResult,
      },
    }) => {
      const { refetch } = data

      return {
        dataSourcesResult: selectDataSourcesResult(data),
        groupSourcesResult: selectGroupsResult(data),
        refetchData: refetch,
      }
    },
    skip: ({
      networkAssetIds,
      deviceIds,
      groupIds,
    }) => {
      return !networkAssetIds.length && !deviceIds.length && !groupIds.length
    },
  })
)(AnalysisSidebar)
