import { useMemo } from 'react'
import gql from 'graphql-tag'
import {
  formatTimestampForAPI,
  createSelectGraphQLResult,
} from '../../utils/index'
import { parseGraphQLResult } from '../../api/presenters/index'
import { useQuery } from '@apollo/client'

const startDate = formatTimestampForAPI(new Date().setHours(0, 0, 0, 0))
const endDate = formatTimestampForAPI(new Date())

export function useGetDevicesStatisticsSummaries() {
  const DEVICES_STATISTICS = gql`
    query DevicesStatisticsModel($start: String!, $end: String!) {
      getDevicesStatistics(start: $start, end: $end) {
        installed
        commissionedOnly
        decommissioned
        withErrors
        withWarnings
        healthy
        total
      }
    }
  `

  const devicesStatisticsQueryResult = useQuery(DEVICES_STATISTICS, {
    variables: {
      start: startDate,
      end: endDate,
    },
    fetchPolicy: 'network-only',
  })

  const statisticsResult = useMemo(() =>
    createSelectGraphQLResult('data', {
      mapResult: (values) => parseGraphQLResult(values.getDevicesStatistics),
      nullObject: [],
    })(devicesStatisticsQueryResult)
  , [devicesStatisticsQueryResult])

  return { statisticsResult }
}
