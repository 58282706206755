import { flatten, takeWhile } from 'lodash/fp/array'

import TimeSeriesRawDataListItems from './time_series_raw_data_list_items'

export default class PressureSubsetData {
  constructor(params) {
    const {
      deviceId, networkAssetId, channel, data = [], rawDataRequests = [],
      resolution,
    } = params
    Object.assign(this, { deviceId, networkAssetId, channel })

    this.dataSegments = formatDataIntoSegments(data, resolution)
    this.rawDataSegments = rawDataRequests
      .filter(r => r && r.data && r.data.length)
      .map(r => r.data.map(({ time, ...rest }) => {
        return TimeSeriesRawDataListItems.from({
          time: new Date(Number(time)),
          ...rest,
        })
      }))

    this.mixedRawDataSegments = createMixedRawDataSegments(
      this.dataSegments,
      this.rawDataSegments
    )

    Object.freeze(this)
  }

  isFromDevice() {
    return !this.channel && !this.networkAssetId
  }

  isFromNetworkAsset() {
    return !this.isFromDevice()
  }

  static from(...args) {
    return new PressureSubsetData(...args)
  }
}

function formatDataIntoSegments(subsetData, resolution) {
  if (subsetData.length === 0) {
    return [{ data: [] }]
  }

  const resolutionInMillis = resolution * 1000

  let currentChunk = { index: 0, data: [subsetData[0]] }
  const chunks = [currentChunk]

  for (let i = 1; i < subsetData.length; i++) {
    const a = subsetData[i - 1]
    const b = subsetData[i]

    if (Math.abs(b.time - a.time) > resolutionInMillis) {
      const lastChunkIndex = currentChunk.index
      currentChunk = { index: lastChunkIndex + 1, data: [b] }
      chunks.push(currentChunk)
    } else {
      currentChunk.data.push(b)
    }
  }

  return chunks
}

function createMixedRawDataSegments(dataSegments, rawDataChunks) {
  if (dataSegments.length === 0) {
    return [{ data: flatten(rawDataChunks) }]
  }

  if (rawDataChunks.length === 0) {
    return dataSegments
  }

  let segmentHasRawData = false

  const spliceRawDataIntoSubset = (rawData, { data }, i) => {
    const startSegmentTime = data[0].time
    const endSegmentTime = data[data.length - 1].time
    const startRawDataTime = rawData[0].time
    const endRawDataTime = rawData[rawData.length - 1].time
    const startsInSegment = startRawDataTime > startSegmentTime &&
      startRawDataTime <= endSegmentTime
    const endsInSegment = endRawDataTime >= startSegmentTime &&
      endRawDataTime < endSegmentTime
    const replacesSegment = startRawDataTime <= startSegmentTime &&
      endRawDataTime >= endSegmentTime
    let rawDataSegment = rawData

    if (replacesSegment || startsInSegment || endsInSegment) {
      let overlapStartIndex = 0
      let overlapEndIndex = data.length

      segmentHasRawData = true

      for (let j = 0; j < data.length; j++) {
        const datumTime = data[j].time

        if (startsInSegment && overlapStartIndex === 0 &&
          datumTime > startRawDataTime) {
          overlapStartIndex = j - 1

          if (!endsInSegment) {
            break
          }
        }

        if (endsInSegment && overlapEndIndex === data.length &&
          datumTime > endRawDataTime) {
          overlapEndIndex = j
          break
        }
      }

      // If there's an adjacent segment, we need to check whether the raw
      // data extends into it, and only use raw data up to the start of it
      if (i < dataSegments.length - 1) {
        const nextDataSegmentData = dataSegments[i + 1].data
        const nextSegmentStartDate = nextDataSegmentData[0].time

        if (endRawDataTime >= nextSegmentStartDate) {
          rawDataSegment = takeWhile(
            ({ time }) => time < nextSegmentStartDate
          )(rawData)
        }
      }

      return {
        data: [
          ...data.slice(0, overlapStartIndex),
          ...rawDataSegment,
          ...data.slice(overlapEndIndex),
        ],
      }
    } else {
      return { data }
    }
  }

  let mixedRawDataSegments = dataSegments
  for (const rawData of rawDataChunks) {
    mixedRawDataSegments = mixedRawDataSegments.map((segment, i) => (
      spliceRawDataIntoSubset(rawData, segment, i)
    ))

    // Raw data does not start or end in a data segment
    if (!segmentHasRawData) {
      const startIndex = dataSegments.findIndex(
        ({ data }) => rawData[rawData.length - 1].time < data[0].time
      )

      // Raw data starts after the last normal data segment
      if (startIndex === -1) {
        mixedRawDataSegments = dataSegments.slice(0)

        const lastSegmentData =
          mixedRawDataSegments[mixedRawDataSegments.length - 1].data

        if (rawData[0].time >
          lastSegmentData[lastSegmentData.length - 1].time) {
          mixedRawDataSegments.push({
            data: rawData,
          })
        } else {
          // Raw data overlaps all normal data segments
          mixedRawDataSegments = [{
            data: rawData,
          }]
        }
      } else {
        // Raw data starts before a data segment
        mixedRawDataSegments.splice(startIndex, 0, {
          data: rawData,
        })
      }
    }
  }

  return mixedRawDataSegments
}
