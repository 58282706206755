import React from 'react'

export default function createEventDispatcher() {
  const channels = []

  class Channel extends React.PureComponent {
    render() {
      return this.props.children || null
    }

    componentDidMount() {
      channels.push(this)
    }

    componentWillUnmount() {
      channels.splice(channels.indexOf(this), 1)
    }
  }

  return {
    dispatch(handlerName, ...args) {
      for (const channel of channels) {
        const handler = channel.props[handlerName]

        if (handler) {
          handler(...args)
        }
      }
    },
    Channel,
  }
}
