import gql from 'graphql-tag'

export const ExportDevicesHealthCSV = gql`
  mutation ExportDevicesHealthToCsv(
    $serialNumber: String,
    $networkAssetReadableId:  String,
    $networkAssetReadableName:  String,
    $groups:  String,
    $hasActiveAlerts: Boolean,
    $hasDataGaps:  Boolean,
    $hasBatteryIssues:  Boolean,
    $signalStrengthMin: Float,
    $signalStrengthMax:  Float,
    $batteryVoltageMin:  Float,
    $batteryVoltageMax: Float,
  ) {
    requestDevicesHealthReport(
      serialNumber: $serialNumber,
      networkAssetReadableId: $networkAssetReadableId,
      networkAssetReadableName: $networkAssetReadableName,
      groups: $groups,
      hasActiveAlerts: $hasActiveAlerts,
      hasDataGaps: $hasDataGaps,
      hasBatteryIssues: $hasBatteryIssues,
      signalStrengthMin: $signalStrengthMin,
      signalStrengthMax: $signalStrengthMax,
      batteryVoltageMin: $batteryVoltageMin,
      batteryVoltageMax: $batteryVoltageMax,
    ) {
      jobId
    }
  }
`
