import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash/fp/object'
import { compose } from 'redux'
import { createSelector } from 'reselect'
import { withTranslation } from 'react-i18next'

import AsyncResult from '@@src/utils/async_result'
import GraphAlertGroup from '@@src/components/graphs/graph_alert_group'

class CpisViewAlertGroup extends React.PureComponent {
  static propTypes = {
    cpisDataResult: PropTypes.instanceOf(AsyncResult).isRequired,
  }

  render() {
    const { className } = this.props
    const alertConfigs = this.selectAlertConfigs(this.props)

    return (
      <GraphAlertGroup className={className} alertConfigs={alertConfigs}/>
    )
  }

  selectAlertConfigs = createSelector(
    [
      get('t'),
      get('cpisDataResult'),
    ],
    (t, cpisDataResult) => {
      switch (true) {
        case (cpisDataResult.wasFailure()):
          return [{ color: 'danger', text: t('text.error_loading_cpis') }]

        case (
          cpisDataResult.wasSuccessful() &&
          !cpisDataResult.data.some(({ chunkedData }) => chunkedData.length)
        ):
          return [{ color: 'warning', text: t('text.no_cpis_data') }]

        default:
          return []
      }
    }
  )
}

export default compose(
  withTranslation([
    'src/analysis_path/pressure_analysis_path/cpis_view/cpis_view_alert_group',
    'common/text',
  ]),
)(CpisViewAlertGroup)
