import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { divIcon } from 'leaflet'
import { Marker } from 'react-leaflet'
import { renderToString } from 'react-dom/server'
import { MARKER_ANCHOR_HEIGHT, MARKER_HEIGHT, MARKER_WIDTH } from './constants'
import GeoCoordinate from '../../../utils/graphs/geo_coordinate'

import './map_marker.component.scss'

const MapMarkerComponent = ({
  name = '',
  onClick = () => null,
  children,
  markerRef,
  iconUrl,
  errorCount,
  warningCount,
  position,
}) => {
  return (
    <Marker
      name={name}
      ref={markerRef}
      position={position.toArray()}
      onclick={onClick}
      icon={divIcon({
        iconSize: [MARKER_WIDTH, MARKER_HEIGHT],
        iconAnchor: [MARKER_WIDTH / 2, MARKER_ANCHOR_HEIGHT],
        shadowUrl: undefined,
        popupAnchor: [0, -0.7 * MARKER_HEIGHT],
        className: 'map-label custom-map-marker',
        html: renderToString((
          <div className="marker-icon d-flex justify-content-center" style={{ width: MARKER_WIDTH }}>
            <img width={MARKER_WIDTH} height={MARKER_HEIGHT} src={iconUrl} alt="" />
            {(errorCount > 0 || warningCount > 0) && (
              <span className={`marker-map-badge fas fa-exclamation-circle ${classnames({
                'bg-white text-danger': errorCount > 0,
                'bg-dark text-warning': warningCount > 0,
              })}`} />
            )}
          </div>
        )),
      })}
    >
      {children}
    </Marker>
  )
}

MapMarkerComponent.propTypes = {
  markerRef: PropTypes.shape({ current: PropTypes.any }),
  iconUrl: PropTypes.string,
  errorCount: PropTypes.number,
  warningCount: PropTypes.number,
  position: PropTypes.instanceOf(GeoCoordinate),
}

export default React.memo(MapMarkerComponent)
