import React, { PureComponent, createRef } from 'react'
import PropTypes from 'prop-types'
import { noop } from 'lodash/fp/util'
import { min, max } from 'lodash/fp/math'
import { isObject } from 'lodash/lang'
import { zoom, zoomIdentity } from 'd3-zoom'
import { select, event as d3Event } from 'd3-selection'

import { ONLY_X, X_AND_Y }
from '@@src/components/graphs/drag_layer/drag_event_layer'
import { MouseEventContext }
from '@@src/components/graphs/mouse_event_layer'
import { GraphConfig } from '@@src/components/graphs/graph_context'

class DragPanLayer extends PureComponent {
  static defaultProps = {
    dragBehaviour: ONLY_X,
    onPan: noop,
    onPanEnd: noop,
  }

  static propTypes = {
    dragBehaviour: PropTypes.oneOf([ONLY_X, X_AND_Y]).isRequired,
    graphConfig: PropTypes.instanceOf(GraphConfig).isRequired,
    children: PropTypes.node,
    onPan: PropTypes.func.isRequired,
    onPanEnd: PropTypes.func.isRequired,
  }

  state = {
    mouseX: 0,
    mouseY: 0,
    panOrigin: { x: 0, y: 0 },
  }

  backgroundRef = createRef()

  render() {
    return (
      <g ref={this.backgroundRef}>{this.props.children}</g>
    )
  }

  componentDidMount() {
    this.bindPan()
  }

  componentWillUnmount() {
    this.unbindPan()
  }

  unbindPan = () => {
    select(this.backgroundRef.current.ownerSVGElement).on('.zoom', null)
  }

  bindPan = () => {
    const graph = select(this.backgroundRef.current.ownerSVGElement)
    const baseZoomTransform = zoom().transform

    const axisZoom = zoom()
      .on('start', this.handleStart)
      .on('end', this.handleEnd.bind(this, graph, baseZoomTransform))
      .on('zoom', this.handleZoom)

    graph.call(axisZoom)
      .on('wheel.zoom', null)
      .on('dblclick.zoom', null)
  }

  handleStart = () => {
    this.onMouseDown(d3Event.sourceEvent)
  }

  handleEnd(graph, baseZoomTransform) {
    // The current graph Transform is held within an internal
    // prop ('__zoom') on the 'zoomed' element, e.g. the graph svg.
    //
    // When the 'graphConfig' is updated due to new data, the axes are
    // re-rendered, and rebased around the new data, leaving
    // the previous Transform out of sync.
    //
    // The existing Transform must be reverted to its original
    // state: '(x: 0, y: 0, k: 1)' - or 'zoomIdentity'.
    //
    // See: https://github.com/d3/d3-zoom#zoom_transform
    this.onMouseUp(d3Event.sourceEvent)
    graph.call(baseZoomTransform, zoomIdentity)
  }

  handleZoom = () => {
    const panEvent = this.computePanEvent(d3Event.sourceEvent)

    if (panEvent) {
      this.props.onPan(panEvent)
    }
  }

  onMouseDown = event => {
    const { dragBehaviour } = this.props

    if (isObject(event) && ('pageX' in event || 'pageY' in event)) {
      this.setState({
        panOrigin: {
          x: event.pageX,
          y: dragBehaviour === X_AND_Y ? event.pageY : this.state.panOrigin.y,
        },
        initialXScale: this.props.graphConfig.xScale,
        initialYScale: this.props.graphConfig.yScale,
      })
    }
  }

  onMouseUp = event => {
    const panEvent = this.computePanEvent(event)

    if (panEvent) {
      this.props.onPanEnd(panEvent)
    }

    this.setState({
      panOrigin: { x: 0, y: 0 },
      initialXScale: null,
    })
  }

  computePanEvent(event) {
    const { dragBehaviour } = this.props

    const panXDistance = this.computePanXDistance(event)
    const panYDistance = dragBehaviour === X_AND_Y ?
      this.computePanYDistance(event) : 0

    if (panXDistance || panYDistance) {
      let invertedPanX = null
      let invertedPanY = null

      if (panXDistance) {
        const xScale = this.state.initialXScale

        const range = xScale.range()
        const minX = min(range)
        const maxX = max(range)

        invertedPanX = [
          xScale.invert(minX + panXDistance),
          xScale.invert(maxX + panXDistance),
        ]
      }

      if (panYDistance) {
        const yScale = this.state.initialYScale

        const range = yScale.range()
        const minY = min(range)
        const maxY = max(range)

        invertedPanY = [
          yScale.invert(minY + panYDistance),
          yScale.invert(maxY + panYDistance),
        ]
      }

      return {
        panEventX: !invertedPanX ? null : {
          start: min(invertedPanX),
          end: max(invertedPanX),
        },
        panEventY: !invertedPanY ? null : {
          start: min(invertedPanY),
          end: max(invertedPanY),
        },
      }
    } else {
      return null
    }
  }

  computePanXDistance(event) {
    const mouseX = event.pageX
    const { panOrigin } = this.state
    const dragOffsetX = mouseX - panOrigin.x

    return -dragOffsetX
  }

  computePanYDistance(event) {
    const mouseY = event.pageY
    const { panOrigin } = this.state
    const dragOffsetY = mouseY - panOrigin.y

    return -dragOffsetY
  }
}

export default function DragPanLayerContainer(props) {
  return (
    <MouseEventContext.Consumer>
      {({ Channel }) => (
        <DragPanLayer Channel={Channel} {...props}/>
      )}
    </MouseEventContext.Consumer>
  )
}
