import React from 'react'
import { Link } from 'react-router-dom'
import Device from '@@src/api/presenters/device'
import routes from '@@src/routes'
import classnames from 'classnames'
import DeviceIssue from './device_issue'

class GroupMembersType {
  constructor(rawData) {
    Object.assign(this, rawData)

    Object.freeze(this)
  }
}

export class GroupDetailsType {
  static GroupMembersType = GroupMembersType

  constructor(rawData) {
    Object.assign(this, rawData)

    this.currentAlertsMuteEnd = rawData.currentAlertsMuteEnd ?
      new Date(rawData.currentAlertsMuteEnd) : null

    this.uuid = `g.${rawData.id}`

    Object.freeze(this)
  }

  get dataSourceId() {
    return this.uuid
  }

  static from(...args) {
    return new GroupDetailsType(...args)
  }

  static isGroupDetailsDataSourceId(dataSourceId = '') {
    return String(dataSourceId).match(/^g\.\d+$/)
  }

  static parseIdFromDataSourceId(dataSourceId = '') {
    return parseInt(String(dataSourceId).replace(/^g\./, ''), 10)
  }

  isNetworkAssetGroup() {
    return this.members.type === 'VNetworkAsset'
  }

  isDeviceGroup() {
    return this.members.type === 'VDevice'
  }

  isGroupOfGroups() {
    return this.members.type === 'VGroup'
  }

  isGroup() {
    return (this.isNetworkAssetGroup() || this.isDeviceGroup() ||
      this.isGroupOfGroups)
  }

  getTypeOfGroup() {
    return this.members.type
  }

  constructGroupAncestryPath() {
    return ''
  }

  constructGroupAncestryPathWithLinks() {
    return ''
  }
}

export class MixedGroupMembersType {
  constructor(rawData) {
    Object.assign(this, rawData)

    Object.freeze(this)
  }
}

export class MixedGroupDetailsType {
  static MixedGroupMembersType = MixedGroupMembersType

  constructor(rawData) {
    Object.assign(this, rawData)

    this.currentAlertsMuteEnd = rawData.currentAlertsMuteEnd ?
      new Date(rawData.currentAlertsMuteEnd) : null

    this.uuid = `g.${rawData.id}`

    Object.freeze(this)
  }

  get dataSourceId() {
    return this.uuid
  }

  get mostSevereDeviceIssue() {
    if (this.isGroupOfGroups()) {
      return Device.getMostSevereIssue(this.activeIssues)
    } else {
      const mostSevereIssues = this.isNetworkAssetGroup() ?
        this.members.data.map(m => m.mostSevereDeviceIssue) :
        this.members.data.map(m => m.mostSevereIssue)
      const filteredMostSevereIssues = mostSevereIssues.filter(a => a)

      return Device.getMostSevereIssue(filteredMostSevereIssues)
    }
  }

  static from(...args) {
    return new MixedGroupDetailsType(...args)
  }

  static isGroupDetailsDataSourceId(dataSourceId = '') {
    return String(dataSourceId).match(/^g\.\d+$/)
  }

  static parseIdFromDataSourceId(dataSourceId = '') {
    return parseInt(String(dataSourceId).replace(/^g\./, ''), 10)
  }

  isNetworkAssetGroup() {
    return this.members.type === 'VNetworkAsset'
  }

  isDeviceGroup() {
    return this.members.type === 'VDevice'
  }

  isGroupOfGroups() {
    return this.members.type === 'VGroup'
  }

  isGroup() {
    return (this.isNetworkAssetGroup() || this.isDeviceGroup() ||
      this.isGroupOfGroups)
  }

  isCongruityGroup() {
    return !!this.congruity
  }

  getTypeOfGroup() {
    return this.members.type
  }

  constructGroupAncestryPath() {
    const ancestors = this.ancestry || []
    return ancestors.reduce((accumulator, currentValue) => {
      return `${accumulator}${currentValue.name} > `
    }, '')
  }

  constructGroupAncestryPathWithLinks() {
    const ancestors = this.ancestry || []
    return ancestors.reduce((accumulator, currentValue) => {
      return (
        <React.Fragment>
          {accumulator}
          <Link
            to={routes.managementGroupsDetailPath(currentValue.id)}
            className={classnames('text-muted')}>
            {currentValue.name}
          </Link>
          &nbsp;
          >
          &nbsp;
        </React.Fragment>
      )
    }, '')
  }
}

export class GroupListItemModel {
  constructor(rawData) {
    Object.assign(this, rawData)

    Object.freeze(this)
  }
}

export class GetGroupsResult {
  static GroupListItemModel = GroupListItemModel
  constructor(rawData) {
    Object.assign(this, rawData)

    // this.currentAlertsMuteEnd = rawData.currentAlertsMuteEnd ?
    //   new Date(rawData.currentAlertsMuteEnd) : null

    this.uuid = `g.${rawData.groupId}`

    Object.freeze(this)
  }

  get dataSourceId() {
    return this.uuid
  }

  get mostSevereDeviceIssue() {
    switch (true) {
      case this.hasErrors:
        return new DeviceIssue({ severity: DeviceIssue.ERROR_SEVERITY })
      case this.hasWarnings:
        return new DeviceIssue({ severity: DeviceIssue.WARNING_SEVERITY })
      case this.hasInformative:
        return new DeviceIssue({ severity: DeviceIssue.INFORMATION_SEVERITY })
      default:
        return null
    }
  }

  get countOfMembers() {
    return this.membersCount
  }

  static from(...args) {
    return new GetGroupsResult(...args)
  }

  static isGroupDetailsDataSourceId(dataSourceId = '') {
    return String(dataSourceId).match(/^g\.\d+$/)
  }

  static parseIdFromDataSourceId(dataSourceId = '') {
    return parseInt(String(dataSourceId).replace(/^g\./, ''), 10)
  }

  isNetworkAssetGroup() {
    return this.membersType === 'VNetworkAsset'
  }

  isDeviceGroup() {
    return this.membersType === 'VDevice'
  }

  isGroupOfGroups() {
    return this.membersType === 'VGroup'
  }

  isGroup() {
    return (this.isNetworkAssetGroup() || this.isDeviceGroup() ||
      this.isGroupOfGroups())
  }

  isCongruityGroup() {
    return !!this.congruity
  }

  getTypeOfGroup() {
    return this.membersType
  }

  constructGroupAncestryPath() {
    const ancestors = this.ancestry || []
    return ancestors.reduce((accumulator, currentValue) => {
      return `${accumulator}${currentValue.name} > `
    }, '')
  }

  constructGroupAncestryPathWithLinks() {
    const ancestors = this.ancestry || []
    return ancestors.reduce((accumulator, currentValue) => {
      return (
        <React.Fragment>
          {accumulator}
          <Link
            to={routes.managementGroupsDetailPath(currentValue.id)}
            className={classnames('text-muted')}>
            {currentValue.name}
          </Link>
          &nbsp;
          >
          &nbsp;
        </React.Fragment>
      )
    }, '')
  }
}
