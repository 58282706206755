import { graphql } from '@apollo/client/react/hoc'
import classnames from 'classnames'
import gql from 'graphql-tag'
import L from 'leaflet'
import { get } from 'lodash/fp/object'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { withTranslation } from 'react-i18next'
import MarkerClusterGroup from 'react-leaflet-markercluster'
import {
  Alert,
  NavLink as BNavLink,
  Button,
  Col,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap'
import { compose } from 'redux'
import { createSelector } from 'reselect'

import * as analytics from '@@src/analytics'
import { parseGraphQLResult } from '@@src/api/presenters'
import DeviceCommission from '@@src/api/presenters/device_commission'
import { MixedGroupDetailsType } from '@@src/api/presenters/group'
import NetworkAsset from '@@src/api/presenters/network_asset'
import AsyncResultSwitch from '@@src/components/async_result_switch'
import SubmitButton from '@@src/components/buttons/submit_button'
import ErrorInfo from '@@src/components/error_info'
import DatetimePicker from '@@src/components/forms/datetime_picker'
import DebouncedInput from '@@src/components/forms/debounced_input'
import {
  LOCATION_SOURCE_GPS,
  LOCATION_SOURCE_SURVEY,
} from '@@src/components/forms/inputs/location_information_input_group'
import NetworkAssetIcon from '@@src/components/icons/network_asset_icon'
import DeviceCommissionMarker from '@@src/components/maps/markers/device_commission_marker'
import NetworkAssetMarker from '@@src/components/maps/markers/network_asset_marker'
import StandardMap from '@@src/components/maps/standard_map'
import transformProps from '@@src/components/transform_props'
import { COORDINATE_REGEX } from '@@src/management_path/network_assets_path/network_asset_fields'
import {
  AsyncResult,
  createSelectGraphQLResult,
  validAssetTypes,
} from '@@src/utils'
import AppError from '@@src/utils/app_error'
import {
  convertToWGS84,
  COORDS_FORMAT_DEFAULT,
} from '@@src/utils/conversions/coordinate_conversions'
import AssignNetworkAssetFields from './assign_asset_fields'
import CreateAndAssignNetworkAssetInterface from './create_and_assign_network_asset_interface'
import CreateAndAssignNetworkAssetFields from './create_and_assign_network_asset_interface/create_and_assign_network_asset_fields' // eslint-disable-line max-len
import NetworkAssetPopupWithAssignmentOptions from './network_asset_popup_with_assignment_options'

import styles from './index.css'

const API_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSS'
const NETWORK_ASSETS_PENDING = AsyncResult.pending([])

class AssignNetworkAssetToDeviceModal extends React.Component {
  static defaultProps = {
    onSuccess: () => {},
  }

  static propTypes = {
    onSuccess: PropTypes.func.isRequired,
    commission: PropTypes.instanceOf(DeviceCommission),
    googleMaps: PropTypes.object,
  }

  render() {
    const { t, isOpen, commission, googleMaps } = this.props
    const { createFromExisting, result, sourceFilter } = this.state

    const networkAssetsResult = this.selectNetworkAssetResultAwaitingAnimation(
      this.props,
      this.state
    )

    if (!commission) {
      return null
    }

    const assetToFocus = this.selectAssetInFocus(this.props, this.state)

    const selectedChannel = this.selectFieldValue('channel')
    const assetIdToFocus = assetToFocus ? assetToFocus.id : null

    return (
      <Modal
        isOpen={isOpen}
        toggle={this.onToggle}
        onClosed={this.onModalClosed}
        onOpened={this.onModalOpened}
        className={styles.container}
      >
        <ModalHeader>
          {t('headings.title', {
            deviceSerialNumber: commission.device.serialNumber,
          })}
        </ModalHeader>

        <ModalBody>
          <div className={styles['modal-body-section']}>
            <Row className={styles['tab-navigation-row']}>
              <Col sm="12">
                <Nav tabs>
                  <NavItem>
                    <BNavLink
                      onClick={this.setTabToExisting}
                      className={classnames({ active: createFromExisting })}
                    >
                      {t('buttons.existing_asset_tab')}
                    </BNavLink>
                  </NavItem>

                  <NavItem>
                    <BNavLink
                      name="create-and-assign-asset-tab-btn"
                      onClick={this.setTabToCreateFromNew}
                      className={classnames({ active: !createFromExisting })}
                    >
                      {t('buttons.create_and_assign_asset_tab')}
                    </BNavLink>
                  </NavItem>
                </Nav>
              </Col>
            </Row>

            <Row className={styles['tabs-section']}>
              <TabContent
                activeTab={String(createFromExisting)}
                className={classnames('col-sm-6', styles['tab-contents-row'])}
              >
                <TabPane tabId="true" className={styles['tab-contents-tab']}>
                  {createFromExisting
                    ? this.renderCreateFromExistingAssetTab()
                    : null}
                </TabPane>

                <TabPane tabId="false" className={styles['tab-contents-tab']}>
                  {!createFromExisting ? (
                    <CreateAndAssignNetworkAssetInterface
                      device={commission.device}
                      fields={this.createAndAssignFields}
                      sourceFilter={sourceFilter}
                      handleSelectGroup={this.handleClickSelectGroup}
                      className={styles['create-and-assign-interface']}
                    />
                  ) : null}
                </TabPane>
              </TabContent>

              <StandardMap
                zoom={this.selectMapZoom(this.props, this.state)}
                center={this.selectMapCenter(this.props, this.state)}
                className={classnames('col-sm-6', styles.map)}
              >
                <MarkerClusterGroup
                  showCoverageOnHover={false}
                  removeOutsideVisibleBounds={true}
                >
                  {commission ? (
                    <DeviceCommissionMarker commission={commission} />
                  ) : null}

                  {createFromExisting ? (
                    networkAssetsResult.data.map((networkAsset) => (
                      <NetworkAssetMarker
                        key={networkAsset.id}
                        onClick={this.createOnClickNetworkAsset(networkAsset)}
                        isPopupOpen={networkAsset.id === assetIdToFocus}
                        networkAsset={networkAsset}
                      >
                        {networkAsset.id === assetIdToFocus ? (
                          <NetworkAssetPopupWithAssignmentOptions
                            googleMaps={googleMaps}
                            datetime={this.selectFieldValue('startTime')}
                            networkAsset={networkAsset}
                            datetimeError={this.assignAssetFields.errorFor(
                              'startTime'
                            )}
                            result={result}
                            isValidDate={this.isValidInstallationStartTime}
                            onClickInstall={this.onSubmitForm}
                            selectedChannel={selectedChannel}
                            onChangeDatetime={this.onChangeDatetime}
                            onSelectAssetChannel={this.onChangeNetworkAsset}
                            selectedNetworkAssetId={assetIdToFocus}
                          />
                        ) : null}
                      </NetworkAssetMarker>
                    ))
                  ) : assetToFocus ? (
                    <NetworkAssetMarker networkAsset={assetToFocus} />
                  ) : null}
                </MarkerClusterGroup>
              </StandardMap>
            </Row>
          </div>
        </ModalBody>

        <ModalFooter className={styles['buttons-row']}>
          <Button
            name="cancel-button"
            type="button"
            color="secondary"
            onClick={this.onToggle}
            className="float-left"
          >
            {t('buttons.cancel')}
          </Button>

          {!createFromExisting ? (
            <div className={styles['extra-actions-section']}>
              <SubmitButton
                buttonStatus=""
                name="submit-button"
                color="primary"
                result={result}
                submitText={t('buttons.create_installation')}
                onSubmitForm={this.onSubmitCreateAndAssign}
              />
            </div>
          ) : null}
        </ModalFooter>
      </Modal>
    )
  }

  renderCreateFromExistingAssetTab() {
    const { t } = this.props
    const { result, searchQuery, assetTypeFilter } = this.state

    const networkAssetsResult = this.selectNetworkAssetResultAwaitingAnimation(
      this.props,
      this.state
    )

    return (
      <Row className={styles['tab-contents-tab-row']}>
        <Col sm="12" className={styles['network-assets-list-section']}>
          {!result.wasFailure() ? null : <ErrorInfo error={result.error} />}

          {this.assignAssetFields.errorFor('startTime') ? (
            <Alert color="danger">
              {t(this.assignAssetFields.errorFor('startTime'))}
            </Alert>
          ) : null}

          <Row className={styles['network-assets-list-controls']}>
            <Col sm="5">
              <p className={styles['network-assets-list-heading']}>
                {t('headings.available_assets')}
              </p>
            </Col>

            <Col sm="4">
              <InputGroup>
                <DebouncedInput
                  type="search"
                  name="network-assets-search-input"
                  value={searchQuery}
                  debounce={200}
                  onChange={this.onChangeNetworkAssetsQuery}
                  placeholder={t('text.search_placeholder')}
                />

                <InputGroupText>
                  <i className="fa fa-search"></i>
                </InputGroupText>
              </InputGroup>
            </Col>

            <Col sm="3">
              <Input
                type="select"
                name="network-assets-filter-input"
                value={assetTypeFilter}
                onChange={this.onChangeAssetTypeFilter}
              >
                <option value="">{t('options.any')}</option>

                {validAssetTypes().map((type) => (
                  <option key={type} value={type}>
                    {t(`common/text:text.${type}`)}
                  </option>
                ))}
              </Input>
            </Col>
          </Row>

          <AsyncResultSwitch
            result={networkAssetsResult}
            renderSuccessResult={this.renderNetworkAssetsList}
          />
        </Col>
      </Row>
    )
  }

  renderNetworkAssetsList = ({ data: networkAssets }) => {
    const { t, commission } = this.props
    const { result, selectedNetworkAsset } = this.state
    const assignableNetworkAssets =
      this.selectAssignableNetworkAssets(networkAssets)
    const assignAssetFields = this.assignAssetFields
    const selectedChannel = this.selectFieldValue('channel')
    const selectedNetworkAssetId = selectedNetworkAsset
      ? selectedNetworkAsset.id
      : null

    return (
      <ul className={styles['network-assets-list']}>
        {assignableNetworkAssets.map((networkAsset) => {
          const isSelected = networkAsset.id === selectedNetworkAssetId

          const classNameSuffix = isSelected ? '-selected' : ''

          const channelListClassName =
            styles[`asset-channel-options-list${classNameSuffix}`]

          const containerClassName =
            styles[`network-asset-list-item${classNameSuffix}`]

          const actionsClassName =
            styles[`network-asset-list-item-actions${classNameSuffix}`]

          return (
            <li
              key={networkAsset.id}
              name="network-asset-item"
              onClick={this.createOnClickNetworkAsset(networkAsset)}
              className={containerClassName}
            >
              <Row>
                <Col sm="8" className={styles['summary-column']}>
                  <div className={styles['icon-subcolumn']}>
                    <NetworkAssetIcon
                      networkAsset={networkAsset}
                      className={styles.icon}
                    />
                  </div>

                  <div className={styles['summary-subcolumn']}>
                    <span className={styles['network-asset-id']}>
                      {networkAsset.assetId}
                    </span>

                    {networkAsset.assetName ? (
                      <div>
                        <i>
                          <small>{networkAsset.assetName}</small>
                        </i>
                      </div>
                    ) : null}

                    <small className={styles['network-asset-type']}>
                      {networkAsset.translateAssetType(t)}
                    </small>

                    <ul className={channelListClassName}>
                      {networkAsset.availableChannels.map((channel) => (
                        <li key={channel}>
                          <Label onClick={stopPropagation}>
                            <Input
                              type="radio"
                              name="network-asset-channel-input"
                              value={channel}
                              checked={
                                networkAsset.id === selectedNetworkAssetId &&
                                channel === selectedChannel
                              }
                              onChange={this.createOnClickNetworkAsset(
                                networkAsset,
                                channel
                              )}
                            />
                            {t(`text.asset_channel_option_${channel}`)}
                          </Label>
                        </li>
                      ))}
                    </ul>

                    {isSelected ? (
                      <div
                        className={actionsClassName}
                        onClick={stopPropagation}
                      >
                        <SubmitButton
                          buttonStatus=""
                          name="submit-button"
                          size="sm"
                          color="primary"
                          onSubmitForm={this.onSubmitForm}
                          result={result}
                          submitText={t('buttons.create_installation')}
                        ></SubmitButton>{' '}
                        {!commission.end ? (
                          <DatetimePicker
                            name="installation-start-time"
                            type="datetime"
                            value={this.selectFieldValue('startTime')}
                            invalid={!!assignAssetFields.errorFor('startTime')}
                            onChange={this.onChangeDatetime}
                            className={styles['datetime-input']}
                            isValidDate={this.isValidInstallationStartTime}
                          />
                        ) : null}
                      </div>
                    ) : null}
                  </div>
                </Col>
              </Row>
            </li>
          )
        })}
      </ul>
    )
  }

  constructor(props) {
    super(props)

    this.assignAssetFields = new AssignNetworkAssetFields(
      this,
      'assignAssetFields'
    )

    this.createAndAssignFields = new CreateAndAssignNetworkAssetFields(
      this,
      'createAndAssignFields'
    )

    this.state = this.initialState(props, false)
  }

  initialState(props, opened = this.state.opened) {
    const { commission } = props

    const normalized = (date) =>
      moment(date).add(1, 'minute').startOf('minute').toDate()

    const prevInstallation =
      !commission || !commission.device
        ? null
        : (commission.device.installations || []).find((ins) => {
            return (
              ins.deviceId === commission.device.id &&
              normalized(ins.start) >= commission.start &&
              ins.end
            )
          })
    const startTime = !commission
      ? moment().toDate()
      : !commission.end && prevInstallation
      ? normalized(prevInstallation.end)
      : normalized(commission.start, API_DATE_FORMAT)

    const { latitude, longitude, altitude } = commission
      ? commission.location
      : { latitude: 50.9614246, longitude: -1.4231234, altitude: 0 }
    const coordinates = `${latitude}, ${longitude}`
    const elevation = altitude || 0

    return {
      result: AsyncResult.notFound(),
      opened,
      searchQuery: '',
      assetTypeFilter: '',
      assignAssetFields: this.assignAssetFields.initialState({ startTime }),
      createFromExisting: true,
      selectedNetworkAsset: null,
      createAndAssignFields: this.createAndAssignFields.initialState({
        locationSource:
          !commission || !commission.device || !commission.device.coordinates
            ? LOCATION_SOURCE_SURVEY
            : LOCATION_SOURCE_GPS,
        elevation,
        startTime,
        coordinates,
        coordinatesFormat: COORDS_FORMAT_DEFAULT,
      }),
      sourceFilter: '',
    }
  }

  selectNetworkAssetResultAwaitingAnimation = createSelector(
    [get('networkAssetsResult'), (props, state) => state.opened],
    (networkAssetsResult, opened) => {
      if (opened) {
        return networkAssetsResult
      } else {
        return NETWORK_ASSETS_PENDING
      }
    }
  )

  onModalOpened = () => {
    this.setState({ opened: true })
  }

  onModalClosed = () => {
    this.setState({ opened: false })
  }

  onToggle = (...args) => {
    const { toggle } = this.props

    this.setState(this.initialState(this.props, false))

    if (toggle) {
      toggle(...args)
    }
  }

  selectFieldValue(fieldName) {
    return this.assignAssetFields.selectValue(this.state, fieldName)
  }

  createSelectCreateAndAssignFieldValueFor = (fieldName) => {
    return (props, state) => {
      return this.createAndAssignFields.selectValue(state, fieldName)
    }
  }

  selectAssetInFocus = createSelector(
    [
      (props, state) => state.createFromExisting,
      (props, state) => state.selectedNetworkAsset,
      this.createSelectCreateAndAssignFieldValueFor('assetType'),
      this.createSelectCreateAndAssignFieldValueFor('assetId'),
      this.createSelectCreateAndAssignFieldValueFor('coordinates'),
      this.createSelectCreateAndAssignFieldValueFor('coordinatesFormat'),
    ],
    (
      createFromExisting,
      selectedAsset,
      assetType,
      assetId,
      coordinates,
      coordinatesFormat
    ) => {
      if (createFromExisting) {
        return selectedAsset
      }

      const match = coordinates.match(COORDINATE_REGEX)
      const [latitude, longitude] = match
        ? convertToWGS84(
            coordinatesFormat,
            coordinates.split(',').map(parseFloat)
          )
        : []

      return match
        ? NetworkAsset.from({
            assetId,
            location: { latitude, longitude },
            assetType,
          })
        : null
    }
  )

  selectMapCenter = createSelector(
    [get('commission'), this.selectAssetInFocus],
    (commission, networkAsset) =>
      networkAsset
        ? L.latLng([
            networkAsset.location.latitude,
            networkAsset.location.longitude,
          ])
        : commission
        ? L.latLng([
            commission.location.latitude,
            commission.location.longitude,
          ])
        : undefined
  )

  selectMapZoom = createSelector(
    [get('commission'), this.selectAssetInFocus],
    (commission, asset) => (asset || commission ? 15 : undefined)
  )

  selectAssignableNetworkAssets = createSelector(
    [(a) => a],
    (networkAssets) => {
      return networkAssets.filter((networkAsset) => {
        return networkAsset.assignableChannelCount > 0
      })
    }
  )

  onChangeDatetime = (datetime) => {
    this.assignAssetFields.updateFieldValue('startTime', datetime.toDate())
  }

  onChangeNetworkAssetsQuery = (event) => {
    this.setState({ searchQuery: event.target.value })
  }

  onChangeAssetTypeFilter = (event) => {
    this.setState({ assetTypeFilter: event.target.value })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.commission !== this.props.commission) {
      this.setState(this.initialState(this.props))
    }

    if (
      this.state.searchQuery !== prevState.searchQuery ||
      this.state.assetTypeFilter !== prevState.assetTypeFilter
    ) {
      this.props.refetch({
        searchQuery: this.state.searchQuery,
        assetType: this.state.assetTypeFilter || undefined,
      })
    }
  }

  createOnClickNetworkAsset = (
    networkAsset,
    channel = networkAsset.availableChannels[0]
  ) => {
    return () => this.onChangeNetworkAsset(networkAsset, channel)
  }

  onChangeNetworkAsset = (networkAsset, channel) => {
    this.setState(
      {
        assignAssetFields: this.assignAssetFields.updateState(this.state, {
          channel,
          networkAsset,
        }),
        selectedNetworkAsset: networkAsset,
      },
      () => this.assignAssetFields.debounceValidations()
    )
  }

  setTabToExisting = () => {
    this.setState({
      result: AsyncResult.notFound(),
      createFromExisting: true,
    })
  }

  setTabToCreateFromNew = () => {
    this.setState({
      result: AsyncResult.notFound(),
      createFromExisting: false,
    })
  }

  isValidInstallationStartTime = (currentDate) => {
    const currentDateMoment = moment(currentDate)

    return (
      currentDateMoment.isSameOrAfter(this.props.commission.start) &&
      currentDateMoment.isSameOrBefore(moment())
    )
  }

  onSubmitForm = async (event) => {
    event.preventDefault()

    this.setState({ result: AsyncResult.pending() })

    const res = await this.assignAssetFields
      .performPreSubmitChecks()
      .catch(() => {
        return false
      })

    if (!res) {
      this.setState({ result: AsyncResult.notFound() })
    } else {
      const { commission } = this.props
      const formatDate = (date) =>
        moment(date).format('YYYY-MM-DD HH:mm:00.000000Z')

      // If there is an end time, submit to backend
      const end =
        commission && commission.end ? formatDate(commission.end) : null

      const start =
        commission && commission.end
          ? formatDate(commission.start)
          : formatDate(this.selectFieldValue('startTime'))

      const channel = this.selectFieldValue('channel')
      const networkAssetId = parseInt(
        this.selectFieldValue('networkAsset').id,
        10
      )

      try {
        await this.props.createInstallation({
          variables: {
            start,
            end,
            deviceId: commission.device.id,
            channelMap: {
              pressure_1: channel,
            },
            networkAssetId,
          },
        })

        this.setState({ result: AsyncResult.success() })
        this.onToggle()
        this.props.onSuccess()
      } catch (e) {
        analytics.logError(e)
        this.setState({ result: AsyncResult.fail(AppError.from(e)) })
      }
    }
  }

  onSubmitCreateAndAssign = async (event) => {
    event.preventDefault()

    this.setState({ result: AsyncResult.pending() })

    const res = await this.createAndAssignFields
      .performPreSubmitChecks()
      .catch(() => {
        return false
      })

    if (!res) {
      this.setState({ result: AsyncResult.notFound() })
    } else {
      try {
        const { createInstallation, createNetworkAsset } = this.props
        const { sourceFilter } = this.state

        const fields = this.createAndAssignFields
        const coordinates = fields.valueFor('coordinates')
        const coordinatesFormat = fields.valueFor('coordinatesFormat')
        const [latitude, longitude] = convertToWGS84(
          coordinatesFormat,
          coordinates.split(',').map(parseFloat)
        )
        const altitude = parseFloat(fields.valueFor('elevation') || '0')
        const groupIds = sourceFilter
          ? [MixedGroupDetailsType.parseIdFromDataSourceId(sourceFilter)]
          : []

        const response = await createNetworkAsset({
          variables: {
            groupIds,
            assetId: fields.valueFor('assetId') || null,
            comment: fields.valueFor('comment') || null,
            location: { altitude, latitude, longitude },
            assetName: fields.valueFor('assetName') || null,
            assetType: fields.valueFor('assetType') || null,
            customLabels: fields.valueFor('customLabels') || null,
            locationSource: fields.valueFor('locationSource') || null,
          },
        })

        const { commission } = this.props
        const channel = fields.valueFor('channel')
        const startTime = fields.valueFor('startTime')
        await createInstallation({
          variables: {
            start: moment(startTime).format('YYYY-MM-DD HH:mm:00.000000Z'),
            deviceId: commission.device.id,
            channelMap: { pressure_1: channel },
            networkAssetId: response.data.createNetworkAsset.id,
          },
        })

        this.setState({ result: AsyncResult.success() })
        this.onToggle()
        this.props.onSuccess()
      } catch (e) {
        analytics.logError(e)
        this.setState({ result: AsyncResult.fail(e) })
      }
    }
  }

  handleClickSelectGroup = (source) => {
    this.setState({ sourceFilter: source })
  }

  static CREATE_INSTALLATION = gql`
    mutation CreateInstallation(
      $networkAssetId: Int!
      $deviceId: Int!
      $start: String!
      $end: String
      $channelMap: ChannelMappingInput!
    ) {
      addInstallation(
        deviceId: $deviceId
        channelMap: $channelMap
        whenInstalled: $start
        whenRemoved: $end
        networkAssetId: $networkAssetId
      )
    }
  `

  static QUERY_DEVICE_INSTALLATIONS = gql`
    query DeviceWithInstallations($id: Int) {
      device(id: $id) {
        id
        installations {
          end
          start
          channelMap {
            pressure_1
          }
        }
      }
    }
  `

  static QUERY_ALL_NETWORK_ASSETS = gql`
    query NetworkAssets($searchQuery: String, $assetType: AssetType) {
      networkAssets(searchQuery: $searchQuery, assetType: $assetType) {
        id
        assetId
        location {
          latitude
          longitude
        }
        assetName
        assetType
        installations {
          start
          end
          channelMap {
            pressure_1
          }
          deviceId
          device {
            id
            currentCommission {
              start
              end
              location {
                altitude
                latitude
                longitude
              }
            }
            activeIssues {
              id
              type
              severity
              description
              deviceId
            }
          }
        }
      }
    }
  `

  static CREATE_NETWORK_ASSET_MUTATION = gql`
    mutation CreateNetworkAsset(
      $groupIds: [Int]
      $assetId: String!
      $comment: String
      $location: AssetLocationInput!
      $assetName: String
      $assetType: InflowNetAssetType!
      $customLabels: String
      $locationSource: LocationSourceType
    ) {
      createNetworkAsset(
        groupIds: $groupIds
        comment: $comment
        assetId: $assetId
        location: $location
        assetName: $assetName
        assetType: $assetType
        customLabels: $customLabels
        locationSource: $locationSource
      ) {
        id
      }
    }
  `
}

function stopPropagation(event) {
  event.stopPropagation()
}

export default compose(
  transformProps(() => () => ({
    selectNetworkAssetsResult: createSelectGraphQLResult('networkAssets', {
      mapResult: parseGraphQLResult,
      nullObject: [],
    }),
    selectExistingInstallations: createSelectGraphQLResult('device', {
      mapResult: (d) => parseGraphQLResult(d.installations),
      nullObject: [],
    }),
  })),
  graphql(AssignNetworkAssetToDeviceModal.CREATE_NETWORK_ASSET_MUTATION, {
    name: 'createNetworkAsset',
  }),
  graphql(AssignNetworkAssetToDeviceModal.CREATE_INSTALLATION, {
    name: 'createInstallation',
  }),
  graphql(AssignNetworkAssetToDeviceModal.QUERY_DEVICE_INSTALLATIONS, {
    skip: (props) => !props.commission,
    options: (props) => {
      return {
        variables: { id: props.commission.device.id },
        fetchPolicy: 'no-cache',
      }
    },
    props: ({ data, ownProps: { selectExistingInstallations } }) => {
      return {
        refetchInstallations: data.refetch,
        existingInstallationsResult: selectExistingInstallations(data),
      }
    },
  }),
  graphql(AssignNetworkAssetToDeviceModal.QUERY_ALL_NETWORK_ASSETS, {
    options: () => {
      return {
        variables: {},
        fetchPolicy: 'no-cache',
      }
    },
    props: ({ data, ownProps: { selectNetworkAssetsResult } }) => {
      return {
        refetch: data.refetch,
        networkAssetsResult: selectNetworkAssetsResult(data),
      }
    },
  }),
  withTranslation([
    'src/management_path/devices_path/assign_network_asset_to_device_modal',
    'common/text',
  ])
)(AssignNetworkAssetToDeviceModal)
