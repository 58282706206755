import { useMemo } from 'react'
import gql from 'graphql-tag'
import { createSelectGraphQLResult } from '../../utils/index'
import { parseGraphQLResult } from '../../api/presenters/index'
import { useQuery } from '@apollo/client'

const UNHEALTHY_NETWORK_ASSETS_PER_PAGE = 5
const UNHEALTHY_NETWORK_ASSETS_PAGE_NUMBER = 1

export function useGetPagedUnhealthyAssets() {
  const UNHEALTHY_NETWORK_ASSETS_QUERY = gql`
    query PagedUnhealthyNetworkAssetsQuery(
      $pageNumber: Int!,
      $resultsPerPage: Int!
    ) {
      pagedNetworkAssets(
        pageNumber: $pageNumber,
        resultsPerPage: $resultsPerPage,
        orderBy: "activeIssueTypeSeverity",
        orderDirection: "desc"
      ) {
        networkAssets {
          id
          assetId
          assetType
          assetName
          installations {
            start
            end
            device {
              id
              serialNumber
              lastCommunication
              currentCommission {
                end
                start
              }
              activeIssues {
                deviceId
                severity
              }
            }
          }
        }
        pagination {
          pageNumber
          totalPages
          totalResults
        }
      }
    }
  `

  const unhealthyNetworkAssetsQueryResult = useQuery(UNHEALTHY_NETWORK_ASSETS_QUERY, {
    variables: {
      pageNumber: UNHEALTHY_NETWORK_ASSETS_PAGE_NUMBER,
      resultsPerPage: UNHEALTHY_NETWORK_ASSETS_PER_PAGE,
    },
    fetchPolicy: 'network-only',
  })

  const unhealthyPagedAssets = useMemo(() =>
    createSelectGraphQLResult('data', {
      mapResult: (result) => {
        const parsedResult = parseGraphQLResult(result.pagedNetworkAssets)
        return parsedResult.data.filter(asset => {
          return asset.installations.some(installation => {
            return installation.device &&
            Array.isArray(installation.device.activeIssues) &&
              installation.device.activeIssues.length
          })
        })
      },
      nullObject: [],
    })(unhealthyNetworkAssetsQueryResult)
  , [unhealthyNetworkAssetsQueryResult])

  return {
    unhealthyPagedAssets,
    assetCount: UNHEALTHY_NETWORK_ASSETS_PER_PAGE,
  }
}
