import gql from 'graphql-tag'

export const GET_NETWORK_ASSETS_WITH_INSTALLATIONS = gql`
  query GetNetworkAssetsListForInstallModal (
    $filters: GetNetworkAssetsListFilters
  ) {
    getNetworkAssetsList(
      filters: $filters
    ) {
      networkAssets {
        assetId
        type
        name
        readableName
        installedDevicesCount
        installedDevices {
          deviceId
          status
          serialNumber
          logicalChannel
          activeErrorCount
          activeWarningCount
          activeInformationCount
          lastRecordedDataTime
        }
        lat
        lng
        alt
      }
    }
  }
`
