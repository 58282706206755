import { useCallback, useEffect, useMemo, useState } from 'react'
import { parseGraphQLResult } from '../../api/presenters'
import { RAW_DATA_QUERY } from '../../analysis_path/pressure_analysis_path/pressure_subset_graph/graphql'
import { AsyncResult, MINUTES } from '../../utils'
import { INTERNAL_PRESSURE_UNIT } from '../../utils/unit_constants'

const DISPLAY_RAW_DATA_THRESHOLD = 5 * MINUTES

const useGetRawData = (props) => {
  const [rawData, setRawData] = useState([])
  const [loading, setLoading] = useState(0)
  const [_totalParts, setTotalParts] = useState(0)
  const [error, setError] = useState('')

  const rawDataRequestsResult = useMemo(() => {
    if (loading !== 0) {
      return AsyncResult.pending(rawData)
    }

    return AsyncResult.success(rawData)
  }, [rawData])

  const formatResults = useCallback((result) => {
    const pagedRawResults = parseGraphQLResult(result.data.pagedRawData)

    if (!pagedRawResults) {
      return
    }

    const rawResults = pagedRawResults.requests.reduce((acc, rawResult) => {
      const containsDuplicate = acc.some((item) => {
        return item.dataEnd === rawResult.dataEnd &&
          item.dataStart === rawResult.dataStart &&
          item.deviceId === rawResult.deviceId &&
          item.networkAssetId === rawResult.networkAssetId &&
          item.units === rawResult.units
      })

      if (containsDuplicate) {
        return acc
      }

      acc.push(rawResult.withUnits(props.units ? props.units.pressure : INTERNAL_PRESSURE_UNIT))

      return acc
    }, [])

    setRawData(prevValue => prevValue.concat(rawResults))
  }, [setRawData, props.units])

  const fetchData = useCallback(
    async (
      client,
      start,
      end,
      networkAssetChannels,
      signal,
      pageNumber = 1,
      numberOfPages = 1,
    ) => {
      const queryList = []
      const range = end - start
      for (let i = pageNumber; i <= numberOfPages; i++) {
        const query = client.query(
          {
            query: RAW_DATA_QUERY,
            variables: {
              start: new Date(start).toISOString(),
              end: new Date(end).toISOString(),
              skip: range > DISPLAY_RAW_DATA_THRESHOLD,
              networkAssetChannels,
              pageNumber: i,
            },
          }
        )

        queryList.push(query)
      }

      setLoading((oldLoading) => oldLoading + queryList.length)
      setTotalParts((oldTotalParts) => oldTotalParts + queryList.length)

      let results = []

      try {
        results = await Promise.all(queryList)
      } catch (err) {
        setError(err)
      }

      results.forEach(result => formatResults(result))
      setLoading((loadingOld) => loadingOld - queryList.length)
      if (pageNumber === 1 && results[0].data.pagedRawData && results[0].data.pagedRawData.pagination.totalPages > 1) {
        fetchData(
          client,
          start,
          end,
          networkAssetChannels,
          signal,
          2,
          results[0].data.pagedRawData.pagination.totalPages,
        )
      }
    }, [formatResults])

  const fetchPartialData = useCallback(() => {
    const { start, end, client, networkAssetChannels, signal } = props
    fetchData(
      client,
      start,
      end,
      networkAssetChannels,
      signal,
    )
  }, [props.start, props.end, props.client, props.networkAssetChannels, props.signal])

  useEffect(() => {
    if (Object.keys(props).length) {
      fetchPartialData()
      return () => {
        setLoading(0)
        setTotalParts(0)
        setRawData([])
      }
    }
    return () => { }
  }, [props])

  return { rawDataRequestsResult, error, loading: loading !== 0 }
}

export default useGetRawData
