export default class TimeSeriesRawDataListItems {
  constructor({ time, ...rest }) {
    Object.assign(this, rest, {
      time: time instanceof Date ? time : new Date(Number(time)),
    })
    Object.freeze(this)
  }

  get mean() {
    return this.value
  }

  get min() {
    return this.value
  }

  get max() {
    return this.value
  }

  static from(...data) {
    return new TimeSeriesRawDataListItems(...data)
  }
}
