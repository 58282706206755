import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash/fp/object'
import { mapValues } from 'lodash'
import { createSelector } from 'reselect'
import { withTranslation } from 'react-i18next'
import {
  Form,
  FormGroup,
  Button,
  Alert,
  Input,
  Row,
  Col,
} from 'reactstrap'

import * as analytics from '@@src/analytics'
import AlertSettingsCascade from
'@@src/components/alerts/alert_settings_cascade'
import ErrorInfo from '@@src/components/error_info'
import AlertSetting from '@@src/components/alerts/alert_setting'
import SubmitButton from '@@src/components/buttons/submit_button'
import AsyncResultSwitch from '@@src/components/async_result_switch'
import TenantLicenceFeatureGate from
'@@src/components/tenant_licence/tenant_licence_feature_gate'
import AlertThresholdSettingsInheritance
from '@@src/components/alerts/alert_settings_inheritance'
import {
  FormFields,
  AsyncResult,
  AppError,
} from '@@src/utils'

const noop = () => {}

const INHERIT_SETTINGS = 'inherit'
const CUSTOM_SETTINGS = 'custom'
const DEFAULT_SETTINGS = 'default'
const INHERIT_FROM_TENANT = 'tenant'
const INHERIT_FROM_GROUP = 'group'

export const fieldsDataPropTypeShape = PropTypes.arrayOf(
  PropTypes.shape({
    fieldBool: PropTypes.string.isRequired,
    fieldValue: PropTypes.string,
    min: PropTypes.number,
    max: PropTypes.number,
    name: PropTypes.string.isRequired,
    fieldLegend: PropTypes.string.isRequired,
    boundString: PropTypes.string,
    unit: PropTypes.string,
    featureGate: PropTypes.string,
  })
)

/**
 * Alert Settings Form Props
 * @param {Object} alertThresholds - Current alert thresholds
 * @param {Object} tenantAlertThresholds - Tenant (Default) alert thresholds
 * @param {Function} onSubmit - GraphQL mutation function to edit alert thresholds
 * @param {Function} onSubmitSuccess - refetch function called on submission success
 * @param {Function} onReset - GraphQL refetch function from parent to fetch values from db
 * @param {Function} changeInheritance - Function used to change inherited threshold values, will be fed with target inheritance group ID as a variable
 * @param {Array} fieldsData - Data used to construct form fields, note that fieldBool and fieldValue should be the same as their corresponding GraphQL result key names, as these values are used as variables in GraphQL mutations.
 * @param {Boolean} refreshOnSubmit - If specified, onSubmitSuccess will be triggered and the values will be refreshed.
 * @param {Array} ancestors - Inheritable configuration ids in flat array, originally designed around values returned from groups -> ancestry field. This should be able to be assembled to valid tree up to the tenant, which should have a parentId of null. It is assembled by this component into a hierarchical tree.
 *
 * The featureGate optional object key will create a feature gate around form fields if present.
 **/

const calculateInheritanceType = (inputThresholds) => {
  switch (inputThresholds.inheritedFrom) {
    case INHERIT_FROM_TENANT:
      return DEFAULT_SETTINGS
    case INHERIT_FROM_GROUP:
      return INHERIT_SETTINGS
    default:
      return CUSTOM_SETTINGS
  }
}

class AlertThresholdSettingsForm extends React.PureComponent {

  static propTypes = {
    alertThresholds: PropTypes.object.isRequired,
    tenantAlertThresholds: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
    onReset: PropTypes.func.isRequired,
    onSubmitSuccess: PropTypes.func,
    refreshOnSubmit: PropTypes.bool,
    ancestors: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        parentId: PropTypes.number,
      })
    ).isRequired,
    fieldsData: fieldsDataPropTypeShape,
    fetchAncestorAlertThresholds: PropTypes.func,
    ancestorAlertThresholdsResult: PropTypes.instanceOf(AsyncResult),
  }

  static defaultProps = {
    onReset: noop,
    onSubmit: noop,
    ancestors: [],
    onSubmitSuccess: noop,
    ancestorAlertThresholdsResult: AsyncResult.notFound(),
  }

  constructor(props) {
    super(props)

    const extractFormKeys = () => {
      const constructedArray = []
      props.fieldsData.forEach(data => {
        // There will always be a fieldBool
        constructedArray.push(data.fieldBool)
        // There may not always be a field value
        if (data.fieldValue) {
          constructedArray.push(data.fieldValue)
        }
      })

      return constructedArray
    }

    this.fields = new FormFields(this, 'alertThresholdsFields',
      Object.assign(
        {},
        ...extractFormKeys().map(
          objKey => ({ [objKey]: v => v ? '' : 'errors.required' })
        )
      )
    )

    this.state = {
      result: AsyncResult.notFound(),
      formKeys: extractFormKeys(),
      alertThresholdsFields: this.alertThresholdsFieldsInitialState({}, props),
      settingsCascadeOpen: false,
    }

    this.form = React.createRef()
  }

  alertThresholdsFieldsInitialState(overrides = {}, props = this.props) {
    return this.fields.initialState({
      ...props.alertThresholds,
      alertInheritanceType: calculateInheritanceType(props.alertThresholds),
      ...overrides,
    })
  }

  selectFieldValue(fieldName) {
    return this.fields.selectValue(this.state, fieldName)
  }

  selectAncestorTree = createSelector(
    [get('ancestors')],
    ancestors => ancestors.filter(ancestor => !ancestor.parentId)
      .map(ancestor => mapGroupWithChildren(ancestor, ancestors))
  )

  onChangeInheritanceType = async event => {
    const { onReset } = this.props
    let parentId = null
    const alertInheritanceType = event.target.value

    // Auto-fetch inheritable values if needed
    // Ensures that first index of inheritance tree is selected when 'inherit_from_group' is enabled
    if (alertInheritanceType === INHERIT_SETTINGS) {
      const defaultParentId = this.selectAncestorTree(this.props)[0].id
      await this.props.fetchAncestorAlertThresholds(
        { id: defaultParentId, skip: false }
      )
      parentId = defaultParentId
    } else if (alertInheritanceType === CUSTOM_SETTINGS) {
      await onReset()
    }

    await this.fields.batchUpdateFieldValues({
      ...this.props.alertThresholds,
      parentId,
      alertInheritanceType,
    })
  }

  onSubmitForm = async event => {
    const { onSubmitSuccess, descendants } = this.props
    event.preventDefault()

    await this.setState({ result: AsyncResult.pending() })
    try {
      const alertInheritanceType = this.selectFieldValue('alertInheritanceType')
      const parentId = alertInheritanceType === INHERIT_SETTINGS ?
        this.selectFieldValue('parentId') : null
      const inheritFrom =
        alertInheritanceType === DEFAULT_SETTINGS ? INHERIT_FROM_TENANT :
        alertInheritanceType === INHERIT_SETTINGS ? INHERIT_FROM_GROUP :
        null

      // Throw custom error if device no comms is not whole number
      if (this.selectFieldValue('deviceNoComms') % 1 !== 0) {
        throw new AppError('Device No Communication must be whole hour', {
          key: 'common/errors:alert_thresholds.no_comms_resolution',
        })
      }
      // Assemble form for submission
      const assembledFormObject = Object.assign(
        {},
        ...this.state.formKeys.map(val =>
          ({ [val]: this.selectFieldValue(val) }))
      )

      // Convert strings to booleans, reverse of conversion in presenter
      const formattedFormObject = mapValues(assembledFormObject, (v) => {
        switch (v) {
          case true:
          case 'true':
            return true

          case false:
          case 'false':
            return false

          default:
            return Number(v)
        }
      })

      // check for overlapping form values
      const {
        dailyCpisHigh,
        dailyCpisHighEnabled,
        dailyCpisLow,
        dailyCpisLowEnabled,
        pressureHigh,
        pressureHighEnabled,
        pressureLow,
        pressureLowEnabled,
      } = formattedFormObject

      // Check that if both high and low thresholds are enabled they do not overlap
      if (
        dailyCpisHigh < dailyCpisLow
        && dailyCpisHighEnabled
        && dailyCpisLowEnabled ||

        pressureHigh < pressureLow
        && pressureHighEnabled
        && pressureLowEnabled
      ) {

        throw new AppError('Overlapping high and low values', {
          key: 'common/errors:alert_thresholds.overlap',
        })
      }

      switch (inheritFrom) {
        case INHERIT_FROM_TENANT:
          await this.props.onSubmit({ variables: { inheritFrom } })
          break

        case INHERIT_FROM_GROUP:
          await this.props.onSubmit({ variables: { parentId, inheritFrom } })
          break

        default:
          await this.props.onSubmit({ variables: formattedFormObject })
          break
      }

      await this.setState({
        result: AsyncResult.success(),
      })
      if (this.props.refreshOnSubmit) {
        await onSubmitSuccess()
      }
      // Open secondary modal to cascade updated alert settings
      if (descendants && this.checkInheritance(descendants).length !== 0) {
        this.handleCascadeToggle()
      }
      // Set initial field state on submission to disable discard changes button
      const vals = this.state.alertThresholdsFields.values
      await this.setState({
        alertThresholdsFields: this.alertThresholdsFieldsInitialState({
          ...vals,
        }),
      })
    } catch (e) {
      analytics.logError(e)
      await this.setState({ result: AsyncResult.fail(e) })
    }
  }

  onResetForm = async event => {
    const { onReset } = this.props
    event.preventDefault()
    await this.setState({
      alertThresholdsFields: this.alertThresholdsFieldsInitialState(),
    })
    await onReset()
  }

  setInheritance = async input => {
    await this.fields.updateFieldValue('parentId', input)
    await this.props.fetchAncestorAlertThresholds({ id: input, skip: false })
  }

  checkInheritance = descendants => {
    const { groupId } = this.props
    const processedDescendants = descendants.filter(descendant => (
      descendant.alertThresholds.parentId !== groupId
    ))
    return processedDescendants
  }

  selectAlertSettingsPropsResult = createSelector(
    [
      get('fieldsData'),
      (_props, _state, comp) => comp.selectFieldValue('alertInheritanceType'),
      get('ancestorAlertThresholdsResult'),
      get('alertThresholds'),
      get('tenantAlertThresholds'),
      (_props, _state, comp) => comp,
      // necessary to handle changes in field values
      (_props, _state, comp) => comp.fields.state.values,
    ], (fieldsData, alertInheritanceType, ancestorAlertThresholdsResult, alertThresholds, tenantAlertThresholds, comp) => { // eslint-disable-line max-len
      let inheritedThresholds = null
      const useCustom = alertInheritanceType === CUSTOM_SETTINGS

      switch (alertInheritanceType) {
        case DEFAULT_SETTINGS:
          if (!alertThresholds) {
            return new AsyncResult([null])
          }
          inheritedThresholds = tenantAlertThresholds
          break
        case INHERIT_SETTINGS:
          if (!ancestorAlertThresholdsResult.wasSuccessful()) {
            return ancestorAlertThresholdsResult.map(() => null)
          }
          inheritedThresholds = ancestorAlertThresholdsResult.data
          break
      }

      return AsyncResult.success(fieldsData.map((data, i) => {
        const {
          fieldValue,
          fieldBool,
          boundString,
          unit,
          fieldLegend,
          fieldInfo,
          min,
          max,
          name,
          featureGate,
        } = data

        return {
          key: `alertSettingsForm-${i}`,
          fieldValue: fieldValue ? (
            useCustom ? comp.selectFieldValue(fieldValue) :
            inheritedThresholds[fieldValue]
          ) : null,
          fieldValueOnChange: fieldValue && useCustom ? (
            comp.fields.onChangeHandlerFor(fieldValue)
          ) : null,
          fieldBool:
            useCustom ? comp.selectFieldValue(fieldBool) :
            inheritedThresholds[fieldBool],
          fieldBoolOnChange:
            useCustom ? comp.fields.onChangeHandlerFor(fieldBool) : null,
          fieldInfo,
          fieldLegend,
          unit,
          min,
          max,
          boundString,
          name,
          disabled: !useCustom,
          featureGate,
        }
      }))
    }
  )

  componentDidUpdate = prevProps => {
    if (prevProps.alertThresholds !== this.props.alertThresholds) {
      this.setState({
        alertThresholdsFields: this.alertThresholdsFieldsInitialState(),
      })
    }
  }

  render() {
    const {
      t,
      ancestors,
      customOnly,
      descendants,
      groupId,
    } = this.props
    const { result, settingsCascadeOpen } = this.state

    const alertInheritanceType = this.selectFieldValue('alertInheritanceType')

    return (
      <React.Fragment>

        {
          result.wasSuccessful() ? (
            <Alert color="success">
              {t('text.update_successful')}
            </Alert>
          ) : null
        }
        {result.wasFailure() ? <ErrorInfo error={result.error}/> : null}

        <Form name="alert-settings" className="pb-3">
          {
            !customOnly ? (
              <React.Fragment>
                <legend className="h6">
                  {t('text.configuration')}
                </legend>

                <Row>
                  <Col sm="4">
                    <Input
                      type="select"
                      name="alert-inheritance-select"
                      value={alertInheritanceType}
                      onChange={this.onChangeInheritanceType}
                      className="mb-4">
                      <option value={DEFAULT_SETTINGS} name="default-settings">
                        {t('form.default_settings')}
                      </option>
                      {
                        ancestors && ancestors.length !== 0
                          ? (
                            <option
                              value={INHERIT_SETTINGS}
                              name="inherit-settings"
                            >
                              {t('form.inherit_from_group')}
                            </option>
                          ) : (
                            null
                          )
                      }
                      <option value={CUSTOM_SETTINGS} name="custom-settings">
                        {t('form.custom')}
                      </option>
                    </Input>
                  </Col>
                </Row>
              </React.Fragment>
            ) : (
              null
            )
          }

          {
            alertInheritanceType === INHERIT_SETTINGS ? (
              <FormGroup tag="fieldset" className="mb-4">
                <AlertThresholdSettingsInheritance
                  tree={this.selectAncestorTree(this.props)}
                  setInheritance={this.setInheritance}
                  activeInheritance={this.selectFieldValue('parentId')}
                />
              </FormGroup>
            ) : (
              null
            )
          }

          <AsyncResultSwitch
            result={this.selectAlertSettingsPropsResult(
              this.props, this.state, this,
            )}
            renderSuccessResult={this.renderAlertThresholdSettings}/>

          {
            descendants ? (
              <AlertSettingsCascade
                descendants={descendants.filter(descendant => (
                  descendant.alertThresholds.parentId !== groupId
                ))}
                isOpen={settingsCascadeOpen}
                handleToggle={this.handleCascadeToggle}
                groupId={groupId}
                targetAggregate={{
                  aggregateId: groupId,
                  aggregateType: 'VGroup',
                }}
              />
            ) : (
              null
            )
          }

          <div className="mt-5">
            <SubmitButton
              name="alert-settings-submit"
              color="primary"
              submitText={t('buttons.save')}
              result={result}
              onSubmitForm={this.onSubmitForm}
            />

            <Button
              name="alert-settings-reset"
              color="secondary"
              onClick={this.onResetForm}
              disabled={this.fields.isPristine()}
              className="ml-2"
            >
              { t('buttons.discard_changes') }
            </Button>
          </div>
        </Form>
      </React.Fragment>
    )
  }

  handleCascadeToggle = () => {
    this.setState({
      settingsCascadeOpen: !this.state.settingsCascadeOpen,
    })
  }

  renderAlertThresholdSettings = ({ data: alertSettingsProps }) => {
    return alertSettingsProps.map(({ featureGate, ...props }) => {
      return (
        featureGate ? (
          <TenantLicenceFeatureGate
            key={props.key}
            requiredTenantLicenceFeature={featureGate}>
            <AlertSetting {...props}/>
          </TenantLicenceFeatureGate>
        ) : (
          <AlertSetting {...props}/>
        )
      )
    })
  }
}

function mapGroupWithChildren(parent, ancestors, depth = 0) {
  // fail
  if (depth > 1000) {
    analytics.logError(`Exceeded depth limit for group with ID "${parent.id}"`)
    return { ...parent, children: [] }
  }

  const children = ancestors.filter(ancestor => ancestor.parentId === parent.id)
    .map(child => mapGroupWithChildren(child, ancestors, depth + 1))

  return {
    id: parent.id,
    name: parent.name,
    children,
    parentId: parent.parentId,
  }
}

export default withTranslation([
  'src/components/alerts/alert_settings_form',
])(AlertThresholdSettingsForm)
