import React from 'react'
import ReactDOM from 'react-dom'
import { addMissingUnits } from '@@src/utils/app_unit_conversion'

import './vendor_setup'
import './global.css'
import AppRoot from './app_root'

addMissingUnits()

const render = () => {
  ReactDOM.render(<AppRoot />, document.getElementById('container'))
}

render()

if (module.hot) {
  module.hot.accept('./app_root', render)
}
