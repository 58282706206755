import React from 'react'
import { createSelector } from 'reselect'
import { get } from 'lodash/fp/object'
import { round } from 'mathjs'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import { graphql } from '@apollo/client/react/hoc'
import gql from 'graphql-tag'
import { connect } from 'react-redux'

import { EVENT_LOCALISATION_FEATURE } from
'@@src/components/tenant_licence/tenant_licence_feature_gate'
import {
  convertUnitFromDB,
  convertUnitToDB,
  PRESSURE_TYPE,
} from '@@src/utils/app_unit_conversion'
import { INTERNAL_PRESSURE_UNIT } from '@@src/utils/unit_constants'
import { parseGraphQLResult } from '@@src/api/presenters'
import { createSelectGraphQLResult, AsyncResult } from '@@src/utils'

const PRESSURE_HIGH_NAME = 'pressureHigh'
const PRESSURE_LOW_NAME = 'pressureLow'
const EVENT_SEVERITY_NAME = 'eventSeverity'

const PRESSURE_LOW_MIN_INTERNAL = 0
const SEVERITY_MIN_INTERNAL = 5
const PRESSURE_AND_SEVERITY_MAX_INTERNAL = 250

export default function withConvertedAlertThresholds(Component) {
  class AlertThresholdsProvider extends React.PureComponent {
    static propTypes = {
      t: PropTypes.func.isRequired,
      fetchAncestorAlertThresholds: PropTypes.func.isRequired,
      ancestorAlertInternalThresholdsResult:
        PropTypes.instanceOf(AsyncResult).isRequired,
      units: PropTypes.object.isRequired,
      alertThresholds: PropTypes.object.isRequired,
      tenantAlertThresholds: PropTypes.object.isRequired,
    }

    render() {
      return (
        <Component
          {...this.props}
          alertThresholds={this.selectAlertThresholds(this.props)}
          tenantAlertThresholds={this.selectTenantAlertThresholds(this.props)}
          fieldsData={this.selectFieldsData(this.props)}
          submitConvertedAlertThresholds={this.submitConvertedAlertThresholds}
          ancestorAlertThresholdsResult={
            this.selectAncestorAlertInternalThresholdsResult(this.props)
          }
        />
      )
    }

    submitConvertedAlertThresholds = (submitter, variables) => {
      const { units: { pressure = INTERNAL_PRESSURE_UNIT } } = this.props
      const {
        [PRESSURE_HIGH_NAME]: pressureHigh,
        [PRESSURE_LOW_NAME]: pressureLow,
        [EVENT_SEVERITY_NAME]: eventSeverity,
        ...rest
      } = variables
      const convertedVariables = { ...rest }

      if (Number.isFinite(pressureHigh)) {
        convertedVariables[PRESSURE_HIGH_NAME] =
          convertUnitToDB(pressureHigh, pressure, PRESSURE_TYPE)
      }

      if (Number.isFinite(pressureLow)) {
        convertedVariables[PRESSURE_LOW_NAME] =
          convertUnitToDB(pressureLow, pressure, PRESSURE_TYPE)
      }

      if (Number.isFinite(eventSeverity)) {
        convertedVariables[EVENT_SEVERITY_NAME] =
          convertUnitToDB(eventSeverity, pressure, PRESSURE_TYPE)
      }

      return submitter({ variables: convertedVariables })
    }

    convertPressureUnitsFromDb = (
      values = {},
      pressureUnit,
      minMaxThresholds
    ) => {
      const roundUnitFromDb = value => round(
        convertUnitFromDB(value, pressureUnit, PRESSURE_TYPE, 0),
        2
      )
      const {
        eventSeverity,
        pressureHigh,
        pressureLow,
        ...rest
      } = values
      const {
        severityMin,
        pressureMin,
        pressureAndSeverityMax,
      } = minMaxThresholds

      return {
        [EVENT_SEVERITY_NAME]: Math.max(
          severityMin,
          Math.min(pressureAndSeverityMax, roundUnitFromDb(eventSeverity))
        ),
        [PRESSURE_HIGH_NAME]: Math.max(
          pressureMin,
          Math.min(pressureAndSeverityMax, roundUnitFromDb(pressureHigh))
        ),
        [PRESSURE_LOW_NAME]: Math.max(
          pressureMin,
          Math.min(pressureAndSeverityMax, roundUnitFromDb(pressureLow))
        ),
        ...rest,
      }
    }

    selectConvertedMinMax = createSelector([get('units')], units => {
      const { pressure = INTERNAL_PRESSURE_UNIT } = units
      const rounder = value => Math.round(value / 10) * 10
      const pressureMin = rounder(
        Number(convertUnitFromDB(
          PRESSURE_LOW_MIN_INTERNAL,
          pressure,
          PRESSURE_TYPE,
          0
        ))
      )
      const severityMin = rounder(
        Number(convertUnitFromDB(
          SEVERITY_MIN_INTERNAL,
          pressure,
          PRESSURE_TYPE,
          0
        ))
      )
      const pressureAndSeverityMax = rounder(
        Number(convertUnitFromDB(
          PRESSURE_AND_SEVERITY_MAX_INTERNAL,
          pressure,
          PRESSURE_TYPE,
          0
        ))
      )

      return {
        pressureMin,
        severityMin,
        pressureAndSeverityMax,
      }
    })

    selectAlertThresholds = createSelector(
      [
        this.selectConvertedMinMax,
        get('units'),
        get('alertThresholds'),
      ],
      (convertedMinMax, units, alertThresholds) => {
        const { pressure = INTERNAL_PRESSURE_UNIT } = units

        return this.convertPressureUnitsFromDb(
          alertThresholds,
          pressure,
          convertedMinMax
        )
      }
    )

    selectTenantAlertThresholds = createSelector(
      [
        this.selectConvertedMinMax,
        get('units'),
        get('tenantAlertThresholds'),
      ],
      (convertedMinMax, units, tenantAlertThresholds) => {
        const { pressure = INTERNAL_PRESSURE_UNIT } = units

        return this.convertPressureUnitsFromDb(
          tenantAlertThresholds,
          pressure,
          convertedMinMax
        )
      }
    )

    selectAncestorAlertInternalThresholdsResult = createSelector(
      [
        this.selectConvertedMinMax,
        get('units'),
        get('ancestorAlertInternalThresholdsResult'),
      ],
      (convertedMinMax, units, ancestorAlertInternalThresholdsResult) => {
        if (ancestorAlertInternalThresholdsResult.wasSuccessful()) {
          const { pressure = INTERNAL_PRESSURE_UNIT } = units
          const newResult = ancestorAlertInternalThresholdsResult.withData(
            this.convertPressureUnitsFromDb(
                ancestorAlertInternalThresholdsResult.data,
                pressure,
                convertedMinMax
            ),
          )

          return newResult
        }

        return ancestorAlertInternalThresholdsResult
      }
    )

    selectFieldsData = createSelector(
      [this.selectConvertedMinMax, get('t'), get('units')],
      (convertedMinMax, t, units) => {
        const { pressure = INTERNAL_PRESSURE_UNIT } = units
        const {
          pressureMin,
          severityMin,
          pressureAndSeverityMax,
        } = convertedMinMax

        return [
          {
            fieldValue: PRESSURE_HIGH_NAME,
            fieldBool: 'pressureHighEnabled',
            boundString: t('common/forms:alerts.label.pressure_is_above'),
            unit: t(`common/text:units.${pressure}`),
            fieldLegend:
              t('common/forms:alerts.label.pressure_above_threshold'),
            fieldInfo: t('common/forms:alerts.information.pressure_above_info'),
            min: pressureMin,
            max: pressureAndSeverityMax,
            name: PRESSURE_HIGH_NAME,
          },
          {
            fieldValue: PRESSURE_LOW_NAME,
            fieldBool: 'pressureLowEnabled',
            boundString: t('common/forms:alerts.label.pressure_is_below'),
            unit: t(`common/text:units.${pressure}`),
            fieldLegend:
              t('common/forms:alerts.label.pressure_below_threshold'),
            fieldInfo: t('common/forms:alerts.information.pressure_below_info'),
            min: pressureMin,
            max: pressureAndSeverityMax,
            name: PRESSURE_LOW_NAME,
          },
          {
            fieldValue: 'dailyCpisHigh',
            fieldBool: 'dailyCpisHighEnabled',
            boundString: t('common/forms:alerts.label.cpis_is_above'),
            fieldLegend: t('common/forms:alerts.label.daily_cpis_score_high'),
            fieldInfo: t('common/forms:alerts.information.cpis_above_info'),
            min: 0,
            max: 1000000,
            name: 'dailyCpisHigh',
          },
          {
            fieldValue: 'dailyCpisLow',
            fieldBool: 'dailyCpisLowEnabled',
            boundString: t('common/forms:alerts.label.cpis_is_below'),
            fieldLegend: t('common/forms:alerts.label.daily_cpis_score_low'),
            fieldInfo: t('common/forms:alerts.information.cpis_below_info'),
            min: 0,
            max: 1000000,
            name: 'dailyCpisLow',
          },
          {
            fieldValue: 'eventCpis',
            fieldBool: 'eventCpisEnabled',
            boundString: t('common/forms:alerts.label.event_cpis_is_above'),
            fieldLegend:
              t('common/forms:alerts.label.pressure_event_cpis_score'),
            fieldInfo: t('common/forms:alerts.information.cpis_event_info'),
            min: 0,
            max: 1000000,
            name: 'eventCpis',
          },
          {
            fieldValue: EVENT_SEVERITY_NAME,
            fieldBool: 'eventSeverityEnabled',
            boundString: t('common/forms:alerts.label.pressure_is_above'),
            unit: t(`common/text:units.${pressure}`),
            fieldLegend: t('common/forms:alerts.label.pressure_event_severity'),
            fieldInfo: t('common/forms:alerts.information.event_severity_info'),
            min: severityMin,
            max: pressureAndSeverityMax,
            name: EVENT_SEVERITY_NAME,
          },
          {
            fieldBool: 'eventSourceLocalisationEnabled',
            fieldLegend:
              t('common/forms:alerts.label.event_source_localisation'),
            fieldInfo:
              t('common/forms:alerts.information.event_source_localisation_info'), // eslint-disable-line max-len
            name: 'eventSourceLocalisation',
            featureGate: EVENT_LOCALISATION_FEATURE,
          },
          {
            fieldBool: 'batteryDepletingEnabled',
            fieldLegend:
              t('common/forms:alerts.label.battery_depleting_enabled'),
            fieldInfo:
              t('common/forms:alerts.information.battery_depleting_info'),
            name: 'batteryDepletingEnabled',
          },
          {
            fieldValue: 'deviceNoComms',
            fieldBool: 'deviceNoCommsEnabled',
            fieldLegend: t('common/forms:alerts.label.data_not_received'),
            fieldInfo:
              t('common/forms:alerts.information.no_communication_info'),
            unit: t('common/text:units.hour_plural'),
            boundString: t('common/forms:alerts.label.when_no_communication'),
            min: 24,
            max: 72,
            name: 'deviceNoComms',
          },
        ]
      })

    static GROUP_ANCESTOR_ALERT_THRESHOLDS_QUERY = gql`
      query GroupAncestorAlertsConfig($id: Int!, $skip: Boolean!) {
        group(id: $id) @skip(if: $skip) {
          id
          alertThresholds {
            inheritedFrom
            parentId

            dailyCpisHigh,
            dailyCpisHighEnabled,

            dailyCpisLow,
            dailyCpisLowEnabled,

            eventCpis,
            eventCpisEnabled,

            eventSeverity,
            eventSeverityEnabled,

            pressureHigh,
            pressureHighEnabled,

            pressureLow,
            pressureLowEnabled,

            deviceNoComms,
            deviceNoCommsEnabled,

            batteryDepletingEnabled,
            eventSourceLocalisationEnabled,
          }
        }
      }
    `
  }

  function createMapStateToProps() {
    return function mapStateToProps() {
      return {
        selectGroupAncestorAlertThresholdsResult:
          createSelectGraphQLResult('group', {
            mapResult: a => parseGraphQLResult(a).alertThresholds,
          }),
      }
    }
  }

  return compose(
    withTranslation(['common/forms']),
    connect(createMapStateToProps),
    graphql(AlertThresholdsProvider.GROUP_ANCESTOR_ALERT_THRESHOLDS_QUERY, {
      options: ({ alertThresholds }) => ({
        fetchPolicy: 'network-only',
        variables: {
          // 1 is a 'dummy' int, is only supplied to pass required test on group endpoint as check on required happens before skip directive is executed
          id: alertThresholds.parentId || 1,
          skip: !alertThresholds.parentId,
        },
      }),
      props: ({ data, ownProps }) => {
        const { selectGroupAncestorAlertThresholdsResult } = ownProps

        return {
          fetchAncestorAlertThresholds: data.refetch,
          ancestorAlertInternalThresholdsResult:
            selectGroupAncestorAlertThresholdsResult(data),
        }
      },
    })
  )(AlertThresholdsProvider)
}
