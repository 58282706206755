import PropTypes from 'prop-types'
import React from 'react'
import { withTranslation } from 'react-i18next'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  InputGroup,
} from 'reactstrap'

import AppFormGroup from '@@src/components/forms/app_form_group'
import { SUPPORTED_COORDS_FORMAT } from '@@src/utils/conversions/coordinate_conversions'

class CoordinatesInputFormGroup extends React.Component {
  static propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    errorText: PropTypes.string,
    coordinatesValue: PropTypes.string.isRequired,
    onChangeCoordinates: PropTypes.func.isRequired,
    coordinatesFormatValue: PropTypes.oneOf(SUPPORTED_COORDS_FORMAT).isRequired,
    onChangeCoordinatesFormat: PropTypes.func.isRequired,
  }

  render() {
    const {
      id,
      name,
      label,
      coordinatesValue,
      onChangeCoordinates,
      required,
      errorText,
      disabled,
    } = this.props

    return (
      <AppFormGroup
        id={id}
        name={name}
        type="text"
        label={label}
        value={coordinatesValue}
        disabled={disabled}
        onChange={onChangeCoordinates}
        required={required}
        errorText={errorText}
        renderInput={this.renderCoordinatesInput}
      />
    )
  }

  renderCoordinatesInput = (input) => {
    const { t, coordinatesFormatValue } = this.props
    const { isCoordinateDropdownOpen } = this.state

    return (
      <InputGroup>
        <Dropdown
          addonType="prepend"
          isOpen={isCoordinateDropdownOpen}
          toggle={this.onToggleCoordinatesInput}
        >
          <DropdownToggle caret>
            {t(`common/text:text.coords_format_${coordinatesFormatValue}`)}
          </DropdownToggle>

          <DropdownMenu style={{ zIndex: 100000000 }}>
            {SUPPORTED_COORDS_FORMAT.map((format) => (
              <DropdownItem
                key={format}
                active={coordinatesFormatValue === format}
                onClick={this.createOnChangeCoordinatesFormat(format)}
              >
                {t(`common/text:text.coords_format_${format}`)}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>

        {input}
      </InputGroup>
    )
  }

  constructor(props, context) {
    super(props, context)

    this.state = {
      isCoordinateDropdownOpen: false,
    }
  }

  componentDidMount() {
    this.setState({ isCoordinateDropdownOpen: false })
  }

  onToggleCoordinatesInput = () => {
    this.setState({
      isCoordinateDropdownOpen: !this.state.isCoordinateDropdownOpen,
    })
  }

  createOnChangeCoordinatesFormat = (format) => () => {
    return this.props.onChangeCoordinatesFormat(format)
  }
}

export default withTranslation(['common/text'])(CoordinatesInputFormGroup)
