import * as analytics from '@@src/analytics'
import LegendItemColorTheme from
'@@src/components/colors/legend_item_color_theme'

export const LS_KEY = 'inflownet/shared-colors'

export default class AssignableColorStore {
  static * getNextColorGenerator(colors) {
    while (true) {
      for (const color of colors) {
        yield color
      }
    }
  }

  constructor(colorTheme = new LegendItemColorTheme()) {
    Object.assign(this, { colorTheme })

    const { itemColors, overrides } =
      JSON.parse(localStorage.getItem(LS_KEY) || '{}')

    this.itemColors = new Map(itemColors || [])
    this.overrides = new Map(overrides || [])
    this.nextColorGenerator =
      AssignableColorStore.getNextColorGenerator(colorTheme.defaultColors)

    Object.seal(this)
  }

  setItemColor(id, color) {
    if (!this.colorTheme.defaultColors.has(color)) {
      analytics.logError('attempt to use color not in default color theme')
    }

    this.overrides.set(id, color)
    this.persistState()
  }

  persistState({
    itemColors = this.itemColors, overrides = this.overrides,
  } = {}) {
    localStorage.setItem(
      LS_KEY,
      JSON.stringify({
        overrides: Array.from(overrides.entries()),
        itemColors: Array.from(itemColors.entries()),
      }),
    )
  }

  getItemColor(id) {
    if (this.itemColors.has(id)) {
      return this.itemColors.get(id)
    }

    if (this.overrides.has(id)) {
      return this.overrides.get(id)
    }

    const nextColor = this.nextColorGenerator.next().value

    this.setItemColor(id, nextColor)

    return nextColor
  }

  resetColorFor(id) {
    this.overrides.delete(id)
    this.persistState()
  }

  resetAllColors() {
    this.resetColorGenerator()
    this.overrides = new Map()
    this.itemColors = new Map()
    this.persistState()
  }

  resetColorGenerator() {
    this.nextColorGenerator = AssignableColorStore.getNextColorGenerator(
      this.colorTheme.defaultColors
    )
  }

  getAssignableColors() {
    return this.colorTheme.assignableColors
  }
}
