import React from 'react'
import { useTranslation } from 'react-i18next'
import requiresLogin from '../../../../components/security/requires_login'

import AppLayout from '../../../../components/app_layout'
import DevicesList from './components/devices_list.component'
import DevicesMap from './components/devices_map.component'

import { ALLOWED_SORT_BY_DEVICES } from './utils/filters'
import useUrlFilterControll from '../hooks/useUrlFilterControll'
import { parseFiltersFromLocation } from '../../../utils'
import { compose } from 'redux'
import userPermissions from '../../../../components/permissions/user_permissions'

const DevicesPathV2 = ({ permissions }) => {
  const { t } = useTranslation('v2/common/text')

  const {
    listQueryFilters,
    mergedFilters,
    currentMapPosition,
    setListQueryPagination,
    setQueryFilters,
    onPositionUpdate,
  } = useUrlFilterControll(
    parseFiltersFromLocation(location.search, {
      orderBy: ALLOWED_SORT_BY_DEVICES[0],
      filters: ['any'],
    })
  )

  if (!permissions.includes('can_view_devices')) {
    return <Redirect to="/page-not-found" />
  }

  return (
    <AppLayout title={t('devices')} contentsStyle="fixed-at-full-height">
      <div className="w-100 h-100 d-flex flex-row">
        <div className="h-100 w-50">
          <DevicesList
            onShowEverywhereClick={() => onPositionUpdate(null)}
            setFilters={setQueryFilters}
            setPagination={setListQueryPagination}
            currentSettings={mergedFilters}
          />
        </div>
        <div className="h-100 w-50">
          <DevicesMap
            filters={listQueryFilters}
            currentMapPosition={currentMapPosition}
            onPositionUpdate={onPositionUpdate}
          />
        </div>
      </div>
    </AppLayout>
  )
}

export default compose(requiresLogin, userPermissions)(DevicesPathV2)
