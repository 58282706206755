import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import TemperatureHistoryGraph, { DEVICE_TELEMETRY_QUERY } from
'@@src/management_path/devices_path/temperature_history_modal/temperature_history_graph' // eslint-disable-line max-len
import getHistoryGraphContainer, { getHistoryGraphContainerChildWithQuery } from
'@@src/management_path/devices_path/history_modal/history_graph_container'
import withHistoryModal from '@@src/management_path/devices_path/history_modal'

import styles from '../history_modal/simple_view_modal.css'

const HistoryGraphContainerChildWithQuery =
  getHistoryGraphContainerChildWithQuery(DEVICE_TELEMETRY_QUERY)
const HistoryGraphContainer =
  getHistoryGraphContainer(HistoryGraphContainerChildWithQuery)
const HistoryModal = withHistoryModal(HistoryGraphContainer)

const channels = ['tcxo_temperature']

function TemperatureHistoryModal(props) {
  const {
    t,
    deviceId,
    ...rest
  } = props

  return (
    <HistoryModal
      deviceId={deviceId}
      channels={channels}
      title={t('headings.title')}
      {...rest}>
      <TemperatureHistoryGraph className={styles.graph} deviceId={deviceId}/>
    </HistoryModal>
  )
}

TemperatureHistoryModal.propTypes = {
  t: PropTypes.func.isRequired,
  deviceId: PropTypes.number.isRequired,
}

export default withTranslation([
  'src/management_path/devices_path/temperature_history_modal/index_page',
])(React.memo(TemperatureHistoryModal))
