import gql from 'graphql-tag'
import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { get } from 'lodash/fp/object'
import { partition } from 'lodash/fp/collection'
import { uniqBy } from 'lodash/fp/array'
import { compose } from 'redux'
import { graphql } from '@apollo/client/react/hoc'
import { NavLink } from 'react-router-dom'
import { createSelector } from 'reselect'
import { withTranslation } from 'react-i18next'
import {
  Col,
  Row,
  Card,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  UncontrolledTooltip,
  UncontrolledPopover,
  PopoverBody,
} from 'reactstrap'
import MarkerClusterGroup from 'react-leaflet-markercluster'
import L from 'leaflet'
import ReactTooltip from 'react-tooltip'
import moment from 'moment'
import { Link } from 'react-router-dom'
import * as analytics from '@@src/analytics'
import routes from '@@src/routes'
import AppButton from '@@src/components/buttons/app_button'
import AppLayout from '@@src/components/app_layout'
import withModals from '@@src/components/modals/with_modals'
import StandardMap from '@@src/components/maps/standard_map'
import requiresLogin from '@@src/components/security/requires_login'
import transformProps from '@@src/components/transform_props'
import InstallationRow from './device_detail_page/installation_row'
import AsyncResultSwitch from '@@src/components/async_result_switch'
import AsyncResult from '@@src/utils/async_result'
import AddGroupToDeviceModal from './add_group_to_device_modal'
import SignalHistoryModal from '@@src/management_path/devices_path/signal_history_modal'
import TemperatureHistoryModal from '@@src/management_path/devices_path/temperature_history_modal'
import DeleteInstallationPrompt from '@@src/components/modals/delete_installation_prompt'
import BatteryHistoryModal from '@@src/management_path/devices_path/battery_history_modal'
import DeviceIconWithStatus from '@@src/components/icons/device_icon_with_status'
import DeviceCommissionPopup from '@@src/components/maps/markers/device_commission_popup'
import DeviceCommissionMarker from '@@src/components/maps/markers/device_commission_marker'
import CurrentCommissionsSection from './device_detail_page/current_commissions_section'
import AssignNetworkAssetToDeviceModal from './assign_network_asset_to_device_modal'
import UninstallDeviceModal from '@@src/components/modals/uninstall_device_modal/uninstall_device_modal'
import EditInstallationModal from './edit_installation_modal'
import { parseGraphQLResult } from '@@src/api/presenters'
import withGoogleMapsProvider from '@@src/components/maps/google_maps_provider'
import {
  createSelectGraphQLResult,
  sleep,
  MINUTES,
  isMobile,
} from '@@src/utils'
import DeviceIssueAlert from '@@src/components/alerts/device_issue_alert'
import userPermissions from '@@src/components/permissions/user_permissions'
import Device from '@@src/api/presenters/device'
import { TenantConfigContext } from '@@src/components/tenant_config_provider'
import AppSettingsConsumer from '@@src/components/app_settings_consumer'
import DeviceSessionConfigurationModal from '@@src/components/device_configuration/device_session_configuration_modal'
import UpdatingDeviceDate from '@@src/management_path/devices_path/updating_device_date'
import InfoButton from '@@src/components/buttons/info_button'
import { AVAILABLE_TEMPERATURE_UNITS } from '@@src/utils/unit_constants'
import {
  convertUnitFromDB,
  getTemperatureSymbol,
  TEMPERATURE_TYPE,
} from '@@src/utils/app_unit_conversion'

import styles from './device_detail_page/index.css'

class DeviceDetailPage extends React.PureComponent {
  static propTypes = {
    googleMaps: PropTypes.object,
    editConfigurationUpdateDelay: PropTypes.number.isRequired,
    refetchData: PropTypes.func.isRequired,
    temperatureUnits: PropTypes.oneOf(AVAILABLE_TEMPERATURE_UNITS).isRequired,
  }

  static defaultProps = {
    editConfigurationUpdateDelay: 2000,
  }

  render() {
    const { t, deviceId, deviceResult: result, googleMaps } = this.props
    const { commission } = this.state
    return (
      <AppLayout
        title={t('headings.page_title', { deviceId })}
        contentsStyle="fixed-at-full-height"
      >
        <div className={classnames(styles.container, 'container-fluid')}>
          <Row className={styles.row}>
            <Col
              sm={result.wasSuccessful() ? '6' : '12'}
              className={styles['detail-section']}
            >
              <Breadcrumb>
                <BreadcrumbItem>
                  <span>{t('buttons.management')}</span>
                </BreadcrumbItem>

                <BreadcrumbItem>
                  <NavLink to={routes.managementDevicesPath()}>
                    {t('buttons.management_devices')}
                  </NavLink>
                </BreadcrumbItem>

                <BreadcrumbItem active>
                  {t('text.device_details')}
                </BreadcrumbItem>
              </Breadcrumb>

              <AsyncResultSwitch
                result={result}
                renderSuccessResult={this.renderDeviceDetail}
                renderFailResult={this.renderDeviceNotFound}
                renderNotFoundResult={this.renderDeviceNotFound}
              />
            </Col>

            {result.wasSuccessful() && !result.data.wasReturned() ? (
              <StandardMap
                center={this.selectMapCenter(this.props, this.state)}
                className="col-sm-6 pr-0"
              >
                <MarkerClusterGroup
                  showCoverageOnHover={false}
                  removeOutsideVisibleBounds={true}
                >
                  {commission ? (
                    <DeviceCommissionMarker
                      commission={commission}
                      isPopupOpen={true}
                    >
                      <DeviceCommissionPopup
                        googleMaps={googleMaps}
                        commission={commission}
                        showDetailsLink={false}
                      />
                    </DeviceCommissionMarker>
                  ) : null}
                </MarkerClusterGroup>
              </StandardMap>
            ) : null}
          </Row>
        </div>
      </AppLayout>
    )
  }

  renderDeviceDetail = ({ data: device }) => {
    const { t, googleMaps, permissions } = this.props
    const {
      assignTarget,
      editTarget,
      uninstallTarget,
      showSignalHistory,
      showTemperatureHistory,
      showBatteryHistory,
      isGroupModalOpen,
    } = this.state
    const pastCommissionsAndInstallations = this.selectPastResults(
      this.props,
      this.state
    ).data
    const [_commission, currentInstallations] =
      this.selectCurrentResults(this.props, this.state).data || []
    const { lastTelemetrySnapshot, isSignalValid, currentCommission, groups } =
      device
    const { battery1, battery2, signal, temperature } =
      lastTelemetrySnapshot || {}
    const sortedUniqueDeviceIssues = this.selectUniqueSortedDeviceIssues(
      this.props
    )
    const canEditDevices = permissions.includes('can_edit_devices')

    return (
      <React.Fragment>
        <AddGroupToDeviceModal
          isOpen={isGroupModalOpen}
          toggle={this.onToggleAddToGroupModal}
          onSuccess={this.props.refetchData}
          deviceId={device.id}
        />

        <AssignNetworkAssetToDeviceModal
          googleMaps={googleMaps}
          isOpen={!!assignTarget}
          toggle={this.onToggleAssignNetworkAssetModal}
          commission={assignTarget}
          onSuccess={this.onAssignSuccess}
        />

        <UninstallDeviceModal
          isOpen={!!uninstallTarget}
          toggle={this.onToggleUninstallDeviceModal}
          onSuccess={this.onUninstallSuccess}
          installation={uninstallTarget}
        />

        <EditInstallationModal
          isOpen={!!editTarget}
          toggle={this.onToggleEditInstallationModal}
          commission={currentCommission}
          onSuccess={this.onEditInstallationSuccess}
        />

        <SignalHistoryModal
          isOpen={showSignalHistory}
          toggle={this.onToggleSignalHistoryModal}
          deviceId={device.id}
        />

        <TemperatureHistoryModal
          isOpen={showTemperatureHistory}
          toggle={this.onToggleTemperatureHistoryModal}
          deviceId={device.id}
        />

        <BatteryHistoryModal
          isOpen={showBatteryHistory}
          toggle={this.onToggleBatteryHistoryModal}
          deviceId={device.id}
        />

        <Row>
          <Col sm="7" className={styles['device-name-section']}>
            <DeviceIconWithStatus
              device={device}
              className={styles['device-icon']}
            />

            <div className={styles['device-name-section-serial-and-activity']}>
              <h1>{device.serialNumber}</h1>

              {device.wasReturned() ? (
                <p>{t('text.device_was_returned')}</p>
              ) : (
                this.renderContactDates(device)
              )}
            </div>
          </Col>

          {!device.wasReturned() ? (
            <Col sm="5">
              <div className={styles['device-status-section']}>
                {this.renderTemperature(temperature)}
                &nbsp;
                {this.renderBatteries(battery1, battery2)}
                &nbsp;
                {this.renderSignalSymbol(signal, isSignalValid)}
              </div>

              <p
                name="firmware-version-text"
                className={styles['device-firmware-version']}
              >
                {device.firmwareVersion || t('text.not_available')}
              </p>
            </Col>
          ) : null}
        </Row>

        {sortedUniqueDeviceIssues.length ? (
          <div className="mt-4 mb-5">
            {sortedUniqueDeviceIssues.map(this.renderDeviceIssue)}
          </div>
        ) : null}

        <Row className={styles['data-row']}>
          <Col sm="4">
            <p className="h4">{t('headings.groups')}</p>
          </Col>

          <Col sm="8">
            {groups.length === 0 ? (
              <div className={styles['no-group']}>
                <span>{t('text.no_groups')}</span>
              </div>
            ) : null}
            {canEditDevices ? null : (
              <ReactTooltip effect="solid" place="bottom" />
            )}
            {groups.map((group) => (
              <div key={group.id} className={styles['group-item']}>
                <span>
                  {t(`common/text:text.category_${group.category}`)}
                  &nbsp; / &nbsp;
                  <NavLink
                    className={'text-break'}
                    to={routes.managementGroupsDetailPath(group.id)}
                  >
                    {group.name}
                  </NavLink>
                </span>
                <span data-tip={t('common/text:permissions.disabled')}>
                  <Button
                    name="remove-group-button"
                    color="link"
                    disabled={!canEditDevices}
                    outline
                    onClick={this.onRemoveGroup(group)}
                    className={styles['remove-group-button']}
                  >
                    <i className="fas fa-minus-circle"></i>
                  </Button>
                </span>
              </div>
            ))}
            {canEditDevices ? (
              <Button
                name="add-to-group-button"
                onClick={this.onClickAddGroupButton}
                className={styles['add-to-group-button']}
              >
                <i className="fas fa-plus"></i>
                &nbsp;
                {t('buttons.add_to_group')}
              </Button>
            ) : null}
          </Col>
        </Row>

        {device.hasConfigurableSessions()
          ? this.renderConfiguration(device)
          : null}

        {currentCommission ? (
          <CurrentCommissionsSection
            className={styles['current-commission-card']}
            commission={currentCommission}
            permissions={permissions}
            installations={currentInstallations}
            onClickInstall={this.createOnClickInstallFor(currentCommission)}
            onClickCommission={this.createOnClickItemFor(currentCommission)}
            onClickUninstallFor={this.createOnUninstallInstallation}
            oneClickReinstallInfo={this.selectOneClickReinstallInfo(
              this.props,
              this.state
            )}
            onClickDeleteInstallation={this.renderDeleteInstallationPromptFor}
          />
        ) : null}

        {pastCommissionsAndInstallations.length > 0 ? (
          <React.Fragment>
            <h5 className={styles['past-commissions-heading']}>
              {t('headings.past_commissions')}
            </h5>
            {pastCommissionsAndInstallations.map(
              ([commission, installations]) => {
                return this.renderPastCommission(commission, installations)
              }
            )}
          </React.Fragment>
        ) : (
          <p>{t('text.no_past_commissions')}</p>
        )}
      </React.Fragment>
    )
  }

  // Also rendered on error in case of malformed URL int
  renderDeviceNotFound = () => {
    const { t } = this.props

    return (
      <React.Fragment>
        <h1 className="text-danger text-center ">
          {t('text.source_not_found')}
        </h1>
        <p className="muted text-center pt-2">
          {t('common/text:text.check_address_bar')}{' '}
          <Link to={routes.homePath()}>{t('common/text:text.dashboard')}</Link>
        </p>
      </React.Fragment>
    )
  }

  renderContactDates = (device) => {
    return (
      <React.Fragment>
        {this.renderPrevContact(device)}
        {device.hasConfigurableSessions() && !device.isDecommissioned
          ? this.renderNextContact(device)
          : null}
      </React.Fragment>
    )
  }

  renderPrevContact = (device) => {
    const { t, refetchData } = this.props
    const devicePrevContactDateId = 'device-prev-contact-date'

    return (
      <p
        name="device-prev-contact"
        className={classnames(styles['device-activity'], 'mb-0')}
      >
        <UpdatingDeviceDate
          device={device}
          incrementAmountMilliseconds={1 * MINUTES}
          refetchDevice={refetchData}
        >
          {() => {
            return (
              <React.Fragment>
                <span id={devicePrevContactDateId}>
                  {device.translateLastActivityText(t)}
                </span>
                {device.lastCommunication ? (
                  <UncontrolledTooltip
                    target={devicePrevContactDateId}
                    modifiers={{ flip: { enabled: false } }}
                    placement="right"
                  >
                    {moment(device.lastCommunication).format('lll')}
                  </UncontrolledTooltip>
                ) : null}
              </React.Fragment>
            )
          }}
        </UpdatingDeviceDate>
      </p>
    )
  }

  renderDeviceIssue = (issue) => {
    const { t, deviceResult } = this.props
    const { data: device } = deviceResult

    const oneClickInfo = this.selectOneClickReinstallInfo(
      this.props,
      this.state
    )

    switch (true) {
      case !!oneClickInfo && issue === oneClickInfo.issue: {
        return (
          <DeviceIssueAlert
            key={issue.id}
            name="recommission-issue"
            deviceIssue={issue}
            customActionComponent={
              <AppButton
                size="sm"
                color="outline-warning"
                onClick={oneClickInfo.reinstall}
              >
                {t('buttons.reinstall_to_network_asset')}
              </AppButton>
            }
          >
            <strong>
              {t('text.issues_detached_from_asset', {
                assetName: oneClickInfo.suggestedAssetName,
              })}
            </strong>
          </DeviceIssueAlert>
        )
      }

      default:
        return (
          <DeviceIssueAlert key={issue.id} deviceIssue={issue}>
            <strong>
              {t(`common/text:issues.${issue.type}`, {
                count: issue.value,
                serialNumber: device.serialNumber,
              })}
            </strong>
          </DeviceIssueAlert>
        )
    }
  }

  renderNextContact = (device) => {
    const { t, refetchData } = this.props
    const nextContactDate = device.getNextContactDate()
    const targetId = 'device-next-contact-info-btn'
    const deviceNextContactDateId = 'device-next-contact-date'

    if (nextContactDate) {
      return (
        <div name="device-next-contact">
          <UpdatingDeviceDate
            device={device}
            incrementAmountMilliseconds={1 * MINUTES}
            refetchDevice={refetchData}
          >
            {() => {
              const isPendingCommunication =
                device.isCommunicatingPendingOrActive()

              return (
                <span className="d-flex align-items-center">
                  <span
                    id={deviceNextContactDateId}
                    className={styles['device-activity']}
                  >
                    {device.translateNextCommunication(
                      t,
                      isPendingCommunication
                    )}
                  </span>
                  {!isPendingCommunication ? (
                    <UncontrolledTooltip
                      target={deviceNextContactDateId}
                      modifiers={{ flip: { enabled: false } }}
                      placement="right"
                    >
                      {moment(device.getNextContactDate()).format('LT')}
                    </UncontrolledTooltip>
                  ) : null}
                  <InfoButton id={targetId} />
                  <UncontrolledPopover
                    trigger="focus"
                    placement="bottom"
                    target={targetId}
                  >
                    <PopoverBody>
                      {t('text.next_contact_explanation')}
                    </PopoverBody>
                  </UncontrolledPopover>
                </span>
              )
            }}
          </UpdatingDeviceDate>
        </div>
      )
    }

    return null
  }

  renderTemperature(temperature) {
    const { t, temperatureUnits } = this.props

    return (
      <span
        name="temperature-text"
        id="temperature-div"
        className={styles['temperature-text']}
        onClick={this.showTemperatureHistoryModal}
      >
        <span className={styles['temperature-label']}>
          <i className="fas fa-thermometer-three-quarters" />
        </span>
        {temperature
          ? convertUnitFromDB(
              temperature,
              temperatureUnits,
              TEMPERATURE_TYPE,
              1
            ) + getTemperatureSymbol(temperatureUnits)
          : t('text.not_available')}
        <UncontrolledTooltip
          target="temperature-div"
          // the following line is necessary to fix this issue:
          // https://github.com/reactstrap/reactstrap/issues/1482#issuecomment-498747688
          modifiers={{ flip: { enabled: false } }}
          placement="top"
        >
          <span
            name="temperature-tooltip"
            className={styles['temperature-tooltip']}
          >
            {t('text.temperature')}
          </span>
        </UncontrolledTooltip>
      </span>
    )
  }

  renderBatteries(primarymv, secondarymv) {
    const { t } = this.props

    return (
      <div id="battery-div" onClick={this.showBatteryHistoryModal}>
        <span
          name="primary-battery"
          className={styles['battery-symbol-container']}
        >
          <span className={styles['battery-label']}>
            <i className="fas fa-battery-three-quarters"></i>
          </span>
          {Number.isFinite(primarymv)
            ? (primarymv / 1000).toFixed(2) + 'V'
            : t('text.not_available')}
        </span>

        <UncontrolledTooltip
          target="battery-div"
          // the following line is necessary to fix this issue:
          // https://github.com/reactstrap/reactstrap/issues/1482#issuecomment-498747688
          modifiers={{ flip: { enabled: false } }}
          placement="top"
        >
          <div className={styles['batteries-title']}>{t('text.batteries')}</div>
          <span name="battery-text" className={styles['batteries-tooltip']}>
            {Number.isFinite(primarymv)
              ? (primarymv / 1000).toFixed(2) + 'V'
              : t('text.not_available')}
            <span className={styles.lowercase}>
              &nbsp;({t('text.primary_battery')})
            </span>
            <br />
            {Number.isFinite(secondarymv)
              ? (secondarymv / 1000).toFixed(2) + 'V'
              : t('text.not_available')}
            <span className={styles.lowercase}>
              &nbsp;({t('text.secondary_battery')})
            </span>
          </span>
        </UncontrolledTooltip>
      </div>
    )
  }

  renderSignalSymbol(signal, isSignalValid) {
    const { t } = this.props
    const {
      deviceResult: { data: device },
    } = this.props

    const signalStrength = !Number.isFinite(signal)
      ? '0bar'
      : signal >= -69
      ? '4bar'
      : signal >= -97
      ? '3bar'
      : signal >= -104
      ? '2bar'
      : signal >= -105
      ? '1bar'
      : '0bar'

    return (
      <div
        id="signal-strength-symbol"
        onClick={this.showSignalHistoryModal}
        className={styles[`signal-icon-container-${signalStrength}`]}
      >
        {isSignalValid ? null : (
          <i
            className={classnames(
              styles['signal-invalid-icon'],
              'fa fa-question'
            )}
          ></i>
        )}
        <i
          className={classnames(styles['signal-icon-bg'], 'fa fa-signal-4')}
        ></i>
        <i
          className={classnames(styles['signal-icon-fg'], 'fa fa-signal-4')}
        ></i>

        <UncontrolledTooltip
          target="signal-strength-symbol"
          // the following line is necessary to fix this issue:
          // https://github.com/reactstrap/reactstrap/issues/1482#issuecomment-498747688
          modifiers={{ flip: { enabled: false } }}
          placement="top"
        >
          <div
            name="signal-text-title"
            className={styles['signal-strength-label-title']}
          >
            {t('signal.' + signalStrength)}
            &nbsp;
            {t('signal.label')}
          </div>
          <div
            name="signal-text-subtitle"
            className={styles['signal-strength-label']}
          >
            {device.translateReportedTimeAgo(t)}
          </div>
        </UncontrolledTooltip>
      </div>
    )
  }

  renderConfiguration = (device) => {
    const { t, permissions } = this.props
    const { showEditConfiguration } = this.state
    const canEditDevices = permissions.includes('can_edit_devices')

    if (device.isDecommissioned) {
      return null
    }

    return (
      <TenantConfigContext.Consumer>
        {(config = {}) => {
          const {
            dateFrom: tenantDateFrom,
            secondsInterval: tenantSecondsInterval,
            timeZone: tenantTimeZone,
          } = config.deviceConfig || {}
          const {
            dateFrom,
            secondsInterval,
            timeZone,
            momentDate,
            sourceAggregate,
          } = device.activeStandardConfiguration || {}
          const {
            dateFrom: pendingDateFrom,
            momentDate: pendingMomentDate,
            secondsInterval: pendingSecondsInterval,
            timeZone: pendingTimeZone,
            sourceAggregate: pendingSourceAggregate,
          } = device.pendingStandardConfiguration || {}
          const hasActiveStandardConfiguration =
            dateFrom && Number.isFinite(secondsInterval)
          const hasPendingConfiguration =
            pendingDateFrom && Number.isFinite(pendingSecondsInterval)

          if (!hasActiveStandardConfiguration && !hasPendingConfiguration) {
            return null
          }

          const tenantConfiguration = {
            dateFrom: tenantDateFrom,
            secondsInterval: tenantSecondsInterval,
            timeZone: tenantTimeZone,
            sourceAggregate: Device.CONFIGURATION_SOURCE_TENANT_TYPE,
          }
          const deviceConfiguration = {
            dateFrom,
            secondsInterval,
            timeZone,
            sourceAggregate: Device.CONFIGURATION_SOURCE_DEVICE_TYPE,
            displayLabel: 'custom',
          }

          return (
            <React.Fragment>
              <DeviceSessionConfigurationModal
                configurations={[tenantConfiguration, deviceConfiguration]}
                selectedConfiguration={
                  hasPendingConfiguration
                    ? device.pendingStandardConfiguration
                    : device.activeStandardConfiguration
                }
                handleSuccess={this.onEditConfigurationSuccess}
                isOpen={showEditConfiguration}
                handleToggle={this.onToggleEditConfiguration}
                deviceId={device.id}
              />
              <Row className="my-4">
                <Col sm="auto">
                  <h2 className="h4">{t('headings.configuration')}</h2>
                </Col>
                <Col>
                  <p className="font-weight-bold">
                    {t('headings.current_communication')}
                  </p>
                  {sourceAggregate && typeof sourceAggregate === 'string' ? (
                    <p
                      name="current-device-configuration-source"
                      className="mb-0"
                    >
                      {t(`text.source_${sourceAggregate.toLowerCase()}`)}
                    </p>
                  ) : null}
                  <p name="current-device-configuration">
                    {hasActiveStandardConfiguration
                      ? this.getFormattedCommunication(
                          momentDate,
                          secondsInterval,
                          timeZone
                        )
                      : t('text.no_configuration')}
                  </p>
                  {hasPendingConfiguration ? (
                    <React.Fragment>
                      <p className="font-weight-bold">
                        {t('headings.pending_communication')}
                      </p>
                      {pendingSourceAggregate &&
                      typeof pendingSourceAggregate === 'string' ? (
                        <p
                          name="pending-device-configuration-source"
                          className="mb-0"
                        >
                          {t(
                            `text.source_${pendingSourceAggregate.toLowerCase()}`
                          )}
                        </p>
                      ) : null}
                      <p name="pending-device-configuration">
                        {this.getFormattedCommunication(
                          pendingMomentDate,
                          pendingSecondsInterval,
                          pendingTimeZone
                        )}
                      </p>
                    </React.Fragment>
                  ) : null}
                  <React.Fragment>
                    <span data-tip={t('common/text:permissions.disabled')}>
                      <Button
                        disabled={!canEditDevices}
                        name="toggle-edit-configuration-button"
                        onClick={this.onToggleEditConfiguration}
                        outline={true}
                      >
                        {t('buttons.edit')}
                      </Button>
                    </span>
                    {canEditDevices ? null : (
                      <ReactTooltip effect="solid" place="bottom" />
                    )}
                  </React.Fragment>
                </Col>
              </Row>
            </React.Fragment>
          )
        }}
      </TenantConfigContext.Consumer>
    )
  }

  getFormattedCommunication = (timeMoment, secondsInterval, timeZone) => {
    const { t } = this.props
    const hours = secondsInterval / 60 / 60

    const dateFromTime = timeMoment.format('LT')
    const hour = timeZone ? `${dateFromTime} ${timeZone}` : dateFromTime

    return hours < 1
      ? t('text.schedule_minute', {
          hour,
          count:
            Math.round((secondsInterval / 60 + Number.EPSILON) * 100) / 100,
        })
      : t('text.schedule_hour', {
          hour,
          count: Math.round((hours + Number.EPSILON) * 100) / 100,
        })
  }

  renderPastCommission(commission, installations) {
    const { t, permissions } = this.props
    const { location } = commission
    const installationDuration = moment.duration(
      moment(commission.end).diff(commission.start)
    )
    const timeLink =
      installationDuration.asHours() > 24
        ? {
            from: moment(commission.end).subtract(24, 'hours').toISOString(),
            to: moment(commission.end).toISOString(),
          }
        : {
            from: moment(commission.start).toISOString(),
            to: moment(commission.end).toISOString(),
          }

    return (
      <Card
        key={commission.uuid}
        className={styles['previous-commission-card']}
      >
        <Row>
          <Col sm="8">
            <p className={styles['commission-time-text']}>
              {commission.end ? (
                <React.Fragment>
                  <span>
                    {t('text.long_readable_full_time', {
                      time: commission.start,
                    })}
                  </span>
                  &nbsp; &rarr; &nbsp;
                  <span>
                    {t('text.long_readable_full_time', {
                      time: commission.end,
                    })}
                  </span>
                </React.Fragment>
              ) : (
                t('text.long_readable_full_time', { time: commission.start })
              )}
            </p>

            <p
              className={styles['location-text']}
              name="device-commission-location"
              onClick={this.createOnClickItemFor(commission)}
            >
              <i className="far fa-map-marker-alt"></i>
              &nbsp;
              {location.latitude.toFixed(6)},{location.longitude.toFixed(6)}
            </p>
          </Col>

          <Col sm="4" className={styles['actions-column']}>
            <NavLink
              name={'show-data-link' + commission.device.uuid + commission.uuid}
              to={routes.analysisPressurePath({
                d: [commission.device.uuid],
                ...timeLink,
              })}
              className={classnames(
                styles.button,
                'btn btn-light',
                styles['info-button']
              )}
            >
              <i className="fa fa-chart-area"></i>
              &nbsp;
              {t('buttons.show_data')}
            </NavLink>
          </Col>
        </Row>

        <Row>
          <Col sm="12">
            <div className={styles.separator}></div>

            <h6 className={styles['installations-heading']}>
              {t('headings.installations')}
            </h6>
          </Col>
        </Row>

        {installations.length
          ? installations.map((installation) => (
              <InstallationRow
                key={installation.uuid}
                installation={installation}
                onClickDelete={this.renderDeleteInstallationPromptFor(
                  installation
                )}
                onClickCommission={this.createOnClickItemFor(commission)}
              />
            ))
          : null}

        <Row>
          <Col sm="12">
            <span data-tip={t('common/text:permissions.disabled')}>
              <Button
                disabled={!permissions.includes('can_edit_devices')}
                name="install-network-asset-button"
                size="sm"
                color="light"
                onClick={this.createOnClickInstallFor(commission)}
                className={styles['add-installation-button']}
              >
                <i className="far fa-plus"></i>
                &nbsp;
                {t('buttons.install_network_asset')}
              </Button>
            </span>
          </Col>
        </Row>
      </Card>
    )
  }

  renderDeleteInstallationPromptFor = (installation) => {
    return () =>
      this.props.renderModal(
        <DeleteInstallationPrompt
          installation={installation}
          handleSuccess={this.props.refetchData}
        />
      )
  }

  constructor(props) {
    super(props)

    this.state = {
      now: new Date(),
      assignTarget: null,
      editTarget: null,
      commission: null,
      uninstallTarget: null,
      showSignalHistory: false,
      isGroupModalOpen: false,
      showTemperatureHistory: false,
      showBatteryHistory: false,
      showEditConfiguration: false,
    }
  }

  componentDidMount() {
    if (this.props.deviceResult.wasSuccessful()) {
      this.focusOnCurrentCommission()
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.deviceResult !== prevProps.deviceResult &&
      this.props.deviceResult.wasSuccessful()
    ) {
      this.focusOnCurrentCommission()
    }
  }

  focusOnCurrentCommission() {
    const { data: device } = this.props.deviceResult
    const { now } = this.state

    const commission = device.commissionAtTime(now)
    if (commission) {
      this.focusOnCommission(commission)
    }
  }

  selectMapCenter = createSelector(
    [(_props, state) => state.commission],
    (commission) =>
      commission
        ? L.latLng([
            commission.location.latitude,
            commission.location.longitude,
          ])
        : undefined
  )

  selectCommissionsAndInstallationsResult = createSelector(
    [get('deviceResult')],
    (result) =>
      result.map((device) => {
        const { commissions, installations } = device

        return commissions.map((commission) => {
          const commStart = commission.start
          const commEnd = commission.end

          const relatedInstallations = installations.filter((inst) => {
            return (
              (inst.start >= commStart && inst.start <= commEnd) ||
              (inst.end >= commStart && inst.end <= commEnd) ||
              (commStart >= inst.start && commStart <= inst.end) ||
              (commEnd >= inst.start && commEnd <= inst.end) ||
              (!inst.end &&
                (inst.start <= commStart || inst.start <= commEnd)) ||
              (!commEnd && (commStart <= inst.start || commStart <= inst.end))
            )
          })

          return [commission, relatedInstallations]
        })
      })
  )

  selectCurrentResults = createSelector(
    [this.selectCommissionsAndInstallationsResult, get('deviceResult')],
    (result, deviceResult) =>
      result.and(deviceResult).map(([commissionsAndInstallations, device]) => {
        return device.currentCommission ? commissionsAndInstallations[0] : null
      })
  )

  selectPastResults = createSelector(
    [this.selectCommissionsAndInstallationsResult, get('deviceResult')],
    (result, deviceResult) =>
      result.and(deviceResult).map(([commissionsAndInstallations, device]) => {
        return device.currentCommission
          ? commissionsAndInstallations.slice(1)
          : commissionsAndInstallations
      })
  )

  selectUniqueSortedDeviceIssues = createSelector(
    [get('deviceResult')],
    (deviceResult) => {
      if (!deviceResult.wasSuccessful()) {
        return []
      }

      if (deviceResult.data.isDecommissioned) {
        return []
      }

      const uniqueActiveIssues = uniqBy('type')(deviceResult.data.activeIssues)
      const [errors, rest] = partition((i) => i.isError())(uniqueActiveIssues)
      const [warnings, info] = partition((i) => i.isWarning())(rest)

      return [...errors, ...warnings, ...info]
    }
  )

  selectOneClickReinstallInfo = createSelector(
    [
      this.selectUniqueSortedDeviceIssues,
      get('deviceResult'),
      get('createInstallation'),
    ],
    (deviceIssues, { data: device }, createInstallation) => {
      const recommissionIssue = deviceIssues.find(
        (issue) => issue.type === 'detached_from_asset'
      )

      if (!recommissionIssue) {
        return null
      }

      const { installations, currentCommission } = device
      const previousInstallation = installations[0]

      if (!previousInstallation || !currentCommission) {
        return { issue: recommissionIssue }
      }

      const { networkAsset } = previousInstallation
      const lastInstallationEndDate = previousInstallation.end
      const start = currentCommission.start
      let formattedStartDate = moment(start).format(
        'YYYY-MM-DD HH:mm:00.000000Z'
      )

      // Fixes https://github.com/Inflowmatix/InflowNet-Apps/issues/2843
      if (
        lastInstallationEndDate &&
        moment(start).toDate() < lastInstallationEndDate
      ) {
        formattedStartDate = moment(start)
          .add(1, 'second')
          .format('YYYY-MM-DD HH:mm:ss.000000Z')
      }

      return {
        issue: recommissionIssue,
        reinstall: async () => {
          await createInstallation({
            variables: {
              start: formattedStartDate,
              deviceId: device.id,
              channelMap: {
                pressure_1: previousInstallation.channelMap.pressure_1,
              },
              networkAssetId: networkAsset.id,
            },
          })

          this.props.refetchData()
        },
        suggestedAsset: networkAsset,
        suggestedAssetName: networkAsset.assetName || networkAsset.assetId,
        previousInstallation,
      }
    }
  )

  onClickAddGroupButton = (event) => {
    event.preventDefault()
    this.setState({ isGroupModalOpen: true })
  }

  onToggleAddToGroupModal = () => {
    this.setState({
      isGroupModalOpen: !this.state.isGroupModalOpen,
    })
  }

  showSignalHistoryModal = () => {
    const isMobileDevice = isMobile()
    if (isMobileDevice) {
      return
    }

    this.setState({ showSignalHistory: true })
  }

  showTemperatureHistoryModal = () => {
    const isMobileDevice = isMobile()
    if (isMobileDevice) {
      return
    }

    this.setState({ showTemperatureHistory: true })
  }

  showBatteryHistoryModal = () => {
    const isMobileDevice = isMobile()
    if (isMobileDevice) {
      return
    }

    this.setState({ showBatteryHistory: true })
  }

  onToggleAssignNetworkAssetModal = () => {
    this.setState({ assignTarget: null })
  }

  onToggleUninstallDeviceModal = () => {
    this.setState({ uninstallTarget: null })
  }

  onToggleEditInstallationModal = () => {
    this.setState({ editTarget: null })
  }

  onToggleSignalHistoryModal = () => {
    this.setState({ showSignalHistory: false })
  }

  onToggleTemperatureHistoryModal = () => {
    this.setState({ showTemperatureHistory: false })
  }

  onToggleBatteryHistoryModal = () => {
    this.setState({ showBatteryHistory: false })
  }

  onToggleEditConfiguration = () => {
    this.setState({ showEditConfiguration: !this.state.showEditConfiguration })
  }

  onAssignSuccess = () => {
    this.props.refetchData()
  }

  onUninstallSuccess = () => {
    this.props.refetchData()
  }

  onEditInstallationSuccess = () => {
    this.props.refetchData()
  }

  onEditConfigurationSuccess = async () => {
    const { refetchData, editConfigurationUpdateDelay } = this.props

    await sleep(editConfigurationUpdateDelay)
    refetchData()
    this.onToggleEditConfiguration()
  }

  createOnClickItemFor(commission) {
    return () => this.focusOnCommission(commission)
  }

  createOnClickInstallFor(commission) {
    return () => {
      this.setState({
        assignTarget: commission,
      })
    }
  }

  createOnClickEditInstallationFor(commission) {
    return () => {
      this.setState({
        editTarget: commission,
      })
    }
  }

  createOnUninstallInstallation = (installation) => {
    return () => {
      this.setState({
        uninstallTarget: installation,
      })
    }
  }

  focusOnCommission(commission) {
    this.setState({ commission: commission })
  }

  onRemoveGroup = (group) => async (event) => {
    event.preventDefault()

    const { deviceResult, refetchData } = this.props
    const device = deviceResult.wasSuccessful() ? deviceResult.data : null

    this.setState({ groupsResult: AsyncResult.pending() })

    try {
      await this.props.removeFromGroup({
        variables: {
          groupId: group.id,
          aggregateType: 'VDevice',
          aggregateId: device.id,
        },
      })

      await refetchData()
      this.setState({ groupsResult: AsyncResult.success() })
    } catch (err) {
      analytics.logError(err)
      this.setState({ groupsResult: AsyncResult.fail(err) })
    }
  }

  static DEVICE_QUERY = gql`
    query DeviceById($id: Int) {
      device(id: $id) {
        id
        state
        serialNumber
        firmwareVersion
        protocolVersion
        lastCommunication
        lastTelemetrySnapshot {
          signal
          battery1
          battery2
          temperature
        }
        commissions {
          start
          end
          location {
            altitude
            latitude
            longitude
          }
        }
        groups {
          id
          category
          name
        }
        installations {
          end
          start
          channelMap {
            pressure_1
          }
          deviceId
          networkAsset {
            id
            assetId
            assetName
            location {
              latitude
              longitude
            }
            assetType
          }
          networkAssetId
        }
        currentCommission {
          start
          end
          location {
            altitude
            latitude
            longitude
          }
        }
        activeIssues {
          id
          type
          severity
          description
          deviceId
          value
        }
        activeStandardConfiguration {
          dateFrom
          secondsInterval
          sourceAggregate
          timeZone
        }
        pendingStandardConfiguration {
          dateFrom
          secondsInterval
          sourceAggregate
          timeZone
        }
      }
    }
  `

  static CREATE_INSTALLATION = gql`
    mutation CreateInstallation(
      $networkAssetId: Int!
      $deviceId: Int!
      $start: String!
      $channelMap: ChannelMappingInput!
    ) {
      addInstallation(
        deviceId: $deviceId
        channelMap: $channelMap
        whenInstalled: $start
        networkAssetId: $networkAssetId
      )
    }
  `

  static REMOVE_FROM_GROUP_MUTATION = gql`
    mutation RemoveMemberFromGroup(
      $groupId: Int!
      $aggregateType: AggregateType!
      $aggregateId: Int!
    ) {
      removeMemberFromGroup(
        groupId: $groupId
        aggregateType: $aggregateType
        aggregateId: $aggregateId
      )
    }
  `
}

function DeviceDetailPageContainer(props) {
  return (
    <AppSettingsConsumer>
      {(units) => (
        <DeviceDetailPage temperatureUnits={units.temperature} {...props} />
      )}
    </AppSettingsConsumer>
  )
}
export default compose(
  requiresLogin,
  userPermissions,
  withModals([DeleteInstallationPrompt]),
  transformProps(() => ({ match }) => ({
    deviceId: Number(decodeURIComponent(match.params.id)),
    selectDeviceResult: createSelectGraphQLResult('device', {
      mapResult: parseGraphQLResult,
      nullObject: {},
    }),
  })),
  withGoogleMapsProvider,
  graphql(DeviceDetailPage.DEVICE_QUERY, {
    options: ({ deviceId }) => {
      return {
        variables: { id: deviceId },
        fetchPolicy: 'network-only',
      }
    },
    props: ({ data, ownProps }) => {
      const { selectDeviceResult } = ownProps
      const { refetch } = data

      return {
        refetchData: () => refetch({ id: ownProps.deviceId }),
        deviceResult: selectDeviceResult(data),
      }
    },
  }),
  graphql(DeviceDetailPage.REMOVE_FROM_GROUP_MUTATION, {
    name: 'removeFromGroup',
  }),
  graphql(DeviceDetailPage.CREATE_INSTALLATION, {
    name: 'createInstallation',
  }),
  withTranslation([
    'src/management_path/devices_path/device_detail_page',
    'common/text',
  ])
)(DeviceDetailPageContainer)
