import GeoBounds from '../../utils/graphs/geo_bounds'
import { normalizeGeoBoundsToQuery } from '../components/base_map/helpers/map-positions'
import { mapFlattenedFiltersToQueryObject as mapDevicesVariables }
from '../paths/management/devices/utils/filters'
import { mapFlattenedFiltersToQueryObject as mapNetworkAssetVariables }
from '../paths/management/network_assets/utils/filters'
import { EXPORT_DEVICES_MUTATION } from './queries/device'
import { EXPORT_NETWORK_ASSETS_MUTATION } from './queries/network_asset'

const createMutationVariables = (filters, mapFiltersFunction) => {
  return {
    filters: {
      search: filters.search,
      ...mapFiltersFunction(filters.filters),
      inBounds: filters && filters.mapPosition && filters.mapPosition.bounds instanceof GeoBounds
        ? normalizeGeoBoundsToQuery(filters.mapPosition.bounds).toObject()
        : null,
    },
    orderBy: filters.orderBy,
    orderDirection: filters.orderDirection,
    time: (new Date()).getTime(),
  }
}

/**
 * @param {object} client - graphql apollo client
 * @param {object} filters - filters
 * @param {string} filters.search - filters search
 * @param {GeoBounds} filters.inBounds - filters search
 * @param {string[]} filters.assetTypeIn - filters asset type in
 * @param {string[]} filters.issueTypeIn - filters issue type in
 * @param {string[]} filters.installations - filters  installations
 * @param {string[]} filters.issueSeverityIn - filters issue severity in
 * @param {string} filters.orderBy - filters order by
 * @param {string} filters.orderDirection - filters order direction
 * @returns {Promise<void>} - promise
 */
export const exportNetworkAssets = (client, filters) =>
  client
    .mutate({
      mutation: EXPORT_NETWORK_ASSETS_MUTATION,
      variables: createMutationVariables(filters, mapNetworkAssetVariables),
    })

/**
 * @param {object} client - graphql apollo client
 * @param {object} filters - filters
 * @param {string} filters.search - filters search
 * @param {GeoBounds} filters.inBounds - filters bounding box
 * @param {boolean} filters.hasActiveError - filters device has active error
 * @param {boolean} filters.hasActiveInformation - filters device has active information
 * @param {boolean} filters.hasActiveWarning - filters device has active wornings
 * @param {boolean} filters.isCommissioned - filters device is commissioned
 * @param {boolean} filters.isInstalled - filters device is installed
 * @param {boolean} filters.issueTypeIn - filters device is installed
 * @param {object} order - order
 * @param {string} order.by - order by
 * @param {string} order.direction - order direction
 * @returns {Promise<void>} - promise
 */
export const exportDevices = (client, filters) =>
  client
    .mutate({
      mutation: EXPORT_DEVICES_MUTATION,
      variables: createMutationVariables(filters, mapDevicesVariables),
    })
