import React, { PureComponent, cloneElement } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { compose } from 'redux'
import { graphql } from '@apollo/client/react/hoc'
import { scaleTime } from 'd3-scale'
import { createSelector } from 'reselect'
import { get } from 'lodash/fp/object'
import { withRouter } from 'react-router'

import transformProps from '@@src/components/transform_props'
import { parseGraphQLResult } from '@@src/api/presenters'
import { createSelectGraphQLResult, DAYS } from '@@src/utils'

export const DEFAULT_PERIOD_MILLISECONDS = 7 * DAYS

const HistoryGraphContainerChild = ({ children, ...rest }) => (
  cloneElement(children, { ...rest })
)

HistoryGraphContainerChild.propTypes = {
  children: PropTypes.node.isRequired,
  refetchData: PropTypes.func,
  deviceWithTelemetryResult: PropTypes.object,
}

export const getHistoryGraphContainerChildWithQuery = graphQuery => {
  return compose(
    transformProps(() => () => {
      return {
        selectDeviceWithTelemetryResult: createSelectGraphQLResult('device', {
          mapResult: parseGraphQLResult,
        }),
      }
    }),
    graphql(graphQuery, {
      options: (props) => {
        const { deviceId, start, end, channels } = props
        return {
          variables: {
            id: deviceId,
            end: moment(end).toISOString(),
            start: moment(start).toISOString(),
            channels,
          },
        }
      },
      props: ({ ownProps, data }) => {
        const { selectDeviceWithTelemetryResult } = ownProps

        return {
          refetchData: data.refetch,
          deviceWithTelemetryResult: selectDeviceWithTelemetryResult(data),
        }
      },
    })
  )(HistoryGraphContainerChild)
}

const getHistoryGraphContainer = HistoryGraphContainerChildWithQuery => {
  class HistoryGraphContainer extends PureComponent {
    static propTypes = {
      children: PropTypes.node.isRequired,
      deviceId: PropTypes.number.isRequired,
      channels: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
      dispatchUpdateQueryParams: PropTypes.func.isRequired,
      dispatchClearQueryParams: PropTypes.func.isRequired,
      location: PropTypes.object.isRequired,
    }

    static defaultProps = {
      defaultPeriod: DEFAULT_PERIOD_MILLISECONDS,
    }

    render() {
      const { channels, startTime, endTime, timezone } = this.props
      const { panStart, panEnd } = this.state

      return (
        <HistoryGraphContainerChildWithQuery
          start={startTime}
          end={endTime}
          channels={channels}
          deviceId={this.props.deviceId}>
          <HistoryGraphContainerChild
            {...this.props}
            onAxesChange={this.onAxesChange}
            onResetZoom={this.onResetZoom}
            selectXScale={this.selectXScale}
            handleGraphPan={this.onGraphPan}
            panStart={panStart}
            panEnd={panEnd}
            start={startTime}
            end={endTime}
            timezone={timezone}/>
        </HistoryGraphContainerChildWithQuery>
      )
    }

    constructor(props) {
      super(props)

      this.state = {
        panStart: undefined,
        panEnd: undefined,
      }
    }

    onAxesChange = ({ xAxes }) => {
      if (xAxes) {
        this.setState({
          panStart: undefined,
          panEnd: undefined,
        })
        this.props.dispatchUpdateQueryParams(this.props, {
          endTime: xAxes.end,
          startTime: xAxes.start,
        })
      }
    }

    onResetZoom = () => {
      this.props.dispatchClearQueryParams(this.props)
    }

    onGraphPan = ({ panEventX }) => {
      if (panEventX) {
        this.setState({
          panStart: panEventX.start,
          panEnd: panEventX.end,
        })
      }
    }

    selectXScale = createSelector(
      [
        get('start'),
        get('end'),
        get('panStart'),
        get('panEnd'),
      ],
      (start, end, panStart, panEnd) => {
        if (panStart instanceof Date && panEnd instanceof Date) {
          return scaleTime().domain([panStart, panEnd])
        } else {
          return scaleTime().domain([new Date(start), new Date(end)])
        }
      }
    )
  }

  return withRouter(HistoryGraphContainer)
}

export default getHistoryGraphContainer
