import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { Alert } from 'reactstrap'
import { withTranslation } from 'react-i18next'

function TenantLicenceInactiveFeatureWarning(props) {
  const { className, t, color } = props

  return (
    <Alert className={className} color={color}>
      {t('alert.required_tenant_licence')}
    </Alert>
  )
}

TenantLicenceInactiveFeatureWarning.propTypes = {
  t: PropTypes.func.isRequired,
  color: PropTypes.string.isRequired,
  className: PropTypes.string,
}

TenantLicenceInactiveFeatureWarning.defaultProps = {
  color: 'info',
}

export default withTranslation([
  'src/components/tenant_licence/tenant_licence_inactive_feature_warning',
])(memo(TenantLicenceInactiveFeatureWarning))
