import {
  EXPORT_ALERTS_DATA_TYPE,
  EXPORT_CSV_REPORT_TYPE,
  EXPORT_DEVICES_DATA_TYPE,
  EXPORT_DEVICES_HEALTH_DATA_TYPE,
  EXPORT_EVENTS_DATA_TYPE,
  EXPORT_NETWORK_ASSETS_DATA_TYPE,
} from '@@src/components/dropdowns/export_dropdown_with_modal/export_dropdown_with_modal'
import { EXPORT_ANOMALY_REPORT_TYPE } from '../analysis_path/pressure_analysis_path/anomaly_list/consts'

/**
 * @module report_downloads
 * These functions are used for the list of report downloads.
 *
 * Some functions specify just a params argument (empty object by default).
 * This is so the calling functions don't have to worry about which order
 * the inputs should be passed in.
 * You would still need to provide all the correct arguments so the functions
 * can run correctly.
 */

/**
 * @function getReportFilename
 * Function returns a consistent file name for report downloads
 * The report file name is displayed in HTML and also used for file (csv) creation
 *
 * @param {object} t - Translations object used to lookup the relevant text (language changes)
 * @param {string} jobCreated - When the report job was created (i.e. timestamp)
 * @param {string} dataType - The data classification of the report (i.e. alerts, device, etc)
 * @param {string} reportType - The file type of the report (i.e. csv, pdf, etc)
 *
 * @returns {string} A report name based on the type of data and job created timestamp
 *
 * @example
 * Example 1: Alerts report 2021-07-21T15:39:53.453Z.csv
 * Example 2: Device report 2021-07-21T13:27:02.103Z.csv
 */

export function getReportFilename(params = {}) {
  const { t, jobCreated, dataType, reportType } = params
  const extension = '.' + getReportFileType(reportType).toLowerCase()

  switch (dataType) {

    case EXPORT_ALERTS_DATA_TYPE:
      return t('common/text:text.alerts_report_filename', {
        date: jobCreated,
      }) + extension

    case EXPORT_EVENTS_DATA_TYPE:
      return t('common/text:text.events_report_filename', {
        date: jobCreated,
      }) + extension

    case EXPORT_DEVICES_DATA_TYPE:
      return t('common/text:text.devices_report_filename', {
        date: jobCreated,
      }) + extension

    case EXPORT_DEVICES_HEALTH_DATA_TYPE:
      return t('common/text:text.devices_health_export_filename', {
        date: jobCreated,
      }) + extension

    case EXPORT_NETWORK_ASSETS_DATA_TYPE:
      return t('common/text:text.network_assets_report_filename', {
        date: jobCreated,
      }) + extension

    case EXPORT_ANOMALY_REPORT_TYPE:
      return t('common/text:text.anomaly_table_report_filename', {
        date: jobCreated,
      }) + extension

    default:
      /**
       * You shouldn't ever reach this default; however, in case this switch statement
       * has not been updated with the latest dataTypes
       * it will still return a non-specific report type name
       * (e.g. 'Report timestamp.csv')
       */
      return t('common/text:text.default_report_filename', {
        date: jobCreated,
      }) + extension
  }
}

/**
 * @function getReportFileType
 * Function returns the appropriate file extension type
 *
 * @param {string} reportType - The file type of the report (i.e. csv)
 *
 * @returns {string} returns the file extension acronym
 *
 * @example CSV
 */

export function getReportFileType(reportType) {

  switch (reportType) {

    case EXPORT_CSV_REPORT_TYPE:
      return 'CSV'

    default:
      return reportType

  }
}

/**
 * @function getReportDataType
 * Function categorizes what type of data the report mainly consists of
 *
 * @param {object} t - Translations object used to lookup the relevant text (language changes)
 * @param {string} dataType - The data classification of the report (i.e. alerts, device, etc)
 *
 * @returns {string} returns the report classification
 *
 * @example
 * Example 1: Alerts     (English translation)
 * Example 2: Alertes    (French translation)
 */

export function getReportDataType(params = {}) {
  const { t, dataType } = params

  switch (dataType) {

    case EXPORT_ALERTS_DATA_TYPE:
      return t('text.alerts_data_type')

    case EXPORT_DEVICES_DATA_TYPE:
      return t('text.devices_data_type')

    case EXPORT_NETWORK_ASSETS_DATA_TYPE:
      return t('text.network_assets_data_type')

    default:
      // You shouldn't reach this default as we don't know what
      // dataType the report corresponds to; return empty string
      return ''
  }
}
