import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { get } from 'lodash/fp/object'
import { range } from 'lodash/fp/util'
import { createSelector } from 'reselect'
import { withTranslation } from 'react-i18next'
import ReactTooltip from 'react-tooltip'
import {
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledButtonDropdown,
} from 'reactstrap'

import NetworkAssetIcon from '@@src/components/icons/network_asset_icon'
import DeviceIconWithStatus from '@@src/components/icons/device_icon_with_status'
import GroupIcon from '@@src/components/icons/group_icon'
import routes from '@@src/routes'
import LegendItem, {
  LEGEND_ITEM_TYPE_CHANNEL,
  LEGEND_ITEM_TYPE_MULTI_CHANNEL_ASSET,
  LEGEND_ITEM_TYPE_GROUP,
  LEGEND_ITEM_TYPE_DEVICE,
  LEGEND_ITEM_TYPE_ASSET,
} from '@@src/components/graphs/legend_item'
import LegendItemColorPicker from '@@src/components/colors/legend_item_color_picker'
import AsyncResult from '@@src/utils/async_result'
import NetworkAsset from '../../../api/presenters/network_asset'
import Device from '../../../api/presenters/device'
import {
  MixedGroupDetailsType,
  GroupDetailsType,
} from '../../../api/presenters/group'

import styles from './index.css'

const flatten = (obj) => {
  const array = Array.isArray(obj) ? obj : [obj]
  return array.reduce((acc, value) => {
    acc.push(value)
    if (value.children) {
      acc = acc.concat(flatten(value.children))
    }
    return acc
  }, [])
}

class LegendListItem extends Component {
  static propTypes = {
    depth: PropTypes.number.isRequired,
    className: PropTypes.string,
    legendItem: PropTypes.instanceOf(LegendItem).isRequired,
    onRemoveSource: PropTypes.func,
    onChangeVisibility: PropTypes.func.isRequired,
    itemsHaveData: PropTypes.instanceOf(AsyncResult).isRequired,
    legendListItemRefs: PropTypes.arrayOf(
      PropTypes.shape({
        item: PropTypes.object.isRequired,
        ref: PropTypes.object.isRequired,
      })
    ),
    focusedLegendItemId: PropTypes.string,
  }

  static defaultProps = {
    depth: 0,
    legendListItemRefs: [],
  }

  render() {
    const {
      t,
      depth,
      legendItem,
      children,
      className,
      onRemoveSource,
      onChangeVisibility,
      focusedLegendItemId,
      legendListItemRefs,
      emptiesCollapsed,
      itemsHaveData,
    } = this.props
    const listItemWithRef = legendListItemRefs.find((listItem) => {
      return listItem.item === legendItem
    })
    const renderData = this.selectRenderData(this.props)
    const { visible } = legendItem
    const hasData = this.selectItemHasData(this.props)

    if (emptiesCollapsed && !hasData) {
      return null
    }

    return (
      <li
        ref={listItemWithRef ? listItemWithRef.ref : undefined}
        name="legend-item"
        className={classnames(
          styles[`legend-list-item-container${depth === 0 ? '-root' : ''}`],
          className
        )}
      >
        <div className={styles['legend-list-item-body']}>
          <div
            className={classnames(styles['legend-item-list-item-info'], {
              [styles['not-visible-item-info']]: !legendItem.visible,
            })}
          >
            <LegendItemColorPicker
              t={t}
              legendItem={legendItem}
              className={styles['legend-list-item-color-button']}
            />

            <div className={styles['depth-padding-container']}>
              {range(0, depth, 1).map((i) => (
                <div
                  key={i}
                  className={classnames(
                    styles['depth-padding'],
                    'far fa-level-up'
                  )}
                />
              ))}
            </div>

            {renderData.icon}

            <div
              className={classnames(styles['legend-item-name'], {
                'font-weight-bold': focusedLegendItemId === legendItem.id,
              })}
            >
              <span data-tip={renderData.tooltip}>{renderData.name}</span>

              {renderData.tooltip ? (
                <ReactTooltip effect="solid" place="left" />
              ) : null}
            </div>
          </div>

          <div className={styles['legend-item-actions']}>
            <button
              name="toggle-visibility-button"
              onClick={this.onToggleThisItemVisibility}
              className={classnames({
                [styles['toggle-visibility-button-visible']]: visible,
                [styles['toggle-visibility-button-hidden']]: !visible,
              })}
            >
              <i
                className={classnames('far', {
                  'fa-eye': visible,
                  'fa-eye-slash': !visible,
                })}
              ></i>
            </button>

            <UncontrolledButtonDropdown
              className={classnames({
                [styles['dropdown-disabled']]: renderData.disableDropdown,
              })}
            >
              <DropdownToggle outline className={styles['dropdown-toggle']}>
                <i className="far fa-ellipsis-v"></i>
              </DropdownToggle>
              <DropdownMenu end>
                <DropdownItem
                  href={renderData.href}
                  className={styles['dropdown-item']}
                >
                  <i
                    className={classnames(
                      styles['dropdown-icon'],
                      'fa fa-info'
                    )}
                  />

                  <span className={styles['dropdown-item-text']}>
                    {t('common/text:buttons.details')}
                  </span>

                  <i
                    className={classnames(
                      styles['dropdown-icon-external-link'],
                      'far fa-external-link-alt'
                    )}
                  />
                </DropdownItem>

                <DropdownItem
                  href={renderData.dataHref}
                  className={styles['dropdown-item']}
                >
                  <i
                    className={classnames(
                      styles['dropdown-icon'],
                      'fa fa-chart-area'
                    )}
                  />

                  <span className={styles['dropdown-item-text']}>
                    {t('common/text:buttons.show_data')}
                  </span>

                  <i
                    className={classnames(
                      styles['dropdown-icon-external-link'],
                      'far fa-external-link-alt'
                    )}
                  />
                </DropdownItem>

                {onRemoveSource ? (
                  <DropdownItem
                    name="remove-source-button"
                    onClick={onRemoveSource}
                    className={styles['dropdown-item']}
                  >
                    <i
                      className={classnames(
                        styles['dropdown-icon-remove'],
                        'fa fa-times'
                      )}
                    />

                    <span className={styles['dropdown-item-text']}>
                      {t('buttons.remove')}
                    </span>
                  </DropdownItem>
                ) : null}
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        </div>

        {legendItem.children && legendItem.children.length ? (
          <ul className={styles['legend-list-item-child-list']}>
            {legendItem.children.map((child) => {
              return (
                <LegendListItem
                  t={t}
                  key={child.id}
                  depth={depth + 1}
                  legendListItemRefs={legendListItemRefs}
                  focusedLegendItemId={focusedLegendItemId}
                  itemsHaveData={itemsHaveData}
                  legendItem={child}
                  emptiesCollapsed={emptiesCollapsed}
                  onChangeVisibility={onChangeVisibility}
                />
              )
            })}
          </ul>
        ) : null}

        {children}
      </li>
    )
  }

  selectRenderData = createSelector(
    [get('t'), get('legendItem')],
    (t, legendItem) => {
      const common = {
        icon: null,
        name: legendItem.name,
        tooltip: null,
        legendItem,
        dataHref: routes.analysisPressurePath({
          d: legendItem.source.dataSourceId,
        }),
        disableDropdown: false,
      }

      switch (legendItem.type) {
        case LEGEND_ITEM_TYPE_DEVICE: {
          const device = legendItem.source

          return {
            ...common,
            icon: (
              <DeviceIconWithStatus
                device={device}
                className={styles['legend-icon-device']}
              />
            ),
            href: routes.managementDevicesDetailPath(legendItem.sourceId),
          }
        }

        case LEGEND_ITEM_TYPE_GROUP: {
          const group = legendItem.source
          const ancestorsPath = group.constructGroupAncestryPath()

          return {
            ...common,
            href: routes.managementGroupsDetailPath(legendItem.sourceId),
            name: ancestorsPath !== '' ? `.. > ${group.name}` : group.name,
            icon: <GroupIcon className={styles['legend-icon-group']} />,
            tooltip: ancestorsPath,
          }
        }

        case LEGEND_ITEM_TYPE_ASSET:
        case LEGEND_ITEM_TYPE_MULTI_CHANNEL_ASSET: {
          const asset = legendItem.source

          return {
            ...common,
            href: routes.networkAssetsDetailPath(legendItem.sourceId),
            icon: (
              <NetworkAssetIcon
                networkAsset={legendItem.source}
                className={styles['legend-icon-network-asset']}
              />
            ),
            tooltip: asset.assetName ? asset.assetId : null,
          }
        }

        case LEGEND_ITEM_TYPE_CHANNEL: {
          return {
            ...common,
            href: routes.networkAssetsDetailPath(legendItem.sourceId),
            name: t(`common/text:text.asset_channel_${legendItem.name}`),
            disableDropdown: true,
          }
        }

        default:
          throw new Error(`Unexpected legend item type "${legendItem.type}"`)
      }
    }
  )

  /*
   * Check group type
   * Does the group have children that contain data or not
   */
  selectGroupHasValidChildren = createSelector(
    [get('legendItem'), get('itemsHaveData')],
    (legendItem, itemsHaveData) => {
      // Match legendItem up with itemsHaveData
      const flatLI = flatten(legendItem)
      const result = flatLI.some((individualLegendItem) => {
        let itemHasValidChildren = false
        itemsHaveData.data.forEach((itemWithData) => {
          if (
            (individualLegendItem.source instanceof NetworkAsset &&
              individualLegendItem.source.id === itemWithData.networkAssetId &&
              itemWithData.hasDataInRange) ||
            (individualLegendItem.source instanceof Device &&
              individualLegendItem.source.id === itemWithData.deviceId &&
              itemWithData.hasDataInRange)
          ) {
            itemHasValidChildren = true
          }
        })
        return itemHasValidChildren
      })
      return result
    }
  )

  /*
   * Check network asset type
   * - If it is a network asset icon with channels, does it have channels that contain data
   * - If it is a channel, does it contain data
   */
  selectNetworkAssetChannelsAndParent = createSelector(
    [get('legendItem'), get('itemsHaveData')],
    (legendItem, itemsHaveData) => {
      let itemIsActive = false
      // If there is no source channel, it is a network asset legend item. Check its children
      if (!legendItem.sourceChannel) {
        itemIsActive = legendItem.children.some((legendItemChild) => {
          const legendItemChildIsValid = itemsHaveData.data.some(
            (itemWithDataInfo) => {
              if (
                legendItemChild.source.id === itemWithDataInfo.networkAssetId &&
                itemWithDataInfo.hasDataInRange
              ) {
                return true
              }
              return false
            }
          )
          return legendItemChildIsValid
        })
      } else {
        // If it has a channel check the legendItem against itemsHaveData directly, both networkAssetId and Channel
        itemIsActive = itemsHaveData.data.some((itemWithDataInfo) => {
          if (
            legendItem.sourceChannel === itemWithDataInfo.channel &&
            legendItem.source.id === itemWithDataInfo.networkAssetId &&
            itemWithDataInfo.hasDataInRange
          ) {
            return true
          }
          return false
        })
      }
      return itemIsActive
    }
  )

  selectItemHasData = createSelector(
    [get('legendItem'), get('itemsHaveData')],
    (legendItem, itemsHaveData) => {
      let itemIsActive = false

      // If this has not loaded, show items
      if (!itemsHaveData.wasSuccessful()) {
        return true
      }

      itemsHaveData.data.forEach((item) => {
        if (
          (legendItem.source instanceof NetworkAsset &&
            this.selectNetworkAssetChannelsAndParent({
              legendItem,
              itemsHaveData,
            })) ||
          (legendItem.source instanceof Device &&
            legendItem.source.id === item.deviceId &&
            item.hasDataInRange) ||
          ((legendItem.source instanceof MixedGroupDetailsType ||
            legendItem.source instanceof GroupDetailsType) &&
            this.selectGroupHasValidChildren({ legendItem, itemsHaveData }))
        ) {
          itemIsActive = true
        }
      })
      return itemIsActive
    }
  )

  onToggleThisItemVisibility = (ev) => {
    ev.preventDefault()

    const { legendItem } = this.props

    this.props.onChangeVisibility({
      ids: [legendItem.id, ...legendItem.allChildrenIds()],
      visible: !legendItem.visible,
    })
  }
}

export default withTranslation([
  'src/analysis_path/pressure_analysis_path/legend_list_item',
  'src/components/colors/legend_item_color_picker',
  'common/text',
])(LegendListItem)
