import gql from 'graphql-tag'
import { compose } from 'redux'
import { graphql } from '@apollo/client/react/hoc'

import routes from '@@src/routes'
import transformProps from '@@src/components/transform_props'
import CollapsibleAlertsSection
from '@@src/components/alerts/collapsible_alerts_section'
import { parseGraphQLResult } from '@@src/api/presenters'
import { createSelectGraphQLResult } from '@@src/utils'

const PAGED_ALERTS_QUERY = gql`
  query AlertsForPressureData(
    $networkAssetId: Int!,
  ) {
    pagedAlerts(
      statuses: [open],
      pageNumber: 1,
      resultsPerPage: 5,
      networkAssetSourceId: $networkAssetId,
      orderBy: occurredAt,
      orderDirection: desc,
    ) {
      alerts {
        type
        status
        alertId
        occurredAt

        details {
          ... on StandardAlertDetails {
            networkAssetId
            networkAssetName
            networkAssetFriendlyName
            networkAssetType
            deviceId
            deviceSerialNumber
            logicalChannel
            threshold
            value
            unit
          }
          ... on DeviceNoCommsAlertDetails {
            deviceId
            deviceSerialNumber
            threshold
            unit
          }
          ... on EventSourceLocalisationAlertDetails {
            groupId
            groupName
            localisationId
            eventIds
            eventsStartDate
            eventsEndDate
          }
          ... on EventAlertDetails {
            networkAssetId
            networkAssetName
            networkAssetFriendlyName
            networkAssetType
            deviceId
            logicalChannel
            eventCharacteristic
            eventId
            startTimestamp
            endTimestamp
            value
            threshold
            unit
          }
        }
      }

      pagination {
        pageNumber
        totalPages
        totalResults
      }

      openAlertsCount
    }
  }
`

export default compose(
  transformProps(() => ({ networkAssetId }) => {
    return {
      showAllOpenAlertsHref: routes.alertsPath({
        ob: 'occurredAt',
        od: 'desc',
        source: `a.${networkAssetId}`,
        status: 'open',
      }),
      selectedPagedAlertsResult: createSelectGraphQLResult('pagedAlerts', {
        mapResult: parseGraphQLResult,
        nullObject: null,
      }),
    }
  }),
  graphql(PAGED_ALERTS_QUERY, {
    options: ({ networkAssetId }) => {
      return {
        fetchPolicy: 'network-only',
        variables: {
          networkAssetId: networkAssetId,
        },
      }
    },
    props: ({ data, ownProps: { selectedPagedAlertsResult } }) => {
      return {
        pagedAlertsResult: selectedPagedAlertsResult(data),
        onChangeAlertStatus: () => data.refetch(),
      }
    },
  }),
)(CollapsibleAlertsSection)
