import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash/fp/object'
import { zip } from 'lodash/fp/array'
import { select } from 'd3-selection'
import { axisBottom } from 'd3-axis'
import { timeInterval } from 'd3-time'
import { createSelector } from 'reselect'
import { max, mean, min } from 'lodash/fp/math'
import { withTranslation } from 'react-i18next'

import GraphContext, { GraphConfig }
from '@@src/components/graphs/graph_context'
import {
  DAYS, DEFAULT_TIMEZONE, AVAILABLE_TIME_ZONES, selectDateFormatter,
  timezoneAbbreviation,
} from '@@src/utils'

import styles from './bottom_time_axis.css'

const FORMAT_MILLISECONDS = 'HH:mm:ss.SSS'
const FORMAT_SECONDS = 'HH:mm:ss'
const FORMAT_MINS = 'HH:mm'
const FORMAT_DAY = 'Do MMM'

const defaultCreateTickFormatter = timezone => {
  const dateFormat = selectDateFormatter(timezone)

  return date => {
    return date.getMilliseconds() ? dateFormat(date, FORMAT_MILLISECONDS)
      : date.getSeconds() ? dateFormat(date, FORMAT_SECONDS)
      : (date.getMinutes() || date.getHours()) ? dateFormat(date, FORMAT_MINS)
      : dateFormat(date, FORMAT_DAY)
  }
}

function BottomTimeAxisContainer(props) {
  return (
    <GraphContext.Consumer>
      {config => <BottomTimeAxis graphConfig={config} {...props}/> }
    </GraphContext.Consumer>
  )
}

class BottomTimeAxis extends React.PureComponent {
  static defaultProps = {
    timezone: DEFAULT_TIMEZONE,
    tickPadding: 5,
    createTickFormatter: defaultCreateTickFormatter,
  }

  static propTypes = {
    timezone: PropTypes.oneOf(AVAILABLE_TIME_ZONES).isRequired,
    graphConfig: PropTypes.instanceOf(GraphConfig).isRequired,
    tickPadding: PropTypes.number.isRequired,
    createTickFormatter: PropTypes.func.isRequired,
  }

  axisRef = React.createRef()

  render() {
    const { t, name, graphConfig, timezone } = this.props
    const { height, bottomPadding, xScale } = graphConfig

    const topOffset = height - bottomPadding

    return (
      <g
        name={name || 'bottom-time-axis-layer'}
        transform={`translate(0, ${topOffset})`}>
        <g
          ref={this.axisRef}
          name="bottom-time-axis-ticks-layer"
          className={styles['ticks-layer']}/>

        <text
          className={styles.label}
          transform={
            `translate(${mean(xScale.range())}, ${bottomPadding - 10})`
          }>
          {t('text.time_label', { timezone: timezoneAbbreviation(timezone) })}
        </text>
      </g>
    )
  }

  componentDidMount() {
    this.renderAxis()
  }

  componentDidUpdate() {
    this.renderAxis()
  }

  renderAxis() {
    const { graphConfig, tickPadding, tickValues, ticks } = this.props
    const tickFormatter = this.selectTickFormatter(this.props)

    let axisGenerator = axisBottom(graphConfig.xScale)
      .tickPadding(tickPadding)

    if (tickFormatter) {
      axisGenerator = axisGenerator.tickFormat(tickFormatter)
    }

    if (tickValues) {
      axisGenerator = axisGenerator.tickValues(tickValues)
    }

    if (ticks) {
      axisGenerator = axisGenerator.ticks(ticks)
    } else {
      const scaleTicks = graphConfig.xScale.ticks()
      const intervals = zip(
        scaleTicks.slice(0, scaleTicks.length - 1),
        scaleTicks.slice(1, scaleTicks.length)
      ).map(([previous, next]) => {
        return Number(next) - Number(previous)
      })

      const minInterval = min(intervals)
      const maxInterval = max(intervals)

      if (minInterval !== maxInterval) {
        // to account for daylight savings
        if (maxInterval % DAYS === 0 || minInterval % DAYS === 0) {
          const numDays = Math.round(maxInterval / DAYS)
          axisGenerator = axisGenerator.tickValues(timeInterval(
            (day) => day.setHours(0, 0, 0, 0),
            (day, n) => day.setDate(day.getDate() + numDays * n)
          ).range(...graphConfig.xScale.domain()))
        }
      }
    }

    select(this.axisRef.current).call(axisGenerator)
  }

  selectTickFormatter = createSelector(
    [get('timezone'), get('createTickFormatter')],
    (timezone, createTickFormatter) => createTickFormatter(timezone)
  )

  selectAxisDatumFormatter = createSelector(
    [get('timezone')],
    timezone => date => selectDateFormatter(timezone)(date, FORMAT_DAY)
  )
}

export default withTranslation(
  'src/components/graphs/bottom_time_axis',
)(BottomTimeAxisContainer)
