import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { FormGroup, Label } from 'reactstrap'
import { Pagination as PaginationModel } from '../../api/models/pagination'
import SelectInput from '../inputs/select_input/select_input.component'

const PAGINATION_LIMIT_VALUES = [10, 25, 50, 100, 150, 200, 500]

const PaginationLimitComponent = ({ t, pagination, onChangeLimit }) => {
  const [activeLimit, setActiveLimit] = useState(pagination.perPage)
  const handleChange = (e) => {
    e.currentTarget.blur()
    setActiveLimit(+e.target.value)
    onChangeLimit(+e.target.value)
  }

  useEffect(() => setActiveLimit(pagination.perPage), [pagination])

  return (
    <FormGroup className="d-flex flex-row justify-content-end align-items-center m-0 p-0">
      <Label
        for="results-per-page"
        className="text-muted font-weight-normal m-0 pr-2"
      >
        {t('results_per_page')}
      </Label>
      <SelectInput
        choiceOptions={PAGINATION_LIMIT_VALUES}
        inputProps={{
          type: 'select',
          id: 'results-per-page',
          name: 'results-per-page',
          onChange: handleChange,
          value: activeLimit,
          style: {
            width: 70,
          },
        }}
      />
    </FormGroup>
  )
}

PaginationLimitComponent.propTypes = {
  pagination: PropTypes.instanceOf(PaginationModel).isRequired,
  onChangeLimit: PropTypes.func.isRequired,
}

export default withTranslation(['v2/common/pagination'])(
  PaginationLimitComponent
)
