import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { NavLink } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { Button, Col, Row } from 'reactstrap'
import ReactTooltip from 'react-tooltip'
import { compose } from 'redux'
import gql from 'graphql-tag'
import { graphql } from '@apollo/client/react/hoc'

import NetworkAsset from '@@src/api/presenters/network_asset'
import Device from '@@src/api/presenters/device'
import { MixedGroupDetailsType } from '@@src/api/presenters/group'
import {
  createSelectGraphQLResult,
} from '@@src/utils'
import { parseGraphQLResult } from '@@src/api/presenters/index'
import routes from '@@src/routes'
import NetworkAssetIcon from '@@src/components/icons/network_asset_icon'
import DeviceIconWithStatus
from '@@src/components/icons/device_icon_with_status'
import GroupIcon from '@@src/components/icons/group_icon'

import styles from './analysis_list_item.css'

class AnalysisListItem extends React.PureComponent {
  static GROUP_MOST_DISTANT_GROUPS = gql`
  query GetMostDistantGroups(	$parentGroupId: Int ) {
    getMostDistantGroups( parentGroupId: $parentGroupId ) {
      groupId
      groupName
      groupCategory
      membersType
      membersCount
      membersIds
    }
  }
  `
  static propTypes = {
    className: PropTypes.string,
    t: PropTypes.func.isRequired,
    selected: PropTypes.bool.isRequired,
    dataSource: PropTypes.object.isRequired,
    addToSelected: PropTypes.func.isRequired,
    removeFromSelected: PropTypes.func.isRequired,
    isLimitReached: PropTypes.bool.isRequired,
    onClick: PropTypes.func,
    showAncestors: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    selected: false,
    isLimitReached: false,
    showAncestors: false,
  }

  render() {
    const { className, onClick, selected, isLimitReached, t,
      dataSource, showAncestors } = this.props
    const isDevice = this.isDevice()
    const isNetworkAsset = this.isNetworkAsset()
    const isGroup = this.isGroup()
    const navLink = isDevice ?
      routes.managementDevicesDetailPath(dataSource.id) :
      isNetworkAsset ?
        routes.networkAssetsDetailPath(dataSource.id) :
        routes.managementGroupsDetailPath(dataSource.id)
    const ancestorsPath = isGroup ? dataSource.constructGroupAncestryPath() : ''

    return (
      <li className={classnames(className, styles.container)} onClick={onClick}>
        <Row className={styles.row}>
          <Col sm="8" className={styles['summary-column']}>
            <div className={styles['icon-subcolumn']}>
              {
                isDevice ?
                  <DeviceIconWithStatus
                    device={{ status: dataSource.commissionStatus }}
                    className={styles.icon} />
                  :
                  isNetworkAsset ?
                    <NetworkAssetIcon
                      networkAsset={dataSource}
                      className={styles.icon} />
                    :
                    <GroupIcon />
              }
            </div>
            <div className={styles['summary-subcolumn']}>
              <span data-tip={ancestorsPath}>
                <span className={styles['data-source-id']}>
                  {
                    isDevice ?
                      dataSource.serialNumber :
                      isNetworkAsset ?
                        dataSource.assetId :
                        ancestorsPath !== '' && showAncestors ?
                          `.. > ${dataSource.name}` : dataSource.name
                  }
                </span>
              </span>
              {
                ancestorsPath !== '' && showAncestors ?
                  <ReactTooltip effect="solid" place="left" /> : null
              }

              {
                isNetworkAsset && dataSource.assetName ? (
                  <div><i><small>{dataSource.assetName}</small></i></div>
                ) : null
              }

              <small className={styles['data-source-type']}>
                {
                  isDevice ?
                    t('common/text:text.device') :
                    isNetworkAsset ?
                      t(`common/text:text.${dataSource.assetType}`) :
                      this.getGroupName(dataSource.getTypeOfGroup())
                }
              </small>
            </div>
          </Col>
          <Col sm="4" className={styles['actions-column']}>
            <NavLink
              to={navLink}
              className={
                classnames(styles.button,
                  'btn btn-outline-secondary', styles['info-button'])
              }>
              <i className="fa fa-info" />
            </NavLink>
            {
              selected === true ?
                <Button
                  name="remove-from-selected-sources"
                  onClick={this.removeFromSources}
                  className={classnames(styles.button,
                    'btn btn-outline-secondary', styles['cross-button'])}>
                  <i className="fal fa-times" />
                </Button>
                :
                <Button
                  disabled={isLimitReached}
                  name="add-to-selected-sources"
                  color="primary"
                  onClick={this.addToSources}
                  className={classnames(styles.button)}>
                  <i className="fal fa-plus" />
                </Button>
            }
          </Col>
        </Row>
      </li>
    )
  }

  addToSources = async () => {
    const { addToSelected, dataSource, mostDistantGroups } = this.props

    if (this.isGroup()) {
      const datasources =
        mostDistantGroups.data.reduce((acc, group) => {
          const innerGroup = MixedGroupDetailsType.from({
            id: group.groupId,
            addedFromGroup: dataSource.name,
            __typename: 'MixedGroupDetailsType',
          })
          const innerAssets = group.membersIds.map(id => {
            if(group.membersType === 'VDevice') {
              return new Device({ id })
            }
            return new NetworkAsset({ id })
          })

          return [...acc, innerGroup, ...innerAssets]
        }, [])
      if(dataSource.isGroupOfGroups()) {
        datasources.push(dataSource)
      }

      const arrUniq = [...new Map(datasources.map(v => [v.uuid, v])).values()]

      addToSelected(arrUniq)
    } else {
      addToSelected(dataSource)
    }
  }

  removeFromSources = () => {
    const { removeFromSelected, dataSource, mostDistantGroups } = this.props
    if (this.isGroup()) {
      const datasources =
        mostDistantGroups.data.reduce((acc, group) => {
          const innerGroup = MixedGroupDetailsType.from({
            id: group.groupId,
            addedFromGroup: dataSource.name,
            __typename: 'MixedGroupDetailsType',
          })
          const innerAssets = group.membersIds.map(id => {
            if(group.membersType === 'VDevice') {
              return new Device({ id, __typename: 'Device' })
            }
            return new NetworkAsset({ id, __typename: 'NetworkAsset' })
          })

          return [...acc, innerGroup, ...innerAssets]
        }, [])
      if(dataSource.isGroupOfGroups()) {
        datasources.push(dataSource)
      }
      removeFromSelected(datasources)
    } else {
      removeFromSelected(dataSource)
    }
  }

  isDevice() {
    return this.props.dataSource instanceof Device
  }

  isNetworkAsset() {
    return this.props.dataSource instanceof NetworkAsset
  }

  isGroup() {
    return this.props.dataSource instanceof MixedGroupDetailsType
  }

  getGroupName(groupType) {
    const { t } = this.props
    if (groupType === 'VNetworkAsset') {
      return t('common/text:text.network_asset_group')
    } else if (groupType === 'VDevice') {
      return t('common/text:text.device_group')
    } else {
      return t('common/text:text.group_group')
    }
  }
}

export default compose(
  withTranslation([
    'common/text',
  ]),
  graphql(AnalysisListItem.GROUP_MOST_DISTANT_GROUPS, {
    skip: (({ dataSource }) => !(dataSource instanceof MixedGroupDetailsType)),
    options: ({ dataSource }) => {
      return {
        fetchPolicy: 'network-only',
        variables: {
          parentGroupId: dataSource.id,
        },
      }
    },
    props: ({ data }) => {
      const mostDistantGroups = createSelectGraphQLResult('getMostDistantGroups', {
        mapResult: parseGraphQLResult,
        nullObject: [],
      })(data)
      return {
        mostDistantGroups: mostDistantGroups,
      }
    },
  }),
)(AnalysisListItem)
