import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash/fp/object'
import { Alert, Button } from 'reactstrap'
import { createSelector } from 'reselect'
import { withTranslation } from 'react-i18next'

import { AppError } from '@@src/utils'

import styles from './error_info.css'

let TranslatedErrorInfo = null

class ErrorInfo extends React.PureComponent {
  static propTypes = {
    error: PropTypes.any.isRequired,
    className: PropTypes.string,
  }

  state = {
    showErrorDetail: false,
  }

  render() {
    const { t, className } = this.props
    const { showErrorDetail } = this.state

    const appError = this.selectAppError(this.props)

    if (appError.isAggregate()) {
      return (
        <React.Fragment>
          {appError.original.map((e, index) => (
            <TranslatedErrorInfo key={index} error={e} className={className}/>
          ))}
        </React.Fragment>
      )
    }

    return (
      <Alert color="danger" className={className}>
        {appError.translateWith(t)}

        <br/>

        {
          appError.isUnknown() ? (
            <Button
              type="button"
              name="show-details-button"
              color="link"
              onClick={this.onToggleShowErrorDetail}
              className={styles['show-detail-button']}>
              {t(
                showErrorDetail ?
                  'buttons.hide_details' :
                  'buttons.show_details'
              )}
            </Button>
          ) : null
        }

        {
          showErrorDetail && appError.isUnknown() ? (
            <Alert color="warning">
              <pre className={styles['error-description']}>
                {String(appError.original)}
              </pre>
            </Alert>
          ) : null
        }
      </Alert>
    )
  }

  selectAppError = createSelector([get('error')], AppError.from)

  componentDidUpdate(prevProps) {
    if (this.props.error !== prevProps.error) {
      this.setState({ showErrorDetail: false })
    }
  }

  onToggleShowErrorDetail = () => {
    this.setState({ showErrorDetail: !this.state.showErrorDetail })
  }
}

TranslatedErrorInfo = withTranslation([
  'src/components/error_info',
  'common/errors',
])(ErrorInfo)

export default TranslatedErrorInfo
