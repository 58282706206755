import { map } from 'lodash/fp/collection'
import { flow } from 'lodash/fp/util'
import { omit } from 'lodash/fp/object'
import { maxBy } from 'lodash/fp/math'
import { flatten } from 'lodash/fp/array'
import { createSelector } from 'reselect'
import moment from 'moment'
import { Schedule } from '@rschedule/core/generators'

import {
  translateLastCommunication as translateLastCommunicationUtil,
  translateReportedTimeAgo as translateReportedTimeAgoUtil,
  translateNextCommunication as translateNextCommunicationUtil,
  DAYS, HOURS, MINUTES,
} from '@@src/utils'
import DeviceIssue from '@@src/api/presenters/device_issue'
import DeviceConfig from '@@src/api/presenters/device_config'

export default class Device {
  static INSTALLED = 'installed'
  static COMMISSIONED = 'commissioned'
  static DECOMMISSIONED = 'decommissioned'
  static RETURNED = 'returned'

  static CONFIGURABLE_SESSIONS_MIN_MAJOR_PROTOCOL_VERSION = 4
  static CONFIGURATION_SOURCE_TENANT_TYPE = 'VTenant'
  static CONFIGURATION_SOURCE_DEVICE_TYPE = 'VDevice'
  static CONFIGURATION_SOURCE_GROUP_TYPE = 'VGroup'
  static CONFIGURATION_SOURCE_TYPES = [
    Device.CONFIGURATION_SOURCE_TENANT_TYPE,
    Device.CONFIGURATION_SOURCE_DEVICE_TYPE,
    Device.CONFIGURATION_SOURCE_GROUP_TYPE,
  ]
  static COMMUNICATION_TIME_PADDING_WINDOW = [
    2 * MINUTES,
    5 * MINUTES,
  ]
  static COMMUNICATING_STATES = [
    'enagaged_and_online',
    'engaged_and_installed',
  ]

  constructor(data) {
    Object.assign(this, omit('telemetry')(data))

    this.commissions = (data.commissions || []).map(c => c.withDevice(this))
    this.lastCommunication =
      data.lastCommunication ? new Date(data.lastCommunication) : null
    this.lastCommission = maxBy('start')(this.commissions)
    this.currentCommission = data.currentCommission ?
      data.currentCommission.withDevice(this) : null
    this.rawTelemetry = data.telemetry || []
    this.activeStandardConfiguration = this.activeStandardConfiguration ?
      new DeviceConfig(this.activeStandardConfiguration) : null
    this.pendingStandardConfiguration = this.pendingStandardConfiguration ?
      new DeviceConfig(this.pendingStandardConfiguration) : null
    this.groups = data.groups ? data.groups : []

    this.uuid = `d.${data.id}`
    Object.freeze(this)
  }

  get dataSourceId() {
    return this.uuid
  }

  get status() {
    if (this.state === Device.RETURNED) {
      return Device.RETURNED
    }

    if (this.hasOwnProperty('commissionStatus')) {
      return this.commissionStatus
    }

    if (this.currentCommission) {
      return Device.COMMISSIONED
    } else {
      return Device.DECOMMISSIONED
    }
  }

  get currentLocation() {
    return this.currentCommission ? this.currentCommission.location : null
  }

  get coordinates() {
    const currentLocation = this.currentLocation

    return currentLocation ?
      `${currentLocation.latitude}, ${currentLocation.longitude}` : null
  }

  get telemetry() {
    return this.selectFlattenedTelemetry(this)
  }

  get telemetrySegments() {
    return this.selectTelemetrySegments(this)
  }

  get mostSevereIssue() {
    if (this.isDecommissioned) {
      return null
    }

    return Device.getMostSevereIssue(this.activeIssues || [])
  }

  wasReturned() {
    return this.status === Device.RETURNED
  }

  get isDecommissioned() {
    return this.status === Device.DECOMMISSIONED
  }

  get isSignalValid() {
    return this.checkSignalValidity()
  }

  get isCommunicating() {
    return Device.COMMUNICATING_STATES.includes(this.state)
  }

  isPendingConfiguration() {
    if (!Array.isArray(this.activeIssues)) {
      return false
    }

    return !!this.activeIssues.find(
      issue => issue.type === DeviceIssue.CONFIGURATION_PENDING
    )
  }

  hasConfigurableSessions() {
    return Array.isArray(this.protocolVersion) &&
      this.protocolVersion[0] >=
        Device.CONFIGURABLE_SESSIONS_MIN_MAJOR_PROTOCOL_VERSION
  }

  commissionAtTime(date) {
    return this.commissions.find(c => c.wasActiveAtTime(date)) || null
  }

  checkSignalValidity() {
    if (this.lastCommunication) {
      const timestamp = (new Date()).getTime() -
        this.lastCommunication.getTime()
      if (Math.floor(timestamp / DAYS) < 3) {
        return true
      }
    }
    return false
  }

  translateLastCommunication(t, isCommunicating) {
    return translateLastCommunicationUtil(
      t,
      this.lastCommunication,
      isCommunicating
    )
  }

  translateNextCommunication(t, isCommunicating) {
    return translateNextCommunicationUtil(
      t,
      moment(this.getNextContactDate()),
      isCommunicating
    )
  }

  translateReportedTimeAgo(t) {
    return translateReportedTimeAgoUtil(t, this.lastCommunication)
  }

  translateLastDecommission(t) {
    const timestamp = (new Date()).getTime() -
        this.lastCommission.end.getTime()

    if (timestamp > 3 * DAYS) {
      return t('common/text:text.decommission_in_days', {
        count: Math.floor(timestamp / DAYS),
      })
    } else if (timestamp >= 1 * HOURS) {
      return t('common/text:text.decommission_in_hours', {
        count: Math.floor(timestamp / HOURS),
      })
    } else {
      return t('common/text:text.decommission_in_minutes', {
        count: Math.floor(timestamp / MINUTES),
      })
    }
  }

  translateCurrentCommission(t) {
    const timestamp = (new Date()).getTime() -
        this.currentCommission.start.getTime()

    if (timestamp > 3 * DAYS) {
      return t('common/text:text.commission_in_days', {
        count: Math.floor(timestamp / DAYS),
      })
    } else if (timestamp >= 1 * HOURS) {
      return t('common/text:text.commission_in_hours', {
        count: Math.floor(timestamp / HOURS),
      })
    } else {
      return t('common/text:text.commission_in_minutes', {
        count: Math.floor(timestamp / MINUTES),
      })
    }
  }

  translateCommissionDetails(t) {
    if (this.lastCommission && this.lastCommission.end) {
      return this.translateLastDecommission(t)
    } else if (this.currentCommission) {
      return this.translateCurrentCommission(t)
    } else {
      return '-'
    }
  }

  translateLastActivityText(t, isCommunicating) {
    if (this.currentCommission && (!this.lastCommunication ||
      this.currentCommission.start > this.lastCommunication)) {
      return this.translateCurrentCommission(t)
    } else if (this.lastCommission && this.lastCommission.end &&
      (!this.lastCommunication ||
        this.lastCommission.end > this.lastCommunication)) {
      return this.translateLastDecommission(t)
    } else if (this.lastCommunication) {
      return this.translateLastCommunication(t, isCommunicating)
    } else {
      return '-'
    }
  }

  selectTelemetrySegments = createSelector(
    [({ rawTelemetry }) => rawTelemetry],
    rawTelemetry => {
      let currentSlice = []
      const slices = []

      for (const entry of rawTelemetry) {
        // Break graph around invalid signal results with value -32768
        // see https://github.com/Inflowmatix/InflowSys/blob/master/storage/sd_card/specification/data_storage.md#signal-strength for more information
        if (entry.value === -32768) {
          if (currentSlice.length > 0) {
            slices.push({ index: slices.length, data: currentSlice })
          }
          currentSlice = []
        } else {
          currentSlice.push(entry)
        }
      }

      if (currentSlice.length > 0) {
        slices.push({ index: slices.length, data: currentSlice })
      }

      return slices
    }
  )

  selectFlattenedTelemetry = createSelector(
    [this.selectTelemetrySegments],
    flow(
      map(({ data }) => data),
      flatten
    )
  )

  static from(data) {
    return new Device(data)
  }

  static isDeviceDataSourceId(dataSourceId) {
    return dataSourceId.match(/^d\.\d+$/)
  }

  static parseIdFromDataSourceId(dataSourceId) {
    return parseInt(dataSourceId.replace(/^d\./, ''), 10)
  }

  static getMostSevereIssue(issues) {
    const errorIssue = issues.find(i => i.isError())

    if (errorIssue) {
      return errorIssue
    }

    const warningIssue = issues.find(i => i.isWarning())

    if (warningIssue) {
      return warningIssue
    }

    const infoIssue = issues.find(i => i.isInformation())

    if (infoIssue) {
      return infoIssue
    }

    return null
  }

  getContactSchedule() {
    const {
      momentDate,
      secondsInterval,
    } = this.activeStandardConfiguration || {}

    if (!momentDate || !Number.isFinite(secondsInterval)) {
      return null
    }

    return new Schedule({
      rrules: [
        {
          frequency: 'SECONDLY',
          interval: secondsInterval,
          start: momentDate.toDate(),
        },
      ],
    })
  }

  getContactScheduleOccurrences(options = { take: 1, start: new Date() }) {
    const schedule = this.getContactSchedule()

    if (schedule) {
      const occurrences = schedule
        .occurrences(options)
        .toArray()
        .map(o => o.date)

      return moment(occurrences[0])
    }

    return null
  }

  getNextContactDate() {
    return this.getContactScheduleOccurrences()
  }

  getExpectedPrevContactDate() {
    return this.getContactScheduleOccurrences({
      take: 1,
      end: new Date(),
      reverse: true,
    })
  }

  isPendingCommunication = (
    communicationTimePadding = Device.COMMUNICATION_TIME_PADDING_WINDOW
  ) => {
    if (this.isDecommissioned) {
      return false
    }

    const now = moment()
    const nextContactDate = this.getNextContactDate()
    const expectedPrevContactDate = this.getExpectedPrevContactDate()

    if (Array.isArray(communicationTimePadding) &&
      communicationTimePadding.length === 2 &&
        nextContactDate && expectedPrevContactDate) {
      const [min, max] = communicationTimePadding
      const minNextMoment =
        nextContactDate.clone().subtract(min, 'milliseconds')
      const maxNextMoment = nextContactDate.clone().add(max, 'milliseconds')

      const isWithinNextContactDateWindow =
        now.isSameOrAfter(minNextMoment) && now.isSameOrBefore(maxNextMoment)

      if (isWithinNextContactDateWindow) {
        return true
      }

      if (this.lastCommunication instanceof Date &&
        this.lastCommunication >= expectedPrevContactDate) {
        return false
      }

      const minPrevMoment =
        expectedPrevContactDate.clone().subtract(min, 'milliseconds')
      const maxPrevMoment =
        expectedPrevContactDate.clone().add(max, 'milliseconds')

      return now.isSameOrAfter(minPrevMoment) &&
        now.isSameOrBefore(maxPrevMoment)
    }

    return false
  }

  isCommunicatingPendingOrActive(...args) {
    return this.isCommunicating || this.isPendingCommunication(...args)
  }
}
