import gql from 'graphql-tag'
import moment from 'moment'
import { MAX_DAYS_LIMIT } from '@@src/components/modals/csv_export_configuration_modal/csv_export_configuration_modal'
export { default, SMALLEST_SAMPLE, RESOLUTION_OPTIONS } from './optimize_subset_data_query'

export const DATA_SOURCES_QUERY = gql`
    query DataSources(
      $deviceIds: [Int],
      $networkAssetIds: [Int],
      $groupIds: [Int]
    ) {
      dataSources(deviceIds: $deviceIds, networkAssetIds: $networkAssetIds) {
        ... on Device {
          id
          serialNumber
          commissionStatus
        }
        ... on NetworkAsset {
          id
          assetId
          assetName
          assetType
          congruityInfo {
            logicalChannel
            pointId
          }
        }
      }

      pagedGroups(ids: $groupIds, pageNumber: 1, resultsPerPage: 10000) {
        groups {
          id
          name
          category
          ancestry {
            id
            name
          }
          mostDistantDeviceOrAssetGroups {
            id
            name
            type
          }
          members {
            type
            count
            data {
              ... on NetworkAsset {
                id
                assetId
                assetName
                assetType
                congruityInfo {
                  logicalChannel
                  pointId
                }
                location {
                  latitude
                  longitude
                }
              }
              ... on Device {
                id
                serialNumber
                currentCommission {
                  location {
                    latitude
                    longitude
                  }
                }
              }
            }
          }
        }
        pagination {
          pageNumber
          totalPages
          totalResults
        }
      }
    }
  `

// visibility constants
export const SETTING_DISPLAY_EVENTS = 'display_events'
export const SETTING_DISPLAY_ALERTS = 'display_alerts'
export const SETTING_DISPLAY_ANOMALIES = 'display_anomalies'
export const SETTING_DISPLAY_RAW_DATA = 'display_raw_data'
export const SETTING_MIN_MAX_LINE = 'min_max_line'
export const SETTING_CURSOR_TOOL_DISPLAY = 'cursor_tool_display'
export const SETTING_AUTO_Y_AXIS_ZOOM = 'auto_y_axis_zoom'
export const SETTING_COLLECTION_OBJECT = {
  'display_events': true,
  'display_alerts': false,
  'display_anomalies': true,
  'display_raw_data': true,
  'min_max_line': false,
  'cursor_tool_display': true,
  'auto_y_axis_zoom': true,
}
export const SETTING_DISPLAY_OPTIONS = [
  SETTING_DISPLAY_EVENTS,
  SETTING_DISPLAY_ALERTS,
  SETTING_DISPLAY_ANOMALIES,
  SETTING_DISPLAY_RAW_DATA,
]
export const SETTING_GRAPH_OPTIONS = [
  SETTING_MIN_MAX_LINE,
  SETTING_AUTO_Y_AXIS_ZOOM,
  SETTING_CURSOR_TOOL_DISPLAY,
]

// histogram options
export const HISTOGRAM_TYPE_EVENTS = 'histogram_events'
export const HISTOGRAM_TYPE_EVENTS_CPIS = 'histogram_events_CPIS'
export const HISTOGRAM_TYPE_EVENTS_SEVERITY = 'histogram_events_severity'
export const HISTOGRAM_TYPE_ANOMALIES = 'histogram_anomalies'
export const HISTOGRAM_TYPE_ALERTS = 'histogram_alerts'
export const HISTOGRAM_TYPE_RAW_DATA = 'histogram_raw_data'
export const HISTOGRAM_TYPE_CPIS = 'histogram_CPIS'

export const HISTOGRAM_TYPES = [
  HISTOGRAM_TYPE_EVENTS,
  HISTOGRAM_TYPE_EVENTS_CPIS,
  HISTOGRAM_TYPE_EVENTS_SEVERITY,
  HISTOGRAM_TYPE_ANOMALIES,
  HISTOGRAM_TYPE_ALERTS,
  HISTOGRAM_TYPE_RAW_DATA,
  HISTOGRAM_TYPE_CPIS,
]

// view type
export const VIEW_TYPE_PLOT = 'plot'
export const VIEW_TYPE_CPIS = 'cpis'
export const VIEW_TYPE_EVENTS = 'events'
export const VIEW_TYPE_ANOMALY_LIST = 'anomaly-list'
export const VIEW_TYPE_EVENT_LOCALISATION = 'event-sources'

export const VIEW_TYPE_OPTIONS = [
  VIEW_TYPE_PLOT,
  VIEW_TYPE_CPIS,
  VIEW_TYPE_EVENTS,
  VIEW_TYPE_EVENT_LOCALISATION,
  VIEW_TYPE_ANOMALY_LIST,
]

// other constants
export const ORDER_ASC = 'asc'

export const SOURCE_LIMIT = 50
export const PAN_MODIFIER = 0.25
export const ZOOM_MODIFIER = 0.25
export const DEFAULT_USER_ACTION_DEBOUNCE_DELAY = 0

export const DEFAULT_DATE_NOW = new Date()
export const DEFAULT_DATE_YESTERDAY = moment(DEFAULT_DATE_NOW)
  .subtract(1, 'days').toDate()

export const EXPORT_LIMITS = {
  [VIEW_TYPE_PLOT]: [MAX_DAYS_LIMIT, 'days'],
  [VIEW_TYPE_CPIS]: [68, 'years'],
}

export const DEFAULT_EVENTS_OBJECT = {
  eventsCountSum: 0,
  eventsCpisSum: 0,
  eventsSeveritySum: 0,
  maxCount: 0,
  maxCpis: 0,
  maxSeverity: 0,
  data: {
    countData: [],
    cpisData: [],
    severityData: [],
  },
}
