import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { Button, ButtonGroup } from 'reactstrap'
import EventsOn from '@@src/static_assets/svgs/plot_feature_events_on.svg'

import styles from './event_detail_carousel.css'

class EventDetailCarousel extends React.PureComponent {
  static defaultProps = {
    onClickNextEvent: () => { },
    onClickPrevEvent: () => { },
    activeEventId: 0,
    totalEventCount: 0,
    onNavigateToEventsList: () => { },
  }

  static propTypes = {
    onClickNextEvent: PropTypes.func.isRequired,
    onClickPrevEvent: PropTypes.func.isRequired,
    activeEventId: PropTypes.number.isRequired,
    totalEventCount: PropTypes.number.isRequired,
    onNavigateToEventsList: PropTypes.func.isRequired,
  }

  render() {
    const { activeEventId, totalEventCount } = this.props
    const { t, onClickNextEvent, onClickPrevEvent, onNavigateToEventsList } = this.props

    if (totalEventCount === 0) {
      return null
    }

    return (
      <div className={styles['main-container']}>
        <div className="d-flex align-items-center">
          <img
            src={EventsOn}
            className={styles.icon}
            alt="events-image"
          />
          &nbsp;
          <b><span>{t('heading.events')}:</span></b>
          &nbsp;
          <span>{t('text.all_in_range')}</span>
        </div>
        <div className={styles['right-side-container']}>
          <span>
            {t('text.events_number', { current: activeEventId + 1, max: totalEventCount })}
          </span>
          <ButtonGroup>
            <Button
              name="previous-events-button"
              color="light"
              onClick={onClickPrevEvent}
              disabled={activeEventId === 0}
            >
              <i className="far fa-chevron-left" />
            </Button>

            <Button
              name="next-events-button"
              color="light"
              onClick={onClickNextEvent}
              disabled={(totalEventCount - 1) === activeEventId}
            >
              <i className="far fa-chevron-right"></i>
            </Button>
          </ButtonGroup>
          <Button
            name="all-events-button"
            onClick={onNavigateToEventsList}
            disabled={totalEventCount === 0}
            className={styles['events-report']}
          >
            <span>{t('buttons.events_report')}</span>
          </Button>
        </div>
      </div>
    )
  }
}

export default withTranslation(
  'src/analysis_path/pressure_analysis_path/pressure_subset_graph/event_detail_carousel', // eslint-disable-line max-len
)(EventDetailCarousel)
