import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap'
import { Pagination as PaginationModel } from '../../api/models/pagination'

const PaginationComponent = ({ pagination, onPaginate }) => {
  const [current, setCurrent] = useState(pagination.getCurrentPage())
  const first = pagination.getFirstPage()
  const prev = pagination.getPrevPage(current)
  const next = pagination.getNextPage(current)
  const last = pagination.getLastPage()
  const total = pagination.getLastPage()
  const isFirst = current === first
  const isLast = current === last

  const before = current === last && total > 2 ? prev - 1 : null
  const after = current === first && total > 2 ? next + 1 : null

  const onClick = (page) => (e) => {
    e.currentTarget.blur()
    setCurrent(page)
    onPaginate(page)
  }

  useEffect(() => setCurrent(pagination.getCurrentPage()), [pagination])

  if (!pagination.shouldPaginate()) {
    return <div></div>
  }

  return (<>
    <Pagination className="m-0 p-0" listClassName="m-0 p-0">
      <PaginationItem disabled={isFirst}><PaginationLink onClick={onClick(first)}>«</PaginationLink></PaginationItem>
      <PaginationItem disabled={!prev}><PaginationLink onClick={onClick(prev)}>‹</PaginationLink></PaginationItem>

      {before && (<PaginationItem><PaginationLink onClick={onClick(before)}>{before}</PaginationLink></PaginationItem>)}
      {prev && (<PaginationItem><PaginationLink onClick={onClick(prev)}>{prev}</PaginationLink></PaginationItem>)}

      <PaginationItem active={true}> <PaginationLink>{current}</PaginationLink> </PaginationItem>

      {next && (<PaginationItem><PaginationLink onClick={onClick(next)}>{next}</PaginationLink></PaginationItem>)}
      {after && (<PaginationItem><PaginationLink onClick={onClick(after)}>{after}</PaginationLink></PaginationItem>)}

      <PaginationItem disabled={!next}><PaginationLink onClick={onClick(next)}>›</PaginationLink></PaginationItem>
      <PaginationItem disabled={isLast}><PaginationLink onClick={onClick(last)}>»</PaginationLink></PaginationItem>
    </Pagination>
  </>)
}

PaginationComponent.propTypes = {
  pagination: PropTypes.instanceOf(PaginationModel).isRequired,
  onPaginate: PropTypes.func.isRequired,
}

export default PaginationComponent
