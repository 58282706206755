import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { format } from 'd3-format'

import Graph from '@@src/components/graphs/graph'

export const GRAPH_PADDING = 0.02
export const formatTicks = format('.1f')

export default class HistoryGraph extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    xScale: PropTypes.func.isRequired,
    yScale: PropTypes.func.isRequired,
    yScale2: PropTypes.func,
    refetchData: PropTypes.func.isRequired,
  }

  render() {
    const {
      className, children, xScale, yScale, yScale2,
    } = this.props
    const verticalPadding = 50
    const horizontalPadding = 70

    return (
      <Graph
        xScale={xScale}
        yScale={yScale}
        yScale2={yScale2}
        className={className}
        topPadding={verticalPadding}
        leftPadding={horizontalPadding}
        rightPadding={horizontalPadding}
        bottomPadding={verticalPadding}>
        {children}
      </Graph>
    )
  }
}
