import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { range } from 'lodash'
import { NavLink } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { Form, FormGroup, Input, Pagination, PaginationItem } from 'reactstrap'

import styles from './pagination_row.css'

export const MIN_LINKS = 5
export const DEFAULT_PER_PAGE_OPTIONS = [5, 10, 15, 20]

class PaginationRow extends React.PureComponent {
  static defaultProps = {
    numLinks: 12,
    perPageOptions: DEFAULT_PER_PAGE_OPTIONS,
  }

  static propTypes = {
    page: PropTypes.number.isRequired,
    perPage: PropTypes.number.isRequired,
    numLinks: PropTypes.number.isRequired,
    className: PropTypes.string,
    totalPages: PropTypes.number.isRequired,
    createPageLink: PropTypes.func.isRequired,
    perPageOptions: PropTypes.array.isRequired,
    onChangePerPage: PropTypes.func,
  }

  render() {
    const {
      t, page, perPage, numLinks, totalPages, createPageLink, className,
      onChangePerPage, perPageOptions,
    } = this.props

    const numVariableLinks = Math.max(numLinks - 2, 2)
    const firstPageOption = Math.max(1, page - Math.floor(numVariableLinks / 2))

    const pageOptions = range(
      firstPageOption,
      Math.min(firstPageOption + numVariableLinks, totalPages + 1)
    )

    const displayedPerPageOptions = (
      perPageOptions.indexOf(perPage) === -1 ?
        perPageOptions.concat(perPage) : perPageOptions.slice(0)
    ).sort((a, b) => a - b)

    return (
      <div className={classnames(styles.container, className)}>
        {
          totalPages < 2 ? null : (
            <Pagination className={styles['pagination-section']}>
              <PaginationItem disabled={page < 2}>
                <NavLink
                  to={createPageLink(Math.max(page - 1, 1))}
                  className="page-link">
                  <i className="far fa-chevron-left"></i>
                </NavLink>
              </PaginationItem>

              {
                pageOptions.map(pageOption => (
                  <PaginationItem
                    key={pageOption}
                    disabled={page === pageOption}>
                    <NavLink
                      to={createPageLink(pageOption)}
                      className="page-link">
                      {pageOption}
                    </NavLink>
                  </PaginationItem>
                ))
              }

              <PaginationItem disabled={page + 1 > totalPages}>
                <NavLink
                  to={createPageLink(Math.min(page + 1, totalPages))}
                  className="page-link">
                  <i className="far fa-chevron-right"></i>
                </NavLink>
              </PaginationItem>
            </Pagination>
          )
        }

        {
          !onChangePerPage ? null : (
            <Form inline>
              <FormGroup>
                <span className={styles['per-page-text']}>
                  {t('text.per_page')}
                </span>

                &nbsp;

                <Input
                  type="select"
                  name="per-page-select"
                  value={perPage}
                  onChange={this.onChange}
                  className={styles.select}>
                  {
                    displayedPerPageOptions.map(option => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))
                  }
                </Input>
              </FormGroup>
            </Form>
          )
        }
      </div>
    )
  }

  onChange = event => {
    this.props.onChangePerPage(event.target.value)
  }
}

export default withTranslation(['src/components/pagination_row'])(PaginationRow)
