import React, { PureComponent } from 'react'

export const ORDER_ASC = 'asc'
export const ORDER_DESC = 'desc'

export default function withOrderByAndDirection(SortableComponent) {
  return class OrderByAndDirectionContainer extends PureComponent {
    state = {
      orderBy: undefined,
      orderDirection: ORDER_DESC,
    }

    render() {
      const { orderBy, orderDirection } = this.state

      return (
        <SortableComponent
          orderBy={orderBy}
          orderDirection={orderDirection}
          setOrderByAndDirection={this.setOrderByAndDirection}
          {...this.props} />
      )
    }

    setOrderByAndDirection = (orderBy, orderDirection) => {
      this.setState({
        orderBy,
        orderDirection,
      })
    }
  }
}
