import React, { useEffect, useState } from 'react'
import { Input, InputGroup, InputGroupText } from 'reactstrap'

import classnames from 'classnames'
import PropTypes from 'prop-types'

import './search_field.component.scss'

const SearchField = ({
  onChange = () => null,
  initialValue = '',
  placeholder = '',
  className,
  debounceTime = 500,
}) => {
  const [search, setSearch] = useState(initialValue)

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(search)
    }, debounceTime)

    return () => clearTimeout(timeout)
  }, [search])

  return (
    <InputGroup
      className={classnames('search-group', { [className]: !!className })}
    >
      <Input
        className={'border-right-0 shadow-none search-input'}
        placeholder={placeholder}
        value={search}
        onChange={(e) => setSearch(e.target.value || '')}
      />
      <InputGroupText className={'bg-white text-black-50 search-input-text'}>
        <i className="fa fa-search"></i>
      </InputGroupText>
    </InputGroup>
  )
}

SearchField.propTypes = {
  onChange: PropTypes.func,
  initialValue: PropTypes.string,
  className: PropTypes.string,
  debounceTime: PropTypes.number,
}

export default SearchField
