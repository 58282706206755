import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash/fp/object'
import { compose } from 'redux'
import { createSelector } from 'reselect'
import { withTranslation } from 'react-i18next'

import AsyncResult from '@@src/utils/async_result'
import GraphAlertGroup from '@@src/components/graphs/graph_alert_group'
import { withPressureSubsetDataContainer } from '../../../hooks/plot/PlotDataContext'

class PressureSubsetGraphAlertGroup extends React.PureComponent {
  static propTypes = {
    alertsResult: PropTypes.instanceOf(AsyncResult).isRequired,
    rawDataRequestsResult: PropTypes.instanceOf(AsyncResult).isRequired,
    pressureSubsetDataResult: PropTypes.instanceOf(AsyncResult).isRequired,
    pressureEventsWithData: PropTypes.instanceOf(AsyncResult).isRequired,
  }

  render() {
    const { className } = this.props
    const alertConfigs = this.selectAlertConfigs(this.props)

    return (
      <GraphAlertGroup className={className} alertConfigs={alertConfigs} />
    )
  }

  selectAlertConfigs = createSelector(
    [
      get('t'),
      get('pressureEventsWithData'),
      get('rawDataRequestsResult'),
      get('pressureSubsetDataResult'),
      get('alertsResult'),
      get('setEventsDisplayMode'),
    ],
    (
      t, pressureEventsWithData, rawDataRequestsResult,
      pressureSubsetDataResult, alertsResult, setEventsDisplayMode,
    ) => {
      return [
        ...computeAlertsForPagedPressureEvents(
          t, pressureEventsWithData, setEventsDisplayMode,
        ),
        ...computeAlertsForRawDataRequests(t, rawDataRequestsResult),
        ...computeAlertsForPressureSubsetData(t, pressureSubsetDataResult),
        ...computeAlertsForAlerts(t, alertsResult),
      ]
    }
  )
}

function computeAlertsForPagedPressureEvents(t, result) {
  switch (true) {
    case (result.wasFailure()):
      return [{ color: 'danger', text: t('text.error_loading_events') }]

    default:
      return []
  }
}

function computeAlertsForRawDataRequests(t, result) {
  switch (true) {
    case (result.wasFailure()):
      return [{ color: 'danger', text: t('text.error_loading_raw_data') }]

    default:
      return []
  }
}

function computeAlertsForPressureSubsetData(t, result) {
  switch (true) {
    case (result.wasFailure()):
      return [{ color: 'danger', text: t('text.error_loading_pressure_data') }]

    case (
      result.wasSuccessful() &&
      !result.data.data.some(({ data }) => data.length)
    ):
      return [{ color: 'warning', text: t('text.no_pressure_data') }]

    default:
      return []
  }
}

function computeAlertsForAlerts(t, result) {
  switch (true) {
    case (result.wasFailure()):
      return [{ color: 'danger', text: t('text.error_loading_alerts') }]

    default:
      return []
  }
}

export default compose(
  withTranslation([
    'src/analysis_path/pressure_analysis_path/pressure_subset_graph/pressure_subset_graph_alert_group', // eslint-disable-line max-len
    'common/text',
  ]),
  withPressureSubsetDataContainer,
)(PressureSubsetGraphAlertGroup)
