import React from 'react'
import { createSelector } from 'reselect'
import { isEqual } from 'lodash/fp/lang'
import { omit } from 'lodash/fp/object'

import * as analytics from '@@src/analytics'
import AsyncResult from '@@src/utils/async_result'
import AsyncResultSwitch from '@@src/components/async_result_switch'
import { AppError } from '@@src/utils'
import {
  renderAbsolutePositionedLoading,
} from '@@src/components/app_suspense_container'

const noop = () => {}
const norend = () => null

export default function awaitAsyncOperation(config) {
  const { operation, skip = noop } = config

  return Component => {
    return class AwaitAsyncOperation extends React.PureComponent {
      state = {
        result: AsyncResult.pending(),
      }

      render() {
        return (
          <AsyncResultSwitch
            result={this.state.result}
            renderPendingResult={
              this.props.hideLoading ? norend : renderAbsolutePositionedLoading
            }
            renderSuccessResult={this.renderSuccessResult}/>
        )
      }

      renderSuccessResult = ({ data }) => {
        return <Component {...this.selectChildProps(this.props, data)}/>
      }

      selectChildProps = createSelector(
        [props => props, (_props, a) => a],
        (props, data) => ({
          ...props,
          ...data,
        })
      )

      componentDidMount() {
        this.runOperation()
      }

      componentDidUpdate(prevProps) {
        const omitChildren = omit('children')
        const propsWithoutChildren = omitChildren(this.props)
        const prevPropsWithoutChildren = omitChildren(prevProps)

        if (!isEqual(propsWithoutChildren, prevPropsWithoutChildren) &&
          !skip(this.props)) {
          this.runOperation()
        }
      }

      async runOperation() {
        this.setState({ result: AsyncResult.pending() })

        try {
          const res = await operation(this.props)

          this.setState({ result: AsyncResult.success(res) })
        } catch (e) {
          analytics.logError(e)

          this.setState({ result: AsyncResult.fail(AppError.from(e)) })
        }
      }
    }
  }
}
