import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { difference } from 'lodash/array'
import { map, flatMap } from 'lodash/fp/collection'

import NetworkAssetIcon from '@@src/components/icons/network_asset_icon'
import DeviceIconWithStatus
from '@@src/components/icons/device_icon_with_status'
import GroupTypeIcon from '@@src/components/icons/group_type_icon'
import ExportLegendItem from
'@@src/components/dropdowns/export_dropdown_with_modal/export_legend_item'
import { LegendItemColorContext } from
'@@src/components/colors/legend_item_color_provider'
import AsyncResult from '@@src/utils/async_result'
import LegendItem from '@@src/components/graphs/legend_item'

import styles from './export_legend_list.css'

class ExportLegendList extends React.PureComponent {
  static propTypes = {
    dataResult: PropTypes.instanceOf(AsyncResult).isRequired,
    legendItem: PropTypes.instanceOf(LegendItem).isRequired,
    getHandleSelectLegendItem: PropTypes.func.isRequired,
    getLegendItemColor: PropTypes.func.isRequired,
    selectedLegendItemIds: PropTypes.array.isRequired,
    dataType: PropTypes.string.isRequired,
    className: PropTypes.string,
  }

  static defaultProps = {
    selectedLegendItemIds: [],
  }

  render() {
    const {
      dataResult,
      legendItem,
      className,
      getHandleSelectLegendItem,
      getLegendItemColor,
      dataType,
    } = this.props

    return (
      <ol className="border-bottom list-unstyled">
        <ExportLegendItem
          dataType={dataType}
          indeterminate={this.isLegendItemIndeterminate(legendItem)}
          className={className}
          dataResult={dataResult}
          getHandleSelectLegendItem={getHandleSelectLegendItem}
          checked={this.isLegendItemChecked(legendItem)}
          legendItem={legendItem}
          legendItemColor={!legendItem.hasChildren() ?
            getLegendItemColor(legendItem) : undefined}>
          {
            legendItem.isFromDevice() ? (
              <DeviceIconWithStatus
                device={legendItem.source}
                className={styles['graph-item-device-icon']}/>
            ) : legendItem.isFromNetworkAsset() ? (
              <NetworkAssetIcon
                className={styles['graph-item-icon']}
                networkAsset={legendItem.source}/>
            ) : (
              <GroupTypeIcon
                className={styles['graph-item-group-icon']}
                group={legendItem.source}/>
            )
          }
        </ExportLegendItem>
        {
          legendItem.hasChildren() ?
            legendItem.children.map(this.renderChildren)
            : null
        }
      </ol>
    )
  }

  renderChildren = childLegendItem => {
    const {
      getLegendItemColor,
      dataResult,
      getHandleSelectLegendItem,
      dataType,
    } = this.props

    return (
      <React.Fragment key={childLegendItem.id}>
        <ExportLegendItem
          dataResult={dataResult}
          dataType={dataType}
          indeterminate={this.isLegendItemIndeterminate(childLegendItem)}
          getHandleSelectLegendItem={getHandleSelectLegendItem}
          checked={this.isLegendItemChecked(childLegendItem)}
          legendItem={childLegendItem}
          legendItemColor={!childLegendItem.hasChildren() ?
            getLegendItemColor(childLegendItem) : undefined}>
          <span className={classnames(
            'far fa-level-up fa-rotate-90 text-black-50 ml-3',
            {
              'mr-2': !childLegendItem.isFromDevice(),
              'mr-3': childLegendItem.isFromDevice(),
            }
          )} />
          {
            childLegendItem.isFromDevice() ? (
              <DeviceIconWithStatus
                device={childLegendItem.source}
                className={styles['graph-item-device-icon']}/>
            ) : childLegendItem.isFromNetworkAsset() ? (
              <NetworkAssetIcon
                className={styles['graph-item-icon']}
                networkAsset={childLegendItem.source}/>
            ) : null
          }
        </ExportLegendItem>
        {
          childLegendItem.hasChildren() ?
            childLegendItem.children.map(this.renderGrandChildren)
            : null
        }
      </React.Fragment>
    )
  }

  renderGrandChildren = channelLegendItem => {
    const {
      dataResult,
      getHandleSelectLegendItem,
      getLegendItemColor,
      dataType,
    } = this.props

    return (
      <ExportLegendItem
        dataResult={dataResult}
        dataType={dataType}
        getHandleSelectLegendItem={getHandleSelectLegendItem}
        checked={this.isLegendItemChecked(channelLegendItem)}
        legendItem={channelLegendItem}
        legendItemColor={getLegendItemColor(channelLegendItem)}
        key={channelLegendItem.id}>
        <span className={
          'far fa-level-up fa-rotate-90 text-black-50 ml-4 mr-3'
        } />
      </ExportLegendItem>
    )
  }

  getAllLegendItemChildrenIds = legendItem => {
    return flatMap(child => {
      return child.hasChildren() ? map('id')(child.children) : child.id
    })(legendItem.children)
  }

  isLegendItemIndeterminate = legendItem => {
    const { selectedLegendItemIds } = this.props

    if (legendItem.hasChildren() &&
      !selectedLegendItemIds.includes(legendItem.sourceId)) {
      const childrenIds = this.getAllLegendItemChildrenIds(legendItem)
      const idDifference = difference(childrenIds, selectedLegendItemIds)

      return idDifference.length !== 0 &&
        idDifference.length !== childrenIds.length
    }

    return false
  }

  isLegendItemChecked = legendItem => {
    const { selectedLegendItemIds } = this.props

    if (!legendItem.hasChildren()) {
      return selectedLegendItemIds.includes(legendItem.id)
    } else {
      const childrenIds = this.getAllLegendItemChildrenIds(legendItem)

      return (difference(childrenIds, selectedLegendItemIds).length === 0)
    }
  }
}

export default function ExportLegendListWithContainers(props) {
  return (
    <LegendItemColorContext.Consumer>
      {
        ({ getLegendItemColor }) => (
          <ExportLegendList
            getLegendItemColor={getLegendItemColor}
            {...props} />
        )
      }
    </LegendItemColorContext.Consumer>
  )
}
