import React from 'react'
import PropTypes from 'prop-types'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import moment from 'moment'
import { createSelector } from 'reselect'
import { get } from 'lodash/object'
import classnames from 'classnames'

import AsyncResult from '@@src/utils/async_result'
import SubmitButton from '@@src/components/buttons/submit_button'
import defineQueryParams from '@@src/components/define_query_params'
import {
  STATUS_FILTER_PARAM,
  TYPE_FILTER_PARAM,
  NETWORK_ASSET_TYPE_FILTER_PARAM,
  FROM_FILTER_PARAM,
  TO_FILTER_PARAM,
  DATE_URL_FORMAT,
  DATE_AND_TIME_DISPLAY_FORMAT,
} from '@@src/alerts_path/filters_panel'
import withSourceFilterProvider from
'@@src/components/forms/source_filter_provider'
import LoadingIcon from '@@src/components/loading_icon'
import NetworkAsset from '@@src/api/presenters/network_asset'
import { MixedGroupDetailsType } from '@@src/api/presenters/group'
import { formatTimestampForAPI, getMappedCongruityTypes } from '@@src/utils'
import { SOURCE_FILTER_PARAM } from '@@src/components/forms/source_filter'
import { PagedAlertsContext } from '@@src/alerts_path/paged_alerts_provider'
import AsyncResultSwitch from '@@src/components/async_result_switch'

import styles from './export_csv_modal.css'

class ExportCsvModal extends React.PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    result: PropTypes.instanceOf(AsyncResult).isRequired,
    location: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    handleToggle: PropTypes.func.isRequired,
    alertStatuses: PropTypes.array,
    alertType: PropTypes.string,
    networkAssetType: PropTypes.string,
    dateFrom: PropTypes.string,
    dateTo: PropTypes.string,
    sourceFilter: PropTypes.string,
    networkAssetSourceFilterResult:
      PropTypes.instanceOf(AsyncResult).isRequired,
    searchQuery: PropTypes.string,
    groupSourceFilterResult: PropTypes.instanceOf(AsyncResult).isRequired,
    pagedAlertsResult: PropTypes.instanceOf(AsyncResult).isRequired,
    children: PropTypes.node,
  }

  static defaultProps = {
    isOpen: false,
  }

  render() {
    const {
      isOpen,
      handleToggle,
      t,
      result,
      alertStatuses,
      alertType,
      networkAssetType,
      searchQuery,
      children,
      pagedAlertsResult,
    } = this.props

    return (
      <Modal isOpen={isOpen} toggle={handleToggle}>

        <ModalHeader>
          {t('headings.modal_heading')}
        </ModalHeader>

        <ModalBody>
          <AsyncResultSwitch
            renderSuccessResult={this.renderPagedAlertsTotal}
            result={pagedAlertsResult} />
          {children}
          <div className={styles['export-label']}>
            {t('text.search')}
          </div>
          <div className="font-weight-bold mb-3">
            { searchQuery ? searchQuery : t('text.all') }
          </div>
          <div className={styles['export-label']}>
            {t('text.time_range')}
          </div>
          <div className="font-weight-bold mb-3">
            {this.getFormattedTimeRange()}
          </div>
          <div className={styles['export-label']}>
            {t('text.source')}
          </div>
          <div className="font-weight-bold mb-3">
            {this.renderSourceFilter()}
          </div>
          <div className={styles['export-label']}>
            {t('text.alert_type')}
          </div>
          <div className="font-weight-bold mb-3">
            {
              alertType ?
                t(`common/text:alerts.${alertType}_type`) : t('text.any')
            }
          </div>
          <div className={styles['export-label']}>
            {t('text.alert_status')}
          </div>
          <div className="font-weight-bold mb-3 text-capitalize">
            {
              alertStatuses.length > 0
                ? this.createTypeList(alertStatuses)
                : t('text.any')
            }
          </div>
          <div className={styles['export-label']}>
            {t('text.network_asset_type')}
          </div>
          <div className="font-weight-bold">
            {
              networkAssetType ?
                t(`common/text:text.${networkAssetType}`) : t('text.any')
            }
          </div>
        </ModalBody>

        <ModalFooter>
          <Button name="cancel-button" color="secondary" onClick={handleToggle}>
            {t('buttons.cancel')}
          </Button>

          <SubmitButton
            className={classnames({
              'is-loading': pagedAlertsResult.isPending(),
            })}
            disabled={this.selectIsSubmitDisabled(this.props)}
            name="export-button"
            color="primary"
            result={result}
            submitText={t('buttons.export')}
            onSubmitForm={this.handleSubmit}
          />
        </ModalFooter>
      </Modal>
    )
  }

  createTypeList = (array) => {
    const createNewString = (totalString, newString, index, src) => {
      if (src.length === index - 1) {
        return `${totalString} ${newString}`
      }
      return `${totalString}, ${newString}`
    }
    const assembledStrings = array.reduce(createNewString)
    return assembledStrings
  }

  getFormattedTimeRange = () => {
    const { t, dateFrom, dateTo } = this.props
    const momentDateFrom = moment(dateFrom, DATE_URL_FORMAT)
    const momentDateTo = moment(dateTo, DATE_URL_FORMAT)
    const separator = (
      <span className="far fa-arrow-right mx-2" />
    )

    if (!momentDateFrom.isValid() && !momentDateTo.isValid()) {
      return t('text.all')
    } else if (momentDateFrom.isValid() && !momentDateTo.isValid()) {
      return (
        <React.Fragment>
          {momentDateFrom.format(DATE_AND_TIME_DISPLAY_FORMAT)}
          {separator}
          {t('text.now')}
        </React.Fragment>
      )
    } else if (!momentDateFrom.isValid() && momentDateTo.isValid()) {
      return (
        <React.Fragment>
          ...
          {separator}
          {momentDateTo.format(DATE_AND_TIME_DISPLAY_FORMAT)}
        </React.Fragment>
      )
    } else {
      return (
        <React.Fragment>
          {momentDateFrom.format(DATE_AND_TIME_DISPLAY_FORMAT)}
          {separator}
          {momentDateTo.format(DATE_AND_TIME_DISPLAY_FORMAT)}
        </React.Fragment>
      )
    }
  }

  renderSourceFilter = () => {
    const {
      t, sourceFilter, networkAssetSourceFilterResult, groupSourceFilterResult,
    } = this.props

    if (networkAssetSourceFilterResult.isPending() ||
      groupSourceFilterResult.isPending()) {
      return (
        <LoadingIcon className="text-center" />
      )
    }

    if (sourceFilter && networkAssetSourceFilterResult.wasSuccessful() &&
      NetworkAsset.isNetworkAssetDataSourceId(sourceFilter)) {
      return (
        <div className="d-flex flex-column align-items-start">
          {networkAssetSourceFilterResult.data.assetId}
          {
            networkAssetSourceFilterResult.data.assetName ?
              <small className="text-muted">
                {networkAssetSourceFilterResult.data.assetName}
              </small>
              : null
          }
        </div>
      )
    } else if (sourceFilter && groupSourceFilterResult.wasSuccessful() &&
      MixedGroupDetailsType.parseIdFromDataSourceId(sourceFilter)) {
      return groupSourceFilterResult.data.name
    }

    return t('text.any')
  }

  renderPagedAlertsTotal = ({ data }) => {
    const { t } = this.props

    return (
      <p className="font-italic">
        {t('text.result_count', { count: data.pagination.totalResults })}
      </p>
    )
  }

  handleSubmit = () => {
    const {
      handleSubmit,
      alertStatuses,
      alertType,
      networkAssetType,
      dateFrom,
      dateTo,
      sourceFilter,
      searchQuery,
    } = this.props
    const variables = {
      alertStatuses,
      alertType,
      networkAssetType: getMappedCongruityTypes([networkAssetType]),
      dateFrom: dateFrom ? formatTimestampForAPI(dateFrom) : undefined,
      dateTo: dateTo ? formatTimestampForAPI(dateTo) : undefined,
      searchQuery,
    }

    if (NetworkAsset.isNetworkAssetDataSourceId(sourceFilter)) {
      variables.networkAssetSourceId =
        NetworkAsset.parseIdFromDataSourceId(sourceFilter)
    } else if (MixedGroupDetailsType.isGroupDetailsDataSourceId(sourceFilter)) {
      variables.groupSourceId = MixedGroupDetailsType.parseIdFromDataSourceId(
        sourceFilter
      )
    }

    handleSubmit(variables)
  }

  selectIsSubmitDisabled = createSelector(
    [get('pagedAlertsResult')],
    pagedAlertsResult => {
      return !pagedAlertsResult.wasSuccessful() ||
        pagedAlertsResult.data.pagination.totalResults === 0
    }
  )
}

function ExportCSVModalWithProviders(props) {
  return (
    <PagedAlertsContext.Consumer>
      {
        ({ pagedAlertsResult }) => (
          <ExportCsvModal pagedAlertsResult={pagedAlertsResult} {...props} />
        )
      }
    </PagedAlertsContext.Consumer>
  )
}

export default compose(
  defineQueryParams({
    alertStatuses: {
      alias: STATUS_FILTER_PARAM,
      parseFunc: defineQueryParams.parseArray,
    },
    alertType: { alias: TYPE_FILTER_PARAM },
    networkAssetType: { alias: NETWORK_ASSET_TYPE_FILTER_PARAM },
    dateFrom: { alias: FROM_FILTER_PARAM },
    dateTo: { alias: TO_FILTER_PARAM },
    searchQuery: { alias: 's' },
    sourceFilter: { alias: SOURCE_FILTER_PARAM },
  }),
  withTranslation([
    'src/alerts_path/export_csv_modal',
    'common/text',
  ]),
  withSourceFilterProvider,
)(ExportCSVModalWithProviders)
