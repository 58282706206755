import gql from 'graphql-tag'
import React from 'react'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import { get } from 'lodash/fp/object'
import { map, partition, flatMap } from 'lodash/fp/collection'
import { difference } from 'lodash/fp/array'
import { flow } from 'lodash/fp/util'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { graphql } from '@apollo/client/react/hoc'
import { createSelector } from 'reselect'
import { withTranslation } from 'react-i18next'
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap'

import * as Changes from '@@src/store/changes'
import * as analytics from '@@src/analytics'
import FormFields from '@@src/utils/form_fields'
import AsyncResult from '@@src/utils/async_result'
import AppFormGroup from '@@src/components/forms/app_form_group'
import SubmitButton from '@@src/components/buttons/submit_button'
import ExportLegendList
from '@@src/components/dropdowns/export_dropdown_with_modal/export_legend_list'
import { SECONDS, MINUTES, HOURS, DAYS } from '@@src/utils'
import { PressureSubsetDataContext } from
'@@src/analysis_path/pressure_analysis_path/pressure_subset_data_provider'
import { CpisDataContext } from
'@@src/analysis_path/pressure_analysis_path/cpis_view/cpis_data_provider'
import Device from '@@src/api/presenters/device'

import styles from './csv_export_configuration_modal.css'

import {
  EXPORT_RAW_DATA_TYPE,
  EXPORT_PRESSURE_DATA_TYPE,
} from '@@src/components/dropdowns/export_dropdown_with_modal/export_dropdown_with_modal'

const DATA_POINTS_LIMIT = 610000
const DISPLAY_TIME_FORMAT = 'MMM D, YYYY h:mm A'
const AVAILABLE_RESOLUTIONS = [
  1 * SECONDS,
  30 * SECONDS,
  1 * MINUTES,
  5 * MINUTES,
  15 * MINUTES,
  30 * MINUTES,
  1 * HOURS,
  6 * HOURS,
  12 * HOURS,
  1 * DAYS,
]

export const MAX_DAYS_LIMIT = 180

class CsvExportConfigurationModal extends React.PureComponent {
  static defaultProps = {
    onExportSuccess: () => { },
  }

  static propTypes = {
    isOpen: PropTypes.bool,
    toggle: PropTypes.func,
    endTime: PropTypes.instanceOf(Date).isRequired,
    dataType: PropTypes.string.isRequired,
    startTime: PropTypes.instanceOf(Date).isRequired,
    timeZone: PropTypes.string.isRequired,
    legendItems: PropTypes.array.isRequired,
    onExportSuccess: PropTypes.func.isRequired,
    pressureSubsetDataResult: PropTypes.instanceOf(AsyncResult).isRequired,
    cpisDataResult: PropTypes.instanceOf(AsyncResult).isRequired,
  }

  render() {
    const {
      t, dataType, legendItems, isOpen, startTime, endTime, timeZone,
    } = this.props
    const { result, selectedLegendItemIds } = this.state

    const minResolution = this.selectMinResolution(this.props)

    if(!legendItems || !legendItems.length) {
      return null
    }

    return (
      <Modal isOpen={isOpen} toggle={this.handleToggle}>
        <ModalHeader>
          { dataType === EXPORT_RAW_DATA_TYPE ?
            t('headings.raw_modal_heading') :
            t('headings.modal_heading')
          }
        </ModalHeader>

        <ModalBody>
          <div className="d-flex flex-column">
            <small>
              {t('text.time_range')}
            </small>

            <strong>
              {moment(startTime).tz(timeZone).format(DISPLAY_TIME_FORMAT)}
              &nbsp;
              <i className="far fa-long-arrow-alt-right"></i>
              &nbsp;
              {moment(endTime).tz(timeZone).format(DISPLAY_TIME_FORMAT)}
            </strong>
          </div>

          <div className="d-flex flex-column mt-2">
            <small>
              {t('text.time_zone')}
            </small>

            <strong>
              {timeZone}
            </strong>
          </div>

          {
            dataType === EXPORT_PRESSURE_DATA_TYPE ? (
              <section className="mt-4">
                <AppFormGroup
                  hint={
                    minResolution > AVAILABLE_RESOLUTIONS[0] ?
                      t('text.min_resolution_updated') :
                      ''
                  }
                  name="resolution-input"
                  type="select"
                  label={t('labels.resolution')}
                  value={this.selectFieldValue('resolution')}
                  onChange={this.fields.onChangeHandlerFor('resolution')}
                  errorText={this.selectFieldErrorText('resolution')}>
                  {
                    AVAILABLE_RESOLUTIONS.map(resolution => (
                      <option
                        key={resolution}
                        name="resolution-option"
                        value={resolution}
                        disabled={resolution < minResolution}>
                        {formatResolution(t, resolution)}
                      </option>
                    ))
                  }
                </AppFormGroup>
              </section>
            ) : null
          }

          <section className="mt-4">
            <h4 className="mb-3">
              {t('headings.data_sources')}
            </h4>

            <ol className={styles['graph-item-list']}>
              {legendItems.map(this.renderLegendItem)}
            </ol>
          </section>
        </ModalBody>

        <ModalFooter>
          <Button
            name="cancel-button"
            color="secondary"
            onClick={this.handleToggle}>
            {t('buttons.cancel')}
          </Button>

          <SubmitButton
            disabled={selectedLegendItemIds.length === 0}
            name="export-button"
            color="primary"
            result={result}
            submitText={t('buttons.export')}
            onSubmitForm={this.onExport}
          />
        </ModalFooter>
      </Modal>
    )
  }

  renderLegendItem = legendItem => {
    const { cpisDataResult, dataType } = this.props
    const { selectedLegendItemIds } = this.state

    return (
      <li key={legendItem.id}>
        <ExportLegendList
          getHandleSelectLegendItem={this.getHandleSelectLegendItem}
          selectedLegendItemIds={selectedLegendItemIds}
          legendItem={legendItem}
          dataType={dataType}
          dataResult={
            dataType === EXPORT_PRESSURE_DATA_TYPE ?
              this.selectPressureDataWithPaginationResult(this.props) :
              cpisDataResult
          } />
      </li>
    )
  }

  constructor(props) {
    super(props)

    this.fields = new FormFields(this, 'csvExportFields', {
      resolution: v => v ? '' : 'errors.required',
    })

    const minResolution = this.selectMinResolution(props)
    this.state = {
      csvExportFields: this.fields.initialState({
        resolution: AVAILABLE_RESOLUTIONS.find(resolution => {
          return resolution >= minResolution
        }),
      }),
      selectedLegendItemIds: props.dataType === EXPORT_RAW_DATA_TYPE ?
        props.legendItems.map(l => l.id) : [],
      result: AsyncResult.notFound(),
    }
  }

  componentDidUpdate(prevProps) {
    const prevMinResolution = this.selectMinResolution(prevProps)
    const minResolution = this.selectMinResolution(this.props)

    if (minResolution !== prevMinResolution) {
      this.setState({
        csvExportFields: this.fields.initialState({
          resolution: AVAILABLE_RESOLUTIONS.find(resolution => {
            return resolution >= minResolution
          }),
        }),
      })
    }
  }

  getHandleSelectLegendItem = legendItemIds => {
    return () => {
      const { selectedLegendItemIds } = this.state
      const unselectedIds = difference(legendItemIds, selectedLegendItemIds)

      if (unselectedIds.length === 0) {
        this.setState({
          selectedLegendItemIds: difference(
            selectedLegendItemIds,
            legendItemIds
          ),
        })
      } else {
        this.setState({
          selectedLegendItemIds: [...selectedLegendItemIds, ...unselectedIds],
        })
      }
    }
  }

  selectFieldValue(fieldName) {
    return this.fields.selectValue(this.state, fieldName)
  }

  selectFieldErrorText(fieldName) {
    const error = this.fields.selectError(this.state, fieldName)

    return error ? this.props.t(error) : error
  }

  selectPressureDataWithPaginationResult = createSelector(
    [get('pressureSubsetDataResult')],
    result => result.map(d => d ? d.data : d)
  )

  selectMinResolution = createSelector(
    [get('startTime'), get('endTime')],
    (startTime, endTime) => {
      return (Number(endTime) - Number(startTime)) / DATA_POINTS_LIMIT
    }
  )

  onExport = async event => {
    event.preventDefault()

    this.setState({ result: AsyncResult.pending() })

    try {
      const {
        startTime, endTime, timeZone, dataType, requestDataReport, legendItems,
      } = this.props
      const { selectedLegendItemIds } = this.state
      const childrenMapper = legendItem => legendItem.hasChildren() ?
        legendItem.children : legendItem

      const [deviceSourceIds, assetSourceIds] = partition(itemId => {
        return Device.isDeviceDataSourceId(itemId)
      })(selectedLegendItemIds)
      const deviceIds = deviceSourceIds.map(Device.parseIdFromDataSourceId)
      const flatLegendItems = flow(
        flatMap(childrenMapper),
        flatMap(childrenMapper)
      )(legendItems)
      const networkAssetChannels = map(assetId => {
        const legendItem = flatLegendItems.find(i => i.id === assetId)

        return {
          networkAssetId: legendItem.sourceId,
          channel: legendItem.sourceChannel,
        }
      })(assetSourceIds)

      const resolution = dataType === EXPORT_PRESSURE_DATA_TYPE ?
        this.selectFieldValue('resolution') / SECONDS :
        1 * HOURS // this is never used by the API anyway

      await requestDataReport({
        variables: {
          end: endTime.toISOString(),
          start: startTime.toISOString(),
          dataType,
          reportType: 'csv',
          resolution,
          deviceIds,
          networkAssetChannels,
          timezoneForData: timeZone,
        },
      })

      this.props.dispatchNotifyDownloadsChanged()
      this.setState({ result: AsyncResult.success() })

      await this.props.onExportSuccess()
      await this.handleToggle()
    } catch (err) {
      analytics.logError(err)

      this.setState({ result: AsyncResult.fail(err) })
    }
  }

  handleToggle = async () => {
    const { toggle } = this.props

    this.setState({
      selectedLegendItemIds: [],
    })

    await toggle()
  }

  static REQUEST_DOWNLOADS_QUERY = gql`
    mutation RequestDataReport(
      $dataType: RequestedDataTypes!,
      $reportType: RequestedReportTypes!,
      $networkAssetChannels: [NetworkAssetChannel]!,
      $deviceIds: [Int],
      $start: String!,
      $end: String!,
      $resolution: Int!,
      $timezoneForData: String,
    ) {
      requestDataReport(
        dataType: $dataType,
        reportType: $reportType,
        networkAssetChannels: $networkAssetChannels,
        deviceIds: $deviceIds,
        start: $start,
        end: $end
        resolution: $resolution,
        timezoneForData: $timezoneForData,
      ) {
        jobIds
      }
    }
  `
}

function formatResolution(t, resolution) {
  switch (true) {
    case (resolution % DAYS === 0):
      return t('text.resolution_option_days', { count: resolution / DAYS })

    case (resolution % HOURS === 0):
      return t('text.resolution_option_hours', { count: resolution / HOURS })

    case (resolution % MINUTES === 0):
      return t('text.resolution_option_minutes', {
        count: resolution / MINUTES,
      })

    default:
      return t('text.resolution_option_seconds', {
        count: resolution / SECONDS,
      })
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchNotifyDownloadsChanged() {
      dispatch(Changes.notifyChange(Changes.CSV_DOWNLOAD_CHANGES))
    },
  }
}

function CsvExportConfigurationModalContainer(props) {
  return (
    <PressureSubsetDataContext.Consumer>
      {({ pressureSubsetDataResult }) => (
        <CpisDataContext.Consumer>
          {({ cpisDataResult }) => (
            <CsvExportConfigurationModal
              cpisDataResult={cpisDataResult}
              pressureSubsetDataResult={pressureSubsetDataResult}
              {...props} />
          )}
        </CpisDataContext.Consumer>
      )}
    </PressureSubsetDataContext.Consumer>
  )
}

export default compose(
  withTranslation([
    'src/analysis_path/pressure_analysis_path/csv_export_configuration_modal',
    'common/text',
  ]),
  connect(null, mapDispatchToProps),
  graphql(CsvExportConfigurationModal.REQUEST_DOWNLOADS_QUERY, {
    name: 'requestDataReport',
    skip: ({ isOpen }) => !isOpen,
  }),
)(CsvExportConfigurationModalContainer)
