import gql from 'graphql-tag'
export const ANOMALIES_DATA_QUERY = gql`
query GetAnomaliesPlot(
	$start: String,
	$end: String,
	$sources: [AnomalySource],
	$pressureUnit: PressureUnit,
) {
  getAnomalies(
    startTime: $start
    endTime: $end
    sources: $sources
    pressureUnit: $pressureUnit
  ) {
    anomalies {
      startTime
      endTime
      type
	  deviceId
      networkAssetId
      networkAssetReadableId
      networkAssetChannel
      duration
      avg
      min
      max
      diff
    }
  }
}
`
export const ANOMALIES_BOUNDS_DATA_QUERY = gql`
query GetAnomaliesBounds (
	$start: String!,
	$end: String!,
	$source: AnomalySource,
	$pressureUnit: PressureUnit
) {
	getAnomaliesBounds(
		startTime: $start
		endTime: $end
		source: $source,
		pressureUnit: $pressureUnit
	) {
		startTime
		endTime
		upperBound
		lowerBound
	}
}
`
export const RAW_DATA_QUERY = gql`
query RawDataQuery(
  $end: DateString!,
  $skip: Boolean!,
  $start: DateString!,
  $pageNumber: Int!,
  $networkAssetChannels: [NetworkAssetChannel]!,
) {
  pagedRawData(
    end: $end,
    start: $start,
    pageNumber: $pageNumber,
    networkAssetChannels: $networkAssetChannels,
  ) @skip(if: $skip) {
    requests {
      channel
      dataEnd
      deviceId
      dataStart
      networkAssetId

      data {
        time
        value
      }
    }

    pagination {
      pageNumber
      totalPages
    }
  }
}
`

export const PAGED_SUBSET_QUERY = gql`
query PagedPressureSubsetData(
  $end: DateString!,
  $start: DateString!,
  $deviceIds: [Int],
  $resolution: Int!,
  $networkAssetChannels: [NetworkAssetChannel],
  $pageNumber: Int!,
  $sourcesPerPage: Int!,
) {
  pagedPressureSubsetData(
    end: $end,
    start: $start,
    deviceIds: $deviceIds,
    pageNumber: $pageNumber,
    resolution: $resolution,
    sourcesPerPage: $sourcesPerPage,
    networkAssetChannels: $networkAssetChannels,
  ) {
    data {
      ... on InstallationPressureSubsetDataChunk {
        end
        start
        channel
        deviceId
        networkAssetId
        data {
          time
          min
          max
          mean
        }
      }

      ... on CommissionPressureSubsetDataChunk {
        end
        start
        deviceId
        data {
          time
          min
          max
          mean
        }
      }
    }

    pagination {
      pageNumber
      totalPages
    }
  }
}
`

export const PRESSURE_EVENTS_GRAPH_QUERY = gql`
  query GetAssetData(
    $assetId: Int!,
    $slicesCount: Int!,
    $assetChannel: String!,
    $timeRange: GetEventsPlotClustersTimeRangeInput!
  ) {
    getEventsPlotClusters(
      assetId: $assetId
      assetChannel: $assetChannel
      slicesCount: $slicesCount
      timeRange: $timeRange
    ) {
      assetId,
      eventsSeverity,
      maxEventSeverity
      eventsCpis,
      maxEventCpis,
      assetChannel,
      eventsCount,
      sliceUtcStart,
      sliceUtcEnd,
      singleEventDetails {
        eventId,
        utcStart,
        utcEnd
      }
    }
  }
`

export const PRESSURE_EVENTS_QUERY = gql`
  query PressureEventsData(
    $end: String!,
    $start: String!,
    $pageNumber: Int!,
    $resultsPerPage: Int!,
    $networkAssetChannels: [NetworkAssetChannel],
    $eventIds: [Int]
  ) {
    pagedPressureEvents(
      end: $end,
      start: $start,
      pageNumber: $pageNumber,
      resultsPerPage: $resultsPerPage,
      networkAssetChannels: $networkAssetChannels,
      eventIds: $eventIds
    ) {
      page
      totalPages
      totalResults
      pressureEvents {
        id
        assetId
        endTime
        startTime
        maxPressure
        minPressure
        meanPressure
        logicalChannel
        eventCharacteristics {
          id
          value
          eventCharacteristic
        }
        eventClasses {
          id
          eventClass
        }
      }
    }
  }
`
// change dateFrom to start and dateTo to end
export const PAGED_ALERTS_QUERY = gql`
  query AlertsForPressureData(
    $pageNumber: Int!,
    $resultsPerPage: Int!,
    $dateTo: String!,
    $dateFrom: String!,
    $networkAssetSourceIds: [Int]!,
    $skip: Boolean!
  ) {
    pagedAlerts(
      dateTo: $dateTo,
      dateFrom: $dateFrom,
      pageNumber: $pageNumber,
      resultsPerPage: $resultsPerPage,
      networkAssetSourceIds: $networkAssetSourceIds,
    ) @skip(if: $skip) {
      alerts {
        type
        alertId
        occurredAt

        details {
          ... on StandardAlertDetails {
            networkAssetId
            networkAssetName
            networkAssetFriendlyName
            networkAssetType
            deviceId
            deviceSerialNumber
            logicalChannel
            threshold
            value
            unit
          }
          ... on DeviceNoCommsAlertDetails {
            deviceId
            deviceSerialNumber
            threshold
            unit
          }
          ... on EventSourceLocalisationAlertDetails {
            groupId
            groupName
            localisationId
            eventIds
            eventsStartDate
            eventsEndDate
          }
          ... on EventAlertDetails {
            networkAssetId
            networkAssetName
            networkAssetFriendlyName
            networkAssetType
            deviceId
            logicalChannel
            eventCharacteristic
            eventId
            startTimestamp
            endTimestamp
            value
            threshold
            unit
          }
        }
      }

      pagination {
        pageNumber
        totalPages
        totalResults
      }
    }
  }
`
export const LIST_RAW_DATA_REQUESTS_QUERY = gql`
query ListRawDataRequests(
  $start: String!,
  $end: String!,
  $networkAssetChannels: [NetworkAssetChannel],
) {
  listRawDataRequests (
    start: $start,
    end: $end,
    networkAssetChannels: $networkAssetChannels,
  ) {
    rawDataRequests {
      startTime
      endTime
      networkAsset {
        networkAssetId
        channel
      }
      deviceId
      status
    }
    networkAssetsMinutesLeft {
      networkAssetId
      channelsMinutesLeft {
        channel
        minutesLeft {
          date
          minutes
        }
      }
    }
  }
}
`
