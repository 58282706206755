import gql from 'graphql-tag'
import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { graphql } from '@apollo/client/react/hoc'
import { withTranslation } from 'react-i18next'

import * as analytics from '@@src/analytics'
import ErrorInfo from '@@src/components/error_info'
import FormFields from '@@src/utils/form_fields'
import AppFormGroup from '@@src/components/forms/app_form_group'
import SubmitButton from '@@src/components/buttons/submit_button'
import { AsyncResult, validRoles, createSelectGraphQLResult } from '@@src/utils'
import { parseGraphQLResult } from '@@src/api/presenters'
import transformProps from '@@src/components/transform_props'
import {
  Row, Col, Button, Modal, ModalBody, ModalHeader, ModalFooter, Form,
} from 'reactstrap'
import userPermissions from '@@src/components/permissions/user_permissions'

import styles from './create_user_modal.css'
import { TenantSettingsContext } from
'@@src/components/tenant_settings_provider'

class CreateUserModal extends React.PureComponent {
  static defaultProps = {
    onSuccess: () => { },
  }

  static propTypes = {
    onSuccess: PropTypes.func.isRequired,
    tenantSettings: PropTypes.object,
  }

  render() {
    const { t, isOpen } = this.props
    const { result } = this.state

    return (
      <Form>
        <Modal
          isOpen={isOpen}
          toggle={this.onToggle}>
          <ModalHeader toggle={this.toggle}>
            {t('headings.title')}
          </ModalHeader>

          <ModalBody>
            {result.wasFailure() ? <ErrorInfo error={result.error}/> : null}

            <Row>
              <Col sm="12">
                {this.renderForm()}
              </Col>
            </Row>
          </ModalBody>

          <ModalFooter className={styles['buttons-row']}>
            <Button
              name="cancel-button"
              type="button"
              color="secondary"
              onClick={this.onCancel}>
              {t('buttons.cancel')}
            </Button>

            <SubmitButton
              name="create-button"
              color="primary"
              result={result}
              disabled={this.fields.hasAnyValidationErrors()}
              buttonStatus=""
              onSubmitForm={this.onSubmitForm}
              submitText={t('buttons.create')}>
            </SubmitButton>
          </ModalFooter>
        </Modal>
      </Form>
    )
  }

  constructor(props) {
    super(props)

    this.fields = new FormFields(this, 'userFields', {
      firstName: v => v ? '' : 'errors.required',
      lastName: v => v ? '' : 'errors.required',
      email: v => v ? '' : 'errors.required',
      role: v => v ? '' : 'errors.required',
      language: v => v ? '' : 'errors.required',
    })

    this.state = {
      result: AsyncResult.notFound(),
      userFields: this.fields.initialState({
        role: 'viewer',
        language: 'en',
      }),
    }
  }

  renderForm = () => {
    const { t, loggedInUser, tenantSettings } = this.props
    const { mfaEnabled } = loggedInUser.data
    const { languagesAvailable } = tenantSettings

    return (
      <React.Fragment>
        <AppFormGroup
          name="firstName"
          type="text"
          label={t('labels.first_name')}
          value={this.selectFieldValue('firstName')}
          onChange={this.fields.onChangeHandlerFor('firstName')}
          errorText={this.selectFieldErrorText('firstName')}>
        </AppFormGroup>

        <AppFormGroup
          name="lastName"
          type="text"
          label={t('labels.last_name')}
          value={this.selectFieldValue('lastName')}
          onChange={this.fields.onChangeHandlerFor('lastName')}
          errorText={this.selectFieldErrorText('lastName')}>
        </AppFormGroup>

        <AppFormGroup
          name="email"
          type="email"
          label={t('labels.email')}
          value={this.selectFieldValue('email')}
          onChange={this.fields.onChangeHandlerFor('email')}
          errorText={this.selectFieldErrorText('email')}>
        </AppFormGroup>

        { mfaEnabled ?
          <AppFormGroup
            name="phoneNumber"
            type="text"
            hint="+447711223344"
            label={t('labels.phoneNumber')}
            value={this.selectFieldValue('phoneNumber')}
            onChange={this.fields.onChangeHandlerFor('phoneNumber')}
            errorText={this.selectFieldErrorText('phoneNumber')}>
          </AppFormGroup>
          : null }

        <AppFormGroup
          name="roles"
          type="select"
          label={t('labels.role')}
          value={this.selectFieldValue('role')}
          onChange={this.fields.onChangeHandlerFor('role')}
          errorText={this.selectFieldErrorText('role')}>
          {
            validRoles().map(role => (
              <option key={role} value={role}>
                {t(`common/text:text.role_${role}`)}
              </option>
            ))
          }
        </AppFormGroup>

        <AppFormGroup
          name="language"
          type="select"
          label={t('common/forms:labels.language')}
          value={this.selectFieldValue('language')}
          onChange={this.fields.onChangeHandlerFor('language')}
          errorText={this.selectFieldErrorText('language')}
        >
          {
            languagesAvailable.map((localecode) => (
              <option
                key={localecode}
                value={localecode}
              >
                {t(`common/forms:language.${localecode}`)}
              </option>
            ))
          }
        </AppFormGroup>

      </React.Fragment>
    )
  }

  componentDidUpdate(prevProps) {
    const { tenantSettings } = this.props

    if (prevProps.tenantSettings !== tenantSettings) {
      this.fields.updateFieldValue('language', tenantSettings.language)
    }
  }

  selectFieldValue(fieldName) {
    return this.fields.selectValue(this.state, fieldName)
  }

  selectFieldErrorText(fieldName) {
    const error = this.fields.selectError(this.state, fieldName)

    return error ? this.props.t(error) : error
  }

  onCancel = () => {
    this.onToggle()
  }

  onToggle = (...args) => {
    const { toggle } = this.props
    this.resetState()

    if (toggle) {
      toggle(...args)
    }
  }

  resetState = () => {
    this.setState({
      result: AsyncResult.notFound(),
    })
  }

  onSubmitForm = async event => {
    const {
      tenantSettings: {
        units: {
          temperature, pressure, distance,
        },
      },
    } = this.props
    event.preventDefault()

    this.setState({ result: AsyncResult.pending() })

    try {
      const res = await this.fields.performPreSubmitChecks().catch(() => false)
      if (!res) {
        this.setState({ result: AsyncResult.notFound() })
        return
      }

      const firstName = this.selectFieldValue('firstName')
      const lastName = this.selectFieldValue('lastName')
      const email = this.selectFieldValue('email')
      const phoneNumber = this.selectFieldValue('phoneNumber')
      const newRole = this.selectFieldValue('role')
      const language = this.selectFieldValue('language')
      const roleId = this.getRoleIdForName(newRole)

      const userNumber = phoneNumber === '' ? undefined : phoneNumber
      await this.props.createUser({
        variables: { firstName,
          lastName,
          email,
          phoneNumber: userNumber,
          roleId,
          settings: {
            language,
            units: {
              temperature,
              pressure,
              distance,
            },
          },
        },
      })
      this.setState({ result: AsyncResult.success() })

      this.onToggle()
      this.props.onSuccess()
    } catch (e) {
      analytics.logError(e)
      this.setState({ result: AsyncResult.fail(e) })
    }
  }

  getRoleIdForName = (name) => {
    const { roles } = this.props
    const role = roles.filter((r) => {
      return r.name === name
    })
    return role[0].id
  }

  static CREATE_USER_MUTATION = gql`
    mutation CreateUser (
      $firstName: String!,
      $lastName: String!,
      $phoneNumber: String,
      $email: String!,
      $roleId: Int!
      $settings: UserSettingsObject!
    ) {
      createUser (
        firstName: $firstName,
        lastName: $lastName,
        phoneNumber: $phoneNumber,
        email: $email,
        roleId: $roleId
        settings: $settings
      )
    }
  `
}

const CreateUserModalContainer = props => (
  <TenantSettingsContext.Consumer>
    {
      ({
        settings: tenantSettings,
      } = {}) => (
        <CreateUserModal
          tenantSettings={tenantSettings}
          {...props} />
      )
    }
  </TenantSettingsContext.Consumer>
)

export default compose(
  userPermissions,
  transformProps(() => () => ({
    selectTenantSettingsResult: createSelectGraphQLResult('tenantSettings', {
      mapResult: parseGraphQLResult,
    }),
  })),
  graphql(CreateUserModal.CREATE_USER_MUTATION, {
    name: 'createUser',
  }),
  withTranslation([
    'src/admin_path/users/create_users_modal',
    'common/text',
    'common/forms',
  ])
)(CreateUserModalContainer)
