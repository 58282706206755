import React from 'react'
import {
  Button,
} from 'reactstrap'
import { Link } from 'react-router-dom'
import DeviceIssueSeverityBadge from
'@@src/components/badges/device_issue_severity_badge'
import DeviceIconWithStatus
from '@@src/components/icons/device_icon_with_status'

import routes from '@@src/routes'
import styles from './index.css'

function ManagementGroupsDeviceList({ devices, onClick, canEdit }) {
  return (
    <ul className="list-unstyled px-4 py-0 bg-white rounded">
      {devices.map(device => {
        const mostSevereIssue = device.mostSevereIssue

        return (
          <li
            name="device-list-item"
            key={device.id}
            className={styles['group-device-item']}>
            <DeviceIconWithStatus
              className={styles['group-device-icon']}
              device={device} />

            <div className="d-flex align-items-center">
              <Link
                className="mr-2"
                to={routes.managementDevicesDetailPath(device.id)}>
                {device.serialNumber}
              </Link>
              {
                mostSevereIssue ?
                  <DeviceIssueSeverityBadge issue={mostSevereIssue} />
                  : null
              }
            </div>

            <Button
              name="remove-network-asset-button"
              color="link"
              disabled={!canEdit}
              outline
              onClick={onClick(device)}
              className="text-danger ml-auto flex-grow-0 flex-shrink-0">
              <i className="fas fa-minus-circle"></i>
            </Button>
          </li>
        )
      })}
    </ul>
  )
}

export default ManagementGroupsDeviceList
