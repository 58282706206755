/*
 * Transient Localisation Modals:
 *
 * A 'controller' for a transient localisation modal flow.
 *
 * - Pre modals, which limit:
 *   - Less than two sources
 *   - Less than ten minutes of data
 * - Request modal, openable on a valid request
 * - Post modal, which displays the user's email to notify them
 *
 *
 * the handleToggle function passed in from the pressure subset graph is used to control the modal _group_ from above
 * the modalState state entry is used to control which 'modal'/state is used
 */

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { createSelector } from 'reselect'
import { get } from 'lodash/fp/object'

import TransientLocalisationSuccessModal from
'@@src/components/graphs/modals/transient_localisation_modals/transient_localisation_success_modal' // eslint-disable-line max-len
import TransientLocalisationRequest from
'@@src/components/graphs/modals/transient_localisation_modals/transient_localisation_modal' // eslint-disable-line max-len
import TimeRangeTooLongModal from
'@@src/components/graphs/modals/transient_localisation_modals/time_range_too_long_modal' // eslint-disable-line max-len
import NotEnoughSourcesModal from
'@@src/components/graphs/modals/transient_localisation_modals/not_enough_sources_modal' // eslint-disable-line max-len
import GraphItem from '@@src/components/graphs/graph_item'
import AsyncResult from '@@src/utils/async_result'

// A redux-style const for each modal state - used to prevent spelling errors
const NOT_ENOUGH_SOURCES = 'not_enough_sources'
const TIME_RANGE_TOO_LONG = 'time_range_too_long'
const REQUEST_MODAL = 'request_modal'
export const LOCALISATION_STARTED = 'localisation_started'

export default class TransientLocalistionModalController extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleToggle: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    listRawDataRequestsResult: PropTypes.instanceOf(AsyncResult).isRequired,
    networkAssetGraphItems:
    PropTypes.arrayOf(PropTypes.instanceOf(GraphItem)).isRequired,
    timeFrom: PropTypes.instanceOf(Date).isRequired,
    timeTo: PropTypes.instanceOf(Date).isRequired,
  }

  // By default, the request modal will open. If necessary this will be overwritten in componentDidMount
  state = {
    modalType: REQUEST_MODAL,
  }

  // Select the number of assets total we have available (not channels)
  selectGraphAssets = createSelector(
    [get('networkAssetGraphItems')],
    networkAssetGraphItems => {
      const uniqueValues = networkAssetGraphItems.reduce(
        (accumulator, currentValue) => {
          const assetId = currentValue.legendItem.source.id
          // If we have a new unique asset Id, add it to the accumulator array
          if (!accumulator.includes(assetId)) {
            return [...accumulator, assetId]
          }

          return accumulator
        },
        []
      )

      return uniqueValues
    }
  )

  componentDidMount() {
    const {
      timeFrom,
      timeTo,
    } = this.props

    // Open 'too long' pre-modal if necessary
    if (this.isRequestOver10Minutes(timeTo, timeFrom)) {
      this.setModalType(TIME_RANGE_TOO_LONG)
      return
    }

    // If there are less than two total sources (assets), display dialog
    if (this.selectGraphAssets(this.props).length < 2) {
      this.setModalType(NOT_ENOUGH_SOURCES)
      return
    }
  }

  setModalType = (type) => {
    this.setState({ modalType: type })
  }

  isRequestOver10Minutes(timeTo, timeFrom) {
    // Is the request over 10 minutes? If so, return true
    return moment.duration(
      moment(timeTo)
        .diff(moment(timeFrom))
    ).asSeconds() > 600
  }

  render() {
    const { modalType } = this.state
    return (
      <>
        <NotEnoughSourcesModal
          name="not-enough-sources-modal"
          {...this.props}
          isOpen={modalType === NOT_ENOUGH_SOURCES}
        />
        <TimeRangeTooLongModal
          name="time-range-too-long-modal"
          {...this.props}
          isOpen={modalType === TIME_RANGE_TOO_LONG}
        />
        <TransientLocalisationRequest
          name="transient-localisation-request-modal"
          {...this.props}
          isOpen={modalType === REQUEST_MODAL}
          setModalType={this.setModalType}
        />
        <TransientLocalisationSuccessModal
          {...this.props}
          name="transient-localisation-request-success"
          isOpen={modalType === LOCALISATION_STARTED}
        />
      </>
    )
  }
}

