import React from 'react'
import { get } from 'lodash/fp/object'
import { compose } from 'redux'
import { graphql } from '@apollo/client/react/hoc'
import { isEqual } from 'lodash/fp/lang'
import { partition } from 'lodash/fp/collection'
import { createSelector } from 'reselect'

import PagedData from '@@src/api/presenters/paged_data'
import AsyncResult from '@@src/utils/async_result'
import transformProps from '@@src/components/transform_props'
import { parseGraphQLResult } from '@@src/api/presenters'
import { createSelectGraphQLResult } from '@@src/utils'

export default function incrementalRequestsLoaderContainer(config) {
  const {
    resultName, methodName, graphQLQuery, mergeData = simpleMergeData,
    computeNextVariables, computeInitialVariables,
    finalResultNullObject = [], shouldContinueFetch = () => true,
    componentProps = defaultComponentProps,
    createSelectMappedData = () => i => i,
    shouldResetData = defaultShouldResetData,
  } = config

  const skipFunc = config.skip || (() => false)
  const propsFunc = config.props || (() => ({}))

  const mapReduceReceivedData =
    createMemoizedMapReduce(createSelectMappedData, mergeData)

  return function createIncrementalRequestsLoaderContainer(Component) {
    class IncrementalRequestsLoaderContainer extends React.PureComponent {
      static defaultProps = {
        queryState: {},
        initialVars: {},
      }

      state = {
        receivedData: null,
      }

      render() {
        return (
          <Component {...this.selectChildProps(this.props, this.state)}/>
        )
      }

      selectChildPropsWithoutResult = createSelector(
        [get('ownProps'), get('otherProps')],
        (ownProps, otherProps) => ({ ...ownProps, ...otherProps })
      )

      selectMapReducedData = createSelector(
        [
          (_, state) => state.receivedData,
          this.selectChildPropsWithoutResult,
          get('queryState'),
        ],
        (data, childProps, queryState) => data ? (
          mapReduceReceivedData(data, childProps, queryState)
        ) : []
      )

      selectResult = createSelector(
        [
          (_, state) => state.receivedData,
          this.selectMapReducedData,
          get('queryState.loading'),
          (_, state) => state.error,
        ],
        (data, mapReducedData, isLoading, error) => {
          if (data === null && !isLoading) {
            return AsyncResult.notFound(finalResultNullObject)
          }

          if (!data || data.length === 0) {
            return AsyncResult.pending(finalResultNullObject)
          }

          if (isLoading) {
            return AsyncResult.pending(mapReducedData)
          }

          if (error) {
            return AsyncResult.fail(error, mapReducedData)
          }

          return AsyncResult.success(mapReducedData)
        }
      )

      selectChildProps = createSelector(
        [
          this.selectChildPropsWithoutResult,
          this.selectResult,
          get('queryState'),
        ],
        (inheritedProps, result, queryState) => componentProps({
          ...inheritedProps,
          [resultName]: result,
        }, queryState)
      )

      componentDidUpdate() {
        const { initialVars, queryState } = this.props
        const shouldSkip = skipFunc(this.props.ownProps)

        if (!queryState || shouldSkip) {
          if (this.state.receivedData) {
            this.setState({
              error: null,
              receivedData: null,
            })
          } else if (shouldSkip) {
            this.setState({ lastVariables: null })
          }
        } else if (
          !queryState.loading &&
          shouldResetData(
            queryState.variables,
            this.state.lastVariables,
            initialVars,
            this.state.initialVars,
          )
        ) {
          this.setState({
            error: null,
            initialVars,
            receivedData: [],
            lastVariables: queryState.variables,
          }, () => {
            this.onReceiveResult(
              initialVars,
              queryState,
              queryState.variables,
            )
          })
        } else if (
          !queryState.loading &&
          !isEqual(this.state.lastVariables, queryState.variables)
        ) {
          this.onReceiveResult(
            initialVars,
            queryState,
            queryState.variables,
          )
        }
      }

      onReceiveResult(initialVarsForOp, queryState, variables) {
        const currentPagedResult = this.props.selectPagedResult(queryState)
        const receivedData = isEqual(variables, this.props.initialVars) ?
          [] : this.state.receivedData

        const newReceivedData = currentPagedResult.data !== null ?
          receivedData.concat({ data: currentPagedResult.data, variables }) :
          receivedData

        if (currentPagedResult.wasSuccessful()) {
          const nextVariables =
            computeNextVariables(this.props, queryState[methodName], variables)
          const shouldFetch = shouldContinueFetch(
            nextVariables,
            this.props.ownProps,
          )

          this.setState({
            error: null,
            receivedData: newReceivedData,
            nextVariables,
            lastVariables: variables,
            wasFetchStopped: !shouldFetch,
          }, async () => {
            if (nextVariables && shouldFetch) {
              if (shouldResetData(
                nextVariables, variables,
                initialVarsForOp, this.state.initialVars,
              )) {
                return
              }

              this.props.queryState.refetch(nextVariables)
            }
          })
        } else {
          this.setState({
            error: currentPagedResult.error,
            receivedData: newReceivedData,
            nextVariables: null,
            lastVariables: variables,
          })
        }
      }
    }

    return compose(
      transformProps(() => {
        const selectPagedResult = createSelectGraphQLResult(methodName, {
          mapResult: parseGraphQLResult,
          nullObject: null,
        })

        return props => {
          const inputOpts = config.options(props)

          return {
            ownProps: props,
            initialVars: computeInitialVariables(props, inputOpts.variables),
            selectPagedResult,
          }
        }
      }),
      graphql(graphQLQuery, {
        skip: skipFunc,
        options: props => {
          const inputOpts = config.options(props)

          return {
            ...inputOpts,
            variables: props.initialVars,
            fetchPolicy: 'no-cache',
            notifyOnNetworkStatusChange: true,
          }
        },
        props: (...args) => {
          const { ownProps: ownPropsOuter, data } = args[0]
          const { selectPagedResult } = ownPropsOuter

          return {
            ownProps: ownPropsOuter.ownProps,
            queryState: data,
            otherProps: propsFunc(...args),
            currentPagedResult: selectPagedResult(data),
          }
        },
      })
    )(IncrementalRequestsLoaderContainer)
  }
}

export function fromFirstPage(props, variables) {
  return { ...variables, pageNumber: 1 }
}

export function incrementPageNumber(props, data, variables) {
  const { pageNumber } = variables

  return pageNumber >= data.pagination.totalPages ? null : {
    ...variables,
    pageNumber: pageNumber + 1,
  }
}

export function simpleMergeData(a, b) {
  return a.concat(b)
}

export function mergePagedData(a, b) {
  return new PagedData({
    data: a.data.concat(b.data),
    pagination: b.pagination,
  })
}

export function createMergePaginatedData(fieldName) {
  return (a, b) => {
    return {
      ...b,
      [fieldName]: a[fieldName].concat(b[fieldName]),
    }
  }
}

export function createDeepMergeData(isSameRequest = () => false) {
  return function mergeData(oldRequests, requests) {
    const [existingRequests, newRequests] =
      partition(reqA => oldRequests.some(reqB => {
        return isSameRequest(reqA, reqB)
      }))(requests)

    const mergedResults = oldRequests.map(oldReq => {
      const existingReq = existingRequests.find(req => {
        return isSameRequest(req, oldReq)
      })

      return existingReq ? ({
        ...oldReq,
        data: mergeTimeData(oldReq.data, existingReq.data),
      }) : oldReq
    })

    return mergedResults.concat(newRequests)
  }
}

// assumming the data is strictly ordered by time
function mergeTimeData(dataA, dataB) {
  if (dataA.length === 0) {
    return dataB
  } else if (dataB.length === 0) {
    return dataA
  } else if (dataA[0].time >= dataB[0].time) {
    return dataB.concat(dataA)
  } else {
    return dataA.concat(dataB)
  }
}

function defaultComponentProps(a) {
  return a
}

function defaultShouldResetData(vars, prevVars, initialVars, prevInitialVars) {
  return !isEqual(initialVars, prevInitialVars)
}

function createMemoizedMapReduce(createMap, reduce) {
  const mappers = []
  let previousResults = []

  const selectMappedCollection = createSelector(
    [i => i, (_, i) => i, (_a, _b, i) => i],
    (collection, props, queryState) => {
      const result = collection.map((item, index) => {
        const mapFunc = mappers[index] || createMap()
        mappers[index] = mapFunc

        return mapFunc(item.data, props, item.variables, queryState)
      })

      if (
        result.length !== previousResults.length ||
        result.some((item, i) => item !== previousResults[i])
      ) {
        previousResults = result
        return result
      } else {
        return previousResults
      }
    }
  )

  const selectMapReducedCollection = createSelector(
    [selectMappedCollection],
    (mappedCollection) => {
      return mappedCollection.slice(1).reduce(reduce, mappedCollection[0])
    }
  )

  return selectMapReducedCollection
}
