import EventSourceLocalisationStore from '@@src/store/event_source_localisation'

export default class EventSourceLocalisation {
  constructor(data) {
    Object.assign(this, data)

    Object.freeze(this)
  }

  get displayName() {
    return `#${this.groupId}-${this.heatmapId}`
  }

  isRead() {
    return this.status === EventSourceLocalisationStore.ALERT_STATUS_READ
  }

  isUnread() {
    return this.status === EventSourceLocalisationStore.ALERT_STATUS_UNREAD
  }
}
