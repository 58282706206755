import gql from 'graphql-tag'

export const RequestAnomaliesTableReportMutation = gql`
  mutation RequestAnomaliesReport(
    $start: String!,
    $end: String!, 
    $sources: [AnomalySource], 
    $pressureUnit: PressureUnit,
    $orderBy: AnomaliesSortOptions,
    $orderDirection: AnomaliesSortDirection,
  ) {
    requestAnomaliesReport(
      startTime: $start
      endTime: $end
      sources: $sources
      pressureUnit: $pressureUnit
      orderBy: $orderBy
      orderDirection: $orderDirection
    ) {
      jobId
    }
  }
`
