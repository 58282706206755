import React from 'react'

export default function DragLayerMaxValueIcon({ x, y, fill }) {
  return(
    <svg x={x - 9} y={y} width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.714 12H1.286C.576 12 0 11.424 0 10.714V5.857c0-.473.429-.902 1.286-1.286L6
          2l4.714 2.571c.857.384 1.286.813 1.286 1.286v4.857c0 .71-.576 1.286-1.286 1.286z"
        fill={fill}
        transform="scale(1.5)"
        fillRule="evenodd"/>
    </svg>
  )
}
