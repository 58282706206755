import React, { useState } from 'react'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import { NavLink } from 'react-router-dom'
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap'
import routes from '../../../../../../routes'
import {
  FeatureFlags,
  withTenantFeatureFlags,
} from '../../../../../contexts/feature_flags/tenants_feature_flags.context'
import { CAN_EDIT_NETWORK_ASSETS, CAN_EXPORT } from '../../../../../contexts/user/consts/permissions'
import { withUser } from '../../../../../contexts/user/user.context'
import NetworkAssetListNewNetworkAssetModal
from '../create_network_asset_modal/network_assets_list_new_network_asset_modal.component'
import './network_assets_list_filters.component.scss'
import ExportNetworkAssetsModal from '../export-network-assets-modal/export-network-assets-modal.component'

const NetworkAssetsListHeaderComponent = ({
  t,
  exportEnabled,
  networkAssetNumber,
  filters,
  isFeatureEnabled,
  overrideFeatureFlag,
  authorizedUser,
}) => {
  const [createNetworkAssetModalOpen, setCreateNetworkAssetModalOpen] = useState(false)
  const toggleNewNetworkAssetModal = () => setCreateNetworkAssetModalOpen((prevState) => !prevState)
  const [exportDropdownOpen, setExportDropdownOpen] = useState(false)
  const toggleExportDropdownOpen = () => setExportDropdownOpen((prevState) => !prevState)
  const [exportPopupOpen, setExportPopupOpen] = useState(false)
  const toggleExportPopupOpen = () => setExportPopupOpen((prevState) => !prevState)

  return (
    <>
      <ExportNetworkAssetsModal
        isOpen={exportPopupOpen}
        toggle={toggleExportPopupOpen}
        enabled={exportEnabled}
        networkAssetNumber={networkAssetNumber}
        filters={filters} />
      <NetworkAssetListNewNetworkAssetModal isOpen={createNetworkAssetModalOpen} toggle={toggleNewNetworkAssetModal} />
      <div className="d-flex flex-row justify-content-between">
        <Breadcrumb className="m-0 p-0" listClassName="m-0 px-0 py-2">
          <BreadcrumbItem>
            <span>{t('v2/common/text:management')}</span>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <NavLink to={routes.managementNetworkAssetsPath()}>{t('v2/common/text:network_assets')}</NavLink>
          </BreadcrumbItem>
        </Breadcrumb>
        {isFeatureEnabled(FeatureFlags.networkAssetsListV2, { omitOverridden: true }) && (
          <Breadcrumb className="m-0 p-0" listClassName="m-0 px-0 py-2">
            <BreadcrumbItem>
              <NavLink to={routes.managementNetworkAssetsPath()} onClick={() => {
                overrideFeatureFlag(FeatureFlags.networkAssetsListV2, false)
              }}>
                {t('v2/common/feature_flags:buttons.back_to_old_view')}
              </NavLink>
            </BreadcrumbItem>
          </Breadcrumb>
        )}
      </div>

      <div className="d-flex flex-row justify-content-between align-items-center">
        <h1 className="m-0 p-0">{t('v2/common/text:network_assets')}</h1>
        <div>
          {authorizedUser.can(CAN_EXPORT) && (
            <ButtonDropdown
              isOpen={exportDropdownOpen}
              toggle={toggleExportDropdownOpen}
              className={'export-button-dropdown'}>
              <DropdownToggle caret >
                <span className="mr-2 fa fa-file-download" />
                {t('v2/common/buttons:export')}&nbsp;
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  disabled={networkAssetNumber === 0}
                  value={t('v2/common/buttons:export')}
                  onClick={() => toggleExportPopupOpen()}>
                  CSV
                </DropdownItem>
              </DropdownMenu>
            </ButtonDropdown>
          )}
          <Button
            color="primary"
            onClick={toggleNewNetworkAssetModal}
            disabled={!authorizedUser.can(CAN_EDIT_NETWORK_ASSETS)} >
            <i className="fa fa-plus"></i> &nbsp;
            {` ${t('v2/network_assets/forms:buttons.create_network_asset')}`}
          </Button>
        </div>
      </div>
    </>
  )
}

export default compose(
  withUser,
  withTenantFeatureFlags,
  withTranslation([
    'v2/network_assets/common',
    'v2/network_assets/forms',
    'v2/common/feature_flags',
    'v2/common/permissions',
    'v2/common/text',
  ]),
)(NetworkAssetsListHeaderComponent)
