import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash/fp/object'
import { compose } from 'redux'
import { line } from 'd3-shape'
import { createSelector } from 'reselect'
import chroma from 'chroma-js'
import { PopupAnchor } from '@@src/components/popup'
import AnomalyPopup from './anomaly_popup'
import { withTranslation } from 'react-i18next'
import AnomalyStartIcon from '@@src/components/icon-components/anomaly_start_icon'
import AnomalyEndIcon from '@@src/components/icon-components/anomaly_end_icon'
import styles from './anomaly_layer.css'
import noop from 'lodash/noop'

export const IMAGE_SIZE = 24
export const BADGE_SIZE = 16

export function AnomalyStart({
  children,
  id,
  color: sourceColor,
  x,
  y,
  isActive,
  type,
  setActiveExpandedAnomalyId,
  ...rest
}) {
  const color = isActive ? chroma(sourceColor).darken() : sourceColor
  return (
    <PopupAnchor
      {...rest}
      x={x - IMAGE_SIZE / 2}
      y={y - IMAGE_SIZE / 2}
      width={IMAGE_SIZE + 10}
      height={IMAGE_SIZE + 10}
      className={styles['anomaly-boundary-marker']}
      onClick={() => setActiveExpandedAnomalyId(id, true)}
      elementType="svg" >
      <AnomalyStartIcon color={color} type={type}/>
      {children}
    </PopupAnchor>
  )
}
function AnomalyEnd({ color: sourceColor, x, y, isActive, setActiveExpandedAnomalyId, id, ...rest }) {
  const color = isActive ? chroma(sourceColor).darken() : sourceColor
  return(
    <svg
      {...rest}
      x={x - 12}
      y={y - 12}
      onClick={() => setActiveExpandedAnomalyId(id, true)}
      width="24" height="24" viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg">
      <AnomalyEndIcon color={color} />
    </svg>
  )
}

class AnomalyMarker extends React.PureComponent {
  render() {
    const meanLineGenerator = this.selectMeanLineGenerator(this.props)
    const {
      t,
      anomalyItem,
      boundsShown,
      activeAnomalyId,
      setActiveExpandedAnomalyId,
      isActiveAnomalyExpanded,
    } = this.props
    if(!anomalyItem) {
      return null
    }
    const isAnomalyActive = anomalyItem.id === activeAnomalyId

    return(
      <g>
        <path
          name={'anomaly-line-' + anomalyItem.id}
          stroke={chroma(anomalyItem.color).darken()}
          strokeWidth={isAnomalyActive ? '2px' : '1.25px'}
          strokeDasharray="0 4 8"
          fill="none"
          d={meanLineGenerator(anomalyItem.pressureData)}
        />
        <AnomalyStart
          id={anomalyItem.id}
          name={'anomaly-start-' + anomalyItem.id}
          key={'anomaly-start-' + anomalyItem.id}
          {...this.selectAnomalyStartCoordinates(this.props)}
          isActive={isAnomalyActive}
          setActiveExpandedAnomalyId={setActiveExpandedAnomalyId}
          type={anomalyItem.anomaly.type}
          color={anomalyItem.color}>
          <AnomalyPopup
            t={t}
            anomalyItem={anomalyItem}
            isActive={isAnomalyActive}
            isActiveAnomalyExpanded={isActiveAnomalyExpanded}
            onRemoveExpandedAnomaly={this.onRemoveExpandedAnomaly}
            boundsShown={boundsShown}
            onHideBounds={this.onHideBounds}
          />
        </AnomalyStart>

        <AnomalyEnd
          id={anomalyItem.id}
          name={'anomaly-end-' + anomalyItem.id}
          key={'anomaly-end-' + anomalyItem.id}
          {...this.selectAnomalyEndCoordinates(this.props)}
          isActive={isAnomalyActive}
          setActiveExpandedAnomalyId={setActiveExpandedAnomalyId}
          color={anomalyItem.color}/>
      </g>
    )
  }

  onRemoveExpandedAnomaly = () => {
    const { setActiveExpandedAnomalyId, activeAnomalyId } = this.props
    setActiveExpandedAnomalyId(activeAnomalyId, false)
  }

  onHideBounds = () => {
    const { toggleShowBoundsForActiveAnomaly } = this.props
    toggleShowBoundsForActiveAnomaly()
  }

  selectAnomalyStartCoordinates = createSelector(
    [get('graphConfig'), get('anomalyItem')],
    (graphConfig, anomalyItem) => {
      if(anomalyItem.pressureData.length !== 0) {
        return {
          x: graphConfig.xScale(anomalyItem.pressureData[0].time),
          y: graphConfig.yScale(anomalyItem.pressureData[0].mean),
        }
      }
      return {
        x: graphConfig.xScale(anomalyItem.anomaly.startTime),
        y: graphConfig.yScale(0),
      }
    }
  )

  selectAnomalyEndCoordinates = createSelector(
    [get('graphConfig'), get('anomalyItem')],
    (graphConfig, anomalyItem) => {
      if(anomalyItem.pressureData.length !== 0) {
        return {
          x: graphConfig.xScale(anomalyItem.pressureData[anomalyItem.pressureData.length - 1].time),
          y: graphConfig.yScale(anomalyItem.pressureData[anomalyItem.pressureData.length - 1].mean),
        }
      }
      return {
        x: graphConfig.xScale(anomalyItem.anomaly.endTime),
        y: graphConfig.yScale(0),
      }
    }
  )

  selectMeanLineGenerator = createSelector(
    [get('graphConfig')],
    graphConfig => {
      return line()
        .x(d => graphConfig.xScale(d.time))
        .y(d => graphConfig.yScale(d.mean))
    }
  )
}

AnomalyMarker.propTypes = {
  t: PropTypes.func.isRequired,
  anomalyItem: PropTypes.object.isRequired,
  clipPath: PropTypes.string.isRequired,
  boundsShown: PropTypes.bool.isRequired,
  graphConfig: PropTypes.object.isRequired,
  setActiveExpandedAnomalyId: PropTypes.func.isRequired,
  isActiveAnomalyExpanded: PropTypes.bool.isRequired,
}

AnomalyMarker.defaultProps = {
  isActiveAnomalyExpanded: false,
  boundsShown: false,
  setActiveExpandedAnomalyId: noop,
}

export default compose(
  withTranslation([
    'src/analysis_path/pressure_analysis_path/pressure_subset_graph/anomaly_layer', // eslint-disable-line max-len
  ])
)(AnomalyMarker)
