import React from 'react'
import PropTypes from 'prop-types'
import { createSelector } from 'reselect'
import { get } from 'lodash/fp/object'

import AsyncResult from '@@src/utils/async_result'

export const PressureSubsetDataContext = React.createContext()

export function withPressureSubsetData() {
  return Component => {
    return function WithPressureSubsetData(props) {
      return (
        <PressureSubsetDataContext.Consumer>
          {({ pressureSubsetDataResult, setPressureSubsetDataResult }) => (
            <Component {...{
              ...props,
              pressureSubsetDataResult,
              setPressureSubsetDataResult,
            }} />
          )}
        </PressureSubsetDataContext.Consumer>
      )
    }
  }
}

class PressureSubsetDataProvider extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node,
  }

  state = {
    pressureSubsetDataResult: AsyncResult.notFound([]),
  }

  render() {
    const { children } = this.props
    const value = this.selectPressureSubsetDataContext(this.state)

    return (
      <PressureSubsetDataContext.Provider value={value}>
        {children}
      </PressureSubsetDataContext.Provider>
    )
  }

  setPressureSubsetDataResult = result => {
    this.setState({
      pressureSubsetDataResult: result,
    })
  }

  selectPressureSubsetDataContext = createSelector([
    get('pressureSubsetDataResult'),
  ], (pressureSubsetDataResult) => {
    return {
      pressureSubsetDataResult,
      setPressureSubsetDataResult: this.setPressureSubsetDataResult,
    }
  })
}

export default PressureSubsetDataProvider
