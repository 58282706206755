import React from 'react'
import { compose } from 'redux'
import { NavLink } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import {
  Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap'

import routes from '@@src/routes'
import requiresLogin from '@@src/components/security/requires_login'
import listenForChanges from '@@src/components/listen_for_changes_container'
import { CSV_DOWNLOAD_CHANGES } from '@@src/store/changes'
import CsvDownloadsDropdownItemList
from './csv_downloads_dropdown/csv_downloads_dropdown_item_list'

class CsvDownloadsDropdown extends React.Component {
  state = {
    isDropdownOpen: false,
  }

  render() {
    const { t } = this.props
    const { isDropdownOpen } = this.state

    return (
      <Dropdown nav isOpen={isDropdownOpen} toggle={this.toggle}>
        <DropdownToggle nav>
          <i className="fa fa-file-download" />
        </DropdownToggle>

        <DropdownMenu>
          <DropdownItem header>
            {t('text.dropdown_header')}
          </DropdownItem>

          <CsvDownloadsDropdownItemList isOpen={isDropdownOpen}/>

          <DropdownItem>
            <NavLink to={routes.downloadsPath()}>
              {t('links.show_all')}
            </NavLink>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    )
  }

  toggle = () => {
    this.setState({ isDropdownOpen: !this.state.isDropdownOpen })
  }

  componentDidUpdate() {
    if (!this.props.handledLatestDownloads) {
      this.setState({ isDropdownOpen: true })
      this.props.handleLatestDownloads()
    }
  }
}

export default compose(
  requiresLogin,
  withTranslation('src/components/app_layout/csv_downloads_dropdown'),
  listenForChanges(CSV_DOWNLOAD_CHANGES, {
    props: ({ handled, handleChanges }) => ({
      handleLatestDownloads: handleChanges,
      handledLatestDownloads: handled,
    }),
  })
)(CsvDownloadsDropdown)
