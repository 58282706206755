import L from 'leaflet'
import React from 'react'
import PropTypes from 'prop-types'
import ResizeObserver from 'resize-observer-polyfill'
import { Map, TileLayer, LeafletConsumer } from 'react-leaflet'
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer'
import Control from 'react-leaflet-control'
import NetworkAssetMarkerTranslationProvider from
'@@src/components/maps/markers/network_asset_marker_translation_provider'

import MapConfigModal from './map_config_modal'
import AsyncResult from '@@src/utils/async_result'
import LoadingIcon from '@@src/components/loading_icon'
import MapGEOjsonLayer from '../../geojson-poc/map_geojson_layer'
import AppSuspenseContainer, { renderAbsolutePositionedLoading }
from '@@src/components/app_suspense_container'
import {
  mapDistanceToTime,
  DEFAULT_MAP_CENTER,
  DEFAULT_MAP_ZOOM,
  DEFAULT_TILE_LAYER_URL,
  MAP_LOAD_GRACE_PERIOD,
  MAP_TILES,
} from '../../utils/map_utils'

import styles from './standard_map.css'

class MapController extends React.PureComponent {
  static propTypes = {
    map: PropTypes.object.isRequired,
    bounds: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    center: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    layerContainer: PropTypes.object.isRequired,
    children: PropTypes.node,
  }

  ref = React.createRef()
  state = { initialized: false }
  loading = false
  postInitQueue = []

  render() {
    const { children, result } = this.props
    const { initialized } = this.state

    return (
      <div name="map-controller" ref={this.ref}>
        {
          result.isPending() || !initialized ? (
            <div className={styles['loading-container']}>
              <div className={styles['loading-wrapper']}>
                <LoadingIcon className={styles.loading}/>
              </div>
            </div>
          ) : (
            children
          )
        }
      </div>
    )
  }

  componentDidMount() {
    this.observer = new ResizeObserver(() => {
      if (this.ref.current) {
        this.props.map.invalidateSize()
      }
    })
    this.observer.observe(this.ref.current)

    this.updateMapState({}, this.props)
  }

  componentWillUnmount() {
    this.observer.disconnect()
  }

  setInitialized = initialized => {
    this.setState({ initialized })
  }

  componentDidUpdate(prevProps) {
    this.updateMapState(prevProps, this.props)
  }

  updateMapState = (oldProps, newProps) => {
    const { map } = newProps
    const { initialized } = this.state

    if (!initialized && !this.loading) {
      map.whenReady(() => {
        setTimeout(this.performPostInitOperations, MAP_LOAD_GRACE_PERIOD)
      })
      map.setView(
        newProps.center || DEFAULT_MAP_CENTER,
        newProps.zoom || DEFAULT_MAP_ZOOM,
      )

      this.loading = true
    }

    const operation = () => {
      this.props.map.invalidateSize()

      const newPropsCenter = Array.isArray(newProps.center) ?
        L.latLng(newProps.center) : newProps.center
      const newPropBounds = Array.isArray(newProps.bounds) ?
        L.latLngBounds(newProps.bounds) : newProps.bounds

      if (newProps.zoom && oldProps.zoom !== newProps.zoom) {
        map.setZoom(newProps.zoom)
      }

      if (newPropsCenter && !newPropsCenter.equals(oldProps.center)) {
        if(newProps.enablePanAnimation) {
          //  scale the duration, the further away next marker is the longer the animation
          const duration = mapDistanceToTime(oldProps.center, newPropsCenter)
          try {
            map.flyTo(newPropsCenter, newProps.zoom, { animate: true, duration })
          } catch {
            map.flyTo(newPropsCenter, newProps.zoom, { animate: false })
          }
        } else {
          map.flyTo(newPropsCenter, newProps.zoom, { animate: false })
        }
      }

      if (newPropBounds && !newPropBounds.equals(oldProps.bounds)) {
        map.flyToBounds(newPropBounds, { animate: false })
      }
    }

    if (initialized) {
      operation()
    } else {
      this.postInitQueue.push(operation)
    }
  }

  performPostInitOperations = () => {
    for (const op of this.postInitQueue) {
      op()
    }

    this.postInitQueue = []

    this.setInitialized(true)
  }

  componentWillUnmount() {
    clearInterval(this.domCheckInterval)
  }
}

function MapControllerPlugin(props) {
  return (
    <LeafletConsumer>
      {({ map, layerContainer }) => (
        <MapController map={map} layerContainer={layerContainer} {...props}/>
      )}
    </LeafletConsumer>
  )
}

export default class StandardMap extends React.PureComponent {
  static propTypes = {
    zoom: PropTypes.number,
    center: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    result: PropTypes.instanceOf(AsyncResult).isRequired,
    primaryTileLayerUrl: PropTypes.string.isRequired,
    enablePanAnimation: PropTypes.bool,
    enableGEOjson: PropTypes.bool,
    handleMoveEnd: PropTypes.func,
  }

  static defaultProps = {
    zoom: DEFAULT_MAP_ZOOM,
    center: DEFAULT_MAP_CENTER,
    result: AsyncResult.success(),
    primaryTileLayerUrl: DEFAULT_TILE_LAYER_URL,
    enableGEOjson: false,
  }

  constructor(props) {
    super(props)

    this.state = {
      tileType: MAP_TILES,
    }
  }

  onChangeMapSettings = input => {
    this.setState({ ...input })
  }

  render() {
    const {
      children,
      bounds,
      zoom,
      center,
      result,
      handleMoveEnd,
      primaryTileLayerUrl,
      enablePanAnimation,
      enableGEOjson,
      ...rest
    } = this.props

    const { tileType } = this.state

    return (
      <Map
        {...rest}
        maxZoom={18}
        onMoveEnd={handleMoveEnd}>
        {
          tileType === MAP_TILES ? (
            <TileLayer
              maxZoom={enableGEOjson ? 22 : 18}
              maxNativeZoom={enableGEOjson ? 22 : 18}
              url={primaryTileLayerUrl}
              attribution=""/>
          ) : (
            <ReactLeafletGoogleLayer
              type={'hybrid'}
              useGoogMapsLoader={false}
            />
          )
        }

        <Control position={'bottomleft'}>
          <MapConfigModal
            changeSettings={this.onChangeMapSettings}
          />
        </Control>

        {
          enableGEOjson &&
            <MapGEOjsonLayer tileType={tileType} />
        }

        <MapControllerPlugin
          result={result}
          bounds={bounds}
          zoom={bounds ? undefined : zoom}
          center={bounds ? undefined : center}
          enablePanAnimation={enablePanAnimation}>
          <AppSuspenseContainer
            elemType=""
            renderSuspenseFallback={renderAbsolutePositionedLoading}>
            <NetworkAssetMarkerTranslationProvider>
              {children}
            </NetworkAssetMarkerTranslationProvider>
          </AppSuspenseContainer>
        </MapControllerPlugin>
      </Map>
    )
  }
}
