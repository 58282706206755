import { getPressureDataFreshnessKey } from '../../domain/devices'

export class NetworkAssetForDeviceInstallModal {
  constructor(props) {
    Object.assign(this, { ...props })
  }
}

export class NetworkAssetForListItemMostSeverityIssue {
  constructor({ type, severity }) {
    this.type = type
    this.severity = severity
  }
}

export class NetworkAssetForListItemInstalledDevice {
  constructor(props) {
    this.deviceId = props.deviceId
    this.status = props.status
    this.serialNumber = props.serialNumber
    this.logicalChannel = props.logicalChannel
    this.activeErrorCount = props.activeErrorCount
    this.activeWarningCount = props.activeWarningCount
    this.activeInformationCount = props.activeInformationCount
    this.lastRecordedDataTime = props.lastRecordedDataTime
      ? new Date(props.lastRecordedDataTime)
      : null
  }

  getSeverity() {
    if (this.activeErrorCount > 0) {
      return 'ERROR'
    } else if (this.activeWarningCount > 0) {
      return 'WARNING'
    } else if (this.activeInformationCount > 0) {
      return 'INFORMATION'
    }

    return null
  }

  getTableIconName() {
    switch(true) {
      case !!this.getSeverity(): {
        return `icon-${this.getSeverity().toLowerCase()}`
      }
      case this.status === 'returned': {
        return 'icon-returned'
      }
      case this.status === 'commissioned' || this.status === 'installed': {
        return 'icon-commissioned'
      }
      default: return 'icon-decommissioned'
    }
  }

  getPressureDataFreshnessKey() {
    return getPressureDataFreshnessKey(this.lastRecordedDataTime)
  }

  isCommissioned() {
    return this.status === 'commissioned'
  }

}

export class NetworkAssetForListItem {
  constructor(props) {
    this.assetId = props.assetId
    this.name = props.name
    this.readableName = props.readableName
    this.type = props.type
    this.activeErrorCount = +props.activeErrorCount
    this.activeWarningCount = +props.activeWarningCount
    this.activeInformationCount = +props.activeInformationCount
    this.installedDevicesCount = +props.installedDevicesCount
    this.lastRecordedDataTime = props.lastRecordedDataTime
      ? new Date(props.lastRecordedDataTime)
      : null
    this.mostSevereIssue = props.mostSeverityIssue
      ? new NetworkAssetForListItemMostSeverityIssue(props.mostSeverityIssue)
      : null
    this.installedDevices = Array.isArray(props.installedDevices)
      ? props.installedDevices.map(device => new NetworkAssetForListItemInstalledDevice(device))
      : []
    this.lat = props.lat
    this.lng = props.lng
    this.alt = props.alt
  }

  getShowDataId() {
    return `a.${this.assetId}`
  }

  getMostSeverityIssueWithOtherIssuesCount() {
    const { activeErrorCount, activeWarningCount, activeInformationCount, mostSevereIssue } = this
    const errorsBySeverity = {
      ERROR: activeErrorCount || 0,
      WARNING: activeWarningCount || 0,
      INFORMATION: activeInformationCount || 0,
    }

    if (mostSevereIssue) {
      errorsBySeverity[mostSevereIssue.severity] -= 1
    }

    const [errorsCount, warningsCount, informationCount] = [...Object.values(errorsBySeverity)]

    return (errorsCount || warningsCount || informationCount || mostSevereIssue)
      ? { mostSevereIssue, errorsCount, warningsCount, informationCount }
      : null
  }

  getPressureDataFreshnessKey() {
    return getPressureDataFreshnessKey(this.lastRecordedDataTime)
  }
}
