import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import GroupIcon from '@@src/components/icons/group_icon'
import { MixedGroupDetailsType, GetGroupsResult } from '@@src/api/presenters/group'
import DeviceIconWithStatus
from '@@src/components/icons/device_icon_with_status'
import NetworkAssetIcon from '@@src/components/icons/network_asset_icon'

import styles from './group_type_icon.css'

function GroupTypeIcon(props) {
  const { group, className, backgroundColor } = props
  let memberIcon = null

  if (group.isDeviceGroup()) {
    memberIcon = (
      <DeviceIconWithStatus
        className="h-100 w-100 d-block"
        device={{ status: 'commissioned' }}/>
    )
  } else if (group.isNetworkAssetGroup()) {
    memberIcon = (
      <NetworkAssetIcon
        className="h-100 w-100 d-block"
        networkAsset={{ assetType: 'hydrant-marker' }}/>
    )
  }

  return (
    <div className={classnames('position-relative', className)}>
      <GroupIcon className={styles['legend-icon-group']}/>
      {memberIcon ?
        <div className={classnames(backgroundColor, styles['item-icon-wrap'])}>
          {memberIcon}
        </div> : null
      }
    </div>
  )
}

GroupTypeIcon.propTypes = {
  group: PropTypes.oneOfType(
    [PropTypes.instanceOf(MixedGroupDetailsType),
      PropTypes.instanceOf(GetGroupsResult)]
  ).isRequired,
  backgroundColor: PropTypes.string,
  className: PropTypes.string,
}

GroupTypeIcon.defaultProps = {
  backgroundColor: 'bg-white',
}

export default React.memo(GroupTypeIcon)
