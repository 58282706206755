import React from 'react'
import { compose } from 'redux'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { Col, Row, Button } from 'reactstrap'

import routes from '@@src/routes'
import userPermissions from '@@src/components/permissions/user_permissions'

import styles from './index.css'

class InstallationRow extends React.PureComponent {
  render() {
    const {
      t, installation, onClickCommission, onClickDelete, permissions,
    } = this.props
    const installationIsOnUnavailableAsset = installation.isOnUnavailableAsset()
    const { networkAsset } = installation

    return (
      <Row className={styles['installation-row']}>
        <Col sm="12">
          {
            installationIsOnUnavailableAsset ?
              <span className="mr-2 font-italic">
                {t('text.removed_asset')}
              </span> :
              <Link
                className="mr-2"
                to={routes.networkAssetsDetailPath(networkAsset.id)}>
                {networkAsset.assetId}
              </Link>
          }

          ({installation.translateAssetChannel(t, 1)})

          <p className={styles['commission-time-text']}>
            {
              !installation.isActive() ? (
                <React.Fragment>
                  <span>
                    {t('text.long_readable_full_time', {
                      time: installation.start,
                    })}
                  </span>

                  &nbsp;
                  &rarr;
                  &nbsp;

                  <span>
                    {t('text.long_readable_full_time', {
                      time: installation.end,
                    })}
                  </span>
                </React.Fragment>
              ) : (
                t('text.long_readable_full_time', {
                  time: installation.start,
                })
              )
            }
          </p>

          {
            !installationIsOnUnavailableAsset ?
              <p className={styles['location-text']}
                name="device-commission-location"
                onClick={onClickCommission}>
                <i className="far fa-map-marker-alt"></i>
                &nbsp;
                {networkAsset.location.latitude.toFixed(6)},
                {networkAsset.location.longitude.toFixed(6)}
              </p>
              : null
          }

          <div className={styles['installation-row-btn-group']}>
            {onClickDelete ? (
              <Button
                name="delete-installation-button"
                size="sm"
                color="light"
                disabled={
                  !permissions.includes('can_edit_devices') ||
                    !permissions.includes('can_edit_network_assets')
                }
                onClick={onClickDelete}>
                <i className="far fa-trash-alt"></i>
              </Button>
            ) : null}
          </div>
        </Col>
      </Row>
    )
  }
}

export default compose(
  userPermissions,
  withTranslation([
    'src/management_path/devices_path/device_detail_page',
    'common/text',
  ])
)(InstallationRow)
