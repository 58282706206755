import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import gql from 'graphql-tag'
import { graphql } from '@apollo/client/react/hoc'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { get } from 'lodash/fp/object'
import { createSelector } from 'reselect'
import { Table } from 'reactstrap'
import classnames from 'classnames'
import moment from 'moment'

import PaginationToolbar from '@@src/components/pagination/pagination_toolbar'
import AsyncTable from '@@src/components/lists/async_table'
import AsyncResult from '@@src/utils/async_result'
import getSortDropdown from
'@@src/components/sort_component/sort_component'
import getWithPagination from '@@src/components/pagination/pagination_container'
import AlertListItem from '@@src/alerts_path/alert_list_item'
import * as analytics from '@@src/analytics'
import {
  createSelectGraphQLResult,
  parseSearchParams,
  getMappedCongruityTypes,
} from '@@src/utils'
import { parseGraphQLResult } from '@@src/api/presenters'
import defineQueryParams from '@@src/components/define_query_params'
import ErrorInfo from '@@src/components/error_info'
import {
  STATUS_FILTER_PARAM,
  TYPE_FILTER_PARAM,
  NETWORK_ASSET_TYPE_FILTER_PARAM,
  FROM_FILTER_PARAM,
  TO_FILTER_PARAM,
  DATE_URL_FORMAT,
} from '@@src/alerts_path/filters_panel'
import { SOURCE_FILTER_PARAM } from '@@src/components/forms/source_filter'
import { MixedGroupDetailsType } from '@@src/api/presenters/group'
import NetworkAsset from '@@src/api/presenters/network_asset'
import AppSettingsConsumer from '@@src/components/app_settings_consumer'
import { PagedAlertsContext } from '@@src/alerts_path/paged_alerts_provider'
import { AVAILABLE_PRESSURE_UNITS } from '@@src/utils/unit_constants'

import styles from './alerts_list.css'

export const VISIBLE_GROUPS_PAGES = 3
export const SEED_ALERTS_PAGE_NUMBER = 1
export const SEED_ALERTS_RESULTS_PER_PAGE = 10

const SortDropdown = getSortDropdown()
const SORT_DIRECTIONS = [
  'asc',
  'desc',
]

class AlertsList extends React.PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    pagedAlertsResult: PropTypes.instanceOf(AsyncResult).isRequired,
    resultsPerPage: PropTypes.number.isRequired,
    setPageNumber: PropTypes.func.isRequired,
    setResultsPerPage: PropTypes.func.isRequired,
    refetchAlerts: PropTypes.func,
    location: PropTypes.object.isRequired,
    orderDirection: PropTypes.oneOf(SORT_DIRECTIONS).isRequired,
    setPagedAlertsResult: PropTypes.func.isRequired,
    pressureUnits: PropTypes.oneOf(AVAILABLE_PRESSURE_UNITS).isRequired,
  }

  static defaultProps = {
    pagedAlertsResult: AsyncResult.notFound([]),
  }

  state = {
    updateStatusResult: AsyncResult.notFound(),
  }

  render() {
    const {
      pagedAlertsResult, resultsPerPage, setPageNumber, setResultsPerPage,
      orderDirection, orderBy, t,
    } = this.props
    const { updateStatusResult } = this.state

    return (
      <React.Fragment>
        <div className={styles['list-controls']}>
          <span>
            {
              pagedAlertsResult.wasSuccessful() ? (
                t('text.open_alerts_found', {
                  count: pagedAlertsResult.data.openAlertsCount,
                })
              ) : null
            }
          </span>

          <SortDropdown
            name="sort-alerts-dropdown"
            options={this.selectSortList(this.props)}
            onChange={this.onChangeOrderBy}
            className={styles['sort-dropdown']}
            isDescending={orderDirection === 'desc'}
            value={orderBy}/>
        </div>

        {
          updateStatusResult.wasFailure() ?
            <ErrorInfo error={updateStatusResult.error}/> :
            null
        }
        <Table className="list-unstyled bg-white rounded mt-5">
          <thead className={`${styles['table-head']}`}>
            <tr>
              <th>{t('text.reason')}</th>
              <th>{t('text.id')}</th>
              <th>{t('text.time')}</th>
              <th>{t('text.source')}</th>
              <th />
            </tr>
          </thead>
          <tbody>
            <AsyncTable
              columns={6}
              result={pagedAlertsResult}
              renderNotFoundResult={this.renderNotFoundResult}
              renderItem={this.renderAlert}
            />
          </tbody>
        </Table>
        {
          pagedAlertsResult.wasSuccessful() ?
            <PaginationToolbar
              visiblePageOptionCount={VISIBLE_GROUPS_PAGES}
              pageNumber={pagedAlertsResult.data.pageNumber}
              totalPages={pagedAlertsResult.data.totalPages}
              totalResults={pagedAlertsResult.data.totalResults}
              resultsPerPage={resultsPerPage}
              setPageNumber={setPageNumber}
              setResultsPerPage={setResultsPerPage} /> : null
        }
      </React.Fragment>
    )
  }

  componentDidUpdate(prevProps) {
    const {
      setPageNumber,
      alertStatusFilter,
      alertTypeFilter,
      networkAssetTypeFilter,
      dateFromFilter,
      dateToFilter,
      sourceIdFilter,
      alertsSearchQuery,
      setPagedAlertsResult,
      pagedAlertsResult,
    } = this.props
    const {
      alertStatusFilter: prevAlertStatusFilter,
      alertTypeFilter: prevAlertTypeFilter,
      networkAssetTypeFilter: prevNetworkAssetTypeFilter,
      dateFromFilter: prevDateFromFilter,
      dateToFilter: prevDateToFilter,
      sourceIdFilter: prevSourceIdFilter,
      alertsSearchQuery: prevAlertsSearchQuery,
      pagedAlertsResult: prevPagedAlertsResult,
    } = prevProps

    if (alertStatusFilter !== prevAlertStatusFilter ||
      alertTypeFilter !== prevAlertTypeFilter ||
        networkAssetTypeFilter !== prevNetworkAssetTypeFilter ||
          dateFromFilter !== prevDateFromFilter ||
            dateToFilter !== prevDateToFilter ||
              sourceIdFilter !== prevSourceIdFilter) {
      setPageNumber(SEED_ALERTS_PAGE_NUMBER)
    }

    if (prevAlertsSearchQuery !== alertsSearchQuery) {
      setPageNumber(1)
    }

    if (pagedAlertsResult !== prevPagedAlertsResult) {
      setPagedAlertsResult(pagedAlertsResult)
    }
  }

  renderNotFoundResult = () => (
    <tr className="p-3 font-italic text-secondary">
      <td colSpan={6}>
        {this.props.t('text.no_alerts_found')}
      </td>
    </tr>
  )

  renderAlert = (alert, i, alerts) => {
    const { pressureUnits } = this.props
    const { updateStatusResult } = this.state

    return (
      <AlertListItem
        updateStatusResult={updateStatusResult}
        pressureUnits={pressureUnits}
        onChangeAlertStatus={this.onChangeAlertStatus}
        className={classnames({
          'border-bottom': i !== alerts.length - 1,
        })}
        key={alert.uuid}
        alert={alert} />
    )
  }

  onChangeAlertStatus = result => {
    const { refetchAlerts } = this.props

    if (result.wasSuccessful() && typeof refetchAlerts === 'function') {
      refetchAlerts()
    }

    this.setState({ updateStatusResult: result })
  }

  selectSortList = createSelector(
    [get('t')],
    t => [
      { value: 'occurredAt', text: t('options.occurred_at') },
      { value: 'lastCommentTime', text: t('options.last_comment_time') },
    ]
  )

  onChangeOrderBy = orderBy => {
    const orderDirection =
      orderBy === this.props.orderBy && this.props.orderDirection === 'desc' ?
        'asc' : 'desc'

    this.props.dispatchUpdateQueryParams(this.props, {
      orderBy,
      orderDirection,
    })
  }

  static ALERTS_QUERY = gql`
    query PagedAlerts(
      $orderBy: AlertsOrderByOption,
      $pageNumber: Int!,
      $resultsPerPage: Int!,
      $orderDirection: OrderDirection,
      $statuses: [AlertStatus],
      $networkAssetType: [AssetType],
      $type: AlertType,
      $dateFrom: String,
      $dateTo: String,
      $networkAssetSourceId: Int,
      $groupSourceId: Int,
      $searchQuery: String
    ) {
      pagedAlerts(
        orderBy: $orderBy,
        pageNumber: $pageNumber,
        resultsPerPage: $resultsPerPage,
        orderDirection: $orderDirection,
        statuses: $statuses,
        networkAssetType: $networkAssetType,
        type: $type,
        dateFrom: $dateFrom,
        dateTo: $dateTo,
        networkAssetSourceId: $networkAssetSourceId,
        groupSourceId: $groupSourceId
        searchQuery: $searchQuery
      ) {
        alerts {
          alertId
          status
          type
          occurredAt
          commentCount
          lastCommentTime
          details {
            ... on StandardAlertDetails {
              networkAssetId
              networkAssetName
              networkAssetFriendlyName
              networkAssetType
              deviceId
              deviceSerialNumber
              logicalChannel
              threshold
              value
              unit
            }
            ... on DeviceNoCommsAlertDetails {
              deviceId
              deviceSerialNumber
              threshold
              unit
            }
            ... on EventSourceLocalisationAlertDetails {
              groupId
              groupName
              localisationId
              eventIds
              eventsStartDate
              eventsEndDate
            }
            ... on EventAlertDetails {
              networkAssetId
              networkAssetName
              networkAssetFriendlyName
              networkAssetType
              deviceId
              logicalChannel
              eventCharacteristic
              eventId
              startTimestamp
              endTimestamp
              value
              threshold
              unit
            }
          }
        }
        pagination {
          pageNumber
          totalPages
          totalResults
        }
        openAlertsCount
      }
    }
  `
}

function createMapStateToProps() {
  const selectPagedAlertsResult = createSelectGraphQLResult('pagedAlerts', {
    onError: analytics.logError,
    mapResult: parseGraphQLResult,
    nullObject: [],
  })

  const selectParsedSearchParams = createSelector(
    [(_state, { location }) => location.search],
      parseSearchParams
  )

  const selectAlertsSearchQuery =
    (state, props) => selectParsedSearchParams(state,
      props).s

  const selectPagedAlertsQueryVariables = createSelector(
    [
      get('pageNumber'),
      get('resultsPerPage'),
      get('orderBy'),
      get('orderDirection'),
      get('alertStatusFilter'),
      get('alertTypeFilter'),
      get('networkAssetTypeFilter'),
      get('dateFromFilter'),
      get('dateToFilter'),
      get('sourceIdFilter'),
      get('searchQuery'),
    ],
    (
      pageNumber,
      resultsPerPage,
      orderBy,
      orderDirection,
      statuses,
      type,
      networkAssetType,
      dateFrom,
      dateTo,
      sourceId,
      searchQuery,
    ) => {
      const filters = {
        orderBy,
        orderDirection,
        pageNumber,
        resultsPerPage,
        statuses,
        type,
        networkAssetType,
        dateFrom,
        dateTo,
        searchQuery,
      }

      if (sourceId) {
        if (MixedGroupDetailsType.isGroupDetailsDataSourceId(sourceId)) {
          filters.groupSourceId =
            MixedGroupDetailsType.parseIdFromDataSourceId(sourceId)
        } else if (NetworkAsset.isNetworkAssetDataSourceId(sourceId)) {
          filters.networkAssetSourceId =
            NetworkAsset.parseIdFromDataSourceId(sourceId)
        }
      }

      return filters
    }
  )

  const selectRequestAssetsFilter = createSelector(
    [f => f],
    assetsFilter => {
      if (!assetsFilter) {
        return undefined
      }

      return getMappedCongruityTypes([assetsFilter])
    }
  )

  return function mapStateToProps(state, ownProps) {
    return {
      selectRequestAssetsFilter,
      selectPagedAlertsResult,
      selectPagedAlertsQueryVariables,
      alertsSearchQuery: selectAlertsSearchQuery(state, ownProps),
    }
  }
}

function AlertsListWithProviders(props) {
  return (
    <PagedAlertsContext.Consumer>
      {
        ({ setPagedAlertsResult }) => (
          <AppSettingsConsumer>
            {
              (units) => (
                <AlertsList
                  setPagedAlertsResult={setPagedAlertsResult}
                  pressureUnits={units.pressure}
                  {...props} />
              )
            }
          </AppSettingsConsumer>
        )
      }
    </PagedAlertsContext.Consumer>
  )
}

export default compose(
  connect(createMapStateToProps),
  getWithPagination({
    pageNumber: SEED_ALERTS_PAGE_NUMBER,
    resultsPerPage: SEED_ALERTS_RESULTS_PER_PAGE,
  }),
  defineQueryParams({
    orderBy: {
      alias: 'ob',
      parseFunc: s => s || 'occurredAt',
    },
    orderDirection: {
      alias: 'od',
      parseFunc: s => s || 'desc',
    },
    alertStatusFilter: {
      alias: STATUS_FILTER_PARAM,
      parseFunc: defineQueryParams.parseArray,
    },
    alertTypeFilter: { alias: TYPE_FILTER_PARAM },
    networkAssetTypeFilter: { alias: NETWORK_ASSET_TYPE_FILTER_PARAM },
    dateFromFilter: { alias: FROM_FILTER_PARAM },
    dateToFilter: { alias: TO_FILTER_PARAM },
    sourceIdFilter: { alias: SOURCE_FILTER_PARAM },
  }),
  withTranslation([
    'src/alerts_path/alerts_list',
  ]),
  graphql(AlertsList.ALERTS_QUERY, {
    skip: ({ dateFromFilter, dateToFilter }) => {
      const momentDateFromFilter = moment(dateFromFilter, DATE_URL_FORMAT, true)
      const momentDateToFilter = moment(dateToFilter, DATE_URL_FORMAT, true)

      if ((dateFromFilter && !momentDateFromFilter.isValid()) ||
        dateToFilter && !momentDateToFilter.isValid()) {
        return true
      }

      return false
    },
    options: ({
      selectPagedAlertsQueryVariables,
      alertsSearchQuery,
      selectRequestAssetsFilter,
      networkAssetTypeFilter,
      ...rest
    }) => {
      const mappedNetworkAssetTypeFilter = selectRequestAssetsFilter(
        networkAssetTypeFilter
      )

      return {
        variables: selectPagedAlertsQueryVariables(
          {
            ...rest,
            searchQuery: alertsSearchQuery,
            networkAssetTypeFilter: mappedNetworkAssetTypeFilter,
          }
        ),
      }
    },
    props: ({
      data: { refetch, ...rest },
      ownProps: { selectPagedAlertsResult },
    }) => {
      return {
        refetchAlerts: refetch,
        pagedAlertsResult: selectPagedAlertsResult(rest),
      }
    },
  }),
)(AlertsListWithProviders)
