import gql from 'graphql-tag'

export const GET_NETWORK_ASSETS_FOR_LIST_ITEM_QUERY = gql`
  query GetNetworkAssetsListForListItem (
    $networkAssetId: Int!
  ) {
    getNetworkAssetsListItem(
      networkAssetId: $networkAssetId
    ) {
      networkAsset {
        assetId
        type
        name
        readableName
        activeErrorCount
        activeWarningCount
        activeInformationCount
        lastRecordedDataTime
        mostSeverityIssue {
          type
          severity
        }
        installedDevicesCount
        installedDevices {
          deviceId
          status
          serialNumber
          logicalChannel
          activeErrorCount
          activeWarningCount
          activeInformationCount
          lastRecordedDataTime
        }
        lat
        lng
        alt
      }
    }
  }
`
