import React from 'react'
import { Alert, Button, Modal, ModalHeader, ModalBody } from 'reactstrap'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import classnames from 'classnames'
import { Link } from 'react-router-dom'
import routes from '@@src/routes'

import DeviceIssue from '@@src/api/presenters/device_issue'
import * as analytics from '@@src/analytics'
import Device from '@@src/api/presenters/device'
import NetworkAsset from '@@src/api/presenters/network_asset'

import styles from './device_issue_alert.css'

class DeviceIssueAlert extends React.PureComponent {
  static propTypes = {
    deviceIssue: PropTypes.instanceOf(DeviceIssue).isRequired,
    t: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    sourceToLinkTo: PropTypes.oneOfType([
      PropTypes.instanceOf(Device),
      PropTypes.instanceOf(NetworkAsset),
    ]),
    customActionComponent: PropTypes.node,
  }

  state = {
    isModalOpen: false,
  }

  render() {
    const {
      deviceIssue, t, children, sourceToLinkTo, customActionComponent,
    } = this.props
    const { isModalOpen } = this.state

    return (
      <React.Fragment>
        <Modal isOpen={isModalOpen} toggle={this.toggleModalOpen}>
          <ModalHeader toggle={this.toggleModalOpen}>
            {t(`common/text:issues.${deviceIssue.descriptionTitle}`)}
          </ModalHeader>
          <ModalBody>
            <p>
              {t(`common/text:issues.${deviceIssue.type}_description`)}
            </p>
          </ModalBody>
        </Modal>
        <Alert
          className="mb-2 d-flex justify-content-between align-items-center"
          color={deviceIssue.alertColor}>
          <div className="d-flex align-items-center pr-2">
            <span
              className={classnames(this.getAlertIconClassnames(), 'mr-3')} />
            <p className="m-0">
              {children}
            </p>
          </div>
          <div className="d-flex">
            {
              sourceToLinkTo ?
                <Button
                  name="device-issue-show-source-button"
                  className="mr-2"
                  size="sm"
                  color={deviceIssue.alertColor}
                  to={sourceToLinkTo instanceof NetworkAsset ?
                    routes.networkAssetsDetailPath(sourceToLinkTo.id) :
                    routes.managementDevicesDetailPath(sourceToLinkTo.id)
                  }
                  tag={Link}>
                  {
                    sourceToLinkTo instanceof NetworkAsset ?
                      t('button.show_asset') : t('button.show_device')
                  }
                </Button>
                : null
            }

            {
              customActionComponent ? customActionComponent : (
                <Button
                  name="device-issue-info-description-button"
                  className={styles['info-description-button']}
                  onClick={this.toggleModalOpen} size="sm" color="link">
                  <span className="fa fa-info" />
                </Button>
              )
            }
          </div>
        </Alert>
      </React.Fragment>
    )
  }

  getAlertIconClassnames = () => {
    const { deviceIssue } = this.props

    if (deviceIssue.isError()) {
      return 'fas fa-exclamation-triangle'
    } else if (deviceIssue.isWarning()) {
      return 'fas fa-exclamation-triangle'
    } else {
      if (!deviceIssue.isInformation()) {
        analytics.logError(
          `unknown device issue severity ${deviceIssue.severity}`
        )
      }

      return 'fas fa-info-circle'
    }
  }

  toggleModalOpen = () => {
    this.setState({ isModalOpen: !this.state.isModalOpen })
  }
}

export default withTranslation([
  'src/components/alerts/device_issue_alert',
  'common/text',
])(DeviceIssueAlert)
