import classnames from 'classnames'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Button } from 'reactstrap'
import { compose } from 'redux'

import Device from '@@src/api/presenters/device'
import DeviceIssue from '@@src/api/presenters/device_issue'
import DeviceIssueSeverityBadge from '@@src/components/badges/device_issue_severity_badge'
import DeviceIconWithStatus from '@@src/components/icons/device_icon_with_status'
import NetworkAssetIcon from '@@src/components/icons/network_asset_icon'
import SummaryPanel from '@@src/components/summary/summary_panel'
import SummaryPanelEmpty from '@@src/components/summary/summary_panel_empty'
import SummaryPanelListItem from '@@src/components/summary/summary_panel_list_item'
import routes from '@@src/routes'
import { formatAsQueryString } from '@@src/utils'
import 'react-loading-skeleton/dist/skeleton.css'
import { ListPlaceholder } from '../../../_v2/components/placeholders/list_placeholder'
import { CAN_VIEW_ANALYSIS } from '../../../_v2/contexts/user/consts/permissions'
import { withUser } from '../../../_v2/contexts/user/user.context'
import { useGetPagedUnhealthyAssets } from '../../../hooks/summaries/useGetPagedUnhealthyAssets'
import styles from './unhealthy_assets_summary.css'

function UnhealthyAssetsSummary({ t, authorizedUser }) {
  const { unhealthyPagedAssets, assetCount } = useGetPagedUnhealthyAssets()

  const networkAssetsPath = routes.managementNetworkAssetsPath()
  const sortedNetworkAssetsParams = formatAsQueryString({
    ob: 'name',
    od: 'asc',
    t: DeviceIssue.VISIBLE_ISSUE_TYPES.join('+'),
  })

  const renderPendingResult = () => {
    return <ListPlaceholder count={5} listItemHeight={20} />
  }

  const renderSeverityBadge = (asset) => {
    const issue = asset.mostSevereDeviceIssue

    if (issue && issue.typeWarning) {
      return <DeviceIssueSeverityBadge issue={issue} />
    }

    return null
  }

  const renderDeviceIcons = (asset) => {
    const emptyInstallations = []
    const currentInstallations = asset.installations.filter((installation) => {
      return installation.end === null
    })

    for (let i = 0; i < asset.assignableChannelCount; i++) {
      emptyInstallations.push(
        <DeviceIconWithStatus
          key={`${asset.uuid}-empty-installation-${i}`}
          name="unhealthy-asset-empty-channel-icon"
          className={styles['unhealthy-asset-device-icon']}
          device={Device.from({})}
        />
      )
    }

    return (
      <>
        {emptyInstallations}
        {currentInstallations.map((installation) => {
          return (
            <DeviceIconWithStatus
              name="unhealthy-asset-channel-icon"
              className={styles['unhealthy-asset-device-icon']}
              key={`${asset.uuid}-${installation.device.uuid}`}
              device={installation.device}
            />
          )
        })}
      </>
    )
  }

  const renderSuccessAlertsResult = ({ data: items }) => {
    if (items.length === 0) {
      return <SummaryPanelEmpty />
    }

    return (
      <ol className="list-unstyled mb-3">
        {items.slice(0, assetCount).map((asset) => (
          <SummaryPanelListItem
            icon={
              <NetworkAssetIcon
                className={styles['unhealthy-asset-icon']}
                networkAsset={asset}
              />
            }
            itemActions={
              <>
                {renderDeviceIcons(asset)}
                {authorizedUser.can(CAN_VIEW_ANALYSIS) && (<Button
                  className={classnames(
                    styles['unhealthy-asset-action-btn'],
                    'mr-2'
                  )}
                  to={routes.analysisPressurePath({ d: asset.uuid })}
                  name="unhealthy-asset-plot-btn"
                  size="sm"
                  color="light"
                  tag={Link}
                >
                  <span className="far fa-chart-line" />
                </Button>)}
                <Button
                  className={styles['unhealthy-asset-action-btn']}
                  to={routes.networkAssetsDetailPath(asset.id)}
                  name="unhealthy-asset-detail-btn"
                  size="sm"
                  color="primary"
                  tag={Link}
                >
                  <span className="fas fa-info" />
                </Button>
              </>
            }
            key={asset.id}
          >
            <span>
              <span className="mr-2">{asset.assetId}</span>
              {renderSeverityBadge(asset)}
            </span>

            {asset.assetName ? (
              <i>
                <small>{asset.assetName}</small>
              </i>
            ) : null}

            <small className="text-muted">{asset.translateAssetType(t)}</small>
          </SummaryPanelListItem>
        ))}
      </ol>
    )
  }

  return (
    <SummaryPanel
      title={t('headings.asset_monitoring_health')}
      subtitle={t('headings.errors_and_warnings')}
      renderSuccessResult={renderSuccessAlertsResult}
      renderPendingResult={renderPendingResult}
      showAllLink={`${networkAssetsPath}${sortedNetworkAssetsParams}`}
      showAllBtnText={t('buttons.show_all_network_assets')}
      result={unhealthyPagedAssets}
    />
  )
}

export default compose(
  withUser,
  withTranslation([
    'src/components/summary/unhealthy_assets_summary',
    'common/text',
  ])
)(UnhealthyAssetsSummary)
