import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { get } from 'lodash/fp/object'
import { Alert, Button } from 'reactstrap'
import { createSelector } from 'reselect'

import styles from './graph_alert_group.css'

export default class GraphAlertGroup extends React.PureComponent {
  static defaultProps = {
    alertConfigs: [],
  }

  static propTypes = {
    alertConfigs: PropTypes.arrayOf(PropTypes.shape({
      color: PropTypes.oneOf(['danger', 'warning', 'info', 'success']),
      text: PropTypes.string.isRequired,
      button: PropTypes.shape({
        text: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
      }),
    })).isRequired,
    className: PropTypes.string,
  }

  state = {
    hiddenMap: {},
  }

  render() {
    const { className, children } = this.props
    const computedAlertConfigs =
      this.selectAlertConfigs(this.props, this.state, this)

    return (
      <div className={classnames(styles.container, className)}>
        {computedAlertConfigs.map(config => (
          <Alert
            key={config.key}
            color={config.color}
            isOpen={config.isOpen}
            toggle={config.toggle}
            className={styles.alert}>
            {config.text}

            {
              config.button ? (
                <Button
                  size="sm"
                  color={config.button.color || 'primary'}
                  onClick={config.button.onClick}
                  className={styles.button}>
                  {config.button.text}
                </Button>
              ) : null
            }
          </Alert>
        ))}

        {children}
      </div>
    )
  }

  selectAlertConfigs = createSelector(
    [
      get('alertConfigs'),
      (_props, state) => state.hiddenMap,
      (_props, _state, el) => el,
    ],
    (alertConfigs, hiddenMap, el) => alertConfigs.map(alertConfig => {
      const key = alertConfig.text
      const isOpen = !hiddenMap[key]

      return {
        ...alertConfig,
        key,
        isOpen,
        toggle: event => {
          event.preventDefault()

          el.setState({
            hiddenMap: {
              ...el.state.hiddenMap,
              [key]: !el.state.hiddenMap[key],
            },
          })
        },
      }
    })
  )
}
