import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { withTranslation } from 'react-i18next'
import { Card, CardTitle, CardText, CardBody } from 'reactstrap'

import LoadingIcon from '@@src/components/loading_icon'

import styles from './marker_loader.css'

export const DEFAULT_MARKER_LOADING_THRESHOLD = 500

function MarkerLoader(props) {
  const { totalMarkers, processedMarkers, t, loadingThreshold } = props

  if (processedMarkers === totalMarkers ||
    totalMarkers < loadingThreshold) {
    return null
  }

  return (
    <div className={classnames(
      `position-absolute w-100 h-100 d-flex
      align-items-center justify-content-center flex-column`,
      styles['map-marker-loader']
    )}>
      <Card className="text-center">
        <CardBody>
          <LoadingIcon className="fa-3x mb-4" />
          <CardTitle className="lead">
            {t('text.loading_markers')}
          </CardTitle>
          <CardText className="muted">
            {t('text.markers_loaded', {
              count: totalMarkers,
              loadedMarkers: processedMarkers,
            })}
          </CardText>
        </CardBody>
      </Card>
    </div>
  )
}

MarkerLoader.propTypes = {
  totalMarkers: PropTypes.number.isRequired,
  processedMarkers: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
  loadingThreshold: PropTypes.number.isRequired,
}

MarkerLoader.defaultProps = {
  loadingThreshold: DEFAULT_MARKER_LOADING_THRESHOLD,
}

export default withTranslation([
  'src/components/maps/marker_loader',
])(MarkerLoader)
