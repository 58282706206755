import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

export const ListPlaceholder = ({ count = 10, listItemHeight = 40 }) => {
  const skeletons = Array.from({ length: count })
  return (
    <>
      {skeletons.map((_, index) => (
        <Skeleton
          key={index}
          height={listItemHeight}
          className={index > 0 ? 'mt-3' : ''}
        />
      ))}
    </>
  )
}
