import React, { PureComponent } from 'react'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import { graphql } from '@apollo/client/react/hoc'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import { Alert } from 'reactstrap'

import AppError from '@@src/utils/app_error'
import MessageBox from '@@src/components/modals/message_box'
import AsyncResult from '@@src/utils/async_result'
import NetworkAsset from '@@src/api/presenters/network_asset'
import * as analytics from '@@src/analytics'

class RemoveNetworkAssetModal extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    networkAsset: PropTypes.instanceOf(NetworkAsset).isRequired,
    toggle: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    voidNetworkAsset: PropTypes.func.isRequired,
    onSuccessfullyRemovedAsset: PropTypes.func.isRequired,
  }

  static defaultProps = {
    isOpen: false,
  }

  state = {
    result: AsyncResult.notFound(),
  }

  render() {
    const { t, toggle, isOpen, networkAsset,
      onSuccessfullyRemovedAsset } = this.props
    const { result } = this.state
    const assetName = networkAsset ? networkAsset.assetId : '???'
    const assetIsRemovable = this.assetIsRemovable()

    if (result.wasSuccessful()) {
      return (
        onSuccessfullyRemovedAsset()
      )
    } else {
      return (
        <MessageBox
          toggle={toggle}
          isOpen={isOpen}
          result={!assetIsRemovable ? AsyncResult.pending([]) : result}
          onAccept={this.handleAccept}
          acceptText={t('buttons.remove_asset')}
          cancelText={t('buttons.cancel')}
          headerText={t('headings.title')}>
          {
            !assetIsRemovable ?
              <Alert color="warning">
                {t('alerts.has_current_installations_or_groups')}
              </Alert>
              : null
          }
          {t('text.confirmation', { assetName })}
        </MessageBox>
      )
    }
  }

  handleAccept = async (event) => {
    const { networkAsset, toggle } = this.props

    event.preventDefault()

    try {
      if (!this.assetIsRemovable()) {
        throw new AppError('attempt to void asset with group or installation', {
          key: 'common/errors:validation.cannot_void_asset_with_group_or_installation', // eslint-disable-line max-len
        })
      }

      this.setState({ result: AsyncResult.pending() })

      await this.props.voidNetworkAsset({
        variables: {
          id: networkAsset.id,
        },
      })

      this.setState({ result: AsyncResult.success() })

      await toggle(event)
    } catch (err) {
      analytics.logError(err)

      this.setState({ result: AsyncResult.fail(err) })
    }
  }

  assetIsRemovable = () => {
    const { networkAsset } = this.props

    return networkAsset && networkAsset.currentInstallations.length === 0 &&
      networkAsset.groups.length === 0
  }

  static VOID_NETWORK_ASSET = gql`
    mutation VoidNetworkAsset($id: Int!) { voidNetworkAsset (id: $id) }
  `
}

export default compose(
  withTranslation([
    'src/management_path/network_assets_path/remove_network_asset_modal',
  ]),
  graphql(RemoveNetworkAssetModal.VOID_NETWORK_ASSET, {
    name: 'voidNetworkAsset',
  }),
)(RemoveNetworkAssetModal)
