import React, { memo } from 'react'
import { Alert, Button } from 'reactstrap'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { withTranslation } from 'react-i18next'

function EventFilterAlert(props) {
  const { t, className, handleClickShowAll } = props

  return (
    <Alert
      color="warning"
      className={
        classnames(
          className,
          'mb-0 w-100 b-0 rounded-0 d-flex justify-content-between align-items-center' // eslint-disable-line max-len
        )
      }>
      <span className="pr-4 text-dark">
        {t('button.only_showing_filtered_events')}
      </span>
      {
        handleClickShowAll ?
          <Button
            name="event-filter-btn-show-all"
            size="sm"
            color="secondary"
            onClick={handleClickShowAll}>
            {t('button.show_all_events')}
          </Button>
          : null
      }
    </Alert>
  )
}

EventFilterAlert.propTypes = {
  t: PropTypes.func.isRequired,
  eventSourceLocalisationId: PropTypes.string,
  handleClickShowAll: PropTypes.func,
  className: PropTypes.string,
}

export default withTranslation([
  'src/components/alerts/event_filter_alert',
])(memo(EventFilterAlert))
