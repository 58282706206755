import React from 'react'
import { Col, FormFeedback, Input, FormGroup, FormText } from 'reactstrap'
import { validAssetTypes } from '../../../../../../../utils'
import { useTranslation } from 'react-i18next'
import SelectInput from '../../../../../../components/inputs/select_input/select_input.component'

const assetTypes = validAssetTypes()

const NetworkAssetBasicFormFields = ({ register, errors }) => {
  const { t } = useTranslation([
    'v2/network_assets/forms',
    'src/management_path/network_assets_path/network_asset_form_modal',
    // eslint-disable-next-line max-len
    'src/management_path/devices_path/assign_network_asset_to_device_modal/create_and_assign_network_asset_interface/index',
  ])

  return(
    <FormGroup tag="fieldset" className="d-flex flex-row">
      <Col sm="4" className="m-0 p-0 pr-4 d-flex flex-column">
        <label className="h6">{t('text.types')}<strong style={{ color: 'red' }}>*</strong></label>
        <SelectInput
          t={(option) => t(`common/text:text.${option}`)}
          choiceOptions={assetTypes}
          inputProps={{
            id: 'assetType',
            type: 'select',
            required: true,
            ...register('assetType', {
              required: t('error_field_text.field_required'),
              pattern: {
                value: (value) => assetTypes.find(asset => asset === value) ?
                  true : t('error_field_text.asset_type'),
              },
            }),
          }}
        />
        <FormFeedback name="input-error">
          {errors.assetType && errors.assetType.message}
        </FormFeedback>
      </Col>
      <Col sm="8" className="m-0 p-0">
        <label className="h6">{t('text.asset_id')}<strong style={{ color: 'red' }}>*</strong></label>
        <Input {...register('assetId', { required: t('error_field_text.field_required') })} />
        <FormFeedback name="input-error" className={'d-block'}>
          {errors.assetId && errors.assetId.message}
        </FormFeedback>
        <FormText name="input-hint" className={'mb-4'}>
          {t('v2/network_assets/forms:info_field_text.unique_id')}
        </FormText>

        <label className="h6">{t('text.friendly_name')}</label>
        <Input {...register('friendlyName')} />
        <FormText name="input-hint">
          {t('v2/network_assets/forms:info_field_text.friendly_name')}
        </FormText>
      </Col>
    </FormGroup>
  )
}

export default NetworkAssetBasicFormFields
