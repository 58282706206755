import React from 'react'
import PropTypes from 'prop-types'
import chroma from 'chroma-js'
import GraphItem from '@@src/components/graphs/graph_item'
import { GraphConfig }
from '@@src/components/graphs/graph_context'
import { globalSequence } from '@@src/utils'
import PressureEventsPopup from '../../../../components/graphs/plot_markers/pressure_events_popup'
import PressureEventsMarker from '../../../../components/graphs/plot_markers/pressure_events_marker'
import PressureEventCluster from '../../../../components/graphs/plot_markers/pressure_events_cluster'

const CLUSTER_SIZE = 21

class PressureEventsLayer extends React.PureComponent {
  static defaultProps = {
    setActiveExpandedEventId: () => { },
    isActiveEventExpanded: false,
  }

  static propTypes = {
    graphItems: PropTypes.arrayOf(PropTypes.instanceOf(GraphItem)).isRequired,
    graphConfig: PropTypes.instanceOf(GraphConfig).isRequired,
    pressureEventsWithDataAndColor: PropTypes.array.isRequired,
    setActiveExpandedEventId: PropTypes.func.isRequired,
    isActiveEventExpanded: PropTypes.bool.isRequired,
    activeEventId: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
  }

  render() {
    const {
      name,
      graphConfig,
      pressureUnits,
      pressureEventsWithDataAndColor,
      eventIndexInSet,
      setActiveEventForSlice,
      isActiveEventExpanded,
    } = this.props
    const { xScale, yScale } = graphConfig
    const threshold = 3 * Math.abs(xScale.invert(0) - xScale.invert(CLUSTER_SIZE))
    const clipPathId = `pressure-events-layer-clip-path-${this.id}`

    return (
      <g name={name || 'pressure-events-layer'}>
        <defs>
          <clipPath id={clipPathId}>
            <rect
              x={graphConfig.leftPadding}
              y={graphConfig.topPadding}
              width={graphConfig.plotAreaWidth}
              height={graphConfig.plotAreaHeight} />
          </clipPath>
        </defs>

        <g name="pressure-event-markers-layer" clipPath={`url(#${clipPathId})`}>
          {
            pressureEventsWithDataAndColor.map((eventData, index) => {
              if (!eventData.data || eventData.data.length === 0) {
                return null
              }

              const isActive = eventIndexInSet.sliceIndex === index
              if (eventData.singleEventDetails &&
                !isTooClose(
                  new Date(eventData.singleEventDetails.utcStart).getTime(),
                  new Date(eventData.singleEventDetails.utcEnd).getTime(),
                  threshold,
                )) {
                const color = !isActive ?
                  eventData.color :
                  chroma(eventData.color).darken()
                return <PressureEventsMarker
                  id={`event-marker-${index}`}
                  index={index}
                  clipPath={`url(#${clipPathId})`}
                  graphConfig={graphConfig}
                  pressureData={eventData.data}
                  color={color}
                  isActive={isActive}
                  onClick={() => setActiveEventForSlice(index)}
                >
                  {
                    isActive ?
                      <PressureEventsPopup
                        currentlySelectedEvent={eventIndexInSet.eventIndex}
                        start={eventData.singleEventDetails.utcStart}
                        end={eventData.singleEventDetails.utcEnd}
                        pressureUnits={pressureUnits}
                        networkAssetChannel={{
                          networkAssetId: eventData.assetId,
                          channel: eventData.assetChannel,
                        }}
                        eventId={eventData.singleEventDetails && eventData.singleEventDetails.eventId}
                        isActiveEventExpanded={isActiveEventExpanded}
                        isActive={isActive}
                        onRemoveExpandedEvent={this.onRemoveExpandedEvent} />
                      : null
                  }
                </PressureEventsMarker>
              } else {
                const color = !isActive ?
                  eventData.color :
                  chroma(eventData.color).darken()
                return (
                  <PressureEventCluster
                    id={index}
                    key={index}
                    x={eventData.data[0].time ? xScale(eventData.data[0].time) : 0}
                    y={eventData.data[0].mean ? yScale(eventData.data[0].mean) : 0}
                    isActive={isActive}
                    color={color}
                    count={eventData.eventsCount}
                    onClick={() => setActiveEventForSlice(index)}>
                    {
                      isActive ?
                        <PressureEventsPopup
                          currentlySelectedEvent={eventIndexInSet.eventIndex}
                          start={eventData.sliceUtcStart}
                          end={eventData.sliceUtcEnd}
                          pressureUnits={pressureUnits}
                          networkAssetChannel={{
                            networkAssetId: eventData.assetId,
                            channel: eventData.assetChannel,
                          }}
                          isActiveEventExpanded={isActiveEventExpanded}
                          isActive={isActive}
                          onRemoveExpandedEvent={this.onRemoveExpandedEvent} />
                        : null
                    }

                  </PressureEventCluster>
                )
              }
            })
          }
        </g>
      </g>
    )
  }

  constructor(props) {
    super(props)

    this.id = globalSequence.next()
  }

  componentWillUnmount() {
    const { setActiveExpandedEventId } = this.props

    setActiveExpandedEventId(0, false)
  }

  onRemoveExpandedEvent = event => {
    const { activeEventId, setActiveExpandedEventId } = this.props

    event.stopPropagation()

    setActiveExpandedEventId(activeEventId, false)
  }
}

function isTooClose(a, b, threshold) {
  return Math.abs(a - b) <= threshold
}

export default PressureEventsLayer
