import gql from 'graphql-tag'

export const EXPORT_DEVICES_MUTATION = gql`
mutation OrderDevicesListReport(
  $filters: GetDevicesListFilters,
  $orderBy: GetDevicesListOrderBy,
  $orderDirection: OrderDirection,
) {
    requestDevicesListReport(
      filters: $filters,
      orderBy: $orderBy,
      orderDirection: $orderDirection,
    ) {
      jobId
    }
  }
`
