import gql from 'graphql-tag'

export const GET_DEVICES_FOR_LIST_QUERY = gql`
  query GetDevicesForList (
    $filters: GetDevicesListFilters,
    $orderBy: GetDevicesListOrderBy,
    $orderDirection: OrderDirection,
    $pageNumber: Int,
    $resultsPerPage: Int,
  ) {
    getDevicesList(
      filters: $filters,
      orderBy: $orderBy,
      orderDirection: $orderDirection,
      pageNumber: $pageNumber,
      resultsPerPage: $resultsPerPage,
    ) {
      pagination {
        perPage
        pageIndex
        pageNumber
        totalPages
        totalResults
      }
      devices {
        deviceId
        serialNumber
        lastCommunicationTime
        lastRecordedDataTime
        scheduledSessionPeriod
        isCommissioned
        isInstalled
        installation {
            assetId
            assetName
            assetType
            assetChannel
        }
        mostSeverityIssue {
            issueType
            issueSeverity
        }
        activeErrorsCount
        activeWarningsCount
        activeInfoCount
      }
    }
  }
`
