import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { withTranslation } from 'react-i18next'

import { MINUTES } from '@@src/utils'
import Device from '@@src/api/presenters/device'

class UpdatingDeviceDate extends React.PureComponent {
  static propTypes = {
    incrementAmountMilliseconds: PropTypes.number.isRequired,
    t: PropTypes.func.isRequired,
    device: PropTypes.instanceOf(Device).isRequired,
    refetchDevice: PropTypes.func.isRequired,
    children: PropTypes.func.isRequired,
  }

  static defaultProps = {
    incrementAmountMilliseconds: 1 * MINUTES,
  }

  constructor(props) {
    const now = moment()
    const endOfMinute = now.clone().endOf('minute')
    const millisecondsUntilNextMinute =
      moment.duration(endOfMinute.diff(now)).asMilliseconds()

    super(props)
    this.setIncrementerIntervalTimeout = global.setTimeout(
      this.setIncrementerInterval,
      millisecondsUntilNextMinute
    )
  }

  render() {
    const { children } = this.props

    return (
      <span>
        {children()}
      </span>
    )
  }

  setIncrementerInterval = () => {
    const { incrementAmountMilliseconds, refetchDevice } = this.props

    this.forceUpdate()

    this.incrementerInterval = global.setInterval(
      () => {
        const { device } = this.props

        if (device.isCommunicatingPendingOrActive()) {
          refetchDevice()
        }

        this.forceUpdate()
      },
      Math.abs(incrementAmountMilliseconds)
    )
  }

  componentWillUnmount() {
    if (this.incrementerInterval) {
      global.clearInterval(this.incrementerInterval)
    }

    if (this.setIncrementerIntervalTimeout) {
      global.clearTimeout(this.setIncrementerIntervalTimeout)
    }
  }
}

export default withTranslation([
  'common/text',
])(UpdatingDeviceDate)
