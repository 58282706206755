import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash/fp/object'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'

import * as SharedColorStore from '@@src/store/shared_color_store'

export const LegendItemColorContext = React.createContext()

class LegendItemColorProvider extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node,
  }

  render() {
    const { children } = this.props
    const legendItemColorManager = this.selectLegendItemColorManager(this.props)

    return (
      <LegendItemColorContext.Provider value={legendItemColorManager}>
        {children}
      </LegendItemColorContext.Provider>
    )
  }

  selectLegendItemColorManager = createSelector(
    [get('assignableColors'), get('getLegendItemColor')],
    (assignableColors, getLegendItemColor) => ({
      assignableColors: Array.from(assignableColors),
      getLegendItemColor,
      getSetLegendItemColor: (item, color) => () => (
        this.props.dispatchUpdateColorFor(item.id, color)
      ),
      clearUserAssignedColors: () => this.props.dispatchResetColors(),
      getClearUserLegendItemColor: item => () => (
        this.props.dispatchResetColorFor(item.id)
      ),
    })
  )
}

function mapStateToProps(state) {
  return {
    assignableColors: SharedColorStore.selectAssignableColors(state),
    getLegendItemColor: item => SharedColorStore.selectColorFor(state, item.id),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchUpdateColorFor(id, color) {
      return dispatch(SharedColorStore.updateColorFor(id, color))
    },

    dispatchResetColorFor(id) {
      return dispatch(SharedColorStore.resetColorFor(id))
    },

    dispatchResetColors() {
      return dispatch(SharedColorStore.resetColors())
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LegendItemColorProvider)
