import React, { createContext } from 'react'
import { withTranslation } from 'react-i18next'

export const NetworkAssetMarkerTranslationContext = createContext()

const NetworkAssetMarkerTranslationProvider = ({ t, children }) => (
  <NetworkAssetMarkerTranslationContext.Provider value={t}>
    {children}
  </NetworkAssetMarkerTranslationContext.Provider>
)

export default withTranslation([
  'src/components/maps/markers/network_asset_popup_content',
  'src/components/maps/markers/street_view_link',
  'common/text',
])(NetworkAssetMarkerTranslationProvider)
