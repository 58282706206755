import { useCallback, useRef, useState } from 'react'
import { CREATE_NETWORK_ASSET_MUTATION } from '../../../../api/queries/network_asset'
import { useApolloClient } from '@apollo/client'

export const useAddNewNetworkAsset = () => {
  const client = useApolloClient()
  const abortController = useRef(null)

  const [loading, setLoading] = useState(false)

  const addNewNetworkAsset = useCallback((variables, callback) => {
    const {
      group,
      assetId,
      comments,
      latitude,
      longitude,
      elevation,
      assetType,
      friendlyName,
      customLabels,
      source,
    } = variables
    const altitude = parseFloat(elevation)

    if (abortController.current) {
      abortController.current.abort()
    }

    abortController.current = new AbortController()
    setLoading(true)

    client
      .mutate({
        mutation: CREATE_NETWORK_ASSET_MUTATION,
        variables: {
          groupIds: group ? [group.groupId] : [],
          assetId,
          comment: comments,
          location: {
            altitude,
            latitude,
            longitude,
          },
          assetType,
          assetName: friendlyName,
          customLabels: customLabels?.length && customLabels,
          locationSource: source,
        },
      })
      .then((result) => {
        callback('success', result.data.createNetworkAsset.id)
        setLoading(false)
      })
      .catch(error => {
        callback(error.message)
        setLoading(false)
      })

  }, [abortController, setLoading, client])

  return { addNewNetworkAsset, loading }
}
