import React from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'reactstrap'
import { withTranslation } from 'react-i18next'

import Device from '@@src/api/presenters/device'
import AppFormGroup from '@@src/components/forms/app_form_group'
import CoordinatesInputFormGroup
from '@@src/components/forms/inputs/coordinates_input_form_group'
import {
  SUPPORTED_COORDS_FORMAT,
} from '@@src/utils/conversions/coordinate_conversions'

export const LOCATION_SOURCE_GPS = 'gps'
export const LOCATION_SOURCE_SURVEY = 'survey'

export const LOCATION_SOURCE_OPTIONS = [
  LOCATION_SOURCE_SURVEY,
  LOCATION_SOURCE_GPS,
]

class LocationInformationInputGroup extends React.PureComponent {
  static propTypes = {
    device: PropTypes.instanceOf(Device),
    className: PropTypes.string,

    locationSourceLabel: PropTypes.string,
    locationSourceValue: PropTypes.oneOf(LOCATION_SOURCE_OPTIONS).isRequired,
    locationSourceRequired: PropTypes.bool,
    onChangeLocationSource: PropTypes.func.isRequired,
    locationSourceErrorText: PropTypes.string,

    elevationLabel: PropTypes.string,
    elevationValue: PropTypes.string.isRequired,
    elevationRequired: PropTypes.bool,
    onChangeElevation: PropTypes.func.isRequired,
    elevationErrorText: PropTypes.string,
    elevationPlaceholder: PropTypes.string,

    coordinatesLabel: PropTypes.string,
    coordinatesValue: PropTypes.string,
    coordinatesRequired: PropTypes.bool,
    onChangeCoordinates: PropTypes.func.isRequired,
    coordinatesErrorText: PropTypes.string,
    coordinatesFormatValue: PropTypes.oneOf(SUPPORTED_COORDS_FORMAT).isRequired,
    onChangeCoordinatesFormat: PropTypes.func.isRequired,
  }

  static defaultProps = {
    elevationRequired: false,
  }

  render() {
    const {
      t, className, locationSourceLabel, locationSourceValue, device,
      locationSourceRequired, locationSourceErrorText, onChangeLocationSource,
      coordinatesErrorText, coordinatesValue, onChangeCoordinates,
      elevationLabel, elevationValue, onChangeElevation, elevationRequired,
      elevationErrorText, coordinatesFormatValue, onChangeCoordinatesFormat,
      coordinatesLabel, elevationPlaceholder,
    } = this.props

    return (
      <div className={className}>
        <Row>
          <Col sm="6">
            <AppFormGroup
              id="network-asset-location-source"
              name="network-asset-location-source"
              type="select"
              label={locationSourceLabel || t('labels.location_source')}
              value={locationSourceValue}
              onChange={onChangeLocationSource}
              required={locationSourceRequired}
              errorText={locationSourceErrorText}>
              <option value={LOCATION_SOURCE_SURVEY}>
                {t('text.location_source_option_survey')}
              </option>

              <option
                value={LOCATION_SOURCE_GPS}
                disabled={!device || !device.coordinates}>
                {t('text.location_source_option_gps')}
              </option>
            </AppFormGroup>
          </Col>

          <Col sm="6">
            <AppFormGroup
              id="network-asset-elevation"
              name="network-asset-elevation"
              type="text"
              label={elevationLabel || t('labels.elevation')}
              value={elevationValue}
              onChange={onChangeElevation}
              required={elevationRequired}
              errorText={elevationErrorText}
              placeholder={elevationPlaceholder}>
            </AppFormGroup>
          </Col>
        </Row>

        <CoordinatesInputFormGroup
          id="network-asset-coordinates"
          name="network-asset-coordinates"
          label={coordinatesLabel || t('labels.coordinates')}
          disabled={locationSourceValue === LOCATION_SOURCE_GPS}
          errorText={coordinatesErrorText}
          coordinatesValue={coordinatesValue}
          onChangeCoordinates={onChangeCoordinates}
          coordinatesFormatValue={coordinatesFormatValue}
          onChangeCoordinatesFormat={onChangeCoordinatesFormat}/>
      </div>
    )
  }
}

export default withTranslation([
  'src/components/forms/inputs/location_information_input_group',
  'common/text',
])(LocationInformationInputGroup)
