export function parseBooleanFilter(value) {
  if (value === '1' || value === 'true' || value === true) {
    return true
  } else if (value === '0' || value === 'false' || value === false) {
    return false
  }

  return null
}

export function mapDevicesHealthPropsToFilterObject({
  serialNumber, networkAssetReadableId, networkAssetReadableName,
  groups, hasActiveAlerts, hasDataGaps, hasBatteryIssues,
  signalStrengthMin, signalStrengthMax,
  batteryVoltageMin, batteryVoltageMax,
}) {
  const filters = {
    serialNumber, groups,
    networkAssetReadableId,
    networkAssetReadableName,
    signalStrengthMin,
    signalStrengthMax,
    hasDataGaps: parseBooleanFilter(hasDataGaps),
    hasActiveAlerts: parseBooleanFilter(hasActiveAlerts),
    hasBatteryIssues: parseBooleanFilter(hasBatteryIssues),
  }

  if (![typeof signalStrengthMin, typeof signalStrengthMax].includes('undefined')) {
    const [min, max] = [signalStrengthMin, signalStrengthMax]
      .sort((a, b) => a - b)

    filters.signalStrengthMin = min
    filters.signalStrengthMax = max
  }

  if (batteryVoltageMin !== '' && typeof batteryVoltageMin !== 'undefined') {
    filters.batteryVoltageMin = +batteryVoltageMin * 1000
  }

  if (batteryVoltageMax !== '' && typeof batteryVoltageMax !== 'undefined') {
    filters.batteryVoltageMax = +batteryVoltageMax * 1000
  }

  if (![typeof batteryVoltageMin, typeof batteryVoltageMax].includes('undefined')) {
    const [min, max] = [batteryVoltageMin, batteryVoltageMax]
      .sort((a, b) => a - b)

    filters.batteryVoltageMin = min
    filters.batteryVoltageMax = max
  }

  return filters
}
