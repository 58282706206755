import React from 'react'
import PropTypes from 'prop-types'
import {
  Form,
  Alert,
  Button,
  Row,
  Col,
} from 'reactstrap'
import gql from 'graphql-tag'
import { get } from 'lodash/fp/object'
import { compose } from 'redux'
import { graphql } from '@apollo/client/react/hoc'
import { createSelectGraphQLResult, AsyncResult } from '@@src/utils'
import NetworkAsset from '@@src/api/presenters/network_asset'
import { parseGraphQLResult } from '@@src/api/presenters'
import { withTranslation } from 'react-i18next'
import ErrorInfo from '@@src/components/error_info'
import transformProps from '@@src/components/transform_props'
import AsyncResultSwitch from '@@src/components/async_result_switch'
import SubmitButton from '@@src/components/buttons/submit_button'
import * as analytics from '@@src/analytics'

import NetworkAssetFields, { COORDINATE_REGEX } from '../network_asset_fields'
import requiresLogin from '@@src/components/security/requires_login'
import StandardMap from '@@src/components/maps/standard_map'
import { createSelector } from 'reselect'
import MarkerClusterGroup from 'react-leaflet-markercluster'
import NetworkAssetMarker
from '@@src/components/maps/markers/network_asset_marker'
import AppSettingsConsumer from '@@src/components/app_settings_consumer'
import LocationInformationInputGroup
from '@@src/components/forms/inputs/location_information_input_group'
import { AVAILABLE_DISTANCE_UNITS } from '@@src/utils/unit_constants'
import {
  COORDS_FORMAT_DEFAULT,
} from '@@src/utils/conversions/coordinate_conversions'
import {
  convertUnitFromDB,
  convertUnitToDB,
  DISTANCE_TYPE,
} from '@@src/utils/app_unit_conversion'
import {
  LOCATION_SOURCE_GPS,
} from
'@@src/components/forms/inputs/location_information_input_group'

import styles from './edit_location_configuration_select.css'

class EditAssetLocation extends React.PureComponent {

  static propTypes = {
    distanceUnits: PropTypes.oneOf(AVAILABLE_DISTANCE_UNITS).isRequired,
    refetchData: PropTypes.func.isRequired,
  }

  render() {
    const { t } = this.props
    const { result } = this.state

    return (
      <React.Fragment>
        <h2 className="h2 mb-0">
          {t('headings.location')}
        </h2>
        <hr className="mb-5" />

        {
          result.wasSuccessful() ? (
            <Alert color="success">
              {t('common/forms:text.update_successful')}
            </Alert>
          ) : null
        }
        {result.wasFailure() ? <ErrorInfo error={result.error}/> : null}

        <AsyncResultSwitch
          result={this.selectNetworkAssetLocationResult(this.props)}
          renderSuccessResult={this.renderSettingsSelectors} />
      </React.Fragment>
    )
  }

  constructor(props) {
    super(props)

    this.fields = new NetworkAssetFields(this, 'networkAssetFields')

    this.state = {
      result: AsyncResult.notFound(),
      networkAssetFields: this.fields.initialState({
        coordinatesFormat: COORDS_FORMAT_DEFAULT,
      }),
      fetching: false,
    }
  }

  async componentDidUpdate(prevProps) {
    const { networkAssetLocation } = this.props
    const { fetching } = this.state

    if(
      prevProps.networkAssetLocation.isPending()
      && networkAssetLocation.wasSuccessful()
      || fetching && networkAssetLocation.wasSuccessful()
    ) {
      const {
        data: {
          coordinates,
          assetId,
          assetType,
          locationSource,
          location,
        },
      } = networkAssetLocation

      await this.setState({
        fetching: false,
        networkAssetFields: this.fields.initialState({
          coordinates,
          elevation: location.altitude,
          assetId,
          assetType,
          locationSource,
          coordinatesFormat: COORDS_FORMAT_DEFAULT,
        }),
      })
    }
  }

  selectFieldValue(fieldName) {
    return this.fields.selectValue(this.state, fieldName)
  }

  selectFieldErrorText(fieldName) {
    const error = this.fields.selectError(this.state, fieldName)
    return error ? this.props.t(error) : error
  }

  onSubmitForm = async event => {
    const { refetchData, distanceUnits } = this.props
    const {
      id, assetId, assetName, assetType,
    } = this.props.networkAssetLocation.data
    event.preventDefault()
    await this.setState({ result: AsyncResult.pending() })

    try {
      const coordinates = this.selectFieldValue('coordinates')
      const [latitude, longitude] = coordinates.split(',').map(parseFloat)
      const altitude = parseFloat(this.selectFieldValue('elevation') || '0')
      const convertedAltitude = convertUnitToDB(
        altitude,
        distanceUnits,
        DISTANCE_TYPE,
      )
      const locationSource = this.selectFieldValue('locationSource')

      await this.props.editAssetLocation({
        variables: {
          id,
          assetId,
          assetType,
          assetName,
          locationSource,
          location: {
            altitude: convertedAltitude,
            latitude,
            longitude,
          },
        },
      })
      await this.setState({
        result: AsyncResult.success(),
        networkAssetFields: this.fields.initialState({
          coordinates,
          elevation: altitude,
          assetId,
          assetType,
          locationSource,
          coordinatesFormat: COORDS_FORMAT_DEFAULT,
        }),
      })

      if (refetchData) {
        await refetchData()
      }
    } catch (e) {
      analytics.logError(e)
      await this.setState({ result: AsyncResult.fail(e) })
    }
  }

  createSelectFieldValueFor = fieldName => {
    return (props, state) => {
      return this.fields.selectValue(state, fieldName)
    }
  }

  onResetForm = async event => {
    event.preventDefault()
    await this.setState({ fetching: true })
  }

  renderSettingsSelectors = () => {

    const { t, distanceUnits } = this.props
    const { result } = this.state

    const device =
      this.props.networkAssetLocation.data.currentInstallations.length === 0
        ? null
        : this.props.networkAssetLocation.data.currentInstallations[0].device

    const validNetworkAsset =
      this.selectNetworkAssetIfValid(this.props, this.state)

    this.selectFieldValue('elevation')

    return (
      <React.Fragment>
        <Form className="pb-3" name="network-asset-location">
          <Row>
            <Col sm="12">
              <LocationInformationInputGroup
                device={device}
                locationSourceValue={this.selectFieldValue('locationSource')}
                onChangeLocationSource={
                  this.onChangeLocationSource
                }
                locationSourceRequired={true}
                locationSourceErrorText={
                  this.selectFieldErrorText('locationSource')
                }
                elevationLabel={`${t('labels.elevation')} (${
                  t(
                    `common/text:units.${distanceUnits}`,
                    // Pluralise units
                    { count: 2 }
                  )
                })`}
                elevationValue={this.selectFieldValue('elevation')}
                onChangeElevation={this.fields.onChangeHandlerFor('elevation')}
                elevationErrorText={this.selectFieldErrorText('elevation')}

                coordinatesValue={this.selectFieldValue('coordinates')}
                onChangeCoordinates={
                  this.fields.onChangeHandlerFor('coordinates')
                }
                coordinatesRequired={true}
                coordinatesErrorText={this.selectFieldErrorText('coordinates')}
                coordinatesFormatValue={
                  this.selectFieldValue('coordinatesFormat')
                }
                onChangeCoordinatesFormat={this.onChangeCoordinatesFormat}/>

              <StandardMap
                center={this.selectMapCenter(this.props, this.state)}
                className={styles['location-preview-map']}>
                <MarkerClusterGroup
                  showCoverageOnHover={false}
                  removeOutsideVisibleBounds={true}>
                  {
                    validNetworkAsset ? (
                      <NetworkAssetMarker networkAsset={validNetworkAsset}/>
                    ) : null
                  }
                </MarkerClusterGroup>
              </StandardMap>

              <div className="mt-5">
                <SubmitButton
                  name="submit-changes"
                  color="primary"
                  submitText={t('common/forms:buttons.save')}
                  result={result}
                  disabled={
                    this.fields.hasAnyValidationErrors()
                    || this.fields.isPristine()
                  }
                  onSubmitForm={this.onSubmitForm}
                />

                <Button
                  name="discard-changes"
                  disabled={this.fields.isPristine()}
                  color="secondary"
                  className="ml-2"
                  onClick={this.onResetForm}
                >
                  { t('common/forms:buttons.discard_changes') }
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    )
  }

  onChangeCoordinatesFormat = async format => {
    await this.fields.updateFieldValue('coordinatesFormat', format)
  }

  onChangeLocationSource = async source => {
    const gpsLocation = this.selectDeviceLocation(this.props)
    const value = source.target.value
    if (value === LOCATION_SOURCE_GPS && gpsLocation) {
      await this.fields.updateFieldValue('coordinates', gpsLocation)
    } else {
      await this.fields.updateFieldValue(
        'coordinates',
        this.props.networkAssetLocation.data.coordinates
      )
    }

    // If we are not sourcing the location from GPS, reset it to current value
    await this.fields.updateFieldValue('locationSource', value)
    await this.fields.updateFieldValue(
      'coordinatesFormat',
      COORDS_FORMAT_DEFAULT
    )
  }

  // Select the first relevant device location available from current installations, or provide null
  selectDeviceLocation = createSelector(
    [
      get('networkAssetLocation'),
    ], (networkAssetLocation) => {
      const { data: { currentInstallations } } = networkAssetLocation
      if (currentInstallations.length === 0) {
        return null
      }
      // return the first available coordinates
      if (currentInstallations[0].device) {
        return currentInstallations[0].device.coordinates
      }

      return null
    }
  )

  selectNetworkAssetIfValid = createSelector(
    [
      this.createSelectFieldValueFor('assetType'),
      this.createSelectFieldValueFor('assetId'),
      this.createSelectFieldValueFor('coordinates'),
    ],
    (assetType, assetId, coordinates) => {
      const match = coordinates.match(COORDINATE_REGEX)

      return match ? NetworkAsset.from({
        assetId,
        location: {
          latitude: parseFloat(match[1]),
          longitude: parseFloat(match[2]),
        },
        assetType,
      }) : null
    }
  )

  selectMapCenter = createSelector(
    [this.selectNetworkAssetIfValid],
    networkAsset => networkAsset ? [
      networkAsset.location.latitude,
      networkAsset.location.longitude,
    ] : undefined
  )

  selectNetworkAssetLocationResult = createSelector(
    [
      get('networkAssetLocation'),
      get('distanceUnits'),
    ], (networkAssetLocation, distanceUnits) => {
      if (networkAssetLocation.wasSuccessful()) {
        const { data:
          { location: { altitude } } } = networkAssetLocation
        const result = { ...networkAssetLocation }
        // Convert altitude units in selector
        result.data.location.altitude = convertUnitFromDB(
          altitude,
          distanceUnits,
          DISTANCE_TYPE
        )
      }
      return networkAssetLocation
    }
  )

  static GET_ASSET_LOCATION = gql`
    query NetworkAssetLocation($id: Int!) {
      networkAsset(id: $id) {
        id
        assetId
        location {
          latitude
          longitude
          altitude
        }
        installations {
          end
          start
          deviceId
          device {
            id
            serialNumber
            lastKnownLocation {
              latitude
              longitude
            }
            currentCommission {
              start
              location {
                latitude
                longitude
              }
              end
            }
          }
          channelMap {
            pressure_1
          }
          networkAssetId
        }
        assetType
        locationSource
      }
    }
  `

  static EDIT_ASSET_LOCATION = gql`
    mutation EditNetworkAsset(
      $id: Int!,
      $location: AssetLocationInput!,
      $assetType: InflowNetAssetType,
      $locationSource: LocationSourceType,
    ) {
      editNetworkAsset(
        id: $id,
        location: $location,
        assetType: $assetType,
        locationSource: $locationSource,
      )
    }
  `
}

function EditAssetLocationContainer(props) {
  return (
    <AppSettingsConsumer>
      {
        (units) => (
          <EditAssetLocation
            distanceUnits={units.distance}
            {...props}
          />
        )
      }
    </AppSettingsConsumer>
  )
}

export default compose(
  requiresLogin,
  withTranslation([
    // eslint-disable-next-line
    'src/management_path/network_assets_path/edit_network_asset_page_sections/edit_location_configuration_section',
    'common/forms',
  ]),
  transformProps(() => () => ({
    selectAssetLocationResult: createSelectGraphQLResult(
      'networkAsset',
      {
        mapResult: parseGraphQLResult,
      }
    ),
  })),
  graphql(EditAssetLocation.GET_ASSET_LOCATION, {
    options: ({ assetId }) => {
      return {
        fetchPolicy: 'network-only',
        variables: {
          id: assetId,
        },
      }
    },
    props: ({ data, ownProps }) => {
      const { selectAssetLocationResult } = ownProps
      const { refetch } = data
      return {
        refetchData: refetch,
        networkAssetLocation: selectAssetLocationResult(data),
      }
    },
  }),
  graphql(EditAssetLocation.EDIT_ASSET_LOCATION, {
    name: 'editAssetLocation',
  }),
)(EditAssetLocationContainer)
