import { graphql } from '@apollo/client/react/hoc'
import classnames from 'classnames'
import { push } from 'connected-react-router'
import gql from 'graphql-tag'
import { get } from 'lodash/fp/object'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import { Button, Col, InputGroup, InputGroupText, Row } from 'reactstrap'
import { compose } from 'redux'
import { createSelector } from 'reselect'

import * as analytics from '@@src/analytics'
import { parseGraphQLResult } from '@@src/api/presenters'
import AppLayout from '@@src/components/app_layout'
import DebouncedInput from '@@src/components/forms/debounced_input'
import AsyncList from '@@src/components/lists/async_list'
import AdvancedFilterModal from '@@src/components/modals/advanced_filter_modal'
import ResendUserInvitation from '@@src/components/modals/resend_user_invitation_modal/resend_user_invitation_modal'
import UserRoleFilters from '@@src/components/modals/user_role_filters'
import getWithPagination from '@@src/components/pagination/pagination_container'
import PaginationToolbar from '@@src/components/pagination/pagination_toolbar'
import requiresLogin from '@@src/components/security/requires_login'
import getSortDropdown from '@@src/components/sort_component/sort_component'
import {
  createSelectGraphQLResult,
  getResultString,
  mergeSearchParams,
  parseSearchParams,
} from '@@src/utils'
import AsyncResult from '@@src/utils/async_result'
import DebouncedInputController from '@@src/utils/debounced_input_controller'
import { CAN_MANAGE_USERS } from '../../_v2/contexts/user/consts/permissions'
import { withUser } from '../../_v2/contexts/user/user.context'
import CreateUserModal from './create_user_modal'
import DeleteUserModal from './delete_user_modal'
import EditUserModal from './edit_user_modal'

import styles from './index_page.css'

export const SEED_USERS_PAGE_NUMBER = 1
export const SEED_USERS_RESULTS_PER_PAGE = 10
export const VISIBLE_USERS_PAGES = 3

const SortDropdown = getSortDropdown()

class AdminUsersPage extends PureComponent {
  static defaultProps = {
    usersOrder: 'name',
    usersOrderDirection: 'asc',
    userRolesResult: AsyncResult.pending([]),
    pagedUsersResult: AsyncResult.pending([]),
    result: AsyncResult.notFound(),
  }

  static propTypes = {
    t: PropTypes.func.isRequired,
    userRolesResult: PropTypes.instanceOf(AsyncResult).isRequired,
    pagedUsersResult: PropTypes.instanceOf(AsyncResult).isRequired,
    result: PropTypes.instanceOf(AsyncResult).isRequired,
    refetchUsersData: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      isEditUserModalOpen: false,
      isCreateUserModalOpen: false,
      isDeleteUserModalOpen: false,
      isAdvancedFilterModalOpen: false,
      isResendInvitationModalOpen: false,
      userRoleFilters: [],
      userRoleFiltersShadow: [],
      searchInputController: new DebouncedInputController(),
    }
  }

  render() {
    const {
      t,
      userRolesResult,
      pagedUsersResult,
      resultsPerPage,
      setPageNumber,
      setResultsPerPage,
      usersOrder,
      usersOrderDirection,
      usersQuery,
      authorizedUser,
    } = this.props

    // Does the user have permissions for this page?
    if (!authorizedUser.can(CAN_MANAGE_USERS)) {
      return <Redirect to="/page-not-found" />
    }

    const {
      searchInputController,
      isEditUserModalOpen,
      isCreateUserModalOpen,
      isDeleteUserModalOpen,
      selectedUser,
      userRoleFiltersShadow,
      isAdvancedFilterModalOpen,
      isResendInvitationModalOpen,
    } = this.state
    const resultsString = getResultString(t, pagedUsersResult, resultsPerPage)

    const sortList = [
      { value: 'name', text: t('options.name') },
      { value: 'email', text: t('options.email') },
    ]

    const orderBy = this.getOrderNameForOrderType(sortList, usersOrder)
    const orderDir =
      usersOrderDirection === 'desc' ? t('text.desc') : t('text.asc')
    const orderingString = `${t('text.order_by', {
      orderDir,
      orderBy,
    })}`

    return (
      <AppLayout title={t('headings.page_title')}>
        {isEditUserModalOpen ? (
          <EditUserModal
            isOpen={isEditUserModalOpen}
            user={selectedUser}
            roles={userRolesResult.data}
            toggle={this.onToggleEditUserModal}
            onSuccess={this.successfullyEditedCreatedDeletedUser}
          />
        ) : null}

        {isCreateUserModalOpen ? (
          <CreateUserModal
            isOpen={isCreateUserModalOpen}
            loggedInUser={authorizedUser}
            roles={userRolesResult.data}
            toggle={this.onToggleCreateUserModal}
            onSuccess={this.successfullyEditedCreatedDeletedUser}
          />
        ) : null}

        {isDeleteUserModalOpen ? (
          <DeleteUserModal
            isOpen={isDeleteUserModalOpen}
            user={selectedUser}
            toggle={this.onToggleDeleteUserModal}
            onSuccess={this.successfullyEditedCreatedDeletedUser}
          />
        ) : null}

        {isResendInvitationModalOpen ? (
          <ResendUserInvitation
            isOpen={isResendInvitationModalOpen}
            username={selectedUser.username}
            toggle={this.onToggleResendModal}
          />
        ) : null}

        <AdvancedFilterModal
          handleClearFilters={this.handleClearAdvancedFilters}
          isOpen={isAdvancedFilterModalOpen}
          handleAccept={this.handleChangeUserRolesFilter}
          handleToggle={this.handleAdvancedFilterToggle}
        >
          <Row>
            <Col>
              <UserRoleFilters
                onChangeSelection={this.handleUserRoleFiltersChange}
                userRoleFilters={userRoleFiltersShadow}
              />
            </Col>
          </Row>
        </AdvancedFilterModal>

        <div className={styles.container}>
          <div className={styles['inner-container']}>
            <Row>
              <Col sm="12">
                <Col sm="12" className={styles['title-row']}>
                  <h1 className={styles.title}>{t('text.title')}</h1>

                  <div className={styles['title-button-user ']}>
                    <span data-tip={t('common/text:permissions.disabled')}>
                      <Button
                        disabled={!authorizedUser.can(CAN_MANAGE_USERS)}
                        name="create-user-button"
                        color="primary"
                        onClick={() => this.openCreateUserModal(user)}
                      >
                        <i className="far fa-plus"></i>
                        &nbsp;
                        {t('buttons.create_new_user')}
                      </Button>
                    </span>
                    {authorizedUser.can(CAN_MANAGE_USERS) ? null : (
                      <ReactTooltip effect="solid" place="bottom" />
                    )}
                  </div>
                </Col>
              </Col>
            </Row>

            <Row className={styles['search-and-filters-row']}>
              <Col>
                <div className={styles['search-results-text']}>
                  {pagedUsersResult.isPending()
                    ? t('text.results_pending')
                    : resultsString}
                </div>
                <div className={styles['ordering-text']}>
                  {pagedUsersResult.isPending() ? '' : orderingString}
                </div>
              </Col>

              <div className={styles['filters-column']}>
                <Button
                  name="clear-filters-button"
                  color="secondary"
                  outline
                  onClick={this.onClearFilters}
                >
                  {t('buttons.clear_filters')}
                </Button>

                <InputGroup className={styles['search-input']}>
                  <DebouncedInput
                    type="search"
                    name="users-search-input"
                    debounce={200}
                    onChange={this.onChangeUsersQuery}
                    controller={searchInputController}
                    initialValue={usersQuery}
                    placeholder={t('text.search_placeholder')}
                  />

                  <InputGroupText>
                    <i className="fa fa-search"></i>
                  </InputGroupText>

                </InputGroup>

                <Button
                  name="advanced-filter-button"
                  color="secondary"
                  onClick={this.handleAdvancedFilterToggle}
                >
                  {t('buttons.filter')}
                </Button>

                <SortDropdown
                  name="sort-input"
                  options={sortList}
                  onChange={this.onChangeUsersSort}
                  isDescending={usersOrderDirection === 'desc'}
                  value={usersOrder}
                />
              </div>
            </Row>

            <Row>
              <Col sm="12">
                <AsyncList
                  result={pagedUsersResult}
                  className={styles['groups-list']}
                  renderItem={this.renderUserItem}
                  noResultText={t('text.no_users_found')}
                  onRequestRetry={this.handleRequestRetry}
                />
                {pagedUsersResult.wasSuccessful() &&
                userRolesResult.wasSuccessful() ? (
                    <PaginationToolbar
                      visiblePageOptionCount={VISIBLE_USERS_PAGES}
                      pageNumber={pagedUsersResult.data.pageNumber}
                      totalPages={pagedUsersResult.data.totalPages}
                      totalResults={pagedUsersResult.data.totalResults}
                      resultsPerPage={resultsPerPage}
                      setPageNumber={setPageNumber}
                      setResultsPerPage={setResultsPerPage}
                    />
                  ) : null}
              </Col>
            </Row>
          </div>
        </div>
      </AppLayout>
    )
  }

  onToggleEditUserModal = () => {
    this.setState({
      isEditUserModalOpen: false,
    })
  }

  onToggleCreateUserModal = () => {
    this.setState({
      isCreateUserModalOpen: false,
    })
  }

  onToggleDeleteUserModal = () => {
    this.setState({
      isDeleteUserModalOpen: false,
    })
  }

  onToggleResendModal = () => {
    this.setState({
      isResendInvitationModalOpen: false,
    })
  }

  successfullyEditedCreatedDeletedUser = () => {
    this.props.refetchUsersData()
    this.props.refetchRolesData()
  }

  getSelectedUser = () => {
    const { selectedUser } = this.state
    return selectedUser
  }

  renderUserItem = (user) => {
    const { t, authorizedUser } = this.props
    const rowClassnames = classnames(
      styles['user-row-container'],
      styles['user-item']
    )

    const usersName = user.name !== null ? user.name : undefined
    const resend = user.status === 'FORCE_CHANGE_PASSWORD'
    const { existsInCognito } = user

    return (
      <Row key={user.username} className={rowClassnames}>
        <Col sm="2" className={styles['icon-column']}>
          <i className="fas fa-user"></i>
        </Col>

        <Col sm="6" className={styles['user-detail-column']}>
          <div>
            <span className={styles['user-name']}>
              <Row>
                {usersName !== undefined ? (
                  <React.Fragment>
                    <div>{usersName}</div>
                    &nbsp;
                    <div className={styles['user-role']}>{user.email}</div>
                  </React.Fragment>
                ) : (
                  <div>{user.email}</div>
                )}
              </Row>
            </span>

            <span className={styles['user-role']}>
              <Row>{user.role.name}</Row>
            </span>
          </div>
        </Col>

        <Col sm="4" className={styles['actions-column']}>
          {resend ? (
            <Button
              className={styles['resend-button']}
              name="resend-invite"
              size="sm"
              color="light"
              onClick={() => this.onClickResend(user)}
            >
              {t('buttons.resend-invite')}
            </Button>
          ) : null}

          {!existsInCognito ? (
            <span className="fas fa-exclamation-triangle" />
          ) : null}

          <Button
            className={styles.button}
            name="edit-user"
            size="sm"
            color="light"
            disabled={!existsInCognito}
            onClick={() => this.onClickEdit(user)}
          >
            {t('buttons.edit_user')}
          </Button>

          <Button
            className={styles.button}
            disabled={
              !authorizedUser.can(CAN_MANAGE_USERS) && !existsInCognito
            }
            name="delete-user"
            size="sm"
            color="light"
            onClick={() => this.onClickDelete(user)}
          >
            <span className="far fa-trash-alt" />
          </Button>
        </Col>
      </Row>
    )
  }

  onClickEdit = (user) => {
    this.setState({
      selectedUser: user,
      isEditUserModalOpen: true,
    })
  }

  onClickResend = (user) => {
    this.setState({
      selectedUser: user,
      isResendInvitationModalOpen: true,
    })
  }

  openCreateUserModal = (user) => {
    this.setState({
      selectedUser: user,
      isCreateUserModalOpen: true,
    })
  }

  onClickDelete = (user) => {
    this.setState({
      selectedUser: user,
      isDeleteUserModalOpen: true,
    })
  }

  getOrderNameForOrderType = (sortList, sortValue) => {
    const sortItem = sortList.filter((item) => {
      return item.value === sortValue
    })
    return sortItem.length < 1 ? '' : sortItem[0].text.toLowerCase()
  }

  onChangeUsersQuery = (event) => {
    const { location, dispatchUpdateUsersSearchQuery, setPageNumber } =
      this.props
    setPageNumber(1)

    dispatchUpdateUsersSearchQuery(location, event.target.value)
  }

  onClearFilters = (event) => {
    event.preventDefault()
    const { searchInputController } = this.state
    const { location, dispatchClearFilters } = this.props

    dispatchClearFilters(location)
    searchInputController.dispatchClearValueEvent()

    this.clearUserFilters()
  }

  clearUserFilters = () => {
    this.setState({
      userRoleFilters: [],
      userRoleFiltersShadow: [],
    })
  }

  handleClearAdvancedFilters = () => {
    this.setState({
      userRoleFiltersShadow: [],
    })
  }

  handleChangeUserRolesFilter = () => {
    const { userRoleFiltersShadow } = this.state
    this.setState({ userRoleFilters: [...userRoleFiltersShadow] })

    const { location, dispatchUpdateUsersFilter, setPageNumber } = this.props
    setPageNumber(1)

    dispatchUpdateUsersFilter(location, userRoleFiltersShadow)

    this.setState({ isAdvancedFilterModalOpen: false })
  }

  handleUserRoleFiltersChange = (newRoleFiltersState) => {
    this.setState({ userRoleFiltersShadow: [...newRoleFiltersState] })
  }

  handleAdvancedFilterToggle = () => {
    const { isAdvancedFilterModalOpen, userRoleFilters } = this.state
    this.setState({
      userRoleFiltersShadow: [...userRoleFilters],
      isAdvancedFilterModalOpen: !isAdvancedFilterModalOpen,
    })
  }

  onChangeUsersSort = (orderBy) => {
    const {
      location,
      dispatchUpdateUsersSort,
      usersOrder,
      usersOrderDirection,
    } = this.props

    let newOrderDirection = usersOrderDirection
    if (orderBy === usersOrder) {
      newOrderDirection = newOrderDirection === 'desc' ? 'asc' : 'desc'
    } else {
      newOrderDirection = 'asc' // default to ascending on change of orderBy
    }

    dispatchUpdateUsersSort(location, orderBy, newOrderDirection)
  }

  handleRequestRetry = () => {
    this.props.refetchUsersData()
  }

  static ROLES_QUERY = gql`
    query Roles {
      roles {
        id
        name
      }
    }
  `

  static USERS_QUERY = gql`
    query PagedUsers(
      $filterState: [String]
      $searchQuery: String
      $pageNumber: Int!
      $resultsPerPage: Int!
      $orderBy: String
      $orderDirection: String
    ) {
      pagedUsers(
        filterState: $filterState
        searchQuery: $searchQuery
        pageNumber: $pageNumber
        resultsPerPage: $resultsPerPage
        orderBy: $orderBy
        orderDirection: $orderDirection
      ) {
        users {
          name
          email
          username
          enabled
          status
          existsInCognito
          role {
            id
            name
          }
        }
        pagination {
          pageNumber
          totalPages
          totalResults
        }
      }
    }
  `
}

function createMapStateToProps() {
  const selectParsedSearchParams = createSelector(
    [(_state, { location }) => location.search],
    parseSearchParams
  )

  const selectUsersSearchQuery = (state, props) =>
    selectParsedSearchParams(state, props).q

  const selectUsersFilter = (state, props) => {
    const filterParam = selectParsedSearchParams(state, props).t

    return filterParam && filterParam.split('+')
  }

  const selectUsersOrder = (state, props) =>
    selectParsedSearchParams(state, props).ob

  const selectUsersOrderDirection = (state, props) =>
    selectParsedSearchParams(state, props).od

  const selectRolesResult = createSelectGraphQLResult('roles', {
    onError: analytics.logError,
    mapResult: parseGraphQLResult,
    nullObject: [],
  })

  const selectUsersResult = createSelectGraphQLResult('pagedUsers', {
    onError: analytics.logError,
    mapResult: parseGraphQLResult,
    nullObject: [],
  })

  const selectUsersQueryVariables = createSelector(
    [
      get('usersQuery'),
      get('filterState'),
      get('pageNumber'),
      get('resultsPerPage'),
      get('usersOrder'),
      get('usersOrderDirection'),
    ],
    (
      usersQuery,
      filterState,
      pageNumber,
      resultsPerPage,
      usersOrder,
      usersOrderDirection
    ) => {
      return {
        filterState: filterState,
        searchQuery: usersQuery,
        orderBy: usersOrder,
        orderDirection: usersOrderDirection,
        pageNumber,
        resultsPerPage,
      }
    }
  )

  return function mapStateToProps(state, ownProps) {
    return {
      selectUsersQueryVariables,
      usersQuery: selectUsersSearchQuery(state, ownProps),
      filterState: selectUsersFilter(state, ownProps),
      usersOrder: selectUsersOrder(state, ownProps),
      usersOrderDirection: selectUsersOrderDirection(state, ownProps),
      selectRolesResult,
      selectUsersResult,
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchUpdateUsersSearchQuery(location, query) {
      dispatch(
        push({
          search: mergeSearchParams(location.search, {
            q: query ? query : undefined,
          }),
          pathname: location.pathname,
        })
      )
    },

    dispatchUpdateUsersFilter(location, filters) {
      dispatch(
        push({
          search: mergeSearchParams(location.search, {
            t: filters.length === 0 ? undefined : filters.join('+'),
          }),
          pathname: location.pathname,
        })
      )
    },

    dispatchClearFilters(location) {
      dispatch(
        push({
          search: mergeSearchParams(location.search, {
            q: undefined,
            t: undefined,
            ob: 'name',
            od: 'asc',
          }),
          pathname: location.pathname,
        })
      )
    },

    dispatchUpdateUsersSort(location, orderBy, orderDirection) {
      dispatch(
        push({
          search: mergeSearchParams(location.search, {
            ob: orderBy,
            od: orderDirection,
          }),
          pathname: location.pathname,
        })
      )
    },
  }
}

export default compose(
  requiresLogin,
  withUser,
  connect(createMapStateToProps, mapDispatchToProps),
  withTranslation(['src/admin_path/users/index_page', 'common/text']),
  getWithPagination({
    pageNumber: SEED_USERS_PAGE_NUMBER,
    resultsPerPage: SEED_USERS_RESULTS_PER_PAGE,
  }),
  graphql(AdminUsersPage.ROLES_QUERY, {
    options: () => {
      return {
        fetchPolicy: 'network-only',
      }
    },
    props: ({ data, ownProps: { selectRolesResult } }) => {
      return {
        refetchRolesData: data.refetch,
        userRolesResult: selectRolesResult(data),
      }
    },
  }),
  graphql(AdminUsersPage.USERS_QUERY, {
    options: ({ selectUsersQueryVariables, ...rest }) => {
      return {
        variables: selectUsersQueryVariables(rest),
        fetchPolicy: 'network-only',
      }
    },
    props: ({ data, ownProps: { selectUsersResult } }) => {
      return {
        refetchUsersData: (...args) => data.refetch(...args),
        pagedUsersResult: selectUsersResult(data),
      }
    },
  })
)(AdminUsersPage)
