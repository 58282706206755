import React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Popover,
  PopoverHeader,
  PopoverBody,
} from 'reactstrap'
import { withTranslation } from 'react-i18next'
import classnames from 'classnames'
import gql from 'graphql-tag'
import { compose } from 'redux'
import { graphql } from '@apollo/client/react/hoc'
import { connect } from 'react-redux'
import { get } from 'lodash/fp/object'
import { createSelector } from 'reselect'
import ReactMarkdown from 'react-markdown'
import { Link } from 'react-router-dom'

import ReleaseNotesModal from '@@src/components/modals/release_notes_modal'
import routes from '@@src/routes'
import * as analytics from '@@src/analytics'
import { createSelectGraphQLResult } from '@@src/utils'
import { parseGraphQLResult } from '@@src/api/presenters'
import AsyncResult from '@@src/utils/async_result'
import requiresLogin from '@@src/components/security/requires_login'
import { UserSettingsContext } from '@@src/components/user_settings_provider'

import styles from './release_notes_button.css'

const APP_RELEASE_NOTES_STORAGE_KEY = 'inflownet/readReleaseNotes'
export const APP_RELEASE_NOTE_DISPLAYED_KEY = 'displayed'
const APP_RELEASE_NOTE_UNAVAILABLE_KEY = 'unavailable'
const APP_RELEASE_NOTE_UNREAD_KEY = 'unread'
const APP_RELEASE_NOTE_STATUSES = [
  APP_RELEASE_NOTE_DISPLAYED_KEY,
  APP_RELEASE_NOTE_UNAVAILABLE_KEY,
  APP_RELEASE_NOTE_UNREAD_KEY,
]

class ReleaseNotesButton extends React.PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    localStorage: PropTypes.object.isRequired,
    storageKey: PropTypes.string.isRequired,
    releaseNoteResult: PropTypes.instanceOf(AsyncResult).isRequired,
    storedCurrentReleaseNoteStatus:
      PropTypes.oneOf(APP_RELEASE_NOTE_STATUSES).isRequired,
    selectReadReleaseNotes: PropTypes.func.isRequired,
    version: PropTypes.string,
    language: PropTypes.string,
    className: PropTypes.string,
  }

  static defaultProps = {
    localStorage: global.localStorage,
    storageKey: APP_RELEASE_NOTES_STORAGE_KEY,
    releaseNoteResult: AsyncResult.notFound(),
    storedCurrentReleaseNoteStatus: APP_RELEASE_NOTE_UNAVAILABLE_KEY,
  }

  constructor(props) {
    super(props)

    this.state = {
      currentReleaseNoteStatus: this.props.storedCurrentReleaseNoteStatus,
      releaseNoteSummary: null,
      releaseNoteBody: null,
      releaseNotesPopoverIsOpen: false,
      releaseNotesModalIsOpen: false,
    }
  }

  async componentDidUpdate(prevProps) {
    const { releaseNoteResult } = this.props
    const { releaseNoteResult: prevReleaseNoteResult } = prevProps
    const { currentReleaseNoteStatus } = this.state

    if (releaseNoteResult.wasSuccessful() &&
      !prevReleaseNoteResult.wasSuccessful() &&
        currentReleaseNoteStatus !== APP_RELEASE_NOTE_UNAVAILABLE_KEY) {
      this.getReleaseNote()
    }
  }

  getReleaseNote = async () => {
    const { releaseNoteResult } = this.props
    const { currentReleaseNoteStatus } = this.state

    if (releaseNoteResult.wasSuccessful()) {
      try {
        const { summarySignedUrl, bodySignedUrl } = releaseNoteResult.data
        const summaryRequest = fetch(summarySignedUrl, { method: 'GET' })
        const bodyRequest = fetch(bodySignedUrl, { method: 'GET' })
        const [
          releaseSummary,
          releaseBody,
        ] = await Promise.all([summaryRequest, bodyRequest])

        if (releaseSummary.ok && releaseBody.ok) {
          this.setState({
            releaseNoteSummary: await releaseSummary.text(),
            releaseNoteBody: await releaseBody.text(),
            releaseNotesPopoverIsOpen:
              currentReleaseNoteStatus === APP_RELEASE_NOTE_UNREAD_KEY,
            releaseNotesModalIsOpen: false,
          })
        } else {
          this.setState({
            releaseNoteSummary: null,
            releaseNoteBody: null,
            releaseNotesPopoverIsOpen: false,
            releaseNotesModalIsOpen: false,
          })
        }
      } catch (err) {
        analytics.logError(err)
        this.setState({
          releaseNoteSummary: null,
          releaseNoteBody: null,
          releaseNotesPopoverIsOpen: false,
          releaseNotesModalIsOpen: false,
        })
      }
    }
  }

  setDisplayedCurrentReleaseNote() {
    this.setCurrentReleaseNoteStatus(APP_RELEASE_NOTE_DISPLAYED_KEY)
  }

  setCurrentReleaseNoteStatus = status => {
    const {
      localStorage,
      storageKey,
      selectReadReleaseNotes,
    } = this.props

    try {
      const readReleaseNotes = selectReadReleaseNotes(this.props)

      localStorage.setItem(storageKey, JSON.stringify({
        ...readReleaseNotes,
        [process.env.APP_VERSION]: status,
      }))

      this.setState({
        currentReleaseNoteStatus: status,
      })
    } catch (err) {
      analytics.logError('unable to set release note status', err)
    }
  }

  handleTogglePopover = () => {
    const { releaseNotesPopoverIsOpen } = this.state
    const newState = {
      releaseNotesModalIsOpen: false,
    }

    if (releaseNotesPopoverIsOpen) {
      this.setDisplayedCurrentReleaseNote()
      newState.releaseNotesPopoverIsOpen = false
    } else {
      newState.releaseNotesPopoverIsOpen = true
    }

    this.setState(newState)
  }

  render() {
    const { t, className } = this.props
    const {
      releaseNotesModalIsOpen,
      releaseNotesPopoverIsOpen,
      releaseNoteSummary,
      releaseNoteBody,
      currentReleaseNoteStatus,
    } = this.state

    return (
      <React.Fragment>
        <Button
          name="release-notes-button"
          size="sm"
          type="button"
          color="link"
          id="release-notes-button"
          className={classnames({
            'text-warning': currentReleaseNoteStatus ===
              APP_RELEASE_NOTE_UNREAD_KEY && releaseNoteSummary,
            [styles['release-notes-nav-button']]:
              currentReleaseNoteStatus !== APP_RELEASE_NOTE_UNREAD_KEY
              || !releaseNoteSummary,
          }, className)}>
          <span className="fas fa-gift" />
        </Button>
        <ReleaseNotesModal
          handleClose={this.handleToggleModal}
          isOpen={releaseNotesModalIsOpen}
          mdReleaseNotes={releaseNoteBody} />
        <Popover
          toggle={this.handleTogglePopover}
          trigger="legacy"
          isOpen={releaseNotesPopoverIsOpen}
          placement="bottom"
          target="release-notes-button">
          <PopoverHeader>
            {t('title.updates', {
              version: process.env.APP_VERSION,
            })}
          </PopoverHeader>
          <PopoverBody>
            <div className={classnames(
              'mb-2',
              styles['release-notes-summary'],
            )}>
              {
                releaseNoteSummary ? (
                  <ReactMarkdown>
                    {releaseNoteSummary}
                  </ReactMarkdown>
                ) : (
                  <Link to={routes.previousReleaseNotesPath()}>
                    {t('text.no_release_notes')}
                  </Link>
                )
              }
            </div>
            <div className="d-flex justify-content-end">
              {
                releaseNoteSummary ? (
                  <Button
                    name="show-more-release-notes-button"
                    className="pl-0 pt-0"
                    size="sm"
                    color="link"
                    onClick={this.handleToggleModal}>
                    {t('button.show_more')}
                  </Button>
                ) : (
                  null
                )
              }
            </div>
          </PopoverBody>
        </Popover>
      </React.Fragment>
    )
  }

  handleToggleModal = () => {
    const { releaseNotesModalIsOpen } = this.state
    const newState = {
      releaseNotesPopoverIsOpen: false,
    }

    if (releaseNotesModalIsOpen) {
      this.setDisplayedCurrentReleaseNote()
      newState.releaseNotesModalIsOpen = false
    } else {
      newState.releaseNotesModalIsOpen = true
    }

    this.setState(newState)
  }

  static RELEASE_NOTE_QUERY = gql`
    query ReleaseNote($version: String!, $language: Language! $skip: Boolean!) {
      releaseNote (version: $version, language: $language) @skip(if: $skip) {
        summarySignedUrl
        bodySignedUrl
      }
    }
  `
}

function createMapStateToProps() {
  const selectReleaseNoteResult = createSelectGraphQLResult('releaseNote', {
    onError: analytics.logError,
    mapResult: parseGraphQLResult,
    nullObject: [],
  })

  const selectReadReleaseNotes = createSelector([
    get('localStorage'),
    get('storageKey'),
  ], (
    localStorage = global.localStorage,
    storageKey = APP_RELEASE_NOTES_STORAGE_KEY,
  ) => {
    try {
      const storedReadNotes = localStorage.getItem(storageKey)

      if (storedReadNotes) {
        return JSON.parse(storedReadNotes)
      }

      return null
    } catch (err) {
      analytics.logError('unable to parse read release notes', err)
      return null
    }
  })

  const selectCurrentReleaseNoteStatus = createSelector(
    [selectReadReleaseNotes],
    readReleaseNotes => {
      try {
        if (!readReleaseNotes) {
          return APP_RELEASE_NOTE_UNREAD_KEY
        } else if (!process.env.APP_VERSION) {
          analytics.logError('undefined app version on checking release notes')

          return APP_RELEASE_NOTE_UNAVAILABLE_KEY
        }

        const releaseNoteStatus = readReleaseNotes[process.env.APP_VERSION]

        return releaseNoteStatus ?
          releaseNoteStatus : APP_RELEASE_NOTE_UNREAD_KEY
      } catch (err) {
        analytics.logError('unable to parse accepted release notes', err)

        return false
      }
    }
  )

  return function mapStateToProps(state, ownProps) {
    return {
      selectReleaseNoteResult,
      selectReadReleaseNotes,
      storedCurrentReleaseNoteStatus: selectCurrentReleaseNoteStatus(ownProps),
    }
  }
}

const ReleaseNotesButtonWithContainers = compose(
  withTranslation([
    'src/components/buttons/release_notes_button',
  ]),
  connect(createMapStateToProps),
  graphql(ReleaseNotesButton.RELEASE_NOTE_QUERY, {
    options: props => {
      const { version, language = 'en', storedCurrentReleaseNoteStatus } = props

      return {
        fetchPolicy: 'network-only',
        variables: {
          language,
          version,
          skip: storedCurrentReleaseNoteStatus ===
            APP_RELEASE_NOTE_UNAVAILABLE_KEY,
        },
      }
    },
    props: ({ data, ownProps }) => {
      const { selectReleaseNoteResult } = ownProps

      return {
        releaseNoteResult: selectReleaseNoteResult(data),
      }
    },
  }),
)(ReleaseNotesButton)

function ReleaseNotesButtonWithUserSettings(props) {
  return (
    <UserSettingsContext.Consumer>
      {({ language }) => (
        <ReleaseNotesButtonWithContainers language={language} {...props} />
      )}
    </UserSettingsContext.Consumer>
  )
}

export default requiresLogin(ReleaseNotesButtonWithUserSettings)
