import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { FormGroup, Label, Input } from 'reactstrap'
import { noop } from 'lodash/fp/util'

import { allValidDedupedAssetTypes, validAssetInstallationTypes } from '@@src/utils'

const filterPropTypes = {
  t: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
  handleChange: PropTypes.func,
}

export function defaultNetworkAssetTypeFilters(filters = []) {
  return allValidDedupedAssetTypes().reduce((carry, type) => {
    carry[type] = filters.includes(type)
    return carry
  }, {})
}

function NetworkAssetTypeFilterFormGroup(props) {
  const { filters, type, handleChange, t } = props

  return (
    <FormGroup check={true}>
      <Label check={true}>
        <Input
          checked={filters[type]}
          onChange={handleChange}
          type="checkbox"
          name={type} />{' '}
        {t(`common/text:text.${type}`)}
      </Label>
    </FormGroup>
  )
}

NetworkAssetTypeFilterFormGroup.propTypes = filterPropTypes

function NetworkAssetTypeFilters(props) {
  const { t, filters, handleChange } = props

  let assetTypeFilters = allValidDedupedAssetTypes()
  const assetInstallationTypes = validAssetInstallationTypes()
  assetTypeFilters = assetTypeFilters.filter((el) => !assetInstallationTypes.includes(el))

  const assetTypeFiltersMid = Math.ceil(assetTypeFilters.length / 2)

  return (
    <FormGroup tag="fieldset">
      <legend className="h6">{t('headers.types')}</legend>
      <div className="d-flex flex-column">
        <div className="d-flex flex-row">
          <div className="mr-5">
            {
              assetTypeFilters.slice(0, assetTypeFiltersMid)
                .map((type) => (
                  <NetworkAssetTypeFilterFormGroup
                    t={t}
                    key={type}
                    filters={filters}
                    handleChange={handleChange}
                    type={type} />
                ))
            }
          </div>
          <div>
            {
              assetTypeFilters.slice(assetTypeFiltersMid)
                .map((type) => (
                  <NetworkAssetTypeFilterFormGroup
                    t={t}
                    key={type}
                    filters={filters}
                    handleChange={handleChange}
                    type={type} />
                ))
            }
          </div>
        </div>
        <legend className="h6 mt-3">{t('headers.installations')}</legend>
        <div className="d-flex flex-column">
          {
            assetInstallationTypes.map(type => (
              <NetworkAssetTypeFilterFormGroup
                t={t}
                key={type}
                filters={filters}
                handleChange={handleChange}
                type={type} />
            ))
          }
        </div>
      </div>
    </FormGroup>
  )
}

NetworkAssetTypeFilters.propTypes = filterPropTypes
NetworkAssetTypeFilters.defaultProps = {
  filters: {},
  handleChange: noop,
}

export default withTranslation([
  'src/components/modals/network_asset_type_filters',
  'common/text',
])(memo(NetworkAssetTypeFilters))
