import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { min, max } from 'lodash/fp/math'
import { get } from 'lodash/fp/object'
import { flow } from 'lodash/fp/util'
import { createSelector } from 'reselect'
import { withTranslation } from 'react-i18next'
import { dropWhile, takeWhile } from 'lodash/fp/array'

import GraphItem from '@@src/components/graphs/graph_item'
import GraphContext, { GraphConfig }
from '@@src/components/graphs/graph_context'
import { DEFAULT_TIMEZONE } from '@@src/utils'
import { DragEventContext }
from '@@src/components/graphs/drag_layer/drag_event_layer'
import { MouseEventContext } from '@@src/components/graphs/mouse_event_layer'
import { Popup, PopupAnchor, RIGHT } from '@@src/components/popup'
import { closestPointWithX, closestGraphItemWithY } from '@@src/utils/graphs'
import { LegendItemColorContext } from
'@@src/components/colors/legend_item_color_provider'
import DragAreaSummaryMetrics from
'@@src/components/graphs/drag_area_summary_layer/drag_area_summary_metrics'
import DragLayerMaxValueIcon from '../../icon-components/drag_layer_max_value_icon'
import DragLayerMinValueIcon from '../../icon-components/drag_layer_min_value_icon'

import styles from './index.css'

const noop = () => { }
const TIME_FORMAT = 'MMM DD YYYY, HH:mm:ss.SSS'
const defaultSelectFormatX = createSelector(
  [timezone => timezone],
  timezone => time => moment(time).tz(timezone).format(TIME_FORMAT)
)

class DragAreaSummaryLayer extends React.PureComponent {
  static propTypes = {
    onAppear: PropTypes.func.isRequired,
    setSelection: PropTypes.func.isRequired,
    setRawDataModalOpen: PropTypes.func.isRequired,
    timezone: PropTypes.string.isRequired,
    dataUnits: PropTypes.any.isRequired,
    onDismiss: PropTypes.func.isRequired,
    graphItems: PropTypes.arrayOf(PropTypes.instanceOf(GraphItem)).isRequired,
    graphConfig: PropTypes.instanceOf(GraphConfig).isRequired,
    measureBounds: PropTypes.array,
    selectFormatX: PropTypes.func.isRequired,
    setFocusedLegendItem: PropTypes.func.isRequired,
    onCopyBoundsIntoLink: PropTypes.func.isRequired,
    getLegendItemColor: PropTypes.func.isRequired,
    networkAssetGraphItems: PropTypes.array.isRequired,
    setTransientLocalisationModalOpen: PropTypes.func.isRequired,
  }

  static defaultProps = {
    timezone: DEFAULT_TIMEZONE,
    onAppear: noop,
    dataUnits: 'mH2O',
    onDismiss: noop,
    setSelection: noop,
    selectFormatX: defaultSelectFormatX,
    setRawDataModalOpen: noop,
    setFocusedLegendItem: noop,
    onCopyBoundsIntoLink: noop,
    networkAssetGraphItems: [],
    setTransientLocalisationModalOpen: noop,
  }

  state = this.initialState()

  render() {
    const {
      t, graphItems, DragEventChannel, MouseEventChannel, timezone, dataUnits,
      selectFormatX, graphConfig, getLegendItemColor, onCopyBoundsIntoLink,
      setRawDataModalOpen, setTransientLocalisationModalOpen,
    } = this.props

    if (graphItems.length === 0) {
      // nothing to do if no data
      return null
    }

    const { closestLegendItemId } = this.state
    const yRange = graphConfig.yScale.range()
    const formatX = selectFormatX(timezone)

    const metrics = this.selectRulerMetrics(this.props, this.state)
    const finalBounds = this.selectFinalBounds(this.props, this.state)

    return (
      <g name="drag-area-summary-layer">
        <DragEventChannel
          onDrag={this.onDrag}
          onDragEnd={this.onDragEnd}
          onDragStart={this.onDragStart} />

        <MouseEventChannel
          onClick={this.onClickOutsidePopup}
          onMouseMove={this.onMouseMove} />

        {
          finalBounds ? (
            <g name="drag-area-summary-metrics-rendering">
              <rect
                className={styles['drag-area-region']}
                x={Math.min(...finalBounds)}
                y={yRange[1]}
                width={Math.abs(finalBounds[1] - finalBounds[0])}
                height={graphConfig.plotAreaHeight} />

              <line
                x1={Math.min(...finalBounds)}
                y1={yRange[0]}
                x2={Math.min(...finalBounds)}
                y2={yRange[1]}
                className={styles['drag-area-boundary']} />

              <line
                x1={Math.max(...finalBounds)}
                y1={yRange[0]}
                x2={Math.max(...finalBounds)}
                y2={yRange[1]}
                className={styles['drag-area-boundary']} />

              {metrics ? metrics.summaries.map(({ graphItem, summary }) => {
                const maxPoint = {
                  x: graphConfig.xScale(summary.maxPoint.time),
                  y: graphConfig.yScale(summary.maxPoint.value),
                }
                const minPoint = {
                  x: graphConfig.xScale(summary.minPoint.time),
                  y: graphConfig.yScale(summary.minPoint.value),
                }
                const legendItemColor = getLegendItemColor(graphItem.legendItem)

                return (
                  <g key={graphItem.id}>
                    <DragLayerMinValueIcon
                      x={minPoint.x}
                      y={minPoint.y}
                      fill={legendItemColor}
                      stroke={legendItemColor} />

                    <text
                      x={minPoint.x - 7}
                      y={minPoint.y + 16}
                      fontSize={7}
                      fill="white"
                      fontWeight={100}>
                      {t('labels.min')}
                    </text>

                    <DragLayerMaxValueIcon
                      x={maxPoint.x}
                      y={maxPoint.y}
                      fill={legendItemColor}
                      stroke={legendItemColor} />

                    <text
                      x={maxPoint.x - 8}
                      y={maxPoint.y - 8}
                      fontSize={7}
                      fill="white"
                      fontWeight={100}>
                      {t('labels.max')}
                    </text>
                  </g>
                )
              }) : null}
            </g>
          ) : null
        }

        {
          metrics ? (
            <PopupAnchor
              r="0"
              cx={metrics.placement.x}
              cy={metrics.placement.y}
              elementType="circle">
              <Popup
                isOpen={true}
                location={RIGHT}
                className={styles['ruler-popup']}
                hideArrow={true}>
                <DragAreaSummaryMetrics
                  t={t}
                  getLegendItemColor={getLegendItemColor}
                  metrics={metrics}
                  handleClickOutsidePopup={this.onClickOutsidePopup}
                  closestLegendItemId={closestLegendItemId}
                  dataUnits={dataUnits}
                  formatX={formatX}
                  onCopyBoundsIntoLink={onCopyBoundsIntoLink}
                  setRawDataModalOpen={setRawDataModalOpen}
                  setTransientLocalisationModalOpen={
                    setTransientLocalisationModalOpen
                  } />
              </Popup>
            </PopupAnchor>
          ) : null
        }
      </g>
    )
  }

  onClickOutsidePopup = () => {
    this.setState({ finalBounds: null, boundsOfInterest: null })
    this.props.onDismiss()
  }

  onDrag = dragEvent => {
    const { graphConfig, graphItems, setFocusedLegendItem } = this.props

    const mouseValueX = Number(graphConfig.xScale.invert(dragEvent.mouseX))
    const mouseValueY = Number(graphConfig.yScale.invert(dragEvent.mouseY))

    const graphItemsWithPoint = graphItems.map(graphItem => ({
      graphItem,
      point: closestPointWithX(graphItem, get('time'), mouseValueX),
    }))

    const closestItem =
      closestGraphItemWithY(graphItemsWithPoint, get('mean'), mouseValueY)

    this.recomputeStateForEvent(dragEvent, {
      closestLegendItemId: closestItem ? closestItem.legendItem.id : '',
    })

    setFocusedLegendItem(closestItem ? closestItem.legendItem : null)
  }

  onDragStart = dragEvent => {
    this.setState({ boundsOfInterest: dragEvent.graphBoundsX })
    this.props.onAppear()
  }

  onDragEnd = dragEvent => {
    this.recomputeStateForEvent(dragEvent, {
      finalBounds: dragEvent.localBoundsX,
    })

    const bounds = dragEvent.graphBoundsX
    if (bounds) {
      const { networkAssetGraphItems, setSelection } = this.props
      const startDate = min(bounds)
      const endDate = max(bounds)

      const itemsInBounds = networkAssetGraphItems
        .filter(({ dataSegments = [] }) => {
          return dataSegments.some(({ data = [] }) => {
            return data.some(({ time }) => time >= startDate && time <= endDate)
          })
        })

      setSelection({
        xRange: dragEvent.localBoundsX,
        endPointX: dragEvent.mouseX,
        startDate,
        endDate,
        networkAssetGraphItems: itemsInBounds,
      })
    } else {
      this.resetSelection()
    }
  }

  resetSelection = () => {
    const { setSelection } = this.props
    setSelection(null)
  }

  onMouseMove = (event, { mouseX, mouseY }) => {
    const { graphConfig, graphItems, setFocusedLegendItem } = this.props

    const mouseValueX = Number(graphConfig.xScale.invert(mouseX))
    const mouseValueY = Number(graphConfig.yScale.invert(mouseY))

    const graphItemsWithPoint = graphItems.map(graphItem => ({
      graphItem,
      point: closestPointWithX(graphItem, get('time'), mouseValueX),
    }))

    const closestItem =
      closestGraphItemWithY(graphItemsWithPoint, get('mean'), mouseValueY)

    this.setState({
      closestLegendItemId: closestItem ? closestItem.legendItem.id : '',
    })

    setFocusedLegendItem(closestItem ? closestItem.legendItem : null)
  }

  recomputeStateForEvent(dragEvent, overrides = {}) {
    const { mouseX, mouseY } = dragEvent

    this.setState({
      mouseX,
      mouseY,
      finalBounds: null,
      boundsOfInterest: dragEvent.graphBoundsX,
      ...overrides,
    })
  }

  initialState() {
    return {
      mouseX: 0,
      mouseY: 0,
      finalBounds: null,
      boundsOfInterest: null,
      closestLegendItemId: '',
    }
  }

  componentDidUpdate(prevProps) {
    const props = this.props

    if (props.graphItems !== prevProps.graphItems) {
      this.setState(this.initialState())
    }
  }

  selectFinalBounds = createSelector(
    get('measureBounds'),
    (_props, state) => state.finalBounds,
    get('graphConfig'),
    (measureBounds, finalBounds, config) => measureBounds ?
      measureBounds.map(config.xScale) : finalBounds
  )

  selectRulerMetrics = createSelector(
    [
      get('graphItems'),
      get('measureBounds'),
      get('graphConfig'),
      (_props, state) => state.boundsOfInterest,
      (_props, state) => state.mouseX,
      (_props, state) => state.mouseY,
    ],
    (graphItems, measureBounds, config, boundsOfInterest, mouseX, mouseY) => {
      const bounds = measureBounds || boundsOfInterest
      if (!bounds) {
        return null
      }

      const summaries = graphItems.map(item => {
        let totalPoints = 0
        const itemSummary =
          item.subsetData.mixedRawDataSegments.reduce((summary, chunk) => {
            const chunkOfInterest = flow(
              dropWhile(({ time }) => time < bounds[0]),
              takeWhile(({ time }) => time <= bounds[1])
            )(chunk.data)
            totalPoints += chunkOfInterest.length

            return chunkOfInterest.reduce((acc, entry) => {
              return {
                mean: acc.mean + entry.mean,
                minPoint: acc.minPoint.value > entry.min ? {
                  value: entry.min,
                  time: entry.time,
                } : acc.minPoint,
                maxPoint: acc.maxPoint.value < entry.max ? {
                  value: entry.max,
                  time: entry.time,
                } : acc.maxPoint,
              }
            }, summary)
          }, {
            mean: 0,
            maxPoint: { value: -Infinity, time: 0 },
            minPoint: { value: Infinity, time: 0 },
          })

        return {
          graphItem: item,
          summary: {
            ...itemSummary,
            mean: itemSummary.mean / totalPoints,
          },
        }
      }).filter((summaryItem) => {
        return summaryItem.summary.maxPoint.value !== -Infinity &&
          summaryItem.summary.minPoint.value !== Infinity
      })

      return {
        summaries,
        timeRange: bounds,
        placement: measureBounds ? {
          x: config.xScale(bounds[1]),
          y: (config.yScale.range()[0] + config.yScale.range()[1]) / 2,
        } : {
          x: mouseX,
          y: mouseY,
        },
      }
    }
  )
}

function DragAreaSummaryLayerContainer(props) {
  return (
    <GraphContext.Consumer>
      {config => (
        <MouseEventContext.Consumer>
          {({ Channel: MouseEventChannel }) => (
            <DragEventContext.Consumer>
              {({ Channel }) => (
                <LegendItemColorContext.Consumer>
                  {({ getLegendItemColor }) => (
                    <DragAreaSummaryLayer
                      getLegendItemColor={getLegendItemColor}
                      graphConfig={config}
                      DragEventChannel={Channel}
                      MouseEventChannel={MouseEventChannel}
                      {...props} />
                  )}
                </LegendItemColorContext.Consumer>
              )}
            </DragEventContext.Consumer>
          )}
        </MouseEventContext.Consumer>
      )}
    </GraphContext.Consumer>
  )
}

export default withTranslation([
  'src/components/graphs/drag_area_summary_layer/index',
  'src/components/graphs/modals/request_raw_data_modal',
  'common/text',
])(DragAreaSummaryLayerContainer)
