import { useMemo } from 'react'
import gql from 'graphql-tag'
import { createSelectGraphQLResult } from '../../utils/index'
import { parseGraphQLResult } from '../../api/presenters/index'
import { useQuery } from '@apollo/client'

const MOST_SEVERE_EVENTS_COUNT = 10

export function useGetHighSeverityPressureEvents() {
  const EVENTS_SUMMARY_QUERY = gql`
    query EventsBySeverity($count: Int!) {
      eventsBySeverity(count: $count) {
        eventId
        eventSeverity
        severityLevel
        startTime
        endTime
        assetName
        assetFriendlyName
        assetId
        assetType
        logicalChannel
      }
    }
  `
  const getSeverityColor = severityLevel => {
    switch(severityLevel) {
      case 'low':
        return 'green'
      case 'medium':
        return 'orange'
      default:
        return 'red'
    }
  }

  const highSeverityPressureQueryResult = useQuery(EVENTS_SUMMARY_QUERY, {
    variables: {
      count: MOST_SEVERE_EVENTS_COUNT,
    },
    fetchPolicy: 'network-only',
  })

  const eventsResult = useMemo(() =>
    createSelectGraphQLResult('data', {
      mapResult: (values) => parseGraphQLResult(values.eventsBySeverity),
      nullObject: [],
    })(highSeverityPressureQueryResult)
  , [highSeverityPressureQueryResult])

  return {
    eventsResult,
    getSeverityColor,
  }
}
