import React from 'react'
import PropTypes from 'prop-types'
import { noop } from 'lodash/fp/util'
import { min, max } from 'lodash/fp/math'

import GraphContext, { GraphConfig }
from '@@src/components/graphs/graph_context'
import { DragEventContext }
from '@@src/components/graphs/drag_layer/drag_event_layer'
import { STYLING_PROP_TYPES } from '@@src/utils'

class DragZoomLayer extends React.PureComponent {
  static defaultProps = {
    onZoom: noop,
  }

  static propTypes = Object.assign({}, {
    onZoom: PropTypes.func.isRequired,
    graphConfig: PropTypes.instanceOf(GraphConfig).isRequired,
    children: PropTypes.node,
  }, STYLING_PROP_TYPES)

  render() {
    const { children, DragEventChannel } = this.props

    return (
      <DragEventChannel
        onDrag={this.onDrag}
        onDragEnd={this.onDragEnd}>
        {children}
      </DragEventChannel>
    )
  }

  onDragEnd = (dragEvent) => {
    const { graphBoundsX, graphBoundsY } = dragEvent

    if (graphBoundsX || graphBoundsY) {
      this.props.onZoom({
        dragX: !graphBoundsX ? null : {
          start: min(graphBoundsX),
          end: max(graphBoundsX),
        },
        dragY: !graphBoundsY ? null : {
          start: min(graphBoundsY),
          end: max(graphBoundsY),
        },
      })
    }
  }
}

export default function DragZoomCaptureLayerContainer(props) {
  return (
    <GraphContext.Consumer>
      {config => (
        <DragEventContext.Consumer>
          {({ Channel }) => (
            <DragZoomLayer
              graphConfig={config}
              DragEventChannel={Channel}
              {...props}/>
          )}
        </DragEventContext.Consumer>
      )}
    </GraphContext.Consumer>
  )
}
