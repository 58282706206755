import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { Button } from 'reactstrap'
import { withTranslation } from 'react-i18next'

import routes from '@@src/routes'
import styles from './index_page.css'
import AppLayout from '@@src/components/app_layout'

class ErrorPage extends React.PureComponent {
  render() {
    const { t } = this.props

    return (
      <AppLayout
        title={t('headings.page_title')}
        showFooter={false}
        backgroundClassname={styles.background}>
        <div className={styles.container} >
          <div className={styles.card}>
            <h2 className={styles['error-title']}>
              {t('text.error_title')}
            </h2>
            <div className={styles['error-message']}>
              {t('text.error_message')}
            </div>
            <div className={styles['error-retry-buttons']}>
              <Button
                name="refresh-button"
                onClick={this.refreshApp}
                type="submit"
                color="primary"
                className={styles['refresh-button']}>
                {t('buttons.refresh')}
              </Button>
            </div>
          </div>
        </div>
      </AppLayout>
    )
  }

  refreshApp = () => {
    const { retryRequest, errorResolved, location } = this.props
    errorResolved()
    retryRequest(location)
  }

  redirectToSystemStatus = () => {
    const { redirectToStatusPage, errorResolved } = this.props
    errorResolved()
    redirectToStatusPage()
  }

}

function mapDispatchToProps(dispatch) {
  return {
    retryRequest(location) {
      dispatch(push(location))
    },

    redirectToStatusPage() {
      dispatch(push(routes.homePath()))
    },
  }
}

export default compose(
  withTranslation(['src/error_path/index_page']),
  connect(null, mapDispatchToProps)
)(ErrorPage)
