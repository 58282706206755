import mapErrorToTranslation from './map_error_to_translation'

export default class AppError {
  constructor(originalError, customI18n) {
    const { key, options } = customI18n ?
      customI18n : mapErrorToTranslation(originalError)

    this.i18nKey = key
    this.i18nOptions = options
    this.original = originalError
  }

  translateWith(t) {
    return t(this.i18nKey, this.i18nOptions)
  }

  isUnknown() {
    return this.i18nKey === 'common/errors:unknown'
  }

  isAggregate() {
    return this.i18nKey === 'common/errors:aggregate'
  }

  originalMessageError() {
    if (this.original && this.original.message) {
      return this.original.message
    }

    return ''
  }

  static from(someError) {
    switch (true) {
      case (someError instanceof AppError):
        return someError

      case (someError instanceof Array):
        return new AppError(someError.map(AppError.from))

      default:
        return new AppError(someError)
    }
  }
}

window.AppError = AppError
