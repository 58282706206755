import React from 'react'

import styles from './labels.css'

import * as units from '@@src/utils/unit_constants'

export function svgLabelFor(unit) {
  switch (unit) {
    case units.MH2O:
      return <MH2O />

    case units.KPA:
      return <KPA />

    case units.PSI:
      return <PSI />

    case units.BAR:
      return <BAR />

    default:
      console.warn(`No supported labels for the unit "${unit}"`) // eslint-disable-line max-len, no-console
      return null
  }
}

export function htmlLabelFor(unit) {
  switch (unit) {
    case units.MH2O:
      return <MH2O.html />

    case units.KPA:
      return <KPA.html />

    case units.PSI:
      return <PSI.html />

    case units.BAR:
      return <BAR.html />

    default:
      console.warn(`No supported labels for the unit "${unit}"`) // eslint-disable-line max-len, no-console
      return null
  }
}

export function KPA(props) {
  return (
    <text {...props}>
      <tspan>kPa</tspan>
    </text>
  )
}

KPA.html = function KPAHtml(props) {
  return <span {...props}>kPa</span>
}

export function PSI(props) {
  return (
    <text {...props}>
      <tspan>psi</tspan>
    </text>
  )
}

PSI.html = function PSIHtml(props) {
  return <span {...props}>psi</span>
}

export function BAR(props) {
  return (
    <text {...props}>
      <tspan>bar</tspan>
    </text>
  )
}

BAR.html = function BARHtml(props) {
  return <span {...props}>bar</span>
}

export function MH2O(props) {
  return (
    <text {...props}>
      <tspan>mH</tspan>
      <tspan className={styles.subscript}>2</tspan>
      <tspan>O</tspan>
    </text>
  )
}

MH2O.html = function MH2OHtml(props) {
  return (
    <span {...props}>
      mH<sub>2</sub>O
    </span>
  )
}
