import SummaryPanel from '@@src/components/summary/summary_panel'
import routes from '@@src/routes'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import { withWindowSizes } from '../../../components/window_size_provider'
import { useGetNetworkAssetSummaries } from '../../../hooks/summaries/useGetNetworkAssetSummaries'
import { SummaryInfo } from '../summary_info/summary_info'
import { SummaryGraph } from '../summary_info/summary_graph'
import { SummaryInfoPlaceholder } from '../summary_info/summary_info_placeholder'
const NetworkAssetSummary = ({ t }) => {
  const { networkStatistics } = useGetNetworkAssetSummaries()
  const networkAssetsPath = routes.managementNetworkAssetsPath()

  const renderSuccessView = ({ data }) => {
    const {
      fullyInstalledCount,
      partialInstalledCount,
      notInstalledCount,
      totalCount,
    } = data

    const summaryInfosGroup = [
      {
        name: 'summary-info-fully-installed-assets',
        value: fullyInstalledCount,
        color: 'tangerine',
        text: t('graphs.devices_on_all_channels'),
      },
      {
        name: 'summary-info-partialy-istalled-assets',
        value: partialInstalledCount,
        color: 'blue',
        text: t('graphs.devices_on_some_channals'),
      },
      {
        name: 'summary-info-non-installed-assets',
        value: notInstalledCount,
        color: 'gray',
        text: t('graphs.no_devices_installed'),
      },
    ]

    const graphData = [
      totalCount,
      fullyInstalledCount,
      partialInstalledCount,
      notInstalledCount,
    ]
    const colors = ['#f94f29', '#5b9bd5', '#7f899a']

    return (
      <div className="d-flex flex-row col justify-content-center align-items-center">
        <SummaryGraph
          graphData={graphData}
          colors={colors}
          title={t('text.with_devices')}
          subtitle={t('graphs.of_total', { amount: totalCount })}
          className="networkAssetsStatistics"
        />
        <SummaryInfo summaryInfos={summaryInfosGroup} />
      </div>
    )
  }

  const renderPendingResult = () => {
    return <SummaryInfoPlaceholder />
  }

  return (
    <SummaryPanel
      title={t('title.network_assets')}
      renderSuccessResult={renderSuccessView}
      renderPendingResult={renderPendingResult}
      result={networkStatistics}
      showAllLink={`${networkAssetsPath}`}
      showAllBtnText={t('button.show_all_assets')}
    />
  )
}

export default compose(
  withTranslation(['src/components/summary/network_assets_summary']),
  withWindowSizes
)(NetworkAssetSummary)
