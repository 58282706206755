import { useCallback, useEffect, useRef, useState } from 'react'
import { getDevicesForMapCall } from '../../../../api/devices.service'
import { mapFlattenedFiltersToQueryObject } from '../utils/filters'
import GeoBounds from '../../../../../utils/graphs/geo_bounds'
import { DeviceForMapModel } from '../../network_assets/models/device_for_map.modal'
import { useApolloClient } from '@apollo/client'

const useGetMapDevices = (filters) => {
  const client = useApolloClient()
  const abortController = useRef(null)
  const everywherePosition = useRef(null)
  const [results, setResults] = useState({ error: null, devices: [], bounds: null, isLoading: true })

  const fetchNewDevicesForMap = useCallback(() => {
    if (abortController.current) {
      abortController.current.abort()
    }

    if (!results.isLoading) {
      setResults({ ...results, isLoading: true })
    }

    abortController.current = new AbortController()

    getDevicesForMapCall({
      client, abortController: abortController.current,
      filters: {
        search: filters.search,
        ...mapFlattenedFiltersToQueryObject(filters.filters),
      },
    })
      .then(({ devices, meta }) => {
        meta.bounds && (everywherePosition.current = GeoBounds.fromJSON(meta.bounds))
        setResults({
          devices: devices.map(device => new DeviceForMapModel(device)),
          error: null,
          isLoading: false,
        })
      })
      .catch(error => {
        setResults({ error, devices: [], isLoading: false })
      })
      .finally(() => {
        abortController.current = null
      })

    return () => {
      if (abortController.current) {
        abortController.current.abort()
        abortController.current = null
      }
    }
  }, [filters, abortController.current, setResults, getDevicesForMapCall, results])

  useEffect(() => {
    return fetchNewDevicesForMap()
  }, [filters])

  return { ...results, everywherePosition: everywherePosition.current }
}

export default useGetMapDevices
