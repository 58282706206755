import { useMemo } from 'react'
import gql from 'graphql-tag'
import {
  createSelectGraphQLResult,
} from '../../utils/index'
import { parseGraphQLResult } from '../../api/presenters/index'
import { useQuery } from '@apollo/client'

const DEVICES_PER_PAGE = 5
const DEVICES_CURRENT_PAGE = 1

export function useGetDevicesSummaries() {
  const DEVICES_QUERY = gql`
    query DeviceSerialNumbers($pageNumber: Int!, $resultsPerPage: Int!) {
      pagedDevices(
        orderBy: "commissionTime"
        pageNumber: $pageNumber
        orderDirection: "desc"
        resultsPerPage: $resultsPerPage
        ignoreDevicesWithStates: [returned]
      ) {
        devices {
          id
          serialNumber
          lastCommunication
          currentCommission {
            start
            end
          }
          commissions {
            start
            end
            location {
              altitude
              latitude
              longitude
            }
          }
        }
      }
    }
  `

  const DEVICES_SUMMARY = gql`
    query DevicesStatisticsModel {
      getDevicesStatistics {
        installed
        notInstalled
      }
    }
  `

  const devicesHeaderQueryResult = useQuery(DEVICES_SUMMARY, {
    fetchPolicy: 'network-only',
  })

  const devicesQueryResult = useQuery(DEVICES_QUERY, {
    variables: {
      pageNumber: DEVICES_CURRENT_PAGE,
      resultsPerPage: DEVICES_PER_PAGE,
    },
    fetchPolicy: 'network-only',
  })

  const parsedDevices = useMemo(() =>
    createSelectGraphQLResult('data', {
      mapResult: ({ pagedDevices: { devices } }) => parseGraphQLResult(devices),
      nullObject: [],
    })(devicesQueryResult), [devicesQueryResult])

  const devicesHeader = useMemo(() =>
    createSelectGraphQLResult('data', {
      mapResult: ({ getDevicesStatistics }) => getDevicesStatistics,
      nullObject: [],
    })(devicesHeaderQueryResult), [devicesHeaderQueryResult])

  return {
    devices: parsedDevices,
    devicesHeader,
  }
}
