import React from 'react'
import PropTypes from 'prop-types'
import {
  UncontrolledDropdown, DropdownMenu, DropdownItem,
} from 'reactstrap'
import { withTranslation } from 'react-i18next'

import Alert from '@@src/api/presenters/alert'

class AlertListItemActions extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    t: PropTypes.func.isRequired,
    handleClickStatus: PropTypes.func.isRequired,
    alert: PropTypes.instanceOf(Alert).isRequired,
    className: PropTypes.string,
  }

  render() {
    const { t, children, alert, className } = this.props

    return (
      <UncontrolledDropdown className={className}>
        {children}
        <DropdownMenu>
          <DropdownItem
            name="new-status-dropdown-item"
            disabled={alert.status === Alert.OPEN_STATUS}
            onClick={this.handleClickOpenStatus}>
            <span className="fas fa-exclamation-triangle"/>
            &nbsp;
            {t('common/text:alerts.open_status')}
          </DropdownItem>
          <DropdownItem
            name="actioned-status-dropdown-item"
            disabled={alert.status === Alert.ACKNOWLEDGED_STATUS}
            onClick={this.handleClickAcknowledgedStatus}>
            <span className="far fa-search"/>
            &nbsp;
            {t('common/text:alerts.acknowledged_status')}
          </DropdownItem>
          <DropdownItem
            disabled={alert.status === Alert.CLOSED_STATUS}
            onClick={this.handleClickClosedStatus}>
            <span className="far fa-check"/>
            &nbsp;
            {t('common/text:alerts.closed_status')}
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    )
  }

  handleClickOpenStatus = () => {
    const { handleClickStatus } = this.props

    handleClickStatus(Alert.OPEN_STATUS)
  }

  handleClickAcknowledgedStatus = () => {
    const { handleClickStatus } = this.props

    handleClickStatus(Alert.ACKNOWLEDGED_STATUS)
  }

  handleClickClosedStatus = () => {
    const { handleClickStatus } = this.props

    handleClickStatus(Alert.CLOSED_STATUS)
  }
}

export default withTranslation([
  'src/alerts_path/alert_list_item_actions',
  'common/text',
])(AlertListItemActions)
