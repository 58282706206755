import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import styles from './network_asset_icon.css'

export default class NetworkAssetIcon extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    isMarker: PropTypes.bool,
  }

  static defaultProps = {
    isMarker: false,
  }

  render() {
    const { className, id, networkAsset, isMarker } = this.props

    return (
      <span
        id={id}
        className={
          classnames(className, {
            [styles[`icon-${networkAsset.assetType}`]]: !isMarker,
            [styles[`icon-${networkAsset.assetType}-marker`]]: isMarker,
          })
        } />
    )
  }
}
