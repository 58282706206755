import React, { useEffect, useMemo, useRef } from 'react'

import BaseMapComponent, {
  DEFAULT_MAP_OPTIONS,
} from '../../../../components/base_map/base_map.component'
import LoadingIcon from '../../../../../components/loading_icon'
import Control from 'react-leaflet-control'
import DeviceMapMarkerComponent from './map_marker/device_map_marker.component'
import DeviceMapSuperClusterComponent from './map_cluster/device_map_super_cluster.component'

import useGetMapDevices from '../hooks/use_get_map_devices'
import useSupercluster from 'use-supercluster'
import { parseFiltersFromLocation } from '../../../../utils'

import GeoCoordinate from '../../../../../utils/graphs/geo_coordinate'
import GeoBounds from '../../../../../utils/graphs/geo_bounds'

import PropTypes from 'prop-types'

const DevicesMap = ({ filters, currentMapPosition, onPositionUpdate }) => {
  const initialPositions = useRef(
    parseFiltersFromLocation(location.search).mapPosition
  )
  const mapRef = useRef(null)

  const { everywherePosition, isLoading, devices } = useGetMapDevices(filters)

  useEffect(() => {
    !currentMapPosition.bounds &&
      everywherePosition &&
      mapRef.current.leafletElement.flyToBounds(
        everywherePosition.toLatLngBounds(),
        { animate: false }
      )
  }, [currentMapPosition.bounds, everywherePosition])

  const { clusters, supercluster } = useSupercluster({
    points: devices,
    bounds: currentMapPosition.bounds
      ? currentMapPosition.bounds.toSuperclusterBounds()
      : GeoBounds.fromCoordinates(-90, -180, 90, 180).toSuperclusterBounds(),
    zoom:
      typeof currentMapPosition.zoom === 'number' ? currentMapPosition.zoom : 1,
    options: { radius: 100, maxZoom: DEFAULT_MAP_OPTIONS.maxZoom },
  })

  const clusteredNetworkAssets = useMemo(
    () =>
      clusters.map((cluster) => {
        const { cluster: isCluster } = cluster.properties || {}
        const device = !isCluster ? cluster.properties.device : null

        const key = !isCluster
          ? `marker-d-${device.deviceId}`
          : `cluster-${cluster.id}`

        return isCluster ? (
          <DeviceMapSuperClusterComponent
            key={key}
            supercluster={supercluster}
            cluster={cluster}
          />
        ) : (
          <DeviceMapMarkerComponent key={key} device={device} />
        )
      }),
    [clusters, supercluster]
  )

  return (
    <BaseMapComponent
      options={{ minZoom: 0, maxZoom: DEFAULT_MAP_OPTIONS.maxZoom }}
      onPositionChange={onPositionUpdate}
      position={initialPositions.current}
      mapRef={mapRef}
    >
      <Control position={'topleft'}>
        {isLoading && (
          <LoadingIcon className="fa-2x text-center" style={{ width: 30 }} />
        )}
      </Control>
      {clusteredNetworkAssets}
    </BaseMapComponent>
  )
}

DevicesMap.propTypes = {
  currentMapPosition: PropTypes.shape({
    bounds: PropTypes.instanceOf(GeoBounds),
    center: PropTypes.instanceOf(GeoCoordinate),
    zoom: PropTypes.number,
  }),
  onPositionUpdate: PropTypes.func,
  filters: PropTypes.shape({
    search: PropTypes.string,
    filters: PropTypes.arrayOf(PropTypes.string),
  }),
}
export default DevicesMap
