import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash/fp/object'
import { push } from 'connected-react-router'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import { withTranslation } from 'react-i18next'
import { Button, ButtonGroup } from 'reactstrap'
import { intersection } from 'lodash/fp/array'

import routes from '@@src/routes'
import { parseSearchParams } from '@@src/utils'

class AnalysisTabs extends React.PureComponent {
  static propTypes = {
    location: PropTypes.shape({
      pathname: PropTypes.String,
      search: PropTypes.String,
    }).isRequired,
  }

  render() {
    const { t, location } = this.props
    const dataSourceIds = this.selectDataSourceIds(this.props)

    return (
      <ButtonGroup className="d-inline-block align-self-center">
        <Button
          name="map-view-switch-button"
          outline={location.pathname !== routes.analysisMapPath()}
          onClick={this.navigateToMapView}
        >
          <i className="fal fa-map"></i>
          &nbsp;&nbsp;
          {t('text.map')}
        </Button>

        <Button
          name="list-view-switch-button"
          outline={location.pathname !== routes.analysisListPath()}
          onClick={this.navigateToListView}
        >
          <i className="fal fa-list"></i>
          &nbsp;&nbsp;
          {t('text.list')}
        </Button>

        <Button
          name="data-view-switch-button"
          disabled={dataSourceIds.length === 0}
          outline={
            location.pathname !== undefined
              ? !intersection(location.pathname.split('/'), ['data']).length
              : false
          }
          onClick={this.navigateToPressureView}
        >
          <i className="far fa-chart-line"></i>
          &nbsp;&nbsp;
          {t('text.data')}
        </Button>
      </ButtonGroup>
    )
  }

  selectParsedSearchParams = createSelector([get('location')], (location) =>
    parseSearchParams(location.search)
  )

  selectDataSourceIds = createSelector(
    [this.selectParsedSearchParams],
    ({ d = [] }) => d
  )

  navigateToMapView = () => {
    const { dispatchPush } = this.props
    dispatchPush(
      routes.analysisMapPath(this.selectParsedSearchParams(this.props))
    )
  }

  navigateToListView = () => {
    const { dispatchPush } = this.props
    dispatchPush(
      routes.analysisListPath(this.selectParsedSearchParams(this.props))
    )
  }

  navigateToPressureView = () => {
    const { dispatchPush } = this.props
    dispatchPush(
      routes.analysisPressurePath(this.selectParsedSearchParams(this.props))
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchPush(pushParams) {
      dispatch(push(pushParams))
    },
  }
}

export default compose(
  connect(null, mapDispatchToProps),
  withTranslation('src/analysis_path/analysis_tabs')
)(AnalysisTabs)
