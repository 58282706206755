import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { Label, FormGroup, Input, Button, Row, Col, FormText } from 'reactstrap'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { withRouter } from 'react-router'
import classnames from 'classnames'
import moment from 'moment'
import { createSelector } from 'reselect'
import { get } from 'lodash/fp/object'
import { debounce } from 'lodash/function'

import AppFilterDropdown from '@@src/components/dropdowns/app_filter_dropdown'
import Alert from '@@src/api/presenters/alert'
import { allValidDedupedAssetTypes, mergeSearchParams } from '@@src/utils'
import defineQueryParams from '@@src/components/define_query_params'
import DatetimePickerFormGroup from '@@src/components/forms/datetime_picker_form_group'
import routes from '@@src/routes'
import SourceFilter, {
  SOURCE_FILTER_PARAM,
} from '@@src/components/forms/source_filter'
import { EVENT_LOCALISATION_FEATURE } from '@@src/components/tenant_licence/tenant_licence_feature_gate'

export const STATUS_FILTER_PARAM = 'status'
export const TYPE_FILTER_PARAM = 'type'
export const NETWORK_ASSET_TYPE_FILTER_PARAM = 'assettype'
export const FROM_FILTER_PARAM = 'from'
export const TO_FILTER_PARAM = 'to'
export const DATE_URL_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ'
const DATE_DISPLAY_FORMAT = 'L'
const TIME_DISPLAY_FORMAT = 'LT'
export const DATE_AND_TIME_DISPLAY_FORMAT = `${DATE_DISPLAY_FORMAT} ${TIME_DISPLAY_FORMAT}`

import styles from './filters_panel.css'

const INPUT_DEBOUNCE = process.env.NODE_ENV === 'test' ? 0 : 500

class FiltersPanel extends React.PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    dispatchUpdateAlertsFiltersQuery: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    displayEventSourceLocalisationFilters: PropTypes.bool.isRequired,
    dispatchUpdateAlertsSourceQuery: PropTypes.func.isRequired,
    statusFilter: PropTypes.array,
    typeFilter: PropTypes.string,
    networkAssetTypeFilter: PropTypes.string,
    fromFilter: PropTypes.string,
    toFilter: PropTypes.string,
  }

  static defaultProps = {
    displayEventSourceLocalisationFilters: false,
  }

  constructor(props) {
    super(props)

    this.state = {
      dateFrom: props.fromFilter,
      dateTo: props.toFilter,
      selectValue: [],
    }
  }

  render() {
    const {
      t,
      networkAssetTypeFilter,
      statusFilter,
      typeFilter,
      sourceFilter,
      displayEventSourceLocalisationFilters,
    } = this.props

    const { dateFrom, dateTo } = this.state
    const isNetworkAssetTypeFilterDisabled =
      displayEventSourceLocalisationFilters &&
      (!typeFilter || typeFilter === Alert.EVENT_SOURCE_LOCALISATION_TYPE)
    const momentFromFilter = moment(dateFrom, DATE_URL_FORMAT, true)
    const momentToFilter = moment(dateTo, DATE_URL_FORMAT, true)
    const formattedFromFilter =
      dateFrom && momentFromFilter.isValid()
        ? momentFromFilter.format(DATE_AND_TIME_DISPLAY_FORMAT)
        : dateFrom
    const formattedToFilter =
      dateTo && momentToFilter.isValid()
        ? momentToFilter.format(DATE_AND_TIME_DISPLAY_FORMAT)
        : dateTo

    return (
      <div>
        <div className="d-flex align-items-center justify-content-between">
          <h2 className="h5">{t('headings.title')}</h2>
          <Button
            name="clear-alert-filters-button"
            onClick={this.handleClickReset}
            color="secondary"
            outline={true}
          >
            {t('buttons.reset')}
          </Button>
        </div>
        <Row className="d-flex align-items-start mt-3" noGutters={true}>
          <Col sm="6">
            <DatetimePickerFormGroup
              errorText={this.selectFromFilterErrorText(this.props)}
              value={formattedFromFilter || ''}
              onChange={this.handleFromChange}
              className="mr-1"
              datetimeProps={{
                dateFormat: DATE_DISPLAY_FORMAT,
                strictParsing: true,
                inputProps: {
                  readOnly: false,
                  id: 'alertTimeRangeFrom',
                },
              }}
              name={'alertTimeRangeFrom'}
              labelText={t('labels.from_time_range')}
            />
          </Col>
          <Col sm="6">
            <DatetimePickerFormGroup
              errorText={this.selectToFilterErrorText(this.props)}
              value={formattedToFilter || ''}
              onChange={this.handleToChange}
              className={classnames(styles['align-right'], 'ml-1')}
              datetimeProps={{
                dateFormat: DATE_DISPLAY_FORMAT,
                strictParsing: true,
                inputProps: {
                  readOnly: false,
                  id: 'alertTimeRangeTo',
                },
              }}
              name={'alertTimeRangeTo'}
              labelText={t('labels.to_time_range')}
            />
          </Col>
        </Row>
        <SourceFilter
          sourceFilter={sourceFilter}
          handleClickSelectSource={this.handleClickSelectSource}
          location={location}
        />
        <FormGroup>
          <Label htmlFor="alert-type">{t('labels.type')}</Label>
          <Input
            value={typeFilter || ''}
            onChange={this.handleTypeChange}
            id="alert-type"
            name="type"
            type="select"
          >
            <option value="">{t('options.any')}</option>
            {Alert.TYPES.map((type) => {
              if (
                type !== Alert.EVENT_SOURCE_LOCALISATION_TYPE ||
                displayEventSourceLocalisationFilters
              ) {
                return (
                  <option key={type} value={type}>
                    {t(`common/text:alerts.${type}_type`)}
                  </option>
                )
              } else {
                return null
              }
            })}
          </Input>
        </FormGroup>
        <FormGroup>
          <div className="d-flex flex-column w-100">
            <Label htmlFor="alert-status">{t('labels.status')}</Label>
            <AppFilterDropdown
              selection={statusFilter}
              onChangeSelection={this.handleStatusChange}
              dropdownToggleClassname="form-control w-100"
              dropdownButtonClassname={classnames(
                styles['filter-button'],
                'w-100',
              )}
              dropdownMenuClassname="w-100"
              labelText={this.createTypeList(statusFilter)}
              dropdownText={statusFilter.map((status) =>
                t(`common/text:alerts.${status}_status`),
              )}
              name="alert-status-filter"
            >
              {Alert.STATUSES.map((status) => (
                <AppFilterDropdown.Item
                  key={status}
                  value={status}
                  name={`alert-status-filter-button-${status}`}
                  className="w-100"
                >
                  {t(`common/text:alerts.${status}_status`)}
                </AppFilterDropdown.Item>
              ))}
            </AppFilterDropdown>
          </div>
        </FormGroup>
        <FormGroup id="network-asset-type-filter-form-group">
          <Label htmlFor="alert-network-asset-type">
            {t('labels.network_asset_type')}
          </Label>
          <Input
            className={classnames({
              [styles['disabled-input']]: isNetworkAssetTypeFilterDisabled,
            })}
            disabled={isNetworkAssetTypeFilterDisabled}
            value={networkAssetTypeFilter || ''}
            onChange={this.handleNetworkAssetTypeChange}
            id="alert-network-asset-type"
            name="networkAssetType"
            type="select"
          >
            <option value="">{t('options.any')}</option>
            {allValidDedupedAssetTypes().map((assetType) => (
              <option key={assetType} value={assetType}>
                {t(`common/text:text.${assetType}`)}
              </option>
            ))}
          </Input>
          {displayEventSourceLocalisationFilters ? (
            <FormText color="muted">
              {t('text.disabled_network_asset_type')}
            </FormText>
          ) : null}
        </FormGroup>
      </div>
    )
  }

  createTypeList = (array) => {
    const { t } = this.props

    if (array.length === 0) {
      return t('options.any')
    }
    const createNewString = (totalString, newString, index, src) => {
      if (src.length === index - 1) {
        return `${totalString} ${t(`common/text:alerts.${newString}_status`)}`
      }
      return `${totalString}, ${t(`common/text:alerts.${newString}_status`)}`
    }
    const assembledStrings = array.reduce(createNewString)
    return assembledStrings
  }

  handleFromChange = (date) => {
    this.setState({ dateFrom: date })

    this.dispatchFromChange(date)
  }

  handleToChange = (date) => {
    this.setState({ dateTo: date })

    this.dispatchToChange(date)
  }

  dispatchFromChange = debounce(
    (date) => {
      const { dispatchUpdateAlertsFiltersQuery, location } = this.props

      dispatchUpdateAlertsFiltersQuery(location, {
        [FROM_FILTER_PARAM]: moment.isMoment(date)
          ? date.format(DATE_URL_FORMAT)
          : date,
      })
    },
    INPUT_DEBOUNCE,
    { leading: false, trailing: true },
  )

  dispatchToChange = debounce(
    (date) => {
      const { dispatchUpdateAlertsFiltersQuery, location } = this.props

      dispatchUpdateAlertsFiltersQuery(location, {
        [TO_FILTER_PARAM]: moment.isMoment(date)
          ? date.format(DATE_URL_FORMAT)
          : date,
      })
    },
    INPUT_DEBOUNCE,
    { leading: false, trailing: true },
  )

  handleClickReset = () => {
    const { dispatchUpdateAlertsFiltersQuery, location } = this.props

    dispatchUpdateAlertsFiltersQuery(location, {
      [STATUS_FILTER_PARAM]: '',
      [TYPE_FILTER_PARAM]: '',
      [NETWORK_ASSET_TYPE_FILTER_PARAM]: '',
      [FROM_FILTER_PARAM]: '',
      [TO_FILTER_PARAM]: '',
      [SOURCE_FILTER_PARAM]: '',
    })

    this.setState({
      dateFrom: undefined,
      dateTo: undefined,
    })
  }

  handleStatusChange = (input) => {
    const { dispatchUpdateAlertsFiltersQuery, location } = this.props

    dispatchUpdateAlertsFiltersQuery(location, {
      [STATUS_FILTER_PARAM]: input,
    })
  }

  handleTypeChange = (ev) => {
    const { dispatchUpdateAlertsFiltersQuery, location } = this.props
    const typeFilter = ev.target.value
    const newFilters = {
      [TYPE_FILTER_PARAM]: typeFilter,
    }

    if (!typeFilter || typeFilter === Alert.EVENT_SOURCE_LOCALISATION_TYPE) {
      newFilters[NETWORK_ASSET_TYPE_FILTER_PARAM] = ''
    }

    dispatchUpdateAlertsFiltersQuery(location, newFilters)
  }

  handleNetworkAssetTypeChange = (ev) => {
    const { dispatchUpdateAlertsFiltersQuery, location } = this.props

    dispatchUpdateAlertsFiltersQuery(location, {
      [NETWORK_ASSET_TYPE_FILTER_PARAM]: ev.target.value,
    })
  }

  handleClickSelectSource = (source) => {
    const { dispatchUpdateAlertsSourceQuery } = this.props
    dispatchUpdateAlertsSourceQuery(location, source)
  }

  selectFromFilterErrorText = createSelector(
    [get('fromFilter'), get('toFilter'), get('t')],
    (fromFilter, toFilter, t) => {
      const momentFromFilter = moment(fromFilter, DATE_URL_FORMAT, true)
      const momentToFilter = moment(toFilter, DATE_URL_FORMAT, true)

      if (fromFilter && !momentFromFilter.isValid()) {
        return t('text.invalid_date')
      } else if (
        fromFilter &&
        toFilter &&
        momentFromFilter.isValid() &&
        momentToFilter.isValid() &&
        momentFromFilter.isAfter(momentToFilter)
      ) {
        return t('text.invalid_range')
      }

      return undefined
    },
  )

  selectToFilterErrorText = createSelector(
    [get('toFilter'), get('t')],
    (toFilter, t) => {
      const momentToFilter = moment(toFilter, DATE_URL_FORMAT, true)

      if (toFilter && !momentToFilter.isValid()) {
        return t('text.invalid_date')
      }

      return undefined
    },
  )
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchUpdateAlertsFiltersQuery(location, filters) {
      dispatch(
        push({
          search: mergeSearchParams(location.search, filters),
          pathname: routes.alertsPath(),
        }),
      )
    },
    dispatchUpdateAlertsSourceQuery(location, sourceId) {
      dispatch(
        push({
          search: mergeSearchParams(location.search, {
            [SOURCE_FILTER_PARAM]: sourceId,
          }),
          pathname: routes.alertsPath(),
        }),
      )
    },
  }
}

function mapStateToProps(state) {
  // todo: tenantLicenceFeatures
  const tenantLicenceFeatures = state?.data?.tenantLicenceFeatures || []

  return {
    displayEventSourceLocalisationFilters: tenantLicenceFeatures.includes(EVENT_LOCALISATION_FEATURE),
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
  defineQueryParams({
    statusFilter: {
      alias: STATUS_FILTER_PARAM,
      parseFunc: defineQueryParams.parseArray,
    },
    typeFilter: { alias: TYPE_FILTER_PARAM },
    networkAssetTypeFilter: { alias: NETWORK_ASSET_TYPE_FILTER_PARAM },
    fromFilter: { alias: FROM_FILTER_PARAM },
    toFilter: { alias: TO_FILTER_PARAM },
    sourceFilter: { alias: SOURCE_FILTER_PARAM },
  }),
  withTranslation(['src/alerts_path/filters_panel', 'common/text']),
)(FiltersPanel)
