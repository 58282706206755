/**
  * @function mapErrorToText
  * This function returns the appropriate JSON property that should be used for modal popup errors.
  * Translation can be found in locales/{language}/src/components/graphs/modals
  *
  * @param {number} errorCode - a number that corresponds to the appropriate error description
  *                           (The actual description is found in the relevant locales json file
  *                           for translation purposes)
*/

export default function mapErrorToText(errorCode) {
  switch (Number(errorCode)) {
    default:
    case -4:
      return 'alert.server_error'
    case -3:
      return 'alert.end_before_start'
    case -2:
      return 'alert.over_allowed_time'
    case -1:
      return 'alert.end_matches_start'
    case 1:
      return 'alert.invalid_asset_id'
    case 2:
      return 'alert.invalid_session'
    case 3:
      return 'alert.not_commissioned'
    case 4:
      return 'alert.exceeds_quota'
    case 5:
      return 'alert.outdated_firmware'
    case 6:
      return 'alert.invalid_channel'
    case 7:
      return 'alert.rate_exceeded'
    case 8:
      return 'alert.not_all_support_event_localisation'
    case 9:
      return 'alert.raw_data_request_overlap'
  }
}
