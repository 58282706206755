import gql from 'graphql-tag'
import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { compose } from 'redux'
import { graphql } from '@apollo/client/react/hoc'
import { NavLink, Redirect } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { Col, Row, Container } from 'reactstrap'

import AppLayout from '@@src/components/app_layout'
import requiresLogin from '@@src/components/security/requires_login'
import transformProps from '@@src/components/transform_props'
import GroupBreadcrumb from './group_breadcrumb'
import defineQueryParams from '@@src/components/define_query_params'
import EditGeneralSettingsSection
from './edit_group_page/edit_general_settings_section'
import EditAlertsConfigurationSection
from './edit_group_page/edit_alerts_configuration_section'
import { parseGraphQLResult } from '@@src/api/presenters'
import { createSelectGraphQLResult } from '@@src/utils'
import routes from '@@src/routes'
import AsyncResult from '@@src/utils/async_result'
import userPermissions from '@@src/components/permissions/user_permissions'

import styles from './edit_group_page.css'

const ALERTS_TAB = 'alerts'
const noop = () => {}

class EditGroupPage extends React.PureComponent {
  static propTypes = {
    dispatchUpdateQueryParams: PropTypes.func.isRequired,
    groupResult: PropTypes.instanceOf(AsyncResult).isRequired,
  }

  render() {
    const { t, tab, withQueryParamsLink, groupResult, permissions } = this.props

    if (groupResult.wasSuccessful() &&
      (!permissions.includes('can_edit_groups') ||
      groupResult.data.isCongruityGroup())) {
      return (
        <Redirect to={routes.managementGroupsDetailPath(groupResult.data.id)} />
      )
    }

    return (
      <AppLayout title={t('headings.page_title', {
        group: groupResult.wasSuccessful() ? groupResult.data.name : '???',
      })}>
        <Container>
          <Row className="mt-5">
            <Col>
              <GroupBreadcrumb groupResult={groupResult}/>
            </Col>
          </Row>

          <Row>
            <Col>
              <h1 className={styles.heading}>{t('headings.edit_group')}</h1>
            </Col>
          </Row>

          <Row>
            <Col md="2">
              <div className={styles['tab-links-column']}>
                <NavLink
                  to={withQueryParamsLink()}
                  isActive={noop}
                  className={classnames('btn btn-outline-primary border-0', {
                    active: !tab,
                  }, styles['tab-link'])}>
                  {t('buttons.general_settings')}
                </NavLink>

                <NavLink
                  to={withQueryParamsLink({ tab: ALERTS_TAB })}
                  isActive={noop}
                  className={classnames('btn btn-outline-primary border-0', {
                    active: tab === ALERTS_TAB,
                  }, styles['tab-link'])}>
                  {t('buttons.alert_configuration')}
                </NavLink>
              </div>
            </Col>

            <Col md="8">
              {this.renderCurrentActiveSection(tab)}
            </Col>
          </Row>
        </Container>
      </AppLayout>
    )
  }

  renderCurrentActiveSection(tab) {
    const { groupId } = this.props

    switch (tab) {
      case ALERTS_TAB: {
        return <EditAlertsConfigurationSection groupId={groupId}/>
      }

      default:
        return <EditGeneralSettingsSection groupId={groupId}/>
    }
  }

  static GROUP_QUERY = gql`
    query Group($id: Int!) {
      groupMix(id: $id) {
        id
        name
        category
        congruity {
          zone {
            id
          }
        }
        ancestry {
          id
          name
          index
        }
        members {
          count
          type
        }
      }
    }
  `
}

export default compose(
  requiresLogin,
  userPermissions,
  withTranslation([
    'src/management_path/groups_path/edit_group_page',
    'common/text',
  ]),
  defineQueryParams({
    tab: { alias: 't' },
  }),
  transformProps(() => ({ match }) => {
    return {
      groupId: parseInt(decodeURIComponent(match.params.id), 10),
      selectGroupResult: createSelectGraphQLResult('groupMix', {
        mapResult: parseGraphQLResult,
        nullObject: null,
      }),
    }
  }),
  graphql(EditGroupPage.GROUP_QUERY, {
    options: ({ groupId }) => {
      return {
        fetchPolicy: 'network-only',
        variables: {
          id: groupId,
        },
      }
    },
    props: ({ data, ownProps: { selectGroupResult } }) => {
      return {
        refetchData: data.refetch,
        groupResult: selectGroupResult(data),
      }
    },
  })
)(EditGroupPage)
