export default class PagedData {
  constructor(data) {
    Object.assign(this, data)
    Object.freeze(this)
  }

  get length() {
    return this.pagination.totalResults
  }

  get totalResults() {
    return this.pagination.totalResults
  }

  get pageNumber() {
    return this.pagination.pageNumber
  }

  get totalPages() {
    return this.pagination.totalPages
  }

  map(mapper) {
    return this.data.map(mapper)
  }
}
