import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { FormGroup, Label, Input } from 'reactstrap'
import { noop } from 'lodash/fp/util'

import { User } from '@@src/api/presenters/user'

export const USER_ROLE_FILTERS = [
  User.ROLE_VIEWER,
  User.ROLE_EDITOR,
  User.ROLE_SUPERUSER,
  User.ROLE_ADMINISTRATOR,
]

class UserRoleFilters extends React.PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    onChangeSelection: PropTypes.func.isRequired,
    userRoleFilters: PropTypes.array.isRequired,
  }

  static defaultProps = {
    onChangeSelection: noop,
    userRoleFilters: [],
  }

  render() {
    const { t } = this.props
    const { userRoleFilters } = this.props

    return (
      <FormGroup tag="fieldset">
        <FormGroup>
          <legend className="font-weight-normal h6">
            {t('headers.roles')}
          </legend>
          <div className="pl-3">
            {this.renderFilterCategory(USER_ROLE_FILTERS, userRoleFilters)}
          </div>
        </FormGroup>
      </FormGroup>
    )
  }

  renderFilterCategory = (filtersInCategory, userRoleFilters) => {
    const { t } = this.props

    return filtersInCategory.map(filter => (
      <FormGroup key={filter} check={true}>
        <Label check={true}>
          <Input
            checked={userRoleFilters.includes(filter)}
            onChange={this.onChangeItem}
            type="checkbox"
            name={filter}/>
          &nbsp;
          {t(`common/text:text.role_${filter}`)}
        </Label>
      </FormGroup>
    ))
  }

  onChangeItem = ev => {
    const { name: value, checked } = ev.target
    const { userRoleFilters, onChangeSelection } = this.props
    const newRoleFiltersState = userRoleFilters

    if (checked && newRoleFiltersState.indexOf(value) === -1) {
      newRoleFiltersState.push(value)
    } else {
      const i = newRoleFiltersState.indexOf(value)
      if (i !== -1) {
        newRoleFiltersState.splice(i, 1)
      }
    }

    onChangeSelection(newRoleFiltersState)
  }
}

export default withTranslation([
  'src/components/modals/user_role_filters',
  'common/text',
])(memo(UserRoleFilters))
