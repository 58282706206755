import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { Input } from 'reactstrap'
import { noop } from 'lodash/fp/util'

import { selectDateFormatter, AVAILABLE_TIME_ZONES } from '@@src/utils'

function TimeZoneSelect(props) {
  const { t, className, handleChange, timezones, value, id } = props

  return (
    <Input
      id={id}
      type="select"
      name="timezone-select"
      value={value}
      onChange={handleChange}
      className={className}>
      {timezones.map(tzOption => (
        <option value={tzOption} key={tzOption}>
          GMT{selectDateFormatter(tzOption)(new Date(), 'Z')}
          &nbsp;
          {t(`common/text:${tzOption}`)}
        </option>
      ))}
    </Input>
  )
}

TimeZoneSelect.propTypes = {
  t: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  timezones: PropTypes.array.isRequired,
  value: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string,
}

TimeZoneSelect.defaultProps = {
  handleChange: noop,
  timezones: AVAILABLE_TIME_ZONES,
}

export default withTranslation(['common/text'])(memo(TimeZoneSelect))
