import { useCallback, useEffect, useMemo, useState } from 'react'
import { PRESSURE_EVENTS_GRAPH_QUERY } from '../../analysis_path/pressure_analysis_path/pressure_subset_graph/graphql'
import { TOTAL_EVENT_SLICES } from './consts'
import { formatNetworkAssetUniqueId } from './useGetPressureData'
import { parseGraphQLResult } from '../../api/presenters'
import { AsyncResult } from '../../utils'

const useGetPressureEvents = (props) => {
  const [eventsData, setEventsData] = useState({})
  const [loading, setLoading] = useState(0)
  const [_totalParts, setTotalParts] = useState(0)
  const [error, setError] = useState('')

  const pressureEvents = useMemo(() => {
    if (loading !== 0) {
      return AsyncResult.pending(Object.values(eventsData))
    }

    return AsyncResult.success(Object.values(eventsData))
  }, [eventsData, loading])

  const formatResults = useCallback((result, asset) => {
    const assetId = formatNetworkAssetUniqueId(asset)
    setEventsData((oldEventData) => {
      return {
        ...oldEventData,
        [assetId]: {
          source: asset,
          events: parseGraphQLResult(result.data.getEventsPlotClusters),
        },
      }
    })
  }, [setEventsData])

  const fetchData = useCallback(
    async (
      client,
      start,
      end,
      networkAssetChannels,
      signal,
    ) => {

      const queryList = []
      for (let i = 0; i < networkAssetChannels.length; i++) {
        queryList.push(
          client.query(
            {
              query: PRESSURE_EVENTS_GRAPH_QUERY,
              variables: {
                assetId: networkAssetChannels[i].networkAssetId,
                assetChannel: networkAssetChannels[i].channel,
                slicesCount: TOTAL_EVENT_SLICES,
                timeRange: {
                  from: new Date(start).toISOString(),
                  to: new Date(end).toISOString(),
                },
              },
              context: {
                fetchOptions: {
                  signal,
                },
              },
            }
          )
        )
      }

      setLoading((oldLoading) => oldLoading + queryList.length)
      setTotalParts((oldTotalParts) => oldTotalParts + queryList.length)

      let results = []

      try {
        results = await Promise.all(queryList)
      } catch (err) {
        setError(err)
      }

      results.forEach((result, index) => formatResults(result, networkAssetChannels[index]))
      setLoading((loadingOld) => loadingOld - queryList.length)
    }, [formatResults])

  useEffect(() => {
    if (Object.keys(props).length) {
      const { start, end, client, networkAssetChannels, signal } = props

      fetchData(client, start, end, networkAssetChannels, signal)
      return () => {
        setLoading(0)
        setTotalParts(0)
        setEventsData({})
      }
    }
    return () => { }
  }, [props.start, props.end, props.client, props.networkAssetChannels, props.signal, fetchData])

  return { pressureEvents, error, loading: loading !== 0 }
}

export default useGetPressureEvents
