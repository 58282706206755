import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash/fp/object'
import { compose } from 'redux'
import { createSelector } from 'reselect'
import { withTranslation } from 'react-i18next'

import GraphStateOverlay from '@@src/components/graphs/graph_state_overlay'
import GraphAlertGroup from '@@src/components/graphs/graph_alert_group'
import AsyncResult from '@@src/utils/async_result'

import styles from './telemetry_graph_state_overlay.css'

class TelemetryGraphStateOverlay extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    dataResult: PropTypes.instanceOf(AsyncResult).isRequired,
    usesTooltips: PropTypes.bool.isRequired,
    displayTooltips: PropTypes.bool,
  }

  static defaultProps = {
    usesTooltips: true,
  }

  render() {
    const { children, ...rest } = this.props

    return (
      <GraphStateOverlay className={styles['telemetry-graph']} {...rest}>
        <GraphAlertGroup
          className={styles['telemetry-alert-group']}
          alertConfigs={this.selectAlertConfigs(this.props)}/>

        {children}
      </GraphStateOverlay>
    )
  }

  selectAlertConfigs = createSelector(
    [get('t'), get('dataResult'), get('usesTooltips'), get('displayTooltips')],
    (t, dataResult, usesTooltips, displayTooltips) => {
      const alerts = []

      if (usesTooltips && !displayTooltips) {
        alerts.push({
          color: 'warning',
          text: t('text.time_range_too_large_for_tooltips'),
        })
      }

      switch (true) {
        case (dataResult.wasFailure()): {
          alerts.push({
            color: 'danger',
            text: t('text.failed_to_load_telemetry'),
          })
          break
        }

        case (
          dataResult.wasSuccessful() &&
          !(dataResult.data.hasOwnProperty('rawTelemetry') &&
            !!dataResult.data.rawTelemetry.length)
        ):
          alerts.push({
            color: 'warning',
            text: t('text.no_telemetry_data'),
          })
          break
      }

      return alerts
    }
  )
}

export default compose(
  withTranslation([
    'src/management_path/devices_path/history_modal/telemetry_graph_state_overlay', // eslint-disable-line max-len
    'common/text',
  ]),
)(TelemetryGraphStateOverlay)
