import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Label, FormFeedback } from 'reactstrap'
import moment from 'moment'
import { withTranslation } from 'react-i18next'
import { noop } from 'lodash/fp/util'

import AppFormGroup from '@@src/components/forms/app_form_group'
import AsyncResult from '@@src/utils/async_result'
import FrequentDeviceSessionConfigAlert from
'@@src/components/alerts/frequent_device_session_config_alert'
import FormFields from '@@src/utils/form_fields'
import Device from '@@src/api/presenters/device'
import SuccessfulConfigUpdateAlert from
'@@src/components/alerts/successful_config_update_alert'
import TimeZoneSelect from '@@src/components/forms/inputs/time_zone_select'
import { isValidTimezone, DEFAULT_TIMEZONE } from '@@src/utils'
import DatetimePickerFormGroup from
'@@src/components/forms/datetime_picker_form_group'

const DEFAULT_TIME = '09:00:00'
const TIME_FORMAT = 'hh:mm A'

export function defaultValidIntervals() {
  const hourInSeconds = 60 * 60

  return [
    0.25 * hourInSeconds,
    0.5 * hourInSeconds,
    1 * hourInSeconds,
    2 * hourInSeconds,
    3 * hourInSeconds,
    4 * hourInSeconds,
    6 * hourInSeconds,
    8 * hourInSeconds,
    12 * hourInSeconds,
    24 * hourInSeconds,
  ]
}

class DeviceSessionConfigurationFields extends PureComponent {
  static propTypes = {
    formResult: PropTypes.instanceOf(AsyncResult).isRequired,
    intervals: PropTypes.arrayOf(PropTypes.number).isRequired,
    selectFormFieldValue: PropTypes.func.isRequired,
    selectFormFieldsError: PropTypes.func.isRequired,
    getFormFieldsChangeHandler: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.formFieldsDateFromChangeHandler =
      props.getFormFieldsChangeHandler('dateFrom')
  }

  render() {
    const {
      t, formResult, intervals, selectFormFieldValue,
      selectFormFieldsError, getFormFieldsChangeHandler,
    } = this.props
    const secondsInterval = selectFormFieldValue('secondsInterval')
    const dateFrom = selectFormFieldValue('dateFrom')
    const dateFromError = selectFormFieldsError('dateFrom')
    const timeZone = selectFormFieldValue('timeZone')
    const timeZoneError = selectFormFieldsError('timeZone')

    return (
      <React.Fragment>
        {
          formResult.wasSuccessful() ?
            <SuccessfulConfigUpdateAlert className="d-inline-block mb-4" />
            : null
        }
        <FrequentDeviceSessionConfigAlert
          className="d-inline-block mb-4"
          secondsInterval={Number(secondsInterval)} />
        <div>
          <div
            className="d-flex align-items-start justify-content-start mb-3">
            <Label
              for="device-config-date-from"
              className="mr-2 mb-0 text-capitalize">
              {t('text.from')}
            </Label>
            <DatetimePickerFormGroup
              value={dateFrom}
              className="mb-0"
              errorText={dateFromError ? t(dateFromError, {
                format: 'hh:mm',
              }) : undefined}
              onChange={this.selectDateFromOnChangeHandler}
              datetimeProps={{
                inputProps: {
                  readOnly: false,
                  id: 'device-config-date-from',
                },
                dateFormat: false,
                viewMode: 'time',
                timeFormat: TIME_FORMAT,
              }} />
            <div className="ml-2">
              <Label className="sr-only" for="device-config-time-zone">
                {t('text.time_zone')}
              </Label>
              <TimeZoneSelect
                value={timeZone}
                handleChange={getFormFieldsChangeHandler('timeZone')}
                id="device-config-time-zone" />
              {
                timeZoneError ?
                  <FormFeedback className="d-block">
                    {t(timeZoneError)}
                  </FormFeedback>
                  : null
              }
            </div>
          </div>
          <div className="d-flex align-items-start justify-content-start">
            <Label
              for="device-config-seconds-interval"
              className="mr-2 mb-0 text-capitalize">
              {t('text.every')}
            </Label>
            <AppFormGroup
              id="device-config-seconds-interval"
              className="mb-0"
              name="secondsInterval"
              type="select"
              value={secondsInterval}
              onChange={getFormFieldsChangeHandler('secondsInterval')}
              errorText={selectFormFieldsError('secondsInterval')}>
              {
                intervals.map(intervalOption => {
                  const hourInSeconds = 60 * 60
                  const intervalMinutes = intervalOption / 60
                  const intervalHour = intervalMinutes / 60
                  let optionLabel = ''

                  if (intervalOption < hourInSeconds) {
                    optionLabel = t('text.interval_minutes', {
                      count: intervalMinutes,
                    })
                  } else {
                    optionLabel = t('text.interval_hours', {
                      count: intervalHour,
                    })
                  }

                  return (
                    <option key={intervalOption} value={intervalOption}>
                      {optionLabel}
                    </option>
                  )
                })
              }
            </AppFormGroup>
          </div>
        </div>
      </React.Fragment>
    )
  }

  selectDateFromOnChangeHandler = date => {
    return this.formFieldsDateFromChangeHandler({
      target: {
        value: moment.isMoment(date) ? date.format(TIME_FORMAT) : date,
      },
    })
  }
}

export function withDeviceSessionConfigurationFieldsProvider(Component) {
  class DeviceSessionConfigurationFieldsProvider extends PureComponent {
    static propTypes = {
      deviceConfigIntervals: PropTypes.arrayOf(PropTypes.number).isRequired,
      selectedConfiguration: PropTypes.shape({
        dateFrom: PropTypes.string,
        secondsInterval: PropTypes.number,
        timeZone: PropTypes.string,
        sourceAggregate: PropTypes.oneOf(Device.CONFIGURATION_SOURCE_TYPES),
      }).isRequired,
      t: PropTypes.func.isRequired,
    }

    static defaultProps = {
      deviceConfigIntervals: defaultValidIntervals(),
      onReset: noop,
      selectedConfiguration: {},
    }

    constructor(props) {
      super(props)

      this.deviceConfigFields = new FormFields(
        this,
        'deviceConfig', {
          dateFrom: date => {
            return moment(date, TIME_FORMAT, true).isValid() ?
              '' : 'error.invalid_time'
          },
          secondsInterval: interval => {
            const { deviceConfigIntervals } = this.props

            return !deviceConfigIntervals.includes(Number(interval)) ?
              'common/errors:form.required' : ''
          },
          timeZone: tz => isValidTimezone(tz) ?
            '' : 'common/errors:form.invalid_timezone',
        }
      )

      const dateFrom = moment.tz(
        props.selectedConfiguration.dateFrom,
        props.selectedConfiguration.timeZone
      )

      this.state = {
        deviceConfig: this.deviceConfigFields.initialState({
          dateFrom: props.selectedConfiguration.dateFrom ?
            dateFrom.format(TIME_FORMAT) : DEFAULT_TIME,
          secondsInterval: props.selectedConfiguration.secondsInterval ||
            props.deviceConfigIntervals[0],
          timeZone: props.selectedConfiguration.timeZone || DEFAULT_TIMEZONE,
        }),
      }
    }

    render() {
      return (
        <Component
          performDeviceConfigPreSubmitChecks={this.performPreSubmitChecks}
          selectDeviceConfigFieldValue={this.selectFieldValue}
          selectDeviceConfigFieldErrorText={this.selectFieldErrorText}
          resetDeviceConfigFields={this.handleResetFields}
          getDeviceConfigFieldChangeHandler={this.getOnChangeHandler()}
          hasDeviceConfigFieldValidationErrors={this.hasFieldValidationErrors()}
          {...this.props} />
      )
    }

    performPreSubmitChecks = () => {
      return this.deviceConfigFields.performPreSubmitChecks()
    }

    selectFieldValue = fieldName => {
      return this.deviceConfigFields.selectValue(this.state, fieldName)
    }

    selectFieldErrorText = fieldName => {
      const error = this.deviceConfigFields.selectError(
        this.state,
        fieldName
      )

      return error ? this.props.t(error) : error
    }

    handleResetFields = async () => {
      const {
        selectedConfiguration, onReset, deviceConfigIntervals,
      } = this.props
      const dateFrom = moment(selectedConfiguration.dateFrom)

      if (selectedConfiguration.timeZone) {
        dateFrom.tz(selectedConfiguration.timeZone)
      }

      this.deviceConfigFields.setState({
        values: {
          dateFrom: selectedConfiguration.dateFrom ?
            dateFrom.format(TIME_FORMAT) : DEFAULT_TIME,
          secondsInterval: selectedConfiguration.secondsInterval ||
            deviceConfigIntervals[0],
          timeZone: selectedConfiguration.timeZone || DEFAULT_TIMEZONE,
        },
      })

      await onReset()
    }

    hasFieldValidationErrors = () => {
      return this.deviceConfigFields.hasAnyValidationErrors()
    }

    getOnChangeHandler = () => {
      return this.deviceConfigFields.onChangeHandlerFor.bind(
        this.deviceConfigFields
      )
    }
  }

  return withTranslation(['common/errors'])(
    DeviceSessionConfigurationFieldsProvider
  )
}

export default withTranslation([
  'src/components/device_configuration/device_session_configuration_fields',
  'common/errors',
])(DeviceSessionConfigurationFields)
