import React, { memo } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { UncontrolledTooltip } from 'reactstrap'

import SubmitButton from '@@src/components/buttons/submit_button'
import { AsyncResult } from '@@src/utils'

import styles from './move_submit_button.css'

function MoveSubmitButton(props) {
  const {
    children,
    isDisabled,
    disabledReason,
    handleSubmit,
    result,
  } = props

  return (
    <React.Fragment>
      {
        isDisabled && disabledReason ?
          <UncontrolledTooltip target={'submit-move-groups-button'}>
            {disabledReason}
          </UncontrolledTooltip>
          : null
      }
      <div id="submit-move-groups-button">
        <SubmitButton
          className={classnames({
            [styles['submit-button-disabled']]: isDisabled,
          })}
          disabled={isDisabled}
          name="move-submit-button"
          color="primary"
          result={result}
          onSubmitForm={handleSubmit}
          submitText={children}>
        </SubmitButton>
      </div>
    </React.Fragment>
  )
}

MoveSubmitButton.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  result: PropTypes.instanceOf(AsyncResult).isRequired,
  disabledReason: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  children: PropTypes.node,
}

MoveSubmitButton.defaultProps = {
  isDisabled: false,
}

export default memo(MoveSubmitButton)
