import React from 'react'
import PropTypes from 'prop-types'
import AnomalyMarker from './anomaly_marker'
import AnomalyCluster from './anomaly_cluster'
import { globalSequence } from '@@src/utils'
import noop from 'lodash/noop'

class AnomalyLayer extends React.PureComponent {
  render() {
    const {
      graphConfig,
      anomalyMarkersAndClusters,
      displayAnomalies,
      activeAnomalyId,
      isActiveAnomalyExpanded,
      setActiveExpandedAnomalyId,
      toggleShowBoundsForActiveAnomaly,
      boundsShown,
    } = this.props

    if(!displayAnomalies) {
      return null
    }

    const clipPathId = `anomalies-layer-clip-path-${this.id}`
    return(
      <g name={'anomalies-layer'}>
        <defs>
          <clipPath id={clipPathId}>
            <rect
              x={graphConfig.leftPadding}
              y={graphConfig.topPadding}
              width={graphConfig.plotAreaWidth}
              height={graphConfig.plotAreaHeight}/>
          </clipPath>
        </defs>
        <g name="anomalies-icons">
          {
            anomalyMarkersAndClusters.map((sourceItemAnomaliesAndClusters, index) => {
              return(
                <g
                  clipPath={`url(#${clipPathId})`}
                  name={'anomaly-marker-cluster-fragment-' + index}
                  key={'anomaly-marker-cluster-fragment-' + index}>
                  {
                    sourceItemAnomaliesAndClusters.markers.map((anomalyItem, anomalyIndex) =>
                      <AnomalyMarker
                        key={`anomaly-marker-info-${anomalyIndex}`}
                        clipPath={`url(#${clipPathId})`}
                        anomalyItem={anomalyItem}
                        graphConfig={graphConfig}
                        activeAnomalyId={activeAnomalyId}
                        isActiveAnomalyExpanded={isActiveAnomalyExpanded}
                        setActiveExpandedAnomalyId={setActiveExpandedAnomalyId}
                        toggleShowBoundsForActiveAnomaly={toggleShowBoundsForActiveAnomaly}
                        boundsShown={boundsShown} />
                    )
                  }
                  {
                    sourceItemAnomaliesAndClusters.clusters.map((cluster, clusterIndex) =>
                      <AnomalyCluster
                        key={`anomaly-cluster-info-${clusterIndex}`}
                        cluster={cluster}
                        graphConfig={graphConfig}
                        activeAnomalyId={activeAnomalyId}
                        isActiveAnomalyExpanded={isActiveAnomalyExpanded}
                        setActiveExpandedAnomalyId={setActiveExpandedAnomalyId}
                        toggleShowBoundsForActiveAnomaly={toggleShowBoundsForActiveAnomaly}
                        boundsShown={boundsShown} />
                    )
                  }
                </g>
              )
            })
          }
        </g>
      </g>
    )
  }

  constructor(props) {
    super(props)
    this.id = globalSequence.next()
  }
}

AnomalyLayer.propTypes = {
  graphConfig: PropTypes.object.isRequired,
  anomalyMarkersAndClusters: PropTypes.array.isRequired,
  displayAnomalies: PropTypes.bool.isRequired,
  activeAnomalyId: PropTypes.number.isRequired,
  isActiveAnomalyExpanded: PropTypes.bool.isRequired,
  setActiveExpandedAnomalyId: PropTypes.func.isRequired,
  toggleShowBoundsForActiveAnomaly: PropTypes.func.isRequired,
  boundsShown: PropTypes.bool.isRequired,
}

AnomalyLayer.defaultProps = {
  displayAnomalies: true,
  activeAnomalyId: 0,
  isActiveAnomalyExpanded: false,
  setActiveExpandedAnomalyId: noop,
  toggleShowBoundsForActiveAnomaly: noop,
  boundsShown: false,
}

export default AnomalyLayer
