import React from 'react'

import { get } from 'lodash/fp/object'
import { compose } from 'redux'
import { area } from 'd3-shape'
import { createSelector } from 'reselect'
import { withTranslation } from 'react-i18next'

import GraphContext from '@@src/components/graphs/graph_context'
import AsyncResult from '@@src/utils/async_result'
import histogramDataContainer from './histogram_data_container'

import {
  globalSequence,
} from '@@src/utils'

import {
  MINIMUM_INTERVAL_MILLISECONDS,
} from './histogram_data_container'

import {
  HISTOGRAM_TYPE_EVENTS,
} from '../../constants'

import HistogramTile from './histogram_tile'

function HistogramLayer(props) {
  return (
    <GraphContext.Consumer>
      {config => (
        <GraphHistogram
          graphConfig={config}
          {...props} />
      )}
    </GraphContext.Consumer>

  )
}

function AreaPlot(props) {
  return (
    <path onMouseOver={props.onMouseOver} onMouseOut={props.onMouseOut} fill={props.color} stroke="none" {...props} />
  )
}

class GraphHistogram extends React.PureComponent {
  static defaultProps = {
    histogramOption: HISTOGRAM_TYPE_EVENTS,
    alertsResult: AsyncResult.success({ data: [] }),
    listRawDataRequestsResult: AsyncResult.success({ rawDataRequests: [] }),
    anomalyData: AsyncResult.success({
      formatedAnomalyData: [],
    }),
    onChangeTimeRange: () => null,
    pressureEvents: AsyncResult.success([]),
  }

  render() {
    const { graphConfig, t, histogramOption, intervals, target = true } = this.props

    const clipPathId = `histogram-layer-clip-path-${this.id}`
    const totalHeight = this.selectTotalHeight(this.props)
    const tilesAreaGenerator = this.selectCalculateAreaPath(this.props)
    const textCoords = this.selectTextCoordinates(this.props)

    return (
      <g name={'histogram-layer'}>
        <defs>
          <clipPath id={clipPathId}>
            <rect
              x={graphConfig.leftPadding}
              y={10}
              width={graphConfig.plotAreaWidth}
              height={totalHeight} />
          </clipPath>
        </defs>

        <g name="histogram-area-layer">
          <g name={'histogram-tiles-layer-shadow'} ref={this.intervalRef}>
            {
              intervals.length ?
                intervals.map((interval, index) => {
                  return (
                    <AreaPlot
                      name="shadow"
                      id={'shadow-' + index}
                      key={'shadow-key-' + index}
                      clipPath={`url(#${clipPathId})`}
                      opacity={0}
                      d={tilesAreaGenerator(interval.segmentStart, interval.segmentEnd, 10, totalHeight, 0)}
                      color={'var(--secondary)'}
                    />)
                })
                :
                <text {...textCoords} backgroundColor="red" textAnchor="middle">
                  {t('text.histogram_zoomed_in', { value: MINIMUM_INTERVAL_MILLISECONDS })}
                </text>
            }
          </g>

          <g name={'histogram-tiles-layer-tiles'} ref={this.tileRef}>
            {
              intervals.map((interval, index) => {
                return (
                  <HistogramTile
                    key={'histogram-tiles-' + index}
                    handleOnMouseClick={this.handleOnMouseClick}
                    handleOnMouseOver={this.handleOnMouseOver}
                    handleOnMouseOut={this.handleOnMouseOut}
                    tilesAreaGenerator={tilesAreaGenerator}
                    histogramOption={histogramOption}
                    interval={interval}
                    clipPathId={clipPathId}
                    index={index}
                    target={target}
                  />
                )
              })
            }
          </g>
        </g>
      </g>
    )
  }

  // find the node and change style on mouse over and mouse out
  // change time range if click and return to default style
  // change i to max size to stop the iterating
  handleOnMouseClick = (id, interval) => {
    const { onChangeTimeRange } = this.props
    return () => {
      for (let i = 0; i < this.tileRef.current.children.length; i++) {
        if (this.tileRef.current.children[i].id === ('tile-' + id)) {
          this.intervalRef.current.children[id].style.opacity = 0
          this.tileRef.current.children[i].style.opacity = 0
        }
      }
      onChangeTimeRange({ startTime: new Date(interval.segmentStart), endTime: new Date(interval.segmentEnd) })
    }
  }

  handleOnMouseOver = (id) => {
    return () => {
      for (let i = 0; i < this.tileRef.current.children.length; i++) {
        if (this.tileRef.current.children[i].id === ('tile-' + id)) {
          this.intervalRef.current.children[id].style.opacity = 0.2
          this.tileRef.current.children[i].style.opacity = 1
        }
      }
    }
  }

  handleOnMouseOut = (id) => {
    return () => {
      for (let i = 0; i < this.tileRef.current.children.length; i++) {
        if (this.tileRef.current.children[i].id === ('tile-' + id)) {
          this.intervalRef.current.children[id].style.opacity = 0
          this.tileRef.current.children[i].style.opacity = 0.33
        }
      }
    }
  }

  // makes a path for provided parametars (g tag)
  selectCalculateAreaPath = createSelector(
    [get('graphConfig')],
    graphConfig =>
      (timeStart, timeEnd, top, bottom, padding) => {
        return area()
          .x(d => (d.time))
          .y0(d => (d.min))
          .y1(d => (d.max))(
            [
              { time: graphConfig.xScale(timeStart) + padding, min: top, max: bottom },
              { time: graphConfig.xScale(timeEnd) - padding, min: top, max: bottom },
            ]
          )
      }
  )

  selectTotalHeight = createSelector(
    [get('graphConfig')],
    ({ plotAreaHeight, topPadding }) => {
      return plotAreaHeight + topPadding
    }
  )

  selectTextCoordinates = createSelector(
    [get('graphConfig')],
    ({ plotAreaWidth, leftPadding }) => {
      return {
        x: plotAreaWidth / 2 + leftPadding,
        y: 70,
      }
    }
  )

  constructor(props) {
    super(props)

    this.id = globalSequence.next()
    // references to parts of the graph we use to get items
    this.intervalRef = React.createRef()
    this.tileRef = React.createRef()
  }
}

export default compose(
  histogramDataContainer,
  withTranslation([
    'src/analysis_path/pressure_analysis_path/pressure_subset_graph/histogram_layer', // eslint-disable-line max-len
  ]),
)(HistogramLayer)
