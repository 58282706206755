import React from 'react'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import { NavLink } from 'react-router-dom'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import { Alert, Card, Form } from 'reactstrap'

import * as analytics from '@@src/analytics'
import routes from '@@src/routes'
import AppLayout from '@@src/components/app_layout'
import ErrorInfo from '@@src/components/error_info'
import AppFormGroup from '@@src/components/forms/app_form_group'
import SubmitButton from '@@src/components/buttons/submit_button'
import PureComponentWithAutofocus from '@@src/components/pure_component_with_autofocus'
import { AppError, AsyncResult, FormFields } from '@@src/utils'
import { withAuthorization } from '../../_v2/contexts/authorization/authorization.context'

import styles from './enter_recovery_code_page.css'

class EnterRecoveryCodePage extends PureComponentWithAutofocus {
  static defaultProps = {
    debounce: 500,
  }

  static propTypes = {
    debounce: PropTypes.number.isRequired,
  }

  render() {
    const { t } = this.props
    const { result, invalidMagicToken } = this.state

    return (
      <AppLayout
        title={t('headings.page_title')}
        footerColor="dark"
        backgroundClassname={styles.background}
      >
        <Card className={styles['login-form-card']}>
          <h2 className={styles.heading}>
            {t('headings.enter_recovery_code')}
          </h2>

          <p>{t('text.enter_recovery_code_text')}</p>

          {result.wasSuccessful() ? (
            <div>
              <Alert color="success">
                {t('text.update_password_successful')}
              </Alert>
            </div>
          ) : (
            <Form onSubmit={this.onSubmitResetPassword}>
              {result.wasFailure() ? <ErrorInfo error={result.error} /> : null}
              {invalidMagicToken ? (
                <Alert color="danger">{t('errors.invalid_magic_token')}</Alert>
              ) : null}

              <AppFormGroup
                id="email"
                name="email"
                type="email"
                label={t('labels.email')}
                value={this.selectFieldValue('email')}
                onChange={this.formFields.onChangeHandlerFor('email')}
                errorText={this.selectFieldErrorText('email')}
              />

              <AppFormGroup
                id="recovery-code"
                name="recovery-code"
                type="text"
                label={t('labels.recovery_code')}
                value={this.selectFieldValue('recoveryCode')}
                innerRef={this.getAutofocusTargetRef()}
                onChange={this.formFields.onChangeHandlerFor('recoveryCode')}
                errorText={this.selectFieldErrorText('recoveryCode')}
              />

              <AppFormGroup
                id="new-password"
                name="new-password"
                type="password"
                label={t('labels.new_password')}
                value={this.selectFieldValue('newPassword')}
                onChange={this.formFields.onChangeHandlerFor('newPassword')}
                errorText={this.selectFieldErrorText('newPassword')}
              />

              <AppFormGroup
                id="new-password-confirmation"
                name="new-password-confirmation"
                type="password"
                label={t('labels.new_password_confirmation')}
                value={this.selectFieldValue('newPasswordConfirmation')}
                onChange={this.formFields.onChangeHandlerFor(
                  'newPasswordConfirmation'
                )}
                errorText={this.selectFieldErrorText('newPasswordConfirmation')}
              />

              <SubmitButton
                name="update-password-button"
                buttonStatus=""
                color="primary"
                onSubmitForm={this.onSubmitResetPassword}
                result={this.state.result}
                disabled={this.formFields.hasAnyValidationErrors()}
                className={styles['update-password-button']}
                submitText={t('buttons.update_password')}
              ></SubmitButton>
            </Form>
          )}

          <NavLink
            to={routes.loginPath()}
            name="back-to-login-button"
            className="btn btn-link btn-block"
          >
            {t('buttons.return_to_login')}
          </NavLink>
        </Card>
      </AppLayout>
    )
  }

  constructor(props) {
    super(props)

    this.formFields = new FormFields(this, 'formFields', {
      email: (v) => (v ? '' : 'errors.required'),
      newPassword: (v) => (v ? '' : 'errors.required'),
      recoveryCode: (v) => (v ? '' : 'errors.required'),
      newPasswordConfirmation: (newPasswordConfirmation, { newPassword }) => {
        if (
          newPasswordConfirmation &&
          newPassword &&
          newPasswordConfirmation !== newPassword
        ) {
          return 'errors.password_confirmation_not_matching'
        } else if (!newPasswordConfirmation) {
          return 'errors.required'
        } else {
          return ''
        }
      },
    })

    this.state = {
      result: AsyncResult.notFound(),
      formFields: this.formFields.initialState(),
      invalidMagicToken: false,
    }
  }

  async componentDidMount() {
    super.componentDidMount()

    const { location } = this.props
    const { magicToken, email: queryStringEmail } = queryString.parse(
      location.search
    )

    if (magicToken) {
      const match = decodeURIComponent(magicToken).match(/^(.+)-([^-]+)$/)
      let email = ''
      let recoveryCode = ''

      try {
        email = atob(match[1])
        recoveryCode = match[2]
      } catch (err) {
        this.setState({ invalidMagicToken: true })
      }

      if (email && recoveryCode) {
        this.setState({ result: AsyncResult.pending() })

        await this.formFields.updateFieldValue('email', email)
        await this.formFields.updateFieldValue('recoveryCode', recoveryCode)
      }
    } else if (queryStringEmail) {
      this.formFields.updateFieldValue('email', queryStringEmail)
    }
  }

  selectFieldValue(fieldName) {
    return this.formFields.selectValue(this.state, fieldName)
  }

  selectFieldErrorText(fieldName) {
    const error = this.formFields.selectError(this.state, fieldName)

    return error ? this.props.t(error) : undefined
  }

  onSubmitResetPassword = async (event) => {
    event.preventDefault()

    this.setState({ result: AsyncResult.pending() })

    try {
      const res = await this.formFields
        .performPreSubmitChecks()
        .catch(() => false)

      if (!res) {
        this.setState({ result: AsyncResult.notFound() })
        return
      }

      const code = this.selectFieldValue('recoveryCode')
      const email = this.selectFieldValue('email')
      const newPassword = this.selectFieldValue('newPassword')

      const identityProvider = this.props.authorization.identityProvider
      await identityProvider.resetPassword(email, code, newPassword)

      this.formFields.resetState()
      this.setState({ result: AsyncResult.success() })
    } catch (err) {
      analytics.logError(err)

      this.setState({ result: AsyncResult.fail(AppError.from(err)) })
    }
  }
}

export default compose(
  withAuthorization,
  withTranslation([
    'src/login_path/password_recovery_path/enter_recovery_code_page',
    'common/forms',
  ])
)(EnterRecoveryCodePage)
