import React from 'react'
import classnames from 'classnames'
import { Button } from 'reactstrap'

import styles from './borderless_button.css'

export default function BorderlessButton(props) {
  return (
    <Button {...props}
      outline
      className={classnames(styles.button, props.className)}/>
  )
}
