import gql from 'graphql-tag'

export const GET_DEVICE_COMMISSIONS = gql`
query GetDeviceCommissions($deviceId: Int!, $onlyCurrent: Boolean) {
  getDeviceCommissions(deviceId: $deviceId, onlyCurrent: $onlyCurrent) {
    count
    items {
      id
      deviceId
      whenCommissioned
      whenDecommissioned
      isCurrent
      latitude
      longitude
      heightSeaLevel
    }
  }
}
`
