import { useCallback, useState } from 'react'
import { useAddNewNetworkAsset } from '../../network_assets/hooks/get_add_new_network_asset'
import useCreateNetworkAssetInstallation from './use_create_network_asset_installation'

const useMutationInstallDevice = (device, callback) => {
  const [installingStatus, setInstallingStatus] = useState(null)
  const [loading, setLoading] = useState(false)
  const { addNewNetworkAsset } = useAddNewNetworkAsset()
  const createInstallation = useCreateNetworkAssetInstallation()

  const installOnExistingNetworkAsset = useCallback(
    (assetId, channel, startDate, endDate) => {
      setLoading(true)
      setInstallingStatus('Adding the instalation...')
      createInstallation({
        networkAssetId: assetId,
        deviceId: +device.deviceId,
        channelMap: { pressure_1: channel },
        start: startDate.toISOString(),
        end: endDate ? endDate.toISOString() : null,
      })
        .then((_result) => {
          setInstallingStatus('Successfully installed')
          setLoading(false)
          callback()
        })
        .catch((error) => {
          setInstallingStatus(
            'Error installing: ' +
              (error.moreInfo ? error.moreInfo : error.message)
          )
          setLoading(false)
          // eslint-disable-next-line no-console
          console.warn('Error creating installation: ', error)
        })
    },
    [device, callback]
  )

  const createNetworkAssetAndInstallDevice = useCallback(
    ({ latitude, longitude, channelType, dateTime, ...rest }) => {
      if (loading) {
        return
      }
      setLoading(true)
      setInstallingStatus('Creating new network asset...')
      addNewNetworkAsset(
        { latitude, longitude, ...rest },
        (status, networkAssetId) => {
          if (status === 'success') {
            installOnExistingNetworkAsset(networkAssetId, channelType, dateTime)
          } else {
            setInstallingStatus('Error creating network asset: ' + status)
            setLoading(false)
            // eslint-disable-next-line no-console
            console.warn('Error adding network asset:', status)
          }
        }
      )
    },
    [device, callback]
  )

  return {
    installOnExistingNetworkAsset,
    createNetworkAssetAndInstallDevice,
    status: installingStatus,
    loading,
  }
}

export default useMutationInstallDevice
