import React from 'react'
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledTooltip,
} from 'reactstrap'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import classnames from 'classnames'
import gql from 'graphql-tag'
import { graphql } from '@apollo/client/react/hoc'

import AsyncResult from '@@src/utils/async_result'
import Alert from '@@src/api/presenters/alert'
import Comment from '@@src/api/presenters/comment'
import * as analytics from '@@src/analytics'
import { sleep } from '@@src/utils'
import userPermissions from '@@src/components/permissions/user_permissions'

const INFLOWNET_WAIT = process.env.NODE_ENV === 'test' ? 0 : 1000

import styles from './change_alert_status_dropdown.css'

class ChangeAlertStatusDropdown extends React.PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    alert: PropTypes.instanceOf(Alert).isRequired,
    updateAlertStatus: PropTypes.func.isRequired,
    updateStatusResult: PropTypes.instanceOf(AsyncResult).isRequired,
    permissions: PropTypes.array.isRequired,
    className: PropTypes.string,
  }

  render() {
    const { t, className, alert, updateStatusResult, permissions } = this.props
    const canEditAlerts = permissions.includes('can_edit_alerts')
    const dropdownId = `change-status-dropdown-${alert.alertId}`

    return (
      <React.Fragment>
        {
          !canEditAlerts ?
            <UncontrolledTooltip
              modifiers={{ flip: { enabled: false } }}
              placement="top"
              target={dropdownId}>
              {t('common/text:permissions.disabled')}
            </UncontrolledTooltip>
            : null
        }
        <div className="d-flex" id={dropdownId}>
          <UncontrolledButtonDropdown className={className}>
            <DropdownToggle
              className={styles['dropdown-toggle-btn']}
              disabled={updateStatusResult.isPending() || !canEditAlerts}
              outline={true}
              caret>
              {
                updateStatusResult.isPending() ?
                  <span className="far fa-spinner-third fa-spin mr-2" /> :
                  <span
                    className={classnames(alert.statusIconClassname, 'mr-2')} />
              }
              {t(`common/text:alerts.${alert.status}_status`)}
            </DropdownToggle>
            <DropdownMenu>
              {
                Alert.STATUSES
                  .filter(status => alert.status !== status)
                  .map(status => (
                    <DropdownItem
                      onClick={this.getHandleClickStatus(status)}
                      key={status}>
                      <span className={classnames(
                        new Alert({ status }).statusIconClassname,
                        'mr-2'
                      )} />
                      {t(`common/text:alerts.${status}_status`)}
                    </DropdownItem>
                  ))
              }
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </div>
      </React.Fragment>
    )
  }

  getHandleClickStatus = newStatus => {
    return async () => {
      const {
        updateAlertStatus, alert, onChangeAlertStatus,
      } = this.props

      try {
        onChangeAlertStatus(AsyncResult.pending({
          alertId: alert.alertId,
        }))

        await updateAlertStatus({
          variables: {
            newStatus,
            alertId: alert.alertId,
            comment:
              Comment.createStatusChangeAutoComment(alert.status, newStatus),
          },
        })

        await sleep(INFLOWNET_WAIT)

        onChangeAlertStatus(AsyncResult.success())
      } catch (err) {
        analytics.logFatalError(err)
        onChangeAlertStatus(AsyncResult.fail(err))
      }
    }
  }

  static UPDATE_STATUS_MUTATION = gql`
    mutation UpdateAlertStatus (
      $alertId: Int!,
      $newStatus: AlertStatus!,
      $comment: String
    ) {
      updateAlertStatus (
        alertId: $alertId,
        newStatus: $newStatus,
        comment: $comment
      )
    }
  `
}

export default compose(
  userPermissions,
  withTranslation([
    'common/text',
  ]),
  graphql(ChangeAlertStatusDropdown.UPDATE_STATUS_MUTATION, {
    name: 'updateAlertStatus',
  }),
)(ChangeAlertStatusDropdown)
