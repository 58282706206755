import React from 'react'
import PropTypes from 'prop-types'
import styles from './index_page.css'
import { Button } from 'reactstrap'

export function DeviceTableHeader({ align, width, order, text }) {
  const { onUpdate, orderBy, orderDir, field } = order || {}
  const iconClassName = orderDir === 'asc' ? 'caret-up' : 'caret-down'
  const isSorted = order && orderBy === field
  const isSortable = !!order

  const updateOrderParams = () => onUpdate(
    field, (orderBy === field && orderDir === 'asc' && 'desc' || 'asc'),
  )

  return (
    <th className={`${styles[`column-${width || '90'}`]} text-${align}`}>
      <Button
        className={`${styles['btn-header']} text-${align || 'left'}`}
        onClick={() => isSortable ? updateOrderParams() : null}
      >
        {text}
        {isSorted && (<i className={`fa fa-${iconClassName} ml-1`}></i>)}
      </Button>
    </th>
  )
}

DeviceTableHeader.propTypes = {
  text: PropTypes.string.isRequired,
  align: PropTypes.string,
  width: PropTypes.string,
  order: PropTypes.shape({
    field: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    orderDir: PropTypes.string.isRequired,
    onUpdate: PropTypes.func.isRequired,
  }),
}
