import gql from 'graphql-tag'
import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { graphql } from '@apollo/client/react/hoc'
import { withTranslation } from 'react-i18next'
import DeviceCommission from '@@src/api/presenters/device_commission'
import SubmitButton from '@@src/components/buttons/submit_button'
import DatetimePicker from '@@src/components/forms/datetime_picker'
import { AsyncResult, formatTimestampForAPI } from '@@src/utils'
import { Alert, Button, Modal, ModalBody, ModalFooter } from 'reactstrap'

import * as analytics from '@@src/analytics'

import styles from './index.css'

class EditInstallationModal extends React.PureComponent {
  static defaultProps = {
    onSuccess: () => {},
  }

  static propTypes = {
    onSuccess: PropTypes.func.isRequired,
    commission: PropTypes.instanceOf(DeviceCommission),
  }

  constructor(props) {
    super(props)
    this.state = this.initialState()
  }

  onToggle = (...args) => {
    const { toggle } = this.props
    this.setState(this.initialState())

    if (toggle) {
      toggle(...args)
    }
  }

  initialState() {
    const { commission } = this.props
    const start =
      commission && commission.currentInstallation
        ? commission.currentInstallation.start
        : null

    return {
      result: AsyncResult.notFound(),
      newDateTime: start ? formatTimestampForAPI(start) : null,
      canSaveChanges: false,
    }
  }

  onSubmitForm = async (event) => {
    event.preventDefault()

    this.setState({ result: AsyncResult.pending() })

    const { commission } = this.props
    const { newDateTime } = this.state
    const networkAssetId = commission.currentInstallation.networkAsset.id
    const start = commission.currentInstallation.start
    const channelMap = commission.currentInstallation.channelMap
    const deviceId = commission.device.id

    try {
      await this.props.mutate({
        variables: {
          deviceId: deviceId,
          networkAssetId: networkAssetId,
          start: formatTimestampForAPI(start),
          channelMap: {
            pressure_1: channelMap.pressure_1,
          },
          newStart: formatTimestampForAPI(newDateTime),
        },
      })

      this.setState({ result: AsyncResult.success() })
      this.onToggle()
      this.props.onSuccess()
    } catch (e) {
      analytics.logError(e)
      this.setState({ result: AsyncResult.fail(e) })
    }
  }

  render() {
    const { isOpen } = this.props

    return !isOpen ? null : (
      <Modal
        isOpen={isOpen}
        toggle={this.onToggle}
        className={styles.container}
      >
        {this.renderStandardModal()}
      </Modal>
    )
  }

  editInstallationDateTimeChange = (newDateTime) => {
    this.setState({
      newDateTime,
      canSaveChanges: true,
    })
  }

  renderStandardModal = () => {
    const { t, commission } = this.props
    const { result } = this.state
    const start = commission.currentInstallation.start

    return (
      <React.Fragment>
        <ModalBody>
          {!result.wasFailure() ? null : (
            <Alert color="danger">{result.error.translateWith(t)}</Alert>
          )}
          <div className={styles['modal-title']}>{t('headings.title')}</div>
          <div className={styles['modal-body']}>{t('text.start')}</div>
          <div className={styles['modal-start-time']}>
            <DatetimePicker
              name="edit-date-picker"
              defaultValue={start}
              dateFormat="DD/MM/YYYY"
              onChange={this.editInstallationDateTimeChange}
            ></DatetimePicker>
          </div>
        </ModalBody>
        <ModalFooter className={styles['buttons-row']}>
          <Button
            name="cancel-button"
            type="button"
            color="secondary"
            onClick={this.onToggle}
          >
            {t('buttons.cancel')}
          </Button>
          <SubmitButton
            buttonStatus=""
            name="save-button"
            color="primary"
            onSubmitForm={this.onSubmitForm}
            disabled={!this.state.canSaveChanges}
            result={result}
            submitText={t('buttons.save')}
          ></SubmitButton>
        </ModalFooter>
      </React.Fragment>
    )
  }

  static EDIT_INSTALLATION = gql`
    mutation EditInstallation(
      $deviceId: Int!
      $networkAssetId: Int!
      $start: String!
      $channelMap: ChannelMappingInput!
      $newStart: String
    ) {
      editInstallation(
        deviceId: $deviceId
        networkAssetId: $networkAssetId
        start: $start
        channelMap: $channelMap
        newStart: $newStart
      )
    }
  `
}

export default compose(
  graphql(EditInstallationModal.EDIT_INSTALLATION),
  withTranslation([
    'src/management_path/devices_path/edit_installation_modal',
    'common/text',
  ])
)(EditInstallationModal)
