import moment from 'moment'

export default class EventPlotCluster {
  constructor(data) {
    Object.assign(this, data)
  }

  withTimezone(timezone) {
    return new EventPlotCluster({
      ...this,
      startLocalized: moment.utc(this.sliceUtcStart).tz(timezone).format(),
      endLocalized: moment.utc(this.sliceUtcEnd).tz(timezone).format(),
    })
  }
}
