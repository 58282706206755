import React from 'react'
import classnames from 'classnames'
import { Link } from 'react-router-dom'
import { createSelector } from 'reselect'
import { withTranslation } from 'react-i18next'
import { Col, Row, UncontrolledTooltip, Button } from 'reactstrap'

import routes from '@@src/routes'
import Device from '@@src/api/presenters/device'
import NetworkAssetIcon from '@@src/components/icons/network_asset_icon'
import DeviceIconWithStatus from '@@src/components/icons/device_icon_with_status'
import { mapAssetTypeToLogicalChannels, isMobile } from '@@src/utils'
import DeviceIssueSeverityBadge from '@@src/components/badges/device_issue_severity_badge'

import styles from './network_asset_list_item.css'

const NO_DEVICE = Device.from({})

class NetworkAssetListItem extends React.PureComponent {
  render() {
    const { t, className, networkAsset, onClick } = this.props
    const mostSevereDeviceIssue = networkAsset.mostSevereDeviceIssue
    const isMobileDevice = isMobile()

    return (
      <li className={classnames(className, styles.container)} onClick={onClick}>
        <Row className={styles.row}>
          <Col className={styles['summary-column']}>
            <div className={styles['icon-subcolumn']}>
              <NetworkAssetIcon
                networkAsset={networkAsset}
                className={styles.icon}
              />
            </div>

            <div className={styles['summary-subcolumn']}>
              <span className="d-flex m-0 flex-column">
                <div>
                  <span className="mr-2">{networkAsset.assetId}</span>
                  {mostSevereDeviceIssue ? (
                    <DeviceIssueSeverityBadge issue={mostSevereDeviceIssue} />
                  ) : null}
                </div>

                {networkAsset.assetName ? (
                  <div>
                    <i>
                      <small className="text-break">
                        {networkAsset.assetName}
                      </small>
                    </i>
                  </div>
                ) : null}

                <small className={styles['network-asset-last-activity']}>
                  {networkAsset.translateLastCommunication(t)}
                </small>
              </span>

              <small className={styles['network-asset-type']}>
                {networkAsset.translateAssetType(t)}
              </small>
            </div>
          </Col>

          <Col className={styles['actions-column']}>
            {this.selectChannelsWithInstallations(this.props).map((values) => {
              const { id, channel, installation, device } = values

              return (
                <div key={channel}>
                  <DeviceIconWithStatus
                    id={id}
                    device={device}
                    className={styles['device-icon']}
                  />

                  {installation ? (
                    <UncontrolledTooltip
                      modifiers={{ flip: { enabled: false } }}
                      placement="left"
                      target={id}
                      className={styles['device-serial-number-tooltip']}
                    >
                      {t(`common/text:text.asset_channel_${channel}`)}:{' '}
                      {device.serialNumber}
                    </UncontrolledTooltip>
                  ) : null}
                </div>
              )
            })}
            {!isMobileDevice ? (
              <Button
                className="mr-2"
                color="light"
                tag={Link}
                to={routes.analysisPressurePath({ d: [networkAsset.uuid] })}
              >
                <i className="fa fa-chart-area" />
              </Button>
            ) : null}
            <Button
              color="light"
              tag={Link}
              to={routes.networkAssetsDetailPath(networkAsset.id)}
            >
              <i className="fa fa-info" />
            </Button>
          </Col>
        </Row>
      </li>
    )
  }

  selectChannelsWithInstallations = createSelector(
    [({ networkAsset }) => networkAsset],
    (networkAsset) => {
      const now = new Date()
      const { assetType } = networkAsset

      return mapAssetTypeToLogicalChannels(assetType).map((channel) => {
        const id = `network-asset-${networkAsset.id}-channel-${channel}`
        const installation = networkAsset.installationForChannel(channel, now)

        const device = installation ? installation.device : NO_DEVICE

        return { id, channel, device, installation }
      })
    },
  )
}

export default withTranslation(['common/text'])(NetworkAssetListItem)
