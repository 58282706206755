import NetworkAssetFields
from '@@src/management_path/network_assets_path/network_asset_fields'
import { mapAssetTypeToLogicalChannels } from '@@src/utils'

export default class CreateAndAssignNetworkAssetFields extends NetworkAssetFields { // eslint-disable-line max-len
  constructor(component, name, validationOverrides) {
    super(component, name, {
      channel: validateChannel,
      startTime: validateStartTime,
      ...validationOverrides,
    })
  }
}

function validateChannel(channel, { assetType }) {
  if (!channel) {
    return 'errors.required'
  }

  const availableChannels = mapAssetTypeToLogicalChannels(assetType)

  return availableChannels.includes(channel) ?
    '' :
    'errors.invalid_channel_for_network_asset'
}

function validateStartTime(date) {
  if (!date) {
    return 'errors.required'
  }

  if (date > new Date()) {
    return 'errors.future_installation'
  }

  return !(date instanceof Date) || isNaN(date.getTime()) ?
    'errors.invalid_date' : ''
}
