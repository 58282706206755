import React from 'react'
import {
  Row,
  Col,
  Button,
  Input,
  Modal,
  ModalBody,
  FormGroup,
} from 'reactstrap'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import gql from 'graphql-tag'
import { graphql } from '@apollo/client/react/hoc'

import NetworkAssetIcon from '@@src/components/icons/network_asset_icon'
import GroupIcon from '@@src/components/icons/group_icon'

import styles from './alert_settings_cascade.css'

class AlertSettingsCascade extends React.PureComponent {

  constructor(props) {
    super(props)

    const settingsCascade = {}

    props.descendants.forEach(data => {
      const { __typename, id } = data
      settingsCascade[id] = {
        checked: false,
        type: __typename,
      }
    })

    this.state = {
      settingsCascade,
      showAll: false,
    }

  }

  render() {
    const { isOpen, descendants, handleToggle, t } = this.props
    const { showAll, settingsCascade } = this.state

    return (
      <Modal isOpen={isOpen} toggle={handleToggle}>
        <ModalBody>
          <Row>
            <Col>
              <div className={`alert-warning ${styles['alert-cascade-box']}`}>
                <div className="mb-2">
                  {t(
                      'text.settings_will_not_be_inherited',
                      { count: descendants.length }
                  )}
                </div>
                <div className="mb-2">
                  <div>
                    {
                      showAll ? (
                        <FormGroup
                          tag="fieldset"
                          name="alert-settings-cascade-form">
                          <GroupIcon
                            className={styles['cascade-icon']}
                          />
                          <label>
                            <Input
                              type="checkbox"
                              className={styles['cascade-checkbox']}
                              name="select-all-checkbox"
                              onChange={() => {
                                this.areAllEnabled()
                                  ? this.changeAllSettings(false)
                                  : this.changeAllSettings(true)
                              }}
                              checked={this.areAllEnabled()}
                            />
                            {t('buttons.select_all')}
                          </label>
                        </FormGroup>
                      ) : (
                        null
                      )
                    }
                  </div>
                  <FormGroup
                    tag="fieldset"
                    className={styles['main-fields']}
                  >

                    {
                      showAll ? (
                        <React.Fragment>
                          {
                            descendants.map((descendant, i) => {
                              return (
                                <React.Fragment key={`${descendant.id}-${i}`}>
                                  <div>
                                    {
                                      descendant.__typename === 'NetworkAsset'
                                        ? (
                                          <NetworkAssetIcon
                                            networkAsset={
                                              {
                                                assetType: descendant.assetType,
                                              }
                                            }
                                            className={styles['cascade-icon']}
                                            isMarker={false}
                                          />
                                        ) : (
                                          <GroupIcon
                                            className={styles['cascade-icon']}
                                          />
                                        )
                                    }
                                    <label className="mb-0">
                                      <Input
                                        type="checkbox"
                                        className={
                                          styles['cascade-checkbox']
                                        }
                                        checked={
                                          this.isItemEnabled(descendant)
                                        }
                                        onChange={(event) => {
                                          this.updateSettingsCascade(event)
                                        }}
                                        name={`checkbox-${descendant.id}`}
                                        data-type={descendant.__typename}
                                        data-id={descendant.id}
                                      />
                                      {
                                        descendant.__typename === 'NetworkAsset'
                                          ? (
                                            descendant.assetId
                                          ) : (
                                            descendant.name
                                          )
                                      }
                                    </label>
                                  </div>
                                </React.Fragment>
                              )
                            })
                          }
                        </React.Fragment>
                      ) : (
                        <Button
                          onClick={this.toggleShowAll}
                          color="link"
                          name="show-all-button"
                        >
                          {t('buttons.show_all')}
                        </Button>
                      )
                    }
                    <div className={styles['footer-button-container']}>
                      <Button
                        color="warning"
                        onClick={this.handleCascadeSubmit}
                        name="force-inheritance-button"
                        disabled={
                          showAll
                            && Object.values(
                              settingsCascade
                            ).every(e => !e.checked)
                        }
                      >
                        {
                          showAll ? (
                            t('buttons.force_inheritance')
                          ) : (
                            t('buttons.force_inheritance_all')
                          )
                        }
                      </Button>
                      <Button
                        name="close-cascade"
                        color="link"
                        onClick={handleToggle}
                        className={styles['close-button']}
                      >
                        {t('buttons.close')}
                      </Button>
                    </div>
                  </FormGroup>
                </div>
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    )
  }

  toggleShowAll = () => {
    this.setState({
      showAll: !this.state.showAll,
    })
  }

  isItemEnabled = (descendant) => {
    return this.state.settingsCascade[descendant.id].checked
  }

  handleCascadeSubmit = async event => {
    const { handleToggle, descendants } = this.props
    const { showAll } = this.state
    event.preventDefault()
    const assembleInheritanceCasade = input => {
      // If show all is enabled, filter the submission by checkbox, otherwise don't
      const filteredInput = Object.keys(input)
        .filter(key => input[key].checked)
      return filteredInput.map(key => {
        const type =
          input[key].type === 'NetworkAsset' ? 'VNetworkAsset' : 'VGroup'
        return {
          aggregateId: Number(key),
          aggregateType: type,
        }
      })
    }

    // If we are showing all, assemble the cascade based on the form, else use descendant array
    const assembledCascade = showAll ? (
      assembleInheritanceCasade(this.state.settingsCascade)
    ) : (
      descendants.map(data => {
        const aggregateType = data.__typename === 'NetworkAsset'
          ? 'VNetworkAsset'
          : 'VGroup'
        return ({ aggregateId: data.id, aggregateType })
      })
    )

    await this.props.bulkModifyAlertThresholds({
      variables: {
        targetAggregate: {
          aggregateId: this.props.groupId,
          // This should always be a group
          aggregateType: 'VGroup',
        },
        aggregateList: assembledCascade,
      },
    })
    handleToggle()
  }

  // Update cascade settings from form event
  updateSettingsCascade = event => {
    event.persist()

    this.setState(prevState => {
      return {
        ...prevState,
        settingsCascade: {
          ...prevState.settingsCascade,
          [event.target.dataset.id]: {
            checked: event.target.checked,
            type: event.target.dataset.type,
          },
        },
      }
    })
  }

  areAllEnabled = () => {
    const { settingsCascade } = this.state
    const res = Object.values(settingsCascade).every(item => item.checked)
    return res
  }

  // Force all checkboxes on
  changeAllSettings = (changeTo) => {
    const { descendants } = this.props
    const completeState = {}

    descendants.forEach(data => {
      const { __typename, id } = data
      completeState[id] = {
        checked: changeTo,
        type: __typename,
      }
    })

    this.setState({
      settingsCascade: completeState,
    })
  }

  static BULK_MODIFY_ALERT_THRESHOLDS = gql`
    mutation BulkUpdateAlertThresholds(
      $aggregateList: [AggregateListItem]!
      $targetAggregate: AggregateListItem!
    ) {
      bulkModifyAlertThresholds(
        aggregateList: $aggregateList,
        targetAggregate: $targetAggregate,
      )
    }
  `
}

export default compose(
  withTranslation([
    'src/components/alerts/alert_settings_form',
  ]),
  graphql(AlertSettingsCascade.BULK_MODIFY_ALERT_THRESHOLDS, {
    name: 'bulkModifyAlertThresholds',
  })
)(AlertSettingsCascade)
