import React, { useState } from 'react'
import {
  Col,
  DropdownMenu,
  DropdownToggle,
  FormFeedback,
  Input,
  InputGroup,
  ButtonDropdown,
} from 'reactstrap'
import { Controller } from 'react-hook-form'
import { COORDINATE_REGEX } from '../../../../../../../management_path/network_assets_path/network_asset_fields'
import { SUPPORTED_COORDS_FORMAT } from '../../../../../../../utils/conversions/coordinate_conversions'
import { validateCoordinates } from '../../../utils/form'

const NetworkAssetListNewNetworkAssetCoordinates = ({
  t,
  control,
  register,
  getValues,
  errorMessage = '',
}) => {
  const [isCoordinateDropdownOpen, setIsCoordinateDropdownOpen] =
    useState(false)
  const toggleIsCoordinateDropdownOpen = () =>
    setIsCoordinateDropdownOpen((oldValue) => !oldValue)
  return (
    <Col sm={12} className="m-0 p-0 mb-3">
      <legend className="h6">{t('text.coordinates')}</legend>
      <InputGroup>
        <Controller
          name={'coordinatesType'}
          control={control}
          render={(fields) => {
            const {
              field: { onChange, ...rest },
            } = fields
            return (
              <ButtonDropdown
                addonType="prepend"
                isOpen={isCoordinateDropdownOpen}
                toggle={toggleIsCoordinateDropdownOpen}
              >
                <DropdownToggle caret>
                  {t(`coords_format.${rest.value}`)}
                </DropdownToggle>
                <DropdownMenu style={{ zIndex: 100000000 }}>
                  {SUPPORTED_COORDS_FORMAT.map((format) => {
                    return (
                      <option
                        className="dropdown-item"
                        key={format}
                        value={format}
                        name={format}
                        onClick={(_e) => {
                          onChange(format)
                          toggleIsCoordinateDropdownOpen()
                        }}
                        {...rest}
                      >
                        {t(`coords_format.${format}`)}
                      </option>
                    )
                  })}
                </DropdownMenu>
              </ButtonDropdown>
            )
          }}
        />

        <Input
          placeholder={t('text.lat_long')}
          className={'rounded-right'}
          {...register('coordinates', {
            validate: {
              value: (value) => {
                const { coordinatesType } = getValues()
                const latLng = value.match(COORDINATE_REGEX)
                if (!latLng) {
                  return false
                }
                const latitude = parseFloat(latLng[1])
                const longitude = parseFloat(latLng[2])
                return validateCoordinates(coordinatesType, latitude, longitude)
                  ? true
                  : t('error_field_text.bad_coordinates')
              },
            },
          })}
        />
        <FormFeedback name="input-error" className={'d-block'}>
          {errorMessage}
        </FormFeedback>
      </InputGroup>
    </Col>
  )
}

export default NetworkAssetListNewNetworkAssetCoordinates
