import moment from 'moment'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import { flow } from 'lodash/fp/util'
import { ApolloError } from '@apollo/client'
import { map, includes } from 'lodash/fp/collection'
import { createSelector } from 'reselect'
import { fromPairs, union } from 'lodash/fp/array'
import { get, pickBy, toPairs } from 'lodash/fp/object'

export * from './constants'
export { default as AppError } from './app_error'
export { default as FormFields } from './form_fields'
export { default as AsyncResult } from './async_result'
export { default as globalSequence } from './global_sequence'
import AppError from './app_error'
import AsyncResult from './async_result'
import { DAYS, HOURS, MINUTES, SECONDS } from './constants'
import NetworkAsset from '@@src/api/presenters/network_asset'

export const STYLING_ATTRIBUTES = ['className', 'style', 'width', 'height']

export const STYLING_PROP_TYPES = flow(
  map(attrib => [attrib, PropTypes.string]),
  fromPairs
)(STYLING_ATTRIBUTES)

export async function sleep(time) {
  return new Promise(resolve => setTimeout(resolve, time))
}

export function isCognitoError(error) {
  return error && error.hasOwnProperty('code') && error.hasOwnProperty('name')
    && error.hasOwnProperty('message')
}

export function extractStylingProps(props) {
  return pickBy((_value, key) => {
    return includes(key)(STYLING_ATTRIBUTES)
  })(props)
}

export function extractNonStylingProps(props) {
  return pickBy((_value, key) => {
    return !includes(key)(STYLING_ATTRIBUTES)
  })(props)
}

export function mergeSearchParams(searchParams, newSearchParams) {
  const searchParamsObject = typeof searchParams === 'string' ?
    queryString.parse(searchParams) : searchParams

  return formatAsQueryString(Object.assign({},
    searchParamsObject,
    newSearchParams
  ))
}

export function parseSearchParams(searchParamsString) {
  return queryString.parse(searchParamsString)
}

export function formatAsQueryString(object) {
  return object ? '?' + queryString.stringify(object) : ''
}

export const createCreateSelectParsedSearchParam = (selectSearch) => {
  const selectParsedSearchParams =
    createSelector([selectSearch], parseSearchParams)

  const selectParsedSearchValuesAsStrings =
    createSelector([selectParsedSearchParams], flow(
      toPairs,
      map(([key, value]) => [key, queryString.stringify({ [key]: value })]),
      fromPairs
    ))

  return (key, defaultValue) => createSelector(
    [(...args) => selectParsedSearchValuesAsStrings(...args)[key]],
    value => queryString.parse(value)[key] || defaultValue
  )
}

export function createSelectGraphQLResult(resultKey, {
  onError = () => { },
  mapResult = a => a,
  bufferData = false,
  nullObject = {},
} = {}) {
  return createSelector(
    [get('error'), get('loading'), get(resultKey)],
    (error, loading, result) => {
      if (error) {
        onError(formatGraphQLError(error, loading, result, resultKey))
      }

      // result is usually present even if the data is loading, assuming a past
      // query was successful
      const data = result ? mapResult(result) : nullObject
      const asyncResult =
        loading ? AsyncResult.pending(bufferData ? data : nullObject) :
        error ? AsyncResult.fail(AppError.from(error), nullObject) :
        result ? AsyncResult.success(data) :
        AsyncResult.notFound(nullObject)

      return asyncResult
    }
  )
}

function formatGraphQLError(error, loading, result, resultKey) {
  if (error instanceof ApolloError) {
    error.message += ` while fetching GraphQL result for "${resultKey}"`

    return error
  } else {
    return error
  }
}

export function formatGraphQLErrorMessage(message) {
  if (typeof message === 'string') {
    return message
  } else {
    switch (true) {
      case (!!message.reason):
        return message.reason

      default:
        return JSON.stringify(message)
    }
  }
}

export function bufferSelectResultData(selector) {
  let hadFirstSuccess = false
  let previousData = null

  return createSelector(
    [selector],
    result => {
      if (result.wasSuccessful()) {
        previousData = result.data
        hadFirstSuccess = true

        return result
      } else if (!hadFirstSuccess) {
        return result
      } else {
        return result.withData(previousData)
      }
    }
  )
}

// full list available here:
// https://github.com/Inflowmatix/inflownet-core/pull/230/files#diff-eaf980f305fefd2260472683feec3b4dR75
export function validGroupCategories(groupType) {
  const allCategories = [
    'area',
    'zone',
    'region',
    'district_metered_area',
    'pressure_managed_area',
    'pressure_managed_zone',
    'water_supply_zone',
    'water_treatment_zone',
    'special_event',
    'trial',
    'personal',
  ]

  const deviceFilterCategories = [
    'area',
    'zone',
    'region',
    'district_metered_area',
    'pressure_managed_area',
    'pressure_managed_zone',
    'water_supply_zone',
    'water_treatment_zone',
  ]

  return allCategories.filter(category => {
    if (deviceFilterCategories.includes(category)
      && groupType !== undefined && groupType === 'devices') {
      return false
    }
    return true
  })
}

export function validRoles() {
  return [
    'viewer',
    'editor',
    'administrator',
    'superuser',
  ]
}

export function validAssetTypes() {
  return [
    'eov',
    'prv',
    'psv',
    'pipe',
    'pump',
    'tank',
    'meter',
    'hydrant',
    'tapping',
    'reservoir',
    'boundary_valve',
    'isolation_valve',
    'flow_control_valve',
    'air_valve',
  ].sort()
}

export function validAssetInstallationTypes() {
  return [
    'devices_on_all_points',
    'device_on_at_least_one_point',
    'no_installed_device',
    'at_least_one_empty_point',
  ].sort()
}

export function validCongruityAssetTypes() {
  return [
    'congruity_fire_hydrant',
    'congruity_wash_out',
    'congruity_tank',
    'congruity_air_valve',
    'congruity_capped_end',
    'congruity_stop_cock',
    'congruity_reservoir',
    'congruity_flow_meter',
    'congruity_district_meter',
    'congruity_revenue_meter',
    'congruity_shared_waste_meter',
    'congruity_strategic_meter',
    'congruity_supply_meter',
    'congruity_waste_meter',
    'congruity_constant_speed_pump',
    'congruity_variable_speed_pump',
    'congruity_pressure_reducing_valve',
    'congruity_pressure_sustaining_valve',
    'congruity_pressure_breaker_valve',
    'congruity_flow_control_valve',
    'congruity_check_valve',
    'congruity_throttle_control_valve',
    'congruity_general_purpose_valve',
  ].sort()
}

export const mapCongruityAssetType = (congruityType) => {
  switch (congruityType) {
    case 'congruity_fire_hydrant':
      return 'hydrant'
    case 'congruity_pressure_reducing_valve':
      return 'prv'
    case 'congruity_pressure_sustaining_valve':
      return 'psv'
    default:
      return congruityType.replace(NetworkAsset.CONGRUITY_TYPE_PREFIX, '')
  }
}

export const mapInflownetAssetType = (inflownetType) => {
  switch (inflownetType) {
    case 'hydrant':
      return 'congruity_fire_hydrant'
    case 'prv':
      return 'congruity_pressure_reducing_valve'
    case 'psv':
      return 'congruity_pressure_sustaining_valve'
    default:
      const allCongruityTypes = validCongruityAssetTypes()
      const congruityType = allCongruityTypes.find(
        t => t === `${NetworkAsset.CONGRUITY_TYPE_PREFIX}${inflownetType}`
      )

      if (congruityType) {
        return congruityType
      }

      return ''
  }
}

export function allValidDedupedAssetTypes() {
  return validCongruityAssetTypes().reduce((carry, congruityType) => {
    const inflownetCongruityType = mapCongruityAssetType(congruityType)

    if (!carry.includes(inflownetCongruityType)) {
      carry.push(congruityType)
    }

    return carry
  }, validAssetTypes().concat(validAssetInstallationTypes()))
    .sort((typeA, typeB) => {
      return typeA.replace(NetworkAsset.CONGRUITY_TYPE_PREFIX, '')
        .localeCompare(typeB.replace(NetworkAsset.CONGRUITY_TYPE_PREFIX, ''))
    })
}

export function allInstallationAssetTypes() {
  return
}

export function mapAssetTypeToLogicalChannels(type) {
  switch (type) {
    case 'pipe':
    case 'hydrant':
    case 'tapping':

    case 'congruity_air_valve':
    case 'congruity_capped_end':
    case 'congruity_fire_hydrant':
    case 'congruity_junction':
    case 'congruity_reservoir':
    case 'congruity_stop_cock':
    case 'congruity_tank':
    case 'congruity_wash_out':
      return ['point']

    case 'eov':
    case 'pump':
    case 'tank':
    case 'reservoir':
    case 'boundary_valve':
    case 'isolation_valve':
    case 'flow_control_valve':
    case 'air_valve':

    case 'congruity_check_valve':
    case 'congruity_constant_speed_pump':
    case 'congruity_district_meter':
    case 'congruity_flow_control_valve':
    case 'congruity_flow_meter':
    case 'congruity_general_purpose_valve':
    case 'congruity_pressure_breaker_valve':
    case 'congruity_pressure_reducing_valve':
    case 'congruity_pressure_sustaining_valve':
    case 'congruity_revenue_meter':
    case 'congruity_shared_waste_meter':
    case 'congruity_strategic_meter':
    case 'congruity_supply_meter':
    case 'congruity_throttle_control_valve':
    case 'congruity_variable_speed_pump':
    case 'congruity_waste_meter':
      return ['inlet', 'outlet']

    case 'meter':
      return ['upstream', 'downstream']

    case 'prv':
    case 'psv':
      return ['inlet', 'outlet', 'control_space']

    default:
      throw new Error(`Unsupported asset type: "${type}"`)
  }
}

export function getMappedCongruityTypes(types) {
  return types.reduce((carry, filter) => {
    const congruityType = mapInflownetAssetType(filter)

    if (congruityType) {
      carry.push(congruityType)
    }

    carry.push(filter)

    return carry
  }, [])
}

export function formatTimestampForAPI(timestamp) {
  return moment.utc(timestamp).utcOffset(0)
    .format('YYYY-MM-DD HH:mm:ss.SSS') + '000Z'
}

export const selectDateFormatter = createSelector(
  [timezone => timezone],
  timezone => {
    const locale = getLocaleForTz(timezone)

    const formatter = (date, format) => {
      moment.locale(locale)
      return moment(date).tz(timezone).format(format)
    }
    formatter.locale = locale

    return formatter
  }
)

export function isValidTimezone(timezone) {
  return moment.tz.zone(timezone) !== null
}

export const DEFAULT_TIMEZONE = moment.tz.guess()

export const AVAILABLE_TIME_ZONES = union([DEFAULT_TIMEZONE], [
  'UTC',
  'Asia/Shanghai',
  'Europe/London',
  'Australia/Sydney',
  'Australia/Perth',
  'America/New_York',
  'America/Los_Angeles',
  'Australia/Adelaide',
  'Pacific/Guadalcanal',
  'Pacific/Fiji',
  'Pacific/Auckland',
  'Pacific/Palau',
  'Asia/Singapore',
  'Asia/Bangkok',
  'Asia/Dhaka',
  'Asia/Kolkata',
  'Asia/Karachi',
  'Asia/Kabul',
  'Asia/Tbilisi',
  'Asia/Tehran',
  'Asia/Tokyo',
  'Europe/Moscow',
  'Europe/Paris',
  'Europe/Dublin',
  'America/Argentina/Buenos_Aires',
  'America/Puerto_Rico',
  'America/Chicago',
  'America/El_Salvador',
  'America/Anchorage',
  'Atlantic/Cape_Verde',
  'Atlantic/South_Georgia',
  'Pacific/Gambier',
  'America/Adak',
  'Pacific/Honolulu',
  'Pacific/Pago_Pago',
]).sort((a, b) => {
  return moment().tz(a).utcOffset() - moment().tz(b).utcOffset()
})

export function getLocaleForTz(timezone) {
  switch(timezone) {
    case 'UTC': return 'en-GB'
    case 'Asia/Shanghai': return 'en-US'
    case 'Europe/London': return 'en-GB'
    case 'Australia/Sydney': return 'en-AU'
    case 'Australia/Perth': return 'en-AU'
    case 'America/New_York': return 'en-US'
    case 'America/Los_Angeles': return 'en-US'
    default: return 'en-GB'
  }
}

export function timezoneAbbreviation(timezone) {
  return moment.tz.zone(timezone).abbr((new Date()).getTime())
}

export function formatEventDuration({ start, end }) {
  const duration = Number(end) - Number(start)
  const hours = Math.floor(duration / HOURS)

  const remainder = duration - hours * HOURS
  const minutes = Math.floor(remainder / MINUTES)

  const remainder2 = remainder - minutes * MINUTES
  const seconds = Math.floor(remainder2 / SECONDS)

  const formattedHours = String(hours).padStart(2, '0')
  const formattedMinutes = String(minutes).padStart(2, '0')
  const formattedSeconds = String(seconds).padStart(2, '0')
  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`
}

export function formatRawDataDuration({ start, end }) {
  return formatEventDuration({ start, end })
}

export function isValidCoordinate({
  latitude,
  longitude,
  lat = latitude,
  lng = longitude,
}) {
  return Math.abs(lat) <= 90 && Math.abs(lng) <= 180
}

export function getResultString(t, pagedResult, resultsPerPage) {
  if (pagedResult.isPending()) {
    return null
  }
  const start = ((pagedResult.data.pageNumber * resultsPerPage) -
    resultsPerPage) + 1
  let end = pagedResult.data.pageNumber * resultsPerPage
  if (end > pagedResult.data.totalResults) {
    end = pagedResult.data.totalResults
  }

  if (pagedResult.data.totalResults === 0) {
    return t('common/text:text.zero_paged_results', {
      count: pagedResult.data.totalResults,
    })
  } else {
    return t('common/text:text.paged_results', {
      start: Math.min(start, end),
      end,
      count: pagedResult.data.totalResults,
    })
  }
}

export function translateLastCommunication(
  t,
  lastCommunicationDate,
  isCommunicating
) {
  if (lastCommunicationDate === null) {
    return '-'
  } else if (isCommunicating) {
    return t('common/text:text.is_communicating')
  }

  const timestamp = (new Date()).getTime() - lastCommunicationDate.getTime()

  if (timestamp > 3 * DAYS) {
    return t('common/text:text.last_contact_in_days', {
      count: Math.floor(timestamp / DAYS),
    })
  } else if (timestamp >= 1 * HOURS) {
    return t('common/text:text.last_contact_in_hours', {
      count: Math.floor(timestamp / HOURS),
    })
  } else {
    return t('common/text:text.last_contact_in_minutes', {
      count: Math.floor(timestamp / MINUTES),
    })
  }
}

export function translateNextCommunication(
  t,
  nextCommunication,
  isCommunicating
) {
  if (!moment.isMoment(nextCommunication) || !nextCommunication.isValid()) {
    return '-'
  } else if (isCommunicating) {
    return t('common/text:text.is_communicating')
  }

  const timeNow = moment()
  const nextCommunicationDuration =
    moment.duration(nextCommunication.diff(timeNow))
  const timestamp = nextCommunicationDuration.asMilliseconds()

  if (timestamp > 3 * DAYS) {
    return t('common/text:text.next_contact_in_days', {
      count: Math.floor(timestamp / DAYS),
    })
  } else if (timestamp >= 1 * HOURS) {
    return t('common/text:text.next_contact_in_hours', {
      count: Math.floor(timestamp / HOURS),
    })
  } else {
    return t('common/text:text.next_contact_in_minutes', {
      count: Math.ceil(timestamp / MINUTES),
    })
  }
}

export function translateReportedTimeAgo(t, lastCommunicationDate) {
  if (lastCommunicationDate === null) {
    return '-'
  }

  const timestamp = (new Date()).getTime() - lastCommunicationDate.getTime()

  if (timestamp > 3 * DAYS) {
    return t('common/text:text.reported_days_ago', {
      count: Math.floor(timestamp / DAYS),
    })
  } else if (timestamp >= 1 * HOURS) {
    return t('common/text:text.reported_hours_ago', {
      count: Math.floor(timestamp / HOURS),
    })
  } else {
    return t('common/text:text.reported_minutes_ago', {
      count: Math.floor(timestamp / MINUTES),
    })
  }
}

export function convertMsToTime(milliseconds) {
  let seconds = Math.floor(milliseconds / 1000)
  let minutes = Math.floor(seconds / 60)
  let hours = Math.floor(minutes / 60)

  seconds %= 60
  minutes %= 60
  hours %= 24

  return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}:${padTo2Digits(seconds)}`
}

export function getDifferenceInTime(start, end) {
  // get total seconds between the times
  let delta = Math.abs(end - start) / 1000

  // calculate (and subtract) whole days
  const days = Math.floor(delta / 86400)
  delta -= days * 86400

  // calculate (and subtract) whole hours
  const hours = Math.floor(delta / 3600) % 24
  delta -= hours * 3600

  // calculate (and subtract) whole minutes
  const minutes = Math.floor(delta / 60) % 60
  delta -= minutes * 60

  // what's left is seconds
  // in theory the modulus is not required
  const seconds = delta % 60

  return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}:${padTo2Digits(seconds)}`
}

export function isMobile() {
  try {
    const mql = window.matchMedia('(max-width: 992px)')
    return mql.matches
  } catch (err) {
    return false
  }
}

function padTo2Digits(num) {
  return num.toString().padStart(2, '0')
}
