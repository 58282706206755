import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import styles from '../../../components/icons/device_icon_with_status.css'

const DevicesIconComponent = ({ type }) => {
  return (<span style={{ width: '2rem', height: '2rem' }} className={classnames('d-block mr-3', styles[type])} />)
}

DevicesIconComponent.propTypes = {
  type: PropTypes.string.isRequired,
}

export default DevicesIconComponent
