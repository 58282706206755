import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import {
  Pagination,
  PaginationItem,
  PaginationLink,
  FormGroup,
  Input,
  Label,
  Row,
  Col,
} from 'reactstrap'
import { withTranslation } from 'react-i18next'

import styles from './pagination_toolbar.css'

class PaginationToolbar extends PureComponent {
  static propTypes = {
    pageNumber: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired,
    totalResults: PropTypes.number.isRequired,
    visiblePageOptionCount: PropTypes.number.isRequired,
    setPageNumber: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    resultsPerPageOptionValues: PropTypes.arrayOf(PropTypes.number).isRequired,
    firstPaginationLink: PropTypes.node,
    lastPaginationLink: PropTypes.node,
    setResultsPerPage: PropTypes.func,
    resultsPerPage: PropTypes.number,
    className: PropTypes.string,
  }

  static defaultProps = {
    pageNumber: 1,
    totalPages: 1,
    resultsPerPageOptionValues: [10, 25, 50, 100],
    visiblePageOptionCount: 3,
  }

  render() {
    const {
      t,
      resultsPerPageOptionValues,
      resultsPerPage,
      totalPages,
      setResultsPerPage,
      className,
      totalResults,
    } = this.props

    return (
      <Row className={className} noGutters={true}>
        <Col>{totalPages > 1 ? this.renderPaginationButtons() : null}</Col>
        {typeof setResultsPerPage === 'function' && totalResults > 10 ? (
          <Col xs="auto">
            <FormGroup className="d-flex align-items-center">
              <Label
                className={styles['results-per-page-label']}
                for="results-per-page"
              >
                {t('text.per_page')}
              </Label>
              <Input
                onChange={this.handleResultsPerPageChange}
                value={resultsPerPage}
                type="select"
                name="results-per-page"
                id="results-per-page"
              >
                {resultsPerPageOptionValues.map((optionValue, i) => (
                  <option value={optionValue} key={`${optionValue}-${i}`}>
                    {optionValue}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>
        ) : null}
      </Row>
    )
  }

  handleResultsPerPageChange = (ev) => {
    const { setResultsPerPage, setPageNumber } = this.props

    setPageNumber(1)
    setResultsPerPage(Number(ev.target.value))
  }

  renderPaginationButtons = () => {
    const {
      t,
      pageNumber,
      totalPages,
      visiblePageOptionCount,
      firstPaginationLink,
      lastPaginationLink,
    } = this.props
    const firstOption =
      Math.floor((pageNumber - 1) / visiblePageOptionCount) *
        visiblePageOptionCount +
      1
    const numberedOptions = []

    for (let i = 0; i < visiblePageOptionCount; i++) {
      const optionValue = firstOption + i

      if (optionValue > totalPages) {
        break
      }

      numberedOptions.push(
        <PaginationItem
          key={`${optionValue}-${i}`}
          name="page-option"
          active={pageNumber === optionValue}
        >
          <PaginationLink
            aria-label={t('text.results_page', { page: optionValue })}
            onClick={this.createOnClickPaginate(optionValue)}
            tag="button"
          >
            {optionValue}
          </PaginationLink>
        </PaginationItem>
      )
    }

    return (
      <Pagination
        className={styles.pagination}
        aria-label={t('text.paginate_results')}
      >
        {totalPages > visiblePageOptionCount ? (
          <PaginationItem name="first-page-option" disabled={pageNumber === 1}>
            <PaginationLink
              aria-label={t('text.previous_results')}
              first={true}
              tag="button"
              onClick={this.createOnClickPaginate(1)}
            >
              {firstPaginationLink}
            </PaginationLink>
          </PaginationItem>
        ) : null}
        {totalPages > 1 ? (
          <PaginationItem
            name="previous-page-option"
            disabled={pageNumber === 1}
          >
            <PaginationLink
              onClick={this.createOnClickPaginate(pageNumber - 1)}
              tag="button"
              previous={true}
            />
          </PaginationItem>
        ) : null}
        {numberedOptions}
        {totalPages > 1 ? (
          <PaginationItem
            name="next-page-option"
            disabled={pageNumber === totalPages}
          >
            <PaginationLink
              onClick={this.createOnClickPaginate(pageNumber + 1)}
              tag="button"
              next={true}
            />
          </PaginationItem>
        ) : null}
        {totalPages > visiblePageOptionCount ? (
          <PaginationItem
            name="last-page-option"
            disabled={pageNumber === totalPages}
          >
            <PaginationLink
              aria-label={t('text.next_results')}
              last={true}
              tag="button"
              onClick={this.createOnClickPaginate(totalPages)}
            >
              {lastPaginationLink}
            </PaginationLink>
          </PaginationItem>
        ) : null}
      </Pagination>
    )
  }

  createOnClickPaginate = (pageNumber) => {
    const { setPageNumber } = this.props

    return () => setPageNumber(pageNumber)
  }
}

export default withTranslation([
  'src/components/pagination/pagination_toolbar',
])(PaginationToolbar)
