import gql from 'graphql-tag'
import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { get } from 'lodash/fp/object'
import { noop } from 'lodash/fp/util'
import { partition } from 'lodash/fp/collection'
import { uniqBy } from 'lodash/fp/array'
import { compose } from 'redux'
import { graphql } from '@apollo/client/react/hoc'
import { NavLink, Link } from 'react-router-dom'
import { createSelector } from 'reselect'
import { withTranslation } from 'react-i18next'
import {
  Col,
  Row,
  Card,
  Button,
  ButtonDropdown,
  Breadcrumb,
  DropdownToggle,
  BreadcrumbItem,
  DropdownMenu,
  DropdownItem,
  CardBody,
} from 'reactstrap'
import { Redirect } from 'react-router-dom'
import MarkerClusterGroup from 'react-leaflet-markercluster'
import L from 'leaflet'
import ReactTooltip from 'react-tooltip'

import * as analytics from '@@src/analytics'
import routes from '@@src/routes'
import AppButton from '@@src/components/buttons/app_button'
import AppLayout from '@@src/components/app_layout'
import withModals from '@@src/components/modals/with_modals'
import DeviceIssue from '@@src/api/presenters/device_issue'
import StandardMap from '@@src/components/maps/standard_map'
import AsyncResult from '@@src/utils/async_result'
import requiresLogin from '@@src/components/security/requires_login'
import transformProps from '@@src/components/transform_props'
import MuteAlertsModal from '@@src/components/modals/mute_alerts_modal/mute_alerts_modal'
import AsyncResultSwitch from '@@src/components/async_result_switch'
import NetworkAssetIcon from '@@src/components/icons/network_asset_icon'
import NetworkAssetPopup from '@@src/components/maps/markers/network_asset_popup'
import NetworkAssetMarker from '@@src/components/maps/markers/network_asset_marker'
import CollapsibleAlertsSection from './network_asset_detail_page/collapsible_alerts_section'
import DeleteInstallationPrompt from '@@src/components/modals/delete_installation_prompt'
import AddGroupToNetworkAssetModal from './add_group_to_network_asset_modal'
import CurrentInstallationsSection from './network_asset_detail_page/current_installations_section'
import AddDeviceToNetworkAssetModal from './network_asset_detail_page/add_device_to_network_asset_modal'
import { parseGraphQLResult } from '@@src/api/presenters'
import { createSelectGraphQLResult } from '@@src/utils'
import RemoveNetworkAssetModal from '@@src/management_path/network_assets_path/remove_network_asset_modal'
import withGoogleMapsProvider from '@@src/components/maps/google_maps_provider'
import { withWindowSizes } from '../../components/window_size_provider'
import UninstallDeviceModal from '@@src/components/modals/uninstall_device_modal/uninstall_device_modal'
import DeviceIssueAlert from '@@src/components/alerts/device_issue_alert'
import userPermissions from '@@src/components/permissions/user_permissions'
import AppSettingsConsumer from '@@src/components/app_settings_consumer'
import {
  convertUnitFromDB,
  DISTANCE_TYPE,
} from '@@src/utils/app_unit_conversion'

import styles from './network_asset_detail_page/index.css'

const MuteAlertsForAssetModal = graphql(
  gql`
    mutation MuteAlertsForAsset(
      $networkAssetId: Int!
      $startTime: DateString!
      $endTime: DateString!
    ) {
      muteAlertsForAsset(
        networkAssetId: $networkAssetId
        startTime: $startTime
        endTime: $endTime
      )
    }
  `,
  { name: 'muteAlerts' }
)(MuteAlertsModal)

class NetworkAssetDetailPage extends React.PureComponent {
  static propTypes = {
    handleUninstallDeviceSuccess: PropTypes.func.isRequired,
    googleMaps: PropTypes.object,
    distanceUnits: PropTypes.string.isRequired,
  }

  static defaultProps = {
    handleUninstallDeviceSuccess: noop,
  }

  render() {
    const { t, networkAssetResult, googleMaps, assetIdentifier, isMobile } =
      this.props
    const networkAsset = networkAssetResult.wasSuccessful()
      ? networkAssetResult.data
      : null

    return (
      <AppLayout
        title={t('headings.page_title', { assetId: assetIdentifier })}
        contentsStyle="fixed-at-full-height"
      >
        <div className={classnames(styles.container, 'container-fluid')}>
          <Row className={styles.row}>
            <Col sm="12" lg="6" className={styles['detail-section']}>
              <Breadcrumb>
                <BreadcrumbItem>
                  <span>{t('buttons.management')}</span>
                </BreadcrumbItem>

                <BreadcrumbItem>
                  <NavLink to={routes.managementNetworkAssetsPath()}>
                    {t('buttons.management_network_assets')}
                  </NavLink>
                </BreadcrumbItem>

                <BreadcrumbItem active>
                  {t('text.network_asset_details')}
                </BreadcrumbItem>
              </Breadcrumb>

              <AsyncResultSwitch
                result={networkAssetResult}
                renderFailResult={this.renderSourceNotFound}
                renderNotFoundResult={this.renderSourceNotFound}
                renderSuccessResult={this.renderNetworkAssetDetail}
              />
            </Col>

            {!isMobile && networkAssetResult.wasSuccessful() ? (
              <StandardMap
                center={this.selectMapCenter(this.props, this.state)}
                className="col-sm-6 p-0"
              >
                <MarkerClusterGroup
                  showCoverageOnHover={false}
                  removeOutsideVisibleBounds={true}
                >
                  {networkAsset ? (
                    <NetworkAssetMarker
                      networkAsset={networkAsset}
                      isPopupOpen={true}
                    >
                      <NetworkAssetPopup
                        googleMaps={googleMaps}
                        networkAsset={networkAsset}
                        showDetailsLink={false}
                      />
                    </NetworkAssetMarker>
                  ) : null}
                </MarkerClusterGroup>
              </StandardMap>
            ) : null}
          </Row>
        </div>
      </AppLayout>
    )
  }

  renderSourceNotFound = () => {
    const { t } = this.props

    return (
      <React.Fragment>
        <h1 className="text-danger text-center ">
          {t('text.source_not_found')}
        </h1>
        <p className="muted text-center pt-2">
          {t('common/text:text.check_address_bar')}{' '}
          <Link to={routes.homePath()}>{t('common/text:text.dashboard')}</Link>
        </p>
      </React.Fragment>
    )
  }

  renderNetworkAssetDetail = ({ data: networkAsset }) => {
    const { t, permissions, distanceUnits, isMobile } = this.props
    const {
      isGroupModalOpen,
      isRemoveModalOpen,
      addDeviceParams,
      installationToRemove,
      isMuteAlertsModalOpen,
    } = this.state

    const pastInstallations = this.selectPastInstallations(
      this.props,
      this.state
    )
    const sortedUniqueAssetDeviceIssues =
      this.selectUniqueSortedAssetDeviceIssues(this.props)
    const editIsDisabled =
      !permissions.includes('can_edit_network_assets') ||
      networkAsset.isCongruityAsset()

    return (
      <div>
        <AddGroupToNetworkAssetModal
          isOpen={isGroupModalOpen}
          toggle={this.onToggleAddToGroupModal}
          onSuccess={this.props.refetchData}
          networkAsset={networkAsset}
        />

        <RemoveNetworkAssetModal
          onSuccessfullyRemovedAsset={this.onRemoveAsset}
          toggle={this.handleToggleRemoveModal}
          networkAsset={networkAsset}
          isOpen={isRemoveModalOpen}
        />

        <UninstallDeviceModal
          isOpen={!!installationToRemove}
          toggle={this.handleToggleUninstallDevice}
          onSuccess={this.handleUninstallInstallationSuccess}
          installation={installationToRemove}
        />

        <AddDeviceToNetworkAssetModal
          isOpen={!!addDeviceParams}
          channel={addDeviceParams ? addDeviceParams.channel : null}
          handleToggle={this.handleAddDeviceToggle}
          networkAsset={networkAsset}
          handleSuccess={this.props.refetchData}
        />

        <MuteAlertsForAssetModal
          isOpen={isMuteAlertsModalOpen}
          handleSubmit={this.handleSubmitMuteAlertsModal}
          handleToggle={this.handleToggleMuteAlertsModal}
          muteAlertsVariables={{ networkAssetId: networkAsset.id }}
        />

        <Row>
          <Col className={styles['network-asset-name-section']}>
            <NetworkAssetIcon
              networkAsset={networkAsset}
              className={styles.icon}
            />

            <div className="w-100">
              <h1 className={styles['network-asset-name']}>
                {networkAsset.assetId}
              </h1>

              {networkAsset.assetName ? (
                <span className={styles['network-asset-name']}>
                  {networkAsset.assetName}
                </span>
              ) : null}

              <p className={styles['network-asset-type']}>
                {t(`common/text:text.${networkAsset.assetType}`)}
              </p>
            </div>
          </Col>

          {!isMobile ? (
            <Col sm="auto">
              <div className="float-right">
                {this.renderEditTooltip(networkAsset)}
                <NavLink
                  name={'show-data-link' + networkAsset.uuid}
                  to={routes.analysisPressurePath({
                    d: [networkAsset.uuid],
                  })}
                  className={classnames(
                    styles.button,
                    'btn btn-primary',
                    styles['info-button']
                  )}
                >
                  <i className="fa fa-chart-area"></i>
                  &nbsp;
                  {t('buttons.show_data')}
                </NavLink>
                &nbsp;
                <span data-tip={this.getDisabledEditDataTip(networkAsset)}>
                  <ButtonDropdown
                    isOpen={this.state.isEditDropdownOpen}
                    color="secondary"
                    toggle={this.handleEditDropdownClick}
                  >
                    <Button
                      disabled={editIsDisabled}
                      tag={Link}
                      to={routes.editNetworkAssetPath(networkAsset.id)}
                      outline
                      name="edit-network-asset-button"
                    >
                      {t('buttons.edit')}
                    </Button>
                    <DropdownToggle
                      disabled={editIsDisabled}
                      name="edit-network-asset-toggle"
                      outline
                      caret
                    />
                    <DropdownMenu className={styles['edit-dropdown-menu']}>
                      <DropdownItem header>
                        {t('common/text:headings.alerts')}
                      </DropdownItem>

                      <DropdownItem
                        name="mute-alerts-button"
                        onClick={this.handleToggleMuteAlertsModal}
                        className={styles['dropdown-item-option']}
                      >
                        <span className={styles['dropdown-item-option-icon']}>
                          <i className="fal fa-bell-slash"></i>
                        </span>
                        &nbsp;
                        {t('common/text:buttons.mute')}
                      </DropdownItem>

                      <DropdownItem
                        name="unmute-alerts-button"
                        onClick={this.handleToggleUnmuteAlertsModal}
                        disabled={!networkAsset.currentAlertsMuteEnd}
                        className={styles['dropdown-item-option']}
                      >
                        <span className={styles['dropdown-item-option-icon']}>
                          <i className="fal fa-bell"></i>
                        </span>
                        &nbsp;
                        {t('common/text:buttons.unmute')}
                      </DropdownItem>

                      <DropdownItem
                        href={routes.editNetworkAssetPath(networkAsset.id, {
                          t: 'alerts',
                        })}
                        className={styles['dropdown-item-option']}
                      >
                        <span
                          className={styles['dropdown-item-option-icon']}
                        ></span>
                        &nbsp;
                        {t('common/text:buttons.alert_settings')}
                      </DropdownItem>

                      <DropdownItem divider />

                      <DropdownItem
                        name="remove-network-asset-button"
                        onClick={this.handleToggleRemoveModal}
                        className={styles['dropdown-item-option']}
                      >
                        <span className={styles['dropdown-item-option-icon']}>
                          <i className="far fa-trash-alt"></i>
                        </span>
                        &nbsp;
                        {t('buttons.remove')}
                      </DropdownItem>
                    </DropdownMenu>
                  </ButtonDropdown>
                </span>
              </div>
            </Col>
          ) : null}
        </Row>

        {sortedUniqueAssetDeviceIssues.length ? (
          <div className="mt-4 mb-5">
            {sortedUniqueAssetDeviceIssues.map(this.renderDeviceIssue)}
          </div>
        ) : null}

        <Row>
          <Col sm="12">
            <CollapsibleAlertsSection
              mutedUntil={networkAsset.currentAlertsMuteEnd}
              networkAssetId={networkAsset.id}
              currentSetting={{
                editHref: routes.editNetworkAssetPath(networkAsset.id, {
                  t: 'alerts',
                }),
                inheritedFrom: networkAsset.alertThresholds.inheritedFrom,
              }}
            />
          </Col>
        </Row>

        <Row className={styles['data-row']}>
          <Col sm="4">
            <h5>{t('headings.location')}</h5>
          </Col>

          <Col sm="8">
            <Row className={styles['data-item-row']}>
              <Col sm="12">
                <span className={styles['data-item-label']}>
                  {t('labels.coordinates')}
                </span>

                <span className={styles['data-item-value']}>
                  {networkAsset.formattedCoordinates('latlng')}
                </span>

                <span className={styles['data-item-additional-info']}>
                  {t(
                    `text.location_source_${
                      networkAsset.locationSource || 'undefined'
                    }`
                  )}
                </span>
              </Col>
            </Row>

            <Row className={styles['data-item-row']}>
              <Col sm="6">
                <span className={styles['data-item-label']}>
                  {t('labels.elevation')}
                </span>

                <span className={styles['data-item-value']}>
                  {convertUnitFromDB(
                    networkAsset.location.altitude,
                    distanceUnits,
                    DISTANCE_TYPE
                  )}{' '}
                  {t(`common/text:units.${distanceUnits}_abbreviated`)}
                </span>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className={styles['data-row']}>
          <Col sm="4">
            <h5>{t('headings.groups')}</h5>
          </Col>

          <Col sm="8">
            {networkAsset.groups.length === 0 ? (
              <div className={styles['no-group']}>
                <span>{t('text.no_groups')}</span>
              </div>
            ) : null}
            {!editIsDisabled ? null : (
              <ReactTooltip effect="solid" place="bottom" />
            )}
            {networkAsset.groups.map((group) => (
              <div key={group.id} className={styles['group-item']}>
                <span>
                  {t(`common/text:text.category_${group.category}`)}
                  &nbsp; / &nbsp;
                  <NavLink to={routes.managementGroupsDetailPath(group.id)}>
                    {group.name}
                  </NavLink>
                </span>
                <span data-tip={t('common/text:permissions.disabled')}>
                  <Button
                    name="remove-group-button"
                    color="link"
                    disabled={editIsDisabled}
                    outline
                    onClick={this.onRemoveGroup(group)}
                    className={styles['remove-group-button']}
                  >
                    <i className="fas fa-minus-circle"></i>
                  </Button>
                </span>
              </div>
            ))}
            {!editIsDisabled ? (
              <Button
                name="add-to-group-button"
                onClick={this.onClickAddGroupButton}
                className={styles['add-to-group-button']}
              >
                <i className="fas fa-plus"></i>
                &nbsp;
                {t('buttons.add_to_group')}
              </Button>
            ) : null}
          </Col>
        </Row>

        <Row className={styles['data-row']}>
          <Col sm="4">
            <h5>{t('headings.description')}</h5>
          </Col>

          <Col sm="8">
            <Row className={styles['data-item-row']}>
              <Col sm="12">
                <span className={styles['data-item-label']}>
                  {t('labels.custom_labels')}
                </span>

                <span className={styles['data-item-comments']}>
                  {networkAsset.customLabels || '-'}
                </span>
              </Col>
            </Row>

            <Row className={styles['data-item-row']}>
              <Col sm="12">
                <span className={styles['data-item-label']}>
                  {t('labels.comments')}
                </span>

                <span className={styles['data-item-comments']}>
                  {networkAsset.comment || '-'}
                </span>
              </Col>
            </Row>
          </Col>
        </Row>

        <CurrentInstallationsSection
          onInstall={this.handleInstall}
          permissions={permissions}
          onUninstall={this.handleUninstallInstallation}
          networkAssetResult={this.props.networkAssetResult}
          onClickDeleteInstallation={this.renderDeleteInstallationPromptFor}
          suggestedDeviceInstallations={this.selectSuggestedDeviceInstallations(
            this.props
          )}
        />

        <h5>{t('headings.previous_installations')}</h5>

        <div className={styles['past-installations-section']}>
          {pastInstallations.length === 0 ? (
            <span>{t('text.no_past_installations')}</span>
          ) : null}

          {pastInstallations.map((installation) =>
            this.renderPastInstallation(installation)
          )}
        </div>
      </div>
    )
  }

  renderEditTooltip = (networkAsset) => {
    const { permissions } = this.props

    if (
      !permissions.includes('can_edit_network_assets') ||
      networkAsset.isCongruityAsset()
    ) {
      return <ReactTooltip effect="solid" place="bottom" />
    }

    return null
  }

  getDisabledEditDataTip = (networkAsset) => {
    const { permissions, t } = this.props

    if (networkAsset.isCongruityAsset()) {
      return t('text.congruity_asset_edit_disabled')
    } else if (!permissions.includes('can_edit_network_assets')) {
      return t('common/text:permissions.disabled')
    }

    return ''
  }

  onRemoveGroup = (group) => async (event) => {
    event.preventDefault()

    const { networkAssetResult, refetchData } = this.props
    const networkAsset = networkAssetResult.wasSuccessful()
      ? networkAssetResult.data
      : null

    this.setState({ groupsResult: AsyncResult.pending() })

    try {
      await this.props.removeFromGroup({
        variables: {
          groupId: group.id,
          aggregateType: 'VNetworkAsset',
          aggregateId: networkAsset.id,
        },
      })

      await refetchData()
      this.setState({ groupsResult: AsyncResult.success() })
    } catch (err) {
      analytics.logError(err)
      this.setState({ groupsResult: AsyncResult.fail(err) })
    }
  }

  onRemoveAsset = () => {
    return <Redirect to={{ pathname: routes.managementNetworkAssetsPath() }} />
  }

  renderPastInstallation(installation) {
    const { t, permissions } = this.props
    const { device } = installation
    const installationDuration = moment.duration(
      moment(installation.end).diff(installation.start)
    )
    const timeLink =
      installationDuration.asHours() > 24
        ? {
            from: moment(installation.end).subtract(24, 'hours').toISOString(),
            to: moment(installation.end).toISOString(),
          }
        : {
            from: moment(installation.start).toISOString(),
            to: moment(installation.end).toISOString(),
          }

    return (
      <Card
        className={styles['past-installation-card']}
        key={installation.uuid}
      >
        <CardBody>
          <Row>
            <Col>
              {this.renderInstallationAction(device)}
              {installation.channelMap ? (
                <span className="ml-2">
                  {t('text.at')} {installation.translateAssetChannel(t)}
                </span>
              ) : null}

              <p className={styles['installation-time-text']}>
                {t('text.long_readable_full_time', {
                  time: installation.start,
                })}
                &nbsp; &rarr; &nbsp;
                <span>
                  {t('text.long_readable_full_time', {
                    time: installation.end,
                  })}
                </span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              {device ? (
                <Button
                  to={routes.analysisPressurePath({
                    d: [installation.networkAsset.uuid],
                    ...timeLink,
                  })}
                  name={'show-data-link' + installation.uuid}
                  tag={Link}
                >
                  <i className="fa fa-chart-area"></i>
                  &nbsp;
                  {t('buttons.show_data')}
                </Button>
              ) : (
                <Button color="secondary" disabled>
                  {t('buttons.show_data')}
                </Button>
              )}
            </Col>
            <Col sm="auto">
              <AppButton
                name="delete-installation-button"
                color="light"
                onClick={this.renderDeleteInstallationPromptFor(installation)}
                disabled={
                  !permissions.includes('can_edit_devices') ||
                  !permissions.includes('can_edit_network_assets')
                }
                className={styles['past-installation-btn']}
              >
                <i className="far fa-trash-alt" />
              </AppButton>
            </Col>
          </Row>
        </CardBody>
      </Card>
    )
  }

  renderInstallationAction = (device) => {
    const { t } = this.props

    if (device && device.id) {
      return (
        <NavLink
          to={routes.managementDevicesDetailPath(device.id)}
          className={styles['device-serial-number']}
        >
          {device.serialNumber}
        </NavLink>
      )
    } else if (device && !device.id) {
      return (
        <span className="text-black-50 font-italic">
          {t('text.device_returned')}
        </span>
      )
    } else {
      return (
        <Button color="primary" disabled>
          <i className="far fa-plus"></i>
          &nbsp;
          {t('buttons.assign_device')}
        </Button>
      )
    }
  }

  renderDeviceIssue = ({ device, issue }) => {
    const { t } = this.props

    const suggestions = this.selectSuggestedDeviceInstallations(this.props)
    const suggestion = suggestions.find(({ issue: iss }) => iss.id === issue.id)

    if (suggestion) {
      return (
        <DeviceIssueAlert
          key={issue.id}
          name="recommission-issue"
          deviceIssue={issue}
          customActionComponent={
            <AppButton
              size="sm"
              name="quick-reinstall-from-alert-button"
              color="outline-warning"
              onClick={suggestion.reinstall}
            >
              {t('buttons.reinstall_device')}
            </AppButton>
          }
        >
          <strong>
            {t('text.issues_detached_from_asset', {
              serialNumber: suggestion.deviceSerialNumber,
            })}
          </strong>
        </DeviceIssueAlert>
      )
    }
    return (
      <DeviceIssueAlert
        sourceToLinkTo={device}
        key={issue.id}
        deviceIssue={issue}
      >
        <strong>
          {t(`common/text:issues.${issue.type}`, {
            count: issue.value,
            serialNumber: device.serialNumber,
          })}
        </strong>
      </DeviceIssueAlert>
    )
  }

  renderDeleteInstallationPromptFor = (installation) => {
    return () =>
      this.props.renderModal(
        <DeleteInstallationPrompt
          installation={installation}
          handleSuccess={this.props.refetchData}
        />
      )
  }

  constructor(props) {
    super(props)

    this.state = {
      isEditModalOpen: false,
      addDeviceParams: null,
      isGroupModalOpen: false,
      isEditDropdownOpen: false,
      isRemoveModalOpen: false,
      installationToRemove: null,
      groupsResult: AsyncResult.notFound(),
    }
  }

  selectMapCenter = createSelector([get('networkAssetResult')], (result) => {
    return result.wasSuccessful()
      ? L.latLng([
          result.data.location.latitude,
          result.data.location.longitude,
        ])
      : undefined
  })

  selectPastInstallations = createSelector(
    [get('networkAssetResult')],
    (result) =>
      result.wasSuccessful()
        ? result.data.installations.filter(({ end }) => end !== null)
        : []
  )

  selectUniqueSortedAssetDeviceIssues = createSelector(
    [get('networkAssetResult')],
    (networkAssetResult) => {
      const activeIssues = networkAssetResult.wasSuccessful()
        ? networkAssetResult.data.activeIssues
        : []

      const uniqFunc = uniqBy(({ type, deviceId }) => type + deviceId)

      const [errors, rest] = partition((i) => i.isError())(activeIssues)

      const [warnings, infos] = partition((i) => i.isWarning())(rest)
      const uniqueErrors = uniqFunc(errors)
        .filter((e) => e)
        .map((error) => ({ device: error.device, issue: error }))
      const uniqueWarnings = uniqFunc(warnings)
        .filter((w) => w)
        .map((warning) => ({ device: warning.device, issue: warning }))
      const uniqueInfos = uniqFunc(infos)
        .filter((i) => i)
        .map((info) => ({ device: info.device, issue: info }))

      return [...uniqueErrors, ...uniqueWarnings, ...uniqueInfos]
    }
  )

  selectSuggestedDeviceInstallations = createSelector(
    [get('networkAssetResult')],
    (networkAssetResult) => {
      const activeIssues = networkAssetResult.wasSuccessful()
        ? networkAssetResult.data.activeIssues
        : []

      if (activeIssues.length === 0) {
        return []
      }

      const detachedFromAssetIssues = activeIssues.filter(
        (issue) => issue.type === DeviceIssue.DETACHED_FROM_ASSET
      )

      const networkAsset = networkAssetResult.data
      const { installations } = networkAsset

      return detachedFromAssetIssues.map((issue) => {
        const installation = installations.find(
          (ins) => ins.deviceId === issue.device.id
        )

        const currentCommission = installation.device.currentCommission

        return {
          issue,
          device: installation.device,
          channel: installation.channelMap.pressure_1,
          reinstall: () =>
            this.handleInstall({
              start: currentCommission.start,
              device: installation.device,
              channel: installation.channelMap.pressure_1,
            }),
          deviceSerialNumber: installation.device.serialNumber,
          previousInstallation: installation,
        }
      })
    }
  )

  onClickEditNetworkAssetButton = (event) => {
    event.preventDefault()
    this.setState({ isEditModalOpen: true })
  }

  onClickAddGroupButton = (event) => {
    event.preventDefault()
    this.setState({ isGroupModalOpen: true })
  }

  handleEditDropdownClick = () => {
    this.setState({ isEditDropdownOpen: !this.state.isEditDropdownOpen })
  }

  handleToggleMuteAlertsModal = async (event) => {
    event.preventDefault()

    this.setState({ isMuteAlertsModalOpen: !this.state.isMuteAlertsModalOpen })
  }

  handleToggleUnmuteAlertsModal = async (event) => {
    event.preventDefault()

    try {
      const { data: networkAsset } = this.props.networkAssetResult

      await this.props.unmuteAlerts({
        variables: {
          networkAssetId: networkAsset.id,
        },
      })

      await this.props.refetchData()
    } catch (e) {
      analytics.logError(e)
    }
  }

  handleSubmitMuteAlertsModal = () => {
    this.props.refetchData()
  }

  handleToggleRemoveModal = (event) => {
    event.preventDefault()
    this.setState({ isRemoveModalOpen: !this.state.isRemoveModalOpen })
  }

  handleInstall = async ({ device, channel, start = new Date() }) => {
    const { networkAssetResult, createInstallation, refetchData } = this.props
    const networkAsset = networkAssetResult.data
    const suggestions = this.selectSuggestedDeviceInstallations(this.props)
    let startDate = moment(start).format('YYYY-MM-DD HH:mm:00.000000Z')

    // Fixes https://github.com/Inflowmatix/InflowNet-Apps/issues/2843
    if (suggestions.length > 0) {
      const lastInstallation = networkAsset.installations[0]
      const lastInstallationEndDate = lastInstallation.end
      if (
        lastInstallationEndDate &&
        moment(startDate).toDate() < lastInstallationEndDate
      ) {
        startDate = moment(start)
          .add(1, 'second')
          .format('YYYY-MM-DD HH:mm:ss.000000Z')
      }
    }

    if (device) {
      await createInstallation({
        variables: {
          start: startDate,
          deviceId: device.id,
          channelMap: {
            pressure_1: channel,
          },
          networkAssetId: networkAsset.id,
        },
      })

      await refetchData()
    } else {
      this.setState({
        addDeviceParams: {
          channel,
          networkAsset: networkAssetResult,
        },
      })
    }
  }

  handleUninstallInstallation = ({ installation }) => {
    this.setState({ installationToRemove: installation })
  }

  handleToggleUninstallDevice = () => {
    this.setState({ installationToRemove: null })
  }

  handleUninstallInstallationSuccess = async () => {
    await this.props.refetchData()

    this.props.handleUninstallDeviceSuccess()
  }

  handleUninstallDeviceSuccess = async () => {
    await this.props.refetchData()

    this.props.handleUninstallDeviceSuccess()
  }

  handleAddDeviceToggle = () => {
    this.setState({ addDeviceParams: null })
  }

  onToggleEditNetworkAssetModal = () => {
    this.setState({
      isEditModalOpen: !this.state.isEditModalOpen,
    })
  }

  onToggleAddToGroupModal = () => {
    this.setState({
      isGroupModalOpen: !this.state.isGroupModalOpen,
    })
  }

  static UNMUTE_ALERTS = gql`
    mutation UnmuteAlertsForAsset($networkAssetId: Int!) {
      unmuteAlertsForAsset(networkAssetId: $networkAssetId)
    }
  `

  static CREATE_INSTALLATION = gql`
    mutation CreateInstallation(
      $networkAssetId: Int!
      $deviceId: Int!
      $start: String!
      $channelMap: ChannelMappingInput!
    ) {
      addInstallation(
        deviceId: $deviceId
        channelMap: $channelMap
        whenInstalled: $start
        networkAssetId: $networkAssetId
      )
    }
  `

  static REMOVE_FROM_GROUP_MUTATION = gql`
    mutation RemoveMemberFromGroup(
      $groupId: Int!
      $aggregateType: AggregateType!
      $aggregateId: Int!
    ) {
      removeMemberFromGroup(
        groupId: $groupId
        aggregateType: $aggregateType
        aggregateId: $aggregateId
      )
    }
  `

  static NETWORK_ASSETS_QUERY = gql`
    query NetworkAssetById($id: Int!) {
      networkAsset(id: $id) {
        id
        assetId
        comment
        assetName
        location {
          altitude
          latitude
          longitude
        }
        groups {
          id
          category
          name
        }
        assetType
        activeIssues {
          id
          type
          value
          deviceId
          severity
          description
          device {
            id
            serialNumber
          }
        }
        installations {
          end
          start
          deviceId
          device {
            id
            serialNumber
            lastKnownLocation {
              latitude
              longitude
            }
            currentCommission {
              start
              end
            }
          }
          channelMap {
            pressure_1
          }
          networkAssetId
        }
        currentAlertsMuteEnd
        customLabels
        locationSource
        alertThresholds {
          inheritedFrom
        }
      }
    }
  `
}

function mergeActiveDeviceIssuesWithDevices(networkAsset) {
  const { activeIssues } = networkAsset

  return {
    ...networkAsset,
    installations: networkAsset.installations.map((installation) => ({
      ...installation,
      device: {
        ...installation.device,
        activeIssues: activeIssues.filter(
          (issue) => issue.device.id === installation.device.id
        ),
      },
    })),
  }
}

function NetworkAssetDetailPageContainer(props) {
  return (
    <AppSettingsConsumer>
      {(units) => (
        <NetworkAssetDetailPage distanceUnits={units.distance} {...props} />
      )}
    </AppSettingsConsumer>
  )
}

export default compose(
  requiresLogin,
  userPermissions,
  withModals([DeleteInstallationPrompt]),
  withGoogleMapsProvider,
  transformProps(() => ({ match }) => {
    return {
      selectNetworkAssetResult: createSelectGraphQLResult('networkAsset', {
        mapResult: (rawAsset) =>
          parseGraphQLResult(mergeActiveDeviceIssuesWithDevices(rawAsset)),
        nullObject: {},
      }),
      assetIdentifier: Number(decodeURIComponent(match.params.id)),
    }
  }),
  graphql(NetworkAssetDetailPage.UNMUTE_ALERTS, {
    name: 'unmuteAlerts',
  }),
  graphql(NetworkAssetDetailPage.REMOVE_FROM_GROUP_MUTATION, {
    name: 'removeFromGroup',
  }),
  graphql(NetworkAssetDetailPage.CREATE_INSTALLATION, {
    name: 'createInstallation',
  }),
  graphql(NetworkAssetDetailPage.NETWORK_ASSETS_QUERY, {
    options: ({ assetIdentifier }) => {
      return {
        variables: {
          id: assetIdentifier,
        },
        fetchPolicy: 'network-only',
      }
    },
    props: ({ ownProps, data }) => {
      const { refetch } = data
      const { assetIdentifier, selectNetworkAssetResult } = ownProps

      return {
        refetchData: () => {
          refetch({ id: assetIdentifier })
        },
        networkAssetResult: selectNetworkAssetResult(data),
      }
    },
  }),
  withTranslation([
    'src/management_path/network_assets_path/network_asset_detail_page',
    'common/text',
  ]),
  withWindowSizes
)(NetworkAssetDetailPageContainer)
