import * as Sentry from '@sentry/browser'
import moment from 'moment'

import config from './config'
import { datadogLogs } from '@datadog/browser-logs'
import { ApolloError } from '@apollo/client'
import { isCognitoError } from '@@src/utils'

window.ga=window.ga||function(){(ga.q=ga.q||[]).push(arguments)};ga.l=+new Date; // eslint-disable-line

const GA_LOCAL_STORAGE_KEY = 'ga:clientId'

// Pipe function for string processing
const pipe = (...fns) => x => fns.reduce((v, f) => f(v), x)

// window.ga_debug = { trace: true } // eslint-disable-line camelcase

const hostname = window.location.hostname
if (hostname !== 'localhost') {
  const environment = hostname.replace(new RegExp(`^${config.tenant}\.`), '')

  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    release: process.env.APP_VERSION,
    environment,
  })
}

window.ga('create', process.env.GA_TRACKING_ID, 'auto', {
  storage: 'none',
  clientId: localStorage.getItem(GA_LOCAL_STORAGE_KEY),
})

window.ga('set', 'appName', 'InflowNet WebApp')
window.ga('set', 'location', undefined)
window.ga('set', 'appVersion', `${config.tenant} (${process.env.APP_VERSION})`)
window.dataLayer = window.dataLayer || []

function gtag() {window.dataLayer.push(arguments)}
gtag('js', new Date())
gtag('config', process.env.GA_MEASUREMENT_ID || 'G-YPVM17CCWK', { 'debug_mode': process.env.NODE_ENV !== 'production' })

window.addEventListener('error', errorEvent => {
  if (errorEvent.error) {
    logError(errorEvent.error)
  }
  return false
})

class UnhandledRejectionError extends Error {
  constructor(event) {
    super('UnhandledRejection: ' +
      formatSanitizedErrorForSentry(event.reason).message)
  }
}

window.addEventListener('unhandledrejection', event => {
  logError(new UnhandledRejectionError(event))
})

if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
  // comment this line to enable sending hits to GA regardless of environment
  window.ga('set', 'sendHitTask', null)
}

window.ga(tracker => {
  localStorage.setItem(GA_LOCAL_STORAGE_KEY, tracker.get('clientId'))
})

if (process.env.NODE_ENV !== 'test') {
  datadogLogs.init({
    clientToken: process.env.DATADOG_CLIENT_TOKEN,
    datacenter: 'us',
    forwardErrorsToLogs: true,
    sampleRate: 100,
  })

  datadogLogs.addLoggerGlobalContext('env',
    (/inflowsysweb\.com/).test(hostname) ? 'production' : 'development'
  )
}

trackLocation(window.location)

export function trackLocation(location) {
  window.ga('send', 'pageview', normalizePathname(location.pathname))
}

function normalizePathname(pathname = '') {
  return pathname.replace(/\/\d+\/?/, '/{ID}/')
}

export function trackRequestLatency(name, latency, requestId = 'N/A') {
  datadogLogs.logger.info('GraphQL Request', {
    'graphql_aws_request_id': requestId,
    'graphql_operation_name': name,
    'graphql_request_latency': latency,
  })
}

export function trackDaterangePicked(source, rangePicked) {
  gtag('event', `QuickRange-${source}`, { 'picked_range': rangePicked })
}

// Used to track how long ago data is being requested from the absolute time range picker
export function trackDetailedDateRange(requestedTimeFrom) {
  const now = moment()
  const momentRequested = moment(requestedTimeFrom)
  const dayDiff = now.diff(momentRequested, 'days')

  let time = 'j. More than 4 years ago'

  if (dayDiff < 7) {
    time = 'a. Within 7 days'
  } else if (dayDiff < 14) {
    time = 'b. Within 14 days'
  } else if (dayDiff < 30) {
    time = 'c. Within 30 days'
  } else if (dayDiff < 90) {
    time = 'd. Within 90 days'
  } else if (dayDiff < 180) {
    time = 'e. Within 180 days'
  } else if (dayDiff < 365) {
    time = 'f. Within 365 days (1 year)'
  } else if (dayDiff < 730) {
    time = 'g. Within 730 days (2 years)'
  } else if (dayDiff < 1095) {
    time = 'h. Within 1095 days (3 years)'
  } else if (dayDiff < 1460) {
    time = 'i. Within 1460 days (4 years)'
  }

  gtag('event', 'FullRange', { time })
}

/**
 * @param {Date} start start
 * @param {Date} end end
 * @returns {void}
 */
export function trackSelectedAnalysisPeriod({ start, end }) {
  const event = 'selected_analysis_date_range'
  const daysDifference = Math.ceil((end.getTime() - start.getTime()) / 1000 / 60 / 60 / 24)
  const daysAgo = Math.ceil((Date.now() - start.getTime()) / 1000 / 60 / 60 / 24)
  const payload = {
    'range_start_year': start.getFullYear(),
    'range_start': start.toISOString(),
    'range_end_year': start.getFullYear(),
    'range_end': end.toISOString(),
    'range_days_difference': daysDifference,
    'range_days_difference_ago': daysAgo,
  }

  gtag('event', event, payload)
}

export function logError(...args) {
  trackAndLogError(false, ...args)
}

export function logFatalError(...args) {
  trackAndLogError(true, ...args)
}

function trackAndLogError(isFatal, ...rest) {
  const error = rest.length > 1 ? rest.join(' ') : rest[0]
  trackError(error, isFatal)

  console.error(error) // eslint-disable-line no-console
}

export async function trackError(error, isFatal = false) {
  const errorInfo = getErrorInfo(error)
  const errorToReport = shouldReportError(error) ?
    formatSanitizedErrorForSentry(error, isFatal) :
    null

  return new Promise(resolve => Sentry.withScope(scope => {
    if (errorToReport !== null) {
      if (errorInfo) {
        scope.setExtras(errorInfo)
      }

      if (error && error._sentryErrorTags) {
        const keys = Object.keys(error._sentryErrorTags)
        for (const key of keys) {
          scope.setTag(key, error._sentryErrorTags[key])
        }
      }

      // returns the Sentry event ID
      resolve(Sentry.captureException(errorToReport), scope)
    } else {
      resolve(null)
    }
  }))
}

function getErrorInfo(error) {
  switch (true) {
    case (!!(error && error.graphQLErrors && error.graphQLErrors.length)): {
      const graphQLErrors = error.graphQLErrors

      if (graphQLErrors.length === 1) {
        return graphQLErrors[0].extensions
      } else {
        return graphQLErrors.map(i => (i || {}).extensions)
      }
    }

    default:
      return undefined
  }
}

function shouldReportError(error) {
  return !isApolloError(error) &&
    (!isCognitoError(error) || shouldReportCognitoError(error)) &&
    includeInReport(error)
}

// Items to selectively omit from Sentry error reports
function includeInReport(error) {
  try {
    if (
      error.message.includes('An account with the given email already exists')
      || error.message.includes('Invalid email address format')
      || error.message.includes('Invalid phone number format')
      || error.message.includes('Exceeds raw data request quota for device')
      || error.message.includes('Device is already installed - must be removed first') // eslint-disable-line max-len
      || error.message.includes('Could not find any cognito services associated with') // eslint-disable-line max-len
      || error.message.includes('Device has already been delivered - must be online first') // eslint-disable-line max-len
      || error.message.includes('Device is already engaged and online - must be removed first') // eslint-disable-line max-len
      || error.message.includes('1 validation error detected: Value at \'username\' failed to satisfy constraint: Member must satisfy regular expression pattern: [\\p{L}\\p{M}\\p{S}\\p{N}\\p{P}]+') // eslint-disable-line max-len
      || error.message.includes('unable to edit asset type with installations')
    ) {
      return false
    }
    return true
  } catch (e) {
    // If error does not meet above criteria it should be reported
    return true
  }
}

function isApolloError(error) {
  return error instanceof ApolloError
}

function shouldReportCognitoError(error) {
  switch (error.code) {
    case 'ExpiredCodeException':
    case 'UserNotFoundException':
    case 'NotAuthorizedException':
    case 'CodeMismatchException':
    case 'InvalidPasswordException':
    case 'PasswordResetRequiredException':
    case 'InvalidParameterException':
      return false

    default:
      return true
  }
}

function formatSanitizedErrorForSentry(...args) {
  // pipe chain allows sequential transformations on error messages
  return pipe(
    stripDBIntErrors,
    sanitizeError,
    formatErrorForSentry
  )(...args)
}

function stripDBIntErrors(error) {
  if (typeof error.message === 'string'
    && error.message.match(/"[0-9-]*" is out of range for type bigint/g)) {
    error.message =
      'GraphQL Error: error: value is out of range for type bigint'
  }

  return error
}

// Ensure that all individual device installation errors are properly aggregated by Sentry
function isDeviceUninstallationError(error) {
  const deviceUninstalledRegex = /Device with id [\d]+ is not installed on asset with id [\d]+/ // eslint-disable-line max-len

  if (error.message
    && error.message.match(deviceUninstalledRegex)) {
    return true
  }
  return false
}

function formatErrorForSentry(error, fatal) {
  switch (true) {
    case isCognitoError(error):
      return createError(
        fatal,
        `CognitoError: ${error.code}/${error.name} - ${error.message}`
      )

    case isDeviceUninstallationError(error):
      return createError(
        fatal,
        'Device is not installed on asset'
      )

    case (error instanceof Error):
      return error

    case (error && !!error.message): {
      return new Error('Custom object with error: ' + error.message)
    }

    default:
      return error
  }
}

function createError(fatal, ...args) {
  const error = new Error(...args)
  if (Error.captureStackTrace) {
    if (fatal) {
      Error.captureStackTrace(error, logFatalError)
    } else {
      Error.captureStackTrace(error, logError)
    }
  }

  return error
}

function sanitizeError(error) {
  const simpleEmailRegex = /\S+@\S+\.\S+/g

  if (typeof error.message === 'string') {
    error.message = error.message.trim().replace(
      simpleEmailRegex,
      '[redacted_email]'
    )
  }

  return error
}
