import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'reactstrap'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import { graphql } from '@apollo/client/react/hoc'
import gql from 'graphql-tag'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import { get } from 'lodash/fp/object'

import Alert from '@@src/api/presenters/alert'
import { createSelectGraphQLResult } from '@@src/utils'
import { parseGraphQLResult } from '@@src/api/presenters'
import AsyncResult from '@@src/utils/async_result'
import AsyncList from '@@src/components/lists/async_list'
import AlertComment from '@@src/alerts_path/alert_comment'
import CreateCommentForm from '@@src/alerts_path/create_comment_form'
import EditCommentForm from '@@src/alerts_path/edit_comment_form'
import userPermissions from '@@src/components/permissions/user_permissions'

class AlertDialog extends React.PureComponent {
  static propTypes = {
    alert: PropTypes.instanceOf(Alert).isRequired,
    t: PropTypes.func.isRequired,
    refetchPagedComments: PropTypes.func.isRequired,
    selectPagedCommentsResult: PropTypes.func.isRequired,
    pagedCommentsResult: PropTypes.instanceOf(AsyncResult).isRequired,
    permissions: PropTypes.array.isRequired,
  }

  static defaultProps = {
    pagedCommentsResult: AsyncResult.notFound(),
  }

  state = {
    isFetchingMoreComments: false,
    commentReplyingId: undefined,
    commentEditingId: undefined,
  }

  render() {
    const {
      t, alert, pagedCommentsResult, refetchPagedComments,
    } = this.props
    const { isFetchingMoreComments } = this.state

    return (
      <div className="d-flex flex-column">
        <CreateCommentForm
          alert={alert}
          handleSuccessfulChange={refetchPagedComments}
          className="mb-5" />
        {
          pagedCommentsResult.wasSuccessful() &&
            pagedCommentsResult.data.length === 0 ?
            <span className="font-italic">
              {t('text.no_comments')}
            </span> :
            <React.Fragment>
              <AsyncList
                className="list-unstyled"
                result={pagedCommentsResult}
                renderItem={this.renderAlertComment}
                onRequestRetry={this.handleRequestRetry} />
              {
                pagedCommentsResult.wasSuccessful() &&
                  pagedCommentsResult.data.pagination.pageNumber <
                    pagedCommentsResult.data.pagination.totalPages ?
                  <Button
                    name="fetch-more-alerts-button"
                    disabled={isFetchingMoreComments}
                    className="pl-0 align-self-start"
                    color="link"
                    onClick={this.handleClickShowMoreComments}>
                    {t('button.show_older_comments')}
                    {
                      isFetchingMoreComments ?
                        <span className="far fa-spinner-third fa-spin ml-2" /> :
                        null
                    }
                  </Button> : null
              }
            </React.Fragment>
        }
      </div>
    )
  }

  renderAlertComment = comment => {
    return (
      <AlertComment
        key={comment.commentId}
        handleReply={this.setCommentReplyingId}
        handleEdit={this.setCommentEditingId}
        comment={comment}>
        {this.renderCommentContent(comment)}
        {
          Array.isArray(comment.replies) ?
            <ol className="list-unstyled ml-4">
              {
                comment.replies.map(reply => (
                  <AlertComment
                    key={reply.commentId}
                    handleReply={this.setCommentReplyingId}
                    handleEdit={this.setCommentEditingId}
                    comment={reply}>
                    {this.renderCommentContent(reply)}
                  </AlertComment>
                ))
              }
            </ol>
            : null
        }
      </AlertComment>
    )
  }

  renderCommentContent = comment => {
    const { t, alert } = this.props
    const { commentReplyingId, commentEditingId } = this.state

    if (commentReplyingId === comment.commentId) {
      return (
        <React.Fragment>
          {
            <p className="mb-4">
              {comment.formattedComment(t)}
            </p>
          }
          <CreateCommentForm
            handleCancel={this.clearCommentReplyingId}
            alert={alert}
            parentComment={comment}
            handleSuccessfulChange={this.handleSuccessfulCommentChange}
            className="mb-3" />
        </React.Fragment>
      )
    } else if (comment.commentId === commentEditingId) {
      return (
        <EditCommentForm
          alert={alert}
          parentComment={comment}
          handleSuccessfulChange={this.handleSuccessfulCommentChange}
          handleCancel={this.clearCommentEditingId}
          className="mb-3 mt-2"
          comment={comment} />
      )
    } else {
      return (
        <p className="mb-4">
          {
            comment.deleted ?
              <span className="font-italic">
                {t('text.comment_deleted')}
              </span>
              :
              comment.formattedComment(t)
          }
        </p>
      )
    }
  }

  componentDidUpdate(prevProps) {
    const { alert } = this.props
    const { alert: prevAlert } = prevProps

    if (alert.status !== prevAlert.status) {
      this.handleSuccessfulCommentChange()
    }
  }

  setCommentReplyingId = commentId => {
    this.setState({
      commentReplyingId: this.state.commentReplyingId === commentId ?
        undefined : commentId,
      commentEditingId: undefined,
    })
  }

  setCommentEditingId = commentId => {
    this.setState({
      commentEditingId: this.state.commentEditingId === commentId ?
        undefined : commentId,
      commentReplyingId: undefined,
    })
  }

  clearCommentReplyingId = () => {
    this.setState({
      commentReplyingId: undefined,
    })
  }

  clearCommentEditingId = () => {
    this.setState({
      commentEditingId: undefined,
    })
  }

  handleClickShowMoreComments = () => {
    const { fetchMoreComments, pagedCommentsResult } = this.props

    fetchMoreComments({
      variables: {
        pageNumber: pagedCommentsResult.data.pagination.pageNumber + 1,
      },
      updateQuery: this.handleMoreComments,
    })

    this.setState({
      isFetchingMoreComments: true,
    })
  }

  handleMoreComments = (previousResult, { fetchMoreResult }) => {
    this.setState({
      isFetchingMoreComments: false,
    })

    const result = parseGraphQLResult(fetchMoreResult)
    const { comments, ...rest } = result.pagedComments

    return {
      pagedComments: {
        comments: [
          ...previousResult.pagedComments.comments,
          ...comments,
        ],
        ...rest,
      },
    }
  }

  handleSuccessfulCommentChange = () => {
    const { refetchPagedComments } = this.props

    refetchPagedComments()
    this.clearCommentReplyingId()
    this.clearCommentEditingId()
  }

  handleRequestRetry = () => {
    this.props.refetchPagedComments()
  }

  static PAGED_COMMENTS_QUERY = gql`
    query PagedComments(
      $pageNumber: Int!,
      $resultsPerPage: Int!,
      $alertId: Int!,
    ) {
      pagedComments(
        pageNumber: $pageNumber,
        resultsPerPage: $resultsPerPage,
        alertId: $alertId
      ) {
        comments {
          commentId
          parentId
          alertId
          comment
          createdAt
          updatedAt
          deleted
          author {
            name
            email
          }
          replies {
            commentId
            parentId
            alertId
            comment
            createdAt
            updatedAt
            deleted
            author {
              name
              email
            }
          }
        }
        pagination {
          pageNumber
          totalPages
          totalResults
        }
      }
    }
  `
}

function createMapStateToProps() {
  const selectPagedCommentsQueryVariables = createSelector(
    [get('alert')],
    (alert) => {
      return {
        pageNumber: 1,
        resultsPerPage: 5,
        alertId: alert.alertId,
      }
    },
  )

  const selectPagedCommentsResult = createSelectGraphQLResult('pagedComments', {
    mapResult: parseGraphQLResult,
    nullObject: {},
  })

  return function mapStateToProps() {
    return {
      selectPagedCommentsQueryVariables,
      selectPagedCommentsResult,
    }
  }
}

export default compose(
  userPermissions,
  withTranslation([
    'src/alerts_path/alert_dialog',
    'common/text',
  ]),
  connect(createMapStateToProps),
  graphql(AlertDialog.PAGED_COMMENTS_QUERY, {
    options: ({ selectPagedCommentsQueryVariables, ...rest }) => {
      return {
        variables: selectPagedCommentsQueryVariables(rest),
      }
    },
    props: ({ data: { fetchMore, refetch, ...rest }, ownProps }) => {
      const { selectPagedCommentsResult } = ownProps

      return {
        fetchMoreComments: fetchMore,
        refetchPagedComments: (...args) => refetch(...args),
        pagedCommentsResult: selectPagedCommentsResult(rest),
      }
    },
  }),
)(AlertDialog)
