import { combineReducers } from 'redux'

import HistorySaga from './store/history'
import TooltipStore from '@@src/components/tooltip/store'
import * as Changes from '@@src/store/changes'
import * as DragCoverStore from '@@src/components/singletons/drag_cover'
import * as SharedColorStore from '@@src/store/shared_color_store'
import EventSourceLocalisationStore from
    '@@src/store/event_source_localisation'
import { connectRouter } from 'connected-react-router'
import { createMemoryHistory } from 'history'

export default function createRootReducer(history = createMemoryHistory()) {
  const appReducer = combineReducers({
    history: HistorySaga.reducer,
    changes: Changes.reducer,
    tooltip: TooltipStore.reducer,
    dragCover: DragCoverStore.reducer,
    sharedColorStore: SharedColorStore.reducer,
    eventSourceLocalisation: EventSourceLocalisationStore.reducer,
    router: connectRouter(history),
  })

  return function rootReducer(state, action) {
    return appReducer(state, action)
  }
}
