import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash/fp/object'
import { noop } from 'lodash/fp/util'
import gql from 'graphql-tag'
import { createSelector } from 'reselect'
import { scaleLinear } from 'd3-scale'
import moment from 'moment'

import { globalSequence } from '@@src/utils'
import GraphContext from '@@src/components/graphs/graph_context'
import LeftAxis from '@@src/components/graphs/left_axis'
import SignalHistoryPlotLayer
from '@@src/management_path/devices_path/signal_history_modal/signal_history_plot_layer' // eslint-disable-line max-len
import SignalHistoryTooltipTriggers from
'@@src/management_path/devices_path/signal_history_modal/signal_history_tooltip_triggers' // eslint-disable-line max-len
import HistoryGraph, { GRAPH_PADDING, formatTicks } from
'@@src/management_path/devices_path/history_modal/history_graph'
import BottomTimeAxis from '@@src/components/graphs/bottom_time_axis'
import DragEventLayer, { ONLY_X }
from '@@src/components/graphs/drag_layer/drag_event_layer'
import TelemetryGraphStateOverlay from
'@@src/management_path/devices_path/history_modal/telemetry_graph_state_overlay' // eslint-disable-line max-len
import DragControlsLayer from '@@src/components/graphs/drag_controls_layer'
import AsyncResult from '@@src/utils/async_result'
import HistoryPlotLayer from
'@@src/management_path/devices_path/history_modal/history_plot_layer'
import DragAreaOverlay
from '@@src/components/graphs/drag_layer/drag_area_overlay'
import { DEFAULT_PERIOD_MILLISECONDS } from
'@@src/management_path/devices_path/history_modal/history_graph_container'

import styles from './signal_history_graph.css'

export const SIGNAL_SCALE_DEFAULT = [-55, -50]

export default class SignalHistoryGraph extends React.PureComponent {
  static defaultProps = {
    onAxesChange: noop,
    onResetZoom: noop,
    handleGraphPan: noop,
    displayTooltipMillisThreshold: DEFAULT_PERIOD_MILLISECONDS,
  }

  static propTypes = {
    className: PropTypes.string,
    start: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    end: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    onAxesChange: PropTypes.func.isRequired,
    onResetZoom: PropTypes.func.isRequired,
    handleGraphPan: PropTypes.func.isRequired,
    displayTooltipMillisThreshold: PropTypes.number.isRequired,
    selectXScale: PropTypes.func,
    refetchData: PropTypes.func,
    deviceWithTelemetryResult: PropTypes.instanceOf(AsyncResult),
  }

  static deviceTelemetryQuery = gql`
    query DeviceTelemetryQuery(
      $id: Int!,
      $end: String!,
      $start: String!,
      $channels: [TelemetryChannelsTypes!]!
    ) {
      device(id: $id) {
        id
        serialNumber
        telemetry(start: $start, end: $end, channels: $channels) {
          time
          signalQuality
          value
          lac
          mnc
          mcc
          rat
          cid
          mobileNetwork
          channel
        }
      }
    }
  `

  id = globalSequence.next()

  render() {
    const {
      className,
      onAxesChange,
      onResetZoom,
      deviceWithTelemetryResult,
      selectXScale,
      start,
      end,
      refetchData,
      deviceId,
      handleGraphPan,
      panStart,
      panEnd,
      timezone,
    } = this.props
    const telemetrySegments =
      deviceWithTelemetryResult.data.telemetrySegments || []
    const clipPathId = `history-plot-layer-clip-path-${this.id}`
    const displayTooltips = this.selectDisplayTooltips(this.props)

    return (
      <TelemetryGraphStateOverlay
        displayTooltips={displayTooltips}
        dataResult={deviceWithTelemetryResult}>
        <HistoryGraph
          deviceId={deviceId}
          className={className}
          refetchData={refetchData}
          panStart={panStart}
          panEnd={panEnd}
          start={start}
          end={end}
          xScale={selectXScale(this.props)}
          yScale={this.selectYScale(this.props)}>
          <LeftAxis withGuides label={
            <g>
              <circle
                r="3"
                cx="-33"
                cy="-4"
                className={styles['signal-strength-legend']}/>
              <text>dBm</text>
            </g>
          } tickFormatter={formatTicks}/>
          <BottomTimeAxis timezone={timezone}/>

          <GraphContext.Consumer>
            {
              config => (
                <HistoryPlotLayer
                  clipPathId={clipPathId}
                  graphConfig={config}
                  name="signal-history-plot-layer">
                  <SignalHistoryPlotLayer
                    clipPathId={clipPathId}
                    graphConfig={config}
                    telemetrySegments={telemetrySegments}/>

                  <DragEventLayer
                    dragBehaviour={ONLY_X}
                    onDoubleClick={onResetZoom}>
                    <DragControlsLayer
                      onAxesChange={onAxesChange}
                      onPan={handleGraphPan}
                      dragBehaviour={ONLY_X}>
                      {
                        displayTooltips ?
                          <SignalHistoryTooltipTriggers
                            clipPathId={clipPathId}
                            telemetrySegments={telemetrySegments}/>
                          : null
                      }
                    </DragControlsLayer>

                    <DragAreaOverlay />
                  </DragEventLayer>
                </HistoryPlotLayer>
              )
            }
          </GraphContext.Consumer>

        </HistoryGraph>
      </TelemetryGraphStateOverlay>
    )
  }

  selectYScale = createSelector(
    [get('deviceWithTelemetryResult')],
    result => {
      const telemetry = result.data.telemetry || []
      const signals = telemetry.filter(t => t.channel === 'sgi_signal')

      if (signals.length === 0) {
        return scaleLinear().domain(SIGNAL_SCALE_DEFAULT)
      }

      const signalStrength = signals.map(tel => tel.value)

      const minSignalStrength = Math.min(...signalStrength)
      const maxSignalStrength = Math.max(...signalStrength)
      const range = (maxSignalStrength - minSignalStrength) || 0.1

      return scaleLinear().domain([
        minSignalStrength - range * GRAPH_PADDING,
        maxSignalStrength + range * GRAPH_PADDING,
      ])
    }
  )

  selectDisplayTooltips = createSelector(
    [get('start'), get('end'), get('displayTooltipMillisThreshold')],
    (start, end, displayTooltipMillisThreshold) => {
      if (!(start instanceof Date) || !(end instanceof Date)) {
        return false
      }

      const startMoment = moment(start)
      const endMoment = moment(end)
      const millisDiff = endMoment.diff(startMoment, 'milliseconds')

      return millisDiff <= displayTooltipMillisThreshold
    }
  )
}
