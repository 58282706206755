import React from 'react'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import { createSelector } from 'reselect'
import { get } from 'lodash/fp/object'

import {
  HISTOGRAM_TYPE_EVENTS_CPIS,
  HISTOGRAM_TYPE_EVENTS_SEVERITY,
  HISTOGRAM_TYPE_CPIS,
  HISTOGRAM_TYPE_EVENTS,
} from '../../constants'

import {
  MARGIN_TOP,
  TILE_HEIGHT,
  TILE_PADDING,
  EMPTY_TILE_HEIGHT,
} from './histogram_data_container'

import UncontrolledTooltip from 'reactstrap/lib/UncontrolledTooltip'

const AreaPlot = (props) => (
  <path fill={props.color} stroke="none" {...props} />
)

class HistogramTile extends React.PureComponent {
  render() {
    const {
      t,
      handleOnMouseOver,
      handleOnMouseOut,
      handleOnMouseClick,
      tilesAreaGenerator,
      interval,
      clipPathId,
      index,
      target,
    } = this.props

    const { tileHeights } = this.selectTileHeights(this.props)
    const { infoText, headerText } = this.selectTileTexts(this.props)
    let currentHeight = TILE_HEIGHT + MARGIN_TOP

    if (interval.data && interval.data.length > 0) {
      return (
        <>
          {
            tileHeights.map(tileHeight => {
              currentHeight -= tileHeight.height
              return (
                <AreaPlot
                  name="tile"
                  id={'tile-' + index}
                  key={'tile-key-' + index + tileHeight.color}
                  clipPath={`url(#${clipPathId})`}
                  opacity={0.33}
                  d={tilesAreaGenerator(
                    interval.segmentStart,
                    interval.segmentEnd,
                    currentHeight,
                    currentHeight + tileHeight.height,
                    TILE_PADDING,
                  )}
                  color={tileHeight.color}
                />
              )
            })
          }

          <AreaPlot
            name="hover"
            id={'hover-' + interval.segmentStart}
            key={'hover-' + interval.segmentStart}
            clipPath={`url(#${clipPathId})`}
            opacity={0}
            style={{ cursor: 'pointer' }}
            onMouseOver={handleOnMouseOver(index)}
            onMouseOut={handleOnMouseOut(index)}
            onClick={handleOnMouseClick(index, interval)}
            d={tilesAreaGenerator(
              interval.segmentStart,
              interval.segmentEnd,
              MARGIN_TOP,
              (TILE_HEIGHT + MARGIN_TOP),
              0,
            )}
          />
          <UncontrolledTooltip
            id={'tooltip-' + interval.segmentStart}
            key={'tooltip-' + interval.segmentStart}
            name="tooltip"
            placement="bottom"
            target={target ? 'hover-' + interval.segmentStart : null}>
            <span className="bold text-white h6">
              {headerText}
            </span>
            <br />
            <span style={{ whiteSpace: 'pre-wrap' }}>
              {infoText}
            </span>
            {infoText && <br />}
            <span>
              {t('text.histogram_click_to_zoom')}
            </span>
          </UncontrolledTooltip>
        </>
      )
    }

    return (
      <AreaPlot
        name="empty-tile"
        id={'empty-tile-' + index}
        key={'empty-tile-key-' + index}
        clipPath={`url(#${clipPathId})`}
        opacity={0.33}
        d={tilesAreaGenerator(
          interval.segmentStart,
          interval.segmentEnd,
          48,
          50,
          3,
        )}
        color={'var(--secondary)'}
      />
    )
  }

  selectTileTexts = createSelector(
    [get('t'), get('interval'), get('histogramOption')],
    (t, interval, histogramOption) => {
      let infoText = ''
      let headerText = ''
      switch (histogramOption) {
        case HISTOGRAM_TYPE_EVENTS_SEVERITY:
        case HISTOGRAM_TYPE_EVENTS_CPIS: {
          infoText = t(`text.${histogramOption}_info`, {
            value: interval.max.toFixed(2),
          })
          headerText = t(`text.${histogramOption}_tooltip`, { value: interval.sum.toFixed(2) })
          break
        }
        case HISTOGRAM_TYPE_EVENTS: {
          headerText = t(`text.${HISTOGRAM_TYPE_EVENTS}_tooltip`, { value: interval.sum })
          break
        }
        case HISTOGRAM_TYPE_CPIS: {
          infoText = t(`text.${HISTOGRAM_TYPE_CPIS}_max_info`, {
            maxValue: interval.max.toFixed(2),
          })
          infoText += '\n'
          infoText += t(`text.${HISTOGRAM_TYPE_CPIS}_avg_info`, {
            avgValue: interval.avg.toFixed(2),
          })
          headerText = t(`text.${HISTOGRAM_TYPE_CPIS}_tooltip`, { value: interval.sum.toFixed(2) })
          break
        }
        default: {
          headerText = t(`text.${histogramOption}_tooltip`, { value: interval.data.length })
        }
      }

      return {
        infoText,
        headerText,
      }
    }
  )

  selectTileHeights = createSelector(
    [get('interval'), get('histogramOption')],
    (interval, histogramOption) => {
      switch (histogramOption) {
        case HISTOGRAM_TYPE_CPIS: {
          const totalTilesHeight = Math.min(TILE_HEIGHT, EMPTY_TILE_HEIGHT + interval.sum / interval.maxValueTileHeight * TILE_HEIGHT) // eslint-disable-line max-len
          const partsOfTile = interval.data.reduce((acc, { color, value }) => {
            if (!acc[color]) {
              acc[color] = 0
            }
            acc[color] += value
            return acc
          }, {})
          const tileHeights = Object.entries(partsOfTile).map(([k, v]) => {
            return {
              color: k,
              height: totalTilesHeight * v / interval.sum,
            }
          })
          return {
            totalTilesHeight,
            tileHeights,
          }
        }
        case HISTOGRAM_TYPE_EVENTS:
        case HISTOGRAM_TYPE_EVENTS_SEVERITY:
        case HISTOGRAM_TYPE_EVENTS_CPIS: {
          const totalTilesHeight = Math.min(TILE_HEIGHT, EMPTY_TILE_HEIGHT + interval.max / interval.maxValueTileHeight * (TILE_HEIGHT - EMPTY_TILE_HEIGHT)) // eslint-disable-line max-len

          const tileHeights = interval.data.map(({ color, data }) => {
            return {
              color: color,
              height: Math.max(totalTilesHeight * data / interval.max, EMPTY_TILE_HEIGHT),
            }
          })
          return {
            totalTilesHeight,
            tileHeights,
          }
        }
        default: {
          const totalTilesHeight = Math.min(TILE_HEIGHT, EMPTY_TILE_HEIGHT + interval.data.length / interval.maxValueTileHeight * (TILE_HEIGHT - EMPTY_TILE_HEIGHT)) // eslint-disable-line max-len
          const partsOfTile = interval.data.reduce((acc, { data, color }) => {
            if (!acc[color]) {
              acc[color] = []
            }
            acc[color].push(data)
            return acc
          }, {})
          const tileHeights = Object.entries(partsOfTile).map(([k, v]) => {
            return {
              color: k,
              height: totalTilesHeight * v.length / interval.data.length,
            }
          })

          return {
            totalTilesHeight,
            tileHeights,
          }
        }
      }
    }
  )
}

export default compose(
  withTranslation([
    'src/analysis_path/pressure_analysis_path/pressure_subset_graph/histogram_layer', // eslint-disable-line max-len
  ]),
)(HistogramTile)
