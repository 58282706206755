import React, { useEffect, useState } from 'react'
import { compose } from 'redux'
import withGoogleMapsProvider from '@@src/components/maps/google_maps_provider'
import requiresLogin from '@@src/components/security/requires_login'
import gql from 'graphql-tag'
import StandardMap from '../components/maps/standard_map'
import MarkerClusterGroup from '@@src/components/maps/marker_cluster_group'
import 'leaflet/dist/leaflet.css'
import NetworkAssetMarker from '@@src/components/maps/markers/network_asset_marker'
import DeviceCommissionMarker from '@@src/components/maps/markers/device_commission_marker'
import { createSelectGraphQLResult } from '@@src/utils'
import { parseGraphQLResult } from '@@src/api/presenters'
import { useQuery } from '@apollo/client'

const DataSourcesWithSelectedSourcesQuery = gql`
  query DataSourcesWithSelectedSources(
    $dataSourceType: String
    $searchQuery: String
    $selectedAssetsIds: [Int]
    $selectedDevicesIds: [Int]
  ) {
    dataSourcesWithSelectedSources(
      dataSourceType: $dataSourceType
      searchQuery: $searchQuery
      selectedAssetsIds: $selectedAssetsIds
      selectedDevicesIds: $selectedDevicesIds
    ) {
      ... on Device {
        id
        serialNumber
        lastCommunication
        commissionStatus
        currentCommission {
          start
          location {
            latitude
            longitude
          }
        }
        activeIssues {
          id
          type
          severity
          description
          deviceId
        }
      }

      ... on NetworkAsset {
        id
        assetId
        assetName
        assetType
        location {
          altitude
          latitude
          longitude
        }
        installations {
          end
          start
          channelMap {
            pressure_1
          }
          deviceId
          device {
            id
            currentCommission {
              start
              end
              location {
                altitude
                latitude
                longitude
              }
            }
            activeIssues {
              id
              type
              severity
              description
              deviceId
            }
          }
        }
      }
    }
  }
`

function MapGEOjson() {
  const [networkAssets, setNetworkAssets] = useState([])
  const [devices, setDevices] = useState([])
  const sourcesData = useQuery(DataSourcesWithSelectedSourcesQuery, {
    variables: {
      selectedAssetsIds: [],
      selectedDevicesIds: [],
    },
  })
  const selectDataSourcesResult = createSelectGraphQLResult(
    'dataSourcesWithSelectedSources',
    {
      mapResult: parseGraphQLResult,
      bufferData: true,
      nullObject: [],
    }
  )
  useEffect(() => {
    const newData = selectDataSourcesResult(sourcesData.data)
    const newDevices =
    newData.data
      .filter((s) => s.__typename === 'Device')
      .filter((s) => s.currentCommission)
    setDevices(newDevices)
    const newAssets = newData.data.filter((s) => s.__typename === 'NetworkAsset')
    setNetworkAssets(newAssets)
  }, [sourcesData])
  const eventsStartDate = new Date()
  eventsStartDate.setHours(0, 0, 0, 0)
  return(
    <div className="d-flex h-100">
      <StandardMap
        name="map-container"
        bounds={undefined}
        className="col-sm-12"
        enableGEOjson={true}
      >
        <MarkerClusterGroup
          showCoverageOnHover={false}
          removeOutsideVisibleBounds={true}
        >
          {devices.map((device) => {
            const commission = device.currentCommission

            return (
              <DeviceCommissionMarker
                key={device.uuid}
                commission={commission}
              >
                {/* {
                      <DeviceCommissionPopup
                        googleMaps={googleMaps}
                        device={device}
                        showDetailsLink={false}
                        showAddLink={true}
                        showDataLink={false}
                        isLimitReached={isLimitReached}
                        selected={false}
                        addToSelected={addToSelected}
                        removeFromSelected={removeFromSelected}
                      />
                    } */}
              </DeviceCommissionMarker>
            )
          })}
          {networkAssets.map((networkAsset) => {

            return (
              <NetworkAssetMarker
                eventsStartDate={eventsStartDate}
                selected={false}
                key={networkAsset.uuid}
                networkAsset={networkAsset}
              >
                {/* <NetworkAssetPopup
                      googleMaps={googleMaps}
                      networkAsset={networkAsset}
                      showDetailsLink={false}
                      showAddLink={true}
                      showDataLink={false}
                      isLimitReached={isLimitReached}
                      selected={selected}
                      addToSelected={addToSelected}
                      removeFromSelected={removeFromSelected}
                    /> */}
              </NetworkAssetMarker>
            )
          })}
        </MarkerClusterGroup>
      </StandardMap>
    </div>
  )
}

export default compose(requiresLogin, withGoogleMapsProvider)(MapGEOjson)
