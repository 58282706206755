import React from 'react'
import { TileLayer } from 'react-leaflet'
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer'

export const DEFAULT_TILE_LAYER_URL = 'https://api.mapbox.com/styles/v1/inflowsys/ckfnllcew0tvn19pc3j15ij9e/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiaW5mbG93c3lzIiwiYSI6ImNqNWF0Z3l0azJwZzIyeWx5ZWtyemt3anMifQ.VfHYd0MuvkqSZNCE0iiD_Q' // eslint-disable-line max-len

export const TILE_MAP = 'map'
export const TILE_SATELLITE = 'satellite'
export const TILE_TYPES = [TILE_MAP, TILE_SATELLITE]

const BaseMapTileComponent = ({ tileType, ...rest }) => {
  switch (tileType) {
    case TILE_SATELLITE:
      return (
        <ReactLeafletGoogleLayer
          {...rest}
          type={'hybrid'}
          useGoogMapsLoader={false}
        />
      )
    default :
      return (
        <TileLayer
          {...rest}
          url={DEFAULT_TILE_LAYER_URL}
          attribution="" />
      )

  }
}

export default BaseMapTileComponent
