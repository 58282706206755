// features
export const CAN_VIEW_ANALYSIS = Object.freeze('can_view_analysis')
export const CAN_EXPORT = Object.freeze('can_export')

// alerts
export const CAN_VIEW_ALERTS = Object.freeze('can_view_alerts')
export const CAN_EDIT_ALERTS = Object.freeze('can_edit_alerts')

// groups
export const CAN_VIEW_GROUPS = Object.freeze('can_view_groups')

// devices
export const CAN_VIEW_DEVICES = Object.freeze('can_view_devices')

// network assets
export const CAN_VIEW_NETWORK_ASSETS = Object.freeze('can_view_network_assets')
export const CAN_EDIT_NETWORK_ASSETS = Object.freeze('can_edit_network_assets')

// users management
export const CAN_MANAGE_USERS = Object.freeze('can_manage_users')
export const CAN_MANAGE_TENANT_SETTINGS = Object.freeze('can_manage_tenant_settings')
