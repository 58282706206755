import React, { useContext, useEffect, useMemo, useState } from 'react'
import { ApolloClient, ApolloLink, ApolloProvider, HttpLink, InMemoryCache } from '@apollo/client'
import { AuthorizationContext } from '../_v2/contexts/authorization/authorization.context'
import createErrorHandlingLink from './create_error_handling_link'
import createRequestAnalyticsLink from './create_request_analytics_link'
import { renderAbsolutePositionedLoading } from '@@src/components/app_suspense_container'
import config from '@@src/config'

const createApolloClient = (token) => {
  if (token) {
    return new ApolloClient({
      link: ApolloLink.from([
        createRequestAnalyticsLink(),
        createErrorHandlingLink(),
        new HttpLink({
          uri: `${config.apiUrl}/graphql/tenant/${config.tenant}`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }),
      ]),
      cache: new InMemoryCache({
        possibleTypes: {
          DataSourceType: ['Device', 'NetworkAsset'],
          PressureSubsetDataItem: [
            'DeviceSubsetDataItem',
            'NetworkAssetSubsetDataItem',
          ],
        },
      }),
    })
  }
  return null
}

const AppApolloProvider = ({ children, hideLoading }) => {
  const { authorization } = useContext(AuthorizationContext)
  const [token, setToken] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchToken = async () => {
      try {
        authorization.getCurrentUserToken().then(setToken)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error fetching token:', error)
      } finally {
        setLoading(false)
      }
    }

    fetchToken()
  }, [authorization])

  const apolloClient = useMemo(() => createApolloClient(token), [token])

  if (loading) {
    return !hideLoading ? renderAbsolutePositionedLoading() : null
  }

  if (apolloClient) {
    return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
  }

  return null
}

export default AppApolloProvider
