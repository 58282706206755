import React from 'react'
import PropTypes from 'prop-types'

import AsyncResult from '@@src/utils/async_result'
import AnalysisGraphToolbar
from '../analysis_graph_toolbar'
import PressureSubsetGraphProgressBar
from '@@src/components/progress_bars/pressure_subset_graph_progress_bar/pressure_subset_graph_progress_bar'
import { HISTOGRAM_TYPES } from '../constants'

import styles from './pressure_subset_graph_toolbar.css'

export default class PressureSubsetGraphToolbar extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    displayRawData: PropTypes.bool.isRequired,
    displayEvents: PropTypes.bool.isRequired,
    displayAlerts: PropTypes.bool.isRequired,
    displayAnomalies: PropTypes.bool.isRequired,
    histogramOption: PropTypes.oneOf(HISTOGRAM_TYPES).isRequired,
    alertsResult: PropTypes.instanceOf(AsyncResult).isRequired,
    isOnlyRawData: PropTypes.bool.isRequired,
    isRawDataAvailable: PropTypes.bool.isRequired,
    rawDataRequestsResult: PropTypes.instanceOf(AsyncResult).isRequired,
    pressureEventsWithData: PropTypes.instanceOf(AsyncResult).isRequired,
    anomalyResult: PropTypes.instanceOf(AsyncResult).isRequired,
    onChangeOptions: PropTypes.func.isRequired,
    setHistogramOption: PropTypes.func.isRequired,
  }

  render() {
    const {
      className, alertsResult, pressureEventsWithData, rawDataRequestsResult,
      resolution, isOnlyRawData, isRawDataAvailable,
      displayEvents, displayAlerts, displayAnomalies, displayRawData, onChangeOptions,
      histogramOption, setHistogramOption, anomalyResult,
    } = this.props

    return (
      <AnalysisGraphToolbar
        className={className}
        displayEvents={displayEvents}
        displayAlerts={displayAlerts}
        displayAnomalies={displayAnomalies}
        displayRawData={displayRawData}
        onChangeOptions={onChangeOptions}
        histogramOption={histogramOption}
        setHistogramOption={setHistogramOption}>
        <PressureSubsetGraphProgressBar
          className={styles.progressbar}
          resolution={resolution}
          alertsResult={alertsResult}
          isOnlyRawData={isOnlyRawData}
          isRawDataAvailable={isRawDataAvailable}
          rawDataRequestsResult={rawDataRequestsResult}
          pressureEventsWithData={pressureEventsWithData}
          anomalyResult={anomalyResult} />
      </AnalysisGraphToolbar>
    )
  }
}
