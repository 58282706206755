import React from 'react'

import * as analytics from '@@src/analytics'
import ErrorPage from './index_page'

class ErrorWrapper extends React.PureComponent {

  constructor(props) {
    super(props)

    this.state = {
      hasError: false,
      errorLocation: {
        search: '',
        pathname: '',
      },
    }
  }

  componentDidCatch = (error, errorInfo) => {
    const { location } = this.props
    analytics.trackError(error, errorInfo)

    this.setState({
      hasError: true,
      errorLocation: location,
    })
  }

  componentWillReceiveProps = (newProps) => {
    const { location } = newProps
    const { errorLocation } = this.state

    if (location !== errorLocation) {
      this.errorResolved()
    }
  }

  errorResolved = () => {
    this.setState({
      hasError: false,
      errorLocation: {
        search: '',
        pathname: '',
      },
    })
  }

  render() {
    const { children } = this.props
    const { hasError } = this.state

    if (hasError) {
      return <ErrorPage errorResolved={this.errorResolved} />
    } else {
      return children
    }
  }

}

export default ErrorWrapper
