import React from 'react'

export default function DragLayerMaxValueIcon({ x, y, fill }) {
  return(
    <svg x={x - 9} y={y - 18} width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.714 2H1.286C.576 2 0 2.576 0 3.286v4.857c0 .473.429.902 1.286 1.286L6 12l4.714-2.571C11.571
          9.045 12 8.616 12 8.143V3.286C12 2.576 11.424 2 10.714 2z"
        fill={fill}
        transform="scale(1.5)"
        fillRule="evenodd"/>
    </svg>
  )
}
