import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import {
  FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, Button,
} from 'reactstrap'

import AppButton from '@@src/components/buttons/app_button'
import DatetimePicker from '@@src/components/forms/datetime_picker'
import { HOURS, MINUTES, formatTimestampForAPI } from '@@src/utils'

import styles from './mute_alerts_modal.css'

const OPTION_ONE_HOUR = 'one-hour'
const OPTION_24_HOURS = '24-hours'
const OPTION_ONE_WEEK = 'one-week'
const OPTION_CUSTOM = 'custom'

const ALL_OPTIONS = [
  OPTION_ONE_HOUR,
  OPTION_24_HOURS,
  OPTION_ONE_WEEK,
  OPTION_CUSTOM,
]

function nearestMinute(date = new Date()) {
  return new Date(Math.ceil(Number(date) / MINUTES) * MINUTES)
}

function mapOptionToTime(option, customDate, now = new Date()) {
  switch (option) {
    case OPTION_ONE_HOUR:
      return nearestMinute(now.getTime() + 1 * HOURS)

    case OPTION_24_HOURS:
      return moment(now).add(1, 'days').toDate()

    case OPTION_ONE_WEEK:
      return moment(now).add(7, 'days').toDate()

    case OPTION_CUSTOM:
      return customDate

    default:
      throw new Error(`Unrecognised mute option "${option}"`)
  }
}

class MuteAlertsModal extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    muteAlerts: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    handleToggle: PropTypes.func.isRequired,
    muteAlertsVariables: PropTypes.object.isRequired,
  }

  static defaultProps = {
    isOpen: false,
    muteAlertsVariables: {},
  }

  state = this.initialState()

  render() {
    const { t, isOpen, handleToggle } = this.props
    const { customDate, selectedOption } = this.state

    return (
      <Modal isOpen={isOpen} toggle={handleToggle}>
        <ModalHeader>
          {t('headings.modal_heading')}
        </ModalHeader>

        <ModalBody>
          <FormGroup tag="fieldset">
            <span>{t('text.mute_info')}</span>

            {ALL_OPTIONS.map(this.renderRadioOption)}

            {
              selectedOption === OPTION_CUSTOM ? (
                <DatetimePicker
                  name="custom-date-picker"
                  dateFormat="DD/MM/YYYY"
                  className={styles.datepicker}
                  isValidDate={isInTheFuture}
                  defaultValue={customDate}
                  onChange={this.onChangeCustomDate}>
                </DatetimePicker>
              ) : null
            }
          </FormGroup>
        </ModalBody>

        <ModalFooter>
          <Button
            name="cancel-button"
            color="secondary"
            onClick={handleToggle}>
            {t('buttons.cancel')}
          </Button>

          <AppButton
            name="mute-button"
            color="primary"
            onClick={this.handleSubmit}>
            {t('buttons.mute_alerts')}
          </AppButton>
        </ModalFooter>
      </Modal>
    )
  }

  renderRadioOption = option => {
    const { t } = this.props
    const { selectedOption } = this.state

    return (
      <FormGroup check key={option}>
        <Label check>
          <Input
            type="radio"
            name={`${option}-option`}
            value={option}
            checked={selectedOption === option}
            onChange={this.onChangeOption}/>
          &nbsp;
          {t(`labels.option_${option.replace('-', '_')}`)}
        </Label>
      </FormGroup>
    )
  }

  initialState() {
    return {
      customDate: nearestMinute(),
      selectedOption: ALL_OPTIONS[0],
    }
  }

  componentDidMount() {
    this.setState(this.initialState())
  }

  onChangeOption = event => {
    this.setState({ selectedOption: event.target.value })
  }

  onChangeCustomDate = date => {
    this.setState({ customDate: date })
  }

  handleSubmit = async event => {
    const {
      muteAlerts, muteAlertsVariables, handleSubmit, handleToggle,
    } = this.props
    const { selectedOption, customDate } = this.state

    const now = new Date()
    await muteAlerts({
      variables: {
        ...muteAlertsVariables,
        startTime: formatTimestampForAPI(now),
        endTime: formatTimestampForAPI(mapOptionToTime(
          selectedOption,
          customDate,
          now,
        )),
      },
    })

    await handleSubmit()
    await handleToggle(event)
  }
}

function isInTheFuture(date) {
  return date.isAfter(moment().subtract(1, 'days'))
}

export default withTranslation([
  'src/components/modals/mute_alerts_modal',
])(MuteAlertsModal)
