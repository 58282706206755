import React, { useEffect, useRef } from 'react'
import { Card } from 'reactstrap'
import { DeviceCommission } from '../../../../../../../api/models/device_commission'
import InstallOnExistingAssetListItem from './install_on_existing_asset_list_item.component'
import LoadingIcon from '../../../../../../../../components/loading_icon'
import { useTranslation } from 'react-i18next'
import useGetNetworkAssetInstallations from '../../../../hooks/use_get_network_asset_installations'

import PropTypes from 'prop-types'

const InstallOnExistingAssetList = ({
  createInstallation,
  currentCommission,
  filters,
  listItemClick,
  selectedListItem,
  isInstalling,
}) => {
  const listRef = useRef(null)

  const { t } = useTranslation('v2/network_assets/forms')
  const { networkAssetsWithInstallations, isLoading } = useGetNetworkAssetInstallations(filters)

  useEffect(() => {
    const childNode = selectedListItem && listRef.current.querySelector(`#list-item-${selectedListItem}`)
    if(childNode) {
      const childRect = childNode.getBoundingClientRect()
      const containerRect = listRef.current.getBoundingClientRect()
      if(childRect.top > containerRect.bottom || childRect.bottom < containerRect.top) {
        childNode.scrollIntoView()
      }
    }
  }, [selectedListItem])

  if(isLoading || !currentCommission) {
    return <LoadingIcon />
  }

  if(!networkAssetsWithInstallations) {
    return(
      <h1
        className="justify-self-center align-self-center">
        {t('install_device_modal.select_existing_tab.no_assets_found')}
      </h1>
    )
  }

  return(
    <Card innerRef={listRef} className="overflow-auto p-2 flex-grow mb-2">
      {
        networkAssetsWithInstallations.map((networkAssetWithInstallation, index) => {
          return(
            <InstallOnExistingAssetListItem
              key={index}
              isInstalling={isInstalling}
              currentCommission={currentCommission}
              onClick={() => listItemClick(networkAssetWithInstallation)}
              onInstall={(channel, startDate, endDate) =>
                createInstallation(networkAssetWithInstallation.assetId, channel, startDate, endDate)}
              isSelected={selectedListItem === networkAssetWithInstallation.assetId}
              assetWithInstallation={networkAssetWithInstallation} />
          )
        })
      }
    </Card>
  )
}

InstallOnExistingAssetList.propTypes = {
  createInstallation: PropTypes.func,
  currentCommission: PropTypes.instanceOf(DeviceCommission),
  filters: PropTypes.shape({
    search: PropTypes.string,
    filters: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  listItemClick: PropTypes.func,
  selectedListItem: PropTypes.number,
  isInstalling: PropTypes.bool,
}

export default InstallOnExistingAssetList
