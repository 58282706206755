import React from 'react'
import PropTypes from 'prop-types'
import { get, values } from 'lodash/fp/object'
import { createSelector } from 'reselect'

import GraphItem from '@@src/components/graphs/graph_item'
import AsyncResult from '@@src/utils/async_result'
import PressureSubsetData from '@@src/api/presenters/pressure_subset_data'

export default function withNetworkAssetsAndGraphItems(Component) {
  return class PressureSubsetGraphContainer extends React.PureComponent {
    static propTypes = {
      legendItemsResult: PropTypes.instanceOf(AsyncResult).isRequired,
    }

    render() {
      const graphItemsResult = this.selectGraphItemsResult(this.props)

      return (
        <Component
          graphItems={graphItemsResult.data}
          graphItemsResult={graphItemsResult}
          networkAssetGraphItems={
            this.selectNetworkAssetGraphItems(graphItemsResult.data)
          }
          {...this.props} />
      )
    }

    selectNetworkAssetGraphItems = createSelector(
      [a => a],
      (graphItems = []) => {
        return graphItems.filter(i => i.isFromNetworkAsset())
      }
    )

    selectPressureSubsetWithRawDataResult = createSelector(
      [
        get('pressureSubsetDataResult'),
        get('rawDataRequestsResult'),
        get('resolution'),
      ],
      (subsetResult, rawDataResult, resolution) => {
        return subsetResult.and(rawDataResult).map(combined => {
          const [{ data: segments }, rawReqs] = combined
          const aggregatedChunks = (segments || []).reduce((acc, segment) => {
            const sourceKey = segment.networkAssetId ?
              `${segment.networkAssetId}:${segment.channel}` :
              String(segment.deviceId)
            const entry = acc[sourceKey] || {
              data: [],
              channel: segment.channel,
              deviceId: segment.deviceId,
              networkAssetId: segment.networkAssetId,
            }

            return {
              ...acc,
              [sourceKey]: {
                ...entry,
                data: entry.data.concat(segment.data),
              },
            }
          }, {})

          return values(aggregatedChunks).map(chunk => PressureSubsetData.from({
            ...chunk,
            resolution,
            rawDataRequests: rawReqs.filter(rawReq => {
              // does not check if the request overlaps with the
              // installation, if such an unlikely scenario occurs it
              // would simply result in an overlapping raw data plot
              return rawReq.data.length > 0 &&
                rawReq.deviceId === chunk.deviceId &&
                rawReq.networkAssetId === chunk.networkAssetId &&
                rawReq.channel === chunk.channel
            }),
          }))
        })
      }
    )

    selectGraphItemsResult = createSelector(
      [
        this.selectPressureSubsetWithRawDataResult,
        get('legendItemsResult'),
        get('resolution'),
      ],
      (result, legendItemsResult, resolution) => {
        return result.and(legendItemsResult)
          .map(([pressureSubsetData, legendItems]) => {
            const deviceSubsetData = pressureSubsetData.filter(subsetData => {
              return subsetData.isFromDevice()
            })
            const assetSubsetData = pressureSubsetData.filter(subsetData => {
              return subsetData.isFromNetworkAsset()
            })

            return legendItems.map(legendItem => {
              if (legendItem.isFromDevice()) {
                const subsetData = deviceSubsetData.find(data => {
                  return data.deviceId === legendItem.sourceId
                })

                return GraphItem.fromLegendItemAndSubsetData(
                  legendItem,
                  subsetData ? subsetData : PressureSubsetData.from({
                    data: [],
                    deviceId: legendItem.sourceId,
                    resolution,
                  }),
                )
              } else {
                const subsetData = assetSubsetData.find(data => {
                  return data.networkAssetId === legendItem.sourceId &&
                    data.channel === legendItem.sourceChannel
                })

                return GraphItem.fromLegendItemAndSubsetData(
                  legendItem,
                  subsetData ? subsetData : PressureSubsetData.from({
                    data: [],
                    channel: legendItem.sourceChannel,
                    resolution,
                    networkAssetId: legendItem.sourceId,
                  }),
                )
              }
            }).filter(item => item.visible)
          })
      }
    )
  }
}
