import classnames from 'classnames'
import React, { useEffect, useMemo, useState } from 'react'
import { withTranslation } from 'react-i18next'
import {
  Button,
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  InputGroup,
  InputGroupText,
} from 'reactstrap'
import {
  PRESSURE_DATA_FILTERS,
  getFiltersForLicenceFeatures,
} from '../../../../../../components/lists/utils'
import FiltersAlert from '../../../../../components/filters_alert/filter_alert.component'
import { isFilterAdvanced } from '../../utils/filters'
import NetworkAssetListAdvancedFilterModal from './network_assets_list_advanced_filter_modal.component'

import './network_assets_list_filters.component.scss'

const NetworkAssetsListFiltersComponent = ({
  t,
  filters: currentFilters,
  setFilters,
}) => {
  const [search, setSearch] = useState(currentFilters.search)
  const [focused, setFocused] = useState(false)

  const filtersList = useMemo(() => {
    // todo:tenantLicenceFeatures
    const tenantLicenceFeatures = []
    const pressureDataFilters = PRESSURE_DATA_FILTERS.map((filter) => {
      return {
        value: filter,
        text: t(`v2/network_assets/filters:options.${filter}`),
      }
    })
    return [
      ...getFiltersForLicenceFeatures(tenantLicenceFeatures, t).concat(
        pressureDataFilters,
      ),
    ].sort((f) => f.text)
  }, [t])

  const hasAdvancedFiltersSelected =
    !!currentFilters.filters.find(isFilterAdvanced)
  const selectedBasicFilter = useMemo(() => {
    return (
      currentFilters.filters.length === 1 &&
      filtersList.find((filter) => filter.value === currentFilters.filters[0])
    )
  }, [currentFilters.filters])

  const [dropdownOpen, setDropdownOpen] = useState(false)
  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState)

  const [dropdownPopupOpen, setDropdownPopupOpen] = useState(false)
  const toggleDropdownPopup = () => {
    setDropdownPopupOpen((prevState) => !prevState)
    setDropdownOpen(false)
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (currentFilters.search !== search) {
        setFilters({ ...currentFilters, search })
      }
    }, 500)

    return () => clearTimeout(timeout)
  }, [search])

  const onChangeBasicFilter = (filter) => {
    setFilters({ ...currentFilters, filters: [filter] })
  }

  const handleChangeAdvancedFilter = (filters) => {
    const onlyAdvancedFilters = filters.filter(isFilterAdvanced)
    setFilters({ ...currentFilters, filters: onlyAdvancedFilters })
  }

  const handleClearFilters = () => {
    setDropdownPopupOpen(false)
    setFilters({ ...currentFilters, filters: ['any'] })
  }

  return (
    <div className="w-100 d-flex flex-column justify-content-between mt-4">
      <div className="d-flex flex-row justify-content-between align-items-center">
        <NetworkAssetListAdvancedFilterModal
          isOpen={dropdownPopupOpen}
          activeFilterList={currentFilters.filters}
          toggle={toggleDropdownPopup}
          handleAccept={handleChangeAdvancedFilter}
          clearFilters={handleClearFilters}
        />
        <InputGroup style={{ maxWidth: 220 }} className={''}>
          <Input
            className={`border-right-0 shadow-none ${
              focused ? 'border-right-0 border-primary' : ''
            }`}
            placeholder={t('v2/common/text:search_placeholder')}
            value={search}
            onChange={(e) => setSearch(e.target.value || '')}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
          />

          <InputGroupText
            className={`bg-white text-black-50 ${
              focused ? 'border-primary' : ''
            }`}
          >
            <i className="fa fa-search"></i>
          </InputGroupText>
        </InputGroup>

        <ButtonDropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
          <DropdownToggle caret className={'dropdown-filter-button'}>
            {t('v2/common/buttons:filter')}&nbsp;
          </DropdownToggle>
          <DropdownMenu className="dropdown-scroll-menu">
            {filtersList.map((filter, i) => {
              return (
                <DropdownItem
                  key={i}
                  value={filter.value}
                  onClick={() => onChangeBasicFilter(filter.value)}
                >
                  <div className="d-flex align-items-center justify-content-between">
                    <div
                      className={classnames({
                        'font-weight-bold':
                          selectedBasicFilter &&
                          selectedBasicFilter.value === filter.value,
                      })}
                    >
                      {filter.text}
                    </div>
                    {selectedBasicFilter &&
                      selectedBasicFilter.value === filter.value && (
                        <i
                          name="check-icon"
                          className={'fa fa-check font-weight-normal pl-1'}
                        />
                      )}
                  </div>
                </DropdownItem>
              )
            })}
            <Button
              name="advanced-filter-modal-btn"
              type="button"
              className="mt-2 d-flex justify-content-between align-items-center"
              size="sm"
              block={true}
              color="link"
              onClick={toggleDropdownPopup}
            >
              <span />
              <span
                className={classnames({
                  'font-weight-bold': hasAdvancedFiltersSelected,
                })}
              >
                {t('v2/common/buttons:advanced')}
              </span>
              <span
                name="check-icon"
                className={classnames({
                  'fa fa-check': hasAdvancedFiltersSelected,
                })}
              />
            </Button>
          </DropdownMenu>
        </ButtonDropdown>
      </div>
      <FiltersAlert
        t={(filterText) => t(`v2/network_assets/filters:options.${filterText}`)}
        activeFilters={currentFilters.filters.filter(
          (filter) => filter !== 'any',
        )}
        onClick={handleClearFilters}
      />
    </div>
  )
}

export default withTranslation([
  'v2/network_assets/filters',
  'common/text',
  'src/components/forms/inputs/location_information_input_group',
  'src/management_path/network_assets_path/network_asset_form_modal',
])(NetworkAssetsListFiltersComponent)
