import moment from 'moment'

import { isValidTimezone } from '@@src/utils'

export default class DeviceConfig {
  constructor(data) {
    Object.assign(this, data)

    this.dateFromInLocalTime = data.dateFrom.replace(/[\+-]\d{2}:\d{2}/, '')
    this.momentDate = isValidTimezone(data.timeZone) ?
      moment.tz(this.dateFromInLocalTime, data.timeZone) : moment(data.dateFrom)
  }
}
