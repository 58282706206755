import React, { useCallback, useState } from 'react'
import { Button, FormGroup, Input, Label } from 'reactstrap'
import Datetime from 'react-datetime'
import NetworkAssetIcon from '../../../../../../../components/network_asset/network_asset_icon.component'
import LoadingIcon from '../../../../../../../../components/loading_icon'
import { NetworkAssetWithInstallations } from '../../../../../../../api/models/network_asset_with_installations'
import { mapAssetTypeToLogicalChannels } from '../../../../../../../../utils'
import { useTranslation } from 'react-i18next'
import { DeviceCommission } from '../../../../../../../api/models/device_commission'
import moment from 'moment'
import classnames from 'classnames'

import PropTypes from 'prop-types'

const InstallOnExistingAssetListItem = ({
  onClick = () => null,
  onInstall = () => null,
  currentCommission,
  isInstalling,
  assetWithInstallation,
  isSelected,
}) => {
  const logicalChannel = mapAssetTypeToLogicalChannels(assetWithInstallation.type)
  const { t } = useTranslation('v2/common/buttons')
  const checkIfDisabled = (channel) => {
    return assetWithInstallation.installedDevices.find(installation =>
      installation.logicalChannel === channel && installation.status === 'installed')
  }

  const [selectedChannel, setSelectedChannel] = useState(logicalChannel.filter(channel => !checkIfDisabled(channel))[0])
  const [selectedDate, setSelectedDate] = useState(moment(currentCommission.whenCommissioned))

  const handleInstall = useCallback(() => {
    onInstall(selectedChannel, selectedDate.toDate(), currentCommission.whenDecommissioned)
  }, [selectedChannel, selectedDate, currentCommission])

  const checkIfDateIsValid = useCallback((date) => {
    if(currentCommission) {
      const isAfterCommissionStart = date.isAfter(moment(currentCommission.whenCommissioned).subtract(1, 'day'))
      const isBeforeCommissionEnd =
      currentCommission.whenDecommissioned ?
        date.isBefore(moment(currentCommission.whenDecommissioned).add(1, 'day'))
        :
        true
      return(isAfterCommissionStart && isBeforeCommissionEnd)
    }
    return true
  }, [currentCommission])

  return(
    <div
      id={`list-item-${assetWithInstallation.assetId}`}
      className={classnames('d-flex flex-column p-2', {
        'list-item-active': isSelected,
      })}
    >
      <div className="d-flex flex-row" onClick={onClick}>
        <NetworkAssetIcon
          type={assetWithInstallation.type}
          style={{ marginLeft: '0.5rem', marginRight: '1.5rem', width: '2rem', height: '2rem' }} />
        <div className="d-flex flex-column w-75">
          <h2>{assetWithInstallation.name}</h2>
          <i>{assetWithInstallation.readableName}</i>
          <small><i>{assetWithInstallation.type}</i></small>
        </div>
      </div>
      {
        isSelected &&
          <div className="pl-5">

            <FormGroup tag="fieldset">
              {
                logicalChannel.map(channel => (
                  <FormGroup key={channel} check>
                    <Label check>
                      <Input
                        type="radio"
                        disabled={!!checkIfDisabled(channel)}
                        checked={selectedChannel === channel}
                        onChange={() => setSelectedChannel(channel)}
                        name={'channel' + channel} />
                      {channel}
                    </Label>
                  </FormGroup>
                )
                )
              }
            </FormGroup>
            <div className="d-flex flex-row">
              <Button
                id="install-asset-button"
                name="install-asset-button"
                color="primary"
                className="mr-2"
                disabled={isInstalling}
                onClick={handleInstall} >
                {
                  isInstalling ?
                    <LoadingIcon />
                    :
                    <span>{t('install')}</span>
                }
              </Button>
              <Datetime
                value={selectedDate}
                isValidDate={checkIfDateIsValid}
                onChange={setSelectedDate} />
            </div>
          </div>
      }
    </div>
  )
}

InstallOnExistingAssetListItem.propTypes = {
  onClick: PropTypes.func,
  onInstall: PropTypes.func,
  currentCommission: PropTypes.instanceOf(DeviceCommission),
  isInstalling: PropTypes.bool,
  assetWithInstallation: PropTypes.instanceOf(NetworkAssetWithInstallations),
  isSelected: PropTypes.bool,
}

export default InstallOnExistingAssetListItem
