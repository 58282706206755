import { last } from 'lodash/fp/array'

import { DAYS, SECONDS, MINUTES, HOURS } from '@@src/utils'

export const SMALLEST_SAMPLE = 1000
export const RESOLUTION_OPTIONS = [
  1 * SECONDS,
  5 * SECONDS,
  30 * SECONDS,
  1 * MINUTES,
  2 * MINUTES,
  5 * MINUTES,
  10 * MINUTES,
  15 * MINUTES,
  30 * MINUTES,
  45 * MINUTES,
  1 * HOURS,
  4 * HOURS,
  12 * HOURS,
  1 * DAYS,
]

export default function optimizeSubsetDataQuery({
  end,
  start,
  maxPoints = 220,
  bufferLength = 1,
}) {
  const startTime = start.getTime()
  const endTime = end.getTime()

  const duration = Math.max(endTime - startTime, SMALLEST_SAMPLE)
  const resolutionInMillis = RESOLUTION_OPTIONS.find(resolutionOption => {
    return (duration / resolutionOption) < maxPoints
  }) || last(RESOLUTION_OPTIONS)

  return {
    end: Math.ceil((endTime + bufferLength * resolutionInMillis) / SMALLEST_SAMPLE) * SMALLEST_SAMPLE, // eslint-disable-line max-len
    start: Math.floor((startTime - bufferLength * resolutionInMillis) / SMALLEST_SAMPLE) * SMALLEST_SAMPLE, // eslint-disable-line max-len
    resolution: Math.round(resolutionInMillis / SECONDS),
  }
}
