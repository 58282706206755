import React from 'react'
import PropTypes from 'prop-types'
import { difference } from 'lodash/fp/array'

import AppDropdownItem from './app_dropdown_item'

const noop = () => { }

const DropdownGroupContext = React.createContext()

const DropdownGroupItemContainer = props => (
  <DropdownGroupContext.Consumer>
    {({ selection, onChangeSelection }) => (
      <DropdownGroupItem
        selection={selection}
        onChangeSelection={onChangeSelection}
        {...props}/>
    )}
  </DropdownGroupContext.Consumer>
)

class DropdownGroupItem extends React.PureComponent {
  static propTypes = {
    value: PropTypes.any.isRequired,
    selection: PropTypes.array.isRequired,
    onChangeSelection: PropTypes.func.isRequired,
  }

  render() {
    const { value, children, selection, partial } = this.props

    return (
      <AppDropdownItem
        value={value}
        active={selection.includes(value)}
        partial={partial}
        onClick={this.onClick}>
        {children}
      </AppDropdownItem>
    )
  }

  onClick = event => {
    const { value, selection, onChangeSelection, onClick } = this.props

    const newSelection = selection.includes(value) ?
      difference(selection, [value]) : selection.concat(value)

    if (onChangeSelection) {
      onChangeSelection(newSelection, value)
    }

    if (onClick) {
      onClick(event)
    }
  }
}

export default class DropdownGroup extends React.PureComponent {
  static Item = DropdownGroupItemContainer

  static propTypes = {
    selection: PropTypes.array.isRequired,
    onChangeSelection: PropTypes.func.isRequired,
  }

  static defaultProps = {
    selection: [],
    onChangeSelection: noop,
  }

  render() {
    const { children } = this.props

    return (
      <DropdownGroupContext.Provider value={this.props}>
        {children}
      </DropdownGroupContext.Provider>
    )
  }
}
