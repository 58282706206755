import React from 'react'
import PropTypes from 'prop-types'
import { omit } from 'lodash/fp/object'
import { Input } from 'reactstrap'
import { createSelector } from 'reselect'

import DebouncedInputController from '@@src/utils/debounced_input_controller'

export default class DebouncedInput extends React.PureComponent {
  static defaultProps = {
    controller: new DebouncedInputController(),
  }

  static propTypes = {
    controller: PropTypes.instanceOf(DebouncedInputController).isRequired,
  }

  render() {
    const { value } = this.state
    const inputProps = this.selectFilteredProps(this.props)

    return <Input {...inputProps} value={value} onChange={this.onChange}/>
  }

  constructor(props) {
    super(props)

    this.state = { value: props.initialValue || '', timeout: null }
  }

  componentDidMount() {
    const { controller } = this.props

    controller.subscribe(this.handleDebouncedInputControllerEvents)

    this.setState({ value: this.props.initialValue || '', timeout: null })
  }

  onChange = event => {
    event.persist()

    if (this.state.timeout) {
      clearTimeout(this.state.timeout)
    }

    const debounce = Number.isFinite(this.props.debounce) ?
      this.props.debounce : 500

    const value = typeof event.target.value === 'string' ? event.target.value.substring(0, 200) : event.target.value
    this.setState({
      value,
      timeout: setTimeout(() => {
        this.props.onChange(event)
      }, debounce),
    })
  }

  handleDebouncedInputControllerEvents = event => {
    switch (event.type) {
      case DebouncedInputController.CLEAR_VALUE_EVENT:
        this.setState({ value: '' })
        break

      default:
        console.warn('Unhandled debounced input event:', event) // eslint-disable-line no-console, max-len
        break
    }
  }

  selectFilteredProps = createSelector(
    [props => props],
    omit(['initialValue', 'onChange'])
  )
}
