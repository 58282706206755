import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from '@apollo/client/react/hoc'
import gql from 'graphql-tag'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import { noop } from 'lodash/fp/util'

import AsyncResult from '@@src/utils/async_result'
import MessageBox from '@@src/components/modals/message_box'
import * as analytics from '@@src/analytics'
import EventSourceLocalisationStore from '@@src/store/event_source_localisation'

class ToggleGroupAlertStatusPrompt extends React.PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    setGroupAlertStatus: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    newStatus:
      PropTypes.oneOf(EventSourceLocalisationStore.ALERT_STATUSES).isRequired,
    handleCancel: PropTypes.func.isRequired,
    handleSuccess: PropTypes.func.isRequired,
    heatmapIds: PropTypes.arrayOf(PropTypes.number),
    groupId: PropTypes.number,
    children: PropTypes.node,
  }

  static defaultProps = {
    handleCancel: noop,
    handleSuccess: noop,
  }

  state = {
    changeStatusResult: AsyncResult.notFound(),
    isOpen: false,
  }

  render() {
    const { t, isOpen, children } = this.props
    const { changeStatusResult } = this.state

    return (
      <MessageBox
        toggle={this.handleCancel}
        result={changeStatusResult}
        onAccept={this.handleAccept}
        acceptText={t('buttons.accept')}
        cancelText={t('buttons.cancel')}
        headerText={t('headings.change_alert_status')}
        isOpen={isOpen}>
        {children}
      </MessageBox>
    )
  }

  handleAccept = async () => {
    const { setGroupAlertStatus, newStatus, groupId, heatmapIds } = this.props

    this.setState({ changeStatusResult: AsyncResult.pending() })

    const variables = {
      status: newStatus,
      groupId,
    }

    if (Array.isArray(heatmapIds)) {
      variables.heatmapIds = heatmapIds
    }

    try {
      await setGroupAlertStatus({ variables })
      this.handleSuccess()
    } catch (err) {
      analytics.logError(err)
      this.setState({ changeStatusResult: AsyncResult.fail(err) })
    }
  }

  handleCancel = () => {
    this.setState({ changeStatusResult: AsyncResult.notFound() })

    this.props.handleCancel()
  }

  handleSuccess = () => {
    this.setState({ changeStatusResult: AsyncResult.notFound() })

    this.props.handleSuccess()
  }

  static GROUP_ALERT_SET_STATUS_MUTATION = gql`
    mutation UpdateGroupAlertStatus(
      $status: EventSourceLocalisationStatus!,
      $groupId: Int!,
      $heatmapIds: [Int]
    ) {
      updateGroupAlertStatus (
        status: $status,
        groupId: $groupId,
        heatmapIds: $heatmapIds
      )
    }
  `
}

export default compose(
  withTranslation([
    'src/components/modals/toggle_group_alert_status_prompt',
  ]),
  graphql(ToggleGroupAlertStatusPrompt.GROUP_ALERT_SET_STATUS_MUTATION, {
    name: 'setGroupAlertStatus',
  }),
)(ToggleGroupAlertStatusPrompt)
