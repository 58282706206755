import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import * as Changes from '@@src/store/changes'
import CsvExportConfigurationModal from '@@src/components/modals/csv_export_configuration_modal/csv_export_configuration_modal'

import styles from './export_dropdown_with_modal.css'
import { EXPORT_ANOMALY_REPORT_TYPE } from '@@src/analysis_path/pressure_analysis_path/anomaly_list/consts'

export const EXPORT_PRESSURE_DATA_TYPE = 'pressureTimeseries'
export const EXPORT_CPIS_DATA_TYPE = 'cpisTimeseries'
export const EXPORT_DEVICES_DATA_TYPE = 'devices'
export const EXPORT_DEVICES_DATA_TYPE_V2 = 'devicesV2'
export const EXPORT_DEVICES_HEALTH_DATA_TYPE = 'devicesHealth'
export const EXPORT_ALERTS_DATA_TYPE = 'alerts'
export const EXPORT_NETWORK_ASSETS_DATA_TYPE = 'networkAssets'
export const EXPORT_RAW_DATA_TYPE = 'rawTimeseries'
export const EXPORT_EVENTS_DATA_TYPE = 'events'
export const EXPORT_CSV_REPORT_TYPE = 'csv'
export const EXPORT_REPORT_TYPE = [EXPORT_CSV_REPORT_TYPE]
export const EXPORT_DATA_TYPE = [
  EXPORT_PRESSURE_DATA_TYPE,
  EXPORT_CPIS_DATA_TYPE,
  EXPORT_DEVICES_DATA_TYPE,
  EXPORT_DEVICES_DATA_TYPE_V2,
  EXPORT_ALERTS_DATA_TYPE,
  EXPORT_NETWORK_ASSETS_DATA_TYPE,
  EXPORT_EVENTS_DATA_TYPE,
  EXPORT_RAW_DATA_TYPE,
  EXPORT_DEVICES_HEALTH_DATA_TYPE,
  EXPORT_ANOMALY_REPORT_TYPE,
]

class ExportDropdownWithModal extends PureComponent {
  static propTypes = {
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOf(EXPORT_REPORT_TYPE).isRequired,
        text: PropTypes.string.isRequired,
      })
    ).isRequired,
    isOpen: PropTypes.bool.isRequired,
    onChangeValue: PropTypes.func.isRequired,
    onToggle: PropTypes.func.isRequired,
    canPerformAction: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    dataType: PropTypes.oneOf(EXPORT_DATA_TYPE).isRequired,
    startTime: PropTypes.instanceOf(Date).isRequired,
    endTime: PropTypes.instanceOf(Date).isRequired,
    timeZone: PropTypes.string.isRequired,
    legendItemsResult: PropTypes.object.isRequired,
    className: PropTypes.string,
  }

  static defaultProps = {
    isOpen: false,
  }

  state = {
    isCsvExportModalOpen: false,
  }

  handleClick = () => {
    const { onToggle, canPerformAction } = this.props

    if (!canPerformAction()) {
      return
    }

    onToggle()
    this.setState({ isCsvExportModalOpen: true })
  }

  renderDropdownItems = () => {
    const { options } = this.props

    return (
      <DropdownMenu>
        {options.map((f, i) => {
          return (
            <DropdownItem
              className={styles['dropdown-item']}
              key={i}
              value={f.value}
              onClick={this.handleClick}
            >
              {f.text}
            </DropdownItem>
          )
        })}
      </DropdownMenu>
    )
  }

  render() {
    const {
      t,
      isOpen,
      endTime,
      startTime,
      timeZone,
      onToggle,
      dataType,
      legendItemsResult,
      className,
    } = this.props
    const { isCsvExportModalOpen } = this.state

    return (
      <React.Fragment>
        <CsvExportConfigurationModal
          isOpen={isCsvExportModalOpen}
          toggle={this.onCsvExportModalToggle}
          endTime={endTime}
          dataType={dataType}
          startTime={startTime}
          timeZone={timeZone}
          legendItems={legendItemsResult.data}
        />

        <ButtonDropdown isOpen={isOpen} toggle={onToggle}>
          <DropdownToggle className={className} color="info" caret>
            <i className="fa fa-file-download" />
            <span className={styles['dropdown-toggle']}>{t('text.label')}</span>
          </DropdownToggle>
          {isOpen ? this.renderDropdownItems() : null}
        </ButtonDropdown>
      </React.Fragment>
    )
  }

  onCsvExportModalToggle = () => {
    this.setState({ isCsvExportModalOpen: false })
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchNotifyDownloadsChanged() {
      dispatch(Changes.notifyChange(Changes.CSV_DOWNLOAD_CHANGES))
    },
  }
}

export default compose(
  connect(null, mapDispatchToProps),
  withTranslation(['src/analysis_path/pressure_analysis_path/export_dropdown'])
)(ExportDropdownWithModal)
