import React from 'react'
import PropTypes from 'prop-types'
import { createSelector } from 'reselect'
import { get } from 'lodash/fp/object'

import AsyncResult from '@@src/utils/async_result'

export const CpisDataContext = React.createContext()

export default class CpisDataProvider extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node,
  }

  state = {
    cpisDataResult: AsyncResult.notFound([]),
  }

  render() {
    const { children } = this.props
    const value = this.selectCpisDataContext(this.state)

    return (
      <CpisDataContext.Provider value={value}>
        {children}
      </CpisDataContext.Provider>
    )
  }

  setCpisDataResult = result => {
    this.setState({
      cpisDataResult: result,
    })
  }

  selectCpisDataContext = createSelector([
    get('cpisDataResult'),
  ], (cpisDataResult) => {
    return {
      cpisDataResult,
      setCpisDataResult: this.setCpisDataResult,
    }
  })
}
