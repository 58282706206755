import AssignableColorStore from
'@@src/components/colors/assignable_color_store'

export const RESET_COLOR = '@shared-color-store/reset-color'
export const RESET_COLORS = '@shared-color-store/reset-all-colors'
export const UPDATE_COLOR = '@shared-color-store/update-color-for'

export function reducer(state = createInitialState(), action) {
  switch (action.type) {
    case RESET_COLOR: {
      const { store } = state

      store.resetColorFor(action.id)

      return { store }
    }

    case RESET_COLORS: {
      const { store } = state

      store.resetAllColors()

      return { store }
    }

    case UPDATE_COLOR: {
      const { id, color } = action
      const { store } = state
      store.setItemColor(id, color)

      return { store }
    }

    default:
      return state
  }
}

function createInitialState() {
  return { store: new AssignableColorStore() }
}

export function updateColorFor(id, color) {
  return { type: UPDATE_COLOR, id, color }
}

export function resetColorFor(id) {
  return { type: RESET_COLOR, id }
}

export function resetColors() {
  return { type: RESET_COLORS }
}

export function selectColorFor(state, id) {
  return selectColorStore(state).getItemColor(id)
}

export function selectAssignableColors(state) {
  return selectColorStore(state).getAssignableColors()
}

function selectColorStore(state) {
  return state.sharedColorStore.store
}
