import React from 'react'
import {
  Button,
} from 'reactstrap'
import { Link } from 'react-router-dom'
import DeviceIssueSeverityBadge from
'@@src/components/badges/device_issue_severity_badge'
import NetworkAssetIcon from '@@src/components/icons/network_asset_icon'
import routes from '@@src/routes'
import styles from './index.css'

function ManagementGroupsNetworkAssetList({
  t,
  networkAssets,
  dataTip,
  onClick,
  canEdit,
}) {

  return(
    <ul className="list-unstyled px-4 py-0 bg-white rounded">
      {networkAssets.map(asset => {
        const mostSevereDeviceIssue = asset.mostSevereDeviceIssue

        return (
          <li
            key={asset.id}
            className={styles['group-network-asset-item']}>
            <NetworkAssetIcon
              className={styles['group-network-asset-icon']}
              networkAsset={asset} />

            <div className="d-flex flex-column mr-auto">
              <div className="d-flex align-items-center">
                <Link
                  className="mr-2"
                  to={routes.networkAssetsDetailPath(asset.id)}>
                  {asset.assetId}
                </Link>
                {
                  mostSevereDeviceIssue ?
                    <DeviceIssueSeverityBadge
                      issue={asset.mostSevereDeviceIssue} />
                    : null
                }
              </div>

              {
                asset.assetName ? (
                  <div><i><small>{asset.assetName}</small></i></div>
                ) : null
              }

              <small className="text-muted d-block">
                {t(`common/text:text.${asset.assetType}`)}
              </small>
            </div>

            <Button
              name="remove-network-asset-button"
              color="link"
              disabled={!canEdit}
              outline
              onClick={onClick(asset)}
              data-tip={dataTip}
              className="text-danger ml-auto flex-grow-0 flex-shrink-0">
              <i className="fas fa-minus-circle"></i>
            </Button>
          </li>
        )
      })}
    </ul>
  )
}

export default ManagementGroupsNetworkAssetList
