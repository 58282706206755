import React from 'react'
import PropTypes from 'prop-types'
import {
  Modal, ModalHeader, ModalFooter, Button,
} from 'reactstrap'
import { compose } from 'redux'
import gql from 'graphql-tag'
import { withTranslation } from 'react-i18next'
import classnames from 'classnames'
import { get } from 'lodash/fp/object'
import { noop } from 'lodash/fp/util'
import { createSelector } from 'reselect'
import { connect } from 'react-redux'

import { AsyncResult, sleep } from '@@src/utils'
import PagedData from '@@src/api/presenters/paged_data'
import { MixedGroupDetailsType } from '@@src/api/presenters/group'
import AsyncResultSwitch from '@@src/components/async_result_switch'
import GroupTypeIcon from '@@src/components/icons/group_type_icon'
import * as analytics from '@@src/analytics'
import InfiniteScrollList from '@@src/components/lists/infinite_scroll_list'
import DebouncedInputController from '@@src/utils/debounced_input_controller'
import incrementalPaginatedRequests
from '@@src/components/pagination/incremental_paginated_request_container'
import LoadingIcon from '@@src/components/loading_icon'
import GroupMemberListItem from
'@@src/components/modals/move_groups_modal/group_member_list_item'
import SiblingsGroupListHeader from
'@@src/components/modals/move_groups_modal/siblings_group_list_header'
import GroupsBreadcrumbs from
'@@src/components/modals/move_groups_modal/groups_breadcrumbs'
import MoveUpHierarchyButton from
'@@src/components/modals/move_groups_modal/move_up_hierarchy_button'
import withMoveGroupsProvider from
'@@src/components/modals/move_groups_modal/move_groups_provider'
import MoveSubmitButton from
'@@src/components/modals/move_groups_modal/move_submit_button'

import moveMembersModalStyles from './move_members_modal.css'
import moveModalStyles from './move_modal.css'

const INFLOWNET_WAIT = process.env.NODE_ENV === 'test' ? 0 : 3000

class MoveMembersModal extends React.PureComponent {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    selectDestinationSiblings: PropTypes.func.isRequired,
    selectDestinationId: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    refetchDestinationGroup: PropTypes.func.isRequired,
    refetchDestinationTopLevelPagedGroups: PropTypes.func.isRequired,
    refetchSourceTopLevelPagedGroups: PropTypes.func.isRequired,
    destinationGroupResult: PropTypes.instanceOf(AsyncResult).isRequired,
    destinationTopLevelPagedGroupsResult:
      PropTypes.instanceOf(AsyncResult).isRequired,
    sourceTopLevelPagedGroupsResult:
      PropTypes.instanceOf(AsyncResult).isRequired,
    group: PropTypes.instanceOf(MixedGroupDetailsType),
    viewTopLevelGroups: PropTypes.func.isRequired,
    setInitialMoveGroupsState: PropTypes.func.isRequired,
    renderNoGroupSiblings: PropTypes.func.isRequired,
    renderFailure: PropTypes.func.isRequired,
    getHandleNavigateGroupHierarchy: PropTypes.func.isRequired,
    moveToHierarchy: PropTypes.func.isRequired,
    moveToTopLevel: PropTypes.func.isRequired,
    viewingTopLevelGroups: PropTypes.bool.isRequired,
    destinationGroupId: PropTypes.number,
    handleToggle: PropTypes.func,
    fetchDestinationNextPagedGroupsResult: PropTypes.func,
    fetchSourceNextPagedGroupsResult: PropTypes.func,
    className: PropTypes.string,
  }

  static defaultProps = {
    isOpen: false,
    onMoveGroups: noop,
    refetchDestinationGroup: noop,
    refetchDestinationTopLevelPagedGroups: noop,
    refetchSourceTopLevelPagedGroups: noop,
    destinationGroupResult: AsyncResult.success([]),
    destinationTopLevelPagedGroupsResult: AsyncResult.success([]),
    sourceTopLevelPagedGroupsResult: AsyncResult.success([]),
    viewingTopLevelGroups: false,
  }

  searchDestinationInputController = new DebouncedInputController()
  searchSourceInputController = new DebouncedInputController()

  constructor(props) {
    super(props)

    this.state = this.initialState()
  }

  initialState() {
    return {
      result: AsyncResult.notFound(),
      destinationSearchValue: '',
      sourceSearchValue: '',
      selectedGroupIds: [],
    }
  }

  render() {
    const {
      t,
      className,
      isOpen,
      group,
      sourceTopLevelPagedGroupsResult,
      fetchDestinationNextPagedGroupsResult,
      fetchSourceNextPagedGroupsResult,
      viewingTopLevelGroups,
      viewTopLevelGroups,
      getHandleNavigateGroupHierarchy,
    } = this.props
    const {
      result,
      selectedGroupIds,
      sourceSearchValue,
    } = this.state
    const destinationResult = this.selectDestinationResult(
      this.props,
      this.state
    )

    return (
      <Modal
        contentClassName={classnames(
          moveMembersModalStyles['modal-body'],
          'h-100'
        )}
        className={classnames(className, 'm-0 mw-100 h-100')}
        isOpen={isOpen}
        toggle={this.handleToggle}>
        <ModalHeader
          className={moveMembersModalStyles['modal-header']}
          toggle={this.handleToggle}>
          {t('headings.title')}
        </ModalHeader>
        { result.wasFailure() ? this.handleFailure(result) : null }

        <SiblingsGroupListHeader
          inputController={this.searchSourceInputController}
          handleSearchChange={this.handleSearchSourceGroups}
          group={group}
          searchValue={sourceSearchValue}
          className={classnames(
            moveMembersModalStyles['source-header'],
            'border-right'
          )}
          searchInputName={'search-source-top-level-groups'}
          title={t('headings.from')}
          breadcrumbs={(
            <GroupsBreadcrumbs
              viewingTopLevelGroups={viewingTopLevelGroups}
              handleViewTopLevelGroups={viewTopLevelGroups}
              getHandleNavigate={getHandleNavigateGroupHierarchy}
              isNavigatable={false}
              group={group} />
          )}>
          <div
            className="d-flex align-items-center justify-content-between">
            <span name="selected-group-count" className="lead">
              {t('text.groups_selected', {
                count: selectedGroupIds.length,
              })}
            </span>
          </div>
        </SiblingsGroupListHeader>

        {
          group ?
            <ol
              name="source-members-groups-list"
              className={classnames(
                'border-right px-3 list-unstyled d-flex flex-column mb-0',
                moveMembersModalStyles['movable-group-list']
              )}>
              {this.renderMovableGroupMembers(group)}
            </ol>
            :
            <InfiniteScrollList
              name="source-top-level-groups-list"
              result={sourceTopLevelPagedGroupsResult}
              className={classnames(
                'border-right list-unstyled mb-0 d-flex flex-column h-100 px-3',
                moveMembersModalStyles['movable-group-list'],
              )}
              fetchNextPage={fetchSourceNextPagedGroupsResult}>
              {
                sourceTopLevelPagedGroupsResult.data.data.length === 0 &&
                  sourceTopLevelPagedGroupsResult.wasSuccessful() ?
                  this.renderNoResults() :
                  sourceTopLevelPagedGroupsResult.data.data.map(
                    (member, ...args) => {
                      if (member.isCongruityGroup()) {
                        return this.renderGroupMember(member, ...args)
                      }

                      return this.renderMovableGroupMember(member, ...args)
                    }
                  )
              }
            </InfiniteScrollList>
        }

        {
          viewingTopLevelGroups ?
            <React.Fragment>
              {this.renderDestinationSiblingsGroupListHeader(
                'search-destination-top-level-groups',
              )}
              <InfiniteScrollList
                name="destination-top-level-groups-list"
                result={destinationResult}
                className={classnames(
                  'list-unstyled mb-0 d-flex flex-column h-100 px-3',
                  moveMembersModalStyles['siblings-list'],
                )}
                fetchNextPage={fetchDestinationNextPagedGroupsResult}>
                {
                  destinationResult.data.data.length === 0 &&
                    destinationResult.wasSuccessful() ?
                    this.renderNoResults() :
                    destinationResult.data.data.map(
                      this.renderGroupMember
                    )
                }
              </InfiniteScrollList>
            </React.Fragment> :
            <AsyncResultSwitch
              renderFailResult={this.handleFailure}
              result={destinationResult}
              renderSuccessResult={this.renderHierarchicalGroups}
              renderPendingResult={this.renderPendingResult} />
        }

        <ModalFooter className={classnames(
          'position-relative bg-white',
          moveMembersModalStyles['modal-footer'])
        }>
          <Button
            className="mr-auto"
            name="cancel-button"
            type="button"
            color="secondary"
            onClick={this.handleToggle}>
            {t('buttons.close')}
          </Button>
        </ModalFooter>
      </Modal>
    )
  }

  renderPendingResult = () => (
    <div className={classnames(
      moveMembersModalStyles['siblings-list'],
      moveMembersModalStyles['loading-icon-container'],
      'text-center'
    )}>
      <LoadingIcon />
    </div>
  )

  renderNoResults = () => (
    <li className="py-3 font-italic">
      {this.props.t('text.no_groups_found')}
    </li>
  )

  renderHierarchicalGroups = ({ data: group }) => {
    const {
      selectDestinationSiblings,
      selectDestinationId,
      viewTopLevelGroups,
      getHandleNavigateGroupHierarchy,
      renderNoGroupSiblings,
    } = this.props
    const siblings = selectDestinationSiblings({
      ...this.props,
      group,
    })
    const destinationId = selectDestinationId(this.props)

    return (
      <React.Fragment>
        {this.renderDestinationSiblingsGroupListHeader(
          'search-destination-top-level-groups',
          group,
        )}

        <div className={
          classnames(
            'mb-0 d-flex flex-column px-3',
            moveMembersModalStyles['siblings-list'],
          )
        }>
          <MoveUpHierarchyButton
            handleClick={
              destinationId !== -1 ?
                getHandleNavigateGroupHierarchy(destinationId) :
                viewTopLevelGroups
            }
            buttonClassName={classnames(
              moveMembersModalStyles['group-item'],
              moveModalStyles['group-item-button'],
              moveModalStyles['group-item-active']
            )}
            className={moveModalStyles['item-border-bottom']} />
          <ol
            name="destination-members-groups-list"
            className={'list-unstyled d-flex flex-column mb-0'}>
            {
              siblings.length > 0 ?
                siblings.map(this.renderGroupMember) :
                renderNoGroupSiblings(moveMembersModalStyles['group-item'])
            }
          </ol>
        </div>
      </React.Fragment>
    )
  }

  renderDestinationSiblingsGroupListHeader = (searchInputName, group) => {
    const {
      t,
      viewingTopLevelGroups,
      viewTopLevelGroups,
      getHandleNavigateGroupHierarchy,
    } = this.props
    const { destinationSearchValue, result } = this.state
    const [
      isSubmitDisabled,
      submitDisabledReason,
    ] = this.selectIsSubmitDisabled(this.props, this.state)

    return (
      <SiblingsGroupListHeader
        inputController={this.searchDestinationInputController}
        handleSearchChange={this.handleSearchDestinationGroups}
        group={group}
        searchValue={destinationSearchValue}
        className={classnames(moveMembersModalStyles['destination-header'])}
        searchInputName={searchInputName}
        title={t('headings.to')}
        breadcrumbs={(
          <GroupsBreadcrumbs
            viewingTopLevelGroups={viewingTopLevelGroups}
            handleViewTopLevelGroups={viewTopLevelGroups}
            getHandleNavigate={getHandleNavigateGroupHierarchy}
            group={group} />
        )}>
        <MoveSubmitButton
          isDisabled={isSubmitDisabled}
          disabledReason={submitDisabledReason}
          result={result}
          handleSubmit={this.handleSubmit}>
          <React.Fragment>
            <span className="mr-2 far fa-arrow-right" />
            {t('buttons.move_selected')}
          </React.Fragment>
        </MoveSubmitButton>
      </SiblingsGroupListHeader>
    )
  }

  renderGroupMember = (group, i, members) => {
    const { getHandleNavigateGroupHierarchy } = this.props
    const { selectedGroupIds } = this.state
    const isGroupOfGroups = group.isGroupOfGroups()
    const isCongruityGroup = group.isCongruityGroup()
    const buttonClassName = classnames({
      [moveModalStyles['group-item-active']]: isGroupOfGroups,
    })
    const className = classnames({
      [moveModalStyles['item-border-bottom']]: i !== members.length - 1,
    })
    const handleClick = isGroupOfGroups ?
      getHandleNavigateGroupHierarchy(group.id) : undefined

    return (
      <GroupMemberListItem
        key={group.dataSourceId}
        isDisabled={!isGroupOfGroups || isCongruityGroup ||
          selectedGroupIds.includes(group.dataSourceId)}
        handleClick={handleClick}
        className={className}
        buttonClassName={buttonClassName}>
        <div className="d-flex align-items-center text-dark">
          <GroupTypeIcon className="fa-2x" group={group} />
          <span className="ml-3 d-flex flex-column align-items-start">
            {group.name}
          </span>
        </div>
        {
          isGroupOfGroups ?
            <span className="far fa-chevron-right text-secondary" /> : null
        }
      </GroupMemberListItem>
    )
  }

  renderMovableGroupMembers = group => {
    return group.members.data.map((member, i) => {
      if (member.isCongruityGroup()) {
        return this.renderGroupMember(member, i, group)
      }

      return this.renderMovableGroupMember(member, i, group)
    })
  }

  renderMovableGroupMember = (group, i, groups) => {
    const { selectedGroupIds } = this.state
    const isSelected = selectedGroupIds.find(
      id => String(id) === String(group.dataSourceId)
    )
    const className = classnames(moveMembersModalStyles['group-item-movable'], {
      [moveModalStyles['item-border-bottom']]: i !== groups.length - 1,
    })

    return (
      <GroupMemberListItem
        key={group.dataSourceId}
        handleClick={this.getHandleClickMovableGroup(group.dataSourceId)}
        className={className}>
        <div className="d-flex align-items-center text-dark">
          <GroupTypeIcon className="fa-2x" group={group} />
          <span className="ml-3 d-flex flex-column align-items-start">
            {group.name}
          </span>
        </div>
        {
          isSelected ?
            <span className={classnames(
              'far fa-check text-primary',
              moveMembersModalStyles['selected-group-icon'],
            )} /> :
            <span className={classnames(
              'far fa-check text-black-50',
              moveMembersModalStyles['selected-group-icon-inactive'],
            )} />
        }
      </GroupMemberListItem>
    )
  }

  getHandleClickMovableGroup = groupId => {
    const {
      refetchDestinationTopLevelPagedGroups,
      setInitialMoveGroupsState,
    } = this.props

    return () => {
      const {
        group,
        viewingTopLevelGroups,
      } = this.props
      const { selectedGroupIds, destinationSearchValue } = this.state
      const filteredSelectedGroupIds = selectedGroupIds.filter(
        id => String(id) !== String(groupId)
      )

      if (selectedGroupIds.length === filteredSelectedGroupIds.length) {
        filteredSelectedGroupIds.push(groupId)
      }

      this.setState({
        selectedGroupIds: filteredSelectedGroupIds,
      })

      const newChildFilterIds = filteredSelectedGroupIds.map(
        MixedGroupDetailsType.parseIdFromDataSourceId
      )

      if (group) {
        newChildFilterIds.push(group.id)
      }

      if (viewingTopLevelGroups) {
        refetchDestinationTopLevelPagedGroups({
          pageNumber: 1,
          searchQuery: destinationSearchValue,
          childFilterIds: Array.isArray(newChildFilterIds) &&
            newChildFilterIds.length ? newChildFilterIds : undefined,
        })
      } else {
        setInitialMoveGroupsState()
      }
    }
  }

  handleFailure = result => {
    const { renderFailure } = this.props

    return (
      <div className={classnames(
        moveMembersModalStyles['move-error'],
        'border-bottom'
      )}>
        {renderFailure(result)}
      </div>
    )
  }

  handleToggle = (...args) => {
    const {
      handleToggle,
      refetchDestinationGroup,
      destinationGroupId,
      setInitialMoveGroupsState,
    } = this.props

    handleToggle(...args)
    refetchDestinationGroup({ id: destinationGroupId })

    this.searchDestinationInputController.dispatchClearValueEvent()
    this.searchSourceInputController.dispatchClearValueEvent()
    this.setState(this.initialState())
    setInitialMoveGroupsState()
  }

  handleSearchDestinationGroups = ev => {
    const { refetchDestinationTopLevelPagedGroups, group } = this.props
    const { selectedGroupIds } = this.state
    const childFilterIds = [...selectedGroupIds]

    if (group) {
      childFilterIds.push(group.id)
    }

    this.setState({
      destinationSearchValue: ev.target.value.substring(0, 200),
    })

    refetchDestinationTopLevelPagedGroups({
      pageNumber: 1,
      searchQuery: ev.target.value.substring(0, 200),
      childFilterIds: childFilterIds.length ?
        childFilterIds.map(MixedGroupDetailsType.parseIdFromDataSourceId) :
        undefined,
    })
  }

  handleSearchSourceGroups = ev => {
    const { refetchSourceTopLevelPagedGroups } = this.props

    this.setState({
      sourceSearchValue: ev.target.value.substring(0, 200),
    })

    refetchSourceTopLevelPagedGroups({
      pageNumber: 1,
      searchQuery: ev.target.value.substring(0, 200),
    })
  }

  handleSubmit = async () => {
    const {
      onMoveGroups,
      handleToggle,
      viewingTopLevelGroups,
      moveToTopLevel,
      moveToHierarchy,
      destinationGroupId,
      destinationGroupResult,
      setInitialMoveGroupsState,
    } = this.props
    const { selectedGroupIds } = this.state

    try {
      this.setState({ result: AsyncResult.pending() })

      if (viewingTopLevelGroups) {
        await moveToTopLevel({
          parentGroupId: Number(destinationGroupId),
          groupMemberIds: selectedGroupIds.map(
            MixedGroupDetailsType.parseIdFromDataSourceId
          ),
        })
      } else {
        await moveToHierarchy({
          groupIds: selectedGroupIds.map(
            MixedGroupDetailsType.parseIdFromDataSourceId
          ),
          newParentGroupId: Number(destinationGroupResult.data.id),
        })
      }

      await sleep(INFLOWNET_WAIT)
      await onMoveGroups()
      await handleToggle()
      this.setState(this.initialState())
      setInitialMoveGroupsState()
    } catch (err) {
      analytics.logError(err)
      this.setState({ result: AsyncResult.fail(err) })
    }
  }

  selectDestinationResult = createSelector(
    [
      get('destinationTopLevelPagedGroupsResult'),
      get('destinationGroupResult'),
      get('viewingTopLevelGroups'),
    ],
    (
      destinationTopLevelPagedGroupsResult,
      destinationGroupResult,
      viewingTopLevelGroups,
    ) => {
      if (viewingTopLevelGroups) {
        return destinationTopLevelPagedGroupsResult
      } else {
        return destinationGroupResult
      }
    }
  )

  selectIsSubmitDisabled = createSelector(
    [
      this.selectDestinationResult,
      get('sourceTopLevelPagedGroupsResult'),
      get('group'),
      get('t'),
      get('destinationGroupId'),
      get('viewingTopLevelGroups'),
      (_, state) => get('selectedGroupIds')(state),
    ],
    (
      destinationResult,
      sourceTopLevelPagedGroupsResult,
      group,
      t,
      destinationGroupId,
      viewingTopLevelGroups,
      selectedGroupIds,
    ) => {
      if (destinationResult.isPending() ||
        (!group && sourceTopLevelPagedGroupsResult.isPending())) {
        return [true, null]
      }

      if (destinationResult.wasSuccessful() &&
        (String(destinationGroupId) === String(destinationResult.data.id)) ||
        (!group && viewingTopLevelGroups)) {
        return [true, t('text.disabled_submit_button_source_destination')]
      }

      if ((!destinationResult.wasSuccessful()) ||
        (!group && !sourceTopLevelPagedGroupsResult.wasSuccessful())) {
        return [true, null]
      }

      if (selectedGroupIds.length === 0) {
        return [true, t('text.disabled_submit_button_no_selection')]
      }

      return [false, null]
    }
  )

  static TOP_LEVEL_PAGED_GROUP_QUERY = gql`
    query PagedGroups (
      $pageNumber: Int!,
      $searchQuery: String,
      $childFilterIds: [Int],
      $showOnlyGroupsWithNoParent: Boolean,
      $skipPagedGroups: Boolean!
    ) {
      pagedGroups(
        searchQuery: $searchQuery,
        pageNumber: $pageNumber,
        resultsPerPage: 10,
        childFilterIds: $childFilterIds,
        showOnlyGroupsWithNoParent: $showOnlyGroupsWithNoParent
      ) @skip(if: $skipPagedGroups) {
        groups {
          id
          name
          category
          congruity {
            zone {
              id
            }
          }
          members {
            type
            count
            data {
              ... on MixedGroupDetailsType {
                id
                name
                category
              }
            }
          }
        }
        pagination {
          perPage
          pageNumber
          totalPages
          totalResults
        }
      }
    }
  `
}

function mapStateToProps(state, ownProps) {
  return {
    destinationGroupId: ownProps.group ? ownProps.group.id : -1,
  }
}

export default compose(
  connect(mapStateToProps),
  withMoveGroupsProvider,
  incrementalPaginatedRequests(MoveMembersModal.TOP_LEVEL_PAGED_GROUP_QUERY, {
    options: ({ group, isOpen }) => ({
      variables: {
        pageNumber: 1,
        childFilterIds: group ? [group.id] : undefined,
        showOnlyGroupsWithNoParent: false,
        skipPagedGroups: !isOpen,
      },
    }),
    mergeData: (a, b) => new PagedData({
      ...b,
      data: a.data.concat(b.data),
    }),
    methodName: 'pagedGroups',
    nullObject: { data: [], map: () => {}, pagination: { totalResults: 0 } },
    resultFunc: ({ result, refetch, fetchNext }) => {
      return {
        destinationTopLevelPagedGroupsResult: result,
        fetchDestinationNextPagedGroupsResult: fetchNext,
        refetchDestinationTopLevelPagedGroups: refetch,
      }
    },
  }),
  incrementalPaginatedRequests(MoveMembersModal.TOP_LEVEL_PAGED_GROUP_QUERY, {
    options: ({ isOpen, group }) => ({
      variables: {
        pageNumber: 1,
        showOnlyGroupsWithNoParent: true,
        skipPagedGroups: !!(!isOpen || group),
      },
    }),
    mergeData: (a, b) => new PagedData({
      ...b,
      data: a.data.concat(b.data),
    }),
    methodName: 'pagedGroups',
    nullObject: { data: [], map: () => {}, pagination: { totalResults: 0 } },
    resultFunc: ({ result, refetch, fetchNext }) => {
      return {
        sourceTopLevelPagedGroupsResult: result,
        fetchSourceNextPagedGroupsResult: fetchNext,
        refetchSourceTopLevelPagedGroups: refetch,
      }
    },
  }),
  withTranslation([
    'src/components/modals/move_groups_modal/move_members_modal',
    'common/text',
  ]),
)(MoveMembersModal)
