import gql from 'graphql-tag'
import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { graphql } from '@apollo/client/react/hoc'
import { withTranslation } from 'react-i18next'

import * as analytics from '@@src/analytics'
import ErrorInfo from '@@src/components/error_info'
import FormFields from '@@src/utils/form_fields'
import AppFormGroup from '@@src/components/forms/app_form_group'
import SubmitButton from '@@src/components/buttons/submit_button'
import { AsyncResult, validRoles } from '@@src/utils'
import {
  Row, Col, Button, Modal, ModalBody, ModalHeader, ModalFooter, Form,
} from 'reactstrap'
import userPermissions from '@@src/components/permissions/user_permissions'

import styles from './edit_user_modal.css'

class EditUserModal extends React.PureComponent {
  static defaultProps = {
    onSuccess: () => { },
  }

  static propTypes = {
    onSuccess: PropTypes.func.isRequired,
  }

  render() {
    const { t, isOpen, user } = this.props
    const { result } = this.state

    return (
      <Form>
        <Modal
          isOpen={isOpen}
          toggle={this.onToggle}>
          <ModalHeader toggle={this.toggle}>
            {t('headings.title')}
          </ModalHeader>

          <ModalBody>
            {result.wasFailure() ? <ErrorInfo error={result.error} /> : null}

            <Row>
              <Col sm="6">
                <span>
                  {user.email}
                </span>
              </Col>
              <Col sm="6">
                <AppFormGroup
                  name="roles"
                  type="select"
                  value={this.selectFieldValue('role')}
                  onChange={this.fields.onChangeHandlerFor('role')}
                  errorText={this.selectFieldErrorText('role')}>
                  {
                    validRoles().map(role => (
                      <option key={role} value={role}>
                        {t(`common/text:text.role_${role}`)}
                      </option>
                    ))
                  }
                </AppFormGroup>
              </Col>
            </Row>
          </ModalBody>

          <ModalFooter className={styles['buttons-row']}>
            <Button
              name="cancel-button"
              type="button"
              color="secondary"
              onClick={this.onCancel}>
              {t('buttons.cancel')}
            </Button>

            <SubmitButton
              name="save-button"
              color="primary"
              result={result}
              disabled={this.fields.hasAnyValidationErrors()}
              buttonStatus=""
              onSubmitForm={this.onSubmitForm}
              submitText={t('buttons.save')}>
            </SubmitButton>
          </ModalFooter>
        </Modal>
      </Form>
    )
  }

  constructor(props) {
    super(props)

    this.fields = new FormFields(this, 'roleFields', {
      role: v => v ? '' : 'errors.required',
    })

    this.state = {
      result: AsyncResult.notFound(),
      roleFields: this.fields.initialState({
        role: this.getRoleNameForId(props.user.role.id),
      }),
    }
  }

  selectFieldValue(fieldName) {
    return this.fields.selectValue(this.state, fieldName)
  }

  selectFieldErrorText(fieldName) {
    const error = this.fields.selectError(this.state, fieldName)

    return error ? this.props.t(error) : error
  }

  onCancel = () => {
    this.onToggle()
  }

  onToggle = (...args) => {
    const { toggle } = this.props
    this.resetState()

    if (toggle) {
      toggle(...args)
    }
  }

  resetState = () => {
    this.setState({
      result: AsyncResult.notFound(),
    })
  }

  onSubmitForm = async event => {
    event.preventDefault()

    this.setState({ result: AsyncResult.pending() })

    const { user } = this.props
    const newRole = this.selectFieldValue('role')
    const roleId = this.getRoleIdForName(newRole)

    const username = user.username
    try {
      await this.props.editUser({
        variables: { username, roleId },
      })
      this.setState({ result: AsyncResult.success() })
      this.onToggle()
      this.props.onSuccess()
    } catch (e) {
      analytics.logError(e)
      this.setState({ result: AsyncResult.fail(e) })
    }
  }

  getRoleNameForId = (roleId) => {
    const { roles } = this.props
    const role = roles.filter((r) => {
      return r.id === roleId
    })
    return role[0].name
  }

  getRoleIdForName = (name) => {
    const { roles } = this.props
    const role = roles.filter((r) => {
      return r.name === name
    })
    return role[0].id
  }

  static EDIT_USER_MUTATION = gql`
    mutation editUser ($username: String!, $email: String, $roleId: Int) {
      editUser (username: $username, email: $email, roleId: $roleId)
    }
  `
}

export default compose(
  userPermissions,
  graphql(EditUserModal.EDIT_USER_MUTATION, {
    name: 'editUser',
  }),
  withTranslation([
    'src/admin_path/users/edit_users_modal',
    'common/text',
  ])
)(EditUserModal)
