import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { graphql } from '@apollo/client/react/hoc'
import gql from 'graphql-tag'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import { noop } from 'lodash/fp/util'
import { Form, Row, Col, Button, Alert } from 'reactstrap'
import moment from 'moment'
import { connect } from 'react-redux'

import AsyncResult from '@@src/utils/async_result'
import MessageBox from '@@src/components/modals/message_box'
import * as analytics from '@@src/analytics'
import DeviceSessionConfigurationFields, {
  withDeviceSessionConfigurationFieldsProvider,
} from '@@src/components/device_configuration/device_session_configuration_fields' // eslint-disable-line max-len
import { createSelectGraphQLResult } from '@@src/utils'
import { parseGraphQLResult } from '@@src/api/presenters'
import AsyncResultSwitch from '@@src/components/async_result_switch'

export const CONFIG_OPTION_TIME_FORMAT = 'hh:mm A'

class TenantDeviceSessionConfigurationForm extends PureComponent {
  static propTypes = {
    updateTenantDeviceSessionConfig: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    deviceConfigIntervals: PropTypes.arrayOf(PropTypes.number).isRequired,
    onToggle: PropTypes.func.isRequired,
    selectDeviceConfigFieldValue: PropTypes.func.isRequired,
    selectDeviceConfigFieldErrorText: PropTypes.func.isRequired,
    getDeviceConfigFieldChangeHandler: PropTypes.func.isRequired,
    resetDeviceConfigFields: PropTypes.func.isRequired,
    hasDeviceConfigFieldValidationErrors: PropTypes.bool.isRequired,
    configurableSessionsPagedDevicesResult:
      PropTypes.instanceOf(AsyncResult).isRequired,
    configurableSessionsPagedDevicesErrorResult:
      PropTypes.instanceOf(AsyncResult).isRequired,
    performDeviceConfigPreSubmitChecks: PropTypes.func.isRequired,
  }

  static defaultProps = {
    onSuccess: noop,
    onToggle: noop,
    hasDeviceConfigFieldValidationErrors: false,
    configurableSessionsPagedDevicesResult: AsyncResult.pending(),
    configurableSessionsPagedDevicesErrorResult: AsyncResult.pending(),
  }

  state = {
    isConfirmModalOpen: false,
    result: AsyncResult.notFound(),
  }

  render() {
    const {
      t,
      deviceConfigIntervals,
      selectDeviceConfigFieldValue,
      selectDeviceConfigFieldErrorText,
      getDeviceConfigFieldChangeHandler,
      hasDeviceConfigFieldValidationErrors,
      resetDeviceConfigFields,
      configurableSessionsPagedDevicesResult,
      dateFrom,
      secondsInterval,
      configurableSessionsPagedDevicesErrorResult,
    } = this.props
    const { isConfirmModalOpen, result } = this.state
    const hourInSeconds = 60 * 60
    const newSecondsInterval = selectDeviceConfigFieldValue('secondsInterval')
    const newDateFrom = selectDeviceConfigFieldValue('dateFrom')
    const newTimeZone = selectDeviceConfigFieldValue('timeZone')
    const newMomentDateFrom = moment(newDateFrom, CONFIG_OPTION_TIME_FORMAT)
    const dateFromTime = newMomentDateFrom.format('LT')
    const hour = newTimeZone ? `${dateFromTime} ${newTimeZone}` : dateFromTime

    return (
      <Form>
        <Row className="pb-3" noGutters={true}>
          <Col sm="10">
            {!dateFrom || !secondsInterval ? (
              <Alert name="tenant-settings-no-config-alert" color="info">
                {t('text.no_configuration')}
              </Alert>
            ) : null}
            <DeviceSessionConfigurationFields
              formResult={result}
              intervals={deviceConfigIntervals}
              selectFormFieldValue={selectDeviceConfigFieldValue}
              selectFormFieldsError={selectDeviceConfigFieldErrorText}
              getFormFieldsChangeHandler={getDeviceConfigFieldChangeHandler}
            />
            <div className="mt-4">
              <AsyncResultSwitch
                result={configurableSessionsPagedDevicesResult}
                renderSuccessResult={this.renderConfigurablePagedDevicesSuccess}
                renderNotFoundResult={this.renderNotFound}
              />
            </div>
            <div className="mt-2">
              <AsyncResultSwitch
                result={configurableSessionsPagedDevicesErrorResult}
                renderSuccessResult={
                  this.renderConfigurablePagedDevicesErrorSuccess
                }
                renderNotFoundResult={this.renderNotFound}
              />
            </div>
            <div className="mt-5">
              <Button
                name="update-device-session-config-button"
                color="primary"
                disabled={hasDeviceConfigFieldValidationErrors}
                onClick={this.handleToggle}
              >
                {t('buttons.save')}
              </Button>

              <Button
                className="ml-2"
                name="reset-update-device-session-config-button"
                type="button"
                color="secondary"
                onClick={resetDeviceConfigFields}
              >
                {t('buttons.discard_changes')}
              </Button>
            </div>
          </Col>
        </Row>

        <MessageBox
          toggle={this.handleToggle}
          isOpen={isConfirmModalOpen}
          result={result}
          onAccept={this.handleAccept}
          acceptText={t('buttons.update')}
          cancelText={t('buttons.cancel')}
          headerText={t('headings.title')}
        >
          <Alert color="warning">{t('text.unaccepted_schedule_change')}</Alert>
          <p>{t('text.confirmation')}</p>
          <p className="text-center font-weight-bold">
            {newSecondsInterval < hourInSeconds
              ? t('text.new_schedule_minute', {
                  hour,
                  count: newSecondsInterval / 60,
                })
              : t('text.new_schedule_hour', {
                  hour,
                  count: newSecondsInterval / 60 / 60,
                })}
          </p>
        </MessageBox>
      </Form>
    )
  }

  renderConfigurablePagedDevicesSuccess = ({ data }) => {
    const { t } = this.props

    return (
      <p>
        {t('text.total_configurable_devices', {
          count: data.totalResults,
        })}
      </p>
    )
  }

  renderConfigurablePagedDevicesErrorSuccess = ({ data }) => {
    const { t } = this.props

    if (String(data.totalResults) === '0') {
      return null
    }

    return (
      <p>
        {t('text.total_configurable_error_devices', {
          count: data.totalResults,
        })}
      </p>
    )
  }

  handleAccept = async (ev) => {
    ev.preventDefault()

    const {
      updateTenantDeviceSessionConfig,
      onSuccess,
      selectDeviceConfigFieldValue,
      performDeviceConfigPreSubmitChecks,
    } = this.props

    const deviceConfigRes = await performDeviceConfigPreSubmitChecks().catch(
      () => false
    )

    if (!deviceConfigRes) {
      this.setState({ result: AsyncResult.notFound() })
      return
    }

    const newSecondsInterval = selectDeviceConfigFieldValue('secondsInterval')
    const newDateFrom = selectDeviceConfigFieldValue('dateFrom')
    const newTimeZone = selectDeviceConfigFieldValue('timeZone')
    const newMomentDateFrom = moment.tz(
      newDateFrom,
      CONFIG_OPTION_TIME_FORMAT,
      newTimeZone
    )

    try {
      await updateTenantDeviceSessionConfig({
        variables: {
          dateFrom: newMomentDateFrom.format(),
          secondsInterval: Number(newSecondsInterval),
          timeZone: newTimeZone,
        },
      })

      await onSuccess(ev)
      this.setState({
        result: AsyncResult.success(),
        isConfirmModalOpen: false,
      })
    } catch (err) {
      analytics.logError(err)
      this.setState({ result: AsyncResult.fail(err) })
    }
  }

  handleToggle = async (...args) => {
    const { onToggle } = this.props

    this.setState({
      isConfirmModalOpen: !this.state.isConfirmModalOpen,
      result: AsyncResult.notFound(),
    })

    await onToggle(...args)
  }

  static CONFIGURABLE_PAGED_DEVICES_QUERY = gql`
    query ConfigurableSessionsPagedDevices {
      pagedDevices(
        status: "configurableSessions"
        pageNumber: 1
        resultsPerPage: 1
      ) {
        devices {
          id
        }
        pagination {
          totalResults
        }
      }
    }
  `

  static CONFIGURABLE_PAGED_DEVICES_ERROR_QUERY = gql`
    query ConfigurableSessionsPagedErrorDevices {
      pagedDevices(
        status: ["withErrors", "configurableSessions"]
        pageNumber: 1
        resultsPerPage: 1
        ignoreDevicesWithStates: [returned]
      ) {
        devices {
          id
        }
        pagination {
          totalResults
        }
      }
    }
  `

  static UPDATE_TENANT_DEVICE_SESSION_CONFIG_MUTATION = gql`
    mutation UpdateSessionConfig(
      $dateFrom: String!
      $secondsInterval: Int!
      $timeZone: String!
    ) {
      updateSessionConfig(
        dateFrom: $dateFrom
        secondsInterval: $secondsInterval
        timeZone: $timeZone
        aggregateType: VTenant
      )
    }
  `
}

function createMapStateToProps() {
  const selectConfigurableSessionsPagedDevicesResult =
    createSelectGraphQLResult('pagedDevices', {
      mapResult: parseGraphQLResult,
      nullObject: {},
    })
  const selectConfigurableSessionsPagedDevicesErrorResult =
    createSelectGraphQLResult('pagedDevices', {
      mapResult: parseGraphQLResult,
      nullObject: {},
    })

  return function mapStateToProps() {
    return {
      selectConfigurableSessionsPagedDevicesResult,
      selectConfigurableSessionsPagedDevicesErrorResult,
    }
  }
}

export default compose(
  connect(createMapStateToProps),
  graphql(
    TenantDeviceSessionConfigurationForm.UPDATE_TENANT_DEVICE_SESSION_CONFIG_MUTATION, // eslint-disable-line max-len
    {
      name: 'updateTenantDeviceSessionConfig',
    }
  ),
  graphql(
    TenantDeviceSessionConfigurationForm.CONFIGURABLE_PAGED_DEVICES_QUERY,
    {
      props: ({ data, ownProps }) => {
        const { selectConfigurableSessionsPagedDevicesResult } = ownProps

        return {
          configurableSessionsPagedDevicesResult:
            selectConfigurableSessionsPagedDevicesResult(data),
        }
      },
    }
  ),
  graphql(
    TenantDeviceSessionConfigurationForm.CONFIGURABLE_PAGED_DEVICES_ERROR_QUERY,
    {
      props: ({ data, ownProps }) => {
        const { selectConfigurableSessionsPagedDevicesErrorResult } = ownProps

        return {
          configurableSessionsPagedDevicesErrorResult:
            selectConfigurableSessionsPagedDevicesErrorResult(data),
        }
      },
    }
  ),
  withDeviceSessionConfigurationFieldsProvider,
  withTranslation([
    'src/components/device_configuration/tenant_device_session_configuration_form', // eslint-disable-line max-len
    'common/text',
  ])
)(TenantDeviceSessionConfigurationForm)
