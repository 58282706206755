import gql from 'graphql-tag'
import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { graphql } from '@apollo/client/react/hoc'
import { groupBy } from 'lodash/fp/collection'
import { toPairs } from 'lodash/fp/object'
import { withTranslation } from 'react-i18next'
import { parseGraphQLResult } from '@@src/api/presenters'
import { createSelectGraphQLResult } from '@@src/utils'
import transformProps from '@@src/components/transform_props'

import * as analytics from '@@src/analytics'
import ErrorInfo from '@@src/components/error_info'
import FormFields from '@@src/utils/form_fields'
import AppFormGroup from '@@src/components/forms/app_form_group'
import SubmitButton from '@@src/components/buttons/submit_button'
import { AsyncResult } from '@@src/utils'
import AsyncResultSwitch from '@@src/components/async_result_switch'
import {
  Row,
  Col,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Form,
} from 'reactstrap'

import styles from './add_group_to_device_modal.css'

class AddGroupToDeviceModal extends React.Component {
  static defaultProps = {
    onSuccess: () => {},
  }

  static propTypes = {
    onSuccess: PropTypes.func.isRequired,
  }

  render() {
    const { t, isOpen, groupsResult } = this.props
    const { result } = this.state

    return (
      <Form>
        <Modal isOpen={isOpen} toggle={this.onToggle}>
          <ModalHeader>{t('headings.title')}</ModalHeader>

          <AsyncResultSwitch
            name="data-sources-list"
            result={groupsResult}
            renderSuccessResult={this.renderModalBody}
          />

          <ModalFooter className={styles['buttons-row']}>
            <Button
              name="cancel-button"
              type="button"
              color="secondary"
              onClick={this.onToggle}
            >
              {t('buttons.cancel')}
            </Button>

            <SubmitButton
              name="save-button"
              color="primary"
              result={result}
              disabled={this.state.category === null}
              buttonStatus=""
              onSubmitForm={this.onSubmitForm}
              submitText={t('buttons.save')}
            ></SubmitButton>
          </ModalFooter>
        </Modal>
      </Form>
    )
  }

  renderModalBody = () => {
    const { result, groups } = this.state
    const { t, groupsResult } = this.props

    const categoryNameMap = groupsResult.data.map((item) => {
      return {
        category: item[0],
        groups: item[1],
      }
    })

    return (
      <ModalBody>
        {result.wasFailure() ? <ErrorInfo error={result.error} /> : null}

        <Row>
          <Col sm="6">
            <AppFormGroup
              name="category"
              type="select"
              label={t('labels.category')}
              defaultValue={t('common/text:text.blank_select_category')}
              onChange={(event) => {
                const value = event.target.value
                this.onChangeCategory(value, categoryNameMap)
              }}
              errorText={this.selectFieldErrorText('category')}
            >
              <option value="">
                {t('common/text:text.blank_select_category')}
              </option>
              {categoryNameMap.map((item) => (
                <option key={item.category} value={item.category}>
                  {t(`common/text:text.category_${item.category}`)}
                </option>
              ))}
            </AppFormGroup>
          </Col>

          <Col sm="6">
            <AppFormGroup
              name="name"
              type="select"
              label={t('labels.name')}
              disabled={this.state.category === null}
              defaultValue={t('common/text:text.blank_select_name')}
              onChange={(event) => {
                const value = event.target.value
                this.onChangeName(value)
              }}
              errorText={this.selectFieldErrorText('name')}
            >
              {groups.map((item) => (
                <option key={item.name} value={item.name}>
                  {item.name}
                </option>
              ))}
            </AppFormGroup>
          </Col>
        </Row>
      </ModalBody>
    )
  }

  onChangeCategory = (value, categoryNameMap) => {
    const namesList = categoryNameMap.filter((item) => {
      return item.category === value
    })

    const groups = namesList[0].groups
    const groupName = namesList[0].groups[0].name

    this.setState({
      category: value,
      groups: namesList[0].groups,
    })

    this.setSelectedGroupId(groups, groupName)
  }

  onChangeName = (value) => {
    const { groups } = this.state
    this.setSelectedGroupId(groups, value)
  }

  setSelectedGroupId = (groups, value) => {
    const group = groups.filter((g) => {
      return g.name === value
    })
    this.setState({ selectedGroupId: group[0].id })
  }

  constructor(props) {
    super(props)

    this.fields = new FormFields(this, 'createGroupFields', {
      name: (v) => (v ? '' : 'errors.required'),
      category: (v) => (v ? '' : 'errors.required'),
    })

    this.state = {
      result: AsyncResult.notFound(),
      createGroupFields: this.fields.initialState(),
      category: null,
      groups: [],
      selectedGroupId: null,
    }
  }

  selectFieldErrorText(fieldName) {
    const error = this.fields.selectError(this.state, fieldName)

    return error ? this.props.t(error) : error
  }

  onToggle = (...args) => {
    const { toggle } = this.props
    this.resetState()

    if (toggle) {
      toggle(...args)
    }
  }

  resetState = () => {
    this.setState({
      result: AsyncResult.notFound(),
      createGroupFields: this.fields.initialState(),
      category: null,
      groups: [],
      selectedGroupId: null,
    })
  }

  onSubmitForm = async (event) => {
    event.preventDefault()

    this.setState({ result: AsyncResult.pending() })

    try {
      const { deviceId } = this.props
      const { selectedGroupId } = this.state

      await this.props.addDeviceToGroup({
        variables: {
          groupId: selectedGroupId,
          aggregateId: deviceId,
          aggregateType: 'VDevice',
        },
      })

      this.setState({ result: AsyncResult.success() })
      this.onToggle()
      this.props.onSuccess()
    } catch (e) {
      analytics.logError(e)
      this.setState({ result: AsyncResult.fail(e) })
    }
  }

  static DEVICE_GROUPS = gql`
    query Groups($memberType: AggregateType) {
      groups(memberType: $memberType) {
        id
        name
        category
      }
    }
  `

  static ADD_TO_GROUP_MUTATION = gql`
    mutation AddMemberToGroup(
      $groupId: Int!
      $aggregateType: AggregateType!
      $aggregateId: Int!
    ) {
      addMemberToGroup(
        groupId: $groupId
        aggregateType: $aggregateType
        aggregateId: $aggregateId
      )
    }
  `
}

export default compose(
  transformProps(() => () => {
    return {
      groupsResult: AsyncResult.notFound([]),
      selectGroupsResult: createSelectGraphQLResult('groups', {
        mapResult: (rawData) => {
          const groups = parseGraphQLResult(rawData)
          return toPairs(groupBy('category')(groups))
        },
        nullObject: [],
      }),
    }
  }),
  withTranslation([
    'src/management_path/groups_path/index_page',
    'common/text',
  ]),
  graphql(AddGroupToDeviceModal.DEVICE_GROUPS, {
    skip: ({ isOpen }) => !isOpen,
    options: () => {
      return {
        fetchPolicy: 'network-only',
        variables: {
          memberType: 'VDevice',
        },
      }
    },
    props: ({ data, ownProps: { selectGroupsResult } }) => {
      return {
        refetchData: data.refetch,
        groupsResult: selectGroupsResult(data),
      }
    },
  }),
  graphql(AddGroupToDeviceModal.ADD_TO_GROUP_MUTATION, {
    name: 'addDeviceToGroup',
  }),
  withTranslation([
    'src/management_path/network_assets_path/add_network_asset_to_group_modal',
    'common/text',
  ])
)(AddGroupToDeviceModal)
