import gql from 'graphql-tag'

export const GET_DEVICES_FOR_MAP_QUERY = gql`
  query GetDevicesForMap (
    $filters: GetDevicesListFilters,
  ) {
    getDevicesList(
      filters: $filters,
    ) {
      meta {
        bounds {
          southwest {
            lat
            lng
          }
          northeast {
            lat
            lng
          }
        }
      }
      devices {
        deviceId
        isCommissioned
        isInstalled
        mostSeverityIssue {
            issueType
            issueSeverity
        }
        activeErrorsCount
        activeWarningsCount
        activeInfoCount

        lat
        lng
        alt
      }
    }
  }
`
