import { useMemo } from 'react'
import gql from 'graphql-tag'
import {
  createSelectGraphQLResult,
} from '../../../utils/index'
import { GetGroupsResult } from '@@src/api/presenters/group'
import { parseGraphQLResult } from '../../../api/presenters/index'
import { useQuery } from '@apollo/client'

export function useGetMostDistantGroups(group, isEventSourceLocalisationLicensed) {
  const GROUP_MOST_DISTANT_GROUPS = gql`
  query GetMostDistantGroups(	$parentGroupId: Int, $isEventSourceLocalisationLicensed: Boolean! ) {
    getMostDistantGroups( parentGroupId: $parentGroupId ) {
      groupId
      groupName
      groupCategory
      membersType
      membersCount
      membersIds
      unreadEventSourceLocalisationsCount @include(if: $isEventSourceLocalisationLicensed)
      lastUnreadEventSourceLocalisationDate @include(if: $isEventSourceLocalisationLicensed)
      firstUnreadEventSourceLocalisationDate @include(if: $isEventSourceLocalisationLicensed)
    }
  }
  `

  const mostDistantGroups = useQuery(GROUP_MOST_DISTANT_GROUPS, {
    variables: {
      parentGroupId: group.groupId,
      skip: !group.isGroupOfGroups() || group.unreadEventSourceLocalisationsCount === 0,
      isEventSourceLocalisationLicensed: isEventSourceLocalisationLicensed,
    },
    fetchPolicy: 'network-only',
  })

  const selectMostDistantAssetGroups = useMemo(() => {
    const normalizedGroups = createSelectGraphQLResult('data', {
      mapResult: ({ getMostDistantGroups }) => parseGraphQLResult(getMostDistantGroups),
      nullObject: [],
    })(mostDistantGroups)

    return !normalizedGroups.isPending() ?
      normalizedGroups.data
        .map(g => GetGroupsResult.from({
          groupId: g.groupId,
          groupName: g.groupName,
          membersIds: g.membersIds,
          lastUnreadEventSourceLocalisationDate:
        g.lastUnreadEventSourceLocalisationDate,
          firstUnreadEventSourceLocalisationDate:
        g.firstUnreadEventSourceLocalisationDate,
          unreadEventSourceLocalisationsCount:
        g.unreadEventSourceLocalisationsCount,
          membersType: g.membersType,
        }))
        .filter(g => g.isNetworkAssetGroup())
      :
      []
  }, [mostDistantGroups])

  return {
    mostDistantGroups: selectMostDistantAssetGroups,
  }
}
