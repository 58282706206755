import React, { useContext } from 'react'

import AppApolloProvider from '@@src/components/app_apollo_provider'
import TenantConfigProvider from '@@src/components/tenant_config_provider'
import DeprecatedTenantSettingsProvider from '@@src/components/tenant_settings_provider'
import UserSettingsProvider from '@@src/components/user_settings_provider'
import TenantsFeatureFlagsProvider from '../../_v2/contexts/feature_flags/tenants_feature_flags.context'
import { TenantSettingsProvider } from '../../_v2/contexts/tenant_settings/tenant_settings.context'
import { UserContext } from '../../_v2/contexts/user/user.context'
import RequireEulaAcceptanceGuard from './require_eula_acceptance_guard'

function SecuredConfigProviders({ children }) {
  const { isLogged } = useContext(UserContext)

  console.log('SecuredConfigProviders', { isLogged: isLogged() })

  if (!isLogged()) {
    return children
  }

  return (
    <AppApolloProvider>
      <RequireEulaAcceptanceGuard>
        <TenantSettingsProvider>
          <TenantConfigProvider>
            <DeprecatedTenantSettingsProvider>
              <TenantsFeatureFlagsProvider>
                <UserSettingsProvider>
                  {children}
                </UserSettingsProvider>
              </TenantsFeatureFlagsProvider>
            </DeprecatedTenantSettingsProvider>
          </TenantConfigProvider>
        </TenantSettingsProvider>
      </RequireEulaAcceptanceGuard>
    </AppApolloProvider>
  )
}

export default SecuredConfigProviders
