import {
  MapLayer,
  withLeaflet,
} from 'react-leaflet'
import L from 'leaflet'

import 'leaflet.markercluster'

L.MarkerClusterGroup.include({
  _flushLayerBuffer() {
    this.addLayers(this._layerBuffer)
    this._layerBuffer = []
  },

  addLayer(layer) {
    if (this._layerBuffer.length === 0) {
      setTimeout(this._flushLayerBuffer.bind(this), 50)
    }
    this._layerBuffer.push(layer)
  },
})

// eslint-disable-next-line func-names
L.MarkerClusterGroup.addInitHook(function() {
  this._layerBuffer = []
})

class MarkerClusterGroup extends MapLayer {
  createLeafletElement({
    leaflet: {
      map,
    },
    ...props
  }) {
    const clusterProps = {}
    const clusterEvents = {}

    Object.entries(props).forEach(([propName, prop]) => {
      propName.startsWith('on') ?
        clusterEvents[propName] = prop :
        clusterProps[propName] = prop
    })

    const markerClusterGroup = new L.markerClusterGroup(clusterProps)
    this.contextValue = {
      layerContainer: markerClusterGroup,
      map,
    }

    Object.entries(clusterEvents).forEach(
      ([eventAsProp, callback]) => {
        const clusterEvent = `cluster${eventAsProp.substring(2).toLowerCase()}`
        markerClusterGroup.on(clusterEvent, callback)
      },
    )

    return markerClusterGroup
  }
}

export default withLeaflet(MarkerClusterGroup)
