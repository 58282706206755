import { FeatureFlagsForTenant } from './models/feature_flags_for_tenant'
import { GET_TENANT_FEATURE_FLAGS_GRAPHQL } from './queries/get_tenant_feature_flags.graphql'

/**
 * @param {object} client - graphql apollo client
 * @param {AbortController} abortController - abort controller
 * @returns {Promise<{featureFlags: FeatureFlagsForTenant}>} network asset
 */
export const getFeatureFlagsForTenant = ({ client, abortController }) =>
  client
    .query({
      query: GET_TENANT_FEATURE_FLAGS_GRAPHQL,
      variables: { time: Date.now() },
      fetchPolicy: 'network-only',
      context: { fetchOptions: { signal: abortController.signal } },
    })
    .then(result => {
      const featureFlags = result && result.data && result.data.getTenantFeatureFlags || null

      return {
        featureFlags: featureFlags ? new FeatureFlagsForTenant(featureFlags) : null,
      }
    })
