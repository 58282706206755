import { convertUnit } from '@@src/utils/app_unit_conversion'
import { INTERNAL_PRESSURE_UNIT } from '@@src/utils/unit_constants'

export default class RawDataRequestDataSlice {
  constructor(data, original = data) {
    this.dataEndDate = data.dataEnd ? new Date(data.dataEnd) : null
    this.dataStartDate = data.dataStart ? new Date(data.dataStart) : null

    Object.assign(this, data)

    this._original = Object.assign({}, original)
    this.units = this.units || INTERNAL_PRESSURE_UNIT

    Object.freeze(this)
  }

  withUnits(units) {
    return units === this.units ? this : new RawDataRequestDataSlice({
      ...this._original,
      units,
      data: this._original.data.map(item => ({
        ...item,
        value: convertUnit(item.value, this.units, units),
      })),
    }, this._original)
  }
}
