import moment from 'moment'

import * as analytics from '@@src/analytics'

export class StandardAlertDetails {
  constructor(data) {
    Object.assign(this, data)
  }
}

export class DeviceNoCommsAlertDetails {
  constructor(data) {
    Object.assign(this, data)
  }
}

export class EventSourceLocalisationAlertDetails {
  constructor(data) {
    Object.assign(this, data)
  }
}

export class EventAlertDetails {
  constructor(data) {
    Object.assign(this, data)
  }
}

export default class Alert {
  static OPEN_STATUS = 'open'
  static ACKNOWLEDGED_STATUS = 'acknowledged'
  static CLOSED_STATUS = 'closed'
  static STATUSES = [
    Alert.OPEN_STATUS,
    Alert.ACKNOWLEDGED_STATUS,
    Alert.CLOSED_STATUS,
  ]

  static PRESSURE_HIGH_TYPE = 'pressure_high'
  static PRESSURE_LOW_TYPE = 'pressure_low'
  static EVENT_SOURCE_LOCALISATION_TYPE = 'event_source_localisation'
  static DEVICE_NO_COMMS_TYPE = 'device_no_comms'
  static EVENT_CPIS_TYPE = 'event_cpis'
  static EVENT_SEVERITY_TYPE = 'event_severity'
  static DAILY_CPIS_LOW_TYPE = 'daily_cpis_low'
  static DAILY_CPIS_HIGH_TYPE = 'daily_cpis_high'
  static BATTERY_DEPLETING_TYPE = 'battery_depleting'
  static TYPES = [
    Alert.PRESSURE_HIGH_TYPE,
    Alert.PRESSURE_LOW_TYPE,
    Alert.EVENT_SOURCE_LOCALISATION_TYPE,
    Alert.DEVICE_NO_COMMS_TYPE,
    Alert.EVENT_CPIS_TYPE,
    Alert.EVENT_SEVERITY_TYPE,
    Alert.DAILY_CPIS_HIGH_TYPE,
    Alert.DAILY_CPIS_LOW_TYPE,
    Alert.BATTERY_DEPLETING_TYPE,
  ]

  constructor(data) {
    Object.assign(this, { details: {} }, data)

    this.occurredAt =
      this.occurredAt ? moment(this.occurredAt).toDate() : this.occurredAt
    this.uuid = `al.${data.alertId}`
  }

  get statusIconClassname() {
    switch (this.status) {
      case Alert.OPEN_STATUS:
        return 'fas fa-exclamation-triangle'
      case Alert.ACKNOWLEDGED_STATUS:
        return 'far fa-search'
      case Alert.CLOSED_STATUS:
        return 'far fa-check'
      default:
        analytics.logError('unknown alert status', this.status)
        return ''
    }
  }

  get descriptionKey() {
    return `common/text:alerts.${this.type}_description`
  }

  get unitKey() {
    // CPIS should no longer be displayed with units, although entries have a unit in the db
    if (this.type.includes('cpis')) {
      return null
    }

    const unit = this.details.unit ? this.details.unit.toLowerCase() : ''
    return `common/text:units.${unit}`
  }

  // Check if values on event should be converted or not
  get areUnitsConvertable() {
    if (
      this.type.includes('cpis')
      || this.isDeviceNoCommsAlert()
      || this.isEventSourceLocalisationAlert()
      || this.isBatteryDepletingAlert()
    ) {
      return false
    }

    return true
  }

  isStandardAlert() {
    return this.details instanceof StandardAlertDetails
  }

  isDeviceNoCommsAlert() {
    return this.details instanceof DeviceNoCommsAlertDetails
  }

  isEventSourceLocalisationAlert() {
    return this.details instanceof EventSourceLocalisationAlertDetails
  }

  isEventAlert() {
    return this.details instanceof EventAlertDetails
  }

  isBatteryDepletingAlert() {
    return this.type === Alert.BATTERY_DEPLETING_TYPE
  }

  isRelatedToNetworkAssets() {
    return this.isStandardAlert() || this.isEventAlert()
  }

  static from(...args) {
    return new Alert(...args)
  }
}
