import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import LoadingIcon from '@@src/components/loading_icon'

import styles from './street_view_link.css'

class StreetViewLink extends PureComponent {
  static propTypes = {
    latitude: PropTypes.number.isRequired,
    longitude: PropTypes.number.isRequired,
    t: PropTypes.func.isRequired,
    googleMaps: PropTypes.object,
    type: PropTypes.oneOf(['link', 'icon']),
  }

  streetViewService = null

  state = {
    fetchingStreetView: true,
    streetViewLink: '',
  }

  componentDidMount() {
    const { googleMaps } = this.props

    if (googleMaps && !this.streetViewService) {
      this.createStreetViewSource()
      this.fetchGoogleMapsPanorama()
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.googleMaps && !this.streetViewService) {
      this.createStreetViewSource()
      this.fetchGoogleMapsPanorama()
    }

    if (this.props.latitude !== prevProps.latitude ||
      this.props.longitude !== prevProps.longitude) {
      this.fetchGoogleMapsPanorama()
    }
  }

  createStreetViewSource = () => {
    const { googleMaps } = this.props

    this.streetViewService = new googleMaps.StreetViewService()
  }

  fetchGoogleMapsPanorama = () => {
    const { googleMaps, latitude, longitude } = this.props

    this.streetViewService.getPanorama({
      location: new googleMaps.LatLng(latitude, longitude),
      source: googleMaps.StreetViewSource.OUTDOOR,
    }, this.handleGoogleMapsPanoramaFetch)

    this.setState({ fetchingStreetView: true })
  }

  handleGoogleMapsPanoramaFetch = (data, status) => {
    const { googleMaps, latitude, longitude } = this.props
    const newState = {
      fetchingStreetView: false,
    }

    if (status === googleMaps.StreetViewStatus.OK) {
      const viewpoint = `viewpoint=${latitude},${longitude}`

      newState.streetViewLink =
        `https://www.google.com/maps/@?api=1&map_action=pano&${viewpoint}`
    } else {
      newState.streetViewLink = ''
    }

    this.setState(newState)
  }

  renderIconStreetViewLink = (streetViewLink) => {
    return(
      streetViewLink ?
        <a
          className={styles['street-view-link']}
          target="_blank"
          rel="noopener noreferrer"
          href={streetViewLink}>
          <i className={`fas fa-street-view text-dark btn btn-light btn-sm
          ${styles['street-view-link-icon']}`} />
        </a>
        :
        <span className={styles['street-view-link']}>
          <i className={`fas fa-street-view text-dark btn btn-light btn-sm disabled
          ${styles['street-view-link-icon']}`} />
        </span>
    )
  }

  renderTextStreetViewLink = (streetViewLink, t) => {
    return(
      streetViewLink ?
        <a
          className={styles['street-view-link']}
          target="_blank"
          rel="noopener noreferrer"
          href={streetViewLink}>
          {t('src/components/maps/markers/street_view_link:text.street_view')}
        </a>
        :
        <p className="m-0 text-muted">
          {t('src/components/maps/markers/street_view_link:text.street_view_unavailable')}
        </p>
    )
  }

  render() {
    const { t, type = 'link' } = this.props
    const { streetViewLink, fetchingStreetView } = this.state

    if (fetchingStreetView) {
      return (
        <LoadingIcon className={styles['loading-icon']}/>
      )
    }
    return (
      type === 'link' ?
        this.renderTextStreetViewLink(streetViewLink, t)
        :
        this.renderIconStreetViewLink(streetViewLink)
    )
  }
}

export default StreetViewLink
