import React, { useContext, useEffect, useMemo, useState } from 'react'
import { CpisDataContext } from './cpis_data_provider'
import { useApolloClient } from '@apollo/client'
import gql from 'graphql-tag'
import moment from 'moment'
import { createSelectGraphQLResult } from '../../../utils'
import { parseGraphQLResult } from '../../../api/presenters'

const cpisDataContainer = (Component) => {
  return (props) => {
    const { fatchingVariables } = props
    const { start, end, networkAssetChannels } = fatchingVariables
    const { setCpisDataResult } = useContext(CpisDataContext)
    const [cpisData, setCpisData] = useState(null)
    const client = useApolloClient()

    const CPIS_SUMMARY_QUERY = gql`
    query CPISSubsetData(
      $start: String!,
      $end: String!,
      $networkAssetChannels: [NetworkAssetChannel],
    ) {
      cpisSubsetData(
        start: $start,
        end: $end,
        networkAssetChannels: $networkAssetChannels,
      ) {
        logicalChannel
        networkAssetId
        chunkedData {
          index
          length
          data {
            time
            cpis
            physicalChannel
          }
        }
      }
    }
    `

    const formatCPISdata = createSelectGraphQLResult('cpisSubsetData', {
      mapResult: parseGraphQLResult,
      nullObject: [],
      bufferData: true,
    })

    const queryStart = useMemo(() => moment(start).startOf('day').toISOString(), [start])
    const queryEnd = useMemo(() => moment(end).subtract(1, 'minute').add(2, 'day').startOf('day').toISOString(), [end])

    const fetchNewCpisData = async (cpisStart, cpisEnd, cpisNetworkAssetChannels) => {
      await client.query({
        query: CPIS_SUMMARY_QUERY,
        variables: {
          start: cpisStart,
          end: cpisEnd,
          networkAssetChannels: cpisNetworkAssetChannels,
        },
      }).then((results) => {
        const formatedData = formatCPISdata(results.data)
        setCpisData(formatedData)
        setCpisDataResult(formatedData)
      })
    }

    useEffect(() => {
      fetchNewCpisData(queryStart, queryEnd, networkAssetChannels)
    }, [queryStart, queryEnd, networkAssetChannels])

    return <Component
      cpisDataResult={cpisData}
      {...props} />
  }
}

export default cpisDataContainer
