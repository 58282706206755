import React, { useState, useEffect } from 'react'
import L from 'leaflet'
import AppLayout from '@@src/components/app_layout'
import {
  Col, Row, Button, Container,
} from 'reactstrap'
import classnames from 'classnames'
import { NavLink } from 'react-router-dom'
import RemoveAggregateFromGroupConfirmationPrompt
from '@@src/components/modals/remove_aggregate_from_group_confirmation_prompt'
import Device from '@@src/api/presenters/device'
import DevicesMap from '../../../components/maps/devices_map'
import DeviceIssueAlert from '@@src/components/alerts/device_issue_alert'
import AddAggregateToGroupModal from '../add_aggregate_to_group_modal'
import NetworkAsset from '@@src/api/presenters/network_asset'
import NetworkAssetMap from '../../../components/maps/network_asset_map'
import { ADD_ASSETS, REMOVE_AGGREGATE } from '../group_detail_page'
import GroupBreadcrumb from '../group_breadcrumb'
import GroupTypeIcon from '@@src/components/icons/group_type_icon'
import CollapsibleAlertsSection
from './collapsible_alerts_section'
import ReactTooltip from 'react-tooltip'
import ManagementGroupsNetworkAssetList from '../../../components/lists/mamagement_group_lists/network_asset_list'
import ManagementGroupsDeviceList from '../../../components/lists/mamagement_group_lists/device_list'
import EditGroupButton from '../../../components/buttons/edit_group_button'
import routes from '@@src/routes'

import styles from '../group_detail_page.css'

function GroupOfAssetsOrDevicesDetail(props) {
  const {
    t,
    group,
    onMove,
    onDelete,
    onToggleOverlay,
    overlayState,
    refetchPageData,
    groupResult,
    mostSevereMemberDeviceIssues,
    onRemoveNetworkAsset,
    onRemoveDevice,
    canEdit,
    getDisabledEditTooltip,
    onClickAddNetworkAssetButton,
    findAllUuidForGroupOfAssetsOrDevices,
  } = props

  const [mapBounds, setMapBounds] = useState(undefined)
  const [aggregatesInfo, setAggregatesInfo] = useState({
    aggregates: [],
    aggregateType: undefined,
    uuidGroupArray: [],
  })
  const [selectedAggregate, setSelectedAggregate] = useState(null)
  const [mapOptions, setMapOptions] = useState({
    mapZoom: undefined,
    mapCenter: undefined,
  })

  function onClickDeviceMarker(commission) {
    setSelectedAggregate(commission.device)
  }

  function onClickNetworkAssetMarker(aggregate) {
    setSelectedAggregate(aggregate)
  }

  useEffect(() => {
    const groupAggregates = group.members ? group.members.data : []

    const res = groupAggregates.length ?
      L.latLngBounds(groupAggregates.map((aggregate) => {
        if (aggregate instanceof Device) {
          if (aggregate.lastKnownLocation) {
            return L.latLng(aggregate.lastKnownLocation.latitude,
              aggregate.lastKnownLocation.longitude)
          } else {
            return null
          }
        } else {
          return L.latLng(aggregate.location.latitude,
            aggregate.location.longitude)
        }
      })) : undefined

    setAggregatesInfo({
      aggregates: groupAggregates,
      aggregateType: group.members ? group.members.type : undefined,
      uuidGroupArray: findAllUuidForGroupOfAssetsOrDevices(group),
    })

    setMapBounds((res && res.isValid()) ? res : undefined)

  }, [group])

  useEffect(()=> {
    if(!selectedAggregate) {
      setMapOptions({
        mapZoom: undefined,
        mapCenter: undefined,
      })
      return
    }

    if (selectedAggregate instanceof Device) {
      if (selectedAggregate.lastKnownLocation) {
        setMapOptions({
          mapCenter: [selectedAggregate.lastKnownLocation.latitude,
            selectedAggregate.lastKnownLocation.longitude],
          mapZoom: 15,
        })
        return
      } else {
        setMapOptions({
          mapZoom: undefined,
          mapCenter: undefined,
        })
        return
      }
    } else {
      setMapOptions({
        mapCenter: [selectedAggregate.location.latitude, selectedAggregate.location.longitude],
        mapZoom: 15,
      })
    }
  }, [selectedAggregate])

  const selectedAggregateId = selectedAggregate ?
    selectedAggregate.id : null
  const groupHasDeviceIssues = group.mostSevereDeviceIssue !== null

  return (
    <AppLayout
      title={t('headings.page_title', {
        group: group.name,
      })}
      contentsStyle="fixed-at-full-height">
      <Container fluid={true} className="h-100">
        <AddAggregateToGroupModal
          isOpen={overlayState.name === ADD_ASSETS}
          toggle={onToggleOverlay}
          onSuccess={refetchPageData}
          aggregateType={aggregatesInfo.aggregateType}
          group={group} />

        <RemoveAggregateFromGroupConfirmationPrompt
          group={overlayState.group}
          toggle={onToggleOverlay}
          isOpen={overlayState.name === REMOVE_AGGREGATE}
          aggregate={overlayState.aggregate}
          aggregateType={overlayState.aggregateType}
          onRemovedAggregate={refetchPageData} />

        <Row className="h-100">
          <Col className={styles['group-details-section']}>
            <Row className="flex-grow-0 flex-shrink-0">
              <Col sm="12">
                <GroupBreadcrumb groupResult={groupResult} />
              </Col>
            </Row>

            <Row>
              <Col className="mb-5 d-flex align-items-center flex-row
                justify-content-between">
                <div>
                  <div className="d-flex align-items-center">
                    <GroupTypeIcon
                      backgroundColor="bg-light"
                      group={group}
                      className="mr-3 fa-2x" />
                    <h1 className="mb-0 text-break">
                      {group.name}
                    </h1>
                  </div>
                  <div className="mt-2">
                    <span className="text-muted text-capitalize">
                      {t(`common/text:text.category_${group.category}`)}
                    </span>
                  </div>
                </div>

                <div className={styles['header-options']}>
                  <NavLink
                    to={routes.analysisPressurePath({
                      d: aggregatesInfo.uuidGroupArray,
                    })}
                    className={
                      classnames(
                          styles.button,
                          'btn btn-light mr-2',
                          (group.membersCount === 0) ? 'disabled' : undefined
                      )
                    }>

                    <i className="fa fa-chart-area" />
                  </NavLink>

                  <EditGroupButton
                    t={t}
                    group={group}
                    canEdit={canEdit}
                    onMove={onMove}
                    onDelete={onDelete}
                    refetchGroups={refetchPageData}
                    dataTip={getDisabledEditTooltip(group)}/>
                </div>

              </Col>
            </Row>

            <Row className={styles['edit-button']}>
              <Col sm="12">
                <span data-tip={getDisabledEditTooltip(group)}>
                  <Button
                    name="edit-group-members"
                    disabled={!canEdit}
                    color="primary"
                    onClick={onClickAddNetworkAssetButton}
                    className="mb-3">
                    <i className="far fa-edit"></i>
                      &nbsp;
                    {aggregatesInfo.aggregateType === 'VNetworkAsset' ?
                      t('buttons.edit_network_assets') :
                      aggregatesInfo.aggregateType === 'VDevice' ?
                        t('buttons.edit_devices') :
                        t('buttons.edit_aggregates')
                    }
                  </Button>
                </span>
              </Col>
            </Row>

            {
              groupHasDeviceIssues &&
              mostSevereMemberDeviceIssues &&
              mostSevereMemberDeviceIssues.length ?
                <div className="mt-4 mb-5">
                  {
                    mostSevereMemberDeviceIssues.map(({ member, issue }) => (
                      <DeviceIssueAlert
                        deviceIssue={issue}
                        key={member.uuid}
                        sourceToLinkTo={member}>
                        <strong>
                          {
                            issue.isError() ?
                              t('text.device_errors') :
                              issue.isWarning() ?
                                t('text.device_warnings') :
                                t('text.device_information')
                          }
                        </strong>
                        &nbsp;
                        {
                          member instanceof NetworkAsset ?
                            t('common/text:text.asset_issue_description', {
                              assetId: member.assetId,
                            })
                            :
                            t('common/text:text.device_issue_description', {
                              serialNumber: member.serialNumber,
                            })
                        }
                      </DeviceIssueAlert>)
                    )
                  }
                </div>
                : null
            }

            <CollapsibleAlertsSection
              groupId={group.id}
              mutedUntil={group.currentAlertsMuteEnd}
              className={styles['alerts-section-group-root']} />

            {aggregatesInfo.aggregateType === 'VNetworkAsset' ?
              <ManagementGroupsNetworkAssetList
                t={t}
                networkAssets={aggregatesInfo.aggregates}
                onClick={(asset) => onRemoveNetworkAsset(asset, group)}
                dataTip={getDisabledEditTooltip(group)}
                canEdit={canEdit}
              />
              :
              <ManagementGroupsDeviceList
                devices = {aggregatesInfo.aggregates}
                onClick={(device) => onRemoveDevice(device, group)}
                canEdit={canEdit}
              />
            }

          </Col>
          <Col className={styles['group-map-section']}>
            {
              aggregatesInfo.aggregateType === 'VNetworkAsset' ?
                <NetworkAssetMap
                  networkAssets={aggregatesInfo.aggregates}
                  onClick={onClickNetworkAssetMarker}
                  selectedAggregateId={selectedAggregateId}
                  mapCenter={mapOptions.mapCenter}
                  mapZoom={mapOptions.mapZoom}
                  mapBounds={mapBounds} />
                :
                <DevicesMap
                  devices={aggregatesInfo.aggregates}
                  onClick={onClickDeviceMarker}
                  selectedAggregateId={selectedAggregateId}
                  mapCenter={mapOptions.mapCenter}
                  mapZoom={mapOptions.mapZoom}
                  mapBounds={mapBounds} />
            }
          </Col>
        </Row>
        {
          canEdit ? null :
          <ReactTooltip
            effect="solid"
            place="bottom" />
        }
      </Container>
    </AppLayout>
  )
}

export default GroupOfAssetsOrDevicesDetail
