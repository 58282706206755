import { useCallback, useEffect, useMemo, useState } from 'react'
import { ANOMALIES_DATA_QUERY } from '../../analysis_path/pressure_analysis_path/pressure_subset_graph/graphql'
import { parseGraphQLResult } from '@@src/api/presenters'
import { AsyncResult } from '../../utils'

const MAX_SOURCES_PER_FETCH = 5

const useGetAnomaliesData = (props, anomalyEnabled) => {
  const [anomaliesData, setAnomaliesData] = useState(undefined)
  const [loading, setLoading] = useState(0)
  const [totalParts, setTotalParts] = useState(0)
  const [error, setError] = useState('')

  const formatResults = useCallback((result) => {
    if (!result.data) {
      return
    }

    setAnomaliesData((oldAnomalies) => {
      if (oldAnomalies === undefined) {
        return { ...result.data.getAnomalies }
      }
      return { ...oldAnomalies, anomalies: [...oldAnomalies.anomalies, ...result.data.getAnomalies.anomalies] }
    })
  }, [setAnomaliesData])

  const anomaliesDataResult = useMemo(() => {
    if (anomaliesData === null || props.skip === true || !anomalyEnabled) {
      return AsyncResult.success({
        data: [],
        activeParts: 1,
        totalParts: 1,
      })
    }

    if (anomaliesData === undefined) {
      return AsyncResult.pending({
        data: [],
        activeParts: loading + 1,
        totalParts: totalParts + 1,
      })
    }

    if (loading !== 0) {
      return AsyncResult.pending({
        ...parseGraphQLResult(anomaliesData),
        activeParts: loading + 1,
        totalParts: totalParts + 1,
      })
    }

    return AsyncResult.success(parseGraphQLResult(anomaliesData))
  }, [anomaliesData, loading, totalParts, anomalyEnabled])

  const fetchData = useCallback(
    async (
      client,
      start,
      end,
      pressureUnit,
      networkAssetChannels,
      signal,
    ) => {
      const anomalyQuery = client.query(
        {
          query: ANOMALIES_DATA_QUERY,
          variables: {
            sources: networkAssetChannels,
            pressureUnit,
            start,
            end,
          },
          context: {
            fetchOptions: {
              signal,
            },
          },
        }
      )

      setLoading((oldLoading) => oldLoading + 1)
      setTotalParts((oldTotalParts) => oldTotalParts + 1)

      let results = []
      try {
        results = await anomalyQuery
      } catch (err) {
        setError(err)
      }

      results && formatResults(results)
      setLoading((loadingOld) => loadingOld - 1)
    }, [formatResults])

  const fetchPartialData = useCallback(() => {
    const { start, end, client, networkAssetChannels, units, signal } = props

    for (let i = 0; i < networkAssetChannels.length; i += MAX_SOURCES_PER_FETCH) {
      const chunk = networkAssetChannels.slice(i, i + MAX_SOURCES_PER_FETCH)
      fetchData(
        client,
        new Date(start).toISOString(),
        new Date(end).toISOString(),
        units.pressure,
        chunk,
        signal,
      )
    }

  }, [props.start, props.end, props.client, props.networkAssetChannels, props.units, props.signal])

  useEffect(() => {
    if (anomalyEnabled && Object.keys(props).length) {
      const { networkAssetChannels } = props
      if (networkAssetChannels.length === 0) {
        setAnomaliesData(null)
      } else {
        fetchPartialData()
      }
      return () => {
        setLoading(0)
        setTotalParts(0)
        setError('')
        setAnomaliesData(undefined)
      }
    }
    return () => { }
  }, [props.start, props.end, props.client, props.networkAssetChannels, props.units, anomalyEnabled])

  return { anomalyResult: anomaliesDataResult, error, loading: loading !== 0 }
}

export default useGetAnomaliesData
