import React, { useState } from 'react'
import gql from 'graphql-tag'
import { compose } from 'redux'
import PropTypes from 'prop-types'

import requiresLogin from '@@src/components/security/requires_login'
import transformProps from '@@src/components/transform_props'
import { createSelectGraphQLResult } from '@@src/utils'
import { useQuery } from '@apollo/client'

const DOWNLOAD_REPORTS_QUERY = gql`
  query DownloadReportsQuery {
    dataReports {
      ... on DataReportListItem {
        jobId
        start
        end
        jobCreated
        reportStatus
        reportType
        dataType
        logicalChannel
        aggregateType
        aggregateName
        estimatedCompletion
        fileListStatus {
          reportStatus
          uuid
        }
      }
      
      ... on AlertReportListItem {
        jobId
        dataType
        jobCreated
        reportType
        reportStatus
        fileListStatus {
          reportStatus
          uuid
        }
      }

      ... on DevicesDataReportItem {
        jobId
        dataType
        reportType
        jobCreated
        reportStatus
        fileListStatus {
          reportStatus
          uuid
        }
      }

      ... on NetworkAssetsDataReportItem {
        jobId
        dataType
        reportType
        jobCreated
        reportStatus
        fileListStatus {
          reportStatus
          uuid
        }
      }
      
      ... on EventsDataReportItem {
        jobId
        dataType
        jobCreated
        reportType
        reportStatus
        start
        end
        fileListStatus {
          reportStatus
          uuid
        }
      }
      
      ... on DevicesHealthDataReportItem {
        jobId
        dataType
        reportType
        jobCreated
        reportStatus
        fileListStatus {
          reportStatus
          uuid
        }
      }

      
      ... on AnomalyReportItem {
        jobId
        jobCreated
        reportStatus
        dataType
        reportType
        start
        end
        fileListStatus {
          reportStatus
          uuid
        }
      }
    }
  }
`

const withDownloads = Component => {
  function DownloadsContainer(props) {
    const {
      selectDataReportsResult,
      pollInterval,
      maxRetryCount,
      ...rest
    } = props
    const [retryCount, setRetryCount] = useState(0)
    const {
      stopPolling,
      startPolling,
      ...result
    } = useQuery(DOWNLOAD_REPORTS_QUERY, {
      onError() {
        if (retryCount >= maxRetryCount) {
          stopPolling()
        } else {
          if (Number.isFinite(pollInterval)) {
            startPolling(pollInterval)
          }

          setRetryCount(retryCount + 1)
        }
      },
      onCompleted() {
        if (Number.isFinite(pollInterval)) {
          startPolling(pollInterval)
          setRetryCount(0)
        }
      },
    })
    const downloads = selectDataReportsResult(result)

    return (
      <Component downloads={downloads} {...rest} />
    )
  }

  DownloadsContainer.propTypes = {
    selectDataReportsResult: PropTypes.func.isRequired,
    maxRetryCount: PropTypes.number.isRequired,
  }

  DownloadsContainer.defaultProps = {
    maxRetryCount: 3,
  }

  return compose(
    requiresLogin,
    transformProps(() => () => ({
      selectDataReportsResult: createSelectGraphQLResult('data', {
        mapResult: ({ dataReports }) => dataReports,
        nullObject: [],
      }),
    })),
  )(DownloadsContainer)
}

export default withDownloads
