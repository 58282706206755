import GeoCoordinate from '../../../utils/graphs/geo_coordinate'

export class NetworkAssetForMapItem {
  constructor(props) {
    this.assetId = props.assetId
    this.type = props.type
    this.activeErrorCount = props.activeErrorCount
    this.activeWarningCount = props.activeWarningCount
    this.activeInformationCount = props.activeInformationCount
    this.lat = props.lat
    this.lng = props.lng
    this.alt = props.alt
  }

  getCoordinates() {
    return GeoCoordinate.fromValue(this.lat, this.lng)
  }
}
