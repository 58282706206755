import React from 'react'
import { useTranslation } from 'react-i18next'
import requiresLogin from '../../../../components/security/requires_login'

import AppLayout from '../../../../components/app_layout'
import NetworkAssetsListComponent from './components/network_assets_list.component'
import NetworkAssetsMapComponent from './components/network_assets_map.component'

import useUrlFilterControll from '../hooks/useUrlFilterControll'
import { parseFiltersFromLocation } from '../../../utils'

const NetworkAssetsPathV2 = () => {
  const { t } = useTranslation('v2/common/text')
  const {
    listQueryFilters,
    mergedFilters,
    currentMapPosition,
    setQueryFilters,
    setListQueryPagination,
    onPositionUpdate,
  } = useUrlFilterControll(
    parseFiltersFromLocation(location.search, { filters: ['any'] })
  )

  return (
    <AppLayout
      title={t('v2/common/text:network_assets')}
      contentsStyle="fixed-at-full-height"
    >
      <div className="w-100 h-100 d-flex flex-row">
        <div className="h-100 w-50">
          <NetworkAssetsListComponent
            onShowEverywhereClick={() => onPositionUpdate(null)}
            setFilters={setQueryFilters}
            setPagination={setListQueryPagination}
            filters={mergedFilters}
          />
        </div>
        <div className="h-100 w-50">
          <NetworkAssetsMapComponent
            filters={listQueryFilters}
            currentMapPosition={currentMapPosition}
            onPositionUpdate={onPositionUpdate}
          />
        </div>
      </div>
    </AppLayout>
  )
}

export default requiresLogin(NetworkAssetsPathV2)
