import React from 'react'

export default function AnomalyEndIcon({ color }) {

  return(
    <>
      <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M22.682 9.713a3.242 3.242 0 0 1 0 4.584l-8.383 8.383a3.242 3.242 0 0
            1-4.585 0L1.33 14.297a3.242 3.242 0 0 1 0-4.584L9.714 1.33a3.242 3.242 0 0
            1 4.585 0l8.383 8.383z"
          fill="white"
          fillRule="evenodd"/>
      </svg>
      <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10.875 13.125V16a1.125 1.125 0 0 0 2.25 0V8c0-1.452-2.107-1.498-2.243-.14l-.007.14v2.875H.584a3.23
            3.23 0 0 1 .747-1.162L9.714 1.33a3.242 3.242 0 0 1 4.585 0l8.383 8.383a3.242 3.242 0 0 1 0 4.584l-8.383
            8.383a3.242 3.242 0 0 1-4.585 0L1.33 14.297a3.23 3.23 0 0 1-.751-1.172h10.295z"
          fill={color}
          fillRule="evenodd"/>
      </svg>
    </>
  )
}
