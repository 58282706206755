import gql from 'graphql-tag'
import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { get } from 'lodash/fp/object'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { graphql } from '@apollo/client/react/hoc'
import { createSelector } from 'reselect'
import { map, partition } from 'lodash/fp/collection'
import { withTranslation } from 'react-i18next'
import { Alert } from 'reactstrap'

import AsyncResult from '@@src/utils/async_result'
import { parseGraphQLResult } from '@@src/api/presenters'
import { DEFAULT_TIMEZONE, createSelectGraphQLResult } from '@@src/utils'
import { ORDER_ASC, ORDER_DESC } from
'@@src/analysis_path/pressure_analysis_path/with_orderby_and_direction'
import EventListTableContent from
'@@src/analysis_path/pressure_analysis_path/event_list_table/event_list_table_content' // eslint-disable-line max-len
import EventFilterAlert from '@@src/components/alerts/event_filter_alert'

export const START_TIME_KEY = 'startTime'
export const END_TIME_KEY = 'endTime'
export const MIN_PRESSURE_KEY = 'minPressure'
export const MAX_PRESSURE_KEY = 'maxPressure'
export const MEAN_PRESSURE_KEY = 'meanPressure'
export const CPIS_KEY = 'cpis'
export const SEVERITY_KEY = 'severity'

class EventListTable extends React.PureComponent {
  static defaultProps = {
    timezone: DEFAULT_TIMEZONE,
    orderBy: START_TIME_KEY,
    onClickViewEvents: () => { },
    setActiveExpandedEventId: () => { },
  }

  static propTypes = {
    page: PropTypes.number.isRequired,
    endTime: PropTypes.instanceOf(Date).isRequired,
    perPage: PropTypes.number.isRequired,
    timezone: PropTypes.string.isRequired,
    startTime: PropTypes.instanceOf(Date).isRequired,
    onChangePerPage: PropTypes.func,
    legendItemsResult: PropTypes.instanceOf(AsyncResult).isRequired,
    onClickViewEvents: PropTypes.func.isRequired,
    setOrderByAndDirection: PropTypes.func.isRequired,
    orderBy: PropTypes.oneOf([
      START_TIME_KEY,
      END_TIME_KEY,
      MIN_PRESSURE_KEY,
      MAX_PRESSURE_KEY,
      MEAN_PRESSURE_KEY,
      CPIS_KEY,
      SEVERITY_KEY,
    ]).isRequired,
    orderDirection: PropTypes.oneOf([ORDER_ASC, ORDER_DESC]).isRequired,
    t: PropTypes.func.isRequired,
    setActiveExpandedEventId: PropTypes.func.isRequired,
    onClearEvents: PropTypes.func.isRequired,
    eventIds: PropTypes.array,
  }

  render() {
    const {
      className, page, perPage, createPageLink, onChangePerPage, timezone,
      pagedPressureEventsResult, t, orderBy, eventIds, onClearEvents,
      orderDirection, setOrderByAndDirection, legendItemsResult,
    } = this.props
    const { totalPages } = pagedPressureEventsResult.data

    return (
      <React.Fragment>
        {
          Array.isArray(eventIds) ?
            <EventFilterAlert
              handleClickShowAll={onClearEvents} />
            : null
        }
        <EventListTableContent
          orderBy={orderBy}
          orderDirection={orderDirection}
          setOrderByAndDirection={setOrderByAndDirection}
          perPage={perPage}
          page={page}
          totalPages={totalPages}
          onChangePerPage={onChangePerPage}
          pagedPressureEventsResult={pagedPressureEventsResult}
          legendItemsResult={legendItemsResult}
          createPageLink={createPageLink}
          onClickViewEvents={this.onClickViewEvents}
          className={className}
          timezone={timezone}>
          {this.hasAnyDevices() ? (
            <Alert color="warning">
              {t('warnings.events_for_devices_not_supported')}
            </Alert>
          ) : null}
        </EventListTableContent>
      </React.Fragment>
    )
  }

  onClickViewEvents = (event) => {
    const { setActiveExpandedEventId, onClickViewEvents } = this.props

    setActiveExpandedEventId(event.id, true)
    onClickViewEvents(event)
  }

  getAssetId = id => {
    const { t, dataSourcesAndGroupsResult = [] } = this.props

    const dataSource = dataSourcesAndGroupsResult
      .find(data => String(data.id) === String(id))

    return dataSource ? dataSource.assetId : t('errors.unknown')
  }

  hasAnyDevices() {
    return this.props.legendItemsResult.data.some(item => {
      return item.isFromDevice()
    })
  }

  static EVENT_LIST_QUERY = gql`
    query EventList(
      $end: String!,
      $page: Int!,
      $start: String!,
      $perPage: Int!,
      $networkAssetChannels: [NetworkAssetChannel],
      $orderBy: String,
      $orderDirection: String,
      $eventIds: [Int]
    ) {
      pagedPressureEvents(
        end: $end,
        start: $start,
        pageNumber: $page,
        resultsPerPage: $perPage,
        networkAssetChannels: $networkAssetChannels,
        orderBy: $orderBy,
        orderDirection: $orderDirection,
        eventIds: $eventIds
      ) {
        totalPages
        totalResults
        pressureEvents {
          id
          assetId
          endTime
          startTime
          maxPressure
          minPressure
          meanPressure
          logicalChannel
          eventCharacteristics {
            id
            value
            eventCharacteristic
          }
          eventClasses {
            id
            eventClass
          }
        }
      }
    }
  `
}

function createMapStateToProps() {
  const selectPressureEventsResultFromGraphQLResult =
    createSelectGraphQLResult('pagedPressureEvents', {
      mapResult: parseGraphQLResult,
      nullObject: {
        page: 0,
        totalPages: 0,
        totalResults: 0,
        pressureEvents: [],
      },
    })

  const selectPressureEventsQueryVariables = createSelector(
    [
      get('legendItemsResult'),
      get('startTime'),
      get('endTime'),
      get('page'),
      get('perPage'),
      get('orderBy'),
      get('orderDirection'),
      get('eventIds'),
    ],
    (
      legendItemsResult,
      startTime,
      endTime,
      page,
      perPage,
      orderBy,
      orderDirection,
      eventIds,
    ) => {
      const { data: legendItems } = legendItemsResult

      const [deviceSources, assetSources] =
        partition(item => item.isFromDevice())(legendItems)

      const deviceIds = map('sourceId')(deviceSources)
      const networkAssetChannels =
        assetSources.map(({ sourceId, sourceChannel }) => {
          return { networkAssetId: sourceId, channel: sourceChannel }
        })

      const variables = {
        end: moment(endTime).toISOString(),
        page,
        start: moment(startTime).toISOString(),
        perPage,
        deviceIds,
        networkAssetChannels,
        orderBy,
        orderDirection,
      }

      if (Array.isArray(eventIds)) {
        variables.eventIds = eventIds
      }

      return variables
    }
  )

  return function mapStateToProps() {
    return {
      selectPressureEventsQueryVariables,
      selectPressureEventsResultFromGraphQLResult,
    }
  }
}

export default compose(
  withTranslation([
    'src/analysis_path/pressure_analysis_path/event_list_table',
  ]),
  connect(createMapStateToProps),
  graphql(EventListTable.EVENT_LIST_QUERY, {
    options: ownProps => {
      return {
        variables: ownProps.selectPressureEventsQueryVariables(ownProps),
        fetchPolicy: 'network-only',
      }
    },
    props: ({ data, ownProps }) => {
      const { selectPressureEventsResultFromGraphQLResult } = ownProps

      return {
        refetchData: () => data.refetch(),
        pagedPressureEventsResult:
          selectPressureEventsResultFromGraphQLResult(data),
      }
    },
  })
)(EventListTable)
