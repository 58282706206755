import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash/fp/object'
import { line, area } from 'd3-shape'
import { createSelector } from 'reselect'

import GraphContext, { GraphConfig }
from '@@src/components/graphs/graph_context'
import { globalSequence } from '@@src/utils'
import { LegendItemColorContext } from
'@@src/components/colors/legend_item_color_provider'

export default function SubsetPlotsLayerContainer(props) {
  return (
    <GraphContext.Consumer>
      {config => (
        <LegendItemColorContext.Consumer>
          {({ getLegendItemColor }) => (
            <SubsetPlotsLayer
              getLegendItemColor={getLegendItemColor}
              graphConfig={config} {...props} />
          )}
        </LegendItemColorContext.Consumer>
      )}
    </GraphContext.Consumer>
  )
}

function PathPlot(props) {
  return (
    <path fill="none" stroke={props.color} {...props} />
  )
}

function AreaPlot(props) {
  return (
    <path fill={props.color} stroke="none" {...props} />
  )
}

function CirclePlot(props) {
  return (
    <circle fill={props.color} stroke="none" {...props} />
  )
}

function LinePlot(props) {
  return (
    <line fill="none" stroke={props.color} {...props} />
  )
}

class SubsetPlotsLayer extends React.PureComponent {
  static propTypes = {
    graphItems: PropTypes.array.isRequired,
    graphConfig: PropTypes.instanceOf(GraphConfig).isRequired,
    displayMinMaxLine: PropTypes.bool.isRequired,
    getLegendItemColor: PropTypes.func.isRequired,
  }

  static defaultProps = {
    displayMinMaxLine: false,
  }

  id = globalSequence.next()

  renderMinMaxLines = (graphItem, segment, clipPathId) => {
    const { graphConfig, getLegendItemColor } = this.props
    const maxLineGenerator = this.selectMaxLineGenerator(this.props)
    const minLineGenerator = this.selectMinLineGenerator(this.props)

    if (segment.data.length === 1) {
      return (
        <>
          <CirclePlot
            clipPath={`url(#${clipPathId})`}
            r={1}
            cx={graphConfig.xScale(segment.data[0].time)}
            cy={graphConfig.yScale(segment.data[0].max)}
            color={4} />
          <CirclePlot
            clipPath={`url(#${clipPathId})`}
            r={1}
            cx={graphConfig.xScale(segment.data[0].time)}
            cy={graphConfig.yScale(segment.data[0].min)}
            color={getLegendItemColor(graphItem)} />
        </>
      )
    } else {
      return (
        <>
          <PathPlot
            d={maxLineGenerator(segment.data)}
            color={getLegendItemColor(graphItem.legendItem)}
            clipPath={`url(#${clipPathId})`} />
          <PathPlot
            d={minLineGenerator(segment.data)}
            color={getLegendItemColor(graphItem.legendItem)}
            clipPath={`url(#${clipPathId})`} />
        </>
      )
    }
  }

  renderRawDataDots = clipPathId => {
    const { graphConfig, getLegendItemColor } = this.props
    const networkAssetGraphItems = this.selectNetworkAssetGraphItems(this.props)

    return (
      <g name="raw-data-points-layer" clipPath={`url(#${clipPathId})`}>
        {
          networkAssetGraphItems.map(graphItem => (
            graphItem.rawDataChunks.map(c => c.map(({ time, mean }, i) => (
              <CirclePlot
                key={`${time.getTime()}-${i}`}
                r="3"
                color={getLegendItemColor(graphItem.legendItem)}
                cx={graphConfig.xScale(time)}
                cy={graphConfig.yScale(mean)} />
            )))
          ))
        }
      </g>
    )
  }

  selectNetworkAssetGraphItems = createSelector(
    [(get('graphItems'))],
    graphItems => graphItems.filter(item => !item.isFromDevice())
  )

  renderMultiPointPlots = (graphItem, segment, clipPathId) => {
    const { getLegendItemColor } = this.props
    const meanLineGenerator = this.selectMeanLineGenerator(this.props)
    const areaGenerator = this.selectSubsetEnvelopeAreaGenerator(this.props)
    const legendItemColor = getLegendItemColor(graphItem.legendItem)
    return (
      <>
        <AreaPlot
          opacity={0.1}
          d={areaGenerator(segment.data)}
          color={legendItemColor}
          clipPath={`url(#${clipPathId})`} />
        <PathPlot
          d={meanLineGenerator(segment.data)}
          color={legendItemColor}
          clipPath={`url(#${clipPathId})`} />
      </>
    )
  }

  renderSinglePointPlots = (graphItem, segment, clipPathId) => {
    const { graphConfig, getLegendItemColor } = this.props
    const { time, mean, max, min } = segment.data[0]
    const scaledTime = graphConfig.xScale(time)
    const scaledMax = graphConfig.yScale(max)
    const scaledMin = graphConfig.yScale(min)
    const scaledMean = graphConfig.yScale(mean)
    const legendItemColor = getLegendItemColor(graphItem.legendItem)

    return (
      <>
        <LinePlot
          x1={scaledTime}
          y1={scaledMax}
          x2={scaledTime}
          y2={scaledMin}
          opacity={0.1}
          color={legendItemColor}
          clipPath={`url(#${clipPathId})`} />
        <CirclePlot
          clipPath={`url(#${clipPathId})`}
          r={1}
          cx={scaledTime}
          cy={scaledMean}
          color={legendItemColor} />
      </>
    )
  }

  render() {
    const { name, graphConfig, graphItems, displayMinMaxLine } = this.props
    const clipPathId = `subset-plots-layer-clip-path-${this.id}`
    const topOffset = graphConfig.topPadding
    const leftOffset = graphConfig.leftPadding

    let ticksDeltaMs = 1000

    if (graphConfig.xScale.ticks().length > 1) {
      const firstTickMs = graphConfig.xScale.ticks()[0]
      const secondTickMs = graphConfig.xScale.ticks()[1]
      ticksDeltaMs = secondTickMs - firstTickMs
    }

    return (
      <g name={name || 'subset-plots-layer'}>
        <defs>
          <clipPath id={clipPathId}>
            <rect
              x={leftOffset}
              y={topOffset}
              width={graphConfig.plotAreaWidth}
              height={graphConfig.plotAreaHeight} />
          </clipPath>
        </defs>

        {
          graphItems.map(graphItem => (
            <g key={graphItem.id} name={`plot-${graphItem.id}`}>
              {
                graphItem.data.map((segment, index) => (
                  <g key={index} name={`plot-${graphItem.id}-${index}`}>
                    {
                      segment.data.length === 1 ?
                        this.renderSinglePointPlots(
                          graphItem,
                          segment,
                          clipPathId
                        ) :
                        this.renderMultiPointPlots(
                          graphItem,
                          segment,
                          clipPathId
                        )
                    }
                    {
                      displayMinMaxLine ?
                        this.renderMinMaxLines(graphItem, segment, clipPathId) :
                        null
                    }
                  </g>
                ))
              }
            </g>
          ))
        }

        {ticksDeltaMs <= 100 ? this.renderRawDataDots(clipPathId) : null}
      </g>
    )
  }

  selectSubsetEnvelopeAreaGenerator = createSelector(
    [get('graphConfig')],
    graphConfig => {
      return area()
        .x(d => graphConfig.xScale(d.time))
        .y0(d => graphConfig.yScale(d.min))
        .y1(d => graphConfig.yScale(d.max))
    }
  )

  selectMeanLineGenerator = createSelector(
    [get('graphConfig')],
    graphConfig => {
      return line()
        .x(d => graphConfig.xScale(d.time))
        .y(d => graphConfig.yScale(d.mean))
    }
  )

  selectMaxLineGenerator = createSelector(
    [get('graphConfig')],
    graphConfig => {
      return line()
        .x(d => graphConfig.xScale(d.time))
        .y(d => graphConfig.yScale(d.max))
    }
  )

  selectMinLineGenerator = createSelector(
    [get('graphConfig')],
    graphConfig => {
      return line()
        .x(d => graphConfig.xScale(d.time))
        .y(d => graphConfig.yScale(d.min))
    }
  )
}
