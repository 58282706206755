import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { I18nextProvider } from 'react-i18next'
import { Route, Switch, Redirect } from 'react-router-dom'

import * as analytics from '@@src/analytics'
import { AppConfigContext, AppConfigProvider } from './_v2/contexts/app_config/app-config.context'
import { AuthorizationProvider } from './_v2/contexts/authorization/authorization.context'
import {
  FeatureFlags,
  isFeatureFlagEnabled,
} from './_v2/contexts/feature_flags/tenants_feature_flags.context'
import { UserProvider } from './_v2/contexts/user/user.context'
import routes from './routes'
import FreshdeskWrapper from './components/app_freshdesk_wrapper'
import WindowSizesProvider from './components/window_size_provider'
import AppError from './utils/app_error'
import HomePage from './home_path/index_page'
import LoginPage from './login_path/index_page'
import GroupsPage from './management_path/groups_path/index_page'
import AppInfoPage from './app_info_page'
import ErrorWrapper from './error_path/error_wrapper'
import DownloadsPage from '@@src/downloads_path/index_page'
import EditGroupPage from './management_path/groups_path/edit_group_page'
import AdminUsersPage from '@@src/admin_path/users/index_page'
import AdminTenantSettingsPage from '@@src/admin_path/tenant_settings/index_page'
import AlertsPage from '@@src/alerts_path/index_page'
import AlertDetailsPage from '@@src/alerts_path/alert_details_page'
import GroupDetailPage from './management_path/groups_path/group_detail_page'
import DeviceDetailPage from './management_path/devices_path/device_detail_page'
import PageNotFoundPage from './page_not_found_path/index_page'
import NetworkAssetsPage from './management_path/network_assets_path/index_page'
import EditNetworkAssetsPage from './management_path/network_assets_path/edit_network_asset_page'
import AnalysisMapView from './analysis_path/analysis_map_view'
import AnalysisListView from './analysis_path/analysis_list_view'
import createI18nInstance from './create_i18n_instance'
import AppSuspenseContainer, {
  renderAbsolutePositionedLoading,
} from './components/app_suspense_container'
import FailedToStartAppPage from './error_path/failed_to_start_app_error_page'
import PressureAnalysisPage from './analysis_path/pressure_analysis_path/index_page'
import PasswordRecoveryPage from './login_path/password_recovery_path/index_page'
import EnterRecoveryCodePage from './login_path/password_recovery_path/enter_recovery_code_page'
import ManagementDevicesPage from './management_path/devices_path/index_page'
import SecuredConfigProviders from './components/security/secured_config_providers'
import NetworkAssetsDetailPage from './management_path/network_assets_path/network_asset_detail_page'
import SettingsPage from './settings_path/index_page'
import ReleaseNotesPage from './release_notes_path/index_page'
import createGlobalAppComponents from './create_global_app_components'
import ManagementDevicesTablePath from './management_path/devices_table_path/index_page'

import MapGEOjson from './geojson-poc'
import NetworkAssetsPathV2 from './_v2/paths/management/network_assets/network_assets.path'
import ManagementDevicesPathV2 from './_v2/paths/management/devices/devices.path'

export const handleErrors = (Component) => (props) =>
  (
    <ErrorWrapper>
      <Component {...props} />
    </ErrorWrapper>
  )

const AppRoot = ({ setupI18n, setupAppComponents }) => {
  const [globalState, setGlobalState] = useState({ error: null, i18n: null, appGlobalComponents: null })
  const { appConfig } = useContext(AppConfigContext)

  useEffect(() => {
    (async function AppRootEffect() {
      try {
        const { history, store } = setupAppComponents()
        const i18n = await setupI18n({ language: appConfig?.tenantSettings?.language || 'en' })

        setGlobalState({ error: null, i18n, appGlobalComponents: { history, store } })
      } catch (err) {
        analytics.logFatalError(err)
        setGlobalState({ error: AppError.from(err), i18n })
      }
    })()
  }, [setGlobalState])

  const { error, i18n, appGlobalComponents } = globalState
  const store = appGlobalComponents?.store
  const history = appGlobalComponents?.history

  return (
    <>
      {!appGlobalComponents && !error && (renderAbsolutePositionedLoading())}
      {appGlobalComponents && error && (<FailedToStartAppPage error={error} i18n={i18n} />)}
      {appGlobalComponents && !error && (
        <I18nextProvider i18n={i18n}>
          <WindowSizesProvider>
            <Provider store={store}>
              <AuthorizationProvider>
                <UserProvider>
                  <SecuredConfigProviders>
                    <FreshdeskWrapper>
                      <ConnectedRouter history={history}>
                        <Switch>
                          <Route
                            path={routes.loginPath()}
                            exact={true}
                            component={handleErrors(LoginPage)}
                          />

                          <Route
                            path={routes.appInfoPath()}
                            exact={true}
                            component={handleErrors(AppInfoPage)}
                          />

                          <Route
                            path={routes.loginPasswordRecoveryPath()}
                            exact={true}
                            component={handleErrors(PasswordRecoveryPage)}
                          />

                          <Route
                            path={routes.loginPasswordRecoveryEnterRecoveryCodePath()}
                            exact={true}
                            component={handleErrors(EnterRecoveryCodePage)}
                          />

                          <Route
                            path={routes.homePath()}
                            exact={true}
                            component={handleErrors(HomePage)}
                          />

                          <Route
                            path={routes.managementDevicesPath()}
                            exact={true}
                            component={isFeatureFlagEnabled(
                              FeatureFlags.devicesListV2,
                              handleErrors(ManagementDevicesPage),
                              handleErrors(ManagementDevicesPathV2),
                            )}
                          />

                          <Route
                            path={routes.managementDevicesTablePath()}
                            exact={true}
                            component={ManagementDevicesTablePath}
                          />

                          <Route
                            path={routes.managementNetworkAssetsPath()}
                            exact={true}
                            component={isFeatureFlagEnabled(
                              FeatureFlags.networkAssetsListV2,
                              handleErrors(NetworkAssetsPage),
                              handleErrors(NetworkAssetsPathV2),
                            )}
                          />
                          <Route
                            path="/management/devices/:id"
                            exact={true}
                            component={handleErrors(DeviceDetailPage)}
                          />

                          <Route
                            path="/management/groups/:id"
                            exact={true}
                            component={handleErrors(GroupDetailPage)}
                          />

                          <Route
                            path="/management/groups/:id/edit"
                            exact={true}
                            component={handleErrors(EditGroupPage)}
                          />

                          <Route
                            path="/management/network-assets/:id"
                            exact={true}
                            component={handleErrors(NetworkAssetsDetailPage)}
                          />

                          <Route
                            path="/management/network-assets/:id/edit"
                            exact={true}
                            component={handleErrors(EditNetworkAssetsPage)}
                          />

                          <Route
                            path={routes.managementGroupsPath()}
                            exact={true}
                            component={handleErrors(GroupsPage)}
                          />

                          <Route
                            path={routes.downloadsPath()}
                            exact={true}
                            component={handleErrors(DownloadsPage)}
                          />

                          <Route
                            path={routes.alertsPath()}
                            exact={true}
                            component={handleErrors(AlertsPage)}
                          />

                          <Route
                            path="/alerts/:id"
                            exact={true}
                            component={handleErrors(AlertDetailsPage)}
                          />

                          <Route
                            path={routes.adminUsersPath()}
                            exact={true}
                            component={handleErrors(AdminUsersPage)}
                          />

                          <Route
                            path={routes.adminTenantSettingsRoot()}
                            exact={true}
                            component={() => (
                              <Redirect
                                to={routes.adminTenantSettingsLanguageAndRegion()}
                              />
                            )}
                          />

                          <Route
                            path={routes.adminTenantSettingsPath()}
                            exact={true}
                            component={handleErrors(AdminTenantSettingsPage)}
                          />

                          <Route
                            path={routes.settingsPath()}
                            exact={true}
                            component={handleErrors(SettingsPage)}
                          />

                          <Route
                            path={routes.previousReleaseNotesPath()}
                            exact={true}
                            component={handleErrors(ReleaseNotesPage)}
                          />

                          <Route
                            path={routes.analysisPath()}
                            exact={true}
                            component={({ location }) => (
                              <Redirect
                                to={{
                                  search: location.search,
                                  pathname: routes.analysisListPath(),
                                }}
                              />
                            )}
                          />

                          <Route
                            path={routes.analysisListPath()}
                            exact={true}
                            component={handleErrors(AnalysisListView)}
                          />

                          <Route
                            path={routes.analysisMapPath()}
                            exact={true}
                            component={handleErrors(AnalysisMapView)}
                          />

                          <Route
                            path={'/geojson'}
                            exact={true}
                            component={handleErrors(MapGEOjson)}
                          />

                          <Route
                            path={routes.analysisPressurePath()}
                            component={handleErrors((props) => (
                              <PressureAnalysisPage
                                userActionDebounceDelay={300}
                                {...props}
                              />
                            ))}
                          />

                          <Route
                            path="*"
                            exact
                            component={handleErrors(PageNotFoundPage)}
                          />
                        </Switch>
                      </ConnectedRouter>
                    </FreshdeskWrapper>
                  </SecuredConfigProviders>
                </UserProvider>
              </AuthorizationProvider>
            </Provider>
          </WindowSizesProvider>
        </I18nextProvider>
      )}
    </>
  )
}

AppRoot.propTypes = {
  setupI18n: PropTypes.func,
  setupAppComponents: PropTypes.func,
}

AppRoot.defaultProps = {
  setupI18n: createI18nInstance,
  setupAppComponents: createGlobalAppComponents,
}

export default function AppRootWithConfigContextWrapped() {
  return (
    <AppSuspenseContainer elemType="">
      <AppConfigProvider><AppRoot /></AppConfigProvider>
    </AppSuspenseContainer>
  )
}
