import React, { memo } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { withTranslation } from 'react-i18next'
import { Breadcrumb, BreadcrumbItem, Button } from 'reactstrap'

import { MixedGroupDetailsType } from '@@src/api/presenters/group'

import styles from './groups_breadcrumbs.css'

function GroupsBreadcrumbs(props) {
  const {
    t,
    isNavigatable,
    group,
    getHandleNavigate,
    handleViewTopLevelGroups,
    viewingTopLevelGroups,
    className,
  } = props

  return (
    <Breadcrumb listClassName={classnames('mt-1 pt-1 pb-2 mb-0', className)}>
      <BreadcrumbItem className={classnames(
        styles['breadcrumb-item'],
        'd-flex align-items-center'
      )}>
        {
          !isNavigatable || viewingTopLevelGroups ?
            <span>
              {t('common/text:text.group_plural')}
            </span> :
            <Button
              className="p-0 border-0"
              color={'link'}
              onClick={handleViewTopLevelGroups}>
              {t('common/text:text.group_plural')}
            </Button>
        }
      </BreadcrumbItem>
      {
        group && Array.isArray(group.ancestry) ?
          group.ancestry.sort((a1, a2) => a1 - a2).map(ancestor => (
            <BreadcrumbItem
              className={classnames(
                'd-flex align-items-center',
                styles['breadcrumb-item'],
              )}
              key={ancestor.id}>
              {
                isNavigatable ?
                  <Button
                    className="p-0 border-0"
                    color={'link'}
                    onClick={getHandleNavigate(ancestor.id)}>
                    {ancestor.name}
                  </Button>
                  :
                  <span>
                    {ancestor.name}
                  </span>
              }
            </BreadcrumbItem>
          ))
          : null
      }
      {
        group ?
          <BreadcrumbItem className={classnames(
            styles['breadcrumb-item'],
            'd-flex align-items-center text-muted',
          )}>
            {group.name}
          </BreadcrumbItem>
          : null
      }
      <BreadcrumbItem className={classnames(
        'text-muted',
        styles['breadcrumb-item'])
      } />
    </Breadcrumb>
  )
}

GroupsBreadcrumbs.propTypes = {
  isNavigatable: PropTypes.bool.isRequired,
  viewingTopLevelGroups: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  getHandleNavigate: PropTypes.func.isRequired,
  handleViewTopLevelGroups: PropTypes.func.isRequired,
  group: PropTypes.instanceOf(MixedGroupDetailsType),
  className: PropTypes.string,
}

GroupsBreadcrumbs.defaultProps = {
  isNavigatable: true,
  viewingTopLevelGroups: false,
}

export default withTranslation([
  'common/text',
])(memo(GroupsBreadcrumbs))
