import { useEffect, useState } from 'react'

export function useGetWindowSizes() {

  const [sizeData, setSizeData] = useState({
    width: 0,
    height: 0,
    isMobile: false,
  })

  const handleResize = () => {
    setSizeData({
      ...sizeData,
      width: window.innerWidth,
      height: window.innerHeight,
      isMobile: window.innerWidth < 992,
    })
  }

  useEffect(() => {
    setSizeData({
      ...sizeData,
      width: window.innerWidth,
      height: window.innerHeight,
      isMobile: window.innerWidth < 992,
    })

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return { width: sizeData.width, height: sizeData.height, isMobileDevice: sizeData.isMobile }
}
