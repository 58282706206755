import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { Row, Col } from 'reactstrap'
import { Alert } from 'reactstrap'
import { Redirect } from 'react-router-dom'

import * as analytics from '@@src/analytics'
import ErrorInfo from '@@src/components/error_info'
import AsyncResult from '@@src/utils/async_result'
import LoadingIcon from '@@src/components/loading_icon'
import withDownloads from '@@src/downloads_path/downloads_container'
import AppLayout from '@@src/components/app_layout'
import requiresLogin from '@@src/components/security/requires_login'
import DataDownloadItem from '@@src/downloads_path/data_download_item'
import DownloadItem from '@@src/downloads_path/download_item'
import fileDownload from 'js-file-download'
import { AppError } from '@@src/utils'
import { withAuthorization } from '../_v2/contexts/authorization/authorization.context'
import { CAN_VIEW_DEVICES } from '../_v2/contexts/user/consts/permissions'
import { withUser } from '../_v2/contexts/user/user.context'

import styles from './index_page.css'

class DownloadsPage extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    downloads: PropTypes.instanceOf(AsyncResult).isRequired,
  }

  static defaultProps = {
    downloads: AsyncResult.pending([]),
  }

  state = {
    result: AsyncResult.success(),
  }

  getSignedUrl = async (downloadLink) => {
    const { authorization } = this.props
    try {
      const token = await authorization.getCurrentUserToken()
      const response = await fetch(downloadLink, {
        method: 'get',
        headers: new Headers({
          Authorization: `Bearer ${token}`,
        }),
      })
      const body = await response.json()
      return body.signedUrl
    } catch (err) {
      throw err
    }
  }

  onDownloadClick = async (downloadLink, filename) => {
    this.setState({ result: AsyncResult.pending() })

    try {
      const signedUrl = await this.getSignedUrl(downloadLink)

      const response = await fetch(signedUrl, {
        method: 'get',
      })

      if (!response.ok) {
        throw Error(response.statusText)
      }

      const data = await response.text()
      fileDownload(data, filename)
      this.setState({ result: AsyncResult.success() })
    } catch (err) {
      analytics.logError(err)

      this.setState({
        result: AsyncResult.fail(AppError.from(err)),
      })
    }
  }

  renderDownloads = () => {
    const { downloads, t } = this.props
    const { result } = this.state

    if (downloads.isPending()) {
      return <LoadingIcon className={styles['loading-spinner']} />
    } else if (downloads.wasFailure()) {
      return <ErrorInfo error={downloads.error} />
    } else if (downloads.data.length) {
      return (
        <ol className="list-unstyled">
          {result.wasFailure() ? <ErrorInfo error={result.error} /> : null}

          {downloads.data.map((report, reportIndex) => {
            switch (report.__typename) {
              case 'AlertReportListItem':
              case 'DevicesDataReportItem':
              case 'DevicesHealthDataReportItem':
              case 'NetworkAssetsDataReportItem':
                return this.renderDownloadItem(report, reportIndex)
              default:
                return this.renderDataDownloadItem(report, reportIndex)
            }
          })}
        </ol>
      )
    } else {
      return <Alert color="info">{t('alerts.no_downloads_available')}</Alert>
    }
  }

  render() {
    const { t, authorizedUser } = this.props

    // Does the user have permissions for this page?
    if (!authorizedUser.can(CAN_VIEW_DEVICES)) {
      return <Redirect to="/page-not-found" />
    }

    return (
      <AppLayout title={t('headings.page_title')}>
        <div className="container">
          <Row>
            <Col sm="12">
              <h1 className={styles['downloads-title']}>
                {t('headings.page_title')}
              </h1>
              {this.renderDownloads()}
            </Col>
          </Row>
        </div>
      </AppLayout>
    )
  }

  renderDataDownloadItem(report, reportIndex) {
    const {
      fileListStatus,
      start,
      end,
      reportType,
      aggregateName,
      dataType,
      jobCreated,
    } = report

    if (fileListStatus.length > 0) {
      return fileListStatus.map((file, fileIndex) => (
        <DataDownloadItem
          uuid={file.uuid}
          aggregateName={aggregateName}
          key={`download-item-${reportIndex}-${fileIndex}`}
          start={start}
          end={end}
          reportType={reportType}
          dataType={dataType}
          reportStatus={file.reportStatus}
          jobCreated={jobCreated}
          onDownloadClick={this.onDownloadClick}
        />
      ))
    } else {
      return (
        <DataDownloadItem
          key={`download-item-${reportIndex}`}
          start={start}
          end={end}
          reportType={reportType}
          dataType={dataType}
          aggregateName={aggregateName}
          reportStatus={report.reportStatus}
          jobCreated={jobCreated}
          onDownloadClick={this.onDownloadClick}
        />
      )
    }
  }

  renderDownloadItem(report, reportIndex) {
    const { fileListStatus, reportType, dataType, jobCreated } = report

    if (fileListStatus.length > 0) {
      return fileListStatus.map((file, fileIndex) => (
        <DownloadItem
          key={`download-item-${reportIndex}-${fileIndex}`}
          uuid={file.uuid}
          dataType={dataType}
          reportType={reportType}
          reportStatus={file.reportStatus}
          onDownloadClick={this.onDownloadClick}
          fileIndex={fileIndex}
          jobCreated={jobCreated}
        />
      ))
    } else {
      return (
        <DownloadItem
          key={`download-item-${reportIndex}`}
          dataType={dataType}
          reportType={reportType}
          reportStatus={report.reportStatus}
          onDownloadClick={this.onDownloadClick}
          jobCreated={jobCreated}
        />
      )
    }
  }
}

const DownloadsPageWithDownloads = withDownloads(DownloadsPage)

function mapStateToProps() {
  return { pollInterval: 15000 }
}

export default compose(
  requiresLogin,
  withTranslation(['src/downloads_path/index_page', 'common/text']),
  withUser,
  withAuthorization,
  connect(mapStateToProps),
)(DownloadsPageWithDownloads)
