import React from 'react'
import { useTranslation } from 'react-i18next'

import SearchField from '../../../../../components/inputs/search_field/search_field.component'
import ListSort from '../../../../../components/inputs/list_sort/list_sort.component'
import ListSummary from '../../../../../components/list_summary/list_summary.component'
import DevicesListFiltersDropdown from './devices_list_filters_dropdown.component'
import FiltersAlert from '../../../../../components/filters_alert/filter_alert.component'

import { ALLOWED_SORT_BY_DEVICES } from '../../utils/filters'

import PropTypes from 'prop-types'

const DevicesListFilters = ({
  loadingResults = true,
  totalResults = 0,
  setFilters = () => null,
  search = '',
  filters = [],
  orderBy = '',
  orderDirection = 'asc',
  onShowEverywhereClick = () => null,
}) => {
  const { t } = useTranslation([
    'v2/devices/filters',
    'common/text',
    'v2/common/text',
    'v2/common/filters',
    'src/management_path/devices_path/index_page',
  ])

  const handleSearchChange = (newSearch) => {
    if (search !== newSearch) {
      setFilters(oldFilters => {
        return { ...oldFilters, search: newSearch, pageNumber: 1 }
      })
    }
  }

  const handleOnSortChange = (newSortOptions) => {
    setFilters(oldFilters => {
      return { ...oldFilters, ...newSortOptions, pageNumber: 1 }
    })
  }

  const handleOnFilterChange = (newFilters) => {
    setFilters(oldFilters => {
      return { ...oldFilters, filters: newFilters, pageNumber: 1 }
    })
  }

  const handleClearFilters = () => {
    setFilters(oldFilters => ({ ...oldFilters, filters: ['any'] }))
  }

  return(
    <>
      <div className="d-flex flex-row justify-content-between mt-4">
        <SearchField
          placeholder={t('v2/common/text:search_placeholder')}
          initialValue={search}
          onChange={handleSearchChange}/>
        <DevicesListFiltersDropdown
          currentFilters={filters}
          onFilterChange={handleOnFilterChange} />
      </div>
      <div className="d-flex flex-row justify-content-between mt-3">
        <ListSummary
          isLoading={loadingResults}
          totalResults={totalResults}
          onShowEverywhereClick={onShowEverywhereClick}
        />
        <ListSort
          t={t}
          onSortChange={handleOnSortChange}
          orderBy={orderBy}
          orderDirection={orderDirection}
          sortOptions={ALLOWED_SORT_BY_DEVICES} />
      </div>
      <FiltersAlert
        t={(filterText) => t(`v2/devices/filters:filter_options.${filterText}`)}
        activeFilters={filters.filter(filter => filter !== 'any')}
        onClick={handleClearFilters} />
    </>
  )
}

DevicesListFilters.propTypes = {
  loadingResults: PropTypes.bool.isRequired,
  totalResults: PropTypes.number.isRequired,
  setFilters: PropTypes.func.isRequired,
  search: PropTypes.string.isRequired,
  filters: PropTypes.arrayOf(PropTypes.string).isRequired,
  orderBy: PropTypes.oneOf(ALLOWED_SORT_BY_DEVICES).isRequired,
  orderDirection: PropTypes.oneOf(['asc', 'desc']).isRequired,
}

export default DevicesListFilters
