import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { min, max } from 'lodash/fp/math'
import { noop } from 'lodash/fp/util'
import { withTranslation } from 'react-i18next'

import AreaOverlay from '../drag_layer/area_overlay'
import SelectDropdown from
'@@src/components/graphs/drag_select_layer/select_dropdown'
import GraphContext, { GraphConfig }
from '@@src/components/graphs/graph_context'
import { Popup, PopupAnchor, RIGHT } from '@@src/components/popup'
import { DragEventContext }
from '@@src/components/graphs/drag_layer/drag_event_layer'
import { MouseEventContext }
from '@@src/components/graphs/mouse_event_layer'
import AsyncResult from '@@src/utils/async_result'

import styles from './index.css'

function SelectDropdownPopup({ children, x, y }) {
  return (
    <PopupAnchor elementType="circle" cx={x} cy={y} r="0">
      {children}
    </PopupAnchor>
  )
}

class DragSelectLayerWithPopup extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    graphConfig: PropTypes.instanceOf(GraphConfig).isRequired,
    networkAssetGraphItems: PropTypes.array.isRequired,
    refetchRawDataRequests: PropTypes.func.isRequired,
    listRawDataRequestsResult: PropTypes.instanceOf(AsyncResult).isRequired,
  }

  static defaultProps = {
    listRawDataRequestsResult: AsyncResult.success({
      rawDataRequests: [],
      networkAssetsMinutesLeft: [],
    }),
    refetchRawDataRequests: noop,
  }

  state = {
    selection: null,
  }

  render() {
    const {
      graphConfig, t, refetchRawDataRequests, DragEventChannel, children,
      MouseEventChannel, listRawDataRequestsResult,
    } = this.props
    const { selection } = this.state

    return (
      <>
      <DragEventChannel
        onDragEnd={this.onDragEnd}
        onDragStart={this.resetSelection}/>

      <MouseEventChannel onClick={this.resetSelection}/>

      {
        selection ? (
          <>
            <AreaOverlay xRange={selection.xRange}/>

            <SelectDropdownPopup
              y={graphConfig.plotAreaHeight / 2 + graphConfig.topPadding}
              x={selection.endPointX}>
              <Popup
                hideArrow={true}
                location={RIGHT}
                className={styles['dropdown-popup-anchor']}
                isOpen={true}>
                <SelectDropdown
                  listRawDataRequestsResult={listRawDataRequestsResult}
                  refetchRawDataRequests={refetchRawDataRequests}
                  t={t}
                  networkAssetGraphItems={selection.networkAssetGraphItems}
                  timeTo={selection.endDate}
                  timeFrom={selection.startDate}
                  resetSelection={this.resetSelection}/>
              </Popup>
            </SelectDropdownPopup>

            children
          </>
        ) : children
      }
      </>
    )
  }

  onDragEnd = (dragEvent) => {
    const bounds = dragEvent.graphBoundsX

    if (bounds) {
      const { networkAssetGraphItems } = this.props
      const startDate = min(bounds)
      const endDate = max(bounds)

      const itemsInBounds = networkAssetGraphItems
        .filter(({ dataSegments = [] }) => {
          return dataSegments.some(({ data = [] }) => {
            return data.some(({ time }) => time >= startDate && time <= endDate)
          })
        })

      this.setState({
        selection: {
          xRange: dragEvent.localBoundsX,
          endPointX: dragEvent.mouseX,
          startDate,
          endDate,
          networkAssetGraphItems: itemsInBounds,
        },
      })
    } else {
      this.resetSelection()
    }
  }

  resetSelection = () => {
    this.setState({ selection: null })
  }
}

function DragSelectLayerWithPopupContainer(props) {
  return (
    <GraphContext.Consumer>
      {config => (
        <MouseEventContext.Consumer>
          {({ Channel: MouseEventChannel }) => (
            <DragEventContext.Consumer>
              {({ Channel }) => (
                <DragSelectLayerWithPopup
                  graphConfig={config}
                  DragEventChannel={Channel}
                  MouseEventChannel={MouseEventChannel}
                  {...props}/>
              )}
            </DragEventContext.Consumer>
          )}
        </MouseEventContext.Consumer>
      )}
    </GraphContext.Consumer>
  )
}

export default withTranslation([
  'src/components/graphs/drag_select_layer/select_dropdown',
  'src/components/graphs/modals/request_raw_data_modal',
])(DragSelectLayerWithPopupContainer)
