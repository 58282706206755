import gql from 'graphql-tag'
import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash/fp/object'
import { partition } from 'lodash/fp/collection'
import { uniqBy } from 'lodash/fp/array'
import { compose } from 'redux'
import { graphql } from '@apollo/client/react/hoc'
import { createSelector } from 'reselect'
import { withTranslation } from 'react-i18next'
import { Col, Row, Container } from 'reactstrap'
import { push } from 'connected-react-router'
import { connect } from 'react-redux'

import routes from '@@src/routes'
import AppLayout from '@@src/components/app_layout'
import requiresLogin from '@@src/components/security/requires_login'
import transformProps from '@@src/components/transform_props'

import AsyncResultSwitch from '@@src/components/async_result_switch'
import AsyncResult from '@@src/utils/async_result'
import VoidGroupModal from './void_group_modal'
import { Link, Redirect } from 'react-router-dom'
import { parseGraphQLResult } from '@@src/api/presenters'
import { createSelectGraphQLResult } from '@@src/utils'

import Device from '@@src/api/presenters/device'
import userPermissions from '@@src/components/permissions/user_permissions'
import MoveGroupModal
from '@@src/components/modals/move_groups_modal/move_group_modal'
import GroupBreadcrumb from './group_breadcrumb'
import GroupOfAssetsOrDevicesDetail from './group_detail_page/group_of_assets_devices_detail'

import styles from './group_detail_page.css'
import GroupOfGroupsDetail from './group_detail_page/group_of_groups_detail'

export const ADD_ASSETS = 'add-asset'
export const VOID_GROUP = 'void-group'
export const MOVE_GROUP = 'move-group'
export const REMOVE_AGGREGATE = 'remove-aggregate'

class GroupDetailPage extends React.PureComponent {
  static propTypes = {
    navigateToPage: PropTypes.func.isRequired,
  }

  state = {
    editDropdownOpen: false,
    isMuteAlertsModalOpen: false,
    selectedAggregate: null,
    name: null,
    category: null,
    overlayState: {},
    groupVoided: false,
  }

  render() {
    const { groupResult } = this.props
    const { groupVoided } = this.state

    if (groupVoided) {
      return (
        <Redirect to={{ pathname: routes.managementGroupsPath() }} />
      )
    }

    return (
      <AsyncResultSwitch
        result={groupResult}
        renderFailResult={this.renderGroupNotFound}
        renderNotFoundResult={this.renderGroupNotFound}
        renderSuccessResult={this.renderGroupDetail} />
    )
  }

  componentWillUnmount() {
    this.setState({
      groupVoided: false,
      newlyCreatedGroupId: null,
    })
  }

  renderGroupDetail = ({ data: group }) => {
    const { overlayState } = this.state
    const { t, permissions } = this.props
    const canEdit = permissions.includes('can_edit_groups') &&
    !group.isCongruityGroup()
    return (
      <React.Fragment>
        <VoidGroupModal
          isOpen={overlayState.name === VOID_GROUP}
          toggle={this.onToggleOverlay}
          onSuccess={this.successfullyVoidedGroup}
          group={group} />
        <MoveGroupModal
          onMoveGroup={this.refetchPageData}
          group={group}
          handleToggle={this.onToggleOverlay}
          isOpen={overlayState.name === MOVE_GROUP} />

        {
          group.isGroupOfGroups() ?
            <GroupOfGroupsDetail
              t={t}
              group={group}
              canEdit={canEdit}
              groupResult={this.props.groupResult}
              onMove={this.onMove}
              onDelete={this.onDelete}
              navigateToPage={this.props.navigateToPage}
              refetchPageData={this.refetchPageData}
              newlyCreatedGroupId={this.state.newlyCreatedGroupId}
              handleCreatedAndCreateAnother={
                this.handleCreatedAndCreateAnother
              }
              getDisabledEditTooltip={this.getDisabledEditTooltip}
            />
            :
            <GroupOfAssetsOrDevicesDetail
              t={t}
              group={group}
              onMove={this.onMove}
              onDelete={this.onDelete}
              canEdit={canEdit}
              groupResult={this.props.groupResult}
              getDisabledEditTooltip={this.getDisabledEditTooltip}
              refetchPageData={this.refetchPageData}
              onToggleOverlay={this.onToggleOverlay}
              onClickAddNetworkAssetButton={this.onClickAddNetworkAssetButton}
              createOnClickMarker={this.createOnClickMarker}
              overlayState={this.state.overlayState}
              onRemoveNetworkAsset={this.onRemoveNetworkAsset}
              onRemoveDevice={this.onRemoveDevice}
              mostSevereMemberDeviceIssues= {this.selectMostSevereMemberDeviceIssues(this.props)}
              findAllUuidForGroupOfAssetsOrDevices={this.findAllUuidForGroupOfAssetsOrDevices}
            />
        }
      </React.Fragment>
    )
  }

  navigateToNewGroupDetailPage = groupId => {
    this.props.navigateToPage(routes.managementGroupsDetailPath(groupId))
  }

  handleCreatedAndCreateAnother = groupId => {
    this.setState({ newlyCreatedGroupId: groupId })
  }

  renderGroupNotFound = () => {
    const { t } = this.props
    return (
      <AppLayout
        title={'headings.group_not_found'}
      >
        <Container fluid={true} className="d-flex flex-row pr-0">
          <Row className="w-100 ml-0">
            <Col className={styles['group-details-section']} sm="12">
              <Row className="flex-grow-0 flex-shrink-0">
                <Col sm="12">
                  <GroupBreadcrumb groupResult={AsyncResult.notFound()} />
                  <h1 className="text-danger text-center">
                    {t('text.source_not_found')}
                  </h1>
                  <p className="muted text-center pt-2">
                    {t('common/text:text.check_address_bar')}
                    {' '}
                    <Link to={routes.homePath()}>
                      {t('common/text:text.dashboard')}
                    </Link>
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </AppLayout>
    )
  }

  findAllUuidForGroupOfAssetsOrDevices = group => {
    const arrayOfIds = [group.uuid]
    group.members.data.forEach(member => {
      arrayOfIds.push(member.uuid)
    })
    return arrayOfIds
  }

  getDisabledEditTooltip = (group) => {
    const { t, permissions } = this.props

    if (group.isCongruityGroup()) {
      return t('text.congruity_group_edit_disabled')
    } else if (!permissions.includes('can_edit_groups')) {
      return t('common/text:permissions.disabled')
    }

    return ''
  }

  // move this to GroupOfAssetsOrDevicesDetail
  selectMostSevereMemberDeviceIssues = createSelector(
    [get('groupResult')], (groupResult) => {
      if (!groupResult.wasSuccessful()) {
        return []
      }

      const mostSevereIssues = groupResult.data.members.data.map(member => {
        return member instanceof Device ?
          {
            issue: member.mostSevereIssue,
            member,
          } : {
            issue: member.mostSevereDeviceIssue,
            member,
          }
      }).filter(a => a.issue)

      const uniqueActiveIssues = uniqBy(
        ({ member }) => member.uuid,
      )(mostSevereIssues)
      const [errors, rest] = partition(
        ({ issue }) => issue.isError()
      )(uniqueActiveIssues)
      const [warnings, info] = partition(({ issue }) => issue.isWarning())(rest)

      return [...errors, ...warnings, ...info]
    }
  )

  // this should be in GroupOfAssetsOrDevicesDetail also
  onRemoveNetworkAsset = (networkAsset, group) => async event => {
    event.preventDefault()

    this.setState({
      overlayState: {
        name: REMOVE_AGGREGATE,
        group,
        aggregate: networkAsset,
        aggregateType: 'VNetworkAsset',
      },
    })
  }

  // this should be in GroupOfAssetsOrDevicesDetail also
  onRemoveDevice = (device, group) => async event => {
    event.preventDefault()

    this.setState({
      overlayState: {
        name: REMOVE_AGGREGATE,
        group,
        aggregate: device,
        aggregateType: 'VDevice',
      },
    })
  }

  onDelete = () => {
    this.setState({ overlayState: { name: VOID_GROUP } })
  }

  onMove = () => {
    this.setState({ overlayState: { name: MOVE_GROUP } })
  }

  onClickAddNetworkAssetButton = () => {
    this.setState({ overlayState: { name: ADD_ASSETS } })
  }

  onToggleOverlay = () => {
    this.setState({ overlayState: {} })
  }

  refetchPageData = async () => {
    return await this.props.refetchData()
  }

  successfullyVoidedGroup = async () => {
    this.setState({ groupVoided: true })
  }

  static GROUP_QUERY = gql`
    query Group($id: Int!) {
      groupMix(id: $id) {
        id
        name
        category
        congruity {
          zone {
            id
          }
        }
        ancestry {
          id
          name
          index
        }
        members {
          count
          type
          data {
            ... on MixedGroupDetailsType {
              id
              name
              category
              members {
                type
              }
            }
            ... on NetworkAsset {
              __typename
              id
              assetId
              assetName
              assetType
              location {
                latitude
                longitude
              }
              installations {
                end
                start
                deviceId
                channelMap {
                  pressure_1
                }
                device {
                  id
                  currentCommission {
                    start
                    end
                    location {
                      altitude
                      latitude
                      longitude
                    }
                  }
                  activeIssues {
                    id
                    type
                    severity
                    description
                    deviceId
                  }
                }
              }
            }
            ... on Device {
              __typename
              id
              serialNumber
              commissionStatus
              lastKnownLocation {
                longitude
                latitude
              }
              currentCommission {
                device {
                  id
                }
                location {
                  latitude
                  longitude
                }
                start
                end
              }
              activeIssues {
                id
                type
                severity
                description
                deviceId
              }
            }
          }
        }
        currentAlertsMuteEnd
        alertThresholds {
          inheritedFrom
        }
      }
    }
  `
}

function mapDispatchToProps(dispatch) {
  return {
    navigateToPage(route) {
      dispatch(push(route))
    },
  }
}

export default compose(
  requiresLogin,
  userPermissions,
  transformProps(() => () => {
    return {
      selectGroupResult: createSelectGraphQLResult('groupMix', {
        mapResult: parseGraphQLResult,
        nullObject: null,
      }),
    }
  }),
  withTranslation([
    'src/management_path/groups_path/group_detail_page',
    'common/text',
  ]),
  connect(null, mapDispatchToProps),
  graphql(GroupDetailPage.GROUP_QUERY, {
    options: ({ match }) => {
      return {
        fetchPolicy: 'network-only',
        variables: {
          id: parseInt(decodeURIComponent(match.params.id), 10),
        },
      }
    },
    props: ({ data, ownProps: { selectGroupResult } }) => {
      return {
        refetchData: data.refetch,
        groupResult: selectGroupResult(data),
      }
    },
  }),
)(GroupDetailPage)
