export class AuthorizedUser {
  /**
   * @param {object} props user properties
   * @param {string} props.idpUserId
   * @param {string} props.email
   * @param {string} props.firstName
   * @param {string} props.lastName
   * @param {string} props.role
   * @param {string[]} props.permissions
   * @param {object} props.settings
   * @constructor
   */
  constructor(props) {
    this.idpUserId = props.idpUserId
    this.email = props.email
    this.firstName = props.firstName
    this.lastName = props.lastName
    this.role = props.role
    this.permissions = props.permissions
    this.settings = props.settings
    this.displayName = [props.firstName, props.lastName].filter(Boolean).join(' ')

    // make authorized user read only
    Object.freeze(this)
  }

  /**
   * @return {AuthorizedUser|null}
   */
  static initNull() {
    return null
  }

  /**
   * @param {string} permission
   * @return {boolean}
   */
  can(permission) {
    return this.permissions.includes(permission)
  }
}
