import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem }
from 'reactstrap'

import SortComponentItem from
'@@src/components/sort_component/sort_component_item'

import styles from './sort_component.css'

export class SortDropdown extends PureComponent {
  static propTypes = {
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
    isOpen: PropTypes.bool.isRequired,
    onChangeValue: PropTypes.func.isRequired,
    onToggle: PropTypes.func.isRequired,
    updateLabel: PropTypes.bool.isRequired,
    value: PropTypes.string,
    valueLabel: PropTypes.string,
    isDescending: PropTypes.bool.isRequired,
    buttonStyle: PropTypes.string,
    className: PropTypes.string,
  }

  static defaultProps = {
    updateLabel: false,
    isOpen: false,
    isDescending: true,
  }

  renderDropdownItems = () => {
    const { options, value = options[0].value,
      onChangeValue, isDescending } = this.props

    return (
      <DropdownMenu>
        {options.map((f, i) => {
          return (
            <DropdownItem
              key={i}
              className={styles['dropdown-item']}
              value={f.value}
              onClick={onChangeValue}>
              <SortComponentItem
                value={f.value}
                isSelected={value === f.value}
                isDescending={isDescending}
                text={f.text} />
            </DropdownItem>)
        })}
      </DropdownMenu>
    )
  }

  render() {
    const {
      buttonStyle, isOpen, onToggle, isDescending, className, valueLabel,
    } = this.props

    const downArrowClassnames = classnames(
      styles['dropdown-icon'],
      isDescending ? 'fas fa-long-arrow-alt-down' : 'fas fa-long-arrow-alt-up',
      'font-weight-bold'
    )

    return (
      <ButtonDropdown isOpen={isOpen} toggle={onToggle} className={className}>
        <DropdownToggle caret
          className={classnames(styles['dropdown-toggle'], buttonStyle)}>
          <i name="down-arrow-icon" className={downArrowClassnames} />
          {
            valueLabel || <>
              &nbsp;
              AZ
              &nbsp;
            </>
          }

        </DropdownToggle>
        {isOpen ? this.renderDropdownItems() : null}
      </ButtonDropdown>
    )
  }
}

const getSortDropdown = (Component = SortDropdown) => {
  return class extends PureComponent {
    state = {
      isOpen: false,
    }

    onClickSort = () => {
      this.setState({ isOpen: !this.state.isOpen })
    }

    changeSort = (e) => {
      const { onChange } = this.props
      onChange(e.currentTarget.value)
    }

    render() {
      return (
        <Component
          {...this.props}
          isOpen={this.state.isOpen}
          onToggle={this.onClickSort}
          onChangeValue={this.changeSort} />
      )
    }
  }
}

export default getSortDropdown
