import React from 'react'
import chroma from 'chroma-js'
import EventsEndIcon from '@@src/components/icon-components/event_end_icon'
import {
  IMAGE_SIZE,
} from '../../../analysis_path/pressure_analysis_path/pressure_subset_graph/anomaly_layer/anomaly_marker'

const EventEnd = ({ color: sourceColor, x, y, isActive, onClick }) => {
  const color = isActive ? chroma(sourceColor).darken() : sourceColor

  return (
    <svg
      x={x - IMAGE_SIZE / 2}
      y={y - IMAGE_SIZE / 2}
      onClick={onClick}
      width="24" height="24" viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg">
      <EventsEndIcon color={color} />
    </svg>
  )
}

export default EventEnd
