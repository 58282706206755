import { useEffect, useRef, useState } from 'react'
import { getFeatureFlagsForTenant } from '../../../api/feature_flags.service'
import { FeatureFlagsForTenant } from '../../../api/models/feature_flags_for_tenant'
import { useApolloClient } from '@apollo/client'

export const useFeatureFlagsForTenant = () => {
  const client = useApolloClient()
  const abortController = useRef(null)
  const [results, setResults] = useState({
    error: null,
    featureFlags: FeatureFlagsForTenant.ofEmpty(),
    isLoading: true,
  })

  useEffect(() => {
    if (abortController.current) {
      abortController.current.abort()
    }

    if (!results.isLoading) {
      setResults({ ...results, isLoading: true })
    }

    abortController.current = new AbortController()
    getFeatureFlagsForTenant({ client, abortController: abortController.current })
      .then(({ featureFlags }) => {
        setResults({ featureFlags, isLoading: false, error: null })
      })
      .catch((error) => {
        setResults({ error, featureFlags: FeatureFlagsForTenant.ofEmpty(), isLoading: false })
      })
      .finally(() => {
        abortController.current = null
      })

    return () => {
      if (abortController.current) {
        abortController.current.abort()
        abortController.current = null
      }
    }
  }, [])

  return results
}
