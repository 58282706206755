import { useCallback, useContext, useEffect, useState } from 'react'
import { AuthorizedUser } from '../../../models/authorized-user'
import { AuthorizationContext } from '../../authorization/authorization.context'

export const useAuthorizedUser = () => {
  const [isAuthorizing, setIsAuthorizing] = useState(true)
  const [authorizedUser, setAuthorizedUser] = useState(AuthorizedUser.initNull())
  const { authorization } = useContext(AuthorizationContext)

  useEffect(() => {
    authorization
      .getCurrentUser()
      .then(user => {
        setAuthorizedUser(user)
        setIsAuthorizing(false)
      })
  }, [authorization, setIsAuthorizing, setAuthorizedUser])

  const reloadAuthorizedUser = useCallback(
    async (showFullscreenLoading = false) => {
      if (showFullscreenLoading) {
        setIsAuthorizing(true)
      }

      const user = await authorization.getCurrentUser()

      setAuthorizedUser(user)
      setIsAuthorizing(false)
    },
    [authorization, setAuthorizedUser, setIsAuthorizing],
  )

  const isLogged = useCallback(() => !!authorizedUser, [authorizedUser])
  const logout = useCallback(async () => {
    await authorization.logout()
    setAuthorizedUser(null)
  }, [setAuthorizedUser, authorization])

  return {
    isAuthorizing,
    isLogged,
    authorizedUser,
    logout,
    reloadAuthorizedUser,
  }
}
