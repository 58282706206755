import { FormFields } from '@@src/utils'
import {
  COORDS_FORMAT_WGS84, COORDS_FORMAT_OSGB36,
} from '@@src/utils/conversions/coordinate_conversions'

export const COORDINATE_REGEX =
  /^\s*([+-]?\d+(?:\.\d*)?)\s*,\s*([+-]?\d+(?:\.\d*)?)\s*$/

export default class NetworkAssetFields extends FormFields {
  constructor(component, name, validationOverrides) {
    super(component, name, {
      assetId: validateAssetId,
      assetType: validateAssetType,
      coordinates: validateCoordinates,
      locationSource: validateLocationSource,
      ...validationOverrides,
    })
  }
}

function validateAssetId(assetId) {
  return validatePresence(assetId)
}

function validateAssetType(assetType) {
  return validatePresence(assetType)
}

function validateCoordinates(coordinates, { coordinatesFormat }) {
  if (!coordinates) {
    return 'errors.required'
  }

  switch (coordinatesFormat || COORDS_FORMAT_WGS84) {
    case COORDS_FORMAT_WGS84: {
      const match = coordinates.match(COORDINATE_REGEX)

      if (!match) {
        return 'errors.invalid_coordinate_format'
      }

      const latitude = parseFloat(match[1])
      const longitude = parseFloat(match[2])

      if (Math.abs(latitude) > 90 || Math.abs(longitude) > 180) {
        return 'errors.invalid_coordinate_format'
      }
      break
    }

    case COORDS_FORMAT_OSGB36: {
      const match = coordinates.match(COORDINATE_REGEX)

      if (!match) {
        return 'common/errors:validation.invalid_coordinate_format_OSGB36'
      }

      const latitude = parseFloat(match[1])
      const longitude = parseFloat(match[2])

      if (
        latitude < -90619.29 ||
        latitude > 612435.55 ||
        longitude < 10097.13 ||
        longitude > 1234954.16
      ) {
        return 'common/errors:validation.invalid_coordinate_format_OSGB36'
      }
      break
    }

    default:
      throw new Error(`Invalid coordinate format "${coordinatesFormat}"`)
  }

  return ''
}

function validateLocationSource(locationSource) {
  return validatePresence(locationSource)
}

function validatePresence(value) {
  return value ? '' : 'errors.required'
}
