import React from 'react'
import PropTypes from 'prop-types'
import { createSelector } from 'reselect'

import { TenantSettingsContext } from
'@@src/components/tenant_settings_provider'
import { UserSettingsContext } from '@@src/components/user_settings_provider'
import { INTERNAL_UNITS } from '@@src/utils/unit_constants'

export function withAppSettings() {
  return Component => {
    return function WithAppSettings(props) {
      return (
        <AppSettingsConsumer>
          {(units, alertThresholds) => (
            <Component
              units={units}
              alertThresholds={alertThresholds}
              {...props} />
          )}
        </AppSettingsConsumer>
      )
    }
  }
}

export default class AppSettingsConsumer extends React.PureComponent {
  static propTypes = {
    children: PropTypes.func.isRequired,
  }

  render() {
    const { children } = this.props

    return (
      <TenantSettingsContext.Consumer>
        {
          ({
            settings: {
              units: tenantUnits = INTERNAL_UNITS,
              alertThresholds: tenantAlertThresholds,
            } = {},
          } = {}) => {
            return (
              <UserSettingsContext.Consumer>
                {
                  (({ units: userUnits } = {}) => {
                    return children(
                      this.selectAppSettings(tenantUnits, userUnits),
                      tenantAlertThresholds,
                    )
                  })
                }
              </UserSettingsContext.Consumer>
            )
          }
        }
      </TenantSettingsContext.Consumer>
    )
  }

  selectAppSettings = createSelector(
    [
      tenantUnits => tenantUnits,
      (_, userUnits) => userUnits,
    ],
    (tenantUnits, userUnits) => {
      if (userUnits) {
        return Object.assign({}, tenantUnits, userUnits)
      }

      return tenantUnits
    }
  )
}
