import React from 'react'
import { Form, Button, UncontrolledTooltip } from 'reactstrap'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { noop } from 'lodash/fp/util'

import AppFormGroup from '@@src/components/forms/app_form_group'
import FormFields from '@@src/utils/form_fields'
import Alert from '@@src/api/presenters/alert'
import Comment from '@@src/api/presenters/comment'
import SubmitButton from '@@src/components/buttons/submit_button'
import * as analytics from '@@src/analytics'
import { sleep } from '@@src/utils'
import userPermissions from '@@src/components/permissions/user_permissions'
import ErrorInfo from '@@src/components/error_info'
import DeleteCommentButton from '@@src/alerts_path/delete_comment_button'

import styles from './comment_form.css'

const INFLOWNET_WAIT = process.env.NODE_ENV === 'test' ? 0 : 1000

class CommentForm extends React.PureComponent {
  static propTypes = {
    alert: PropTypes.instanceOf(Alert).isRequired,
    t: PropTypes.func.isRequired,
    permissions: PropTypes.array.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    handleSubmitError: PropTypes.func.isRequired,
    handleSuccessfulChange: PropTypes.func.isRequired,
    handleCancel: PropTypes.func.isRequired,
    className: PropTypes.string,
    parentComment: PropTypes.instanceOf(Comment),
    comment: PropTypes.instanceOf(Comment),
  }

  static defaultProps = {
    handleSuccessfulChange: noop,
    handleCancel: noop,
  }

  render() {
    const {
      t, className, parentComment, handleCancel, permissions,
      alert, commentResult, comment, handleSuccessfulChange,
    } = this.props
    const canEditAlerts = permissions.includes('can_edit_alerts')
    const commentFormId = parentComment && parentComment.commentId ?
      `comment-form-${alert.alertId}-${parentComment.commentId}` :
      `comment-form-${alert.alertId}`

    return (
      <React.Fragment>
        {
          !canEditAlerts ?
            <UncontrolledTooltip
              modifiers={{ flip: { enabled: false } }}
              placement="top"
              target={commentFormId}>
              {t('common/text:permissions.disabled')}
            </UncontrolledTooltip>
            : null
        }
        {
          commentResult.wasFailure() ?
            <ErrorInfo error={commentResult.error}/> :
            null
        }
        <Form id={commentFormId} className={classnames(
          className,
          'd-flex flex-column align-items-end',
        )}>
          <AppFormGroup
            disabled={!canEditAlerts}
            value={this.selectFieldValue('comment')}
            onChange={this.commentFields.onChangeHandlerFor('comment')}
            errorText={this.selectFieldErrorText('comment')}
            className="mb-3 w-100"
            placeholder={t('input.write_comment')}
            type="textarea"
            name="comment"
            rows={4} />
          <div className="d-flex justify-content-end align-items-center w-100">
            {
              comment ?
                <DeleteCommentButton
                  className="mr-auto"
                  onDeleteSuccess={handleSuccessfulChange}
                  comment={comment} />
                : null
            }
            {
              parentComment ?
                <Button
                  name="cancel-comment-form-button"
                  className="mr-2"
                  onClick={handleCancel}>
                  {t('button.cancel')}
                </Button>
                : null
            }
            <SubmitButton
              className={styles['create-comment-form-btn']}
              disabled={!canEditAlerts}
              name="create-comment-submit-button"
              color="primary"
              submitText={
                comment ?
                  t('button.save_changes') :
                  parentComment ?
                    t('button.add_reply') :
                    t('button.add_comment')
              }
              result={commentResult}
              onSubmitForm={this.handleClickSubmit} />
          </div>
        </Form>
      </React.Fragment>
    )
  }

  constructor(props) {
    super(props)
    this.commentFields = new FormFields(
      this,
      'createOrEditComment', {
        comment: comment => comment ? '' : 'common/errors:form.required',
      },
    )

    this.state = {
      createOrEditComment: this.commentFields.initialState({
        comment: this.props.comment ?
          this.props.comment.formattedComment(props.t) : '',
      }),
    }
  }

  selectFieldValue(fieldName) {
    return this.commentFields.selectValue(this.state, fieldName)
  }

  selectFieldErrorText(fieldName) {
    const { t } = this.props
    const error = this.commentFields.selectError(this.state, fieldName)

    return error ? t(error) : ''
  }

  handleClickSubmit = async ev => {
    ev.preventDefault()

    const {
      handleSubmit,
      handleSubmitError,
      handleSuccessfulChange,
    } = this.props
    const comment = this.selectFieldValue('comment')

    try {
      const res = await this.commentFields.performPreSubmitChecks()
        .catch(() => false)

      if (!res) {
        return
      }

      await handleSubmit(comment)
      await sleep(INFLOWNET_WAIT)
      await handleSuccessfulChange()
      await this.commentFields.resetState()
    } catch (err) {
      analytics.logError(err)
      handleSubmitError(err)
    }
  }
}

export default compose(
  userPermissions,
  withTranslation([
    'src/alerts_path/comment_form',
    'common/errors',
    'common/text',
  ]),
)(CommentForm)
