import gql from 'graphql-tag'
import React from 'react'
import { compose } from 'redux'
import { graphql } from '@apollo/client/react/hoc'
import { withTranslation } from 'react-i18next'
import { Alert, Row, Button, Form } from 'reactstrap'

import * as analytics from '@@src/analytics'
import FormFields from '@@src/utils/form_fields'
import AppFormGroup from '@@src/components/forms/app_form_group'
import SubmitButton from '@@src/components/buttons/submit_button'
import transformProps from '@@src/components/transform_props'
import AsyncResultSwitch from '@@src/components/async_result_switch'
import { parseGraphQLResult } from '@@src/api/presenters'
import {
  AppError, AsyncResult, validGroupCategories, createSelectGraphQLResult,
} from '@@src/utils'

import styles from './edit_general_settings_section.css'

class EditGeneralSettingsSection extends React.PureComponent {
  render() {
    const { t, groupResult } = this.props

    return (
      <div>
        <h3>{t('headings.form_title')}</h3>
        <div className={styles.separator}/>

        <AsyncResultSwitch
          result={groupResult}
          renderSuccessResult={this.renderGeneralSettings}/>
      </div>
    )
  }

  renderGeneralSettings = ({ data: group }) => {
    const { t } = this.props
    const { result } = this.state
    const groupType = group.isGroupOfGroups() ? 'groups' :
      group.isDeviceGroup() ? 'devices' : 'network_assets'

    return (
      <Form className="pb-3">
        {
          !result.wasFailure() ? null :
          <Alert color="danger">
            {result.error.translateWith(t)}
          </Alert>
        }

        <Row>
          <AppFormGroup
            name="category"
            type="select"
            label={t('labels.category')}
            value={this.selectFieldValue('category')}
            onChange={this.fields.onChangeHandlerFor('category')}
            errorText={this.selectFieldErrorText('category')}
            className="col-sm-12 col-md-8">
            {
              validGroupCategories(groupType).map(category => (
                <option key={category} value={category}>
                  {t(`common/text:text.category_${category}`)}
                </option>
              ))
            }
          </AppFormGroup>
        </Row>

        <Row>
          <AppFormGroup
            name="name"
            type="text"
            label={t('labels.name')}
            value={this.selectFieldValue('name')}
            onChange={this.fields.onChangeHandlerFor('name')}
            errorText={this.selectFieldErrorText('name')}
            className="col-sm-12 col-md-8">
          </AppFormGroup>
        </Row>

        <SubmitButton
          name="save-button"
          color="primary"
          result={result}
          disabled={
            this.fields.hasAnyValidationErrors() ||
              this.fields.isPristine()
          }
          buttonStatus=""
          onSubmitForm={this.onSubmitForm}
          submitText={t('buttons.save')}
          className={styles.button}/>

        <Button
          name="discard-changes-button"
          type="button"
          color="secondary"
          onClick={this.onDiscardChanges}
          disabled={this.fields.isPristine()}
          className={styles.button}>
          {t('buttons.discard_changes')}
        </Button>
      </Form>
    )
  }

  constructor(props) {
    super(props)

    this.fields = new FormFields(this, 'editGroupFields', {
      name: v => v ? '' : 'errors.required',
      category: v => v ? '' : 'errors.required',
    })

    this.state = {
      result: AsyncResult.notFound(),
      editGroupFields: this.fields.initialState(),
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.groupResult !== prevProps.groupResult &&
      this.props.groupResult.wasSuccessful()
    ) {
      this.resetFormState()
    }
  }

  onSubmitForm = async event => {
    event.preventDefault()

    this.setState({ result: AsyncResult.pending() })

    const { groupId } = this.props

    try {
      const res = await this.fields.performPreSubmitChecks().catch(() => false)

      if (!res) {
        this.setState({ result: AsyncResult.notFound() })
        return
      }

      const name = this.selectFieldValue('name')
      const category = this.selectFieldValue('category')

      await this.props.editGroup({
        variables: { groupId: groupId, name, category },
      })

      this.setState({
        result: AsyncResult.success(),
        editGroupFields: this.fields.initialState({
          name: name,
          category: category,
        }),
      })
    } catch (e) {
      analytics.logError(e)

      this.setState({ result: AsyncResult.fail(AppError.from(e)) })
    }
  }

  onDiscardChanges = () => {
    this.resetFormState()
  }

  resetFormState() {
    const { data: group } = this.props.groupResult

    return this.setState({
      editGroupFields: this.fields.initialState({
        name: group.name,
        category: group.category,
      }),
    })
  }

  selectFieldValue(fieldName) {
    return this.fields.selectValue(this.state, fieldName)
  }

  selectFieldErrorText(fieldName) {
    const error = this.fields.selectError(this.state, fieldName)

    return error ? this.props.t(error) : error
  }

  static GROUP_QUERY = gql`
    query Groups($id: Int!) {
      group(id: $id) {
        id
        name
        category
        members {
          count
          type
        }
      }
    }
  `

  static EDIT_GROUP_MUTATION = gql`
    mutation EditGroup($groupId: Int!, $name: String, $category: CategoryType) {
      editGroup (groupId: $groupId, name: $name, category: $category) {
        id,
        name,
        category
      }
  }`
}

export default compose(
  transformProps(() => () => {
    return {
      selectGroupResult: createSelectGraphQLResult('group', {
        mapResult: parseGraphQLResult,
        nullObject: null,
      }),
    }
  }),
  graphql(EditGeneralSettingsSection.GROUP_QUERY, {
    options: ({ groupId }) => {
      return {
        fetchPolicy: 'network-only',
        variables: {
          id: groupId,
        },
      }
    },
    props: ({ data, ownProps: { selectGroupResult } }) => {
      return {
        refetchData: data.refetch,
        groupResult: selectGroupResult(data),
      }
    },
  }),
  graphql(EditGeneralSettingsSection.EDIT_GROUP_MUTATION, {
    name: 'editGroup',
  }),
  withTranslation([
    'src/management_path/groups_path/edit_group_page/edit_general_settings_section', // eslint-disable-line max-len
    'common/text',
  ]),
)(EditGeneralSettingsSection)
