import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash/fp/object'
import { createSelector } from 'reselect'
import { withTranslation } from 'react-i18next'

import AsyncResult from '@@src/utils/async_result'
import AnalysisGraphProgressBar
from '@@src/components/progress_bars/analysis_graph_progress_bar'

const DUMMY_START_PROGRESS = 33

class CpisViewProgressBar extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    cpisDataResult: PropTypes.instanceOf(AsyncResult).isRequired,
  }

  render() {
    const { className } = this.props

    return (
      <AnalysisGraphProgressBar
        className={className}
        {...this.selectProgressInfo(this.props)}/>
    )
  }

  selectProgressInfo = createSelector(
    [get('t'), get('cpisDataResult')],
    (t, cpisDataResult) => {
      switch (true) {
        case (cpisDataResult.wasFailure()): {
          return {
            text: t('text.loading_error'),
            color: 'danger',
            progress: 100,
            errorDetail: cpisDataResult.error.translateWith(t),
          }
        }

        case (cpisDataResult.isPending()): {
          return {
            text: t('text.loading_cpis_data'),
            color: 'info',
            progress: DUMMY_START_PROGRESS,
          }
        }

        default:
          return { text: t('text.cpis_data'), color: 'success', progress: 100 }
      }
    }
  )
}

export default withTranslation([
  'src/analysis_path/pressure_analysis_path/cpis_view/cpis_view_progress_bar',
  'common/text',
])(CpisViewProgressBar)
