import TimeSeriesModel from './time_series_model'
import { convertUnit } from '@@src/utils/app_unit_conversion'
import { INTERNAL_PRESSURE_UNIT } from '@@src/utils/unit_constants'

export default class PagedPressureSubsetData {
  constructor(data, original = data) {
    Object.assign(this, data)

    this._original = Object.assign({}, original)
    this.units = this.units || INTERNAL_PRESSURE_UNIT
  }

  withUnits(units) {
    return units === this.units ? this : new PagedPressureSubsetData({
      ...this._original,
      units,
      data: this._original.data.map(item => ({
        ...item,
        data: item.data.map(point => new TimeSeriesModel({
          ...point,
          min: convertUnit(point.min, this.units, units),
          max: convertUnit(point.max, this.units, units),
          mean: convertUnit(point.mean, this.units, units),
        })),
      })),
    }, this._original)
  }
}
