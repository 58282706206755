import { useCallback, useEffect, useMemo, useState } from 'react'
import { ANOMALIES_BOUNDS_DATA_QUERY } from '../../analysis_path/pressure_analysis_path/pressure_subset_graph/graphql'
import { formatNetworkAssetUniqueId } from './useGetPressureData'
import { AsyncResult } from '../../utils'
import { parseGraphQLResult } from '../../api/presenters'

const useGetAnomalyBoundsData = (props, anomalyEnabled) => {
  const [anomalyBounds, _setAnomalyBoundsData] = useState(new Map())
  const [loading, setLoading] = useState(0)
  const [_totalParts, setTotalParts] = useState(0)
  const [error, setError] = useState('')

  const anomalyBoundsResult = useMemo(() => {
    const anomalyBoundsWithSources =
      Array.from(anomalyBounds).map((value) => {
        return value[1]
      })

    if (loading !== 0) {
      return AsyncResult.pending(anomalyBoundsWithSources)
    }

    return AsyncResult.success(anomalyBoundsWithSources)
  }, [anomalyBounds, loading])

  const formatResults = useCallback((result, asset) => {
    const assetId = formatNetworkAssetUniqueId(asset)
    anomalyBounds.set(assetId, { source: asset, bounds: parseGraphQLResult(result.data.getAnomaliesBounds) })
  }, [anomalyBounds])

  const fetchData = useCallback(
    async (
      client,
      start,
      end,
      pressureUnit,
      networkAssetChannels,
      signal,
    ) => {

      const queryList = []
      for (let i = 0; i < networkAssetChannels.length; i++) {
        queryList.push(
          client.query(
            {
              query: ANOMALIES_BOUNDS_DATA_QUERY,
              variables: {
                start,
                end,
                pressureUnit,
                source: networkAssetChannels[i],
              },
              context: {
                fetchOptions: {
                  signal,
                },
              },
            }
          )
        )
      }

      setLoading((oldLoading) => oldLoading + queryList.length)
      setTotalParts((oldTotalParts) => oldTotalParts + queryList.length)

      let results = []

      try {
        results = await Promise.all(queryList)
      } catch (err) {
        setError(err)
      }

      results.forEach((result, index) => formatResults(result, networkAssetChannels[index]))
      setLoading((loadingOld) => loadingOld - queryList.length)
    }, [formatResults])

  useEffect(() => {
    if (anomalyEnabled && Object.keys(props).length) {
      const { start, end, client, networkAssetChannels, units, signal } = props
      fetchData(
        client,
        new Date(start).toISOString(),
        new Date(end).toISOString(),
        units.pressure,
        networkAssetChannels,
        signal,
      )
      return () => {
        setLoading(0)
        setTotalParts(0)
        anomalyBounds.clear()
      }
    }
    return () => { }
  }, [
    props.start,
    props.end,
    props.client,
    props.networkAssetChannels,
    props.units,
    props.signa,
    fetchData,
    anomalyEnabled,
  ])

  return { anomalyBoundsResult, error, loading: loading !== 0 }
}

export default useGetAnomalyBoundsData
