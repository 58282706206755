import React, { useEffect, useMemo, useRef } from 'react'

import BaseMapComponent, {
  DEFAULT_MAP_OPTIONS,
} from '../../../../components/base_map/base_map.component'
import LoadingIcon from '../../../../../components/loading_icon'
import Control from 'react-leaflet-control'
import NetworkAssetMapSuperClusterComponent from './map-cluster/network_asset_map_supercluster.component'
import NetworkAssetMapMarkerComponent from './map-marker/network_asset_map_marker.component'

import useSupercluster from 'use-supercluster'
import { useGetNetworkAssetsForMap } from '../hooks/get_network_assets_for_map'
import { parseFiltersFromLocation } from '@@src/_v2/utils'

import GeoBounds from '../../../../../utils/graphs/geo_bounds'
import GeoCoordinate from '../../../../../utils/graphs/geo_coordinate'

import PropTypes from 'prop-types'

const MAX_ZOOM = DEFAULT_MAP_OPTIONS.maxZoom

const NetworkAssetsMapComponent = ({
  externalMapRef,
  onMarkerClick,
  filters,
  currentMapPosition,
  onPositionUpdate = () => null,
}) => {
  const initialPositions = useRef(
    parseFiltersFromLocation(location.search).mapPosition
  )
  const mapRef = useRef(null)

  useEffect(() => {
    mapRef.current &&
      externalMapRef &&
      (externalMapRef.current = mapRef.current)
    return () => externalMapRef && (externalMapRef.current = null)
  }, [mapRef.current])

  const { everywherePosition, isLoading, networkAssets } =
    useGetNetworkAssetsForMap(filters)

  useEffect(() => {
    !currentMapPosition.bounds &&
      everywherePosition &&
      mapRef.current.leafletElement.flyToBounds(
        everywherePosition.toLatLngBounds(),
        { animate: false }
      )
  }, [currentMapPosition.bounds, everywherePosition])

  const { clusters, supercluster } = useSupercluster({
    points: networkAssets,
    bounds: currentMapPosition.bounds
      ? currentMapPosition.bounds.toSuperclusterBounds()
      : GeoBounds.fromCoordinates(-90, -180, 90, 180).toSuperclusterBounds(),
    zoom:
      typeof currentMapPosition.zoom === 'number' ? currentMapPosition.zoom : 1,
    options: { radius: 100, maxZoom: MAX_ZOOM },
  })

  const clusteredNetworkAssets = useMemo(
    () =>
      clusters.map((cluster) => {
        const { cluster: isCluster } = cluster.properties || {}
        const networkAsset = !isCluster ? cluster.properties.networkAsset : null
        const key = !isCluster
          ? `marker-na-${networkAsset.assetId}`
          : `cluster-${cluster.id}`

        return isCluster ? (
          <NetworkAssetMapSuperClusterComponent
            key={key}
            onMarkerClick={onMarkerClick}
            supercluster={supercluster}
            cluster={cluster}
          />
        ) : (
          <NetworkAssetMapMarkerComponent
            key={key}
            onClick={onMarkerClick}
            networkAsset={networkAsset}
          />
        )
      }),
    [clusters, supercluster]
  )

  return (
    <BaseMapComponent
      options={{ minZoom: 0, maxZoom: MAX_ZOOM }}
      onPositionChange={onPositionUpdate}
      position={initialPositions.current}
      mapRef={mapRef}
    >
      <Control position={'topleft'}>
        {isLoading && (
          <LoadingIcon className="fa-2x text-center" style={{ width: 30 }} />
        )}
      </Control>
      {clusteredNetworkAssets}
    </BaseMapComponent>
  )
}

NetworkAssetsMapComponent.propTypes = {
  externalMapRef: PropTypes.shape({ current: PropTypes.object }),
  currentMapPosition: PropTypes.shape({
    bounds: PropTypes.instanceOf(GeoBounds),
    center: PropTypes.instanceOf(GeoCoordinate),
    zoom: PropTypes.number,
  }),
  onPositionUpdate: PropTypes.func,
  filters: PropTypes.shape({
    search: PropTypes.string,
    filters: PropTypes.arrayOf(PropTypes.string),
  }),
  onMarkerClick: PropTypes.func,
}

export default NetworkAssetsMapComponent
