import React from 'react'
import routes from '../../routes'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import styles from '../../components/lists/device_list_item/device_list_item.css'
import { Link } from 'react-router-dom'
import moment from 'moment'
import DevicesTableItemIcon from '../../components/icons/devices_table_item_icon'

function formatDate(date) {
  return `${moment(date).format('L')} ${moment(date).format('LTS')}`
}

function ManagementDevicesTableItem({ deviceHealthRow, t }) {
  const {
    deviceId, serialNumber, groups, lastCommunicationTime, firstCommissioned,
    installationChannel, networkAssetId, networkAssetReadableId, networkAssetReadableName,
    battery1, signal, hasActiveAlerts, hasDataGaps, hasBatteryIssues,
  } = deviceHealthRow

  const hasBatteryVoltage = Number.isFinite(battery1)
  const batteryVoltage = hasBatteryVoltage ? (battery1 / 1000).toFixed(2) : null
  const hasSignalStrength = Number.isFinite(signal) && signal !== -32768
  const signalText = hasSignalStrength ? `${signal} dbm` : '-'
  const signalStrength = !hasSignalStrength ? '0bar' :
    signal >= -69 ? '4bar' :
    signal >= -97 ? '3bar' :
    signal >= -104 ? '2bar' :
    signal >= -105 ? '1bar' :
    '0bar'

  return (
    <tr>
      <td className={styles['valign-middle']}>
        <DevicesTableItemIcon {...deviceHealthRow} className={styles['icon-small']} />
      </td>
      <td className={styles['valign-middle']}>
        <Link to={routes.managementDevicesDetailPath(deviceId)}>
          {serialNumber}
        </Link>
      </td>
      <td className={styles['valign-middle']}>
        {networkAssetId ? (
          <>
            <Link to={routes.networkAssetsDetailPath(networkAssetId)}>{networkAssetReadableId}</Link>
            {installationChannel && ` (${t(`common/text:text.asset_channel_${installationChannel}`)})`}
          </>
        ) : (<>–</>)}
      </td>
      <td className={styles['valign-middle']}>
        {networkAssetId && networkAssetReadableName || '-'}
      </td>
      <td className={styles['valign-middle']}>
        {Array.isArray(groups) && groups.map((group, index) => (
          <Link
            className={'d-block'}
            key={`${serialNumber}_${index}`}
            to={routes.managementGroupsDetailPath(group.id)}>{group.name}</Link>
        ))}
      </td>
      <td className={styles['valign-middle']}>
        {firstCommissioned ? (formatDate(firstCommissioned)) : '–'}
      </td>
      <td className={styles['valign-middle']}>
        {lastCommunicationTime ? formatDate(lastCommunicationTime) : '-'}
      </td>
      <td className={`${styles['valign-middle']} text-center`}>
        {hasActiveAlerts ? t('text.yes') : t('text.no')}
      </td>
      <td className={`${styles['valign-middle']} text-center`}>
        {hasDataGaps ? t('text.yes') : t('text.no')}
      </td>
      <td className={`${styles['valign-middle']} text-right`}>
        {signalText}
      </td>
      <td className={`${styles['valign-middle']} text-right`}>
        {t('signal.' + signalStrength)}
      </td>
      <td className={`${styles['valign-middle']} text-right`}>
        {hasBatteryVoltage ? `${batteryVoltage} V` : '-'}
      </td>
      <td className={`${styles['valign-middle']} text-right`}>
        {hasBatteryIssues
          ? t('battery.replace')
          : hasBatteryVoltage
            ? t('battery.good')
            : '-'
        }
      </td>
    </tr>
  )
}

ManagementDevicesTableItem.propTypes = {
  deviceHealthRow: PropTypes.object.isRequired,
}

export default compose(
  withTranslation([
    'src/management_path/devices_table_path/devices_table_item',
    'common/text',
  ]),
)(ManagementDevicesTableItem)
