import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import classnames from 'classnames'

import {
  AVAILABLE_DISTANCE_UNITS,
  AVAILABLE_PRESSURE_UNITS,
  AVAILABLE_TEMPERATURE_UNITS,
  INTERNAL_DISTANCE_UNIT,
  INTERNAL_PRESSURE_UNIT,
  INTERNAL_TEMPERATURE_UNIT,
} from '@@src/utils/unit_constants'
import AppFormGroup from '@@src/components/forms/app_form_group'

import styles from './unit_settings.css'

class UnitSettings extends React.PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    heading: PropTypes.string,
    getChangeHandler: PropTypes.func.isRequired,
    selectFieldErrorText: PropTypes.func.isRequired,
    pressureUnit: PropTypes.oneOf(AVAILABLE_PRESSURE_UNITS).isRequired,
    distanceUnit: PropTypes.oneOf(AVAILABLE_DISTANCE_UNITS).isRequired,
    temperatureUnit: PropTypes.oneOf(AVAILABLE_TEMPERATURE_UNITS).isRequired,
    availablePressureUnits: PropTypes.arrayOf(
      PropTypes.oneOf(AVAILABLE_PRESSURE_UNITS)
    ).isRequired,
    availableDistanceUnits: PropTypes.arrayOf(
      PropTypes.oneOf(AVAILABLE_DISTANCE_UNITS)
    ).isRequired,
    availableTemperatureUnits: PropTypes.arrayOf(
      PropTypes.oneOf(AVAILABLE_TEMPERATURE_UNITS)
    ).isRequired,
    className: PropTypes.string,
  }

  static defaultProps = {
    availablePressureUnits: AVAILABLE_PRESSURE_UNITS,
    availableDistanceUnits: AVAILABLE_DISTANCE_UNITS,
    availableTemperatureUnits: AVAILABLE_TEMPERATURE_UNITS,
    pressureUnit: INTERNAL_PRESSURE_UNIT,
    distanceUnit: INTERNAL_DISTANCE_UNIT,
    temperatureUnit: INTERNAL_TEMPERATURE_UNIT,
  }

  render() {
    const {
      heading,
      pressureUnit,
      distanceUnit,
      temperatureUnit,
      getChangeHandler,
      selectFieldErrorText,
      t,
      availablePressureUnits,
      availableDistanceUnits,
      availableTemperatureUnits,
      className,
    } = this.props

    return (
      <fieldset className={classnames('b-0', className)}>
        <legend className={classnames('h6 mb-2', styles.heading)}>
          {heading || t('headings.unit_presets')}
        </legend>
        <AppFormGroup
          className={styles['unit-form-group']}
          name="pressureUnit"
          type="select"
          label={t('common/forms:unit_labels.pressure')}
          value={pressureUnit}
          onChange={getChangeHandler('pressureUnit')}
          errorText={selectFieldErrorText('pressureUnit')}
        >
          {this.renderUnitOptions(availablePressureUnits)}
        </AppFormGroup>
        <AppFormGroup
          className={styles['unit-form-group']}
          name="distanceUnit"
          type="select"
          label={t('common/forms:unit_labels.distance')}
          value={distanceUnit}
          onChange={getChangeHandler('distanceUnit')}
          errorText={selectFieldErrorText('distanceUnit')}
        >
          {this.renderUnitOptions(availableDistanceUnits, true)}
        </AppFormGroup>
        <AppFormGroup
          className={styles['unit-form-group']}
          name="temperatureUnit"
          type="select"
          label={t('common/forms:unit_labels.temperature')}
          value={temperatureUnit}
          onChange={getChangeHandler('temperatureUnit')}
          errorText={selectFieldErrorText('temperatureUnit')}
        >
          {this.renderUnitOptions(availableTemperatureUnits)}
        </AppFormGroup>
      </fieldset>
    )
  }

  renderUnitOptions = (units) => {
    const { t } = this.props

    return units.map((unit) => (
      <option key={unit} value={unit}>
        {t(`common/text:units.${unit}`)}
      </option>
    ))
  }
}

export default withTranslation([
  'src/components/forms/unit_settings',
  'common/forms',
])(UnitSettings)
