import React from 'react'
import GraphContext from '@@src/components/graphs/graph_context'
import { get } from 'lodash/fp/object'
import { compose } from 'redux'
import { area } from 'd3-shape'
import { createSelector } from 'reselect'
import { withTranslation } from 'react-i18next'
import { globalSequence } from '@@src/utils'

class AnomalyBoundsLayer extends React.PureComponent {
  render() {
    const { selectedItemBounds, graphConfig, boundsShown } = this.props
    if (!selectedItemBounds || !selectedItemBounds.length || !boundsShown) {
      return null
    }

    const boundsNodes = this.selectBoundsNodes(this.props)
    const calculateAreaPath = this.calculateAreaPath()
    const areaTopBoundAnomaly = calculateAreaPath(boundsNodes.topSide)
    const areaBottomBoundAnomaly = calculateAreaPath(boundsNodes.bottomSide)
    const clipPathId = '-bounds-clip-path'
    const clipPathIdGlobal = `anomalies-layer-clip-path-${this.id}`
    return (
      <g
        key={'anomalies-layer-bounds'}
        name={'anomalies-layer-bounds'}
      >
        <defs>
          <clipPath id={clipPathIdGlobal}>
            <rect
              x={graphConfig.leftPadding}
              y={graphConfig.topPadding}
              width={graphConfig.plotAreaWidth}
              height={graphConfig.plotAreaHeight} />
          </clipPath>

          <pattern
            id="diagonalHatch"
            width="8"
            height="8"
            patternTransform="rotate(45 0 0)"
            patternUnits="userSpaceOnUse"
          >
            <line
              x1="0"
              y1="0"
              x2="0"
              y2="8"
              stroke="#EFEFEF"
              strokeWidth={8}
            />
          </pattern>
          <clipPath id={clipPathId}>
            <path
              d={areaTopBoundAnomaly}
            />
            <path
              d={areaBottomBoundAnomaly}
            />
          </clipPath>
        </defs>
        <g name="anomalies-bounds" clipPath={`url(#${clipPathIdGlobal})`}>
          <rect
            fill={'url(#diagonalHatch)'}
            clipPath={`url(#${clipPathId})`}
            x={graphConfig.leftPadding}
            y={graphConfig.topPadding}
            width={graphConfig.plotAreaWidth}
            height={graphConfig.plotAreaHeight} />
          <path
            stroke="#EFEFEF"
            fill="none"
            d={areaBottomBoundAnomaly}
          />
          <path
            stroke="#EFEFEF"
            fill="none"
            d={areaTopBoundAnomaly}
          />
        </g>
      </g>
    )
  }

  constructor(props) {
    super(props)
    this.id = globalSequence.next()
  }

  calculateAreaPath = () =>
    area()
      .x(d => d.x)
      .y0(d => d.y0)
      .y1(d => d.y1)

  selectBoundsNodes = createSelector(
    [
      get('graphConfig'),
      get('selectedItemBounds'),
    ],
    (graphConfig, bounds) => {
      return bounds.reduce((acc, bound) => {
        acc.topSide.push({
          x: graphConfig.xScale(bound.startTime),
          y0: 0,
          y1: graphConfig.yScale(bound.upperBound),
        })
        acc.topSide.push({
          x: graphConfig.xScale(bound.endTime),
          y0: 0,
          y1: graphConfig.yScale(bound.upperBound),
        })
        acc.bottomSide.push({
          x: graphConfig.xScale(bound.startTime),
          y0: graphConfig.plotAreaHeight + graphConfig.topPadding,
          y1: graphConfig.yScale(bound.lowerBound),
        })
        acc.bottomSide.push({
          x: graphConfig.xScale(bound.endTime),
          y0: graphConfig.plotAreaHeight + graphConfig.topPadding,
          y1: graphConfig.yScale(bound.lowerBound),
        })
        return acc
      }, { topSide: [], bottomSide: [] })
    }
  )
}

function AnomalyBounds(props) {
  return (
    <GraphContext.Consumer>
      {config => (
        <AnomalyBoundsLayer
          graphConfig={config} {...props} />
      )}
    </GraphContext.Consumer>
  )
}

export default compose(
  withTranslation([
    'src/analysis_path/pressure_analysis_path/pressure_subset_graph/histogram_layer', // eslint-disable-line max-len
  ]),
)(AnomalyBounds)
