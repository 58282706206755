import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { mapTypeAndColourToMarkerIcon } from '../../../../../../utils/network_asset'
import NetworkAssetMapPopupComponent from './network_asset_map_popup.component'
import { NetworkAssetForMapItem } from '../../../../../api/models/network_asset_for_map_item'
import MapMarkerComponent from '../../../../../components/map_marker/map_marker.component'

import './network_asset_map_marker.component.scss'

const NetworkAssetMapMarkerComponent = ({ networkAsset, onClick = () => null }) => {
  const iconUrl = mapTypeAndColourToMarkerIcon('primary', networkAsset.type)
  const markerRef = useRef()

  return (
    <MapMarkerComponent
      name={`asset-marker-${networkAsset.assetId}`}
      onClick={() => onClick(networkAsset)}
      markerRef={markerRef}
      iconUrl={iconUrl}
      errorCount={networkAsset.activeErrorCount}
      warningCount={networkAsset.activeWarningCount}
      position={networkAsset.getCoordinates()} >
      <NetworkAssetMapPopupComponent markerRef={markerRef} networkAsset={networkAsset} />
    </MapMarkerComponent>
  )
}

NetworkAssetMapMarkerComponent.propTypes = {
  networkAsset: PropTypes.instanceOf(NetworkAssetForMapItem),
  onClick: PropTypes.func,
}

export default React.memo(NetworkAssetMapMarkerComponent)
