import { graphql } from '@apollo/client/react/hoc'
import classnames from 'classnames'
import gql from 'graphql-tag'
import { maxBy } from 'lodash/fp/math'
import { noop } from 'lodash/fp/util'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { withTranslation } from 'react-i18next'
import {
  Button,
  FormGroup,
  InputGroup,
  InputGroupText,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap'
import { compose } from 'redux'

import * as analytics from '@@src/analytics'
import NetworkAsset from '@@src/api/presenters/network_asset'
import PagedData from '@@src/api/presenters/paged_data'
import SubmitButton from '@@src/components/buttons/submit_button'
import ErrorInfo from '@@src/components/error_info'
import DebouncedInput from '@@src/components/forms/debounced_input'
import DeviceIconWithStatus from '@@src/components/icons/device_icon_with_status'
import InfiniteScrollList from '@@src/components/lists/infinite_scroll_list'
import incrementalPaginatedRequests from '@@src/components/pagination/incremental_paginated_request_container'
import { AppError, AsyncResult } from '@@src/utils'

import styles from './add_device_to_network_asset_modal.css'

class AddDeviceToNetworkAssetModal extends React.PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    channel: PropTypes.string,
    networkAsset: PropTypes.instanceOf(NetworkAsset).isRequired,
    refetchDevices: PropTypes.func.isRequired,
    devicesResult: PropTypes.instanceOf(AsyncResult).isRequired,
    handleToggle: PropTypes.func,
    handleSuccess: PropTypes.func.isRequired,
    className: PropTypes.string,
  }

  static defaultProps = {
    isOpen: false,
    handleSuccess: noop,
    refetchDevices: noop,
    devicesResult: AsyncResult.success([]),
  }

  render() {
    const {
      t,
      className,
      isOpen,
      networkAsset,
      handleToggle,
      devicesResult,
      fetchNextDevices,
    } = this.props
    const { submitResult, selectedDevice } = this.state

    return (
      <Modal className={className} isOpen={isOpen} toggle={handleToggle}>
        <ModalHeader className="border-bottom-0" toggle={handleToggle}>
          {t('headings.title', { assetName: networkAsset.assetName })}
        </ModalHeader>

        <ModalBody className="p-0">
          {submitResult.wasFailure() ? (
            <ErrorInfo className="mx-3 mt-3" error={submitResult.error} />
          ) : null}

          <FormGroup
            className={classnames(
              styles['device-item'],
              'px-3 py-2 bg-light mb-0',
              {
                [styles['item-border-bottom']]:
                  devicesResult.wasSuccessful() &&
                  devicesResult.data.pagination.totalResults !== 0,
              }
            )}
          >
            <InputGroup>
              <DebouncedInput
                type="search"
                name="search-devices-input"
                debounce={200}
                onChange={this.handleSearchDevices}
                placeholder={t('text.search_placeholder')}
              />

              <InputGroupText>
                <i className="fa fa-search" />
              </InputGroupText>
            </InputGroup>
          </FormGroup>

          <InfiniteScrollList
            name="top-level-groups-list"
            result={devicesResult}
            className={classnames(
              'list-unstyled mb-0 d-flex flex-column',
              styles['siblings-list']
            )}
            fetchNextPage={fetchNextDevices}
          >
            {devicesResult.data.map((device) => (
              <li
                key={device.id}
                className={classnames(styles['device-item-container'])}
              >
                <Button
                  type="button"
                  name="device-item-button"
                  color="link"
                  onClick={this.createHandleDeviceClick(device)}
                  disabled={
                    !device.currentCommission ||
                    !!device.currentCommission.currentInstallation
                  }
                  className={classnames(
                    styles['device-item'],
                    styles['device-item-button'],
                    'w100 rounded-0 py-1'
                  )}
                >
                  <DeviceIconWithStatus
                    device={device}
                    className={styles['device-item-icon']}
                  />

                  <div className={styles['device-item-info']}>
                    <span className={styles['device-item-serial-number']}>
                      {device.serialNumber}
                    </span>

                    <span className={styles['device-item-activity-text']}>
                      {device.translateLastActivityText(t)}
                    </span>
                  </div>
                </Button>
              </li>
            ))}
          </InfiniteScrollList>
        </ModalBody>

        <ModalFooter>
          <Button
            name="cancel-button"
            type="button"
            color="secondary"
            onClick={handleToggle}
          >
            {t('buttons.cancel')}
          </Button>

          <SubmitButton
            disabled={!selectedDevice}
            name="submit-button"
            color="primary"
            result={submitResult}
            onSubmitForm={this.handleSubmit}
            submitText={t('buttons.install')}
          ></SubmitButton>
        </ModalFooter>
      </Modal>
    )
  }

  constructor(props) {
    super(props)

    this.state = this.initialState()
  }

  initialState() {
    return {
      submitResult: AsyncResult.notFound(),
      selectedDevice: null,
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.isOpen && !prevProps.isOpen) {
      this.setState(this.initialState())
    }
  }

  handleSearchDevices = (ev) => {
    const { refetchDevices } = this.props

    refetchDevices({
      pageNumber: 1,
      searchQuery: ev.target.value.substring(0, 200),
    })
  }

  createHandleDeviceClick = (device) => {
    return () => this.setState({ selectedDevice: device })
  }

  handleSubmit = async () => {
    const {
      handleToggle,
      handleSuccess,
      createInstallation,
      networkAsset,
      channel,
    } = this.props
    const { selectedDevice } = this.state

    try {
      this.setState({ submitResult: AsyncResult.pending() })

      const commissionStart = selectedDevice.currentCommission.start.getTime()

      const getLastInstallation = maxBy('end')

      const lastDeviceInstallation = getLastInstallation(
        selectedDevice.installations
      )
      const endOfLastDeviceInstallation = lastDeviceInstallation
        ? lastDeviceInstallation.end.getTime()
        : 0

      const lastAssetInstallation = getLastInstallation(
        (networkAsset.installations || []).filter((ins) => {
          return ins.channelMap.pressure_1 === channel
        })
      )
      const endOfLastAssetinstallation = lastAssetInstallation
        ? lastAssetInstallation.end.getTime()
        : 0

      const start = moment(
        new Date(
          Math.max(
            commissionStart,
            endOfLastDeviceInstallation,
            endOfLastAssetinstallation
          )
        )
      )
        .add(1, 'minute')
        .startOf('minute')
        .tz('UTC')

      if (start.isAfter(moment())) {
        throw new Error('Please wait before installing a new device')
      }

      await createInstallation({
        variables: {
          start: start.format('YYYY-MM-DD HH:mm:00.000') + '000Z',
          deviceId: selectedDevice.id,
          channelMap: {
            pressure_1: channel,
          },
          networkAssetId: networkAsset.id,
        },
      })

      await handleSuccess()
      await handleToggle()
    } catch (err) {
      if (err.message.includes('Please wait before installing a new device')) {
        const customError = new AppError(err, {
          key: 'common/errors:please_wait_before_install',
        })
        this.setState({ submitResult: AsyncResult.fail(customError) })
        return
      }
      this.setState({ submitResult: AsyncResult.fail(err) })
      analytics.logError(err)
    }
  }

  static CREATE_INSTALLATION = gql`
    mutation CreateInstallation(
      $networkAssetId: Int!
      $deviceId: Int!
      $start: String!
      $channelMap: ChannelMappingInput!
    ) {
      addInstallation(
        deviceId: $deviceId
        channelMap: $channelMap
        whenInstalled: $start
        networkAssetId: $networkAssetId
      )
    }
  `

  static DEVICES_QUERY = gql`
    query PagedDevices($searchQuery: String, $pageNumber: Int!) {
      pagedDevices(
        searchQuery: $searchQuery
        pageNumber: $pageNumber
        resultsPerPage: 20
        orderBy: "communicationTime"
        orderDirection: "desc"
        ignoreDevicesWithStates: [returned]
      ) {
        devices {
          id
          state
          serialNumber
          currentCommission {
            start
            currentInstallation {
              networkAsset {
                id
                assetId
                assetType
              }
            }
          }
          installations {
            end
            start
          }
        }
        pagination {
          pageNumber
          totalPages
          totalResults
        }
      }
    }
  `
}

export default compose(
  graphql(AddDeviceToNetworkAssetModal.CREATE_INSTALLATION, {
    name: 'createInstallation',
  }),
  incrementalPaginatedRequests(AddDeviceToNetworkAssetModal.DEVICES_QUERY, {
    skip: ({ isOpen }) => !isOpen,
    options: () => ({
      variables: {
        pageNumber: 1,
      },
    }),
    mergeData: (a, b) =>
      new PagedData({
        ...b,
        data: a.data.concat(b.data),
      }),
    methodName: 'pagedDevices',
    nullObject: { data: [], map: () => {}, pagination: { totalResults: 0 } },
    resultFunc: ({ result, refetch, fetchNext }) => {
      return {
        refetchDevices: refetch,
        fetchNextDevices: fetchNext,
        devicesResult: result,
      }
    },
  }),
  withTranslation([
    'src/management_path/network_assets_path/network_asset_detail_page/add_device_to_network_asset_modal', // eslint-disable-line max-len
    'common/text',
  ])
)(AddDeviceToNetworkAssetModal)
