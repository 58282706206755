import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { get } from 'lodash/fp/object'
import { Link } from 'react-router-dom'
import { compose } from 'redux'
import { createSelector } from 'reselect'
import { withTranslation } from 'react-i18next'

import * as d3Shape from 'd3-shape'
import moment from 'moment'
import routes from '@@src/routes'
import GraphItem from '@@src/components/graphs/graph_item'
import NetworkAssetIcon from '@@src/components/icons/network_asset_icon'
import DeviceIconWithStatus
from '@@src/components/icons/device_icon_with_status'
import { AsyncResult, globalSequence } from '@@src/utils'
import { withGraphConfig } from '@@src/components/graphs/graph_context'
import { closestPointWithX } from '@@src/utils/graphs'
import { Popup, PopupAnchor, BOTTOM } from '@@src/components/popup'
import AppSettingsConsumer from '@@src/components/app_settings_consumer'
import { AVAILABLE_PRESSURE_UNITS } from '@@src/utils/unit_constants'
import {
  convertAlertThreshold,
  convertAlertUnitText,
} from '@@src/utils/app_unit_conversion'

import styles from './alerts_display_layer.css'

const ALERT_DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss'

class AlertsDisplayLayer extends React.PureComponent {
  static propTypes = {
    disabled: PropTypes.bool,
    graphItems: PropTypes.arrayOf(PropTypes.instanceOf(GraphItem)).isRequired,
    alertsResult: PropTypes.instanceOf(AsyncResult).isRequired,
    pressureUnits: PropTypes.oneOf(AVAILABLE_PRESSURE_UNITS).isRequired,
  }

  clipPathId = `alerts-display-layer-clip-path-${globalSequence.next()}`

  render() {
    const { disabled, alertsResult, graphConfig } = this.props
    const pagedData = alertsResult.data

    if (disabled || !pagedData.data.length) {
      return null
    }

    const alertInfoParams = this.selectAlertInfoParams(this.props)

    return (
      <g name="alerts-display-layer">
        <defs>
          <clipPath id={this.clipPathId}>
            <rect
              x={graphConfig.leftPadding}
              y={0}
              width={graphConfig.plotAreaWidth}
              height={graphConfig.height} />
          </clipPath>
        </defs>
        {alertInfoParams.map(info => {
          const { alert } = info

          return (
            <g
              name="alerts-display-layer-item"
              clipPath={`url(#${this.clipPathId})`}
              key={alert.alertId}>
              <path
                key={alert.alertId}
                d={info.lineData}
                fill="none"
                className={styles['alert-path']}
                strokeDasharray="2,5" />

              <PopupAnchor
                x={info.textX}
                y={info.textY}
                className={styles['alert-icon']}
                elementType="text">
                &#xf071;

                {this.renderPopupForAlert(info.alert)}
              </PopupAnchor>
            </g>
          )
        })}
      </g>
    )
  }

  renderPopupForAlert(alert) {
    const { t, graphItems, pressureUnits } = this.props
    const graphItem = findGraphItemForAlert(alert, graphItems)

    if (
      !graphItem ||
      (!graphItem.isFromDevice() && !graphItem.isFromNetworkAsset())
    ) {
      return null
    }
    const { source } = graphItem

    return (
      <Popup
        isOpen="auto"
        location={BOTTOM}
        className={styles['alert-popup-container']}>
        <div>
          <i className={classnames(
            styles['alert-popup-icon'],
            'fas fa-exclamation-triangle',
          )}></i>

          &nbsp;

          <Link
            to={routes.alertDetailsPath(alert.alertId)}
            className={styles['alert-popup-alert-description']}>
            {t(alert.descriptionKey, alert.details ? {
              unit: t(
                convertAlertUnitText(alert, pressureUnits), {
                  count: convertAlertThreshold(alert, pressureUnits),
                }
              ),
              threshold: convertAlertThreshold(alert, pressureUnits),
            } : undefined)}
          </Link>

          &nbsp;

          <span className={styles['alert-popup-alert-id']}>
            #{alert.alertId}
          </span>
        </div>

        <div>
          {t('text.alert_occurred_at', {
            occurredAt: moment(alert.occurredAt).format(ALERT_DATE_FORMAT),
          })}

          &nbsp;

          {
            graphItem.isFromDevice() ? (
              <DeviceIconWithStatus device={source} className={styles.icon} />
            ) : null
          }

          {
            graphItem.isFromNetworkAsset() ? (
              <NetworkAssetIcon
                networkAsset={source}
                className={styles.icon} />
            ) : null
          }

          <Link to={
            graphItem.isFromDevice() ?
              routes.managementDevicesDetailPath(source.id) :
              graphItem.isFromNetworkAsset() ?
                routes.networkAssetsDetailPath(source.id) :
                null
          }>
            {graphItem.name}
          </Link>
        </div>
      </Popup>
    )
  }

  selectAlertInfoParams = createSelector(
    [get('alertsResult'), get('graphItems'), get('graphConfig')],
    (result, graphItems, graphConfig) => result.data.data.map(alert => {
      const graphItem = findGraphItemForAlert(alert, graphItems)
      if (!graphItem) {
        return null
      }

      const closestPoint =
        closestPointWithX(graphItem, get('time'), alert.occurredAt)

      if (!closestPoint) {
        return null
      }

      const maxY = graphConfig.yScale.domain()[1]
      const minY = graphConfig.yScale.domain()[0]
      const alertHeight = closestPoint.mean + 0.05 * (maxY - minY)

      return {
        closestPoint,
        alert,
        textX: graphConfig.xScale(alert.occurredAt),
        textY: graphConfig.yScale(alertHeight),
        lineData: d3Shape.line()
          .x(d => graphConfig.xScale(d[0]))
          .y(d => graphConfig.yScale(d[1]))([
            [alert.occurredAt, alertHeight],
            [alert.occurredAt, closestPoint.mean],
            [closestPoint.time, closestPoint.mean],
          ]),
      }
    }).filter(a => !!a)
  )
}

export function findGraphItemForAlert(alert, graphItems) {
  switch (alert.details.__typename) {
    case 'StandardAlertDetails': {
      return graphItems.find(graphItem => {
        const source = graphItem.source

        return source.__typename === 'NetworkAsset' &&
          source.id === alert.details.networkAssetId
      })
    }

    case 'DeviceNoCommsAlertDetails': {
      return graphItems.find(graphItem => {
        const source = graphItem.source

        switch (source.__typename) {
          case 'Device': {
            return source.id === alert.details.deviceId
          }

          default:
            return false
        }
      })
    }

    case 'EventAlertDetails': {
      return graphItems.find(graphItem => {
        const source = graphItem.source

        switch (source.__typename) {
          case 'NetworkAsset': {
            return source.id === alert.details.networkAssetId
          }

          case 'Device': {
            return source.id === alert.details.deviceId
          }

          default:
            return false
        }
      })
    }

    default:
      return null
  }
}

function AlertsDisplayLayerContainer(props) {
  return (
    <AppSettingsConsumer>
      {
        (units) => (
          <AlertsDisplayLayer
            pressureUnits={units.pressure}
            {...props}
          />
        )
      }
    </AppSettingsConsumer>
  )
}

export default compose(
  withGraphConfig(),
  withTranslation([
    'src/analysis_path/pressure_analysis_path/pressure_subset_graph/alerts_display_layer', // eslint-disable-line max-len
    'common/text',
  ])
)(AlertsDisplayLayerContainer)
