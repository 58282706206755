import gql from 'graphql-tag'

export const CREATE_INSTALLATION = gql`
mutation CreateInstallation(
  $networkAssetId: Int!,
  $deviceId: Int!,
  $start: String!,
  $end: String
  $channelMap: ChannelMappingInput!,
) {
  addInstallation(
    deviceId: $deviceId,
    channelMap: $channelMap,
    whenInstalled: $start,
    whenRemoved: $end,
    networkAssetId: $networkAssetId,
  )
}
`
