import L from 'leaflet'
import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash/fp/object'
import { Marker } from 'react-leaflet'
import { createSelector } from 'reselect'

import DeviceCommission from '@@src/api/presenters/device_commission'
import commissionedIconUrl from
'@@src/static_assets/svgs/device_icon_pin_commissioned.svg'
import decommissionedIconUrl from
'@@src/static_assets/svgs/device_icon_pin_decommissioned.svg'

export default class DeviceCommissionMarker extends React.PureComponent {
  static defaultProps = {
    timePeriod: [new Date(), new Date()],
    openPopupDelay: 300,
  }

  static propTypes = {
    onClick: PropTypes.func,
    timePeriod: PropTypes.array.isRequired,
    commission: PropTypes.instanceOf(DeviceCommission).isRequired,
    isPopupOpen: PropTypes.bool,
    openPopupDelay: PropTypes.number.isRequired,
  }

  render() {
    const { children, onClick } = this.props

    return (
      <Marker
        ref={this.markerRef}
        icon={this.selectIcon(this.props)}
        onClick={onClick}
        position={this.selectPosition(this.props)}>
        {children}
      </Marker>
    )
  }

  markerRef = React.createRef()

  openMarkerTimeoutID = undefined

  componentDidMount() {
    this.updatePopupOpenState(this.props.isPopupOpen)
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.commission !== prevProps.commission &&
      this.markerRef.current &&
      this.markerRef.current.leafletElement
    ) {
      this.markerRef.current.leafletElement.closePopup()
    }

    if (
      this.props.isPopupOpen !== prevProps.isPopupOpen ||
      this.props.commission !== prevProps.commission
    ) {
      this.updatePopupOpenState(this.props.isPopupOpen)
    }
  }

  componentWillUnmount() {
    if (Number.isFinite(this.openMarkerTimeoutID)) {
      global.clearTimeout(this.openMarkerTimeoutID)
    }
  }

  updatePopupOpenState(isPopupOpen) {
    if (
      isPopupOpen &&
      this.markerRef.current &&
      this.markerRef.current.leafletElement
    ) {
      // timeout is necessary to give the map some time to zoom into the element
      // should it be necessary, otherwise the popup will force a pan on its own
      if (isPopupOpen) {
        this.openMarkerTimeoutID = setTimeout(() => {
          if (this.markerRef.current && this.markerRef.current.leafletElement) {
            this.markerRef.current.leafletElement.openPopup()
          }
        }, this.props.openPopupDelay)
      } else {
        this.markerRef.current.leafletElement.closePopup()
      }
    }
  }

  selectPosition = createSelector(
    [get('commission')],
    ({ location }) => [location.latitude, location.longitude]
  )

  selectIcon = createSelector(
    [get('commission'), get('timePeriod')],
    (commission, [start, end]) => {
      const mostSevereIssue = commission.device ?
        commission.device.mostSevereIssue : null
      const imgHeight = 72
      const imgWidth = 48
      const anchorHeight = 60
      let iconUrl = ''

      if (mostSevereIssue) {
        iconUrl = mostSevereIssue.mapIconPinUrl
      } else {
        iconUrl =
          commission.wasActiveInPeriod(start, end) ? commissionedIconUrl :
          decommissionedIconUrl
      }

      return L.icon({
        iconUrl,
        iconRetinaUrl: iconUrl,
        shadowUrl: undefined,
        iconSize: [imgWidth, imgHeight],
        iconAnchor: [imgWidth / 2, anchorHeight],
        popupAnchor: [0, -0.8 * imgHeight],
        tooltipAnchor: [0, -0.8 * imgHeight],
        shadowSize: [41, 41],
      })
    }
  )
}
