import React from 'react'
import PropTypes from 'prop-types'
import { Alert } from 'reactstrap'
import { withTranslation } from 'react-i18next'

function SuccessfulConfigUpdateAlert(props) {
  const { t, className } = props

  return (
    <Alert
      name="device-config-update-success-alert"
      className={className} color="success">
      {t('text.device_config_updated_successfully')}
    </Alert>
  )
}

SuccessfulConfigUpdateAlert.propTypes = {
  className: PropTypes.string,
}

export default withTranslation([
  'src/components/alerts/successful_config_update_alert',
])(SuccessfulConfigUpdateAlert)
