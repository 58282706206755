import React from 'react'
import PropTypes from 'prop-types'
import { Label, Input, FormGroup } from 'reactstrap'

/**
 * Alerts inheritance props
 * @param {Array} tree - Assembled component tree, valid up to the tenant. This will be recursively rendered to the DOM by this component
 * @param {Function} setInheritance - Function to be called to change inheritance
 * @param {Number} activeInheritance - id of where settings are currently inherited from
 */

class AlertSettingsInheritance extends React.PureComponent {
  static propTypes = {
    tree: PropTypes.array.isRequired,
    setInheritance: PropTypes.func.isRequired,
    activeInheritance: PropTypes.number,
  }

  hasChildren(item) {
    return item.children && item.children.length
  }

  render() {
    const {
      activeInheritance,
      tree,
    } = this.props
    const level = this.props.level || 0

    return (
      <React.Fragment>
        {
          tree.map((item, i) => {
            return (
              <div
                className="ml-3" key={`level-${level}-${i}`}
                name={`level-${level}-${i}`}>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="radio"
                      name={`inheritance-${level}-${i}`}
                      value={item.id}
                      onChange={(event) => {
                        this.props.setInheritance(Number(event.target.value))
                      }}
                      checked={item.id === activeInheritance}
                    />
                    <span className="far fa-folder mr-2"/>
                    <span
                      className={ item.id === activeInheritance
                        ? 'font-weight-bold'
                        : null }
                    >
                      {item.name}
                    </span>
                  </Label>
                </FormGroup>

                {
                  this.hasChildren(item) ? (
                    <AlertSettingsInheritance
                      tree={item.children}
                      level={level + 1}
                      setInheritance={this.props.setInheritance}
                      activeInheritance={activeInheritance}/>
                  ) : null
                }
              </div>
            )
          })
        }
      </React.Fragment>
    )
  }
}

export default AlertSettingsInheritance
