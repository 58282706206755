import React from 'react'

import AreaOverlay from '../drag_layer/area_overlay'
import { DragEventContext }
from '@@src/components/graphs/drag_layer/drag_event_layer'

const DragAreaOverlayContainer = props => (
  <DragEventContext.Consumer>
    {({ Channel }) => (
      <DragAreaOverlay DragEventChannel={Channel} {...props}/>
    )}
  </DragEventContext.Consumer>
)

class DragAreaOverlay extends React.PureComponent {
  state = {
    zoomX: null,
    zoomY: null,
  }

  render() {
    const { className, DragEventChannel } = this.props
    const { zoomX, zoomY } = this.state

    return (
      <DragEventChannel
        onDrag={this.onDrag}
        onDragEnd={this.onDragEnd}
        onDragStart={this.onDragStart}>

        {
          (zoomX || zoomY) ? (
            <AreaOverlay className={className} xRange={zoomX} yRange={zoomY}/>
          ) : null
        }
      </DragEventChannel>
    )
  }

  onDragStart = () => {
    this.setState({ zoomX: null, zoomY: null })
  }

  onDrag = (dragEvent) => {
    const { localBoundsX, localBoundsY } = dragEvent

    this.setState({ zoomX: localBoundsX, zoomY: localBoundsY })
  }

  onDragEnd = () => {
    this.setState({ zoomX: null, zoomY: null })
  }
}

export default DragAreaOverlayContainer
