import React from 'react'
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

function ExportDropdown(props) {
  const {
    isOpen, handleToggle, className, t, children, color,
  } = props

  return (
    <Dropdown
      isOpen={isOpen}
      toggle={handleToggle}
      className={className}>
      <DropdownToggle color={color} caret>
        <span className="mr-2 fa fa-file-download" />
        {t('buttons.export')}
      </DropdownToggle>

      <DropdownMenu>
        {children}
      </DropdownMenu>
    </Dropdown>
  )
}

ExportDropdown.propTypes = {
  t: PropTypes.func.isRequired,
  handleToggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  color: PropTypes.string.isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
}

ExportDropdown.defaultProps = {
  isOpen: false,
  color: 'secondary',
}

export default withTranslation([
  'src/components/dropdowns/export_dropdown',
])(React.memo(ExportDropdown))
