import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { FormGroup, Input } from 'reactstrap'
import { createSelector } from 'reselect'
import { get } from 'lodash/fp/object'
import {
  FeatureFlags,
  withTenantFeatureFlags,
} from '../../../_v2/contexts/feature_flags/tenants_feature_flags.context'

import {
  HISTOGRAM_TYPES,
  HISTOGRAM_TYPE_ANOMALIES,
} from '../../../analysis_path/pressure_analysis_path/constants/index'
import { compose } from 'redux'

class PressureAnalysisViewHistogramDropdown extends React.PureComponent {
  static propTypes = {
    histogramOption: PropTypes.oneOf(HISTOGRAM_TYPES).isRequired,
    setHistogramOption: PropTypes.func.isRequired,
  }

  render() {
    const { t, setHistogramOption, histogramOption } = this.props

    return (
      <FormGroup className={'m-0'}>
        <Input
          value={histogramOption || ''}
          onChange={e => setHistogramOption(e.target.value)}
          id="histogram-type"
          name="type"
          type="select"
          style={{
            height: 'fit-content',
            padding: '0.15rem 3.75rem 0.15rem 0.75rem',
          }}
        >
          {
            this.selectHistogramTypes(this.props).map((type) => {
              return (
                <option key={type} value={type}>
                  {t(`label.${type}`)}
                </option>
              )
            })
          }
        </Input>
      </FormGroup>
    )
  }

  selectHistogramTypes = createSelector(
    [get('isFeatureEnabled')], (isFeatureEnabled) => {
      return isFeatureEnabled(FeatureFlags.anomalyDetection)
        ? HISTOGRAM_TYPES
        : [...HISTOGRAM_TYPES].filter(item => item !== HISTOGRAM_TYPE_ANOMALIES)
    },
  )
}

export default compose(
  withTranslation([
    'src/analysis_path/pressure_analysis_path/pressure_analysis_view_histogram_dropdown',
  ]),
  withTenantFeatureFlags,
)(PressureAnalysisViewHistogramDropdown)
