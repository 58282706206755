import moment from 'moment'

import FormFields from '@@src/utils/form_fields'

export default class AssignNetworkAssetFields extends FormFields {
  constructor(component, name, validationOverrides) {
    super(component, name, {
      channel: isRequired,
      startTime: createValidateStartTime(component),
      networkAsset: isRequired,
      ...validationOverrides,
    })
  }
}

function createValidateStartTime(component) {
  return (time, { networkAsset, channel }) => {
    const { commission, existingInstallationsResult } = component.props
    const now = moment()

    if (!commission) {
      return ''
    }

    const date = moment(time)
    if (
      !date.isSameOrAfter(moment(commission.start)) ||
      commission.end && !date.isSameOrBefore(moment(commission.end))
    ) {
      return 'errors.installation_cannot_be_outside_commission'
    }

    if (!date.isSameOrBefore(now)) {
      return 'errors.future_installation'
    }

    const { installations } = networkAsset

    const { data: existingInstallations } = existingInstallationsResult
    if (
      overlapsInstallations(installations, date, channel) ||
      overlapsInstallations(existingInstallations, date, channel)
    ) {
      return 'errors.overlapping_installation'
    }

    return ''
  }
}

function overlapsInstallations(installations, date, channel) {
  return installations.some(installation => {
    return channel === installation.channelMap.pressure_1 &&
      (
        installation.end === null ||
        (
          date.isAfter(installation.start) &&
          date.isBefore(installation.end)
        )
      )
  })
}

function isRequired(field) {
  return field ? '' : 'errors.required'
}
