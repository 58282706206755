import { useEffect, useState } from 'react'
import { useApolloClient } from '@apollo/client'
import { LIST_RAW_DATA_REQUESTS_QUERY } from '../../analysis_path/pressure_analysis_path/pressure_subset_graph/graphql'
import { SECONDS, createSelectGraphQLResult } from '../../utils'
import { parseGraphQLResult } from '../../api/presenters'

export const useGetListRawData = (props) => {
  const { start, end, networkAssetChannels, signal } = props

  const formatResults = createSelectGraphQLResult(
    'listRawDataRequests',
    {
      mapResult: (...args) => {
        return parseGraphQLResult(...args)
      },
      nullObject: {
        rawDataRequests: [],
        networkAssetsMinutesLeft: [],
      },
    }
  )

  const [listRawData, setListRawData] = useState(null)
  const client = useApolloClient()

  const fetchNewListRawData = async (queryStart, queryEnd, channels, abortSignal) => {
    await client.query({
      query: LIST_RAW_DATA_REQUESTS_QUERY,
      variables: {
        start: new Date(queryStart).toISOString(),
        end: new Date(queryEnd).toISOString(),
        networkAssetChannels: channels,
      },
      context: {
        fetchOptions: {
          signal: abortSignal,
        },
      },
      fetchPolicy: 'network-only',
    }).then(({ data }) => {
      const { rawDataRequests, networkAssetsMinutesLeft } = data.listRawDataRequests
      const filteredRawDataRequests = rawDataRequests.reduce((acc, request) => {
        if (acc.some(filteredRequest => checkIfSameRawRequest(filteredRequest, request))) {
          return acc
        }
        acc.push({ ...request })
        return acc
      }, [])
      setListRawData(formatResults({
        listRawDataRequests: { rawDataRequests: filteredRawDataRequests, networkAssetsMinutesLeft },
      }))
    })
  }

  useEffect(() => {
    fetchNewListRawData(start, end, networkAssetChannels, signal)

    const pollTimout = setInterval(() => {
      fetchNewListRawData(start, end, networkAssetChannels, signal)
    }, 90 * SECONDS)

    return () => clearInterval(pollTimout)
  }, [start, end, networkAssetChannels, signal])

  function checkIfSameRawRequest(first, second) {
    return first.startTime === second.startTime &&
      first.endTime === second.endTime &&
      first.networkAsset.channel === second.networkAsset.channel &&
      first.networkAsset.networkAssetId === second.networkAsset.networkAssetId &&
      first.deviceId === second.deviceId &&
      first.status === second.status
  }

  return {
    listRawDataRequestsResult:
      listRawData ? listRawData : formatResults({ rawDataRequests: [], networkAssetsMinutesLeft: [] }),
    refetchRawDataRequests: () => fetchNewListRawData(start, end, networkAssetChannels),
  }
}
