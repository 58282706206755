import React from 'react'
import PropTypes from 'prop-types'
import { Button, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap'

import ErrorInfo from '@@src/components/error_info'
import AsyncResult from '@@src/utils/async_result'
import SubmitButton from '@@src/components/buttons/submit_button'

export default class MessageBox extends React.PureComponent {
  static defaultProps = {
    result: AsyncResult.notFound(),
    onAccept: () => { },
    acceptText: 'Ok',
  }

  static propTypes = {
    toggle: PropTypes.func,
    isOpen: PropTypes.bool,
    result: PropTypes.instanceOf(AsyncResult).isRequired,
    onAccept: PropTypes.func.isRequired,
    acceptText: PropTypes.string.isRequired,
    cancelText: PropTypes.string,
    headerText: PropTypes.string.isRequired,
    footerContent: PropTypes.node,
    size: PropTypes.oneOf(['sm', 'lg']),
  }

  render() {
    const {
      isOpen, headerText, acceptText, children, cancelText, onAccept, result,
      footerContent, size,
    } = this.props

    return (
      <Modal size={size} isOpen={isOpen} toggle={this.toggle}>
        <ModalHeader toggle={this.toggle}>
          {headerText}
        </ModalHeader>

        <ModalBody>
          {result.wasFailure() ? <ErrorInfo error={result.error}/> : null}

          {children}
        </ModalBody>

        <ModalFooter>
          {
            footerContent ?
              <div className="mr-auto">
                {footerContent}
              </div>
              : null
          }

          {
            cancelText ? (
              <Button
                name="cancel-button"
                color="secondary"
                onClick={this.toggle}>
                {cancelText}
              </Button>
            ) : null
          }

          <SubmitButton
            name="accept-button"
            result={result}
            color="primary"
            disabled={result.isPending()}
            submitText={acceptText}
            onSubmitForm={onAccept}>
          </SubmitButton>
        </ModalFooter>
      </Modal>
    )
  }

  toggle = (...args) => {
    if (this.props.toggle) {
      this.props.toggle(...args)
    }
  }
}
