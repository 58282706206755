import React from 'react'
import PropTypes from 'prop-types'
import Datetime from 'react-datetime'
import { createSelector } from 'reselect'
import { get, omit } from 'lodash/fp/object'

import 'react-datetime/css/react-datetime.css'

export default class DatetimePicker extends React.PureComponent {
  static propTypes = {
    inputProps: PropTypes.object,
  }


  render() {
    const inputProps = this.selectInputProps(this.props)
    const nonInputProps = this.selectNonInputProps(this.props)

    return <Datetime inputProps={inputProps} {...nonInputProps}/>
  }

  selectInputProps = createSelector([get('inputProps')], inputProps => {
    const readOnlyProp = { readOnly: true }

    if (typeof inputProps === 'object') {
      return Object.assign({}, readOnlyProp, inputProps)
    } else {
      return readOnlyProp
    }
  })

  selectNonInputProps = createSelector([a => a], props => {
    return omit('inputProps')(props)
  })
}
