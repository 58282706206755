import React from 'react'
import { useGetWindowSizes } from '../hooks/utils/useGetWindowSizes'

// this is used to track width, height and if device is considered mobile device
// using custom hook in every component creates new event listener for each instance
// using context we only add one listener
export const WindowSizesContext = React.createContext()

export default function WindowSizesProvider({ children, testSizes = false }) {
  const { width, height, isMobileDevice } = useGetWindowSizes()
  return(
    <WindowSizesContext.Provider value={{
      windowHeight: testSizes ? 1081 : height,
      windowWidth: testSizes ? 1081 : width,
      isMobile: testSizes ? false : isMobileDevice,
    }}>
      {children}
    </WindowSizesContext.Provider>
  )
}

export function withWindowSizes(Component) {
  return function WithWindowSizes(props) {
    return (
      <WindowSizesContext.Consumer>
        {
          ({ windowHeight, windowWidth, isMobile }) => {
            return(
              <Component {...{
                ...props,
                windowWidth,
                windowHeight,
                isMobile: isMobile === undefined ? false : isMobile,
              }} />
            )
          }
        }
      </WindowSizesContext.Consumer>
    )
  }
}
