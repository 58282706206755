/*
 * Modal for displaying an error to the user that the time range is too long
 *
 * controlled using the Transient Localisation Modal Controller
 *
 */

import PropTypes from 'prop-types'
import React from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from 'reactstrap'
import { withTranslation } from 'react-i18next'

import styles from './transient_localisation_success_modal.css'

function NotEnoughSourcesModal(props) {
  const {
    t,
    handleToggle,
    isOpen,
  } = props

  return (
    <Modal
      centered={true}
      isOpen={isOpen}
    >
      <ModalHeader>
        <span className={styles['modal-title']}>
          {t('heading.title')}
        </span>
      </ModalHeader>
      <ModalBody>
        {t('body.dialog')}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={handleToggle}>
          {t('buttons.close')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

NotEnoughSourcesModal.propTypes = {
  t: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleToggle: PropTypes.func.isRequired,
}

export default withTranslation([
  'src/components/graphs/modals/transient_localisation_modals/not_enough_sources_modal', // eslint-disable-line max-len
])(NotEnoughSourcesModal)

