import { useEffect, useRef, useState } from 'react'
import { getNetworkAssetsForInstallDeviceModal } from '../../../../api/network_assets.service'
import { mapFlattenedFiltersToQueryObject } from '../../network_assets/utils/filters'
import { NetworkAssetWithInstallations } from '../../../../api/models/network_asset_with_installations'
import { useApolloClient } from '@apollo/client'

const useGetNetworkAssetInstallations = (filters) => {
  const client = useApolloClient()
  const abortController = useRef(null)
  const [results, setResults] = useState({
    error: null,
    networkAssetsWithInstallations: [],
    isLoading: true,
  })

  useEffect(() => {
    if (abortController.current) {
      abortController.current.abort()
    }

    if (!results.isLoading) {
      setResults({ ...results, isLoading: true })
    }

    abortController.current = new AbortController()

    getNetworkAssetsForInstallDeviceModal({
      client, abortController: abortController.current,
      filters: {
        search: filters.search,
        ...mapFlattenedFiltersToQueryObject(filters.filters),
        inBounds: null,
      },
    }).then(networkAssetsWithInstallations => {
      setResults({
        networkAssetsWithInstallations: networkAssetsWithInstallations.map(
          networkAssetWithInstallations => new NetworkAssetWithInstallations(networkAssetWithInstallations)
        ),
        error: null,
        isLoading: false,
      })
    })
      .catch(error => {
        setResults({ error, networkAssetsWithInstallations: [], isLoading: false })
      })
      .finally(() => {
        abortController.current = null
      })
  }, [filters])

  return { ...results }
}

export default useGetNetworkAssetInstallations
