import React from 'react'
import { createSelector } from 'reselect'
import { compose } from 'redux'
import gql from 'graphql-tag'
import { graphql } from '@apollo/client/react/hoc'

import { LegendItemColorContext } from
'@@src/components/colors/legend_item_color_provider'
import { parseGraphQLResult } from '@@src/api/presenters'
import transformProps from '@@src/components/transform_props'
import moment from 'moment'

import { sumBy, max } from 'lodash/fp/math'
import { dropWhile, takeWhile } from 'lodash/fp/array'
import { flow } from 'lodash/fp/util'
import { get, toPairs } from 'lodash/fp/object'
import { map, flatMap, groupBy, filter } from 'lodash/fp/collection'
import {
  createSelectGraphQLResult,
} from '@@src/utils'
import { findGraphItemForAlert } from '../alerts_display_layer/alerts_display_layer'

import {
  HISTOGRAM_TYPE_EVENTS,
  HISTOGRAM_TYPE_EVENTS_CPIS,
  HISTOGRAM_TYPE_EVENTS_SEVERITY,
  HISTOGRAM_TYPE_ANOMALIES,
  HISTOGRAM_TYPE_ALERTS,
  HISTOGRAM_TYPE_RAW_DATA,
  HISTOGRAM_TYPE_CPIS,
  DEFAULT_EVENTS_OBJECT,
} from '../../constants'

const DEFAULT_TILE_COUNT = 30
export const MARGIN_TOP = 10
export const TILE_HEIGHT = 40
export const EMPTY_TILE_HEIGHT = 2
export const INCREMENT_TILE_HEIGHT = 5
export const TILE_PADDING = 3
export const MINIMUM_INTERVAL_MILLISECONDS = 30

export default function histogramDataContainer(Component) {
  class HistogramDataContainer extends React.PureComponent {
    render() {
      return (
        <Component
          intervals={this.selectIntervals(this.props)}
          {...this.props} />
      )
    }

    // when time changes we create new intervals
    selectHistogramTimeIntervals = createSelector(
      [get('startTime'), get('endTime')],
      (startTime, endTime) => {
        const step = (endTime - startTime) / DEFAULT_TILE_COUNT
        const timeIntervals = []

        if (step < MINIMUM_INTERVAL_MILLISECONDS) { return timeIntervals }

        for (let i = 0; i < DEFAULT_TILE_COUNT; i++) {
          timeIntervals[i] = {
            segmentStart: Math.round(startTime + (step * i)),
            segmentEnd: Math.round(startTime + (step * (i + 1))),
          }
        }
        return timeIntervals
      }
    )

    selectIntervalsWithAlerts = createSelector(
      [this.selectHistogramTimeIntervals, get('alertsResult'), get('getLegendItemColor'), get('graphItems')],
      (intervals, alertsResult, getLegendItemColor, graphItems) => {
        const alertsCopy = alertsResult.wasSuccessful() ? [...alertsResult.data.data] : []
        const intervalCopy = intervals.map(a => { return { ...a } })
        let maxValueTileHeight = 0
        intervalCopy.forEach((interval) => {
          interval.data = []
          alertsCopy.forEach(alert => {
            if (moment(alert.occurredAt).isBetween(moment(interval.segmentStart), moment(interval.segmentEnd))) {
              const graphItem = findGraphItemForAlert(alert, graphItems)
              interval.data.push({
                data: alert,
                color: graphItem ? getLegendItemColor(graphItem.legendItem) : 'var(--secondary)',
              })
            }
          })
          if (maxValueTileHeight < interval.data.length) {
            maxValueTileHeight = interval.data.length
          }
        })
        intervalCopy.forEach((interval) => {
          interval.maxValueTileHeight = maxValueTileHeight
          const something = flow(
            groupBy(rawData => rawData.color),
            toPairs,
            map((toDecouple) => toDecouple[1]),
          )(interval.data)

          const sortedLists =
            something.sort((a, b) => {
              return b.length - a.length
            })

          interval.data = sortedLists.flat(1)
        })
        return intervalCopy
      }
    )

    selectIntervalsWithEventsData = createSelector(
      [
        this.selectHistogramTimeIntervals,
        get('pressureEvents'),
        get('graphItems'),
        get('getLegendItemColor'),
      ],
      (intervals, pressureEvents, graphItems, getLegendItemColor) => {
        const eventsCopy = pressureEvents.wasSuccessful() ? [...pressureEvents.data] : []

        return intervals.map((interval, index) => {
          const intervalData = eventsCopy.map(eventInterval => eventInterval.events[index])
          const dataForThis = intervalData.length ?
            intervalData.reduce((acc, event) => {
              const corespondingGraphItem = graphItems.find((item) => {
                return item.sourceId === event.assetId && item.sourceChannel === event.assetChannel
              })

              const newData = {
                countData: [],
                cpisData: [],
                severityData: [],
              }

              const color =
                corespondingGraphItem ? getLegendItemColor(corespondingGraphItem.legendItem) : 'var(--secondary)'

              if (event.eventsCpis > 0) {
                newData.cpisData.push({
                  data: event.eventsCpis,
                  color,
                })
              }
              if (event.eventsCount > 0) {
                newData.countData.push({
                  data: event.eventsCount,
                  color,
                })
              }
              if (event.eventsSeverity > 0) {
                newData.severityData.push({
                  data: event.eventsSeverity,
                  color,
                })
              }

              return {
                eventsCountSum: acc.eventsCountSum + event.eventsCount,
                eventsCpisSum: acc.eventsCpisSum + event.eventsCpis,
                eventsSeveritySum: acc.eventsSeveritySum + event.eventsSeverity,
                maxCount: Math.max(acc.maxCount, event.eventsCount),
                maxCpis: event.maxEventCpis ? Math.max(acc.maxCpis, event.maxEventCpis) : acc.maxCpis,
                maxSeverity:
                  event.maxEventSeverity ? Math.max(acc.maxSeverity, event.maxEventSeverity) : acc.maxSeverity,
                data: {
                  countData: [...acc.data.countData, newData.countData],
                  cpisData: [...acc.data.cpisData, newData.cpisData],
                  severityData: [...acc.data.severityData, newData.severityData],
                },
              }
            }, DEFAULT_EVENTS_OBJECT)
            :
            DEFAULT_EVENTS_OBJECT
          return {
            ...interval,
            ...dataForThis,
          }
        })
      }
    )

    selectIntervalsWithEvents = createSelector(
      [
        this.selectIntervalsWithEventsData,
        get('histogramOption'),
      ],
      (eventsWithAllData, histogramOption) => {
        let maxValueTileHeight = 0
        let intervalsWithData = []
        switch (histogramOption) {
          case HISTOGRAM_TYPE_EVENTS_CPIS: {
            intervalsWithData = eventsWithAllData.map(event => {
              maxValueTileHeight = Math.max(event.eventsCpisSum, maxValueTileHeight)
              return {
                segmentStart: event.segmentStart,
                segmentEnd: event.segmentEnd,
                max: event.maxCpis,
                sum: event.eventsCpisSum,
                data: [...event.data.cpisData.flat()],
              }
            })
            break
          }
          case HISTOGRAM_TYPE_EVENTS_SEVERITY: {
            intervalsWithData = eventsWithAllData.map(event => {
              maxValueTileHeight = Math.max(event.eventsSeveritySum, maxValueTileHeight)
              return {
                segmentStart: event.segmentStart,
                segmentEnd: event.segmentEnd,
                max: event.maxSeverity,
                sum: event.eventsSeveritySum,
                data: [...event.data.severityData.flat()],
              }
            })
            break
          }
          default: {
            intervalsWithData = eventsWithAllData.map(event => {
              maxValueTileHeight = Math.max(event.eventsCountSum, maxValueTileHeight)
              return {
                segmentStart: event.segmentStart,
                segmentEnd: event.segmentEnd,
                max: event.maxCount,
                sum: event.eventsCountSum,
                data: [...event.data.countData.flat()],
              }
            })
            break
          }
        }
        return intervalsWithData.map(interval => {
          interval.maxValueTileHeight = maxValueTileHeight
          return interval
        })
      }
    )

    selectIntervalsWithRawData = createSelector(
      [
        this.selectHistogramTimeIntervals,
        get('listRawDataRequestsResult'),
        get('graphItems'),
        get('getLegendItemColor'),
      ],
      (intervals, listRawDataRequestsResult, graphItems, getLegendItemColor) => {
        const rawDataCopy = listRawDataRequestsResult.wasSuccessful() ?
          [...listRawDataRequestsResult.data.rawDataRequests] : []
        const intervalCopy = intervals.map(a => { return { ...a } })
        let maxValueTileHeight = 0
        intervalCopy.forEach((interval) => {
          interval.data = []
          rawDataCopy.forEach(rawDataChunck => {
            const graphItem = graphItems.find(item => {
              return rawDataChunck.networkAsset.networkAssetId === item.sourceId &&
                rawDataChunck.networkAsset.channel === item.sourceChannel
            })
            if (checkIfDateInSegment(
              interval.segmentStart,
              interval.segmentEnd,
              rawDataChunck.startTime,
              rawDataChunck.endTime)
            ) {
              interval.data.push({
                data: rawDataChunck,
                color: graphItem ? getLegendItemColor(graphItem.legendItem) : 'var(--secondary)',
              })
            }
          })
          if (maxValueTileHeight < interval.data.length) {
            maxValueTileHeight = interval.data.length
          }
        })
        intervalCopy.forEach((interval) => {
          interval.maxValueTileHeight = maxValueTileHeight
          const something = flow(
            groupBy(rawData => rawData.color),
            toPairs,
            map((toDecouple) => toDecouple[1]),
          )(interval.data)

          const sortedLists =
            something.sort((a, b) => {
              return b.length - a.length
            })

          interval.data = sortedLists.flat(1)
        })
        return intervalCopy
      }
    )

    selectIntervalsWithAnomalies = createSelector(
      [
        this.selectHistogramTimeIntervals,
        get('anomalyData'),
        get('getLegendItemColor'),
      ],
      (intervals, { anomaliesWithSourcesAndPressure: anomalyData }, getLegendItemColor) => {
        const intervalCopy = intervals.map(a => { return { ...a } })
        let maxValueTileHeight = 0
        intervalCopy.forEach((interval) => {
          interval.data = []
          anomalyData.forEach(anomalyItem => {
            if (checkIfDateInSegment(
              interval.segmentStart,
              interval.segmentEnd,
              anomalyItem.anomaly.startTime,
              anomalyItem.anomaly.endTime,
            )) {
              interval.data.push({
                data: anomalyItem,
                color: getLegendItemColor(anomalyItem.legendItem),
              })
            }
          })
          if (maxValueTileHeight < interval.data.length) {
            maxValueTileHeight = interval.data.length
          }
        })
        intervalCopy.forEach((interval) => {
          interval.maxValueTileHeight = maxValueTileHeight
          const something = flow(
            groupBy(rawData => rawData.color),
            toPairs,
            map((toDecouple) => toDecouple[1]),
          )(interval.data)

          const sortedLists =
            something.sort((a, b) => {
              return b.length - a.length
            })

          interval.data = sortedLists.flat(1)
        })
        return intervalCopy
      }
    )

    selectIntervalsWithCpisData = createSelector(
      [
        this.selectHistogramTimeIntervals,
        get('cpisDataResult'),
        get('graphItems'),
        get('getLegendItemColor'),
      ],
      (intervals, { data: cpisData }, graphItems, getLegendItemColor) => {
        const intervalCopy = intervals.map(a => { return { ...a } })
        let maxValueTileHeight = 0
        intervalCopy.forEach(interval => {
          interval.data = []
          interval.sum = 0
          interval.avg = 0

          cpisData.forEach(cpisDataItem => {
            const legendItem = flow(
              flatMap('legendItem'),
              filter(item => item.source.__typename === 'NetworkAsset'),
              filter(item => item.sourceId === cpisDataItem.networkAssetId &&
                item.sourceChannel === cpisDataItem.logicalChannel)
            )(graphItems)[0]

            const formatedCPIS = flow(
              flatMap('data'),
              dropWhile(d => {
                return Number(d.time) < interval.segmentStart
              }),
              takeWhile(d => {
                return Number(d.time) <= interval.segmentEnd
              }),
              filter((CPISpoint) => {
                return CPISpoint.cpis
              })
            )(cpisDataItem.chunkedData)

            formatedCPIS.length &&
              interval.data.push({
                color: legendItem ? getLegendItemColor(legendItem) : 'var(--secondary)',
                data: formatedCPIS,
                value: sumBy('cpis')(formatedCPIS),
              })
          })

          interval.sum = flow(
            flatMap('data'),
            sumBy('cpis'),
          )(interval.data)

          const maxValue = max(flow(
            flatMap('data'),
            flatMap('cpis'),
          )(interval.data))
          interval.max = maxValue ? maxValue : 0

          const length = flow(flatMap('data'))(interval.data).length
          interval.avg = length ? interval.sum / length : 0

          if (maxValueTileHeight < interval.sum) {
            maxValueTileHeight = interval.sum
          }
        })
        intervalCopy.forEach((interval) => {
          interval.maxValueTileHeight = maxValueTileHeight
          const something = flow(
            groupBy(rawData => rawData.color),
            toPairs,
            map((toDecouple) => toDecouple[1]),
          )(interval.data)

          const sortedLists =
            something.sort((a, b) => {
              return b.reduce((ba, bb) => ba + bb.value, 0) - a.reduce((aa, ab) => aa + ab.value, 0)
            })

          interval.data = sortedLists.flat(1)
        })
        return intervalCopy
      }
    )

    // when we change option in dropdown this selector changes
    selectIntervals = createSelector(
      [
        get('histogramOption'),
        this.selectIntervalsWithAlerts,
        this.selectIntervalsWithEvents,
        this.selectIntervalsWithRawData,
        this.selectIntervalsWithAnomalies,
        this.selectIntervalsWithCpisData,
        this.selectHistogramTimeIntervals,
      ],
      (
        histogramOption,
        intervalsWithAlerts,
        intervalsWithEvents,
        intervalsWithRawData,
        intervalsWithAnomalies,
        intervalsWithCpisData,
        defaultIntervals
      ) => {
        switch (histogramOption) {
          case HISTOGRAM_TYPE_ALERTS: {
            return intervalsWithAlerts
          }
          case HISTOGRAM_TYPE_ANOMALIES: {
            return intervalsWithAnomalies
          }
          case HISTOGRAM_TYPE_EVENTS:
          case HISTOGRAM_TYPE_EVENTS_CPIS:
          case HISTOGRAM_TYPE_EVENTS_SEVERITY: {
            return intervalsWithEvents
          }
          case HISTOGRAM_TYPE_RAW_DATA: {
            return intervalsWithRawData
          }
          case HISTOGRAM_TYPE_CPIS: {
            return intervalsWithCpisData
          }
          default: {
            return defaultIntervals
          }
        }
      }
    )

    static CPIS_SUMMARY_QUERY = gql`
    query CPISSubsetData(
      $start: String!,
      $end: String!,
      $networkAssetChannels: [NetworkAssetChannel],
    ) {
      cpisSubsetData(
        start: $start,
        end: $end,
        networkAssetChannels: $networkAssetChannels,
      ) {
        logicalChannel
        networkAssetId
        chunkedData {
          index
          length
          data {
            time
            cpis
            physicalChannel
          }
        }
      }
    }
    `
  }

  function checkIfDateInSegment(segmentStart, segmentEnd, startTime, endTime) {
    return moment(segmentStart).isBetween(moment(startTime), moment(endTime)) ||
      moment(segmentEnd).isBetween(moment(startTime), moment(endTime)) ||
      moment(startTime).isBetween(moment(segmentStart), moment(segmentEnd)) ||
      moment(endTime).isBetween(moment(segmentStart), moment(segmentEnd)) ||
      moment(segmentStart).isSame(endTime) ||
      moment(segmentEnd).isSame(startTime)
  }

  function HistogramDataContainerWithColors(props) {
    return (
      <LegendItemColorContext.Consumer>
        {({ getLegendItemColor }) => (
          <HistogramDataContainer
            getLegendItemColor={getLegendItemColor}
            {...props} />
        )}
      </LegendItemColorContext.Consumer>
    )
  }

  return compose(
    transformProps(() => () => {
      const selectCpisData =
        createSelectGraphQLResult('cpisSubsetData', {
          mapResult: parseGraphQLResult,
          nullObject: [],
          bufferData: true,
        })

      const selectCpisDataQueryVariables = createSelector(
        [get('legendItemsResult'), get('startTime'), get('endTime')],
        (legendItemsResult, start, end) => {
          const queryStart = moment(start).startOf('day').toISOString()
          const queryEnd = moment(end).subtract(1, 'minute')
            .add(2, 'day').startOf('day').toISOString()
          const { data: legendItems } = legendItemsResult

          const networkAssetChannels = legendItems.filter(legendItem => {
            return legendItem.isFromNetworkAsset() && legendItem.visible
          }).map(legendItem => ({
            channel: legendItem.sourceChannel,
            networkAssetId: legendItem.sourceId,
          }))

          return {
            end: queryEnd,
            start: queryStart,
            networkAssetChannels,
          }
        }
      )

      return {
        selectCpisData,
        selectCpisDataQueryVariables,
      }
    }),
    graphql(HistogramDataContainer.CPIS_SUMMARY_QUERY, {
      options: (props) => {
        const { selectCpisDataQueryVariables } = props
        return {
          variables: selectCpisDataQueryVariables(props),
          fetchPolicy: 'network-only',
        }
      },
      props: ({ ownProps, data }) => {
        const { selectCpisData } = ownProps

        return {
          refetchData: data.refetch,
          cpisDataResult: selectCpisData(data),
        }
      },
    }),
  )(HistogramDataContainerWithColors)
}
