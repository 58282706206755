import React from 'react'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import { graphql } from '@apollo/client/react/hoc'
import gql from 'graphql-tag'
import ReactMarkdown from 'react-markdown'

import transformProps from '@@src/components/transform_props'
import AppLayout from '@@src/components/app_layout'
import { UserSettingsContext } from '@@src/components/user_settings_provider'
import requiresLogin from '@@src/components/security/requires_login'
import { parseGraphQLResult } from '@@src/api/presenters'
import { createSelectGraphQLResult } from '@@src/utils'
import AsyncResult from '@@src/utils/async_result'
import * as analytics from '@@src/analytics'
import AsyncResultSwitch from '@@src/components/async_result_switch'

import styles from './index_page.css'

const loadRelease = async (input) => {
  const { version, bodySignedUrl } = input

  const urlFetch = await fetch(bodySignedUrl, { method: 'GET' })
  const versionText = await urlFetch.text()

  return {
    version,
    bodySignedUrl: versionText,
  }
}

class ReleaseNotesPage extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      releaseNoteTexts: AsyncResult.pending(),
    }
  }

  async componentDidUpdate(prevProps) {
    const { releaseNoteList } = this.props
    const { releaseNoteList: prevReleaseNoteList } = prevProps

    if (releaseNoteList.wasSuccessful() &&
      !prevReleaseNoteList.wasSuccessful()
    ) {
      this.getReleaseNoteList()
    } else if (releaseNoteList.wasFailure() &&
      !prevReleaseNoteList.wasFailure()) {
      this.setState({
        releaseNoteTexts: AsyncResult.fail(releaseNoteList.error),
      })
    }
  }

  getReleaseNoteList = async () => {
    const { releaseNoteList } = this.props

    try {
      const releaseNoteTexts = await Promise.all(
        releaseNoteList.data
          .filter(item => item && item.bodySignedUrl)
          .map(async item => await loadRelease(item))
      )

      await this.setState({
        releaseNoteTexts: AsyncResult.success(releaseNoteTexts),
      })
    } catch (err) {
      analytics.logError('unable to construct release notes list', err)
      this.setState({
        releaseNoteTexts: AsyncResult.fail(err),
      })
    }
  }

  renderReleaseNotes = () => {
    const { t } = this.props
    const { releaseNoteTexts: { data: texts } } = this.state

    return (
      <React.Fragment>
        {
          texts.length > 0 ? (
            texts.map(text => (
              <React.Fragment key={text.version}>
                <h2>{t('text.version', { version: text.version })}</h2>
                <ReactMarkdown>
                  {text.bodySignedUrl}
                </ReactMarkdown>
                <br />
              </React.Fragment>
            ))
          ) : (
            <h2>{t('text.no_release_notes')}</h2>
          )
        }
      </React.Fragment>
    )
  }

  renderFailedReleaseNotes = () => (
    <h2 className="text-danger">{this.props.t('text.release_notes_failed')}</h2>
  )

  render() {
    const { t } = this.props
    const { releaseNoteTexts } = this.state

    return (
      <AppLayout title={t('text.title')}>
        <div className={`container ${styles['page-root']}`}>
          <h1>{t('text.title')}</h1>
          <br />

          <AsyncResultSwitch
            result={releaseNoteTexts}
            renderSuccessResult={this.renderReleaseNotes}
            renderFailResult={this.renderFailedReleaseNotes}
          />
        </div>
      </AppLayout>
    )
  }

  static RELEASE_NOTES_LIST_QUERY = gql`
    query ListReleaseNotes($language: Language!) {
      listReleaseNotes(language: $language) {
        version
        bodySignedUrl
      }
    }
  `
}

const ReleaseNotesListWithContainers = compose(
  transformProps(() => () => ({
    selectReleaseNoteListResult: createSelectGraphQLResult('listReleaseNotes', {
      mapResult: parseGraphQLResult,
    }),
  })),
  graphql(ReleaseNotesPage.RELEASE_NOTES_LIST_QUERY, {
    options: props => {
      const { language = 'en' } = props

      return {
        fetchPolicy: 'network-only',
        variables: {
          language,
        },
      }
    },
    props: ({ data, ownProps }) => {
      const { selectReleaseNoteListResult } = ownProps

      return {
        releaseNoteList: selectReleaseNoteListResult(data),
      }
    },
  }),
  withTranslation([
    'src/release_notes_path/index_page',
  ])
)(ReleaseNotesPage)

function ReleaseNotesPageWithUserSettings(props) {
  return (
    <UserSettingsContext.Consumer>
      {({ language }) => (
        <ReleaseNotesListWithContainers language={language} {...props} />
      )}
    </UserSettingsContext.Consumer>
  )
}

export default requiresLogin(ReleaseNotesPageWithUserSettings)
