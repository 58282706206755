import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { createSelector } from 'reselect'
import { get } from 'lodash/fp/object'
import DisplayDropdown from
'@@src/analysis_path/pressure_analysis_path/display_dropdown'
import getFilterDropdown from
'@@src/components/filter_component/filter_component'
import { EVENT_LOCALISATION_FEATURE } from
'@@src/components/tenant_licence/tenant_licence_feature_gate'
import PressureDropdownCPISIcon from
'@@src/static_assets/svgs/pressure_dropdown_cpis.svg'
import PressureDropdownPlotIcon from
'@@src/static_assets/svgs/pressure_dropdown_plot.svg'
import PressureDropdownEventsIcon from
'@@src/static_assets/svgs/pressure_dropdown_events.svg'
import PressureDropdownEventLocalisationIcon from
'@@src/static_assets/svgs/pressure_dropdown_event_localisation.svg'
import PressureDropdownAnomalyList from
'@@src/static_assets/svgs/pressure_dropdown_anomaly_list.svg'
import { FeatureFlags, withTenantFeatureFlags } from '../../_v2/contexts/feature_flags/tenants_feature_flags.context'

import {
  VIEW_TYPE_OPTIONS,
  VIEW_TYPE_PLOT,
  VIEW_TYPE_CPIS, VIEW_TYPE_EVENTS,
  VIEW_TYPE_EVENT_LOCALISATION,
  VIEW_TYPE_ANOMALY_LIST,
} from './constants'
import { compose } from 'redux'

const DisplayDropdownFilter = getFilterDropdown(DisplayDropdown)

class PressureAnalysisViewOptionsDropdown extends React.PureComponent {
  static propTypes = {
    currentView: PropTypes.oneOf(VIEW_TYPE_OPTIONS).isRequired,
    onChangeView: PropTypes.func.isRequired,
  }

  render() {
    const { name, className, onChangeView, currentView } = this.props
    return (
      <DisplayDropdownFilter
        name={name}
        value={currentView}
        options={this.selectDropdownOptions(this.props)}
        onChange={onChangeView}
        className={className} />
    )
  }

  selectDropdownOptions = createSelector(
    [get('isFeatureEnabled'), get('t')],
    (isFeatureEnabled, t) => {
      return [
        {
          value: VIEW_TYPE_PLOT,
          text: t('labels.plot'),
          description: t('text.view_description_plot'),
          image: PressureDropdownPlotIcon,
          licenceFeature: undefined,
        },
        {
          value: VIEW_TYPE_CPIS,
          text: t('labels.cpis'),
          description: t('text.view_description_plot'),
          image: PressureDropdownCPISIcon,
          licenceFeature: undefined,
        },
        {
          value: VIEW_TYPE_EVENTS,
          text: t('labels.events'),
          description: t('text.view_description_events'),
          image: PressureDropdownEventsIcon,
          licenceFeature: undefined,
        },
        {
          value: VIEW_TYPE_EVENT_LOCALISATION,
          text: t('labels.event_localisation'),
          description: t('text.view_description_event_localisation'),
          image: PressureDropdownEventLocalisationIcon,
          licenceFeature: EVENT_LOCALISATION_FEATURE,
        },
        isFeatureEnabled(FeatureFlags.anomalyDetection) &&
        {
          value: VIEW_TYPE_ANOMALY_LIST,
          text: t('labels.anomaly_list'),
          description: t('text.view_description_anomaly_list'),
          image: PressureDropdownAnomalyList,
          licenceFeature: undefined,
        },
      ].filter(option => option)
    })

}

export default compose(
  withTranslation([
    'src/analysis_path/pressure_analysis_path/pressure_analysis_view_options_dropdown',
  ]),
  withTenantFeatureFlags,
)(PressureAnalysisViewOptionsDropdown)
