import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { Alert } from 'reactstrap'
import moment from 'moment'

import * as analytics from '@@src/analytics'

const HOUR_IN_SECONDS = 60 * 60

function TenantDefaultConfiguration(props) {
  const { t, tenantConfiguration } = props
  const { secondsInterval, dateFrom, timeZone } = tenantConfiguration

  if (!dateFrom || !Number.isFinite(secondsInterval)) {
    analytics.logError('tenant session config not found on rendering device')

    return (
      <Alert color="danger">
        {t('error.no_tenant_config')}
      </Alert>
    )
  }

  const timeFormat = 'HH:mm:ss Z'
  const dateFromMoment = moment(dateFrom)
  const timeMoment = moment(dateFromMoment.format(timeFormat), timeFormat)

  if (timeZone) {
    timeMoment.tz(timeZone)
  }

  const dateFromTime = timeMoment.format('LT')
  const hour = timeZone ? `${dateFromTime} ${timeZone}` : dateFromTime

  return secondsInterval < HOUR_IN_SECONDS ?
    t('text.new_schedule_minute', {
      hour,
      count: secondsInterval / 60,
    }) :
    t('text.new_schedule_hour', {
      hour,
      count: secondsInterval / 60 / 60,
    })
}

TenantDefaultConfiguration.propTypes = {
  t: PropTypes.func.isRequired,
  tenantConfiguration: PropTypes.object.isRequired,
}

TenantDefaultConfiguration.defaultProps = {
  tenantConfiguration: {},
}

export default withTranslation([
  'src/components/device_configuration/tenant_default_configuration',
])(memo(TenantDefaultConfiguration))
