import gql from 'graphql-tag'

export const GROUP_SUGGESTIONS_QUERY = gql`
query GetGroups (
  $search: String,
) {
  getGroups(
    search: $search,
    pageNumber: 1,
    resultsPerPage: 5,
    ){
    pagination {
      perPage
      pageNumber
      pageIndex
      totalPages
      totalResults
    }
    groups {
      groupId
      groupName
    }
  }
}
`
