import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

export const REGISTER_DRAG_LISTENER = '@drag-cover/registerDragListener'
export const UNREGISTER_DRAG_LISTENER = '@drag-cover/unregisterDragListener'
export const SET_DRAG_MODE = '@drag-cover/setDragMode'

export const PAN_DRAG_MODE = 'panDragMode'
export const ZOOM_DRAG_MODE = 'zoomDragMode'
export const SELECT_DRAG_MODE = 'selectDragMode'
export const DRAG_MODES = [
  PAN_DRAG_MODE, ZOOM_DRAG_MODE, SELECT_DRAG_MODE,
]

class DragCover extends React.PureComponent {
  static propTypes = {
    resetDragMode: PropTypes.func.isRequired,
    unregisterDragListener: PropTypes.func.isRequired,
    dragListener: PropTypes.object,
  }

  render() {
    const { dragListener } = this.props

    return !dragListener ? null : (
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          cursor: 'crosshair',
          zIndex: 1000000000,
          userSelect: 'none',
          backgroundColor: 'transparent',
        }}
        onMouseUp={this.onMouseUp}
        onMouseMove={this.onMouseMove}/>
    )
  }

  constructor(props) {
    super(props)

    this.onMouseUp = this.onMouseUp.bind(this)
    this.onMouseMove = this.onMouseMove.bind(this)
  }

  onMouseUp(event) {
    event.stopPropagation()
    const { dragListener, unregisterDragListener } = this.props

    unregisterDragListener()
    dragListener.onMouseUp(event)
  }

  onMouseMove(event) {
    event.stopPropagation()
    const { dragListener } = this.props

    dragListener.onMouseMove(event)
  }
}

function mapStateToProps(state) {
  return {
    dragListener: selectDragListener(state),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    unregisterDragListener() {
      dispatch({ type: UNREGISTER_DRAG_LISTENER })
    },
    resetDragMode() {
      dispatch({ type: SET_DRAG_MODE, mode: ZOOM_DRAG_MODE })
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DragCover)

const initialState = {
  dragListener: null,
  dragMode: ZOOM_DRAG_MODE,
}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case REGISTER_DRAG_LISTENER:
      return Object.assign({}, state, {
        dragListener: action.listener,
      })

    case UNREGISTER_DRAG_LISTENER:
      return Object.assign({}, state, {
        dragListener: null,
      })

    case SET_DRAG_MODE:
      return Object.assign({}, state, {
        dragMode: action.dragMode,
      })

    default:
      return state
  }
}

export function registerDragListener(listener) {
  return { type: REGISTER_DRAG_LISTENER, listener }
}

export function selectDragListener({ dragCover }) {
  return dragCover.dragListener
}

export function setDragMode(dragMode) {
  return { type: SET_DRAG_MODE, dragMode }
}
