import React from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { graphql } from '@apollo/client/react/hoc'
import { Redirect } from 'react-router-dom'
import { createSelector } from 'reselect'
import { withTranslation } from 'react-i18next'
import { Row } from 'reactstrap'
import { noop } from 'lodash/fp/util'

import AsyncList from '@@src/components/lists/async_list'
import AsyncResult from '@@src/utils/async_result'
import requiresLogin from '@@src/components/security/requires_login'
import transformProps from '@@src/components/transform_props'
import AnalysisListItem from '@@src/components/lists/analysis_list_item/analysis_list_item'
import getWithPagination from '@@src/components/pagination/pagination_container'
import PaginationToolbar from '@@src/components/pagination/pagination_toolbar'
import withDataSourceSelectionLayout from '@@src/analysis_path/data_source_selection_layout'

import { parseGraphQLResult } from '@@src/api/presenters'
import {
  parseSearchParams,
  createSelectGraphQLResult,
  getResultString,
} from '@@src/utils'
import userPermissions from '@@src/components/permissions/user_permissions'
import { CAN_VIEW_ANALYSIS } from '../../_v2/contexts/user/consts/permissions'

import styles from './index.css'

export const SEED_ALL_SOURCES_PAGE_NUMBER = 1
export const SEED_ALL_SOURCES_RESULTS_PER_PAGE = 10
export const VISIBLE_ALL_SOURCES_PAGES = 5

class AnalysisList extends React.PureComponent {
  static propTypes = {
    addToSelected: PropTypes.func.isRequired,
    removeFromSelected: PropTypes.func.isRequired,
    dataGroupsResult: PropTypes.instanceOf(AsyncResult).isRequired,
    dataSourcesResult: PropTypes.instanceOf(AsyncResult).isRequired,
    refetchGroupsData: PropTypes.func.isRequired,
    refetchSourcesData: PropTypes.func,
  }

  static defaultProps = {
    dataGroupsResult: AsyncResult.pending([]),
    dataSourcesResult: AsyncResult.pending([]),
    refetchGroupsData: noop,
    refetchSourcesData: noop,
  }

  render() {
    const {
      t,
      permissions,
      allDataSourcesResult,
      resultsPerPage,
      setPageNumber,
      setResultsPerPage,
    } = this.props

    // Does the user have permissions for this page?
    if (!permissions.includes(CAN_VIEW_ANALYSIS)) {
      return <Redirect to="/page-not-found" />
    }

    return (
          <>
            <Row className={`
              ${styles['results-row']}
              ${styles['search-results-text']}
              ${styles['data-sources-margins']}
            `}>
              <p>
                {allDataSourcesResult.isPending()
                  ? t('text.results_pending')
                  : getResultString(t, allDataSourcesResult, resultsPerPage)}
              </p>
            </Row>

            <AsyncList
              name="data-sources-list"
              result={allDataSourcesResult}
              className={`${styles['data-sources-list']} ${styles['data-sources-margins']}`}
              renderItem={this.renderDataSource}
              noResultText={t('text.no_data_sources_found')}
              onRequestRetry={this.refetchData}
            />

            {
              allDataSourcesResult.wasSuccessful() ?
                <PaginationToolbar
                  visiblePageOptionCount={VISIBLE_ALL_SOURCES_PAGES}
                  pageNumber={allDataSourcesResult.data.pageNumber}
                  totalPages={allDataSourcesResult.data.totalPages}
                  totalResults={allDataSourcesResult.data.totalResults}
                  resultsPerPage={resultsPerPage}
                  setPageNumber={setPageNumber}
                  className={styles['data-sources-margins']}
                  setResultsPerPage={setResultsPerPage} />
                :
                null
            }
          </>
    )
  }

  renderDataSource = (source) => {
    const {
      addToSelected,
      removeFromSelected,
      selectedDataSources,
      isLimitReached,
    } = this.props
    const selected =
      typeof selectedDataSources.find((a) => {
        return source.id === a.id && source.__typename === a.__typename
      }) !== 'undefined'

    return (
      <AnalysisListItem
        key={source.uuid}
        dataSource={source}
        selected={selected}
        addToSelected={addToSelected}
        removeFromSelected={removeFromSelected}
        isLimitReached={isLimitReached}
      />
    )
  }

  refetchData = () => {
    this.props.refetchAllDataSources()
  }
}

const createMapStateToProps = () => {
  const selectParsedSearchParams = createSelector(
    [(_state, { location }) => location.search],
    (search) => {
      return parseSearchParams(search)
    }
  )

  const selectSearchQuery = (state, props) =>
    selectParsedSearchParams(state, props).q

  const selectDataSourceType = (state, props) =>
    selectParsedSearchParams(state, props).t

  const selectAllDataSourcesListResult = createSelectGraphQLResult(
    'allDataSourcesList',
    {
      mapResult: parseGraphQLResult,
      nullObject: [],
    }
  )

  return function mapStateToProps(state, ownProps) {
    return {
      searchQuery: selectSearchQuery(state, ownProps),
      dataSourceType: selectDataSourceType(state, ownProps),
      selectAllDataSourcesListResult,
    }
  }
}

const AllDataSourcesQuery = gql`
  query ListDataSources(
    $pageNumber: Int!
    $resultsPerPage: Int!
    $dataSourceType: AllDataSourcesListType
    $searchQuery: String
  ) {
    allDataSourcesList(
      pageNumber: $pageNumber
      resultsPerPage: $resultsPerPage
      dataSourceType: $dataSourceType
      searchQuery: $searchQuery
    ) {
      allDataSources {
        ... on Device {
          id
          serialNumber
          commissionStatus
          __typename
        }
        ... on NetworkAsset {
          id
          assetId
          assetName
          assetType
          __typename
        }
        ... on MixedGroupDetailsType {
          id
          name
          members {
            type
          }
          __typename
        }
      }
      pagination {
        perPage
        pageIndex
        totalPages
        totalResults
        pageNumber
      }
    }
  }
`

export default compose(
  requiresLogin,
  userPermissions,
  withTranslation(['src/analysis_path/index_page', 'common/text']),
  transformProps(() => ({ t }) => ({
    title: t('headings.list_page_title'),
  })),
  getWithPagination({
    pageNumber: SEED_ALL_SOURCES_PAGE_NUMBER,
    resultsPerPage: SEED_ALL_SOURCES_RESULTS_PER_PAGE,
  }),
  withDataSourceSelectionLayout,
  connect(createMapStateToProps),
  graphql(AllDataSourcesQuery, {
    options: ({ searchQuery, pageNumber, resultsPerPage, dataSourceType }) => {
      return {
        fetchPolicy: 'network-only',
        variables: {
          searchQuery,
          pageNumber,
          resultsPerPage,
          dataSourceType,
        },
      }
    },
    props: ({ data, ownProps }) => {
      const { refetch } = data
      const { searchQuery, selectAllDataSourcesListResult, dataSourceType } =
        ownProps

      return {
        refetchAllDataSources: () => {
          refetch({
            searchQuery: searchQuery,
            dataSourceType: dataSourceType,
          })
        },
        allDataSourcesResult: selectAllDataSourcesListResult(data),
      }
    },
  })
)(AnalysisList)
