import {
  PRESSURE_TYPE,
  TEMPERATURE_TYPE,
  DISTANCE_TYPE,
} from '@@src/utils/app_unit_conversion'

export const CELSIUS = 'celsius'
export const FAHRENHEIT = 'fahrenheit'
export const METER = 'meter'
export const INCH = 'inch'
export const FOOT = 'foot'
export const MH2O = 'mh2o'
export const PSI = 'psi'
export const KPA = 'kpa'
export const BAR = 'bar'

export const INTERNAL_TEMPERATURE_UNIT = CELSIUS
export const INTERNAL_DISTANCE_UNIT = METER
export const INTERNAL_PRESSURE_UNIT = MH2O

export const INTERNAL_UNITS = {
  [PRESSURE_TYPE]: INTERNAL_PRESSURE_UNIT,
  [TEMPERATURE_TYPE]: INTERNAL_TEMPERATURE_UNIT,
  [DISTANCE_TYPE]: INTERNAL_DISTANCE_UNIT,
}

export const AVAILABLE_TEMPERATURE_UNITS = [
  CELSIUS,
  FAHRENHEIT,
]
export const AVAILABLE_DISTANCE_UNITS = [
  METER,
  INCH,
  FOOT,
]
export const AVAILABLE_PRESSURE_UNITS = [
  MH2O,
  PSI,
  KPA,
  BAR,
]
