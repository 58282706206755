import React from 'react'
import { connect } from 'react-redux'

import TooltipStore from './store'

class Instance extends React.PureComponent {
  render() {
    const { instanceRef, instance } = this.props

    return !instance ? null : React.cloneElement(instance, {
      triggerBoundingRect: instanceRef ?
        instanceRef.getBoundingClientRect() :
        undefined,
    })
  }
}

function mapStateToProps(state) {
  return {
    instance: TooltipStore.selectInstance(state),
    instanceRef: TooltipStore.selectInstanceRef(state),
  }
}

export default connect(mapStateToProps)(Instance)
