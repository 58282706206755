import { DeviceSingleInfo } from './models/device_single_info'
import { DevicesForListItem } from './models/devices_for_list_item'
import { DeviceForMapItem } from './models/devices_for_map_item'
import { Pagination } from './models/pagination'
import { GET_DEVICES_FOR_LIST_QUERY, GET_SINGLE_DEVICE_INFO, GET_DEVICES_FOR_MAP_QUERY } from './queries/device'

/**
 * @param {object} client - graphql apollo client
 * @param {AbortController} abortController - abort controller
 * @param {number} networkAssetId - network asset id
 * @returns {Promise<{networkAsset: NetworkAssetForListItem}>} network asset
 */
export const getSingleDeviceInfo = ({ client, abortController, deviceId }) =>
  client
    .query({
      query: GET_SINGLE_DEVICE_INFO,
      variables: {
        deviceId,
        time: Date.now(),
      },
      fetchPolicy: 'network-only',
      context: { fetchOptions: { signal: abortController.signal } },
    })
    .then(result => {
      const { device } = result && result.data && result.data.getDevicesListItem || {}

      return {
        device: device ? new DeviceSingleInfo(device) : null,
      }
    })

/**
 * @param {object} client - graphql apollo client
 * @param {AbortController} abortController - abort controller
 * @param {object} filters - filters
 * @param {string} filters.search - filters search
 * @param {GeoBounds} filters.inBounds - filters bounding box
 * @param {boolean} filters.hasActiveError - filters device has active error
 * @param {boolean} filters.hasActiveInformation - filters device has active information
 * @param {boolean} filters.hasActiveWarning - filters device has active wornings
 * @param {boolean} filters.isCommissioned - filters device is commissioned
 * @param {boolean} filters.isInstalled - filters device is installed
 * @param {boolean} filters.issueTypeIn - filters device is installed
 * @param {object} order - order
 * @param {string} order.by - order by
 * @param {string} order.direction - order direction
 * @param {object} pagination pagination model
 * @param {number|undefined} pagination.pageNumber page number
 * @param {number|undefined} pagination.resultsPerPage page limit
 * @returns {Promise<{devices:DevicesForListItem[],pagination:Pagination}>} promise
 */
export const getDevicesForListCall = ({ client, abortController, filters, order, pagination }) =>
  client
    .query({
      query: GET_DEVICES_FOR_LIST_QUERY,
      variables: {
        pageNumber: pagination.pageNumber,
        resultsPerPage: pagination.resultsPerPage,
        orderBy: order.by,
        orderDirection: order.direction,
        filters,
        time: Date.now(),
      },
      fetchPolicy: 'network-only',
      context: { fetchOptions: { signal: abortController.signal } },
    })
    .then(response => {
      const result = response && response.data && response.data.getDevicesList || {}
      const { devices, pagination: paginationResponse } = result
      return {
        devices: Array.isArray(devices)
          ? devices.map(item => new DevicesForListItem(item))
          : [],
        pagination: paginationResponse ? Pagination.fromResponse(paginationResponse) : null,
      }
    })

/**
 * @param {object} client - graphql apollo client
 * @param {AbortController} abortController - abort controller
 * @param {object} filters - filters
 * @param {string} filters.search - filters search
 * @param {string[]} filters.assetTypeIn - filters asset type in
 * @param {string[]} filters.issueTypeIn - filters issue type in
 * @param {string[]} filters.installations - filters  installations
 * @param {string[]} filters.issueSeverityIn - filters issue severity in
 * @returns {Promise<{networkAssets:NetworkAssetForMapItem[],meta}>} promise
 */
export const getDevicesForMapCall = ({ client, abortController, filters }) =>
  client
    .query({
      query: GET_DEVICES_FOR_MAP_QUERY,
      variables: { filters, time: Date.now() },
      fetchPolicy: 'network-only',
      context: { fetchOptions: { signal: abortController.signal } },
    })
    .then(result => {
      const { devices, meta } = result && result.data && result.data.getDevicesList || {}
      return {
        meta,
        devices: Array.isArray(devices)
          ? devices.map(item => new DeviceForMapItem(item))
          : [],
      }
    })

