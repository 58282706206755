import React from 'react'
import withGoogleMapsProvider from '@@src/components/maps/google_maps_provider'
import StandardMap from '@@src/components/maps/standard_map'
import MarkerClusterGroup from 'react-leaflet-markercluster'
import NetworkAssetPopup
from '@@src/components/maps/markers/network_asset_popup'
import NetworkAssetMarker
from '@@src/components/maps/markers/network_asset_marker'

function NetworkAssetMap({
  googleMaps,
  onClick,
  networkAssets,
  selectedAggregateId,
  mapCenter,
  mapZoom,
  mapBounds,
}) {

  return(
    <StandardMap
      zoom={mapZoom}
      bounds={mapBounds}
      center={mapCenter}
      className="h-100 pr-0">
      <MarkerClusterGroup
        showCoverageOnHover={false}
        removeOutsideVisibleBounds={true}>
        {
          networkAssets.map(networkAsset => (
            <NetworkAssetMarker
              key={networkAsset.uuid}
              onClick={onClick(networkAsset)}
              isPopupOpen={networkAsset.id === selectedAggregateId}
              networkAsset={networkAsset}>
              <NetworkAssetPopup
                googleMaps={googleMaps}
                networkAsset={networkAsset} />
            </NetworkAssetMarker>
          ))
        }
      </MarkerClusterGroup>
    </StandardMap>
  )
}

export default withGoogleMapsProvider(NetworkAssetMap)
