import * as d3 from 'd3-shape'
import { select } from 'd3-selection'

function checkIfZeroDevice(first, second) {
  return first && second ? first / second : 0
}

export function renderSummaryGraph(
  data,
  colors = ['#64b900', '#ffc900', '#dc3545'],
  title,
  subtitle,
  parentClassName,
  isMobileDevice = false
) {
  // Data
  const text = Math.round((data[1] / data[0]) * 100) + '%'
  const percentages = [
    checkIfZeroDevice(data[1], data[0]),
    checkIfZeroDevice(data[2], data[0]),
    checkIfZeroDevice(data[3], data[0]),
  ]
  // Settings
  const width = isMobileDevice ? 156 : 220
  const height = isMobileDevice ? 140 : 200
  const anglesRange = 0.65 * Math.PI
  const radis = Math.min(width, 2 * height) / 2
  const thickness = 12

  const pies = d3
    .pie()
    .value((d) => d)
    .sort(null)
    .startAngle(anglesRange * -1)
    .endAngle(anglesRange)

  const arc = d3
    .arc()
    .outerRadius(radis)
    .innerRadius(radis - thickness)

  const translation = (x, y) => `translate(${x}, ${y / 2 + 10})`

  select(parentClassName).selectAll('*').remove()

  const svg = select(parentClassName)
    .append('svg')
    .attr('width', width)
    .attr('height', height)
    .attr('class', 'half-donut')
    .attr('name', 'summary-half-donut')
    .append('g')
    .attr('transform', translation(width / 2, height))

  svg
    .selectAll('path')
    .data(pies(percentages))
    .enter()
    .append('path')
    .attr('fill', (_, i) => colors[i])
    .attr('d', arc)

  svg
    .append('text')
    .text(() => text)
    .attr('dy', '-0.5em')
    .attr('name', 'summary-graph-text')
    .attr(
      'class',
      isMobileDevice ? 'h4 font-weight-bold' : 'h2 font-weight-bold'
    )
    .attr('text-anchor', 'middle')

  svg
    .append('text')
    .text(() => title)
    .attr('name', 'summary-graph-title')
    .attr('dy', '1.1em')
    .attr('class', 'label font-weight-bold')
    .attr('text-anchor', 'middle')

  svg
    .append('text')
    .text(() => subtitle)
    .attr('name', 'summary-graph-subtitle')
    .attr('dy', '2.4em')
    .attr('class', 'label')
    .attr('text-anchor', 'middle')
}
