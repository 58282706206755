import { useCallback, useEffect, useMemo, useState } from 'react'
import { MAX_RESOLUTION_PARTS, MAX_ALERTS_PER_PAGE } from './consts'
import { PAGED_ALERTS_QUERY } from '../../analysis_path/pressure_analysis_path/pressure_subset_graph/graphql'
import { parseGraphQLResult } from '@@src/api/presenters'
import { AsyncResult } from '../../utils'

const useGetAlertsData = (props) => {
  const [alertsData, setAlertsData] = useState(undefined)
  const [loading, setLoading] = useState(0)
  const [totalParts, setTotalParts] = useState(0)
  const [error, setError] = useState('')

  const alertsDataResult = useMemo(() => {
    if (alertsData === null || props.skip === true) {
      return AsyncResult.success({
        data: [],
      })
    }

    if (alertsData === undefined) {
      return AsyncResult.pending({
        data: [],
      })
    }

    if (loading !== 0) {
      return AsyncResult.pending({
        ...parseGraphQLResult(alertsData),
        pagination: {
          totalResults: alertsData.alerts.length,
          totalPages: totalParts,
          page: totalParts - loading,
          perPage: MAX_ALERTS_PER_PAGE,
        },
      })
    }

    return AsyncResult.success({
      ...parseGraphQLResult(alertsData),
      pagination: {
        totalResults: alertsData.alerts.length,
        totalPages: totalParts,
        page: totalParts - loading,
        perPage: MAX_ALERTS_PER_PAGE,
      },
    })
  }, [alertsData, loading, totalParts])

  const formatResults = useCallback((result) => {
    setAlertsData((oldAlerts) => {
      if (oldAlerts === undefined) {
        return {
          ...result.data.pagedAlerts,
        }
      }
      return {
        ...oldAlerts,
        alerts: [...oldAlerts.alerts, ...result.data.pagedAlerts.alerts],
      }
    })
  }, [setAlertsData])

  const fetchData = useCallback(
    async (
      client,
      numberOfPages = 1,
      currentPage = 1,
      start,
      end,
      resolution,
      networkAssetSourceIds,
    ) => {
      // create query list
      const queryList = []
      for (let i = currentPage; i <= numberOfPages; i++) {
        queryList.push(
          client.query(
            {
              query: PAGED_ALERTS_QUERY,
              variables: {
                networkAssetSourceIds,
                skip: false,
                resolution,
                dateFrom: new Date(start).toISOString(),
                dateTo: new Date(end).toISOString(),
                pageNumber: i,
                resultsPerPage: MAX_ALERTS_PER_PAGE,
              },
              context: {
                fetchOptions: {
                  signal: props.signal,
                },
              },
            }
          )
        )
      }

      setLoading((oldLoading) => oldLoading + queryList.length)
      setTotalParts((oldTotalParts) => oldTotalParts + queryList.length)

      let results = []
      // fetching multiple queries concurently
      try {
        results = await Promise.all(queryList)
      } catch (err) {
        setError(err)
      }

      results.forEach(result => formatResults(result))
      setLoading((loadingOld) => loadingOld - queryList.length)

      if (currentPage === 1 && results[0].data.pagedAlerts.pagination.totalPages > 1) {
        fetchData(
          client,
          results[0].data.pagedAlerts.pagination.totalPages,
          2,
          start,
          end,
          resolution,
          networkAssetSourceIds,
        )
      }
    }, [formatResults])

  const fetchPartialData = useCallback(() => {
    const { start, end, resolution, client, networkAssetChannels } = props

    let queryStart = Math.max(end - (MAX_RESOLUTION_PARTS * resolution * 1000), start)
    let queryEnd = end
    let maxLoops = 10000
    while (maxLoops > 0) {
      maxLoops--
      fetchData(
        client,
        1,
        1,
        queryStart,
        queryEnd,
        resolution,
        networkAssetChannels.map(channel => channel.networkAssetId),
      )
      if (queryStart === start) {
        break
      }
      queryEnd = queryStart
      queryStart = Math.max(queryEnd - (MAX_RESOLUTION_PARTS * resolution * 1000), start)
    }
  }, [props.start, props.end, props.resolution, props.client, props.networkAssetChannels])

  useEffect(() => {
    if (Object.keys(props).length) {
      fetchPartialData()
    }
    return () => {
      setLoading(0)
      setTotalParts(0)
      setAlertsData(undefined)
    }
  }, [props.start, props.end, props.resolution, props.client, props.networkAssetChannels])

  return { alertsResult: alertsDataResult, error, loading: loading !== 0 }
}

export default useGetAlertsData
