import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { STANDARD_FILTER_OPTIONS, isFilterAdvanced } from '../../utils/filters'
import DevicesListAdvancedFilterModal from '../devices_advanced_filter_modal/devices_advanced_filter_modal.component'
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Button,
} from 'reactstrap'
import classnames from 'classnames'
import PropTypes from 'prop-types'

const DevicesListFiltersDropdown = ({ currentFilters, onFilterChange }) => {
  const { t } = useTranslation([
    'v2/devices/filters',
    'common/text',
    'v2/common/buttons',
    'src/management_path/devices_path/index_page',
  ])

  const [dropdownOpen, setDropdownOpen] = useState(false)
  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState)

  const [dropdownPopupOpen, setDropdownPopupOpen] = useState(false)
  const toggleDropdownPopup = () => {
    setDropdownPopupOpen((prevState) => !prevState)
    setDropdownOpen(false)
  }

  const hasAdvancedFiltersSelected = false

  const onChangeBasicFilter = (filter) => {
    onFilterChange([filter])
  }

  const handleChangeAdvancedFilter = (filters) => {
    const onlyAdvancedFilters = filters.filter(isFilterAdvanced)
    onFilterChange(onlyAdvancedFilters)
  }

  const onClearFilter = () => {
    onFilterChange(['any'])
    toggleDropdownPopup()
  }

  const selectedBasicFilter = useMemo(() => {
    return currentFilters.length === 1
      && STANDARD_FILTER_OPTIONS.find(filter => filter === currentFilters[0])
  }, [currentFilters])

  return(
    <>
      <DevicesListAdvancedFilterModal
        isOpen={dropdownPopupOpen}
        activeFilterList={currentFilters}
        toggle={toggleDropdownPopup}
        handleAccept={handleChangeAdvancedFilter}
        clearFilters={onClearFilter}
      />
      <ButtonDropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
        <DropdownToggle caret className={'dropdown-filter-button'}>
          {t('v2/common/buttons:filter')}&nbsp;
        </DropdownToggle>
        <DropdownMenu className="dropdown-scroll-menu">
          {
            STANDARD_FILTER_OPTIONS.map((filter, i) => {
              return (
                <DropdownItem
                  key={i}
                  value={filter}
                  onClick={() => onChangeBasicFilter(filter)}>
                  <div className="d-flex align-items-center justify-content-between">
                    <div
                      className={classnames({
                        'font-weight-bold': selectedBasicFilter && selectedBasicFilter === filter,
                      })}>
                      {t(`v2/devices/filters:filter_options.${filter}`)}
                    </div>
                    { selectedBasicFilter && selectedBasicFilter === filter &&
                        <i name="check-icon" className={'fa fa-check font-weight-normal pl-1'} /> }
                  </div>
                </DropdownItem>)
            })
          }
          <Button
            name="advanced-filter-modal-btn"
            type="button"
            className="mt-2 d-flex justify-content-between align-items-center"
            size="sm"
            block={true}
            color="link"
            onClick={toggleDropdownPopup}>
            <span />
            <span className={classnames({
              'font-weight-bold': hasAdvancedFiltersSelected,
            })}>
              {t('v2/common/buttons:advanced')}
            </span>
            <span name="check-icon" className={classnames({
              'fa fa-check': hasAdvancedFiltersSelected,
            })} />
          </Button>
        </DropdownMenu>
      </ButtonDropdown>
    </>
  )
}

DevicesListFiltersDropdown.propTypes = {
  currentFilters: PropTypes.arrayOf(PropTypes.string).isRequired,
  onFilterChange: PropTypes.func.isRequired,
}

export default DevicesListFiltersDropdown
