import { omit } from 'lodash/fp/object'
import { isEqual } from 'lodash/fp/lang'

import incrementalRequestsLoaderContainer, {
  incrementPageNumber,
} from './incremental_requests_loader_container'

const withoutPageNumber = omit('pageNumber')

export default function incrementalPaginatedRequestContainer(query, config) {
  return incrementalRequestsLoaderContainer({
    ...config,
    resultName: 'result',
    graphQLQuery: query,
    shouldResetData: config.shouldResetData || defaultShouldResetData,
    shouldContinueFetch: () => false,
    computeNextVariables: incrementPageNumber,
    finalResultNullObject: config.nullObject,
    computeInitialVariables: (_props, variables) => variables,
    componentProps: ({ result, ...rest }, { refetch }) => {
      const { pagination = {} } = result.data

      return {
        ...rest,
        ...config.resultFunc({
          result,
          refetch,
          fetchNext: pagination.pageNumber < pagination.totalPages ?
            () => refetch({ pageNumber: pagination.pageNumber + 1 }) : null,
        }),
      }
    },
  })
}

function defaultShouldResetData(vars, prevVars) {
  return !isEqual(withoutPageNumber(vars), withoutPageNumber(prevVars))
}
