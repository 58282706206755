import L from 'leaflet'

export const DEFAULT_TILE_LAYER_URL = 'https://api.mapbox.com/styles/v1/inflowsys/ckfnllcew0tvn19pc3j15ij9e/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiaW5mbG93c3lzIiwiYSI6ImNqNWF0Z3l0azJwZzIyeWx5ZWtyemt3anMifQ.VfHYd0MuvkqSZNCE0iiD_Q' // eslint-disable-line max-len
export const GREYSCALE_TILE_LAYER_URL = 'https://api.mapbox.com/styles/v1/inflowsys/cjxwzx6yg2lc71cnic7hofupt/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiaW5mbG93c3lzIiwiYSI6ImNqNWF0Z3l0azJwZzIyeWx5ZWtyemt3anMifQ.VfHYd0MuvkqSZNCE0iiD_Q' // eslint-disable-line max-len

export const DEFAULT_MAP_ZOOM = 15
export const DEFAULT_MAP_CENTER = L.latLng(50.9614246, -1.4231234)
export const DEFAULT_MAP_BOUNDS = [
  [50.505, -29.09],
  [52.505, 29.09],
]
export const MAP_LOAD_GRACE_PERIOD = 2000

export const MAP_TILES = 'map'
export const SATELLITE_TILES = 'satellite'
export const TILE_TYPES = [MAP_TILES, SATELLITE_TILES]

export function mapDistanceToTime(centerOne, centerTwo) {
  let finalDuration = 0
  try {
    const duration = centerOne ?
      ((Math.abs(centerOne.lat - centerTwo.lat)) / 180 +
      (Math.abs(centerTwo.lng - centerOne.lng)) / 90)
      : 0
    finalDuration = duration < 0.1 ? 0.1 : duration > 2.0 ? 1.99 : duration
  } catch {
    finalDuration = 0.1
  }
  return finalDuration
}
