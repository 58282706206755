import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { withTranslation } from 'react-i18next'

import LegendItem from '@@src/components/graphs/legend_item'
import NetworkAssetIcon from '@@src/components/icons/network_asset_icon'

import styles from './raw_data_network_asset_item.css'

function RawDataNetworkAssetItem(props) {

  const { networkAssetLegendItem, t, minutesLeft } = props

  return (
    <li
      name="raw-data-network-asset-item"
      className={classnames(
        styles['asset-item'],
        { [styles['asset-item-disabled']]:
          props.showCongruityIsDisabled }
      )}>
      <NetworkAssetIcon
        networkAsset={networkAssetLegendItem.source}
        className={styles['asset-item-icon']} />
      <div className={styles['asset-item-content']}>
        <div className={styles['asset-item-name']}>
          {networkAssetLegendItem.source.assetId}
        </div>
        <div className={styles['asset-item-type']}>
          {t(`common/text:text.${networkAssetLegendItem.source.assetType}`)}
          {
            networkAssetLegendItem.isFromNetworkAssetChannel() ?
              ' - ' + t(`common/text:text.asset_channel_${networkAssetLegendItem.sourceChannel}`) // eslint-disable-line max-len
              :
              null
          }
        </div>
        <small>
          <ol
            name="network-asset-daily-raw-data-quotas"
            className={styles['asset-item-list-details']}>
            { // Minutes left for raw data requests for device
              minutesLeft.map(({ date, minutes }) => {
                const minutesDate = new Date(0, 0)

                minutesDate.setSeconds(minutes * 60)

                return (
                  <li key={date}>
                    {t('text.request_time_remaining', {
                      date,
                      minutes: String(minutesDate.getMinutes())
                        .padStart(2, '0'),
                      seconds: String(minutesDate.getSeconds())
                        .padStart(2, '0'),
                    })}
                  </li>
                )
              })}
            {
              // Congruity Enabled
              props.showCongruityIsDisabled ?
                <li name="congruity-not-enabled">
                  {t('text.congruity_not_enabled')}
                </li>
                : null
            }
          </ol>
        </small>
      </div>
    </li>
  )
}

RawDataNetworkAssetItem.propTypes = {
  networkAssetLegendItem: PropTypes.instanceOf(LegendItem).isRequired,
  t: PropTypes.func.isRequired,
  minutesLeft: PropTypes.arrayOf(PropTypes.shape({
    date: PropTypes.string.isRequired,
    minutes: PropTypes.number.isRequired,
  })).isRequired,
  // to enable displaying of congruity info, if we DO NOT have congruity info and wish to display that information, this should be true.
  showcongruityisdisabled: PropTypes.bool,
}

RawDataNetworkAssetItem.defaultProps = {
  minutesLeft: [],
  showCongruityInfo: false,
}

export default withTranslation([
  'src/components/graphs/modals/raw_data_network_asset_item',
  'common/text',
])(RawDataNetworkAssetItem)
