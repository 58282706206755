import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'

import requiresLogin from '@@src/components/security/requires_login'
import { CAN_EXPORT, CAN_VIEW_DEVICES } from '../../_v2/contexts/user/consts/permissions'
import { withUser } from '../../_v2/contexts/user/user.context'
import { AuthorizedUser } from '../../_v2/models/authorized-user'

import AppLayout from '../../components/app_layout'
import styles from './index_page.css'
import { Redirect, withRouter } from 'react-router-dom'
import { Button, Table } from 'reactstrap'
import DevicesViewSwitcher from './devices_view_switcher/devices_view_switcher'
import PaginationToolbar from '../../components/pagination/pagination_toolbar'
import getWithPagination from '../../components/pagination/pagination_container'
import {
  AsyncResult, createSelectGraphQLResult, mergeSearchParams,
} from '../../utils'
import AsyncTable from '../../components/lists/async_table'
import { graphql } from '@apollo/client/react/hoc'
import { connect } from 'react-redux'
import { PagedDevicesTableQuery } from './graphql/devices_table_graphql'
import { ExportDevicesHealthCSV } from './graphql/devices_table_report_graphql'
import FiltersPanel, { FiltersQueryParams } from './filters_panel'
import defineQueryParams from '../../components/define_query_params'
import { push } from 'connected-react-router'
import routes from '../../routes'
import { DeviceTableHeader } from './device_table_header'
import ExportModal from '../../components/modals/export_modal/export_modal'
import ManagementDevicesTableItem from './devices_table_item'
import * as Changes from '../../store/changes'
import { mapDevicesHealthPropsToFilterObject } from '../../utils/mappers/devices-health-filters-mapper'
import Pagination from '../../api/presenters/pagination'
import PagedData from '../../api/presenters/paged_data'

export const SEED_DEVICES_PAGE_NUMBER = 1
export const SEED_DEVICES_RESULTS_PER_PAGE = 25
export const VISIBLE_DEVICE_PAGES = 3

const ORDER_BY_ALIAS = 'order_by'
const ORDER_DIR_ALIAS = 'orders_dir'
const DEFAULT_ORDER_BY = 'serial_number'
const DEFAULT_ORDER_DIR = 'asc'

function ManagementDevicesTablePath(props) {
  const {
    t,
    location,
    authorizedUser,
    setPageNumber,
    resultsPerPage,
    setResultsPerPage,
    getDevicesHealthResult,
    dispatchUpdateSortQuery,
    dispatchNotifyDownloadsChanged,
    requestDevicesHealthReport,
  } = props

  if (!authorizedUser.can(CAN_VIEW_DEVICES)) {
    return <Redirect to="/page-not-found" />
  }

  const initialOrderBy = props.orderBy || DEFAULT_ORDER_BY
  const initialOrderDirection = props.orderDirection || DEFAULT_ORDER_DIR
  const [currentOrderBy, setOrderBy] = useState(initialOrderBy)
  const [currentOrderDir, setOrderDir] = useState(initialOrderDirection)
  const [isExportModalOpened, setIsExportModalOpened] = useState(false)

  const onUpdateOrderParams = (orderBy, orderDir) => {
    setOrderBy(orderBy)
    setOrderDir(orderDir)
    dispatchUpdateSortQuery(location, {
      [ORDER_BY_ALIAS]: orderBy !== DEFAULT_ORDER_BY ? orderBy : undefined,
      [ORDER_DIR_ALIAS]: orderDir !== DEFAULT_ORDER_DIR ? orderDir : undefined,
    })
  }
  const orderParams = {
    orderBy: currentOrderBy, orderDir: currentOrderDir, onUpdate: onUpdateOrderParams,
  }

  return (
    <AppLayout title={t('headings.devices')}>
      <ExportModal
        title={t('src/management_path/devices_path/export_devices_config_modal:buttons.export')}
        isOpen={isExportModalOpened}
        onCancel={() => setIsExportModalOpened(false)}
        onSuccess={() => setTimeout(() => {
          setIsExportModalOpened(false)
          dispatchNotifyDownloadsChanged()
        }, 500)}
        requestExport={() => requestDevicesHealthReport(mapDevicesHealthPropsToFilterObject(props))}
      >
        {t('src/management_path/devices_path/export_devices_config_modal:text.devices_count', {
          count: getDevicesHealthResult.wasSuccessful() && getDevicesHealthResult.data.totalResults || 0,
        })}
      </ExportModal>

      <div className="d-flex w-100">
        <div className={`${styles['devices-table']} p-5`}>
          <h1 className={'mb-4'}>
            {t('headings.devices')}
            <DevicesViewSwitcher location={location} />
            {authorizedUser.can(CAN_EXPORT) && (
              <Button
                color="primary"
                className="float-right"
                disabled={!getDevicesHealthResult.wasSuccessful() || !getDevicesHealthResult.data.totalResults}
                onClick={() => setIsExportModalOpened(true)}
              >
                <span className="mr-2 fa fa-file-download" />
                {t('src/management_path/devices_path/export_devices_config_modal:buttons.export')}
              </Button>
            )}
          </h1>
          <p>
            {getDevicesHealthResult.wasSuccessful() ?
              (t('src/management_path/devices_path/index_page:text.results_count_plural', {
                count: getDevicesHealthResult.data.totalResults,
              })) : null}
          </p>
          <div className={styles['devices-table-overflow']}>
            <Table className={'table-sm bg-white'}>
              <thead className={styles['table-header']}>
                <tr>
                  <th className={styles['column-40']}>&nbsp;</th>
                  <DeviceTableHeader width="200" text={t('table.header.serial_number')}
                    order={{ ...orderParams, field: 'serialNumber' }} />
                  <DeviceTableHeader width="200" text={t('table.header.network_asset')}
                    order={{ ...orderParams, field: 'networkAssetReadableId' }} />
                  <DeviceTableHeader width="200" text={t('table.header.network_asset_name')}
                    order={{ ...orderParams, field: 'networkAssetReadableName' }} />
                  <DeviceTableHeader width="200" text={t('table.header.parent_groups')} />
                  <DeviceTableHeader width="160" text={t('table.header.first_commissioned')}
                    order={{ ...orderParams, field: 'firstCommissioned' }} />
                  <DeviceTableHeader width="160" text={t('table.header.last_contact')}
                    order={{ ...orderParams, field: 'lastCommunicationTime' }} />
                  <DeviceTableHeader width="90" align="center" text={t('table.header.active_alerts')}
                    order={{ ...orderParams, field: 'hasActiveAlerts' }} />
                  <DeviceTableHeader width="90" align="center" text={t('table.header.data_gaps')}
                    order={{ ...orderParams, field: 'hasDataGaps' }} />
                  <DeviceTableHeader width="90" align="right" text={t('table.header.signal_strength')}
                    order={{ ...orderParams, field: 'signal' }} />
                  <DeviceTableHeader width="90" align="right" text={t('table.header.signal_rating')}
                    order={{ ...orderParams, field: 'signal' }} />
                  <DeviceTableHeader width="90" align="right" text={t('table.header.battery_voltage')}
                    order={{ ...orderParams, field: 'battery1' }} />
                  <DeviceTableHeader width="160" align="right" text={t('table.header.battery_health')}
                    order={{ ...orderParams, field: 'hasBatteryIssues' }} />
                </tr>
              </thead>
              <tbody>
                <AsyncTable
                  columns={13}
                  result={getDevicesHealthResult}
                  renderNotFoundResult={() => (
                    <tr>
                      <td className={'justify-content-center'}>
                        {'No results found'}
                      </td>
                    </tr>
                  )}
                  renderItem={(deviceHealthRow, index) =>
                    (
                      <ManagementDevicesTableItem
                        key={`key_${deviceHealthRow.serialNumber}_${index}`}
                        deviceHealthRow={deviceHealthRow} />
                    )} />
              </tbody>
            </Table>
          </div>
          <PaginationToolbar
            className={'mt-4'}
            visiblePageOptionCount={VISIBLE_DEVICE_PAGES}
            pageNumber={getDevicesHealthResult.data.pageNumber}
            totalPages={getDevicesHealthResult.data.totalPages}
            totalResults={getDevicesHealthResult.data.totalResults || 0}
            resultsPerPage={resultsPerPage}
            setPageNumber={setPageNumber}
            setResultsPerPage={setResultsPerPage} />
        </div>
        <FiltersPanel />
      </div>
    </AppLayout>
  )
}

ManagementDevicesTablePath.propTypes = {
  t: PropTypes.func.isRequired,
  dispatchUpdateSortQuery: PropTypes.func.isRequired,
  dispatchNotifyDownloadsChanged: PropTypes.func.isRequired,
  authorizedUser: PropTypes.instanceOf(AuthorizedUser).isRequired,
  location: PropTypes.object.isRequired,
  setPageNumber: PropTypes.func.isRequired,
  setResultsPerPage: PropTypes.func.isRequired,
  resultsPerPage: PropTypes.number.isRequired,
  getDevicesHealthResult: PropTypes.instanceOf(AsyncResult).isRequired,
  orderBy: PropTypes.string,
  orderDirection: PropTypes.string,
}

ManagementDevicesTablePath.defaultProps = {
  permissions: [],
  getDevicesHealthResult: AsyncResult.pending([]),
}

function createMapStateToProps() {
  const selectGetDevicesHealthResult = createSelectGraphQLResult('getDevicesHealth', {
    mapResult: (data) => new PagedData({
      data: data.devices || [],
      pagination: new Pagination(data.pagination),
    }),
    nullObject: [],
  })

  return function mapStateToProps() {
    return { selectGetDevicesHealthResult }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchUpdateSortQuery(location, sort) {
      dispatch(push({
        search: mergeSearchParams(location.search, sort),
        pathname: routes.managementDevicesTablePath(),
      }))
    },
    dispatchNotifyDownloadsChanged() {
      dispatch(Changes.notifyChange(Changes.CSV_DOWNLOAD_CHANGES))
    },
  }
}

export default compose(
  requiresLogin,
  withUser,
  withRouter,
  connect(createMapStateToProps, mapDispatchToProps),
  withTranslation([
    'src/management_path/devices_table_path/index_page',
    'src/management_path/devices_path/index_page',
    'src/management_path/devices_path/export_devices_config_modal',
    'common/text',
  ]),
  defineQueryParams({
    ...FiltersQueryParams,
    orderBy: { alias: ORDER_BY_ALIAS },
    orderDirection: { alias: ORDER_DIR_ALIAS },
  }),
  getWithPagination({
    pageNumber: SEED_DEVICES_PAGE_NUMBER,
    resultsPerPage: SEED_DEVICES_RESULTS_PER_PAGE,
  }),
  graphql(PagedDevicesTableQuery, {
    options: ({ orderBy, orderDirection, pageNumber, resultsPerPage, ...props }) => ({
      fetchPolicy: 'network-only',
      variables: {
        pageNumber,
        resultsPerPage,
        orderBy: orderBy || DEFAULT_ORDER_BY,
        orderDirection: orderDirection || DEFAULT_ORDER_DIR, ...mapDevicesHealthPropsToFilterObject(props),
      },
    }),
    props: ({ data, ownProps: { selectGetDevicesHealthResult } }) => ({
      refetchPagedDevices: (...args) => data.refetch(...args),
      getDevicesHealthResult: selectGetDevicesHealthResult(data),
    }),
  }),
  graphql(ExportDevicesHealthCSV, {
    name: 'requestDevicesHealthReport',
  }),
)(ManagementDevicesTablePath)
