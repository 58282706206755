import { useEffect } from 'react'
import { compose } from 'redux'
import { withUser } from '../_v2/contexts/user/user.context'
import { withWindowSizes } from './window_size_provider'
import { setupFreshServiceScript } from '../utils/freshdesk_helpers'

function FreshdeskWrapper({ children, authorizedUser, isLogged }) {
  useEffect(() => {
    if (isLogged()) {
      setupFreshServiceScript(authorizedUser)
    } else {
      setupFreshServiceScript()
    }
  }, [])

  return children
}

export default compose(withWindowSizes, withUser)(FreshdeskWrapper)
