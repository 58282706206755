import React from 'react'
import { compose } from 'redux'
import { graphql } from '@apollo/client/react/hoc'
import gql from 'graphql-tag'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { createSelector } from 'reselect'
import { get } from 'lodash/fp/object'
import { withTranslation } from 'react-i18next'
import classnames from 'classnames'

import { MixedGroupDetailsType } from '@@src/api/presenters/group'
import { createSelectGraphQLResult } from '@@src/utils'
import { parseGraphQLResult } from '@@src/api/presenters'
import ErrorInfo from '@@src/components/error_info'

export default function withMoveGroupsProvider(Component) {
  class MoveGroupsProvider extends React.PureComponent {
    static propTypes = {
      t: PropTypes.func.isRequired,
      group: PropTypes.instanceOf(MixedGroupDetailsType),
      destinationGroupId: PropTypes.number,
    }

    constructor(props) {
      super(props)

      this.state = this.getInitialState()
    }

    render() {
      const { viewingTopLevelGroups } = this.state

      return (
        <Component
          selectDestinationSiblings={this.selectDestinationSiblings}
          selectDestinationId={this.selectDestinationId}
          viewTopLevelGroups={this.viewTopLevelGroups}
          getHandleNavigateGroupHierarchy={this.getHandleNavigate}
          setInitialMoveGroupsState={this.setInitialState}
          viewingTopLevelGroups={viewingTopLevelGroups}
          renderFailure={this.renderFailure}
          renderNoGroupSiblings={this.renderNoGroupSiblings}
          moveToHierarchy={this.moveToHierarchy}
          moveToTopLevel={this.moveToTopLevel}
          {...this.props} />
      )
    }

    renderNoGroupSiblings = className => (
      <li className={classnames(
        'd-flex align-items-center px-3 text-muted',
        className
      )}>
        {this.props.t('text.no_siblings')}
      </li>
    )

    renderFailure = ({ error }) => (
      <ErrorInfo className="mx-3 mt-3" error={error} />
    )

    setInitialState = () => {
      const { refetchDestinationGroup, destinationGroupId } = this.props

      this.setState(this.getInitialState())
      refetchDestinationGroup({ id: destinationGroupId })
    }

    getInitialState = () => {
      return {
        viewingTopLevelGroups: this.props.destinationGroupId === -1,
      }
    }

    viewTopLevelGroups = () => {
      this.setState({ viewingTopLevelGroups: true })
    }

    viewHierarchyGroups = () => {
      this.setState({ viewingTopLevelGroups: false })
    }

    moveToTopLevel = async args => {
      const { removeGroupFromParent } = this.props

      return await removeGroupFromParent({
        variables: {
          ...args,
        },
      })
    }

    moveToHierarchy = async args => {
      const { moveGroup } = this.props

      return await moveGroup({
        variables: {
          inheritParentConfig: [],
          ...args,
        },
      })
    }

    getHandleNavigate = parentId => {
      const { refetchDestinationGroup } = this.props

      return () => {
        this.viewHierarchyGroups()
        refetchDestinationGroup({ id: parentId })
      }
    }

    selectDestinationSiblings = createSelector(
      [get('destinationGroupResult'), get('group')],
      (destinationGroupResult, group) => {
        if (destinationGroupResult.wasSuccessful() &&
          destinationGroupResult.data && destinationGroupResult.data.members &&
            Array.isArray(destinationGroupResult.data.members.data)) {
          return destinationGroupResult.data.members.data.filter(member => {
            return String(member.uuid) !== String(group.uuid)
          })
        }

        return []
      }
    )

    selectDestinationId = createSelector(
      [get('destinationGroupResult')],
      destinationGroupResult => {
        if (destinationGroupResult.wasSuccessful()) {
          const group = destinationGroupResult.data

          if (Array.isArray(group.ancestry) && group.ancestry.length > 0) {
            return group.ancestry
              .slice(0)
              .sort((a, b) => b.index - a.index)[0].id
          }
        }

        return -1
      }
    )

    static MOVE_GROUP_MUTATION = gql`
      mutation MoveGroup (
        $groupIds: [Int!]!,
        $newParentGroupId: Int!,
        $inheritParentConfig: [InheritanceConfigType]!,
      ) {
        moveGroup (
          groupIds: $groupIds,
          newParentGroupId: $newParentGroupId,
          inheritParentConfig: $inheritParentConfig,
        )
      }
    `

    static REMOVE_GROUP_FROM_PARENT_MUTATION = gql`
      mutation RemoveGroupMembersFromGroup (
        $parentGroupId: Int!,
        $groupMemberIds: [Int!]!,
      ) {
        removeGroupMembersFromGroup (
          parentGroupId: $parentGroupId,
          groupMemberIds: $groupMemberIds,
        )
      }
    `

    static DESTINATION_GROUP_QUERY = gql`
      query Group($id: Int!) {
        groupMix(id: $id) {
          id
          name
          category
          ancestry {
            id
            name
            index
          }
          members {
            type
            data {
              ... on MixedGroupDetailsType {
                id
                name
                category
                members {
                  type
                }
              }
            }
          }
        }
      }
    `
  }

  function mapStateToProps() {
    return {
      selectDestinationGroupResult: createSelectGraphQLResult('groupMix', {
        mapResult: parseGraphQLResult,
        nullObject: null,
      }),
    }
  }

  return compose(
    withTranslation([
      'src/components/modals/move_groups_modal/move_groups_provider',
    ]),
    connect(mapStateToProps),
    graphql(MoveGroupsProvider.MOVE_GROUP_MUTATION, {
      name: 'moveGroup',
    }),
    graphql(MoveGroupsProvider.REMOVE_GROUP_FROM_PARENT_MUTATION, {
      name: 'removeGroupFromParent',
    }),
    graphql(MoveGroupsProvider.DESTINATION_GROUP_QUERY, {
      options: ({ destinationGroupId }) => {
        return {
          variables: {
            id: destinationGroupId,
          },
        }
      },
      props: ({ data, ownProps: { selectDestinationGroupResult } }) => {
        return {
          refetchDestinationGroup: data.refetch,
          destinationGroupResult: selectDestinationGroupResult(data),
        }
      },
    }),
  )(MoveGroupsProvider)
}
