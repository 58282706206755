import binarySearch from 'binary-search'

export function closestPointWithX(graphItem, getX, x) {
  const graphItemData = graphItem.data
  const closestDataPoints = [{
    point: null,
    difference: Infinity,
  }]

  if (graphItemData.length === 0) {
    return null
  }

  for (let i = 0; i < graphItemData.length; i++) {
    const chunk = graphItemData[i]

    const searchIndex = binarySearch(
      chunk.data, x, (pt, needle) => {
        return Number(getX(pt)) - needle
      }
    )

    // Binary search returns -(low + 1) on not found and returns an index
    // between a higher and lower value. But mouse value may be closer to
    // preceding or following values.
    const absSearchIndex = searchIndex >= 0 ?
      searchIndex : Math.min(searchIndex * -1 - 1, chunk.data.length - 1)
    let searchPoint = chunk.data[absSearchIndex]
    const searchPointValue = Number(getX(searchPoint))
    let searchPointDifference =
      Math.abs(searchPointValue - x)
    let precedingPointDifference = Infinity

    if (absSearchIndex > 0) {
      const precedingPoint = chunk.data[absSearchIndex - 1]
      const precedingPointNumber = Number(getX(precedingPoint))

      precedingPointDifference =
        Math.abs(precedingPointNumber - x)

      if (precedingPointDifference < searchPointDifference) {
        searchPoint = precedingPoint
        searchPointDifference = precedingPointDifference
      }
    }

    if (absSearchIndex < chunk.data.length - 1 &&
      !Number.isFinite(precedingPointDifference)) {
      const followingPoint = chunk.data[absSearchIndex + 1]
      const followingPointNumber = Number(getX(followingPoint))
      const followingPointDifference =
        Math.abs(followingPointNumber - x)

      if (followingPointDifference < searchPointDifference) {
        searchPoint = followingPoint
        searchPointDifference = followingPointDifference
      }
    }

    closestDataPoints.push({
      point: searchPoint,
      difference: searchPointDifference,
    })
  }

  const [closestDataPoint] = closestDataPoints.sort(
    ({ difference: diffOne }, { difference: diffTwo }) => diffOne - diffTwo
  )

  return closestDataPoint.point
}

export function closestGraphItemWithY(graphItemsWithPoint, getY, y) {
  if (graphItemsWithPoint.length < 1) {
    return null
  } else if (graphItemsWithPoint.length === 1) {
    return graphItemsWithPoint[0].graphItem
  }

  const closestGraphItemPoints = graphItemsWithPoint
    .filter(a => a && a.point)
    .sort((datumOne, datumTwo) => {
      const datumDiffOne =
        Math.abs(y - getY(datumOne.point))
      const datumDiffTwo =
        Math.abs(y - getY(datumTwo.point))

      return datumDiffOne - datumDiffTwo
    })

  return closestGraphItemPoints.length > 0 ?
    closestGraphItemPoints[0].graphItem : null
}
