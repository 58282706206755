import { withLeaflet, MapComponent } from 'react-leaflet'
import PropTypes from 'prop-types'
import GeoRasterLayer from 'georaster-layer-for-leaflet'
import parseGeoRaster from 'georaster'

class MapRasterLayer extends MapComponent {
  static propTypes = {
    rasterUrl: PropTypes.string.isRequired,
    opacity: PropTypes.number.isRequired,
    resolution: PropTypes.number,
    pixelValuesToColorFn: PropTypes.func,
  }

  static defaultProps = {
    opacity: 1,
    resolution: 200,
  }

  get layerContainer() {
    return this.props.leaflet.layerContainer || this.props.leaflet.map
  }

  async componentDidMount() {
    super.componentDidMount()

    this.leafletElement = await this.createLeafletElement(this.props)
    this.layerContainer.addLayer(this.leafletElement)
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.rasterUrl !== this.props.rasterUrl ||
      prevProps.resolution !== this.props.resolution ||
        prevProps.opacity !== this.props.opacity) {
      this.layerContainer.removeLayer(this.leafletElement)
      this.leafletElement = await this.createLeafletElement(this.props)
      this.layerContainer.addLayer(this.leafletElement)
    }
  }

  componentWillUnmount() {
    super.componentWillUnmount()

    if (this.leafletElement) {
      this.layerContainer.removeLayer(this.leafletElement)
    }
  }

  async createLeafletElement(props) {
    const {
      rasterUrl, opacity, resolution, pixelValuesToColorFn,
    } = props
    const options = {
      opacity,
    }

    if (pixelValuesToColorFn) {
      options.pixelValuesToColorFn = pixelValuesToColorFn
    }

    if (resolution) {
      options.resolution = resolution
    }

    const response = await global.fetch(rasterUrl)
    const rasterBuffer = await response.arrayBuffer()
    const geoRaster = await parseGeoRaster(rasterBuffer)

    return new GeoRasterLayer({
      georaster: geoRaster,
      ...options,
    })
  }

  render() {
    return null
  }
}

export default withLeaflet(MapRasterLayer)
