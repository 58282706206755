import Device from '@@src/api/presenters/device'
import DeviceIssue from '@@src/api/presenters/device_issue'
import routes from '@@src/routes'
import { formatAsQueryString } from '@@src/utils'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Button, Card, CardTitle, Col, Row } from 'reactstrap'
import { compose } from 'redux'
import { withWindowSizes } from '../../../components/window_size_provider'
import { useGetDevicesStatisticsSummaries } from '../../../hooks/summaries/useGetDevicesStatisticsSummaries'
import AsyncResultSwitch from '../../async_result_switch'
import { NOT_INSTALLED } from '../../modals/device_state_filters'
import styles from './devices_installed_healthy_summary.css'
import { SummaryInfoPlaceholder } from '../summary_info/summary_info_placeholder'
import { SummaryInfo } from '../summary_info/summary_info'
import { SummaryGraph } from '../summary_info/summary_graph'

const COMMISSIONED_DEVICES_FILTER = [NOT_INSTALLED, Device.COMMISSIONED]

function DevicesInstalledHealthySummary({ t }) {
  const { statisticsResult } = useGetDevicesStatisticsSummaries()

  const devicesPath = routes.managementDevicesPath()
  const formatLinkURL = (type) =>
    formatAsQueryString({ od: 'asc', f: type, s: type }) // adding both f (filters) and s (status) for now to make sure we don't break anything

  const renderDevicesLayout = ({ data }) => {
    const {
      total,
      commissionedOnly,
      decommissioned,
      healthy,
      installed,
      withErrors,
      withWarnings,
    } = data

    const summaryInfosFirstGroup = [
      {
        name: 'summary-info-installed',
        value: installed,
        color: 'tangerine',
        text: t('graphs.installed_on_asset'),
        link: `${devicesPath}${formatLinkURL(Device.INSTALLED)}`,
      },
      {
        name: 'summary-info-commissioned-only',
        value: commissionedOnly,
        color: 'blue',
        text: t('graphs.commissioned_only'),
        link: `${devicesPath}${formatLinkURL(
          COMMISSIONED_DEVICES_FILTER.join('+')
        )}`,
      },
      {
        name: 'summary-info-decommissioned',
        value: decommissioned,
        color: 'gray',
        text: t('graphs.decommissioned'),
        link: `${devicesPath}${formatLinkURL(Device.DECOMMISSIONED)}`,
      },
    ]

    const summaryInfosSecondGroup = [
      {
        name: 'summary-info-healthy',
        value: healthy,
        color: 'green',
        text: t('graphs.healthy'),
        link: `${devicesPath}${formatLinkURL(DeviceIssue.HEALTHY_FILTER)}`,
      },
      {
        name: 'summary-info-with-warnings',
        value: withWarnings,
        color: 'yellow',
        text: t('graphs.with_warnings'),
        link: `${devicesPath}${formatLinkURL(
          DeviceIssue.WARNING_SEVERITY_FILTER
        )}`,
      },
      {
        name: 'summary-info-with-errors',
        value: withErrors,
        color: 'red',
        text: t('graphs.with_error'),
        link: `${devicesPath}${formatLinkURL(
          DeviceIssue.ERROR_SEVERITY_FILTER
        )}`,
      },
    ]

    return (
      <Row sm="12">
        <div className="d-flex row-sm col-lg-3 justify-content-center align-items-center mb-4">
          <div className="d-flex flex-column justify-content-center align-items-center">
            <p
              name="summary-info-total-devices"
              className={`d-block ${styles['total-devices-number']}`}
            >
              {total}
            </p>
            <p className={`${styles['total-device-text']}`}>
              {t('text.total_devices')}
            </p>
          </div>
        </div>
        <div className="d-flex col-lg row">
          <div className="d-flex flex-row col justify-content-center align-items-center">
            <SummaryGraph
              graphData={[total, installed, commissionedOnly, decommissioned]}
              colors={['#f94f29', '#5b9bd5', '#7f899a']}
              title={t('text.installed')}
              subtitle={t('graphs.of_total', { amount: total })}
              className="installedDevicesStatistics"
            />
            <SummaryInfo summaryInfos={summaryInfosFirstGroup} />
          </div>
          <div className="d-flex flex-row col justify-content-center align-items-center">
            <SummaryGraph
              graphData={[total, healthy, withWarnings, withErrors]}
              colors={['#64b900', '#ffc900', '#dc3545']}
              title={t('text.healthy')}
              subtitle={t('graphs.of_total', { amount: total })}
              className="healthDevicesStatistics"
            />
            <SummaryInfo summaryInfos={summaryInfosSecondGroup} />
          </div>
        </div>
      </Row>
    )
  }

  return (
    <>
      <Col sm="12" md="12" className="mb-4 d-flex">
        <Card body>
          <CardTitle>
            <h2 className="h4">{t('text.device_summary')}</h2>
          </CardTitle>

          <AsyncResultSwitch
            result={statisticsResult}
            renderSuccessResult={renderDevicesLayout}
            renderPendingResult={() => <SummaryInfoPlaceholder count={2} />}
          />
          <Button
            className="mr-auto pl-0 pb-0 mt-auto"
            color="link"
            tag={Link}
            to={routes.managementDevicesPath()}
          >
            {t('src/home_path/index_page:links.devices')}
          </Button>
        </Card>
      </Col>
    </>
  )
}

export default compose(
  withTranslation([
    'src/components/summary/devices_summary',
    'src/home_path/index_page',
  ]),
  withWindowSizes
)(DevicesInstalledHealthySummary)
