import gql from 'graphql-tag'

export const PagedDevicesTableQuery = gql`
  query DevicesHealthQuery(
    $pageNumber: Int!,
    $resultsPerPage: Int!,
    $orderBy: String,
    $orderDirection: String
    $serialNumber: String,
    $networkAssetReadableId:  String,
    $networkAssetReadableName:  String,
    $groups:  String,
    $hasActiveAlerts: Boolean,
    $hasDataGaps:  Boolean,
    $hasBatteryIssues:  Boolean,
    $signalStrengthMin: Float,
    $signalStrengthMax:  Float,
    $batteryVoltageMin:  Float,
    $batteryVoltageMax: Float,
  ) {
    getDevicesHealth(
      pageNumber: $pageNumber,
      resultsPerPage: $resultsPerPage,
      orderBy: $orderBy,
      orderDirection: $orderDirection,
      serialNumber: $serialNumber,
      networkAssetReadableId: $networkAssetReadableId,
      networkAssetReadableName: $networkAssetReadableName,
      groups: $groups,
      hasActiveAlerts: $hasActiveAlerts,
      hasDataGaps: $hasDataGaps,
      hasBatteryIssues: $hasBatteryIssues,
      signalStrengthMin: $signalStrengthMin,
      signalStrengthMax: $signalStrengthMax,
      batteryVoltageMin: $batteryVoltageMin,
      batteryVoltageMax: $batteryVoltageMax,
    ) {
      pagination {
        perPage
        pageIndex
        pageNumber
        totalPages
        totalResults
      }
      devices {
        deviceId
        deviceStatus
        mostSevereIssueSeverity
        serialNumber
        lastCommunicationTime
        firstCommissioned
        isCommissioned
        installationChannel
        networkAssetId
        networkAssetReadableId
        networkAssetReadableName
        temperature
        battery1
        battery2
        signal
        hasActiveAlerts
        hasDataGaps
        hasBatteryIssues
        groups {
          id
          name
        }
      }
    }
  }
`
