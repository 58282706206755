import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { Redirect } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import {
  Container,
  Row,
  Col,
  Nav,
} from 'reactstrap'
import { NavLink, Switch, Route } from 'react-router-dom'

import LanguageAndRegionPath from './language_and_region_path'
import DevicesPath from './devices_path'
import AlertThresholdPath from './alerts_path'

import requiresLogin from '@@src/components/security/requires_login'
import AppLayout from '@@src/components/app_layout'
import userPermissions from '@@src/components/permissions/user_permissions'
import routes from '@@src/routes'

function AdminTenantSettingsPage(props) {
  const { t, permissions } = props

  if (!permissions.includes('can_manage_tenant_settings')) {
    return (
      <Redirect to="/page-not-found" />
    )
  }

  return (
    <AppLayout title={t('headings.page_title')}>
      <Container className="mt-5">
        <Row>
          <Col>
            <h1 className="mb-5">
              {t('headings.settings')}
            </h1>
          </Col>
        </Row>

        <Row>
          <Col md="4">
            <Nav pills={true} vertical>
              <NavLink to={routes.adminTenantSettingsLanguageAndRegion()}
                className="nav-link"
                name="link-tenant-language-and-region">
                {t('nav.language_and_region')}
              </NavLink>
              <NavLink to="devices"
                className="nav-link"
                name="link-tenant-devices">
                {t('nav.devices')}
              </NavLink>
              <NavLink to="alerts"
                className="nav-link"
                name="link-tenant-alerts">
                {t('nav.alerts')}
              </NavLink>
            </Nav>
          </Col>
          <Col>
            <Switch>
              <Route
                path={routes.adminTenantSettingsLanguageAndRegion()}
                component={() => (<LanguageAndRegionPath {...props} />)}
              />
              <Route
                path="/admin/settings/devices"
                component={() => (<DevicesPath {...props} />)}
              />
              <Route
                path="/admin/settings/alerts"
                component={() => (<AlertThresholdPath {...props} />)}
              />
            </Switch>
          </Col>
        </Row>
      </Container>
    </AppLayout>
  )
}

AdminTenantSettingsPage.propTypes = {
  permissions: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
}

AdminTenantSettingsPage.defaultProps = {
  permissions: [],
}

export default compose(
  requiresLogin,
  userPermissions,
  withTranslation([
    'src/admin_path/tenant_settings/index_page',
    'src/admin_path/tenant_settings/language_and_region_path',
    'common/forms',
  ]),
)(memo(AdminTenantSettingsPage))
