import React, { PureComponent } from 'react'
import classnames from 'classnames'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import { Button, ButtonGroup, UncontrolledTooltip } from 'reactstrap'

import * as DragCover from '@@src/components/singletons/drag_cover'

import styles from './toggle_drag_mode_button.css'

export class ToggleDragModeButton extends PureComponent {
  static propTypes = {
    dragMode: PropTypes.oneOf(DragCover.DRAG_MODES).isRequired,
    t: PropTypes.func.isRequired,
    setPanDragMode: PropTypes.func.isRequired,
    setZoomDragMode: PropTypes.func.isRequired,
    setSelectDragMode: PropTypes.func.isRequired,
    showSelectOption: PropTypes.bool.isRequired,
    className: PropTypes.string,
  }

  static defaultProps = {
    dragMode: DragCover.ZOOM_DRAG_MODE,
    showSelectOption: true,
  }

  render() {
    const {
      t, dragMode, setPanDragMode, setZoomDragMode,
      className, showSelectOption, setSelectDragMode,
    } = this.props
    const buttonClassnames = classnames(
      styles['toggle-zoom-pan-button'],
      className,
    )

    return (
      <ButtonGroup className={buttonClassnames}>
        {
          showSelectOption ?
            <Button
              id="select-mode-button"
              onClick={setSelectDragMode}
              color={dragMode === DragCover.SELECT_DRAG_MODE ? 'dark' : 'light'}
              aria-label={t('buttons.select_mode')}
              size="sm">
              <i className="far fa-i-cursor" />

              <UncontrolledTooltip placement="top" target="select-mode-button">
                {t('buttons.select_mode')}
              </UncontrolledTooltip>
            </Button>
            :
            null
        }

        <Button
          id="zoom-mode-button"
          onClick={setZoomDragMode}
          color={dragMode === DragCover.ZOOM_DRAG_MODE ? 'dark' : 'light'}
          aria-label={t('buttons.zoom_mode')}
          size="sm">
          <i className="far fa-search" />

          <UncontrolledTooltip placement="top" target="zoom-mode-button">
            {t('buttons.zoom_mode')}
          </UncontrolledTooltip>
        </Button>

        <Button
          id="pan-mode-button"
          onClick={setPanDragMode}
          color={dragMode === DragCover.PAN_DRAG_MODE ? 'dark' : 'light'}
          aria-label={t('buttons.pan_mode')}
          size="sm">
          <i className="far fa-arrows-alt" />

          <UncontrolledTooltip placement="top" target="pan-mode-button">
            {t('buttons.pan_mode')}
          </UncontrolledTooltip>
        </Button>
      </ButtonGroup>
    )
  }

  componentWillUnmount() {
    const { setZoomDragMode } = this.props

    setZoomDragMode()
  }
}

function mapStateToProps(state) {
  return {
    dragMode: state.dragCover.dragMode,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setZoomDragMode() {
      dispatch(DragCover.setDragMode(DragCover.ZOOM_DRAG_MODE))
    },
    setPanDragMode() {
      dispatch(DragCover.setDragMode(DragCover.PAN_DRAG_MODE))
    },
    setSelectDragMode() {
      dispatch(DragCover.setDragMode(DragCover.SELECT_DRAG_MODE))
    },
  }
}

export default compose(
  withTranslation([
    'src/components/graphs/toggle_drag_mode_button',
  ]),
  connect(mapStateToProps, mapDispatchToProps)
)(ToggleDragModeButton)
