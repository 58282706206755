import gql from 'graphql-tag'
import React from 'react'
import moment from 'moment'
import { compose } from 'redux'
import { graphql } from '@apollo/client/react/hoc'
import { Translation } from 'react-i18next'

import transformProps from '@@src/components/transform_props'
import AsyncResultSwitch from '@@src/components/async_result_switch'
import { createSelectGraphQLResult } from '@@src/utils'
import { renderAbsolutePositionedLoading } from '@@src/components/app_suspense_container'

export const UserSettingsContext = React.createContext()

const noop = () => null

class UserSettingsProvider extends React.PureComponent {
  render() {
    return (
      <AsyncResultSwitch
        result={this.props.result}
        renderPendingResult={
          this.props.hideLoading ? noop : renderAbsolutePositionedLoading
        }
        renderSuccessResult={this.renderSuccessResult}
      ></AsyncResultSwitch>
    )
  }

  renderSuccessResult = ({ data: config }) => {
    const { refetchUserSettings } = this.props
    return (
      // User settings are spread alongside refetchUserSettings so that refetching can be done further down the tree
      // Particularly for refetching as part of user settings form
      <UserSettingsContext.Provider value={{ ...config, refetchUserSettings }}>
        {this.props.children}
      </UserSettingsContext.Provider>
    )
  }

  componentDidUpdate() {
    const { i18n, result } = this.props

    if (result.wasSuccessful()) {
      if (i18n.language !== result.data.language) {
        i18n.changeLanguage(result.data.language)
        moment.locale(result.data.language)
      }
    }
  }

  static USER_SETTINGS_QUERY = gql`
    query userSettings {
      userSettings {
        language
        units {
          temperature
          distance
          pressure
        }
      }
    }
  `
}

const WithI18n = (props) => (
  <Translation>
    {(_, { i18n }) => <UserSettingsProvider i18n={i18n} {...props} />}
  </Translation>
)

export default compose(
  transformProps(() => () => {
    return {
      selectUserSettingsResult: createSelectGraphQLResult('userSettings', {
        nullObject: null,
      }),
    }
  }),
  graphql(UserSettingsProvider.USER_SETTINGS_QUERY, {
    options: () => ({
      fetchPolicy: 'cache-and-network',
    }),
    props: ({ data, ownProps: { selectUserSettingsResult } }) => {
      const { refetch } = data
      return {
        result: selectUserSettingsResult(data),
        refetchUserSettings: refetch,
      }
    },
  })
)(WithI18n)
