import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import classnames from 'classnames'
import { get } from 'lodash/fp/object'
import { flow } from 'lodash/fp/util'
import { filter, flatMap, map } from 'lodash/fp/collection'
import {
  difference,
  flatten,
  intersection,
  take,
  union,
  uniqBy,
} from 'lodash/fp/array'
import { compose } from 'redux'
import { push } from 'connected-react-router'
import { graphql } from '@apollo/client/react/hoc'
import { createSelector } from 'reselect'
import { withTranslation } from 'react-i18next'
import { Alert, Col, Row } from 'reactstrap'
import { Redirect, Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import * as analytics from '@@src/analytics'
import routes from '@@src/routes'
import AppLayout from '@@src/components/app_layout'
import { CAN_VIEW_ANALYSIS } from '../../_v2/contexts/user/consts/permissions'
import CpisView from './cpis_view/cpis_view'
import LegendItem from '@@src/components/graphs/legend_item'
import withDataSources from '@@src/analysis_path/with_data_sources'
import requiresLogin from '@@src/components/security/requires_login'
import EventListTable, {
  START_TIME_KEY,
} from './event_list_table/event_list_table'
import defineQueryParams from '@@src/components/define_query_params'
import EventSourceLocalisationMapWithEvents from '@@src/analysis_path/pressure_analysis_path/event_source_localisation_map_with_events' // eslint-disable-line max-len
import PressureSubsetGraph from './pressure_subset_graph/pressure_subset_graph'
import PressureAnalysisConfigMenu from './pressure_analysis_config_menu'
import { parseGraphQLResult } from '@@src/api/presenters'
import {
  createSelectGraphQLResult,
  DEFAULT_TIMEZONE,
  mergeSearchParams,
  parseSearchParams,
} from '@@src/utils'
import { mergeSourceData } from '../data_source_sidebar'
import * as DragCover from '@@src/components/singletons/drag_cover'
import AsyncResult from '@@src/utils/async_result'
import TenantLicenceFeatureGate, {
  EVENT_LOCALISATION_FEATURE,
} from '@@src/components/tenant_licence/tenant_licence_feature_gate'
import TenantLicenceInactiveFeatureWarning from '@@src/components/tenant_licence/tenant_licence_feature_inactive_warning'
import LegendListItems from '@@src/analysis_path/pressure_analysis_path/legend_list_items'
import userPermissions from '@@src/components/permissions/user_permissions'
import LegendItemColorProvider from '@@src/components/colors/legend_item_color_provider'
import PressureSubsetDataProvider from '@@src/analysis_path/pressure_analysis_path/pressure_subset_data_provider'
import CpisDataProvider from '@@src/analysis_path/pressure_analysis_path/cpis_view/cpis_data_provider'
import transformProps from '@@src/components/transform_props'

import NetworkAsset from '@@src/api/presenters/network_asset'
import Device from '@@src/api/presenters/device'
import { MixedGroupDetailsType } from '@@src/api/presenters/group'
import AnomalyListContentContainer from './anomaly_list/anomaly_list'
import * as Changes from '../../store/changes'
import PlotDataContextProvider from '../../hooks/plot/PlotDataContext'
import AsyncResultSwitch from '../../components/async_result_switch'
import { WithFatchingVariablesAndRequestControll } from './with_fetching_variables_and_request_controll'
import LegendListHeader from './legend_list_header'

import {
  DATA_SOURCES_QUERY,
  DEFAULT_DATE_NOW,
  DEFAULT_DATE_YESTERDAY,
  ORDER_ASC,
  SETTING_AUTO_Y_AXIS_ZOOM,
  SETTING_CURSOR_TOOL_DISPLAY,
  SETTING_GRAPH_OPTIONS,
  SOURCE_LIMIT,
  VIEW_TYPE_OPTIONS,
  VIEW_TYPE_PLOT,
} from './constants'

import styles from './index_page.css'

class PressureAnalysisPage extends React.PureComponent {
  static propTypes = {
    dataSourcesAndGroupsResult: PropTypes.instanceOf(AsyncResult).isRequired,
    eventIds: PropTypes.arrayOf(PropTypes.number),
  }

  static defaultProps = {
    dataSourcesAndGroupsResult: AsyncResult.pending([]),
  }

  clipboardTextRef = React.createRef()

  constructor(props) {
    super(props)
    this.state = {
      collapseEmptyChannels: false,
      selectedGraphSetting: [
        SETTING_AUTO_Y_AXIS_ZOOM,
        SETTING_CURSOR_TOOL_DISPLAY,
      ],
      minPressure: undefined,
      maxPressure: undefined,
      focusedLegendItem: undefined,
      requestAnomaliesTableReportResult: AsyncResult.notFound(),
      shouldDisplayExportButton: false,
    }
  }

  setCollapseState = (collapseEmptyChannels) => {
    this.setState({ collapseEmptyChannels })
  }

  componentDidUpdate(props) {
    const {
      startTime,
      endTime,
      dispatchUpdateQueryParams,
      timezone: currentTimezone,
      deviceIds,
      networkAssetIds,
      dispatchPush,
    } = props
    if (deviceIds.length === 0 && networkAssetIds.length === 0) {
      dispatchPush(
        routes.analysisListPath(this.selectParsedSearchParams(this.props))
      )
    }
    let start = moment(startTime)
    if (moment(endTime).diff(startTime, 'month') > 6) {
      start = moment(endTime).subtract(6, 'month')
      dispatchUpdateQueryParams(props, {
        startTime: new Date(start.format()),
        endTime,
        timezone: currentTimezone,
      })
    }
  }

  render() {
    const {
      t,
      startTime,
      endTime,
      location,
      timezone,
      permissions,
      orderBy,
      orderDirection,
    } = this.props

    // Does the user have permissions for this page?
    if (!permissions.includes(CAN_VIEW_ANALYSIS)) {
      return <Redirect to="/page-not-found" />
    }

    const {
      focusedLegendItem,
      collapseEmptyChannels,
      shouldDisplayExportButton,
    } = this.state

    const legendItemsHierarchyResult = this.selectLegendItemsHierarchyResult(
      this.props,
      this.state
    )
    const allValidLegendItemsResult = this.selectFlatListOfLegendItemsResult(
      this.props
    )

    const legendItemsResult = this.selectValidSourceLegendItemsResult(
      this.props,
      this.state
    )
    const currentGraphOptions = this.createGetCurrentGraphOptions(
      this.props,
      this.state
    )
    const viewType = this.selectViewType(this.props)

    return (
      <AppLayout
        title={t('headings.page_title')}
        contentsStyle="fixed-at-full-height"
      >
        <LegendItemColorProvider>
          <WithFatchingVariablesAndRequestControll
            startTime={startTime}
            endTime={endTime}
            legendItemsResult={legendItemsResult}
            viewType={viewType}
            renderViews={(fatchingVariables) => (
              <PressureSubsetDataProvider>
                <CpisDataProvider>
                  <div className="w-100 h-100 bg-white">
                    <div
                      ref={this.clipboardTextRef}
                      style={{
                        top: -999999,
                        position: 'fixed',
                      }}
                    />

                    <Row className="flex-nowrap h-100" noGutters={true}>
                      <Col
                        sm="9"
                        className={classnames(
                          styles['graph-left-section'],
                          'h-100 w-100 d-flex flex-column'
                        )}
                      >
                        <PressureAnalysisConfigMenu
                          t={t}
                          endTime={endTime}
                          timezone={timezone}
                          startTime={startTime}
                          viewType={viewType}
                          orderDirection={orderDirection}
                          orderBy={orderBy}
                          shouldDisplayExportButton={shouldDisplayExportButton}
                          legendItemsResult={legendItemsResult}
                          legendItemsHierarchyResult={
                            legendItemsHierarchyResult
                          }
                          currentGraphOptions={currentGraphOptions}
                          onChangeView={this.onChangeViewType}
                          onChangeOptions={this.onChangeOptions}
                          onChangeTimeRange={this.onChangeTimeRange}
                        />

                        <AsyncResultSwitch
                          result={legendItemsResult}
                          renderSuccessResult={() => (
                            <div
                              name="data-view-container"
                              className={styles['data-view-container']}
                            >
                              <Switch name="data-view-switch">
                                <Route
                                  path={routes.analysisPressurePath()}
                                  exact={true}
                                  component={() => (
                                    <Redirect
                                      to={{
                                        search: location.search,
                                        pathname:
                                          routes.analysisPressurePlotPath(),
                                      }}
                                    />
                                  )}
                                />
                                <Route
                                  path={routes.analysisPressureCPISPath()}
                                  exact={true}
                                >
                                  <CpisView
                                    setFocusedLegendItem={
                                      this.setFocusedLegendItem
                                    }
                                    startTime={startTime}
                                    endTime={endTime}
                                    timezone={timezone}
                                    fatchingVariables={fatchingVariables}
                                    className="w-100 h-100 bg-white"
                                    onAxesChange={this.onGraphAxesChange}
                                    legendItemsResult={legendItemsResult}
                                  />
                                </Route>
                                <Route
                                  path={routes.analysisPressurePlotPath()}
                                  exact={true}
                                >
                                  <PlotDataContextProvider
                                    fatchingVariables={fatchingVariables}
                                    legendItemsResult={legendItemsResult}
                                  >
                                    <PressureSubsetGraph
                                      name="pressure-subset-graph"
                                      setFocusedLegendItem={
                                        this.setFocusedLegendItem
                                      }
                                      minPressure={this.state.minPressure}
                                      maxPressure={this.state.maxPressure}
                                      startTime={startTime}
                                      endTime={endTime}
                                      timezone={timezone}
                                      className="bg-white w-100 h-100"
                                      onAxesChange={this.onGraphAxesChange}
                                      legendItemsResult={legendItemsResult}
                                      selectedGraphSetting={
                                        this.state.selectedGraphSetting
                                      }
                                      onChangeViewType={this.onChangeViewType}
                                      eventIds={this.props.eventIds}
                                      measureBounds={this.props.measureBounds}
                                      onCopyBoundsIntoLink={
                                        this.onCopyMeasureBoundsIntoLink
                                      }
                                      onDismissDragAreaSummary={
                                        this.onDismissDragAreaSummary
                                      }
                                      onClearEvents={this.onClearEvents}
                                      onChangeOptions={this.onChangeOptions}
                                      onChangeTimeRange={this.onChangeTimeRange}
                                    />
                                  </PlotDataContextProvider>
                                </Route>
                                <Route
                                  path={routes.analysisPressureEventSourcesPath()}
                                  exact={true}
                                >
                                  <TenantLicenceFeatureGate
                                    renderInactiveLicenceFeatureChild={() => (
                                      <TenantLicenceInactiveFeatureWarning
                                        color="info"
                                        className="mt-4 ml-4 d-inline-block"
                                      />
                                    )}
                                    requiredTenantLicenceFeature={
                                      EVENT_LOCALISATION_FEATURE
                                    }
                                  >
                                    <EventSourceLocalisationMapWithEvents
                                      setActiveExpandedEventId={
                                        this.setActiveExpandedEventId
                                      }
                                      onClickViewEvents={
                                        this.onClickViewEventsOnly
                                      }
                                      timezone={timezone}
                                      endTime={endTime}
                                      startTime={startTime}
                                      fatchingVariables={fatchingVariables}
                                      legendItemsResult={legendItemsResult}
                                      groupDataSourcesLegendItems={this.selectAllValidVisibleGroupLegendItemsResult(
                                        this.props,
                                        this.state
                                      )}
                                      className={
                                        styles['event-localisation-map']
                                      }
                                    />
                                  </TenantLicenceFeatureGate>
                                </Route>
                                <Route
                                  path={routes.analysisPressureEventsPath()}
                                  exact={true}
                                >
                                  <EventListTable
                                    orderBy={orderBy}
                                    orderDirection={orderDirection}
                                    page={this.props.page}
                                    perPage={this.props.perPage}
                                    endTime={endTime}
                                    timezone={timezone}
                                    className="pb-4 px-4 pt-5"
                                    startTime={startTime}
                                    createPageLink={this.createPageLink}
                                    onChangePerPage={this.onChangePerPage}
                                    legendItemsResult={legendItemsResult}
                                    onClickViewEvents={this.onClickViewEvents}
                                    setActiveExpandedEventId={
                                      this.setActiveExpandedEventId
                                    }
                                    eventIds={this.props.eventIds}
                                    onClearEvents={this.onClearEvents}
                                    setOrderByAndDirection={
                                      this.setOrderByAndDirection
                                    }
                                  />
                                </Route>
                                <Route
                                  path={routes.analysisPressureAnomaliesPath()}
                                  exact={true}
                                >
                                  <AnomalyListContentContainer
                                    page={this.props.page}
                                    perPage={this.props.perPage}
                                    orderBy={
                                      orderBy === START_TIME_KEY
                                        ? undefined
                                        : orderBy
                                    }
                                    orderDirection={orderDirection}
                                    startTime={startTime}
                                    endTime={endTime}
                                    timezone={timezone}
                                    legendItemsResult={legendItemsResult}
                                    createPageLink={this.createPageLink}
                                    onChangePerPage={this.onChangePerPage}
                                    setOrderByAndDirection={
                                      this.setOrderByAndDirection
                                    }
                                    viewType={viewType}
                                    onAnomalyClick={this.onClickViewAnomaly}
                                    onDataChanged={(val) =>
                                      this.setState({
                                        shouldDisplayExportButton: val,
                                      })
                                    }
                                    pressureUnit={
                                      fatchingVariables.units.pressure
                                    }
                                  />
                                </Route>
                              </Switch>
                            </div>
                          )}
                        />
                      </Col>

                      <Col
                        sm="3"
                        className={classnames(
                          styles['graph-right-section'],
                          'd-flex flex-column'
                        )}
                      >
                        <LegendListHeader
                          collapseEmptyChannels={collapseEmptyChannels}
                          location={location}
                        />

                        {legendItemsResult.data &&
                        legendItemsResult.data.length > SOURCE_LIMIT ? (
                          <Alert color="warning">
                            {t('errors.too_many_data_sources', {
                              limit: SOURCE_LIMIT,
                            })}
                          </Alert>
                        ) : null}

                        <LegendListItems
                          startTime={startTime}
                          endTime={endTime}
                          deviceIds={this.props.deviceIds}
                          onRequestRetry={this.props.refetchData}
                          onRemoveSource={this.onRemoveSource}
                          handleChangeVisibility={this.onChangeVisibility}
                          focusedLegendItem={focusedLegendItem}
                          allValidLegendItems={allValidLegendItemsResult}
                          emptiesCollapsed={collapseEmptyChannels}
                          legendItemsResult={legendItemsResult}
                          hierarchyLegendItemsResult={
                            legendItemsHierarchyResult
                          }
                        />
                      </Col>
                    </Row>
                  </div>
                </CpisDataProvider>
              </PressureSubsetDataProvider>
            )}
          />
        </LegendItemColorProvider>
      </AppLayout>
    )
  }

  createGetCurrentGraphOptions = createSelector(
    [(props, _state) => props.t, (_props, state) => state.selectedGraphSetting],
    (t, selectedGraphSetting) => {
      return SETTING_GRAPH_OPTIONS.map((setting) => {
        return {
          value: setting,
          text: t(`options.${setting}`),
          selected: selectedGraphSetting.includes(setting),
        }
      }).filter((s) => s)
    }
  )

  createPageLink = (page) => {
    const { location } = this.props

    return (
      location.pathname +
      mergeSearchParams(location.search, {
        p: page !== 1 ? page : undefined,
      })
    )
  }

  onCopyMeasureBoundsIntoLink = (bounds) => {
    const { location } = this.props

    if (this.clipboardTextRef && this.clipboardTextRef.current) {
      const link = [
        window.location.origin,
        location.pathname,
        mergeSearchParams(location.search, {
          measure: parseMeasureBounds.invert(bounds),
        }),
      ].join('')

      const node = this.clipboardTextRef.current
      const input = document.createElement('textarea')
      node.appendChild(input)

      input.innerText = link
      input.select()
      document.execCommand('copy')

      node.removeChild(input)
    } else {
      analytics.logError(
        new Error(
          'Failed to copy to clipboard because HTML clipboard helper node was not available'
        )
      ) // eslint-disable-line max-len
    }
  }

  onDismissDragAreaSummary = () => {
    this.props.dispatchUpdateQueryParams(this.props, {
      measureBounds: undefined,
    })
  }

  getPaddedEventTime({ start, end, eventDuration }) {
    const PADDING_MULTIPLIER = 2
    const endTime = start.getTime() + PADDING_MULTIPLIER * eventDuration
    const startTime = end.getTime() - PADDING_MULTIPLIER * eventDuration

    return { startTime, endTime }
  }

  getUpdatedAnomalySearchParams = (anomaly) => {
    const start = new Date(anomaly.startTime)
    const end = new Date(anomaly.endTime)
    const { startTime, endTime } = this.getPaddedEventTime({
      start,
      end,
      eventDuration: end.getTime() - start.getTime(),
    })

    return {
      from: moment(startTime).toISOString(),
      to: moment(endTime).toISOString(),
    }
  }

  getUpdatedEventSearchParams = (events, sources) => {
    let eventStart = null
    let eventEnd = null

    if (Array.isArray(events)) {
      eventStart = events.sort((e1, e2) => e1.startTime - e2.startTime)[0].start
      eventEnd = events.sort((e1, e2) => e2.endTime - e1.endTime)[0].end
    } else {
      eventStart = events.start
      eventEnd = events.end
    }

    const eventDuration = eventEnd.getTime() - eventStart.getTime()
    const { startTime: paddedStartTime, endTime: paddedEndTime } =
      this.getPaddedEventTime({
        start: eventStart,
        end: eventEnd,
        eventDuration,
      })

    const searchParams = {
      startTime: moment(paddedStartTime).toISOString(),
      endTime: moment(paddedEndTime).toISOString(),
    }

    if (Array.isArray(sources) && sources.length > 0) {
      searchParams.d = sources.map((source) => source.dataSourceId)
    } else if (!Array.isArray(sources) && sources) {
      searchParams.d = sources.dataSourceId
    }

    return searchParams
  }

  onClearEvents = () => {
    this.props.dispatchUpdateQueryParams(this.props, {
      eventIds: undefined,
      orderBy: undefined,
      orderDirection: undefined,
    })
  }

  setOrderByAndDirection = (orderBy, orderDirection) => {
    this.props.dispatchUpdateQueryParams(this.props, {
      orderBy,
      orderDirection,
    })
  }

  onClickViewAnomaly = (anomaly) => {
    const { location } = this.props
    const queryParams = this.getUpdatedAnomalySearchParams(anomaly)
    this.props.dispatchUpdateQueryParams(
      {
        location: {
          ...location,
          pathname: routes.analysisPressurePath() + `/${VIEW_TYPE_PLOT}/`,
        },
      },
      queryParams
    )
  }

  onClickViewEvents = (events = [], sources = []) => {
    const { location } = this.props
    const queryParams = this.getUpdatedEventSearchParams(events, sources)
    this.props.dispatchUpdateQueryParams(
      {
        location: {
          ...location,
          pathname: routes.analysisPressurePath() + `/${VIEW_TYPE_PLOT}/`,
        },
      },
      queryParams
    )
  }

  onClickViewEventsOnly = (events = [], sources = []) => {
    const queryParams = this.getUpdatedEventSearchParams(events, sources)
    const eventIds = []

    if (!Array.isArray(events)) {
      eventIds.push(events.id)
    } else {
      eventIds.push(...events.map(({ id }) => id))
    }

    this.props.dispatchUpdateQueryParams(this.props, {
      ...queryParams,
      ev: eventIds.join('+'),
    })
  }

  onChangePerPage = (perPage) => {
    this.props.dispatchUpdateQueryParams(this.props, {
      page: undefined,
      perPage,
    })
  }

  onChangeViewType = (value) => {
    const { dispatchPush, location } = this.props

    dispatchPush(
      routes.analysisPressurePath() +
        '/' +
        value +
        '/' +
        mergeSearchParams(location.search, {
          p: 1,
        })
    )
  }

  selectViewType = createSelector([get('location')], ({ pathname }) => {
    try {
      const splitArray = intersection(pathname.split('/'), VIEW_TYPE_OPTIONS)
      const newViewType = splitArray[0]
      if (VIEW_TYPE_OPTIONS.includes(newViewType)) {
        return newViewType
      }
    } catch (e) {
      return VIEW_TYPE_PLOT
    }

    return VIEW_TYPE_PLOT
  })

  onChangeOptions = (value) => {
    const { selectedGraphSetting } = this.state

    if (selectedGraphSetting.includes(value)) {
      this.setState({
        selectedGraphSetting: selectedGraphSetting.filter(
          (item) => item !== value
        ),
      })
    } else {
      this.setState({
        selectedGraphSetting: [...selectedGraphSetting, value],
      })
    }
  }

  onGraphAxesChange = ({ xAxes, yAxes }) => {
    const autoZoomY = this.state.selectedGraphSetting.includes(
      SETTING_AUTO_Y_AXIS_ZOOM
    )

    if (!autoZoomY && yAxes) {
      this.setState({
        minPressure: yAxes.start,
        maxPressure: yAxes.end,
      })
    } else {
      this.setState({
        minPressure: undefined,
        maxPressure: undefined,
      })
    }

    this.props.dispatchUpdateQueryParams(this.props, {
      endTime: xAxes ? xAxes.end : this.props.endTime,
      startTime: xAxes ? xAxes.start : this.props.startTime,
    })
  }

  selectParsedSearchParams = createSelector([get('location')], (location) =>
    parseSearchParams(location.search)
  )

  onRemoveSource = (legendItem) => {
    const { updateDataSources } = this.props
    const dataSources = this.selectDataSources(this.props)
    let sourcesToRemove = legendItem.source
    if (legendItem.sourceType === 'group') {
      sourcesToRemove = [sourcesToRemove, ...legendItem.source.members.data]
    }

    if (Array.isArray(sourcesToRemove)) {
      const result = dataSources.filter((selectedSource) => {
        return !sourcesToRemove.some((newSource) => {
          if (selectedSource.__typename !== newSource.__typename) {
            return false
          }
          return selectedSource.id === newSource.id
        })
      })
      const uniqueNewResult = new Set(result)
      updateDataSources(Array.from(uniqueNewResult))
      return
    }

    const result = dataSources.filter((a) => {
      if (a.__typename === sourcesToRemove.__typename) {
        return a.id !== sourcesToRemove.id
      }
      return true
    })

    const uniqueNewResult = new Set(result)
    updateDataSources(Array.from(uniqueNewResult))
  }

  setFocusedLegendItem = (focusedLegendItem) => {
    this.setState({ focusedLegendItem })
  }

  selectDataSources = createSelector(
    [get('deviceIds'), get('networkAssetIds'), get('groupIds')],
    (deviceIds, networkAssetIds, groupIds) => {
      const result = []
      for (const deviceId of deviceIds) {
        result.push(
          new Device({
            id: deviceId,
            __typename: 'Device',
          })
        )
      }
      for (const assetId of networkAssetIds) {
        result.push(
          new NetworkAsset({
            id: assetId,
            __typename: 'NetworkAsset',
          })
        )
      }
      for (const groupId of groupIds) {
        result.push(
          new MixedGroupDetailsType({
            id: groupId,
            __typename: 'MixedGroupDetailsType',
          })
        )
      }
      return result
    }
  )

  selectLegendItemsHierarchyResult = createSelector(
    [get('dataSourcesAndGroupsResult'), get('hiddenLegendItemIds')],
    (dataSourcesAndGroupsResult, hiddenLegendItemIds) => {
      function mapWithVisibility(item) {
        return item.mapWith({
          visible: !hiddenLegendItemIds.includes(item.id),
          children: item.children
            ? item.children.map((child) => mapWithVisibility(child))
            : item.children,
        })
      }
      if (dataSourcesAndGroupsResult.wasFailure()) {
        return AsyncResult.fail([])
      }

      return dataSourcesAndGroupsResult.map((sources) => {
        return sources.map((source) => {
          return mapWithVisibility(LegendItem.fromDataSource(source))
        })
      })
    }
  )

  onChangeVisibility = ({ ids, visible }) => {
    const { hiddenLegendItemIds } = this.props

    const allValidLegendItemsResult = this.selectFlatListOfLegendItemsResult(
      this.props
    )
    const allValidHiddenIds = flow(
      map('id'),
      intersection(hiddenLegendItemIds)
    )(allValidLegendItemsResult.data)

    this.props.dispatchUpdateQueryParams(this.props, {
      hiddenLegendItemIds: visible
        ? difference(allValidHiddenIds, ids)
        : union(allValidHiddenIds, ids),
    })
  }

  selectFlatListOfLegendItemsResult = createSelector(
    [this.selectLegendItemsHierarchyResult],
    (legendItemsResult) => {
      return legendItemsResult.map(
        flow(flatMap(flatMapWithChildren), uniqBy('id'))
      )
    }
  )

  selectAllValidVisibleLegendItemsResult = createSelector(
    [this.selectFlatListOfLegendItemsResult],
    (legendItemsResult) =>
      legendItemsResult.map(
        filter((item) => item.isDataSource() && item.visible)
      )
  )

  selectAllValidVisibleGroupLegendItemsResult = createSelector(
    [this.selectFlatListOfLegendItemsResult],
    (legendItemsResult) =>
      legendItemsResult.map(
        filter((item) => item.isFromGroup() && item.visible)
      )
  )

  selectValidSourceLegendItemsResult = createSelector(
    [this.selectAllValidVisibleLegendItemsResult],
    (legendItemsResult) => {
      return legendItemsResult.map(take(SOURCE_LIMIT))
    }
  )

  onChangeTimeRange = ({ startTime, endTime, timezone: newTimezone }) => {
    const { dispatchUpdateQueryParams, timezone: currentTimezone } = this.props
    let start = moment(startTime)
    if (moment(endTime).diff(startTime, 'month') > 6) {
      start = moment(endTime).subtract(6, 'month')
    }
    dispatchUpdateQueryParams(this.props, {
      startTime: new Date(start.format()),
      endTime,
      timezone: newTimezone || currentTimezone,
      page: 1,
    })
  }
}

function createMapStateToProps() {
  const selectDataSourcesResultFromGraphQLResult = createSelectGraphQLResult(
    'dataSources',
    {
      mapResult: parseGraphQLResult,
      nullObject: null,
    }
  )

  const selectGroupsResultFromGraphQLResult = createSelectGraphQLResult(
    'pagedGroups',
    {
      mapResult: parseGraphQLResult,
      nullObject: null,
    }
  )

  const selectDataSourcesAndGroupsResult = createSelector(
    [
      selectGroupsResultFromGraphQLResult,
      selectDataSourcesResultFromGraphQLResult,
    ],
    (groupSourcesResult, dataSourcesResult) => {
      if (
        dataSourcesResult.wasSuccessful() &&
        groupSourcesResult.wasSuccessful()
      ) {
        const mergedData = mergeSourceData(
          dataSourcesResult,
          groupSourcesResult
        )

        const sourceItemsNoGroupOfGroups = mergedData.data.reduce(
          (acc, sourceItem) => {
            if (
              sourceItem instanceof MixedGroupDetailsType &&
              sourceItem.members.type === Device.CONFIGURATION_SOURCE_GROUP_TYPE
            ) {
              acc = [...acc, ...sourceItem.members.data]
              return acc
            }
            acc.push(sourceItem)
            return acc
          },
          []
        )

        return AsyncResult.success(sourceItemsNoGroupOfGroups)
          .withMeta(dataSourcesResult.meta)
          .mergeMeta(groupSourcesResult.meta)
      }
      if (groupSourcesResult.wasFailure() || dataSourcesResult.wasFailure()) {
        return AsyncResult.fail([])
          .withMeta(dataSourcesResult.meta)
          .mergeMeta(groupSourcesResult.meta)
      }
      return AsyncResult.pending([])
        .withMeta(dataSourcesResult.meta)
        .mergeMeta(groupSourcesResult.meta)
    }
  )

  return function mapStateToProps() {
    return {
      selectDataSourcesAndGroupsResult,
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setZoomDragMode() {
      dispatch(DragCover.setDragMode(DragCover.ZOOM_DRAG_MODE))
    },
    dispatchNotifyDownloadsChanged() {
      dispatch(Changes.notifyChange(Changes.CSV_DOWNLOAD_CHANGES))
    },
    dispatchPush(pushParams) {
      dispatch(push(pushParams))
    },
  }
}

function flatMapWithChildren(item) {
  return flatten([
    item,
    ...(item.children || []).map((child) => {
      return flatMapWithChildren(child)
    }),
  ])
}

function parseMeasureBounds(item) {
  return item ? item.split('+').map((i) => moment(i).toDate()) : item
}

parseMeasureBounds.invert = (item) =>
  item ? item.map((i) => moment(i).toISOString()).join('+') : item

export default compose(
  requiresLogin,
  userPermissions,
  defineQueryParams(() => {
    return {
      page: {
        alias: 'p',
        default: 1,
        parseFunc: defineQueryParams.parseNumber,
      },
      perPage: {
        alias: 'pp',
        default: 10,
        parseFunc: defineQueryParams.parseNumber,
      },
      eventIds: {
        alias: 'ev',
        parseFunc: (result) => {
          let eventIds

          if (result) {
            eventIds = result
              .split('+')
              .map((id) => Number(String(id).trim()))
              .filter((id) => Number.isFinite(id))
          }

          return eventIds
        },
      },
      orderBy: {
        alias: 'ob',
        default: START_TIME_KEY,
        parseFunc: defineQueryParams.parseString,
      },
      orderDirection: {
        alias: 'od',
        default: ORDER_ASC,
        parseFunc: defineQueryParams.parseString,
      },
      timezone: { alias: 'tz', default: DEFAULT_TIMEZONE },
      startTime: {
        alias: 'from',
        default: DEFAULT_DATE_YESTERDAY,
        parseFunc: defineQueryParams.parseDate,
      },
      endTime: {
        alias: 'to',
        default: DEFAULT_DATE_NOW,
        parseFunc: defineQueryParams.parseDate,
      },
      measureBounds: {
        alias: 'measure',
        parseFunc: parseMeasureBounds,
      },
      rawDataSourceIds: {
        alias: 'd',
        default: [],
        parseFunc: defineQueryParams.parseArray,
      },
      hiddenLegendItemIds: {
        alias: 'h',
        default: [],
        parseFunc: defineQueryParams.parseArray,
      },
    }
  }),
  transformProps(() => ({ startTime, endTime }) => {
    const invalidDates = !startTime || !endTime

    return {
      startTime: invalidDates ? DEFAULT_DATE_YESTERDAY : startTime,
      endTime: invalidDates ? DEFAULT_DATE_NOW : endTime,
    }
  }),
  withDataSources(
    ({ deviceIds, dataSourceIds, networkAssetIds, groupIds }) => ({
      deviceIds,
      networkAssetIds,
      dataSourceIds,
      groupIds,
    })
  ),
  connect(createMapStateToProps, mapDispatchToProps),
  graphql(DATA_SOURCES_QUERY, {
    options: ({ deviceIds, networkAssetIds, groupIds }) => {
      return {
        fetchPolicy: 'network-only',
        variables: { deviceIds, networkAssetIds, groupIds },
      }
    },
    props: ({ data, ownProps }) => {
      const {
        selectDataSourcesAndGroupsResult,
        deviceIds,
        networkAssetIds,
        groupIds,
      } = ownProps

      return {
        refetchData: () => {
          data.refetch({
            deviceIds: deviceIds,
            networkAssetIds: networkAssetIds,
            groupIds: groupIds,
          })
        },
        dataSourcesAndGroupsResult: selectDataSourcesAndGroupsResult(
          data,
          ownProps
        ),
      }
    },
  }),
  withTranslation([
    'src/analysis_path/pressure_analysis_path/index_page',
    'common/text',
  ])
)(PressureAnalysisPage)
