import moment from 'moment'
import i18next from 'i18next'
import XhrBackend from 'i18next-xhr-backend'

import * as analytics from '@@src/analytics'
import { SECONDS, sleep } from '@@src/utils'

export default function createI18nInstance({ language } = {}) {
  const i18n = i18next.createInstance()

  if (process.env.MARK_TRANSLATIONS) {
    // might be better to have it as a post-processor, e.g:
    // https://github.com/i18next/i18next-sprintf-postProcessor
    //
    // but couldn't get it to work?
    const t = i18n.t.bind(i18n)
    i18n.t = (...args) => `|{${t(...args)}}|`
  }

  return new Promise((resolve, reject) => {
    i18n.use(XhrBackend).init({
      ns: ['common/errors'],
      lng: language || 'en',
      debug: process.env.NODE_ENV !== 'production',
      defaultNS: 'common/text',
      fallbackNS: 'common/text',
      fallbackLng: 'en',

      interpolation: {
        // trust React to handle escaping
        escapeValue: false,
        format(value, format, _lang) {
          if (value instanceof Date) {
            return moment(value).format(format)
          } else {
            return value
          }
        },
      },

      backend: {
        parse: data => data,
        loadPath: '{{lng}}/{{ns}}',

        ajax: createBackendAjaxFunc({
          totalTries: 5,
          waitInterval: 3 * SECONDS,
        }),
      },

      react: {
        useSuspense: true,
      },
    }, err => {
      if (err) {
        reject(err)
      } else {
        resolve(i18n)
      }
    })
  })
}

export function createBackendAjaxFunc({
  waitInterval = 3 * SECONDS, importLocale = defaultImport, totalTries = 5,
} = {}) {
  return async (url, options, callback) => {
    let tries = 1
    let keepTrying = true
    while (keepTrying) {
      try {
        const locale = await importLocale(url)

        keepTrying = false
        callback(locale.default, { status: '200' })
      } catch (err) {
        if (tries++ >= totalTries) {
          analytics.logError(err)

          keepTrying = false
          callback(null, { status: '404' })
        } else {
          console.error(err) // eslint-disable-line no-console

          await sleep(waitInterval)
        }
      }
    }
  }
}

function defaultImport(url) {
  return import(`../locales/${url}.json`)
}
