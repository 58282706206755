import React, { useState } from 'react'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import {
  Col, Row, Container, Breadcrumb, BreadcrumbItem,
} from 'reactstrap'
import { Redirect } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { withWindowSizes } from '../../components/window_size_provider'

import requiresLogin from '@@src/components/security/requires_login'
import AppLayout from '@@src/components/app_layout'
import routes from '@@src/routes'
import GroupList from '@@src/management_path/groups_path/group_list/group_list'
import CreateGroupButtonAndModal from
'@@src/management_path/groups_path/create_group_button_and_modal'

function GroupsPage({ t, location, isMobile }) {
  const [newGroupData, setNewGroupData] = useState({
    newGroupId: null,
    newlyCreatedGroupId: null,
  })

  const successfullyCreatedGroup = groupId => {
    setNewGroupData({ ...newGroupData, newGroupId: groupId })
  }

  const handleCreatedAndCreateAnother = groupId => {
    setNewGroupData({ ...newGroupData, newlyCreatedGroupId: groupId })
  }

  if (newGroupData.newGroupId !== null) {
    return (
      <Redirect to={{
        pathname: routes.managementGroupsDetailPath(newGroupData.newGroupId),
      }}/>
    )
  }

  const classnames = isMobile ?
    'd-flex align-items-center flex-row justify-content-between' :
    'mb-3 d-flex align-items-center flex-row justify-content-between'

  return (
    <AppLayout
      title={t('headings.page_title')}
      contentsStyle="fixed-at-full-height">
      <Container className="h-100 container-fluid" name="container-devices">
        <Col sm="12" lg="9" className="d-flex flex-column h-100 pt-lg-5 pt-sm-4">
          <Breadcrumb>
            <BreadcrumbItem>
              {t('buttons.management')}
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Link to={routes.managementGroupsPath()}>
                {t('buttons.management_groups')}
              </Link>
            </BreadcrumbItem>
          </Breadcrumb>
          <Row>
            <Col className={'d-flex m-0 ' + classnames}>
              <h1>
                {t('text.title')}
              </h1>

              <CreateGroupButtonAndModal
                handleSuccessfullyCreatedNewGroup={
                  successfullyCreatedGroup
                }
                handleCreatedAndCreateAnother={
                  handleCreatedAndCreateAnother
                } />
            </Col>
          </Row>
          <GroupList
            isMobileDevice={isMobile}
            location={location}
            newlyCreatedGroupId={newGroupData.newlyCreatedGroupId}
          />
        </Col>
      </Container>
    </AppLayout>
  )
}

export default compose(
  requiresLogin,
  withTranslation([
    'src/management_path/groups_path/index_page',
    'common/text',
  ]),
  withWindowSizes,
)(GroupsPage)
