import DeviceIssue from './device_issue'
import {
  translateLastCommunication as translateLastCommunicationUtil,
} from '@@src/utils'
import { mapAssetTypeToLogicalChannels } from '../../utils'

export default class NetworkAssetListItem {
  constructor(data) {
    Object.assign(this, data)

    this.uuid = `a.${data.assetId}`
    this.assetName = data.name || ''
    this.assetType = data.type

    this.lastCommunicationTime = data.lastCommunicationTime
      ? new Date(data.lastCommunicationTime)
      : null

    switch(true) {
      case this.hasActiveError: {
        this.mostSevereDeviceIssue = new DeviceIssue({ severity: DeviceIssue.ERROR_SEVERITY })
        break
      }
      case this.hasActiveWarning: {
        this.mostSevereDeviceIssue = new DeviceIssue({ severity: DeviceIssue.WARNING_SEVERITY })
        break
      }
      case this.hasActiveInformation: {
        this.mostSevereDeviceIssue = new DeviceIssue({ severity: DeviceIssue.INFORMATION_SEVERITY })
        break
      }
      default: this.mostSevereDeviceIssue = null
    }

    Object.freeze(this)
  }

  getInstallationsForChannels() {
    return mapAssetTypeToLogicalChannels(this.assetType).map(channel => {
      return {
        channel: channel,
        installation: this.installedDevices.find(device => device.logicalChannel === channel),
      }
    })
  }

  translateLastCommunication(t) {
    return translateLastCommunicationUtil(t, this.lastCommunicationTime)
  }

  translateAssetType(t) {
    return t(`common/text:text.${this.assetType}`)
  }
}
