import React from 'react'
import classnames from 'classnames'
import { NavLink } from 'react-router-dom'
import { NavItem } from 'reactstrap'

import styles from './app_nav_item_link.css'

export default function AppNavLink(props) {
  const activeClassnames = classnames('active', styles['nav-link-active'])

  return (
    <NavItem>
      <NavLink
        {...props}
        activeClassName={activeClassnames}
        className={classnames('nav-link', props.className)}>
        {props.children}
      </NavLink>
    </NavItem>
  )
}
