import React from 'react'
import PropTypes from 'prop-types'
import { omit } from 'lodash/fp/object'
import { createSelector } from 'reselect'
import { FormFeedback, FormText, FormGroup, Input, Label } from 'reactstrap'

import styles from './app_form_group.css'

const omitNonInputProps = omit([
  't', 'children', 'label', 'fieldName', 'errorText', 'className',
  'renderInput', 'maxLength',
])

export default class AppFormGroup extends React.Component {
  static propTypes = {
    hint: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    innerRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    onChange: PropTypes.func.isRequired,
    className: PropTypes.string,
    errorText: PropTypes.string,
    renderInput: PropTypes.func,
  }

  static defaultProps = {
    renderInput: a => a,
  }

  render() {
    const {
      t, children, id, required, formFields, fieldName, label, hint,
      value, onChange, errorText, innerRef, className, renderInput,
      maxLength,
    } = this.props
    const error = errorText || formFields && t(formFields.errorFor(fieldName))

    const inputProps = {
      value: value || formFields && formFields.valueFor(fieldName),
      invalid: !!error,
      children,
      innerRef: innerRef,
      maxLength: maxLength || 200,
      onChange: onChange ||
        formFields && formFields.onChangeHandlerFor(fieldName),
      ...this.selectInputProps(this.props),
    }

    return (
      <FormGroup className={className}>
        {
          label ? (
            <Label for={id}>
              {label}
              { required ? <span className={styles.required}>*</span> : null }
            </Label>
          ) : null
        }

        {renderInput(
          <React.Fragment>
            <Input {...inputProps}/>

            {
              error ? (
                <FormFeedback name="input-error">
                  {error}
                </FormFeedback>
              ) : null
            }

            {
              hint ? (
                <FormText name="input-hint">{hint}</FormText>
              ) : null
            }
          </React.Fragment>
        )}
      </FormGroup>
    )
  }

  selectInputProps = createSelector([props => props], omitNonInputProps)
}
