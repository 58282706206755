import React from 'react'
import PropTypes from 'prop-types'
import {
  Modal, ModalHeader, ModalBody, ModalFooter, Button,
} from 'reactstrap'
import { compose } from 'redux'
import gql from 'graphql-tag'
import { withTranslation } from 'react-i18next'
import classnames from 'classnames'
import { get } from 'lodash/fp/object'
import { noop } from 'lodash/fp/util'
import { createSelector } from 'reselect'
import { connect } from 'react-redux'

import { AsyncResult } from '@@src/utils'
import ErrorInfo from '@@src/components/error_info'
import PagedData from '@@src/api/presenters/paged_data'
import { MixedGroupDetailsType } from '@@src/api/presenters/group'
import { sleep } from '@@src/utils'
import AsyncResultSwitch from '@@src/components/async_result_switch'
import GroupTypeIcon from '@@src/components/icons/group_type_icon'
import * as analytics from '@@src/analytics'
import InfiniteScrollList from '@@src/components/lists/infinite_scroll_list'
import DebouncedInputController from '@@src/utils/debounced_input_controller'
import incrementalPaginatedRequests
from '@@src/components/pagination/incremental_paginated_request_container'
import GroupsBreadcrumbs from
'@@src/components/modals/move_groups_modal/groups_breadcrumbs'
import MoveUpHierarchyButton from
'@@src/components/modals/move_groups_modal/move_up_hierarchy_button'
import GroupMemberListItem from
'@@src/components/modals/move_groups_modal/group_member_list_item'
import GroupSearch from '@@src/components/modals/move_groups_modal/group_search'
import withMoveGroupsProvider from
'@@src/components/modals/move_groups_modal/move_groups_provider'
import MoveSubmitButton from
'@@src/components/modals/move_groups_modal/move_submit_button'

import moveGroupModalStyles from './move_group_modal.css'
import moveModalStyles from './move_modal.css'

const INFLOWNET_WAIT = process.env.NODE_ENV === 'test' ? 0 : 1000

class MoveGroupModal extends React.PureComponent {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    selectDestinationSiblings: PropTypes.func.isRequired,
    selectDestinationId: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    refetchDestinationGroup: PropTypes.func.isRequired,
    refetchTopLevelPagedGroups: PropTypes.func.isRequired,
    group: PropTypes.instanceOf(MixedGroupDetailsType).isRequired,
    destinationGroupResult: PropTypes.instanceOf(AsyncResult).isRequired,
    topLevelPagedGroupsResult: PropTypes.instanceOf(AsyncResult).isRequired,
    onMoveGroup: PropTypes.func.isRequired,
    viewTopLevelGroups: PropTypes.func.isRequired,
    moveToHierarchy: PropTypes.func.isRequired,
    moveToTopLevel: PropTypes.func.isRequired,
    getHandleNavigateGroupHierarchy: PropTypes.func.isRequired,
    setInitialMoveGroupsState: PropTypes.func.isRequired,
    renderNoGroupSiblings: PropTypes.func.isRequired,
    renderFailure: PropTypes.func.isRequired,
    viewingTopLevelGroups: PropTypes.bool.isRequired,
    destinationGroupId: PropTypes.number,
    handleToggle: PropTypes.func,
    className: PropTypes.string,
  }

  static defaultProps = {
    isOpen: false,
    onMoveGroup: noop,
    refetchDestinationGroup: noop,
    refetchTopLevelPagedGroups: noop,
    destinationGroupResult: AsyncResult.success([]),
    topLevelPagedGroupsResult: AsyncResult.success([]),
  }

  searchInputController = new DebouncedInputController()

  constructor(props) {
    super(props)

    this.state = this.initialState()
  }

  initialState() {
    return {
      result: AsyncResult.notFound(),
      searchValue: '',
    }
  }

  render() {
    const {
      t, className, isOpen, destinationGroupResult, group,
      topLevelPagedGroupsResult, fetchNextPagedGroupsResult,
      viewingTopLevelGroups, renderFailure,
    } = this.props
    const { result } = this.state
    const isSubmitDisabled = this.selectIsSubmitDisabled(this.props, this.state)

    return (
      <Modal
        className={className}
        isOpen={isOpen}
        toggle={this.handleToggle}>
        <ModalHeader className="border-bottom-0" toggle={this.handleToggle}>
          {t('headings.title')} - {group.name}
        </ModalHeader>
        <ModalBody className="p-0">
          {
            result.wasFailure() ?
              <ErrorInfo className="mx-3 mt-3" error={result.error} />
              : null
          }
          {
            viewingTopLevelGroups ?
              <React.Fragment>
                {this.renderTopLevelGroupsSearch()}

                <InfiniteScrollList
                  name="top-level-groups-list"
                  result={topLevelPagedGroupsResult}
                  className={classnames(
                    'list-unstyled mb-0 d-flex flex-column',
                    moveGroupModalStyles['siblings-list'],
                  )}
                  fetchNextPage={fetchNextPagedGroupsResult}>
                  {topLevelPagedGroupsResult.data.data.map(
                    this.renderGroupMember
                  )}
                </InfiniteScrollList>
              </React.Fragment> :
              <AsyncResultSwitch
                renderFailResult={renderFailure}
                result={destinationGroupResult}
                renderSuccessResult={this.renderHierarchicalGroups} />
          }
        </ModalBody>
        <ModalFooter>
          <Button
            name="cancel-button"
            type="button"
            color="secondary"
            onClick={this.handleToggle}>
            {t('buttons.cancel')}
          </Button>
          <MoveSubmitButton
            isDisabled={isSubmitDisabled}
            result={result}
            handleSubmit={this.handleSubmit}>
            {t('buttons.move_here')}
          </MoveSubmitButton>
        </ModalFooter>
      </Modal>
    )
  }

  renderHierarchicalGroups = ({ data: group }) => {
    const {
      selectDestinationSiblings,
      selectDestinationId,
      viewTopLevelGroups,
      getHandleNavigateGroupHierarchy,
      renderNoGroupSiblings,
    } = this.props
    const siblings = selectDestinationSiblings(this.props)
    const destinationId = selectDestinationId(this.props)

    return (
      <React.Fragment>
        <GroupsBreadcrumbs
          className={classnames(
            'px-3 rounded-0',
            moveModalStyles['item-border-bottom']
          )}
          handleViewTopLevelGroups={viewTopLevelGroups}
          getHandleNavigate={getHandleNavigateGroupHierarchy}
          group={group} />

        <MoveUpHierarchyButton
          handleClick={
            destinationId !== -1 ?
              getHandleNavigateGroupHierarchy(destinationId) :
              viewTopLevelGroups
          }
          buttonClassName={classnames(
            moveGroupModalStyles['group-item'],
            moveModalStyles['group-item-button'],
            moveModalStyles['group-item-active'],
          )}
          className={classnames(
            moveModalStyles['item-border-bottom'],
            'd-flex flex-column',
          )} />

        <ul name="hierarchical-groups-list" className={classnames(
          'list-unstyled mb-0 d-flex flex-column',
          moveGroupModalStyles['siblings-list'],
        )}>
          {
            siblings.length > 0 ?
              siblings.map(this.renderGroupMember) :
              renderNoGroupSiblings(moveGroupModalStyles['group-item'])
          }
        </ul>
      </React.Fragment>
    )
  }

  renderTopLevelGroupsSearch = () => {
    const {
      topLevelPagedGroupsResult,
      getHandleNavigateGroupHierarchy,
      viewTopLevelGroups,
    } = this.props
    const { searchValue } = this.state

    return (
      <React.Fragment>
        <GroupsBreadcrumbs
          className={classnames(
            'px-3 rounded-0',
            moveModalStyles['item-border-bottom']
          )}
          viewingTopLevelGroups={true}
          handleViewTopLevelGroups={viewTopLevelGroups}
          getHandleNavigate={getHandleNavigateGroupHierarchy} />
        <GroupSearch
          controller={this.searchInputController}
          value={searchValue}
          name="search-top-level-groups"
          className={classnames(
            moveGroupModalStyles['group-item'],
            'px-3 py-2 bg-light', {
              [moveModalStyles['item-border-bottom']]:
                topLevelPagedGroupsResult.wasSuccessful() &&
                  topLevelPagedGroupsResult.data.pagination.totalResults !== 0,
            }
          )}
          handleChange={this.handleSearchGroups} />
      </React.Fragment>
    )
  }

  renderGroupMember = (group, i, members) => {
    const { getHandleNavigateGroupHierarchy } = this.props
    const isGroupOfGroups = group.isGroupOfGroups()
    const buttonClassName = classnames(
      moveGroupModalStyles['group-item'],
      moveModalStyles['group-item-button'],
      'w-100 rounded-0 py-1',
      {
        [moveModalStyles['group-item-active']]: isGroupOfGroups,
      },
    )
    const className = classnames({
      [moveModalStyles['item-border-bottom']]: i !== members.length - 1,
    })

    return (
      <GroupMemberListItem
        key={group.dataSourceId}
        isDisabled={!isGroupOfGroups}
        handleClick={
          isGroupOfGroups ?
            getHandleNavigateGroupHierarchy(group.id) : undefined
        }
        className={className}
        buttonClassName={buttonClassName}>
        <div className="d-flex align-items-center text-dark">
          <GroupTypeIcon className="fa-2x" group={group} />
          <span className="ml-3 d-flex flex-column align-items-start">
            {group.name}
          </span>
        </div>
        {
          isGroupOfGroups ?
            <span className="far fa-chevron-right text-secondary" /> : null
        }
      </GroupMemberListItem>
    )
  }

  handleToggle = (...args) => {
    const {
      handleToggle,
      destinationGroupId,
      refetchDestinationGroup,
      setInitialMoveGroupsState,
    } = this.props

    handleToggle(...args)
    refetchDestinationGroup({ id: destinationGroupId })

    this.searchInputController.dispatchClearValueEvent()
    this.setState(this.initialState())
    setInitialMoveGroupsState()
  }

  handleSearchGroups = ev => {
    const { refetchTopLevelPagedGroups, group } = this.props

    this.setState({
      searchValue: ev.target.value.substring(0, 200),
    })

    refetchTopLevelPagedGroups({
      pageNumber: 1,
      searchQuery: ev.target.value.substring(0, 200),
      childFilterId: group.id,
    })
  }

  handleSubmit = async () => {
    const {
      onMoveGroup,
      handleToggle,
      viewingTopLevelGroups,
      moveToTopLevel,
      moveToHierarchy,
      group,
      destinationGroupResult,
      destinationGroupId,
    } = this.props

    try {
      this.setState({ result: AsyncResult.pending() })

      if (viewingTopLevelGroups) {
        await moveToTopLevel({
          parentGroupId: Number(destinationGroupId),
          groupMemberIds: [Number(group.id)],
        })
      } else {
        await moveToHierarchy({
          groupIds: [Number(group.id)],
          newParentGroupId: Number(destinationGroupResult.data.id),
        })
      }

      await sleep(INFLOWNET_WAIT)
      await onMoveGroup()
      await handleToggle()
    } catch (err) {
      analytics.logError(err)
      this.setState({ result: AsyncResult.fail(err) })
    }
  }

  selectIsSubmitDisabled = createSelector(
    [
      get('destinationGroupResult'),
      get('destinationGroupId'),
      get('topLevelPagedGroupsResult'),
      get('t'),
      get('viewingTopLevelGroups'),
    ],
    (
      destinationResult,
      destinationGroupId,
      topLevelPagedGroupsResult,
      t,
      viewingTopLevelGroups,
    ) => {
      if ((!viewingTopLevelGroups && destinationResult.isPending()) ||
        (topLevelPagedGroupsResult.isPending() && viewingTopLevelGroups)) {
        return true
      }

      if (viewingTopLevelGroups && destinationGroupId === -1 ||
        (destinationResult.wasSuccessful() && !viewingTopLevelGroups &&
          String(destinationGroupId) === String(destinationResult.data.id))) {
        return true
      }

      return false
    }
  )

  static TOP_LEVEL_PAGED_GROUP_QUERY = gql`
    query PagedGroups (
      $pageNumber: Int!,
      $searchQuery: String,
      $childFilterIds: [Int],
      $showOnlyGroupsWithNoParent: Boolean,
      $skipPagedGroups: Boolean!
    ) {
      pagedGroups(
        searchQuery: $searchQuery,
        pageNumber: $pageNumber,
        resultsPerPage: 10,
        childFilterIds: $childFilterIds,
        showOnlyGroupsWithNoParent: $showOnlyGroupsWithNoParent
      ) @skip(if: $skipPagedGroups) {
        groups {
          id
          name
          category
          members {
            type
            count
            data {
              ... on MixedGroupDetailsType {
                id
                name
                category
              }
            }
          }
        }
        pagination {
          perPage
          pageNumber
          totalPages
          totalResults
        }
      }
    }
  `
}

function createMapStateToProps() {
  const selectDestinationGroupId = createSelector([get('group')], group => {
    if (Array.isArray(group.ancestry) && group.ancestry.length > 0) {
      return group.ancestry.slice(0).sort((a, b) => b.index - a.index)[0].id
    }

    return -1
  })

  return function mapStateToProps(state, ownProps) {
    return {
      destinationGroupId: selectDestinationGroupId(ownProps),
    }
  }
}

export default compose(
  connect(createMapStateToProps),
  withMoveGroupsProvider,
  incrementalPaginatedRequests(MoveGroupModal.TOP_LEVEL_PAGED_GROUP_QUERY, {
    options: ({ group, isOpen }) => ({
      variables: {
        pageNumber: 1,
        childFilterIds: [group.id],
        skipPagedGroups: !isOpen,
      },
    }),
    mergeData: (a, b) => new PagedData({
      ...b,
      data: a.data.concat(b.data),
    }),
    methodName: 'pagedGroups',
    nullObject: { data: [], map: () => {}, pagination: { totalResults: 0 } },
    resultFunc: ({ result, refetch, fetchNext }) => {
      return {
        topLevelPagedGroupsResult: result,
        fetchNextPagedGroupsResult: fetchNext,
        refetchTopLevelPagedGroups: refetch,
      }
    },
  }),
  withTranslation([
    'src/components/modals/move_groups_modal/move_group_modal',
    'common/text',
  ]),
)(MoveGroupModal)
