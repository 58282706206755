import React from 'react'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import { push } from 'connected-react-router'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { Alert, Button, Card, Form } from 'reactstrap'
import * as analytics from '@@src/analytics'
import routes from '@@src/routes'
import AppLayout from '@@src/components/app_layout'
import ErrorInfo from '@@src/components/error_info'
import AppFormGroup from '@@src/components/forms/app_form_group'
import PureComponentWithAutofocus from '@@src/components/pure_component_with_autofocus'
import SubmitButton from '@@src/components/buttons/submit_button'
import { AppError, AsyncResult, FormFields, MINUTES } from '@@src/utils'
import BrowserWarning from '@@src/components/app_layout/browser_warning'
import { withAuthorization } from '../_v2/contexts/authorization/authorization.context'
import { withUser } from '../_v2/contexts/user/user.context'

import styles from './index_page.css'

const SECONDS_TO_RETRY_SMS = 20

class LoginPage extends PureComponentWithAutofocus {
  static defaultProps = {
    secondsToRetrySms: SECONDS_TO_RETRY_SMS,
    eulaLicenseTimeout: 15 * MINUTES,
  }

  static propTypes = {
    secondsToRetrySms: PropTypes.number.isRequired,
    eulaLicenseTimeout: PropTypes.number.isRequired,
  }

  render() {
    const { isLogged, authorizedUser, location } = this.props
    const { challenge } = this.state

    if (isLogged()) {
      if (authorizedUser.role === 'administrator') {
        return (
          <Redirect
            to={{
              pathname: routes.adminUsersPath(),
            }}
          />
        )
      }

      const { returnTo } = queryString.parse(location.search)
      if (returnTo && !returnTo.match(/login/)) {
        const [pathname, search] = returnTo.split('?')

        return (
          <Redirect
            to={{
              search: search ? '?' + search : undefined,
              pathname,
            }}
          />
        )
      } else {
        return (
          <Redirect
            to={{
              pathname: routes.homePath(),
            }}
          />
        )
      }
    }

    switch (challenge.name) {
      case 'NEW_PASSWORD_REQUIRED':
        return this.renderForceChangePasswordChallenge()

      case 'SMS_MFA':
        return this.renderSmsAuthenticationChallenge()

      default:
        return this.renderLogin()
    }
  }

  renderLogin() {
    const { t, redirectToForgottenPasswordPage } = this.props
    const { result, invalidMagicToken } = this.state

    return (
      <div className={styles.container}>
        <BrowserWarning />
        <AppLayout
          title={t('headings.page_title')}
          footerColor="dark"
          backgroundClassname={styles.background}
        >
          <Card className={styles['login-form-card']}>
            <h2 className={styles.heading}>{t('headings.login')}</h2>

            <Form onSubmit={this.onSubmitLoginForm}>
              {result.wasFailure() ? <ErrorInfo error={result.error} /> : null}
              {invalidMagicToken ? (
                <Alert color="danger">{t('errors.invalid_magic_token')}</Alert>
              ) : null}

              <AppFormGroup
                id="user-email"
                type="email"
                name="email"
                label={t('labels.email')}
                value={this.selectLoginFieldValue('email')}
                innerRef={this.getAutofocusTargetRef()}
                onChange={this.loginFields.onChangeHandlerFor('email')}
                errorText={this.selectLoginFieldErrorText('email')}
              />

              <AppFormGroup
                id="user-password"
                type="password"
                name="password"
                label={t('labels.password')}
                value={this.selectLoginFieldValue('password')}
                onChange={this.loginFields.onChangeHandlerFor('password')}
                errorText={this.selectLoginFieldErrorText('password')}
              />

              <SubmitButton
                name="login-button"
                className={styles['login-button']}
                buttonStatus=""
                color="primary"
                onSubmitForm={this.onSubmitLoginForm}
                result={this.state.result}
                disabled={this.loginFields.hasAnyValidationErrors()}
                submitText={t('buttons.login')}
              ></SubmitButton>

              <Button
                type="button"
                color="link"
                onClick={redirectToForgottenPasswordPage}
                block
              >
                {t('buttons.forgot_password')}
              </Button>
            </Form>
          </Card>
        </AppLayout>
      </div>
    )
  }

  renderForceChangePasswordChallenge() {
    const { t } = this.props
    const { challenge } = this.state

    return (
      <AppLayout footerColor="dark" backgroundClassname={styles.background}>
        <Card className={styles['login-form-card']}>
          <h2 className={styles.heading}>
            {t('headings.new_password_required')}
          </h2>

          <div className={styles['password-requirements']}>
            <h6>{t('headings.password_requirements')}</h6>

            <ul className={styles['password-requirements-list']}>
              <li>{t('text.8_characters_minimum')}</li>
              <li>{t('text.upper_and_lowercase_letters')}</li>
              <li>{t('text.one_numerical_character')}</li>
              <li>{t('text.one_special_character')}</li>
            </ul>
          </div>

          <Form onSubmit={this.onSubmitNewPasswordForm}>
            {challenge.result.wasFailure() ? (
              <ErrorInfo error={challenge.result.error} />
            ) : null}

            <AppFormGroup
              id="new-password"
              type="password"
              name="new-password"
              label={t('labels.new_password')}
              value={this.selectNewPasswordFieldValue('newPassword')}
              innerRef={this.getAutofocusTargetRef()}
              onChange={this.newPasswordFields.onChangeHandlerFor(
                'newPassword',
              )}
              errorText={this.selectNewPasswordFieldErrorText('newPassword')}
            />

            <AppFormGroup
              id="new-password-confirmation"
              type="password"
              name="new-password-confirmation"
              label={t('labels.new_password_confirmation')}
              value={this.selectNewPasswordFieldValue(
                'newPasswordConfirmation',
              )}
              onChange={this.newPasswordFields.onChangeHandlerFor(
                'newPasswordConfirmation',
              )}
              errorText={this.selectNewPasswordFieldErrorText(
                'newPasswordConfirmation',
              )}
            />

            <SubmitButton
              name="change-password-button"
              buttonStatus=""
              className={styles['login-button']}
              color="primary"
              onSubmitForm={this.onSubmitNewPasswordForm}
              result={challenge.result}
              disabled={this.newPasswordFields.hasAnyValidationErrors()}
              submitText={t('buttons.update_password')}
            ></SubmitButton>
          </Form>
        </Card>
      </AppLayout>
    )
  }

  renderSmsAuthenticationChallenge() {
    const { t } = this.props
    const { challenge, timeToRetrySms } = this.state

    // assumption is that only SMS is supported
    const phoneNumber = challenge.meta.destination

    return (
      <AppLayout backgroundClassname={styles.background}>
        <Card className={styles['login-form-card']}>
          <h2 className={styles.heading}>
            {t('headings.sms_mfa_code_required')}
          </h2>

          <p>{t('text.sms_sent', { phoneNumber })}</p>

          <Form onSubmit={this.onSubmitSmsCode}>
            {challenge.result.wasFailure() ? (
              <ErrorInfo error={challenge.result.error} />
            ) : null}

            <AppFormGroup
              id="sms-code"
              type="text"
              hint={t('text.sms_code_hint')}
              name="sms-code"
              label={t('labels.sms_code')}
              value={this.selectMfaFieldValue('smsCode')}
              innerRef={this.getAutofocusTargetRef()}
              onChange={this.mfaFields.onChangeHandlerFor('smsCode')}
              errorText={this.selectMfaFieldErrorText('smsCode')}
            />

            <SubmitButton
              name="submit-mfa-code-button"
              buttonStatus=""
              className={styles['login-button']}
              color="primary"
              onSubmitForm={this.onSubmitSmsCode}
              result={challenge.result}
              submitText={t('buttons.submit_mfa_code')}
            ></SubmitButton>

            <span className={styles['did-not-receive-code-text']}>
              {t('text.did_not_receive_code')}
            </span>

            <Button
              name="send-new-sms-code-button"
              block
              type="button"
              color="link"
              disabled={timeToRetrySms !== 0}
              onClick={this.onClickSendNewSmsCode}
            >
              {timeToRetrySms === 0
                ? t('buttons.send_new_sms_code')
                : t('buttons.send_new_sms_code_wait', {
                  count: timeToRetrySms,
                })}
            </Button>
          </Form>
        </Card>
      </AppLayout>
    )
  }

  constructor(props) {
    super(props)

    this.loginFields = new FormFields(this, 'loginFields', {
      email: (v) => (v ? '' : 'errors.required'),
      password: (v) => (v ? '' : 'errors.required'),
    })

    this.newPasswordFields = new FormFields(this, 'newPasswordFields', {
      newPassword: (v) => (v ? '' : 'errors.required'),
      newPasswordConfirmation: (newPasswordConfirmation, { newPassword }) => {
        if (
          newPasswordConfirmation &&
          newPassword &&
          newPasswordConfirmation !== newPassword
        ) {
          return 'errors.password_confirmation_not_matching'
        } else if (!newPasswordConfirmation) {
          return 'errors.required'
        } else {
          return ''
        }
      },
    })

    this.mfaFields = new FormFields(this, 'mfaFields', {
      smsCode: (v) => (v ? '' : 'errors.required'),
    })

    this.state = {
      result: AsyncResult.notFound(),
      challenge: {
        result: AsyncResult.notFound(),
      },
      mfaFields: this.mfaFields.initialState(),
      loginFields: this.loginFields.initialState(),
      timeToRetrySms: -1,
      newPasswordFields: this.newPasswordFields.initialState(),
      timeToRetryTimeout: null,
      eulaLicenseTimeoutID: undefined,
      invalidMagicToken: false,
    }
  }

  async componentDidMount() {
    super.componentDidMount()

    const { location } = this.props
    const { magicToken } = queryString.parse(location.search)

    if (magicToken) {
      let email = ''
      let oneTimePassword = ''

      try {
        const match = decodeURIComponent(magicToken).match(/^(.+)-([^-]+)$/)

        email = atob(match[1])
        oneTimePassword = match[2]
      } catch (err) {
        this.setState({ invalidMagicToken: true })
      }

      if (email && oneTimePassword) {
        this.setState({ result: AsyncResult.pending() })

        await this.loginFields.updateFieldValue('email', email)
        await this.loginFields.updateFieldValue('password', oneTimePassword)

        this.onSubmitLoginForm({
          preventDefault: () => {
          },
        })
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.timeToRetrySms <= 0) {
      this.startTimeToRetrySmsCountdown()
    }
  }

  componentWillUnmount() {
    // Refresh on login will reload and autofill Freshdesk widget
    // refresh should only be performed if part of the login process
    if (this.state.result.wasSuccessful()) {
      window.location.reload()
    }
  }

  async startTimeToRetrySmsCountdown() {
    const sleep = (delay) =>
      new Promise((resolve) => setTimeout(resolve, delay))

    while (this.state.timeToRetrySms > 0) {
      await sleep(1000)

      this.setState({ timeToRetrySms: this.state.timeToRetrySms - 1 })
    }
  }

  selectLoginFieldValue(fieldName) {
    return this.loginFields.selectValue(this.state, fieldName)
  }

  selectLoginFieldErrorText(fieldName) {
    const { t } = this.props
    const error = this.loginFields.selectError(this.state, fieldName)

    return error ? t(error) : ''
  }

  selectNewPasswordFieldValue(fieldName) {
    return this.newPasswordFields.selectValue(this.state, fieldName)
  }

  selectNewPasswordFieldErrorText(fieldName) {
    const { t } = this.props
    const error = this.newPasswordFields.selectError(this.state, fieldName)

    return error ? t(error) : ''
  }

  selectMfaFieldValue(fieldName) {
    return this.mfaFields.selectValue(this.state, fieldName)
  }

  selectMfaFieldErrorText(fieldName) {
    const { t } = this.props
    const error = this.mfaFields.selectError(this.state, fieldName)

    return error ? t(error) : ''
  }

  onSubmitLoginForm = async (event) => {
    event.preventDefault()

    this.setState({ result: AsyncResult.pending() })

    try {
      const res = await this.loginFields.performPreSubmitChecks().catch(() => {
        return false
      })

      if (!res) {
        this.setState({ result: AsyncResult.notFound() })
        return
      }

      const email = String(this.selectLoginFieldValue('email')).toLowerCase()
      const password = this.selectLoginFieldValue('password')

      const optionalChallenge = await this.props.authorization
        .identityProvider.signIn(email, password)

      this.respondToCognitoUserChallenge(optionalChallenge)
    } catch (err) {
      analytics.logError(err)

      this.setState({ result: AsyncResult.fail(AppError.from(err)) })
    }
  }

  onSubmitNewPasswordForm = async (event) => {
    event.preventDefault()

    const { challenge } = this.state

    this.setState({
      challenge: {
        ...challenge,
        result: AsyncResult.pending(),
      },
    })

    try {
      const res = await this.newPasswordFields
        .performPreSubmitChecks()
        .catch(() => {
          return false
        })

      if (!res) {
        this.setState({
          challenge: {
            ...challenge,
            result: AsyncResult.notFound(),
          },
        })
        return
      }

      const newPassword = this.selectNewPasswordFieldValue('newPassword')
      const optionalChallenge = await this.props.authorization
        .identityProvider.completeNewPassword(newPassword)

      this.respondToCognitoUserChallenge(optionalChallenge)
    } catch (error) {
      analytics.logError(error)

      this.setState({
        challenge: {
          ...challenge,
          result: AsyncResult.fail(AppError.from(error)),
        },
      })
    }
  }

  onClickSendNewSmsCode = async (event) => {
    event.stopPropagation()

    const email = String(this.selectLoginFieldValue('email')).toLowerCase()
    const password =
      this.selectNewPasswordFieldValue('newPassword') ||
      this.selectLoginFieldValue('password')

    try {
      const optionalChallenge = await this.props.authorization
        .identityProvider.signIn(email, password)

      this.respondToCognitoUserChallenge(optionalChallenge)
    } catch (error) {
      analytics.logError(error)

      this.setState({ result: AsyncResult.fail(AppError.from(error)) })
    }
  }

  onSubmitSmsCode = async (event) => {
    event.preventDefault()

    const { challenge } = this.state

    this.setState({
      challenge: {
        ...challenge,
        result: AsyncResult.pending(),
      },
    })

    try {
      const res = await this.mfaFields.performPreSubmitChecks().catch(() => {
        return false
      })

      if (!res) {
        this.setState({
          challenge: {
            ...challenge,
            result: AsyncResult.notFound(),
          },
        })
        return
      }
      const mfaCode = this.selectMfaFieldValue('smsCode')
      const optionalChallenge = await this.props.authorization
        .identityProvider.completeSignInWithMFA(mfaCode)

      this.respondToCognitoUserChallenge(optionalChallenge)
    } catch (error) {
      analytics.logError(error)

      this.setState({
        challenge: {
          ...challenge,
          result: AsyncResult.fail(AppError.from(error)),
        },
      })
    }
  }

  respondToCognitoUserChallenge = async (challenge) => {
    if (challenge.success) {
      await this.props.reloadAuthorizedUser()
      return
    }

    switch (challenge.reason) {
      case 'NEW_PASSWORD_REQUIRED': {
        this.setState({
          challenge: {
            meta: challenge.meta,
            name: challenge.reason,
            result: AsyncResult.notFound(),
          },
          timeToRetrySms: this.props.secondsToRetrySms,
        })
        break
      }

      case 'SMS_MFA': {
        this.setState({
          challenge: {
            meta: challenge.meta,
            name: challenge.reason,
            result: AsyncResult.notFound(),
          },
          timeToRetrySms: this.props.secondsToRetrySms,
        })
        break
      }

      default: {
        console.warn('unrecognised challenge in response') // eslint-disable-line no-console, max-len
      }
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    redirectToForgottenPasswordPage() {
      dispatch(push(routes.loginPasswordRecoveryPath()))
    },
  }
}

export default compose(
  withAuthorization,
  withUser,
  withTranslation(['src/login_path/index_page', 'common/forms']),
  connect(null, mapDispatchToProps),
)(LoginPage)
