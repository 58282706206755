import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { GraphConfig } from '@@src/components/graphs/graph_context'
import classnames from 'classnames'
import styles from './horizontal_band.css'
import { area } from 'd3-shape'

export default class HorizontalBand extends PureComponent {
  static propTypes = {
    band: PropTypes.shape({
      yStart: PropTypes.number.isRequired,
      yEnd: PropTypes.number.isRequired,
      className: PropTypes.string,
    }).isRequired,
    graphConfig: PropTypes.instanceOf(GraphConfig).isRequired,
  }

  getPositionArea = (yStart, yEnd) => {
    const { graphConfig } = this.props
    const [yMin, yMax] = graphConfig.yScale.domain()
    const [yMinScaled, yMaxScaled] = graphConfig.yScale.range()
    const [xMinScaled, xMaxScaled] = graphConfig.xScale.range()

    if (!isFinite(yStart)) {
      yStart = yMin
    }

    // Use the maximum value if the band value isn't within range
    const yScaledStart = graphConfig.yScale(yStart)
    const yMinScaledStart = yStart < yMin ? yMinScaled : yScaledStart

    if (!isFinite(yEnd)) {
      yEnd = yMax
    }

    const yScaledEnd = graphConfig.yScale(yEnd)
    const yMaxScaledEnd = yEnd > yMax ? yMaxScaled : yScaledEnd

    return area().y0(yMaxScaledEnd)([
      [
        xMinScaled,
        yMaxScaledEnd,
      ],
      [
        xMinScaled,
        yMinScaledStart,
      ],
      [
        xMaxScaled,
        yMinScaledStart,
      ],
      [
        xMaxScaled,
        yMaxScaledEnd,
      ],
    ])
  }

  getPosition = ({ yStart, yEnd }) => {
    const { graphConfig } = this.props
    const [yMin, yMax] = graphConfig.yScale.domain()

    if (yEnd < yMin || yStart > yMax) {
      // Return empty if neither the band start and end is within the data range
      return ''
    } else {
      return this.getPositionArea(yStart, yEnd)
    }
  }

  render() {
    const { band } = this.props
    const position = this.getPosition(band)
    const bandClassnames = classnames({
      [band.className]: !!band.className,
      [styles['horizontal-band']]: true,
    })

    if (position) {
      return (
        <path
          name="horizontal-band"
          className={bandClassnames}
          d={position} />
      )
    } else {
      return null
    }
  }
}
