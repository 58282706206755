import { PAGED_SUBSET_QUERY } from '../../analysis_path/pressure_analysis_path/pressure_subset_graph/graphql'
import { useCallback, useEffect, useMemo, useState } from 'react'
import PagedPressureSubsetData from '../../api/presenters/paged_pressure_subset_data'
import { AsyncResult } from '../../utils'
import { MAX_SOURCES_PER_PAGE, MAX_RESOLUTION_PARTS } from './consts'
import { INTERNAL_PRESSURE_UNIT } from '../../utils/unit_constants'

const useGetPressureData = (props) => {
  const [pressureData, setPressureData] = useState({})
  const [loading, setLoading] = useState(0)
  const [totalParts, setTotalParts] = useState(0)
  const [error, setError] = useState('')

  // memoised result of formating the recieved pressure data
  const groupedPressureSubsetData = useMemo(() => {
    function orderTimeSeriesByTime(data) {
      return data.sort((a, b) => {
        if (!a.data[0]) {
          return 1
        }
        if (!b.data[0]) {
          return -1
        }
        return a.data[0].time - b.data[0].time
      })
    }

    return Object.entries(pressureData).map(([_sourceId, source]) => {
      const withOrderedTimeSeries = orderTimeSeriesByTime(source.data)

      const pressureSubsetData = [{ ...withOrderedTimeSeries[0] }]

      if (withOrderedTimeSeries.length === 1) {
        return pressureSubsetData
      }

      for (let i = 1; i < withOrderedTimeSeries.length; i++) {
        const first = pressureSubsetData[pressureSubsetData.length - 1]
        const second = withOrderedTimeSeries[i]

        if (
          !first.data[first.data.length - 1] ||
          !second.data[0] ||
          first.data[first.data.length - 1].time !== second.data[0].time
        ) {
          pressureSubsetData.push({ ...withOrderedTimeSeries[i] })
        } else {
          const arrayWithoutLastItem = [...pressureSubsetData[pressureSubsetData.length - 1].data]
          arrayWithoutLastItem.pop()
          pressureSubsetData[pressureSubsetData.length - 1].data = [
            ...arrayWithoutLastItem,
            ...withOrderedTimeSeries[i].data,
          ]
        }
      }

      return pressureSubsetData
    }).flat()
  }, [pressureData])

  const pressureSubsetDataResult = useMemo(() => {
    const pagedPressureSubsetData = (new PagedPressureSubsetData({
      data: groupedPressureSubsetData,
      timeSlicePagination: {
        pageNumber: loading + 1,
        totalPages: totalParts + 1,
      },
    })).withUnits(props.units ? props.units.pressure : INTERNAL_PRESSURE_UNIT)

    if (loading !== 0) {
      return AsyncResult.pending(pagedPressureSubsetData)
    }

    return AsyncResult.success(pagedPressureSubsetData)
  }, [groupedPressureSubsetData, props.units, loading, totalParts])

  const formatResults = useCallback((result) => {
    setPressureData(oldValue => {
      return result.data.pagedPressureSubsetData.data.reduce((acc, sourceData) => {
        const sourceId = formatSourceUniqueId(sourceData)
        if (acc[sourceId]) {
          return {
            ...acc,
            [sourceId]:
            {
              ...acc[sourceId],
              data: [...acc[sourceId].data, { ...sourceData }],
            },
          }
        }
        return { ...acc, [sourceId]: { data: [{ ...sourceData }] } }
      }, oldValue)
    })
  }, [setPressureData])

  const fetchData = useCallback(
    async (
      client,
      numberOfSources,
      start,
      end,
      resolution,
      deviceIds,
      networkAssetChannels,
      signal,
    ) => {
      const totalPages = Math.floor((numberOfSources / MAX_SOURCES_PER_PAGE) + 1)
      // create query list
      const queryList = []
      for (let i = 0; i < totalPages; i++) {
        queryList.push(
          client.query(
            {
              query: PAGED_SUBSET_QUERY,
              variables: {
                deviceIds,
                networkAssetChannels,
                resolution,
                start,
                end,
                pageNumber: i + 1,
                sourcesPerPage: MAX_SOURCES_PER_PAGE,
              },
              context: {
                fetchOptions: {
                  signal,
                },
              },
            }
          )
        )
      }

      setLoading((oldLoading) => oldLoading + totalPages)
      setTotalParts((oldTotalParts) => oldTotalParts + totalPages)

      let results = []
      // fetching multiple queries concurently
      try {
        results = await Promise.all(queryList)
      } catch (err) {
        setError(err)
      }

      // function for extractring data and placing in state
      results.forEach(result => formatResults(result))
      setLoading((loadingOld) => loadingOld - totalPages)
    }, [formatResults])

  const fetchPartialData = useCallback(async () => {
    const { start, end, resolution, client, deviceIds, networkAssetChannels, signal } = props
    const numberOfSources = deviceIds.length + networkAssetChannels.length
    let queryStart = Math.max(end - (MAX_RESOLUTION_PARTS * resolution * 1000), start)
    let queryEnd = end
    let maxLoops = 10000
    while (maxLoops > 0) {
      maxLoops--
      await fetchData(
        client,
        numberOfSources,
        queryStart,
        queryEnd,
        resolution,
        deviceIds,
        networkAssetChannels,
        signal,
      )
      if (queryStart === start) {
        break
      }
      queryEnd = queryStart
      queryStart = Math.max(queryEnd - (MAX_RESOLUTION_PARTS * resolution * 1000), start)
    }
  }, [
    props.start,
    props.end,
    props.resolution,
    props.client,
    props.deviceIds,
    props.networkAssetChannels,
    props.signal,
  ])

  useEffect(() => {
    if (Object.keys(props).length) {
      fetchPartialData()
      return () => {
        setLoading(0)
        setTotalParts(0)
        setPressureData({})
      }
    }
    return () => { }
  }, [props.start, props.end, props.resolution, props.client, props.deviceIds, props.networkAssetChannels])

  return { pressureSubsetDataResult, error, loading: loading !== 0 }
}

export function formatSourceUniqueId(source) {
  if (isSourceNetworkAsset(source)) {
    return formatNetworkAssetUniqueId(source)
  }
  return formatDeviceUniqueId(source)
}

function isSourceNetworkAsset(source) {
  if (source.networkAssetId) {
    return true
  }
  return false
}

function formatDeviceUniqueId(device) {
  return `d.${device.deviceId}`
}

export function formatNetworkAssetUniqueId(networkAsset) {
  return `a.${networkAsset.networkAssetId}.${networkAsset.channel}`
}

export default useGetPressureData
