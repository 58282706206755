export default class DeviceCommission {
  constructor(data) {
    Object.assign(this, data)

    this.start = new Date(data.start)
    this.end = data.end ? new Date(data.end) : null
    this.currentInstallation = data.currentInstallation || null

    Object.freeze(this)
  }

  get uuid() {
    const deviceId = this.device ? this.device.id : '???'

    return `commission:${deviceId}:${this.start.getTime()}`
  }

  wasActiveAtTime(timestamp) {
    return this.start <= timestamp && (!this.end || this.end >= timestamp)
  }

  wasActiveInPeriod(start, end) {
    return (this.start >= start && this.start <= end) ||
      (this.end && this.end >= start && this.end <= end) ||
      (start >= this.start && (!this.end || start <= this.end)) ||
      (end >= this.start && (!this.end || end <= this.end))
  }

  withDevice(device) {
    return DeviceCommission.from({
      ...this,
      device,
    })
  }

  static from(...args) {
    return new DeviceCommission(...args)
  }
}
