import React, { createContext } from 'react'
import { renderAbsolutePositionedLoading } from '../../../components/app_suspense_container'
import { AuthorizedUser } from '../../models/authorized-user'
import { useAuthorizedUser } from './hooks/use_authorized_user.hook'

export const UserContext = createContext({
  authorizedUser: AuthorizedUser.initNull(),
  isLogged: () => false,
  logout: () => Promise.resolve(null),
  reloadAuthorizedUser: () => Promise.resolve(null),
})


export const UserProvider = ({ children }) => {
  const { isLoading, isLogged, authorizedUser, logout, reloadAuthorizedUser } = useAuthorizedUser()

  console.log('UserProvider', { isLoading, isLogged, authorizedUser, logout, reloadAuthorizedUser })

  if (isLoading) {
    return renderAbsolutePositionedLoading()
  }

  return (
    <UserContext.Provider value={{ authorizedUser, isLogged, logout, reloadAuthorizedUser }}>
      {children}
    </UserContext.Provider>
  )
}

export const withUser = (Component) => (props) => (
  <UserContext.Consumer>
    {(contextValues) => (<Component {...props} {...contextValues} />)}
  </UserContext.Consumer>
)
