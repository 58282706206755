import { put, select, takeEvery } from 'redux-saga/effects'

import {
  LOCATION_CHANGE, updateHistory,
} from './actions'

export function createRootSaga() {

  function* rootSaga() {
    // Store initial location in state
    const location = yield select(s => {
      if (typeof s.router !== 'undefined') {
        return s.router.location
      } else {
        return null
      }
    })
    if (location) {
      yield put(updateHistory(location))
    }

    yield takeEvery(LOCATION_CHANGE, addLocationToHistory)
  }

  return rootSaga
}

function* addLocationToHistory(action) {
  if (action.payload !== undefined && action.payload.location !== undefined) {
    const currentLocation = action.payload.location
    yield put(updateHistory(currentLocation))
  }
}

