
import moment from 'moment'
import { get } from 'lodash/fp/object'
import { createSelector } from 'reselect'

/*  ABOUT
    Function calculates how many minutes of raw data requests
    remain for each device associated to an asset. Returns an array of objects (devices)

    Note: minutesLeft may include more than one object (day) if selection on the chart spans multiple dates

    EXAMPLE RETURN OUTPUT
    [{
        channel: 'inlet'
        minutesLeft: [{date: '04-07-2021', minutes: 10}]
        networkAssetId: 491
    },
    {
        channel: 'outlet'
        minutesLeft: [{date: '04-07-2021', minutes: 10}]
        networkAssetId: 491
    }
    ]

    createSelector memorizes the inputs. If an input value changes then the whole function will run again
    to calculate the return value, otherwise it will return the previous return cached value (this speeds up rendering)
*/
const selectNetworkAssetMinutesLeft = createSelector(
    [
      get('timeFrom'),
      get('timeTo'),
      get('listRawDataRequestsResult'),
      get('timezone'),
      get('networkAssetGraphItems'),
    ],
    (
      timeFrom,
      timeTo,
      listRawDataRequestsResult,
      timezone,
      networkAssetGraphItems,
    ) => {
      if (listRawDataRequestsResult.wasSuccessful()) {
        const timeFromMoment = moment(timeFrom)
        const timeToMoment = moment(timeTo)
        const dateFormat = 'YYYY-MM-DD'
        const maxMinutesLeft = 10
        const defaultDateMinutesLeft = [timeFromMoment.format(dateFormat)]
        const networkAssetsWithMinutesLeft = listRawDataRequestsResult.data
          .networkAssetsMinutesLeft

        if (!timeFromMoment.isSame(timeToMoment, 'day')) {
          defaultDateMinutesLeft.push(timeToMoment.format(dateFormat))
        }

        return networkAssetGraphItems.map(graphItem => {
          const defaultMinutesLeft = defaultDateMinutesLeft.map(date => {
            return {
              date: moment(date, 'YYYY-MM-DD').tz(timezone)
                .format('DD-MM-YYYY'),
              minutes: maxMinutesLeft,
            }
          })
          const assetIdWithChannel = {
            networkAssetId: graphItem.sourceId,
            channel: graphItem.sourceChannel,
          }
          const assetChannelWithDefaultMinutesLeft = {
            ...assetIdWithChannel,
            minutesLeft: defaultMinutesLeft,
          }
          const assetWithMinutesLeft = networkAssetsWithMinutesLeft.find(
            ({ networkAssetId }) =>
              String(networkAssetId) === String(graphItem.sourceId)
          )

          if (!assetWithMinutesLeft) {
            return assetChannelWithDefaultMinutesLeft
          }

          const channelWithMinutesLeft = assetWithMinutesLeft
            .channelsMinutesLeft.find(
              ({ channel }) => channel === graphItem.sourceChannel
            )

          if (!channelWithMinutesLeft) {
            return assetChannelWithDefaultMinutesLeft
          }

          return {
            ...assetIdWithChannel,
            minutesLeft: defaultDateMinutesLeft.map(date => {
              const requestDateMinutes = channelWithMinutesLeft
                .minutesLeft.find(
                  ({ date: requestDate }) => date === requestDate
                )

              return {
                date: moment(date, 'YYYY-MM-DD').tz(timezone)
                  .format('DD-MM-YYYY'),
                minutes: requestDateMinutes ?
                  requestDateMinutes.minutes : maxMinutesLeft,
              }
            }),
          }
        })
      } else {
        return []
      }
    }
)

export default selectNetworkAssetMinutesLeft
