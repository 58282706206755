import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { FormGroup, Label, Input } from 'reactstrap'
import { withTranslation } from 'react-i18next'

import Device from '@@src/api/presenters/device'

export const CONFIGURATION_SHAPE = {
  dateFrom: PropTypes.string.isRequired,
  secondsInterval: PropTypes.number.isRequired,
  timeZone: PropTypes.string,
  sourceAggregate:
    PropTypes.oneOf(Device.CONFIGURATION_SOURCE_TYPES).isRequired,
  displayLabel: PropTypes.string,
}

function DeviceSessionConfigurationSourceField(props) {
  const {
    t, configurations, sourceAggregateType, handleChange,
  } = props

  return (
    <FormGroup>
      <Label for="device-config-preset">
        {t('text.configuration')}
      </Label>
      <Input
        value={sourceAggregateType}
        onChange={handleChange}
        type="select"
        id="device-config-preset"
        name="deviceConfigPreset">
        {
          configurations.map(config => {
            const optionLabel = config.displayLabel ?
              `label.${config.displayLabel}` :
              `label.source_${config.sourceAggregate.toLowerCase()}`

            return (
              <option
                key={config.sourceAggregate}
                value={config.sourceAggregate}>
                {t(optionLabel)}
              </option>
            )
          })
        }
      </Input>
    </FormGroup>
  )
}

DeviceSessionConfigurationSourceField.propTypes = {
  sourceAggregateType: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  configurations:
    PropTypes.arrayOf(PropTypes.shape(CONFIGURATION_SHAPE)).isRequired,
  handleChange: PropTypes.func.isRequired,
}

DeviceSessionConfigurationSourceField.defaultProps = {
  configurations: [],
}

export function withDeviceSessionConfigSourceProvider(Component) {
  return class DeviceSessionConfigSourceProvider extends React.PureComponent {
    static propTypes = {
      selectedConfiguration: PropTypes.shape(CONFIGURATION_SHAPE).isRequired,
    }

    constructor(props) {
      super(props)

      this.state = {
        sourceAggregateType: props.selectedConfiguration.sourceAggregate,
      }
    }

    render() {
      return (
        <Component
          setSourceAggregateType={this.setSourceAggregateType}
          resetSourceAggregateType={this.resetSourceAggregateType}
          sourceAggregateType={this.state.sourceAggregateType}
          {...this.props} />
      )
    }

    componentWillUnmount() {
      this.resetSourceAggregateType()
    }

    setSourceAggregateType = aggregateType => {
      this.setState({ sourceAggregateType: aggregateType })
    }

    resetSourceAggregateType = () => {
      this.setSourceAggregateType(
        this.props.selectedConfiguration.sourceAggregate
      )
    }
  }
}

export default withTranslation([
  'src/components/device_configuration/device_session_configuration_source_field', // eslint-disable-line max-len
])(memo(DeviceSessionConfigurationSourceField))
