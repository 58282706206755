import React from 'react'
import { createSelector } from 'reselect'

export default function transformProps(funcCreator) {
  return function createTransformPropsComponent(Component) {
    const func = funcCreator()

    return class TransformPropsComponent extends React.PureComponent {
      render() {
        return (
          <Component {...this.selectProps(this.props)}/>
        )
      }

      selectProps = createSelector(
        [props => props],
        props => ({ ...props, ...func(props) })
      )
    }
  }
}
