import React from 'react'
import { Input } from 'reactstrap'

const SelectInput = ({ t = (option) => option, choiceOptions, inputProps }) => {
  return(
    <Input {...inputProps}>
      {
        choiceOptions.map(sourceOption =>
          <option key={sourceOption} value={sourceOption}>
            {t(sourceOption)}
          </option>
        )
      }
    </Input>
  )
}

export default SelectInput
