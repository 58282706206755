import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import PointCloud from
'@@src/management_path/devices_path/history_modal/point_cloud'
import GraphContext, { GraphConfig }
from '@@src/components/graphs/graph_context'

import styles from './signal_history_tooltip_triggers.css'

class SignalHistoryTooltipTriggers extends React.PureComponent {
  static propTypes = {
    graphConfig: PropTypes.instanceOf(GraphConfig).isRequired,
    telemetrySegments: PropTypes.array.isRequired,
    name: PropTypes.string.isRequired,
    clipPathId: PropTypes.string.isRequired,
  }

  static defaultProps = {
    name: 'signal-history-tooltip-triggers',
  }

  render() {
    const { name, telemetrySegments, clipPathId } = this.props

    return (
      <g name={name} clipPath={`url(#${clipPathId})`}>
        {telemetrySegments.map(({ index, data }) => (
          <g key={index}>
            {this.renderPointClouds(data)}
          </g>
        ))}
      </g>
    )
  }

  renderPointClouds = data => {
    const { graphConfig } = this.props

    return (
      <PointCloud
        data={data}
        getX={datum => graphConfig.xScale(datum.time)}
        getY={datum => graphConfig.yScale(datum.value)}
        renderTooltipContents={this.renderTooltipContents}
        pointClassName={styles['signal-history-point']} />
    )
  }

  renderTooltipContents = data => {
    return (
      <>
        <span className={styles['signal-history-tooltip-mobile-network']}>
          {data.mobileNetwork}, {formatRadioAccessTechnology(data.rat)}
        </span>

        <br/>

        <span className={styles['signal-history-tooltip-text']}>
          LAC: {data.lac}
          <br/>
          CID: {data.cid}
        </span>
      </>
    )
  }

}

function formatRadioAccessTechnology(text) {
  // Mapped against cellular utils from lambdas, see https://github.com/Inflowmatix/inflownet-webapp-lambdas/blob/develop/src/graphql_api/cellular/cellular_utils.js#L3
  switch (text) {
    case 'GSM':
    case 'GSM COMPACT':
      return '2G'

    case 'UTRAN':
      return '3G'

    case 'GSM with EDGE availability':
      return '2G'

    case 'UTRAN with HSDPA availability':
    case 'UTRAN with HSUPA availability':
    case 'UTRAN with HSDPA and HSUPA availability':
      return '3G'

    case 'E-UTRAN':
    case 'EC-GSM-IoT (A/Gb mode)':
    case 'E-UTRAN (NB-S1 mode)':
      return '4G'

    default:
      return text
  }
}

function SignalHistoryTooltipTriggersContainer(props) {
  return (
    <GraphContext.Consumer>
      {config => (
        <SignalHistoryTooltipTriggers graphConfig={config} {...props}/>
      )}
    </GraphContext.Consumer>
  )
}

export default withTranslation([
  'src/management_path/devices_path/signal_history_modal/signal_history_tooltip_triggers', // eslint-disable-line max-len
])(SignalHistoryTooltipTriggersContainer)
