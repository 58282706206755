import Alert from '@@src/api/presenters/alert'

export default class Comment {
  constructor(data) {
    Object.assign(this, { replies: [] }, data)
    this.uuid = `c.${data.alertId}`
  }

  get replyCount() {
    return (this.replies || []).length
  }

  formattedAuthor(t) {
    if (this.author.name) {
      return this.author.name
    } else if (this.author.email) {
      return this.author.email
    } else {
      return t('common/text:alerts.comment_author_unknown')
    }
  }

  formattedComment(t) {
    const statusChangeAutoCommentDetails = this.parseStatusChangeAutoComment()

    if (Array.isArray(statusChangeAutoCommentDetails) &&
      statusChangeAutoCommentDetails.length >= 2) {
      return t('common/text:alerts.status_change_comment', {
        oldStatus: statusChangeAutoCommentDetails[1],
        newStatus: statusChangeAutoCommentDetails[2],
      })
    }

    return this.comment
  }

  parseStatusChangeAutoComment() {
    const alertStatuses = Alert.STATUSES.join('|')
    // eslint-disable-next-line max-len
    const regex = `${Comment.STATUS_CHANGE_AUTO_COMMENT_KEY}_(${alertStatuses})+_to_(${alertStatuses})+`

    return new RegExp(regex).exec(this.comment)
  }

  static STATUS_CHANGE_AUTO_COMMENT_KEY = 'status_change_auto_comment'

  static from(...args) {
    return new Comment(...args)
  }

  static createStatusChangeAutoComment(oldStatus, newStatus) {
    // eslint-disable-next-line max-len
    return `${Comment.STATUS_CHANGE_AUTO_COMMENT_KEY}_${oldStatus}_to_${newStatus}`
  }
}
