
export const NETWORK_ASSET_ID = 'networkAssetId'
export const NETWORK_ASSET_READABLE_ID = 'networkAssetReadableId'
export const NETWORK_ASSET_CHANNEL = 'networkAssetChannel'
export const TYPE = 'type'
export const START = 'start'
export const END = 'end'
export const AVG = 'avg'
export const MIN = 'min'
export const MAX = 'max'
export const DIFF = 'diff'
export const DURATION = 'duration'

export const AVAILABLE_SORT_OPTIONS = [
  NETWORK_ASSET_ID,
  NETWORK_ASSET_READABLE_ID,
  NETWORK_ASSET_CHANNEL,
  TYPE, START, END,
  AVG, MIN, MAX, DIFF,
  DURATION,
]

export const FORMAT_DATE = 'YYYY.MM.DD'
export const FORMAT_TIME_OF_DAY = 'HH:mm:ss'
export const FORMAT_TIME = 'MMM D, YYYY h:mm A'

export const ANOMALY_REPORT_CSV = 'anomaly_report_csv'
export const EXPORT_ANOMALY_REPORT_TYPE = 'anomalies'
