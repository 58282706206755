import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import styles from '../../../components/icons/network_asset_icon.css'

const NetworkAssetIconComponent = ({ type, style }) => {
  return (<span style={style} className={classnames('d-block', styles[`icon-${type}`])} />)
}

NetworkAssetIconComponent.propTypes = {
  type: PropTypes.string.isRequired,
  style: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

export default NetworkAssetIconComponent
