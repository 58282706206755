import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import classnames from 'classnames'

import GroupSearch from '@@src/components/modals/move_groups_modal/group_search'
import GroupIcon from '@@src/components/icons/group_icon'
import { MixedGroupDetailsType } from '@@src/api/presenters/group'

import styles from './siblings_group_list_header.css'

class SiblingsGroupListHeader extends React.PureComponent {
  static propTypes = {
    inputController: PropTypes.object.isRequired,
    handleSearchChange: PropTypes.func.isRequired,
    group: PropTypes.instanceOf(MixedGroupDetailsType),
    searchValue: PropTypes.string,
    className: PropTypes.string,
    searchInputName: PropTypes.string,
    title: PropTypes.string,
    children: PropTypes.node,
    breadcrumbs: PropTypes.node,
  }

  render() {
    const {
      children, className, title, group, t, inputController, handleSearchChange,
      searchValue, breadcrumbs, searchInputName,
    } = this.props

    return (
      <div className={classnames('px-3 pt-3', className)}>
        <h3 className="h4">
          {title}
        </h3>
        {breadcrumbs}
        <div className="d-flex align-items-center mb-4">
          <GroupIcon className={classnames(styles['group-icon'], 'mr-3')} />
          <h2 className="h1 mb-0">
            {group ? group.name : t('headings.groups')}
          </h2>
        </div>
        <div className="d-flex align-items-center justify-content-between pb-2">
          {children}
          {
            !group ?
              <GroupSearch
                className="w-50"
                handleChange={handleSearchChange}
                controller={inputController}
                value={searchValue}
                name={searchInputName} />
              : null
          }
        </div>
      </div>
    )
  }
}

export default withTranslation([
  'src/components/modals/move_groups_modal/siblings_group_list_header',
])(SiblingsGroupListHeader)
