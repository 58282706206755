import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { FormGroup, Label, Input } from 'reactstrap'
import { noop } from 'lodash/fp/util'

const COMMISSIONED = 'commissioned'
const DECOMMISSIONED = 'decommissioned'
export const NETWORK_ASSET_FILTERS = [
  COMMISSIONED,
  DECOMMISSIONED,
]

export function defaultNetworkAssetFilters(filters = []) {
  return {
    [COMMISSIONED]: filters.includes(COMMISSIONED),
    [DECOMMISSIONED]: filters.includes(DECOMMISSIONED),
  }
}

function NetworkAssetFilters(props) {
  const { t, filters, handleChange } = props

  return (
    <FormGroup tag="fieldset">
      <legend className="h6">{t('headers.state')}</legend>
      <FormGroup check={true}>
        <Label check={true}>
          <Input
            checked={filters[COMMISSIONED]}
            onChange={handleChange}
            type="checkbox"
            name={COMMISSIONED} />{' '}
          {t('common/text:options.commissioned')}
        </Label>
      </FormGroup>
      <FormGroup check={true}>
        <Label check={true}>
          <Input
            checked={filters[DECOMMISSIONED]}
            onChange={handleChange}
            type="checkbox"
            name={DECOMMISSIONED} />{' '}
          {t('common/text:options.decommissioned')}
        </Label>
      </FormGroup>
    </FormGroup>
  )
}

NetworkAssetFilters.propTypes = {
  t: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
  handleChange: PropTypes.func,
}

NetworkAssetFilters.defaultProps = {
  filters: {},
  handleChange: noop,
}

export default withTranslation([
  'src/components/modals/network_asset_filters',
  'common/text',
])(memo(NetworkAssetFilters))
