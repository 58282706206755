import React from 'react'
import { createSelector } from 'reselect'
import { Button, ButtonGroup } from 'reactstrap'
import { get } from 'lodash/fp/object'
import { withTranslation } from 'react-i18next'
import AnomaliesOn from '@@src/static_assets/svgs/plot_feature_anomaly_on.svg'
import styles from './anomaly_detail_carousel.css'

class AnomalyDetailCarousel extends React.PureComponent {
  render() {
    const { t, allAnomalies, selectedItemAnomaly } = this.props
    if(!allAnomalies.length || !selectedItemAnomaly) {
      return null
    }

    const selectedAnomalyIndex = this.anomalySelectedIndex(this.props)
    return(
      <div className={styles['main-container']}>
        <div className="d-flex align-items-center">
          <img
            src={AnomaliesOn}
            className={styles.icon}
            alt="anomaly-image"
          />
          &nbsp;
          <b><span>{t('heading.anomalies')}:</span></b>
          &nbsp;
          <span>{t('text.all_in_range')}</span>
        </div>
        <div className={styles['right-side-container']}>
          <span>
            {t('text.anomaly_number', { current: selectedAnomalyIndex + 1, max: allAnomalies.length })}
          </span>
          <ButtonGroup>
            <Button
              name="previous-anomaly-button"
              color="light"
              onClick={this.prevoiusAnomaly}
              disabled={selectedAnomalyIndex === 0}
            >
              <i className="far fa-chevron-left" />
            </Button>

            <Button
              name="next-anomaly-button"
              color="light"
              onClick={this.nextAnomaly}
              disabled={(allAnomalies.length - 1) === selectedAnomalyIndex}
            >
              <i className="far fa-chevron-right"></i>
            </Button>
          </ButtonGroup>
          <Button
            name="all-anomalies-button"
            onClick={this.props.onNavigateToAnomalyList}
            disabled={allAnomalies.length === 0}
            className={styles['anomaly-report']}
          >
            <span>{t('text.anomaly_report')}</span>
          </Button>
        </div>
      </div>
    )
  }

  prevoiusAnomaly = () => {
    const { setActiveExpandedAnomalyId, allAnomalies } = this.props
    const selectedAnomalyIndex = this.anomalySelectedIndex(this.props)
    if(selectedAnomalyIndex > 0) {
      const newAnomalyId = allAnomalies[selectedAnomalyIndex - 1].id
      setActiveExpandedAnomalyId(newAnomalyId, true)
    }
  }

  nextAnomaly = () => {
    const { setActiveExpandedAnomalyId, allAnomalies } = this.props
    const selectedAnomalyIndex = this.anomalySelectedIndex(this.props)
    if(selectedAnomalyIndex < allAnomalies.length - 1) {
      const newAnomalyId = allAnomalies[selectedAnomalyIndex + 1].id
      setActiveExpandedAnomalyId(newAnomalyId, true)
    }
  }

  anomalySelectedIndex = createSelector(
    [
      get('selectedItemAnomaly'),
      get('allAnomalies'),
    ],
    (selectedItemAnomaly, allAnomalies) => {
      return allAnomalies.findIndex(anomalyItem => {
        return anomalyItem.id === selectedItemAnomaly.id
      })
    }
  )
}

export default withTranslation(
  'src/analysis_path/pressure_analysis_path/pressure_subset_graph/anomaly_carousel', // eslint-disable-line max-len
)(AnomalyDetailCarousel)
