import React from 'react'
import AnomalyPopup from './anomaly_popup'
import PropTypes from 'prop-types'
import chroma from 'chroma-js'
import { createSelector } from 'reselect'
import { get } from 'lodash/fp/object'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import { AnomalyStart } from './anomaly_marker'
import { noop } from 'lodash/util'
import PlotClusterAnnotationIcon from '../../../../components/icon-components/plot_cluster_annotation_icon'

function Cluster({
  children,
  type,
  id,
  color: sourceColor,
  x,
  y,
  count,
  setActiveExpandedAnomalyId,
  isActive,
}) {
  const color = isActive ? chroma(sourceColor).darken() : sourceColor
  return (
    <AnomalyStart
      id={id}
      name={'anomaly-cluster-' + id}
      key={'anomaly-cluster-' + id}
      isActive={isActive}
      setActiveExpandedAnomalyId={setActiveExpandedAnomalyId}
      type={type}
      x={x}
      y={y}
      color={color}
    >
      {children}
      <PlotClusterAnnotationIcon x={16} y={16} count={count} />
    </AnomalyStart>
  )
}

class AnomalyCluster extends React.PureComponent {
  render() {
    const {
      t,
      clipPath,
      cluster,
      isActiveAnomalyExpanded,
      boundsShown,
    } = this.props

    if(!cluster.length) {
      return null
    }

    const clusterInfo = this.selectClusterInfo(this.props)
    const activeAnomalyInCluster = this.selectActiveAnomalyInCluster(this.props)
    const { midType, ...rest } = clusterInfo
    let type = midType
    if(activeAnomalyInCluster) {
      type = activeAnomalyInCluster.anomaly.type
    }

    return(
      <Cluster
        clipPath={clipPath}
        {...rest}
        type={type}
        isActive={!!activeAnomalyInCluster}
      >
        <AnomalyPopup
          t={t}
          anomalyItem={activeAnomalyInCluster ? activeAnomalyInCluster : null}
          isActive={!!activeAnomalyInCluster}
          isActiveAnomalyExpanded={isActiveAnomalyExpanded}
          onRemoveExpandedAnomaly={this.onRemoveExpandedAnomaly}
          boundsShown={boundsShown}
          onHideBounds={this.onHideBounds}
        />
      </Cluster>
    )
  }

  selectActiveAnomalyInCluster = createSelector(
    [get('activeAnomalyId'), get('cluster')],
    (activeAnomalyId, cluster) => {
      return cluster.find(anomaly => {
        return anomaly.id === activeAnomalyId
      })
    }
  )

  onRemoveExpandedAnomaly = () => {
    const { setActiveExpandedAnomalyId, activeAnomalyId } = this.props
    setActiveExpandedAnomalyId(activeAnomalyId, false)
  }

  onHideBounds = () => {
    const { toggleShowBoundsForActiveAnomaly } = this.props
    toggleShowBoundsForActiveAnomaly()
  }

  selectClusterInfo = createSelector(
    [
      get('cluster'),
      get('graphConfig'),
      get('setActiveExpandedAnomalyId'),
    ],
    (cluster, graphConfig, setActiveExpandedAnomalyId) => {
      const midAnomaly = cluster[Math.floor((cluster.length - 1) / 2)]
      const midPressureData = midAnomaly.pressureData[Math.floor((midAnomaly.pressureData.length - 1) / 2)]

      return {
        color: midAnomaly.color,
        midType: midAnomaly.anomaly.type,
        id: midAnomaly.id,
        x: midPressureData ? graphConfig.xScale(midPressureData.time) : 0,
        y: midPressureData ? graphConfig.yScale(midPressureData.mean) : 0,
        setActiveExpandedAnomalyId: (id) => setActiveExpandedAnomalyId(id, true),
        count: cluster.length,
      }
    }
  )
}

AnomalyCluster.propTypes = {
  t: PropTypes.func.isRequired,
  cluster: PropTypes.array.isRequired,
  graphConfig: PropTypes.object.isRequired,
  clusterColor: PropTypes.string.isRequired,
  isActiveAnomalyExpanded: PropTypes.bool.isRequired,
  anomalyInfo: PropTypes.object,
  boundsShown: PropTypes.bool.isRequired,
  setActiveExpandedAnomalyId: PropTypes.func.isRequired,
  activeAnomalyId: PropTypes.number.isRequired,
  toggleShowBoundsForActiveAnomaly: PropTypes.func.isRequired,
}

AnomalyCluster.defaultProps = {
  clusterColor: 'green',
  isActiveAnomalyExpanded: false,
  boundsShown: false,
  setActiveExpandedAnomalyId: noop,
  activeAnomalyId: 0,
  toggleShowBoundsForActiveAnomaly: noop,
}

export default compose(
  withTranslation([
    'src/analysis_path/pressure_analysis_path/pressure_subset_graph/anomaly_layer', // eslint-disable-line max-len
  ])
)(AnomalyCluster)
