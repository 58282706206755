export default class GraphItem {
  constructor({ legendItem, subsetData }) {
    Object.assign(this, { legendItem, subsetData })

    this.id = `gi:${legendItem.id}`

    Object.freeze(this)
  }

  get name() {
    return this.legendItem.name
  }

  get type() {
    return this.legendItem.type
  }

  get source() {
    return this.legendItem.source
  }

  get sourceId() {
    return this.legendItem.sourceId
  }

  get sourceChannel() {
    return this.legendItem.sourceChannel
  }

  get data() {
    return this.isFromDevice() ?
      this.subsetData.dataSegments : this.subsetData.mixedRawDataSegments
  }

  get dataSegments() {
    return this.subsetData.dataSegments
  }

  get rawDataChunks() {
    return this.isFromDevice() ? [] : this.subsetData.rawDataSegments
  }

  get visible() {
    return this.legendItem.visible
  }

  isFromDevice() {
    return this.legendItem.isFromDevice()
  }

  isFromNetworkAsset() {
    return this.legendItem.isFromNetworkAsset()
  }

  static fromLegendItemAndSubsetData(legendItem, subsetData) {
    return new GraphItem({ legendItem, subsetData })
  }
}
