import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Alert, Row, Col, Label, Input, FormGroup, Button } from 'reactstrap'
import { withTranslation } from 'react-i18next'
import L from 'leaflet'
import chroma from 'chroma-js'
import MarkerClusterGroup from 'react-leaflet-markercluster'
import { get } from 'lodash/fp/object'
import { noop } from 'lodash/fp/util'
import { createSelector } from 'reselect'
import { compose } from 'redux'
import gql from 'graphql-tag'
import { graphql } from '@apollo/client/react/hoc'
import { connect } from 'react-redux'
import classnames from 'classnames'

import { parseGraphQLResult } from '@@src/api/presenters'
import { setViewOnlyUnreadEventSourceLocalisations as setViewOnlyUnreadEventSourceLocalisationsAction } from '@@src/store/event_source_localisation/actions'
import withGoogleMapsProvider from '@@src/components/maps/google_maps_provider'
import { createSelectGraphQLResult } from '@@src/utils'
import getWithPagination from '@@src/components/pagination/pagination_container'
import PaginationToolbar from '@@src/components/pagination/pagination_toolbar'
import StandardMap from '@@src/components/maps/standard_map'
import { GREYSCALE_TILE_LAYER_URL } from '../../../utils/map_utils'
import AsyncResult from '@@src/utils/async_result'
import NetworkAssetPopup from '@@src/components/maps/markers/network_asset_popup'
import NetworkAssetMarker from '@@src/components/maps/markers/network_asset_marker'
import MapRasterLayer from '@@src/components/maps/map_raster_layer'
import AsyncResultSwitch from '@@src/components/async_result_switch'
import EventSourceLocalisationEvents from '@@src/analysis_path/pressure_analysis_path/event_source_localisation/event_source_localisation_events' // eslint-disable-line max-len
import LegendItem from '@@src/components/graphs/legend_item'
import withOrderByAndDirection, {
  ORDER_ASC,
  ORDER_DESC,
} from '@@src/analysis_path/pressure_analysis_path/with_orderby_and_direction'
import {
  START_TIME_KEY,
  END_TIME_KEY,
  MIN_PRESSURE_KEY,
  MAX_PRESSURE_KEY,
  MEAN_PRESSURE_KEY,
  CPIS_KEY,
  SEVERITY_KEY,
} from '@@src/analysis_path/pressure_analysis_path/event_list_table/event_list_table'
import {
  PRIMARY_ICON_COLOUR,
  STEEL_ICON_COLOUR,
} from '@@src/utils/network_asset'

import styles from './event_source_localisation_map.css'

const VISIBLE_EVENT_LOCALISATION_PAGES = 3
const PRESSURE_EVENT_LIMIT = 1000

class EventSourceLocalisationMap extends PureComponent {
  static propTypes = {
    groupDataSourcesLegendItems: PropTypes.instanceOf(AsyncResult).isRequired,
    eventSourceLocalisationsResult:
      PropTypes.instanceOf(AsyncResult).isRequired,
    legendItemsResult: PropTypes.instanceOf(AsyncResult).isRequired,
    pagedPressureEventsResult: PropTypes.instanceOf(AsyncResult).isRequired,
    t: PropTypes.func.isRequired,
    startTime: PropTypes.instanceOf(Date).isRequired,
    endTime: PropTypes.instanceOf(Date).isRequired,
    viewOnlyUnreadEventSourceLocalisations: PropTypes.bool.isRequired,
    setViewOnlyUnreadEventSourceLocalisations: PropTypes.func.isRequired,
    setActiveExpandedEventId: PropTypes.func.isRequired,
    onClickViewEvents: PropTypes.func.isRequired,
    refetchEventSourceLocalisations: PropTypes.func.isRequired,
    selectedGroupNetworkAssets: PropTypes.array.isRequired,
    selectedGroup: PropTypes.instanceOf(LegendItem),
    onChangeHeatmapStatusSuccess: PropTypes.func.isRequired,
    onChangeHeatmapStatusCancel: PropTypes.func.isRequired,
    heatmapIndex: PropTypes.number.isRequired,
    setHeatmapIndex: PropTypes.func.isRequired,
    className: PropTypes.string,
    timezone: PropTypes.string.isRequired,
    setOrderByAndDirection: PropTypes.func.isRequired,
    orderBy: PropTypes.oneOf([
      START_TIME_KEY,
      END_TIME_KEY,
      MIN_PRESSURE_KEY,
      MAX_PRESSURE_KEY,
      MEAN_PRESSURE_KEY,
      CPIS_KEY,
      SEVERITY_KEY,
    ]).isRequired,
    orderDirection: PropTypes.oneOf([ORDER_ASC, ORDER_DESC]).isRequired,
  }

  static defaultProps = {
    viewOnlyUnreadEventSourceLocalisations: false,
    groupDataSourcesLegendItems: AsyncResult.pending([]),
    eventSourceLocalisationsResult: AsyncResult.pending([]),
    pagedPressureEventsResult: AsyncResult.pending([]),
    onChangeHeatmapStatusSuccess: noop,
    onChangeHeatmapStatusCancel: noop,
    onClickViewEvents: noop,
    refetchEventSourceLocalisations: noop,
    setActiveExpandedEventId: noop,
    orderBy: START_TIME_KEY,
  }

  state = {
    selectedNetworkAssetId: undefined,
    focusedEventNetworkAssetId: undefined,
    isEventsExpanded: false,
  }

  constructor(props) {
    super(props)
    this.mapColourScale = chroma
      .scale(['rgba(255, 201, 0, 0)', '#dc3545'])
      .domain([1, 2])
  }

  render() {
    const {
      t,
      groupDataSourcesLegendItems,
      eventSourceLocalisationsResult,
      mapBounds,
      selectedGroupNetworkAssets,
      selectedGroup,
      setHeatmapIndex,
      viewOnlyUnreadEventSourceLocalisations,
      heatmapIndex,
      pagedPressureEventsResult,
    } = this.props
    const {
      selectedNetworkAssetId,
      focusedEventNetworkAssetId,
      isEventsExpanded,
    } = this.state

    return (
      <div className={styles['event-localisation-container']}>
        <div
          className={classnames(styles['event-localisation-map'], {
            [styles['event-localisation-map-hidden']]: isEventsExpanded,
          })}
        >
          <StandardMap
            boundsOptions={{
              padding: [100, 100],
            }}
            renderMapLayers={this.renderLocalisationLayers}
            bounds={mapBounds}
            className="flex-grow-1"
            primaryTileLayerUrl={GREYSCALE_TILE_LAYER_URL}
          >
            <MarkerClusterGroup
              disableClusteringAtZoom={10}
              showCoverageOnHover={false}
              removeOutsideVisibleBounds={true}
            >
              {this.renderRasterLayer()}
              {pagedPressureEventsResult.wasSuccessful()
                ? selectedGroupNetworkAssets.map((networkAsset) => {
                    const isActive =
                      pagedPressureEventsResult.data.pressureEvents.some(
                        (event) => {
                          return (
                            String(event.assetId) === String(networkAsset.id)
                          )
                        }
                      )

                    return (
                      <NetworkAssetMarker
                        iconColour={
                          isActive ? PRIMARY_ICON_COLOUR : STEEL_ICON_COLOUR
                        }
                        key={networkAsset.uuid}
                        onClick={this.createOnClickHandlerFor(networkAsset)}
                        isPopupOpen={networkAsset.id === selectedNetworkAssetId}
                        isFocused={
                          String(focusedEventNetworkAssetId) ===
                          String(networkAsset.id)
                        }
                        networkAsset={networkAsset}
                      >
                        <NetworkAssetPopup networkAsset={networkAsset} />
                      </NetworkAssetMarker>
                    )
                  })
                : null}
            </MarkerClusterGroup>
          </StandardMap>
          {!selectedGroup && groupDataSourcesLegendItems.wasSuccessful() ? (
            <Alert
              className={styles['invalid-detection-alert']}
              color="warning"
            >
              {t('alert.invalid_selection')}
            </Alert>
          ) : null}
          {selectedGroup &&
          eventSourceLocalisationsResult.wasSuccessful() &&
          eventSourceLocalisationsResult.data.length === 0 ? (
            <Alert
              className={styles['invalid-detection-alert']}
              color="warning"
            >
              {t('alert.no_event_localisations')}
            </Alert>
          ) : null}
        </div>
        <Row
          className={classnames(styles['event-localisation-toolbar'], {
            [styles['event-localisation-toolbar-expanded']]: isEventsExpanded,
          })}
          noGutters={true}
        >
          <Col className="bg-white">
            <Row className="px-3 mb-3" noGutters={true}>
              <Col className="d-flex align-items-center justify-content-start">
                <Button
                  name="expand-event-source-localisation-table-btn"
                  aria-label={t('button.expand_events')}
                  onClick={this.handleExpandEventsClick}
                  className="mr-3"
                  size="sm"
                  color="light"
                >
                  {isEventsExpanded ? (
                    <span className="fas fa-chevron-down" />
                  ) : (
                    <span className="fas fa-chevron-up" />
                  )}
                </Button>
                <h2 className="h5 mb-0">{t('heading.events')}</h2>
              </Col>
              <Col className="d-flex align-items-center justify-content-end">
                <FormGroup check className="mr-4">
                  <Label className="text-nowrap" check>
                    <Input
                      name="unread-only-event-source-localisations"
                      type="checkbox"
                      checked={viewOnlyUnreadEventSourceLocalisations}
                      onChange={this.handleUnreadOnlyChange}
                    />
                    &nbsp;
                    {t('label.unread_only')}
                  </Label>
                </FormGroup>
                {eventSourceLocalisationsResult.wasSuccessful() &&
                eventSourceLocalisationsResult.data.length > 0 ? (
                  <span
                    name="event-source-localisation-index"
                    className={styles['event-localisation-index-of-total']}
                  >
                    {t('text.heatmap_index_of_total', {
                      index: heatmapIndex + 1,
                      total: eventSourceLocalisationsResult.data.length,
                    })}
                  </span>
                ) : null}
                <PaginationToolbar
                  firstPaginationLink={t('button.newest')}
                  lastPaginationLink={t('button.oldest')}
                  visiblePageOptionCount={VISIBLE_EVENT_LOCALISATION_PAGES}
                  pageNumber={heatmapIndex + 1}
                  totalPages={eventSourceLocalisationsResult.data.length}
                  totalResults={eventSourceLocalisationsResult.data.length}
                  setPageNumber={setHeatmapIndex}
                />
              </Col>
            </Row>
            {selectedGroup && selectedGroupNetworkAssets.length >= 3 ? (
              <AsyncResultSwitch
                result={eventSourceLocalisationsResult}
                renderSuccessResult={this.renderEventSourceLocalisationSuccess}
              />
            ) : null}
          </Col>
        </Row>
      </div>
    )
  }

  renderEventSourceLocalisationSuccess = () => {
    const {
      eventSourceLocalisationsResult,
      selectedGroupNetworkAssets,
      selectedGroup,
      onClickViewEvents,
      refetchEventSourceLocalisations,
      onChangeHeatmapStatusSuccess,
      onChangeHeatmapStatusCancel,
      heatmapIndex,
      pagedPressureEventsResult,
      timezone,
      legendItemsResult,
      orderBy,
      orderDirection,
      setOrderByAndDirection,
      setActiveExpandedEventId,
    } = this.props
    const eventSourceLocalisation =
      eventSourceLocalisationsResult.data[heatmapIndex]

    if (eventSourceLocalisation) {
      return (
        <EventSourceLocalisationEvents
          setActiveExpandedEventId={setActiveExpandedEventId}
          onMouseEnterEventRow={this.setFocusedEventNetworkAssetId}
          onMouseLeaveEventRow={this.clearFocusedEventNetworkAssetId}
          orderBy={orderBy}
          orderDirection={orderDirection}
          setOrderByAndDirection={setOrderByAndDirection}
          timezone={timezone}
          legendItemsResult={legendItemsResult}
          pagedPressureEventsResult={pagedPressureEventsResult}
          onChangeHeatmapStatusSuccess={onChangeHeatmapStatusSuccess}
          onChangeHeatmapStatusCancel={onChangeHeatmapStatusCancel}
          refetchEventSourceLocalisations={refetchEventSourceLocalisations}
          onClickViewEvents={onClickViewEvents}
          selectedGroup={selectedGroup}
          networkAssets={selectedGroupNetworkAssets}
          eventSourceLocalisation={eventSourceLocalisation}
        />
      )
    } else {
      return null
    }
  }

  renderRasterLayer = () => {
    const { eventSourceLocalisationsResult, heatmapIndex } = this.props

    if (
      eventSourceLocalisationsResult.wasSuccessful() &&
      eventSourceLocalisationsResult.data.length > heatmapIndex
    ) {
      const eventSourceLocalisation =
        eventSourceLocalisationsResult.data[heatmapIndex]

      return (
        <MapRasterLayer
          pixelValuesToColorFn={this.translatePixelValuesToColor}
          rasterUrl={eventSourceLocalisation.signedUrl}
          opacity={0.5}
        />
      )
    } else {
      return null
    }
  }

  componentDidUpdate(prevProps) {
    const { startTime, endTime, setHeatmapIndex } = this.props
    const { startTime: prevStartTime, endTime: prevEndTime } = prevProps

    if (startTime !== prevStartTime || endTime !== prevEndTime) {
      setHeatmapIndex(1)
      this.setState({ selectedNetworkAssetId: undefined })
    }
  }

  translatePixelValuesToColor = (pixelValue) => {
    return pixelValue[0] === 0
      ? 'rgba(255, 255, 255, 0)'
      : this.mapColourScale(pixelValue[0])
  }

  createOnClickHandlerFor = (networkAsset) => {
    return () => this.setState({ selectedNetworkAssetId: networkAsset.id })
  }

  handleUnreadOnlyChange = (ev) => {
    const { setViewOnlyUnreadEventSourceLocalisations, setHeatmapIndex } =
      this.props

    setHeatmapIndex(1)
    this.setState({ selectedNetworkAssetId: undefined })
    setViewOnlyUnreadEventSourceLocalisations(ev.target.checked)
  }

  setFocusedEventNetworkAssetId = (pressureEvent) => {
    this.setState({ focusedEventNetworkAssetId: pressureEvent.assetId })
  }

  clearFocusedEventNetworkAssetId = () => {
    this.setState({ focusedEventNetworkAssetId: undefined })
  }

  handleExpandEventsClick = () => {
    this.setState({ isEventsExpanded: !this.state.isEventsExpanded })
  }

  static PRESSURE_EVENTS_QUERY = gql`
    query PagedPressureEvents(
      $end: String!,
      $start: String!,
      $eventIds: [Int]
    ) {
      pagedPressureEvents(
      end: $end,
      start: $start,
      eventIds: $eventIds,
      pageNumber: 1,
      resultsPerPage: ${PRESSURE_EVENT_LIMIT}
    ) {
      pressureEvents {
        id
        assetId
        endTime
        startTime
        maxPressure
        minPressure
        meanPressure
        logicalChannel
        eventCharacteristics {
          id
          value
          eventCharacteristic
        }
        eventClasses {
          id
          eventClass
        }
      }
    }
  }
  `
}

function createMapStateToProps() {
  const selectValidGroupNetworkAssets = createSelector(
    [get('selectedGroup')],
    (validGroupSource) => {
      if (validGroupSource) {
        return validGroupSource.children
          .filter((c) => c.isFromNetworkAsset())
          .map(({ source }) => source)
      } else {
        return []
      }
    }
  )

  const selectMapBounds = createSelector(
    [selectValidGroupNetworkAssets],
    (validGroupNetworkAssets) => {
      if (validGroupNetworkAssets.length) {
        return L.latLngBounds(
          validGroupNetworkAssets.map(({ location }) => {
            return [location.latitude, location.longitude]
          })
        )
      } else {
        return undefined
      }
    }
  )

  const selectPressureEventsQueryVariables = createSelector(
    [
      get('eventSourceLocalisationsResult'),
      get('heatmapIndex'),
      get('fatchingVariables'),
    ],
    (eventSourceLocalisationsResult, heatmapIndex, fatchingVariables) => {
      return {
        eventIds:
          heatmapIndex < eventSourceLocalisationsResult.data.length
            ? eventSourceLocalisationsResult.data[heatmapIndex].eventIds
            : [],
        start: new Date(fatchingVariables.start).toISOString(),
        end: new Date(fatchingVariables.end).toISOString(),
      }
    }
  )

  const selectPagedPressureEventsResult = createSelectGraphQLResult(
    'pagedPressureEvents',
    {
      mapResult: parseGraphQLResult,
      nullObject: { total: 0, pressureEvents: [] },
    }
  )

  return function mapStateToProps(state, ownProps) {
    return {
      selectedGroupNetworkAssets: selectValidGroupNetworkAssets(ownProps),
      mapBounds: selectMapBounds(ownProps),
      selectPressureEventsQueryVariables,
      selectPagedPressureEventsResult,
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setViewOnlyUnreadEventSourceLocalisations(unreadOnly) {
      dispatch(setViewOnlyUnreadEventSourceLocalisationsAction(unreadOnly))
    },
  }
}

export default compose(
  withTranslation([
    // eslint-disable-next-line max-len
    'src/analysis_path/pressure_analysis_path/event_source_localisation/event_source_localisation_map',
  ]),
  withOrderByAndDirection,
  withGoogleMapsProvider,
  connect(createMapStateToProps, mapDispatchToProps),
  graphql(EventSourceLocalisationMap.PRESSURE_EVENTS_QUERY, {
    options: ({ selectPressureEventsQueryVariables, ...rest }) => {
      return {
        variables: selectPressureEventsQueryVariables(rest),
      }
    },
    props: ({ data, ownProps }) => {
      const { selectPagedPressureEventsResult } = ownProps

      return {
        pagedPressureEventsResult: selectPagedPressureEventsResult(data),
      }
    },
  }),
  getWithPagination()
)(EventSourceLocalisationMap)
