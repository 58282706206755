import { graphql } from '@apollo/client/react/hoc'
import { push } from 'connected-react-router'
import gql from 'graphql-tag'
import L from 'leaflet'
import { isEqual } from 'lodash/fp/lang'
import { get } from 'lodash/fp/object'
import PropTypes from 'prop-types'
import React from 'react'
import { withTranslation } from 'react-i18next'
import MarkerClusterGroup from 'react-leaflet-markercluster'
import { connect } from 'react-redux'
import { NavLink, Redirect } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import {
  Alert,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Col,
  DropdownItem,
  InputGroup,
  InputGroupText,
  Row,
} from 'reactstrap'
import { compose } from 'redux'
import { createSelector } from 'reselect'

import { parseGraphQLResult } from '@@src/api/presenters'
import DeviceIssue from '@@src/api/presenters/device_issue'
import ActiveFiltersAlert from '@@src/components/alerts/active_filters_alert'
import AppLayout from '@@src/components/app_layout'
import ExportDropdown from '@@src/components/dropdowns/export_dropdown'
import getFilterDropdown, {
  ADVANCED_FILTER_OPTION,
} from '@@src/components/filter_component/filter_component'
import DebouncedInput from '@@src/components/forms/debounced_input'
import AsyncList from '@@src/components/lists/async_list'
import NetworkAssetListItem from '@@src/components/lists/network_asset_list_item'
import withGoogleMapsProvider from '@@src/components/maps/google_maps_provider'
import NetworkAssetMarker from '@@src/components/maps/markers/network_asset_marker'
import NetworkAssetPopup from '@@src/components/maps/markers/network_asset_popup'
import StandardMap from '@@src/components/maps/standard_map'
import AdvancedFilterModal from '@@src/components/modals/advanced_filter_modal'
import DeviceIssueFilters, {
  filterDeviceIssuesFilters,
} from '@@src/components/modals/device_issue_filters'
import NetworkAssetTypeFilters, {
  defaultNetworkAssetTypeFilters,
} from '@@src/components/modals/network_asset_type_filters'
import getWithPagination from '@@src/components/pagination/pagination_container'
import PaginationToolbar from '@@src/components/pagination/pagination_toolbar'
import requiresLogin from '@@src/components/security/requires_login'
import getSortDropdown from '@@src/components/sort_component/sort_component'
import { EXPORT_RAW_DATA_FEATURE } from '@@src/components/tenant_licence/tenant_licence_feature_gate'
import routes from '@@src/routes'
import {
  createSelectGraphQLResult,
  getMappedCongruityTypes,
  getResultString,
  mergeSearchParams,
  parseSearchParams,
  validAssetInstallationTypes,
} from '@@src/utils'
import AsyncResult from '@@src/utils/async_result'
import DebouncedInputController from '@@src/utils/debounced_input_controller'
import {
  FeatureFlags,
  withTenantFeatureFlags,
} from '../../_v2/contexts/feature_flags/tenants_feature_flags.context'
import {
  CAN_EDIT_NETWORK_ASSETS,
  CAN_EXPORT,
  CAN_VIEW_NETWORK_ASSETS,
} from '../../_v2/contexts/user/consts/permissions'
import { withUser } from '../../_v2/contexts/user/user.context'
import {
  AIR_VALVE_FILTER_OPTION,
  ANY_FILTER_OPTION,
  BOUNDARY_VALVE_FILTER_OPTION,
  EOV_FILTER_OPTION,
  FLOW_CONTROL_VALVE_FILTER_OPTION,
  HYDRANT_FILTER_OPTION,
  ISOLATION_VALVE_FILTER_OPTION,
  METER_FILTER_OPTION,
  PIPE_FILTER_OPTION,
  PRV_FILTER_OPTION,
  PSV_FILTER_OPTION,
  PUMP_FILTER_OPTION,
  RESERVOIR_FILTER_OPTION,
  TANK_FILTER_OPTION,
  TAPPING_FILTER_OPTION,
  WASH_OUT_OPTION,
} from '../../components/lists/utils'
import { withWindowSizes } from '../../components/window_size_provider'
import {
  PRIMARY_ICON_COLOUR,
  STEEL_ICON_COLOUR,
} from '../../utils/network_asset'
import CreateNetworkAssetModal from './create_network_asset_modal'
import ExportNetworkAssetsConfigModal from './export_network_assets_config_modal'

import styles from './index_page.css'

export const SEED_NETWORK_ASSETS_PAGE_NUMBER = 1
export const SEED_NETWORK_ASSETS_RESULTS_PER_PAGE = 10
export const VISIBLE_NETWORK_ASSETS_PAGES = 3

const FilterDropdown = getFilterDropdown()
const SortDropdown = getSortDropdown()

class NetworkAssetsPage extends React.PureComponent {
  static propTypes = {
    pagedNetworkAssetsResult: PropTypes.instanceOf(AsyncResult).isRequired,
    setPageNumber: PropTypes.func.isRequired,
    setResultsPerPage: PropTypes.func.isRequired,
    resultsPerPage: PropTypes.number.isRequired,
    assetsOrder: PropTypes.string.isRequired,
    assetsOrderDirection: PropTypes.string.isRequired,
    tenantLicenceFeatures: PropTypes.arrayOf(PropTypes.string).isRequired,
    googleMaps: PropTypes.object,
  }

  static defaultProps = {
    pagedNetworkAssetsResult: AsyncResult.pending([]),
    assetsOrder: 'id',
    assetsOrderDirection: 'desc',
  }

  render() {
    const {
      t,
      pagedNetworkAssetsResult,
      resultsPerPage,
      setPageNumber,
      setResultsPerPage,
      assetsFilter,
      networkAssetsQuery,
      assetsOrder,
      assetsOrderDirection,
      googleMaps,
      authorizedUser,
      tenantLicenceFeatures,
      isMobile,
      isFeatureEnabled,
      overrideFeatureFlag,
    } = this.props

    // Does the user have permissions for this page?
    if (!authorizedUser.can(CAN_VIEW_NETWORK_ASSETS)) {
      return <Redirect to="/page-not-found" />
    }

    const {
      isCreateNetworkAssetModalOpen,
      searchInputController,
      selectedNetworkAsset,
      isAdvancedFilterModalOpen,
      deviceIssuesFilter,
      networkAssetTypeFilters,
      isExportDropdownOpen,
      isExportNetworkAssetsConfigOpen,
    } = this.state
    const selectedNetworkAssetId = selectedNetworkAsset
      ? selectedNetworkAsset.id
      : null

    const filterList = tenantLicenceFeatures.includes(EXPORT_RAW_DATA_FEATURE)
      ? this.getLicensedFilterList()
      : this.getUnlicensedFilterList()

    const sortList = [
      { value: 'id', text: t('options.created') },
      { value: 'assetId', text: t('options.assetId') },
      { value: 'installationTime', text: t('options.installation') },
      { value: 'communicationTime', text: t('options.communication') },
      {
        value: 'activeIssueTime',
        text: t('common/text:options.latest_issues'),
      },
      {
        value: 'activeIssueTypeSeverity',
        text: t('common/text:options.issue_type_severity'),
      },
    ]

    const orderBy = this.getOrderNameForOrderType(sortList, assetsOrder)
    const orderDir =
      assetsOrderDirection === 'desc' ? t('text.desc') : t('text.asc')

    const resultsString = getResultString(
      t,
      pagedNetworkAssetsResult,
      resultsPerPage
    )

    const orderingString = `${t('text.order_by', {
      orderDir,
      orderBy,
    })}`

    const hasSimpleFilter =
      Array.isArray(assetsFilter) &&
      assetsFilter.length === 1 &&
      filterList.map(({ value }) => value).includes(assetsFilter[0])

    return (
      <AppLayout
        title={t('headings.page_title')}
        contentsStyle="fixed-at-full-height"
      >
        <CreateNetworkAssetModal
          isOpen={isCreateNetworkAssetModalOpen}
          toggle={this.onToggleCreateNetworkAssetModal}
          onSuccess={this.props.refetchData}
        />
        <AdvancedFilterModal
          size={'lg'}
          handleClearFilters={this.handleClearAdvancedFilters}
          isOpen={isAdvancedFilterModalOpen}
          handleAccept={this.handleChangeNetworkAssetAdvancedFilter}
          handleToggle={this.handleAdvancedFilterToggle}
        >
          <Row>
            <Col sm="7">
              <NetworkAssetTypeFilters
                handleChange={this.handleNetworkAssetTypeFiltersChange}
                filters={networkAssetTypeFilters}
              />
            </Col>
            <Col sm="5">
              <DeviceIssueFilters
                onChangeSelection={this.handleDeviceIssueFiltersChange}
                deviceIssuesFilter={deviceIssuesFilter}
              />
            </Col>
          </Row>
        </AdvancedFilterModal>

        <ExportNetworkAssetsConfigModal
          name="export-network-assets-modal"
          isOpen={isExportNetworkAssetsConfigOpen}
          toggle={this.onToggleExportModal}
          totalResultsCount={
            pagedNetworkAssetsResult.wasSuccessful()
              ? pagedNetworkAssetsResult.data.totalResults
              : 0
          }
          exportQueryParams={{
            filterState: assetsFilter,
            searchQuery: networkAssetsQuery,
            orderBy: assetsOrder,
            orderDirection: assetsOrderDirection,
          }}
        />

        <div className="h-100 container-fluid">
          <Row className="h-100 flex-nowrap">
            {authorizedUser.can(CAN_EDIT_NETWORK_ASSETS) ? null : (
              <ReactTooltip effect="solid" place="bottom" />
            )}
            <Col sm="12" lg="6" className={styles['list-section']}>
              <div className="d-flex flex-row justify-content-between">
                <Breadcrumb>
                  <BreadcrumbItem>
                    <span>{t('buttons.management')}</span>
                  </BreadcrumbItem>

                  <BreadcrumbItem>
                    <NavLink to={routes.managementNetworkAssetsPath()}>
                      {t('buttons.management_network_assets')}
                    </NavLink>
                  </BreadcrumbItem>
                </Breadcrumb>
                {isFeatureEnabled(FeatureFlags.networkAssetsListV2, {
                  omitOverridden: true,
                }) && (
                  <Breadcrumb>
                    <BreadcrumbItem>
                      <NavLink
                        to={routes.managementNetworkAssetsPath()}
                        onClick={() => {
                          overrideFeatureFlag(
                            FeatureFlags.networkAssetsListV2,
                            true
                          )
                        }}
                      >
                        {t('buttons.try_new_beta_view')}
                      </NavLink>
                    </BreadcrumbItem>
                  </Breadcrumb>
                )}
              </div>

              <Row>
                <Col sm="12" lg="6">
                  <h1 className="mb-4">{t('headings.network_assets')}</h1>
                </Col>
                {!isMobile ? (
                  <Col sm="12" lg="6">
                    <div className="d-flex justify-content-end">
                      {authorizedUser.can(CAN_EXPORT) && (
                        <div className="btn-group">
                          <ExportDropdown
                            handleToggle={this.onToggleExportDropdown}
                            isOpen={isExportDropdownOpen}
                            name={'asset-export-dropdown'}
                            className="float-right mr-2"
                          >
                            <DropdownItem
                              onClick={this.onExportNetworkAssetsToCsv}
                              name={'asset-export-button'}
                              disabled={!pagedNetworkAssetsResult.wasSuccessful()}
                            >
                              {t('buttons.export_csv')}
                            </DropdownItem>
                          </ExportDropdown>
                        </div>
                      )}
                      <div className="btn-group">
                        <span data-tip={t('common/text:permissions.disabled')}>
                          <Button
                            disabled={
                              !authorizedUser.can(CAN_EDIT_NETWORK_ASSETS)
                            }
                            name="create-network-asset"
                            color="primary"
                            onClick={this.onClickCreateNetworkAssetButton}
                            className="float-right"
                          >
                            <i className="fa fa-plus"></i>
                            &nbsp;
                            {t('buttons.create_network_asset')}
                          </Button>
                        </span>
                      </div>
                    </div>
                  </Col>
                ) : null}
              </Row>

              <Row
                className="d-flex mb-3 justify-content-between align-items-center"
                noGutters={true}
              >
                <Col sm="auto">
                  <div className={styles['search-results-text']}>
                    {pagedNetworkAssetsResult.isPending()
                      ? t('text.results_pending')
                      : resultsString}
                  </div>

                  <div className={styles['ordering-text']}>
                    {pagedNetworkAssetsResult.isPending() ? '' : orderingString}
                  </div>
                </Col>

                <div className="d-flex justify-content-end">
                  <Button
                    className="mr-2"
                    name="clear-filters-button"
                    color="secondary"
                    outline
                    onClick={this.onClearFilters}
                  >
                    {t('buttons.clear_filters')}
                  </Button>

                  <InputGroup className={styles['search-input']}>
                    <DebouncedInput
                      type="search"
                      name="network-assets-search-input"
                      debounce={200}
                      onChange={this.onChangeNetworkAssetsQuery}
                      controller={searchInputController}
                      initialValue={networkAssetsQuery}
                      placeholder={t('text.search_placeholder')}
                    />

                    <InputGroupText>
                      <i className="fa fa-search"></i>
                    </InputGroupText>
                  </InputGroup>

                  <FilterDropdown
                    buttonStyle="mr-2"
                    handleAdvancedFilterToggle={this.handleAdvancedFilterToggle}
                    name="filter-input"
                    label={t('buttons.filter')}
                    options={filterList}
                    onChange={this.onChangeAssetFilter}
                    value={
                      hasSimpleFilter
                        ? assetsFilter[0]
                        : assetsFilter
                        ? ADVANCED_FILTER_OPTION
                        : ''
                    }
                  />

                  <SortDropdown
                    name="sort-input"
                    options={sortList}
                    onChange={this.onChangeAssetSort}
                    isDescending={assetsOrderDirection === 'desc'}
                    value={assetsOrder}
                  />
                </div>
              </Row>

              {pagedNetworkAssetsResult.wasSuccessful() ? (
                <ActiveFiltersAlert
                  className={styles['assets-active-filters']}
                  activeFilters={assetsFilter}
                />
              ) : null}

              <AsyncList
                result={pagedNetworkAssetsResult}
                className={styles['network-assets-list']}
                renderItem={this.renderNetworkAssetItem}
                noResultText={t('text.no_network_assets_found')}
                onRequestRetry={this.props.refetchData}
              />

              {pagedNetworkAssetsResult.wasSuccessful() ? (
                <PaginationToolbar
                  resultsPerPageOptionValues={[10, 25, 50, 100, 500]}
                  visiblePageOptionCount={VISIBLE_NETWORK_ASSETS_PAGES}
                  pageNumber={pagedNetworkAssetsResult.data.pageNumber}
                  totalPages={pagedNetworkAssetsResult.data.totalPages}
                  totalResults={pagedNetworkAssetsResult.data.totalResults}
                  resultsPerPage={resultsPerPage}
                  setPageNumber={setPageNumber}
                  setResultsPerPage={setResultsPerPage}
                />
              ) : null}
            </Col>
            {!isMobile ? (
              <Col sm="6" className="pr-0">
                <StandardMap
                  className="h-100"
                  zoom={this.selectMapZoom(this.props, this.state)}
                  bounds={this.selectMapBounds(this.props)}
                  center={this.selectMapCenter(this.props, this.state)}
                >
                  <MarkerClusterGroup
                    showCoverageOnHover={false}
                    removeOutsideVisibleBounds={true}
                  >
                    {pagedNetworkAssetsResult.data.map((networkAsset) => (
                      <NetworkAssetMarker
                        iconColour={this.getMarkerColorForAsset(networkAsset)}
                        key={networkAsset.uuid}
                        onClick={this.createOnClickHandlerFor(networkAsset)}
                        isPopupOpen={networkAsset.id === selectedNetworkAssetId}
                        networkAsset={networkAsset}
                      >
                        <NetworkAssetPopup
                          googleMaps={googleMaps}
                          networkAsset={networkAsset}
                        />
                      </NetworkAssetMarker>
                    ))}
                  </MarkerClusterGroup>
                </StandardMap>
                {pagedNetworkAssetsResult.wasSuccessful() &&
                pagedNetworkAssetsResult.data.totalPages > 1 ? (
                  <Alert
                    name="has-more-assets-alert"
                    className={styles['network-asset-pagination-alert']}
                    color="warning"
                  >
                    {t('alerts.has_more_paginated_network_assets')}
                  </Alert>
                ) : null}
              </Col>
            ) : null}
          </Row>
        </div>
      </AppLayout>
    )
  }

  getUnlicensedFilterList = () => {
    const { t } = this.props

    return [
      this.getAnyFilterListOption(),
      {
        value: EOV_FILTER_OPTION,
        text: t('common/text:text.eov'),
      },
      {
        value: PSV_FILTER_OPTION,
        text: t('common/text:text.psv'),
      },
      {
        value: BOUNDARY_VALVE_FILTER_OPTION,
        text: t('common/text:text.boundary_valve'),
      },
      {
        value: FLOW_CONTROL_VALVE_FILTER_OPTION,
        text: t('common/text:text.flow_control_valve'),
      },
      {
        value: ISOLATION_VALVE_FILTER_OPTION,
        text: t('common/text:text.isolation_valve'),
      },
      {
        value: PIPE_FILTER_OPTION,
        text: t('common/text:text.pipe'),
      },
      {
        value: TANK_FILTER_OPTION,
        text: t('common/text:text.tank'),
      },
      {
        value: RESERVOIR_FILTER_OPTION,
        text: t('common/text:text.reservoir'),
      },
      {
        value: METER_FILTER_OPTION,
        text: t('common/text:text.meter'),
      },
      {
        value: AIR_VALVE_FILTER_OPTION,
        text: t('common/text:text.air_valve'),
      },
      ...this.getCommonFilterList(),
    ]
  }

  getLicensedFilterList = () => {
    const { t } = this.props

    return [
      this.getAnyFilterListOption(),
      {
        value: WASH_OUT_OPTION,
        text: t('common/text:text.wash_out'),
      },
      ...this.getCommonFilterList(),
    ]
  }

  getAnyFilterListOption = () => {
    const { t } = this.props

    return {
      value: ANY_FILTER_OPTION,
      text: t('options.any'),
    }
  }

  getCommonFilterList = () => {
    const { t } = this.props

    return [
      {
        value: HYDRANT_FILTER_OPTION,
        text: t('common/text:text.hydrant'),
      },
      {
        value: PRV_FILTER_OPTION,
        text: t('common/text:text.prv'),
      },
      {
        value: PUMP_FILTER_OPTION,
        text: t('common/text:text.pump'),
      },
      {
        value: TAPPING_FILTER_OPTION,
        text: t('common/text:text.tapping'),
      },
      {
        value: DeviceIssue.ERROR_SEVERITY_FILTER,
        text: t('common/text:options.with_errors'),
      },
      {
        value: DeviceIssue.WARNING_SEVERITY_FILTER,
        text: t('common/text:options.with_warnings'),
      },
      {
        value: DeviceIssue.INFORMATION_SEVERITY_FILTER,
        text: t('common/text:options.with_information'),
      },
    ]
  }

  renderNetworkAssetItem = (networkAsset) => {
    const { selectedNetworkAsset } = this.state
    const selectedNetworkAssetId = selectedNetworkAsset
      ? selectedNetworkAsset.id
      : null

    return (
      <NetworkAssetListItem
        key={networkAsset.id}
        className={
          selectedNetworkAssetId === networkAsset.id
            ? styles['network-asset-list-item-selected']
            : styles['network-asset-list-item']
        }
        onClick={this.createOnClickHandlerFor(networkAsset)}
        networkAsset={networkAsset}
      />
    )
  }

  constructor(props) {
    super(props)

    this.state = {
      selectedNetworkAsset: null,
      isCreateNetworkAssetModalOpen: false,
      searchInputController: new DebouncedInputController(),
      isAdvancedFilterModalOpen: false,
      deviceIssuesFilter: filterDeviceIssuesFilters(props.assetsFilter),
      networkAssetTypeFilters: defaultNetworkAssetTypeFilters(
        props.assetsFilter
      ),
      isExportDropdownOpen: false,
      isExportNetworkAssetsConfigOpen: false,
    }
  }

  componentDidUpdate(prevProps) {
    const { assetsFilter } = this.props
    const { assetsFilter: prevAssetsFilter } = prevProps

    if (!isEqual(assetsFilter, prevAssetsFilter)) {
      this.setNetworkAssetFilters()
    }
  }

  selectMapBounds = createSelector(
    [get('pagedNetworkAssetsResult')],
    (result) =>
      result.wasSuccessful() && result.data.length
        ? L.latLngBounds(
            result.data.map(({ location }) => {
              return L.latLng(location.latitude, location.longitude)
            })
          )
        : undefined
  )

  selectMapCenter = createSelector(
    [(props, state) => state.selectedNetworkAsset],
    (selectedNetworkAsset) =>
      selectedNetworkAsset
        ? L.latLng([
            selectedNetworkAsset.location.latitude,
            selectedNetworkAsset.location.longitude,
          ])
        : undefined
  )

  selectMapZoom = createSelector(
    [(props, state) => state.selectedNetworkAsset],
    (selectedNetworkAsset) => (selectedNetworkAsset ? 15 : undefined)
  )

  onToggleExportDropdown = () => {
    this.setState({ isExportDropdownOpen: !this.state.isExportDropdownOpen })
  }

  onExportNetworkAssetsToCsv = () => {
    this.setState({ isExportNetworkAssetsConfigOpen: true })
  }

  onToggleExportModal = () => {
    this.setState({
      isExportNetworkAssetsConfigOpen:
        !this.state.isExportNetworkAssetsConfigOpen,
    })
  }

  setNetworkAssetFilters = () => {
    const { assetsFilter } = this.props

    this.setState({
      deviceIssuesFilter: filterDeviceIssuesFilters(assetsFilter),
      networkAssetTypeFilters: defaultNetworkAssetTypeFilters(assetsFilter),
    })
  }

  getOrderNameForOrderType = (sortList, sortValue) => {
    const sortItem = sortList.filter((item) => {
      return item.value === sortValue
    })
    return sortItem.length < 1 ? '' : sortItem[0].text.toLowerCase()
  }

  onClearFilters = (event) => {
    event.preventDefault()
    const { searchInputController } = this.state
    const { location, dispatchClearFilters } = this.props

    dispatchClearFilters(location)
    searchInputController.dispatchClearValueEvent()
  }

  onChangeNetworkAssetsQuery = (event) => {
    const { location, dispatchUpdateNetworkAssetsSearchQuery, setPageNumber } =
      this.props
    setPageNumber(1)

    dispatchUpdateNetworkAssetsSearchQuery(location, event.target.value)
  }

  handleChangeNetworkAssetAdvancedFilter = () => {
    const { deviceIssuesFilter, networkAssetTypeFilters } = this.state

    const filterCriteria = [
      ...deviceIssuesFilter.map((i) => [i, true]),
      ...Object.entries(networkAssetTypeFilters),
    ].reduce((carry, [filter, isActive]) => {
      if (isActive) {
        carry.push(filter)
      }

      return carry
    }, [])

    this.onChangeAssetFilter(
      filterCriteria.length ? filterCriteria.join('+') : undefined
    )
    this.handleAdvancedFilterToggle()
  }

  onChangeAssetFilter = (value) => {
    const { location, dispatchUpdateAssetFilter, setPageNumber } = this.props
    setPageNumber(1)

    dispatchUpdateAssetFilter(location, value)
  }

  onChangeAssetSort = (orderBy) => {
    const {
      location,
      dispatchUpdateAssetSort,
      assetsOrder,
      assetsOrderDirection,
    } = this.props

    let newOrderDirection = assetsOrderDirection
    if (orderBy === assetsOrder) {
      newOrderDirection = newOrderDirection === 'desc' ? 'asc' : 'desc'
    } else {
      newOrderDirection = 'desc' // default to descending on change of orderBy
    }

    dispatchUpdateAssetSort(location, orderBy, newOrderDirection)
  }

  createOnClickHandlerFor = (networkAsset) => {
    return () => this.setState({ selectedNetworkAsset: networkAsset })
  }

  getMarkerColorForAsset = (networkAsset) => {
    const assetIsActive = !!networkAsset.installations.some((instalation) => {
      return instalation.end === null
    })
    if (assetIsActive) {
      return PRIMARY_ICON_COLOUR
    }
    return STEEL_ICON_COLOUR
  }

  onClickCreateNetworkAssetButton = (event) => {
    event.preventDefault()

    this.setState({ isCreateNetworkAssetModalOpen: true })
  }

  onToggleCreateNetworkAssetModal = () => {
    this.setState({
      isCreateNetworkAssetModalOpen: !this.state.isCreateNetworkAssetModalOpen,
    })
  }

  handleAdvancedFilterToggle = () => {
    if (this.state.isAdvancedFilterModalOpen) {
      this.setNetworkAssetFilters()
    }

    this.setState({
      isAdvancedFilterModalOpen: !this.state.isAdvancedFilterModalOpen,
    })
  }

  handleDeviceIssueFiltersChange = (newDeviceIssuesFilter) => {
    this.setState({ deviceIssuesFilter: newDeviceIssuesFilter })
  }

  handleNetworkAssetTypeFiltersChange = (ev) => {
    const oldFilters = this.state.networkAssetTypeFilters
    if (
      validAssetInstallationTypes().includes(ev.target.name) &&
      ev.target.checked
    ) {
      validAssetInstallationTypes().forEach((instalationType) => {
        oldFilters[instalationType] = false
      })
    }
    this.setState({
      networkAssetTypeFilters: Object.assign({}, oldFilters, {
        [ev.target.name]: ev.target.checked,
      }),
    })
  }

  handleClearAdvancedFilters = () => {
    this.clearNetworkAssetFilters()
  }

  clearNetworkAssetFilters = () => {
    this.setState({
      deviceIssuesFilter: [],
      networkAssetTypeFilters: defaultNetworkAssetTypeFilters(),
    })
  }

  static NETWORK_ASSETS_QUERY = gql`
    query PagedNetworkAssets(
      $searchQuery: String
      $filterState: [NetworkAssetFilterStates]
      $pageNumber: Int!
      $resultsPerPage: Int!
      $orderBy: String
      $orderDirection: String
    ) {
      pagedNetworkAssets(
        searchQuery: $searchQuery
        filterState: $filterState
        pageNumber: $pageNumber
        resultsPerPage: $resultsPerPage
        orderBy: $orderBy
        orderDirection: $orderDirection
      ) {
        networkAssets {
          id
          assetId
          location {
            altitude
            latitude
            longitude
          }
          assetName
          assetType
          lastCommunicationTime
          installations {
            start
            end
            device {
              id
              serialNumber
              currentCommission {
                end
                start
              }
              activeIssues {
                id
                type
                severity
                description
                deviceId
              }
            }
            channelMap {
              pressure_1
            }
          }
        }
        pagination {
          pageNumber
          totalPages
          totalResults
        }
      }
    }
  `
}

function createMapStateToProps() {
  const selectParsedSearchParams = createSelector(
    [(_state, { location }) => location.search],
    parseSearchParams
  )

  const selectNetworkAssetsSearchQuery = (state, props) =>
    selectParsedSearchParams(state, props).q

  const selectAssetsFilter = (state, props) => {
    const filterParam = selectParsedSearchParams(state, props).t

    return filterParam && filterParam.split('+')
  }

  const selectRequestAssetsFilter = createSelector(
    [(f) => f],
    (assetsFilter) => {
      if (!Array.isArray(assetsFilter)) {
        return undefined
      }

      return getMappedCongruityTypes(assetsFilter)
    }
  )

  const selectAssetsOrder = (state, props) =>
    selectParsedSearchParams(state, props).ob

  const selectAssetsOrderDirection = (state, props) =>
    selectParsedSearchParams(state, props).od

  const selectPagedNetworkAssetsResult = createSelectGraphQLResult(
    'pagedNetworkAssets',
    {
      mapResult: parseGraphQLResult,
      nullObject: [],
    }
  )

  return function mapStateToProps(state, ownProps) {
    const assetsFilter = selectAssetsFilter(state, ownProps)
    // todo: tenantLicenceFeatures
    const tenantLicenceFeatures = state?.data?.tenantLicenceFeatures || []

    return {
      networkAssetsQuery: selectNetworkAssetsSearchQuery(state, ownProps),
      assetsFilter,
      requestAssetsFilter: selectRequestAssetsFilter(assetsFilter),
      assetsOrder: selectAssetsOrder(state, ownProps),
      assetsOrderDirection: selectAssetsOrderDirection(state, ownProps),
      selectPagedNetworkAssetsResult,
      tenantLicenceFeatures,
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchUpdateNetworkAssetsSearchQuery(location, query) {
      dispatch(
        push({
          search: mergeSearchParams(location.search, {
            q: query ? query : undefined,
          }),
          pathname: location.pathname,
        })
      )
    },

    dispatchUpdateAssetFilter(location, filter) {
      dispatch(
        push({
          search: mergeSearchParams(location.search, {
            t: filter === 'any' ? undefined : filter,
          }),
          pathname: location.pathname,
        })
      )
    },

    dispatchClearFilters(location) {
      dispatch(
        push({
          search: mergeSearchParams(location.search, {
            q: undefined,
            t: undefined,
            ob: 'id',
            od: 'desc',
          }),
          pathname: location.pathname,
        })
      )
    },

    dispatchUpdateAssetSort(location, orderBy, orderDirection) {
      dispatch(
        push({
          search: mergeSearchParams(location.search, {
            ob: orderBy,
            od: orderDirection,
          }),
          pathname: location.pathname,
        })
      )
    },
  }
}

export default compose(
  requiresLogin,
  connect(createMapStateToProps, mapDispatchToProps),
  withTranslation([
    'src/management_path/network_assets_path/index_page',
    'common/text',
  ]),
  withGoogleMapsProvider,
  getWithPagination({
    pageNumber: SEED_NETWORK_ASSETS_PAGE_NUMBER,
    resultsPerPage: SEED_NETWORK_ASSETS_RESULTS_PER_PAGE,
  }),
  graphql(NetworkAssetsPage.NETWORK_ASSETS_QUERY, {
    options: ({
      networkAssetsQuery,
      requestAssetsFilter,
      pageNumber,
      resultsPerPage,
      assetsOrder,
      assetsOrderDirection,
    }) => {
      return {
        fetchPolicy: 'network-only',
        variables: {
          searchQuery: networkAssetsQuery,
          filterState: requestAssetsFilter,
          orderBy: assetsOrder,
          orderDirection: assetsOrderDirection,
          pageNumber,
          resultsPerPage,
        },
      }
    },
    props: ({ data, ownProps: { selectPagedNetworkAssetsResult } }) => {
      return {
        refetchData: () => data.refetch(data.variables),
        pagedNetworkAssetsResult: selectPagedNetworkAssetsResult(data),
      }
    },
  }),
  withWindowSizes,
  withTenantFeatureFlags,
  withUser,
)(NetworkAssetsPage)
