import React from 'react'
import routes from '@@src/routes'
import { Link } from 'react-router-dom'
import {
  Button,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { LegendItemColorContext } from '../../components/colors/legend_item_color_provider'
import classnames from 'classnames'

import styles from './legend_list_header.css'

const LegendListHeader = ({ collapseEmptyChannels, location }) => {
  const { t } = useTranslation([
    'src/analysis_path/pressure_analysis_path/index_page',
  ])

  return (
    <Row className={`${styles['sources-sidebar']}`} noGutters={true}>
      <Col sm="6">
        <h5 className="m-0 p-3 h-100 align-items-center d-flex">
          {t('headings.selected_sources')}
        </h5>
      </Col>

      <Col sm="6" className="d-flex align-items-center justify-content-end">
        <Button
          className="mr-2"
          to={{
            search: location.search,
            pathname: routes.analysisPath(),
          }}
          size="sm"
          tag={Link}
        >
          <i className="fas fa-pencil-alt mr-2" />
          {t('buttons.pick_sources')}
        </Button>

        <LegendItemColorContext.Consumer>
          {({ clearUserAssignedColors }) => (
            <UncontrolledDropdown>
              <DropdownToggle
                name="legend-item-options-toggle"
                className="mr-3 btn-light btn-sm px-3"
              >
                <span className="far fa-ellipsis-v" />
              </DropdownToggle>
              <DropdownMenu className={styles['dropdown-menu']}>
                <DropdownItem
                  name="legend-item-reset-colors-option"
                  onClick={clearUserAssignedColors}
                >
                  {t('text.reset_colors')}
                </DropdownItem>
                <DropdownItem divider />

                <DropdownItem disabled>
                  <div className={styles['dropdown-description']}>
                    {t('text.auto_collapse')}
                  </div>
                </DropdownItem>

                <DropdownItem
                  name="legend-items-do-not-collapse-channels"
                  disabled={!collapseEmptyChannels}
                  onClick={() => this.setCollapseState(false)}
                >
                  <div
                    className={
                      'w-100 d-flex justify-content-between align-items-center'
                    }
                  >
                    <span
                      className={classnames({
                        'font-weight-bold': !collapseEmptyChannels, // eslint-disable-line max-len
                      })}
                    >
                      {t('text.never')}
                    </span>
                    <span
                      name="check-icon"
                      className={classnames({
                        'fa fa-check ml-1': !collapseEmptyChannels,
                      })}
                    />
                  </div>
                </DropdownItem>

                <DropdownItem
                  name="legend-items-collapse-channels"
                  disabled={collapseEmptyChannels}
                  onClick={() => this.setCollapseState(true)}
                >
                  <div
                    className={`
                                      w-100 d-flex justify-content-between
                                      align-items-center
                                    `}
                  >
                    <span
                      className={classnames({
                        'font-weight-bold': collapseEmptyChannels,
                      })}
                    >
                      {t('text.without_data')}
                    </span>
                    <span
                      name="check-icon"
                      className={classnames({
                        'fa fa-check ml-1': collapseEmptyChannels,
                      })}
                    />
                  </div>
                  <div className={styles['dropdown-description']}>
                    {t('text.temporarily_removed')}
                  </div>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          )}
        </LegendItemColorContext.Consumer>
      </Col>
    </Row>
  )
}

export default LegendListHeader
