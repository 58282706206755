import { useEffect, useRef } from 'react'

const useIntervalService = (intervalTime = 1000) => {
  const callbacks = useRef([])

  useEffect(() => {
    if(callbacks.current.length > 0) {
      const interval = setInterval(() => {
        callbacks.current.forEach(storedCallback => storedCallback())
      }, intervalTime)

      return () => {
        clearInterval(interval)
      }
    }
    return () => null
  }, [intervalTime, callbacks.current])

  const subscribe = (callback) => {
    callbacks.current = [...callbacks.current, callback]
  }
  const unsubscribe = (callback) => {
    callbacks.current = callbacks.current.filter(storedCallback => storedCallback !== callback)
  }

  return { subscribe, unsubscribe }
}

export default useIntervalService
