import React from 'react'
import classnames from 'classnames'
import ResizeObserver from 'resize-observer-polyfill'
import { createSelector } from 'reselect'

import { extractNonStylingProps } from '@@src/utils'

import styles from './responsive_component.css'

export default function responsiveComponent(Component) {
  return class ResponsiveComponent extends React.Component {
    ref = React.createRef()
    observer = null

    constructor(props) {
      super(props)
      this.state = {
        measuredWidth: 1,
        measuredHeight: 1,
      }
      this.measureDimensions = this.measureDimensions.bind(this)
    }

    measureDimensions() {
      const { clientHeight, clientWidth } = this.ref.current

      const computedStyle = getComputedStyle(this.ref.current)

      this.setState({
        measured: true,
        measuredWidth: Math.max(clientWidth - (parseFloat(computedStyle.paddingLeft) || 0) - (parseFloat(computedStyle.paddingRight) || 0), 0), // eslint-disable-line max-len
        measuredHeight: Math.max(clientHeight - (parseFloat(computedStyle.paddingTop) || 0) - (parseFloat(computedStyle.paddingBottom) || 0), 0), // eslint-disable-line max-len
      })
    }

    componentDidMount() {
      this.observer = new ResizeObserver(() => this.measureDimensions())
      this.observer.observe(this.ref.current)
      this.measureDimensions()
    }

    componentWillUnmount() {
      if (this.observer) {
        this.observer.disconnect()
      }
    }

    render() {
      const { className, style } = this.props
      const { measured, measuredHeight, measuredWidth } = this.state
      const styleProps = { measuredHeight, measuredWidth }
      const nonStylingProps = this.selectNonStylingProps(this.props)

      return (
        <div
          ref={this.ref}
          name="self-measuring-element"
          style={style}
          className={classnames(styles.element, className)}>
          {
            !measured ? null : (
              <Component {...styleProps} {...nonStylingProps}/>
            )
          }
        </div>
      )
    }

    selectNonStylingProps = createSelector(
      [props => props],
      extractNonStylingProps
    )
  }
}
