import React, { useCallback, useEffect, useState, useRef } from 'react'
import Control from 'react-leaflet-control'
import { LeafletConsumer } from 'react-leaflet'
import { useMakeCanvasGEOJsonLayer } from './useMakeCanvasGEOJsonLayer'
import {
  HIGH_ZOOM_LEVEL,
  MEDIUM_ZOOM_LEVEL,
  LOW_ZOOM_LEVEL,
  HIDE_ELEMENTS_ZOOM_LEVEL,
} from './utils'
import LoadingIcon from '@@src/components/loading_icon'
import styles from './index.css'

function MapGEOjsonLayer({ map, tileType }) {
  const [geoJsonData, setGeoJsonData] = useState(undefined)
  const [latestFileName, setLatestFileName] = useState('')
  const [zoomLevel, setZoomLevel] = useState(undefined)
  const inputRef = useRef(undefined)

  const { isLoading, clearData } = useMakeCanvasGEOJsonLayer(map, geoJsonData, tileType, zoomLevel, latestFileName)
  const zoomLevelNames = {
    [HIGH_ZOOM_LEVEL]: 'High',
    [MEDIUM_ZOOM_LEVEL]: 'Medium',
    [LOW_ZOOM_LEVEL]: 'Low',
    [HIDE_ELEMENTS_ZOOM_LEVEL]: 'Marker shown',
  }

  useEffect(() => {
    map.setMaxZoom(22)
    map.on('zoomend', (event) => {
      switch(true) {
        case event.target.getZoom() > HIGH_ZOOM_LEVEL && zoomLevel !== HIGH_ZOOM_LEVEL: {
          setZoomLevel(HIGH_ZOOM_LEVEL)
          break
        }
        case event.target.getZoom() > MEDIUM_ZOOM_LEVEL && zoomLevel !== MEDIUM_ZOOM_LEVEL: {
          setZoomLevel(MEDIUM_ZOOM_LEVEL)
          break
        }
        case event.target.getZoom() > LOW_ZOOM_LEVEL && zoomLevel !== LOW_ZOOM_LEVEL: {
          setZoomLevel(LOW_ZOOM_LEVEL)
          break
        }
        default: {
          setZoomLevel(HIDE_ELEMENTS_ZOOM_LEVEL)
          break
        }
      }
    })

    return () => {
      map.setMaxBounds([])
      map.setMinZoom(undefined)
      map.setMaxZoom(18)
    }
  }, [map])

  const readFileOnUpload = useCallback((e) => {
    const uploadedFile = e.target.files[0]

    if(!uploadedFile) {
      return
    }

    const allowedExtension = /(\.geojson)$/i
    if(!allowedExtension.exec(uploadedFile.name)) {
      alert('invalid file extension') // eslint-disable-line
      return
    }

    const fileReader = new FileReader()
    fileReader.onloadend = () => {
      try{
        const parsedGeojsonFile = JSON.parse(fileReader.result)
        setGeoJsonData(parsedGeojsonFile)
        setLatestFileName(uploadedFile.name)
      } catch(err) {
        alert('JSON parsing error' + err) // eslint-disable-line
        console.log(err) // eslint-disable-line
      }
    }

    fileReader.readAsText(uploadedFile)
  }, [geoJsonData, setGeoJsonData])

  const clearAllData = useCallback((_event) => {
    inputRef.current.value = ''
    clearData()
    setGeoJsonData(undefined)
  }, [clearData, setGeoJsonData])

  return(
    <>
    {
      isLoading &&
      <div className={styles['loading-container']}>
        <div className={styles['loading-wrapper']}>
          <LoadingIcon className={styles.loading}/>
        </div>
      </div>
    }
    <Control position={'topright'} className={'bg-secondary'}>
      <div className="m-2">
        <input
          type="file"
          name="GEOjsonFile"
          ref={inputRef}
          className={styles.uploadButton}
          onChange={readFileOnUpload} />
      </div>
      <div className="m-2 d-flex align-items-center justify-content-center">
        <p className="text-white">Zoom level: <b>{zoomLevelNames[zoomLevel]}</b></p>
      </div>
      <div className="m-2 d-flex align-items-center justify-content-center">
        <button onClick={clearAllData}>Clear GEOJson data</button>
      </div>
    </Control>
    </>
  )
}

function MapGEOjsonLayerWrapper(props) {
  return(
    <LeafletConsumer>
      {({ map }) => (
        <MapGEOjsonLayer map={map} {...props}/>
      )}
    </LeafletConsumer>
  )
}

export default MapGEOjsonLayerWrapper
