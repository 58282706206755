import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import {
  ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, Button,
} from 'reactstrap'
import { withTranslation } from 'react-i18next'

import FilterComponentItem from
'@@src/components/filter_component/filter_component_item'

import styles from './filter_component.css'

export const ADVANCED_FILTER_OPTION = 'advanced_filter_option'

export class FilterDropdown extends PureComponent {
  static propTypes = {
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
    isOpen: PropTypes.bool.isRequired,
    onChangeValue: PropTypes.func.isRequired,
    onToggle: PropTypes.func.isRequired,
    updateLabel: PropTypes.bool.isRequired,
    handleAdvancedFilterToggle: PropTypes.func,
    value: PropTypes.string,
    buttonStyle: PropTypes.string,
  }

  static defaultProps = {
    updateLabel: false,
    isOpen: false,
  }

  state = {
    isAdvancedFilterModalOpen: false,
  }

  renderDropdownItems = () => {
    const {
      options, value = options[0].value, onChangeValue, t,
      handleAdvancedFilterToggle,
    } = this.props

    return (
      <DropdownMenu>
        {options.map((f, i) => {
          return (
            <DropdownItem
              key={i}
              className={styles['dropdown-item']}
              value={f.value}
              onClick={onChangeValue}>
              <FilterComponentItem
                value={f.value}
                isSelected={value === f.value}
                text={f.text} />
            </DropdownItem>)
        })}
        {
          typeof handleAdvancedFilterToggle === 'function' ?
            <Button
              name="advanced-filter-modal-btn"
              type="button"
              className="mt-2 d-flex justify-content-between align-items-center"
              size="sm"
              block={true}
              color="link"
              onClick={handleAdvancedFilterToggle}>
              <span />
              <span className={classnames({
                'font-weight-bold': value === ADVANCED_FILTER_OPTION,
              })}>
                {t('buttons.advanced')}
              </span>
              <span name="check-icon" className={classnames({
                'fa fa-check': value === ADVANCED_FILTER_OPTION,
              })} />
            </Button>
            : null
        }
      </DropdownMenu>
    )
  }

  render() {
    const {
      updateLabel, value, options, buttonStyle, isOpen, onToggle,
    } = this.props

    let label = null
    if (updateLabel) {
      if (typeof value === 'undefined') {
        label = options[0].text
      } else {
        label = options.find(e => e.value === value).text
      }
    } else {
      label = this.props.label
    }

    return (
      <ButtonDropdown isOpen={isOpen} toggle={onToggle}>
        <DropdownToggle caret
          className={classnames(styles['dropdown-toggle'], buttonStyle)}>
          {label}&nbsp;
        </DropdownToggle>
        {isOpen ? this.renderDropdownItems() : null}
      </ButtonDropdown>
    )
  }
}

const FilterDropdownWithContainers = withTranslation([
  'src/components/filter_component/filter_component',
])(FilterDropdown)

const getFilterDropdown = (Component = FilterDropdownWithContainers) => {
  return class extends PureComponent {
    state = {
      isOpen: false,
    }

    onClickFilter = () => {
      this.setState({ isOpen: !this.state.isOpen })
    }

    changeFilter = (e) => {
      const { onChange } = this.props
      onChange(e.currentTarget.value)
    }

    render() {
      return (
        <Component
          {...this.props}
          isOpen={this.state.isOpen}
          onToggle={this.onClickFilter}
          onChangeValue={this.changeFilter} />
      )
    }
  }
}

export default getFilterDropdown
