import React from 'react'
import gql from 'graphql-tag'
import { compose } from 'redux'
import { graphql } from '@apollo/client/react/hoc'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import withConvertedAlertThresholds from
'@@src/components/alerts/with_converted_alert_thresholds'
import AlertThresholdSettingsForm, { fieldsDataPropTypeShape } from
'@@src/components/alerts/alert_settings_form'
import { TenantSettingsContext } from
'@@src/components/tenant_settings_provider'
class AlertThresholdSettings extends React.PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    refetchData: PropTypes.func.isRequired,
    fieldsData: fieldsDataPropTypeShape,
    alertThresholds: PropTypes.object.isRequired,
    editTenantAlertThresholds: PropTypes.func.isRequired,
    submitConvertedAlertThresholds: PropTypes.func.isRequired,
  }

  render() {
    const { t, refetchData, fieldsData, alertThresholds } = this.props

    return (
      <React.Fragment>
        <h2 className="h2 mb-0">
          {t('headings.alerts')}
        </h2>
        <hr className="mb-5" />

        <AlertThresholdSettingsForm
          fieldsData={fieldsData}
          alertThresholds={alertThresholds}
          onSubmit={this.handleSubmitEditAlertThresholds}
          onSubmitSuccess={refetchData}
          onReset={refetchData}
          customOnly
        />

      </React.Fragment>
    )
  }

  componentDidMount() {
    this.props.refetchData()
  }

  handleSubmitEditAlertThresholds = ({ variables }) => {
    const {
      editTenantAlertThresholds,
      submitConvertedAlertThresholds,
    } = this.props

    return submitConvertedAlertThresholds(editTenantAlertThresholds, variables)
  }

  static EDIT_ALERT_THRESHOLDS_MUTATION = gql`
    mutation EditTenantAlertThresholds(
      $dailyCpisHigh: Float!
      $dailyCpisHighEnabled: Boolean!
      $dailyCpisLow: Float!
      $dailyCpisLowEnabled: Boolean!
      $deviceNoComms: Int!
      $deviceNoCommsEnabled: Boolean!
      $eventCpis: Float!
      $eventCpisEnabled: Boolean!
      $eventSeverity: Float!
      $eventSeverityEnabled: Boolean!
      $eventSourceLocalisationEnabled: Boolean!
      $pressureHigh: Float!
      $pressureHighEnabled: Boolean!
      $pressureLow: Float!
      $pressureLowEnabled: Boolean!
      $batteryDepletingEnabled: Boolean!
    ) {
      editTenantSettings (
        alertThresholds: {
          dailyCpisHigh: $dailyCpisHigh,
          dailyCpisHighEnabled: $dailyCpisHighEnabled,
          dailyCpisLow: $dailyCpisLow,
          dailyCpisLowEnabled: $dailyCpisLowEnabled,
          deviceNoComms: $deviceNoComms,
          deviceNoCommsEnabled: $deviceNoCommsEnabled,
          eventCpis: $eventCpis,
          eventCpisEnabled: $eventCpisEnabled,
          eventSeverity: $eventSeverity,
          eventSeverityEnabled: $eventSeverityEnabled,
          eventSourceLocalisationEnabled: $eventSourceLocalisationEnabled,
          pressureHigh: $pressureHigh,
          pressureHighEnabled: $pressureHighEnabled,
          pressureLow: $pressureLow,
          pressureLowEnabled: $pressureLowEnabled,
          batteryDepletingEnabled: $batteryDepletingEnabled
        }
      ) {
        alertThresholds
      }
    }
  `
}

const ConvertedAlertThresholdSettings =
  withConvertedAlertThresholds(AlertThresholdSettings)

function AlertThresholdSettingsContainer(props) {
  return (
    <TenantSettingsContext.Consumer>
      {
        ({
          settings: {
            units,
            alertThresholds,
          } = {},
          refetchTenantSettings: refetchData,
        } = {}) => {
          return (
            <ConvertedAlertThresholdSettings
              units={units}
              alertThresholds={alertThresholds}
              tenantAlertThresholds={alertThresholds}
              refetchData={() => {
                refetchData()
              }}
              {...props} />
          )
        }
      }
    </TenantSettingsContext.Consumer>
  )
}

export default compose(
  withTranslation([
    'src/admin_path/tenant_settings/alerts_path',
    'common/text',
  ]),
  graphql(AlertThresholdSettings.EDIT_ALERT_THRESHOLDS_MUTATION, {
    name: 'editTenantAlertThresholds',
  }),
)(AlertThresholdSettingsContainer)
