import React from 'react'
import PropTypes from 'prop-types'
import MarkerClusterGroup from 'react-leaflet-markercluster'
import { createSelector } from 'reselect'
import { get } from 'lodash/fp/object'
import { graphql } from '@apollo/client/react/hoc'
import gql from 'graphql-tag'
import { compose } from 'redux'
import { connect } from 'react-redux'
import L from 'leaflet'

import StandardMap from '@@src/components/maps/standard_map'
import Alert from '@@src/api/presenters/alert'
import Device from '@@src/api/presenters/device'
import { parseGraphQLResult } from '@@src/api/presenters'
import AsyncResult from '@@src/utils/async_result'
import { createSelectGraphQLResult } from '@@src/utils'
import DeviceCommissionPopup
from '@@src/components/maps/markers/device_commission_popup'
import DeviceCommissionMarker
from '@@src/components/maps/markers/device_commission_marker'
import NetworkAssetPopup
from '@@src/components/maps/markers/network_asset_popup'
import NetworkAssetMarker
from '@@src/components/maps/markers/network_asset_marker'
import withGoogleMapsProvider from '@@src/components/maps/google_maps_provider'
import AsyncResultSwitch from '@@src/components/async_result_switch'

class AlertSourceMap extends React.PureComponent {
  static propTypes = {
    alert: PropTypes.instanceOf(Alert).isRequired,
    alertGroupSourceResult: PropTypes.instanceOf(AsyncResult).isRequired,
    alertDeviceSourceResult: PropTypes.instanceOf(AsyncResult).isRequired,
    alertNetworkAssetSourceResult: PropTypes.instanceOf(AsyncResult).isRequired,
    className: PropTypes.string,
    googleMaps: PropTypes.object,
  }

  render() {
    const { className } = this.props
    const requiredResult = this.selectAlertResult(this.props)

    return (
      <StandardMap
        bounds={this.selectMapBounds(this.props)}
        className={className}
        center={this.selectMapCenter(this.props)}>
        <MarkerClusterGroup
          showCoverageOnHover={false}
          removeOutsideVisibleBounds={true}>
          <AsyncResultSwitch
            result={requiredResult}
            renderSuccessResult={this.renderAlertMarkers}
          />
        </MarkerClusterGroup>
      </StandardMap>
    )
  }

  renderAlertMarkers = ({ data }) => {
    const { alert } = this.props

    if (alert.isEventSourceLocalisationAlert()) {
      return this.renderGroupMarkers(data)
    } else if (alert.isDeviceNoCommsAlert() ||
      alert.isBatteryDepletingAlert()) {
      return this.renderDeviceMarker(data)
    } else if ((alert.isEventAlert() || alert.isStandardAlert())) {
      return this.renderNetworkAssetMarker(data)
    }

    return null
  }

  renderDeviceMarker = (device, isPopupOpen = true) => {
    const { googleMaps } = this.props

    if (!device || !device.currentLocation) {
      return null
    }

    return (
      <DeviceCommissionMarker
        key={device.id}
        commission={device.currentCommission}
        isPopupOpen={isPopupOpen}>
        <DeviceCommissionPopup
          googleMaps={googleMaps}
          commission={device.currentCommission} />
      </DeviceCommissionMarker>
    )
  }

  renderNetworkAssetMarker = (networkAsset, isPopupOpen = true) => {
    const { googleMaps } = this.props

    if (!networkAsset || !networkAsset.location) {
      return null
    }

    return (
      <NetworkAssetMarker
        key={networkAsset.id}
        networkAsset={networkAsset}
        isPopupOpen={isPopupOpen}>
        <NetworkAssetPopup
          googleMaps={googleMaps}
          networkAsset={networkAsset} />
      </NetworkAssetMarker>
    )
  }

  renderGroupMarkers = group => {
    return group.members.data.map(member => {
      if (member instanceof Device) {
        return this.renderDeviceMarker(member, false)
      } else {
        return this.renderNetworkAssetMarker(member, false)
      }
    })
  }

  selectAlertResult = createSelector(
    [
      get('alert'),
      get('alertGroupSourceResult'),
      get('alertDeviceSourceResult'),
      get('alertNetworkAssetSourceResult'),
    ],
    (
      alert,
      alertGroupSourceResult,
      alertDeviceSourceResult,
      alertNetworkAssetSourceResult,
    ) => {
      if (alert.isEventSourceLocalisationAlert()) {
        return alertGroupSourceResult
      } else if (alert.isDeviceNoCommsAlert() ||
        alert.isBatteryDepletingAlert()) {
        return alertDeviceSourceResult
      } else if (alert.isEventAlert() || alert.isStandardAlert()) {
        return alertNetworkAssetSourceResult
      }

      return null
    }
  )

  selectMapCenter = createSelector(
    [get('alert'), this.selectAlertResult],
    (alert, alertResult) => {
      if (alert.isEventSourceLocalisationAlert() &&
        alertResult.wasSuccessful()) {
        return undefined
      } else if ((alert.isDeviceNoCommsAlert() ||
        alert.isBatteryDepletingAlert()) && alertResult.wasSuccessful() &&
        alertResult.data.currentLocation) {
        return L.latLng([
          alertResult.data.currentLocation.latitude,
          alertResult.data.currentLocation.longitude,
        ])
      } else if ((alert.isEventAlert() || alert.isStandardAlert()) &&
          alertResult.wasSuccessful()) {
        return L.latLng([
          alertResult.data.location.latitude,
          alertResult.data.location.longitude,
        ])
      }

      return undefined
    }
  )

  selectMapBounds = createSelector(
    [get('alert'), this.selectAlertResult],
    (alert, result) => {
      if (alert.isEventSourceLocalisationAlert() && result.wasSuccessful()) {
        return L.latLngBounds(result.data.members.data.map((member) => {
          if (member instanceof Device) {
            return [
              member.currentLocation.latitude,
              member.currentLocation.longitude,
            ]
          } else {
            return [
              member.location.latitude,
              member.location.longitude,
            ]
          }
        })).pad(0.25)
      }

      return undefined
    }
  )

  static ALERT_SOURCE_QUERY = gql`
    query AlertSource(
      $deviceId: Int!,
      $networkAssetId: Int!,
      $groupId: Int!,
      $skipDevice: Boolean!,
      $skipNetworkAsset: Boolean!,
      $skipGroup: Boolean!,
    ) {
      device(id: $deviceId) @skip(if: $skipDevice) {
        id
        serialNumber
        currentCommission {
          start
          end
          location {
            altitude
            latitude
            longitude
          }
        }
        activeIssues {
          id
          severity
          deviceId
        }
      }

      networkAsset(id: $networkAssetId) @skip(if: $skipNetworkAsset) {
        id
        assetType
        assetId
        assetName
        location {
          altitude
          latitude
          longitude
        }
        installations {
          end
          start
          deviceId
          device {
            id
            serialNumber
            lastKnownLocation {
              latitude
              longitude
            }
            currentCommission {
              start
              end
            }
            activeIssues {
              id
              severity
              deviceId
            }
          }
          channelMap {
            pressure_1
          }
        }
      }

      groupMix(id: $groupId) @skip(if: $skipGroup) {
        members {
          count
          type
          data {
            ... on NetworkAsset {
              id
              assetType
              assetId
              assetName
              location {
                altitude
                latitude
                longitude
              }
              installations {
                end
                start
                deviceId
                device {
                  id
                  serialNumber
                  lastKnownLocation {
                    latitude
                    longitude
                  }
                  currentCommission {
                    start
                    end
                  }
                  activeIssues {
                    id
                    severity
                    deviceId
                  }
                }
                channelMap {
                  pressure_1
                }
              }
            }
            ... on Device {
              id
              serialNumber
              currentCommission {
                start
                end
                location {
                  altitude
                  latitude
                  longitude
                }
              }
              activeIssues {
                id
                severity
                deviceId
              }
            }
          }
        }
      }
    }
  `
}

function createMapStateToProps() {
  const selectAlertGroupSourceResult =
    createSelectGraphQLResult('groupMix', {
      mapResult: parseGraphQLResult,
      nullObject: [],
    })

  const selectAlertDeviceSourceResult =
    createSelectGraphQLResult('device', {
      mapResult: parseGraphQLResult,
      nullObject: [],
    })

  const selectAlertNetworkAssetSourceResult =
    createSelectGraphQLResult('networkAsset', {
      mapResult: parseGraphQLResult,
      nullObject: [],
    })

  const selectAlertSourceQueryVariables = createSelector(
    [get('alert')],
    alert => {
      const variables = {
        skipDevice: true,
        skipNetworkAsset: true,
        skipGroup: true,
        deviceId: -1,
        groupId: -1,
        networkAssetId: -1,
      }

      if (alert.isEventSourceLocalisationAlert()) {
        Object.assign(variables, {
          skipGroup: false,
          groupId: alert.details.groupId,
        })
      } else if (alert.isDeviceNoCommsAlert() ||
        alert.isBatteryDepletingAlert()) {
        Object.assign(variables, {
          skipDevice: false,
          deviceId: alert.details.deviceId,
        })
      } else if (alert.isEventAlert() || alert.isStandardAlert()) {
        Object.assign(variables, {
          skipNetworkAsset: false,
          networkAssetId: alert.details.networkAssetId,
        })
      }

      return variables
    }
  )

  return function mapStateToProps() {
    return {
      selectAlertGroupSourceResult,
      selectAlertDeviceSourceResult,
      selectAlertNetworkAssetSourceResult,
      selectAlertSourceQueryVariables,
    }
  }
}

export default compose(
  connect(createMapStateToProps),
  graphql(AlertSourceMap.ALERT_SOURCE_QUERY, {
    options: ({ selectAlertSourceQueryVariables, ...rest }) => {
      return {
        variables: selectAlertSourceQueryVariables(rest),
      }
    },
    props: ({ data: { refetch, ...rest }, ownProps }) => {
      const {
        selectAlertGroupSourceResult,
        selectAlertDeviceSourceResult,
        selectAlertNetworkAssetSourceResult,
      } = ownProps

      return {
        refetchAlert: refetch,
        alertGroupSourceResult: selectAlertGroupSourceResult(rest),
        alertDeviceSourceResult: selectAlertDeviceSourceResult(rest),
        alertNetworkAssetSourceResult:
          selectAlertNetworkAssetSourceResult(rest),
      }
    },
  }),
  withGoogleMapsProvider,
)(AlertSourceMap)
