import React from 'react'
import classnames from 'classnames'

import styles from './device_icon_with_status.css'

export default class DeviceIconWithStatus extends React.PureComponent {
  render() {
    const { id, className } = this.props

    return (
      <span
        id={id}
        className={classnames(className, styles[this.getIconClassname()])}/>
    )
  }

  getIconClassname = () => {
    const { device } = this.props
    const mostSevereIssue = device.mostSevereIssue

    if (mostSevereIssue && !device.isDecommissioned) {
      return `icon-${mostSevereIssue.severity}`
    } else {
      return `icon-${device.status}`
    }
  }
}
