import classnames from 'classnames'
import { push } from 'connected-react-router'
import { get } from 'lodash/fp/object'
import PropTypes from 'prop-types'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { createSelector } from 'reselect'

import AnalysisTabs from '@@src/analysis_path/analysis_tabs'
import { parseGraphQLResult } from '@@src/api/presenters'
import Device from '@@src/api/presenters/device'
import { MixedGroupDetailsType } from '@@src/api/presenters/group'
import NetworkAsset from '@@src/api/presenters/network_asset'
import AppLayout from '@@src/components/app_layout'
import getFilterDropdown from '@@src/components/filter_component/filter_component'
import DebouncedInput from '@@src/components/forms/debounced_input'
import routes from '@@src/routes'
import {
  createSelectGraphQLResult,
  mergeSearchParams,
  parseSearchParams,
} from '@@src/utils'
import AsyncResult from '@@src/utils/async_result'
import DebouncedInputController from '@@src/utils/debounced_input_controller'
import { NavLink } from 'react-router-dom'
import { Button, Col, InputGroup, InputGroupText, Row } from 'reactstrap'
import DataSourceSidebar from './data_source_sidebar'
import { SOURCE_LIMIT } from './pressure_analysis_path/constants' // eslint-disable-line max-len
import withDataSources from './with_data_sources'

import styles from './data_source_selection_layout.css'

const FilterDropdown = getFilterDropdown()

export default function withDataSourceSelectionLayout(Component) {
  class DataSourceSelectionLayout extends React.Component {
    static propTypes = {
      location: PropTypes.object.isRequired,
      updateDataSources: PropTypes.func.isRequired,
      dataSourcesResult: PropTypes.instanceOf(AsyncResult).isRequired,
      groupSourcesResult: PropTypes.instanceOf(AsyncResult).isRequired,
    }

    static defaultProps = {
      dataSourcesResult: AsyncResult.pending([]),
      groupSourcesResult: AsyncResult.pending([]),
    }

    state = {
      searchInputController: new DebouncedInputController(),
    }

    render() {
      const {
        t,
        dataSourceType,
        parsedSearchParams,
        searchQuery,
        selectedDataSources,
        location,
        dataSourcesResult,
        ownProps,
        children,
        groupSourcesResult,
        title,
      } = this.props
      const { searchInputController } = this.state

      const isLimitReached = selectedDataSources.length >= SOURCE_LIMIT

      const filterList = [
        { value: 'any', text: t('options.any') },
        { value: 'device', text: t('options.devices') },
        { value: 'network_asset', text: t('options.assets') },
        { value: 'imported_network_asset', text: t('options.imported_assets') },
        { value: 'group', text: t('options.groups') },
      ]

      return (
        <AppLayout contentsStyle="fixed-at-full-height" title={title}>
          <div className="h-100 w-100 container-fluid p-0">
            <Row className={styles.row} noGutters={true}>
              <Col sm="9" className={styles['list-section']}>
                <Row
                  noGutters={true}
                  className={`${styles['search-and-filters-row']} p-20`}
                >
                  <AnalysisTabs location={location} />

                  <Button
                    name="clear-filters-button"
                    color="secondary"
                    outline
                    onClick={this.onClearFilters}
                    className={styles['clear-filters-button']}
                  >
                    {t('buttons.clear_filters')}
                  </Button>

                  <InputGroup className={styles['search-input']}>
                    <DebouncedInput
                      type="search"
                      name="search-input"
                      debounce={200}
                      onChange={this.onChangeSearchQuery}
                      controller={searchInputController}
                      initialValue={searchQuery}
                      placeholder={t('text.search_placeholder')}
                    />

                    <InputGroupText>
                      <i className="fa fa-search"></i>
                    </InputGroupText>
                  </InputGroup>

                  <FilterDropdown
                    name="filter-input"
                    label={t('buttons.filter')}
                    options={filterList}
                    onChange={this.onChangeFilter}
                    value={dataSourceType}
                  />
                </Row>

                <Component
                  {...ownProps}
                  isLimitReached={isLimitReached}
                  dataSourcesResult={dataSourcesResult}
                  selectedDataSources={selectedDataSources}
                  groupSourcesResult={groupSourcesResult}
                  addToSelected={this.addToSelected}
                  dataSourceType={dataSourceType}
                  removeFromSelected={this.removeFromSelected}
                >
                  {children}
                </Component>
              </Col>

              <Col sm="3" className={styles['selected-section']}>
                <div className={styles['sources-sidebar-heading']}>
                  <h5 className={styles['selected-section-title']}>
                    {t('sources.selected_sources')}
                  </h5>
                  <div>
                    <Button
                      className={classnames(
                        'btn btn-primary',
                        styles.button,
                        styles['showdata-button']
                      )}
                      onClick={this.removeAllSelectedSources}
                    >
                      {t('buttons.clear')}
                    </Button>
                    <NavLink
                      name="show-data-button"
                      onClick={(e) => {
                        const shouldPreventDefault =
                          !this.props.deviceIdsFromSources.length &&
                          !this.props.networkAssetIdsFromSources.length
                        return shouldPreventDefault ? e.preventDefault() : null
                      }}
                      to={routes.analysisPressurePlotPath(parsedSearchParams)}
                      className={classnames(
                        'btn btn-primary',
                        styles.button,
                        styles['showdata-button']
                      )}
                    >
                      <i className="fa fa-chart-line" />
                      &nbsp;&nbsp;
                      {t('buttons.show_data')}
                    </NavLink>
                  </div>
                </div>

                <div className={styles['selected-section-list']}>
                  <DataSourceSidebar
                    removeFromSelected={this.removeFromSelected}
                    noResultText={t('text.no_selected_assets')}
                    networkAssetIds={this.props.networkAssetIdsFromSources}
                    deviceIds={this.props.deviceIdsFromSources}
                    groupIds={this.props.groupIdsFromSources}
                  />
                </div>
              </Col>
            </Row>
          </div>
        </AppLayout>
      )
    }

    addToSelected = (dataSource) => {
      const { selectedDataSources, updateDataSources } = this.props

      if (Array.isArray(dataSource)) {
        const uniqueSources = dataSource.filter((a) => {
          return !selectedDataSources.some((source) => source.uuid === a.uuid)
        })
        const result = selectedDataSources.concat(uniqueSources)
        const uniqueNewResult = new Set(result)
        updateDataSources(Array.from(uniqueNewResult))
        return
      }

      const duplicate = selectedDataSources.find((a) => {
        return dataSource.id === a.id && dataSource.__typename === a.__typename
      })

      if (typeof duplicate === 'undefined') {
        const result = selectedDataSources.concat([dataSource])
        const uniqueNewResult = new Set(result)
        updateDataSources(Array.from(uniqueNewResult))
      }
    }

    removeFromSelected = (dataSource) => {
      const { selectedDataSources, updateDataSources } = this.props
      if (Array.isArray(dataSource)) {
        const results = selectedDataSources.filter((selectedSource) => {
          return !dataSource.some((newSource) => {
            if (selectedSource.__typename !== newSource.__typename) {
              return false
            }
            return selectedSource.id === newSource.id
          })
        })

        const uniqueNewResult = new Set(results)
        updateDataSources(Array.from(uniqueNewResult))
        return
      }

      const result = selectedDataSources.filter((a) => {
        if (a.__typename === dataSource.__typename) {
          return a.id !== dataSource.id
        }
        return true
      })

      const uniqueNewResult = new Set(result)
      updateDataSources(Array.from(uniqueNewResult))
    }

    removeAllSelectedSources = () => {
      const { updateDataSources } = this.props
      updateDataSources([])
    }

    onClearFilters = (event) => {
      event.preventDefault()
      const { searchInputController } = this.state
      const { location, dispatchClearFilters } = this.props
      dispatchClearFilters(location)
      searchInputController.dispatchClearValueEvent()
    }

    onChangeSearchQuery = (event) => {
      const { location, dispatchUpdateSearchQuery, setPageNumber } = this.props
      setPageNumber && setPageNumber(1)
      dispatchUpdateSearchQuery(location, event.target.value)
    }

    onChangeFilter = (value) => {
      const { location, dispatchUpdateDataSourceType, setPageNumber } =
        this.props
      setPageNumber && setPageNumber(1)
      dispatchUpdateDataSourceType(location, value)
    }

    selectAllSourcesData = createSelector(
      [get('dataSourcesResult'), get('groupSourcesResult')],
      (dataSourcesResult, groupSourcesResult) => {
        return dataSourcesResult.data.concat(groupSourcesResult.data.data)
      }
    )
  }

  const createMapStateToProps = () => {
    const selectParsedSearchParams = createSelector(
      [(_state, { location }) => location.search],
      parseSearchParams
    )

    const selectSearchQuery = (state, props) =>
      selectParsedSearchParams(state, props).q

    const selectDataSourceType = (state, props) =>
      selectParsedSearchParams(state, props).t

    const getFromProps = (key) => (_state, props) => props[key]

    const selectDataSources = createSelector(
      [
        getFromProps('deviceIdsFromSources'),
        getFromProps('networkAssetIdsFromSources'),
        getFromProps('groupIdsFromSources'),
      ],
      (deviceIds, networkAssetIds, groupIds) => {
        const result = []
        for (const deviceId of deviceIds) {
          result.push(
            new Device({
              id: deviceId,
              __typename: 'Device',
            })
          )
        }
        for (const assetId of networkAssetIds) {
          result.push(
            new NetworkAsset({
              id: assetId,
              __typename: 'NetworkAsset',
            })
          )
        }
        for (const groupId of groupIds) {
          result.push(
            new MixedGroupDetailsType({
              id: groupId,
              __typename: 'MixedGroupDetailsType',
            })
          )
        }
        return result
      }
    )

    const selectDataSourcesResult = createSelectGraphQLResult('dataSources', {
      mapResult: parseGraphQLResult,
      nullObject: [],
    })

    const selectGroupsResult = createSelectGraphQLResult('pagedGroups', {
      // do nothing here, same error is already logged above
      onError: () => {},
      mapResult: parseGraphQLResult,
      nullObject: [],
    })

    return function mapStateToProps(state, ownProps) {
      return {
        ownProps,
        searchQuery: selectSearchQuery(state, ownProps),
        dataSourceType: selectDataSourceType(state, ownProps),
        selectedDataSources: selectDataSources(state, ownProps),
        history: state.history,
        parsedSearchParams: selectParsedSearchParams(state, ownProps),
        selectGroupsResult,
        selectDataSourcesResult,
      }
    }
  }

  const mapDispatchToProps = (dispatch) => {
    return {
      dispatchUpdateSearchQuery(location, query) {
        dispatch(
          push({
            search: mergeSearchParams(location.search, {
              q: query ? query : undefined,
            }),
            pathname: location.pathname,
          })
        )
      },

      dispatchUpdateDataSourceType(location, type) {
        dispatch(
          push({
            search: mergeSearchParams(location.search, {
              t: type === 'any' ? undefined : type,
            }),
            pathname: location.pathname,
          })
        )
      },

      dispatchClearFilters(location) {
        dispatch(
          push({
            search: mergeSearchParams(location.search, {
              q: undefined,
              t: undefined,
            }),
            pathname: location.pathname,
          })
        )
      },
    }
  }

  return compose(
    withDataSources(({ deviceIds, networkAssetIds, groupIds }) => ({
      groupIdsFromSources: groupIds,
      deviceIdsFromSources: deviceIds,
      networkAssetIdsFromSources: networkAssetIds,
    })),
    connect(createMapStateToProps, mapDispatchToProps),
    withTranslation(['src/analysis_path/index_page', 'common/text'])
  )(DataSourceSelectionLayout)
}
