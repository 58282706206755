import React from 'react'
import { Col, FormFeedback, FormGroup, Input, Row } from 'reactstrap'
import { LOCATION_SOURCE_OPTIONS } from '../../../../../../../components/forms/inputs/location_information_input_group'
import { useTranslation } from 'react-i18next'
import NetworkAssetListNewNetworkAssetCoordinates from './network_assets_list_new_network_asset_coordinates.component'
import SelectInput from '../../../../../../components/inputs/select_input/select_input.component'

const NetworkAssetLocationFormFields = ({ control, register, errors, getValues }) => {
  const { t } = useTranslation([
    'v2/network_assets/forms',
    'src/components/forms/inputs/location_information_input_group',
    'src/components/forms/source_filter',
    // eslint-disable-next-line max-len
    'src/management_path/devices_path/assign_network_asset_to_device_modal/create_and_assign_network_asset_interface/index',
  ])
  return(
    <FormGroup tag="fieldset">
      <Row className="m-0 p-0">
        <Col sm={6} className="m-0 p-0 mb-3 pr-2">
          <label className="h6">{t('text.source')}<strong style={{ color: 'red' }}>*</strong></label>
          <SelectInput
            t={(option) => t(`v2/network_assets/forms:location_source_option.${option}`)}
            choiceOptions={LOCATION_SOURCE_OPTIONS}
            inputProps={
              {
                id: 'source',
                type: 'select',
                required: true,
                ...register('source', {
                  required: t('error_field_text.field_required'),
                  pattern: {
                    value: (value) => LOCATION_SOURCE_OPTIONS.find(sourceOption => sourceOption === value) ?
                      false : t('error_field_text.source_type'),
                  },
                }),
              }
            }
          />
          <FormFeedback name="input-error" className={'d-block'}>
            {errors.source_type && errors.source_type.message}
          </FormFeedback>
        </Col>
        <Col sm={6} className="m-0 p-0 mb-3 pl-2">
          <label className="h6">{t('text.elevation')}</label>
          <Input placeholder="meters" {...register('elevation', {
            validate: {
              value: value => value.match(/^(?:[0-9]|[1-9][0-9]{1,2}|[1-2][0-9]{3})$/) ?
                true : t('error_field_text.elevation'),
            },
          })} />
          <FormFeedback name="input-error" className={'d-block'}>
            {errors.elevation && errors.elevation.message}
          </FormFeedback>
        </Col>
      </Row>

      <NetworkAssetListNewNetworkAssetCoordinates
        t={t}
        control={control}
        register={register}
        getValues={getValues}
        errorMessage={errors.coordinates && errors.coordinates.message}
      />
    </FormGroup>
  )
}

export default NetworkAssetLocationFormFields
