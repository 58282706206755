import React, { useState } from 'react'
import gql from 'graphql-tag'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import * as Changes from '@@src/store/changes'
import styles from './event_list_export_button.css'
import { AsyncResult } from '../../../utils'
import SubmitButton from '../../../components/buttons/submit_button'
import { useMutation } from '@apollo/client'

const REQUEST_EVENTS_REPORT_QUERY = gql`
  mutation ExportEventsToCsv(
    $networkAssetChannels: [NetworkAssetChannel]!
    $start: String!
    $end: String!
    $orderBy: String!
    $orderDirection: String!
  ) {
    requestEventsReport(
      networkAssetChannels: $networkAssetChannels
      start: $start
      end: $end
      orderBy: $orderBy
      orderDirection: $orderDirection
    ) {
      jobId
    }
  }
`

/* Component for exporting Events report */
export default function EventListExportButton({
  legendItemsResult,
  startTime,
  endTime,
  orderBy,
  orderDirection,
  canPerformAction,
}) {
  const { t } = useTranslation(['src/components/dropdowns/export_dropdown'])
  const [result, setResult] = useState(AsyncResult.notFound())
  const dispatch = useDispatch()
  const [requestEventsReport] = useMutation(REQUEST_EVENTS_REPORT_QUERY)

  /* Parses Legends Items into Network Assets Channels array */
  const parseLegendsItems = () => {
    if (legendItemsResult.data.length < 1) {
      return []
    }
    return legendItemsResult.data
      .filter(a => a.sourceType !== 'device')
      .map((item) => {
        return {
          networkAssetId: item.source.id,
          channel: item.sourceChannel,
        }
      })
  }

  const onExport = async () => {
    setResult(AsyncResult.pending())

    try {
      await requestEventsReport({
        variables: {
          start: startTime,
          end: endTime,
          orderBy,
          orderDirection,
          networkAssetChannels: parseLegendsItems(),
        },
      })
      setResult(AsyncResult.success())
      dispatch(Changes.notifyChange(Changes.CSV_DOWNLOAD_CHANGES))
    } catch (err) {
      setResult(AsyncResult.fail())
    }
  }

  /* Hide button if user has no permissions */
  if (!canPerformAction) {
    return null
  }

  const renderButtonContent = () => (
    <div className={styles['btn-export-content']}>
      <span className="mr-2 fa fa-file-download" />
      {t('buttons.export')}
    </div>
  )

  return (
    <div className="ml-2">
      <SubmitButton
        color="info"
        result={result}
        size="lg"
        submitText={renderButtonContent()}
        onSubmitForm={onExport}
      />
    </div>
  )
}
