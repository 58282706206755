import classnames from 'classnames'
import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Button } from 'reactstrap'
import routes from '../../../../../../routes'
import { mapAssetTypeToLogicalChannels } from '../../../../../../utils'
import { NetworkAssetForListItem } from '../../../../../api/models/network_asset_for_list_item'
import IssuesSummaryBadgeComponent from '../../../../../components/issues/issues_summary_badge.component'
import NetworkAssetIconComponent from '../../../../../components/network_asset/network_asset_icon.component'
import NetworkAssetInstallationIconComponent
from '../../../../../components/network_asset/network_asset_installation_icon.component'

const NetworkAssetsListItemComponent = ({ t, networkAsset, isLast }) => {
  const issuesStats = networkAsset.getMostSeverityIssueWithOtherIssuesCount()
  const showDataLink = routes.analysisPressurePath({ d: [networkAsset.getShowDataId()] })
  const detailsLink = routes.networkAssetsDetailPath(networkAsset.assetId)

  const dividerCss = classnames({
    'border-bottom mb-2 pb-2': !isLast,
  })

  return (
    <div className={['w-100 d-flex flex-row justify-content-start', dividerCss].join(' ')}>
      <div className="mr-3">
        <NetworkAssetIconComponent type={networkAsset.type} style={{ width: '2rem', height: '2rem' }} />
      </div>
      <div className="w-100 align-self-stretch mr-3">
        <div className="w-100 d-flex flex-row">
          <div className="text-uppercase">{networkAsset.name}</div>
          {!!issuesStats && (
            <IssuesSummaryBadgeComponent
              className="ml-2 align-self-center"
              issue={issuesStats.mostSevereIssue ? issuesStats.mostSevereIssue : null}
              errors={issuesStats.errorsCount}
              warnings={issuesStats.warningsCount}
              information={issuesStats.informationCount}
            />
          )}
        </div>
        <div className="w-100 small font-italic">{networkAsset.readableName || '-'}</div>
        <div className="w-100 small text-muted">
          <span>
            {
              `${t('v2/common/text:pressure_data_freshness')}: 
              ${t(`v2/common/text:pressure_data_freshness_options.${networkAsset.getPressureDataFreshnessKey()}`)}`
            }
          </span>
          <span className="mx-2">•</span>
          <span>{t(`options.${networkAsset.type}`)}</span>
        </div>
      </div>
      <div className="mr-3 d-flex flex-row">
        {mapAssetTypeToLogicalChannels(networkAsset.type).map((channel, key) => (
          <NetworkAssetInstallationIconComponent
            logicalChannel={`${channel}`}
            id={`network-asset-list-item-${networkAsset.assetId}-${channel}-${key}`}
            key={`network-asset-list-item-${networkAsset.assetId}-${channel}-${key}`}
            device={networkAsset.installedDevices.find(where => where.logicalChannel === channel) || null}
            style={{ width: '1.2rem', height: '2rem' }}
          />
        ))}
      </div>
      <div className="d-flex flex-row align-self-start">
        <Button className="mr-1" color="light" tag={Link} to={showDataLink}>
          <i className="fa fa-chart-area" />
        </Button>
        <Button className="ml-1" color="light" tag={Link} to={detailsLink}>
          <i className="fa fa-info" />
        </Button>
      </div>
    </div>
  )
}

NetworkAssetsListItemComponent.propTypes = {
  networkAsset: PropTypes.instanceOf(NetworkAssetForListItem).isRequired,
  isLast: PropTypes.bool,
}

export default withTranslation([
  'v2/network_assets/filters',
])(NetworkAssetsListItemComponent)
