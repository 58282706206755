import gql from 'graphql-tag'

export const GET_NETWORK_ASSETS_FOR_LIST_QUERY = gql`
  query GetNetworkAssetsListForList (
    $filters: GetNetworkAssetsListFilters
    $orderBy: GetNetworkAssetsListOrderBy
    $orderDirection: OrderDirection
    $pageNumber: Int!
    $resultsPerPage: Int!
  ) {
    getNetworkAssetsList(
      filters: $filters
      pageNumber: $pageNumber
      resultsPerPage: $resultsPerPage
      orderBy: $orderBy
      orderDirection: $orderDirection
    ) {
      pagination {
        perPage
        pageIndex
        pageNumber
        totalPages
        totalResults
      }
      networkAssets {
        assetId
        type
        name
        readableName
        activeErrorCount
        activeWarningCount
        activeInformationCount
        lastRecordedDataTime
        mostSeverityIssue {
          type
          severity
        }
        installedDevicesCount
        installedDevices {
          deviceId
          status
          serialNumber
          logicalChannel
          activeErrorCount
          activeWarningCount
          activeInformationCount
          lastRecordedDataTime
        }
        lat
        lng
        alt
      }
    }
  }
`
