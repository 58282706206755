const sagas = require('./sagas')
const actions = require('./actions')

import { UPDATE_HISTORY } from './actions'

export function reducer(state = createInitialState(), action) {
  switch (action.type) {
    case UPDATE_HISTORY: {
      const newLocation = action.location
      switch (newLocation.pathname) {
        case '/analysis':
          return [...state, newLocation]
        default:
          return [...state]
      }
    }

    default:
      return state
  }
}

function createInitialState() {
  return []
}

export default {
  ...actions,
  ...sagas,
  reducer,
}
