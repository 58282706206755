import React from 'react'

export default function withModals(modals) {
  return Component => {
    return class ModalManager extends React.Component {
      state = {
        activeModals: [],
      }
      handlers = []

      render() {
        return (
          <React.Fragment>
            {
              modals.map(Modal => (
                <Modal
                  key={modals.indexOf(Modal)}
                  isOpen={this.isModalOpen(Modal)}
                  toggle={this.onToggleHandlerFor(Modal)}
                  {...(this.propsForModal(Modal))}
                />
              ))
            }

            <Component renderModal={this.renderModal} {...this.props}/>
          </React.Fragment>
        )
      }

      isModalOpen(Modal) {
        return this.state.activeModals.some(instance => {
          return instance.type === Modal
        })
      }

      onToggleHandlerFor(Modal) {
        const index = modals.indexOf(Modal)

        if (index < 0) {
          throw new Error('Modal not recognised')
        }

        const handler = this.handlers[index] || (() => {
          this.setState({
            activeModals: this.state.activeModals.filter(instance => {
              return instance.type !== Modal
            }),
          })
        })

        this.handlers[index] = handler
        return handler
      }

      propsForModal(Modal) {
        return (this.state.activeModals.find(instance => {
          return instance.type === Modal
        }) || { props: {} }).props
      }

      renderModal = instance => {
        this.setState({
          activeModals: this.state.activeModals.concat(instance),
        })
      }
    }
  }
}
