import React from 'react'
import { withTranslation } from 'react-i18next'

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { Link } from 'react-router-dom'
import { compose } from 'redux'

import { Button, Card, CardSubtitle, CardTitle, Col, Row } from 'reactstrap'

import { useGetDevicesSummaries } from '../../../hooks/summaries/useGetDevicesSummaries'

import AsyncResultSwitch from '@@src/components/async_result_switch'
import DeviceListItem from '@@src/components/lists/device_list_item/device_list_item'
import DevicesSummaryHeader from '@@src/components/summary/devices_summaries/devices_header_summary'
import routes from '@@src/routes'
import { ListPlaceholder } from '../../../_v2/components/placeholders/list_placeholder'

import 'react-loading-skeleton/dist/skeleton.css'
import styles from './devices_summary.css'

function DevicesSummary({ t }) {
  const { devices, devicesHeader } = useGetDevicesSummaries()

  const renderHeader = ({ data }) => {
    return (
      <CardSubtitle>
        <DevicesSummaryHeader devicesHeader={data} />
      </CardSubtitle>
    )
  }

  const renderDevicesList = ({ data: pagedDevices }) => {
    return (
      <ul name="device-list" className={styles['device-list']}>
        {pagedDevices.slice(0, 4).map((device) => {
          return (
            <DeviceListItem
              key={device.serialNumber}
              device={device}
              className={styles['device-list-item']}
            />
          )
        })}
      </ul>
    )
  }

  const renderPendingResult = () => {
    return (
      <div className="pt-2">
        <ListPlaceholder count={4} listItemHeight={20} />
      </div>
    )
  }

  return (
    <Col sm="12" lg="6" className="mb-4 d-flex flex-column">
      <Card body>
        <CardTitle>
          <h2 className="h4">{t('headings.devices')}</h2>
        </CardTitle>

        <AsyncResultSwitch
          result={devicesHeader}
          renderSuccessResult={renderHeader}
          renderPendingResult={() => (
            <Row xs="3" className="mb-3">
              <Col>
                <Skeleton height="30px" width="140px" />
              </Col>
              <Col>
                <Skeleton height="30px" width="140px" />
              </Col>
            </Row>
          )}
        />

        <AsyncResultSwitch
          result={devices}
          renderSuccessResult={renderDevicesList}
          renderPendingResult={renderPendingResult}
        />

        <Button
          className="mr-auto pl-0 pb-0 mt-auto"
          color="link"
          tag={Link}
          to={routes.managementDevicesPath()}
        >
          {t('links.devices')}
        </Button>
      </Card>
    </Col>
  )
}

export default compose(withTranslation(['src/home_path/index_page']))(
  DevicesSummary
)
