import React from 'react'
import { compose } from 'redux'
import { useTranslation } from 'react-i18next'

import {
  FeatureFlags,
  withTenantFeatureFlags,
} from '../../../../../contexts/feature_flags/tenants_feature_flags.context'

import { Breadcrumb, BreadcrumbItem } from 'reactstrap'
import { NavLink } from 'react-router-dom'
import routes from '../../../../../../routes'

const DevicesListBreadcrumbs = ({ isFeatureEnabled, overrideFeatureFlag }) => {
  const { t } = useTranslation([
    'v2/common/text',
    'v2/common/feature_flags',
  ])

  return (
    <div className="d-flex flex-row justify-content-between">
      <Breadcrumb className="m-0 p-0" listClassName="m-0 px-0 py-2">
        <BreadcrumbItem>
          <span>{t('management')}</span>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <NavLink to={routes.managementDevicesPath()}>{t('devices')}</NavLink>
        </BreadcrumbItem>
      </Breadcrumb>
      {isFeatureEnabled(FeatureFlags.devicesListV2, { omitOverridden: true }) && (
        <Breadcrumb className="m-0 p-0" listClassName="m-0 px-0 py-2">
          <BreadcrumbItem>
            <NavLink to={routes.managementDevicesPath()} onClick={() => {
              overrideFeatureFlag(FeatureFlags.devicesListV2, false)
            }}>
              {t('v2/common/feature_flags:buttons.back_to_old_view')}
            </NavLink>
          </BreadcrumbItem>
        </Breadcrumb>
      )}
    </div>
  )
}

export default compose(
  withTenantFeatureFlags,
)(DevicesListBreadcrumbs)
