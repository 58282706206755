import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ButtonDropdown, ButtonGroup, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'
import { Link } from 'react-router-dom'
import { CAN_EXPORT } from '../../../../../contexts/user/consts/permissions'
import { useAuthorizedUser } from '../../../../../contexts/user/hooks/use_authorized_user.hook'
import { UserContext } from '../../../../../contexts/user/user.context'
import ExportDevicesModal from './devices_list_export.component'
import routes from '../../../../../../routes'
import classnames from 'classnames'
import PropTypes from 'prop-types'

const DevicesListActions = ({ enableExport = false, deviceNumber, filters }) => {
  const { authorizedUser } = useContext(UserContext)
  const [exportDropdownOpen, setExportDropdownOpen] = useState(false)
  const toggleExportDropdownOpen = () => setExportDropdownOpen((prevState) => !prevState)
  const [exportPopupOpen, setExportPopupOpen] = useState(false)
  const toggleExportPopupOpen = () => setExportPopupOpen((prevState) => !prevState)

  const { t } = useTranslation([
    'src/management_path/text',
    'v2/devices/buttons',
    'v2/common/text',
  ])

  return (
    <div className="d-flex flex-row justify-content-between mt-4">
      <ExportDevicesModal
        isOpen={exportPopupOpen}
        toggle={toggleExportPopupOpen}
        enabled={enableExport && deviceNumber > 0}
        deviceNumber={deviceNumber}
        filters={filters}
      />
      <div className="d-flex flex-row justify-content-between align-items-center">
        <h1 className="m-0 p-0">{t('v2/common/text:devices')}</h1>
        <ButtonGroup className={'ml-sm-4 '}>
          <Link to={routes.managementDevicesPath()} className={classnames('btn', {
            'btn-secondary active': location.pathname === routes.managementDevicesPath(),
            'btn btn-outline-secondary': location.pathname === routes.managementDevicesTablePath(),
          })}>
            <i className="fa fa-list mr-1" />
            {t('v2/devices/buttons:inventory')}
          </Link>
          <Link to={routes.managementDevicesTablePath()} className={classnames('btn', {
            'btn-secondary active': location.pathname === routes.managementDevicesTablePath(),
            'btn btn-outline-secondary': location.pathname === routes.managementDevicesPath(),
          })}>
            <i className="fa fa-heartbeat mr-1" />
            {t('v2/devices/buttons:health')}
          </Link>
        </ButtonGroup>
      </div>

      {authorizedUser.can(CAN_EXPORT) && (
        <ButtonDropdown
          isOpen={exportDropdownOpen}
          toggle={toggleExportDropdownOpen}
          className={'export-button-dropdown'}>
          <DropdownToggle caret>
            <span className="mr-2 fa fa-file-download" />
            {t('v2/common/buttons:export')}&nbsp;
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem
              disabled={deviceNumber === 0}
              value={t('v2/common/buttons:export')}
              onClick={() => toggleExportPopupOpen()}>
              CSV
            </DropdownItem>
          </DropdownMenu>
        </ButtonDropdown>
      )}
    </div>
  )
}

DevicesListActions.propTypes = {
  enableExport: PropTypes.bool,
  deviceNumber: PropTypes.number,
  filters: PropTypes.object.isRequired,
}

export default DevicesListActions
