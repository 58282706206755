import { AbstractIdentityProvider } from './abstract-identity-provider'

export class InternalIdentityProvider extends AbstractIdentityProvider {
  /**
   * Signs in a user with the given username and password.
   * @abstract
   * @param {string} username - The username of the user.
   * @param {string} password - The password of the user.
   * @throws {Error} This method must be implemented by a subclass.
   * @returns {Promise<{success: boolean, reason?: string}>} The sign-in result, with an optional challenge reason.
   */
  async signIn(username, password) {
    this._throwUnimplementedMethodError('signIn');
  }

  /**
   * Completes the sign-in process with Multi-Factor Authentication (MFA).
   * @abstract
   * @param {string} mfaCode - The MFA code provided by the user.
   * @throws {Error} This method must be implemented by a subclass.
   * @returns {Promise<{success: boolean, reason?: string}>} The MFA sign-in result, with an optional challenge reason.
   */
  async completeSignInWithMFA(mfaCode) {
    this._throwUnimplementedMethodError('completeSignInWithMFA');
  }

  /**
   * Completes the sign-in process by setting a new password.
   * @abstract
   * @param {string} newPassword - The new password to be set.
   * @throws {Error} This method must be implemented by a subclass.
   * @returns {Promise<{success: boolean, reason?: string}>}
   *          The result of setting a new password, with an optional challenge reason.
   */
  async completeNewPassword(newPassword) {
    this._throwUnimplementedMethodError('completeNewPassword');
  }

  /**
   * Initiates a password reset request for the given email.
   * @abstract
   * @param {string} email - The email address of the user requesting the password reset.
   * @throws {Error} This method must be implemented by a subclass.
   * @returns {Promise<void>} A promise that resolves when the password reset request is initiated.
   */
  async resetPasswordRequest(email) {
    this._throwUnimplementedMethodError('resetPasswordRequest');
  }

  /**
   * Resets the password for the given email, code, and new password.
   * @abstract
   * @param {string} email - The email address of the user.
   * @param {string} code - The reset code provided to the user.
   * @param {string} newPassword - The new password to be set.
   * @throws {Error} This method must be implemented by a subclass.
   * @returns {Promise<void>} A promise that resolves when the password is reset.
   */
  async resetPassword(email, code, newPassword) {
    this._throwUnimplementedMethodError('resetPassword');
  }

  async getCurrentUser() {
    return super.getCurrentUser()
  }

  async getCurrentUserToken() {
    return super.getCurrentUserToken()
  }

  async signOut() {
    return super.signOut()
  }

  async getAcceptedEulaVersion() {
    return super.getAcceptedEulaVersion()
  }

  async setAcceptedEulaVersion(version) {
    return super.setAcceptedEulaVersion(version)
  }
}
