import { push } from 'connected-react-router'
import L from 'leaflet'
import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import MarkerClusterGroup from 'react-leaflet-markercluster'
import { Redirect } from 'react-router-dom'
import {
  Alert,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Col,
  DropdownItem,
  InputGroup,
  InputGroupText,
  Row,
} from 'reactstrap'
import { compose } from 'redux'
import { CAN_EXPORT, CAN_VIEW_DEVICES } from '../../_v2/contexts/user/consts/permissions'
import { withUser } from '../../_v2/contexts/user/user.context'
import { withWindowSizes } from '../../components/window_size_provider'
import { useDevicesInventory } from '../../hooks/management/useDevicesInventory'

import DeviceIssue from '@@src/api/presenters/device_issue'
import ActiveFiltersAlert from '@@src/components/alerts/active_filters_alert'
import AppLayout from '@@src/components/app_layout'
import ExportDropdown from '@@src/components/dropdowns/export_dropdown'
import getFilterDropdown, {
  ADVANCED_FILTER_OPTION,
} from '@@src/components/filter_component/filter_component'
import DebouncedInput from '@@src/components/forms/debounced_input'
import AsyncList from '@@src/components/lists/async_list'
import DeviceListItem from '@@src/components/lists/device_list_item/device_list_item'
import withGoogleMapsProvider from '@@src/components/maps/google_maps_provider'
import DeviceCommissionMarker from '@@src/components/maps/markers/device_commission_marker'
import DeviceCommissionPopup from '@@src/components/maps/markers/device_commission_popup'
import StandardMap from '@@src/components/maps/standard_map'
import AdvancedFilterModal from '@@src/components/modals/advanced_filter_modal'
import DeviceIssueFilters, {
  filterDeviceIssuesFilters,
} from '@@src/components/modals/device_issue_filters'
import DeviceStateFilters, {
  defaultDeviceStateFilters,
} from '@@src/components/modals/device_state_filters'
import NetworkAssetFilters, {
  defaultNetworkAssetFilters,
} from '@@src/components/modals/network_asset_filters'
import PaginationToolbar from '@@src/components/pagination/pagination_toolbar'
import requiresLogin from '@@src/components/security/requires_login'
import getSortDropdown from '@@src/components/sort_component/sort_component'
import { getResultString, mergeSearchParams } from '@@src/utils'
import DebouncedInputController from '@@src/utils/debounced_input_controller'
import { NavLink } from 'react-router-dom'
import {
  FeatureFlags,
  withTenantFeatureFlags,
} from '../../_v2/contexts/feature_flags/tenants_feature_flags.context'
import routes from '../../routes'
import { mapDistanceToTime } from '../../utils/map_utils'
import DevicesViewSwitcher from '../devices_table_path/devices_view_switcher/devices_view_switcher'
import AssignNetworkAssetToDeviceModal from './assign_network_asset_to_device_modal'
import ExportDevicesConfigModal from './export_devices_config_modal'

import styles from './index_page.css'

export const SEED_DEVICES_PAGE_NUMBER = 1
export const SEED_DEVICES_RESULTS_PER_PAGE = 10
export const VISIBLE_DEVICE_PAGES = 3

const FilterDropdown = getFilterDropdown()
const SortDropdown = getSortDropdown()

const ANY_FILTER_OPTION = 'any'
const COMMISSIONED_FILTER_OPTION = 'commissioned'
const DECOMMISSIONED_FILTER_OPTION = 'decommissioned'
const INSTALLED_FILTER_OPTION = 'installed'
const NOT_INSTALLED_FILTER_OPTION = 'notInstalled'
const HEALTHY_FILTER_OPTION = 'healthy'
const STANDARD_FILTER_OPTIONS = [
  ANY_FILTER_OPTION,
  COMMISSIONED_FILTER_OPTION,
  DECOMMISSIONED_FILTER_OPTION,
  INSTALLED_FILTER_OPTION,
  NOT_INSTALLED_FILTER_OPTION,
  HEALTHY_FILTER_OPTION,
  DeviceIssue.ERROR_SEVERITY_FILTER,
  DeviceIssue.WARNING_SEVERITY_FILTER,
  DeviceIssue.INFORMATION_SEVERITY_FILTER,
]

function ManagementDevicesPage(props) {
  const {
    t,
    location,
    googleMaps,
    authorizedUser,
    dispatch,
    isMobile,
    isFeatureEnabled,
    overrideFeatureFlag,
  } = props

  const {
    pagedDevicesResult,
    searchQuery,
    status,
    setPageNumber,
    setResultsPerPage,
    resultsPerPage,
    orderBy,
    orderDirection,
    refetchPagedDevices,
    commissions,
  } = useDevicesInventory(location)

  // Does the user have permissions for this page?
  if (!authorizedUser.can(CAN_VIEW_DEVICES)) {
    return <Redirect to="/page-not-found" />
  }
  const [state, setState] = useState({
    selectedDevice: undefined,
    mapZoom: 2,
    mapCenter: undefined,
    mapBounds: undefined,
    inTransition: false,
    isExportDropdownOpen: false,
    searchInputController: new DebouncedInputController(),
    isAdvancedFilterModalOpen: false,
    deviceIssuesFilter: filterDeviceIssuesFilters(status),
    deviceStateFilters: defaultDeviceStateFilters(status),
    networkAssetFilters: defaultNetworkAssetFilters(status),
  })

  const [commissionToAssign, setCommissionToAssign] = useState({
    commissionToAssign: null,
    isCommissionToAssignModalOpen: false,
  })

  const [isExportDevicesConfigOpen, setIsExportDevicesConfigOpen] =
    useState(false)

  useEffect(() => {
    setState({
      ...state,
      deviceIssuesFilter: filterDeviceIssuesFilters(status),
      deviceStateFilters: defaultDeviceStateFilters(status),
      networkAssetFilters: defaultNetworkAssetFilters(status),
      mapZoom: 2,
      mapCenter: L.latLng([25.9614246, 11.4231234]),
      mapBounds: undefined,
      selectedDevice: undefined,
    })
  }, [commissions])

  const selectedDeviceId = state.selectedDevice ? state.selectedDevice.id : null

  const sortList = [
    { value: 'serialNumber', text: t('options.serial_number') },
    { value: 'commissionTime', text: t('options.commission') },
    { value: 'communicationTime', text: t('options.communication') },
    {
      value: 'activeIssueTime',
      text: t('common/text:options.latest_issues'),
    },
    {
      value: 'activeIssueTypeSeverity',
      text: t('common/text:options.issue_type_severity'),
    },
  ]
  const filterList = [
    {
      value: ANY_FILTER_OPTION,
      text: t('options.any'),
    },
    {
      value: COMMISSIONED_FILTER_OPTION,
      text: t('common/text:options.commissioned'),
    },
    {
      value: DECOMMISSIONED_FILTER_OPTION,
      text: t('common/text:options.decommissioned'),
    },
    {
      value: INSTALLED_FILTER_OPTION,
      text: t('common/text:options.installed'),
    },
    {
      value: NOT_INSTALLED_FILTER_OPTION,
      text: t('common/text:options.not_installed'),
    },
    {
      value: HEALTHY_FILTER_OPTION,
      text: t('common/text:options.healthy'),
    },
    {
      value: DeviceIssue.ERROR_SEVERITY_FILTER,
      text: t('common/text:options.with_errors'),
    },
    {
      value: DeviceIssue.WARNING_SEVERITY_FILTER,
      text: t('common/text:options.with_warnings'),
    },
    {
      value: DeviceIssue.INFORMATION_SEVERITY_FILTER,
      text: t('common/text:options.with_information'),
    },
  ]

  const getOrderNameForOrderType = () => {
    const sortItem = sortList.filter((item) => {
      return item.value === orderBy
    })
    return sortItem.length < 1 ? '' : sortItem[0].text.toLowerCase()
  }

  const orderByName = getOrderNameForOrderType()
  const orderDir = orderDirection === 'desc' ? t('text.desc') : t('text.asc')

  const resultsString = getResultString(t, pagedDevicesResult, resultsPerPage)
  const orderingString = `${t('text.order_by', {
    orderDir,
    orderBy: orderByName,
  })}`

  const hasSimpleFilter =
    Array.isArray(status) &&
    status.length === 1 &&
    STANDARD_FILTER_OPTIONS.includes(status[0])

  const dispatchClearFilters = () => {
    dispatch(
      push({
        search: mergeSearchParams(location.search, {
          q: undefined,
          s: undefined,
          ob: 'serialNumber',
          od: 'desc',
        }),
        pathname: location.pathname,
      })
    )
  }

  const dispatchUpdateDeviceSearchQuery = (query) => {
    dispatch(
      push({
        search: mergeSearchParams(location.search, {
          q: query ? query : undefined,
        }),
        pathname: location.pathname,
      })
    )
  }

  const dispatchUpdateDeviceFilter = (newStatus) => {
    dispatch(
      push({
        search: mergeSearchParams(location.search, {
          s: newStatus === 'any' ? undefined : newStatus,
        }),
        pathname: location.pathname,
      })
    )
  }

  const dispatchUpdateDeviceSort = (newOrderBy, newOrderDirection) => {
    dispatch(
      push({
        search: mergeSearchParams(location.search, {
          ob: newOrderBy,
          od: newOrderDirection,
        }),
        pathname: location.pathname,
      })
    )
  }

  const onToggleAssignNetworkAssetModal = () => {
    setCommissionToAssign({
      ...commissionToAssign,
      commissionToAssign: null,
      isCommissionToAssignModalOpen: false,
    })
  }

  const createOnClickInstallToDevice = (device) => {
    setCommissionToAssign({
      ...commissionToAssign,
      commissionToAssign: device.currentCommission,
      isCommissionToAssignModalOpen: true,
    })
  }

  const clearDeviceFilters = () => {
    setState({
      ...state,
      deviceIssuesFilter: [],
      deviceStateFilters: defaultDeviceStateFilters(),
      networkAssetFilters: defaultNetworkAssetFilters(),
    })
  }

  const handleClearAdvancedFilters = () => {
    clearDeviceFilters()
  }

  const onChangeDeviceFilter = (value) => {
    dispatchUpdateDeviceFilter(value)
  }

  const setDeviceFilters = () => {
    setState({
      ...state,
      deviceIssuesFilter: filterDeviceIssuesFilters(status),
      deviceStateFilters: defaultDeviceStateFilters(status),
      networkAssetFilters: defaultNetworkAssetFilters(status),
    })
  }

  const handleAdvancedFilterToggle = () => {
    if (state.isAdvancedFilterModalOpen) {
      setDeviceFilters()
    }

    setState({
      ...state,
      isAdvancedFilterModalOpen: !state.isAdvancedFilterModalOpen,
    })
  }

  const handleChangeDeviceAdvancedFilter = () => {
    const { deviceIssuesFilter, deviceStateFilters, networkAssetFilters } =
      state

    const filterCriteria = [
      ...deviceIssuesFilter.map((i) => [i, true]),
      ...Object.entries(deviceStateFilters),
      ...Object.entries(networkAssetFilters),
    ].reduce((carry, [filter, isActive]) => {
      if (isActive) {
        carry.push(filter)
      }

      return carry
    }, [])

    onChangeDeviceFilter(
      filterCriteria.length ? filterCriteria.join('+') : undefined
    )
    handleAdvancedFilterToggle()
  }

  const handleDeviceStateFiltersChange = (ev) => {
    const newStateFilterDevice = Object.assign({}, state.deviceStateFilters, {
      [ev.target.name]: ev.target.checked,
    })
    setState({
      ...state,
      deviceStateFilters: newStateFilterDevice,
    })
  }

  const handleDeviceIssueFiltersChange = (newDeviceIssuesFilter) => {
    setState({ ...state, deviceIssuesFilter: newDeviceIssuesFilter })
  }

  const handleNetworkAssetFiltersChange = (ev) => {
    const newNetworkAssetFilter = Object.assign({}, state.networkAssetFilters, {
      [ev.target.name]: ev.target.checked,
    })
    setState({
      ...state,
      networkAssetFilters: newNetworkAssetFilter,
    })
  }

  const onToggleExportModal = () => {
    setIsExportDevicesConfigOpen(!isExportDevicesConfigOpen)
  }

  const onExportSuccess = () => {
    setIsExportDevicesConfigOpen(false)
  }

  const onExportDevicesToCsv = () => {
    setIsExportDevicesConfigOpen(true)
  }

  const onToggleExportDropdown = () => {
    setState({ ...state, isExportDropdownOpen: !state.isExportDropdownOpen })
  }

  const onClearFilters = (event) => {
    event.preventDefault()

    dispatchClearFilters()
    state.searchInputController.dispatchClearValueEvent()

    clearDeviceFilters()
  }

  const onChangeDevicesSearch = (event) => {
    dispatchUpdateDeviceSearchQuery(event.target.value)
  }

  const onChangeDeviceSort = (newOrderBy) => {
    let newOrderDirection = orderDirection
    if (newOrderBy === orderBy) {
      newOrderDirection = newOrderDirection === 'desc' ? 'asc' : 'desc'
    } else {
      newOrderDirection = 'desc' // default to descending on change of orderBy
    }

    dispatchUpdateDeviceSort(newOrderBy, newOrderDirection)
  }

  const createOnClickHandlerFor = (device) => {
    return () => {
      if (state.inTransition) {
        return
      }

      if (!device.currentCommission) {
        return
      }

      if (
        isNaN(device.currentCommission.location.latitude) ||
        isNaN(device.currentCommission.location.longitude)
      ) {
        return
      }

      const selectMapCenter = L.latLng([
        device.currentCommission.location.latitude,
        device.currentCommission.location.longitude,
      ])

      const duration = isMobile
        ? 0
        : (mapDistanceToTime(state.mapCenter, selectMapCenter) + 0.5) * 1000

      if (isMobile) {
        setState({
          ...state,
          selectedDevice: device,
          mapCenter: selectMapCenter,
          mapZoom: 15,
          inTransition: false,
        })
      } else {
        setState({
          ...state,
          mapCenter: selectMapCenter,
          mapZoom: 15,
          inTransition: true,
        })

        setTimeout(
          () =>
            setState({
              ...state,
              selectedDevice: device,
              mapCenter: selectMapCenter,
              mapZoom: 15,
              inTransition: false,
            }),
          duration
        )
      }
    }
  }

  const createOnClickMarker = (commission) => {
    return () =>
      setState({
        ...state,
        selectedDevice: commission.device,
      })
  }

  const handleRequestRetry = () => {
    refetchPagedDevices()
  }

  const renderDeviceItem = (device) => {
    const canEdit = authorizedUser.can(CAN_VIEW_DEVICES)

    return (
      <DeviceListItem
        key={device.serialNumber}
        device={device}
        onClick={createOnClickHandlerFor(device)}
        className={
          selectedDeviceId === device.id
            ? styles['device-list-item-selected']
            : styles['device-list-item']
        }
        canEdit={canEdit}
        onClickInstall={() => createOnClickInstallToDevice(device)}
      />
    )
  }

  return (
    <AppLayout
      title={t('headings.page_title')}
      contentsStyle="fixed-at-full-height"
    >
      <AssignNetworkAssetToDeviceModal
        googleMaps={googleMaps}
        isOpen={commissionToAssign.isCommissionToAssignModalOpen}
        toggle={onToggleAssignNetworkAssetModal}
        commission={commissionToAssign.commissionToAssign}
        onSuccess={refetchPagedDevices}
      />
      <AdvancedFilterModal
        handleClearFilters={handleClearAdvancedFilters}
        isOpen={state.isAdvancedFilterModalOpen}
        handleAccept={handleChangeDeviceAdvancedFilter}
        handleToggle={handleAdvancedFilterToggle}
      >
        <Row>
          <Col sm="5">
            <DeviceStateFilters
              handleChange={handleDeviceStateFiltersChange}
              filters={state.deviceStateFilters}
            />
            <NetworkAssetFilters
              handleChange={handleNetworkAssetFiltersChange}
              filters={state.networkAssetFilters}
            />
          </Col>
          <Col sm="7">
            <DeviceIssueFilters
              onChangeSelection={handleDeviceIssueFiltersChange}
              deviceIssuesFilter={state.deviceIssuesFilter}
            />
          </Col>
        </Row>
      </AdvancedFilterModal>

      <ExportDevicesConfigModal
        isOpen={isExportDevicesConfigOpen}
        toggle={onToggleExportModal}
        onSuccess={onExportSuccess}
        totalResultsCount={
          pagedDevicesResult.wasSuccessful()
            ? pagedDevicesResult.data.totalResults
            : 0
        }
        devicesQueryParams={{
          status: status,
          orderBy: orderBy,
          searchQuery: searchQuery,
          orderDirection: orderDirection,
        }}
      />

      <div className="container-fluid h-100">
        <Row className="h-100 flex-nowrap">
          <Col sm="12" lg="6" className={styles['list-section']}>
            <div className="d-flex flex-row justify-content-between">
              <Breadcrumb>
                <BreadcrumbItem>
                  <span>{t('buttons.management')}</span>
                </BreadcrumbItem>

                <BreadcrumbItem>
                  <NavLink to={routes.managementDevicesPath()}>
                    {t('buttons.management_devices')}
                  </NavLink>
                </BreadcrumbItem>
              </Breadcrumb>
              {isFeatureEnabled(FeatureFlags.devicesListV2, {
                omitOverridden: true,
              }) && (
                <Breadcrumb className="m-0 p-0" listClassName="m-0 px-0 py-2">
                  <BreadcrumbItem>
                    <NavLink
                      to={routes.managementDevicesPath()}
                      onClick={() => {
                        overrideFeatureFlag(FeatureFlags.devicesListV2, true)
                      }}
                    >
                      {t('v2/common/feature_flags:buttons.try_new_beta_view')}
                    </NavLink>
                  </BreadcrumbItem>
                </Breadcrumb>
              )}
            </div>
            <Row>
              <Col>
                <h1 name="heading-container" className={'mb-4'}>
                  {t('headings.devices')}
                  <DevicesViewSwitcher location={location} />
                  {authorizedUser.can(CAN_EXPORT) && (
                    <ExportDropdown
                      handleToggle={onToggleExportDropdown}
                      isOpen={state.isExportDropdownOpen}
                      className="float-right"
                    >
                      <DropdownItem
                        onClick={onExportDevicesToCsv}
                        disabled={!pagedDevicesResult.wasSuccessful()}
                      >
                        {t('buttons.export_csv')}
                      </DropdownItem>
                    </ExportDropdown>
                  )}
                </h1>
              </Col>
            </Row>

            <Row
              className="d-flex mb-3 justify-content-between align-items-center"
              noGutters={true}
            >
              <Col sm="auto">
                <div
                  name="search-results-text"
                  className={styles['search-results-text']}
                >
                  {pagedDevicesResult.isPending()
                    ? t('text.results_pending')
                    : resultsString}
                </div>
                <div className={styles['ordering-text']}>
                  {pagedDevicesResult.isPending() ? '' : orderingString}
                </div>
              </Col>
              <div className="d-flex justify-content-end">
                <Button
                  className="mr-2"
                  name="clear-filters-button"
                  color="secondary"
                  outline
                  onClick={onClearFilters}
                >
                  {t('buttons.clear_filters')}
                </Button>

                <InputGroup className={styles['search-input']}>
                  <DebouncedInput
                    type="search"
                    name="devices-search-input"
                    debounce={250}
                    onChange={onChangeDevicesSearch}
                    controller={state.searchInputController}
                    initialValue={searchQuery}
                    placeholder={t('text.search_by_serial_number')}
                  />

                  <InputGroupText>
                    <i className="fa fa-search"></i>
                  </InputGroupText>
                </InputGroup>

                <FilterDropdown
                  buttonStyle="mr-2"
                  handleAdvancedFilterToggle={handleAdvancedFilterToggle}
                  name="filter-input"
                  label={t('buttons.filter')}
                  options={filterList}
                  onChange={onChangeDeviceFilter}
                  value={
                    hasSimpleFilter
                      ? status[0]
                      : status
                      ? ADVANCED_FILTER_OPTION
                      : ''
                  }
                />

                <SortDropdown
                  name="sort-input"
                  options={sortList}
                  onChange={onChangeDeviceSort}
                  isDescending={orderDirection === 'desc'}
                  value={orderBy}
                />
              </div>
            </Row>

            {pagedDevicesResult.wasSuccessful() ? (
              <ActiveFiltersAlert
                className={styles['device-active-filters']}
                activeFilters={status}
              />
            ) : null}

            <AsyncList
              result={pagedDevicesResult}
              className={styles['device-list']}
              renderItem={renderDeviceItem}
              noResultText={t('text.no_devices_found')}
              onRequestRetry={handleRequestRetry}
            />
            {pagedDevicesResult.wasSuccessful() ? (
              <PaginationToolbar
                resultsPerPageOptionValues={[10, 25, 50, 100, 500]}
                visiblePageOptionCount={VISIBLE_DEVICE_PAGES}
                pageNumber={pagedDevicesResult.data.pageNumber}
                totalPages={pagedDevicesResult.data.totalPages}
                totalResults={pagedDevicesResult.data.totalResults}
                resultsPerPage={resultsPerPage}
                setPageNumber={setPageNumber}
                setResultsPerPage={setResultsPerPage}
              />
            ) : null}
          </Col>
          {!isMobile ? (
            <Col sm="6" className="pr-0">
              <StandardMap
                className="h-100"
                enablePanAnimation={true}
                zoom={state.mapZoom}
                bounds={state.mapBounds}
                center={state.mapCenter}
              >
                <MarkerClusterGroup
                  zoomToBoundsOnClick={true}
                  showCoverageOnHover={false}
                  removeOutsideVisibleBounds={true}
                >
                  {commissions.map((commission) => (
                    <DeviceCommissionMarker
                      key={commission.uuid}
                      onClick={createOnClickMarker(commission)}
                      commission={commission}
                      isPopupOpen={commission.device.id === selectedDeviceId}
                    >
                      <DeviceCommissionPopup
                        googleMaps={googleMaps}
                        commission={commission}
                      />
                    </DeviceCommissionMarker>
                  ))}
                </MarkerClusterGroup>
                {pagedDevicesResult.wasSuccessful() &&
                pagedDevicesResult.data.totalPages > 1 ? (
                  <Alert
                    name="has-more-devices-alert"
                    className={styles['device-pagination-alert']}
                    color="warning"
                  >
                    {t('alerts.has_more_paginated_devices')}
                  </Alert>
                ) : null}
              </StandardMap>
            </Col>
          ) : null}
        </Row>
      </div>
    </AppLayout>
  )
}

export default compose(
  requiresLogin,
  withUser,
  withTranslation([
    'src/management_path/devices_path/index_page',
    'src/components/summary/devices_summary',
    'v2/common/feature_flags',
    'common/text',
  ]),
  withGoogleMapsProvider,
  withWindowSizes,
  withTenantFeatureFlags
)(ManagementDevicesPage)
