import React from 'react'

import { Row, Col } from 'reactstrap'
import { withTranslation } from 'react-i18next'

import styles from './devices_header_summary.css'

function DevicesSummaryHeader({ t, devicesHeader }) {
  return (
    <Row>
      <Col key={'not-installed-devices'}>
        <Row className={styles['device-summary-row']}>
          <Col className={styles['device-summary-col']}>
            <div
              name="device-summary-count"
              className={styles['device-summary-number']}>
              {devicesHeader.notInstalled}
            </div>
            <div className={styles['device-summary-steel']} />
            <a className={styles['device-summary-text']} href={'./management/devices?s=notInstalled'}>
              {t('text.not_installed')}
            </a>
          </Col>
        </Row>
      </Col>
      <Col key={'installed-devices'}>
        <Row className={styles['device-summary-row']}>
          <Col className={styles['device-summary-col']}>
            <div
              name="device-summary-count"
              className={styles['device-summary-number']}>
              {devicesHeader.installed}
            </div>
            <div className={styles['device-summary-green']} />
            <a className={styles['device-summary-text']} href={'./management/devices?s=installed'}>
              {t('text.installed')}
            </a>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default withTranslation([
  'src/components/summary/devices_summary',
])(DevicesSummaryHeader)
