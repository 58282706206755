import gql from 'graphql-tag'
import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { compose } from 'redux'
import { graphql } from '@apollo/client/react/hoc'
import { NavLink, Redirect } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { Col, Row, Container,
  Breadcrumb, BreadcrumbItem, Nav } from 'reactstrap'

import AppLayout from '@@src/components/app_layout'
import requiresLogin from '@@src/components/security/requires_login'
import transformProps from '@@src/components/transform_props'
import defineQueryParams from '@@src/components/define_query_params'
import AsyncResultSwitch from '@@src/components/async_result_switch'
import EditAlertsConfigurationSection
from './edit_network_asset_page_sections/edit_alerts_configuration_section'
import EditLocationConfigurationSection
from './edit_network_asset_page_sections/edit_location_configuration_section'
import EditGeneralConfigurationSection
from './edit_network_asset_page_sections/edit_general_configuration_section'
import userPermissions from '@@src/components/permissions/user_permissions'
import AsyncResult from '@@src/utils/async_result'

import routes from '@@src/routes'
import { parseGraphQLResult } from '@@src/api/presenters'
import { createSelectGraphQLResult } from '@@src/utils'

export const ALERTS_TAB = 'alerts'
export const LOCATION_TAB = 'location'

const noop = () => {}

class EditNetworkAssetPage extends React.PureComponent {
  static propTypes = {
    dispatchUpdateQueryParams: PropTypes.func.isRequired,
    assetDetails: PropTypes.instanceOf(AsyncResult).isRequired,
    permissions: PropTypes.array.isRequired,
  }

  render() {
    const {
      t, tab, withQueryParamsLink, assetDetails, permissions,
    } = this.props

    if (assetDetails.wasSuccessful() &&
      (!permissions.includes('can_edit_network_assets') ||
      assetDetails.data.isCongruityAsset())) {
      return (
        <Redirect to={routes.networkAssetsDetailPath(assetDetails.data.id)} />
      )
    }

    return (
      <AppLayout title={t('headings.page_title')}>
        <Container>
          <Row className="mt-5">
            <Col>
              <AsyncResultSwitch
                result={assetDetails}
                renderSuccessResult={this.renderBreadcrumbs}
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <h1 className="mb-5">{t('headings.edit_network_asset')}</h1>
            </Col>
          </Row>

          <Row>
            <Col md="3">
              <Nav pills={true} vertical>
                <NavLink
                  to={withQueryParamsLink()}
                  isActive={noop}
                  name="general-settings"
                  className={classnames(
                    'btn btn-outline-primary border-0 text-left', {
                      active: !tab,
                    })}>
                  {t('buttons.general_settings')}
                </NavLink>

                <NavLink
                  to={withQueryParamsLink({ tab: LOCATION_TAB })}
                  isActive={noop}
                  name="location-settings"
                  className={classnames(
                    'btn btn-outline-primary border-0 text-left', {
                      active: tab === LOCATION_TAB,
                    })}>
                  {t('buttons.location_configuration')}
                </NavLink>

                <NavLink
                  to={withQueryParamsLink({ tab: ALERTS_TAB })}
                  name="alert-settings"
                  isActive={noop}
                  className={classnames(
                    'btn btn-outline-primary border-0 text-left', {
                      active: tab === ALERTS_TAB,
                    })}>
                  {t('buttons.alert_configuration')}
                </NavLink>
              </Nav>

            </Col>

            <Col md="9">
              {this.renderCurrentActiveSection(tab)}
            </Col>
          </Row>
        </Container>
      </AppLayout>
    )
  }

  renderBreadcrumbs = ({ data: { assetId, id } }) => {
    const { t } = this.props
    return (
      <Breadcrumb>
        <BreadcrumbItem>
          <span>
            {t('buttons.management')}
          </span>
        </BreadcrumbItem>

        <BreadcrumbItem>
          <NavLink
            to={routes.managementNetworkAssetsPath()}
            name="network-assets-link"
          >
            {t('buttons.management_network_assets')}
          </NavLink>
        </BreadcrumbItem>

        <BreadcrumbItem active>
          <NavLink
            to={routes.networkAssetsDetailPath(id)}
            name="network-asset-detail-link"
          >
            {assetId}
          </NavLink>
        </BreadcrumbItem>
      </Breadcrumb>
    )
  }

  renderCurrentActiveSection(tab) {
    const {
      match: {
        params: {
          id: assetId,
        },
      },
    } = this.props

    const processedId = parseInt(decodeURIComponent(assetId), 10)

    switch (tab) {
      case ALERTS_TAB: {
        return <EditAlertsConfigurationSection
          assetId={processedId}
        />
      }

      case LOCATION_TAB: {
        return <EditLocationConfigurationSection
          assetId={processedId}
        />
      }

      default:
        return <EditGeneralConfigurationSection
          assetId={processedId}
        />
    }
  }

  static ASSET_QUERY = gql`
  query MinimalNetworkAssetDetails($id: Int!) {
    networkAsset(id: $id) {
      id
      assetId
    }
  }
  `
}

export default compose(
  requiresLogin,
  userPermissions,
  withTranslation([
    'src/management_path/network_assets_path/edit_network_asset_page',
    'common/text',
  ]),
  defineQueryParams({
    tab: { alias: 't' },
  }),
  transformProps(() => ({ match }) => {
    return {
      id: parseInt(decodeURIComponent(match.params.id), 10),
      assetDetailResult: createSelectGraphQLResult('networkAsset', {
        mapResult: parseGraphQLResult,
        nullObject: null,
      }),
    }
  }),
  graphql(EditNetworkAssetPage.ASSET_QUERY, {
    options: ({ id }) => {
      return {
        fetchPolicy: 'network-only',
        variables: {
          id,
        },
      }
    },
    props: ({ data, ownProps: { assetDetailResult } }) => {
      return {
        refetchData: data.refetch,
        assetDetails: assetDetailResult(data),
      }
    },
  })
)(EditNetworkAssetPage)
