import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { omit } from 'lodash/fp/object'
import { DropdownItem } from 'reactstrap'

import styles from './app_dropdown_item.css'

export default function AppDropdownItem(props) {
  const { className, partial, children, ...rest } = props

  return (
    <DropdownItem
      {...omit('onChangeSelection')(rest)}
      className={classnames(
        className,
        styles[partial ? 'item-partial' : 'item']
      )}>
      {children}
    </DropdownItem>
  )
}

AppDropdownItem.propTypes = {
  partial: PropTypes.bool,
}
