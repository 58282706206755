import { useCallback, useEffect, useRef, useState } from 'react'
import { useApolloClient } from '@apollo/client'
import { mapFlattenedFiltersToQueryObject } from '../utils/filters'
import { NetworkAssetForMapModel } from '../models/network_asset_for_map.model'
import { getNetworkAssetsForMapCall } from '../../../../api/network_assets.service'
import GeoBounds from '../../../../../utils/graphs/geo_bounds'

export const useGetNetworkAssetsForMap = (filters) => {
  const client = useApolloClient()
  const abortController = useRef(null)
  const everywherePosition = useRef(null)
  const [results, setResults] = useState({ error: null, networkAssets: [], bounds: null, isLoading: true })

  const fetchNewNetworkAssetsForMap = useCallback(() => {
    if (abortController.current) {
      abortController.current.abort()
    }

    if (!results.isLoading) {
      setResults({ ...results, isLoading: true })
    }

    abortController.current = new AbortController()

    getNetworkAssetsForMapCall({
      client, abortController: abortController.current,
      filters: {
        search: filters.search,
        ...mapFlattenedFiltersToQueryObject(filters.filters),
      },
    })
      .then(({ networkAssets, meta }) => {
        meta.bounds && (everywherePosition.current = GeoBounds.fromJSON(meta.bounds))
        setResults({
          networkAssets: networkAssets.map(asset => NetworkAssetForMapModel.fromNetworkAsset(asset)),
          error: null,
          isLoading: false,
        })
      })
      .catch(error => {
        setResults({ error, networkAssets: [], bounds: null, isLoading: false })
      })
      .finally(() => {
        abortController.current = null
      })

    return () => {
      if (abortController.current) {
        abortController.current.abort()
        abortController.current = null
      }
    }
  }, [abortController.current, setResults, getNetworkAssetsForMapCall, results, filters])

  useEffect(() => {
    return fetchNewNetworkAssetsForMap()
  }, [filters])

  return { ...results, everywherePosition: everywherePosition.current }
}
