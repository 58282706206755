import React from 'react'
import { NavLink } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { Alert, Card, Form } from 'reactstrap'

import * as analytics from '@@src/analytics'
import routes from '@@src/routes'
import AppLayout from '@@src/components/app_layout'
import ErrorInfo from '@@src/components/error_info'
import AppFormGroup from '@@src/components/forms/app_form_group'
import PureComponentWithAutofocus
from '@@src/components/pure_component_with_autofocus'
import SubmitButton from '@@src/components/buttons/submit_button'
import { AppError, AsyncResult, FormFields } from '@@src/utils'
import { compose } from 'redux'
import { withAuthorization } from '../../_v2/contexts/authorization/authorization.context'

import styles from './index_page.css'

class PasswordRecoveryPage extends PureComponentWithAutofocus {
  render() {
    const { t } = this.props
    const { result } = this.state

    return (
      <AppLayout
        title={t('headings.page_title')}
        footerColor="dark"
        backgroundClassname={styles.background}>
        <Card className={styles['login-form-card']}>
          <h2 className={styles.heading}>{t('headings.password_recovery')}</h2>

          <p>{t('text.password_recovery_text')}</p>
          <p>{t('text.password_recovery_text_medium')}</p>

          {
            result.wasSuccessful() ? (
              <Alert color="success">{t('text.recovery_code_sent')}</Alert>
            ) : (
              <Form>
                {result.wasFailure() ? <ErrorInfo error={result.error}/> : null}

                <AppFormGroup
                  id="user-email"
                  type="email"
                  name="email"
                  label={t('labels.email')}
                  value={this.selectFieldValue('email')}
                  innerRef={this.getAutofocusTargetRef()}
                  onChange={this.formFields.onChangeHandlerFor('email')}
                  errorText={this.selectFieldErrorText('email')}/>

                <SubmitButton
                  name="send-password-recovery-code-button"
                  buttonStatus=""
                  color="primary"
                  onSubmitForm={this.onSubmitPasswordRecoveryForm}
                  result={this.state.result}
                  disabled={this.formFields.hasAnyValidationErrors()}
                  className={styles['send-password-recovery-link-button']}
                  submitText={t('buttons.send_password_recovery_code')}>
                </SubmitButton>
              </Form>
            )
          }

          <NavLink
            to={{
              search: routes.createSearchParams({
                email: this.selectFieldValue('email'),
              }),
              pathname: routes.loginPasswordRecoveryEnterRecoveryCodePath(),
            }}
            name="enter-recovery-code-button"
            className="btn btn-link btn-block">
            {t('buttons.recovery_code_received')}
          </NavLink>

          <NavLink
            to={routes.loginPath()}
            name="back-to-login-button"
            className="btn btn-link btn-block">
            {t('buttons.return_to_login')}
          </NavLink>
        </Card>
      </AppLayout>
    )
  }

  constructor(props) {
    super(props)

    this.formFields = new FormFields(this, 'formFields', {
      email: v => v ? '' : 'errors.required',
    })

    this.state = {
      result: AsyncResult.notFound(),
      formFields: this.formFields.initialState(),
    }
  }

  selectFieldValue(fieldName) {
    return this.formFields.selectValue(this.state, fieldName)
  }

  selectFieldErrorText(fieldName) {
    const { t } = this.props
    const error = this.formFields.selectError(this.state, fieldName)

    return error ? t(error) : ''
  }

  onSubmitPasswordRecoveryForm = async event => {
    event.preventDefault()

    this.setState({ result: AsyncResult.pending() })

    try {
      const res = await this.formFields.performPreSubmitChecks().catch(() => {
        return false
      })

      if (!res) {
        this.setState({ result: AsyncResult.notFound() })
        return
      }

      const email = String(this.selectFieldValue('email')).toLowerCase()
      await this.props.authorization.identityProvider.resetPasswordRequest(email)

      this.setState({ result: AsyncResult.success() })
    } catch (err) {
      analytics.logError(err)

      this.setState({ result: AsyncResult.fail(AppError.from(err)) })
    }
  }
}

export default compose(
  withAuthorization,
  withTranslation([
    'src/login_path/password_recovery_path/index_page',
    'common/forms',
  ])
)(PasswordRecoveryPage)
