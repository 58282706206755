import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import Tooltip from '@@src/components/tooltip'

export default class PointCloud extends PureComponent {
  static propTypes = {
    getX: PropTypes.func.isRequired,
    getY: PropTypes.func.isRequired,
    renderTooltipContents: PropTypes.func.isRequired,
    pointClassName: PropTypes.string,
  }

  render() {
    const { data: propsData, getX, getY, renderTooltipContents,
      pointClassName } = this.props

    return (
      <g>
        {
          propsData.map((data, index) => (
            <Tooltip.Wrapper key={`${index}-${getX(data)}`}>
              <Tooltip.Trigger>
                <circle
                  r="5"
                  id={`point-${index}`}
                  cx={getX(data)}
                  cy={getY(data)}
                  stroke="none"
                  className={pointClassName}/>
              </Tooltip.Trigger>

              <Tooltip.Contents>
                {renderTooltipContents(data)}
              </Tooltip.Contents>
            </Tooltip.Wrapper>
          ))
        }
      </g>
    )
  }
}
