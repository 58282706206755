import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { Map } from 'react-leaflet'
import GeoBounds from '../../../utils/graphs/geo_bounds'
import BaseMapControllerComponent from './components/base_map_controller.component'
import BaseMapTileComponent, { TILE_TYPES } from './components/base_map_tile.component'
import GeoCoordinate from '../../../utils/graphs/geo_coordinate'

import 'leaflet/dist/leaflet.css'
import './base_map.component.scss'

export const DEFAULT_MAP_OPTIONS = {
  minZoom: 0,
  maxZoom: 22,
}

const DEFAULT_MAP_POSITION = {
  zoom: 1,
  bounds: undefined,
  center: new GeoCoordinate(0, 0),
}

const BaseMapComponent = ({ children, onPositionChange = () => null, position, mapRef: externalMapRef, ...props }) => {
  const options = Object.assign(DEFAULT_MAP_OPTIONS, props.options)
  const mapRef = useRef(null)
  return (
    <Map
      className="h-100 w-100"
      minZoom={options.minZoom}
      maxZoom={options.maxZoom}
      zoom={position.zoom || DEFAULT_MAP_POSITION.zoom}
      center={position.center ? position.center.toLatLng() : DEFAULT_MAP_POSITION.center}
      maxBoundsViscosity={1.0}
      ref={(ref) => {
        mapRef.current = ref
        externalMapRef && (externalMapRef.current = ref)
      }}
    >
      <BaseMapTileComponent
        maxZoom={options.maxZoom}
        maxNativeZoom={options.maxZoom}
        attribution=""
      />

      <BaseMapControllerComponent mapRef={mapRef} onPositionChange={onPositionChange}>
        {children}
      </BaseMapControllerComponent>
    </Map>
  )
}

BaseMapComponent.propTypes = {
  children: PropTypes.node,
  mapRef: PropTypes.object,
  onPositionChange: PropTypes.func,
  position: PropTypes.shape({
    bounds: PropTypes.instanceOf(GeoBounds),
    center: PropTypes.instanceOf(GeoCoordinate),
    zoom: PropTypes.number,
  }),
  options: PropTypes.shape({
    tileType: PropTypes.oneOf(TILE_TYPES),
    minZoom: PropTypes.number,
    maxZoom: PropTypes.number,
  }),
}

export default BaseMapComponent
