import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash/fp/object'
import { noop } from 'lodash/fp/util'
import { flatten } from 'lodash/fp/array'
import gql from 'graphql-tag'
import { createSelector } from 'reselect'
import { scaleLinear } from 'd3-scale'

import GraphContext from '@@src/components/graphs/graph_context'
import LeftAxis from '@@src/components/graphs/left_axis'
import BottomTimeAxis from '@@src/components/graphs/bottom_time_axis'
import HistoryGraph, { GRAPH_PADDING, formatTicks } from
'@@src/management_path/devices_path/history_modal/history_graph'
import DragEventLayer, { ONLY_X }
from '@@src/components/graphs/drag_layer/drag_event_layer'
import DragControlsLayer from '@@src/components/graphs/drag_controls_layer'
import TemperatureHistoryPlotLayer from
'@@src/management_path/devices_path/temperature_history_modal/temperature_history_plot_layer' // eslint-disable-line max-len
import TelemetryGraphStateOverlay from
'@@src/management_path/devices_path/history_modal/telemetry_graph_state_overlay' // eslint-disable-line max-len
import AsyncResult from '@@src/utils/async_result'
import DragAreaOverlay
from '@@src/components/graphs/drag_layer/drag_area_overlay'
import {
  getTemperatureSymbol,
  TEMPERATURE_TYPE,
  convertUnitFromDB,
} from '@@src/utils/app_unit_conversion'
import AppSettingsConsumer from '@@src/components/app_settings_consumer'
import {
  INTERNAL_TEMPERATURE_UNIT,
  AVAILABLE_TEMPERATURE_UNITS,
  FAHRENHEIT,
} from '@@src/utils/unit_constants'

import styles from './temperature_history_graph.css'

const CELSIUS_TEMPERATURE_SCALE_DEFAULT = [0, 20]
const FAHRENHEIT_TEMPERATURE_SCALE_DEFAULT = [32, 68]
export const DEVICE_TELEMETRY_QUERY = gql`
  query DeviceTelemetryQuery(
    $id: Int!,
    $end: String!,
    $start: String!,
    $channels: [TelemetryChannelsTypes!]!
  ) {
    device(id: $id) {
      id
      serialNumber
      telemetry(start: $start, end: $end, channels: $channels) {
        time
        value
        channel
      }
    }
  }
`

export function temperatureScaleDefault(temperatureUnit) {
  if (temperatureUnit === FAHRENHEIT) {
    return FAHRENHEIT_TEMPERATURE_SCALE_DEFAULT
  }

  return CELSIUS_TEMPERATURE_SCALE_DEFAULT
}

class TemperatureHistoryGraph extends React.PureComponent {
  static defaultProps = {
    onAxesChange: noop,
    onResetZoom: noop,
    handleGraphPan: noop,
    temperatureUnit: INTERNAL_TEMPERATURE_UNIT,
  }

  static propTypes = {
    className: PropTypes.string,
    start: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    end: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
    panStart: PropTypes.instanceOf(Date),
    panEnd: PropTypes.instanceOf(Date),
    onAxesChange: PropTypes.func.isRequired,
    onResetZoom: PropTypes.func.isRequired,
    handleGraphPan: PropTypes.func.isRequired,
    temperatureUnit: PropTypes.oneOf(AVAILABLE_TEMPERATURE_UNITS).isRequired,
    selectXScale: PropTypes.func,
    refetchData: PropTypes.func,
    deviceWithTelemetryResult: PropTypes.instanceOf(AsyncResult),
  }

  render() {
    const {
      className,
      onAxesChange,
      onResetZoom,
      selectXScale,
      start,
      end,
      refetchData,
      deviceId,
      panStart,
      panEnd,
      handleGraphPan,
      timezone,
      temperatureUnit,
      deviceWithTelemetryResult,
    } = this.props
    const telemetrySegments = this.selectConvertedTelemetrySegments(this.props)

    return (
      <TelemetryGraphStateOverlay
        dataResult={deviceWithTelemetryResult}
        usesTooltips={false}>
        <HistoryGraph
          deviceId={deviceId}
          className={className}
          refetchData={refetchData}
          panStart={panStart}
          panEnd={panEnd}
          start={start}
          end={end}
          xScale={selectXScale(this.props)}
          yScale={this.selectYScale(this.props)}>
          <LeftAxis withGuides label={
            <g>
              <circle
                r="3"
                cx="-33"
                cy="-4"
                className={styles['temperature-legend']}/>
              <text transform="translate(-15, 0)">
                {getTemperatureSymbol(temperatureUnit)}
              </text>
            </g>
          } tickFormatter={formatTicks}/>
          <BottomTimeAxis timezone={timezone}/>

          <GraphContext.Consumer>
            {config => (
              <TemperatureHistoryPlotLayer
                graphConfig={config}
                telemetrySegments={telemetrySegments}/>
            )}
          </GraphContext.Consumer>

          <DragEventLayer
            dragBehaviour={ONLY_X}
            onDoubleClick={onResetZoom}>
            <DragControlsLayer
              onPan={handleGraphPan}
              onAxesChange={onAxesChange}
              dragBehaviour={ONLY_X}/>

            <DragAreaOverlay />
          </DragEventLayer>
        </HistoryGraph>
      </TelemetryGraphStateOverlay>
    )
  }

  selectConvertedTelemetrySegments = createSelector(
    [
      get('deviceWithTelemetryResult'),
      get('temperatureUnit'),
    ],
    (deviceWithTelemetryResult, temperatureUnit) => {
      if (!deviceWithTelemetryResult.wasSuccessful()) {
        return []
      }

      if (temperatureUnit !== INTERNAL_TEMPERATURE_UNIT) {
        return deviceWithTelemetryResult.data.telemetrySegments
          .map(({ index, data }) => {
            return {
              index,
              data: data.map(({ value, ...rest }) => {
                return {
                  value: convertUnitFromDB(
                    value,
                    temperatureUnit,
                    TEMPERATURE_TYPE
                  ),
                  ...rest,
                }
              }),
            }
          })
      } else {
        return deviceWithTelemetryResult.data.telemetrySegments
      }
    }
  )

  selectYScale = createSelector(
    [
      this.selectConvertedTelemetrySegments,
      get('temperatureUnit'),
    ],
    (telemetrySegments, temperatureUnit) => {
      const telemetry = flatten(telemetrySegments.map(({ data }) => data))
      const temperatures = telemetry
        .filter(t => t.channel === 'tcxo_temperature')

      if (temperatures.length === 0) {
        return scaleLinear().domain(temperatureScaleDefault(temperatureUnit))
      }

      const temperature = temperatures.map(tel => tel.value)

      const minTemperature = Math.min(...temperature)
      const maxTemperature = Math.max(...temperature)
      const range = (maxTemperature - minTemperature) || 0.5

      return scaleLinear().domain([
        minTemperature - range * GRAPH_PADDING,
        maxTemperature + range * GRAPH_PADDING,
      ])
    }
  )
}

export default function TemperatureHistoryGraphContainer(props) {
  return (
    <AppSettingsConsumer>
      {
        units => (
          <TemperatureHistoryGraph
            temperatureUnit={units.temperature}
            {...props} />
        )
      }
    </AppSettingsConsumer>
  )
}
