import React from 'react'
import PropTypes from 'prop-types'

import AsyncResult from '@@src/utils/async_result'
import AnalysisGraphToolbar
from '@@src/analysis_path/pressure_analysis_path/analysis_graph_toolbar'
import CpisViewProgressBar from '../../../components/progress_bars/cpis_view_progress_bar/cpis_view_progress_bar'

import styles from './cpis_view_toolbar.css'

export default class CpisViewToolbar extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    cpisDataResult: PropTypes.instanceOf(AsyncResult).isRequired,
  }

  render() {
    const { className, cpisDataResult } = this.props

    return (
      <AnalysisGraphToolbar className={className}>
        <CpisViewProgressBar
          className={styles.progressbar}
          cpisDataResult={cpisDataResult}/>
      </AnalysisGraphToolbar>
    )
  }
}
