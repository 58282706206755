import React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  DropdownToggle,
  UncontrolledTooltip,
} from 'reactstrap'
import classnames from 'classnames'
import { withTranslation } from 'react-i18next'
import moment from 'moment'
import { compose } from 'redux'
import gql from 'graphql-tag'
import { graphql } from '@apollo/client/react/hoc'
import { Link } from 'react-router-dom'

import Alert from '@@src/api/presenters/alert'
import { mapTypeToIcon } from '@@src/utils/network_asset'
import AlertListItemActions from '@@src/alerts_path/alert_list_item_actions'
import * as analytics from '@@src/analytics'
import { sleep } from '@@src/utils'
import AsyncResult from '@@src/utils/async_result'
import deviceIconError from '@@src/static_assets/svgs/device_icon_error.svg'
import routes from '@@src/routes'
import userPermissions from '@@src/components/permissions/user_permissions'
import AlertTypeLinkButton from '@@src/alerts_path/alert_type_link_button'
import {
  convertAlertUnitText,
  convertAlertThreshold,
  convertAlertValue,
} from '@@src/utils/app_unit_conversion'
import { AVAILABLE_PRESSURE_UNITS } from '@@src/utils/unit_constants'

import styles from './alert_list_item.css'

const INFLOWNET_WAIT = process.env.NODE_ENV === 'test' ? 0 : 1000

class AlertListItem extends React.PureComponent {
  static propTypes = {
    alert: PropTypes.instanceOf(Alert).isRequired,
    t: PropTypes.func.isRequired,
    pressureUnits: PropTypes.oneOf(AVAILABLE_PRESSURE_UNITS).isRequired,
    updateAlertStatus: PropTypes.func.isRequired,
    onChangeAlertStatus: PropTypes.func.isRequired,
    updateStatusResult: PropTypes.instanceOf(AsyncResult).isRequired,
    permissions: PropTypes.array.isRequired,
    className: PropTypes.string,
  }

  render() {
    const { t, className, alert, updateStatusResult,
      permissions, pressureUnits } = this.props
    const alertIsOpen = alert.status === Alert.OPEN_STATUS
    const alertStatusDescription = this.renderStatusDescription()
    const canEditAlerts = permissions.includes('can_edit_alerts')
    const isUpdatePending = updateStatusResult.isPending() &&
      updateStatusResult.data.alertId === alert.alertId
    const alertItemId = `alert-list-item-${alert.alertId}`

    return (
      <tr className={classnames(className, 'py-3 mx-3')}>
        <td>
          <div className="d-flex flex-column">
            <div>
              <span className={classnames(alert.statusIconClassname, 'mr-2', {
                'text-danger': alertIsOpen,
              })} />
              <span className={classnames({
                'font-weight-bold': alertIsOpen,
              })}>
                <Link
                  to={routes.alertDetailsPath(alert.alertId)}
                  className={styles['alert-reason-link']}
                >
                  {
                    t(alert.descriptionKey, {
                      threshold: convertAlertThreshold(alert, pressureUnits),
                      unit: t(convertAlertUnitText(alert, pressureUnits), {
                        count: convertAlertThreshold(alert, pressureUnits),
                      }),
                    })
                  }
                </Link>
              </span>
            </div>
            <div>
              {
                alertStatusDescription ?
                  alertStatusDescription
                  : null
              }
            </div>
          </div>
        </td>
        <td>#{alert.alertId}</td>
        <td>
          <div>
            {moment(alert.occurredAt).format('L')}
          </div>
          <div>
            {moment(alert.occurredAt).format('LTS')}
          </div>
        </td>
        <td>
          {this.renderAlertListItemIcon()}
        </td>
        <td className={`d-flex flex-row
        align-items-start justify-content-end
        ${styles['item-buttons']}`}>
          <div name="alert-comment"
            className="d-flex flex-column align-items-center mr-2">
            {
              alert.commentCount > 0 ?
              <>
                  <small
                    name="alert-comment-count"
                    className="d-flex align-items-center font-weight-bold">
                    <span className="far fa-comment mr-1" />
                    {alert.commentCount}
                  </small>
                  <small
                    name="last-comment-time"
                    className={`d-flex w-100
                                justify-content-end text-black-50
                                font-italic mt-2
                                justify-content-center
                                text-nowrap flex-grow-1`}>
                    {
                      moment(alert.lastCommentTime)
                        .isAfter(moment().subtract(30, 'd'))
                        ? moment(alert.lastCommentTime).fromNow()
                        : moment(alert.lastCommentTime).format('L')
                    }
                  </small>
                </>
                : null
            }
          </div>

          <div className="d-flex flex-row justify-content-end">
            <Button
              href={routes.alertDetailsPath(alert.alertId)}
              size="sm"
              className={classnames(styles['action-btn'], 'mr-2')}
              color="light">
              <span className="fa fa-info" />
            </Button>
            <AlertTypeLinkButton
              alert={alert}
              color="light"
              size="sm"
              className={classnames(styles['action-btn'], 'mr-2')}
              color="light">
              <span className="far fa-chart-line" />
            </AlertTypeLinkButton>
            <React.Fragment>
              {
                !canEditAlerts ?
                  <UncontrolledTooltip
                    modifiers={{ flip: { enabled: false } }}
                    placement="top"
                    target={alertItemId}>
                    {t('common/text:permissions.disabled')}
                  </UncontrolledTooltip>
                  : null
              }
              <div id={alertItemId}>
                <AlertListItemActions
                  alert={alert}
                  handleClickStatus={this.handleChangeAlertStatusClick}>
                  <DropdownToggle
                    disabled={isUpdatePending || !canEditAlerts}
                    name="change-status-dropdown-toggle"
                    className={classnames(styles['action-btn'], 'btn-sm')}
                    color={alertIsOpen ? 'primary' : 'light'}>
                    {
                      isUpdatePending ?
                        <span className="far fa-spinner-third fa-spin" /> :
                        <span className="far fa-angle-down" />
                    }
                  </DropdownToggle>
                </AlertListItemActions>
              </div>
            </React.Fragment>
          </div>

        </td>
      </tr>
    )
  }

  renderAlertListItemIcon = () => {
    const { alert } = this.props
    const alertIsOpen = alert.status === Alert.OPEN_STATUS
    let link = null

    if (alert.isEventSourceLocalisationAlert()) {
      link = routes.managementGroupsDetailPath(alert.details.groupId)
    } else if (alert.isDeviceNoCommsAlert() ||
      alert.isBatteryDepletingAlert()) {
      link = routes.managementDevicesDetailPath(alert.details.deviceId)
    } else {
      link = routes.networkAssetsDetailPath(alert.details.networkAssetId)
    }

    if (alert.isEventSourceLocalisationAlert()) {
      return (
        <div className="d-flex align-items-center">
          <span className={classnames('far fa-folder mr-1', {
            [styles['asset-icon-muted']]: !alertIsOpen,
          })} />
          <span className={classnames({
            'text-primary': alertIsOpen,
          })}>
            <Link to={link}>
              {alert.details.groupName}
            </Link>
          </span>
        </div>
      )
    } else if (alert.isDeviceNoCommsAlert() ||
      alert.isBatteryDepletingAlert()) {
      return (
        <div className="d-flex align-items-center">
          <img
            className={classnames('mr-1', styles['device-icon'])}
            name="alert-list-item-icon"
            src={deviceIconError} />
          <span className={classnames({
            'text-primary': alertIsOpen,
          }, 'mr-2')}>
            <Link to={link}>
              {alert.details.deviceSerialNumber}
            </Link>
          </span>
        </div>
      )
    } else {
      return (
        <React.Fragment>
          <div className="d-flex flex-direction-row">
            <span className="mr-1 d-flex align-items-center">
              <img
                name="alert-list-item-icon"
                className={classnames(styles['asset-icon'], {
                  [styles['asset-icon-muted']]: !alertIsOpen,
                })}
                src={mapTypeToIcon(alert.details.networkAssetType)} />
            </span>
            <span className={classnames({
              'text-primary': alertIsOpen,
            }, 'mr-2')}>
              <Link to={link}>
                {alert.details.networkAssetName}
              </Link>
            </span>
          </div>
          {
            alert.details.networkAssetFriendlyName ?
              <span className={`mr-1 ${styles['friendly-text']}`}>
                {alert.details.networkAssetFriendlyName}
              </span>
              : null
          }
        </React.Fragment>
      )
    }
  }

  renderStatusDescription = () => {

    const { t, alert, pressureUnits } = this.props

    if (alert.isStandardAlert() || alert.isEventAlert() ||
      alert.isBatteryDepletingAlert()) {
      return (
        <span>
          {convertAlertValue(alert, pressureUnits)}
          {' '}
          {t(convertAlertUnitText(alert, pressureUnits), {
            count: convertAlertValue(alert, pressureUnits, 5),
          })}
        </span>
      )
    } else {
      return ''
    }
  }

  handleChangeAlertStatusClick = async newStatus => {
    const {
      updateAlertStatus, alert, onChangeAlertStatus,
    } = this.props

    try {
      onChangeAlertStatus(AsyncResult.pending({
        alertId: alert.alertId,
      }))

      await updateAlertStatus({
        variables: {
          newStatus,
          alertId: alert.alertId,
        },
      })

      await sleep(INFLOWNET_WAIT)

      onChangeAlertStatus(AsyncResult.success())
    } catch (err) {
      analytics.logFatalError(err)
      onChangeAlertStatus(AsyncResult.fail(err))
    }
  }

  static UPDATE_STATUS_MUTATION = gql`
    mutation UpdateAlertStatus (
      $alertId: Int!,
      $newStatus: AlertStatus!,
    ) {
      updateAlertStatus (
        alertId: $alertId,
        newStatus: $newStatus,
      )
    }
  `
}

export default compose(
  userPermissions,
  graphql(AlertListItem.UPDATE_STATUS_MUTATION, {
    name: 'updateAlertStatus',
  }),
  withTranslation([
    'src/alerts_path/alert_list_item',
    'common/text',
  ])
)(AlertListItem)
