import React from 'react'
import classnames from 'classnames'

import styles from './loading_icon.css'

export default function LoadingIcon({ className, style }) {
  return (
    <div className={className} style={style}>
      <i className={classnames('fal fa-spinner-third', styles.icon)}/>
    </div>
  )
}
