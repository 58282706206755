import React from 'react'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

import styles from './index.css'

class CsvExportErrorModal extends React.PureComponent {
  onToggle = (...args) => {
    const { toggle } = this.props
    if (toggle) {
      toggle(...args)
    }
  }

  render() {
    const { isOpen } = this.props
    return (
      !isOpen ? null :
      <Modal
        isOpen={isOpen}
        toggle={this.onToggle}
        className={styles.container}>

        {
          this.renderStandardModal()
        }

      </Modal>
    )
  }

  renderStandardModal = () => {
    const { t, viewType } = this.props

    return (
      <div>
        <ModalHeader>{t('headings.title')}</ModalHeader>
        <ModalBody>
          <div className={styles['modal-body']}>
            {viewType === 'cpis' ?
              t('text.warning_cpis') : t('text.warning_pressure') }
          </div>
        </ModalBody>
        <ModalFooter className={styles['buttons-row']}>
          <Button
            name="cancel-button"
            type="button"
            color="primary"
            onClick={this.onToggle}>
            {t('buttons.ok')}
          </Button>
        </ModalFooter>
      </div>
    )
  }
}

export default compose(
  withTranslation([
    'src/analysis_path/csv_feedback_modal',
    'common/text',
  ])
)(CsvExportErrorModal)
