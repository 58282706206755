import React from 'react'
import { divIcon } from 'leaflet'
import { Marker } from 'react-leaflet'
import { MARKER_ANCHOR_HEIGHT, MARKER_HEIGHT, MARKER_WIDTH } from '../map_marker/constants'
import { renderToString } from 'react-dom/server'
import classnames from 'classnames'
import BaseMapComponent from '../base_map/base_map.component'

import GeoCoordinate from '../../../utils/graphs/geo_coordinate'
import PropTypes from 'prop-types'

const PreviewSinglePinMap = ({ mapPinPlacement, mapPinIcon, className }) => {
  return(
    <BaseMapComponent
      position={{
        center: mapPinPlacement,
        zoom: mapPinPlacement ? 8 : 4,
      }}>
      {
        mapPinPlacement &&
        <Marker
          position={mapPinPlacement.toArray()}
          icon={divIcon({
            iconSize: [MARKER_WIDTH, MARKER_HEIGHT],
            iconAnchor: [MARKER_WIDTH / 2, MARKER_ANCHOR_HEIGHT],
            shadowUrl: undefined,
            popupAnchor: [0, -0.7 * MARKER_HEIGHT],
            className: classnames('map-label', className),
            html: renderToString((
              <div className="marker-icon d-flex justify-content-center" style={{ width: MARKER_WIDTH }}>
                <img
                  width={MARKER_WIDTH}
                  height={MARKER_HEIGHT}
                  src={mapPinIcon}
                  alt="" />
              </div>
            )),
          })}
        />
      }
    </BaseMapComponent>
  )
}

PreviewSinglePinMap.propTypes = {
  mapPinPlacement: PropTypes.instanceOf(GeoCoordinate),
  mapPinIcon: PropTypes.string,
}

export default PreviewSinglePinMap
