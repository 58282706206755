import React from 'react'
import PropTypes from 'prop-types'
import { noop } from 'lodash/fp/util'
import { connect } from 'react-redux'

import * as DragCoverStore from '@@src/components/singletons/drag_cover'
import DragEvent from './drag_event'
import MouseEventLayer, { MouseEventContext }
from '@@src/components/graphs/mouse_event_layer'
import createEventDispatcher from '@@src/components/create_event_dispatcher'

export const NONE = 'none'
export const DRAGGING = 'dragging'
export const HOVERING = 'hovering'
export const DRAGGING_INTENT = 'draggingIntent'
export const INTERACTION_STATES = [NONE, DRAGGING_INTENT, DRAGGING, HOVERING]

export const ONLY_X = 'onlyX'
export const ONLY_Y = 'onlyY'
export const X_AND_Y = 'xAndY'
export const DRAG_BEHAVIOURS = [NONE, ONLY_X, ONLY_Y, X_AND_Y]

export const DragEventContext = React.createContext(null)

const DragEventDispatchContainer = props => (
  <MouseEventLayer
    className={props.className}
    onMouseUp={props.onMouseUp}
    onMouseDown={props.onMouseDown}
    onMouseMove={props.onMouseMove}
    onMouseEnter={props.onMouseEnter}
    onMouseLeave={props.onMouseLeave}
    onDoubleClick={props.onDoubleClick}>
    <MouseEventContext.Consumer>
      {({ Channel, withLocalCoords }) => (
        <DragEventLayer
          withLocalCoords={withLocalCoords}
          MouseEventChannel={Channel}
          {...props}/>
      )}
    </MouseEventContext.Consumer>
  </MouseEventLayer>
)

class DragEventLayer extends React.PureComponent {
  static defaultProps = {
    onDrag: noop,
    onDragEnd: noop,
    onDragStart: noop,
    withLocalCoords: a => a,

    dragThreshold: 15,
    dragBehaviour: X_AND_Y,
  }

  static propTypes = {
    onDrag: PropTypes.func.isRequired,
    onDragEnd: PropTypes.func.isRequired,
    onDragStart: PropTypes.func.isRequired,
    withLocalCoords: PropTypes.func.isRequired,
    MouseEventChannel: PropTypes.any.isRequired,

    dragThreshold: PropTypes.number.isRequired,
    dragBehaviour: PropTypes.oneOf(DRAG_BEHAVIOURS).isRequired,
  }

  dispatcher = createEventDispatcher()
  value = {
    Channel: this.dispatcher.Channel,
  }

  state = {
    dragState: NONE,
  }

  render() {
    const { children, MouseEventChannel } = this.props

    return (
      <MouseEventChannel
        onMouseUp={this.onMouseUp}
        onMouseDown={this.onMouseDown}
        onMouseMove={this.onMouseMove}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        onDoubleClick={this.onDoubleClick}>
        <DragEventContext.Provider value={this.value}>
          {children}
        </DragEventContext.Provider>
      </MouseEventChannel>
    )
  }

  onMouseUp = (event, localCoords) => {
    if (this.state.dragState === DRAGGING) {
      const drag = this.computeDragEvent(localCoords)
      this.props.onDragEnd(drag)
      this.dispatcher.dispatch('onDragEnd', drag)
    }

    this.setState({ dragState: NONE, dragStart: null })
  }

  onMouseMove = (event, localCoords) => {
    const { dragState } = this.state

    switch (dragState) {
      case DRAGGING_INTENT:
      case DRAGGING: {
        const {
          currentDragListener, registerAsDragListener, withLocalCoords,
        } = this.props

        if (!currentDragListener ||
            currentDragListener.onMouseUp !== this.onMouseUp) {
          registerAsDragListener({
            onMouseUp: withLocalCoords(this.onMouseUp),
            onMouseMove: withLocalCoords(this.onMouseMove),
          })
        }

        const drag = this.computeDragEvent(localCoords)

        if (drag.x || drag.y) {
          if (dragState !== DRAGGING) {
            this.props.onDragStart(drag)
            this.dispatcher.dispatch('onDragStart', drag)

            this.setState({ dragState: DRAGGING })
          }
        } else if (dragState !== DRAGGING_INTENT) {
          this.setState({ dragState: DRAGGING_INTENT })
        }

        this.props.onDrag(drag)
        this.dispatcher.dispatch('onDrag', drag)
        break
      }

      case HOVERING:
        break

      default:
        this.setState({ dragState: HOVERING })
        break
    }
  }

  onMouseDown = (event, localCoords) => {
    this.setState({ dragState: DRAGGING_INTENT, dragStart: localCoords })
  }

  onMouseEnter = () => {
    switch (this.state.dragState) {
      case DRAGGING_INTENT:
      case DRAGGING:
        break

      default:
        this.setState({ dragState: HOVERING })
        break
    }
  }

  onMouseLeave = () => {
    switch (this.state.dragState) {
      case DRAGGING_INTENT:
      case DRAGGING:
        break

      default:
        this.setState({ dragState: NONE })
        break
    }
  }

  onDoubleClick = () => {
    if (this.state.dragState !== HOVERING) {
      this.setState({ dragState: HOVERING })
    }
  }

  computeDragEvent(localCoords, dragStart = this.state.dragStart) {
    const { mouseX, mouseY, graphConfig } = localCoords
    const { dragThreshold, dragBehaviour } = this.props

    const diffX = mouseX - dragStart.mouseX
    const diffY = mouseY - dragStart.mouseY

    return DragEvent.from({
      x: (dragBehaviour === ONLY_X || dragBehaviour === X_AND_Y) &&
        Math.abs(diffX) > dragThreshold ? diffX : 0,
      y: (dragBehaviour === ONLY_Y || dragBehaviour === X_AND_Y) &&
        Math.abs(diffY) > dragThreshold ? diffY : 0,
      mouseX,
      mouseY,
      dragStart,
      graphConfig,
    })
  }
}

function mapStateToProps(state) {
  return {
    currentDragListener: DragCoverStore.selectDragListener(state),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    registerAsDragListener(callbacks) {
      dispatch(DragCoverStore.registerDragListener(callbacks))
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DragEventDispatchContainer)
