import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'reactstrap'
import { withTranslation } from 'react-i18next'

import ErrorInfo from '@@src/components/error_info'
import AsyncResultSwitch from '@@src/components/async_result_switch'
import { AsyncResult } from '@@src/utils'

import styles from './async_list.css'
import { ListPlaceholder } from '../../_v2/components/placeholders/list_placeholder'

class AsyncTable extends React.Component {
  static propTypes = {
    result: PropTypes.instanceOf(AsyncResult).isRequired,
    className: PropTypes.string,
    renderItem: PropTypes.func.isRequired,
    renderNotFoundResult: PropTypes.func,
    noResultText: PropTypes.string,
    onRequestRetry: PropTypes.func,
  }

  render() {
    const { name, result } = this.props

    return (
      <AsyncResultSwitch
        name={name}
        result={result}
        renderFailResult={this.renderFailResult}
        renderPendingResult={this.renderPendingResult}
        renderSuccessResult={this.renderSuccessResult}
        renderNotFoundResult={this.renderNotFoundResult}
      />
    )
  }

  renderSuccessResult = ({ data: items }) => {
    return items.length === 0
      ? this.renderNotFoundResult()
      : items.map(this.props.renderItem)
  }

  renderNotFoundResult = () => {
    const { renderNotFoundResult } = this.props

    if (typeof renderNotFoundResult === 'function') {
      return renderNotFoundResult()
    }

    return (
      <span className={styles['no-results-text']}>
        {this.props.noResultText || this.props.t('text.no_results')}
      </span>
    )
  }

  renderPendingResult = () => {
    const { columns } = this.props
    return (
      <tr>
        <td
          colSpan={columns}
          className="justify-content-center align-items-center"
        >
          <ListPlaceholder />
        </td>
      </tr>
    )
  }

  renderFailResult = ({ error }) => {
    const { t, onRequestRetry, columns } = this.props

    return (
      <React.Fragment>
        <tr>
          <td colSpan={columns}>
            <ErrorInfo error={error} />

            <div className={styles['no-results-container']}>
              <div
                className={`${styles['no-results-text']} 
                d-flex 
                justify-content-center
                align-items-center`}
              >
                <i className="fas fa-exclamation-triangle"></i>
                &nbsp;
                <span>{t('text.unable_to_load_results')}</span>
              </div>

              {onRequestRetry ? (
                <Button
                  name="retry-button"
                  color="light"
                  onClick={onRequestRetry}
                  className={styles['retry-button']}
                >
                  <i className="far fa-sync"></i>
                  &nbsp;
                  {t('buttons.retry')}
                </Button>
              ) : null}
            </div>
          </td>
        </tr>
      </React.Fragment>
    )
  }
}

export default withTranslation(['src/components/lists/async_list'])(AsyncTable)
