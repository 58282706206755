import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { FormFeedback } from 'reactstrap'

import DatetimePicker from './datetime_picker'

import styles from './datetime_picker_form_group.css'

export default class DatetimePickerFormGroup extends React.PureComponent {
  static defaultProps = {
    name: 'datetime-input',
    datetimeProps: {},
  }

  static propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired,
    datetimeProps: PropTypes.object.isRequired,
    className: PropTypes.string,
    labelText: PropTypes.string,
    errorText: PropTypes.string,
  }

  render() {
    const {
      name, className, errorText, labelText, onChange, value, datetimeProps,
    } = this.props

    return (
      <div className={classnames('form-group', className)}>
        {
          labelText ? (
            <label htmlFor={name}>
              {labelText}
            </label>
          ) : null
        }

        <DatetimePicker
          name={name}
          type="datetime"
          value={value}
          onChange={onChange}
          className={styles.picker}
          {...datetimeProps} />

        {
          errorText ? (
            <FormFeedback name={`${name}-error`} className={styles.error}>
              {errorText}
            </FormFeedback>
          ) : null
        }
      </div>
    )
  }
}
