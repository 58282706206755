import { useCallback, useState, useMemo } from 'react'
import gql from 'graphql-tag'
import { createSelector } from 'reselect'
import { createSelectGraphQLResult } from '../../utils/index'
import { parseSearchParams } from '../../utils'
import { parseGraphQLResult } from '../../api/presenters/index'
import {
  SEED_DEVICES_PAGE_NUMBER,
  SEED_DEVICES_RESULTS_PER_PAGE,
} from '../../management_path/devices_path/index_page'
import { useQuery } from '@apollo/client'

export function useDevicesInventory(location) {
  const DEVICES_QUERY = gql`
    query PagedDevices(
      $searchQuery: String
      $status: [String]
      $pageNumber: Int!
      $resultsPerPage: Int!
      $orderBy: String
      $orderDirection: String
      $onlyHealthy: Boolean!
    ) {
      pagedDevices(
        searchQuery: $searchQuery
        status: $status
        pageNumber: $pageNumber
        resultsPerPage: $resultsPerPage
        orderBy: $orderBy
        orderDirection: $orderDirection
        ignoreDevicesWithStates: [returned]
        onlyHealthy: $onlyHealthy
      ) {
        devices {
          id
          state
          serialNumber
          lastCommunication
          protocolVersion
          commissions {
            start
            end
            location {
              altitude
              latitude
              longitude
            }
          }
          currentCommission {
            start
            location {
              latitude
              longitude
            }
            currentInstallation {
              networkAsset {
                id
                assetId
                assetType
              }
            }
          }
          activeIssues {
            severity
            type
            description
          }
          activeStandardConfiguration {
            dateFrom
            secondsInterval
            timeZone
          }
        }
        pagination {
          pageNumber
          totalPages
          totalResults
        }
      }
    }
  `

  const selectParsedSearchParams = createSelector(
    [(locationObject) => locationObject.location.search],
    parseSearchParams
  )

  const [prevLocation, setPrevLocation] = useState(location)

  const selectDevicesStatusFilter = useCallback(() => {
    const filterParam = selectParsedSearchParams({ location }).s
    return filterParam && filterParam.split('+')
  }, [location])

  const selectIfOnlyHealthy = useCallback(() => {
    const filterParam = selectParsedSearchParams({ location }).s
    if (filterParam === 'healthy') {
      return true
    }
    return false
  }, [location])

  const [paginationData, setPaginationData] = useState({
    pageNumber: SEED_DEVICES_PAGE_NUMBER,
    resultsPerPage: SEED_DEVICES_RESULTS_PER_PAGE,
  })

  const selectPagination = createSelector([(pagination) => pagination], () => {
    if (prevLocation !== location) {
      setPrevLocation(location)
      setPaginationData({
        pageNumber: SEED_DEVICES_PAGE_NUMBER,
        resultsPerPage: SEED_DEVICES_RESULTS_PER_PAGE,
      })
      return {
        pageNumber: SEED_DEVICES_PAGE_NUMBER,
        resultsPerPage: SEED_DEVICES_RESULTS_PER_PAGE,
      }
    }
    return paginationData
  })

  const selectPagedDevicesResult = createSelectGraphQLResult('data', {
    mapResult: ({ pagedDevices }) => parseGraphQLResult(pagedDevices),
    nullObject: [],
  })

  const devicesDataResult = useQuery(DEVICES_QUERY, {
    variables: {
      status: selectDevicesStatusFilter(),
      searchQuery: selectParsedSearchParams({ location }).q,
      orderBy: selectParsedSearchParams({ location }).ob,
      orderDirection: selectParsedSearchParams({ location }).od,
      pageNumber: selectPagination(paginationData).pageNumber,
      resultsPerPage: selectPagination(paginationData).resultsPerPage,
      onlyHealthy: selectIfOnlyHealthy(),
    },
    fetchPolicy: 'network-only',
  })

  const pagedDevicesResult = useMemo(
    () => selectPagedDevicesResult(devicesDataResult),
    [devicesDataResult]
  )

  const commissions = useMemo(
    () =>
      pagedDevicesResult.data.map((d) => d.currentCommission).filter((a) => a),
    [pagedDevicesResult]
  )

  const setPageNumber = (number) => {
    setPaginationData({
      ...paginationData,
      pageNumber: number,
    })
  }

  const setResultsPerPage = (numberResultsPerPage) => {
    setPaginationData({
      ...paginationData,
      pageNumber: 1,
      resultsPerPage: numberResultsPerPage,
    })
  }

  return {
    ...devicesDataResult.variables,
    pagedDevicesResult,
    refetchPagedDevices: (...args) => devicesDataResult.refetch(...args),
    commissions,
    setPageNumber,
    setResultsPerPage,
  }
}
