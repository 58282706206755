import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { withTranslation } from 'react-i18next'
import { Button, UncontrolledTooltip } from 'reactstrap'
import { compose } from 'redux'

import Comment from '@@src/api/presenters/comment'
import { DEFAULT_TIMEZONE } from '@@src/utils'
import { CAN_EDIT_ALERTS } from '../_v2/contexts/user/consts/permissions'
import { withUser } from '../_v2/contexts/user/user.context'
import { AuthorizedUser } from '../_v2/models/authorized-user'

import styles from './alert_comment.css'

class AlertComment extends React.PureComponent {
  static propTypes = {
    comment: PropTypes.instanceOf(Comment).isRequired,
    authorizedUser: PropTypes.instanceOf(AuthorizedUser).isRequired,
    handleEdit: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    children: PropTypes.node,
    handleReply: PropTypes.func,
  }

  render() {
    const { t, comment, authorizedUser, children } = this.props
    const commentActionReplyId = `comment-reply-${comment.commentId}`
    const commentActionEditId = `comment-edit-${comment.commentId}`
    // todo verify authorized user
    const userEmail = authorizedUser.email
    const canEditAlerts = authorizedUser.can(CAN_EDIT_ALERTS)

    return (
      <li>
        <div className={styles['alert-comment-content']}>
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <span className="font-weight-bold mr-2">
                {comment.formattedAuthor(t)}
              </span>
              <span className="d-flex align-items-center text-black-50">
                {moment.utc(comment.updatedAt).tz(DEFAULT_TIMEZONE)
                  .format('lll')}
                {
                  comment.createdAt !== comment.updatedAt ?
                    <div className="ml-1">
                      ({t('text.edited')})
                    </div>
                    : null
                }
              </span>
            </div>
            <div className="d-flex">
              {
                comment.author.email === userEmail ?
                  <React.Fragment>
                    {
                      comment.deleted || canEditAlerts ?
                        null :
                        this.renderDisabledTooltip(commentActionEditId)
                    }
                    {
                      !comment.deleted ?
                        <div id={commentActionEditId}>
                          <Button
                            name="edit-comment-button"
                            disabled={!canEditAlerts}
                            className={styles['alert-comment-btn']}
                            size="sm"
                            color="light"
                            onClick={this.handleClickEditButton}>
                            <span className="fas fa-pencil-alt mr-1" />
                            {t('button.edit')}
                          </Button>
                        </div>
                        : null
                    }
                  </React.Fragment>
                  : null
              }
              {
                !canEditAlerts ?
                  this.renderDisabledTooltip(commentActionReplyId) :
                  null
              }
              {
                !comment.parentId && !comment.deleted ?
                  <div id={commentActionReplyId}>
                    <Button
                      name="reply-comment-button"
                      disabled={!canEditAlerts}
                      className={styles['alert-comment-btn']}
                      size="sm"
                      color="light"
                      onClick={this.handleClickReplyButton}>
                      <span className="fas fa-reply mr-1" />
                      {t('button.reply')}
                    </Button>
                  </div>
                  : null
              }
            </div>
          </div>
        </div>
        {children}
      </li>
    )
  }

  renderDisabledTooltip = tooltipId => {
    const { t } = this.props

    return (
      <UncontrolledTooltip
        modifiers={{ flip: { enabled: false } }}
        placement="left"
        target={tooltipId}>
        {t('common/text:permissions.disabled')}
      </UncontrolledTooltip>
    )
  }

  handleClickReplyButton = () => {
    const { handleReply, comment } = this.props

    handleReply(comment.commentId)
  }

  handleClickEditButton = () => {
    const { handleEdit, comment } = this.props

    handleEdit(comment.commentId)
  }
}

export default compose(
  withUser,
  withTranslation([
    'src/alerts_path/alert_comment',
    'common/text',
  ]),
)(AlertComment)
