export const apiUrl = process.env.API_URL
export const tenant =
  process.env.TENANT || window.location.hostname === 'localhost'
    ? process.env.TENANT
    : window.location.hostname.split('.')[0]

export default {
  apiUrl,
  tenant,
}
