import React from 'react'
import { scaleLinear } from 'd3-scale'

export class GraphConfig {
  constructor({
    width, height,
    topPadding = 0,
    leftPadding = 0,
    rightPadding = 0,
    bottomPadding = 0,
    xScale = scaleLinear().domain([0, 1]),
    yScale = scaleLinear().domain([0, 1]),
    yScale2 = scaleLinear().domain([0, 1]),
  }) {
    Object.assign(this, {
      width,
      height,
      xScale: xScale.range([leftPadding, width - rightPadding]),
      yScale: yScale.range([height - bottomPadding, topPadding]),
      yScale2: yScale2.range([height - bottomPadding, topPadding]),
      topPadding,
      leftPadding,
      rightPadding,
      bottomPadding,
    })

    Object.freeze(this)
  }

  get plotAreaWidth() {
    return Math.max(this.width - this.leftPadding - this.rightPadding, 0)
  }

  get plotAreaHeight() {
    return Math.max(this.height - this.topPadding - this.bottomPadding, 0)
  }
}

const GraphContext = React.createContext(new GraphConfig({
  width: 100,
  height: 100,
}))

export function withGraphConfig({ graphConfigAlias = 'graphConfig' } = {}) {
  return Component => {
    return function WithGraphConfig(props) {
      return (
        <GraphContext.Consumer>
          {config => <Component {...{
            ...props,
            [graphConfigAlias]: config,
          }}/>}
        </GraphContext.Consumer>
      )
    }
  }
}

export default GraphContext
