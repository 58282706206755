import { useApolloClient } from '@apollo/client'
import { CREATE_INSTALLATION } from '../../../../api/queries/network_asset'

const useCreateNetworkAssetInstallation = () => {
  const client = useApolloClient()
  return (variables) =>
    client
      .mutate({
        mutation: CREATE_INSTALLATION,
        variables: variables,
      })
}

export default useCreateNetworkAssetInstallation
