import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import LoadingIcon from '../../../../components/loading_icon'

import PropTypes from 'prop-types'

const ExportSourceModal = ({
  isOpen = false,
  toggle = () => null,
  onExport = () => null,
  header = '',
  body = '',
  enabled = true,
}) => {
  const { t } = useTranslation('v2/common/buttons')

  return(
    <Modal isOpen={isOpen} toggle={toggle} >
      <ModalHeader toggle={toggle}>
        {header}
      </ModalHeader>
      <ModalBody>
        {body}
      </ModalBody>
      <ModalFooter className={'d-flex justify-content-end'}>
        <Button
          name="clear-filters-button"
          type="button"
          color="light">
          {t('v2/common/buttons:cancel')}
        </Button>
        <Button
          disabled={!enabled}
          type="submit"
          name="accept-button"
          color="primary"
          onClick={onExport}>
          { !enabled ? <LoadingIcon /> : t('v2/common/buttons:export')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

ExportSourceModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  onExport: PropTypes.func.isRequired,
  header: PropTypes.oneOf([PropTypes.node, PropTypes.string]),
  body: PropTypes.oneOf([PropTypes.node, PropTypes.string]),
  enabled: PropTypes.bool,
}

export default ExportSourceModal
