import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { withTranslation } from 'react-i18next'
import {
  Label, Button, Dropdown, DropdownMenu,
  DropdownToggle, FormFeedback, Col, Row,
} from 'reactstrap'

import FormFields from '@@src/utils/form_fields'
import DatetimePicker from '@@src/components/forms/datetime_picker'
import * as analytics from '../../../analytics'
import QuickTimeRangesPicker from './quick_time_ranges_picker'
import {
  selectDateFormatter, isValidTimezone, DEFAULT_TIMEZONE, AVAILABLE_TIME_ZONES,
} from '@@src/utils'
import TimeZoneSelect from '@@src/components/forms/inputs/time_zone_select'

import styles from './index.css'

class TimeRangePicker extends React.PureComponent {
  static defaultProps = {
    timezone: DEFAULT_TIMEZONE,
    displayQuickTimeRanges: true,
    source: 'unknown',
  }

  static propTypes = {
    endTime: PropTypes.instanceOf(Date).isRequired,
    timezone: PropTypes.oneOf(AVAILABLE_TIME_ZONES).isRequired,
    startTime: PropTypes.instanceOf(Date).isRequired,
    onChange: PropTypes.func.isRequired,
    displayQuickTimeRanges: PropTypes.bool.isRequired,
    source: PropTypes.string.isRequired,
  }

  render() {
    const {
      t, className, timezone, displayQuickTimeRanges,
      source,
    } = this.props
    const dateFormatter = selectDateFormatter(timezone)

    return (
      <Dropdown
        isOpen={this.state.isOpen}
        toggle={this.toggle}
        className={className}>
        <DropdownToggle
          color="outline-primary"
          className={styles['dropdown-toggle']}>
          <div>
            <div>
              {dateFormatter(this.props.startTime, 'L')}
            </div>
            <div>
              {dateFormatter(this.props.startTime, 'LT')}
            </div>
          </div>
          <div className="mx-2">
            <i className="far fa-arrow-right"></i>
          </div>

          <div>
            <div>
              {dateFormatter(this.props.endTime, 'L')}
            </div>
            <div>
              {dateFormatter(this.props.endTime, 'LT')}
            </div>
          </div>

          <div className={styles.timezone}>
            {t(`common/text:${timezone}`).split('/').map((item, key) => {
              return (
                <span key={key}>
                  {item}
                  <br />
                </span>
              )
            })}
          </div>
        </DropdownToggle>

        <DropdownMenu className={'p-3'}>

          <Row className="flex-nowrap">

            <Col sm="6" className={classnames('input-group')}>
              <Label className={styles['input-label']} for="start-time-input">
                {t('text.from')}
              </Label>
            </Col>

            <Col sm="6" className={classnames('input-group')}>
              <Label className={styles['input-label']} for="end-time-input">
                {t('text.to')}
              </Label>
            </Col>

          </Row>

          <Row className="flex-nowrap">

            <Col sm="6" className="input-group pr-0 flex-nowrap">
              <DatetimePicker
                id="start-time-input"
                name="start-time-input"
                value={this.selectFieldValue('startTime')}
                locale={dateFormatter.locale}
                invalid={true}
                onChange={this.onChangeStartTime}
                inputProps={{
                  className: classnames('form-control', {
                    'is-invalid': this.selectFieldError('startTime'),
                  }),
                }}
                className={styles['datetime-picker']}
                isValidDate={this.isStartDateValid}
                displayTimeZone={timezone} />

              {
                (this.selectFieldError('startTime')) ?
                  <FormFeedback style={{ display: 'block' }}>
                    {t(this.selectFieldError('startTime'))}
                  </FormFeedback> : null
              }

              <span className={styles['data-range-arrow']}>
                <i className="far fa-arrow-right" />
              </span>
            </Col>

            <Col sm="6" className={classnames('input-group')}>
              <DatetimePicker
                id="end-time-input"
                name="end-time-input"
                value={this.selectFieldValue('endTime')}
                locale={dateFormatter.locale}
                onChange={this.onChangeEndTime}
                inputProps={{
                  className: classnames('form-control', {
                    'is-invalid': this.selectFieldError('endTime'),
                  }),
                }}
                className={styles['datetime-picker']}
                isValidDate={this.isEndDateValid}
                displayTimeZone={timezone} />

              {
                (this.selectFieldError('endTime')) ?
                  <FormFeedback style={{ display: 'block' }}>
                    {t(this.selectFieldError('endTime'))}
                  </FormFeedback> : null
              }
            </Col>

          </Row>

          <Row>
            <Col sm="12">
              <TimeZoneSelect
                value={this.selectFieldValue('timezone')}
                handleChange={this.onChangeTimezone}
                className="mt-3" />
            </Col>
          </Row>

          <div className={classnames('d-flex align-items-start', {
            'flex-column': displayQuickTimeRanges,
          })}>
            <Button
              name="apply-changes-button"
              block
              color="primary"
              onClick={this.applyTimeRange}
              disabled={this.dateFields.hasAnyValidationErrors()}
              className={classnames('mt-3', {
                'order-1': !displayQuickTimeRanges,
              })}>
              {t('buttons.apply_changes')}
            </Button>

            {
              displayQuickTimeRanges ?
                <QuickTimeRangesPicker
                  onChangeStartTime={this.onChangeStartTime}
                  onChangeEndTime={this.onChangeEndTime}
                  applyTimeRange={this.applyTimeRange}
                  source={source}
                />
                : null
            }

            <Button
              color="light"
              className={classnames('mt-3', {
                'mr-2': !displayQuickTimeRanges,
              })}
              onClick={this.toggle}>
              {t('buttons.cancel')}
            </Button>
          </div>

        </DropdownMenu>
      </Dropdown>
    )
  }

  constructor(props) {
    super(props)

    this.dateFields = new FormFields(this, 'dateFields', {
      timezone: tz => {
        return isValidTimezone(tz) ? '' : 'errors.invalid_timezone'
      },
      startTime: date => {
        if (typeof date === 'string') {
          return 'errors.invalid_date'
        } else if (!date) {
          return 'errors.required'
        } else {
          return ''
        }
      },
      endTime: (date, { startTime }) => {
        if (typeof date === 'string') {
          return 'errors.invalid_date'
        } else if (!date) {
          return 'errors.required'
        } else if (typeof startTime !== 'string' && date.isBefore(startTime)) {
          return 'errors.invalid_range'
        } else {
          return ''
        }
      },
    })

    this.state = this.createInitialState(props)
  }

  createInitialState(props, isOpen = false) {
    return {
      isOpen,
      dateFields: this.dateFields.initialState({
        endTime: moment(props.endTime),
        timezone: props.timezone,
        startTime: moment(props.startTime),
      }),
    }
  }

  selectFieldValue(fieldName) {
    return this.dateFields.selectValue(this.state, fieldName)
  }

  selectFieldError(fieldName) {
    return this.dateFields.selectError(this.state, fieldName)
  }

  onChangeStartTime = (date) => {
    this.dateFields.updateFieldValue('startTime', date)
  }

  onChangeEndTime = (date) => {
    this.dateFields.updateFieldValue('endTime', date)
  }

  onChangeTimezone = event => {
    this.dateFields.updateFieldValue('timezone', event.target.value)
  }

  isStartDateValid(date) {
    return date.isBefore(moment())
  }

  isEndDateValid(date) {
    return date.isBefore(moment())
  }

  toggle = () => {
    if (this.state.isOpen) {
      this.setState(this.createInitialState(this.props))
    } else {
      this.setState(this.createInitialState(this.props, true))
    }
  }

  applyTimeRange = async () => {
    const res =
      await this.dateFields.performPreSubmitChecks().catch(() => {
        return false
      })

    if (!res) {
      return
    }

    const { onChange } = this.props
    const startTime = this.selectFieldValue('startTime')
    const endTime = this.selectFieldValue('endTime')

    // Track the time range that is requested by the customer
    analytics.trackSelectedAnalysisPeriod({
      start: startTime.toDate(),
      end: endTime.toDate(),
    })

    onChange({
      endTime: endTime.toDate(),
      timezone: this.dateFields.selectIsPristine(this.state, 'timezone') ?
        undefined :
        this.selectFieldValue('timezone'),
      startTime: startTime.toDate(),
    })
    this.toggle()
  }
}

export default withTranslation([
  'src/components/dropdowns/time_range_picker/index',
  'common/text',
])(TimeRangePicker)
