import gql from 'graphql-tag'
import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { graphql } from '@apollo/client/react/hoc'
import { withTranslation } from 'react-i18next'
import classnames from 'classnames'
import moment from 'moment'
import { createSelector } from 'reselect'
import { get } from 'lodash/fp/object'

import * as analytics from '@@src/analytics'
import ErrorInfo from '@@src/components/error_info'
import FormFields from '@@src/utils/form_fields'
import AppFormGroup from '@@src/components/forms/app_form_group'
import SubmitButton from '@@src/components/buttons/submit_button'
import { AsyncResult, validGroupCategories } from '@@src/utils'
import {
  Row, Col, Input, Label, ModalHeader, ModalBody, ModalFooter, Modal,
  Button,
} from 'reactstrap'
import DeviceIconWithStatus
from '@@src/components/icons/device_icon_with_status'
import NetworkAssetIcon from '@@src/components/icons/network_asset_icon'
import GroupIcon from '@@src/components/icons/group_icon'
import DeviceSessionConfigurationFields, {
  withDeviceSessionConfigurationFieldsProvider,
} from '@@src/components/device_configuration/device_session_configuration_fields' // eslint-disable-line max-len
import DeviceSessionConfigurationSourceField, {
  withDeviceSessionConfigSourceProvider,
  CONFIGURATION_SHAPE,
} from '@@src/components/device_configuration/device_session_configuration_source_field' // eslint-disable-line max-len
import Device from '@@src/api/presenters/device'
import TenantDefaultConfiguration from
'@@src/components/device_configuration/tenant_default_configuration'
import { MixedGroupDetailsType } from '@@src/api/presenters/group'

import styles from './create_group_modal.css'

const TIME_FORMAT = 'HH:mm:ss A'
const NETWORK_ASSET_GROUP_TYPE = 'network_assets'
const DEVICES_GROUP_TYPE = 'devices'
const GROUPS_GROUP_TYPE = 'groups'
const GROUP_TYPES = [
  NETWORK_ASSET_GROUP_TYPE,
  DEVICES_GROUP_TYPE,
  GROUPS_GROUP_TYPE,
]

class CreateGroupModal extends React.PureComponent {
  static defaultProps = {
    onSuccess: () => { },
    onCreateAndCreateAnother: () => { },
    hasDeviceConfigFieldValidationErrors: false,
    configurations: [],
  }

  static propTypes = {
    onSuccess: PropTypes.func.isRequired,
    onCreateAndCreateAnother: PropTypes.func.isRequired,
    createGroup: PropTypes.func.isRequired,
    createChild: PropTypes.func.isRequired,
    deviceConfigIntervals: PropTypes.arrayOf(PropTypes.number),
    hasDeviceConfigFieldValidationErrors: PropTypes.bool,
    setSourceAggregateType: PropTypes.func,
    resetSourceAggregateType: PropTypes.func,
    resetDeviceConfigFields: PropTypes.func,
    performDeviceConfigPreSubmitChecks: PropTypes.func,
    getDeviceConfigFieldChangeHandler: PropTypes.func,
    selectDeviceConfigFieldValue: PropTypes.func,
    sourceAggregateType: PropTypes.string,
    selectDeviceConfigFieldErrorText: PropTypes.func,
    configurations: PropTypes.arrayOf(
      PropTypes.shape(CONFIGURATION_SHAPE)
    ).isRequired,
    parentGroup: PropTypes.instanceOf(MixedGroupDetailsType),
  }

  render() {
    const {
      t, isOpen, hasDeviceConfigFieldValidationErrors,
    } = this.props
    const { result, selectedType } = this.state

    return (
      <Modal isOpen={isOpen} toggle={this.onToggle}>
        <ModalHeader toggle={this.onToggle}>
          {t('headings.title')}
        </ModalHeader>
        <ModalBody>
          { result.wasFailure() ? <ErrorInfo error={result.error}/> : null }
          <Row>
            <Col>
              <AppFormGroup
                name="category"
                type="select"
                label={t('labels.category')}
                value={this.selectFieldValue('category')}
                onChange={this.fields.onChangeHandlerFor('category')}
                errorText={this.selectFieldErrorText('category')}>
                <option value="">
                  {t('common/text:text.blank_select_category')}
                </option>
                {
                  validGroupCategories(selectedType).map(category => (
                    <option key={category} value={category}>
                      {t(`common/text:text.category_${category}`)}
                    </option>
                  ))
                }
              </AppFormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <AppFormGroup
                name="name"
                maxLength={60}
                type="text"
                label={t('labels.name')}
                value={this.selectFieldValue('name')}
                onChange={this.fields.onChangeHandlerFor('name')}
                errorText={this.selectFieldErrorText('name')}>
              </AppFormGroup>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex flex-column">
              <Label className="m-0 font-weight-bold">
                {t('labels.content_type')}
              </Label>
              <Label className="text-muted small">
                {t('labels.warning')}
              </Label>
            </Col>
          </Row>
          <Row>
            <Col>
              <ul className="list-unstyled mb-0">
                {
                  GROUP_TYPES.map((type, i) => (
                    <li
                      className={classnames('d-flex align-items-center ', {
                        'mb-2': i !== GROUP_TYPES.length - 1,
                      })}
                      key={type}>
                      <div className={styles.icon}>
                        {this.renderGroupTypeIcon(type)}
                      </div>
                      <Label onClick={stopPropagation} className="ml-4 mb-0">
                        <Input
                          type="radio"
                          name="group-type"
                          value={type}
                          checked={type === selectedType}
                          disabled={this.radioDisabled(type)}
                          onChange={
                            this.onSelectContentType(type)
                          }/>
                        {t(`labels.${type}`)}
                      </Label>
                    </li>
                  ))
                }
              </ul>
            </Col>
          </Row>
          {
            this.isCreatingDeviceSessionConfig() ?
              this.renderDeviceConfiguration() : null
          }
        </ModalBody>
        <ModalFooter>
          <Button
            name="close-button"
            type="button"
            color="secondary"
            onClick={this.onToggle}>
            {t('buttons.close')}
          </Button>
          <SubmitButton
            name="save-and-create-button"
            color="secondary"
            result={result}
            disabled={
              hasDeviceConfigFieldValidationErrors || result.isPending() ||
                this.fields.hasAnyValidationErrors()
            }
            buttonStatus=""
            onSubmitForm={this.onSaveAndOpenAnother}
            submitText={t('buttons.save_and_create_another')}>
          </SubmitButton>
          <SubmitButton
            name="save-button"
            color="primary"
            result={result}
            disabled={
              hasDeviceConfigFieldValidationErrors || result.isPending() ||
                this.fields.hasAnyValidationErrors()
            }
            buttonStatus=""
            onSubmitForm={this.onSubmitForm}
            submitText={t('buttons.save_and_open')}>
          </SubmitButton>
        </ModalFooter>
      </Modal>
    )
  }

  radioDisabled(type) {
    const validCategoriesForGroupType = validGroupCategories(type)
    return !validCategoriesForGroupType.includes(
      this.selectFieldValue('category'))
  }

  renderGroupTypeIcon(type) {
    if (type === DEVICES_GROUP_TYPE) {
      return (
        <DeviceIconWithStatus
          className="w-100 h-100"
          device={{ status: 'commissioned' }}
        />
      )
    } else if (type === NETWORK_ASSET_GROUP_TYPE) {
      return (
        <NetworkAssetIcon
          className="w-100 h-100"
          networkAsset={{ assetType: 'tapping' }}
        />
      )
    } else {
      return (
        <GroupIcon
          className={classnames(styles['group-icon'], 'text-center')} />
      )
    }
  }

  renderDeviceConfiguration = () => {
    const {
      t, selectDeviceConfigFieldValue, selectDeviceConfigFieldErrorText,
      getDeviceConfigFieldChangeHandler, deviceConfigIntervals,
      sourceAggregateType, configurations,
    } = this.props
    const { result } = this.state

    return (
      <React.Fragment>
        <hr className="my-4" />
        <Row>
          <Col>
            <h3 className="h5 mb-4">
              {t('headings.devices')}
            </h3>
            <DeviceSessionConfigurationSourceField
              handleChange={this.handleDeviceConfigPresetChange}
              sourceAggregateType={sourceAggregateType}
              configurations={configurations} />
            {
              sourceAggregateType ===
                Device.CONFIGURATION_SOURCE_GROUP_TYPE ?
                <DeviceSessionConfigurationFields
                  formResult={result}
                  intervals={deviceConfigIntervals}
                  selectFormFieldValue={selectDeviceConfigFieldValue}
                  selectFormFieldsError={selectDeviceConfigFieldErrorText}
                  getFormFieldsChangeHandler={
                    getDeviceConfigFieldChangeHandler
                  } />
                :
                <TenantDefaultConfiguration tenantConfiguration={
                  this.selectTenantConfiguration(this.props) || {}
                } />
            }
          </Col>
        </Row>
      </React.Fragment>
    )
  }

  constructor(props) {
    super(props)

    this.fields = new FormFields(this, 'createGroupFields', {
      name: v => v ? '' : 'errors.required',
      category: v => v ? '' : 'errors.required',
    })

    const state = {
      result: AsyncResult.notFound(),
      createGroupFields: this.fields.initialState(),
      selectedType: NETWORK_ASSET_GROUP_TYPE,
    }

    if (this.isCreatingDeviceSessionConfig()) {
      state.sourceAggregateType = Device.CONFIGURATION_SOURCE_TENANT_TYPE
    }

    this.state = state
  }

  selectFieldValue(fieldName) {
    return this.fields.selectValue(this.state, fieldName)
  }

  selectFieldErrorText(fieldName) {
    const error = this.fields.selectError(this.state, fieldName)

    return error ? this.props.t(error) : error
  }

  onToggle = (...args) => {
    const { toggle } = this.props
    this.resetState()

    if (toggle) {
      toggle(...args)
    }
  }

  resetState = () => {
    const { resetSourceAggregateType, resetDeviceConfigFields } = this.props

    this.setState({
      result: AsyncResult.notFound(),
      createGroupFields: this.fields.initialState(),
      selectedType: NETWORK_ASSET_GROUP_TYPE,
    })

    if (this.isCreatingDeviceSessionConfig()) {
      resetDeviceConfigFields()
      resetSourceAggregateType()
    }
  }

  onSelectContentType(type) {
    return () => {
      this.setState({
        selectedType: type,
      })
    }
  }

  onSaveAndOpenAnother = async event => {
    const openGroup = false
    return this.onSubmitForm(event, openGroup)
  }

  onSubmitForm = async (event, openGroup = true) => {
    const { createGroup, createChild, onSuccess, onCreateAndCreateAnother,
      parentGroup } = this.props

    event.preventDefault()

    this.setState({ result: AsyncResult.pending() })

    try {
      const hasFormError = await this.performPreSubmitChecks()
      let newGroupId

      if (!hasFormError) {
        this.setState({ result: AsyncResult.notFound() })
        return
      }

      if (parentGroup) {
        const result = await createChild({
          variables: this.getCreateChildGroupVariables(),
        })

        newGroupId = result.data.createChild.id
      } else {
        const result = await createGroup({
          variables: this.getCreateGroupVariables(),
        })

        newGroupId = result.data.createGroup.id
      }

      this.setState({
        result: AsyncResult.success(),
        createGroupFields: this.fields.initialState(),
        selectedType: NETWORK_ASSET_GROUP_TYPE,
      })

      if(openGroup) {
        this.onToggle()
        onSuccess(newGroupId)
      } else {
        onCreateAndCreateAnother(newGroupId)
      }
    } catch (e) {
      analytics.logError(e)
      this.setState({ result: AsyncResult.fail(e) })
    }
  }

  performPreSubmitChecks = async () => {
    const { performDeviceConfigPreSubmitChecks } = this.props
    let deviceConfigResult = true

    if (this.isCreatingDeviceSessionConfig()) {
      deviceConfigResult = await performDeviceConfigPreSubmitChecks()
        .catch(() => false)
    }

    const groupResult = await this.fields.performPreSubmitChecks()
      .catch(() => false)

    return deviceConfigResult && groupResult
  }

  getCreateGroupVariables = () => {
    const { selectDeviceConfigFieldValue, sourceAggregateType } = this.props
    const { selectedType } = this.state

    const name = this.selectFieldValue('name')
    const category = this.selectFieldValue('category')
    const membersType = this.mapSelectedTypeToAggregateType(selectedType)

    const variables = {
      name,
      category,
      membersType,
    }

    if (this.isCreatingDeviceSessionConfig()) {
      const secondsInterval = selectDeviceConfigFieldValue('secondsInterval')
      const dateFrom = selectDeviceConfigFieldValue('dateFrom')
      const timeZone = selectDeviceConfigFieldValue('timeZone')
      const dateFromInTimeZone = moment.tz(dateFrom, TIME_FORMAT, timeZone)

      return {
        ...variables,
        deviceConfig: {
          dateFrom: dateFromInTimeZone.format(),
          timeZone,
          secondsInterval: Number(secondsInterval),
          sourceAggregate: sourceAggregateType,
        },
      }
    } else {
      return variables
    }
  }

  getCreateChildGroupVariables = () => {
    const { parentGroup } = this.props
    const { selectedType } = this.state

    const name = this.selectFieldValue('name')
    const category = this.selectFieldValue('category')
    const membersType = this.mapSelectedTypeToAggregateType(selectedType)

    return {
      name,
      category,
      membersType,
      parentGroupId: parentGroup.id,
      inheritParentConfig: [],
    }
  }

  mapSelectedTypeToAggregateType(selectedType) {
    switch (selectedType) {
      case NETWORK_ASSET_GROUP_TYPE:
        return 'VNetworkAsset'
      case DEVICES_GROUP_TYPE:
        return 'VDevice'
      case GROUPS_GROUP_TYPE:
        return 'VGroup'
      default:
        analytics.logError('unknown selected group type', selectedType)
        return ''
    }
  }

  handleDeviceConfigPresetChange = ev => {
    this.props.setSourceAggregateType(ev.target.value)
  }

  isCreatingDeviceSessionConfig = () => {
    return false
    // We are not implementing this at the moment.
    // return Array.isArray(this.props.configurations) &&
    //   this.props.configurations.length > 0
  }

  selectTenantConfiguration = createSelector(
    [get('configurations')], configurations => {
      return configurations.find(c => {
        return c.sourceAggregate === Device.CONFIGURATION_SOURCE_TENANT_TYPE
      })
    })

  static CREATE_GROUP_MUTATION = gql`
    mutation CreateGroup(
      $name: String!,
      $category: CategoryType!,
      $membersType: AggregateType!
      $deviceConfig: DeviceConfigInput,
    ) {
      createGroup (
        name: $name,
        category: $category,
        membersType: $membersType,
        standardDeviceConfig: $deviceConfig
      ) {
        id
      }
    }
  `

  static CREATE_CHILD_MUTATION = gql`
    mutation CreateChild(
      $name: String!,
      $category: CategoryType!,
      $membersType: AggregateType!,
      $parentGroupId: Int!,
      $inheritParentConfig: [InheritanceConfigType]!
    ) {
      createChild (
        name: $name,
        category: $category,
        membersType: $membersType,
        parentGroupId: $parentGroupId,
        inheritParentConfig: $inheritParentConfig
      ) {
        id
      }
    }
  `
}

function stopPropagation(event) {
  event.stopPropagation()
}

const CreateGroupModalWithContainers = compose(
  graphql(CreateGroupModal.CREATE_GROUP_MUTATION, {
    name: 'createGroup',
  }),
  graphql(CreateGroupModal.CREATE_CHILD_MUTATION, {
    name: 'createChild',
  }),
  withTranslation([
    'src/management_path/groups_path/create_group_modal',
    'common/text',
  ]),
)(CreateGroupModal)

export const CreateGroupModalWithDeviceConfig = compose(
  withDeviceSessionConfigurationFieldsProvider,
  withDeviceSessionConfigSourceProvider,
)(CreateGroupModalWithContainers)

export default CreateGroupModalWithContainers
