import React from 'react'
import PropTypes from 'prop-types'

import AlertThresholdSettingsForm
from '@@src/components/alerts/alert_settings_form'
import withConvertedAlertThresholds from
'@@src/components/alerts/with_converted_alert_thresholds'

class ConvertedAlertThresholdSettingsFormContainer extends React.PureComponent {
  static propTypes = {
    submitConvertedAlertThresholds: PropTypes.func.isRequired,
    editAlertsConfiguration: PropTypes.func.isRequired,
    formatEditVariables: PropTypes.func.isRequired,
  }

  render() {
    return (
      <AlertThresholdSettingsForm
        onSubmit={this.handleSubmit}
        {...this.props} />
    )
  }

  handleSubmit = async ({ variables }) => {
    const {
      editAlertsConfiguration,
      submitConvertedAlertThresholds,
      formatEditVariables,
    } = this.props

    return submitConvertedAlertThresholds(
      editAlertsConfiguration,
      formatEditVariables(variables),
    )
  }
}

export default withConvertedAlertThresholds(
  ConvertedAlertThresholdSettingsFormContainer
)
