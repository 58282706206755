import { parseSearchParams } from '../../../../utils'
import GeoBounds from '../../../../utils/graphs/geo_bounds'
import GeoCoordinate from '../../../../utils/graphs/geo_coordinate'

export const COORDINATES_SEPARATOR = ','
export const COORDINATES_PRECISION = 6

/**
 * @param {string} search location search
 * @returns {{center: (GeoCoordinate|undefined), bounds: (GeoBounds|undefined), zoom: (number|undefined)}} map position
 */
export const parseMapPositionFromLocation = (search) => {
  const { c: center, b: bounds } = parseSearchParams(search)
  const [lat, lng, zoom] = (center || '').split(COORDINATES_SEPARATOR)
  const [southwestLat, southwestLng, northeastLat, northeastLng] = (bounds || '').split(COORDINATES_SEPARATOR)

  return {
    bounds: southwestLat && southwestLng && northeastLat && northeastLng
      ? GeoBounds.fromCoordinates(+southwestLat, +southwestLng, +northeastLat, +northeastLng)
      : undefined,
    center: lat && lng
      ? new GeoCoordinate(+lat, +lng)
      : undefined,
    zoom: zoom
      ? +zoom
      : undefined,
  }
}

/**
 * @param {object} position - visible map bounds
 * @param {GeoBounds} position.bounds - visible map bounds
 * @param {GeoCoordinate} position.center - visible map center point
 * @param {number} position.zoom - zoom level
 * @returns {{c: string, b: string}} - location search object
 */
export const parseMapPositionToLocationObject = (position) => {
  const { bounds, center, zoom } = position
  const centerArray = center instanceof GeoCoordinate
    ? [
      center.lat.toFixed(COORDINATES_PRECISION),
      center.lng.toFixed(COORDINATES_PRECISION),
    ].concat(zoom ? [zoom] : [])
    : []
  const boundsArray = bounds instanceof GeoBounds
    ? [
      bounds.southwest.lat.toFixed(COORDINATES_PRECISION), bounds.southwest.lng.toFixed(COORDINATES_PRECISION),
      bounds.northeast.lat.toFixed(COORDINATES_PRECISION), bounds.northeast.lng.toFixed(COORDINATES_PRECISION),
    ]
    : []

  return {
    c: centerArray.join(COORDINATES_SEPARATOR) || undefined,
    b: boundsArray.join(COORDINATES_SEPARATOR) || undefined,
    z: zoom,
  }
}

/**
 * @param {GeoBounds} bounds bounds to normalize
 * @returns {GeoBounds} normalized bounds
 */
export const normalizeGeoBoundsToQuery = bounds => {
  if (!bounds instanceof GeoBounds) {
    throw new Error('Bounds has to be of type GeoBounds')
  }

  const rawBounds = bounds.toObject()

  return GeoBounds.fromJSON({
    northeast: {
      lat: rawBounds.northeast.lat > 90 ? 90 : rawBounds.northeast.lat < -90 ? -90 : rawBounds.northeast.lat,
      lng: rawBounds.northeast.lng > 180 ? 180 : rawBounds.northeast.lng < -180 ? -180 : rawBounds.northeast.lng,
    },
    southwest: {
      lat: rawBounds.southwest.lat > 90 ? 90 : rawBounds.southwest.lat < -90 ? -90 : rawBounds.southwest.lat,
      lng: rawBounds.southwest.lng > 180 ? 180 : rawBounds.southwest.lng < -180 ? -180 : rawBounds.southwest.lng,
    },
  })
}
