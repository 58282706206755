import { Children, cloneElement, PureComponent } from 'react'
import { createSelector } from 'reselect'
import { get } from 'lodash/fp/object'

class Trigger extends PureComponent {
  render() {
    const { triggerRef } = this.props
    const child = this.selectOnlyChild(this.props)
    const refKey = this.selectRefKey(this.props)

    return cloneElement(child, {
      [refKey]: child.props.ref && triggerRef ? (r) => {
        child.props.ref(r)
        triggerRef(r)
      } : (child.props.ref || triggerRef),
      onMouseEnter: this.onMouseEnter,
      onMouseLeave: this.onMouseLeave,
    })
  }

  constructor(props) {
    super(props)

    this.onMouseEnter = this.onMouseEnter.bind(this)
    this.onMouseLeave = this.onMouseLeave.bind(this)

    this.selectOnlyChild = createSelector([get('children')], Children.only)
    this.selectRefKey = createSelector(
      [this.selectOnlyChild],
      (child) => typeof child.type === 'string' ? 'ref' : 'domRef'
    )
  }

  onMouseEnter(...args) {
    const { onMouseEnter } = this.props
    const child = this.selectOnlyChild(this.props)

    onMouseEnter(...args)

    if (child.props.onMouseEnter) {
      child.props.onMouseEnter(...args)
    }
  }

  onMouseLeave(...args) {
    const { onMouseLeave } = this.props
    const child = this.selectOnlyChild(this.props)

    onMouseLeave(...args)

    if (child.props.onMouseLeave) {
      child.props.onMouseLeave(...args)
    }
  }
}

export default Trigger
