import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import styles from './filter_component_item.css'

const FilterComponentItem = props => {
  const { value, isSelected, text } = props
  const textClassnames = classnames({ 'font-weight-bold': isSelected })
  const iconClassnames = classnames(
    styles['dropdown-icon'],
    'fa fa-check',
    'font-weight-normal'
  )

  return (
    <div value={value}>
      <div className={styles['dropdown-content']}>
        <div className={textClassnames}>
          {text}
        </div>
        {isSelected ? <i name="check-icon" className={iconClassnames} /> : null}
      </div>
    </div>
  )
}

FilterComponentItem.propTypes = {
  value: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
}

FilterComponentItem.defaultProps = {
  isSelected: false,
}

export default FilterComponentItem
