import React from 'react'
import { renderToString } from 'react-dom/server'
import PropTypes from 'prop-types'
import { get } from 'lodash/fp/object'
import { Marker } from 'react-leaflet'
import { divIcon } from 'leaflet'
import { createSelector } from 'reselect'
import classnames from 'classnames'

import NetworkAsset from '@@src/api/presenters/network_asset'
import {
  PRIMARY_ICON_COLOUR, STEEL_ICON_COLOUR, mapTypeAndColourToMarkerIcon,
} from '@@src/utils/network_asset'

import styles from './network_asset_marker.css'

export class NetworkAssetMarker extends React.PureComponent {
  static defaultProps = {
    openPopupDelay: 300,
    selected: false,
    isFocused: false,
    iconColour: PRIMARY_ICON_COLOUR,
  }

  static propTypes = {
    networkAsset: PropTypes.instanceOf(NetworkAsset).isRequired,
    openPopupDelay: PropTypes.number.isRequired,
    selected: PropTypes.bool.isRequired,
    isFocused: PropTypes.bool.isRequired,
    eventsStartDate: PropTypes.instanceOf(Date),
    iconColour: PropTypes.oneOf([
      PRIMARY_ICON_COLOUR,
      STEEL_ICON_COLOUR,
    ]).isRequired,
  }

  openMarkerTimeoutID = undefined

  render() {
    const { children } = this.props

    return (
      <Marker
        icon={this.selectIcon(this.props)}
        position={this.selectPosition(this.props)}>
        {children}
      </Marker>
    )
  }

  componentWillUnmount() {
    if (Number.isFinite(this.openMarkerTimeoutID)) {
      global.clearTimeout(this.openMarkerTimeoutID)
    }
  }

  getDeviceIssueIcon(deviceIssue) {
    const isError = deviceIssue.isError()
    const isWarning = deviceIssue.isWarning()

    if (isError || isWarning) {
      return (
        <span className={classnames(styles['device-issue-icon'], {
          'bg-white': isError,
          'bg-dark': isWarning,
          'text-danger': isError,
          'text-warning': isWarning,
        })} />
      )

    }

    return ''
  }

  selectPosition = createSelector(
    [get('networkAsset')],
    ({ location }) => [location.latitude, location.longitude]
  )

  selectIcon = createSelector(
    [
      get('networkAsset'),
      get('selected'),
      get('iconColour'),
      get('isFocused'),
    ],
    (networkAsset, selected, iconColour, isFocused) => {
      const iconUrl = mapTypeAndColourToMarkerIcon(
        iconColour,
        networkAsset.assetType
      )
      const imgWidth = 48
      const imgHeight = 72
      const anchorHeight = 60
      const divIconClassnames = classnames('map-label', styles['div-icon'], {
        [styles['div-icon-selected']]: selected,
        [styles['div-icon-focused']]: isFocused,
      })
      const divIconContentClassnames = classnames(
        'd-flex justify-content-center', {
          [styles.focused]: isFocused,
        }
      )
      const mostSevereDeviceIssue = networkAsset.mostSevereDeviceIssue
      const deviceIssueSeverityIcon = mostSevereDeviceIssue ?
        this.getDeviceIssueIcon(mostSevereDeviceIssue) : null

      return divIcon({
        iconUrl,
        iconRetinaUrl: iconUrl,
        shadowUrl: undefined,
        iconSize: [imgWidth, imgHeight],
        iconAnchor: [imgWidth / 2, anchorHeight],
        popupAnchor: [0, -0.8 * imgHeight],
        tooltipAnchor: [0, -0.8 * imgHeight],
        className: divIconClassnames,
        html: `
            <div
              class="${divIconContentClassnames}"
              style="width: ${imgWidth}px;">
              <img
                width="${imgWidth}"
                height="${imgHeight}"
                src="${iconUrl}"
                alt="" />
              ${renderToString(deviceIssueSeverityIcon)}
            </div>
          `
        ,
      })
    }
  )
}

export default NetworkAssetMarker
