import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Col, FormGroup, Input, Label, Row } from 'reactstrap'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import { push } from 'connected-react-router'
import { mergeSearchParams } from '../../utils'
import { connect } from 'react-redux'
import routes from '../../routes'
import defineQueryParams from '../../components/define_query_params'
import styles from './filters_panel.css'
import { debounce } from 'lodash/function'

export const FiltersQueryParams = {
  serialNumber: { alias: 'sn' },
  networkAssetReadableId: { alias: 'nari' },
  networkAssetReadableName: { alias: 'narn' },
  groups: { alias: 'g' },
  hasActiveAlerts: { alias: 'haa' },
  hasDataGaps: { alias: 'hdg' },
  hasBatteryIssues: { alias: 'hbi' },
  signalStrengthMin: { alias: 'ssf' },
  signalStrengthMax: { alias: 'sst' },
  batteryVoltageMin: { alias: 'bvf' },
  batteryVoltageMax: { alias: 'bvt' },
}

function FiltersPanel({ t, location, ...props }) {
  const { dispatchUpdateFiltersQuery } = props
  const initFilters = Object
    .keys(FiltersQueryParams)
    .reduce((object, key) => ({ ...object, [key]: props[key] }), {})
  const [filters, setFilters] = useState(initFilters || {})

  const updateFilter = (key, value) => {
    dispatchUpdateFiltersQuery(location,
      typeof value !== 'undefined'
        ? { [FiltersQueryParams[key].alias]: `${value.substring(0, 200)}`.trim() }
        : { [FiltersQueryParams[key].alias]: undefined },
    )
  }

  const performUpdateFilter = ({ target: { name, checked, value } }) => {
    updateFilter(name, +checked || value || undefined)
    setFilters({ ...filters, [name]: +checked || value || undefined })
  }

  const performUpdateValue = ({ target: { name, value } }) => {
    setFilters({ ...filters, [name]: value.substring(0, 200) })

    debounce(() => {
      updateFilter(name, value || undefined)
    }, 500)()
  }

  const handleResetButton = () => {
    setFilters({})
    dispatchUpdateFiltersQuery(
      location,
      Object
        .values(FiltersQueryParams)
        .reduce((obj, param) => ({ ...obj, [param.alias]: undefined }), {}),
    )
  }

  return (
    <div className={`${styles['devices-filters']} bg-white p-4`}>
      <Row id="header">
        <Col xs={4}>
          <p className="h4 mb-4 mt-2">
            {t('filters.headings.filters')}
          </p>
        </Col>
        <Col className={'text-right'}>
          <Button outline color={'secondary'} onClick={handleResetButton}>
            {t('filters.text.reset')}
          </Button>
        </Col>
      </Row>
      <FormGroup>
        <Label for="filterSerialNumber">
          {t('filters.labels.serial_number')}
        </Label>
        <Input
          type="text"
          id="filterSerialNumber"
          name={'serialNumber'}
          value={filters.serialNumber || ''}
          onChange={performUpdateValue}
        />
      </FormGroup>
      <FormGroup>
        <div className="d-flex w-100">
          <Label for="filterNetworkAsset">
            {t('filters.labels.network_asset')}
          </Label>
        </div>
        <Input
          type="text"
          id="filterNetworkAssetId"
          name={'networkAssetReadableId'}
          value={filters.networkAssetReadableId || ''}
          onChange={performUpdateValue}
        />
      </FormGroup>
      <FormGroup>
        <div className="d-flex w-100">
          <Label for="filterNetworkAsset">
            {t('filters.labels.network_asset_name')}
          </Label>
        </div>
        <Input
          type="text"
          id="filterNetworkAsset"
          name={'networkAssetReadableName'}
          value={filters.networkAssetReadableName || ''}
          onChange={performUpdateValue}
        />
      </FormGroup>
      <FormGroup>
        <div className="d-flex w-100">
          <Label for="filterParentGroups">
            {t('filters.labels.parent_groups')}
          </Label>
        </div>
        <Input
          type="text"
          id="filterParentGroups"
          value={filters.groups || ''}
          name={'groups'}
          onChange={performUpdateValue}
        />
      </FormGroup>
      <FormGroup>
        <Label for="filterActiveAlerts">
          {t('filters.labels.active_alerts')}
        </Label>
        <Input
          id="filterActiveAlerts"
          type="select"
          name={'hasActiveAlerts'}
          value={filters.hasActiveAlerts || ''}
          onChange={performUpdateFilter}
        >
          <option value="">
            {t('filters.options.any')}
          </option>
          <option value="1">
            {t('filters.options.yes')}
          </option>
          <option value="0">
            {t('filters.options.no')}
          </option>
        </Input>
      </FormGroup>
      <FormGroup>
        <Label htmlFor="filterDataGaps">
          {t('filters.labels.data_gaps')}
        </Label>
        <Input
          id="filterDataGaps"
          type="select"
          name={'hasDataGaps'}
          value={filters.hasDataGaps || ''}
          onChange={performUpdateFilter}
        >
          <option value="">
            {t('filters.options.any')}
          </option>
          <option value="1">
            {t('filters.options.yes')}
          </option>
          <option value="0">
            {t('filters.options.no')}
          </option>
        </Input>
      </FormGroup>
      <FormGroup>
        <Label>
          {t('filters.labels.signal_strength')}
        </Label>
        <div className="d-flex w-100">
          <div className={'flex-grow-1'}>
            <Input
              type="number"
              name={'signalStrengthMin'}
              value={filters.signalStrengthMin || ''}
              onChange={performUpdateValue}
            />
          </div>
          <div className={'p-2'}>
            {t('filters.text.range_to')}
          </div>
          <div className={'flex-grow-1'}>
            <Input
              type="number"
              name={'signalStrengthMax'}
              value={filters.signalStrengthMax || ''}
              onChange={performUpdateValue}
            />
          </div>
        </div>
      </FormGroup>
      <FormGroup>
        <Label>
          {t('filters.labels.battery_voltage')}
        </Label>
        <div className="d-flex w-100">
          <div className={'flex-grow-1'}>
            <Input
              type="number"
              name={'batteryVoltageMin'}
              value={filters.batteryVoltageMin || ''}
              onChange={performUpdateValue}
            />
          </div>
          <div className={'p-2'}>
            {t('filters.text.range_to')}
          </div>
          <div className={'flex-grow-1'}>
            <Input
              type="number"
              name={'batteryVoltageMax'}
              value={filters.batteryVoltageMax || ''}
              onChange={performUpdateValue}
            />
          </div>
        </div>
      </FormGroup>
      <FormGroup>
        <Label htmlFor="filterDataGaps">
          {t('filters.labels.battery_health')}
        </Label>
        <Input
          id="filterDataGaps"
          type="select"
          name={'hasBatteryIssues'}
          value={filters.hasBatteryIssues || ''}
          onChange={performUpdateFilter}>
          <option value="">
            {t('filters.options.any')}
          </option>
          <option value="0">
            {t('filters.battery.good')}
          </option>
          <option value="1">
            {t('filters.battery.replace')}
          </option>
        </Input>
      </FormGroup>
    </div>
  )
}

FiltersPanel.propTypes = {
  t: PropTypes.func.isRequired,
  dispatchUpdateFiltersQuery: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  ...Object
    .keys(FiltersQueryParams)
    .reduce((object, key) => ({ ...object, [key]: PropTypes.string }), {}),
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchUpdateFiltersQuery(location, filters) {
      dispatch(push({
        search: mergeSearchParams(location.search, filters),
        pathname: routes.managementDevicesTablePath(),
      }))
    },
  }
}

export default compose(
  connect(null, mapDispatchToProps),
  withRouter,
  defineQueryParams(FiltersQueryParams),
  withTranslation([
    'src/management_path/devices_table_path/index_page',
  ]),
)(FiltersPanel)
