export class Pagination {
  constructor(props = {}) {
    this.perPage = +(props.perPage || 10)
    this.pageIndex = +(props.pageIndex || 0)
    this.pageNumber = +(props.pageNumber || 1)
    this.totalPages = +(props.totalPages || 1)
    this.totalResults = +(props.totalResults || 0)
  }

  /**
   * @param {object} model response pagination model
   * @param {number} model.perPage limit results per page
   * @param {number} model.pageIndex page counted from 0
   * @param {number} model.pageNumber page counted from 1
   * @param {number} model.totalPages total pages count
   * @param {number} model.totalResults total results count
   * @returns {Pagination} pagination model
   */
  static fromResponse(model) {
    return new Pagination(model)
  }

  /**
   * @returns {boolean} boolean if pagination should be displayed
   */
  shouldPaginate() {
    return this.totalPages > 1
  }

  /**
   * @returns {number} current page
   */
  getCurrentPage() {
    return this.pageNumber
  }

  /**
   * @returns {number} first page number
   */
  getFirstPage() {
    return 1
  }

  /**
   * @param {number|null} [fromPage] from page
   * @returns {number|null} previous page number or null if there is no previous page
   */
  getPrevPage(fromPage = null) {
    const currentPage = fromPage || this.pageNumber

    return currentPage > 1
      ? currentPage - 1
      : null
  }

  /**
   * @param {number|null} [fromPage] from page
   * @returns {number|null} next page number or null if there is no next page
   */
  getNextPage(fromPage = null) {
    const currentPage = fromPage || this.pageNumber

    return currentPage < this.totalPages
      ? currentPage + 1
      : null
  }

  /**
   * @returns {number} last page
   */
  getLastPage() {
    return this.totalPages || 1
  }

  /**
   * @param {number} pageNumber page number to check
   * @returns {boolean} return if pagination contains given page number
   */
  hasPage(pageNumber) {
    return pageNumber >= 1 && pageNumber <= this.totalPages
  }
}
