import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { FormGroup, Label, Input } from 'reactstrap'
import { noop } from 'lodash/fp/util'

export const INSTALLED = 'installed'
export const NOT_INSTALLED = 'notInstalled'
export const DEVICE_STATE_FILTERS = [
  INSTALLED,
  NOT_INSTALLED,
]

export function defaultDeviceStateFilters(filters = []) {
  return {
    [INSTALLED]: filters.includes(INSTALLED),
    [NOT_INSTALLED]: filters.includes(NOT_INSTALLED),
  }
}

function DeviceStateFilters(props) {
  const { t, handleChange, filters } = props

  return (
    <FormGroup tag="fieldset">
      <legend className="h6">{t('headers.network_assets')}</legend>
      <FormGroup check={true}>
        <Label check={true}>
          <Input
            checked={filters[INSTALLED]}
            onChange={handleChange}
            type="checkbox"
            name={INSTALLED} />{' '}
          {t('common/text:options.installed')}
        </Label>
      </FormGroup>
      <FormGroup check={true}>
        <Label check={true}>
          <Input
            checked={filters[NOT_INSTALLED]}
            onChange={handleChange}
            type="checkbox"
            name={NOT_INSTALLED} />{' '}
          {t('common/text:options.not_installed')}
        </Label>
      </FormGroup>
    </FormGroup>
  )
}

DeviceStateFilters.propTypes = {
  t: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
  handleChange: PropTypes.func,
}

DeviceStateFilters.defaultProps = {
  filters: {},
  handleChange: noop,
}

export default withTranslation([
  'src/components/modals/device_state_filters',
  'common/text',
])(memo(DeviceStateFilters))
