import { flow } from 'lodash/fp/util'
import { reduce } from 'lodash/fp/collection'
import { isEmpty } from 'lodash/fp/lang'
import { toPairs } from 'lodash/fp/object'
import { fromPairs } from 'lodash/fp/array'

export default class FormFields {
  constructor(component, sectionName, validations = {}) {
    this.component = component
    this.sectionName = sectionName
    this.validations = validations

    this.handlers = {}
  }

  get state() {
    return this.component.state[this.sectionName]
  }

  selectFieldState(state) {
    return state[this.sectionName]
  }

  initialState(values = {}) {
    return {
      errors: {},
      values,
      timeout: null,
      touched: {},
    }
  }

  resetState(callback) {
    this.setState(this.initialState(), callback)
  }

  // a default value is necessary to signal to React that the value is intended
  // to be controlled
  selectValue(state, fieldName, defaultValue = '') {
    const value = this.selectFieldState(state).values[fieldName]
    return value !== undefined ? value : defaultValue
  }

  selectError(state, fieldName) {
    return this.selectFieldState(state).errors[fieldName]
  }

  selectIsPristine(state, fieldName) {
    return !this.selectFieldState(state).touched[fieldName]
  }

  // a default value is necessary to signal to React that the value is intended
  // to be controlled
  valueFor(fieldName, defaultValue = '') {
    return this.selectValue(this.component.state, fieldName, defaultValue)
  }

  errorFor(fieldName) {
    return this.state.errors[fieldName]
  }

  onChangeHandlerFor(fieldName) {
    const handler = this.handlers[fieldName] || (event => (
      this.updateFieldValue(fieldName, event.target.value)
    ))

    this.handlers[fieldName] = handler

    return handler
  }

  attributesFor(state, fieldName) {
    return {
      value: this.selectValue(state, fieldName),
      error: this.selectError(state, fieldName),
      onChange: this.onChangeHandlerFor(fieldName),
    }
  }

  hasAnyValidationErrors() {
    return !isEmpty(this.state.errors)
  }

  isPristine() {
    return isEmpty(this.state.touched)
  }

  setState(newState, ...args) {
    this.component.setState({
      [this.sectionName]: {
        ...this.state,
        ...newState,
      },
    }, ...args)
  }

  updateState(state, values) {
    const touched = fromPairs(Object.keys(values).map(key => [key, true]))
    const fieldState = this.selectFieldState(state)

    return {
      ...fieldState,
      values: {
        ...fieldState.values,
        ...values,
      },
      touched: {
        ...fieldState.touched,
        ...touched,
      },
    }
  }

  async updateFieldValue(fieldName, fieldValue) {
    return new Promise(resolve => {
      this.setState(this.updateState(this.component.state, {
        [fieldName]: fieldValue,
      }), () => {
        this.debounceValidations()
        resolve()
      })
    })
  }

  async batchUpdateFieldValues(values) {
    return new Promise(resolve => {
      this.setState(this.updateState(this.component.state, values), () => {
        this.debounceValidations()
        resolve()
      })
    })
  }

  validateCurrentState(skipIfUntouched = false) {
    const state = this.state

    return flow(
      toPairs,
      reduce((errors, [fieldName, validation]) => {
        if (!validation || (skipIfUntouched && !state.touched[fieldName])) {
          return errors
        }

        const error = validation(state.values[fieldName], state.values)
        return !error ? errors : {
          ...errors,
          [fieldName]: error,
        }
      }, {}),
    )(this.validations)
  }

  validateAndUpdate(skipIfUntouched = false, callback = () => { }) {
    const errors = this.validateCurrentState(skipIfUntouched)
    this.setState({ errors }, callback.bind(null, errors))
  }

  performPreSubmitChecks() {
    return new Promise((resolve, reject) => {
      const { timeout } = this.state

      if (timeout) {
        clearTimeout(timeout)
      }

      this.validateAndUpdate(false, errors => {
        if (!isEmpty(errors)) {
          reject(new Error(`Failed pre-submit checks, "${this.sectionName}" has unresolved validation errors`)) // eslint-disable-line max-len
        } else {
          resolve(this.state.values)
        }
      })
    })
  }

  debounceValidations() {
    const propDebounce = this.component.props.debounce
    const debounce = Number.isFinite(propDebounce) ? propDebounce : 500
    const { timeout } = this.state

    if (timeout) {
      clearTimeout(timeout)
    }

    this.setState({
      timeout: setTimeout(this.validateAndUpdate.bind(this, true), debounce),
    })
  }
}
