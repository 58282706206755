import DeviceIssue from './device_issue'
import Device from './device'

export default class InstalledDeviceOnAssetListModel {
  constructor(data) {
    Object.assign(this, data)

    switch(true) {
      case this.hasActiveError: {
        this.mostSevereIssue = new DeviceIssue({ severity: DeviceIssue.ERROR_SEVERITY })
        break
      }
      case this.hasActiveWarning: {
        this.mostSevereIssue = new DeviceIssue({ severity: DeviceIssue.WARNING_SEVERITY })
        break
      }
      case this.hasActiveInformation: {
        this.mostSevereIssue = new DeviceIssue({ severity: DeviceIssue.INFORMATION_SEVERITY })
        break
      }
      default: this.mostSevereIssue = null
    }

    Object.freeze(this)
  }

  get isDecommissioned() {
    return this.status === Device.DECOMMISSIONED
  }

  get isInstalled() {
    return this.status === Device.INSTALLED
  }

  get isCommissioned() {
    return this.status === Device.COMMISSIONED
  }

  get isReturned() {
    return this.status === Device.RETURNED
  }
}
