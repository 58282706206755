import PropTypes from 'prop-types'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import {
  Button,
  Card,
  CardSubtitle,
  CardText,
  CardTitle,
  Col,
} from 'reactstrap'

import AsyncResultSwitch from '@@src/components/async_result_switch'
import SummaryPanelEmpty from '@@src/components/summary/summary_panel_empty'
import AsyncResult from '@@src/utils/async_result'

function SummaryPanel({
  t,
  title,
  subtitle,
  result,
  renderSuccessResult,
  footerText,
  showAllLink,
  showAllBtnText,
  renderNotFoundResult,
  renderPendingResult,
  renderFailResult,
}) {
  const renderNotFoundAlertsResult = () => {
    if (typeof renderNotFoundResult === 'function') {
      return renderNotFoundResult()
    } else {
      return <SummaryPanelEmpty />
    }
  }

  const renderFailAlertsResult = (data) => {
    if (typeof renderFailResult === 'function') {
      return renderFailResult(data)
    } else {
      return (
        <CardText>
          <span className="text-danger">{t('common/errors:unknown')}</span>
        </CardText>
      )
    }
  }

  return (
    <Col sm="12" lg="6" className="mb-4 d-flex">
      <Card body={true}>
        <CardTitle>
          <h2 className="h4 mb-0">{title}</h2>
        </CardTitle>
        <CardSubtitle className="font-weight-bold text-muted mb-4">
          {subtitle}
        </CardSubtitle>
        <AsyncResultSwitch
          result={result}
          renderFailResult={renderFailAlertsResult}
          renderNotFoundResult={renderNotFoundAlertsResult}
          renderPendingResult={renderPendingResult}
          renderSuccessResult={renderSuccessResult}
        />
        {footerText ? (
          <CardText className="text-muted">
            <small>{footerText}</small>
          </CardText>
        ) : null}
        <Button
          className="mr-auto pl-0 pb-0 mt-auto"
          color="link"
          tag={Link}
          to={showAllLink}
        >
          {showAllBtnText}
        </Button>
      </Card>
    </Col>
  )
}

SummaryPanel.propTypes = {
  result: PropTypes.instanceOf(AsyncResult).isRequired,
  renderSuccessResult: PropTypes.func.isRequired,
  renderPendingResult: PropTypes.func,
  t: PropTypes.func.isRequired,
  renderFailResult: PropTypes.func,
  renderNotFoundResult: PropTypes.func,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  footerText: PropTypes.string,
  showAllLink: PropTypes.string,
  showAllBtnText: PropTypes.string,
}

export default withTranslation(['common/errors'])(SummaryPanel)
