import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from '@apollo/client/react/hoc'
import { compose } from 'redux'
import { area, line } from 'd3-shape'
import { scaleLinear, scaleTime } from 'd3-scale'
import { AsyncResult, createSelectGraphQLResult } from '../../utils'
import GraphContext from '../graphs/graph_context'
import Graph from '../graphs/graph'
import transformProps from '../transform_props'
import { parseGraphQLResult } from '../../api/presenters'
import PressureSubsetData from '../../api/presenters/pressure_subset_data'
import { PRESSURE_SUBSET_PREVIEW_QUERY } from './pressure_preview_graphql'
import optimizeSubsetDataQuery from '@@src/analysis_path/pressure_analysis_path/constants'
import LoadingIcon from '../loading_icon'

import styles from './pressure_preview.css'

/**
 * @param {Array<{data: Array<{min:number,max:number}>}>} dataChunks pressure dataChunks
 * @returns {{min:number,max:number}} min and max of the pressure
 */
const getMaxAndMinForDataChunks = (dataChunks) => {
  return dataChunks.reduce((chunkResults, chunk) => {
    return chunk.data.reduce((pointResults, point) => ({
      min: Math.min(pointResults.min, point.min),
      max: Math.max(pointResults.max, point.max),
    }), chunkResults)
  }, { min: Infinity, max: -Infinity })
}

function AreaPlot(props) {
  return <path fill={props.color} stroke="none" {...props} />
}

function LinePlot(props) {
  return <path fill="none" stroke={props.color} {...props} />
}

PressurePreview.propTypes = {
  start: PropTypes.instanceOf(Date).isRequired,
  end: PropTypes.instanceOf(Date).isRequired,
  color: PropTypes.string.isRequired,
  pressureDataResult: PropTypes.instanceOf(AsyncResult).isRequired,
  networkAsset: PropTypes.exact({
    id: PropTypes.number.isRequired,
    channel: PropTypes.string.isRequired,
  }),
  onClick: PropTypes.func,
}

function PressurePreview({ start, end, color, pressureDataResult, onClick }) {
  const dataChunks = pressureDataResult.data.length
    ? pressureDataResult.data[0].dataSegments || []
    : []
  const { min: minPressure, max: maxPressure } = getMaxAndMinForDataChunks(dataChunks)
  const previewScaleX = scaleTime().domain([new Date(start), new Date(end)])
  const previewScaleY = scaleLinear().domain([minPressure, maxPressure])
  const areaGenerator = (graphContext) => area()
    .x(d => graphContext.xScale(d.time))
    .y0(d => graphContext.yScale(d.min))
    .y1(d => graphContext.yScale(d.max))
  const lineGenerator = (graphContext) => line()
    .x(d => graphContext.xScale(d.time))
    .y(d => graphContext.yScale(d.mean))

  if (pressureDataResult.isPending()) {
    return (
      <div className={styles['subset-preview-loading']}>
        <LoadingIcon />
      </div>
    )
  }

  return (
    <div onClick={() => onClick ? onClick() : null}>
      <Graph
        xScale={previewScaleX}
        yScale={previewScaleY}
        className={styles['subset-preview']}
        topPadding={5}
        leftPadding={5}
        rightPadding={5}
        bottomPadding={5}
      >
        <GraphContext.Consumer>
          {context => dataChunks.map((segment, index) => (
            <g key={index}>
              <AreaPlot
                opacity={0.1}
                d={areaGenerator(context)(segment.data)}
                color={color} />

              <LinePlot
                d={lineGenerator(context)(segment.data)}
                color={color} />
            </g>
          ))}
        </GraphContext.Consumer>
      </Graph>
    </div>
  )
}

export default compose(
  transformProps(() => () => ({
    mapPagedPressureSubsetData: createSelectGraphQLResult('pagedPressureSubsetData', {
      mapResult: data => parseGraphQLResult(data).data.map(part => PressureSubsetData.from(part)),
      nullObject: [],
    }),
  })),
  graphql(PRESSURE_SUBSET_PREVIEW_QUERY, {
    options: (props) => {
      const { start, end, resolution } = optimizeSubsetDataQuery({ start: props.start, end: props.end })

      return ({
        variables: {
          resolution,
          start: new Date(start).toISOString(),
          end: new Date(end).toISOString(),
          deviceIds: [],
          networkAssetChannels: [{
            networkAssetId: props.networkAsset.id,
            channel: props.networkAsset.channel,
          }],
        },
        fetchPolicy: 'no-cache',
      })
    },
    props: ({ ownProps, data }) => {
      const { mapPagedPressureSubsetData } = ownProps
      const pressureDataResult = mapPagedPressureSubsetData(data)

      // necessary when using no-cache policy
      if (pressureDataResult.wasNotFound()) {
        data.refetch()
      }

      return {
        refetchData: data.refetch,
        pressureDataResult,
      }
    },
  }),
)(PressurePreview)

