import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { Button, ButtonGroup, UncontrolledTooltip } from 'reactstrap'
import VisibilityButtonGroup from '../../components/graphs/visibility_button_group/visibility_button_group'
import PressureAnalysisViewHistogramDropdown
from '../../components/dropdowns/histogram_dropdown/pressure_analysis_view_histogram_dropdown'
import { HISTOGRAM_TYPES } from './constants'

import * as DragCover from '@@src/components/singletons/drag_cover'

import styles from './analysis_graph_toolbar.css'

class AnalysisGraphToolbar extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    displayRawData: PropTypes.bool,
    displayEvents: PropTypes.bool,
    displayAlerts: PropTypes.bool,
    displayAnomalies: PropTypes.bool,
    onChangeOptions: PropTypes.func,
    setHistogramOption: PropTypes.func,
    histogramOption: PropTypes.oneOf(HISTOGRAM_TYPES),
  }

  render() {
    const {
      t, className, setDragModeToPan, setDragModeToZoom, setDragModeToSelect,
      dragMode, children, displayRawData, displayEvents, displayAnomalies, displayAlerts,
      onChangeOptions, setHistogramOption, histogramOption,
    } = this.props

    return (
      <div className={classnames(styles.container, className)}>
        <span className={styles['action-group-label']}>
          {t('text.toolbar_group_tools')}
        </span>

        <ButtonGroup>
          <Button
            id="select-mode-button"
            size="sm"
            color="light"
            className={styles['non-focusable']}
            active={dragMode === DragCover.SELECT_DRAG_MODE}
            onClick={setDragModeToSelect}
            aria-label={t('buttons.select_mode')}>
            <i className="far fa-i-cursor" />

            <UncontrolledTooltip placement="top" target="select-mode-button">
              {t('buttons.select_mode')}
            </UncontrolledTooltip>
          </Button>

          <Button
            id="zoom-mode-button"
            size="sm"
            color="light"
            className={styles['non-focusable']}
            active={dragMode === DragCover.ZOOM_DRAG_MODE}
            onClick={setDragModeToZoom}
            aria-label={t('buttons.zoom_mode')}>
            <i className="far fa-search" />

            <UncontrolledTooltip placement="top" target="zoom-mode-button">
              {t('buttons.zoom_mode')}
            </UncontrolledTooltip>
          </Button>

          <Button
            id="pan-mode-button"
            size="sm"
            color="light"
            className={styles['non-focusable']}
            active={dragMode === DragCover.PAN_DRAG_MODE}
            onClick={setDragModeToPan}
            aria-label={t('buttons.pan_mode')}>
            <i className="far fa-arrows-alt" />

            <UncontrolledTooltip placement="top" target="pan-mode-button">
              {t('buttons.pan_mode')}
            </UncontrolledTooltip>
          </Button>
        </ButtonGroup>

        {
          displayRawData !== undefined &&
          displayAnomalies !== undefined &&
          displayAlerts !== undefined &&
          displayEvents !== undefined &&
          <>
            <span className={styles['action-group-label']}>
              {t('text.toolbar_group_visibility')}
            </span>
            <VisibilityButtonGroup
              t={t}
              displayAnomalies={displayAnomalies}
              displayRawData={displayRawData}
              displayEvents={displayEvents}
              displayAlerts={displayAlerts}
              onChangeOptions={onChangeOptions}
            />
          </>

        }
        {
          HISTOGRAM_TYPES.includes(histogramOption) &&
        <>
          <span className={styles['action-group-label']}>
            {t('text.toolbar_group_histogram')}
          </span>
            <PressureAnalysisViewHistogramDropdown
              setHistogramOption={setHistogramOption}
              histogramOption={histogramOption}
            />
        </>
        }
        {children}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    dragMode: state.dragCover.dragMode,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setDragModeToZoom() {
      dispatch(DragCover.setDragMode(DragCover.ZOOM_DRAG_MODE))
    },
    setDragModeToPan() {
      dispatch(DragCover.setDragMode(DragCover.PAN_DRAG_MODE))
    },
    setDragModeToSelect() {
      dispatch(DragCover.setDragMode(DragCover.SELECT_DRAG_MODE))
    },
  }
}

export default compose(
  withTranslation([
    'src/analysis_path/pressure_analysis_path/analysis_graph_toolbar',
  ]),
  connect(mapStateToProps, mapDispatchToProps)
)(AnalysisGraphToolbar)
