import gql from 'graphql-tag'
import React from 'react'
import { compose } from 'redux'
import { graphql } from '@apollo/client/react/hoc'
import { withTranslation } from 'react-i18next'

import { parseGraphQLResult } from '@@src/api/presenters'
import transformProps from '@@src/components/transform_props'
import AsyncResultSwitch from '@@src/components/async_result_switch'
import AppSettingsConsumer from '@@src/components/app_settings_consumer'
import { createSelectGraphQLResult } from '../../../utils'
import requiresLogin from '../../../components/security/requires_login'
import ConvertedAlertThresholdSettingsFormContainer from
'@@src/components/alerts/converted_alert_threshold_settings_form_container'

class EditAlertsConfigurationSection extends React.PureComponent {
  render() {
    const { networkAssetAlertThresholds, t } = this.props

    return (
      <React.Fragment>
        <h2 className="h2 mb-0">
          {t('headings.alerts')}
        </h2>
        <hr className="mb-5" />

        <AsyncResultSwitch
          result={networkAssetAlertThresholds}
          renderSuccessResult={this.renderAlertSettingsForm}
        />
      </React.Fragment>
    )
  }

  componentDidMount() {
    this.props.refetchData()
  }

  renderAlertSettingsForm = ({ data }) => {
    const {
      id: assetId,
      assetType,
      alertThresholds: assetAlertThresholds,
      ancestry,
    } = data
    const {
      refetchData,
      editAlertsConfiguration,
    } = this.props

    return (
      <AppSettingsConsumer>
        {
          (units, tenantAlertThresholds) => (
            <ConvertedAlertThresholdSettingsFormContainer
              formatEditVariables={this.formatEditVariables}
              units={units}
              tenantAlertThresholds={tenantAlertThresholds}
              alertThresholds={assetAlertThresholds}
              ancestors={ancestry}
              onReset={refetchData}
              onSubmitSuccess={refetchData}
              editAlertsConfiguration={editAlertsConfiguration}
              assetId={assetId}
              assetType={assetType}
            />
          )
        }
      </AppSettingsConsumer>
    )
  }

  formatEditVariables = variables => {
    const { networkAssetAlertThresholds } = this.props
    const { id, assetType } = networkAssetAlertThresholds.data
    const { inheritFrom, parentId } = variables

    return (inheritFrom ? {
      parentId,
      inheritFrom,
      id,
      assetType,
    } : {
      ...variables,
      parentId: null,
      inheritFrom: null,
      id,
      assetType,
    })
  }

  static EDIT_ALERTS_CONFIGURATION_MUTATION = gql`
    mutation EditAlertsConfigurationSection(
      $id: Int!
      $parentId: Int
      $inheritFrom: AlertThresholdInheritFromTypes
      $assetType: InflowNetAssetType!
      $batteryDepletingEnabled: Boolean
      $dailyCpisHigh: Float
      $dailyCpisHighEnabled: Boolean
      $dailyCpisLow: Float
      $dailyCpisLowEnabled: Boolean
      $deviceNoComms: Int
      $deviceNoCommsEnabled: Boolean
      $eventCpis: Float
      $eventCpisEnabled: Boolean
      $eventSeverity: Float
      $eventSeverityEnabled: Boolean
      $eventSourceLocalisationEnabled: Boolean
      $pressureHigh: Float
      $pressureHighEnabled: Boolean
      $pressureLow: Float
      $pressureLowEnabled: Boolean
    ) {
      editNetworkAsset (
        id: $id
        assetType: $assetType
        alertThresholds: {
          parentId: $parentId
          inheritFrom: $inheritFrom
          batteryDepletingEnabled: $batteryDepletingEnabled
          dailyCpisHigh: $dailyCpisHigh
          dailyCpisHighEnabled: $dailyCpisHighEnabled
          dailyCpisLow: $dailyCpisLow
          dailyCpisLowEnabled: $dailyCpisLowEnabled
          deviceNoComms: $deviceNoComms
          deviceNoCommsEnabled: $deviceNoCommsEnabled
          eventCpis: $eventCpis
          eventCpisEnabled: $eventCpisEnabled
          eventSeverity: $eventSeverity
          eventSeverityEnabled: $eventSeverityEnabled
          eventSourceLocalisationEnabled: $eventSourceLocalisationEnabled
          pressureHigh: $pressureHigh
          pressureHighEnabled: $pressureHighEnabled
          pressureLow: $pressureLow
          pressureLowEnabled: $pressureLowEnabled
        }
      )
    }
  `

  static GET_ASSET_ALERTS_CONFIGURATION = gql`
    query NetworkAssetAlertThresholds($id: Int!) {
      networkAsset(id: $id) {
        id
        assetType
        ancestry {
          id
          name
          parentId
        }
        alertThresholds {
          parentId
          inheritedFrom
          batteryDepletingEnabled
          dailyCpisHigh
          dailyCpisHighEnabled
          dailyCpisLow
          dailyCpisLowEnabled
          deviceNoComms
          deviceNoCommsEnabled
          eventCpis
          eventCpisEnabled
          eventSeverity
          eventSeverityEnabled
          eventSourceLocalisationEnabled
          pressureHigh
          pressureHighEnabled
          pressureLow
          pressureLowEnabled
        }
      }
    }
  `
}

export default compose(
  requiresLogin,
  withTranslation([
    'common/forms',
  ]),
  transformProps(() => () => ({
    selectAssetThresholdsResult: createSelectGraphQLResult(
      'networkAsset',
      {
        mapResult: parseGraphQLResult,
      }
    ),
  })),
  graphql(EditAlertsConfigurationSection.GET_ASSET_ALERTS_CONFIGURATION, {
    options: ({ assetId }) => {
      return {
        fetchPolicy: 'network-only',
        variables: {
          id: assetId,
        },
      }
    },
    props: ({ data, ownProps }) => {
      const { selectAssetThresholdsResult } = ownProps
      const { refetch } = data
      return {
        refetchData: refetch,
        networkAssetAlertThresholds: selectAssetThresholdsResult(data),
      }
    },
  }),
  graphql(EditAlertsConfigurationSection.EDIT_ALERTS_CONFIGURATION_MUTATION, {
    name: 'editAlertsConfiguration',
  }),
)(EditAlertsConfigurationSection)
