import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { createSelector } from 'reselect'
import { withTranslation } from 'react-i18next'
import { union, difference } from 'lodash/fp/array'
import { FormGroup, Label, Input } from 'reactstrap'
import { noop } from 'lodash/fp/util'

import DeviceIssue from '@@src/api/presenters/device_issue'

export const ADVANCED_ERROR_FILTERS = [
  DeviceIssue.DATA_HAS_NOT_BEEN_RECEIVED,
  DeviceIssue.FROZEN_TRANSDUCER,
  DeviceIssue.BATTERY_DEPLETED,
]

export const ADVANCED_WARNING_FILTERS = [
  DeviceIssue.MISSING_DATA,
  DeviceIssue.MISSING_DATA_AND_CATCHING_UP,
  DeviceIssue.MISSING_DATA_AND_FALLING_BEHIND,
  DeviceIssue.POOR_SIGNAL_STRENGTH,
  DeviceIssue.OUT_OF_OPERATING_TEMPERATURE,
  DeviceIssue.NOT_CONNECTED_TO_PRESSURE_SOURCE,
  DeviceIssue.HIGH_PRESSURE,
  DeviceIssue.BATTERY_DEPLETING,
  DeviceIssue.DETACHED_FROM_ASSET,
]

export const ADVANCED_INFORMATION_FILTERS = [
  DeviceIssue.FW_UPDATE_PENDING,
  DeviceIssue.CONFIGURATION_PENDING,
  DeviceIssue.NOT_INSTALLED_ON_ASSET,
]

export function filterDeviceIssuesFilters(filters = []) {
  return filters.filter(item => (
    ADVANCED_INFORMATION_FILTERS.includes(item) ||
      ADVANCED_WARNING_FILTERS.includes(item) ||
      ADVANCED_ERROR_FILTERS.includes(item)
  ))
}

class DeviceIssueFilters extends React.PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    onChangeSelection: PropTypes.func.isRequired,
    deviceIssuesFilter: PropTypes.array.isRequired,
  }

  static defaultProps = {
    onChangeSelection: noop,
    deviceIssuesFilter: [],
  }

  render() {
    const { t } = this.props

    return (
      <FormGroup tag="fieldset">
        <legend className="h6">{t('headers.health')}</legend>
        <FormGroup>
          <legend className="font-weight-normal h6">
            {t('headers.information')}
          </legend>
          <div className="pl-3">
            {this.renderFilterCategory(ADVANCED_INFORMATION_FILTERS)}
          </div>
        </FormGroup>

        <FormGroup>
          <legend className="font-weight-normal h6">
            {t('headers.warnings')}
          </legend>
          <div className="pl-3">
            {this.renderFilterCategory(ADVANCED_WARNING_FILTERS)}
          </div>
        </FormGroup>

        <FormGroup>
          <legend className="font-weight-normal h6">
            {t('headers.errors')}
          </legend>
          <div className="pl-3">
            {this.renderFilterCategory(ADVANCED_ERROR_FILTERS)}
          </div>
        </FormGroup>

      </FormGroup>
    )
  }

  renderFilterCategory = filtersInCategory => {
    const { t } = this.props
    const deviceIssuesFilter = this.selectDeviceIssuesFilter(this.props)

    return filtersInCategory.map(filter => (
      <FormGroup key={filter} check={true}>
        <Label check={true}>
          <Input
            checked={deviceIssuesFilter.includes(filter)}
            onChange={this.onChangeItem}
            type="checkbox"
            name={filter}/>
          &nbsp;
          {t(`options.${filter}`)}
        </Label>
      </FormGroup>
    ))
  }

  onChangeItem = ev => {
    const deviceIssuesFilter = this.selectDeviceIssuesFilter(this.props)
    const { name: value, checked } = ev.target
    let newDeviceIssuesFilter = checked ?
      union(deviceIssuesFilter, [value]) :
      difference(deviceIssuesFilter, [value])

    newDeviceIssuesFilter = ensureFiltersConsistency(
      newDeviceIssuesFilter,
      ADVANCED_INFORMATION_FILTERS,
      DeviceIssue.INFORMATION_SEVERITY_FILTER
    )

    newDeviceIssuesFilter = ensureFiltersConsistency(
      newDeviceIssuesFilter,
      ADVANCED_WARNING_FILTERS,
      DeviceIssue.WARNING_SEVERITY_FILTER
    )

    newDeviceIssuesFilter = ensureFiltersConsistency(
      newDeviceIssuesFilter,
      ADVANCED_ERROR_FILTERS,
      DeviceIssue.ERROR_SEVERITY_FILTER
    )

    this.props.onChangeSelection(newDeviceIssuesFilter)
  }

  selectDeviceIssuesFilter = createSelector(
    [({ deviceIssuesFilter }) => deviceIssuesFilter],
    deviceIssuesFilter => {
      return getConsistentFiltersInCategory(
        deviceIssuesFilter,
        ADVANCED_INFORMATION_FILTERS,
        DeviceIssue.INFORMATION_SEVERITY_FILTER
      ).concat(getConsistentFiltersInCategory(
        deviceIssuesFilter,
        ADVANCED_WARNING_FILTERS,
        DeviceIssue.WARNING_SEVERITY_FILTER
      ), getConsistentFiltersInCategory(
        deviceIssuesFilter,
        ADVANCED_ERROR_FILTERS,
        DeviceIssue.ERROR_SEVERITY_FILTER
      ))
    }
  )
}

function getConsistentFiltersInCategory(filters, filtersInCategory, allValue) {
  const includesAllFromCategory = filters.includes(allValue)

  return filtersInCategory.filter(item => (
    includesAllFromCategory || filters.includes(item)
  ))
}

function ensureFiltersConsistency(filters, filtersInCategory, allValue) {
  const includesAllFromCategory = !filtersInCategory.some(filter => {
    return !filters.includes(filter)
  })

  return includesAllFromCategory ?
    filters :
    difference(filters, [allValue])
}

export default withTranslation([
  'src/components/modals/device_issue_filters',
  'common/text',
])(memo(DeviceIssueFilters))
