import GeoCoordinate from '../../../utils/graphs/geo_coordinate'
import { DevicesListItemIssues } from './devices_list_item_issue'

import commissionedDeviceIcon from '../../../static_assets/svgs/device_icon_pin_commissioned.svg'
import decommissionedDeviceIcon from '../../../static_assets/svgs/device_icon_pin_decommissioned.svg'
import errorDeviceIcon from '../../../static_assets/svgs/device_icon_pin_error.svg'
import warningDeviceIcon from '../../../static_assets/svgs/device_icon_pin_warning.svg'

export class DeviceForMapItem {
  constructor(props) {
    Object.assign(this, props)
    this.mostSevereIssue = new DevicesListItemIssues(props.mostSeverityIssue)
  }

  getCoordinates() {
    return GeoCoordinate.fromValue(this.lat, this.lng)
  }

  getTableIconURL() {
    switch(true) {
      case !!this.mostSevereIssue.severity: {
        switch(this.mostSevereIssue.severity) {
          case 'INFORMATION': {
            return commissionedDeviceIcon
          }
          case 'WARNING': {
            return warningDeviceIcon
          }
          default: return errorDeviceIcon
        }
      }
      case this.isCommissioned || this.isInstalled: {
        return commissionedDeviceIcon
      }
      default: return decommissionedDeviceIcon
    }
  }
}
