import { Amplify, Auth } from 'aws-amplify'
import config from '../../../../config'
import { AuthorizedUser } from '../../../models/authorized-user'
import { InternalIdentityProvider } from './abstract/internal-identity-provider'

export class AwsCognitoIdentityProvider extends InternalIdentityProvider {
  constructor() {
    super()
    this.challengeMeta = null
  }

  static initialize(cfg) {
    const { region, userPoolId, appClientId } = cfg

    Amplify.configure({
      Auth: {
        region: region,
        userPoolId: userPoolId,
        userPoolWebClientId: appClientId,
        mandatorySignIn: true,
      },
    })

    return new AwsCognitoIdentityProvider()
  }

  async signIn(username, password) {
    const results = await Auth.signIn(username, password)

    return this._respondToCognitoChallenge(results)
  }

  async completeSignInWithMFA(mfaCode) {
    const { challengeMeta } = this
    const results = await Auth.confirmSignIn(challengeMeta, mfaCode, 'SMS_MFA')

    return this._respondToCognitoChallenge(results)
  }

  async completeNewPassword(newPassword) {
    const { challengeMeta } = this
    const results = await Auth.completeNewPassword(challengeMeta, newPassword)

    return this._respondToCognitoChallenge(results)
  }

  async resetPasswordRequest(email) {
    await Auth.forgotPassword(email)
  }

  async resetPassword(email, code, newPassword) {
    await Auth.forgotPasswordSubmit(email, code, newPassword)
  }

  async getCurrentUserToken() {
    const user = await this._getCognitoUser()

    return user && user.signInUserSession && user.signInUserSession.idToken
      ? user.signInUserSession.idToken.jwtToken || null
      : null
  }

  async getAcceptedEulaVersion() {
    const user = await this._getCognitoUser()
    const attributes = user ? await Auth.userAttributes(user) : null

    return this._findAttribute(attributes, 'custom:eula_version')
  }

  async setAcceptedEulaVersion(version) {
    const cognitoUser = await this._getCognitoUser()
    await Auth.updateUserAttributes(cognitoUser, {
      'custom:eula_version': version,
    })
  }

  async signOut() {
    await Auth.signOut()
  }

  async getCurrentUser() {
    const token = await this.getCurrentUserToken()

    if (!token) {
      return null
    }

    return super._getUserDetailsFromBff(token)
  }

  async _getCognitoUser() {
    try {
      const user = await Auth.currentAuthenticatedUser()

      if (!user || !user.signInUserSession) {
        return null
      }

      return user
    } catch (e) {
      return null
    }
  }

  _findAttribute(attributes, name) {
    const attribute = attributes.find((a) => a.Name === name)

    return attribute ? attribute.Value : undefined
  }

  _respondToCognitoChallenge(response) {
    this.challengeMeta = response

    if (response.signInUserSession) {
      // set cognito user to null when no challenge needed
      this.challengeMeta = null

      return { success: true, reason: null, meta: null }
    }

    return {
      success: false,
      reason: response.challengeName,
      meta: {
        type: this.challengeMeta.challengeParam.CODE_DELIVERY_DELIVERY_MEDIUM,
        destination:
        this.challengeMeta.challengeParam.CODE_DELIVERY_DESTINATION,
      },
    }
  }
}
