import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Popover, PopoverBody } from 'reactstrap'

import LegendItem from '@@src/components/graphs/legend_item'
import BorderlessButton from '@@src/components/buttons/borderless_button'
import { LegendItemColorContext } from
'@@src/components/colors/legend_item_color_provider'

import styles from './legend_item_color_picker.css'
import { compose } from 'redux'

class LegendItemColorPicker extends React.PureComponent {
  static propTypes = {
    legendItem: PropTypes.instanceOf(LegendItem).isRequired,
    placement: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    className: PropTypes.string,
  }

  static defaultProps = {
    placement: 'left',
  }

  state = {
    colorPickerIsOpen: false,
  }

  render() {
    const { className, legendItem, placement, getLegendItemColor } = this.props
    const { colorPickerIsOpen } = this.state

    const buttonId = `color-picker-${legendItem.id.replace((/\./g), '_')}`

    if (!legendItem.isDataSource()) {
      return (
        <span className={'bg-light p-1'} />
      )
    }

    return (
      <React.Fragment>
        <button
          name="legend-item-color-picker-button"
          onClick={this.handleColorPickerToggle}
          id={buttonId}
          className={classnames(
            'border-0 d-flex justify-content-center text-white p-1',
            styles['color-picker-button'],
            className
          )}
          style={{
            background: getLegendItemColor(legendItem),
          }}
          type="button">
          <span className="fas fa-caret-down" />
        </button>
        <Popover
          trigger="legacy"
          boundariesElement={'#container'}
          placement={placement}
          isOpen={colorPickerIsOpen}
          target={buttonId}
          toggle={this.handleColorPickerToggle}>
          <PopoverBody>
            {this.renderColorGrid()}
          </PopoverBody>
        </Popover>
      </React.Fragment>
    )
  }

  handleColorPickerToggle = () => {
    this.setState({ colorPickerIsOpen: !this.state.colorPickerIsOpen })
  }

  renderColorGrid = () => {
    const { legendItem, t } = this.props
    const {
      assignableColors,
      getSetLegendItemColor,
      getClearUserLegendItemColor,
      getLegendItemColor,
    } = this.props
    const color = getLegendItemColor(legendItem)

    return (
      <div
        name="legend-item-assignable-color-grid"
        className="d-flex flex-column align-items-center pt-1">
        <div className={styles['color-grid']}>
          {
            assignableColors.map(assignableColor => (
              <button
                name="legend-item-assignable-color-btn"
                key={assignableColor}
                onClick={getSetLegendItemColor(legendItem, assignableColor)}
                type="button"
                style={{ background: assignableColor }}
                className={styles['color-grid-item']}>
                {
                  color && color === assignableColor ?
                    <span className="text-white far fa-check" />
                    : null
                }
              </button>
            ))
          }
        </div>
        <BorderlessButton
          name="legend-item-automatic-color-btn"
          color="light"
          className="mt-2 btn-sm text-dark"
          onClick={getClearUserLegendItemColor(legendItem)}>
          {
            !color ?
              <span className="far fa-check mr-2" />
              : null
          }
          {t('src/components/colors/legend_item_color_picker:button.automatic')}
        </BorderlessButton>
      </div>
    )
  }
}

function withLegendItemColor(Component) {
  return function WithLegendItemColor(props) {
    return (
      <LegendItemColorContext.Consumer>
        {({ assignableColors,
          getSetLegendItemColor,
          getClearUserLegendItemColor,
          getLegendItemColor }) => (
          <Component {...{
            ...props,
            assignableColors,
            getSetLegendItemColor,
            getClearUserLegendItemColor,
            getLegendItemColor,
          }} />
        )}
      </LegendItemColorContext.Consumer>
    )
  }
}

export default compose(
  withLegendItemColor,
)(LegendItemColorPicker)
