import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'
import routes from '@@src/routes'
import { Link } from 'react-router-dom'

import MessageBox from '@@src/components/modals/message_box'

class ReleaseNotesModal extends React.PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    mdReleaseNotes: PropTypes.string,
  }

  static defaultProps = {
    isOpen: false,
  }

  render() {
    const { t, mdReleaseNotes, handleClose, isOpen } = this.props

    return (
      <MessageBox
        headerText={t('title.updates', {
          version: process.env.APP_VERSION,
        })}
        toggle={handleClose}
        isOpen={isOpen}
        onAccept={handleClose}
        footerContent={
          <Link to={routes.previousReleaseNotesPath}>
            {t('button.previous_releases')}
          </Link>
        }
        acceptText={t('button.close')}>
        { isOpen && mdReleaseNotes ?
          <ReactMarkdown>{mdReleaseNotes}</ReactMarkdown> :
          null
        }
      </MessageBox>
    )
  }
}

export default withTranslation([
  'src/components/modals/release_notes_modal',
])(ReleaseNotesModal)
