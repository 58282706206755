import gql from 'graphql-tag'
import React from 'react'
import PropTypes from 'prop-types'
import { noop } from 'lodash/fp/util'
import { compose } from 'redux'
import { graphql } from '@apollo/client/react/hoc'
import { withTranslation } from 'react-i18next'

import * as analytics from '@@src/analytics'
import MessageBox from '@@src/components/modals/message_box'
import AsyncResult from '@@src/utils/async_result'
import Installation from '@@src/api/presenters/installation'

class DeleteInstallationPrompt extends React.PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    installation: PropTypes.instanceOf(Installation),
    handleSuccess: PropTypes.func.isRequired,
  }

  static defaultProps = {
    handleSuccess: noop,
  }

  state = {
    result: AsyncResult.notFound(),
  }

  render() {
    const { t, isOpen, installation } = this.props
    const { result } = this.state

    return (
      <MessageBox
        toggle={this.modalToggle}
        result={result}
        onAccept={this.handleAccept}
        acceptText={t('common/text:buttons.yes')}
        cancelText={t('common/text:buttons.cancel')}
        headerText={t('headings.delete_installation', {})}
        isOpen={isOpen}
      >
        {installation
          ? t('text.delete_confirmation', {
              installTime: `${t('common/text:text.long_readable_full_time', {
                time: installation.start,
              })} (${t(
                `common/text:text.asset_channel_${installation.channelMap.pressure_1}`
              )})`, // eslint-disable-line max-len
            })
          : null}

        <br />

        <small>
          <b>
            <i className="text-danger">Warning:</i>
          </b>
        </small>

        <br />

        <small className="text-muted">
          {'1) '}
          {t('text.delete_hint')}
        </small>

        <br />

        <small className="text-muted">
          {'2) '}
          {t('text.delete_hint2')}
        </small>
      </MessageBox>
    )
  }

  handleAccept = async () => {
    const { installation } = this.props

    this.setState({ result: AsyncResult.pending() })

    try {
      await this.props.voidInstallation({
        variables: {
          deviceId: installation.deviceId,
          channelMap: {
            pressure_1: installation.channelMap.pressure_1,
          },
          whenInstalled: installation.start.toISOString(),
          networkAssetId:
            installation.networkAssetId ||
            (installation.networkAsset ? installation.networkAsset.id : null),
        },
      })

      this.setState({ result: AsyncResult.success() })
      this.handleSuccess()
      this.props.toggle()
    } catch (err) {
      analytics.logError(err)
      this.setState({ result: AsyncResult.fail(err) })
    }
  }

  modalToggle = () => {
    const { toggle } = this.props

    this.setState({ result: AsyncResult.notFound() })

    toggle()
  }

  handleSuccess = () => {
    this.props.handleSuccess()
  }

  static VOID_INSTALLATION_MUTATION = gql`
    mutation VoidInstallation(
      $deviceId: Int!
      $channelMap: ChannelMappingInput!
      $whenInstalled: DateString!
      $networkAssetId: Int!
    ) {
      voidInstallation(
        deviceId: $deviceId
        channelMap: $channelMap
        whenInstalled: $whenInstalled
        networkAssetId: $networkAssetId
      )
    }
  `
}

export default compose(
  withTranslation([
    'src/components/modals/delete_installation_prompt',
    'common/text',
  ]),
  graphql(DeleteInstallationPrompt.VOID_INSTALLATION_MUTATION, {
    name: 'voidInstallation',
  })
)(DeleteInstallationPrompt)
