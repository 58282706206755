import routes from '@@src/routes'
import classnames from 'classnames'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Button } from 'reactstrap'
import { compose } from 'redux'

import AppSettingsConsumer from '@@src/components/app_settings_consumer'
import NetworkAssetIcon from '@@src/components/icons/network_asset_icon'
import userPermissions from '@@src/components/permissions/user_permissions'
import SummaryPanel from '@@src/components/summary/summary_panel'
import SummaryPanelEmpty from '@@src/components/summary/summary_panel_empty'
import SummaryPanelListItem from '@@src/components/summary/summary_panel_list_item'
import {
  convertUnitFromDB,
  PRESSURE_TYPE,
} from '@@src/utils/app_unit_conversion'
import { ListPlaceholder } from '../../../_v2/components/placeholders/list_placeholder'
import { useGetHighSeverityPressureEvents } from '../../../hooks/summaries/useGetHighSeverityPressureEvents'
import {
  getAlertTimestamp,
  getAnalysisPathRoute,
  showAllLink,
} from './high_severity_events_helpers'

import PressurePreview from '../../pressure_preview/pressure_preview'
import styles from './high_severity_events_summary.css'

function HighSeverityPressureEventsSummary({ t, pressureUnits }) {
  const { eventsResult, getSeverityColor } = useGetHighSeverityPressureEvents()

  const renderSuccessEventsResult = ({ data: items }) => {
    if (items.length === 0) {
      return <SummaryPanelEmpty />
    }

    return (
      <React.Fragment>
        <ol className="list-unstyled mb-3">
          {items.map((event) => {
            const {
              eventSeverity,
              start,
              end,
              assetName,
              assetFriendlyName,
              assetType,
              assetId,
              severityLevel,
            } = event

            return (
              <SummaryPanelListItem
                key={`${assetId}:${start}`}
                itemActions={
                  <React.Fragment>
                    <span className="d-flex flex-column align-items-end px-2 py-0">
                      <Link
                        name="events-summary-link-asset-details"
                        className={styles['asset-id-text']}
                        to={routes.networkAssetsDetailPath(assetId)}
                      >
                        {assetName}
                      </Link>
                      <span className={styles['asset-friendly-text']}>
                        {assetFriendlyName || t('text.no_friendly_name')}
                      </span>
                    </span>
                    <span className="mr-3 d-flex align-self-start py-2">
                      <NetworkAssetIcon
                        className={classnames(
                          'align-self-start',
                          styles['asset-icon']
                        )}
                        networkAsset={{ assetType }}
                      />
                    </span>
                    <Button
                      name="alert-summary-analysis-plot-btn"
                      aria-label={t('text.analysis_plot')}
                      size="sm"
                      className={classnames(
                        'mr-2 align-self-start',
                        styles['event-summary-button']
                      )}
                      tag={Link}
                      color="primary"
                      to={getAnalysisPathRoute({ start, end, assetId })}
                    >
                      <span className="far fa-chart-line" />
                    </Button>
                  </React.Fragment>
                }
                icon={
                  <Link
                    name="events-summary-link"
                    to={getAnalysisPathRoute({ start, end, assetId })}
                  >
                    <PressurePreview
                      start={event.start}
                      end={event.end}
                      networkAsset={{
                        channel: event.logicalChannel,
                        id: event.assetId,
                      }}
                      color={getSeverityColor(severityLevel)}
                    />
                  </Link>
                }
              >
                <span>
                  {severityLevel === 'high' ? (
                    <React.Fragment>
                      <div className={styles['alert-summary-icon-alert']} />
                      &nbsp;
                    </React.Fragment>
                  ) : null}
                  <strong>
                    {convertUnitFromDB(
                      eventSeverity,
                      pressureUnits,
                      PRESSURE_TYPE
                    )}
                  </strong>
                  &nbsp;
                  {t(`common/text:units.${pressureUnits}`)}
                </span>
                <small className="text-muted">{getAlertTimestamp(event)}</small>
              </SummaryPanelListItem>
            )
          })}
        </ol>
      </React.Fragment>
    )
  }

  return (
    <SummaryPanel
      title={t('headings.severe_events')}
      subtitle={t('headings.subtitle')}
      result={eventsResult}
      renderSuccessResult={renderSuccessEventsResult}
      renderPendingResult={() => (
        <ListPlaceholder count={5} listItemHeight={20} />
      )}
      showAllLink={showAllLink(eventsResult)}
      showAllBtnText={t('buttons.show_events')}
    />
  )
}

function HighSeverityPressureEventsSummaryContainer(props) {
  return (
    <AppSettingsConsumer>
      {(units) => (
        <HighSeverityPressureEventsSummary
          pressureUnits={units.pressure}
          {...props}
        />
      )}
    </AppSettingsConsumer>
  )
}

export default compose(
  userPermissions,
  withTranslation([
    'src/components/summary/high_severity_events_summary',
    'common/errors',
    'common/text',
  ])
)(HighSeverityPressureEventsSummaryContainer)
