import React, { useContext } from 'react'
import { UserContext } from '../../_v2/contexts/user/user.context'

export default function requiresPermissions(permissions = []) {
  return function createRequiresPermission(Component) {
    return (
      function RequiresPermission(props) {
        const {authorizedUser} = useContext(UserContext)

        for (const permission of permissions) {
          if (!authorizedUser.can(permission)) {
            return null
          }
        }

        return <Component {...props}/>
      }
    )
  }
}
