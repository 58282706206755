import { isCognitoError } from '@@src/utils'
import { ApolloError } from '@apollo/client'

export default function mapErrorToTranslation(error) {
  switch (true) {
    case (error instanceof Array):
      return { key: 'common/errors:aggregate' }

    case (error.i18nKey && error.i18nOptions):
      return { key: error.i18nKey, options: error.i18nOptions }

    case (isCognitoError(error)): {
      return mapCognitoErrorToTranslation(error)
    }

    case (isGraphQLError(error)): {
      const translation = mapGraphQLErrorToTranslation(error)

      if (translation) {
        return translation
      }
    }

    case (isNetworkError(error)): {
      const translation = mapNetworkErrorToTranslation(error)

      if (translation) {
        return translation
      }
    }

    case (isGraphQLFailedToFetchError(error)): {
      return { key: 'common/errors:graphql.failed_to_fetch' }
    }

    case(isTenantError(error)): {
      return { key: 'tenant_not_found' }
    }

    default:
      return { key: 'common/errors:unknown' }
  }
}

const GRAPHQL_ERROR_MSG_REGEX = /^GraphQL error:/
const NETWORK_ERROR_MSG_REGEX = /^Network error:/

function isTenantError(error) {
  return (
    /Could not find any cognito services associated with/
  ).test(error.message)
}

function isGraphQLError(error) {
  return error instanceof ApolloError &&
    !!error.message.match(GRAPHQL_ERROR_MSG_REGEX)
}

function isNetworkError(error) {
  return error instanceof ApolloError &&
    !!error.message.match(NETWORK_ERROR_MSG_REGEX)
}

function isGraphQLFailedToFetchError(error) {
  const errorText = error instanceof Error ? error.message : String(error)

  return (/Failed to fetch/).test(errorText)
}

function mapCognitoErrorToTranslation(error) {
  const subcode = mapCognitoErrorToSub(error)

  if (subcode) {
    return {
      key: `common/errors:cognito.${error.code}.${subcode}`,
      options: error,
    }
  } else {
    return {
      key: 'common/errors:cognito.unhandled',
      options: error,
    }
  }
}

function mapCognitoErrorToSub(error) {
  switch (error.code) {
    case 'UserNotFoundException': {
      switch (error.message) {
        case 'Username/client id combination not found.':
          return 'username_and_client_combination_not_found'

        case 'User does not exist.':
          return 'user_does_not_exist'

        default:
          return 'default'
      }
    }

    case 'NotAuthorizedException': {
      switch (error.message) {
        case 'Password attempts exceeded':
          return 'password_attempts_exceeded'

        case 'Incorrect username or password.':
          return 'incorrect_username_or_password'

        default:
          return 'default'
      }
    }

    case 'InvalidPasswordException': {
      switch (error.message) {
        case 'Password does not conform to policy: Password not long enough':
          return 'password_not_long_enough'

        default:
          return 'default'
      }
    }

    case 'ExpiredCodeException':
    case 'CodeMismatchException':
    case 'LimitExceededException':
    case 'InvalidParameterException':
    case 'PasswordResetRequiredException':
      return 'default'

    case 'UnexpectedLambdaException':
      switch (error.message) {
        case 'PreTokenGeneration invocation failed due to error Socket timeout while invoking Lambda function.': // eslint-disable-line max-len
          return 'lambda_function_timeout'

        default:
          return 'default'
      }

    default:
      return undefined
  }
}

function mapGraphQLErrorToTranslation(error) {
  const match = error.message.match(/GraphQL error: ([^"]+ : )?"?([^"]+)"?$/)

  // Check for unauthorised
  if (error && error.graphQLErrors && error.graphQLErrors.length > 0) {
    if (error.graphQLErrors.some(e => {return e.message === 'unauthorised'})) {
      return { 'key': 'common/errors:http.403' }
    }
  }

  if (match) {
    switch (true) {
      case ((/A group name must be longer than \d+ characters./).test(match[2])): { // eslint-disable-line max-len
        const m2 = match[2].match(/A group name must be longer than (\d+) characters./) // eslint-disable-line max-len

        return {
          key: 'common/errors:graphql.group_name_minimum_length',
          options: {
            count: Number(m2[1]),
          },
        }
      }

      case ((/A group name must be shorter than \d+ characters./).test(match[2])): { // eslint-disable-line max-len
        const m2 = match[2].match(/A group name must be shorter than (\d+) characters./) // eslint-disable-line max-len

        return {
          key: 'common/errors:graphql.group_name_maximum_length',
          options: {
            count: Number(m2[1]),
          },
        }
      }

      case (
        match[1] === 'Error while voiding a network asset : ' &&
        match[2] === 'Network Asset is monitored - please uninstall devices first' // eslint-disable-line max-len
      ):
        return { key: 'common/errors:graphql.cannot_void_monitored_asset' }

      case ((/Failed to fetch/).test(match[2])):
        return { key: 'common/errors:graphql.failed_to_fetch' }
    }

    switch (match[2]) {
      case 'A device is already installed on the point channel of this network asset': // eslint-disable-line max-len
      case 'A device is already installed on the inlet channel of this network asset': // eslint-disable-line max-len
      case 'A device is already installed on the outlet channel of this network asset': // eslint-disable-line max-len
        return {
          key: 'common/errors:graphql.network_asset_channel_already_used',
        }

      case 'Device is already installed - must be removed first':
        return { key: 'common/errors:graphql.device_already_used' }

      case 'Device is already engaged and online - must be removed first':
        return { key: 'common/errors:graphql.device_already_communicating' }

      case 'Device has already been delivered - must be online first':
        return { key: 'common/errors:graphql.device_must_be_commissioned' }

      case 'An account with the given email already exists.':
        return { key: 'common/errors:graphql.email_in_use' }

      case 'User cannot delete themselves':
        return { key: 'common/errors:graphql.cannot_delete_yourself' }

      case 'User cannot downgrade themselves':
        return { key: 'common/errors:graphql.cannot_downgrade_yourself' }

      case 'Invalid phone number format.':
      case 'phoneNumber must be supplied':
        return { key: 'common/errors:graphql.invalid_phone_number' }

      case 'Invalid email address format.':
      case '1 validation error detected: Value at \'username\' failed to satisfy constraint: Member must satisfy regular expression pattern: [\\p{L}\\p{M}\\p{S}\\p{N}\\p{P}]+': // eslint-disable-line max-len
        return { key: 'common/errors:graphql.invalid_email_address' }

      case 'unable to edit asset type with installations':
        return { key: 'common/errors:graphql.cannot_change_asset_type_for_asset_with_installations' } // eslint-disable-line max-len

      default:
        return {
          key: 'common/errors:graphql.raw_message',
          options: {
            message: match[2],
          },
        }
    }
  } else {
    return null
  }
}

function mapNetworkErrorToTranslation(error) {
  if (error && error.networkError) {
    if (error.networkError.statusCode === 403) {
      return { 'key': 'common/errors:http.403' }
    }
  }
  return null
}
