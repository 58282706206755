import { AbstractIdentityProvider } from './abstract-identity-provider';

export class OauthLibraryBasedIdentityProvider extends AbstractIdentityProvider {
  /**
   * Setups the authorization library
   *
   * @abstract
   * @returns {Promise<void>} A promise that resolves when the library is initiated.
   * @throws {Error} This method must be implemented by a subclass.
   */
  async setup() {
    this._throwUnimplementedMethodError('setup');
  }

  async getCurrentUser() {
    return super.getCurrentUser()
  }

  async getCurrentUserToken() {
    return super.getCurrentUserToken()
  }

  async signOut() {
    return super.signOut()
  }

  async getAcceptedEulaVersion() {
    return super.getAcceptedEulaVersion()
  }

  async setAcceptedEulaVersion(version) {
    return super.setAcceptedEulaVersion(version)
  }
}
