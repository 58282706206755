import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Marker, useLeaflet } from 'react-leaflet'
import GeoCoordinate from '../../../../../../utils/graphs/geo_coordinate'
import NetworkAssetMapMarkerComponent from '../map-marker/network_asset_map_marker.component'
import NetworkAssetMarkerClusterComponent from './network_asset_map_cluster.component'
import { abbreviateNumber, renderClusterIcon } from '../../../../../components/map_marker/utils'

const getStatsOfClusterChildren = (supercluster, cluster) => {
  const children = supercluster.getChildren(cluster.id)
  const networkAssets = []
  let [errors, warnings, information, shouldReturnAssets] = [0, 0, 0, true]

  children.forEach(kid => {
    const networkAsset = kid.properties.networkAsset
    const isCluster = kid.properties.cluster || false

    if (!isCluster) {
      networkAssets.push(networkAsset)
      errors += +(networkAsset.activeErrorCount || 0)
      warnings += +(networkAsset.activeWarningCount || 0)
      information += +(networkAsset.activeInformationCount || 0)
    } else {
      const childrenCount = getStatsOfClusterChildren(supercluster, kid)

      shouldReturnAssets = false
      errors += +(childrenCount.errors || 0)
      warnings += +(childrenCount.warnings || 0)
      information += +(childrenCount.information || 0)
    }
  })

  return { errors, warnings, information, networkAssets: shouldReturnAssets ? networkAssets : null }
}

const NetworkAssetMapSuperClusterComponent = ({ cluster, supercluster, onMarkerClick }) => {
  const [lng, lat] = cluster.geometry.coordinates
  const { map } = useLeaflet()
  const { errors, warnings, information, networkAssets } = getStatsOfClusterChildren(supercluster, cluster)

  const countText = abbreviateNumber(cluster.properties.point_count)
  const icon = renderClusterIcon({ errors, warnings, information, countText })
  const onClusterClick = () => map.flyTo(
    GeoCoordinate.fromValue(lat, lng).toLatLng(),
    Math.min(supercluster.getClusterExpansionZoom(cluster.id), 20),
    { animate: true, duration: 0.2 },
  )

  return !networkAssets
    ? (<Marker position={[lat, lng]} onClick={onClusterClick} icon={icon} />)
    : useMemo(() => (<NetworkAssetMarkerClusterComponent showCoverageOnHover={false} removeOutsideVisibleBounds={true}>
      {networkAssets.map(networkAsset => (
        <NetworkAssetMapMarkerComponent
          key={`na.marker.${networkAsset.assetId}`}
          onClick={onMarkerClick}
          networkAsset={networkAsset} />
      ))}
    </NetworkAssetMarkerClusterComponent>), [])
}

NetworkAssetMapSuperClusterComponent.propTypes = {
  cluster: PropTypes.object.isRequired,
  supercluster: PropTypes.object.isRequired,
}

export default NetworkAssetMapSuperClusterComponent
