import React, { PureComponent } from 'react'
import { Button } from 'reactstrap'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import { createSelector } from 'reselect'
import { get } from 'lodash/fp/object'
import { min, max } from 'lodash/fp/math'
import { withTranslation } from 'react-i18next'
import classnames from 'classnames'

import GraphItem from '@@src/components/graphs/graph_item'
import { Popup, PopupAnchor } from '@@src/components/popup'
import { globalSequence } from '@@src/utils'
import GraphContext, { GraphConfig }
from '@@src/components/graphs/graph_context'
import BorderlessButton from '@@src/components/buttons/borderless_button'
import AreaOverlay from '@@src/components/graphs/drag_layer/area_overlay'
import AsyncResult from '@@src/utils/async_result'
import { formatRawDataDuration } from '@@src/utils'
import CsvExportConfigurationModal from
'@@src/components/modals/csv_export_configuration_modal/csv_export_configuration_modal'

import styles from './raw_data_requests_layer.css'

export const SUBMITTED_STATUS = 'submitted'
export const IN_PROGRESS_STATUS = 'in_progress'
export const DATA_AVAILABLE_STATUS = 'data_available'
export const CANCELLED_STATUS = 'cancelled'
export const ERROR_STATUS = 'error'

const REQUEST_MARKER_HEIGHT = 19
const DISPLAY_TIME_FORMAT = 'MMM D, YYYY h:mm A'

function RequestMarkerPopup({ children, x, y }) {
  const offset = Math.round(REQUEST_MARKER_HEIGHT / -2)

  // I’m not even entirely sure myself why this works, all I can recall is that
  // `foreignObject`s have notoriously bad behaviour on browsers. Keeping the
  // anchor as a well-behaved SVG element seems to do the trick (still not sure
  // why `x` and `y` needs to be duplicated but it had the best behaviour)
  return (
    <PopupAnchor elementType="g" x={x + offset} y={y + offset}>
      <foreignObject
        x={x + offset}
        y={y + offset}
        width={REQUEST_MARKER_HEIGHT}
        height={REQUEST_MARKER_HEIGHT}>
        {children}
      </foreignObject>
    </PopupAnchor>
  )
}

class RawDataRequestsLayer extends PureComponent {
  static propTypes = {
    displayRawData: PropTypes.bool.isRequired,
    graphConfig: PropTypes.instanceOf(GraphConfig).isRequired,
    name: PropTypes.string.isRequired,
    listRawDataRequestsResult: PropTypes.instanceOf(AsyncResult).isRequired,
    t: PropTypes.func.isRequired,
    startTime: PropTypes.instanceOf(Date).isRequired,
    endTime: PropTypes.instanceOf(Date).isRequired,
    networkAssetGraphItems:
      PropTypes.arrayOf(PropTypes.instanceOf(GraphItem)).isRequired,
    timezone: PropTypes.string.isRequired,
  }

  static defaultProps = {
    name: 'raw-data-requests-layer',
    listRawDataRequestsResult: AsyncResult.success({
      rawDataRequests: [],
      networkAssetsMinutesLeft: [],
    }),
    networkAssetGraphItems: [],
  }

  id = globalSequence.next()

  state = {
    openMarkerPopoverId: false,
    isCsvExportModalOpen: false,
    exportDataObj: undefined,
  }

  render() {
    const { displayRawData, networkAssetGraphItems, graphConfig, name, t,
      timezone } = this.props
    const { openMarkerPopoverId, isCsvExportModalOpen,
      exportDataObj } = this.state
    const rawDataRequestMarkers = this.selectRawDataRequestMarkers(this.props)
    const clipPathId = `raw-data-requests-layer-clip-path-${this.id}`

    if(!displayRawData) {
      return null
    }

    return (
      <React.Fragment>
        {
          isCsvExportModalOpen ?
            <CsvExportConfigurationModal
              isOpen={isCsvExportModalOpen}
              toggle={this.onCsvExportModalToggle}
              endTime={exportDataObj.endTime}
              dataType={exportDataObj.dataType}
              startTime={exportDataObj.startTime}
              timeZone={timezone}
              legendItems={[exportDataObj.source]}
            />
            : null
        }

        <g name={name}>
          <defs>
            <clipPath id={clipPathId}>
              <rect
                x={graphConfig.leftPadding}
                y={graphConfig.topPadding}
                width={graphConfig.plotAreaWidth}
                height={graphConfig.plotAreaHeight} />
            </clipPath>
          </defs>

          <g
            name="raw-data-requests-area-layer"
            clipPath={`url(#${clipPathId})`}>
            {openMarkerPopoverId !== false ? this.renderRequestArea() : null}
          </g>

          <g name="raw-data-requests-markers-layer">
            {
              networkAssetGraphItems.length && rawDataRequestMarkers.map((request, i) => { // eslint-disable-line max-len
                const iconClassnames = classnames(
                  styles['request-popover-icon'],
                  {
                    [styles['request-popover-icon-success']]:
                      request.status === DATA_AVAILABLE_STATUS,
                    [styles['request-popover-icon-failed']]:
                      request.status === ERROR_STATUS ||
                      request.status === CANCELLED_STATUS,
                    [styles['request-popover-icon-pending']]:
                      request.status === SUBMITTED_STATUS ||
                      request.status === IN_PROGRESS_STATUS,
                  }
                )

                return (
                  <RequestMarkerPopup x={request.x} y={request.y} key={i}>
                    {request.status === DATA_AVAILABLE_STATUS ?
                      this.renderPopup(request, i, iconClassnames)
                      :
                      <React.Fragment>
                        <i
                          onMouseOver={request.handleMouseOver}
                          onMouseLeave={request.onClose}
                          className={iconClassnames} />
                        <Popup
                          className={styles['request-popover-anchor']}
                          isOpen={openMarkerPopoverId === i}>
                          <div className={
                            styles['request-popover-anchor-title']}>
                            {t('text.raw_data')}
                          </div>
                          <div
                            className={styles['request-popover-anchor-status']}>
                            {t(this.getStatusLabel(request.status))}
                          </div>
                        </Popup>
                      </React.Fragment>
                    }
                  </RequestMarkerPopup>
                )
              })
            }
          </g>
        </g>
      </React.Fragment>
    )
  }

  renderPopup = (request, i, iconClassnames) => {
    const { t, timezone } = this.props
    const { openMarkerPopoverId } = this.state

    return (
      <React.Fragment>
        <i
          onClick={request.handleClick}
          className={`${iconClassnames} ${styles['icon-click-here']}`} />
        <Popup
          key={i}
          isOpen={
            openMarkerPopoverId === i
          }
          className={styles['request-popover-anchor']}>
          <div className={styles['tooltip-actions-row']}>
            <div className={styles['tooltip-actions-row-text']}>
              <div className={styles['title-text']}>
                {t('text.raw_data')}
              </div>
              &nbsp;
              <div className={styles['duration-text']}>
                {formatRawDataDuration({
                  start: new Date(request.startTime).getTime(),
                  end: new Date(request.endTime).getTime(),
                })}
              </div>
            </div>

            <div className={styles['tooltip-actions-row-actions']}>
              <Button
                outline
                color="secondary"
                onClick={request.onExport}
                className={styles['export-button']}>
                {t('text.export')}
              </Button>
              <BorderlessButton
                color="secondary"
                onClick={request.onClose}
                className={styles['close-button']}>
                <i className="fal fa-times"></i>
              </BorderlessButton>
            </div>
          </div>

          <table className={styles.table}>
            <thead>
              <tr>
                <th>{t('text.from')}</th>
                <th>{t('text.to')}</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>
                  {moment(request.startTime).tz(timezone)
                    .format(DISPLAY_TIME_FORMAT)}
                </td>
                <td>
                  {moment(request.endTime).tz(timezone)
                    .format(DISPLAY_TIME_FORMAT)}
                </td>
              </tr>
            </tbody>
          </table>
        </Popup>
      </React.Fragment>
    )
  }

  renderRequestArea = () => {
    const { openMarkerPopoverId } = this.state
    const { graphConfig } = this.props
    const rawDataRequestMarkers = this.selectRawDataRequestMarkers(this.props)
    const markerInFocus = rawDataRequestMarkers[openMarkerPopoverId]

    // This can happen in the middle of a zoom transition
    if (!markerInFocus) {
      return null
    }

    const { startTime, endTime } = markerInFocus
    const [minRangeX, maxRangeX] = graphConfig.xScale.range()
      .sort((x0, x1) => x0 - x1)
    const xRange = [
      Math.max(minRangeX, graphConfig.xScale(new Date(startTime))),
      Math.min(maxRangeX, graphConfig.xScale(new Date(endTime))),
    ]
    const yRange = graphConfig.yScale.range()
      .sort((y0, y1) => y0 - y1)
      .map(y => y)

    return (
      <AreaOverlay
        xRange={xRange}
        yRange={yRange} />
    )
  }

  handleMouseOver = markerId => {
    this.setState({ openMarkerPopoverId: markerId })
  }

  handleClick = markerId => {
    const { openMarkerPopoverId } = this.state
    this.setState({
      openMarkerPopoverId: openMarkerPopoverId !== false
        && openMarkerPopoverId === markerId
        ? false : markerId,
    })
  }

  onExport = exportData => {
    this.setState({
      isCsvExportModalOpen: true,
      exportDataObj: exportData,
    })
    this.onClose()
  }

  onClose() {
    this.setState({ openMarkerPopoverId: false })
  }

  onCsvExportModalToggle = () => {
    this.setState({
      isCsvExportModalOpen: false,
      xportDataObj: undefined,
    })
  }

  getStatusLabel(status) {
    switch (status) {
      case SUBMITTED_STATUS:
        return 'status.submitted'
      case IN_PROGRESS_STATUS:
        return 'status.in_progress'
      case DATA_AVAILABLE_STATUS:
        return 'status.data_available'
      case CANCELLED_STATUS:
        return 'status.cancelled'
      case ERROR_STATUS:
        return 'status.error'
      default:
        return ''
    }
  }

  selectRawDataRequestMarkers = createSelector(
    [
      get('listRawDataRequestsResult'),
      get('graphConfig'),
      get('networkAssetGraphItems'),
      get('dataSourcesResult'),
    ],
    (listRawDataRequestsResult, graphConfig, networkAssetGraphItems,
      dataSourcesResult) => {
      const { xScale, yScale } = graphConfig

      if (listRawDataRequestsResult.wasSuccessful()) {
        return listRawDataRequestsResult.data.rawDataRequests
          .reduce((carry, { startTime, endTime, networkAsset, ...rest }, i) => {
            const xDomain = xScale.domain()
            const minRangeTime = min(xDomain)
            const maxRangeTime = max(xDomain)
            const startTimeDate = new Date(startTime)
            const minTime = minRangeTime > startTimeDate ?
              minRangeTime : startTimeDate
            let markerPositionY

            if (minTime < maxRangeTime) {
              const assetData = networkAssetGraphItems.find(
                assetGraphItem =>
                  assetGraphItem.sourceId === networkAsset.networkAssetId &&
                  assetGraphItem.sourceChannel === networkAsset.channel
              )

              if (assetData) {
                const summedUpPressureData = assetData.dataSegments
                  .reduce((pressurePoints, { data }) => {
                    pressurePoints.push(...data)
                    return pressurePoints
                  }, [])
                  .sort((a, b) => {
                    let Atime = a.time
                    let Btime = b.time
                    if (!Atime || !Btime) {
                      return false
                    }
                    if (typeof a.time === 'string' || typeof a.time === 'number') {
                      Atime = new Date(Number(a.time))
                    }
                    if (typeof b.time === 'string' || typeof b.time === 'number') {
                      Btime = new Date(Number(b.time))
                    }
                    if (!Atime || !Btime) {
                      return 0
                    }

                    return Atime.getTime() - Btime.getTime()
                  })
                const dataPoint = summedUpPressureData
                  .find(({ time }) => {
                    if (typeof time === 'string') {
                      time = new Date(Number(time))
                    }
                    return time >= minTime
                  })
                if (dataPoint) {
                  markerPositionY = dataPoint.mean
                }
              }

              if (minTime && typeof markerPositionY !== 'undefined' &&
                dataSourcesResult && dataSourcesResult.data) {
                const source = dataSourcesResult.data.find(
                  (sourcesAsset) => {
                    return `a.${sourcesAsset.source.id}.${sourcesAsset.sourceChannel}` === // eslint-disable-line max-len
                      `a.${networkAsset.networkAssetId}.${networkAsset.channel}`
                  }
                )
                carry.push({
                  handleMouseOver: this.handleMouseOver.bind(this, i),
                  handleClick: this.handleClick.bind(this, i),
                  onExport: this.onExport.bind(this, {
                    startTime: new Date(startTime),
                    endTime: new Date(endTime),
                    dataType: 'rawTimeseries',
                    source,
                  }),
                  onClose: this.onClose.bind(this),
                  x: xScale(minTime),
                  y: yScale(markerPositionY),
                  startTime,
                  endTime,
                  networkAsset,
                  ...rest,
                })
              }
            }

            return carry
          }, [])
      } else {
        return []
      }
    }
  )
}

function RawDataRequestsLayerContainer(props) {
  return (
    <GraphContext.Consumer>
      {config => <RawDataRequestsLayer graphConfig={config} {...props} />}
    </GraphContext.Consumer>
  )
}

export default withTranslation([
  'src/analysis_path/pressure_analysis_path/pressure_subset_graph/raw_data_requests_layer', // eslint-disable-line max-len
])(RawDataRequestsLayerContainer)
