import React, { createContext } from 'react'
import PropTypes from 'prop-types'
import { createSelector } from 'reselect'
import { get } from 'lodash/object'

import AsyncResult from '@@src/utils/async_result'

export const PagedAlertsContext = createContext()

export default class PagedAlertsProvider extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node,
  }

  state = {
    pagedAlertsResult: AsyncResult.notFound([]),
  }

  render() {
    const { children } = this.props
    const value = this.selectPagedAlertsValue(this.state)

    return (
      <PagedAlertsContext.Provider value={value}>
        {children}
      </PagedAlertsContext.Provider>
    )
  }

  setPagedAlertsResult = result => {
    this.setState({
      pagedAlertsResult: result,
    })
  }

  selectPagedAlertsValue = createSelector(
    [get('pagedAlertsResult')],
    pagedAlertsResult => {
      return {
        pagedAlertsResult,
        setPagedAlertsResult: this.setPagedAlertsResult,
      }
    }
  )
}
