import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import moment from 'moment'
import { compose } from 'redux'

import {
  UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

import routes from '@@src/routes'
import { MixedGroupDetailsType, GetGroupsResult } from '@@src/api/presenters/group'
import TenantLicenceFeatureGate, { EVENT_LOCALISATION_FEATURE } from
'@@src/components/tenant_licence/tenant_licence_feature_gate'
import { STORAGE_KEY } from '@@src/analysis_path/with_data_sources'

import styles from './event_source_localisation_link.css'

function EventSourceLocalisationContent(props) {
  const { group } = props

  return (
    <span>
      <i className="far fa-bullseye-arrow" />
      <span name="unread-event-source-localisations-count" className="ml-1">
        { group.unreadEventSourceLocalisationsCount ?
          group.unreadEventSourceLocalisationsCount : '' }
      </span>
    </span>
  )
}

EventSourceLocalisationContent.propTypes = {
  group: PropTypes.oneOfType(
    [
      PropTypes.instanceOf(MixedGroupDetailsType),
      PropTypes.instanceOf(GetGroupsResult),
    ]
  ).isRequired,
}

class EventSourceLocalisationLink extends React.PureComponent {

  static propTypes = {
    group: PropTypes.oneOfType(
      [
        PropTypes.instanceOf(MixedGroupDetailsType),
        PropTypes.instanceOf(GetGroupsResult),
      ]
    ).isRequired,
  }

  render() {
    const { group } = this.props
    if (group.unreadEventSourceLocalisationsCount <= 0) {
      return null
    }
    return (
      <TenantLicenceFeatureGate
        requiredTenantLicenceFeature={EVENT_LOCALISATION_FEATURE}>
        {
          group.isGroupOfGroups() ?
            this.renderGroupOfGroupsLink() :
            this.renderDeviceOrNetworkAssetGroupLink()
        }
      </TenantLicenceFeatureGate>

    )
  }

  renderGroupOfGroupsLink = () => {
    const { t, group, mostDistantAssetGroups } = this.props
    return (
      <UncontrolledButtonDropdown name="event-source-detection-dropdown">
        <DropdownToggle
          color="link"
          className={this.getBtnClassnames(group, 'mr-2')}>
          <EventSourceLocalisationContent group={group} />
        </DropdownToggle>
        <DropdownMenu>
          {
            mostDistantAssetGroups.length > 0 ?
              mostDistantAssetGroups.map(g => {
                const arrayOfIds = [g.uuid]
                g.membersIds.forEach(id => {
                  if(g.membersType === 'VNetworkAsset') {
                    arrayOfIds.push(`a.${id}`)
                  }
                  if(g.membersType === 'VDevice') {
                    arrayOfIds.push(`d.${id}`)
                  }
                })
                return(
                  <DropdownItem className="px-0" key={g.groupId}>
                    <Link
                      name="event-source-detection-link"
                      onClick={ () => localStorage.setItem(STORAGE_KEY, JSON.stringify(arrayOfIds)) }
                      to={routes.analysisPressureEventSourcesPath({ d: arrayOfIds })}
                      className={this.getBtnClassnames(
                      g,
                      'd-flex align-items-center justify-content-between w-100'
                      )}>
                      <span className="mr-3 text-dark">
                        {g.groupName}
                      </span>
                      <EventSourceLocalisationContent group={g} />
                    </Link>
                  </DropdownItem>
                )
              })
              :
              <DropdownItem>
                {t('text.no_network_asset_groups')}
              </DropdownItem>
          }
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    )
  }

  renderDeviceOrNetworkAssetGroupLink = () => {
    const { group, groupIdWithMembersIds } = this.props
    const fromDate = moment(
      new Date(group.firstUnreadEventSourceLocalisationDate).toUTCString()
    )
    const toDate = moment(
      new Date(group.lastUnreadEventSourceLocalisationDate).toUTCString()
    )
    return (
      <Link
        name="event-source-detection-link"
        to={routes.analysisMapPath({
          d: groupIdWithMembersIds,
          from: fromDate.toISOString(),
          to: toDate.toISOString(),
        })}
        className={this.getBtnClassnames(group, 'mr-2')}>
        <EventSourceLocalisationContent group={group} />
      </Link>
    )
  }

  getBtnClassnames = (group, classNames) => {
    return classnames('btn btn-link', styles['link-action-button'], classNames,
      {
        'text-primary': group.unreadEventSourceLocalisationsCount > 0,
      }
    )
  }

}

export default compose(
  withTranslation([
    'src/management_path/groups_path/event_source_localisation_link',
  ]),
)(EventSourceLocalisationLink)
