import React from 'react'
import classnames from 'classnames'
import { Row, NavLink as BNavLink, NavItem, Nav } from 'reactstrap'

import PropTypes from 'prop-types'

const DevicesInstallDeviceModalTabs = ({ currentTab, setCurrentTab, tabNames = [] }) => {
  return(
    <Row className="p-0 m-0">
      <Nav tabs className="w-100">
        {
          tabNames.map((tabName, index) => {
            return(
              <NavItem key={tabName}>
                <BNavLink
                  name={tabName}
                  onClick={() => setCurrentTab(index)}
                  className={classnames({ active: currentTab === index })}>
                  {tabName}
                </BNavLink>
              </NavItem>
            )
          })
        }
      </Nav>
    </Row>
  )
}

DevicesInstallDeviceModalTabs.propTypes = {
  currentTab: PropTypes.number.isRequired,
  setCurrentTab: PropTypes.func.isRequired,
  tabName: PropTypes.array,
}

export default DevicesInstallDeviceModalTabs
