import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash/fp/object'
import { createSelector } from 'reselect'

import GraphContext, { GraphConfig }
from '@@src/components/graphs/graph_context'
import responsiveComponent from '@@src/components/responsive_component'

class Graph extends React.PureComponent {
  static defaultProps = {
    topPadding: 50,
    leftPadding: 50,
    rightPadding: 50,
    bottomPadding: 50,
  }

  static propTypes = {
    xScale: PropTypes.func.isRequired,
    yScale: PropTypes.func.isRequired,
    yScale2: PropTypes.func,

    topPadding: PropTypes.number.isRequired,
    leftPadding: PropTypes.number.isRequired,
    rightPadding: PropTypes.number.isRequired,
    bottomPadding: PropTypes.number.isRequired,

    measuredWidth: PropTypes.number.isRequired,
    measuredHeight: PropTypes.number.isRequired,
  }

  render() {
    const { children, measuredWidth, measuredHeight } = this.props

    return (
      <svg width={measuredWidth} height={measuredHeight}>
        <GraphContext.Provider value={this.selectGraphConfig(this.props)}>
          {children}
        </GraphContext.Provider>
      </svg>
    )
  }

  selectGraphConfig = createSelector(
    [
      get('measuredWidth'),
      get('measuredHeight'),
      get('xScale'),
      get('yScale'),
      get('yScale2'),
      get('topPadding'),
      get('leftPadding'),
      get('rightPadding'),
      get('bottomPadding'),
    ],
    (width, height, xScale, yScale, yScale2, topPadding, leftPadding, rightPadding, bottomPadding) => { // eslint-disable-line max-len
      return new GraphConfig({
        width,
        height,
        xScale,
        yScale,
        yScale2,
        topPadding,
        leftPadding,
        rightPadding,
        bottomPadding,
      })
    }
  )
}

export default responsiveComponent(Graph)
