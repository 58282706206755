import React from 'react'
import PropTypes from 'prop-types'
import { line } from 'd3-shape'
import { get } from 'lodash/fp/object'
import { createSelector } from 'reselect'

import { globalSequence } from '@@src/utils'
import { GraphConfig } from '@@src/components/graphs/graph_context'
import HistoryPlotLayer from
'@@src/management_path/devices_path/history_modal/history_plot_layer'
import { LinePlot, CirclePlot } from
'@@src/management_path/devices_path/history_modal/history_plot_layer'
import styles from './temperature_history_plot_layer.css'

export default class TemperatureHistoryPlotLayer extends React.PureComponent {
  static propTypes = {
    telemetrySegments: PropTypes.array.isRequired,
    graphConfig: PropTypes.instanceOf(GraphConfig).isRequired,
  }

  id = globalSequence.next()

  render() {
    const { telemetrySegments, graphConfig, ...props } = this.props
    const clipPathId = `history-plot-layer-clip-path-${this.id}`

    return (
      <HistoryPlotLayer
        {...props}
        clipPathId={clipPathId}
        graphConfig={graphConfig}
        name="temperature-history-plot-layer">
        {telemetrySegments.map(({ data, index }) => (
          this.renderSegment(index, data, clipPathId))
        )}
      </HistoryPlotLayer>
    )
  }

  renderSegment = (index, data, clipPathId) => {
    const temperatureHistoryLine =
      this.selectTemperatureHistoryGenerator(this.props)

    return (
      <g key={index}>
        {
          data.length === 1 ?
            <CirclePlot
              name="temperature-history-plot"
              className={styles['temperature-history-circle']}
              r={1}
              cx={this.props.graphConfig.xScale(data[0].time)}
              cy={this.props.graphConfig.yScale(data[0].value)}
              clipPath={`url(#${clipPathId})`} /> :
            <LinePlot
              d={temperatureHistoryLine(data)}
              name="temperature-history-plot"
              clipPath={`url(#${clipPathId})`}
              className={styles['temperature-history-line']}/>
        }
      </g>
    )
  }

  selectTemperatureHistoryGenerator = createSelector(
    [get('graphConfig')],
    graphConfig => {
      return line()
        .x(d => graphConfig.xScale(d.time))
        .y(d => graphConfig.yScale(d.value))
    }
  )
}
