import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem }
from 'reactstrap'

import FilterComponentItem from
'@@src/components/filter_component/filter_component_item'

import styles from './index.css'

export default class CustomOptionsMenu extends React.PureComponent {
  static propTypes = {
    name: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
    isOpen: PropTypes.bool.isRequired,
    onChangeValue: PropTypes.func.isRequired,
    onToggle: PropTypes.func.isRequired,
    title: PropTypes.string,
  }

  static defaultProps = {
    isOpen: false,
  }

  renderDropdownItems = () => {
    const { options, title, onChangeValue } = this.props

    const titleDiv = typeof title === 'undefined' ?
      null : (<div className={styles['dropdown-title']}>{title}</div>)

    return (
      <DropdownMenu className={styles['dropdown-menu']}>
        {titleDiv}
        {options.map((f, i) => {
          return (
            <DropdownItem
              key={i}
              className={styles['dropdown-item']}
              value={f.value}
              onClick={onChangeValue}>
              <FilterComponentItem
                value={f.value}
                isSelected={f.selected}
                text={f.text} />
            </DropdownItem>
          )
        })}
      </DropdownMenu>
    )
  }

  render() {
    const { name, onToggle, label, isOpen } = this.props
    const dropdownToggleClassnames = classnames(
      styles['dropdown-toggle'],
      'border-0',
      {
        [styles['dropdown-toggle-active']]: isOpen,
      }
    )

    return (
      <ButtonDropdown
        name={name}
        isOpen={isOpen}
        className={styles['dropdown-button']}
        toggle={onToggle}>
        <DropdownToggle outline className={dropdownToggleClassnames}>
          {label}
        </DropdownToggle>
        {isOpen ? this.renderDropdownItems() : null}
      </ButtonDropdown>
    )
  }
}
