import React from 'react'
import Bowser from 'bowser'
import { withTranslation } from 'react-i18next'

import BorderlessButton from '@@src/components/buttons/borderless_button'

import styles from './browser_warning.css'

class BrowserWarning extends React.PureComponent {
  state = {
    show: true,
    browserIssue: null,
  }

  render() {
    const { show, browserIssue } = this.state

    if (!browserIssue || !show) {
      return null
    }

    return browserIssue.renderWarning()
  }

  renderOutdatedBrowserWarning = () => {
    const { t } = this.props

    return (
      <div className={styles['container-warning']}>
        <i className="fas fa-exclamation-triangle"></i>

        <strong>
          {t('text.outdated_browser_title')}
        </strong>

        &nbsp;

        <span className={styles['help-text']}>
          {t('text.outdated_browser_help')}
        </span>

        <div className={styles['dismiss-button-container']}>
          <BorderlessButton
            onClick={this.onDismissWarning}
            className={styles['dismiss-button']}>
            <i className="far fa-times"></i>
          </BorderlessButton>
        </div>
      </div>
    )
  }

  renderUnsupportedBrowserWarning = () => {
    const { t } = this.props

    return (
      <div className={styles['container-error']}>
        <i className="fas fa-times-circle"></i>

        <strong>
          {t('text.unsupported_browser_title')}
        </strong>

        &nbsp;

        <span className={styles['help-text']}>
          {t('text.unsupported_browser_help')}
        </span>

        <div className={styles['dismiss-button-container']}>
          <BorderlessButton
            onClick={this.onDismissWarning}
            className={styles['dismiss-button']}>
            <i className="far fa-times"></i>
          </BorderlessButton>
        </div>
      </div>
    )
  }

  componentDidMount() {
    const browser = Bowser.getParser(window.navigator.userAgent)
    const isValidBrowser = browser.satisfies({
      chrome: '>=1',
    })

    if (!isValidBrowser) {
      this.setState(this.stateWithRender(this.renderUnsupportedBrowserWarning))
    } else {
      const isBrowserUpToDate = browser.satisfies({
        chrome: '>=70',
      })

      if (!isBrowserUpToDate) {
        this.setState(this.stateWithRender(this.renderOutdatedBrowserWarning))
      }
    }
  }

  stateWithRender(renderWarning) {
    return {
      show: true,
      browserIssue: { renderWarning },
    }
  }

  onDismissWarning = () => {
    this.setState({ show: false })
  }
}

export default withTranslation(
  'src/components/app_layout/browser_warning'
)(BrowserWarning)
