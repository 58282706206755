import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import { Breadcrumb, BreadcrumbItem } from 'reactstrap'

import routes from '@@src/routes'
import { AsyncResult } from '@@src/utils'

class GroupBreadcrumb extends React.PureComponent {
  static propTypes = {
    groupResult: PropTypes.instanceOf(AsyncResult).isRequired,
  }

  render() {
    const { t, groupResult } = this.props
    const { data: group } = groupResult

    return (
      <Breadcrumb>
        <BreadcrumbItem className="text-muted">
          {t('buttons.management')}
        </BreadcrumbItem>

        <BreadcrumbItem>
          <Link to={routes.managementGroupsPath()}>
            {t('buttons.management_groups')}
          </Link>
        </BreadcrumbItem>

        {
          groupResult.wasSuccessful() && Array.isArray(group.ancestry) ? (
            group.ancestry.sort((a1, a2) => a1 - a2).map(ancestor => (
              <BreadcrumbItem key={ancestor.id}>
                <Link to={routes.managementGroupsDetailPath(ancestor.id)}>
                  {ancestor.name}
                </Link>
              </BreadcrumbItem>
            ))
          ) : null
        }

        {
          groupResult.wasSuccessful() ? (
            <BreadcrumbItem>
              <Link to={routes.managementGroupsDetailPath(group.id)}>
                {group.name}
              </Link>
            </BreadcrumbItem>
          ) : null
        }
      </Breadcrumb>
    )
  }
}

export default compose(
  withTranslation([
    'src/management_path/groups_path/group_detail_page',
  ])
)(GroupBreadcrumb)
