import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { withTranslation } from 'react-i18next'
import ReactTooltip from 'react-tooltip'
import {
  Button,
  Col,
  Row,
  DropdownMenu,
  DropdownItem,
  UncontrolledButtonDropdown,
  DropdownToggle,
} from 'reactstrap'

import NetworkAsset from '@@src/api/presenters/network_asset'
import Device from '@@src/api/presenters/device'
import { MixedGroupDetailsType } from '@@src/api/presenters/group'
import routes from '@@src/routes'
import NetworkAssetIcon from '@@src/components/icons/network_asset_icon'
import DeviceIconWithStatus from '@@src/components/icons/device_icon_with_status'
import GroupIcon from '@@src/components/icons/group_icon'

import styles from './analysis_list_sources_item.css'

class AnalysisListSourcesItem extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    t: PropTypes.func.isRequired,
    selected: PropTypes.bool.isRequired,
    dataSource: PropTypes.object.isRequired,
    removeFromSelected: PropTypes.func.isRequired,
    isLimitReached: PropTypes.bool.isRequired,
    onClick: PropTypes.func,
    showAncestors: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    selected: false,
    isLimitReached: false,
    showAncestors: false,
  }

  render() {
    const { className, onClick, t, dataSource } = this.props
    const isDevice = this.isDevice()
    const isNetworkAsset = this.isNetworkAsset()
    const isGroup = this.isGroup()
    const navLink = isDevice
      ? routes.managementDevicesDetailPath(dataSource.id)
      : isNetworkAsset
        ? routes.networkAssetsDetailPath(dataSource.id)
        : routes.managementGroupsDetailPath(dataSource.id)

    const arrayOfIds = [dataSource.uuid]
    if(dataSource.__typename === 'MixedGroupDetailsType') {
      if(dataSource.isGroupOfGroups()) {
        dataSource.members.data.map(distantGroup => {
          arrayOfIds.push(distantGroup.uuid)
          distantGroup.members.data.forEach(childSource => {
            arrayOfIds.push(childSource.uuid)
          })
        })
      } else {
        dataSource.members.data.forEach(childSource => {
          arrayOfIds.push(childSource.uuid)
        })
      }
    }

    const analysisLink = routes.analysisPressurePath({ d: arrayOfIds })

    return (
      <li className={classnames(className, styles.sidebar)} onClick={onClick}>
        <Row className={styles.row}>
          <Col sm="8" className={styles['summary-column']}>
            {
              this.renderItemHeader(isDevice, isNetworkAsset, isGroup, dataSource)
            }
          </Col>
          <Col sm="4" className={styles['actions-column']}>
            <Button
              name="remove-from-selected-sources"
              onClick={this.removeFromSources}
              className={classnames(
                styles.button,
                'btn btn-outline-secondary',
                styles['cross-button']
              )}
              name="remove-from-sources"
            >
              <i className="fal fa-times" />
            </Button>
            <UncontrolledButtonDropdown>
              <DropdownToggle outline className={styles.button}>
                <i className="far fa-ellipsis-v"></i>
              </DropdownToggle>
              <DropdownMenu end>
                <DropdownItem
                  href={navLink}
                  className={styles['dropdown-item']}
                  name="item-details-link"
                >
                  <i
                    className={classnames(
                      styles['dropdown-icon'],
                      'fa fa-info'
                    )}
                  />

                  <span className={styles['dropdown-item-text']}>
                    {t('common/text:buttons.details')}
                  </span>

                  <i
                    className={classnames(
                      styles['dropdown-icon-external-link'],
                      'far fa-external-link-alt'
                    )}
                  />
                </DropdownItem>
                <DropdownItem
                  href={analysisLink}
                  className={styles['dropdown-item']}
                  name="item-data-link"
                >
                  <i
                    className={classnames(
                      styles['dropdown-icon'],
                      'fa fa-chart-area'
                    )}
                  />

                  <span className={styles['dropdown-item-text']}>
                    {t('common/text:buttons.show_data')}
                  </span>

                  <i
                    className={classnames(
                      styles['dropdown-icon-external-link'],
                      'far fa-external-link-alt'
                    )}
                  />
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </Col>
        </Row>
        <Col>
          {dataSource.members
            ? dataSource.members.data.map(member => this.renderMember(member))
            : null}
        </Col>
      </li>
    )
  }

  renderItemHeader = (isDevice, isNetworkAsset, isGroup, dataSource) => {
    const { showAncestors } = this.props
    const ancestorsPath =
      isGroup && dataSource.constructGroupAncestryPath ? dataSource.constructGroupAncestryPath() : ''

    return(
      <>
      <div className={styles['icon-subcolumn']}>
        {isDevice ? (
          <DeviceIconWithStatus
            device={{ status: dataSource.commissionStatus }}
            className={styles['asset-device-sidebar-icon']}
          />
        ) : isNetworkAsset ? (
          <NetworkAssetIcon
            networkAsset={dataSource}
            className={styles['asset-device-sidebar-icon']}
          />
        ) : (
          <GroupIcon className={styles['icon-sidebar']} />
        )}
      </div>
      <div className={styles['summary-subcolumn']}>
        <span data-tip={ancestorsPath}>
          <span
            className={classnames(styles['data-source-id'], 'text-break')}
          >
            {isDevice
              ? dataSource.serialNumber
              : isNetworkAsset
                ? dataSource.assetName || dataSource.assetId
                : ancestorsPath !== '' && showAncestors
                  ? `.. > ${dataSource.name}`
                  : dataSource.name}
          </span>
        </span>
        {ancestorsPath !== '' && showAncestors ? (
          <ReactTooltip effect="solid" place="left" />
        ) : null}
      </div>
    </>
    )
  }

  renderMember = (member) => {
    // member will only ever be device or asset
    const memberIsDevice = member.__typename === 'Device'
    const memberIsNetworkAsset = member.__typename === 'NetworkAsset'

    if(!memberIsDevice && !memberIsNetworkAsset) {
      return (
        <React.Fragment key={member.id}>
          <Row className={styles.row} key={member.id}>
            <i
              className={classnames(
                styles['nesting-icon'],
                'far fa-level-up'
              )}
            />
            {this.renderItemHeader(memberIsDevice, memberIsNetworkAsset, true, member)}
          </Row >
          <Col>
            {member.members
              ? member.members.data.map(innerMember => this.renderMember(innerMember))
              : null}
          </Col>
        </React.Fragment>
      )
    }

    return (
      <Row name={'member-' + member.id} className={styles.row} key={member.id}>
        <Col
          className={styles['summary-column']}
          name="sidebar-group-child"
        >
          <div className={styles['icon-subcolumn']}>
            <i
              className={classnames(
                styles['nesting-icon'],
                'far fa-level-up'
              )}
            />
            {memberIsDevice ? (
              <DeviceIconWithStatus
                device={{ status: member.status }}
                className={styles['asset-device-sidebar-icon']}
              />
            ) : (
              <NetworkAssetIcon
                networkAsset={member}
                className={styles['asset-device-sidebar-icon']}
              />
            )
            }
            <div className={styles['summary-subcolumn']}>
              <span className={styles['member-source-id']}>
                {memberIsDevice
                  ? member.serialNumber
                  : member.assetName || member.assetId}
              </span>
            </div>
          </div>
        </Col>
      </Row>
    )
  }

  removeFromSources = () => {
    const { removeFromSelected, dataSource } = this.props
    if(this.isGroup() && dataSource.members.type === Device.CONFIGURATION_SOURCE_GROUP_TYPE) {
      const result = [dataSource, ...dataSource.members.data]
      removeFromSelected(result)
      return
    }
    removeFromSelected(dataSource)
  }

  isDevice() {
    return this.props.dataSource instanceof Device
  }

  isNetworkAsset() {
    return this.props.dataSource instanceof NetworkAsset
  }

  isGroup() {
    return this.props.dataSource instanceof MixedGroupDetailsType
  }
}

export default withTranslation(['common/text'])(AnalysisListSourcesItem)
