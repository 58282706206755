import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { noop } from 'lodash/fp/util'

import LegendItem from '@@src/components/graphs/legend_item'
import PressureEvent from '@@src/api/presenters/pressure_event'
import { DEFAULT_TIMEZONE, formatEventDuration, selectDateFormatter } from '@@src/utils'
import { LegendItemColorContext } from '@@src/components/colors/legend_item_color_provider'
import { AVAILABLE_PRESSURE_UNITS } from '@@src/utils/unit_constants'
import { convertUnitFromDB, PRESSURE_TYPE } from '@@src/utils/app_unit_conversion'
import PressurePreview from '../../../components/pressure_preview/pressure_preview'

const FORMAT_DATE = 'YYYY.MM.DD'
const FORMAT_TIME_OF_DAY = 'HH:mm:ss'

class EventListItem extends React.PureComponent {
  static defaultProps = {
    timezone: DEFAULT_TIMEZONE,
    onClickViewEvents: () => {
    },
    onMouseEnterRow: noop,
    onMouseLeaveRow: noop,
  }

  static propTypes = {
    event: PropTypes.instanceOf(PressureEvent).isRequired,
    timezone: PropTypes.string.isRequired,
    legendItem: PropTypes.instanceOf(LegendItem).isRequired,
    onClickViewEvents: PropTypes.func.isRequired,
    pressureUnits: PropTypes.oneOf(AVAILABLE_PRESSURE_UNITS).isRequired,
  }

  render() {
    const {
      t, event, timezone, legendItem, onMouseEnterRow, onMouseLeaveRow,
      pressureUnits,
    } = this.props
    const dateFormat = selectDateFormatter(timezone)
    const { logicalChannel: channel } = event

    return (
      <tr
        name="event-row"
        onMouseEnter={onMouseEnterRow}
        onMouseLeave={onMouseLeaveRow}>
        <td name="event-subset-preview" onClick={this.onClickViewEvent}>
          <LegendItemColorContext.Consumer>
            {({ getLegendItemColor }) => (
              <PressurePreview
                start={event.start}
                end={event.end}
                networkAsset={{ channel: event.logicalChannel, id: event.assetId }}
                color={getLegendItemColor(legendItem)}
              />
            )}
          </LegendItemColorContext.Consumer>
        </td>

        <td>{legendItem.source.assetId}</td>
        <td>
          {t(`common/text:text.asset_channel_${channel}`)}
        </td>
        <td>
          {dateFormat(event.start, FORMAT_DATE)}
          <br />
          {dateFormat(event.start, FORMAT_TIME_OF_DAY)}
        </td>
        <td>
          {dateFormat(event.end, FORMAT_DATE)}
          <br />
          {dateFormat(event.end, FORMAT_TIME_OF_DAY)}
        </td>
        <td>
          {formatEventDuration(event)}
        </td>
        <td>
          {
            event.cpisScore === null ?
              'N/A' :
              event.cpisScore.toFixed(0)
          }
        </td>
        <td>
          {
            event.severity === null ?
              'N/A' :
              convertUnitFromDB(
                event.severity,
                pressureUnits,
                PRESSURE_TYPE,
                0,
              )
          }
        </td>
        <td>{
          convertUnitFromDB(
            event.minPressure,
            pressureUnits,
            PRESSURE_TYPE,
            1,
          )
        }</td>
        <td>{
          convertUnitFromDB(
            event.meanPressure,
            pressureUnits,
            PRESSURE_TYPE,
            1,
          )
        }</td>
        <td>{
          convertUnitFromDB(
            event.maxPressure,
            pressureUnits,
            PRESSURE_TYPE,
            1,
          )
        }</td>
      </tr>
    )
  }

  onClickViewEvent = () => {
    this.props.onClickViewEvents(this.props.event)
  }
}

export default withTranslation('common/text')(EventListItem)
