import React from 'react'
import gql from 'graphql-tag'
import { graphql } from '@apollo/client/react/hoc'
import { compose } from 'redux'
import PropTypes from 'prop-types'

import CommentForm from '@@src/alerts_path/comment_form'
import AsyncResult from '@@src/utils/async_result'
import * as analytics from '@@src/analytics'

class EditCommentForm extends React.PureComponent {
  static propTypes = {
    editComment: PropTypes.func.isRequired,
  }

  render() {
    const { editCommentResult } = this.state

    return (
      <CommentForm
        {...this.props}
        commentResult={editCommentResult}
        handleSubmit={this.editComment}
        handleSubmitError={this.setEditCommentError} />
    )
  }

  state = {
    editCommentResult: AsyncResult.notFound(),
  }

  editComment = async editedComment => {
    const {
      editComment,
      comment,
    } = this.props

    try {
      this.setState({ editCommentResult: AsyncResult.pending() })

      await editComment({
        variables: {
          commentId: comment.commentId,
          comment: editedComment,
        },
      })

      this.setState({ editCommentResult: AsyncResult.success() })
    } catch (err) {
      analytics.logError(err)
      this.setState({ editCommentResult: AsyncResult.fail(err) })
    }
  }

  setEditCommentError = err => {
    this.setState({
      editCommentResult: AsyncResult.fail(err),
    })
  }

  static EDIT_COMMENT_MUTATION = gql`
    mutation UpdateComment(
      $commentId: Int!,
      $comment: String!,
    ) {
      updateComment(
        commentId: $commentId,
        comment: $comment,
      )
    }
  `
}

export default compose(
  graphql(EditCommentForm.EDIT_COMMENT_MUTATION, {
    name: 'editComment',
  }),
)(EditCommentForm)
