import React, { createRef } from 'react'
import PropTypes from 'prop-types'
import L from 'leaflet'
import gql from 'graphql-tag'
import { get } from 'lodash/fp/object'
import { partition } from 'lodash/fp/collection'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { graphql } from '@apollo/client/react/hoc'
import { Redirect } from 'react-router-dom'
import { createSelector } from 'reselect'
import { withTranslation } from 'react-i18next'
import classnames from 'classnames'

import StandardMap from '@@src/components/maps/standard_map'
import transformProps from '@@src/components/transform_props'
import DeviceCommissionPopup from '@@src/components/maps/markers/device_commission_popup'
import DeviceCommissionMarker from '@@src/components/maps/markers/device_commission_marker'
import NetworkAssetPopup from '@@src/components/maps/markers/network_asset_popup'
import NetworkAssetMarker from '@@src/components/maps/markers/network_asset_marker'
import AsyncResult from '@@src/utils/async_result'
import requiresLogin from '@@src/components/security/requires_login'
import withDataSourceSelectionLayout from '@@src/analysis_path/data_source_selection_layout'
import { parseGraphQLResult } from '@@src/api/presenters'
import { parseSearchParams, isValidCoordinate } from '@@src/utils'
import Device from '@@src/api/presenters/device'
import NetworkAsset from '@@src/api/presenters/network_asset'
import { createSelectGraphQLResult, formatTimestampForAPI } from '@@src/utils'
import withGoogleMapsProvider from '@@src/components/maps/google_maps_provider'
import userPermissions from '@@src/components/permissions/user_permissions'
import MarkerClusterGroup from '@@src/components/maps/marker_cluster_group'
import MarkerLoader from '@@src/components/maps/marker_loader'
import { CAN_VIEW_ANALYSIS } from '../../_v2/contexts/user/consts/permissions'

import styles from './index.css'

const PRESSURE_EVENT_LIMIT = 50000

class AnalysisMap extends React.Component {
  static propTypes = {
    pagedPressureEventsResult: PropTypes.instanceOf(AsyncResult).isRequired,
    groupSourcesResult: PropTypes.instanceOf(AsyncResult).isRequired,
    fetchMoreEvents: PropTypes.func,
    eventsStartDate: PropTypes.instanceOf(Date).isRequired,
    selectedDataSources: PropTypes.arrayOf(PropTypes.object).isRequired,
    googleMaps: PropTypes.object,
  }

  static defaultProps = {
    pagedPressureEventsResult: AsyncResult.pending([]),
    groupSourcesResult: AsyncResult.pending([]),
    selectedDataSources: [],
  }

  state = {
    mapBounds: undefined,
    markersLoaded: 0,
    totalMarkers: 0,
  }

  map = createRef()

  render() {
    const {
      addToSelected,
      removeFromSelected,
      isLimitReached,
      selectedDataSources,
      eventsStartDate,
      googleMaps,
      permissions,
    } = this.props
    const { processedMarkers, totalMarkers } = this.state

    // Does the user have permissions for this page?
    if (!permissions.includes(CAN_VIEW_ANALYSIS)) {
      return <Redirect to="/page-not-found" />
    }

    const { mapBounds } = this.state

    return (
      <div className={styles['map-container']}>
        <div className={classnames(styles['map-view'], 'position-relative')}>
          <MarkerLoader
            totalMarkers={totalMarkers}
            processedMarkers={processedMarkers}
          />

          <StandardMap
            name="map-container"
            bounds={mapBounds}
            className="col-sm-12"
          >
            <MarkerClusterGroup
              chunkProgress={this.handleChunkProgress}
              chunkInterval={50}
              chunkDelay={50}
              chunkedLoading={true}
              showCoverageOnHover={false}
              removeOutsideVisibleBounds={true}
            >
              {this.selectDevices(this.props).map((device) => {
                const commission = device.currentCommission
                const selected =
                  typeof selectedDataSources.find((a) => {
                    return (
                      device.id === a.id && device.__typename === a.__typename
                    )
                  }) !== 'undefined'

                return (
                  <DeviceCommissionMarker
                    key={device.uuid}
                    commission={commission}
                  >
                    {
                      <DeviceCommissionPopup
                        googleMaps={googleMaps}
                        device={device}
                        showDetailsLink={false}
                        showAddLink={true}
                        showDataLink={false}
                        isLimitReached={isLimitReached}
                        selected={selected}
                        addToSelected={addToSelected}
                        removeFromSelected={removeFromSelected}
                      />
                    }
                  </DeviceCommissionMarker>
                )
              })}
              {this.selectAssets(this.props).map((networkAsset) => {
                const selected =
                  typeof selectedDataSources.find((a) => {
                    return (
                      networkAsset.id === a.id &&
                      networkAsset.__typename === a.__typename
                    )
                  }) !== 'undefined'

                return (
                  <NetworkAssetMarker
                    eventsStartDate={eventsStartDate}
                    selected={selected}
                    key={networkAsset.uuid}
                    networkAsset={networkAsset}
                  >
                    <NetworkAssetPopup
                      googleMaps={googleMaps}
                      networkAsset={networkAsset}
                      showDetailsLink={false}
                      showAddLink={true}
                      showDataLink={false}
                      isLimitReached={isLimitReached}
                      selected={selected}
                      addToSelected={addToSelected}
                      removeFromSelected={removeFromSelected}
                    />
                  </NetworkAssetMarker>
                )
              })}
            </MarkerClusterGroup>
          </StandardMap>
        </div>
      </div>
    )
  }

  componentDidUpdate(prevProps) {
    const { dataSourcesResult, pagedPressureEventsResult } = this.props
    const { pagedPressureEventsResult: prevPagedPressureEventsResult } =
      prevProps

    if (
      pagedPressureEventsResult.wasSuccessful() &&
      prevPagedPressureEventsResult.isPending()
    ) {
      this.fetchRemainingEvents()
    }

    if (
      !this.mapBounds &&
      dataSourcesResult.wasSuccessful() &&
      prevProps.dataSourcesResult.isPending()
    ) {
      this.setState({
        mapBounds: this.selectMapBounds(this.props, this.state),
      })
    }
  }

  handleChunkProgress = (processed, total) => {
    this.setState({ processedMarkers: processed, totalMarkers: total })
  }

  fetchRemainingEvents = () => {
    const { fetchMoreEvents, pagedPressureEventsResult } = this.props
    const { totalPages } = pagedPressureEventsResult.data

    for (let i = 2; i <= totalPages; i++) {
      fetchMoreEvents({
        variables: {
          pageNumber: i,
        },
        updateQuery: this.updateEventsQuery,
      })
    }
  }

  updateEventsQuery = (previousResult, { fetchMoreResult, variables }) => {
    const result = parseGraphQLResult(fetchMoreResult)

    if (this.isCurrentEventsQuery(variables)) {
      if (Number(variables.pageNumber) === 1) {
        return result
      } else {
        const { pressureEvents, ...rest } = result.pagedPressureEvents

        return {
          pagedPressureEvents: {
            pressureEvents: [
              ...previousResult.pagedPressureEvents.pressureEvents,
              ...pressureEvents,
            ],
            ...rest,
          },
        }
      }
    } else {
      return previousResult
    }
  }

  isCurrentEventsQuery = ({ networkAssetChannels }) => {
    const { selectedDataSources } = this.props

    return networkAssetChannels.every((assetChannel) => {
      return (
        selectedDataSources.findIndex((dataSource) => {
          return String(dataSource.id) === String(assetChannel.networkAssetId)
        }) !== -1
      )
    })
  }

  getSourceLocation(source) {
    let location = null

    if (source instanceof Device && source.currentLocation) {
      location = source.currentLocation
    } else if (source instanceof NetworkAsset) {
      location = source.location
    }

    return location
  }

  addSelectedDataSourceBounds = (bounds = [], selectedSource) => {
    const { dataSourcesResult } = this.props

    if (dataSourcesResult.wasSuccessful()) {
      const source = dataSourcesResult.data.find(
        ({ id, __typename }) =>
          String(id) === String(selectedSource.id) &&
          __typename === selectedSource.__typename
      )

      if (source) {
        const location = this.getSourceLocation(source)

        if (location && isValidCoordinate(location)) {
          bounds.push(L.latLng(location.latitude, location.longitude))
        }
      }
    }

    return bounds
  }

  addDataSourceBounds = (bounds = [], source) => {
    const location = this.getSourceLocation(source)

    if (location && isValidCoordinate(location)) {
      bounds.push(L.latLng(location.latitude, location.longitude))
    }

    return bounds
  }

  selectDeviceCommissions = createSelector(
    [get('dataSourcesResult')],
    ({ data: dataSources }) =>
      dataSources
        .filter((s) => s.__typename === 'Device')
        .map((s) => s.currentCommission)
        .filter((a) => a)
  )

  selectDevices = createSelector(
    [get('dataSourcesResult')],
    ({ data: dataSources }) =>
      dataSources
        .filter((s) => s.__typename === 'Device')
        .filter((s) => s.currentCommission)
  )

  selectAssets = createSelector(
    [get('dataSourcesResult')],
    ({ data: dataSources }) =>
      dataSources.filter((s) => s.__typename === 'NetworkAsset')
  )

  selectMapBounds = createSelector(
    [
      get('selectedAssetsAndDevices'),
      get('dataSourcesResult'),
      get('selectedGroupAssets'),
      get('selectedGroupDevices'),
    ],
    (
      selectedAssetsAndDevices,
      dataSourcesResult,
      selectedGroupAssets,
      selectedGroupDevices
    ) => {
      if (dataSourcesResult.wasSuccessful()) {
        const bounds = []

        if (
          selectedAssetsAndDevices.length ||
          selectedGroupAssets.length ||
          selectedGroupDevices.length
        ) {
          selectedAssetsAndDevices
            .concat(selectedGroupAssets || [])
            .concat(selectedGroupDevices || [])
            .reduce(this.addSelectedDataSourceBounds, bounds)
        } else {
          dataSourcesResult.data.reduce(this.addDataSourceBounds, bounds)
        }

        return bounds.length ? L.latLngBounds(bounds).pad(0.05) : undefined
      } else {
        return undefined
      }
    }
  )

  selectEventsSeverityByAssetAndHour = createSelector(
    [get('pagedPressureEventsResult')],
    (pagedPressureEventsResult) => {
      if (pagedPressureEventsResult.wasSuccessful()) {
        return pagedPressureEventsResult.data.pressureEvents.reduce(
          (carry, event) => {
            const severityCharacteristic = event.eventCharacteristics.find(
              ({ eventCharacteristic }) => eventCharacteristic === 'severity'
            )

            if (severityCharacteristic) {
              const hour = new Date(event.end).getHours()

              carry[event.assetId] = carry[event.assetId] || {}
              carry[event.assetId][hour] = carry[event.assetId][hour] || []
              carry[event.assetId][hour].push({
                hour,
                ...severityCharacteristic,
              })
            }

            return carry
          },
          {}
        )
      } else {
        return []
      }
    }
  )
}

const createMapStateToProps = () => {
  const eventsStartDate = new Date()
  const now = new Date()

  eventsStartDate.setHours(0, 0, 0, 0)

  const selectParsedSearchParams = createSelector(
    [(_state, { location }) => location.search],
    parseSearchParams
  )

  const selectSearchQuery = (state, props) =>
    selectParsedSearchParams(state, props).q

  const selectDataSourceType = (state, props) =>
    selectParsedSearchParams(state, props).t

  const selectPressureEventsQueryVariables = createSelector(
    [get('dataSourcesResult'), get('page')],
    (dataSourcesResult, pageNumber = 1) => {
      let networkAssetChannels = []

      if (dataSourcesResult.wasSuccessful()) {
        networkAssetChannels = dataSourcesResult.data.reduce(
          (carry, source) => {
            if (source.__typename === 'NetworkAsset') {
              carry.push(
                ...source.availableChannels.map((channel) => {
                  return {
                    networkAssetId: source.id,
                    channel,
                  }
                })
              )
            }

            return carry
          },
          []
        )
      }

      return {
        start: formatTimestampForAPI(eventsStartDate),
        end: formatTimestampForAPI(now),
        pageNumber,
        networkAssetChannels,
      }
    }
  )

  const selectPagedPressureEventsResult = createSelectGraphQLResult(
    'pagedPressureEvents',
    {
      mapResult: parseGraphQLResult,
      nullObject: {
        page: 0,
        totalPages: 0,
        totalResults: 0,
        pressureEvents: [],
      },
    }
  )

  const selectPartitionedSources = createSelector(
    [get('selectedDataSources')],
    (selectedDataSources) => {
      return partition((s) => s.__typename === 'MixedGroupDetailsType')(
        selectedDataSources
      )
    }
  )

  const selectSelectedGroups = createSelector(
    [selectPartitionedSources],
    (paritionedSelectedSources) => paritionedSelectedSources[0]
  )

  const selectSelectedAssetsAndDevices = createSelector(
    [selectPartitionedSources],
    (paritionedSelectedSources) => paritionedSelectedSources[1]
  )

  const selectGroupAssets = createSelector(
    [get('groupSourcesResult'), selectSelectedGroups],
    (groupSourcesResult, selectedGroups) => {
      if (groupSourcesResult.wasSuccessful()) {
        return selectedGroups.reduce((carry, selectedDataSource) => {
          const selectedGroupSource = groupSourcesResult.data.data.find(
            (groupSource) => {
              return (
                String(groupSource.id) === String(selectedDataSource.id) &&
                groupSource.members.type === 'VNetworkAsset'
              )
            }
          )

          if (selectedGroupSource !== undefined) {
            carry.push(...selectedGroupSource.members.data)
          }

          return carry
        }, [])
      } else {
        return []
      }
    }
  )

  const selectGroupDevices = createSelector(
    [get('groupSourcesResult'), selectSelectedGroups],
    (groupSourcesResult, selectedGroups) => {
      if (groupSourcesResult.wasSuccessful()) {
        return selectedGroups.reduce((carry, selectedDataSource) => {
          const selectedGroupSource = groupSourcesResult.data.data.find(
            (groupSource) => {
              return (
                String(groupSource.id) === String(selectedDataSource.id) &&
                groupSource.members.type === 'VDevice'
              )
            }
          )

          if (selectedGroupSource !== undefined) {
            carry.push(...selectedGroupSource.members.data)
          }

          return carry
        }, [])
      } else {
        return []
      }
    }
  )

  const selectPartitionedSelectedSourceIds = createSelector(
    [selectSelectedAssetsAndDevices, selectGroupAssets, selectGroupDevices],
    (selectedAssetsAndDevices, selectedGroupAssets, selectedGroupDevices) => {
      const [selectedAssetsIds, selectedDevicesIds] = partition(
        ({ __typename }) => __typename === 'NetworkAsset'
      )(selectedAssetsAndDevices)

      const selectedAssetsIdsAndGroupAssetsIds = selectedAssetsIds
        .map(({ id }) => id)
        .concat(selectedGroupAssets.map(({ id }) => id))

      const selectedDeviceIdsAndGroupDevicesIds = selectedDevicesIds
        .map(({ id }) => id)
        .concat(selectedGroupDevices.map(({ id }) => id))

      return {
        selectedAssetsIds: selectedAssetsIdsAndGroupAssetsIds,
        selectedDevicesIds: selectedDeviceIdsAndGroupDevicesIds,
      }
    }
  )

  const selectDataSourcesResult = createSelectGraphQLResult(
    'dataSourcesWithSelectedSources',
    {
      mapResult: parseGraphQLResult,
      bufferData: true,
      nullObject: [],
    }
  )

  return function mapStateToProps(state, ownProps) {
    return {
      searchQuery: selectSearchQuery(state, ownProps),
      dataSourceType: selectDataSourceType(state, ownProps),
      selectPressureEventsQueryVariables,
      selectPagedPressureEventsResult,
      eventsStartDate,
      selectedAssetsAndDevices: selectSelectedAssetsAndDevices(ownProps),
      selectedGroupAssets: selectGroupAssets(ownProps),
      selectedGroupDevices: selectGroupDevices(ownProps),
      selectDataSourcesResult,
      selectPartitionedSelectedSourceIds,
    }
  }
}

const DataSourcesWithSelectedSourcesQuery = gql`
  query DataSourcesWithSelectedSources(
    $dataSourceType: String
    $searchQuery: String
    $selectedAssetsIds: [Int]
    $selectedDevicesIds: [Int]
  ) {
    dataSourcesWithSelectedSources(
      dataSourceType: $dataSourceType
      searchQuery: $searchQuery
      selectedAssetsIds: $selectedAssetsIds
      selectedDevicesIds: $selectedDevicesIds
    ) {
      ... on Device {
        id
        serialNumber
        lastCommunication
        commissionStatus
        currentCommission {
          start
          location {
            latitude
            longitude
          }
        }
        activeIssues {
          id
          type
          severity
          description
          deviceId
        }
      }

      ... on NetworkAsset {
        id
        assetId
        assetName
        assetType
        location {
          altitude
          latitude
          longitude
        }
        installations {
          end
          start
          channelMap {
            pressure_1
          }
          deviceId
          device {
            id
            currentCommission {
              start
              end
              location {
                altitude
                latitude
                longitude
              }
            }
            activeIssues {
              id
              type
              severity
              description
              deviceId
            }
          }
        }
      }
    }
  }
`

const PaginatedEventsQuery = gql`
  query PagedPressureEvents(
    $end: String!,
    $start: String!,
    $pageNumber: Int!,
    $networkAssetChannels: [NetworkAssetChannel],
  ) {
    pagedPressureEvents(
      end: $end,
      start: $start,
      pageNumber: $pageNumber,
      resultsPerPage: ${PRESSURE_EVENT_LIMIT},
      networkAssetChannels: $networkAssetChannels,
    ) {
      totalPages
      totalResults
      pressureEvents {
        assetId
        endTime
        startTime
        eventCharacteristics {
          id
          value
          eventCharacteristic
        }
      }
    }
  }
`

export default compose(
  requiresLogin,
  userPermissions,
  withTranslation(['src/analysis_path/index_page', 'common/text']),
  transformProps(() => ({ t }) => ({
    title: t('headings.map_page_title'),
  })),
  withDataSourceSelectionLayout,
  withGoogleMapsProvider,
  connect(createMapStateToProps),
  graphql(DataSourcesWithSelectedSourcesQuery, {
    options: ({
      searchQuery,
      dataSourceType,
      selectPartitionedSelectedSourceIds,
      ...rest
    }) => {
      return {
        fetchPolicy: 'network-only',
        variables: {
          searchQuery,
          dataSourceType,
          ...selectPartitionedSelectedSourceIds(rest),
        },
      }
    },
    props: ({ data, ownProps: { selectDataSourcesResult } }) => {
      return {
        dataSourcesResult: selectDataSourcesResult(data),
      }
    },
  }),
  graphql(PaginatedEventsQuery, {
    options: ({ selectPressureEventsQueryVariables, ...rest }) => {
      return {
        variables: selectPressureEventsQueryVariables(rest),
      }
    },
    props: ({ ownProps, data }) => {
      const { selectPagedPressureEventsResult } = ownProps

      const pagedPressureEventsResult = selectPagedPressureEventsResult(data)

      return {
        fetchMoreEvents: data.fetchMore,
        pagedPressureEventsResult,
      }
    },
  })
)(AnalysisMap)
