import config from '../../../../../config'
import { AuthorizedUser } from '../../../../models/authorized-user'

export class AbstractIdentityProvider {

  /**
   * Retrieves the token of the current user.
   * @abstract
   * @throws {Error} This method must be implemented by a subclass.
   * @returns {Promise<string>} The token of the current user.
   */
  async getCurrentUserToken() {
    this._throwUnimplementedMethodError('getCurrentUserToken')
  }

  /**
   * Retrieves the current user details.
   * @abstract
   * @throws {Error} This method must be implemented by a subclass.
   * @returns {Promise<AuthorizedUser>} The current user details.
   */
  async getCurrentUser() {
    this._throwUnimplementedMethodError('getCurrentUser')
  }

  /**
   * Handles the logout process for the current user.
   * @abstract
   * @throws {Error} This method must be implemented by a subclass.
   * @returns {Promise<void>} A promise that resolves when the logout process is complete.
   */
  async signOut() {
    this._throwUnimplementedMethodError('signOut')
  }

  /**
   * Retrieves the accepted End User License Agreement (EULA) version.
   * @abstract
   * @throws {Error} This method must be implemented by a subclass.
   * @returns {Promise<string>} The version of the accepted EULA.
   */
  async getAcceptedEulaVersion() {
    this._throwUnimplementedMethodError('getAcceptedEulaVersion')
  }

  /**
   * Sets the accepted End User License Agreement (EULA) version.
   * @abstract
   * @param {string} version - The EULA version to set as accepted.
   * @throws {Error} This method must be implemented by a subclass.
   * @returns {Promise<void>} A promise that resolves when the EULA version is set.
   */
  async setAcceptedEulaVersion(version) {
    this._throwUnimplementedMethodError('setAcceptedEulaVersion')
  }

  /**
   * Throws an error indicating that the method is not implemented.
   * @param {string} method - The name of the method that is not implemented.
   * @throws {Error} Unimplemented method error.
   * @returns {void}
   * @protected
   */
  _throwUnimplementedMethodError(method) {
    throw new Error(`Unimplemented method ${this.constructor.name}.${method}()`)
  }

  async _getUserDetailsFromBff(token) {
    const url = `${config.apiUrl}/v1/rest/${config.tenant}/users/current`
    const response = await fetch(url, { headers: new Headers({ Authorization: `Bearer ${token}` }) })

    if (!String(response.status).match(/2\d\d/)) {
      return null
    }

    const userInfo = await response.json()
    const { data, role } = userInfo

    return new AuthorizedUser({
      idpUserId: data.idpUserId,
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      role: role.name,
      permissions: role.permissions || [],
      settings: data.settings,
    })
  }
}
