import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { sumBy } from 'lodash/fp/math'
import { compose } from 'redux'
import { flow, noop } from 'lodash/fp/util'
import { get, toPairs } from 'lodash/fp/object'
import { createSelector } from 'reselect'
import { map, flatMap, groupBy } from 'lodash/fp/collection'
import { scaleLinear, scaleTime } from 'd3-scale'

import Graph from '@@src/components/graphs/graph'
import LeftAxis from '@@src/components/graphs/left_axis'
import GraphItem from '@@src/components/graphs/graph_item'
import AsyncResult from '@@src/utils/async_result'
import LinePlotsLayer from '../line_plots_layer'
import DragEventLayer, { ONLY_X }
from '@@src/components/graphs/drag_layer/drag_event_layer'
import CpisViewToolbar from './cpis_view_toolbar'
import DragAreaOverlay
from '@@src/components/graphs/drag_layer/drag_area_overlay'
import GraphStateOverlay from '@@src/components/graphs/graph_state_overlay'
import CpisViewAlertGroup from './cpis_view_alert_group'
import GraphPlotMeasurementRuler from '@@src/components/graphs/measurement_ruler/graph_plot_measurement_ruler'
import BottomTimeAxis from '@@src/components/graphs/bottom_time_axis'
import DragControlsLayer from '@@src/components/graphs/drag_controls_layer'
import cpisDataContainer from './cpis_data_container'
import PressureSubsetData from '@@src/api/presenters/pressure_subset_data'
import {
  DEFAULT_TIMEZONE, AVAILABLE_TIME_ZONES, DAYS,
  globalSequence,
} from '@@src/utils'

import styles from './cpis_view.css'
import { renderAbsolutePositionedLoading } from '../../../components/app_suspense_container'

class CpisView extends React.PureComponent {
  static defaultProps = {
    timezone: DEFAULT_TIMEZONE,
    onAxesChange: noop,
    onResetZoom: noop,
    displayCursorTool: true,
  }

  static propTypes = {
    timezone: PropTypes.oneOf(AVAILABLE_TIME_ZONES).isRequired,
    onAxesChange: PropTypes.func.isRequired,
    endTime: PropTypes.instanceOf(Date).isRequired,
    startTime: PropTypes.instanceOf(Date).isRequired,
    onResetZoom: PropTypes.func.isRequired,
    legendItemsResult:
      PropTypes.instanceOf(AsyncResult).isRequired,
    displayCursorTool: PropTypes.bool.isRequired,
    setFocusedLegendItem: PropTypes.func.isRequired,
  }

  state = {
    showCursorPos: false,
    xAxes: null,
  }

  id = globalSequence.next()

  render() {
    const {
      className, onResetZoom, cpisDataResult, timezone, displayCursorTool,
      setFocusedLegendItem,
    } = this.props

    if (!cpisDataResult) {
      return renderAbsolutePositionedLoading()
    }

    const graphItems = this.selectGraphItems(this.props)

    return (
      <GraphStateOverlay className={classnames(styles.container, className)}>
        <CpisViewToolbar
          className={styles.toolbar}
          cpisDataResult={cpisDataResult} />

        <CpisViewAlertGroup
          className={styles['alert-group']}
          cpisDataResult={cpisDataResult} />

        <Graph
          xScale={this.selectXScale(this.props, this.state)}
          yScale={this.selectYScale(this.props)}
          className={styles.graph}
          topPadding={50}
          leftPadding={70}
          rightPadding={50}
          bottomPadding={50}>
          <BottomTimeAxis timezone={timezone} />

          <LeftAxis withGuides label={(
            <text>CPIS</text>
          )} />

          <LinePlotsLayer graphItems={graphItems} />

          <DragEventLayer
            dragBehaviour={ONLY_X}
            onDoubleClick={onResetZoom}>
            {
              displayCursorTool ? (
                <GraphPlotMeasurementRuler
                  setFocusedLegendItem={setFocusedLegendItem}
                  dataUnits={''}
                  getX={get('x')}
                  getYComparable={get('y')}
                  timezone={timezone}
                  dataColumns={[['CPIS', get('y')]]}
                  graphItems={graphItems} />
              ) : null
            }

            <DragAreaOverlay />

            <DragControlsLayer
              onPan={this.onGraphPan}
              onAxesChange={this.onAxesChange}
              dragBehaviour={ONLY_X}
              refetchRawDataRequests={noop} />
          </DragEventLayer>
        </Graph>
      </GraphStateOverlay>
    )
  }

  onGraphPan = ({ panEventX }) => {
    if (panEventX) {
      this.setState({ xAxes: panEventX })
    }
  }

  onAxesChange = ({ xAxes, yAxes }) => {
    if (xAxes || yAxes) {
      this.props.onAxesChange({ xAxes, yAxes })
    }

    this.setState({ xAxes: null })
  }

  onGraphPanEnd = ({ panEventX, panEventY }) => {
    if (panEventX || panEventY) {
      this.props.onAxesChange({ xAxes: panEventX, yAxes: panEventY })

      this.setState({ xAxes: null })
    }
  }

  selectGraphItems = createSelector(
    [get('cpisDataResult'), get('legendItemsResult')],
    ({ data: cpisData }, { data: legendItems }) => {
      return cpisData
        .map(cpisDataChunks => {
          const legendItem = legendItems.find(item => {
            return item.isFromNetworkAsset() &&
              cpisDataChunks.networkAssetId === item.sourceId &&
              cpisDataChunks.logicalChannel === item.sourceChannel
          })

          if (!legendItem) {
            return null
          }

          const dailyCpis = flow(
            flatMap('data'),
            groupBy(d => {
              const time = new Date(Number(d.time))
              time.setHours(0)
              time.setMinutes(0)
              time.setSeconds(0)
              time.setMilliseconds(0)

              return time.getTime()
            }),
            toPairs,
            map(([time, dataInADay]) => {
              return { x: Number(time), y: sumBy('cpis')(dataInADay) }
            })
          )(cpisDataChunks.chunkedData)

          return GraphItem.fromLegendItemAndSubsetData(
            legendItem,
            PressureSubsetData.from({
              data: dailyCpis,
              channel: legendItem.sourceChannel,
              resolution: 1 * DAYS,
              networkAssetId: legendItem.sourceId,
            }),
          )
        })
        .filter(chunk => !!chunk)
    },
  )

  selectXScale = createSelector(
    [get('startTime'), get('endTime'), (_props, { xAxes }) => xAxes],
    (startTime, endTime, xAxes) => xAxes ?
      scaleTime().domain([xAxes.start, xAxes.end]) :
      scaleTime().domain([startTime, endTime])
  )

  selectYScale = createSelector(
    [this.selectGraphItems],
    graphItems => {
      const cpisValues = flow(
        flatMap('dataSegments'),
        flatMap('data'),
        flatMap('y')
      )(graphItems)

      if (cpisValues.length === 0) {
        return scaleLinear().domain([0, 1000])
      }

      const maxCpis = Math.max(...cpisValues)
      const minCpis = 1.05 * Math.min(0, ...cpisValues)

      return scaleLinear().domain([minCpis || 0, 1.05 * maxCpis || 1000])
    }
  )
}

export default compose(
  cpisDataContainer,
)(CpisView)
