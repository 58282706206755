import { EXPORT_RAW_DATA_FEATURE } from
'@@src/components/tenant_licence/tenant_licence_feature_gate'
import DeviceIssue from '../../api/presenters/device_issue'
import { LITERAL_FILTERS } from '../alerts/active_filters_alert'
import { NOT_INSTALLED } from '@@src/components/modals/device_state_filters'
import { DEVICE, NETWORK_ASSET, GROUP }
from '@@src/components/modals/group_type_filters'
import * as analytics from '@@src/analytics'
import { allValidDedupedAssetTypes, validAssetInstallationTypes } from '../../utils'
import { filterDeviceIssuesFilters } from '../modals/device_issue_filters'

export const ANY_FILTER_OPTION = 'any'
export const HYDRANT_FILTER_OPTION = 'hydrant'
export const PRV_FILTER_OPTION = 'prv'
export const EOV_FILTER_OPTION = 'eov'
export const PSV_FILTER_OPTION = 'psv'
export const PUMP_FILTER_OPTION = 'pump'
export const BOUNDARY_VALVE_FILTER_OPTION = 'boundary_valve'
export const FLOW_CONTROL_VALVE_FILTER_OPTION = 'flow_control_valve'
export const ISOLATION_VALVE_FILTER_OPTION = 'isolation_valve'
export const PIPE_FILTER_OPTION = 'pipe'
export const TANK_FILTER_OPTION = 'tank'
export const RESERVOIR_FILTER_OPTION = 'reservoir'
export const METER_FILTER_OPTION = 'meter'
export const TAPPING_FILTER_OPTION = 'tapping'
export const AIR_VALVE_FILTER_OPTION = 'air_valve'
export const WASH_OUT_OPTION = 'congruity_wash_out'

export const WITH_PRESSURE_DATA = 'with_pressure_data'
export const WITHOUT_PRESSURE_DATA = 'without_pressure_data'
export const RECENT_PRESSURE_DATA = 'recent_pressure_data'

export const PRESSURE_DATA_FILTERS = [
  WITH_PRESSURE_DATA,
  WITHOUT_PRESSURE_DATA,
  RECENT_PRESSURE_DATA,
]

export function seperateSelectedFiltersIntoGroups(filters = []) {
  const assetInstallationTypes = validAssetInstallationTypes()
  const assetTypeFilters = allValidDedupedAssetTypes()
    .filter((el) => !assetInstallationTypes.includes(el))
    .filter((el) => filters.includes(el))
  const deviceIssuesFilters = filterDeviceIssuesFilters(filters)

  return {
    assetTypeIn: assetTypeFilters,
    issueTypeIn: deviceIssuesFilters,
    installations: assetInstallationTypes.filter((el) => filters.includes(el)),
    issueSeverityIn: [...DeviceIssue.ISSUE_SEVERITY_FILTERS].filter((el) => filters.includes(el)),
  }
}

export const sortList = [
  {
    value: 'name',
    text: 'options.name',
  },
  {
    value: 'state',
    text: 'options.state',
  },
  {
    value: 'type',
    text: 'options.type',
  },
  {
    value: 'communicationTime',
    text: 'options.communication',
  },
  {
    value: 'installationTime',
    text: 'options.installation',
  },
  {
    value: 'activeIssueTime',
    text: 'common/text:options.latest_issues',
  },
  {
    value: 'activeIssueTypeSeverity',
    text: 'common/text:options.issue_type_severity',
  },
  {
    value: 'assetId',
    text: 'options.assetId',
  },
]

export function getFiltersForLicenceFeatures(tenantLicenceFeatures, t = (text) => text) {
  return tenantLicenceFeatures.includes(EXPORT_RAW_DATA_FEATURE) ?
    getLicensedFilterList(t)
    :
    getUnlicensedFilterList(t)
}

function getUnlicensedFilterList(t = (text) => text) {
  return [
    getAnyFilterListOption(t),
    {
      value: EOV_FILTER_OPTION,
      text: t('common/text:text.eov'),
    },
    {
      value: PSV_FILTER_OPTION,
      text: t('common/text:text.psv'),
    },
    {
      value: BOUNDARY_VALVE_FILTER_OPTION,
      text: t('common/text:text.boundary_valve'),
    },
    {
      value: FLOW_CONTROL_VALVE_FILTER_OPTION,
      text: t('common/text:text.flow_control_valve'),
    },
    {
      value: ISOLATION_VALVE_FILTER_OPTION,
      text: t('common/text:text.isolation_valve'),
    },
    {
      value: PIPE_FILTER_OPTION,
      text: t('common/text:text.pipe'),
    },
    {
      value: TANK_FILTER_OPTION,
      text: t('common/text:text.tank'),
    },
    {
      value: RESERVOIR_FILTER_OPTION,
      text: t('common/text:text.reservoir'),
    },
    {
      value: METER_FILTER_OPTION,
      text: t('common/text:text.meter'),
    },
    {
      value: AIR_VALVE_FILTER_OPTION,
      text: t('common/text:text.air_valve'),
    },
    ...getCommonFilterList(t),
  ]
}

function getLicensedFilterList(t = (text) => text) {
  return [
    getAnyFilterListOption(t),
    {
      value: WASH_OUT_OPTION,
      text: t('common/text:text.wash_out'),
    },
    ...getCommonFilterList(t),
  ]
}

function getAnyFilterListOption(t) {
  return {
    value: ANY_FILTER_OPTION,
    text: t('options.any'),
  }
}

function getCommonFilterList(t) {
  return [
    {
      value: HYDRANT_FILTER_OPTION,
      text: t('common/text:text.hydrant'),
    },
    {
      value: PRV_FILTER_OPTION,
      text: t('common/text:text.prv'),
    },
    {
      value: PUMP_FILTER_OPTION,
      text: t('common/text:text.pump'),
    },
    {
      value: TAPPING_FILTER_OPTION,
      text: t('common/text:text.tapping'),
    },
    {
      value: DeviceIssue.ERROR_SEVERITY_FILTER,
      text: t('common/text:options.with_errors'),
    },
    {
      value: DeviceIssue.WARNING_SEVERITY_FILTER,
      text: t('common/text:options.with_warnings'),
    },
    {
      value: DeviceIssue.INFORMATION_SEVERITY_FILTER,
      text: t('common/text:options.with_information'),
    },
  ]
}

export function getFormattedActiveFilters(activeFilters, t = (text) => text) {
  if (!Array.isArray(activeFilters) || activeFilters.length === 0) {
    return ''
  } else {
    return activeFilters.map(getFilterKey)
      .map(f => `"${t(f)}"`).filter(f => f).join(', ')
  }
}

export function getFilterKey(filter) {
  if (LITERAL_FILTERS.includes(filter)) {
    return `filters.${filter}`
  }

  switch (filter) {
    case NOT_INSTALLED:
      return 'filters.not_installed'
    case DeviceIssue.ERROR_SEVERITY_FILTER:
      return 'filters.with_errors'
    case DeviceIssue.WARNING_SEVERITY_FILTER:
      return 'filters.with_warnings'
    case DeviceIssue.INFORMATION_SEVERITY_FILTER:
      return 'filters.with_information'
    case NETWORK_ASSET:
      return 'filters.asset'
    case DEVICE:
      return 'filters.device'
    case GROUP:
      return 'filters.group'
    default:
      analytics.logError(`unknown active filter: ${filter}`)
      return ''
  }
}
