import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Col, Row } from 'reactstrap'
import { validAssetTypes } from '../../../../../../../../utils'
import { useTranslation } from 'react-i18next'
import useGetCurrentDeviceCommission from '../../../../hooks/use_get_current_device_commission'
import NetworkAssetsMapComponent from '../../../../../network_assets/components/network_assets_map.component'
import SearchField from '../../../../../../../components/inputs/search_field/search_field.component'
import InstallOnExistingAssetList from './install_on_existing_asset_list.component'
import GeoCoordinate from '../../../../../../../../utils/graphs/geo_coordinate'
import { DevicesForListItem } from '../../../../../../../api/models/devices_for_list_item'
import SelectInput from '../../../../../../../components/inputs/select_input/select_input.component'

import PropTypes from 'prop-types'

const InstallOnExistingAssetTab = ({
  device,
  isInstalling = false,
  installOnExistingNetworkAsset = () => null,
}) => {
  const { t } = useTranslation(['common/text', 'v2/network_assets/forms'])
  const mapRef = useRef(null)

  const [filters, setFilters] = useState({
    search: '',
    filters: ['at_least_one_empty_point'],
  })
  const [currentMapPosition, setCurrentMapPosition] = useState({ bounds: null })
  const [selectedListItem, setSelectedListItem] = useState(null)
  const [currentCommission, setCurrentCommission] = useState(null)
  const getCurrentDeviceCommission = useGetCurrentDeviceCommission()

  useEffect(() => {
    device &&
      getCurrentDeviceCommission(device.deviceId).then((result) => {
        setCurrentCommission(result)
      })
  }, [device])

  useEffect(() => {
    setCurrentMapPosition({ bounds: null })
  }, [filters])

  const handleMarkerClick = (networkAsset) => {
    setSelectedListItem(networkAsset.assetId)
  }

  const handleChangeMapPosition = (center, name) => {
    if (!mapRef.current) {
      return
    }
    mapRef.current.leafletElement.flyTo(center.toLatLng(), 23, {
      animate: false,
    })
    setTimeout(() => {
      mapRef.current.leafletElement.eachLayer((layer) => {
        if (layer.options && layer.options.name === name) {
          layer.openPopup()
          return
        } else if (
          layer.options &&
          layer.options.children &&
          layer.options.children.length > 0
        ) {
          const markers = layer._topClusterLevel.getAllChildMarkers()
          markers.forEach((child) => {
            if (child.options.name === name) {
              layer._topClusterLevel.spiderfy()
              child.openPopup()
              return
            }
          })
        }
      })
    }, 200)
  }

  const handleListItemClick = (networkAsset) => {
    setSelectedListItem((oldAssetId) =>
      oldAssetId === networkAsset.assetId ? null : networkAsset.assetId
    )
    const { lat, lng } = networkAsset
    handleChangeMapPosition(
      new GeoCoordinate(lat, lng),
      `asset-marker-${networkAsset.assetId}`
    )
  }

  const handleSearchChange = useCallback(
    (newSearch) => {
      if (filters.search !== newSearch) {
        setFilters((oldFilters) => {
          return { ...oldFilters, search: newSearch }
        })
      }
    },
    [filters, setFilters]
  )

  const handleFilterChange = useCallback(
    (newFilter) => {
      setFilters((oldFilters) => {
        return {
          ...oldFilters,
          filters: [newFilter, 'at_least_one_empty_point'],
        }
      })
    },
    [filters, setFilters]
  )

  return (
    <Row className="h-100 p-0 m-0">
      <Col className="list-column w-50">
        <div className="d-flex flex-column h-100 overflow-hidden col p-0 m-0">
          <Row className="justify-content-between p-2 m-0">
            <h4>
              {t(
                'v2/network_assets/forms:install_device_modal.select_existing_tab.available_assets'
              )}
            </h4>
            <div className="d-flex flex-row">
              <SearchField
                onChange={handleSearchChange}
                placeholder={t(
                  'v2/network_assets/forms:install_device_modal.select_existing_tab.search_for_assets_placeholder'
                )}
                className={'mr-2'}
              />
              <SelectInput
                t={(option) => t(`common/text:text.${option}`)}
                choiceOptions={['any', ...validAssetTypes()]}
                inputProps={{
                  id: 'assetType',
                  type: 'select',
                  className: 'w-auto mr-2',
                  required: true,
                  onChange: (e) => handleFilterChange(e.target.value),
                }}
              />
            </div>
          </Row>
          <InstallOnExistingAssetList
            listItemClick={handleListItemClick}
            filters={filters}
            currentCommission={currentCommission}
            selectedListItem={selectedListItem && selectedListItem}
            isInstalling={isInstalling}
            createInstallation={installOnExistingNetworkAsset}
          />
        </div>
      </Col>
      <Col className="w-50">
        <NetworkAssetsMapComponent
          externalMapRef={mapRef}
          filters={filters}
          onMarkerClick={handleMarkerClick}
          currentMapPosition={currentMapPosition}
          onPositionUpdate={setCurrentMapPosition}
        />
      </Col>
    </Row>
  )
}

InstallOnExistingAssetTab.propTypes = {
  device: PropTypes.instanceOf(DevicesForListItem),
  installOnExistingNetworkAsset: PropTypes.func,
  isInstalling: PropTypes.bool,
}

export default InstallOnExistingAssetTab
