import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import styles from './report_download_icon.css'

export default class ReportDownloadIcon extends React.PureComponent {
  static propTypes = {
    report: PropTypes.shape({
      reportType: PropTypes.string.isRequired,
      reportStatus: PropTypes.string.isRequired,
    }).isRequired,
    className: PropTypes.string,
  }

  render() {
    const { className, report } = this.props

    return (
      <div className={classnames({
        [styles['container-no-data']]: report.reportStatus === 'noData',
        [styles['container-data']]: report.reportStatus !== 'noData',
      }, className)}>
        {reportTypeIconFor(report.reportType || 'csv')}
        {reportStatusIconFor(report.reportStatus)}
      </div>
    )
  }
}

function reportTypeIconFor(reportType) {
  switch (reportType) {
    case 'csv':
      return (
        <React.Fragment>
          <i className="far fa-file fa-stack-2x" />
          <i className={styles['report-type-csv-icon-extra']} />
        </React.Fragment>
      )

    default:
      return (
        <i className="far fa-file fa-stack-2x"></i>
      )
  }
}

function reportStatusIconFor(reportStatus) {
  switch (reportStatus) {
    case 'new':
    case 'inProgress':
      return (
        <i className={styles['report-status-icon-in-progress']}></i>
      )

    case 'complete':
      return (
        <i className={styles['report-status-icon-success']}></i>
      )

    case 'noData':
      return(
        <i className={styles['report-status-icon-no-data']} />
      )

    default:
      return (
        <i className={styles['report-status-icon-error-other']}></i>
      )
  }
}
