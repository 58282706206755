import {
  PRESSURE_DATA_FILTERS,
  RECENT_PRESSURE_DATA,
  WITHOUT_PRESSURE_DATA,
  WITH_PRESSURE_DATA,
} from '../../../../../components/lists/utils'
import {
  ADVANCED_ERROR_FILTERS, ADVANCED_INFORMATION_FILTERS,
  ADVANCED_WARNING_FILTERS,
} from '../../../../../components/modals/device_issue_filters'
import {
  mapCongruityAssetType, validAssetInstallationTypes,
  validAssetTypes, validCongruityAssetTypes,
} from '../../../../../utils'

export const VALID_ASSET_TYPE_FILTERS = [...new Set([
  ...validCongruityAssetTypes().map(type => mapCongruityAssetType(type)),
  ...validAssetTypes(),
]).values()].sort()
export const VALID_ASSET_TYPE_FILTERS_WITH_PRESSURE_DATA_FILTERS = [
  ...VALID_ASSET_TYPE_FILTERS,
  ...PRESSURE_DATA_FILTERS,
].sort()
export const VALID_ASSET_INSTALLATION_FILTERS = validAssetInstallationTypes()
export const VALID_ASSET_TYPE_ISSUE_FILTERS = [
  ...ADVANCED_ERROR_FILTERS,
  ...ADVANCED_WARNING_FILTERS,
  ...ADVANCED_INFORMATION_FILTERS,
].sort()
export const VALID_ASSET_TYPE_SEVERITY_FILTERS = [
  'withErrors',
  'withWarnings',
  'withInformation',
]

export const isFilterAdvanced = (filter) => {
  return VALID_ASSET_TYPE_FILTERS
    .concat(VALID_ASSET_INSTALLATION_FILTERS)
    .concat(VALID_ASSET_TYPE_ISSUE_FILTERS)
    .includes(filter)
}

/**
 * @param {string[]} flattenedFilters flattened filters from url
 * @returns {{assetTypeIn:string[],issueTypeIn:string[],installations:string[],issueSeverityIn:string[]}} filters object
 */
export const mapFlattenedFiltersToQueryObject = (flattenedFilters) => {
  const assetTypeIn = flattenedFilters.filter(filter =>VALID_ASSET_TYPE_FILTERS.includes(filter))
  const issueTypeIn = flattenedFilters.filter(filter => VALID_ASSET_TYPE_ISSUE_FILTERS.includes(filter))
  const installations = flattenedFilters.filter(filter => VALID_ASSET_INSTALLATION_FILTERS.includes(filter))
  const issueSeverityIn = flattenedFilters.filter(filter => VALID_ASSET_TYPE_SEVERITY_FILTERS.includes(filter))

  const queryObject = {
    assetTypeIn,
    issueTypeIn,
    installations,
    issueSeverityIn,
  }

  const pressureDataType = flattenedFilters.filter(filter => PRESSURE_DATA_FILTERS.includes(filter))
  switch(pressureDataType.length > 0 && pressureDataType[0]) {
    case WITH_PRESSURE_DATA: {
      queryObject.hasPressureData = true
      break
    }
    case WITHOUT_PRESSURE_DATA: {
      queryObject.hasPressureData = false
      break
    }
    case RECENT_PRESSURE_DATA: {
      const todayDate = new Date()
      todayDate.setDate(todayDate.getDate() - 1)
      queryObject.hasPressureData = true
      queryObject.lastRecordedDataTimeGreaterThanOrEqual = todayDate.toISOString()
      break
    }
    default: queryObject.hasPressureData = null
  }

  return queryObject
}
