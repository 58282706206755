import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useApolloClient } from '@apollo/client'
import { GROUP_SUGGESTIONS_QUERY } from '../../../../api/queries/group/get_group_suggestions.graphql'
import { debounce } from 'lodash/function'

export const useGetGroupSuggestions = (querySearch = '') => {
  const client = useApolloClient()
  const abortController = useRef(null)

  const [loading, setLoading] = useState(false)
  const [groupSuggestions, setGroupSuggestions] = useState([])
  const [groupSuggestionsCount, setGroupSuggestionsCount] = useState([])

  const fetchData = useCallback((search) => {
    if (abortController.current) {
      abortController.current.abort()
    }

    abortController.current = new AbortController()
    setLoading(true)
    setGroupSuggestions([])
    setGroupSuggestionsCount(0)
    client
      .query({
        query: GROUP_SUGGESTIONS_QUERY,
        variables: {
          search,
        },
        fetchPolicy: 'network-only',
        context: { fetchOptions: { signal: abortController.signal } },
      })
      .then(result => {
        const {
          groups,
          pagination: { totalResults },
        } = result && result.data && result.data.getGroups || { pagination: {} }
        setGroupSuggestions(groups)
        setGroupSuggestionsCount(totalResults)
        setLoading(false)
      }).catch(_error => {
        setLoading(false)
        setGroupSuggestions([])
        setGroupSuggestionsCount(0)
      })
  }, [abortController, setLoading, setGroupSuggestions, setGroupSuggestionsCount, client])

  const handleDebounceSearchChange = useMemo(() => debounce(fetchData, 400), [fetchData])

  useEffect(() => {
    handleDebounceSearchChange(querySearch)
  }, [querySearch, client])

  return { groupSuggestions, count: groupSuggestionsCount, loading }
}
