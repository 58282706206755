import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { UncontrolledTooltip } from 'reactstrap'
import { NetworkAssetForListItemInstalledDevice } from '../../api/models/network_asset_for_list_item'
import DevicesIconComponent from '../devices/devices_icon.component'

const NetworkAssetInstallationIconComponent = ({ id, logicalChannel, device, style }) => {
  const { t } = useTranslation(['v2/network_assets/common', 'v2/common/text'])
  return (
    <span style={style} id={id}>
      <DevicesIconComponent type={device ? device.getTableIconName() : 'icon-decommissioned'} />
      <UncontrolledTooltip target={id} placement="top">
        {!!device && (<>
          <span className="d-block text-left">
            <strong>{`${t(`installation.logical_channel.${logicalChannel}`)}: `}</strong>
            {device.serialNumber}
          </span>
          <span className="d-block text-left">
            <strong>{`${t('v2/common/text:pressure_data_freshness')}: `}</strong>
            {t(`v2/common/text:pressure_data_freshness_options.${device.getPressureDataFreshnessKey()}`)}
          </span>
        </>)}
        {!device && (<>
          <span className="d-block">
            <strong>{`${t(`installation.logical_channel.${logicalChannel}`)}: `}</strong>
            {t('installation.not_installed')}
          </span>
        </>)}
      </UncontrolledTooltip>
    </span>
  )
}

NetworkAssetInstallationIconComponent.propTypes = {
  id: PropTypes.string.isRequired,
  logicalChannel: PropTypes.string.isRequired,
  device: PropTypes.instanceOf(NetworkAssetForListItemInstalledDevice),
  style: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

export default memo(NetworkAssetInstallationIconComponent)
