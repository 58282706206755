import React from 'react'
import PropTypes from 'prop-types'
import { difference } from 'lodash/fp/array'
import { withTranslation } from 'react-i18next'
import { ButtonDropdown, DropdownToggle, DropdownMenu }
from 'reactstrap'

import AppDropdownItem from './app_dropdown_item'

import styles from './app_filter_dropdown.css'

const noop = () => { }

const AppFilterDropdownContext = React.createContext()

const AppFilterDropdownItemContainer = ({ value, ...rest }) => (
  <AppFilterDropdownContext.Consumer>
    {({ selection, onChangeSelection }) => (
      <AppFilterDropdownItem
        selection={selection}
        onChangeSelection={onChangeSelection}
        value={value}
        {...rest}/>
    )}
  </AppFilterDropdownContext.Consumer>
)

class AppFilterDropdownItem extends React.PureComponent {
  static defaultProps = {
    selection: [],
  }

  static propTypes = {
    value: PropTypes.any.isRequired,
    selection: PropTypes.array.isRequired,
  }

  render() {
    const { value, children, selection, ...rest } = this.props

    return (
      <AppDropdownItem
        value={value}
        active={selection.includes(value)}
        onClick={this.onClick}
        {...rest}>
        {children}
      </AppDropdownItem>
    )
  }

  onClick = event => {
    const { value, selection, onChangeSelection, onClick } = this.props

    const newSelection = selection.includes(value) ?
      difference(selection, [value]) : selection.concat(value)

    if (onChangeSelection) {
      onChangeSelection(newSelection, value)
    }

    if (onClick) {
      onClick(event)
    }
  }
}

class AppFilterDropdown extends React.PureComponent {
  static Item = AppFilterDropdownItemContainer

  static propTypes = {
    onToggle: PropTypes.func.isRequired,
    advancedOption: PropTypes.shape({
      partial: PropTypes.bool,
      onClick: PropTypes.func.isRequired,
    }),
    onClearFilters: PropTypes.func.isRequired,
    dropdownToggleClassname: PropTypes.string,
    dropdownButtonClassname: PropTypes.string,
    dropdownMenuClassname: PropTypes.string,
    labelText: PropTypes.string,
    clearFiltersButton: PropTypes.bool,
  }

  static defaultProps = {
    onToggle: noop,
    onClearFilters: noop,
  }

  render() {
    const { t, children,
      advancedOption,
      clearFiltersButton,
      dropdownToggleClassname,
      dropdownButtonClassname,
      dropdownMenuClassname,
      labelText,
    } = this.props
    const { isOpen } = this.state

    return (
      <AppFilterDropdownContext.Provider value={this.props}>
        <ButtonDropdown
          className={dropdownButtonClassname}
          isOpen={isOpen}
          toggle={this.onToggle}
        >
          <DropdownToggle className={dropdownToggleClassname} caret>
            {labelText
              ? labelText
              : t('labels.filter')}
          </DropdownToggle>

          <DropdownMenu className={dropdownMenuClassname}>
            {
              (children || []).length > 1 && clearFiltersButton ? (
                <React.Fragment>
                  <AppDropdownItem
                    name="clear-filters-button"
                    value="filter_dropdown_clear_filters"
                    onClick={this.onClickClearFilters}>
                    {t('labels.clear_filters')}
                  </AppDropdownItem>
                </React.Fragment>
              ) : null
            }

            {children}

            {
              advancedOption ? (
                <AppDropdownItem
                  name="advanced-options-button"
                  partial={advancedOption.partial}
                  onClick={advancedOption.onClick}
                  className={styles['advanced-options-item']}>
                  {t('labels.advanced')}
                </AppDropdownItem>
              ) : null
            }
          </DropdownMenu>
        </ButtonDropdown>
      </AppFilterDropdownContext.Provider>
    )
  }

  constructor(props) {
    super(props)

    this.state = { isOpen: false }
  }

  onToggle = event => {
    if (!event.target || !event.target.value) {
      this.setState({ isOpen: !this.state.isOpen })
    }

    this.props.onToggle(event)
  }

  onClickClearFilters = event => {
    this.props.onClearFilters(event)
  }
}

const exported = withTranslation(
  'src/components/dropdowns/app_filter_dropdown'
)(AppFilterDropdown)

exported.Item = AppFilterDropdownItemContainer

export default exported
