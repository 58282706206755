import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { get } from 'lodash/fp/object'
import { compose } from 'redux'
import { graphql } from '@apollo/client/react/hoc'
import { Button } from 'reactstrap'
import { debounce } from 'lodash/function'
import { createSelector } from 'reselect'
import CsvExportErrorModal from '@@src/components/modals/csv_export_error_modal'
import AsyncResult from '@@src/utils/async_result'
import EventListExportButton from './event_list_export_button/event_list_export_button'
import PressureAnalysisViewOptionsDropdown from './pressure_analysis_view_options_dropdown'
import AnomalyListExportDropdown from './anomaly_list/anomaly_list_export_dropdown'
import { RequestAnomaliesTableReportMutation } from './anomaly_list/graphql/anomalies_table_report_graphql'
import { ANOMALY_REPORT_CSV } from './anomaly_list/consts'
import AnalysisTabs from '@@src/analysis_path/analysis_tabs'
import AsyncResultSwitch from '@@src/components/async_result_switch'
import TimeRangePicker from '@@src/components/dropdowns/time_range_picker'
import getFilterDropdown from '@@src/components/filter_component/filter_component'
import OptionsMenu from '@@src/components/options_menu'
import ExportMenu, {
  EXPORT_CPIS_DATA_TYPE,
  EXPORT_CSV_REPORT_TYPE,
  EXPORT_PRESSURE_DATA_TYPE,
} from '@@src/components/dropdowns/export_dropdown_with_modal/export_dropdown_with_modal'
import {
  DEFAULT_USER_ACTION_DEBOUNCE_DELAY,
  EXPORT_LIMITS,
  PAN_MODIFIER,
  VIEW_TYPE_ANOMALY_LIST,
  VIEW_TYPE_CPIS,
  VIEW_TYPE_EVENTS,
  VIEW_TYPE_OPTIONS,
  VIEW_TYPE_PLOT,
  ZOOM_MODIFIER,
} from './constants'

import styles from './pressure_analysis_config_menu.css'
import * as Changes from '../../store/changes'
import { connect } from 'react-redux'
import NetworkAsset from '../../api/presenters/network_asset'
import LoadingIcon from '../../components/loading_icon'

const OptionsDropdownFilter = getFilterDropdown(OptionsMenu)
const ExportDropdownFilter = getFilterDropdown(ExportMenu)

class PressureAnalysisConfigMenu extends React.PureComponent {
  static propTypes = {
    endTime: PropTypes.instanceOf(Date).isRequired,
    timezone: PropTypes.string.isRequired,
    startTime: PropTypes.instanceOf(Date).isRequired,
    viewType: PropTypes.oneOf(VIEW_TYPE_OPTIONS).isRequired,
    onChangeView: PropTypes.func.isRequired,
    onChangeTimeRange: PropTypes.func.isRequired,
    onChangeOptions: PropTypes.func.isRequired,
    currentGraphOptions: PropTypes.array.isRequired,
    requestAnomaliesReport: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    const { t } = this.props
    this.state = {
      exportOptions: [
        {
          value: EXPORT_CSV_REPORT_TYPE,
          text: t('options.csv_data'),
        },
      ],
      requestAnomaliesReportResult: AsyncResult.notFound(),
      csvErrorModalOpen: false,
    }
  }

  render() {
    const {
      t,
      endTime,
      startTime,
      timezone,
      onChangeTimeRange,
      onChangeView,
      viewType,
      currentGraphOptions,
      onChangeOptions,
    } = this.props

    const { exportOptions, requestAnomaliesReportResult } = this.state

    const {
      debouncedUpdateToPastTimeRange,
      debouncedUpdateToFutureTimeRange,
      debouncedUpdateToLongerTimeRange,
      debouncedUpdateToShorterTimeRange,
    } = this.selectDebouncedActions(this.props)

    return (
      <div className={styles['graph-header']}>
        <CsvExportErrorModal
          isOpen={this.state.csvErrorModalOpen}
          viewType={viewType}
          toggle={() => {
            this.setState({ csvErrorModalOpen: false })
          }}
        />
        <div className={styles['options-row']}>
          <AnalysisTabs location={location} />

          <div className="ml-2 d-flex flex-row flex-grow-1">
            <PressureAnalysisViewOptionsDropdown
              name="filter-input"
              currentView={viewType}
              onChangeView={onChangeView}
            />

            <OptionsDropdownFilter
              title={t('text.graph_settings')}
              name="graph-options"
              label={<i className="far fa-cog" />}
              options={currentGraphOptions}
              onChange={onChangeOptions}
            />

            <ExportForViewType
              props={this.props}
              exportOptions={exportOptions}
              requestAnomaliesReportResult={requestAnomaliesReportResult}
              canExport={this.canExport}
              onSelectReportType={this.onSelectReportType}
            />
          </div>
        </div>

        <div
          className="d-flex flex-row align-self-center
         align-content-end align-items-center"
        >
          <Button
            name="pan-left-button"
            color="outline-primary"
            onClick={debouncedUpdateToPastTimeRange}
            className="border-0"
          >
            <i className="far fa-angle-left"></i>
          </Button>

          <Button
            name="pan-right-button"
            color="outline-primary"
            onClick={debouncedUpdateToFutureTimeRange}
            className="border-0"
          >
            <i className="far fa-angle-right"></i>
          </Button>

          <TimeRangePicker
            endTime={endTime}
            onChange={onChangeTimeRange}
            timezone={timezone}
            className="d-flex flex-row justify-content-end"
            startTime={startTime}
            source={'pressure-graph'}
          />

          <Button
            name="zoom-out-button"
            color="outline-primary"
            onClick={debouncedUpdateToLongerTimeRange}
            className="border-0"
          >
            <i className="far fa-search-minus"></i>
          </Button>

          <Button
            name="zoom-in-button"
            color="outline-primary"
            onClick={debouncedUpdateToShorterTimeRange}
            className="border-0"
          >
            <i className="far fa-search-plus"></i>
          </Button>
        </div>
      </div>
    )
  }

  onSelectReportType = (reportType) => {
    const {
      endTime,
      startTime,
      requestAnomaliesReport,
      legendItemsResult,
      orderDirection,
      orderBy,
    } = this.props

    switch (reportType) {
      case ANOMALY_REPORT_CSV:
        this.setState({ requestAnomaliesReportResult: AsyncResult.pending() })
        if (
          legendItemsResult.wasSuccessful() &&
          !legendItemsResult.data.length
        ) {
          return
        }
        requestAnomaliesReport({
          variables: {
            start: startTime,
            end: endTime,
            orderDirection,
            orderBy: orderBy === 'startTime' ? undefined : orderBy,
            sources: legendItemsResult.data
              .filter((legendItem) => {
                return legendItem.source instanceof NetworkAsset
              })
              .map((filteredItem) => {
                return {
                  networkAssetId: filteredItem.source.id,
                  channel: filteredItem.sourceChannel,
                }
              }),
          },
        })
          .then(() =>
            this.setState({
              requestAnomaliesReportResult: AsyncResult.success(),
            })
          )
          .catch((error) =>
            this.setState({
              requestAnomaliesReportResult: AsyncResult.fail(error),
            })
          )
          .finally(() => this.props.dispatchNotifyDownloadsChanged())
        break
    }
  }

  canExport = () => {
    const { startTime, endTime, viewType } = this.props

    const start = moment(startTime)
    const end = moment(endTime)

    const limit = EXPORT_LIMITS[viewType]
    const exceededLimit = limit && end.isAfter(moment(start).add(...limit))

    if (exceededLimit) {
      this.setState({ csvErrorModalOpen: true })
    }

    return !exceededLimit
  }

  selectDebouncedActions = createSelector(
    [get('debounceDelay'), () => this],
    (delay = DEFAULT_USER_ACTION_DEBOUNCE_DELAY, component) => {
      return {
        debouncedUpdateToPastTimeRange: debounce(
          () => {
            const { startTime, endTime, onChangeTimeRange } = component.props
            const currentRange = endTime.getTime() - startTime.getTime()
            const newEndTime = new Date(
              endTime.getTime() - PAN_MODIFIER * currentRange
            )
            const newStartTime = new Date(newEndTime.getTime() - currentRange)

            onChangeTimeRange({ endTime: newEndTime, startTime: newStartTime })
          },
          delay,
          { leading: false, trailing: true }
        ),

        debouncedUpdateToFutureTimeRange: debounce(
          () => {
            const { startTime, endTime, onChangeTimeRange } = component.props
            const currentRange = endTime.getTime() - startTime.getTime()
            const possibleNewEndTime = new Date(
              endTime.getTime() + PAN_MODIFIER * currentRange
            )
            const nowTime = new Date().getTime()
            const newEndTime = new Date(
              nowTime > possibleNewEndTime ? possibleNewEndTime : nowTime
            )
            const newStartTime = new Date(newEndTime.getTime() - currentRange)

            onChangeTimeRange({ endTime: newEndTime, startTime: newStartTime })
          },
          delay,
          { leading: false, trailing: true }
        ),

        debouncedUpdateToLongerTimeRange: debounce(
          () => {
            const { startTime, endTime, onChangeTimeRange } = component.props
            const currentRange = endTime.getTime() - startTime.getTime()
            const possibleNewEndTime =
              endTime.getTime() + 0.5 * ZOOM_MODIFIER * currentRange
            const nowTime = new Date().getTime()
            const newEndTime = new Date(
              nowTime > possibleNewEndTime ? possibleNewEndTime : nowTime
            )
            const newStartTime = new Date(
              newEndTime.getTime() - (1 + ZOOM_MODIFIER) * currentRange
            )

            onChangeTimeRange({ endTime: newEndTime, startTime: newStartTime })
          },
          delay,
          { leading: false, trailing: true }
        ),

        debouncedUpdateToShorterTimeRange: debounce(
          () => {
            const { startTime, endTime, onChangeTimeRange } = component.props
            const currentRange = endTime.getTime() - startTime.getTime()
            const newEndTime = new Date(
              endTime.getTime() - 0.5 * ZOOM_MODIFIER * currentRange
            )
            const newStartTime = new Date(
              newEndTime.getTime() - (1 - ZOOM_MODIFIER) * currentRange
            )

            onChangeTimeRange({ endTime: newEndTime, startTime: newStartTime })
          },
          delay,
          { leading: false, trailing: true }
        ),
      }
    }
  )
}

function ExportForViewType({
  props,
  exportOptions,
  requestAnomaliesReportResult,
  canExport,
  onSelectReportType,
}) {
  const {
    endTime,
    startTime,
    timezone,
    viewType,
    legendItemsHierarchyResult,
    shouldDisplayExportButton,
    legendItemsResult,
    orderBy,
    orderDirection,
  } = props

  switch (viewType) {
    case VIEW_TYPE_CPIS: {
      return (
        <AsyncResultSwitch
          result={legendItemsHierarchyResult}
          renderSuccessResult={() => (
            <ExportDropdownFilter
              name="export-dropdown"
              legendItemsResult={legendItemsHierarchyResult}
              className="mr-2"
              startTime={startTime}
              endTime={endTime}
              timeZone={timezone}
              options={exportOptions}
              onChange={() => null}
              canPerformAction={canExport}
              dataType={EXPORT_CPIS_DATA_TYPE}
            />
          )}
          renderPendingResult={() => (
            <LoadingIcon className={'align-self-center mx-auto'} />
          )}
        />
      )
    }
    case VIEW_TYPE_PLOT: {
      return (
        <AsyncResultSwitch
          result={legendItemsHierarchyResult}
          renderSuccessResult={() => (
            <ExportDropdownFilter
              name="export-dropdown"
              legendItemsResult={legendItemsHierarchyResult}
              className="mr-2"
              startTime={startTime}
              endTime={endTime}
              timeZone={timezone}
              options={exportOptions}
              onChange={() => null}
              canPerformAction={canExport}
              dataType={EXPORT_PRESSURE_DATA_TYPE}
            />
          )}
          renderPendingResult={() => (
            <LoadingIcon className={'align-self-center mx-auto'} />
          )}
        />
      )
    }
    case VIEW_TYPE_ANOMALY_LIST: {
      return (
        <AnomalyListExportDropdown
          disabled={!shouldDisplayExportButton}
          requestReportResult={requestAnomaliesReportResult}
          onSelectReportType={onSelectReportType}
        />
      )
    }

    case VIEW_TYPE_EVENTS: {
      return (
        <AsyncResultSwitch
          result={legendItemsResult}
          renderSuccessResult={() => (
            <EventListExportButton
              startTime={startTime}
              endTime={endTime}
              orderBy={orderBy}
              orderDirection={orderDirection}
              timeZone={timezone}
              canPerformAction={canExport}
              legendItemsResult={legendItemsResult}
            />
          )}
          renderPendingResult={() => (
            <LoadingIcon className={'align-self-center mx-auto'} />
          )}
        />
      )
    }
    default:
      return null
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchNotifyDownloadsChanged() {
      dispatch(Changes.notifyChange(Changes.CSV_DOWNLOAD_CHANGES))
    },
  }
}

export default compose(
  connect(() => {
    return {}
  }, mapDispatchToProps),
  graphql(RequestAnomaliesTableReportMutation, {
    name: 'requestAnomaliesReport',
  })
)(PressureAnalysisConfigMenu)
