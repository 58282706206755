import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { FormGroup, Label, Input } from 'reactstrap'
import { noop } from 'lodash/fp/util'

export const DEVICE = 'VDevice'
export const NETWORK_ASSET = 'VNetworkAsset'
export const GROUP = 'VGroup'
export const GROUP_TYPE_FILTERS = [
  DEVICE,
  NETWORK_ASSET,
  GROUP,
]

export function defaultGroupTypeFilters(filters = []) {
  return {
    [DEVICE]: filters.includes(DEVICE),
    [NETWORK_ASSET]: filters.includes(NETWORK_ASSET),
    [GROUP]: filters.includes(GROUP),
  }
}

function GroupTypeFilters(props) {
  const { t, filters, handleChange } = props

  return (
    <FormGroup tag="fieldset">
      <legend className="h6">{t('headers.types')}</legend>
      <FormGroup check={true}>
        <Label check={true}>
          <Input
            checked={filters[DEVICE] === true}
            onChange={handleChange}
            type="checkbox"
            name={DEVICE} />{' '}
          {t('text.device')}
        </Label>
      </FormGroup>
      <FormGroup check={true}>
        <Label check={true}>
          <Input
            checked={filters[NETWORK_ASSET] === true}
            onChange={handleChange}
            type="checkbox"
            name={NETWORK_ASSET} />{' '}
          {t('text.asset')}
        </Label>
      </FormGroup>
      <FormGroup check={true}>
        <Label check={true}>
          <Input
            checked={filters[GROUP] === true}
            onChange={handleChange}
            type="checkbox"
            name={GROUP} />{' '}
          {t('text.group')}
        </Label>
      </FormGroup>
    </FormGroup>
  )
}

GroupTypeFilters.propTypes = {
  t: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
  handleChange: PropTypes.func,
}

GroupTypeFilters.defaultProps = {
  filters: {},
  handleChange: noop,
}

export default withTranslation([
  'src/components/modals/group_type_filters',
])(memo(GroupTypeFilters))
