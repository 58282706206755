import React, { useRef, useState } from 'react'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, TabContent, TabPane } from 'reactstrap'
import DevicesInstallDeviceModalTabs from './devices_install_device_modal_tabs.component'
import InstallOnExistingAssetTab from './devices_modal_tabs/install_on_existing/install_on_existing_asset_tab.component'
import { useTranslation } from 'react-i18next'
import { DevicesForListItem } from '../../../../../api/models/devices_for_list_item'
import CreateAndInstallNetworkAsset from './devices_modal_tabs/create_and_install/create_and_install.component'
import useMutationInstallDevice from '../../hooks/use_mutation_install_device'

import PropTypes from 'prop-types'

import './devices_install_device_modal.component.scss'

const DevicesInstallModal = ({ isOpen = false, toggle = () => null, device = null, onInstallSuccess = () => null }) => {
  const [currentTabSelected, setCurrentTabSelected] = useState(0)
  const formRef = useRef(null)

  const { t } = useTranslation(['v2/network_assets/forms', 'v2/common/buttons'])
  const {
    loading,
    status,
    createNetworkAssetAndInstallDevice,
    installOnExistingNetworkAsset,
  } = useMutationInstallDevice(device, () => {
    onInstallSuccess()
    toggle()
  })

  const handleSubmitForm = () => formRef.current.dispatchEvent(new Event('submit', { cancelable: true }))

  return (
    <Modal className={'install-devices-to-network-asset__container'} isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        {t('install_device_modal.install_device_on_network_asset', { serialNumber: device && device.serialNumber })}
      </ModalHeader>
      <ModalBody className={'install-devices-to-network-asset__modal-body-section'}>
        <DevicesInstallDeviceModalTabs
          currentTab={currentTabSelected}
          setCurrentTab={setCurrentTabSelected}
          tabNames={[
            t('install_device_modal.select_existing'),
            t('install_device_modal.create_new_asset'),
          ]}
        />
        <TabContent activeTab={currentTabSelected}>
          <TabPane tabId={0}>
            <InstallOnExistingAssetTab
              device={device}
              isInstalling={loading}
              installOnExistingNetworkAsset={installOnExistingNetworkAsset} />
          </TabPane>
          <TabPane tabId={1}>
            <CreateAndInstallNetworkAsset
              formRef={formRef}
              createNetworkAssetAndInstallDevice={createNetworkAssetAndInstallDevice} />
          </TabPane>
        </TabContent>
      </ModalBody>
      <ModalFooter className="d-flex flex-row justify-content-between">
        <div className="w-50 d-flex flex-row">
          <Button onClick={() => toggle()} className="mr-auto">{t('v2/common/buttons:cancel')}</Button>
          <p className="mr-auto p-0 m-0 align-self-center">{ status }</p>
          { currentTabSelected === 1 &&
          <Button disabled={loading} color="primary" onClick={handleSubmitForm}>
            {t('v2/common/buttons:submit')}
          </Button>
          }
        </div>
      </ModalFooter>
    </Modal>
  )
}

DevicesInstallModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  device: PropTypes.instanceOf(DevicesForListItem),
  onInstallSuccess: PropTypes.func,
}

export default DevicesInstallModal
