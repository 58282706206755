import React, { memo } from 'react'
import ReactGoogleMapLoader from 'react-google-maps-loader'

function withGoogleMapsProvider(Component) {
  function GoogleMapsProvider(props) {
    return (
      <ReactGoogleMapLoader
        params={{
          key: process.env.GOOGLE_API_KEY,
        }}
        render={googleMaps => (
          <Component googleMaps={googleMaps} {...props} />
        )} />
    )
  }

  return memo(GoogleMapsProvider)
}

export default withGoogleMapsProvider
