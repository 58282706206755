import createSagaMiddleware from 'redux-saga'
import { createLogger } from 'redux-logger'
import { routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { createStore, applyMiddleware } from 'redux'

import * as analytics from '@@src/analytics'
import HistorySaga from './store/history'
import createRootReducer from './create_root_reducer'

export default function createGlobalAppComponents({
  history = createBrowserHistory(),
  logging = true,
  sagaMiddleware = createSagaMiddleware(),
} = {}) {
  const reduxMiddlewares = [
    sagaMiddleware,
    routerMiddleware(history),
    logging ? createLogger({ collapsed: true }) : undefined,
  ].filter(Boolean)

  const store = createStore(createRootReducer(history), {}, applyMiddleware(...reduxMiddlewares))
  const historySaga = HistorySaga.createRootSaga()

  sagaMiddleware.run(historySaga)
  history.listen(location => analytics.trackLocation(location))

  return { store, history }
}
