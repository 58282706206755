import moment from 'moment'
import { map } from 'lodash/fp/collection'
import { flow } from 'lodash/fp/util'
import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import { fromPairs } from 'lodash/fp/array'
import { createSelector } from 'reselect'
import { toPairs, values, assign } from 'lodash/fp/object'

import { VIEW_TYPE_OPTIONS }
from '@@src/analysis_path/pressure_analysis_path/constants' // eslint-disable-line max-len

import {
  formatAsQueryString, parseSearchParams, mergeSearchParams,
} from '@@src/utils'

export function parseString(item) {
  return item
}

export function parseArray(item) {
  return item ? ([]).concat(item) : []
}

export function parseNumber(item) {
  return Number(item)
}

export function parseViewType(item) {
  if (VIEW_TYPE_OPTIONS.includes(item)) {
    return item
  } else {
    return VIEW_TYPE_OPTIONS[0]
  }
}

export function parseDate(item) {
  const momentDate = item ?
    moment(item, 'YYYY-MM-DDTHH:mm:ss.SSSZ', true) : undefined

  if (momentDate.isValid()) {
    return momentDate.toDate()
  }

  return undefined
}

parseDate.invert = item => moment(item, 'YYYY-MM-DDTHH:mm:ss.SSSZ', true)
  .toISOString()

export function parseBoolean(item) {
  return !!item
}
parseBoolean.invert = item => item ? '1' : undefined

function defaultSelectLocation(props) {
  return props.location
}

export default function defineQueryParams(
  createInputQueryStringSpec, selectLocationFromProps = defaultSelectLocation,
) {
  const inputQueryStringSpec = createInputQueryStringSpec instanceof Function ?
    createInputQueryStringSpec() : createInputQueryStringSpec

  const queryStringSpec = flow(
    toPairs,
    map(([key, spec]) => [key, {
      key,
      alias: key,
      parseFunc: parseString,
      ...spec,
    }]),
    fromPairs
  )(inputQueryStringSpec)

  const mapToQueryParams = flow(
    toPairs,
    map(([key, value]) => {
      const spec = queryStringSpec[key]

      return spec ? [
        spec.alias,
        spec.parseFunc.invert ? spec.parseFunc.invert(value) : value,
      ] : [key, value]
    }),
    fromPairs
  )

  function createMapStateToProps() {
    const selectParsedSearchParams = createSelector(
      [(state, props) => selectLocationFromProps(props).search],
      parseSearchParams
    )

    const mapper = Object.keys(queryStringSpec).map(key => {
      const sp = queryStringSpec[key]

      return {
        ...sp,
        selector: createSelector(
          [(state, props) => (
            selectParsedSearchParams(state, props)[sp.alias] || sp.default
          )],
          sp.parseFunc,
        ),
      }
    })

    const createUpdateQueryParamsLink = createSelector(
      [
        props => selectLocationFromProps(props).pathname,
        props => selectLocationFromProps(props).search,
      ],
      (pathname, search) => changes => {
        return pathname + mergeSearchParams(search, mapToQueryParams(changes))
      }
    )

    const createWithQueryParamsLink = createSelector(
      [props => selectLocationFromProps(props).pathname],
      pathname => changes => {
        return pathname + formatAsQueryString(mapToQueryParams(changes))
      }
    )

    return function mapStateToProps(state, ownProps) {
      return flow(
        map(({ key, selector }) => {
          return [key, selector(state, ownProps)]
        }),
        fromPairs,
        assign({
          withQueryParamsLink: createWithQueryParamsLink(ownProps),
          updateQueryParamsLink: createUpdateQueryParamsLink(ownProps),
        })
      )(mapper)
    }
  }

  function mapDispatchToProps(dispatch) {
    return {
      dispatchUpdateQueryParams(props, changes) {
        const { search, pathname } = selectLocationFromProps(props)

        const newParams = mapToQueryParams(changes)

        dispatch(push({
          search: mergeSearchParams(search, newParams),
          pathname,
        }))
      },

      dispatchClearQueryParams(props) {
        const { search, pathname } = selectLocationFromProps(props)

        const newParams = flow(
          values,
          map(spec => [spec.alias, undefined]),
          fromPairs
        )(queryStringSpec)

        dispatch(push({
          search: mergeSearchParams(search, newParams),
          pathname,
        }))
      },
    }
  }

  return connect(createMapStateToProps, mapDispatchToProps)
}

Object.assign(defineQueryParams, {
  parseDate,
  parseArray,
  parseNumber,
  parseString,
  parseBoolean,
  parseViewType,
})
