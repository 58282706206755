import gql from 'graphql-tag'

export const CREATE_NETWORK_ASSET_MUTATION = gql`
mutation CreateNetworkAsset(
  $groupIds: [Int],
  $assetId: String!,
  $comment: String,
  $location: AssetLocationInput!,
  $assetType: InflowNetAssetType!,
  $assetName: String,
  $customLabels: String,
  $locationSource: LocationSourceType,
) {
  createNetworkAsset(
    groupIds: $groupIds,
    comment: $comment,
    assetId: $assetId,
    location: $location,
    assetName: $assetName,
    assetType: $assetType,
    customLabels: $customLabels,
    locationSource: $locationSource,
  ) {
    id
  }
}
`
