import React, { useContext } from 'react'
import { PlotDataContext } from '../../../../hooks/plot/PlotDataContext'
import { MINUTES } from '../../../../utils'

const withPlotDataWrapper = (Component) => {
  return (props) => {
    const {
      startTime,
      endTime,
      rawDataRequestsResult,
      anomalyBoundsResult,
      plotInfo,
      pressureSubsetDataResult,
      anomalyResult,
      alertsResult,
      pressureEvents,
      listRawDataRequestsResult,
      refetchRawDataRequests,
    } = useContext(PlotDataContext)

    const { resolution, ...rest } = plotInfo

    return <Component
      resolution={resolution ? resolution : MINUTES}
      startTime={startTime}
      endTime={endTime}
      fatchingInfo={rest}
      pressureSubsetDataResult={pressureSubsetDataResult}
      anomalyResult={anomalyResult}
      alertsResult={alertsResult}
      anomalyBoundsResult={anomalyBoundsResult}
      rawDataRequestsResult={rawDataRequestsResult}
      pressureEvents={pressureEvents}
      listRawDataRequestsResult={listRawDataRequestsResult}
      refetchRawDataRequests={refetchRawDataRequests}
      {...props} />
  }
}

export default withPlotDataWrapper
