import gql from 'graphql-tag'

export const GET_SINGLE_DEVICE_INFO = gql`
query GetDeviceListItem($deviceId: Int!) {
  getDevicesListItem(
    deviceId: $deviceId
  ) {
		device {
      deviceId
      serialNumber
      state
      lastCommunicationTime
      lastRecordedDataTime
      scheduledSessionPeriod
      scheduledSessionTimeZone
      isCommissioned
      isInstalled
      installation {
          assetId
          assetName
          assetType
          assetChannel
      }
      lastIssueTime
      mostSeverityIssue {
          issueType
          issueSeverity
      }
      activeErrorsCount
      activeWarningsCount
      activeInfoCount
		}
	}
}
`
