import React from 'react'
import { Card } from 'reactstrap'
import { withTranslation } from 'react-i18next'

import AppLayout from '@@src/components/app_layout'
import styles from './app_info_page.css'

class AppInfoPage extends React.PureComponent {
  render() {
    const { t } = this.props

    return (
      <AppLayout
        title={t('headings.page_title')}
        footerColor={'dark'}
        backgroundClassname={styles.background}>
        <Card className={styles['status-card']}>
          <h1>{t('headings.version_info')}</h1>
          <pre>
            <code>
              webapp: {process.env.APP_VERSION}
            </code>
          </pre>
        </Card>
      </AppLayout>
    )
  }
}

export default withTranslation(['src/app_info_page'])(AppInfoPage)
