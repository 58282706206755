import { useMemo } from 'react'
import gql from 'graphql-tag'
import { createSelectGraphQLResult } from '../../utils/index'
import { parseGraphQLResult } from '../../api/presenters/index'
import { useQuery } from '@apollo/client'

export function useGetNetworkAssetSummaries() {
  const NETWORK_STATISTICS = gql`
    query NetworkAssetsdStatisticModel {
      getNetworkAssetsStatistics {
        totalCount
        fullyInstalledCount
        partialInstalledCount
        notInstalledCount
      }
    }
  `

  const networkStatisticsQueryResult = useQuery(NETWORK_STATISTICS, {
    fetchPolicy: 'network-only',
  })

  const networkStatistics = useMemo(() =>
    createSelectGraphQLResult('data', {
      mapResult: (value) => parseGraphQLResult(value.getNetworkAssetsStatistics),
      nullObject: [],
    })(networkStatisticsQueryResult)
  , [networkStatisticsQueryResult])
  return { networkStatistics }
}
