import React from 'react'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { Badge } from 'reactstrap'

import DeviceIssue from '@@src/api/presenters/device_issue'

function DeviceIssueSeverityBadge(props) {
  const { issue, t, className } = props

  return (
    <Badge color={issue.alertColor} className={className}>
      {t(issue.typeWarning)}
    </Badge>
  )
}

DeviceIssueSeverityBadge.propTypes = {
  issue: PropTypes.instanceOf(DeviceIssue).isRequired,
  className: PropTypes.string,
}

export default withTranslation(['common/text'])(DeviceIssueSeverityBadge)
