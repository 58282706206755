import React from 'react'
import PropTypes from 'prop-types'
import { omit } from 'lodash/fp/object'
import { createSelector } from 'reselect'

import PopupContext from './context'

export default class PopupAnchor extends React.PureComponent {
  static defaultProps = {
    elementType: 'div',
  }

  static propTypes = {
    elementType: PropTypes.string.isRequired,
  }

  ref = React.createRef()

  state = { hover: false }

  render() {
    const { elementType, children } = this.props

    return (
      <PopupContext.Provider value={{
        anchorRef: this.ref,
        isMouseOverAnchor: this.state.hover,
      }}>
        {
          React.createElement(
            elementType,
            {
              ...this.selectElementProps(this.props),
              ref: this.ref,
              onMouseEnter: this.onMouseEnter,
              onMouseLeave: this.onMouseLeave,
            },
            children,
          )
        }
      </PopupContext.Provider>
    )
  }

  componentDidMount() {
    this.forceUpdate()

    this.setState({ hover: false })
  }

  onMouseEnter = event => {
    this.setState({ hover: true })

    if (this.props.onMouseEnter) {
      this.props.onMouseEnter(event)
    }
  }

  onMouseLeave = event => {
    this.setState({ hover: false })

    if (this.props.onMouseLeave) {
      this.props.onMouseLeave(event)
    }
  }

  selectElementProps = createSelector(
    [props => props],
    omit(Object.keys(PopupAnchor.propTypes))
  )
}
