import React from 'react'
import PropTypes from 'prop-types'

import ErrorInfo from '@@src/components/error_info'
import LoadingIcon from '@@src/components/loading_icon'
import { AsyncResult } from '@@src/utils'

import styles from './infinite_scroll_list.css'

export default class InfiniteScrollList extends React.Component {
  static propTypes = {
    result: PropTypes.instanceOf(AsyncResult).isRequired,
    fetchNextPage: PropTypes.func,
  }

  render() {
    const {
      name, className, children, result, fetchNextPage: _s, ...rest
    } = this.props

    return (
      <ul name={name} className={className} {...rest} onScroll={this.onScroll}>
        {children}

        {
          result.wasFailure() ? (
            <ErrorInfo className="mx-3 mt-3" error={result.error}/>
          ) : null
        }

        {
          result.isPending() ? (
            <li className={styles.loading}><LoadingIcon/></li>
          ) : null
        }
      </ul>
    )
  }

  onScroll = event => {
    const { result, fetchNextPage } = this.props
    if (!result.wasSuccessful() || !fetchNextPage) {
      return
    }

    const target = event.target

    if (
      target &&
      target.scrollHeight - target.scrollTop <= target.clientHeight
    ) {
      fetchNextPage()
    }
  }
}
