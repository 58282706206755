import { Schedule } from '@rschedule/core/generators'
import { getPressureDataFreshnessKey } from '../../domain/devices'
import { DevicesListItemIssues } from './devices_list_item_issue'

export class DevicesForListItem {
  constructor(props) {
    const { mostSeverityIssue, lastRecordedDataTime, ...rest } = props
    this.mostSevereIssue = new DevicesListItemIssues(mostSeverityIssue)
    this.lastRecordedDataDate = lastRecordedDataTime
      ? new Date(lastRecordedDataTime)
      : null
    Object.assign(this, { ...rest })
  }

  getTableIconName() {
    switch(true) {
      case !!this.mostSevereIssue.severity: {
        return `icon-${this.mostSevereIssue.severity.toLowerCase()}`
      }
      case this.state === 'returned': {
        return 'icon-returned'
      }
      case this.isCommissioned || this.isInstalled: {
        return 'icon-commissioned'
      }
      default: return 'icon-decommissioned'
    }
  }

  getMostSeveryIssueType() {
    return this.mostSevereIssue.type || null
  }

  getMostSeverityIssueWithOtherIssuesCount() {
    const { activeErrorsCount, activeWarningsCount, activeInfoCount, mostSevereIssue } = this
    const errorsBySeverity = {
      ERROR: activeErrorsCount || 0,
      WARNING: activeWarningsCount || 0,
      INFORMATION: activeInfoCount || 0,
    }

    if (this.getMostSeveryIssueType()) {
      errorsBySeverity[mostSevereIssue.severity] -= 1
    }

    const [errorsCount, warningsCount, informationCount] = [...Object.values(errorsBySeverity)]

    return (errorsCount || warningsCount || informationCount || this.getMostSeveryIssueType())
      ? { mostSevereIssue, errorsCount, warningsCount, informationCount }
      : null
  }

  getDataFreshness() {
    return getPressureDataFreshnessKey(this.lastRecordedDataDate)
  }

  getDataLinkId() {
    return `d.${this.deviceId}`
  }

  getCanProvideContact() {
    return !(!this.lastCommunicationTime || !this.scheduledSessionPeriod)
  }

  getContactSchedule() {
    if(!this.lastCommunicationTime || !this.scheduledSessionPeriod) {
      return null
    }

    return new Schedule({
      rrules: [
        {
          frequency: 'SECONDLY',
          interval: this.scheduledSessionPeriod,
          start: new Date(this.lastCommunicationTime),
        },
      ],
    })
  }

  getNextContactDate(options = { take: 1, start: new Date() }) {
    const schedule = this.getContactSchedule()

    if (schedule) {
      const occurrences = schedule
        .occurrences(options)
        .toArray()
        .map(o => o.date)

      return occurrences[0]
    }

    return null
  }

  getExpectedPrevContactDate() {
    return this.getNextContactDate({
      take: 1,
      end: new Date(),
      reverse: true,
    })
  }
}
