import { ApolloLink } from '@apollo/client'
import { onError } from '@apollo/client/link/error'

import * as analytics from '@@src/analytics'

export default function createRequestAnalyticsLink() {
  return ApolloLink.from([
    onError(({ operation }) => {
      const { $analytics } = operation.getContext()
      if (!$analytics) {
        return
      }

      const duration = (new Date()).getTime() - $analytics.start.getTime()

      analytics.trackRequestLatency($analytics.name, duration)
    }),
    new ApolloLink((operation, forward) => {
      const start = new Date()

      operation.setContext({
        $analytics: {
          name: operation.operationName,
          start,
        },
      })

      return forward(operation).map(response => {
        const duration = (new Date()).getTime() - start.getTime()
        const reqId = (response.meta || {}).awsRequestId

        analytics.trackRequestLatency(operation.operationName, duration, reqId)

        return response
      })
    }),
  ])
}
