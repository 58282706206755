export default class LegendItemColorTheme {
  static GREEN = '#8ade1b'
  static CYAN = '#60d2d9'
  static BLUE = '#649bf2'
  static PURPLE = '#b986ff'
  static DARK_GREEN = '#5c9412'
  static DARK_CYAN = '#408c91'
  static DARK_BLUE = '#284b84'
  static DARK_PURPLE = '#7c59ab'
  static PINK = '#ff6ea7'
  static RED = '#dc4935'
  static ORANGE = '#e78c00'
  static YELLOW = '#e5e617'
  static DARK_PINK = '#9e3961'
  static DARK_RED = '#933023'
  static DARK_ORANGE = '#9a5d00'
  static DARK_YELLOW = '#999a0f'

  static DEFAULT_COLORS = new Set([
    LegendItemColorTheme.CYAN,
    LegendItemColorTheme.PURPLE,
    LegendItemColorTheme.RED,
    LegendItemColorTheme.YELLOW,
    LegendItemColorTheme.BLUE,
    LegendItemColorTheme.PINK,
    LegendItemColorTheme.ORANGE,
    LegendItemColorTheme.GREEN,
    LegendItemColorTheme.DARK_CYAN,
    LegendItemColorTheme.DARK_PURPLE,
    LegendItemColorTheme.DARK_RED,
    LegendItemColorTheme.DARK_YELLOW,
    LegendItemColorTheme.DARK_BLUE,
    LegendItemColorTheme.DARK_PINK,
    LegendItemColorTheme.DARK_ORANGE,
    LegendItemColorTheme.DARK_GREEN,
  ])

  static ASSIGNABLE_COLORS = new Set([
    LegendItemColorTheme.GREEN,
    LegendItemColorTheme.CYAN,
    LegendItemColorTheme.BLUE,
    LegendItemColorTheme.PURPLE,
    LegendItemColorTheme.DARK_GREEN,
    LegendItemColorTheme.DARK_CYAN,
    LegendItemColorTheme.DARK_BLUE,
    LegendItemColorTheme.DARK_PURPLE,
    LegendItemColorTheme.PINK,
    LegendItemColorTheme.RED,
    LegendItemColorTheme.ORANGE,
    LegendItemColorTheme.YELLOW,
    LegendItemColorTheme.DARK_PINK,
    LegendItemColorTheme.DARK_RED,
    LegendItemColorTheme.DARK_ORANGE,
    LegendItemColorTheme.DARK_YELLOW,
  ])

  constructor({
    defaultColors = LegendItemColorTheme.DEFAULT_COLORS,
    assignableColors = LegendItemColorTheme.ASSIGNABLE_COLORS,
  } = {}) {
    Object.assign(this, { defaultColors, assignableColors })
    Object.freeze(this)
  }
}
