import React from 'react'

export default class PureComponentWithAutofocus extends React.PureComponent {
  _autofocusTargetRef = React.createRef()

  componentDidMount() {
    if (this.autofocusTarget) {
      this.autofocusTarget.focus()
    }
  }

  componentDidUpdate() {
    if (this.autofocusTarget !== this._previousAutofocusTarget) {
      if (this.autofocusTarget) {
        this.autofocusTarget.focus()
      }
      this._previousAutofocusTarget = this.autofocusTarget
    }
  }

  getAutofocusTargetRef() {
    return this._autofocusTargetRef
  }

  get autofocusTarget() {
    return this._autofocusTargetRef.current
  }
}
