import React from 'react'
import { withTranslation } from 'react-i18next'
import { Badge } from 'reactstrap'
import PropTypes from 'prop-types'
import { createSelector } from 'reselect'
import { get } from 'lodash/fp/object'
import classnames from 'classnames'

import DeviceIssue from '@@src/api/presenters/device_issue'

import styles from './device_issues_badge.css'

class DeviceIssuesBadge extends React.PureComponent {
  static propTypes = {
    deviceIssues: PropTypes.arrayOf(
      PropTypes.instanceOf(DeviceIssue)
    ).isRequired,
    t: PropTypes.func.isRequired,
  }

  static defaultProps = {
    deviceIssues: [],
  }

  render() {
    const { t } = this.props
    const errors = this.selectErrors(this.props)
    const warnings = this.selectWarnings(this.props)
    const infos = this.selectInfos(this.props)
    const hasErrors = errors.length > 0
    const hasWarnings = warnings.length > 0
    const hasInfos = infos.length > 0
    const errorNumberBadgeClassnames = classnames(
      styles['error-number-badge'],
      'ml-1',
      'text-danger'
    )
    const warningBadgeClassnames = classnames({
      [styles['warning-number-badge']]: hasErrors,
      'ml-1': hasErrors,
    })
    const numberWarningBadgeClassnames = classnames(
      styles['warning-number-badge'],
      'ml-1',
    )
    const infoBadgeClassnames = classnames({
      [styles['info-number-badge']]: hasErrors || hasWarnings,
      'ml-1': hasErrors || hasWarnings,
    })
    const numberInfoBadgeClassnames = classnames(
      styles['info-number-badge'],
      'ml-1',
    )

    return (
      <div className={styles['device-issues-badge']}>
        {
          hasErrors ?
            <Badge color="danger">
              {t(`text.${errors[0].type}`)}
            </Badge>
            : null
        }
        {
          errors.length > 1 ?
            <React.Fragment>
              {this.renderPlusSpacer()}
              <Badge className={errorNumberBadgeClassnames} color="danger">
                {errors.length - 1}
              </Badge>
            </React.Fragment>
            : null
        }
        {
          hasWarnings ?
            <React.Fragment>
              { errors.length === 1 ? this.renderPlusSpacer() : null }
              <Badge className={warningBadgeClassnames} color="warning">
                {
                  !hasErrors ?
                    t(`text.${warnings[0].type}`) : warnings.length
                }
              </Badge>
            </React.Fragment>
            : null
        }
        {
          warnings.length > 1 && !hasErrors ?
            <React.Fragment>
              {this.renderPlusSpacer()}
              <Badge className={numberWarningBadgeClassnames} color="warning">
                {warnings.length - 1}
              </Badge>
            </React.Fragment>
            : null
        }
        {
          hasInfos ?
            <React.Fragment>
              {
                errors.length + warnings.length === 1 ?
                  this.renderPlusSpacer() : null
              }
              <Badge className={infoBadgeClassnames} color="info">
                {
                  !hasErrors && !hasWarnings ?
                    t(`text.${infos[0].type}`) : infos.length
                }
              </Badge>
            </React.Fragment>
            : null
        }
        {
          infos.length > 1 && !hasErrors && !hasWarnings ?
            <React.Fragment>
              {this.renderPlusSpacer()}
              <Badge className={numberInfoBadgeClassnames} color="info">
                {infos.length - 1}
              </Badge>
            </React.Fragment>
            : null
        }
      </div>
    )
  }

  renderPlusSpacer = () => {
    return (
      <span className="ml-1">
        +
      </span>
    )
  }

  selectErrors = createSelector([get('deviceIssues')], deviceIssues => {
    return deviceIssues.filter(device => device.isError())
  })

  selectWarnings = createSelector([get('deviceIssues')], deviceIssues => {
    return deviceIssues.filter(device => device.isWarning())
  })

  selectInfos = createSelector([get('deviceIssues')], deviceIssues => {
    return deviceIssues.filter(device => device.isInformation())
  })
}

export default withTranslation([
  'src/components/badges/device_issues_badge',
])(DeviceIssuesBadge)
