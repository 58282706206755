import DeviceIssue from '../../../../../api/presenters/device_issue'
import {
  PRESSURE_DATA_FILTERS,
  RECENT_PRESSURE_DATA,
  WITHOUT_PRESSURE_DATA,
  WITH_PRESSURE_DATA,
} from '../../../../../components/lists/utils'
import {
  ADVANCED_ERROR_FILTERS,
  ADVANCED_INFORMATION_FILTERS,
  ADVANCED_WARNING_FILTERS,
} from '../../../../../components/modals/device_issue_filters'
import { DEVICE_STATE_FILTERS } from '../../../../../components/modals/device_state_filters'
import { NETWORK_ASSET_FILTERS } from '../../../../../components/modals/network_asset_filters'

const ANY_FILTER_OPTION = 'any'
const COMMISSIONED_FILTER_OPTION = 'commissioned'
const DECOMMISSIONED_FILTER_OPTION = 'decommissioned'
const INSTALLED_FILTER_OPTION = 'installed'
const NOT_INSTALLED_FILTER_OPTION = 'notInstalled'
const HEALTHY_FILTER_OPTION = 'healthy'
export const STANDARD_FILTER_OPTIONS = [
  ANY_FILTER_OPTION,
  COMMISSIONED_FILTER_OPTION,
  DECOMMISSIONED_FILTER_OPTION,
  INSTALLED_FILTER_OPTION,
  NOT_INSTALLED_FILTER_OPTION,
  HEALTHY_FILTER_OPTION,
  DeviceIssue.ERROR_SEVERITY_FILTER,
  DeviceIssue.WARNING_SEVERITY_FILTER,
  DeviceIssue.INFORMATION_SEVERITY_FILTER,
]
export const ALLOWED_SORT_BY_DEVICES = [
  'serialNumber',
  'lastCommunicationTime',
  'lastCommissionedTime',
  'lastIssueTime',
  'issueSeverity',
]

export const ALL_ADVANCED_DEVICES_FILTERS = [
  ...ADVANCED_ERROR_FILTERS,
  ...ADVANCED_INFORMATION_FILTERS,
  ...ADVANCED_WARNING_FILTERS,
  ...NETWORK_ASSET_FILTERS,
  ...DEVICE_STATE_FILTERS,
]

const VALID_ASSET_TYPE_ISSUE_FILTERS = [
  ...ADVANCED_ERROR_FILTERS,
  ...ADVANCED_WARNING_FILTERS,
  ...ADVANCED_INFORMATION_FILTERS,
]

export const isFilterAdvanced = (filter) => {
  return ALL_ADVANCED_DEVICES_FILTERS.includes(filter)
}

/**
 * @param {string[]} flattenedFilters flattened filters from url
 * @returns {{hasActiveError:boolean,hasActiveInformation:boolean,hasActiveWarning:boolean,isCommissioned:boolean,isInstalled:boolean,hasPressureData: boolean,lastRecordedDataTimeGreaterThanOrEqual: Date,issueTypeIn:string[]}} filters object
 */
export const mapFlattenedFiltersToQueryObject = (flattenedFilters = []) => {
  // initialize query object
  const queryObject = {}

  // adding flags related to health filters
  // true - to contains error/warning/info
  // false - that not contains error/warning/info
  // null/undefined - doesn't verifies anything
  switch(true) {
    case flattenedFilters.includes(DeviceIssue.ERROR_SEVERITY_FILTER): {
      Object.assign(queryObject, {
        hasActiveError: true,
      })
      break
    }
    case flattenedFilters.includes(DeviceIssue.WARNING_SEVERITY_FILTER): {
      Object.assign(queryObject, {
        hasActiveWarning: true,
      })
      break
    }
    case flattenedFilters.includes(DeviceIssue.INFORMATION_SEVERITY_FILTER): {
      Object.assign(queryObject, {
        hasActiveInformation: true,
      })
      break
    }
    case flattenedFilters.includes(HEALTHY_FILTER_OPTION): {
      Object.assign(queryObject, {
        hasActiveError: false,
        hasActiveWarning: false,
      })
      break
    }
  }

  // issueTypeIn: array of advanced filters
  // isCommissioned: true -> its commissioned | false -> its decommissioned | undefined -> both
  // isInstalled: true -> its installed | false -> its uninstalled | undefined -> both
  Object.assign(queryObject, {
    issueTypeIn: flattenedFilters.filter(filter => VALID_ASSET_TYPE_ISSUE_FILTERS.includes(filter)),
    isCommissioned: flattenedFilters.includes(COMMISSIONED_FILTER_OPTION) ||
    (flattenedFilters.includes(DECOMMISSIONED_FILTER_OPTION) ? false : undefined),
    isInstalled: flattenedFilters.includes(INSTALLED_FILTER_OPTION) ||
    (flattenedFilters.includes(NOT_INSTALLED_FILTER_OPTION) ? false : undefined),
  })

  // pressureDataType: with pressure data -> hasPressureData = true | without pressure data -> hasPressureData = false
  // recent pressure data -> pressureDataType = true, lastRecordedDataTimeGreaterThanOrEqual = yesterdays date
  const pressureDataType = flattenedFilters.filter(filter => PRESSURE_DATA_FILTERS.includes(filter))
  switch(pressureDataType.length > 0 && pressureDataType[0]) {
    case WITH_PRESSURE_DATA: {
      queryObject.hasPressureData = true
      break
    }
    case WITHOUT_PRESSURE_DATA: {
      queryObject.hasPressureData = false
      break
    }
    case RECENT_PRESSURE_DATA: {
      const todayDate = new Date()
      todayDate.setDate(todayDate.getDate() - 1)
      queryObject.hasPressureData = true
      queryObject.lastRecordedDataTimeGreaterThanOrEqual = todayDate.toISOString()
      break
    }
    default: queryObject.hasPressureData = null
  }

  return queryObject
}
