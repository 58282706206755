import classnames from 'classnames'
import { divIcon } from 'leaflet'

/**
 * @param {number} errors - errors count
 * @param {number} warnings - warnings count
 * @param {number} information - information count
 * @param {string} countText - displayed count
 * @returns {DivIcon} icon
 */
export const renderClusterIcon = ({ errors, warnings, information, countText }) => {
  const textStyle = 'line-height: 29px; opacity: 0.9; left: 5px; top: 5px'
  const css = classnames({
    'bg-danger text-white font-weight-bold': errors > 0,
    'bg-warning text-dark font-weight-bold': errors === 0 && warnings > 0,
    'bg-info text-white font-weight-bold': errors === 0 && warnings === 0 && information > 0,
    'bg-success text-white font-weight-bold': errors === 0 && warnings === 0 && information === 0,
  })

  return divIcon({
    iconSize: [40, 40],
    iconAnchor: [20, 20],
    className: '',
    html: `<div class="position-relative w-100 h-100 text-center">
      <div class="${css} w-100 h-100 position-absolute rounded-circle" style="opacity: 0.3"></div>
      <div class="${css} w-75 h-75 position-absolute rounded-circle" style="${textStyle}">${countText}</div>
    </div>`.replace(/\s{2, }/gmi, ' '),
  })
}

/**
 * @param {number} number number to abbreviate
 * @returns {string} - abbreviated number
 */
export const abbreviateNumber = (number) => {
  if (number >= 1e3 && number < 1e6) {
    return `${Math.round(number / 1e3)}k`
  } else if (number >= 1e6 && number < 1e9) {
    return `${Math.round(number / 1e6)}m`
  } else if (number >= 1e9 && number < 1e12) {
    return `${Math.round(number / 1e9)}b`
  } else if (number >= 1e12) {
    return `${Math.round(number / 1e12)}t`
  } else {
    return `${number}`
  }
}
