const SECOND = 1000
const MINUTE = 60 * SECOND
const HOUR = 60 * MINUTE
const DAY = 24 * HOUR
const WEEK = 7 * DAY

const roundToSecundes = (ms) => ms - (ms % SECOND)
const roundToMunute = (ms) => ms - (ms % MINUTE)
const roundToHour = (ms) => ms - (ms % HOUR)
const roundToDay = (ms) => ms - (ms % DAY)

export function getPressureDataFreshnessKey(lastRecordedDataTime) {
  const recorded = lastRecordedDataTime && Math.floor(lastRecordedDataTime.getTime() / 1000)
  const now = Math.floor(Date.now() / 1000)
  switch(true) {
    case !recorded: return 'not_available'
    case recorded >= (now - DAY): return 'fresh'
    case recorded >= (now - WEEK): return 'mildly_outdated'
    case recorded >= (now - 30 * DAY): return 'slightly_outdated'
    case recorded >= (now - 356 * DAY): return 'moderately_outdated'
    default: return 'highly_outdated'
  }
}

export function getLastContactKey(lastContactTime) {
  const recorded = lastContactTime && Math.floor(lastContactTime.getTime())
  const now = Math.floor(Date.now())

  switch(true) {
    case !recorded: return { text: 'not_available', value: {} }
    case roundToSecundes(recorded) === roundToSecundes(now - MINUTE):
      return { text: 'in_last_minute', value: { } }
    case recorded > (now - HOUR):
      return { text: 'in_last_minute_plural', value: { count: Math.floor((now - recorded) / MINUTE) } }
    case (roundToMunute(recorded) === roundToMunute(now - HOUR)):
      return { text: 'in_last_hour', value: { } }
    case recorded > (now - DAY):
      return { text: 'in_last_hour_plural', value: { count: Math.floor((now - recorded) / HOUR) } }
    case (roundToHour(recorded) === roundToHour(now - DAY)):
      return { text: 'in_last_day', value: { } }
    case recorded > (now - WEEK):
      return { text: 'in_last_day_plural', value: { count: Math.floor((now - recorded) / DAY) } }
    case (roundToDay(recorded) === roundToDay(now - WEEK)):
      return { text: 'in_last_week', value: { } }
    default: return { text: 'in_last_week_plural', value: { count: Math.floor((now - recorded) / WEEK) } }
  }
}

export function getNextContactKey(nextContactTime) {
  const recorded = nextContactTime && Math.floor(nextContactTime.getTime())
  const now = Math.floor(Date.now())

  switch(true) {
    case !recorded: return { text: 'not_available', value: {} }
    case recorded <= (now + MINUTE):
      return { text: 'in_next_seconds', value: { count: Math.ceil((recorded - now) / SECOND) } }
    case recorded <= (now + MINUTE):
      return { text: 'in_next_minute', value: { } }
    case recorded < (now + HOUR):
      return { text: 'in_next_minute_plural', value: { count: Math.ceil((recorded - now) / MINUTE) } }
    case (roundToMunute(recorded) === roundToMunute(now - HOUR)):
      return { text: 'in_next_hour', value: { } }
    case recorded <= (now + DAY):
      return { text: 'in_next_hour_plural', value: { count: Math.ceil((recorded - now) / HOUR) } }
    case (roundToHour(recorded) === roundToHour(now - DAY)):
      return { text: 'in_next_day', value: { } }
    case recorded <= (now + WEEK):
      return { text: 'in_next_day_plural', value: { count: Math.ceil((recorded - now) / DAY) } }
    case (roundToDay(recorded) === roundToDay(now - WEEK)):
      return { text: 'in_next_week', value: { } }
    default: return { text: 'in_next_week_plural', value: { count: Math.ceil((recorded - now) / WEEK) } }
  }
}
