import React from 'react'
import { withTranslation } from 'react-i18next'
import ListSort from '../../../../../components/inputs/list_sort/list_sort.component'

const ALLOWED_SORT_BY_NETWORK_ASSETS = [
  'name',
  'created_at',
  'last_active_issue_time',
  'last_recorded_data_time',
  'max_active_issue_weight',
]

const NetworkAssetsListSortComponent = ({ t, filters, setFilters }) => {
  const handleOnSortChange = (newSortOptions) => {
    setFilters({ ...filters, ...newSortOptions })
  }

  return (
    <ListSort
      t={t}
      onSortChange={handleOnSortChange}
      orderBy={filters.orderBy}
      orderDirection={filters.orderDirection}
      sortOptions={ALLOWED_SORT_BY_NETWORK_ASSETS} />
  )
}

export default withTranslation([
  'v2/network_assets/filters',
  'v2/common/filters',
])(NetworkAssetsListSortComponent)
