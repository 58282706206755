import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { withTranslation } from 'react-i18next'
import { Button, UncontrolledTooltip } from 'reactstrap'

import * as analytics from '@@src/analytics'
import LoadingIcon from '@@src/components/loading_icon'
import { AppError, AsyncResult, globalSequence, sleep } from '@@src/utils'

import styles from './app_button.css'

class AppButton extends React.PureComponent {
  static propTypes = {
    onError: PropTypes.func.isRequired,
    resultDisplayPeriod: PropTypes.number.isRequired,
  }

  static defaultProps = {
    onClick: () => {},
    onError: analytics.logError,
    resultDisplayPeriod: 1500,
  }

  state = {
    result: AsyncResult.notFound(),
  }

  tooltipId = `tooltip-${globalSequence.next()}`

  render() {
    const { t, i18n, tReady, resultDisplayPeriod, ...rest } = this.props // eslint-disable-line no-unused-vars, max-len
    const { result, error } = this.state
    const state = result.status.replace('async.', '')
    const id = rest.id || this.tooltipId

    return (
      <span data-tip={t('common/text:permissions.disabled')}>
        <Button
          {...rest}
          id={id}
          onClick={this.onClick}
          data-tip={error ? error.translateWith(t) : undefined}
          className={
            classnames(styles[`button-${state}`], this.props.className)
          }>
          <div className={styles[error ? 'contents-error' : 'contents']}>
            {this.props.children}
          </div>

          <LoadingIcon className={styles.loading}/>

          <span className={styles.success}>
            <i className="far fa-check"></i>
          </span>

          <span className={styles.error}>
            <i className="fas fa-exclamation-triangle"></i>
          </span>

          {
            !error ? null : (
              <UncontrolledTooltip
                modifiers={{ flip: { enabled: false } }}
                placement="top"
                target={id}>
                {error.translateWith(t)}
              </UncontrolledTooltip>
            )
          }
        </Button>
      </span>
    )
  }

  onClick = event => {
    const { onError, resultDisplayPeriod } = this.props

    this.setState({ error: null, result: AsyncResult.pending() }, async () => {
      try {
        const res = this.props.onClick(event)

        if (res && res.then) {
          await res

          this.setState({ result: AsyncResult.success() })

          await sleep(resultDisplayPeriod)
        }

        this.setState({ result: AsyncResult.notFound() })
      } catch (e) {
        const error = AppError.from(e)
        this.setState({
          result: AsyncResult.fail(error),
        }, () => setTimeout(() => {
          this.setState({ result: AsyncResult.notFound(), error })
        }, resultDisplayPeriod))

        onError(e)
      }
    })
  }
}

export default withTranslation([])(AppButton)
