import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { Button } from 'reactstrap'

import MessageBox from '@@src/components/modals/message_box'

function AdvancedFilterModal(props) {
  const {
    t, isOpen, handleToggle, handleAccept, handleClearFilters, children,
    size,
  } = props

  return (
    <MessageBox
      size={size}
      footerContent={(
        <Button
          name="clear-filters-button"
          type="button"
          color="light"
          onClick={handleClearFilters}>
          {t('buttons.clear_filters')}
        </Button>
      )}
      toggle={handleToggle}
      isOpen={isOpen}
      onAccept={handleAccept}
      acceptText={t('buttons.apply_filters')}
      cancelText={t('buttons.cancel')}
      headerText={t('headers.filters')}>
      {children}
    </MessageBox>
  )
}

AdvancedFilterModal.propTypes = {
  t: PropTypes.func.isRequired,
  handleToggle: PropTypes.func.isRequired,
  handleAccept: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleClearFilters: PropTypes.func.isRequired,
  children: PropTypes.node,
  size: PropTypes.oneOf(['lg', 'sm']),
}

AdvancedFilterModal.defaultProps = {
  isOpen: false,
}

export default withTranslation([
  'src/components/modals/advanced_filter_modal',
])(memo(AdvancedFilterModal))
