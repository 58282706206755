import React, { useState } from 'react'
import LoadingIcon from '../../../../../../../components/loading_icon'
import { Input } from 'reactstrap'
import { Dropdown, DropdownItem, DropdownToggle, DropdownMenu } from 'reactstrap'
import { useGetGroupSuggestions } from '../../../hooks/get_group_suggestions'
import { useTranslation } from 'react-i18next'

const NetworkAssetListNewNetworkAssetModalGroupSelect = ({
  onChangeGroup = () => null,
}) => {
  const [dropdownOpened, setDropdownOpened] = useState(false)
  const toggleDropdownOpened = () => setDropdownOpened(oldValue => !oldValue)
  const [search, setSearch] = useState('')

  const { t } = useTranslation('src/components/forms/source_filter')

  const handleClick = (group) => {
    setSearch('')
    onChangeGroup(group)
  }
  const { groupSuggestions, count, loading } = useGetGroupSuggestions(search)
  return(
    <Dropdown
      direction="down"
      toggle={toggleDropdownOpened}
      isOpen={dropdownOpened}>
      <DropdownToggle tag="div" className="p-0 bg-white w-100">
        <Input
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder={t('input.search')} />
      </DropdownToggle>
      <DropdownMenu className="w-100">
        <DropdownItem
          header
          className="font-weight-normal font-italic">
          {t('text.groups_search_results_limit')}
        </DropdownItem>
        <DropdownItem
          className="font-weight-bold">
          {
            t('text.group_results', {
              results: Math.min(5, count),
              totalResults: count,
            })
          }
        </DropdownItem>
        {
          !loading ?
            groupSuggestions.map((item) => (
              <div key={item.groupId}>
                <DropdownItem
                  name="source-filter-option"
                  value={item.groupName}
                  onClick={() => handleClick(item)}
                  className={'whitespace-normal'}>
                  {item.groupName}
                </DropdownItem>
              </div>
            )) :
            <LoadingIcon className="d-flex align-items-center justify-content-center m-4" />
        }
      </DropdownMenu>
    </Dropdown>
  )
}

export default NetworkAssetListNewNetworkAssetModalGroupSelect
