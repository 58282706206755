import { unit, createUnit } from 'mathjs'
import {
  INTERNAL_DISTANCE_UNIT,
  INTERNAL_PRESSURE_UNIT,
  INTERNAL_TEMPERATURE_UNIT,
  FAHRENHEIT,
} from '@@src/utils/unit_constants'

export const PRESSURE_TYPE = 'pressure'
export const TEMPERATURE_TYPE = 'temperature'
export const DISTANCE_TYPE = 'distance'

// Convert units supplied from db into units for display
// parsing result as float necessary to prevent trailing decimal places
export const convertUnitFromDB = (
  dbValue, targetInputValueUnits, valueType, decimalPlaces = 2
) => {
  switch (valueType) {
    case PRESSURE_TYPE:
      if (targetInputValueUnits !== INTERNAL_PRESSURE_UNIT) {
        return parseFloat(unit(dbValue, INTERNAL_PRESSURE_UNIT)
          .toNumber(targetInputValueUnits).toFixed(decimalPlaces))
      } else {
        return parseFloat(dbValue.toFixed(decimalPlaces))
      }
    case DISTANCE_TYPE:
      if (targetInputValueUnits !== INTERNAL_DISTANCE_UNIT) {
        return parseFloat(unit(dbValue, INTERNAL_DISTANCE_UNIT)
          .toNumber(targetInputValueUnits).toFixed(decimalPlaces))
      } else {
        return parseFloat(dbValue.toFixed(decimalPlaces))
      }
    case TEMPERATURE_TYPE:
      if (targetInputValueUnits !== INTERNAL_TEMPERATURE_UNIT) {
        return parseFloat(unit(dbValue, INTERNAL_TEMPERATURE_UNIT)
          .toNumber(targetInputValueUnits).toFixed(decimalPlaces))
      } else {
        return parseFloat(dbValue.toFixed(decimalPlaces))
      }
    default:
      return parseFloat(dbValue.toFixed(decimalPlaces))
  }
}

export const convertUnitToDB = (
  inputValue, inputValueUnits, valueType
) => {
  switch (valueType) {
    case PRESSURE_TYPE:
      if (inputValueUnits !== INTERNAL_PRESSURE_UNIT) {
        return unit(inputValue, inputValueUnits)
          .toNumber(INTERNAL_PRESSURE_UNIT)
      } else {
        return inputValue
      }
    case DISTANCE_TYPE:
      if (inputValueUnits !== INTERNAL_DISTANCE_UNIT) {
        return unit(inputValue, inputValueUnits)
          .toNumber(INTERNAL_DISTANCE_UNIT)
      } else {
        return inputValue
      }
    case TEMPERATURE_TYPE:
      if (inputValueUnits !== INTERNAL_TEMPERATURE_UNIT) {
        return unit(inputValue, inputValueUnits)
          .toNumber(INTERNAL_TEMPERATURE_UNIT)
      } else {
        return inputValue
      }
    default:
      return inputValue
  }
}

export function convertUnit(value, fromUnit, toUnit) {
  return unit(value, fromUnit).toNumber(toUnit)
}

export const addMissingUnits = () => {
  createUnit('kpa', '1000 Pa')
  createUnit('mh2o', '1000 mmH2O')
}

export const convertAlertThreshold = (alert, pressureUnits) => {
  if (alert.areUnitsConvertable) {
    return convertUnitFromDB(
      alert.details.threshold, pressureUnits, PRESSURE_TYPE
    )
  }

  return alert.details.threshold
}

export const convertAlertUnitText = (alert, pressureUnits) => {
  if (alert.areUnitsConvertable) {
    return `common/text:units.${pressureUnits}`
  }

  return alert.unitKey
}

export const convertAlertValue = (alert, pressureUnits) => {
  if (alert.areUnitsConvertable) {
    return convertUnitFromDB(
      alert.details.value, pressureUnits, PRESSURE_TYPE
    )
  }

  return alert.details.value
}

export const getTemperatureSymbol = temperatureUnit => {
  if (temperatureUnit === FAHRENHEIT) {
    return '°F'
  } else {
    return '°C'
  }
}
