import React, { useEffect } from 'react'
import { renderSummaryGraph } from '../render_summary_graph'
import { isMobile } from '../../../utils'
export const SummaryGraph = ({
  graphData,
  colors,
  title,
  subtitle,
  className = '',
}) => {
  const isMobileDevice = isMobile()

  useEffect(() => {
    renderSummaryGraph(
      graphData,
      colors,
      title,
      subtitle,
      `.${className}`,
      isMobileDevice
    )
  }, [graphData, colors, title, subtitle, className, isMobileDevice])

  return <div name={title} className={className} />
}
