export default class Pagination {
  constructor(data) {
    this.page = data.pageNumber
    this.perPage = data.perPage
    this.totalPages = data.totalPages
    this.totalResults = data.totalResults

    Object.freeze(this)
  }

  get pageNumber() {
    return this.page
  }
}
