import React from 'react'
import {
  Form,
  Alert,
  Button,
  Row,
  Col,
} from 'reactstrap'
import gql from 'graphql-tag'
import { compose } from 'redux'
import { graphql } from '@apollo/client/react/hoc'
import {
  createSelectGraphQLResult,
  AsyncResult,
  validAssetTypes,
} from '@@src/utils'
import NetworkAsset from '@@src/api/presenters/network_asset'
import { parseGraphQLResult } from '@@src/api/presenters'
import { withTranslation } from 'react-i18next'
import ErrorInfo from '@@src/components/error_info'
import transformProps from '@@src/components/transform_props'
import AsyncResultSwitch from '@@src/components/async_result_switch'
import SubmitButton from '@@src/components/buttons/submit_button'
import AppFormGroup from '@@src/components/forms/app_form_group'
import * as analytics from '@@src/analytics'

import NetworkAssetFields from '../network_asset_fields'
import requiresLogin from '@@src/components/security/requires_login'

class EditAssetGeneral extends React.PureComponent {
  render() {
    const { networkAssetGeneral, t } = this.props
    const { result } = this.state

    return (
      <React.Fragment>
        <h2 className="h2 mb-0">
          {t('headings.general')}
        </h2>
        <hr className="mb-5" />

        {
          result.wasSuccessful() ? (
            <Alert color="success">
              {t('common/forms:text.update_successful')}
            </Alert>
          ) : null
        }
        {result.wasFailure() ? <ErrorInfo error={result.error}/> : null}

        <AsyncResultSwitch
          result={networkAssetGeneral}
          renderSuccessResult={this.renderSettingsSelectors} />
      </React.Fragment>
    )
  }

  constructor(props) {
    super(props)

    this.fields = new NetworkAssetFields(this, 'settingsFields', {
      assetType: v => v ? '' : 'errors.required',
      assetId: v => v ? '' : 'errors.required',
    })

    this.state = {
      result: AsyncResult.notFound(),
      settingsFields: this.fields.initialState(),
      fetching: false,
    }
  }

  async componentDidUpdate(prevProps) {
    const { networkAssetGeneral } = this.props
    const { fetching } = this.state

    if(
      prevProps.networkAssetGeneral.isPending()
      && networkAssetGeneral.wasSuccessful()
      || fetching && networkAssetGeneral.wasSuccessful()
    ) {
      const {
        data: {
          assetId,
          comment,
          assetName,
          assetType,
          customLabels,
        },
      } = networkAssetGeneral

      await this.setState({
        fetching: false,
        settingsFields: this.fields.initialState({
          assetId,
          comment: comment || '',
          assetName: assetName || '',
          assetType,
          customLabels: customLabels || '',
        }),
      })
    }
  }

  selectFieldValue(fieldName) {
    return this.fields.selectValue(this.state, fieldName)
  }

  selectFieldErrorText(fieldName) {
    const error = this.fields.selectError(this.state, fieldName)
    return error ? this.props.t(error) : error
  }

  onSubmitForm = async event => {
    const { refetchData } = this.props
    const { id } = this.props.networkAssetGeneral.data
    event.preventDefault()
    await this.setState({ result: AsyncResult.pending() })

    try {
      const mutationVariables = [
        'comment',
        'assetId',
        'assetName',
        'customLabels',
        'assetType',
      ].reduce(
        (o, key) => Object.assign(o, {
          [key]: this.selectFieldValue(key) === '' ?
            null : this.selectFieldValue(key),
        }), {}
      )

      const assetType = this.selectFieldValue('assetType')
      const assetId = this.selectFieldValue('assetId')
      const comment = this.selectFieldValue('comment')
      const assetName = this.selectFieldValue('assetName')
      const customLabels = this.selectFieldValue('customLabels')

      await this.props.editAssetGeneral({
        variables: {
          id,
          ...mutationVariables,
        },
      })
      await this.setState({
        result: AsyncResult.success(),
        settingsFields: this.fields.initialState({
          assetType,
          assetId,
          comment,
          assetName,
          customLabels,
        }),
      })
      await refetchData()
    } catch (e) {
      analytics.logError(e)
      this.setState({ result: AsyncResult.fail(e) })
    }
  }

  onResetForm = async event => {
    event.preventDefault()
    await this.setState({ fetching: true })
  }

  renderSettingsSelectors = () => {
    const { t, networkAsset } = this.props
    const { result } = this.state

    const hasInstallations = networkAsset &&
      Array.isArray(networkAsset.installations) &&
      networkAsset.installations.length > 0

    return (
      <React.Fragment>
        <Form className="pb-3" name="network-asset-general">
          <Row>
            <Col sm="5">

              <AppFormGroup
                disabled={hasInstallations}
                hint={
                  hasInstallations ?
                    t('text.asset_type_has_installations') : undefined
                }
                id="network-asset-type"
                name="network-asset-type"
                type="select"
                label={t('labels.asset_type_label')}
                value={this.selectFieldValue('assetType')}
                onChange={this.fields.onChangeHandlerFor('assetType')}
                required={true}
                errorText={this.selectFieldErrorText('assetType')}>
                <option>
                  {t('text.asset_type_option_blank')}
                </option>

                {
                  validAssetTypes().map(assetType => (
                    <option key={assetType} value={assetType}>
                      {t(`common/text:text.${assetType}`)}
                    </option>
                  ))
                }
              </AppFormGroup>

              <AppFormGroup
                maxLength={NetworkAsset.MAX_ASSET_ID_LENGTH}
                id="network-asset-id"
                name="network-asset-id"
                type="text"
                label={t('labels.asset_id_label')}
                value={this.selectFieldValue('assetId')}
                onChange={this.fields.onChangeHandlerFor('assetId')}
                required={true}
                errorText={this.selectFieldErrorText('assetId')}/>

              <AppFormGroup
                id="network-asset-name"
                name="network-asset-name"
                type="text"
                hint={t('text.asset_name_hint')}
                label={t('labels.asset_name')}
                value={this.selectFieldValue('assetName') || ''}
                onChange={this.fields.onChangeHandlerFor('assetName')}
                errorText={this.selectFieldErrorText('assetName')}/>

              <AppFormGroup
                id="network-asset-custom-labels"
                name="network-asset-custom-labels"
                type="text"
                label={t('labels.custom_labels')}
                value={this.selectFieldValue('customLabels')}
                onChange={this.fields.onChangeHandlerFor('customLabels')}
                errorText={this.selectFieldErrorText('customLabels')}
                placeholder={t('text.custom_labels_placeholder')}/>

              <AppFormGroup
                id="network-asset-comments"
                name="network-asset-comments"
                rows={4}
                type="textarea"
                label={t('labels.comment')}
                value={this.selectFieldValue('comment')}
                onChange={this.fields.onChangeHandlerFor('comment')}
                errorText={this.selectFieldErrorText('comment')}/>

              <div className="mt-5">
                <SubmitButton
                  name="submit-changes"
                  color="primary"
                  submitText={t('common/forms:buttons.save')}
                  disabled={this.fields.isPristine()}
                  result={result}
                  onSubmitForm={this.onSubmitForm}
                />

                <Button
                  name="discard-changes"
                  color="secondary"
                  disabled={this.fields.isPristine()}
                  className="ml-2"
                  onClick={this.onResetForm}
                >
                  { t('common/forms:buttons.discard_changes') }
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    )
  }

  static GET_ASSET_GENERAL = gql`
    query NetworkAssetById($id: Int!) {
      networkAsset(id: $id) {
        id
        assetId
        comment
        assetName
        assetType
        customLabels
      }
    }
  `

  static EDIT_ASSET_GENERAL = gql`
    mutation EditNetworkAsset(
      $id: Int!,
      $assetId: String!,
      $comment: String,
      $assetName: String,
      $assetType: InflowNetAssetType,
      $customLabels: String,
    ) {
      editNetworkAsset(
        id: $id,
        comment: $comment,
        assetId: $assetId,
        assetName: $assetName,
        assetType: $assetType,
        customLabels: $customLabels,
      )
    }
  `
}

export default compose(
  requiresLogin,
  withTranslation([
    // eslint-disable-next-line
    'src/management_path/network_assets_path/edit_network_asset_page_sections/edit_general_configuration_section',
    'common/text',
    'common/forms',
    'common/errors',
  ]),
  transformProps(() => () => ({
    selectAssetGeneralResult: createSelectGraphQLResult(
      'networkAsset',
      {
        mapResult: parseGraphQLResult,
      }
    ),
  })),
  graphql(EditAssetGeneral.GET_ASSET_GENERAL, {
    options: ({ assetId }) => {
      return {
        fetchPolicy: 'network-only',
        variables: {
          id: assetId,
        },
      }
    },
    props: ({ data, ownProps }) => {
      const { selectAssetGeneralResult } = ownProps
      const { refetch } = data
      return {
        refetchData: () => refetch(data.variables),
        networkAssetGeneral: selectAssetGeneralResult(data),
      }
    },
  }),
  graphql(EditAssetGeneral.EDIT_ASSET_GENERAL, {
    name: 'editAssetGeneral',
  })
)(EditAssetGeneral)
