import ErrorInfo from '@@src/components/error_info'
import LoadingIcon from '@@src/components/loading_icon'
import AsyncResult from '@@src/utils/async_result'
import PropTypes from 'prop-types'
import React from 'react'
import styles from './async_result_switch.css'

const AsyncResultSwitch = ({
  result,
  renderSuccessResult,
  renderFailResult,
  renderPendingResult,
  renderNotFoundResult,
}) => {
  switch (result.status) {
    case AsyncResult.SUCCESS:
      return renderSuccessResult(result)

    case AsyncResult.PENDING:
      return renderPendingResult(result)

    case AsyncResult.FAIL:
      return renderFailResult(result)

    case AsyncResult.NOT_FOUND:
      return renderNotFoundResult(result)

    default:
      throw new Error(`Unhandled status: "${result.status}"`)
  }
}

AsyncResultSwitch.defaultProps = {
  renderFailResult: defaultRenderFailResult,
  renderPendingResult: defaultRenderPendingResult,
  renderNotFoundResult: defaultRenderNotFoundResult,
}

AsyncResultSwitch.propTypes = {
  result: PropTypes.instanceOf(AsyncResult).isRequired,
  renderFailResult: PropTypes.func.isRequired,
  renderPendingResult: PropTypes.func.isRequired,
  renderSuccessResult: PropTypes.func.isRequired,
  renderNotFoundResult: PropTypes.func.isRequired,
  skeleton: PropTypes.node,
}

function defaultRenderFailResult({ error }) {
  return <ErrorInfo error={error} />
}

function defaultRenderPendingResult() {
  return (
    <div className={styles.loading}>
      <LoadingIcon />
    </div>
  )
}

function defaultRenderNotFoundResult() {
  return <p>Resource not found</p>
}

export default AsyncResultSwitch
