
import React from 'react'
import { renderToString } from 'react-dom/server'
import { GeoJSON, LatLng } from 'leaflet'
import {
  markerClusterGroup,
  divIcon,
  Point,
} from 'leaflet'

export const HIGH_ZOOM_LEVEL = 18
export const MEDIUM_ZOOM_LEVEL = 15
export const LOW_ZOOM_LEVEL = 10
export const HIDE_ELEMENTS_ZOOM_LEVEL = 6

export const POINT = 'Point'
export const MULTI_POINT = 'MultiPoint'
export const LINE_STRING = 'LineString'
export const MULTI_LINE_STRING = 'MultiLineString'
export const POLYGON = 'Polygon'
export const MULTY_POLYGON = 'MultiPolygon'
export const GEOMETRY_COLLECTION = 'GeometryCollection'

export const ONE_POSITION_GEOMETRY = [
  POINT,
]
export const MULTI_POSITION_GEOMETRY = [
  MULTI_POINT,
  LINE_STRING,
]
export const MANY_MULTI_POSITION_GEOMETRY = [
  POLYGON,
  MULTI_LINE_STRING,
]
export const ARRAY_MANY_MULTY_POSITION_GEOMETRY = [
  MULTY_POLYGON,
]

export const ALL_FEATURE_GEOMETRY_TYPES = [
  POINT,
  MULTI_POINT,
  LINE_STRING,
  MULTI_LINE_STRING,
  POLYGON,
  MULTY_POLYGON,
  GEOMETRY_COLLECTION,
]

export const zoomLevels = [
  HIGH_ZOOM_LEVEL,
  MEDIUM_ZOOM_LEVEL,
  LOW_ZOOM_LEVEL,
  HIDE_ELEMENTS_ZOOM_LEVEL,
]

const markerStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  background: '#42f560',
  borderRadius: '50%',
}

const markerStyleInner = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '80%',
  height: '80%',
  background: '#42b3f5',
  borderRadius: '50%',
}

export function getMarkerClusterGroupLayer() {
  return markerClusterGroup({
    disableClusteringAtZoom: MEDIUM_ZOOM_LEVEL + 1,
    spiderfyOnMaxZoom: false,
    iconCreateFunction: (cluster) => {
      let childCount = cluster.getChildCount()
      let clusterSize = 20
      while(childCount > 0) {
        childCount = Math.floor(childCount / 10)
        clusterSize += 5
      }
      return divIcon({
        html: renderToString(
        <div style={markerStyle}>
          <div style={markerStyleInner}>
            <span style={{ color: 'white' }}>
              {cluster.getChildCount()}
            </span>
          </div>
        </div>
        ),
        className: '',
        iconSize: new Point(clusterSize, clusterSize),
      })
    },
  })
}

export function getFeatureCoordinatesAndMinMaxLongLat(geometry) {
  const latitude = [100, -100]
  const longitude = [100, -100]
  let coordinates

  switch(true) {
    case ONE_POSITION_GEOMETRY.includes(geometry.type): {
      coordinates = GeoJSON.coordsToLatLng(geometry.coordinates)
      saveMinMaxValuesForLonLat(latitude, longitude, [coordinates.lat, coordinates.lng])
      break
    }
    case MULTI_POSITION_GEOMETRY.includes(geometry.type): {
      coordinates = GeoJSON.coordsToLatLngs(geometry.coordinates, 0, (coordinate) => {
        const newCoords = new LatLng(coordinate[1], coordinate[0], coordinate[2])
        saveMinMaxValuesForLonLat(latitude, longitude, [newCoords.lat, newCoords.lng])
        return newCoords
      })
      break
    }
    case MANY_MULTI_POSITION_GEOMETRY.includes(geometry.type): {
      coordinates = GeoJSON.coordsToLatLngs(geometry.coordinates, 1, (coordinate) => {
        const newCoords = new LatLng(coordinate[1], coordinate[0], coordinate[2])
        saveMinMaxValuesForLonLat(latitude, longitude, [newCoords.lat, newCoords.lng])
        return newCoords
      })
      break
    }
    case ARRAY_MANY_MULTY_POSITION_GEOMETRY.includes(geometry.type): {
      coordinates = GeoJSON.coordsToLatLngs(geometry.coordinates, 2, (coordinate) => {
        const newCoords = new LatLng(coordinate[1], coordinate[0], coordinate[2])
        saveMinMaxValuesForLonLat(latitude, longitude, [newCoords.lat, newCoords.lng])
        return newCoords
      })
      break
    }
  }

  return {
    coordinates: coordinates,
    minMaxLatitude: latitude,
    minMaxLongitude: longitude,
  }
}

export function saveMinMaxValuesForLonLat(latitude, longitude, newObject) {
  longitude[0] = longitude[0] < newObject[0] ? longitude[0] : newObject[0]
  longitude[1] = longitude[1] >= newObject[0] ? longitude[1] : newObject[0]
  latitude[0] = latitude[0] < newObject[1] ? latitude[0] : newObject[1]
  latitude[1] = latitude[1] >= newObject[1] ? latitude[1] : newObject[1]
}

export function midWayLngLat(first, second) {
  return [(first[0] + second[0]) / 2, (first[1] + second[1]) / 2]
}

export function createSimpleHeader(title) {
  return(
    renderToString(
    <div className="w-100 d-flex justify-content-center align-items-center">
      <header>{title}</header>
    </div>
    )
  )
}

export function mapObjectEnteriesToParagraphs(popupInfo) {
  return(
    renderToString(
        Object.entries(popupInfo).map(infoPair => {
          return <p>{infoPair[0]}: {infoPair[1]}</p>
        })
    )
  )
}
