import { NetworkAssetForDeviceInstallModal, NetworkAssetForListItem } from './models/network_asset_for_list_item'
import { NetworkAssetForMapItem } from './models/network_asset_for_map_item'
import { Pagination } from './models/pagination'
import {
  GET_NETWORK_ASSETS_FOR_LIST_QUERY,
  GET_NETWORK_ASSETS_FOR_LIST_ITEM_QUERY,
  GET_NETWORK_ASSETS_FOR_MAP_QUERY,
  GET_NETWORK_ASSETS_WITH_INSTALLATIONS,
} from './queries/network_asset'

/**
 * @param {object} client - graphql apollo client
 * @param {AbortController} abortController - abort controller
 * @param {number} networkAssetId - network asset id
 * @returns {Promise<{networkAsset: NetworkAssetForListItem}>} network asset
 */
export const getNetworkAssetsForListItemCall = ({ client, abortController, networkAssetId }) =>
  client
    .query({
      query: GET_NETWORK_ASSETS_FOR_LIST_ITEM_QUERY,
      variables: {
        networkAssetId,
        time: Date.now(),
      },
      fetchPolicy: 'network-only',
      context: { fetchOptions: { signal: abortController.signal } },
    })
    .then(result => {
      const { networkAsset } = result && result.data && result.data.getNetworkAssetsListItem || {}

      return {
        networkAsset: networkAsset ? new NetworkAssetForListItem(networkAsset) : null,
      }
    })

/**
 * @param {object} client - graphql apollo client
 * @param {AbortController} abortController - abort controller
 * @param {object} filters - filters
 * @param {string} filters.search - filters search
 * @param {GeoBounds} filters.inBounds - filters search
 * @param {string[]} filters.assetTypeIn - filters asset type in
 * @param {string[]} filters.issueTypeIn - filters issue type in
 * @param {string[]} filters.installations - filters  installations
 * @param {string[]} filters.issueSeverityIn - filters issue severity in
 * @param {object} order - order
 * @param {string} order.by - order by
 * @param {string} order.direction - order direction
 * @param {object} pagination pagination model
 * @param {number|undefined} pagination.pageNumber page number
 * @param {number|undefined} pagination.resultsPerPage page limit
 * @returns {Promise<{networkAssets:NetworkAssetForListItem[],pagination:Pagination}>} promise
 */
export const getNetworkAssetsForListCall = ({ client, abortController, filters, order, pagination }) =>
  client
    .query({
      query: GET_NETWORK_ASSETS_FOR_LIST_QUERY,
      variables: {
        pageNumber: pagination.pageNumber,
        resultsPerPage: pagination.resultsPerPage,
        orderBy: order.by,
        orderDirection: order.direction,
        filters,
        time: Date.now(),
      },
      fetchPolicy: 'network-only',
      context: { fetchOptions: { signal: abortController.signal } },
    })
    .then(response => {
      const result = response && response.data && response.data.getNetworkAssetsList || {}
      const { networkAssets, pagination: paginationResponse } = result

      return {
        networkAssets: Array.isArray(networkAssets)
          ? networkAssets.map(item => new NetworkAssetForListItem(item))
          : [],
        pagination: paginationResponse ? Pagination.fromResponse(paginationResponse) : null,
      }
    })

/**
 * @param {object} client - graphql apollo client
 * @param {AbortController} abortController - abort controller
 * @param {object} filters - filters
 * @param {string} filters.search - filters search
 * @param {string[]} filters.assetTypeIn - filters asset type in
 * @param {string[]} filters.issueTypeIn - filters issue type in
 * @param {string[]} filters.installations - filters  installations
 * @param {string[]} filters.issueSeverityIn - filters issue severity in
 * @returns {Promise<{networkAssets:NetworkAssetForMapItem[],meta}>} promise
 */
export const getNetworkAssetsForMapCall = ({ client, abortController, filters }) =>
  client
    .query({
      query: GET_NETWORK_ASSETS_FOR_MAP_QUERY,
      variables: { filters, time: Date.now() },
      fetchPolicy: 'network-only',
      context: { fetchOptions: { signal: abortController.signal } },
    })
    .then(result => {
      const { networkAssets, meta } = result && result.data && result.data.getNetworkAssetsList || {}

      return {
        meta,
        networkAssets: Array.isArray(networkAssets)
          ? networkAssets.map(item => new NetworkAssetForMapItem(item))
          : [],
      }
    })

/**
 * @param {object} client - graphql apollo client
 * @param {AbortController} abortController - abort controller
 * @param {object} filters - filters
 * @param {string} filters.search - filters search
 * @param {string[]} filters.assetTypeIn - filters asset type in
 * @param {string[]} filters.issueTypeIn - filters issue type in
 * @param {string[]} filters.installations - filters  installations
 * @param {string[]} filters.issueSeverityIn - filters issue severity in
 * @returns {Promise<{networkAssets:NetworkAssetForMapItem[],meta}>} promise
 */
export const getNetworkAssetsForInstallDeviceModal = ({ client, abortController, filters }) =>
  client
    .query({
      query: GET_NETWORK_ASSETS_WITH_INSTALLATIONS,
      variables: { filters, time: Date.now() },
      fetchPolicy: 'network-only',
      context: { fetchOptions: { signal: abortController.signal } },
    })
    .then(result => {
      const { networkAssets } = result && result.data && result.data.getNetworkAssetsList || {}

      return Array.isArray(networkAssets)
        ? networkAssets.map(item => new NetworkAssetForDeviceInstallModal(item))
        : []
    })
