/* eslint-disable max-len */
import eovMarkerIconPrimary from '@@src/static_assets/svgs/network_asset_eov_map_marker.svg'
import prvMarkerIconPrimary from '@@src/static_assets/svgs/network_asset_prv_map_marker.svg'
import psvMarkerIconPrimary from '@@src/static_assets/svgs/network_asset_psv_map_marker.svg'
import pipeMarkerIconPrimary from '@@src/static_assets/svgs/network_asset_pipe_map_marker.svg'
import pumpMarkerIconPrimary from '@@src/static_assets/svgs/network_asset_pump_map_marker.svg'
import tankMarkerIconPrimary from '@@src/static_assets/svgs/network_asset_tank_map_marker.svg'
import meterMarkerIconPrimary from '@@src/static_assets/svgs/network_asset_meter_map_marker.svg'
import hydrantMarkerIconPrimary from '@@src/static_assets/svgs/network_asset_hydrant_map_marker.svg'
import tappingMarkerIconPrimary from '@@src/static_assets/svgs/network_asset_tapping_map_marker.svg'
import reservoirMarkerIconPrimary from '@@src/static_assets/svgs/network_asset_reservoir_map_marker.svg'
import bvMarkerIconPrimary from '@@src/static_assets/svgs/network_asset_bv_map_marker.svg'
import ivMarkerIconPrimary from '@@src/static_assets/svgs/network_asset_iv_map_marker.svg'
import fcvMarkerIconPrimary from '@@src/static_assets/svgs/network_asset_fcv_map_marker.svg'
import airvalveMarkerIconPrimary from '@@src/static_assets/svgs/network_asset_airvalve_map_marker.svg'
import unknownMarkerIconPrimary from '@@src/static_assets/svgs/network_asset_unknown_map_marker.svg'

import eovMarkerIconSteel from '@@src/static_assets/svgs/network_asset_eov_map_marker_steel.svg'
import prvMarkerIconSteel from '@@src/static_assets/svgs/network_asset_prv_map_marker_steel.svg'
import psvMarkerIconSteel from '@@src/static_assets/svgs/network_asset_psv_map_marker_steel.svg'
import pipeMarkerIconSteel from '@@src/static_assets/svgs/network_asset_pipe_map_marker_steel.svg'
import pumpMarkerIconSteel from '@@src/static_assets/svgs/network_asset_pump_map_marker_steel.svg'
import tankMarkerIconSteel from '@@src/static_assets/svgs/network_asset_tank_map_marker_steel.svg'
import meterMarkerIconSteel from '@@src/static_assets/svgs/network_asset_meter_map_marker_steel.svg'
import hydrantMarkerIconSteel from '@@src/static_assets/svgs/network_asset_hydrant_map_marker_steel.svg'
import tappingMarkerIconSteel from '@@src/static_assets/svgs/network_asset_tapping_map_marker_steel.svg'
import reservoirMarkerIconSteel from '@@src/static_assets/svgs/network_asset_reservoir_map_marker_steel.svg'
import bvMarkerIconSteel from '@@src/static_assets/svgs/network_asset_bv_map_marker_steel.svg'
import ivMarkerIconSteel from '@@src/static_assets/svgs/network_asset_iv_map_marker_steel.svg'
import fcvMarkerIconSteel from '@@src/static_assets/svgs/network_asset_fcv_map_marker_steel.svg'
import airvalveMarkerIconSteel from '@@src/static_assets/svgs/network_asset_airvalve_map_marker_steel.svg'
import unknownMarkerIconSteel from '@@src/static_assets/svgs/network_asset_unknown_map_marker_steel.svg'

import eovIcon from '@@src/static_assets/svgs/network_asset_icon_eov.svg'
import prvIcon from '@@src/static_assets/svgs/network_asset_icon_prv.svg'
import psvIcon from '@@src/static_assets/svgs/network_asset_icon_psv.svg'
import pipeIcon from '@@src/static_assets/svgs/network_asset_icon_pipe.svg'
import pumpIcon from '@@src/static_assets/svgs/network_asset_icon_pump.svg'
import tankIcon from '@@src/static_assets/svgs/network_asset_icon_tank.svg'
import meterIcon from '@@src/static_assets/svgs/network_asset_icon_meter.svg'
import hydrantIcon from '@@src/static_assets/svgs/network_asset_icon_hydrant.svg'
import tappingIcon from '@@src/static_assets/svgs/network_asset_icon_tapping.svg'
import reservoirIcon from '@@src/static_assets/svgs/network_asset_icon_reservoir.svg'
import bvIcon from '@@src/static_assets/svgs/network_asset_icon_bv.svg'
import ivIcon from '@@src/static_assets/svgs/network_asset_icon_iv.svg'
import fcvIcon from '@@src/static_assets/svgs/network_asset_icon_fcv.svg'
import airvalveIcon from '@@src/static_assets/svgs/network_asset_icon_airvalve.svg'
import unknownIcon from '@@src/static_assets/svgs/network_asset_icon_unknown.svg'
/* eslint-enable max-len */

export const PRIMARY_ICON_COLOUR = 'primary'
export const STEEL_ICON_COLOUR = 'steel'

export function mapTypeAndColourToMarkerIcon(colour, assetType) {
  switch(colour) {
    case STEEL_ICON_COLOUR:
      return mapTypeToSteelColourMarkerIcon(assetType)
    case PRIMARY_ICON_COLOUR:
    default:
      return mapTypeToPrimaryColourMarkerIcon(assetType)
  }
}

export function mapTypeToIcon(assetType) {
  switch (assetType) {
    case 'eov':
      return eovIcon

    case 'prv':
    case 'congruity_pressure_reducing_valve':
    case 'congruity_pressure_breaker_valve':
    case 'congruity_check_valve':
    case 'congruity_throttle_control_valve':
    case 'congruity_general_purpose_valv':
      return prvIcon

    case 'psv':
    case 'congruity_pressure_sustaining_valve':
      return psvIcon

    case 'pipe':
      return pipeIcon

    case 'pump':
    case 'congruity_constant_speed_pump':
    case 'congruity_variable_speed_pump':
      return pumpIcon

    case 'tank':
    case 'congruity_tank':
      return tankIcon

    case 'meter':
    case 'congruity_flow_meter':
    case 'congruity_revenue_meter':
    case 'congruity_district_meter':
    case 'congruity_shared_waste_meter':
    case 'congruity_strategic_meter':
    case 'congruity_supply_meter':
    case 'congruity_waste_meter':
      return meterIcon

    case 'hydrant':
    case 'congruity_fire_hydrant':
    case 'congruity_wash_out':
      return hydrantIcon

    case 'tapping':
      return tappingIcon

    case 'reservoir':
    case 'congruity_reservoir':
      return reservoirIcon

    case 'boundary_valve':
    case 'congruity_capped_end':
    case 'congruity_stop_cock':
      return bvIcon

    case 'isolation_valve':
      return ivIcon

    case 'flow_control_valve':
    case 'congruity_flow_control_valve':
      return fcvIcon

    case 'air_valve':
    case 'congruity_air_valve':
      return airvalveIcon

    default:
      return unknownIcon
  }
}

function mapTypeToPrimaryColourMarkerIcon(assetType) {
  switch (assetType) {
    case 'eov':
      return eovMarkerIconPrimary

    case 'prv':
    case 'congruity_pressure_reducing_valve':
    case 'congruity_pressure_breaker_valve':
    case 'congruity_check_valve':
    case 'congruity_throttle_control_valve':
    case 'congruity_general_purpose_valv':
      return prvMarkerIconPrimary

    case 'psv':
    case 'congruity_pressure_sustaining_valve':
      return psvMarkerIconPrimary

    case 'pipe':
      return pipeMarkerIconPrimary

    case 'pump':
    case 'congruity_constant_speed_pump':
    case 'congruity_variable_speed_pump':
      return pumpMarkerIconPrimary

    case 'tank':
    case 'congruity_tank':
      return tankMarkerIconPrimary

    case 'meter':
    case 'congruity_flow_meter':
    case 'congruity_revenue_meter':
    case 'congruity_district_meter':
    case 'congruity_shared_waste_meter':
    case 'congruity_strategic_meter':
    case 'congruity_supply_meter':
    case 'congruity_waste_meter':
      return meterMarkerIconPrimary

    case 'hydrant':
    case 'congruity_fire_hydrant':
    case 'congruity_wash_out':
      return hydrantMarkerIconPrimary

    case 'tapping':
      return tappingMarkerIconPrimary

    case 'reservoir':
    case 'congruity_reservoir':
      return reservoirMarkerIconPrimary

    case 'boundary_valve':
    case 'congruity_capped_end':
    case 'congruity_stop_cock':
      return bvMarkerIconPrimary

    case 'isolation_valve':
      return ivMarkerIconPrimary

    case 'flow_control_valve':
    case 'congruity_flow_control_valve':
      return fcvMarkerIconPrimary

    case 'air_valve':
    case 'congruity_air_valve':
      return airvalveMarkerIconPrimary

    default:
      return unknownMarkerIconPrimary
  }
}

function mapTypeToSteelColourMarkerIcon(assetType) {
  switch (assetType) {
    case 'eov':
      return eovMarkerIconSteel

    case 'prv':
    case 'congruity_pressure_reducing_valve':
    case 'congruity_pressure_breaker_valve':
    case 'congruity_check_valve':
    case 'congruity_throttle_control_valve':
    case 'congruity_general_purpose_valv':
      return prvMarkerIconSteel

    case 'psv':
    case 'congruity_pressure_sustaining_valve':
      return psvMarkerIconSteel

    case 'pipe':
      return pipeMarkerIconSteel

    case 'pump':
    case 'congruity_constant_speed_pump':
    case 'congruity_variable_speed_pump':
      return pumpMarkerIconSteel

    case 'tank':
    case 'congruity_tank':
      return tankMarkerIconSteel

    case 'meter':
    case 'congruity_flow_meter':
    case 'congruity_revenue_meter':
    case 'congruity_district_meter':
    case 'congruity_shared_waste_meter':
    case 'congruity_strategic_meter':
    case 'congruity_supply_meter':
    case 'congruity_waste_meter':
      return meterMarkerIconSteel

    case 'hydrant':
    case 'congruity_fire_hydrant':
    case 'congruity_wash_out':
      return hydrantMarkerIconSteel

    case 'tapping':
      return tappingMarkerIconSteel

    case 'reservoir':
    case 'congruity_reservoir':
      return reservoirMarkerIconSteel

    case 'boundary_valve':
    case 'congruity_capped_end':
    case 'congruity_stop_cock':
      return bvMarkerIconSteel

    case 'isolation_valve':
      return ivMarkerIconSteel

    case 'flow_control_valve':
    case 'congruity_flow_control_valve':
      return fcvMarkerIconSteel

    case 'air_valve':
    case 'congruity_air_valve':
      return airvalveMarkerIconSteel

    default:
      return unknownMarkerIconSteel
  }
}
