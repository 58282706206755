import React from 'react'

export default function EventsEndIcon({ color }) {
  return(
    <>
      <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12 1c6.075 0 11 4.925 11 11s-4.925 11-11 11S1 18.075 1 12 5.925 1 12 1z"
          fill="white"
          fillOpacity={1}
          fillRule="evenodd"/>
      </svg>
      <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10.875 13.125V16a1.125 1.125 0 0 0 2.25 0V8c0-1.452-2.107-1.498-2.243-.14l-.007.14v2.875H1.057C1.62
          5.33 6.304 1 12 1c6.075 0 11 4.925 11 11s-4.925 11-11 11c-5.695 0-10.38-4.328-10.943-9.875h9.818z"
          fill={color}
          fillRule="evenodd"/>
      </svg>
    </>
  )
}
