import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledTooltip,
} from 'reactstrap'

import * as analytics from '@@src/analytics'
import { DAYS, HOURS, MINUTES, SECONDS } from '@@src/utils'
import TenantLicenceFeatureGate, { EXPORT_RAW_DATA_FEATURE } from
'@@src/components/tenant_licence/tenant_licence_feature_gate'

import styles from './drag_area_summary_metrics.css'

class DragAreaSummaryMetrics extends React.PureComponent {
  static propTypes = {
    getLegendItemColor: PropTypes.func.isRequired,
    metrics: PropTypes.shape({
      timeRange: PropTypes.array.isRequired,
      summaries: PropTypes.array.isRequired,
    }).isRequired,
    handleClickOutsidePopup: PropTypes.func.isRequired,
    closestLegendItemId: PropTypes.string.isRequired,
    formatX: PropTypes.func.isRequired,
    onCopyBoundsIntoLink: PropTypes.func.isRequired,
    setRawDataModalOpen: PropTypes.func.isRequired,
    setTransientLocalisationModalOpen: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    dataUnits: PropTypes.node,
  }

  static defaultProps = {
    t: a => a,
  }

  state = {
    selectionDropdownVisible: false,
  }

  render() {
    const {
      metrics,
      formatX,
      dataUnits,
      t,
      closestLegendItemId,
      getLegendItemColor,
      handleClickOutsidePopup,
    } = this.props
    const { selectionDropdownVisible } = this.state

    return (
      <table className="w-100">
        <thead>
          <tr>
            <th
              colSpan="8"
              className={styles['ruler-date-measurement']}>
              {formatX(Math.min(...metrics.timeRange))}
              &nbsp;
              <i className="far fa-arrow-right"></i>
              &nbsp;
              {formatX(Math.max(...metrics.timeRange))}

              <i
                className={
                  classnames('far fa-times', styles['close-button'])
                }
                onClick={handleClickOutsidePopup}></i>
            </th>
          </tr>

          <tr>
            <th colSpan="8" className={styles['ruler-data-units']}>
              {dataUnits}
            </th>
          </tr>

          <tr className={styles['ruler-data-column-headings-row']}>
            <th colSpan="4"></th>
            <th className="text-right">
              {t('labels.mean')}
            </th>
            <th className="text-right">
              {t('labels.min')}
            </th>
            <th className="text-right">
              {t('labels.max')}
            </th>
            <th className="text-right">
              {t('labels.range')}
            </th>
          </tr>
        </thead>

        <tbody>
          {metrics.summaries.map(({ graphItem, summary }) => (
            <tr key={graphItem.id} className={classnames({
              ['font-weight-bold']:
                graphItem.legendItem.id === closestLegendItemId,
            })}>
              <td>
                <div
                  style={{
                    backgroundColor: getLegendItemColor(
                      graphItem.legendItem
                    ),
                  }}
                  className={
                    styles['ruler-data-table-line-preview']
                  }/>
              </td>
              <td colSpan="3">
                {graphItem.legendItem
                  .legendItemNameAndChannelText(t)}
              </td>

              <td className="text-right">
                {formatNumber(summary.mean)}
              </td>
              <td className="text-right">
                {formatNumber(summary.minPoint.value)}
              </td>
              <td className="text-right">
                {formatNumber(summary.maxPoint.value)}
              </td>
              <td className="text-right">
                {formatNumber(
                  summary.maxPoint.value - summary.minPoint.value
                )}
              </td>
            </tr>
          ))}

          <tr>
            <td
              colSpan="4"
              className={styles['ruler-data-duration']}>
              <i className="fal fa-clock"></i>
              &nbsp;
              {formatDuration(t, metrics.timeRange)}
            </td>

            <td colSpan="4" className="text-right">
              <ButtonDropdown
                name="dragged-area-dropdown-btn"
                size={'sm'}
                isOpen={selectionDropdownVisible}
                toggle={this.onToggle}>
                <DropdownToggle caret
                  // eslint-disable-next-line max-len
                  className="mt-2 border-secondary text-white bg-dark">
                  <span className={styles['dropdown-toggle']}>
                    {t('buttons.actions')}
                  </span>
                </DropdownToggle>
                {selectionDropdownVisible ? this.renderDropdownItems() : null}
              </ButtonDropdown>

            </td>
          </tr>
        </tbody>
      </table>
    )
  }

  onToggle = () => {
    const { selectionDropdownVisible } = this.state
    this.setState({
      selectionDropdownVisible: !selectionDropdownVisible,
    })
  }

  renderDropdownItems = () => {
    const { t } = this.props
    return (
      <DropdownMenu>
        <li className="dropdown-header">
          {t('headers.selection')}
        </li>
        <DropdownItem
          className={styles['dropdown-item']}
          key={'copy-link'}
          onClick={this.onClickCreateShareableLink}>
          <React.Fragment>
            <i className="far fa-link"></i>
            &nbsp;
            {t('listItems.copy_link')}
          </React.Fragment>
        </DropdownItem>
        <DropdownItem
          className={styles['dropdown-item']}
          key={'123'}
          onClick={this.onRawDataClick}>
          <React.Fragment>
            <i className={styles['dropdown-option-icon']} />
            &nbsp;
            {t('listItems.request_raw_data')}
          </React.Fragment>
        </DropdownItem>
        <TenantLicenceFeatureGate
          requiredTenantLicenceFeature={EXPORT_RAW_DATA_FEATURE}
          renderInactiveLicenceFeatureChild={
            this.renderDisabledTransientLocalisation
          }>
          <DropdownItem
            name="request-localisation-dropdown-item"
            className={styles['dropdown-item']}
            key={'trans-location-link'}
            onClick={this.onTransientLocalisationClick}>
            <i className="fas fa-bullseye-arrow"></i>
            &nbsp;
            {t('listItems.transient_localisation')}
          </DropdownItem>
        </TenantLicenceFeatureGate>
      </DropdownMenu>
    )
  }

  renderDisabledTransientLocalisation = () => {
    const { t } = this.props
    const dropdownItemId = 'disabled-request-transient-localisation'

    return (
      <React.Fragment>
        <UncontrolledTooltip
          name="request-localisation-dropdown-item-disabled-tooltip"
          fade={false}
          modifiers={{ flip: { enabled: false } }}
          placement="left"
          target={dropdownItemId}>
          {t('text.unlicensed_feature')}
        </UncontrolledTooltip>
        <div id={dropdownItemId}>
          <DropdownItem
            name="request-localisation-dropdown-item"
            disabled={true}
            tag="div"
            className={styles['dropdown-item']}
            key={'trans-location-link'}>
            <i className="fas fa-bullseye-arrow"></i>
            &nbsp;
            {t('listItems.transient_localisation')}
          </DropdownItem>
        </div>
      </React.Fragment>
    )
  }

  // Action Menu: Raw Data
  onRawDataClick = (ev) => {
    ev.stopPropagation()
    const { setRawDataModalOpen } = this.props
    setRawDataModalOpen(true)
  }

  // Action Menu: Transient localisation
  onTransientLocalisationClick = (ev) => {
    ev.stopPropagation()
    const { setTransientLocalisationModalOpen } = this.props
    setTransientLocalisationModalOpen(true)
  }

  onClickCreateShareableLink = () => {
    const { metrics, onCopyBoundsIntoLink } = this.props

    if (metrics) {
      onCopyBoundsIntoLink(metrics.timeRange)
    } else {
      analytics.logError(new Error('Unable to create shared link because no metrics could be found')) // eslint-disable-line no-console, max-len
    }
  }
}

function formatNumber(val) {
  return Number.isFinite(val) ? val.toFixed(2) : 'N/A'
}

function formatDuration(t, bounds) {
  const duration = Math.max(...bounds) - Math.min(...bounds)

  const days = Math.floor(duration / DAYS)
  const hours = Math.floor((duration % DAYS) / HOURS)
  const minutes = Math.floor((duration % HOURS) / MINUTES)
  const seconds = Math.floor((duration % MINUTES) / SECONDS)
  const millis = duration % SECONDS

  const daysString = days > 0 ? String(days).padStart(2, '0') + ':' : ''

  return daysString +
      String(hours).padStart(2, '0') + ':' +
      String(minutes).padStart(2, '0') + ':' +
      String(seconds).padStart(2, '0') + '.' +
      String(millis).padStart(3, '0')
}

export default DragAreaSummaryMetrics
