import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Form } from 'reactstrap'
import { withTranslation } from 'react-i18next'
import { noop } from 'lodash/fp/util'
import { get } from 'lodash/fp/object'
import { compose } from 'redux'
import moment from 'moment'
import { graphql } from '@apollo/client/react/hoc'
import gql from 'graphql-tag'
import { createSelector } from 'reselect'

import * as analytics from '@@src/analytics'
import { AsyncResult } from '@@src/utils'
import DeviceSessionConfigurationFields, {
  withDeviceSessionConfigurationFieldsProvider,
} from '@@src/components/device_configuration/device_session_configuration_fields' // eslint-disable-line max-len
import Device from '@@src/api/presenters/device'
import DeviceSessionConfigurationSourceField, {
  withDeviceSessionConfigSourceProvider,
  CONFIGURATION_SHAPE,
} from '@@src/components/device_configuration/device_session_configuration_source_field' // eslint-disable-line max-len
import ConfirmationModal from '@@src/components/modals/confirmation_modal'
import TenantDefaultConfiguration from
'@@src/components/device_configuration/tenant_default_configuration'

const TIME_FORMAT = 'HH:mm:ss A'
const HOUR_IN_SECONDS = 60 * 60

class DeviceSessionConfigurationModal extends PureComponent {
  static propTypes = {
    configurations: PropTypes.arrayOf(
      PropTypes.shape(CONFIGURATION_SHAPE)
    ).isRequired,
    selectedConfiguration: PropTypes.shape(CONFIGURATION_SHAPE).isRequired,
    isOpen: PropTypes.bool.isRequired,
    handleToggle: PropTypes.func.isRequired,
    handleSuccess: PropTypes.func.isRequired,
    updateSessionConfig: PropTypes.func.isRequired,
    inheritSessionConfig: PropTypes.func.isRequired,
    deviceId: PropTypes.number.isRequired,
    t: PropTypes.func.isRequired,
    selectDeviceConfigFieldValue: PropTypes.func.isRequired,
    selectDeviceConfigFieldErrorText: PropTypes.func.isRequired,
    getDeviceConfigFieldChangeHandler: PropTypes.func.isRequired,
    resetDeviceConfigFields: PropTypes.func.isRequired,
    hasDeviceConfigFieldValidationErrors: PropTypes.bool.isRequired,
    setSourceAggregateType: PropTypes.func.isRequired,
    resetSourceAggregateType: PropTypes.func.isRequired,
    performDeviceConfigPreSubmitChecks: PropTypes.func.isRequired,
    sourceAggregateType: PropTypes.string.isRequired,
  }

  static defaultProps = {
    isOpen: false,
    handleSuccess: noop,
    handleToggle: noop,
    hasDeviceConfigFieldValidationErrors: false,
  }

  constructor(props) {
    super(props)

    this.state = {
      result: AsyncResult.notFound(),
    }
  }

  render() {
    const {
      isOpen, t, deviceConfigIntervals, selectDeviceConfigFieldErrorText,
      getDeviceConfigFieldChangeHandler, configurations,
      hasDeviceConfigFieldValidationErrors, selectDeviceConfigFieldValue,
      sourceAggregateType,
    } = this.props
    const { result } = this.state

    return (
      <ConfirmationModal
        result={result}
        handleConfirm={this.handleConfirm}
        handleToggle={this.handleToggle}
        isOpen={isOpen}
        title={t('headings.title')}
        isDisabled={hasDeviceConfigFieldValidationErrors || result.isPending()}
        renderConfirmationContent={this.renderConfirmationContent}>
        <Form>
          <p>
            {t('text.changes_note')}
          </p>
          <DeviceSessionConfigurationSourceField
            handleChange={this.handleDeviceConfigPresetChange}
            sourceAggregateType={sourceAggregateType}
            configurations={configurations} />
          <p className="font-weight-bold mb-2">
            {t('headings.communication')}
          </p>
          {
            sourceAggregateType ===
              Device.CONFIGURATION_SOURCE_DEVICE_TYPE ?
              <DeviceSessionConfigurationFields
                formResult={result}
                intervals={deviceConfigIntervals}
                selectFormFieldValue={selectDeviceConfigFieldValue}
                selectFormFieldsError={selectDeviceConfigFieldErrorText}
                getFormFieldsChangeHandler={
                  getDeviceConfigFieldChangeHandler
                } />
              :
              <TenantDefaultConfiguration tenantConfiguration={
                this.selectTenantConfiguration(this.props) || {}
              } />
          }
        </Form>
      </ConfirmationModal>
    )
  }

  renderConfirmationContent = () => {
    const {
      t, selectDeviceConfigFieldValue, sourceAggregateType,
    } = this.props
    const dateFrom = selectDeviceConfigFieldValue('dateFrom')
    const momentDateFrom = moment(dateFrom, TIME_FORMAT)
    const timeZone = selectDeviceConfigFieldValue('timeZone')
    const dateFromTime = momentDateFrom.format('LT')
    const secondsInterval = selectDeviceConfigFieldValue('secondsInterval')
    const hour = timeZone ? `${dateFromTime} ${timeZone}` : dateFromTime

    return (
      <React.Fragment>
        <p>
          {t('text.confirmation')}
        </p>
        <p name="proposed-configuration-schedule"
          className="text-center font-weight-bold m-0">
          {
            sourceAggregateType ===
              Device.CONFIGURATION_SOURCE_DEVICE_TYPE ?
              secondsInterval < HOUR_IN_SECONDS ?
                t('text.new_schedule_minute', {
                  hour,
                  count: secondsInterval / 60,
                }) :
                t('text.new_schedule_hour', {
                  hour,
                  count: secondsInterval / 60 / 60,
                })
              :
              <TenantDefaultConfiguration tenantConfiguration={
                this.selectTenantConfiguration(this.props) || {}
              } />
          }
        </p>
      </React.Fragment>
    )
  }

  componentWillUnmount() {
    this.setState({
      result: AsyncResult.notFound(),
    })
  }

  handleToggle = () => {
    const {
      handleToggle, resetDeviceConfigFields, resetSourceAggregateType,
    } = this.props

    this.setState({
      result: AsyncResult.notFound(),
    })

    resetDeviceConfigFields()
    resetSourceAggregateType()
    handleToggle()
  }

  handleConfirm = async ev => {
    ev.preventDefault()

    const {
      updateSessionConfig, handleSuccess, selectDeviceConfigFieldValue,
      deviceId, inheritSessionConfig, sourceAggregateType,
      performDeviceConfigPreSubmitChecks,
    } = this.props

    try {
      const res = await performDeviceConfigPreSubmitChecks().catch(() => false)

      if (!res) {
        this.setState({ result: AsyncResult.notFound() })
        return
      }

      if (sourceAggregateType === Device.CONFIGURATION_SOURCE_DEVICE_TYPE) {
        const newSecondsInterval = selectDeviceConfigFieldValue(
          'secondsInterval'
        )
        const newDateFrom = selectDeviceConfigFieldValue('dateFrom')
        const newTimeZone = selectDeviceConfigFieldValue('timeZone')
        const newMomentDateFrom = moment.tz(
          newDateFrom,
          TIME_FORMAT,
          newTimeZone,
        )

        await updateSessionConfig({
          variables: {
            aggregateId: deviceId,
            dateFrom: newMomentDateFrom.format(),
            timeZone: newTimeZone,
            secondsInterval: Number(newSecondsInterval),
          },
        })
      } else {
        await inheritSessionConfig({
          variables: {
            aggregateId: deviceId,
            inheritFromAggregateType: sourceAggregateType,
          },
        })
      }

      await handleSuccess(ev)
      this.setState({
        result: AsyncResult.notFound(),
      })
    } catch (err) {
      analytics.logError(err)
      this.setState({ result: AsyncResult.fail(err) })
    }
  }

  handleDeviceConfigPresetChange = ev => {
    this.props.setSourceAggregateType(ev.target.value.substring(0, 200))
  }

  selectTenantConfiguration = createSelector(
    [get('configurations')], configurations => {
      return configurations.find(c => {
        return c.sourceAggregate === Device.CONFIGURATION_SOURCE_TENANT_TYPE
      })
    })

  static UPDATE_DEVICE_SESSION_CONFIG_MUTATION = gql`
    mutation UpdateSessionConfig (
      $aggregateId: Int,
      $dateFrom: String!,
      $secondsInterval: Int!,
      $timeZone: String!,
    ) {
      updateSessionConfig (
        aggregateId: $aggregateId,
        dateFrom: $dateFrom,
        secondsInterval: $secondsInterval,
        timeZone: $timeZone,
        aggregateType: VDevice
      )
    }
  `

  static INHERIT_DEVICE_SESSION_CONFIG_MUTATION = gql`
    mutation InheritSessionConfig (
      $aggregateId: Int!
      $inheritFromAggregateType: SessionConfigAggregate!
    ) {
      inheritSessionConfig (
        aggregateId: $aggregateId,
        aggregateType: VDevice
        inheritFromAggregateType: $inheritFromAggregateType,
      )
    }
  `
}

export default compose(
  graphql(
    DeviceSessionConfigurationModal.UPDATE_DEVICE_SESSION_CONFIG_MUTATION, {
      name: 'updateSessionConfig',
    }
  ),
  graphql(
    DeviceSessionConfigurationModal.INHERIT_DEVICE_SESSION_CONFIG_MUTATION, {
      name: 'inheritSessionConfig',
    }
  ),
  withDeviceSessionConfigurationFieldsProvider,
  withDeviceSessionConfigSourceProvider,
  withTranslation([
    'src/components/device_configuration/device_session_configuration_modal',
  ]),
)(DeviceSessionConfigurationModal)
