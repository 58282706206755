import React from 'react'
import PropTypes from 'prop-types'
import { line } from 'd3-shape'
import { get } from 'lodash/fp/object'
import { createSelector } from 'reselect'

import { globalSequence } from '@@src/utils'
import { GraphConfig } from '@@src/components/graphs/graph_context'
import HistoryPlotLayer from
'@@src/management_path/devices_path/history_modal/history_plot_layer'
import { LinePlot, CirclePlot } from
'@@src/management_path/devices_path/history_modal/history_plot_layer'
import styles from './battery_history_plot_layer.css'

export default class BatteryHistoryPlotLayer extends React.PureComponent {
  static propTypes = {
    telemetrySegments: PropTypes.array.isRequired,
    graphConfig: PropTypes.instanceOf(GraphConfig).isRequired,
  }

  id = globalSequence.next()

  render() {
    const { telemetrySegments, graphConfig, ...props } = this.props
    const clipPathId = `history-plot-layer-clip-path-${this.id}`

    return (
      <HistoryPlotLayer
        {...props}
        clipPathId={clipPathId}
        graphConfig={graphConfig}
        name="battery-history-plot-layer">
        {telemetrySegments.map(({ data, index }) => (
          this.renderSegment(index, data, clipPathId))
        )}
      </HistoryPlotLayer>
    )
  }

  renderSegment = (index, data, clipPathId) => {
    const voltageLine =
      this.voltageLineGenerator(this.props)
    const batteryVoltageData = data.filter(d => d.channel === 'battery_voltage')
    const hlcVoltageData = data.filter(d => d.channel === 'hlc_voltage')

    return (
      <g key={index}>
        {
          batteryVoltageData.length === 1 ?
            <CirclePlot
              name="battery-voltage-plot"
              className={styles['battery-history-circle']}
              r={1}
              cx={this.props.graphConfig.xScale(hlcVoltageData[0].time)}
              cy={this.props.graphConfig.yScale(hlcVoltageData[0].value)}
              clipPath={`url(#${clipPathId})`} /> :
            <LinePlot
              d={voltageLine(batteryVoltageData)}
              name="battery-voltage-plot"
              clipPath={`url(#${clipPathId})`}
              className={styles['battery-history-line']}/>
        }
        {
          hlcVoltageData.length === 1 ?
            <CirclePlot
              name="hlc-voltage-plot"
              className={styles['hlc-history-circle']}
              r={1}
              cx={this.props.graphConfig.xScale(hlcVoltageData[0].time)}
              cy={this.props.graphConfig.yScale(hlcVoltageData[0].value)}
              clipPath={`url(#${clipPathId})`} /> :
            <LinePlot
              d={voltageLine(hlcVoltageData)}
              name="hlc-voltage-plot"
              clipPath={`url(#${clipPathId})`}
              className={styles['hlc-history-line']}/>
        }
      </g>
    )
  }

  voltageLineGenerator = createSelector(
    [get('graphConfig')],
    graphConfig => {
      return line()
        .x(d => graphConfig.xScale(d.time))
        .y(d => graphConfig.yScale(d.value))
    }
  )
}
