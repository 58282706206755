import { CREATE_TOOLTIP, REMOVE_TOOLTIP } from './actions'

const initialState = {
  instance: null,
  tooltipId: null,
  instanceRef: null,
}

export function reducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_TOOLTIP: {
      const { tooltipId, instanceRef, tooltip } = action

      return Object.assign({}, state, {
        instance: tooltip,
        tooltipId,
        instanceRef,
      })
    }

    case REMOVE_TOOLTIP:
      return (state.tooltipId === action.tooltipId) ? initialState : state

    default:
      return state
  }
}
