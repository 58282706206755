import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { Card, Table } from 'reactstrap'
import moment from 'moment'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { graphql } from '@apollo/client/react/hoc'
import { AVAILABLE_PRESSURE_UNITS } from '../../../utils/unit_constants'
import NetworkAsset from '@@src/api/presenters/network_asset'
import {
  AsyncResult,
  createSelectGraphQLResult,
  DEFAULT_TIMEZONE,
  ORDER_ASC,
  ORDER_DESC,
  selectDateFormatter,
} from '../../../utils'
import styles from './anomaly_list.css'
import PagedData from '../../../api/presenters/paged_data'
import Pagination from '../../../api/presenters/pagination'
import { AnomaliesTableQuery } from './graphql/anomalies_table_graphql'
import {
  convertUnitFromDB,
  PRESSURE_TYPE,
} from '../../../utils/app_unit_conversion'
import classnames from 'classnames'
import AnomalyTypeOver from '../../../static_assets/svgs/anomaly-types-high.svg'
import AnomalyTypeUnder from '../../../static_assets/svgs/anomaly-types-low.svg'
import PaginationRow from '../../../components/pagination_row'
import { VIEW_TYPE_ANOMALY_LIST } from '../constants'
import PressurePreview from '../../../components/pressure_preview/pressure_preview'
import { LegendItemColorContext } from '../../../components/colors/legend_item_color_provider'
import {
  AVAILABLE_SORT_OPTIONS,
  AVG,
  DIFF,
  DURATION,
  END,
  FORMAT_DATE,
  FORMAT_TIME_OF_DAY,
  MAX,
  MIN,
  NETWORK_ASSET_CHANNEL,
  NETWORK_ASSET_READABLE_ID,
  START,
  TYPE,
} from './consts'
import { ListPlaceholder } from '../../../_v2/components/placeholders/list_placeholder'

AnomalyList.propTypes = {
  page: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  timezone: PropTypes.string.isRequired,
  endTime: PropTypes.instanceOf(Date).isRequired,
  startTime: PropTypes.instanceOf(Date).isRequired,
  orderBy: PropTypes.oneOf(AVAILABLE_SORT_OPTIONS).isRequired,
  orderDirection: PropTypes.oneOf([ORDER_ASC, ORDER_DESC]).isRequired,
  setOrderByAndDirection: PropTypes.func.isRequired,
  createPageLink: PropTypes.func.isRequired,
  onChangePerPage: PropTypes.func.isRequired,
  pressureUnit: PropTypes.oneOf(AVAILABLE_PRESSURE_UNITS).isRequired,
  viewType: PropTypes.oneOf([VIEW_TYPE_ANOMALY_LIST]).isRequired,
  anomaliesTableResult: PropTypes.instanceOf(AsyncResult).isRequired,
  legendItemsResult: PropTypes.instanceOf(AsyncResult).isRequired,
  onAnomalyClick: PropTypes.func.isRequired,
  onDataChanged: PropTypes.func.isRequired,
}

AnomalyList.defaultProps = {
  timezone: DEFAULT_TIMEZONE,
  page: 1,
  perPage: 25,
  totalPages: 1,
  orderBy: START,
  orderDirection: ORDER_DESC,
}

function AnomalyList({
  anomaliesTableResult,
  timezone,
  pressureUnit,
  t,
  ...props
}) {
  const { legendItemsResult } = props
  const { getLegendItemColor } = useContext(LegendItemColorContext)
  const dateFormat = selectDateFormatter(timezone)
  const getPressurePreviewColorForAnomaly = (anomaly) => {
    const corespondingLegendItem = legendItemsResult.data.find(
      (item) =>
        item.sourceId === anomaly.networkAssetId &&
        item.sourceChannel === anomaly.networkAssetChannel
    )
    return corespondingLegendItem
      ? getLegendItemColor(
          legendItemsResult.data.find(
            (item) =>
              item.sourceId === anomaly.networkAssetId &&
              item.sourceChannel === anomaly.networkAssetChannel
          )
      )
      : 'black'
  }
  const renderSortCaret = (orderKey) => {
    const { orderBy, orderDirection } = props
    const caretClassnames = classnames('fas', {
      'fa-caret-up': orderKey === orderBy && orderDirection === ORDER_ASC,
      'fa-caret-down': orderKey === orderBy && orderDirection === ORDER_DESC,
      'fa-minus': orderKey !== orderBy,
    })

    return (
      <span>
        &nbsp;
        <i className={caretClassnames} />
      </span>
    )
  }

  const onChangeSorting = (newOrderBy) => {
    const { setOrderByAndDirection, orderBy, orderDirection } = props
    const newOrderDirection =
      orderBy === newOrderBy && orderDirection === ORDER_DESC
        ? ORDER_ASC
        : ORDER_DESC

    setOrderByAndDirection(newOrderBy, newOrderDirection)
  }

  useEffect(() => {
    const shouldDisplayExportButton =
      anomaliesTableResult.data && anomaliesTableResult.data.length > 0
    props.onDataChanged(shouldDisplayExportButton)
  }, [anomaliesTableResult.data])

  return (
    <div className="d-flex flex-column p-4">
      <React.Fragment>
        <Card>
          <Table className="mb-0">
            <thead className={styles.head}>
              <tr>
                <th width="50" />
                <th onClick={() => onChangeSorting(TYPE)}>
                  {t('headings.type')}
                  {renderSortCaret(TYPE)}
                </th>
                <th />
                <th onClick={() => onChangeSorting(NETWORK_ASSET_READABLE_ID)}>
                  {t('headings.asset_id')}
                  {renderSortCaret(NETWORK_ASSET_READABLE_ID)}
                </th>
                <th onClick={() => onChangeSorting(NETWORK_ASSET_CHANNEL)}>
                  {t('headings.channel')}
                  {renderSortCaret(NETWORK_ASSET_CHANNEL)}
                </th>
                <th onClick={() => onChangeSorting(START)}>
                  {t('headings.start')}
                  {renderSortCaret(START)}
                </th>
                <th onClick={() => onChangeSorting(END)}>
                  {t('headings.end')}
                  {renderSortCaret(END)}
                </th>
                <th onClick={() => onChangeSorting(DURATION)}>
                  {t('headings.duration')}
                  {renderSortCaret(DURATION)}
                </th>
                <th
                  onClick={() => onChangeSorting(AVG)}
                  className={styles.pressure}
                >
                  {t('headings.avg')}
                  {renderSortCaret(AVG)}
                  <div className="font-weight-normal small">
                    ({t(`common/text:units.${pressureUnit}`)})
                  </div>
                </th>
                <th
                  onClick={() => onChangeSorting(MIN)}
                  className={styles.pressure}
                >
                  {t('headings.anomaly_min')}
                  {renderSortCaret(MIN)}
                  <div className="font-weight-normal small">
                    ({t(`common/text:units.${pressureUnit}`)})
                  </div>
                </th>
                <th
                  onClick={() => onChangeSorting(MAX)}
                  className={styles.pressure}
                >
                  {t('headings.anomaly_max')}
                  {renderSortCaret(MAX)}
                  <div className="font-weight-normal small">
                    ({t(`common/text:units.${pressureUnit}`)})
                  </div>
                </th>
                <th
                  onClick={() => onChangeSorting(DIFF)}
                  className={styles.pressure}
                >
                  {t('headings.diff')}
                  {renderSortCaret(DIFF)}
                  <div className="font-weight-normal small">
                    ({t(`common/text:units.${pressureUnit}`)})
                  </div>
                </th>
              </tr>
            </thead>
            <tbody className={styles.body}>
              {anomaliesTableResult.wasSuccessful() &&
                anomaliesTableResult.data.map((anomaly) => (
                  <tr
                    key={`anomaly_${anomaly.startTime}${anomaly.endTime}${anomaly.deviceId}`}
                  >
                    <td align="center">
                      <img
                        width="32"
                        height="32"
                        src={
                          anomaly.type === 'over_pressure'
                            ? AnomalyTypeOver
                            : AnomalyTypeUnder
                        }
                      />
                    </td>
                    <td>{t(`anomaly.type.${anomaly.type}`)}</td>
                    <td>
                      <PressurePreview
                        start={new Date(anomaly.startTime)}
                        end={new Date(anomaly.endTime)}
                        networkAsset={{
                          id: anomaly.networkAssetId,
                          channel: anomaly.networkAssetChannel,
                        }}
                        color={getPressurePreviewColorForAnomaly(anomaly)}
                        onClick={() => props.onAnomalyClick(anomaly)}
                      />
                    </td>
                    <td>{anomaly.networkAssetReadableId}</td>
                    <td>
                      {t(
                        `common/text:text.asset_channel_${anomaly.networkAssetChannel}`
                      )}
                    </td>
                    <td>
                      {dateFormat(anomaly.startTime, FORMAT_DATE)}
                      <br />
                      {dateFormat(anomaly.startTime, FORMAT_TIME_OF_DAY)}
                    </td>
                    <td>
                      {dateFormat(anomaly.endTime, FORMAT_DATE)}
                      <br />
                      {dateFormat(anomaly.endTime, FORMAT_TIME_OF_DAY)}
                    </td>
                    <td>
                      {moment
                        .utc(
                          moment(anomaly.endTime).diff(
                            moment(anomaly.startTime)
                          )
                        )
                        .format('HH:mm:ss')}
                    </td>
                    <td className={styles.pressure}>
                      {convertUnitFromDB(
                        anomaly.avg,
                        pressureUnit,
                        PRESSURE_TYPE
                      )}
                    </td>
                    <td className={styles.pressure}>
                      {convertUnitFromDB(
                        anomaly.min,
                        pressureUnit,
                        PRESSURE_TYPE
                      )}
                    </td>
                    <td className={styles.pressure}>
                      {convertUnitFromDB(
                        anomaly.max,
                        pressureUnit,
                        PRESSURE_TYPE
                      )}
                    </td>
                    <td className={styles.pressure}>
                      {convertUnitFromDB(
                        anomaly.diff,
                        pressureUnit,
                        PRESSURE_TYPE
                      )}
                    </td>
                  </tr>
                ))}
              {anomaliesTableResult.isPending() && (
                <tr>
                  <td
                    colSpan={11}
                    className="justify-content-center align-items-center"
                  >
                    <ListPlaceholder />
                  </td>
                </tr>
              )}
              {anomaliesTableResult.wasSuccessful() &&
                anomaliesTableResult.data.length === 0 && (
                <tr>
                  <td colSpan={11} align="center">
                    {t('src/components/lists/async_list:text.no_results')}
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Card>
        <PaginationRow
          page={anomaliesTableResult.data.pagination.page}
          perPage={anomaliesTableResult.data.pagination.perPage}
          className="flex-grow-0 flex-shrink-0 mt-2"
          totalPages={anomaliesTableResult.data.pagination.totalPages}
          createPageLink={props.createPageLink}
          onChangePerPage={props.onChangePerPage}
        />
      </React.Fragment>
    </div>
  )
}

function createMapStateToProps() {
  const selectGetAnomaliesTableResult = createSelectGraphQLResult(
    'getAnomalies',
    {
      mapResult: (data) =>
        new PagedData({
          data: data.anomalies || [],
          pagination: new Pagination(data.pagination),
        }),
      nullObject: new PagedData({
        data: [],
        pagination: new Pagination({
          pageNumber: 0,
          perPage: 0,
          totalPages: 0,
          totalResults: 0,
        }),
      }),
    }
  )

  return function mapStateToProps() {
    return { selectGetAnomaliesTableResult }
  }
}

export default compose(
  connect(createMapStateToProps),
  graphql(AnomaliesTableQuery, {
    options: ({
      orderBy,
      orderDirection,
      page,
      perPage,
      pressureUnit,
      legendItemsResult,
      ...props
    }) => {
      const filteredSources = legendItemsResult.data
        .filter((legendItem) => {
          return legendItem.source instanceof NetworkAsset
        })
        .map((filteredItem) => {
          return {
            networkAssetId: filteredItem.source.id,
            channel: filteredItem.sourceChannel,
          }
        })

      return {
        fetchPolicy: 'network-only',
        variables: {
          page,
          perPage,
          sources: filteredSources,
          start: props.startTime,
          end: props.endTime,
          orderBy: orderBy || AnomalyList.defaultProps.orderBy,
          orderDirection:
            orderDirection || AnomalyList.defaultProps.orderDirection,
          pressureUnit,
        },
      }
    },
    props: ({ data, ownProps: { selectGetAnomaliesTableResult } }) => ({
      refetchAnomaliesTable: (...args) => data.refetch(...args),
      anomaliesTableResult: selectGetAnomaliesTableResult(data),
    }),
  }),
  withTranslation([
    'src/analysis_path/pressure_analysis_path/anomaly_list/anomaly_list',
    'common/text',
    'src/components/lists/async_list',
  ])
)(AnomalyList)
