import React, { PureComponent } from 'react'

const defaultSeedOptions = {
  pageNumber: 1,
  resultsPerPage: 10,
}

export default function getWithPagination(options = defaultSeedOptions) {
  return function withPagination(Component) {
    return class PaginationContainer extends PureComponent {
      constructor(props) {
        super(props)

        this.state = {
          pageNumber: options.pageNumber,
          resultsPerPage: options.resultsPerPage,
        }
      }

      render() {
        const { children, ...rest } = this.props
        const { pageNumber, resultsPerPage } = this.state

        return (
          <Component
            setPageNumber={this.setPageNumber}
            setResultsPerPage={this.setResultsPerPage}
            pageNumber={pageNumber}
            resultsPerPage={resultsPerPage}
            {...rest}>
            {children}
          </Component>
        )
      }

      setPageNumber = pageNumber => {
        this.setState({
          pageNumber,
        })
      }

      setResultsPerPage = resultsPerPage => {
        this.setState({
          resultsPerPage,
        })
      }
    }
  }
}
