import React, { memo } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import ScrollArea from 'react-scrollbar'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'

import * as analytics from '@@src/analytics'
import config from '@@src/config'
import SubmitButton from '@@src/components/buttons/submit_button'
import awaitAsyncOperation from '@@src/components/await_async_operation'
import { AsyncResult, MINUTES } from '@@src/utils'
import { Navbar, Nav, NavItem, NavbarBrand } from 'reactstrap'
import { withAuthorization } from '../../_v2/contexts/authorization/authorization.context'

import styles from './require_eula_acceptance_guard.css'

let autoRejectEulaTimeout = null
const SUCCESS_RESULT = AsyncResult.success()

class RequireEulaAcceptanceGuard extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    licenseWording: PropTypes.string,
    eulaLicenseTimeout: PropTypes.number.isRequired,
  }

  static defaultProps = {
    eulaLicenseTimeout: 15 * MINUTES,
  }

  state = {
    isLatestEulaAccepted: false,
  }

  render() {
    const {
      t,
      children,
      className,
      licenseWording,
      eulaLicenseTimeout,
      isLatestEulaAccepted,
    } = this.props

    if (isLatestEulaAccepted || this.state.isLatestEulaAccepted) {
      if (autoRejectEulaTimeout) {
        clearTimeout(autoRejectEulaTimeout)
        autoRejectEulaTimeout = null
      }

      return children
    } else if (!autoRejectEulaTimeout) {
      autoRejectEulaTimeout = setTimeout(this.onRejectEula, eulaLicenseTimeout)
    }

    if (document.title !== t('headings.eula')) {
      document.title = t('headings.eula')
    }

    return (
      <div className={classnames(className, styles.container)}>
        <Navbar color="dark" dark expand="md">
          <NavbarBrand className={styles['brand-image']} />
        </Navbar>

        <div className={classnames('container', styles.eula)}>
          <h2 className={styles.heading}>{t('headings.eula')}</h2>

          <ScrollArea
            speed={0.8}
            style={{ height: '550px' }}
            className={classnames('area', styles['eula-contents'])}
            contentClassName="content"
            horizontal={false}
          >
            <div dangerouslySetInnerHTML={{ __html: licenseWording }} />
          </ScrollArea>
        </div>

        <Navbar color="light" light expand="md" className={styles.footer}>
          <Nav
            navbar
            className={classnames(styles['footer-contents'], 'ml-auto')}
          >
            <NavItem className={styles['footer-text']}>
              <SubmitButton
                name="accept-eula-button"
                buttonStatus=""
                className={styles['accept-decline-button']}
                color="primary"
                onSubmitForm={this.onAcceptEula}
                result={SUCCESS_RESULT}
                submitText={t('buttons.agree')}
              ></SubmitButton>

              <SubmitButton
                name="decline-eula-button"
                buttonStatus=""
                className={styles['accept-decline-button']}
                color="secondary"
                onSubmitForm={this.onRejectEula}
                result={SUCCESS_RESULT}
                submitText={t('buttons.decline')}
              ></SubmitButton>
            </NavItem>
          </Nav>
        </Navbar>
      </div>
    )
  }

  onAcceptEula = async () => {
    const { latestEulaVersion, authorization } = this.props

    try {
      await authorization.setAcceptedEulaVersion(latestEulaVersion)
      this.setState({ isLatestEulaAccepted: true })
    } catch (err) {
      analytics.logError(err)
      await this.onRejectEula()
    }
  }

  onRejectEula = async () => {
    const { authorization } = this.props

    try {
      await authorization.signOut()
    } catch (err) {
      analytics.logError(err)
    } finally {
      window.location.reload()
    }
  }
}

export default compose(
  memo,
  withAuthorization,
  awaitAsyncOperation({
    operation: async ({authorization}) => {
      const token = await authorization.getCurrentUserToken()
      const response = await fetch(
        `${config.apiUrl}/v1/rest/${config.tenant}/licenses/eula`,
        {
          method: 'GET',
          headers: new Headers({ Authorization: `Bearer ${token}` }),
        },
      )
      const body = await response.json()
      const latestEulaVersion = body.latestVersion
      const latestEulaUrl = body.url
      const userEulaVersion = await authorization.getAcceptedEulaVersion()

      if (latestEulaVersion === userEulaVersion) {
        return { isLatestEulaAccepted: true }
      }

      try {
        const eulaResponse = await fetch(latestEulaUrl)
        const eulaText = await eulaResponse.text()

        return {
          licenseWording: eulaText,
          latestEulaVersion,
          isLatestEulaAccepted: false,
        }
      } catch (e) {
        analytics.logError(e)
        throw e
      }
    },
  }),
  withTranslation(['src/components/security/require_eula_acceptance_guard']),
)(RequireEulaAcceptanceGuard)
