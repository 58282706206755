import React from 'react'
import {
  DropdownItem,
} from 'reactstrap'

import routes from '@@src/routes'
import styles from './index.css'

function GroupsAlertsDropdown({
  t,
  group,
  handleToggleMuteAlertsModal,
  handleToggleUnmuteAlertsModal,
}) {
  return(
    <React.Fragment>
      <DropdownItem header>
        {t('common/text:headings.alerts')}
      </DropdownItem>

      {
        group.isNetworkAssetGroup() ? (
          <React.Fragment>
            <DropdownItem
              name="mute-alerts-button"
              onClick={handleToggleMuteAlertsModal}
              className={styles['dropdown-item-option']}>
              <span className={styles['dropdown-item-option-icon']}>
                <i className="fal fa-bell-slash"></i>
              </span>
                &nbsp;
              {t('common/text:buttons.mute')}
            </DropdownItem>

            <DropdownItem
              name="unmute-alerts-button"
              disabled={!group.currentAlertsMuteEnd}
              onClick={handleToggleUnmuteAlertsModal}
              className={styles['dropdown-item-option']}>
              <span className={styles['dropdown-item-option-icon']}>
                <i className="fal fa-bell"></i>
              </span>
                &nbsp;
              {t('common/text:buttons.unmute')}
            </DropdownItem>
          </React.Fragment>
        ) : null
      }

      <DropdownItem
        href={routes.editGroupPath(group.id, { t: 'alerts' })}
        className={styles['dropdown-item-option']}>
        <span className={styles['dropdown-item-option-icon']}>
        </span>
          &nbsp;
        {t('common/text:buttons.alert_settings')}
      </DropdownItem>

      <DropdownItem divider />
    </React.Fragment>
  )
}

export default GroupsAlertsDropdown
