import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'reactstrap'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { createSelector } from 'reselect'
import { get } from 'lodash/fp/object'

import Alert from '@@src/api/presenters/alert'
import * as analytics from '@@src/analytics'
import routes from '@@src/routes'
import {
  VIEW_TYPE_PLOT,
  VIEW_TYPE_CPIS,
  VIEW_TYPE_EVENT_LOCALISATION,
} from '@@src/analysis_path/pressure_analysis_path/constants' // eslint-disable-line max-len
import { MixedGroupDetailsType } from '@@src/api/presenters/group'
import NetworkAsset from '@@src/api/presenters/network_asset'

export default class AlertTypeLinkButton extends React.PureComponent {
  static propTypes = {
    alert: PropTypes.instanceOf(Alert).isRequired,
    color: PropTypes.string,
    size: PropTypes.string,
    className: PropTypes.string,
  }

  render() {
    const { className, color, size } = this.props

    return (
      <Button
        color={color}
        size={size}
        tag={Link}
        to={this.getRoute()}
        className={className}>
        <span className="far fa-chart-line" />
      </Button>
    )
  }

  getRoute = () => {
    const { alert } = this.props

    switch (alert.type) {
      case Alert.PRESSURE_HIGH_TYPE:
      case Alert.PRESSURE_LOW_TYPE:
        return routes.analysisPressurePath({
          d: new NetworkAsset({
            id: alert.details.networkAssetId,
          }).dataSourceId,
          dv: VIEW_TYPE_PLOT,
          ...this.selectPaddedTimeseriesAlertDates(this.props),
        })
      case Alert.DAILY_CPIS_LOW_TYPE:
      case Alert.DAILY_CPIS_HIGH_TYPE:
        return routes.analysisPressurePath({
          d: new NetworkAsset({
            id: alert.details.networkAssetId,
          }).dataSourceId,
          dv: VIEW_TYPE_CPIS,
          ...this.selectPaddedCpisAlertDates(this.props),
        })
      case Alert.EVENT_SOURCE_LOCALISATION_TYPE:
        return routes.analysisPressurePath({
          d: new MixedGroupDetailsType({
            id: alert.details.groupId,
          }).dataSourceId,
          dv: VIEW_TYPE_EVENT_LOCALISATION,
          from: moment(alert.details.eventsStartDate).toISOString(),
          to: moment(alert.details.eventsEndDate).toISOString(),
        })
      case Alert.DEVICE_NO_COMMS_TYPE:
      case Alert.BATTERY_DEPLETING_TYPE:
        return routes.managementDevicesDetailPath(alert.details.deviceId)
      case Alert.EVENT_CPIS_TYPE:
      case Alert.EVENT_SEVERITY_TYPE:
        return routes.analysisPressurePath({
          d: new NetworkAsset({
            id: alert.details.networkAssetId,
          }).dataSourceId,
          dv: VIEW_TYPE_PLOT,
          ...this.selectPaddedEventAlertDates(this.props),
        })
      default:
        analytics.logError('unknown alert type', alert.type)
        return ''
    }
  }

  selectPaddedEventAlertDates = createSelector(
    [get('alert')],
    alert => {
      const fromMoment = moment(Number(alert.details.startTimestamp) / 1e6)
      const toMoment = moment(Number(alert.details.endTimestamp) / 1e6)
      const millisPadding = toMoment.diff(fromMoment, 'milliseconds') * 0.1

      return {
        from: fromMoment.subtract(millisPadding, 'milliseconds').toISOString(),
        to: toMoment.add(millisPadding, 'milliseconds').toISOString(),
      }
    }
  )

  selectPaddedTimeseriesAlertDates = createSelector(
    [get('alert')],
    alert => {
      const fromMoment = moment(alert.occurredAt)
      const toMoment = fromMoment.clone()

      fromMoment.subtract(30, 'minutes')
      toMoment.add(30, 'minutes')

      return {
        from: fromMoment.toISOString(),
        to: toMoment.toISOString(),
      }
    }
  )

  selectPaddedCpisAlertDates = createSelector(
    [get('alert')],
    alert => {
      const fromMoment = moment(alert.occurredAt)
      const toMoment = fromMoment.clone()

      fromMoment.subtract(1, 'week')
      toMoment.add(1, 'week')

      return {
        from: fromMoment.toISOString(),
        to: toMoment.toISOString(),
      }
    }
  )
}
