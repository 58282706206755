export class NetworkAssetForMapModel {
  constructor(networkAsset) {
    this.type = 'Feature'
    this.geometry = { type: 'Point', coordinates: [networkAsset.lng, networkAsset.lat] }
    this.properties = { cluster: false, networkAsset }
  }

  /**
   * @param {NetworkAssetForMapItem} networkAsset network asset
   * @returns {NetworkAssetForMapModel} view model
   */
  static fromNetworkAsset(networkAsset) {
    return new NetworkAssetForMapModel(networkAsset)
  }
}
