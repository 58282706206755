import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import Datetime from 'react-datetime'
import { Col, Form, Row } from 'reactstrap'
import {
  LOCATION_SOURCE_SURVEY,
} from '../../../../../../../../components/forms/inputs/location_information_input_group'
import {
  SUPPORTED_COORDS_FORMAT, convertToWGS84,
} from '../../../../../../../../utils/conversions/coordinate_conversions'
import { mapAssetTypeToLogicalChannels } from '../../../../../../../../utils'
import { validAssetTypes } from '../../../../../../../../utils'
import { adjustRegister, validateCoordinates } from '../../../../../network_assets/utils/form'
import { COORDINATE_REGEX } from '../../../../../../../../management_path/network_assets_path/network_asset_fields'
import { mapTypeAndColourToMarkerIcon } from '../../../../../../../../utils/network_asset'
import SelectInput from '../../../../../../../components/inputs/select_input/select_input.component'
import PreviewSinglePinMap from '../../../../../../../components/preview_single_pin_map/PreviewSinglePinMap'
// eslint-disable-next-line max-len
import NetworkAssetBasicFormFields from '../../../../../network_assets/components/create_network_asset_modal/modal_fields/network_asset_basic_form_fields.component'
// eslint-disable-next-line max-len
import NetworkAssetLocationFormFields from '../../../../../network_assets/components/create_network_asset_modal/modal_fields/network_asset_location_form_fields.component'
// eslint-disable-next-line max-len
import NetworkAssetGroupFormFields from '../../../../../network_assets/components/create_network_asset_modal/modal_fields/network_asset_group_form_fields.component'
// eslint-disable-next-line max-len
import NetworkAssetDescriptionFormFields from '../../../../../network_assets/components/create_network_asset_modal/modal_fields/network_asset_description_form_fields.component'
import { useTranslation } from 'react-i18next'

const assetTypes = validAssetTypes()

const CreateAndInstallNetworkAsset = ({ formRef, createNetworkAssetAndInstallDevice = () => null }) => {
  const [mapPinPlacement, setMapPinPlacement] = useState(null)
  const [mapPinType, setMapPinType] = useState(assetTypes[0])

  const { t } = useTranslation([
    'common/text',
    'v2/network_assets/common',
    'v2/network_assets/forms',
    'v2/network_assets/filters',
  ])

  const {
    register,
    watch,
    control,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      assetType: assetTypes[0],
      channelType: mapAssetTypeToLogicalChannels(assetTypes[0])[0],
      dateTime: new Date(),
      assetId: '',
      friendlyName: '',
      source: LOCATION_SOURCE_SURVEY,
      elevation: '',
      coordinatesType: SUPPORTED_COORDS_FORMAT[0],
      coordinates: '',
      group: null,
    },
  })

  useEffect(() => {
    const subscription = watch((value, { name: fieldName, type: fieldType }) => {
      if(fieldType === 'change') {
        switch(fieldName) {
          case 'coordinates':
          case 'coordinatesType': {
            const latLng = value.coordinates.match(COORDINATE_REGEX)

            if(!latLng) {
              setMapPinPlacement(null)
              break
            }

            const latitude = parseFloat(latLng[1])
            const longitude = parseFloat(latLng[2])

            setMapPinPlacement(validateCoordinates(value.coordinatesType, latitude, longitude))
            break
          }
          case 'assetType': {
            setMapPinType(value[fieldName])
            break
          }
          default:
        }
      }
    })

    return () => subscription.unsubscribe()
  }, [watch])

  const adjustedRegister = adjustRegister(register)

  const handleCreateNetworkAssetAndInstallDevice = ({ coordinates, coordinatesType, ...rest }) => {
    const [latitudeRaw, longitudeRaw] = coordinates.split(',').map(a => +a)
    const [latitude, longitude] = convertToWGS84(coordinatesType, [latitudeRaw, longitudeRaw])
    createNetworkAssetAndInstallDevice({ latitude, longitude, ...rest })
  }

  return(
    <Row className="h-100 p-0 m-0">
      <Col className="list-column w-50">
        <div className=" d-flex flex-column h-100 overflow-hidden col p-0 m-0">
          <Form
            innerRef={formRef}
            className="overflow-auto py-2 px-4 flex-grow mt-3"
            onSubmit={handleSubmit(handleCreateNetworkAssetAndInstallDevice)}
            noValidate >
            <Col sm="4" className="p-0 m-0">
              <label className="h6">{t('v2/network_assets/common:text.installation_time')}</label>
              <Controller
                name="dateTime"
                control={control}
                required
                render={({ field }) => (
                  <Datetime
                    {...field}
                    inputProps={{
                      placeholder: 'MM-DD-YYYY HH:mm',
                    }}
                  />
                )}
              />
            </Col>

            <div className="form-seperator" />
            <NetworkAssetBasicFormFields
              register={adjustedRegister}
              errors={errors}
            />

            <div className="form-seperator" />
            <h5 className="mb-2">{t('v2/network_assets/common:text.location')}</h5>
            <NetworkAssetLocationFormFields
              control={control}
              register={adjustedRegister}
              errors={errors}
              getValues={getValues}
            />

            <label className="h6">{t('v2/network_assets/forms:text.network_assets_channel')}</label>
            <SelectInput
              t={
                (option) => t(`v2/network_assets/common:installation.logical_channel.${option}`)
              }
              choiceOptions={mapAssetTypeToLogicalChannels(mapPinType)}
              inputProps={{
                id: 'channelType',
                type: 'select',
                required: true,
                ...register('channelType', {
                  required: t('error_field_text.field_required'),
                  pattern: {
                    value: (value) =>
                      mapAssetTypeToLogicalChannels(mapPinType).find(channelType => channelType === value) ?
                        true : t('error_field_text.channelType'),
                  },
                }),
              }}
            />
            <div className="form-seperator" />
            <h5 className="mb-2">{t('v2/network_assets/common:text.group')}</h5>
            <NetworkAssetGroupFormFields control={control} />

            <div className="form-seperator" />
            <h5>{t('v2/network_assets/forms:text.description')}</h5>
            <NetworkAssetDescriptionFormFields register={adjustedRegister} />
          </Form>
        </div>
      </Col>
      <Col className="w-50 p-0">
        <PreviewSinglePinMap
          mapPinPlacement={mapPinPlacement}
          mapPinIcon={mapTypeAndColourToMarkerIcon('primary', mapPinType)}
        />
      </Col>
    </Row>
  )
}

export default CreateAndInstallNetworkAsset
