import React, { useCallback } from 'react'
import { Col, Row, Container } from 'reactstrap'
import gql from 'graphql-tag'
import { NavLink } from 'react-router-dom'
import GroupBreadcrumb from '../group_breadcrumb'
import GroupTypeIcon from '@@src/components/icons/group_type_icon'
import CreateGroupButtonAndModal from
'@@src/management_path/groups_path/create_group_button_and_modal'
import EditGroupButton from '../../../components/buttons/edit_group_button'
import CollapsibleAlertsSection
from './collapsible_alerts_section'
import GroupList from '@@src/management_path/groups_path/group_list/group_list'
import AppLayout from '@@src/components/app_layout'

import classnames from 'classnames'
import routes from '@@src/routes'
import styles from '../group_detail_page.css'
import { useQuery } from '@apollo/client'

const GROUP_MOST_DISTANT_GROUPS = gql`
query GetMostDistantGroups(	$parentGroupId: Int ) {
  getMostDistantGroups( parentGroupId: $parentGroupId ) {
    groupId
    groupName
    groupCategory
    membersType
    membersCount
    membersIds
  }
}
`

function GroupOfGroupsDetail({
  t,
  group,
  canEdit,
  groupResult,
  onMove,
  onDelete,
  navigateToPage,
  refetchPageData,
  newlyCreatedGroupId,
  handleCreatedAndCreateAnother,
  getDisabledEditTooltip,
}) {

  const { data: mostDistantMembers, loading } = useQuery(GROUP_MOST_DISTANT_GROUPS,
    {
      variables: {
        parentGroupId: group.id,
      },
    }
  )
  const getArrayOfIdsOfAllChildrenOfGroup = useCallback(() => {
    if(loading || !mostDistantMembers) {
      return [group.uuid]
    }
    let arrayOfMembers = [group.uuid]

    mostDistantMembers.getMostDistantGroups.map(mostDistantGroup => {
      arrayOfMembers.push('g.' + mostDistantGroup.groupId)
      if(mostDistantGroup.membersType === 'VNetworkAsset') {
        const newUUIds = mostDistantGroup.membersIds.map(id => 'a.' + id)
        arrayOfMembers = [...arrayOfMembers, ...newUUIds]
      } else {
        const newUUIds = mostDistantGroup.membersIds.map(id => 'd.' + id)
        arrayOfMembers = [...arrayOfMembers, ...newUUIds]
      }
    })
    return arrayOfMembers
  }, [mostDistantMembers, loading])

  return(
    <AppLayout title={t('headings.page_title', { group: group.name })}>
      <Container>
        <Row className="mt-5">
          <Col>
            <GroupBreadcrumb groupResult={groupResult} />
          </Col>
        </Row>
        <Row>
          <Col
            sm="9"
            className="mb-5 d-flex align-items-center flex-row
                justify-content-between">
            <div>
              <div className="d-flex align-items-center">
                <GroupTypeIcon
                  backgroundColor="bg-light"
                  className="mr-3 fa-2x"
                  group={group} />
                <h1 className="mb-0 text-break">
                  {group.name}
                </h1>
              </div>
              <div className="mt-2">
                <span className="text-muted text-capitalize">
                  {t(`common/text:text.category_${group.category}`)}
                </span>
              </div>
            </div>
            <div className={styles['header-options']}>
              <CreateGroupButtonAndModal
                parentGroup={group}
                className="mr-2"
                handleSuccessfullyCreatedNewGroup={
                  (groupId) => navigateToPage(routes.managementGroupsDetailPath(groupId))
                }
                handleCreatedAndCreateAnother={
                  handleCreatedAndCreateAnother
                } />

              <NavLink
                to={routes.analysisPathForGroupList(getArrayOfIdsOfAllChildrenOfGroup())}
                className={
                  classnames(
                        styles.button,
                        'btn btn-light mr-2',
                        (group.membersCount === 0) ? 'disabled' : undefined
                  )
                }>

                <i className="fa fa-chart-area" />
              </NavLink>

              <EditGroupButton
                t={t}
                group={group}
                canEdit={canEdit}
                onMove={onMove}
                onDelete={onDelete}
                refatchGroups={refetchPageData}
                dataTip={getDisabledEditTooltip(group)}/>
            </div>
          </Col>
        </Row>

        <Row>
          <Col sm="9">
            <CollapsibleAlertsSection
              groupId={group.id}
              className={styles['alerts-section']}
              mutedUntil={group.currentAlertsMuteEnd}
              currentSetting={{
                editHref: routes.editGroupPath(group.id, { t: 'alerts' }),
                inheritedFrom: group.alertThresholds.inheritedFrom,
              }} />
          </Col>
        </Row>

        <Row>
          <Col sm="9">
            <GroupList
              displayDeviceIssueSortAndFilter={false}
              location={location}
              ancestor={group}
              newlyCreatedGroupId={newlyCreatedGroupId}
            />
          </Col>
        </Row>
      </Container>
    </AppLayout>
  )
}

export default GroupOfGroupsDetail
