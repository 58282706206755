export default class PressureEvent {
  constructor(data) {
    Object.assign(this, data)

    this.end = new Date(Math.round(data.endTime / 1e6))
    this.start = new Date(Math.round(data.startTime / 1e6))
    this.eventClasses = data.eventClasses || []
    this.eventCharacteristics = data.eventCharacteristics || []
  }

  get duration() {
    return this.end.getTime() - this.start.getTime()
  }

  get cpisScore() {
    const cpisCharacteristic = this.eventCharacteristics.find(char => {
      return char.eventCharacteristic === 'cpis'
    })

    return cpisCharacteristic ? cpisCharacteristic.value : null
  }

  get severity() {
    const severityCharacteristic = this.eventCharacteristics.find(char => {
      return char.eventCharacteristic === 'severity'
    })

    return severityCharacteristic ? severityCharacteristic.value : null
  }
}
