import React, { useContext, useEffect } from 'react'
import { createContext } from 'react'
import useGetPressureData from './useGetPressureData'
import useGetAnomaliesData from './useGetAnomaliesData'
import useGetAlertsData from './useGetAlertsData'
import useGetAnomalyBoundsData from './useGetAnomalyBoundsData'
import useGetRawData from './useGetRawData'
import useGetPressureEvents from './useGetPressureEvents'
import { useGetListRawData } from './useGetListRawData'
import { PressureSubsetDataContext } from '../../analysis_path/pressure_analysis_path/pressure_subset_data_provider'

export const PlotDataContext = createContext({})

const PlotDataContextProvider = ({ children, fatchingVariables }) => {
  const { setPressureSubsetDataResult } = useContext(PressureSubsetDataContext)

  const { pressureSubsetDataResult } = useGetPressureData(fatchingVariables)
  const { alertsResult } = useGetAlertsData(fatchingVariables)
  const { rawDataRequestsResult } = useGetRawData(fatchingVariables)
  const { pressureEvents } = useGetPressureEvents(fatchingVariables)
  const { listRawDataRequestsResult, refetchRawDataRequests } =
    useGetListRawData(fatchingVariables)

  const { anomalyResult } = useGetAnomaliesData(
    fatchingVariables,
    fatchingVariables.anomaliesEnabled
  )
  const { anomalyBoundsResult } = useGetAnomalyBoundsData(
    fatchingVariables,
    fatchingVariables.anomaliesEnabled
  )

  useEffect(() => {
    setPressureSubsetDataResult(pressureSubsetDataResult)
  }, [pressureSubsetDataResult])
  return (
    <PlotDataContext.Provider
      value={{
        plotInfo: fatchingVariables,
        pressureSubsetDataResult,
        anomalyResult,
        alertsResult,
        anomalyBoundsResult,
        rawDataRequestsResult,
        pressureEvents,
        listRawDataRequestsResult,
        refetchRawDataRequests,
      }}
    >
      {children}
    </PlotDataContext.Provider>
  )
}

export const withPressureSubsetDataContainer = (Component) => {
  return (props) => {
    const { pressureSubsetDataResult } = useContext(PlotDataContext)
    return (
      <Component
        {...props}
        pressureSubsetDataResult={pressureSubsetDataResult}
      />
    )
  }
}

export default PlotDataContextProvider
