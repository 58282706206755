import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash/fp/object'
import { line } from 'd3-shape'
import { createSelector } from 'reselect'

import { globalSequence } from '@@src/utils'
import { GraphConfig, withGraphConfig }
from '@@src/components/graphs/graph_context'
import { LegendItemColorContext } from
'@@src/components/colors/legend_item_color_provider'

function LinePlot(props) {
  return <path fill="none" stroke={props.color} {...props}/>
}

class LinePlotsLayer extends React.PureComponent {
  static propTypes = {
    graphItems: PropTypes.array.isRequired,
    graphConfig: PropTypes.instanceOf(GraphConfig).isRequired,
  }

  render() {
    const { name, graphConfig, graphItems } = this.props

    const topOffset = graphConfig.topPadding
    const leftOffset = graphConfig.leftPadding

    const clipPathId = `line-plots-clip-path-${this.id}`
    const lineGenerator = this.selectLineGenerator(this.props)

    return (
      <g name={name || 'subset-plots-layer'}>
        <defs>
          <clipPath id={clipPathId}>
            <rect
              x={leftOffset}
              y={topOffset}
              width={graphConfig.plotAreaWidth}
              height={graphConfig.plotAreaHeight}/>
          </clipPath>
        </defs>

        {
          graphItems.map(graphItem => (
            <LegendItemColorContext.Consumer key={graphItem.id}>
              {({ getLegendItemColor }) => (
                <g>
                  {
                    graphItem.data.map((segment, index) => (
                      <g key={index}>
                        <LinePlot
                          d={lineGenerator(segment.data)}
                          color={getLegendItemColor(graphItem.legendItem)}
                          clipPath={`url(#${clipPathId})`}/>
                      </g>
                    ))
                  }
                </g>
              )}
            </LegendItemColorContext.Consumer>
          ))
        }
      </g>
    )
  }

  constructor(props) {
    super(props)

    this.id = globalSequence.next()
  }

  selectLineGenerator = createSelector(
    [get('graphConfig')],
    graphConfig => {
      return line()
        .x(d => graphConfig.xScale(d.x))
        .y(d => graphConfig.yScale(d.y))
    }
  )
}

export default withGraphConfig()(LinePlotsLayer)
