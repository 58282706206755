import React, { memo } from 'react'
import { ButtonGroup } from 'reactstrap'
import { Link } from 'react-router-dom'
import routes from '../../../routes'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

function DevicesViewSwitcher({ location, t }) {
  const currentPath = location.pathname
  const listClassName = currentPath === routes.managementDevicesPath()
    ? 'btn btn-secondary active'
    : 'btn btn-outline-secondary'
  const tableClassName = currentPath === routes.managementDevicesTablePath()
    ? 'btn btn-secondary active'
    : 'btn btn-outline-secondary'

  return (
    <ButtonGroup className={'ml-sm-4'}>
      <Link to={routes.managementDevicesPath()} className={listClassName}>
        <i className="fa fa-list mr-1"/>
        {t('buttons.inventory')}
      </Link>
      <Link to={routes.managementDevicesTablePath()} className={tableClassName}>
        <i className="fa fa-heartbeat mr-1"/>
        {t('buttons.health')}
      </Link>
    </ButtonGroup>
  )
}

DevicesViewSwitcher.propTypes = {
  location: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
}

DevicesViewSwitcher.defaultProps = {}

export default compose(
  withTranslation(['src/management_path/text']),
)(memo(DevicesViewSwitcher))
