import queryString from 'query-string'
import { isEmpty } from 'lodash/fp/lang'

export default {
  homePath,
  loginPath,
  appInfoPath,
  analysisPath,
  settingsPath,
  editGroupPath,
  managementPath,
  analysisMapPath,
  analysisListPath,
  analysisPressurePath,
  analysisPressurePlotPath,
  analysisPressureCPISPath,
  analysisPressureEventsPath,
  analysisPressureEventSourcesPath,
  analysisPressureAnomaliesPath,
  managementGroupsPath,
  managementDevicesPath,
  managementDevicesTablePath,
  loginPasswordRecoveryPath,
  managementGroupsDetailPath,
  managementDevicesDetailPath,
  managementNetworkAssetsPath,
  networkAssetsDetailPath,
  editNetworkAssetPath,
  loginPasswordRecoveryEnterRecoveryCodePath,
  downloadsPath,
  adminPath,
  adminUsersPath,
  analysisPathForGroup,
  analysisPathForGroupList,
  adminTenantSettingsPath,
  alertsPath,
  alertDetailsPath,
  previousReleaseNotesPath,
  adminTenantSettingsLanguageAndRegion,
  adminTenantSettingsRoot,
  createSearchParams,
}

function createSearchParams(params) {
  return !isEmpty(params) ? '?' + queryString.stringify(params) : ''
}

function homePath() {
  return '/'
}

function loginPath() {
  return '/login'
}

function appInfoPath() {
  return '/app-info'
}

function analysisPath(params) {
  return `/analysis${createSearchParams(params)}`
}

function analysisMapPath(params) {
  return `/analysis/map${createSearchParams(params)}`
}

function analysisListPath(params) {
  return `/analysis/list${createSearchParams(params)}`
}

function analysisPressurePath(params) {
  return `/analysis/data${createSearchParams(params)}`
}

function analysisPressurePlotPath(params) {
  return `/analysis/data/plot${createSearchParams(params)}`
}

function analysisPressureCPISPath(params) {
  return `/analysis/data/cpis${createSearchParams(params)}`
}

function analysisPressureEventsPath(params) {
  return `/analysis/data/events${createSearchParams(params)}`
}

function analysisPressureEventSourcesPath(params) {
  return `/analysis/data/event-sources${createSearchParams(params)}`
}

function analysisPressureAnomaliesPath(params) {
  return `/analysis/data/anomaly-list${createSearchParams(params)}`
}

function managementPath() {
  return '/management'
}

function managementDevicesPath() {
  return '/management/devices'
}

function managementDevicesTablePath() {
  return '/management/devices-table'
}

function managementGroupsPath() {
  return '/management/groups'
}

function editGroupPath(id, params) {
  if (!id || !Number.isFinite(id)) {
    throw new Error(`Invalid device ID "${id}"`)
  }

  const search = createSearchParams(params)
  return `/management/groups/${encodeURIComponent(id)}/edit${search}`
}

function managementDevicesDetailPath(id) {
  if (!id || !Number.isFinite(id)) {
    throw new Error(`Invalid device ID "${id}"`)
  }

  return `/management/devices/${encodeURIComponent(id)}`
}

function managementGroupsDetailPath(id) {
  return `/management/groups/${encodeURIComponent(id)}`
}

function managementNetworkAssetsPath() {
  return '/management/network-assets'
}

function networkAssetsDetailPath(id) {
  if (!id || !Number.isFinite(id)) {
    throw new Error('Invalid network asset ID')
  }

  return `/management/network-assets/${encodeURIComponent(id)}`
}

function editNetworkAssetPath(id, params) {
  if (!id || !Number.isFinite(id)) {
    throw new Error('Invalid network asset ID')
  }

  const search = createSearchParams(params)
  return `/management/network-assets/${encodeURIComponent(id)}/edit${search}`
}

function settingsPath() {
  return '/settings'
}

function loginPasswordRecoveryPath() {
  return '/login/password-recovery'
}

function loginPasswordRecoveryEnterRecoveryCodePath() {
  return '/login/password-recovery/enter-recovery-code'
}

function downloadsPath() {
  return '/downloads'
}

function adminPath() {
  return '/admin'
}

function adminUsersPath() {
  return '/admin/users'
}

function adminTenantSettingsRoot() {
  return '/admin/settings'
}

function adminTenantSettingsPath() {
  return '/admin/settings/:settingType'
}

function adminTenantSettingsLanguageAndRegion() {
  return '/admin/settings/language-and-region'
}

function analysisPathForGroupList(props) {
  return analysisPressurePath({
    d: props,
  })
}

function analysisPathForGroup(group) {
  if (
    group.isGroupOfGroups() &&
    group.mostDistantDeviceOrAssetGroups.length > 0
  ) {
    const groupsToAdd = group.mostDistantDeviceOrAssetGroups.map((g) => {
      return `g.${g.id}`
    })
    return analysisPressurePath({
      d: groupsToAdd,
    })
  } else {
    return analysisPressurePath({
      d: group.dataSourceId,
    })
  }
}

function alertsPath(params) {
  return `/alerts${createSearchParams(params)}`
}

function alertDetailsPath(alertId) {
  return `/alerts/${encodeURIComponent(alertId)}`
}

function previousReleaseNotesPath() {
  return '/release-notes'
}
