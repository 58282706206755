import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import * as analytics from '../../../analytics'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'

const ExportButtonState = {
  idle: 'idle', loading: 'loading', success: 'success', failure: 'failure',
}

function ExportModal({ children, onSuccess, onFailure, onCancel, requestExport, isOpen, title, t }) {
  const [exportButtonState, setExportButtonState] = useState(ExportButtonState.idle)
  const handleExportButton = async () => {
    try {
      setExportButtonState(ExportButtonState.loading)
      await requestExport()
      setExportButtonState(ExportButtonState.success)
      onSuccess()
    } catch (e) {
      analytics.logError(e)
      setExportButtonState(ExportButtonState.failure)

      if (typeof onFailure === 'function') {
        onFailure()
      }
    }
  }

  const renderExportButtonContent = () => {
    switch (exportButtonState) {
      case ExportButtonState.loading :
        return (<i className="far fa-spinner-third fa-spin" />)
      case ExportButtonState.success :
        return (<i className="far fa-check" />)
      case ExportButtonState.failure :
        return (<i className="fas fa-exclamation-triangle" />)
      default:
        return t('buttons.export')
    }
  }

  const handleModalToggle = () => {
    if (exportButtonState !== ExportButtonState.loading) {
      onCancel()
    }
  }

  useEffect(() => {
    if (!isOpen) {
      setTimeout(() => setExportButtonState(ExportButtonState.idle), 500)
    }
  }, [isOpen])

  return (<Modal isOpen={isOpen} toggle={handleModalToggle}>
    <ModalHeader>{title}</ModalHeader>
    <ModalBody>{children}</ModalBody>
    <ModalFooter>
      <Button
        color="secondary"
        name="cancel-button"
        onClick={onCancel}
        disabled={exportButtonState === ExportButtonState.loading}
      >
        {t('buttons.cancel')}
      </Button>
      <Button
        color="primary"
        name="export-button"
        onClick={handleExportButton}
        disabled={exportButtonState === ExportButtonState.loading}
      >{renderExportButtonContent()}</Button>
    </ModalFooter>
  </Modal>)
}

ExportModal.propTypes = {
  title: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  requestExport: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onFailure: PropTypes.func,
  children: PropTypes.node,
}

export default compose(
  withTranslation([
    'src/management_path/devices_path/export_devices_config_modal',
  ]),
)(ExportModal)

