import React from 'react'
import PropTypes from 'prop-types'
import { noop } from 'lodash/fp/util'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import DragPanLayer from '@@src/components/graphs/drag_pan_layer'
import DragZoomLayer from '@@src/components/graphs/drag_zoom_layer'
import DragSelectLayer from '@@src/components/graphs/drag_select_layer'
import { ONLY_X, X_AND_Y, ONLY_Y, NONE }
from '@@src/components/graphs/drag_layer/drag_event_layer'
import { ZOOM_DRAG_MODE, SELECT_DRAG_MODE }
from '@@src/components/singletons/drag_cover'
import GraphContext, { GraphConfig }
from '@@src/components/graphs/graph_context'
import GraphItem from '@@src/components/graphs/graph_item'
import AsyncResult from '@@src/utils/async_result'

class DragControlsLayer extends React.PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    onPan: PropTypes.func.isRequired,
    onAxesChange: PropTypes.func.isRequired,
    dragBehaviour: PropTypes.oneOf([NONE, ONLY_X, ONLY_Y, X_AND_Y]).isRequired,
    graphConfig: PropTypes.instanceOf(GraphConfig).isRequired,
    networkAssetGraphItems:
      PropTypes.arrayOf(PropTypes.instanceOf(GraphItem)).isRequired,
    listRawDataRequestsResult: PropTypes.instanceOf(AsyncResult),
    refetchRawDataRequests: PropTypes.func,
  }

  static defaultProps = {
    onPan: noop,
    onAxesChange: noop,
    dragBehaviour: ONLY_X,
    networkAssetGraphItems: [],
    refetchRawDataRequests: noop,
  }

  render() {
    const {
      dragMode, onPan, dragBehaviour, children, graphConfig,
      networkAssetGraphItems, t, refetchRawDataRequests,
      listRawDataRequestsResult,
    } = this.props

    return (
      dragMode === ZOOM_DRAG_MODE ? (
        <DragZoomLayer graphConfig={graphConfig} onZoom={this.onGraphZoom}>
          {children}
        </DragZoomLayer>
      ) : dragMode === SELECT_DRAG_MODE ? (
        <DragSelectLayer
          listRawDataRequestsResult={listRawDataRequestsResult}
          refetchRawDataRequests={refetchRawDataRequests}
          t={t}
          dragBehaviour={dragBehaviour}
          graphConfig={graphConfig}
          networkAssetGraphItems={networkAssetGraphItems}>
          {children}
        </DragSelectLayer>
      ) : (
        <DragPanLayer
          graphConfig={graphConfig}
          dragBehaviour={dragBehaviour}
          onPan={onPan}
          onPanEnd={this.onGraphPanEnd}>
          {children}
        </DragPanLayer>
      )
    )
  }

  onGraphZoom = ({ dragX, dragY }) => {
    if (dragX || dragY) {
      this.props.onAxesChange({ xAxes: dragX, yAxes: dragY })
    }
  }

  onZoomDrag = ({ dragX, dragY }) => {
    if (dragX || dragY) {
      this.props.onAxesChange({ xAxes: dragX, yAxes: dragY })
    }
  }

  onGraphPanEnd = ({ panEventX, panEventY }) => {
    if (panEventX || panEventY) {

      this.props.onAxesChange({ xAxes: panEventX, yAxes: panEventY })
    }
  }
}

function mapStateToProps(state) {
  return {
    dragMode: state.dragCover.dragMode,
  }
}

function DragControlsLayerContainer(props) {
  return (
    <GraphContext.Consumer>
      {config => <DragControlsLayer graphConfig={config} {...props}/> }
    </GraphContext.Consumer>
  )
}

export default compose(
  connect(mapStateToProps),
  withTranslation([
    'src/components/graphs/drag_select_layer/select_dropdown',
    'src/components/graphs/modals/request_raw_data_modal',
  ])
)(DragControlsLayerContainer)
