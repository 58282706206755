import React, { useCallback, useContext, useEffect, useState } from 'react'
import { renderAbsolutePositionedLoading } from '../../../components/app_suspense_container'
import { apiUrl, tenant } from '../../../config'
import { AuthorizationContext } from '../authorization/authorization.context'

export const TenantSettingsContext = React.createContext({
  tenantSettings: null,
  reloadTenantSettings: () => Promise.resolve(),
})

export const TenantSettingsProvider = ({ children }) => {
  const {authorization} = useContext(AuthorizationContext)
  const [tenantSettings, setTenantSettings] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  const reloadTenantSettings = useCallback(async () => {
    setIsLoading(true)

    const token = await authorization.getCurrentUserToken()
    const response = await fetch(`${apiUrl}/v1/rest/${tenant}/tenant-settings`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    const appConfig = await response.json()

    setIsLoading(false)

    if (!response.ok || response.status !== 200) {
      throw new Error(`Cannot load settings for tenant ${tenant}`)
    }

    setTenantSettings(appConfig)
  }, [setTenantSettings, setIsLoading])

  useEffect(() => {
    reloadTenantSettings().then()
  }, [reloadTenantSettings])

  if (isLoading) {
    return renderAbsolutePositionedLoading()
  }

  return (
    <TenantSettingsContext.Provider value={{ tenantSettings, reloadTenantSettings }}>
      {children}
    </TenantSettingsContext.Provider>
  )
}

export const withTenantSettings = (Component) => (props) => (
  <TenantSettingsContext.Consumer>
    {(contextValues) => (<Component {...props} {...contextValues} />)}
  </TenantSettingsContext.Consumer>
)
