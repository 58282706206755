import React, { useContext } from 'react'
import hoistNonReactStatics from 'hoist-non-react-statics'
import { Redirect } from 'react-router-dom'
import routes from '@@src/routes'
import { AuthorizationContext } from '../../_v2/contexts/authorization/authorization.context'
import {
  InternalIdentityProvider,
} from '../../_v2/contexts/authorization/identity_providers/abstract/internal-identity-provider'
import {
  OauthLibraryBasedIdentityProvider,
} from '../../_v2/contexts/authorization/identity_providers/abstract/oauth-library-based-identity-provider'
import { UserContext } from '../../_v2/contexts/user/user.context'
import { renderAbsolutePositionedLoading } from '../app_suspense_container'

function requiresLogin(Component) {
  const RequiresLogin = (props) => {
    const { authorization } = useContext(AuthorizationContext)
    const { isLogged, reloadAuthorizedUser } = useContext(UserContext)
    const state = { returnTo: window.location?.pathname + (window.location?.search || '') }

    if (isLogged()) {
      return <Component {...props} />
    } else if (!authorization) {
      // render loading screen when there is no authorization service initialized
      return renderAbsolutePositionedLoading()
    } else if (authorization.identityProvider instanceof InternalIdentityProvider) {
      // redirect to the login path when identity provider supports internal app authorization flow
      return (<Redirect to={{ pathname: routes.loginPath(), search: routes.createSearchParams(state) }} />)
    } else if (authorization.identityProvider instanceof OauthLibraryBasedIdentityProvider) {
      authorization.identityProvider.setup().then(() => reloadAuthorizedUser())
      return renderAbsolutePositionedLoading() // render loading page while setting up the auth dependency
    } else {
      throw new Error('Unsupported state of the requiresLogin component')
    }
  }

  hoistNonReactStatics(RequiresLogin, Component)

  return RequiresLogin
}

export default requiresLogin
