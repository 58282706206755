import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { GraphConfig } from '@@src/components/graphs/graph_context'

export function LinePlot(props) {
  return (
    <path fill="none" {...props}/>
  )
}

export function CirclePlot(props) {
  return (
    <circle stroke="none" {...props} />
  )
}

export default class HistoryPlotLayer extends PureComponent {
  static propTypes = {
    graphConfig: PropTypes.instanceOf(GraphConfig).isRequired,
    clipPathId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    children: PropTypes.node,
  }

  render() {
    const { name, graphConfig, children, clipPathId } = this.props
    const topOffset = graphConfig.topPadding
    const leftOffset = graphConfig.leftPadding

    return (
      <g name={name}>
        <defs>
          <clipPath id={clipPathId}>
            <rect
              x={leftOffset}
              y={topOffset}
              width={graphConfig.plotAreaWidth}
              height={graphConfig.plotAreaHeight}/>
          </clipPath>
        </defs>

        {children}
      </g>
    )
  }
}
