import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import AppSuspenseContainer from '@@src/components/app_suspense_container'

import styles from './graph_state_overlay.css'

const GraphStateOverlay = props => {
  const { children, className } = props

  return (
    <AppSuspenseContainer className={classnames(styles.container, className)}>
      {children}
    </AppSuspenseContainer>
  )
}

GraphStateOverlay.propTypes = {
  t: PropTypes.func.isRequired,
  isDataPending: PropTypes.bool.isRequired,
  hasData: PropTypes.bool.isRequired,
  displayTooltips: PropTypes.bool.isRequired,
  noDataText: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
}

GraphStateOverlay.defaultProps = {
  isDataPending: false,
  hasData: false,
  displayTooltips: true,
}

export default withTranslation(
  'src/components/graphs/graph_state_overlay'
)(GraphStateOverlay)
