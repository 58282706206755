import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as Changes from '@@src/store/changes'
import { useDispatch } from 'react-redux'
import { useApolloClient } from '@apollo/client'
import { exportDevices } from '../../../../../api/export.service'
import ExportSourceModal from '../../../../../components/modals/export_source/export_source_modal.component'
import LoadingIcon from '../../../../../../components/loading_icon'

import PropTypes from 'prop-types'

const ExportDevicesModal = ({ isOpen, toggle, deviceNumber, filters, enabled = true }) => {
  const { t } = useTranslation([
    'v2/devices/forms',
    'v2/common/buttons',
  ])
  const client = useApolloClient()
  const dispatch = useDispatch()
  const [loadingForReport, setLoadingForReport] = useState(false)

  const handleExportNetworkAssets = useCallback(() => {
    setLoadingForReport(true)
    exportDevices(client, filters)
      .then(() => {
        setLoadingForReport(false)
        toggle()
        dispatch(Changes.notifyChange(Changes.CSV_DOWNLOAD_CHANGES))
      }).catch((error) => {
        setLoadingForReport(false)
        // eslint-disable-next-line no-console
        console.warn('Error exporting data:', error)
      })
  }, [client, filters, setLoadingForReport, toggle])

  return(
    <ExportSourceModal
      isOpen={isOpen}
      toggle={toggle}
      onExport={handleExportNetworkAssets}
      header={t('v2/devices/forms:export_modal.export_devices')}
      body={
        <>
          {t('v2/devices/forms:export_modal.devices_count', { count: deviceNumber })}
          {
            loadingForReport &&
            <div className="d-flex flex-row">
              <LoadingIcon />
              <span className="ml-2">
                {t('v2/devices/forms:export_modal.waiting_for_report')}
              </span>
            </div>
          }
        </>
      }
      enabled={enabled && !loadingForReport}
    />
  )
}

ExportDevicesModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  deviceNumber: PropTypes.number,
  filters: PropTypes.object.isRequired,
  enabled: PropTypes.bool,
}

export default ExportDevicesModal
