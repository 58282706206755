import React, { PureComponent, createRef } from 'react'
import PropTypes from 'prop-types'
import { Badge } from 'reactstrap'
import classnames from 'classnames'
import { compose } from 'redux'
import { debounce } from 'lodash/function'
import { get } from 'lodash/fp/object'
import { graphql } from '@apollo/client/react/hoc'
import { map, flatMap, partition } from 'lodash/fp/collection'
import moment from 'moment'
import optimizeSubsetDataQuery from './constants/optimize_subset_data_query'
import { withTranslation } from 'react-i18next'
import { createSelector } from 'reselect'
import gql from 'graphql-tag'
import { connect } from 'react-redux'

import AsyncList from '@@src/components/lists/async_list'
import { parseGraphQLResult } from '@@src/api/presenters'
import { createSelectGraphQLResult } from '@@src/utils'
import transformProps from '@@src/components/transform_props'
import LegendListItem from './legend_list_item'
import AsyncResult from '@@src/utils/async_result'
import LegendItem from '@@src/components/graphs/legend_item'
import InvalidSelectionAlert, { isIncorrectIdErrorResult } from
'@@src/components/alerts/invalid_selection_alert'

import styles from './legend_list_items.css'

class LegendListItems extends PureComponent {
  static propTypes = {
    onRemoveSource: PropTypes.func,
    onRequestRetry: PropTypes.func.isRequired,
    startTime: PropTypes.instanceOf(Date),
    endTime: PropTypes.instanceOf(Date),
    handleChangeVisibility: PropTypes.func.isRequired,
    hierarchyLegendItemsResult: PropTypes.instanceOf(AsyncResult).isRequired,
    legendItemsResult: PropTypes.instanceOf(AsyncResult).isRequired,
    focusedLegendItem: PropTypes.instanceOf(LegendItem),
    t: PropTypes.func.isRequired,
  }

  containerRef = createRef()

  state = {
    focusedLegendItemAbove: false,
    focusedLegendItemBelow: false,
  }

  render() {
    const {
      t,
      hierarchyLegendItemsResult,
      focusedLegendItem,
    } = this.props
    const { focusedLegendItemAbove, focusedLegendItemBelow } = this.state
    const badgeClassnames = classnames({
      [styles['active-legend-item-hidden-top']]: focusedLegendItemAbove,
      [styles['active-legend-item-hidden-bottom']]: focusedLegendItemBelow,
      'd-none': !focusedLegendItemAbove && !focusedLegendItemBelow,
    })

    return (
      <div className={styles['legend-list-items']}>
        {
          focusedLegendItem && focusedLegendItemAbove ?
            <div name="top-indicator-badge" className={badgeClassnames}>
              {this.renderIndicatorBadge()}
            </div>
            : null
        }
        <div
          name="legend-list-container"
          // onScroll={this.handleScroll}
          className={styles['legend-list-container']}
          ref={this.containerRef}>
          <AsyncList
            renderFailResult={
              hierarchyLegendItemsResult.wasFailure() &&
                isIncorrectIdErrorResult(hierarchyLegendItemsResult) ?
                this.handleFailResult : undefined
            }
            className={styles['legend-list-items-list']}
            result={hierarchyLegendItemsResult}
            renderItem={this.renderLegendListItem}
            noResultText={t('text.no_data_sources_found')}
            onRequestRetry={this.props.onRequestRetry}
          />
        </div>
        {
          focusedLegendItem && focusedLegendItemBelow ?
            <div name="bottom-indicator-badge" className={classnames(badgeClassnames, 'pb-4')}>
              {this.renderIndicatorBadge()}
            </div>
            : null
        }
      </div>
    )
  }

  renderIndicatorBadge = () => {
    const { focusedLegendItem, t } = this.props
    const { focusedLegendItemAbove, focusedLegendItemBelow } = this.state

    return (
      <Badge
        className={styles['legend-list-indicator']}
        color="dark"
        pill={true}>
        <span className={classnames('far', {
          'fa-arrow-up': focusedLegendItemAbove,
          'fa-arrow-down': focusedLegendItemBelow,
        })} />
        &nbsp; {focusedLegendItem.legendItemNameAndChannelText(t)}
      </Badge>
    )
  }

  componentDidUpdate(prevProps) {
    const { focusedLegendItem } = this.props
    const {
      focusedLegendItem: prevFocusedLegendItem,
    } = prevProps

    if (focusedLegendItem !== prevFocusedLegendItem) {
      this.setFocusedLegendItemDifferences()
    }
  }

  setFocusedLegendItemDifferences = () => {
    const { focusedLegendItem } = this.props
    const newState = {
      focusedLegendItemAbove: false,
      focusedLegendItemBelow: false,
    }
    const legendItemRefs = this.selectLegendListItemRefs(this.props)
    const currentLegendItemRefs = legendItemRefs.filter(
      ({ ref }) => ref && ref.current
    )

    if (focusedLegendItem && currentLegendItemRefs.length > 0 &&
      this.containerRef.current) {
      const difference = this.getClosestFocusedLegendItemDifference()

      newState.focusedLegendItemAbove = difference > 0
      newState.focusedLegendItemBelow = difference < 0
    }

    this.setState(newState)
  }

  getClosestFocusedLegendItemDifference = () => {
    const { focusedLegendItem } = this.props
    const legendItemRefs = this.selectLegendListItemRefs(this.props)
      .filter(itemWithRef => itemWithRef.item.id === focusedLegendItem.id)
      .map(({ ref }) => ref)
      .filter(ref => ref && ref.current)
    let itemDifference = Infinity

    for (let i = 0; i < legendItemRefs.length; i++) {
      itemDifference = this.getDifferenceBetweenItemAndContainer(
        itemDifference,
        legendItemRefs[i].current.getBoundingClientRect(),
        this.containerRef.current.getBoundingClientRect()
      )
    }

    return Number.isFinite(itemDifference) ? itemDifference : 0
  }

  getDifferenceBetweenItemAndContainer(carryDiff, itemRect, containerRect) {
    const {
      bottom: itemBottom,
      top: itemTop,
      height: itemHeight,
    } = itemRect
    const {
      bottom: containerBottom,
      top: containerTop,
    } = containerRect
    const heightBuffer = itemHeight / 3 * 2
    const bufferedTopDifference = containerTop - itemBottom + heightBuffer
    const bufferedBottomDifference = containerBottom - itemTop - heightBuffer

    if (bufferedTopDifference < 0 && bufferedBottomDifference > 0) {
      // Item is within the scroll viewport
      return 0
    }

    if (bufferedTopDifference > 0 &&
      Math.abs(bufferedTopDifference) < Math.abs(carryDiff)) {
      // Item is above scroll viewport and closer than previous item
      return bufferedTopDifference
    } else if (bufferedBottomDifference < 0 &&
      Math.abs(bufferedBottomDifference) < Math.abs(carryDiff)) {
      // Item is below scroll viewport and closer than previous item
      return bufferedBottomDifference
    }

    return carryDiff
  }

  handleScroll = debounce(this.setFocusedLegendItemDifferences, 100)

  renderLegendListItem = legendItem => {
    const {
      focusedLegendItem,
      handleChangeVisibility,
      emptiesCollapsed,
      itemsHaveData,
    } = this.props
    const legendListItemRefs = this.selectLegendListItemRefs(this.props)

    return (
      <LegendListItem
        legendListItemRefs={legendListItemRefs}
        focusedLegendItemId={
          focusedLegendItem ? focusedLegendItem.id : undefined
        }
        key={legendItem.id}
        legendItem={legendItem}
        itemsHaveData={itemsHaveData}
        emptiesCollapsed={emptiesCollapsed}
        onRemoveSource={this.createOnRemoveSource(legendItem)}
        onChangeVisibility={handleChangeVisibility} />
    )
  }

  mapItemToRef = (item) => {
    // We want to collect all children as refs - e.g. all group devices, and
    // asset channels (anything that is represented on the graph)
    // e.g. this can be: group -> asset -> asset channel
    if (item.hasChildren()) {
      return flatMap(this.mapItemToRef)(item.children)
    } else {
      return {
        item: item,
        ref: createRef(),
      }
    }
  }

  createOnRemoveSource(legendItem) {
    return this.props.onRemoveSource ? (event => {
      event.stopPropagation()

      this.props.onRemoveSource(legendItem)
    }) : undefined
  }

  handleFailResult = () => {
    return (
      <li className="mr-3">
        <InvalidSelectionAlert />
      </li>
    )
  }

  selectLegendListItemRefs = createSelector(
    [get('focusedLegendItem'), get('hierarchyLegendItemsResult')],
    (focusedLegendItem, hierarchyLegendItemsResult) => {
      if (hierarchyLegendItemsResult.isPending() || !focusedLegendItem) {
        return []
      }

      return flatMap(this.mapItemToRef)(hierarchyLegendItemsResult.data)
    }
  )

  static HAS_DATA_QUERY = gql`
    query DeviceHasDataInRange(
      $end: DateString!,
      $start: DateString!,
      $deviceIds: [Int],
      $networkAssetChannels: [NetworkAssetChannel],
    ) {
      deviceHasData(
        end: $end,
        start: $start,
        deviceIds: $deviceIds,
        networkAssetChannels: $networkAssetChannels,
      ) {
        deviceId
        channel
        networkAssetId
        hasDataInRange
      }
  }
  `
}

export function createMapStateToProps() {
  const selectPressureSubsetQueryVariables = createSelector(
    [
      get('legendItemsResult'),
      get('startTime'),
      get('endTime'),
      get('eventIds'),
    ],
    (legendItemsResult, startTime, endTime, eventIds) => {
      const { data: legendItems } = legendItemsResult
      const { start, end, resolution } = optimizeSubsetDataQuery({
        start: startTime,
        end: endTime,
      })

      // separate devices from assets in legend items result
      const [deviceSources, assetSources] =
        partition(item => item.isFromDevice())(legendItems)

      // get device ids
      const deviceIds = deviceSources ? map('sourceId')(deviceSources) : []
      // get network asset channels
      const networkAssetChannels =
        assetSources.map(({ sourceId, sourceChannel }) => {
          return { networkAssetId: sourceId, channel: sourceChannel }
        })
      const parameters = {
        end: moment(end).toISOString(),
        start: moment(start).toISOString(),
        deviceIds: deviceIds,
        resolution,
        networkAssetChannels: networkAssetChannels,
      }

      if (Array.isArray(eventIds)) {
        parameters.eventIds = eventIds
      }

      return parameters
    }
  )

  return function mapStateToProps() {
    return {
      selectPressureSubsetQueryVariables,
    }
  }
}

export default compose(
  connect(createMapStateToProps),
  withTranslation([
    'src/analysis_path/pressure_analysis_path/legend_list_items',
    'common/text',
  ]),
  transformProps(() => () => ({
    selectHasDataResult: createSelectGraphQLResult(
      'deviceHasData',
      {
        mapResult: parseGraphQLResult,
      }
    ),
  })),
  graphql(LegendListItems.HAS_DATA_QUERY, {
    options: (props) => {
      const { selectPressureSubsetQueryVariables } = props

      return {
        variables: {
          ...selectPressureSubsetQueryVariables(props),
        },
      }
    },
    props: ({ data, ownProps }) => {
      const { selectHasDataResult } = ownProps

      return {
        itemsHaveData: selectHasDataResult(data),
      }
    },
  }),
)(LegendListItems)
