import React, { memo } from 'react'
import { Badge } from 'reactstrap'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import styles from './info_button.css'

function InfoButton(props) {
  const { className } = props

  return (
    <button className={classnames(className, styles['info-button'])} {...props}>
      <Badge
        pill={true}
        color="info">
        <i className="fas fa-info" />
      </Badge>
    </button>
  )
}

InfoButton.propTypes = {
  className: PropTypes.string,
}

export default memo(InfoButton)
