import gql from 'graphql-tag'
import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { graphql } from '@apollo/client/react/hoc'
import { withTranslation } from 'react-i18next'
import Installation from '@@src/api/presenters/installation'
import SubmitButton from '@@src/components/buttons/submit_button'
import { AsyncResult } from '@@src/utils'
import { Alert, Button, Modal, ModalBody, ModalFooter } from 'reactstrap'

import * as analytics from '@@src/analytics'

import styles from './uninstall_device_modal.css'

class UninstallDeviceModal extends React.PureComponent {
  static defaultProps = {
    onSuccess: () => { },
  }

  static propTypes = {
    onSuccess: PropTypes.func.isRequired,
    installation: PropTypes.instanceOf(Installation),
  }

  constructor(props) {
    super(props)
    this.state = {
      result: AsyncResult.notFound(),
    }
  }

  onToggle = (...args) => {
    const { toggle } = this.props
    this.setState({ result: AsyncResult.notFound() })
    if (toggle) {
      toggle(...args)
    }
  }

  onSubmitForm = async event => {
    event.preventDefault()

    this.setState({ result: AsyncResult.pending() })

    const { installation } = this.props
    const networkAssetId = installation.networkAsset.id
    const deviceId = installation.deviceId

    try {
      await this.props.mutate({
        variables: {
          networkAssetId,
          deviceId,
          reason: 'uninstall at user request',
        },
      })

      this.setState({ result: AsyncResult.success() })
      this.onToggle()
      this.props.onSuccess()
    } catch (e) {
      analytics.logError(e)
      this.setState({ result: AsyncResult.fail(e) })
    }
  }

  render() {
    const { isOpen } = this.props

    return (
      !isOpen ? null :
      <Modal
        isOpen={isOpen}
        toggle={this.onToggle}
        className={styles.container}>

        {
          this.renderStandardModal()
        }

      </Modal>
    )
  }

  renderStandardModal = () => {
    const { t, installation } = this.props
    const { result } = this.state
    const assetName = installation.networkAsset.assetId

    return (
      <div>
        <ModalBody>
          {
            !result.wasFailure() ? null :
            <Alert color="danger">
              {result.error.translateWith(t)}
            </Alert>
          }
          <div className={styles['modal-title']}>
            {t('headings.title')}
          </div>
          <div className={styles['modal-body']}>
            {t('text.warning', {
              assetName,
              channelName: installation.translateAssetChannel(t),
            })}
          </div>
        </ModalBody>
        <ModalFooter className={styles['buttons-row']}>
          <Button
            name="cancel-button"
            type="button"
            color="secondary"
            onClick={this.onToggle}>
            {t('buttons.cancel')}
          </Button>
          <SubmitButton
            buttonStatus=""
            name="uninstall-button"
            color="danger"
            onSubmitForm={this.onSubmitForm}
            result={result}
            submitText={t('buttons.uninstall')}>
          </SubmitButton>
        </ModalFooter>
      </div>
    )
  }

  static UNINSTALL_INSTALLATION = gql`
    mutation UninstallInstallation(
      $networkAssetId: Int!,
      $deviceId: Int!,
      $reason: String!,
    ) {
      uninstallInstallation(
        deviceId: $deviceId,
        networkAssetId: $networkAssetId,
        reason: $reason,
      )
    }`
}

export default compose(
  graphql(UninstallDeviceModal.UNINSTALL_INSTALLATION),
  withTranslation([
    'src/components/modals/uninstall_device_modal',
    'common/text',
  ])
)(UninstallDeviceModal)
