import gql from 'graphql-tag'
import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { compose } from 'redux'
import { graphql } from '@apollo/client/react/hoc'
import { NavLink } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { Button, DropdownToggle, UncontrolledTooltip } from 'reactstrap'

import * as analytics from '@@src/analytics'
import Alert from '@@src/api/presenters/alert'
import routes from '@@src/routes'
import AsyncResult from '@@src/utils/async_result'
import userPermissions from '@@src/components/permissions/user_permissions'
import NetworkAssetIcon from '@@src/components/icons/network_asset_icon'
import AlertTypeLinkButton from '@@src/alerts_path/alert_type_link_button'
import AlertListItemActions from '@@src/alerts_path/alert_list_item_actions'
import { sleep, AppError } from '@@src/utils'
import {
  convertAlertUnitText,
  convertAlertThreshold,
} from '@@src/utils/app_unit_conversion'
import { AVAILABLE_PRESSURE_UNITS } from '@@src/utils/unit_constants'

import styles from './alert_list_item.css'

const noop = () => {}
const ALERT_DATE_FORMAT = 'YYYY.MM.DD HH:mm:ss'
const INFLOWNET_WAIT = process.env.NODE_ENV === 'test' ? 0 : 1000

class AlertListItem extends React.PureComponent {
  static propTypes = {
    alert: PropTypes.any.isRequired,
    onError: PropTypes.func.isRequired,
    permissions: PropTypes.array.isRequired,
    onChangeAlertStatus: PropTypes.func.isRequired,
    onClickChangeAlertStatus: PropTypes.func.isRequired,
    pressureUnits: PropTypes.oneOf(AVAILABLE_PRESSURE_UNITS).isRequired,
  }

  state = {
    result: AsyncResult.notFound(),
    onError: noop,
    onChangeAlertStatus: noop,
    onClickChangeAlertStatus: noop,
  }

  render() {
    const { t, alert, permissions, pressureUnits } = this.props
    const alertIsOpen = alert.status === Alert.OPEN_STATUS
    const canEditAlerts = permissions.includes('can_edit_alerts')
    const isUpdatePending = this.state.result.isPending()

    return (
      <li className={styles.container}>
        <div className={styles['item-info']}>
          <div className={styles['item-info-row-top']}>
            <span className={styles['row-top-text']}>
              #{alert.alertId}
              &nbsp;
              &middot;
              &nbsp;
              {moment(alert.occurredAt).format(ALERT_DATE_FORMAT)}
            </span>

            {
              alert.isRelatedToNetworkAssets() ?
                this.renderNetworkAssetSummary() : null
            }
          </div>

          <div className={styles['item-info-row-bottom']}>
            <NavLink
              to={routes.alertDetailsPath(alert.alertId)}
              className={styles['alert-description-link']}>
              <span className={classnames(alert.statusIconClassname, {
                'text-danger': alertIsOpen,
              })} />

              &nbsp;

              <span className={styles['alert-description-text']}>
                {
                  t(alert.descriptionKey, {
                    threshold: convertAlertThreshold(alert, pressureUnits),
                    unit: t(convertAlertUnitText(alert, pressureUnits), {
                      count: convertAlertThreshold(alert, pressureUnits),
                    }),
                  })
                }
              </span>
            </NavLink>
          </div>
        </div>

        <div className={styles['item-actions']}>
          <Button
            href={routes.alertDetailsPath(alert.alertId)}
            size="sm"
            color="light"
            className={styles['action-btn']}>
            <span className="fa fa-info" />
          </Button>

          <AlertTypeLinkButton
            size="sm"
            alert={alert}
            color="light"
            className={styles['action-btn']}>
            <span className="far fa-chart-line" />
          </AlertTypeLinkButton>

          {
            !canEditAlerts ?
              <UncontrolledTooltip
                modifiers={{ flip: { enabled: false } }}
                placement="top"
                target={`alert-tt-${alert.alertId}`}>
                {t('common/text:permissions.disabled')}
              </UncontrolledTooltip>
              : null
          }
          <div id={`alert-tt-${alert.alertId}`}>
            <AlertListItemActions
              alert={alert}
              handleClickStatus={this.handleChangeAlertStatusClick}>
              <DropdownToggle
                disabled={isUpdatePending || !canEditAlerts}
                name="change-status-dropdown-toggle"
                className={classnames(styles['action-btn'], 'btn-sm')}
                color={alertIsOpen ? 'primary' : 'light'}>
                {
                  isUpdatePending ?
                    <span className="far fa-spinner-third fa-spin" /> :
                    <span className="far fa-angle-down" />
                }
              </DropdownToggle>
            </AlertListItemActions>
          </div>
        </div>
      </li>
    )
  }

  renderNetworkAssetSummary() {
    const { alert } = this.props
    const {
      networkAssetId, networkAssetName, networkAssetType,
      networkAssetFriendlyName,
    } = alert.details

    return (
      <span>
        &nbsp;

        <NetworkAssetIcon
          className={styles['asset-icon']}
          networkAsset={{ assetType: networkAssetType }}/>

        &nbsp;

        <NavLink to={routes.networkAssetsDetailPath(networkAssetId)}>
          {networkAssetName}
        </NavLink>

        &nbsp;

        <span className={styles['row-top-text']}>
          {networkAssetFriendlyName}
        </span>
      </span>
    )
  }

  handleChangeAlertStatusClick = async newStatus => {
    const { updateAlertStatus, alert } = this.props

    this.setState({ result: AsyncResult.pending() })
    this.props.onClickChangeAlertStatus()
    try {
      await updateAlertStatus({
        variables: {
          newStatus,
          alertId: alert.alertId,
        },
      })

      await sleep(INFLOWNET_WAIT)

      this.setState({ result: AsyncResult.success() })
      await this.props.onChangeAlertStatus({ status: newStatus })
    } catch (err) {
      analytics.logFatalError(err)

      const error = AppError.from(err)
      this.setState({ result: AsyncResult.fail(error) })
      this.props.onError(error)
    }
  }

  static UPDATE_STATUS_MUTATION = gql`
    mutation UpdateAlertStatus(
      $alertId: Int!,
      $newStatus: AlertStatus!,
    ) {
      updateAlertStatus (
        alertId: $alertId,
        newStatus: $newStatus,
      )
    }
  `
}

export default compose(
  userPermissions,
  graphql(AlertListItem.UPDATE_STATUS_MUTATION, {
    name: 'updateAlertStatus',
  }),
  withTranslation(['common/text']),
)(AlertListItem)
