import { useEffect, useRef, useState } from 'react'
import { useApolloClient } from '@apollo/client'
import { mapFlattenedFiltersToQueryObject } from '../utils/filters'
import { getNetworkAssetsForListCall } from '../../../../api/network_assets.service'
import { normalizeGeoBoundsToQuery } from '../../../../components/base_map/helpers/map-positions'
import GeoBounds from '../../../../../utils/graphs/geo_bounds'

export const useGetNetworkAssetsForList = (filters) => {
  const client = useApolloClient()
  const abortController = useRef(null)
  const [results, setResults] = useState({ error: null, networkAssets: [], pagination: null, isLoading: true })

  const handleFetchNetworkAssets = () => {
    if (abortController.current) {
      abortController.current.abort()
    }

    if (!results.isLoading) {
      setResults({ ...results, isLoading: true })
    }

    abortController.current = new AbortController()

    getNetworkAssetsForListCall({
      client, abortController: abortController.current,
      pagination: {
        pageNumber: filters.pageNumber,
        resultsPerPage: filters.resultsPerPage,
      },
      order: {
        by: filters.orderBy,
        direction: filters.orderDirection,
      },
      filters: {
        search: filters.search,
        ...mapFlattenedFiltersToQueryObject(filters.filters),
        inBounds: filters && filters.mapPosition && filters.mapPosition.bounds instanceof GeoBounds
          ? normalizeGeoBoundsToQuery(filters.mapPosition.bounds).toObject()
          : null,
      },
    })
      .then(result => {
        setResults({
          networkAssets: result.networkAssets,
          pagination: result.pagination,
          error: null,
          isLoading: false,
        })
      })
      .catch(error => {
        setResults({ error, networkAssets: [], pagination: null, isLoading: false })
      })
      .finally(() => {
        abortController.current = null
      })
  }

  useEffect(() => {
    const timoutId = setTimeout(() => {
      handleFetchNetworkAssets()
    }, 400)

    return () => clearTimeout(timoutId)
  }, [filters])

  return results
}
