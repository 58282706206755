import { AwsCognitoIdentityProvider } from '../identity_providers/aws-cognito-identity-provider'
import { KeycloakIdentityProvider } from '../identity_providers/keycloak-identity-provider'

export class AuthorizationService {
  /**
   * @param {AbstractIdentityProvider} identityProvider - An instance of an identity provider.
   * @protected The initialization logic for the authorization service should be implemented
   *            as a static method within this class. This ensures that the initialization
   *            logic is centralized and returns a new instance of the authorization service.
   */
  constructor(identityProvider) {
    this.identityProvider = identityProvider
  }

  /**
   * Initializes the authorization service based on the provided tenant configuration.
   *
   * @param {AppConfig} appConfig - The configuration for the tenant.
   * @returns {Promise<AuthorizationService>} - A promise that resolves to an instance of AuthorizationService.
   */
  static async initializeForAppConfigConfig(appConfig) {
    const settings = appConfig.identityProviderConfig.settings
    let identityProvider

    switch (appConfig.identityProviderConfig.type) {
      case 'aws-cognito':
        identityProvider = await AwsCognitoIdentityProvider.initialize(settings)
        break
      case 'keycloak':
        identityProvider = await KeycloakIdentityProvider.initialize(settings)
        break
      default:
        throw new Error(
          `Unsupported identity provider ${appConfig.identityProviderConfig.type}`,
        )
    }

    return new AuthorizationService(identityProvider)
  }

  /**
   * @return {null|AuthorizationService}
   */
  static initializeNull() {
    return null;
  }

  async getCurrentUser() {
    return this.identityProvider.getCurrentUser()
  }

  async getCurrentUserToken() {
    return this.identityProvider.getCurrentUserToken()
  }

  async signOut() {
    return this.identityProvider.signOut()
  }


  async getAcceptedEulaVersion() {
    return this.identityProvider.getAcceptedEulaVersion()
  }

  async setAcceptedEulaVersion(version) {
    return this.identityProvider.setAcceptedEulaVersion(version)
  }
}
