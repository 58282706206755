import React from 'react'

import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import LoadingIcon from '../../../components/loading_icon'
const ListSummary = ({
  t,
  totalResults = 0,
  isLoading = false,
  onShowEverywhereClick = () => null,
}) => {
  return (
    <div className="d-inline align-self-center">
      {isLoading && <LoadingIcon className="d-inline-block" />}

      {!isLoading && <strong>{totalResults}</strong>}
      <strong>{` ${t('results')}`}</strong>
      <span>{` ${t('within_visible_map_area')}`}</span>
      <span
        onClick={onShowEverywhereClick}
        className="btn btn-link p-0 m-0 border-0 align-top ml-1"
      >
        <i className="fas fa-globe-americas" />
        {` ${t('show_everywhere')}`}
      </span>
    </div>
  )
}

ListSummary.propTypes = {
  t: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  totalResults: PropTypes.number,
  onShowEverywhereClick: PropTypes.func,
}

export default withTranslation(['v2/common/text'])(ListSummary)
