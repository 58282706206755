import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import MutationObserver from 'mutation-observer'

import styles from './popup.css'

export const TOP = 'popup-top'
export const LEFT = 'popup-left'
export const RIGHT = 'popup-right'
export const BOTTOM = 'popup-bottom'

import PopupContext from './context'

class Popup extends React.PureComponent {
  static defaultProps = {
    location: TOP,
    hideArrow: false,
  }

  static propTypes = {
    isOpen: PropTypes.oneOf([true, false, 'auto']),
    location: PropTypes.oneOf([TOP, LEFT, RIGHT, BOTTOM]).isRequired,
    anchorRef: PropTypes.shape({
      current: PropTypes.instanceOf(Element),
    }).isRequired,
    hideArrow: PropTypes.bool.isRequired,
    isMouseOverAnchor: PropTypes.bool.isRequired,
  }

  render() {
    if (
      (this.props.isOpen === 'auto' && !this.props.isMouseOverAnchor) ||
      !this.props.isOpen
    ) {
      return null
    }

    return ReactDOM.createPortal(
      this.renderPopup(),
      getPopupContainerInstance(),
    )
  }

  renderPopup() {
    const { anchorRef } = this.props
    const node = anchorRef.current

    if (!node) {
      // eslint-disable-next-line no-console
      console.warn('Parent ref not provided for popup, unable to render')
      return null
    } else if (!node.offsetWidth && !node.offsetHeight && !node.getClientRects().length && process.env.NODE_ENV !== 'test') { // eslint-disable-line max-len
      // parent is not visible, probably should not render here
      return null
    }

    if (anchorRef.current) {
      if (!this.observer) {
        this.observer = new MutationObserver(() => {
          if (anchorRef.current) {
            this.forceUpdate()
          }
        })

        this.observer.observe(anchorRef.current, { attributes: true })
      }
    }

    return (
      <div
        onClick={this.stopPropagation}
        onMouseUp={this.stopPropagation}
        onMouseDown={this.stopPropagation}
        onMouseMove={this.stopPropagation}
        onDoubleClick={this.stopPropagation}
        {...this.selectStyleProps(this.props)}>
        {this.props.children}
      </div>
    )
  }

  stopPropagation = event => {
    event.stopPropagation()
  }

  selectStyleProps = props => {
    const { hideArrow, anchorRef, location, className } = props
    const boundingRect = anchorRef.current.getBoundingClientRect()

    return {
      className: classnames(styles[location], className, {
        [styles[`${location}-arrow`]]: !hideArrow,
      }),
      style: boundingRect ? {
        top: location === BOTTOM ? boundingRect.bottom : boundingRect.top,
        left: boundingRect.left + boundingRect.width / 2,
      } : undefined,
    }
  }

  componentWillUnmount() {
    if (this.observer) {
      this.observer.disconnect()
    }
  }
}

function getPopupContainerInstance() {
  return document.getElementById('popup-container-instance') ||
    (() => {
      const element = document.createElement('div')

      element.setAttribute('id', 'popup-container-instance')
      document.body.appendChild(element)

      return element
    })()
}

export default function PopupComponent(props) {
  return (
    <PopupContext.Consumer>
      {context => (
        <Popup {...{
          ...context,
          ...props,
        }}/>
      )}
    </PopupContext.Consumer>
  )
}
