import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as Changes from '@@src/store/changes'
import { useDispatch } from 'react-redux'
import { useApolloClient } from '@apollo/client'
import { exportNetworkAssets } from '../../../../../api/export.service'
import ExportSourceModal from '../../../../../components/modals/export_source/export_source_modal.component'
import LoadingIcon from '../../../../../../components/loading_icon'

import PropTypes from 'prop-types'

const ExportNetworkAssetsModal = ({ isOpen, toggle, networkAssetNumber, filters, enabled = true }) => {
  const { t } = useTranslation([
    'v2/network_assets/forms',
    'src/management_path/network_assets_path/export_network_assets_config_modal',
    'v2/common/buttons',
  ])
  const client = useApolloClient()
  const dispatch = useDispatch()
  const [loadingForReport, setLoadingForReport] = useState(false)

  const handleExportNetworkAssets = useCallback(() => {
    setLoadingForReport(true)
    exportNetworkAssets(client, filters)
      .then(() => {
        setLoadingForReport(false)
        toggle()
        dispatch(Changes.notifyChange(Changes.CSV_DOWNLOAD_CHANGES))
      }).catch((error) => {
        setLoadingForReport(false)
        // eslint-disable-next-line no-console
        console.warn('Error exporting data:', error)
      })
  }, [client, filters, setLoadingForReport, toggle])

  return(
    <ExportSourceModal
      isOpen={isOpen}
      toggle={toggle}
      onExport={handleExportNetworkAssets}
      header={t('v2/network_assets/forms:export_modal.export_network_assets')}
      body={
        <>
          {t('v2/network_assets/forms:export_modal.assets_count', { count: networkAssetNumber })}
          {
            loadingForReport &&
            <div className="d-flex flex-row">
              <LoadingIcon />
              <span className="ml-2">
                {t('v2/network_assets/forms:export_modal.waiting_for_report')}
              </span>
            </div>
          }
        </>
      }
      enabled={enabled && !loadingForReport}
    />
  )
}

ExportNetworkAssetsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  networkAssetNumber: PropTypes.number,
  filters: PropTypes.object.isRequired,
  enabled: PropTypes.bool,
}

export default ExportNetworkAssetsModal
