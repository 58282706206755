import React from 'react'
import { Col } from 'reactstrap'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import userPermissions from '@@src/components/permissions/user_permissions'
import routes from '@@src/routes'

import requiresLogin from '@@src/components/security/requires_login'
import AppLayout from '@@src/components/app_layout'

import styles from './index_page.css'

class PageNotFoundPage extends React.PureComponent {
  render() {
    const { t, role } = this.props
    const navRoute = this.getRoleAwareRoute(role)
    return (
      <AppLayout
        title={t('headings.page_title')}
        contentsStyle="fixed-at-full-height">
        <Col className={styles.column}>
          <div className={styles['error-header']}>
            404
          </div>
          <div className={styles['error-body']}>
            Page not found
          </div>
          <NavLink to={navRoute}>
            {t('buttons.dashboard')}
          </NavLink>
        </Col>
      </AppLayout>
    )
  }

  getRoleAwareRoute = (role) => {
    if (role === 'administrator') {
      return routes.adminUsersPath()
    } else {
      return routes.homePath()
    }
  }
}

export default compose(
  requiresLogin,
  userPermissions,
  withTranslation([
    'src/page_not_found_path/index_page',
    'common/text',
  ]),
)(PageNotFoundPage)
