import { min, max } from 'lodash/fp/math'

export default class DragEvent {
  constructor({ x, y, mouseX, mouseY, dragStart, graphConfig }) {
    Object.assign(this, { x, y, mouseX, mouseY, dragStart, graphConfig })
  }

  get localBoundsX() {
    const { x, mouseX, dragStart, graphConfig } = this
    const { leftPadding, plotAreaWidth } = graphConfig

    return !x ? null : x > 0 ?
      [
        max([leftPadding, dragStart.mouseX]),
        min([plotAreaWidth + leftPadding, mouseX]),
      ] : [
        max([leftPadding, mouseX]),
        min([plotAreaWidth + leftPadding, dragStart.mouseX]),
      ]
  }

  get localBoundsY() {
    const { y, mouseY, dragStart, graphConfig } = this
    const { topPadding, plotAreaHeight } = graphConfig

    return !y ? null : y > 0 ?
      [
        max([topPadding, dragStart.mouseY]),
        min([plotAreaHeight + topPadding, mouseY]),
      ] : [
        max([topPadding, mouseY]),
        min([plotAreaHeight + topPadding, dragStart.mouseY]),
      ]
  }

  get graphBoundsX() {
    const localBoundsX = this.localBoundsX
    const { xScale } = this.graphConfig

    return localBoundsX ? localBoundsX.map(xScale.invert) : null
  }

  get graphBoundsY() {
    const localBoundsY = this.localBoundsY
    const { yScale } = this.graphConfig

    return localBoundsY ? localBoundsY.map(yScale.invert) : null
  }

  static from(...args) {
    return new DragEvent(...args)
  }
}
