import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { noop } from 'lodash/fp/util'
import { connect } from 'react-redux'

import GraphContext from '@@src/components/graphs/graph_context'
import createEventDispatcher from '@@src/components/create_event_dispatcher'
import { ZOOM_DRAG_MODE, SELECT_DRAG_MODE, PAN_DRAG_MODE, DRAG_MODES }
from '@@src/components/singletons/drag_cover'

import styles from './mouse_event_layer.css'

export const MouseEventContext = React.createContext(null)

const MouseEventDispatchLayerContainer = props => (
  <GraphContext.Consumer>
    {config => <MouseEventLayer graphConfig={config} {...props}/>}
  </GraphContext.Consumer>
)

class MouseEventLayer extends React.PureComponent {
  static defaultProps = {
    onClick: noop,
    onMouseUp: noop,
    onMouseDown: noop,
    onMouseMove: noop,
    onMouseEnter: noop,
    onMouseLeave: noop,
    onDoubleClick: noop,
    dragMode: ZOOM_DRAG_MODE,
  }

  static propTypes = {
    onClick: PropTypes.func.isRequired,
    onMouseUp: PropTypes.func.isRequired,
    onMouseDown: PropTypes.func.isRequired,
    onMouseMove: PropTypes.func.isRequired,
    onMouseEnter: PropTypes.func.isRequired,
    onMouseLeave: PropTypes.func.isRequired,
    onDoubleClick: PropTypes.func.isRequired,
    dragMode: PropTypes.oneOf(DRAG_MODES).isRequired,
  }

  dispatcher = createEventDispatcher()
  backgroundRef = React.createRef()

  render() {
    const { className, graphConfig, children, dragMode } = this.props
    const captureLayerClassnames = classnames(styles['event-capture-layer'],
      {
        [styles['event-capture-layer-zoom']]: dragMode === ZOOM_DRAG_MODE,
        [styles['event-capture-layer-pan']]: dragMode === PAN_DRAG_MODE,
        [styles['event-capture-layer-select']]: dragMode === SELECT_DRAG_MODE,
      },
      className,
    )

    return (
      <MouseEventContext.Provider value={this.value}>
        <g
          name="interaction-layer"
          onClick={this.onClick}
          onMouseUp={this.onMouseUp}
          onMouseDown={this.onMouseDown}
          onMouseMove={this.onMouseMove}
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
          onDoubleClick={this.onDoubleClick}>
          <rect
            x={graphConfig.leftPadding}
            y={graphConfig.topPadding}
            ref={this.backgroundRef}
            name="graph-event-capture-layer"
            width={graphConfig.plotAreaWidth}
            height={graphConfig.plotAreaHeight}
            className={captureLayerClassnames}/>

          {children}
        </g>
      </MouseEventContext.Provider>
    )
  }

  mapX = pageX => {
    const left = this.backgroundRef.current ?
      this.backgroundRef.current.getBoundingClientRect().left : 0

    return pageX - left + this.props.graphConfig.leftPadding
  }

  mapY = pageY => {
    const top = this.backgroundRef.current ?
      this.backgroundRef.current.getBoundingClientRect().top : 0

    return pageY - top + this.props.graphConfig.topPadding
  }

  withLocalCoords = func => event => {
    return func(event, this.createLocalCoords(event))
  }

  value = {
    Channel: this.dispatcher.Channel,
    withLocalCoords: this.withLocalCoords,
  }

  createLocalCoords = event => {
    return {
      mouseX: this.mapX(event.pageX),
      mouseY: this.mapY(event.pageY),
      graphConfig: this.props.graphConfig,
    }
  }

  onClick = event => {
    const localCoords = this.createLocalCoords(event)

    this.props.onClick(event, localCoords)
    this.dispatcher.dispatch('onClick', event, localCoords)
  }

  onMouseUp = event => {
    const localCoords = this.createLocalCoords(event)

    this.props.onMouseUp(event, localCoords)
    this.dispatcher.dispatch('onMouseUp', event, localCoords)
  }

  onMouseDown = event => {
    const localCoords = this.createLocalCoords(event)

    this.props.onMouseDown(event, localCoords)
    this.dispatcher.dispatch('onMouseDown', event, localCoords)
  }

  onMouseMove = event => {
    const localCoords = this.createLocalCoords(event)

    this.props.onMouseMove(event, localCoords)
    this.dispatcher.dispatch('onMouseMove', event, localCoords)
  }

  onMouseEnter = event => {
    const localCoords = this.createLocalCoords(event)

    this.props.onMouseEnter(event, localCoords)
    this.dispatcher.dispatch('onMouseEnter', event, localCoords)
  }

  onMouseLeave = event => {
    const localCoords = this.createLocalCoords(event)

    this.props.onMouseLeave(event, localCoords)
    this.dispatcher.dispatch('onMouseLeave', event, localCoords)
  }

  onDoubleClick = event => {
    const localCoords = this.createLocalCoords(event)

    this.props.onDoubleClick(event, localCoords)
    this.dispatcher.dispatch('onDoubleClick', event, localCoords)
  }
}

function mapStateToProps(state) {
  return {
    dragMode: state.dragCover.dragMode,
  }
}

export default connect(mapStateToProps)(MouseEventDispatchLayerContainer)
