import AppSuspenseContainer from '@@src/components/app_suspense_container'
import ReleaseNotesButton from '@@src/components/buttons/release_notes_button'
import DragCover from '@@src/components/singletons/drag_cover'
import Tooltip from '@@src/components/tooltip'
import routes from '@@src/routes'
import classnames from 'classnames'
import { push } from 'connected-react-router'
import React, { useCallback, useContext, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { matchPath } from 'react-router'
import { NavLink as RouterNavLink } from 'react-router-dom'
import {
  Button,
  Col,
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Row,
  UncontrolledDropdown,
} from 'reactstrap'
import { compose } from 'redux'
import { createSelector } from 'reselect'
import {
  CAN_MANAGE_TENANT_SETTINGS,
  CAN_MANAGE_USERS,
  CAN_VIEW_ALERTS, CAN_VIEW_ANALYSIS,
  CAN_VIEW_DEVICES,
  CAN_VIEW_GROUPS,
  CAN_VIEW_NETWORK_ASSETS,
} from '../../_v2/contexts/user/consts/permissions'
import { STORAGE_KEY } from '../../analysis_path/with_data_sources'
import { withWindowSizes } from '../window_size_provider'
import AppNavItemLink from './app_nav_item_link'
import CsvDownloadsDropdown from './csv_downloads_dropdown'
import { AuthorizationContext } from '../../_v2/contexts/authorization/authorization.context'
import { UserContext, withUser } from '../../_v2/contexts/user/user.context'

import styles from './index.css'

function AppLayout(props) {
  const { t, title, children, backgroundClassname, showFooter, footerColor = 'light', windowWidth } = props
  const dispatch = useDispatch()
  const pathname = useSelector((state) => state.hasOwnProperty('router') ? state.router.location.pathname : undefined)
  const { authorization } = useContext(AuthorizationContext)
  const { authorizedUser, isLogged } = useContext(UserContext)
  const [isOpen, setIsOpen] = useState(false)
  const toggleIsOpen = () => setIsOpen(!isOpen)
  const [currentTitle, setCurrentTitle] = useState(undefined)
  const selectMainClassName = createSelector(
    [({ contentsStyle }) => contentsStyle],
    (contentsStyle) => {
      switch (contentsStyle) {
        case 'fixed-at-full-height':
          return styles['wrapper-contents-fixed-at-full-height']

        case 'default':
        default:
          return styles['wrapper-contents']
      }
    },
  )

  const logout = useCallback(() => {
    authorization?.signOut()
      .then(() => {
        window.location.reload()
      })
  }, [authorization])

  const dispatchRoute = async (route) => {
    switch (route) {
      case 'login':
        dispatch(push(routes.loginPath()))
        break
      case 'managementDevices':
        dispatch(push(routes.managementDevicesPath()))
        break
      case 'managementNetworkAssets':
        dispatch(push(routes.managementNetworkAssetsPath()))
        break
      case 'managementGroups':
        dispatch(push(routes.managementGroupsPath()))
        break
      case 'adminUsers':
        dispatch(push(routes.adminUsersPath()))
        break
      case 'adminTenantSettings':
        dispatch(push(routes.adminTenantSettingsLanguageAndRegion()))
        break
      default:
        break;
    }
  }

  const getRoleAwareRoute = (role) => {
    if (role === 'administrator') {
      return routes.adminUsersPath()
    } else {
      return routes.homePath()
    }
  }

  const renderFooter = () => {
    return (
      <Navbar
        color={footerColor}
        expand="md"
        className={styles.footer}
        {...{ [footerColor]: true }}
      >
        <Nav navbar className={'ml-auto ' + styles['footer-contents']}>
          <NavItem className={styles['footer-text']}>
            <RouterNavLink to={routes.appInfoPath()} className="nav-link">
              InflowNet &nbsp;
              <span className={styles['footer-version-text']}>
                {process.env.APP_VERSION}
                {(window.location.origin || '').match(/inflowsysweb\.net$/)
                  ? ' (Beta)'
                  : null}
              </span>
            </RouterNavLink>
          </NavItem>
        </Nav>
      </Navbar>
    )
  }

  const renderUserData = () => {
    return (
      <>
        <NavLink className={`${styles['user-details-name']} p-0`}>
          {authorizedUser.displayName}
        </NavLink>
        <NavLink className={`${styles['user-details-role']} p-0`}>
          {t('text.role', { role: authorizedUser.role })}
        </NavLink>
      </>
    )
  }

  const renderUserLayout = () => {
    const homePath = getRoleAwareRoute(authorizedUser.role)
    const managementDropdownClassnames = classnames({
      [`active ${styles['nav-link-active']}`]: matchPath(pathname, {
        path: routes.managementPath(),
      }),
    })
    const adminDropdownClassnames = classnames({
      [`active ${styles['nav-link-active']}`]: matchPath(pathname, {
        path: routes.adminPath(),
      }),
    })

    if (currentTitle !== title) {
      document.title = t('headings.page_title', { subtitle: title })
      setCurrentTitle(title)
    }

    return (
      <div className={classnames(styles.container, backgroundClassname)}>
        <DragCover />
        <Tooltip.Instance />

        <div className={styles.wrapper}>
          <Navbar color="dark" dark expand="lg">
            <NavbarBrand href={homePath} className={styles['brand-image']} />

            <Row className="my-0 mr-2">
              <Nav
                navbar
                className={styles['user-name-role'] + ' user-name-role'}
              >
                <Col>{renderUserData()}</Col>
              </Nav>

              <NavbarToggler onClick={toggleIsOpen} className="m-0" />
            </Row>

            <Collapse isOpen={isOpen} navbar>
              <Nav navbar>
                {authorizedUser.can(CAN_VIEW_DEVICES) ? (
                  <AppNavItemLink name="dashboard-link" to={homePath} exact>
                    {t('links.dashboard')}
                  </AppNavItemLink>
                ) : null}

                {authorizedUser.can(CAN_VIEW_ANALYSIS) ? (
                  <AppNavItemLink
                    name="analysis-link"
                    onClick={() => localStorage.setItem(STORAGE_KEY, '')}
                    to={routes.analysisPath()}
                  >
                    {t('links.analysis')}
                  </AppNavItemLink>
                ) : null}

                {authorizedUser.can(CAN_VIEW_DEVICES) ||
                authorizedUser.can(CAN_VIEW_NETWORK_ASSETS) ||
                authorizedUser.can(CAN_VIEW_GROUPS) ? (
                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle
                      name="management-link"
                      className={managementDropdownClassnames}
                      nav
                      caret
                    >
                      {t('links.management')}
                    </DropdownToggle>

                    <DropdownMenu end>
                      {authorizedUser.can(CAN_VIEW_DEVICES) && (
                        <DropdownItem
                          className={styles['dropdown-item']}
                          name="manage-devices-link"
                          onClick={() => dispatchRoute('managementDevices')}
                        >
                          {t('links.devices')}
                        </DropdownItem>
                      )}

                      {authorizedUser.can(CAN_VIEW_NETWORK_ASSETS) && (
                        <DropdownItem
                          className={styles['dropdown-item']}
                          name="manage-network-assets-link"
                          onClick={() =>
                            dispatchRoute('managementNetworkAssets')
                          }
                        >
                          {t('links.network_assets')}
                        </DropdownItem>
                      )}

                      {authorizedUser.can(CAN_VIEW_GROUPS) && (
                        <DropdownItem
                          className={styles['dropdown-item']}
                          name="manage-groups-link"
                          onClick={() => dispatchRoute('managementGroups')}
                        >
                          {t('links.groups')}
                        </DropdownItem>
                      )}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                ) : null}

                {authorizedUser.can(CAN_VIEW_ALERTS) ? (
                  <AppNavItemLink
                    name="alerts-link"
                    to={routes.alertsPath()}
                    exact
                  >
                    {t('links.alerts')}
                  </AppNavItemLink>
                ) : null}

                {authorizedUser.can(CAN_MANAGE_USERS) ||
                authorizedUser.can(CAN_MANAGE_TENANT_SETTINGS) ? (
                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle
                      name="admin-link"
                      className={adminDropdownClassnames}
                      nav
                      caret
                    >
                      {t('links.admin')}
                    </DropdownToggle>

                    <DropdownMenu end>
                      {authorizedUser.can(CAN_MANAGE_USERS) ? (
                        <DropdownItem
                          className={styles['dropdown-item']}
                          name="manage-users-link"
                          onClick={() => dispatchRoute('adminUsers')}
                        >
                          {t('links.admin_users')}
                        </DropdownItem>
                      ) : null}
                      {authorizedUser.can(CAN_MANAGE_TENANT_SETTINGS) ? (
                        <DropdownItem
                          className={styles['dropdown-item']}
                          name="admin-tenant-settings-link"
                          onClick={() => dispatchRoute('adminTenantSettings')}
                        >
                          {t('links.admin_tenant_settings')}
                        </DropdownItem>
                      ) : null}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                ) : null}
              </Nav>

              <Nav className="ml-auto align-items-center" navbar>
                <Row className="align-items-center m-0">
                  {process.env.APP_VERSION ? (
                    <ReleaseNotesButton
                      version={process.env.APP_VERSION}
                      className="mr-1"
                    />
                  ) : null}
                  {authorizedUser.can(CAN_VIEW_DEVICES) ? (
                    <CsvDownloadsDropdown />
                  ) : null}
                </Row>

                {!(windowWidth < 1080) && (
                  <Col className={styles['user-details-container']}>
                    {renderUserData()}
                  </Col>
                )}

                <AppNavItemLink
                  name="user-settings-link"
                  to={routes.settingsPath()}
                  exact
                >
                  {t('links.user_settings')}
                </AppNavItemLink>

                <NavItem>
                  {windowWidth < 1080 ? (
                    <NavLink
                      className={`${classnames('nav-link')} ${
                        styles['logout-button']
                      }`}
                      name="logout-button"
                      onClick={() => logout()}
                    >
                      <i className="far fa-sign-out"></i> {t('links.sign_out')}
                    </NavLink>
                  ) : (
                    <NavLink
                      className={styles['logout-button']}
                      name="logout-button"
                      onClick={() => logout()}
                    >
                      <i className="far fa-sign-out"></i>
                    </NavLink>
                  )}
                </NavItem>
              </Nav>
            </Collapse>
          </Navbar>

          <AppSuspenseContainer
            elemType="main"
            className={selectMainClassName(props)}
          >
            {children}
          </AppSuspenseContainer>

          {showFooter ? <div className={styles.push} /> : null}
        </div>
        {!showFooter ? null : renderFooter()}
      </div>
    )
  }

  const renderGuestLayout = () => {
    return (
      <div className={classnames(styles.container, backgroundClassname)}>
        <div className={styles.wrapper}>
          <Navbar color="dark" dark>
            <NavbarBrand
              href={routes.homePath()}
              className={styles['brand-image']}
            />

            <Button
              color="primary"
              name="login-button"
              className={styles['login-button']}
              onClick={() => dispatchRoute('login')}
            >
              <i className="fa fa-sign-in"></i>

              {' ' + t('buttons.login')}
            </Button>
          </Navbar>

          <main className={selectMainClassName(props)}>{children}</main>

          <div className={styles.push} />
        </div>

        {renderFooter()}
      </div>
    )
  }

  return <>{isLogged() ? renderUserLayout() : renderGuestLayout()}</>
}

export default compose(
  withTranslation(['src/components/app_layout/index']),
  withWindowSizes,
  withUser,
)(AppLayout)
