import React from 'react'
import { createSelector } from 'reselect'
import { get } from 'lodash/fp/object'
import { flatten, dropWhile, takeWhile } from 'lodash/fp/array'
import {
  map,
  sortBy,
} from 'lodash/fp/collection'
import { flow } from 'lodash/fp/util'

export default function withEventsAnomaliesData(Component) {
  class EventsAndAnomaliesContainer extends React.PureComponent {

    constructor(props) {
      super(props)

      this.state = {
        activeAnomalyId: 1,
        activeEventId: 0,
        isActiveAnomalyExpanded: false,
        isActiveEventExpanded: false,
        showBoundsForSelectedAnomaly: false,
      }
    }

    componentDidUpdate(prevProps) {
      if (prevProps.pressureEvents !== this.props.pressureEvents) {
        this.setState({
          activeEventId: 0,
          isActiveEventExpanded: false,
        })
      }
    }

    render() {
      return (
        <Component
          {...this.props}
          anomalyData={this.selectSplitAnomaliesData(this.props)}
          activeAnomalyId={this.state.activeAnomalyId}
          isActiveAnomalyExpanded={this.state.isActiveAnomalyExpanded}
          setActiveExpandedAnomalyId={this.setActiveExpandedAnomalyId}
          allAnomalies={this.selectAllAnomalies(this.props)}
          selectedItemAnomaly={this.selectActiveAnomaly(this.props, this.state)}
          selectedItemBounds={this.selectActiveBounds(this.props, this.state)}
          maxMinPressureBounds={this.selectBoundsPressureMinMax(this.props)}
          toggleShowBoundsForActiveAnomaly={this.toggleShowBoundsForActiveAnomaly}
          boundsShown={this.state.showBoundsForSelectedAnomaly}

          pressureEventsWithData={this.selectEventSlicesWithData(this.props)}
          activeEventId={this.state.activeEventId}
          setActiveEventForSlice={this.setActiveEventForSlice}
          totalEventCount={this.selectTotalNumberOfEvents(this.props).totalNumber}
          eventIndexInSet={this.selectEventIdAndIndexInElement(this.props, this.state)}
          isActiveEventExpanded={this.state.isActiveEventExpanded}
          setActiveExpandedEventId={this.setActiveExpandedEventId}
        />
      )
    }

    // Events data
    selectEventSlicesWithData = createSelector(
      [
        get('pressureEvents'),
        get('graphItems'),
        get('resolution'),
      ],
      (pressureEvents, graphItems, resolution) => {
        return pressureEvents.withData(pressureEvents.data.map((sourceWithEvents) => {
          const { events, source } = sourceWithEvents

          // remove empty slices and slices with duplicates
          const filteredSlices = events
            .filter(eventSlice => eventSlice.eventsCount > 0)
            .reduce((acc, eventSlice) => {
              if (
                eventSlice.singleEventDetails &&
                acc.some(uniqueSlice =>
                  uniqueSlice.singleEventDetails &&
                  uniqueSlice.singleEventDetails.eventId === eventSlice.singleEventDetails.eventId
                )) {
                return acc
              }
              acc.push(eventSlice)
              return acc
            }, [])

          // if there are no slices, we show no events
          if (filteredSlices === 0) {
            return []
          }

          // find graph item that coresponds with event
          const corespondingGraphItem = graphItems.find((item) => {
            return item.sourceId === source.networkAssetId && item.sourceChannel === source.channel
          })

          // if there is no graph item, we dont have data to show on plot
          // so we return just events without data
          if (!corespondingGraphItem || !corespondingGraphItem.data || !corespondingGraphItem.data.length) {
            return filteredSlices
          }

          const pressureData =
            Array.isArray(corespondingGraphItem.data) ? corespondingGraphItem.data.filter(item => !!item) : []

          if (!pressureData || !pressureData.length) {
            return filteredSlices
          }

          // get subset pressure data from graph item
          const flattenedSubsetData = flow(
            map('data'),
            flatten,
            sortBy('time'),
          )(pressureData)

          // fuze pressure data with slices
          const newSlices = filteredSlices.map((slice) => {
            // this should never happen
            if (slice.eventsCount === 1 && slice.singleEventDetails === null) {
              return null
            }

            // if there are more then 1 events we find pressure node that
            // is in the middle of the pressure route to put the cluster
            if (slice.eventsCount > 1) {
              const { sliceUtcStart, sliceUtcEnd } = slice

              const startInMS = new Date(sliceUtcStart).getTime()
              const endInMS = new Date(sliceUtcEnd).getTime()

              const subsetDataAfterStart =
                dropWhile(({ time }) => new Date(time).getTime() < startInMS)(flattenedSubsetData)
              const subsetDataInRange =
                takeWhile(({ time }) => new Date(time).getTime() < endInMS)(subsetDataAfterStart)

              if (subsetDataAfterStart.length > 0 && subsetDataInRange.length === 0) {
                return {
                  ...slice,
                  legendItem: corespondingGraphItem.legendItem,
                  data: [{ ...subsetDataAfterStart[0] }],
                }
              } else {
                return {
                  ...slice,
                  legendItem: corespondingGraphItem.legendItem,
                  data: [{ ...subsetDataInRange[Math.floor((subsetDataInRange.length - 1) / 2)] }],
                }
              }
            }

            // take pressure data to represent event markers on the plot
            const { utcStart, utcEnd } = slice.singleEventDetails
            const startInMS = new Date(utcStart).getTime()
            const endInMS = new Date(utcEnd).getTime()

            const subsetDataAfterStart =
              dropWhile(({ time }) => new Date(time).getTime() < startInMS - resolution * 1000)(flattenedSubsetData)
            const subsetDataInRange =
              takeWhile(({ time }) => new Date(time).getTime() < endInMS + resolution * 1000)(subsetDataAfterStart)

            if (subsetDataAfterStart.length > 0 && subsetDataInRange.length === 0) {
              subsetDataInRange.push({ ...subsetDataAfterStart[0] })
            }

            // add buffer nodes to soften the difference on very big
            if (subsetDataInRange.length > 2) {
              const firstNode = { ...subsetDataInRange[0] }
              const secondNode = { ...subsetDataInRange[1] }
              const nextToLastNode = { ...subsetDataInRange[subsetDataInRange.length - 2] }
              const lastNode = { ...subsetDataInRange[subsetDataInRange.length - 1] }

              const percentOverStart =
                1 - ((new Date(secondNode.time).getTime() - startInMS) /
                  (new Date(secondNode.time).getTime() - new Date(firstNode.time).getTime()))
              const percentOverEnd =
                1 - ((new Date(lastNode.time).getTime() - endInMS) /
                  (new Date(lastNode.time).getTime() - new Date(nextToLastNode.time).getTime()))

              subsetDataInRange[0] = {
                mean: firstNode.mean + (secondNode.mean - firstNode.mean) * percentOverStart,
                time: new Date(startInMS),
              }

              subsetDataInRange[subsetDataInRange.length - 1] = {
                mean: nextToLastNode.mean + (lastNode.mean - nextToLastNode.mean) * percentOverEnd,
                time: new Date(endInMS),
              }
            }

            return {
              ...slice,
              legendItem: corespondingGraphItem.legendItem,
              data: [...subsetDataInRange],
            }
          }).filter(a => a)

          return newSlices
        }).flat())
      }
    )

    selectTotalNumberOfEvents = createSelector(
      [this.selectEventSlicesWithData],
      ({ data: slices }) => slices.reduce((acc, slice) => {
        if (slice.singleEventDetails) {
          if (!acc.uniqueSingleEvents.some(uniqueEvent => uniqueEvent.eventId === slice.singleEventDetails.eventId)) {
            acc.totalNumber += 1
            acc.uniqueSingleEvents.push(slice.singleEventDetails)
            return acc
          }
          return acc
        }
        acc.totalNumber += slice.eventsCount
        return acc
      }, { totalNumber: 0, uniqueSingleEvents: [] })
    )

    selectEventIdAndIndexInElement = createSelector(
      [
        (props, _state) => this.selectTotalNumberOfEvents(props),
        (props, _state) => this.selectEventSlicesWithData(props),
        (_props, state) => state.activeEventId,
      ],
      ({ totalNumber }, { data: slices }, activeEventId) => {
        if (totalNumber > 0 && slices.length > 0) {
          let acumulator = slices[0].eventsCount
          let id = 0
          while (acumulator <= activeEventId) {
            id++
            acumulator += slices[id].eventsCount
          }
          return {
            sliceIndex: id,
            eventIndex: slices[id].eventsCount - (acumulator - activeEventId),
          }
        }
        return {
          sliceIndex: 0,
          eventIndex: 0,
        }
      }
    )

    setActiveExpandedEventId = (activeEventId, isExpanded) => {
      if (activeEventId > this.selectTotalNumberOfEvents(this.props).totalNumber || activeEventId < 0) {
        return
      }
      this.setState({
        activeEventId,
        isActiveEventExpanded: isExpanded,
      })
    }

    setActiveEventForSlice = (sliceId) => {
      const { data: slices } = this.selectEventSlicesWithData(this.props)

      let nextActiveEventId = 0
      for (let i = 0; i < sliceId; i++) {
        nextActiveEventId += slices[i].eventsCount
      }
      this.setActiveExpandedEventId(nextActiveEventId, true)
    }

    // Anomalies data
    selectSplitAnomaliesData = createSelector(
      [
        get('anomalyResult'),
        get('graphItems'),
      ],
      (anomaliesResult, graphItems) => {
        if (!anomaliesResult || !anomaliesResult.data || !anomaliesResult.data.anomalies) {
          return { anomaliesWithSourcesAndPressure: [], totalAnomalies: 0 }
        }
        const anomaliesWithSourcesAndPressure = anomaliesResult.data.anomalies.reduce((acc, anomaly, anomalyIndex) => {
          const correspondingGraphItem = graphItems.find(({ legendItem }) => {
            return (
              legendItem.source.id === anomaly.networkAssetId
              && legendItem.sourceChannel === anomaly.networkAssetChannel
            )
          })

          if (!correspondingGraphItem) {
            return acc
          }

          const summedUpPressureData = correspondingGraphItem.subsetData.dataSegments
            .reduce((pressurePoints, { data }) => {
              pressurePoints.push(...data)
              return pressurePoints
            }, [])
            .sort((a, b) => {
              return new Date(a.time).getTime() - new Date(b.time).getTime()
            })

          const pressureStart = dropWhile(({ time }) =>
            new Date(time).getTime() < new Date(anomaly.startTime).getTime()
          )(correspondingGraphItem ? [...summedUpPressureData] : [])
          const pressurePoints = takeWhile(({ time }) =>
            new Date(time).getTime() <= new Date(anomaly.endTime).getTime()
          )(pressureStart)

          pressurePoints.length ?
            acc.push({
              id: anomalyIndex + 1,
              anomaly,
              legendItem: correspondingGraphItem.legendItem,
              pressureData: pressurePoints,
            })
            :
            acc.push({
              id: anomalyIndex + 1,
              anomaly,
              legendItem: correspondingGraphItem.legendItem,
              pressureData: pressureStart.length ? [pressureStart[0]] : [],
            })

          return acc
        }, [])

        return { anomaliesWithSourcesAndPressure, totalAnomalies: anomaliesWithSourcesAndPressure.length }
      }
    )

    selectActiveAnomaly = createSelector(
      [
        (props, _state) => this.selectSplitAnomaliesData(props),
        (_props, state) => state.activeAnomalyId,
      ],
      ({ anomaliesWithSourcesAndPressure }, activeAnomalyId) => {

        if (anomaliesWithSourcesAndPressure.length === 0) {
          return null
        }

        const selectedItem = anomaliesWithSourcesAndPressure.find((item) => {
          return item.id === activeAnomalyId
        })

        if (!selectedItem) {
          this.setActiveExpandedAnomalyId(0, false)
          return anomaliesWithSourcesAndPressure[0]
        }

        return selectedItem ?
          { ...selectedItem }
          :
          null
      }
    )

    selectBoundsPressureMinMax = createSelector(
      [
        (props) => props.anomalyBoundsResult,
      ],
      (anomalyBounds) => {
        if (anomalyBounds.wasSuccessful()) {
          return anomalyBounds.data.reduce((acc1, boundsAndSource) => {
            const { localMax, localMin } = boundsAndSource.bounds.reduce((acc2, bound) => {
              return {
                localMax: Math.max(acc2.localMax, bound.upperBound),
                localMin: Math.min(acc2.localMin, bound.lowerBound),
              }
            }, { localMax: 0, localMin: 0 })
            return {
              max: Math.max(acc1.max, localMax),
              min: Math.min(acc1.min, localMin),
            }
          }, { min: 0, max: 0 })
        }
        return {
          max: 0,
          min: 0,
        }
      }
    )

    selectActiveBounds = createSelector(
      [
        (props, state) => this.selectActiveAnomaly(props, state),
        (props, _state) => props.anomalyBoundsResult,
      ],
      (activeAnomaly, anomalyBoundsResult) => {
        if (!activeAnomaly || !anomalyBoundsResult.wasSuccessful()) {
          return []
        }
        const selectedBoundItem = anomalyBoundsResult.data.find(boundsItem => {
          return boundsItem.source.networkAssetId === activeAnomaly.legendItem.sourceId
            && boundsItem.source.channel === activeAnomaly.legendItem.sourceChannel
        })
        return selectedBoundItem ? selectedBoundItem.bounds : []
      }
    )

    selectAllAnomalies = createSelector(
      [this.selectSplitAnomaliesData],
      ({ anomaliesWithSourcesAndPressure }) => {
        return anomaliesWithSourcesAndPressure
          .sort((a, b) => {
            if (a.anomaly.length && b.anomaly.length) {
              return a.anomaly.anomalyStart.getTime() - b.anomaly.anomalyStart.getTime()
            }
            return false
          })
      }
    )

    setActiveExpandedAnomalyId = (activeAnomalyId, isExpanded) => {
      this.setState({
        activeAnomalyId,
        isActiveAnomalyExpanded: isExpanded,
      })
    }

    toggleShowBoundsForActiveAnomaly = () => {
      this.setState({
        showBoundsForSelectedAnomaly: !this.state.showBoundsForSelectedAnomaly,
      })
    }
  }
  return (EventsAndAnomaliesContainer)
}
