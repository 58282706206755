import NetworkAsset from '@@src/api/presenters/network_asset'

export default class Installation {
  constructor(data) {
    Object.assign(this, data)

    this.end = data.end ? new Date(data.end) : null
    this.start = new Date(data.start)
    this.device = data.device || null
    this.channelMap = data.channelMap || {}
    this.networkAsset = data.networkAsset || null

    const deviceId = this.device ? this.device.id : ''
    const networkAssetId = this.networkAsset ? this.networkAsset.id : ''

    this.uuid =
      `installation:${networkAssetId}:${deviceId}:${this.channelMap.pressure_1}:${this.start.getTime()}` // eslint-disable-line max-len
    Object.freeze(this)
  }

  translateAssetChannel(t, index = 1) {
    const channel = this.channelMap[`pressure_${index}`]

    return t(`common/text:text.asset_channel_${channel}`)
  }

  isActive = () => !(this.end instanceof Date)

  isOnUnavailableAsset() {
    return !this.networkAsset ||
      this.networkAsset.state === NetworkAsset.VOIDED_STATE
  }

  wasActiveAtTime(timestamp) {
    return this.start <= timestamp && (!this.end || this.end >= timestamp)
  }

  withNetworkAsset(networkAsset) {
    return Installation.from({
      ...this,
      networkAsset,
    })
  }

  static from(...args) {
    return new Installation(...args)
  }
}
