import React, { memo } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Button } from 'reactstrap'

import styles from './group_member_list_item.css'

function GroupMemberListItem(props) {
  const {
    className, buttonClassName, handleClick, isDisabled, children,
  } = props

  return (
    <li className={classnames('d-flex flex-column', className)}>
      <Button
        disabled={isDisabled}
        className={classnames(
          styles['group-item'],
          'w-100 rounded-0 py-1',
          buttonClassName
        )}
        onClick={handleClick}
        color="link"
        type="button">
        <div className="d-flex justify-content-between align-items-center">
          {children}
        </div>
      </Button>
    </li>
  )
}

GroupMemberListItem.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  handleClick: PropTypes.func,
  className: PropTypes.string,
  buttonClassName: PropTypes.string,
  children: PropTypes.node,
}

GroupMemberListItem.defaultProps = {
  isDisabled: false,
}

export default memo(GroupMemberListItem)
