import { graphql } from '@apollo/client/react/hoc'
import classnames from 'classnames'
import { push } from 'connected-react-router'
import gql from 'graphql-tag'
import PropTypes from 'prop-types'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { DropdownItem, InputGroup, InputGroupText } from 'reactstrap'
import { compose } from 'redux'
import { createSelector } from 'reselect'

import ExportCsvModal from '@@src/alerts_path/export_csv_modal'
import * as analytics from '@@src/analytics'
import ExportDropdown from '@@src/components/dropdowns/export_dropdown'
import ErrorInfo from '@@src/components/error_info'
import DebouncedInput from '@@src/components/forms/debounced_input'
import * as Changes from '@@src/store/changes'
import { mergeSearchParams, parseSearchParams } from '@@src/utils'
import AsyncResult from '@@src/utils/async_result'
import DebouncedInputController from '@@src/utils/debounced_input_controller'

import styles from './export_bar.css'

class ExportBar extends React.PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    requestAlertsReport: PropTypes.func.isRequired,
    dispatchNotifyDownloadsChanged: PropTypes.func.isRequired,
    className: PropTypes.string,
  }

  constructor(props) {
    super(props)

    this.searchAlerts = React.createRef()

    this.state = {
      searchInputController: new DebouncedInputController(),
      isExportModalOpen: false,
      isExportDropdownOpen: false,
      csvExportResult: AsyncResult.notFound(),
    }
  }

  render() {
    const { t, className, location, alertsSearchQuery } = this.props
    const {
      isExportDropdownOpen,
      isExportModalOpen,
      csvExportResult,
      searchInputController,
    } = this.state

    return (
      <div
        className={classnames(
          'd-flex',
          'justify-content-between',
          'align-items-center',
          styles.background,
          className
        )}
      >
        <ExportCsvModal
          location={location}
          handleSubmit={this.handleSubmitExportCsvModal}
          result={csvExportResult}
          isOpen={isExportModalOpen}
          handleToggle={this.handleToggleExportModal}
        >
          {csvExportResult.wasFailure() ? (
            <ErrorInfo error={csvExportResult.error} />
          ) : null}
        </ExportCsvModal>
        <ExportDropdown
          name="export-dropdown"
          className="my-1"
          color="info"
          isOpen={isExportDropdownOpen}
          handleToggle={this.handleToggleExportDropdown}
        >
          <DropdownItem
            name="export-alerts-csv"
            onClick={this.handleToggleExportModal}
          >
            {t('buttons.export_csv')}
          </DropdownItem>
        </ExportDropdown>
        <InputGroup className={styles['search-bar']}>
          <DebouncedInput
            type="search"
            name="alerts-search-input"
            debounce={200}
            onChange={this.onAlertsIDSearch}
            controller={searchInputController}
            initialValue={alertsSearchQuery}
            placeholder={t('search.placeholder')}
          />

          <InputGroupText>
            <i className="fa fa-search"></i>
          </InputGroupText>
        </InputGroup>
      </div>
    )
  }

  handleToggleExportDropdown = () => {
    this.setState({
      isExportDropdownOpen: !this.state.isExportDropdownOpen,
    })
  }

  handleToggleExportModal = () => {
    this.setState({
      isExportModalOpen: !this.state.isExportModalOpen,
      csvExportResult: AsyncResult.notFound(),
    })
  }

  handleSubmitExportCsvModal = async (variables) => {
    const { requestAlertsReport } = this.props

    try {
      this.setState({ csvExportResult: AsyncResult.pending() })

      await requestAlertsReport({
        variables: {
          reportType: 'csv',
          ...variables,
        },
      })

      this.setState({
        csvExportResult: AsyncResult.success(),
      })

      this.props.dispatchNotifyDownloadsChanged()
      this.handleToggleExportModal()
    } catch (err) {
      analytics.logError('error requesting alerts csv export', err.message)
      this.setState({
        csvExportResult: AsyncResult.fail(err),
      })
    }
  }

  onAlertsIDSearch = (event) => {
    const { location, dispatchUpdateAlertSearchQuery } = this.props
    dispatchUpdateAlertSearchQuery(location, event.target.value)
  }

  static REQUEST_ALERTS_REPORT_MUTATION = gql`
    mutation RequestAlertsReport(
      $reportType: RequestedReportTypes!
      $dateFrom: String
      $dateTo: String
      $networkAssetSourceId: Int
      $groupSourceId: Int
      $alertType: AlertType
      $alertStatuses: [AlertStatus]
      $networkAssetType: [AssetType]
      $searchQuery: String
    ) {
      requestAlertsReport(
        reportType: $reportType
        dateFrom: $dateFrom
        dateTo: $dateTo
        networkAssetSourceId: $networkAssetSourceId
        groupSourceId: $groupSourceId
        alertType: $alertType
        alertStatuses: $alertStatuses
        networkAssetType: $networkAssetType
        searchQuery: $searchQuery
      ) {
        jobId
      }
    }
  `
}

function createMapStateToProps() {
  const selectParsedSearchParams = createSelector(
    [(_state, { location }) => location.search],
    parseSearchParams
  )

  const selectAlertsSearchQuery = (state, props) =>
    selectParsedSearchParams(state, props).s

  return function mapStateToProps(state, ownProps) {
    return {
      alertsSearchQuery: selectAlertsSearchQuery(state, ownProps),
    }
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchNotifyDownloadsChanged() {
      dispatch(Changes.notifyChange(Changes.CSV_DOWNLOAD_CHANGES))
    },

    dispatchUpdateAlertSearchQuery(location, query) {
      dispatch(
        push({
          search: mergeSearchParams(location.search, {
            s: query ? query : undefined,
          }),
          pathname: location.pathname,
        })
      )
    },
  }
}

export default compose(
  withTranslation(['src/alerts_path/export_bar']),
  connect(createMapStateToProps, mapDispatchToProps),
  graphql(ExportBar.REQUEST_ALERTS_REPORT_MUTATION, {
    name: 'requestAlertsReport',
  })
)(ExportBar)
