import classnames from 'classnames'
import { noop } from 'lodash/fp/util'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import { Button, Col, Row, UncontrolledTooltip } from 'reactstrap'
import { compose } from 'redux'

import Device from '@@src/api/presenters/device'
import DeviceIssuesBadge from '@@src/components/badges/device_issues_badge'
import DeviceIconWithStatus from '@@src/components/icons/device_icon_with_status'
import NetworkAssetIcon from '@@src/components/icons/network_asset_icon'
import UpdatingDeviceDate from '@@src/management_path/devices_path/updating_device_date'
import routes from '@@src/routes'
import { MINUTES } from '@@src/utils'
import { CAN_VIEW_ANALYSIS } from '../../../_v2/contexts/user/consts/permissions'
import { withUser } from '../../../_v2/contexts/user/user.context'
import { withWindowSizes } from '../../window_size_provider'

import styles from './device_list_item.css'

class DeviceListItem extends React.PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    device: PropTypes.instanceOf(Device).isRequired,
    contactIncrementAmountMilliseconds: PropTypes.number.isRequired,
    refetchDevice: PropTypes.func.isRequired,
    nextContactDate: PropTypes.string,
  }

  static defaultProps = {
    contactIncrementAmountMilliseconds: 1 * MINUTES,
    refetchDevice: noop,
  }

  render() {
    const {
      t, className, device, onClick, onClickInstall, canEdit, isMobile,
      authorizedUser,
    } =
      this.props
    const networkAsset =
      device.currentCommission &&
      device.currentCommission.currentInstallation &&
      device.currentCommission.currentInstallation.networkAsset
    const installButtonDisabled = !!(
      !canEdit ||
      networkAsset ||
      device.isDecommissioned
    )

    return (
      <li className={className} onClick={onClick}>
        <Row className={styles.row}>
          {canEdit ? null : <ReactTooltip effect="solid" place="bottom" />}
          <Col className={styles['summary-column']}>
            <div className={styles['icon-subcolumn']}>
              <DeviceIconWithStatus device={device} className={styles.icon} />
            </div>

            <div className={styles['summary-subcolumn']}>
              {this.renderSerialNumberAndActivity()}
              {
                <div className="d-flex flex-column justify-content-center">
                  {this.renderPrevContact(device)}
                  {this.renderNextContact(device)}
                </div>
              }
            </div>
          </Col>

          <Col className={styles['actions-column']}>
            {!networkAsset ? null : (
              <div>
                <NavLink to={routes.networkAssetsDetailPath(networkAsset.id)}>
                  <div className={styles['icon-subcolumn']}>
                    <NetworkAssetIcon
                      id={'asset' + networkAsset.id}
                      networkAsset={networkAsset}
                      className={styles.icon}
                    />
                  </div>
                </NavLink>
                <UncontrolledTooltip
                  target={'asset' + networkAsset.id}
                  placement="left"
                  // the following line is necessary to fix this issue:
                  // https://github.com/reactstrap/reactstrap/issues/1482#issuecomment-498747688
                  // modifiers={{ flip: { enabled: false } }}
                  className={styles['network-asset-serial-number-tooltip']}
                >
                  {networkAsset.assetId}
                </UncontrolledTooltip>
              </div>
            )}

            {!isMobile ? (
              <React.Fragment>
                {onClickInstall ? (
                  <span data-tip={t('common/text:permissions.disabled')}>
                    <Button
                      name="install-network-asset-button"
                      size="sm"
                      color="primary"
                      disabled={installButtonDisabled}
                      onClick={onClickInstall}
                    >
                      {t('buttons.install')}
                    </Button>
                  </span>
                ) : null}
                {authorizedUser.can(CAN_VIEW_ANALYSIS) && (
                  <NavLink
                    to={routes.analysisPressurePath({ d: [device.uuid] })}
                    className={classnames(
                      styles.button,
                      'btn btn-outline-secondary',
                      styles['info-button'],
                    )}
                  >
                    <i className="fa fa-chart-area"></i>
                  </NavLink>
                )}
              </React.Fragment>
            ) : null}
            <NavLink
              to={routes.managementDevicesDetailPath(device.id)}
              className={classnames(
                styles.button,
                'btn btn-outline-secondary',
                styles['info-button'],
              )}
            >
              <i className="fa fa-info"></i>
            </NavLink>
          </Col>
        </Row>
      </li>
    )
  }

  renderSerialNumberAndActivity = () => {
    const { device } = this.props

    return (
      <span className={classnames('d-flex m-0 flex-wrap')}>
        <span className="mr-2">{device.serialNumber}</span>
        {Array.isArray(device.activeIssues) &&
        device.activeIssues.length > 0 &&
        !device.isDecommissioned ? (
          <span className={styles['device-issues-badge']}>
              <DeviceIssuesBadge deviceIssues={device.activeIssues} />
            </span>
        ) : null}
      </span>
    )
  }

  renderPrevContact = (device) => {
    const { t, contactIncrementAmountMilliseconds, refetchDevice } = this.props

    return (
      <small className={styles['device-activity']}>
        <UpdatingDeviceDate
          device={device}
          incrementAmountMilliseconds={contactIncrementAmountMilliseconds}
          refetchDevice={refetchDevice}
        >
          {() => {
            const deviceTargetId = `device-prev-contact-info-${device.id}`

            return (
              <React.Fragment>
                <span id={deviceTargetId}>
                  {device.translateLastActivityText(t)}
                </span>
                {device.lastCommunication ? (
                  <UncontrolledTooltip
                    target={deviceTargetId}
                    // modifiers={{ flip: { enabled: false } }}
                    placement="right"
                  >
                    {moment(device.lastCommunication).format('lll')}
                  </UncontrolledTooltip>
                ) : null}
              </React.Fragment>
            )
          }}
        </UpdatingDeviceDate>
      </small>
    )
  }

  renderNextContact = () => {
    const { t, device, contactIncrementAmountMilliseconds, refetchDevice } =
      this.props
    const nextContactDate = device.getNextContactDate()

    if (
      !nextContactDate ||
      !device.hasConfigurableSessions() ||
      device.isDecommissioned
    ) {
      return null
    }

    return (
      <small name="device-list-item-next-contact-date">
        <UpdatingDeviceDate
          device={device}
          incrementAmountMilliseconds={contactIncrementAmountMilliseconds}
          refetchDevice={refetchDevice}
        >
          {() => {
            const isPendingCommunication =
              device.isCommunicatingPendingOrActive()
            const deviceTargetId = `device-next-contact-info-${device.id}`

            return (
              <React.Fragment>
                <span id={deviceTargetId} className={styles['device-activity']}>
                  {device.translateNextCommunication(t, isPendingCommunication)}
                </span>
                {!isPendingCommunication ? (
                  <UncontrolledTooltip
                    target={deviceTargetId}
                    // modifiers={{ flip: { enabled: false } }}
                    placement="right"
                  >
                    {moment(device.getNextContactDate()).format('LT')}
                  </UncontrolledTooltip>
                ) : null}
              </React.Fragment>
            )
          }}
        </UpdatingDeviceDate>
      </small>
    )
  }
}

export default compose(
  withUser,
  withTranslation(['src/components/lists/device_list_item', 'common/text']),
  withWindowSizes,
)(DeviceListItem)
