export const CREATE_TOOLTIP = 'tooltip.create'
export const REMOVE_TOOLTIP = 'tooltip.remove'

export function remove(tooltipId) {
  return { type: REMOVE_TOOLTIP, tooltipId, $inspect: false }
}

export function create(tooltipId, instanceRef, tooltip) {
  return {
    type: CREATE_TOOLTIP,
    tooltip,
    $inspect: false,
    tooltipId,
    instanceRef,
  }
}
