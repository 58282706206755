import React, { useContext } from 'react'
import { UserContext } from '../../_v2/contexts/user/user.context'

export default function userPermissions(Component) {
  return function WithUserPermissions(props) {
    const { authorizedUser } = useContext(UserContext)

    const role = authorizedUser?.role || ''
    const permissions = authorizedUser?.permissions || []

    return <Component {...props} role={role} permissions={permissions} />
  }
}
