import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import DatetimePickerFormGroup
from '@@src/components/forms/datetime_picker_form_group'
import { compose } from 'redux'
import { withTranslation } from 'react-i18next'
import { Row, Col } from 'reactstrap'

import Device from '@@src/api/presenters/device'
import AppFormGroup from '@@src/components/forms/app_form_group'
import LocationInformationInputGroup
from '@@src/components/forms/inputs/location_information_input_group'
import CreateAndAssignNetworkAssetFields
from './create_and_assign_network_asset_fields'
import { validAssetTypes, mapAssetTypeToLogicalChannels } from '@@src/utils'
import NetworkAsset from '@@src/api/presenters/network_asset'
import SourceFilter, {
  V_NETWORK_ASSET_TYPE,
} from '@@src/components/forms/source_filter'
import {
  COORDS_FORMAT_WGS84,
} from '@@src/utils/conversions/coordinate_conversions'

import styles from './index.css'

class CreateAndAssignNetworkAssetInterface extends React.Component {
  static propTypes = {
    fields: PropTypes.instanceOf(CreateAndAssignNetworkAssetFields).isRequired,
    device: PropTypes.instanceOf(Device),
    className: PropTypes.string,
    sourceFilter: PropTypes.string,
    handleSelectGroup: PropTypes.func.isRequired,
  }

  render() {
    const { t, device, className, sourceFilter, handleSelectGroup } = this.props

    const currentAssetType = this.selectFieldValue('assetType')
    const availableChannels = currentAssetType ?
      mapAssetTypeToLogicalChannels(currentAssetType) :
      []

    return (
      <div className={classnames(styles.container, className)}>
        <Row>
          <Col sm="12">
            <DatetimePickerFormGroup
              name="installation-start-time"
              value={this.selectFieldValue('startTime')}
              onChange={this.onChangeDatetime}
              className={styles['datetime-input']}
              errorText={this.selectFieldErrorText('startTime')}
              labelText={t('labels.installation_time')}/>
          </Col>
        </Row>

        <div className={styles.separator}></div>

        <Row>
          <Col sm="4">
            <AppFormGroup
              id="network-asset-type"
              name="network-asset-type"
              type="select"
              label={t('labels.asset_type_label')}
              value={this.selectFieldValue('assetType')}
              onChange={this.onChangeAssetType}
              required={true}
              errorText={this.selectFieldErrorText('assetType')}>
              <option value="">
                {t('text.asset_type_option_blank')}
              </option>

              {
                validAssetTypes().map(assetType => (
                  <option key={assetType} value={assetType}>
                    {t(`common/text:text.${assetType}`)}
                  </option>
                ))
              }
            </AppFormGroup>
          </Col>

          <Col sm="8">
            <AppFormGroup
              maxLength={NetworkAsset.MAX_ASSET_ID_LENGTH}
              id="network-asset-id"
              name="network-asset-id"
              type="text"
              label={t('labels.asset_id_label')}
              value={this.selectFieldValue('assetId')}
              onChange={this.fieldOnChange('assetId')}
              required={true}
              errorText={this.selectFieldErrorText('assetId')}/>
          </Col>
        </Row>

        <Row>
          <Col sm={{ size: 8, offset: 4 }}>
            <AppFormGroup
              id="network-asset-name"
              hint={t('text.asset_name_hint')}
              name="network-asset-name"
              type="text"
              label={t('labels.asset_name')}
              value={this.selectFieldValue('assetName')}
              onChange={this.fieldOnChange('assetName')}
              errorText={this.selectFieldErrorText('assetName')}/>
          </Col>
        </Row>

        <Row>
          <Col sm="12">
            <AppFormGroup
              name="channel"
              type="select"
              label={t('labels.channel')}
              value={this.selectFieldValue('channel')}
              disabled={availableChannels.length === 0}
              onChange={this.fieldOnChange('channel')}
              errorText={this.selectFieldErrorText('channel')}>
              <option>
                {t('text.asset_type_option_blank')}
              </option>

              {
                availableChannels.length > 0 ? (
                  availableChannels.map(channel => (
                    <option key={channel} value={channel}>
                      {t(`common/text:text.asset_channel_${channel}`)}
                    </option>
                  ))
                ) : null
              }
            </AppFormGroup>
          </Col>
        </Row>

        <div className={styles.separator}></div>

        <div>
          <h5>
            {t('headings.location')}
          </h5>

          <LocationInformationInputGroup
            device={device}
            locationSourceValue={this.selectFieldValue('locationSource')}
            locationSourceRequired={true}
            onChangeLocationSource={this.onChangeLocationSource}
            locationSourceErrorText={
              this.selectFieldErrorText('locationSource')
            }

            elevationValue={this.selectFieldValue('elevation')}
            onChangeElevation={this.fieldOnChange('elevation')}
            elevationErrorText={this.selectFieldErrorText('elevation')}

            coordinatesRequired={true}
            coordinatesErrorText={this.selectFieldErrorText('coordinates')}
            coordinatesValue={this.selectFieldValue('coordinates')}
            onChangeCoordinates={this.fieldOnChange('coordinates')}
            coordinatesFormatValue={this.selectFieldValue('coordinatesFormat')}
            onChangeCoordinatesFormat={this.onChangeCoordinatesFormat}/>
        </div>

        <div className={styles.separator}></div>

        <div>
          <h5>
            {t('headings.group')}
          </h5>
          <SourceFilter
            displayLabel={false}
            displayAssets={false}
            sourceFilter={sourceFilter}
            handleClickSelectSource={handleSelectGroup}
            location={location}
            dropdownDirection={'down'}
            groupTypes={[V_NETWORK_ASSET_TYPE]} />
        </div>

        <div className={styles.separator}></div>

        <div>
          <h5>
            {t('headings.description')}
          </h5>

          <AppFormGroup
            id="network-asset-custom-labels"
            name="network-asset-custom-labels"
            type="text"
            label={t('labels.custom_labels')}
            value={this.selectFieldValue('customLabels')}
            onChange={this.fieldOnChange('customLabels')}
            errorText={this.selectFieldErrorText('customLabels')}
            placeholder={t('text.custom_labels_placeholder')}/>

          <AppFormGroup
            id="network-asset-comments"
            name="network-asset-comments"
            rows={4}
            type="textarea"
            label={t('labels.comment')}
            value={this.selectFieldValue('comment')}
            onChange={this.fieldOnChange('comment')}
            errorText={this.selectFieldErrorText('comment')}/>
        </div>
      </div>
    )
  }

  selectFieldValue = fieldName => {
    return this.props.fields.valueFor(fieldName)
  }

  fieldOnChange = fieldName => {
    return this.props.fields.onChangeHandlerFor(fieldName)
  }

  onChangeCoordinatesFormat = async format => {
    await this.props.fields.updateFieldValue('coordinatesFormat', format)
  }

  onChangeLocationSource = async event => {
    // take value before event is recycled
    const locationSource = event.target.value
    await this.fieldOnChange('locationSource')(event)

    const { device } = this.props
    if (locationSource === 'gps' && device && device.coordinates) {
      this.props.fields.batchUpdateFieldValues({
        coordinates: device.coordinates,
        coordinatesFormat: COORDS_FORMAT_WGS84,
      })
    }
  }

  onChangeAssetType = (...args) => {
    const newAssetType = args[0].target.value
    let newAssetTypeChannel = ''

    if (newAssetType) {
      const newAssetTypeAvailableChannels = mapAssetTypeToLogicalChannels(
        newAssetType
      )

      if (newAssetTypeAvailableChannels.length === 1) {
        newAssetTypeChannel = newAssetTypeAvailableChannels[0]
      }
    }

    this.fieldOnChange('assetType')(...args)

    setTimeout(() => {
      this.props.fields.updateFieldValue(
        'channel',
        newAssetTypeChannel,
      )
    }, 0)
  }

  selectFieldErrorText = fieldName => {
    const error = this.props.fields.errorFor(fieldName)
    return error ? this.props.t(error) : error
  }

  onChangeDatetime = datetime => {
    this.props.fields.updateFieldValue(
      'startTime',
      (typeof datetime === 'string') ? datetime : datetime.toDate()
    )
  }
}

export default compose(
  withTranslation([
    'src/management_path/devices_path/assign_network_asset_to_device_modal/create_and_assign_network_asset_interface/index', // eslint-disable-line max-len
    'common/text',
  ]),
)(CreateAndAssignNetworkAssetInterface)
