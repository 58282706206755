import Keycloak from 'keycloak-js'
import { OauthLibraryBasedIdentityProvider } from './abstract/oauth-library-based-identity-provider'

const KC_TOKEN_KEY = 'kcToken'
const KC_REFRESH_TOKEN_KEY = 'kcToken'

export class KeycloakIdentityProvider extends OauthLibraryBasedIdentityProvider {
  constructor({ clientId, realm, url }) {
    super()
    this.initialized = false
    this.keycloak = new Keycloak({ clientId, realm, url })
    this.keycloak.onTokenExpired = () => {
      this.keycloak
        .updateToken(30)
        .then(refreshed => refreshed ? this._storeToken() : Promise.resolve())
        .catch(() => Promise.resolve())
    }
    this.options = { clientId, realm, url }
  }

  /**
   * @param {object} identityProviderSettings
   * @return {Promise<KeycloakIdentityProvider>}
   */
  static async initialize(identityProviderSettings) {
    return new KeycloakIdentityProvider({
      clientId: identityProviderSettings?.clientId,
      realm: identityProviderSettings?.realm,
      url: identityProviderSettings?.baseUrl,
    })
  }

  async setup() {
    try {
      if (!this.initialized) {
        this.initialized = true
        const isAuthenticated = await this.keycloak.init({
          flow: 'standard',
          onLoad: 'check-sso',
          checkLoginIframe: false,
          silentCheckSsoFallback: true,
          token: localStorage.getItem(KC_TOKEN_KEY),
          refreshToken: localStorage.getItem(KC_REFRESH_TOKEN_KEY),
        })

        if (!isAuthenticated) {
          await this.keycloak.login()
        } else {
          await this._storeToken()
        }
      }
    } catch (e) {
    }
  }

  async getCurrentUserToken() {
    try {
      if (this.keycloak.isTokenExpired()) {
        const refreshed = await this.keycloak.updateToken(5)

        if (!refreshed) {
          await this._clearToken()
          return null
        } else {
          await this._storeToken()
        }
      }

      return this.keycloak.token || null
    } catch (e) {
      return null
    }
  }

  async getCurrentUser() {
    try {
      const token = await this.getCurrentUserToken()

      if (!token) {
        // user is not authenticated
        return null
      }

      await this.keycloak.loadUserProfile()
      return await super._getUserDetailsFromBff(token)
    } catch (err) {
      throw err
    }
  }

  async signOut() {
    try {
      await this._clearToken()
      await this.keycloak.logout()
    } catch (err) {
      throw new Error('Failed to sign out')
    }
  }

  async getAcceptedEulaVersion() {
    const userProfile = await this.keycloak.loadUserProfile()

    return userProfile.attributes?.['acceptedEulaVersion']?.[0]
  }

  async setAcceptedEulaVersion(version) {

  }

  async _storeToken() {
    localStorage.setItem(KC_TOKEN_KEY, this.keycloak.token)
    localStorage.setItem(KC_REFRESH_TOKEN_KEY, this.keycloak.refreshToken)
  }

  async _clearToken() {
    localStorage.removeItem(KC_TOKEN_KEY)
    localStorage.removeItem(KC_REFRESH_TOKEN_KEY)
  }
}
