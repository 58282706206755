import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import { Row, Col, Badge } from 'reactstrap'
import moment from 'moment'

import * as analytics from '@@src/analytics'
import config from '@@src/config'
import { EXPORT_DATA_TYPE, EXPORT_REPORT_TYPE, EXPORT_CSV_REPORT_TYPE,
  EXPORT_PRESSURE_DATA_TYPE, EXPORT_CPIS_DATA_TYPE, EXPORT_RAW_DATA_TYPE,
  EXPORT_EVENTS_DATA_TYPE }
from '@@src/components/dropdowns/export_dropdown_with_modal/export_dropdown_with_modal'
import {
  getReportFilename,
} from '@@src/utils/report_downloads'
import SubmitButton from '@@src/components/buttons/submit_button'
import ReportDownloadIcon from '@@src/components/icons/report_download_icon'
import { AppError, AsyncResult } from '@@src/utils'

import styles from './data_download_item.css'
import { EXPORT_ANOMALY_REPORT_TYPE } from '../analysis_path/pressure_analysis_path/anomaly_list/consts'

const DISPLAY_DATE_FORMAT = 'MMM D, YYYY H:mm A'
const PARSE_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSSZ'

export function getDownloadLink(uuid) {
  return `${config.apiUrl}/v1/rest/${config.tenant}/reports/${uuid}`
}

class DataDownloadItem extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    start: PropTypes.string.isRequired,
    end: PropTypes.string.isRequired,
    reportType: PropTypes.oneOf(EXPORT_REPORT_TYPE).isRequired,
    dataType: PropTypes.oneOf(EXPORT_DATA_TYPE).isRequired,
    aggregateName: PropTypes.string,
    reportStatus: PropTypes.string.isRequired,
    uuid: PropTypes.string,
    onDownloadClick: PropTypes.func.isRequired,
    jobCreated: PropTypes.string,
  }

  constructor(props) {
    super(props)

    this.state = {
      downloadResult: AsyncResult.notFound(),
    }
  }

  getStatusLabel = () => {
    const { reportStatus, t } = this.props

    switch (reportStatus) {
      case 'new':
      case 'inProgress':
        return t('text.in_progress')

      case 'noData':
        return t('text.no_data')

      default:
        return t('text.failed')
    }
  }

  getDataType = () => {
    const { t, dataType } = this.props

    switch (dataType) {
      case EXPORT_CPIS_DATA_TYPE:
        return t('text.cpis_data_type')

      case EXPORT_PRESSURE_DATA_TYPE:
        return t('text.pressure_data_type')

      case EXPORT_RAW_DATA_TYPE:
        return t('text.raw_data_type')

      case EXPORT_EVENTS_DATA_TYPE:
        return t('text.events_type')

      case EXPORT_ANOMALY_REPORT_TYPE:
        return t('text.anomalies')

      default:
        return dataType
    }
  }

  getReportType = () => {
    const { reportType } = this.props

    switch (reportType) {
      case EXPORT_CSV_REPORT_TYPE:
        return 'CSV'

      default:
        return reportType
    }
  }

  onClick = async () => {
    const { uuid, onDownloadClick, aggregateName, t, jobCreated, dataType, reportType } = this.props

    const downloadLink = getDownloadLink(uuid)
    const extension = this.getReportType().toLowerCase()
    const filename = `${aggregateName
    || getReportFilename({ t, jobCreated, dataType, reportType })}.${extension}`

    this.setState({ downloadResult: AsyncResult.pending() })

    try {
      await onDownloadClick(downloadLink, filename)
      this.setState({ downloadResult: AsyncResult.success() })
    } catch (err) {
      analytics.logError(err)

      this.setState({ downloadResult: AsyncResult.fail(AppError.from(err)) })
    }
  }

  render() {
    const { downloadResult } = this.state
    const { reportStatus, aggregateName, start, end, t,
      reportType, jobCreated, dataType } = this.props

    return (
      <li className={styles['download-item']}>
        <Row>
          <Col className="d-flex align-items-center">
            <span className={styles['download-item-icon']}>
              <ReportDownloadIcon report={{ reportType, reportStatus }}/>
            </span>
            <div>
              <div>
                <span className={styles['download-item-name']}>
                  {aggregateName ||
                    getReportFilename({ t, jobCreated, dataType, reportType })}
                </span>
                <Badge className={styles['download-item-badge']} color="light">
                  {this.getReportType()}
                </Badge>
                <Badge className={styles['download-item-badge']} color="light">
                  {this.getDataType()}
                </Badge>
              </div>
              <time className={styles['download-item-time']} dateTime={start}>
                {moment(start, PARSE_DATE_FORMAT).format(DISPLAY_DATE_FORMAT)}
              </time>
              <span className={styles['download-item-time-divider']}>
                →
              </span>
              <time className={styles['download-item-time']} dateTime={end}>
                {moment(end, PARSE_DATE_FORMAT).format(DISPLAY_DATE_FORMAT)}
              </time>
            </div>
          </Col>
          <Col className="d-flex align-items-center" sm="auto">
            {
              reportStatus !== 'complete' ?
                <span className={styles['download-item-status']}>
                  {this.getStatusLabel()}
                </span>
                : null
            }
            {
              reportStatus === 'complete' ?
                <SubmitButton
                  color="primary"
                  submitText={t('button.download')}
                  onSubmitForm={this.onClick}
                  result={downloadResult}>
                </SubmitButton>
                : null
            }
          </Col>
        </Row>
      </li>
    )
  }
}

export default withTranslation([
  'src/downloads_path/data_download_item',
])(DataDownloadItem)
