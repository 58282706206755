export default class EventStream {
  constructor() {
    this._backlog = []
    this._listener = null
  }

  subscribe(callback) {
    this._listener = callback

    setTimeout(() => {
      for (const event of this._backlog) {
        callback(event)
      }

      this._backlog = []
    }, 0)

    return () => {
      if (this._listener === callback) {
        this._listener = null
      }
    }
  }

  pushEvent(event) {
    if (this._listener) {
      this._listener(event)
    } else {
      this._backlog.push(event)
    }
  }
}
