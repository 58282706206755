import React, { memo } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import styles from './group_icon.css'

function GroupIcon(props) {
  const { className } = props

  return (
    <span
      className={
        classnames('far fa-folder', styles['group-icon'], className)
      } />
  )
}

GroupIcon.propTypes = {
  className: PropTypes.string,
}

export default memo(GroupIcon)
