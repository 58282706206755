import React from 'react'
import { connect } from 'react-redux'

import * as Changes from '@@src/store/changes'

export default function listenForChanges(key, { props = a => a } = {}) {
  return Component => {
    class ChangeListener extends React.PureComponent {
      state = {
        handled: true,
      }

      render() {
        const { handled } = this.state

        return (
          <Component
            {...this.props}
            {...props({
              handled,
              handleChanges: this.handleChanges,
            })}/>
        )
      }

      handleChanges = () => {
        this.setState({ handled: true })
      }

      componentDidUpdate(prevProps) {
        if (this.props.change !== prevProps.change) {
          this.setState({ handled: false })
        }
      }
    }

    function mapStateToProps(state) {
      return {
        change: Changes.selectChangeInfo(state, key),
      }
    }

    return connect(mapStateToProps)(ChangeListener)
  }
}
