import { onError } from '@apollo/client/link/error'
import { ApolloLink } from '@apollo/client'

import * as analytics from '@@src/analytics'
import { formatGraphQLErrorMessage } from '@@src/utils'

class GraphQLRequestError extends Error {
}

export default function createErrorHandlingLink() {
  return ApolloLink.from([
    onError(({ graphQLErrors, networkError, operation }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(err => {
          err.message = formatGraphQLErrorMessage(err.message)
        })
        const messages = graphQLErrors.map(err => err.message)
        const errorToReport = new GraphQLRequestError(`GraphQL Error: ${messages.length > 1 ? '\n' : ''}${messages.join('\n')}`) // eslint-disable-line max-len
        errorToReport._sentryErrorTags = {
          'graphql:opname': operation.operationName,
        }
        if (graphQLErrors.length) {
          errorToReport.stack = graphQLErrors[0].stack
        }

        analytics.logError(errorToReport)
      }

      if (networkError) {
        const errorToReport = new GraphQLRequestError(`Network Error: ${networkError.message}`) // eslint-disable-line max-len
        errorToReport._sentryErrorTags = {
          'graphql:opname': operation.operationName,
        }
        errorToReport.stack = networkError.stack

        analytics.logError(errorToReport)
      }
    }),
    onError(({ networkError, operation, forward }) => {
      if (networkError) {
        if (networkError.toString().match(/Failed to fetch$/)) {
          // retries the operation, apollo-link-error does not allow errors to
          // be retried more than once (per handler), so no need to track the
          // error here
          //
          // see https://www.apollographql.com/docs/link/links/error/#retrying-failed-requests
          return forward(operation)
        }
      }

      return null
    }),
  ])
}
