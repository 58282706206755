import { DeviceCommission } from '../../../../api/models/device_commission'
import { GET_DEVICE_COMMISSIONS } from '../../../../api/queries/device'
import { useApolloClient } from '@apollo/client'

const useGetCurrentDeviceCommission = () => {
  const client = useApolloClient()
  return (deviceId) =>
    client
      .query({
        query: GET_DEVICE_COMMISSIONS,
        variables: {
          onlyCurrent: true,
          deviceId,
        },
      }).then(result => {
        const { count, items } = result.data.getDeviceCommissions
        if(count === 0) {
          return null
        }
        return new DeviceCommission(items[0])
      })
}

export default useGetCurrentDeviceCommission
