import * as analytics from '@@src/analytics'

import commissionedIconUrl from
'@@src/static_assets/svgs/device_icon_pin_commissioned.svg'
import warningIconUrl from
'@@src/static_assets/svgs/device_icon_pin_warning.svg'
import errorIconUrl from '@@src/static_assets/svgs/device_icon_pin_error.svg'

export default class DeviceIssue {
  static ERROR_SEVERITY = 'error'
  static WARNING_SEVERITY = 'warning'
  static INFORMATION_SEVERITY = 'information'
  static ISSUE_SEVERITIES = [
    DeviceIssue.ERROR_SEVERITY,
    DeviceIssue.WARNING_SEVERITY,
    DeviceIssue.INFORMATION_SEVERITY,
  ]

  static HEALTHY_FILTER = 'healthy'

  static ERROR_SEVERITY_FILTER = 'withErrors'
  static WARNING_SEVERITY_FILTER = 'withWarnings'
  static INFORMATION_SEVERITY_FILTER = 'withInformation'
  static ISSUE_SEVERITY_FILTERS = [
    DeviceIssue.ERROR_SEVERITY_FILTER,
    DeviceIssue.WARNING_SEVERITY_FILTER,
    DeviceIssue.INFORMATION_SEVERITY_FILTER,
  ]

  static FW_UPDATE_PENDING = 'fw_update_pending'
  static CONFIGURATION_PENDING = 'configuration_pending'
  static NOT_INSTALLED_ON_ASSET = 'not_installed_on_asset'
  static MISSING_DATA = 'missing_data'
  static MISSING_DATA_AND_CATCHING_UP = 'missing_data_and_catching_up'
  static MISSING_DATA_AND_FALLING_BEHIND =
    'missing_data_and_falling_behind'
  static POOR_SIGNAL_STRENGTH = 'poor_signal_strength'
  static OUT_OF_OPERATING_TEMPERATURE = 'out_of_operating_temperature'
  static NOT_CONNECTED_TO_PRESSURE_SOURCE = 'not_connected_to_pressure_source'
  static HIGH_PRESSURE = 'high_pressure'
  static IMPOSSIBLE_HLC = 'impossible_hlc'
  static IMPOSSIBLE_BATTERY_VOLTAGE = 'impossible_battery_voltage'
  static BATTERY_DEPLETING = 'battery_depleting'
  static FROZEN_TRANSDUCER = 'frozen_transducer'
  static BATTERY_DEPLETED = 'battery_depleted'
  static DATA_RECEIVED_BUT_UNSAVED = 'data_received_but_unsaved'
  static DATA_HAS_NOT_BEEN_RECEIVED = 'data_has_not_been_received'
  static DETACHED_FROM_ASSET = 'detached_from_asset'

  static ERROR_SEVERITY_FILTER_COLLECTION = [
    DeviceIssue.BATTERY_DEPLETED,
    DeviceIssue.DATA_HAS_NOT_BEEN_RECEIVED,
    DeviceIssue.FROZEN_TRANSDUCER,
  ]

  static WARNING_SEVERITY_FILTER_COLLECTION = [
    DeviceIssue.MISSING_DATA,
    DeviceIssue.MISSING_DATA_AND_CATCHING_UP,
    DeviceIssue.MISSING_DATA_AND_FALLING_BEHIND,
    DeviceIssue.POOR_SIGNAL_STRENGTH,
    DeviceIssue.OUT_OF_OPERATING_TEMPERATURE,
    DeviceIssue.NOT_CONNECTED_TO_PRESSURE_SOURCE,
    DeviceIssue.HIGH_PRESSURE,
    DeviceIssue.BATTERY_DEPLETING,
    DeviceIssue.DETACHED_FROM_ASSET,
  ]

  static INFORMATION_SEVERITY_FILTER_COLLECTION = [
    DeviceIssue.FW_UPDATE_PENDING,
    DeviceIssue.CONFIGURATION_PENDING,
    DeviceIssue.NOT_INSTALLED_ON_ASSET,
  ]

  static VISIBLE_ISSUE_TYPES = [
    DeviceIssue.FW_UPDATE_PENDING,
    DeviceIssue.CONFIGURATION_PENDING,
    DeviceIssue.NOT_INSTALLED_ON_ASSET,
    DeviceIssue.MISSING_DATA,
    DeviceIssue.MISSING_DATA_AND_CATCHING_UP,
    DeviceIssue.MISSING_DATA_AND_FALLING_BEHIND,
    DeviceIssue.POOR_SIGNAL_STRENGTH,
    DeviceIssue.OUT_OF_OPERATING_TEMPERATURE,
    DeviceIssue.NOT_CONNECTED_TO_PRESSURE_SOURCE,
    DeviceIssue.HIGH_PRESSURE,
    DeviceIssue.BATTERY_DEPLETING,
    DeviceIssue.FROZEN_TRANSDUCER,
    DeviceIssue.BATTERY_DEPLETED,
    DeviceIssue.DATA_HAS_NOT_BEEN_RECEIVED,
    DeviceIssue.DETACHED_FROM_ASSET,
  ]
  static ISSUE_TYPES = [
    ...DeviceIssue.VISIBLE_ISSUE_TYPES,
    DeviceIssue.DETACHED_FROM_ASSET,
    DeviceIssue.DATA_RECEIVED_BUT_UNSAVED,
    DeviceIssue.IMPOSSIBLE_BATTERY_VOLTAGE,
    DeviceIssue.IMPOSSIBLE_HLC,
  ]

  static dataMissingTypes = [
    DeviceIssue.MISSING_DATA_AND_CATCHING_UP,
    DeviceIssue.MISSING_DATA_AND_FALLING_BEHIND,
  ]

  constructor(props) {
    Object.assign(this, props)
    Object.freeze(this)
  }

  get alertColor() {
    if (this.isError()) {
      return 'danger'
    } else if (this.isWarning()) {
      return 'warning'
    } else if (this.isInformation()) {
      return 'info'
    } else {
      analytics.logError(`unknown device issue severity: ${this.severity}`)
      return ''
    }
  }

  get typeWarning() {
    if (this.isError()) {
      return 'common/text:text.device_errors'
    } else if (this.isWarning()) {
      return 'common/text:text.device_warnings'
    } else if (this.isInformation()) {
      return 'common/text:text.device_information'
    } else {
      analytics.logError(`unknown device issue severity: ${this.severity}`)
      return ''
    }
  }

  get mapIconPinUrl() {
    if (this.isError()) {
      return errorIconUrl
    } else if (this.isWarning()) {
      return warningIconUrl
    } else if (this.isInformation()) {
      return commissionedIconUrl
    } else {
      analytics.logError(`unknown device issue severity: ${this.severity}`)
      return ''
    }
  }

  get descriptionTitle() {
    switch (this.type) {
      case DeviceIssue.DATA_HAS_NOT_BEEN_RECEIVED:
        return `${DeviceIssue.DATA_HAS_NOT_BEEN_RECEIVED}_title`
      default:
        return this.type
    }
  }

  isError() {
    return this.severity === DeviceIssue.ERROR_SEVERITY
  }

  isWarning() {
    return this.severity === DeviceIssue.WARNING_SEVERITY
  }

  isInformation() {
    return this.severity === DeviceIssue.INFORMATION_SEVERITY
  }
}
