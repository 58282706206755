const actions = require('./actions')

export const ALERT_STATUS_READ = 'read'
export const ALERT_STATUS_UNREAD = 'unread'
export const ALERT_STATUSES = [ALERT_STATUS_READ, ALERT_STATUS_UNREAD]

function createInitialState() {
  return { viewOnlyUnreadEventSourceLocalisations: false }
}

export function reducer(state = createInitialState(), action) {
  switch (action.type) {
    case actions.SET_VIEW_ONLY_UNREAD_EVENT_SOURCE_LOCALISATIONS: {
      return Object.assign({}, state, {
        viewOnlyUnreadEventSourceLocalisations: action.unreadOnly,
      })
    }

    default:
      return state
  }
}

export default {
  ...actions,

  ALERT_STATUSES,
  ALERT_STATUS_READ,
  ALERT_STATUS_UNREAD,
  reducer,
}
