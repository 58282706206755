import React from 'react'
import PropTypes from 'prop-types'
import { Popup } from 'react-leaflet'
import { Button } from 'reactstrap'
import { Link } from 'react-router-dom'
import { isNil } from 'lodash/lang'
import { withTranslation } from 'react-i18next'

import routes from '@@src/routes'
import Device from '@@src/api/presenters/device'
import StreetViewLink from '@@src/components/maps/markers/street_view_link'
import DeviceIssueSeverityBadge from
'@@src/components/badges/device_issue_severity_badge'

import styles from './device_commission_popup.css'

class DeviceCommissionPopup extends React.PureComponent {
  static defaultProps = {
    showDetailsLink: true,
    showAddLink: false,
    showDataLink: true,
    selected: false,
    isLimitReached: false,
    commission: undefined,
    device: undefined,
  }

  static propTypes = {
    showDetailsLink: PropTypes.bool.isRequired,
    showAddLink: PropTypes.bool,
    showDataLink: PropTypes.bool,
    isLimitReached: PropTypes.bool,
    addToSelected: PropTypes.func,
    selected: PropTypes.bool,
    removeFromSelected: PropTypes.func,
    device: PropTypes.instanceOf(Device),
    googleMaps: PropTypes.object,
  }

  render() {
    const {
      t, showDetailsLink, removeFromSelected, showDataLink,
      showAddLink, addToSelected, selected, isLimitReached, googleMaps,
    } = this.props

    let { commission, device } = this.props
    if (typeof commission === 'undefined') {
      commission = device.currentCommission
    }
    if (typeof device === 'undefined') {
      device = commission.device
    }

    const deviceIssue = device ? device.mostSevereIssue : null

    return (
      <Popup>
        <div className="p-3">
          <div className="d-flex flex-wrap align-items-center">
            <h5 className={styles['device-serial-number']}>
              {device.serialNumber}
            </h5>
            {
              deviceIssue ?
                <DeviceIssueSeverityBadge
                  className={styles['device-issue-badge']}
                  issue={deviceIssue} />
                : null
            }
          </div>

          <div className={styles['device-commission-start-and-end']}>
            {t('text.commissioned_date_is')}
            &nbsp;
            {t('text.short_readable_full_time', {
              time: commission.start,
            })}

            {
              commission.end ? (
                <span>
                  <br />

                  <span>
                    {t('text.decommissioned_date_is')}
                    &nbsp;
                    {t('text.short_readable_full_time', {
                      time: commission.end,
                    })}
                  </span>
                </span>
              ) : (
                <span>
                  <br />

                  <span>
                    {device.translateLastActivityText(t)}
                  </span>
                </span>
              )
            }
          </div>

          {
            !isNil(googleMaps) && device.currentLocation ?
              <StreetViewLink
                t={t}
                googleMaps={googleMaps}
                latitude={device.currentLocation.latitude}
                longitude={device.currentLocation.longitude} /> :
              null
          }

          <div className="mt-3 d-flex flex-row">
            {showDetailsLink ? (
              <Button
                className="mr-2 text-white"
                tag={Link}
                size="sm"
                color="primary"
                name="device-detail-link"
                to={routes.managementDevicesDetailPath(device.id)}>
                <i className="fa fa-info mr-2" />
                {t('buttons.show_details')}
              </Button>
            ) : null}

            {showAddLink === true ? (
              selected === true ?
                <Button
                  size="sm"
                  name="remove-from-selected-sources"
                  color="secondary"
                  outline
                  onClick={() => removeFromSelected(device)}>
                  <i className="fa fa-times mr-2" />
                  {t('buttons.remove')}
                </Button>
                :
                <Button
                  size="sm"
                  name="add-to-selected-sources"
                  color="primary"
                  disabled={isLimitReached}
                  onClick={() => addToSelected(device)}>
                  <i className="fa fa-plus mr-2" />
                  {t('buttons.select')}
                </Button>
            ) : null}

            {showDataLink ?
              <Button
                color="light"
                className="text-dark"
                name="show-data-link"
                size="sm"
                to={routes.analysisPressurePath({ d: [device.uuid] })}
                tag={Link}>
                <i className="fa fa-chart-area mr-2" />
                {t('buttons.show_data')}
              </Button>
              : null}
          </div>
        </div>
      </Popup>
    )
  }
}

export default withTranslation([
  'src/components/maps/markers/device_commission_popup',
  'src/components/maps/markers/street_view_link',
  'common/text',
])(DeviceCommissionPopup)
