import gql from 'graphql-tag'
import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { graphql } from '@apollo/client/react/hoc'
import { withTranslation } from 'react-i18next'

import * as analytics from '@@src/analytics'
import MessageBox from './message_box'
import AsyncResult from '@@src/utils/async_result'
import { sleep } from '@@src/utils'
import { MixedGroupDetailsType } from '@@src/api/presenters/group'

const INFLOWNET_WAIT = process.env.NODE_ENV === 'test' ? 0 : 1000

class RemoveAggregateFromGroupConfirmationPrompt extends React.PureComponent {
  static defaultProps = {
    toggle: () => { },
    onRemovedAggregate: () => { },
  }

  static propTypes = {
    group: PropTypes.instanceOf(MixedGroupDetailsType),
    toggle: PropTypes.func,
    isOpen: PropTypes.bool,
    aggregate: PropTypes.object,
    aggregateType: PropTypes.string,
    onRemovedAggregate: PropTypes.func.isRequired,
  }

  state = {
    result: AsyncResult.notFound(),
  }

  render() {
    const { t, toggle, isOpen, aggregate, group, aggregateType } = this.props

    const groupName = group ? group.name : '???'
    let aggregateName = '????'
    if (aggregateType === 'VNetworkAsset') {
      aggregateName = aggregate ? aggregate.assetId : '???'
    } else {
      aggregateName = aggregate ? aggregate.serialNumber : '????'
    }

    return (
      <MessageBox
        toggle={toggle}
        isOpen={isOpen}
        result={this.state.result}
        onAccept={this.onAccept}
        acceptText={t(`buttons.remove_${typeSuffixFor(aggregateType)}`)}
        cancelText={t('buttons.cancel')}
        headerText={t('headings.title')}
      >
        {t('text.confirmation', { aggregateName, groupName })}
      </MessageBox>
    )
  }

  onAccept = async event => {
    event.preventDefault()

    const {
      toggle, group, removeAggregateFromGroup, onRemovedAggregate, aggregate,
      aggregateType,
    } = this.props

    this.setState({ result: AsyncResult.pending() })

    try {
      await removeAggregateFromGroup({
        variables: {
          groupId: group.id,
          aggregateId: aggregate.id,
          aggregateType,
        },
      })

      this.setState({ result: AsyncResult.success() })

      await sleep(INFLOWNET_WAIT)
      await onRemovedAggregate()
      await toggle()
    } catch (err) {
      analytics.logError(err)
      this.setState({ result: AsyncResult.fail(err) })
    }
  }

  static REMOVE_FROM_GROUP_MUTATION = gql`
  mutation RemoveMemberFromGroup(
    $groupId: Int!,
    $aggregateId: Int!,
    $aggregateType: AggregateType!,
  ) {
      removeMemberFromGroup (
        groupId: $groupId,
        aggregateId: $aggregateId,
        aggregateType: $aggregateType,
      )
  }`
}

function typeSuffixFor(aggregateType) {
  switch (aggregateType) {
    case 'VDevice':
      return 'device'

    case 'VNetworkAsset':
      return 'asset'

    default:
      return 'aggregate'
  }
}

export default compose(
  withTranslation([
    'src/components/modals/remove_aggregate_from_group_confirmation_prompt',
  ]),
  graphql(RemoveAggregateFromGroupConfirmationPrompt
    .REMOVE_FROM_GROUP_MUTATION, {
    name: 'removeAggregateFromGroup',
  })
)(RemoveAggregateFromGroupConfirmationPrompt)
