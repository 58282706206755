import { useCallback, useContext, useEffect, useState } from 'react'
import { useApolloClient } from '@apollo/client'
import { debounce } from 'lodash/function'
import {
  FeatureFlags,
  TenantsFeatureFlagsContext,
} from '../../_v2/contexts/feature_flags/tenants_feature_flags.context'
import { calculateFetchingVariables } from '../../hooks/plot/consts'
import { renderAbsolutePositionedLoading } from '../../components/app_suspense_container'
import { UserSettingsContext } from '@@src/components/user_settings_provider'

let controller = null
export const WithFatchingVariablesAndRequestControll = ({
  startTime,
  endTime,
  eventIds,
  legendItemsResult,
  renderViews,
}) => {
  const client = useApolloClient()
  const { isFeatureEnabled } = useContext(TenantsFeatureFlagsContext)

  const { units } = useContext(UserSettingsContext)

  const [fatchingVariables, setFatchingVariables] = useState({})

  const handleSetNewValues = debounce(
    (value) => setFatchingVariables(value),
    500
  )
  const debouceChange = useCallback((value) => handleSetNewValues(value), [])

  useEffect(() => {
    if (legendItemsResult.wasSuccessful()) {
      if (controller) {
        controller.abort()
      }
      controller = new AbortController()
      const signal = controller.signal
      debouceChange({
        ...calculateFetchingVariables(
          startTime,
          endTime,
          legendItemsResult.data,
          eventIds
        ),
        units: units,
        anomaliesEnabled: isFeatureEnabled(FeatureFlags.anomalyDetection),
        client,
        signal,
      })
    }
  }, [
    startTime,
    endTime,
    legendItemsResult,
    eventIds,
    units,
    isFeatureEnabled,
    client,
  ])

  if (Object.keys(fatchingVariables).length) {
    return renderViews(fatchingVariables)
  }

  return renderAbsolutePositionedLoading()
}
