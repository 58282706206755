import React, { useEffect, useState } from 'react'
import { getLastContactKey, getNextContactKey } from '../../../../../domain/devices'
import { useTranslation } from 'react-i18next'

const DevicesListItemInfo = ({ device, subscribe, unsubscribe }) => {
  const { t } = useTranslation(['v2/common/text', 'v2/devices/common'])
  const [contactInfo, setContactInfo] = useState({
    previous: getLastContactKey(device.getExpectedPrevContactDate()),
    next: getNextContactKey(device.getNextContactDate()),
  })

  useEffect(() => {
    const handleReCalculateContactInfo = () => {
      setContactInfo({
        previous: getLastContactKey(device.getExpectedPrevContactDate()),
        next: getNextContactKey(device.getNextContactDate()),
      })
    }

    if(device.getCanProvideContact()) {
      subscribe(handleReCalculateContactInfo)
      return () => unsubscribe(handleReCalculateContactInfo)
    }
    return () => null
  }, [setContactInfo, device])

  return(
    <div className="d-flex flex-row align-items-center mt-2">
      <small className="text-muted">
        {
          `${t('pressure_data_freshness')}: 
        ${t(`pressure_data_freshness_options.${device.getDataFreshness()}`)}`
        }
      </small>
      <span className="text-muted mx-1">•</span>
      <small className="text-muted">
        {
          `${t('v2/devices/common:last_contact')}: 
          ${t(`v2/devices/common:${contactInfo.previous.text}`, { ...contactInfo.previous.value })}`
        }
      </small>
      <span className="text-muted mx-1">•</span>
      <small className="text-muted">
        {
          `${t('v2/devices/common:next_contact')}: 
          ${t(`v2/devices/common:${contactInfo.next.text}`, { ...contactInfo.next.value })}`
        }
      </small>
    </div>
  )
}

export default DevicesListItemInfo
