import React, { PureComponent } from 'react'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import { graphql } from '@apollo/client/react/hoc'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'

import MessageBox from '@@src/components/modals/message_box'
import AsyncResult from '@@src/utils/async_result'
import * as analytics from '@@src/analytics'

class DeleteUserModal extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    toggle: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onSuccess: PropTypes.func.isRequired,
  }

  static defaultProps = {
    isOpen: false,
  }

  state = {
    result: AsyncResult.notFound(),
  }

  render() {
    const { t, toggle, isOpen, user } = this.props
    const { result } = this.state
    const { email } = user

    return (
      <MessageBox
        toggle={toggle}
        isOpen={isOpen}
        result={result}
        onAccept={this.handleAccept}
        acceptText={t('buttons.delete')}
        cancelText={t('buttons.cancel')}
        headerText={t('headings.title')}>
        {t('text.confirmation', { user: email })}
      </MessageBox>
    )
  }

  handleAccept = async (event) => {
    event.preventDefault()

    const { user, toggle, onSuccess } = this.props
    const { username } = user

    try {
      this.setState({ result: AsyncResult.pending() })

      await this.props.deleteUser({
        variables: { username },
      })

      await toggle(event)
      await onSuccess()
    } catch (err) {
      analytics.logError(err)
      this.setState({ result: AsyncResult.fail(err) })
    }
  }

  static DELETE_USER_MUTATION = gql`
    mutation DeleteUser ($username: String!) {
      deleteUser (username: $username)
    }
  `
}

export default compose(
  graphql(DeleteUserModal.DELETE_USER_MUTATION, {
    name: 'deleteUser',
  }),
  withTranslation([
    'src/admin_path/users/delete_users_modal',
    'common/text',
  ]),
)(DeleteUserModal)
