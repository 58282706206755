import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import styles from './sort_component_item.css'

const SortComponentItem = props => {
  const { value, isSelected, isDescending, text } = props
  const textClassnames = classnames({ 'font-weight-bold': isSelected })
  const downArrowClassnames = classnames(
    styles['dropdown-icon'],
    'fas fa-long-arrow-alt-down',
    'font-weight-normal'
  )
  const upArrowClassnames = classnames(
    styles['dropdown-icon'],
    'fas fa-long-arrow-alt-up',
    'font-weight-normal'
  )

  return (
    <div value={value}>
      <div className={styles['dropdown-content']}>
        <div className={textClassnames}>
          {text}
        </div>
        {isSelected ?
          isDescending ?
            <i name="down-arrow-icon" className={downArrowClassnames} /> :
            <i name="up-arrow-icon" className={upArrowClassnames} />
          : null}
      </div>
    </div>
  )
}

SortComponentItem.propTypes = {
  value: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  isDescending: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
}

SortComponentItem.defaultProps = {
  isSelected: false,
  isDescending: true,
}

export default SortComponentItem
