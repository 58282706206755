import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Alert } from 'reactstrap'

import PropTypes from 'prop-types'
import './filter_alert.component.scss'

const FiltersAlert = ({
  t: filterTranslator,
  activeFilters = [],
  onClick = () => null,
}) => {
  const { t } = useTranslation('v2/common/text')
  const stringifyFilters = useMemo(() => {
    if (!Array.isArray(activeFilters) || activeFilters.length === 0) {
      return ''
    }
    return activeFilters
      .map((f) => filterTranslator(f))
      .map((f) => {
        return `${f}`
      })
      .join(', ')
  }, [filterTranslator, activeFilters])

  return (
    <Alert
      className={'d-flex justify-content-between align-items-center mt-3 mb-0'}
      isOpen={Array.isArray(activeFilters) && activeFilters.length > 0}
      color="info"
    >
      <span>
        <strong>{t('applied_filters')}: </strong>
        {stringifyFilters}
      </span>
      <span onClick={onClick} className={'clear-filters'}>
        {t('clear')}
      </span>
    </Alert>
  )
}

FiltersAlert.propTypes = {
  t: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  activeFilters: PropTypes.array.isRequired,
}

export default FiltersAlert
